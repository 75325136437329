<!-- This is Facility Setup page. It shows list of all the Facility Setups and their details -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop" id="al_tb">
            <div class="tp_mid">
              <div class="row">
                <div class="col-lg-12">
                  <ul
                    class="nav nav-tabs"
                    id="custom-tabs-one-tab"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="facility-tab"
                        data-toggle="pill"
                        href="#one"
                        role="tab"
                        aria-selected="true"
                        @click="setTab('facility-tab')"
                        >Facility Setup</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="costs-disbursement-tab"
                        data-toggle="pill"
                        href="#two"
                        role="tab"
                        aria-controls="custom-tabs-one-profile"
                        aria-selected="false"
                        @click="setTab('costs-disbursement-tab')"
                        >Cost & Disbursement</a
                      >
                    </li>
                  </ul>
                </div>
                <!-- /.col -->
              </div>
            </div>
          </div>

          <div class="col-lg-12" v-if="costsDisbursementTabs">
            <ul
              class="nav nav-tabs"
              id="custom-tabs-one-tab"
              role="tablist"
              style="border-bottom: 1px solid #f4f6f9"
            >
              <li class="nav-item ot_i">
                <a
                  class="ot_t nav-link"
                  :class="{ 'active': costsDisbursementTabContent === 'custom-tabs-one-costs-tab' }"
                  id="custom-tabs-one-costs-tab"
                  data-toggle="pill"
                  href="#costs"
                  @click="
                    setTab('custom-tabs-one-costs-tab', 'costsDisbursement')
                  "
                  role="tab"
                  aria-controls="custom-tabs-one-costs"
                  aria-selected="true"
                  >Costs</a
                >
              </li>
              <li class="nav-item ot_i">
                <a
                  class="ot_t nav-link"
                  :class="{ 'active': costsDisbursementTabContent === 'custom-tabs-one-disbursement-tab' }"
                  id="custom-tabs-one-disbursement-tab"
                  data-toggle="pill"
                  href="#disbursement"
                  @click="
                    setTab(
                      'custom-tabs-one-disbursement-tab',
                      'costsDisbursement'
                    )
                  "
                  role="tab"
                  aria-controls="custom-tabs-one-disbursement"
                  aria-selected="false"
                  >Disbursements</a
                >
              </li>
              <li class="nav-item ot_i">
                <a
                  class="ot_t nav-link"
                  :class="{ 'active': costsDisbursementTabContent === 'custom-tabs-one-cost-payment-tab' }"
                  id="custom-tabs-one-cost-payment-tab"
                  data-toggle="pill"
                  href="#costPayments"
                  @click="
                    setTab(
                      'custom-tabs-one-cost-payment-tab',
                      'costsDisbursement'
                    )
                  "
                  role="tab"
                  aria-controls="custom-tabs-one-cost-payments"
                  aria-selected="false"
                  >Cost Payments</a
                >
              </li>
            </ul>
          </div>

          <div class="tab-content" id="custom-tabs-one-tabContent">
            <div
              class="tab-pane fade show active"
              id="one"
              role="tabpanel"
              aria-labelledby="facility-tab"
            >
              <div
                class="container-fluid"
                v-if="loggedInUserGroupId != 'Borrower'"
              >
                <div class="row">
                  <div class="col-md-6 col-sm-5 col-6">
                    <div class="form-group fc">
                      <div class="form-field">
                        <label
                          style="margin-right: 10px"
                          class="exampleInputEmail1"
                          >Filter By:</label
                        >
                        <input type="radio" v-model="filter" value="date" />
                        <label style="margin: 5px; padding-right: 10px"
                          >Date</label
                        >

                        <input type="radio" v-model="filter" value="period" />
                        <label style="margin: 5px; padding-right: 10px"
                          >Period</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6" v-if="filter === 'date'">
                    <div class="form-group fc">
                      <label for="startDate">From Date</label>

                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        id="startDate"
                        placeholder="Select Date"
                        v-model="startDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fas fa-calendar"
                        :calendar-button="true"
                      />
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6" v-if="filter === 'date'">
                    <div class="form-group fc">
                      <label for="endDate">To Date</label>

                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        id="endDate"
                        placeholder="Select Date"
                        v-model="endDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{ to: new Date(startDate - 8640000) }"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="col-md-2" v-if="filter === 'period'">
                    <div class="form-group fc">
                      <label>Period</label>
                      <select
                        id="selectedPeriod"
                        class="form-control"
                        name="selectedPeriod"
                        v-model="selectedPeriod"
                        @change="onPeriodSelect"
                        required
                      >
                        <option value="ytd">YTD</option>
                        <option value="mtd">MTD</option>
                        <option value="last_month">Last Month</option>
                        <option value="qtd">QTD</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="last_2_quarters">Last 2 Quarters</option>
                        <option value="last_fy_1">Last 1 FY Year</option>
                        <option value="last_fy_2">Last 2 FY Years</option>
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>

                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="filter === 'period'"
                  >
                    <div class="form-group fc">
                      <label for="endDate">From Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        id="endDate"
                        v-model="startDatePeriod"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabled="true"
                      ></datepicker>
                    </div>
                  </div>

                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="filter === 'period'"
                  >
                    <div class="form-group fc">
                      <label for="endDate">To Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        id="endDate"
                        v-model="endDatePeriod"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabled="true"
                        :disabledDates="{
                          to: new Date(startDatePeriod - 8640000),
                        }"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group fc">
                      <label>Status</label>
                      <select
                        id="selectedStatus"
                        class="form-control"
                        name="selectedStatus"
                        v-model="selectedStatus"
                        required
                      >
                        <option value="Open">Open</option>
                        <option value="Closed">Closed</option>
                        <option value="Pending">Pending</option>
                        <option value="All">All</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-2 col-12">
                    <ul id="sr_btn">
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh_n mt"
                          v-on:click="getAllFacility()"
                          Filter
                        >
                          Filter
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-dark sh_n mt"
                          v-on:click="resetFacility()"
                        >
                          Reset
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="container-fluid ot_tb pt-3">
                <div class="card card-primary card-tabs">
                  <div class="card-body">
                    <div class="container-fluid" id="al_tb">
                      <div class="tp_mid">
                        <div class="row">
                          <div class="col-lg-6 col-sm-6">
                            <h1 class="headingt">
                              <i class="icofont-coins"></i> Facility Setup
                            </h1>
                          </div>
                          <!-- /.col -->
                          <div
                            class="col-lg-6 col-sm-6"
                            v-if="
                              loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0)
                            "
                          >
                            <ul id="sr_btn">
                              <li>
                                <button
                                  type="button"
                                  class="btn btn-sm sh_n btn-success"
                                  @click="$router.push('/add-facility')"
                                >
                                  <i class="icofont-plus"></i>
                                  <span class="mob">Add Facility</span>
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div
                            class="col-lg-6 col-sm-6"
                            v-if="loggedInUserGroupId == 'Admin'"
                          >
                            <input
                              id="active"
                              type="radio"
                              :value="false"
                              @change="destroyTable"
                              v-model="inActiveToggle"
                            />
                            <label for="active" style="padding-right: 20px"
                              >Active</label
                            >
                            <input
                              id="inactive"
                              type="radio"
                              :value="true"
                              @change="destroyTable"
                              v-model="inActiveToggle"
                            />
                            <label for="inactive">InActive</label>
                          </div>
                          <!-- /.col -->
                        </div>
                      </div>

                      <div
                        class="card-body table-responsive p-0"
                        id="datatable-padding"
                      >
                        <!------menton here style="height: 300px;"-------->
                        <table
                          class="table table-head-fixed text-nowrap table-hover myTable1"
                          id="myTable1"
                        >
                          <thead>
                            <tr>
                              <th>Facility ID</th>
                              <th v-if="loggedInUserGroupId == 'Borrower'">
                                Name
                              </th>
                              <th v-else>Borrower Name</th>
                              <!-- <th>Interest Rate(%)</th>
                              <th>cost</th>
                              <th>Tenure</th> -->
                              <th>Status</th>
                              <th
                                class="c"
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0 &&
                                  !inActiveToggle
                                "
                              >
                                Change Status
                              </th>

                              <th v-if="!inActiveToggle && 
                                  (loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0))" 
                                  class="c center">
                                Add Uti Req
                              </th>
                              <th
                                class="c center"
                                v-if="
                                    (loggedInUserGroupId == 'Analyst' ||
                                    (loggedInUserGroupId == 'Admin' &&
                                      loggedInUserSubGroupId == 0)) &&
                                  !inActiveToggle
                                "
                              >
                                Edit
                              </th>
                              <th
                                class="c center"
                                v-if="
                                  loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0) &&
                                  !inActiveToggle
                                "
                              >
                                Delete
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="(facility, index) in facilities"
                              :key="facility.id"
                            >
                              <template
                                v-if="
                                  loggedInUserGroupId == 'Admin' ||
                                  (loggedInUserGroupId == 'Analyst' &&
                                    loggedInUserSubGroupId == 0)
                                "
                              >
                                <td
                                  v-if="
                                    facility.facility_registration_id !== null
                                  "
                                  class="ln"
                                >
                                  <router-link
                                    :to="{
                                      name: 'FacilityOverview',
                                      params: {
                                        id: facility.facility_id,
                                        inactive: inActiveToggle,
                                      },
                                    }"
                                    >{{
                                      facility.facility_registration_id
                                    }}</router-link
                                  >
                                </td>
                                <td v-else>-</td>
                                <td>
                                  {{ facility.borrower_name }}
                                </td>

                                <!-- <td v-if="facility.interest_type_id === 1">
                                  {{ facility.interest_rate }}
                                </td>
                                <td v-else>
                                  {{ facility.interest_spread }}+{{
                                    facility.benchmark_name
                                  }}
                                </td>

                                <td v-if="facility.cost_name !== null">
                                  {{ facility.cost_name }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="facility.tenure !== null">
                                  {{ facility.tenure }} {{ facility.period }}
                                </td>
                                <td v-else>-</td> -->

                                <td
                                  :class="{
                                    'c text-success':
                                      facility.status === 'Open',
                                    'c text-danger':
                                      facility.status === 'Closed',
                                    'c text-warning':
                                      facility.status === 'Pending',
                                  }"
                                >
                                  {{ facility.status }}
                                </td>
                                <td
                                  class="c status"
                                  v-if="
                                    loggedInUserGroupId == 'Admin' &&
                                    loggedInUserSubGroupId == 0 &&
                                    !inActiveToggle
                                  "
                                >
                                  <div class="status">
                                    <select
                                      id="ReqStatus"
                                      name="ReqStatus"
                                      @change="
                                        onChangeStatus(
                                          $event,
                                          facility.facility_id,
                                          facility.status,
                                          index
                                        )
                                      "
                                      class="form-control"
                                      :value="facility.status"
                                      :disabled="facility.status === 'Closed'"
                                    >
                                      <option value="Open">Open</option>
                                      <option value="Closed">Closed</option>
                                      <option value="Pending">Pending</option>
                                    </select>
                                  </div>
                                </td>
                                <template v-if="!inActiveToggle && 
                                  (loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0))">
                                  <td
                                    class="c text-danger center"
                                    v-if="facility.status === 'Open'"
                                  >
                                    <router-link
                                      style="text-decoration: none"
                                      :to="{
                                        name: 'AddLoan',
                                        params: {
                                          id: facility.borrower_id,
                                          facilityId: facility.facility_id,
                                          type: 'facility',
                                          facRegDate:
                                            facility.facility_request_date,
                                        },
                                      }"
                                    >
                                      <i class="icofont-plus text-success"></i>
                                    </router-link>
                                  </td>
                                  <td class="c text-danger center" v-else>
                                    <i
                                      style="opacity: 0.5"
                                      class="icofont-plus text-success"
                                    ></i>
                                  </td>
                                </template>
                                <template v-if="!inActiveToggle">
                                  <td
                                    class="c center"
                                    v-if="
                                      (facility.status == 'Open' ||
                                        facility.status == 'Pending') &&
                                      (
                                        loggedInUserGroupId == 'Analyst' ||
                                        (loggedInUserGroupId == 'Admin' &&
                                          loggedInUserSubGroupId == 0))
                                    "
                                  >
                                    <router-link
                                      style="text-decoration: none"
                                      :to="{
                                        name: 'EditFacility',
                                        params: {
                                          id: facility.facility_id,
                                          BorRegDate:
                                            facility.registration_date,
                                        },
                                      }"
                                    >
                                      <i
                                        class="icofont-ui-edit text-warning"
                                      ></i>
                                    </router-link>
                                  </td>
                                  <td class="c center" v-else>
                                    <i
                                      style="opacity: 0.5"
                                      class="icofont-ui-edit text-warning"
                                    ></i>
                                  </td>
                                </template>
                                <template
                                  v-if="
                                    !inActiveToggle &&
                                    loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)
                                  "
                                >
                                  <td
                                    class="c center"
                                    v-if="
                                      facility.status == 'Open' ||
                                      facility.status == 'Pending'
                                    "
                                    v-on:click="
                                      deleteFacility(facility.facility_id)
                                    "
                                    :style="{ cursor: 'pointer' }"
                                  >
                                    <i class="icofont-trash text-danger"></i>
                                  </td>
                                  <td class="c center" v-else>
                                    <i
                                      style="opacity: 0.5"
                                      class="icofont-trash text-danger"
                                    ></i>
                                  </td>
                                </template>
                              </template>
                              <template
                                v-else-if="
                                  loggedInUserGroupId == 'Borrower' &&
                                  loggedInUserSubGroupId == facility.borrower_id
                                "
                              >
                              <td
                                  v-if="
                                    facility.facility_registration_id !== null
                                  "
                                  class="ln"
                                >
                                  <router-link
                                    :to="{
                                      name: 'FacilityOverview',
                                      params: {
                                        id: facility.facility_id,
                                      },
                                    }"
                                    >{{
                                      facility.facility_registration_id
                                    }}</router-link
                                  >
                                </td>
                                <td v-else>-</td>
                                <td>
                                  {{ facility.borrower_name }}
                                </td>
                                
                                <td
                                  :class="{
                                    'c text-success':
                                      facility.status === 'Open',
                                    'c text-danger':
                                      facility.status === 'Closed',
                                    'c text-warning':
                                      facility.status === 'Pending',
                                  }"
                                >
                                  {{ facility.status }}
                                </td>
                                <template v-if="!inActiveToggle && 
                                  (loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0))">
                                  <td
                                    v-if="(facility.status === 'Open')"
                                    class="c text-danger center"
                                  >
                                    <router-link
                                      style="text-decoration: none"
                                      :to="{
                                        name: 'AddLoan',
                                        params: {
                                          id: facility.borrower_id,
                                          facilityId: facility.facility_id,
                                          type: 'facility',
                                          facRegDate:
                                            facility.facility_request_date,
                                        },
                                      }"
                                    >
                                      <i class="icofont-plus text-success"></i>
                                    </router-link>
                                  </td>
                                  <td v-else class="center">
                                    <i
                                      style="opacity: 0.5"
                                      class="icofont-plus text-success"
                                    ></i>
                                  </td>
                                </template>
                                <template v-if="!inActiveToggle && 
                                  (loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0))">
                                  <td
                                    class="c center"
                                    v-if="
                                      facility.status == 'Open' ||
                                      facility.status == 'Pending'
                                    "
                                  >
                                    <router-link
                                      style="text-decoration: none"
                                      :to="{
                                        name: 'EditFacility',
                                        params: {
                                          id: facility.facility_id,
                                          BorRegDate: facility.registration_date,
                                        },
                                      }"
                                    >
                                      <i class="icofont-ui-edit text-warning"></i>
                                    </router-link>
                                  </td>
                                  <td v-else class="center">
                                    <i
                                      style="opacity: 0.5"
                                      class="icofont-ui-edit text-warning"
                                    ></i>
                                  </td>
                                </template>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- /.card -->
                </div>
              </div>
            </div>
            <!-- /.Tabpane first end-->

            <div
              class="tab-pane fade"
              id="two"
              role="tabpanel"
              aria-labelledby="costs-disbursement-tab"
            >
              <div
                class="container-fluid"
              >
                <div
                  v-if="costsDisbursementTabContent != 'custom-tabs-one-disbursement-tab'"
                  class="row">
                  <div class="col-md-6 col-sm-5 col-6">
                    <div class="form-group fc">
                      <div class="form-field">
                        <label
                          style="margin-right: 10px"
                          class="exampleInputEmail1"
                          >Filter By:</label
                        >
                        <input type="radio" v-model="filter1" value="date1" />
                        <label style="margin: 5px; padding-right: 10px"
                          >Date</label
                        >

                        <input type="radio" v-model="filter1" value="period1" />
                        <label style="margin: 5px; padding-right: 10px"
                          >Period</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div v-if="loggedInUserGroupId != 'Borrower'" class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedBorrower" class="required-field">Borrower Name</label>
                      <select
                        id="selectedBorrower"
                        class="form-control"
                        v-model="selectedBorrower"
                        @change="onBorrowerChange();getAllFacilitySetupByBorrower()"
                        :disabled="loggedInUserGroupId == 'Borrower'"
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="borrower in borrowers"
                          :value="borrower.borrower_id"
                          :key="borrower.borrower_id"
                        >
                          {{ borrower.borrower_name }}
                        </option>
                      </select>
                      <!-- <div
                        v-if="isSubmitted && $v.selectedBorrower.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedBorrower.required"
                          >Borrower Name is required</span
                        >
                      </div> -->
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedFacility">Facility ID</label>
                      <select
                        id="selectedFacility"
                        class="form-control"
                        v-model="selectedFacility"
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="fac in facilitiesCost"
                          :value="fac.id"
                          :key="fac.id"
                        >
                          {{ fac.facility_registration_id }}
                        </option>
                      </select>
                      <!-- <div
                        v-if="isSubmitted && $v.selectedFacility.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedFacility.required"
                          >Facility ID is required</span
                        >
                      </div> -->
                    </div>
                  </div>

                  <div  class="col-md-2 col-sm-5 col-6" v-if="filter1 === 'date1' && costsDisbursementTabContent != 'custom-tabs-one-disbursement-tab'">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">From Date</label>

                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="startDate"
                        placeholder="Select Date"
                        v-model="startDate1"
                        :format="$store.state.date_format"
                        calendar-button-icon="fas fa-calendar"
                        :calendar-button="true"
                      />
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6" v-if="filter1 === 'date1' && costsDisbursementTabContent != 'custom-tabs-one-disbursement-tab'">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">To Date</label>

                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDate"
                        placeholder="Select Date"
                        v-model="endDate1"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{ to: new Date(startDate1 - 8640000) }"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="col-md-2" v-if="filter1 === 'period1' && costsDisbursementTabContent != 'custom-tabs-one-disbursement-tab'">
                    <div class="form-group fc">
                      <label>Period</label>
                      <select
                        id="selectedPeriod1"
                        class="form-control"
                        name="selectedPeriod1"
                        v-model="selectedPeriod1"
                        required
                      >
                        <option :value="null">-- Select --</option>
                        <option value="ytd">YTD</option>
                        <option value="mtd">MTD</option>
                        <option value="last_month">Last Month</option>
                        <option value="qtd">QTD</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="last_2_quarters">Last 2 Quarters</option>
                        <option value="last_fy_1">Last 1 FY Year</option>
                        <option value="last_fy_2">Last 2 FY Years</option>
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>

                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="filter1 === 'period1' && costsDisbursementTabContent != 'custom-tabs-one-disbursement-tab'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">From Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDate"
                        placeholder
                        v-model="startDatePeriod1"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabled="true"
                      ></datepicker>
                    </div>
                  </div>

                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="filter1 === 'period1' && costsDisbursementTabContent != 'custom-tabs-one-disbursement-tab'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">To Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDate"
                        placeholder
                        v-model="endDatePeriod1"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabled="true"
                        :disabledDates="{
                          to: new Date(startDatePeriod1 - 8640000),
                        }"
                      ></datepicker>
                    </div>
                  </div>

                  <div v-if="costsDisbursementTabContent != 'custom-tabs-one-disbursement-tab'" class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label>Currency</label>
                      <select
                        id="selectedCurrency"
                        class="form-control"
                        v-model="selectedCurrency"
                      >
                        <option :value="null">-- Select a Currency --</option>
                        <option
                          v-for="curr in currencies"
                          :value="curr.id"
                          :key="curr.id"
                        >
                          {{ curr.currency_short_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div v-if="costsDisbursementTabContent != 'custom-tabs-one-disbursement-tab'" class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label >Cost Name</label>
                      <select
                        id="selectedCost"
                        class="form-control"
                        v-model="selectedCost"
                      >
                        <option :value="null">-- Select a Cost --</option>
                        <option
                          v-for="cost in loanExpenses"
                          :value="cost.id"
                          :key="cost.id"
                        >
                          {{ cost.loan_expense_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- for Period -->
                  <div 
                    v-if="((loggedInUserGroupId != 'Borrower' && filter1 == 'period1') || (loggedInUserGroupId == 'Borrower' && filter1 == 'date1')) && costsDisbursementTabContent != 'custom-tabs-one-disbursement-tab'" 
                    class="col-md-2 col-sm-2 col-12">
                    <ul id="sr_btn">
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh_n mt"
                          v-on:click="filterData()"
                          Filter
                        >
                          Filter
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-dark sh_n mt"
                          v-on:click="resetCostDisbursementPaymentSummary()"
                        >
                          Reset
                        </button>
                      </li>
                    </ul>
                  </div>

                  <div 
                    v-if="costsDisbursementTabContent == 'custom-tabs-one-disbursement-tab'" 
                    class="col-md-2 col-sm-2 col-12">
                    <ul id="sr_btn">
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh_n mt"
                          v-on:click="filterData()"
                          Filter
                        >
                          Filter
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-dark sh_n mt"
                          v-on:click="resetCostDisbursementPaymentSummary()"
                        >
                          Reset
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- For date -->
                <div 
                  v-if="((loggedInUserGroupId != 'Borrower' && filter1 == 'date1') || (loggedInUserGroupId == 'Borrower' && filter1 == 'period1')) && costsDisbursementTabContent != 'custom-tabs-one-disbursement-tab'" 
                  class="row">
                  <div class="col-12">
                    <ul 
                    id="sr_btn" 
                    style="margin-top: -25px !important"
                    class="d-flex justify-content-start">
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh_n mt"
                          v-on:click="filterData()"
                          Filter
                        >
                          Filter
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-dark sh_n mt"
                          v-on:click="resetCostDisbursementPaymentSummary()"
                        >
                          Reset
                        </button>
                      </li>
                    </ul>
                  </div>
                </div> 
              </div>
              <div
                v-if="
                  costsDisbursementTabContent === 'custom-tabs-one-costs-tab'
                "
                role="tabpanel"
              >
                <div class="container-fluid ot_tb pt-3">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="container-fluid" id="al_tb">
                        <div class="tp_mid">
                          <div class="row">
                            <div class="col-lg-6 col-sm-6">
                              <h1 class="headingt">
                                <i class="icofont-coins"></i>
                                Costs
                              </h1>
                            </div>
                            <div
                              class="col-lg-6 col-sm-6"
                              v-if="
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                              "
                            >
                              <ul id="sr_btn">
                                <li>
                                  <button
                                    id="addCostButton"
                                    type="button"
                                    class="btn btn-sm sh_n btn-success"
                                    @click="$router.push('/add-cost')"
                                  >
                                    <i class="icofont-plus"></i>
                                    <span class="mob">Add Cost</span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div
                          class="card-body table-responsive p-0"
                          id="datatable-padding"
                        >
                          <table
                            class="table text-nowrap table-hover costTable"
                            id="costTable"
                          >
                            <thead>
                              <tr>
                                <th>Cost ID</th>
                                <th>Facility ID</th>
                                <th>Cost Name</th>
                                <th>Cost Amount</th>
                                <th>Converted Amount</th>
                                <th>Cost Accrued Interest</th>
                                <th>Paid Amount</th>
                                <th>Outstanding Amount</th>
                                <th>Cost Currency</th>
                                <th>Fund Currency</th>
                                <th>Date</th>
                                <th>Status</th>
                                <template
                                    v-if="
                                      loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)"
                                  >
                                <th>Change Status</th>
                                <th>Delete</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                id="tdrow"
                                v-for="(cost, index) in costs"
                                :key="cost.id"
                              >
                                <template
                                  v-if="
                                    loggedInUserGroupId == 'Borrower' ||
                                    loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)
                                  "
                                >
                                  <td class="ln">
                                    <router-link
                                      active-link="active"
                                      :to="{
                                        name: 'CostOverview',
                                        params: {
                                          id: cost.id,
                                          borrower_name: cost.id,
                                        },
                                      }"
                                      >{{ cost.id }}</router-link
                                    >
                                  </td>
                                  <td v-if="cost.facility_registration_id">
                                    {{ cost.facility_registration_id }}
                                  </td>
                                  <td v-else>-</td>
                                  <td v-if="cost.loan_expense_name">
                                    {{ cost.loan_expense_name }}
                                  </td>
                                  <td v-else>-</td>
                                  <td class="right" v-if="cost.expense_amount">
                                    {{ cost.expense_amount }}
                                  </td>
                                  <td class="right" v-else>-</td>
                                  <td class="right" v-if="cost.converted_exp_amt">
                                    {{ cost.converted_exp_amt }}
                                  </td>
                                  <td class="right" v-else>-</td>
                                  <td class="right" v-if="cost.cost_accrued_interest">
                                    {{ cost.cost_accrued_interest }}
                                  </td>
                                  <td class="right" v-else>-</td>
                                  <td class="right" v-if="cost.amount_paid">
                                    {{ cost.amount_paid }}
                                  </td>
                                  <td class="right" v-else>-</td>
                                  <td class="right" v-if="cost.outstanding_amount">
                                    {{ cost.outstanding_amount }}
                                  </td>
                                  <td class="right" v-else>-</td>
                                  <td v-if="cost.currency_short_name">
                                    {{ cost.currency_short_name }}
                                  </td>
                                  <td v-else>-</td>
                                  <td v-if="cost.fund_currency_short_name">
                                    {{ cost.fund_currency_short_name }}
                                  </td>
                                  <td v-else>-</td>
                                  <td v-if="cost.expense_date">
                                    {{ cost.expense_date }}
                                  </td>
                                  <td v-else>-</td>
                                  <td
                                    v-if="cost.status !== null"
                                    :class="{
                                      'c text-success':
                                        cost.status === 'Approved',
                                      'c text-danger':
                                        cost.status === 'Rejected',
                                      'c text-warning':
                                        cost.status === 'Pending',
                                    }"
                                  >
                                    {{ cost.status }}
                                  </td>
                                  <td v-else>-</td>
                                  <td
                                    class="c"
                                    v-if="
                                      loggedInUserGroupId == 'Admin' ||
                                      (loggedInUserGroupId == 'Analyst' &&
                                        loggedInUserSubGroupId == 0)"
                                  >
                                    <div class="status">
                                      <select
                                        id="selectedCostStatus"
                                        name="selectedCostStatus"
                                        class="form-control"
                                        :value="cost.status"
                                        @change="
                                          onChangeCostStatus(
                                            $event,
                                            cost.id,
                                            cost.status,
                                            index,
                                          )
                                        "
                                        :disabled="cost.status !== 'Pending'"
                                      >
                                        <option value="Pending">Pending</option>
                                        <option value="Approved">Approved</option>
                                        <option value="Rejected">Rejected</option>
                                      </select>
                                    </div>
                                  </td>
                                  <template
                                    v-if="
                                      loggedInUserGroupId == 'Admin' ||
                                      (loggedInUserGroupId == 'Analyst' &&
                                        loggedInUserSubGroupId == 0)"
                                  >
                                    <td
                                      v-if="cost.status === 'Pending'"
                                      class="center"
                                      :style="{ cursor: 'pointer' }"
                                      @click="
                                        deleteCost(
                                          cost.id
                                        )
                                      "
                                    >
                                      <i class="icofont-trash text-danger"></i>
                                    </td>
                                    <td class="center" v-else>
                                      <i
                                        style="opacity: 0.5"
                                        disabled
                                        class="icofont-trash text-danger"
                                      ></i>
                                    </td>
                                  </template>
                                </template>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        
                        
                      </div>
                    </div>
                    <span style="font-size: 14px; color: red; margin-left:10px !important; margin-bottom: 10px !important; margin-top: -10px !important;">
                      Note: All calculations are displayed in Fund Currency.</span>
                  </div>
                  
                </div>
                
              </div>
              <div
                v-if="
                  costsDisbursementTabContent ===
                  'custom-tabs-one-disbursement-tab'
                "
                role="tabpanel"
              >
                <div class="container-fluid ot_tb pt-3">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="container-fluid" id="al_tb">
                        <div class="tp_mid">
                          <div class="row">
                            <div class="col-lg-6 col-sm-6">
                              <h1 class="headingt">
                                <i class="icofont-coins"></i>
                                Disbursements
                              </h1>
                            </div>
                            <div
                              class="col-lg-6 col-sm-6"
                              v-if="
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                              "
                            >
                              <ul id="sr_btn">
                                <li>
                                  <button
                                    id="addDisbursementButton"
                                    type="button"
                                    class="btn btn-sm sh_n btn-success"
                                    @click="$router.push('/add-disbursement')"
                                  >
                                    <i class="icofont-plus"></i>
                                    <span class="mob">Add Disbursement</span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div
                          class="card-body table-responsive p-0"
                          id="datatable-padding"
                        >
                          <table
                            class="table text-nowrap table-hover disbursementTable"
                            id="disbursementTable"
                          >
                            <thead>
                              <tr>
                                <th>Disbursement ID</th>
                                <th>Fund Name</th>
                                <th>Facility ID</th>
                                <th>Disbursement On</th>
                                <th>Collateral Name</th>
                                <!-- <th>Actual Disbursement</th>
                                <th>Available to Disburse</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                id="tdrow"
                                v-for="dis in disbursements"
                                :key="dis.id"
                              >
                                <template
                                  v-if="
                                    loggedInUserGroupId == 'Borrower' ||
                                    loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)
                                  "
                                >
                                  <td v-if="dis.id" class="ln">
                                    <router-link
                                      active-link="active"
                                      :to="{
                                        name: 'DisbursementOverview',
                                        params: {
                                          id: dis.id,
                                        },
                                      }"
                                      >{{ dis.id  }}</router-link
                                    >
                                  </td>
                                  <td v-else>-</td>
                                  <td v-if="dis.fund_name">
                                    {{ dis.fund_name }}
                                  </td>
                                  <td v-else>-</td>
                                  <td v-if="dis.facility_registration_id">
                                    {{ dis.facility_registration_id }}
                                  </td>
                                  <td v-else>-</td>
                                  <td v-if="dis.disbursement_on">
                                    {{ dis.disbursement_on }}
                                  </td>
                                  <td v-else>-</td>
                                  <td v-if="dis.collateral_name">
                                    {{ dis.collateral_name }}
                                  </td>
                                  <td v-else>-</td>
                                  <!-- <td class="right" v-if="dis.actual_disbursement_amount">
                                    {{ dis.actual_disbursement_amount }}
                                  </td>
                                  <td class="right" v-else>-</td>
                                  <td class="right" v-if="dis.available_amount">
                                    {{ dis.available_amount }}
                                  </td>
                                  <td class="right" v-else>-</td> -->
                                </template>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  costsDisbursementTabContent ===
                  'custom-tabs-one-cost-payment-tab'
                "
                role="tabpanel"
              >
                <div class="container-fluid ot_tb pt-3">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="container-fluid" id="al_tb">
                        <div class="tp_mid">
                          <div class="row">
                            <div class="col-lg-6 col-sm-6">
                              <h1 class="headingt">
                                <i class="icofont-coins"></i>
                                Cost Payments
                              </h1>
                            </div>
                            <div
                              class="col-lg-6 col-sm-6"
                              v-if="
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                              "
                            >
                              <ul id="sr_btn">
                                <li>
                                  <button
                                    id="addPaymentButton"
                                    type="button"
                                    class="btn btn-sm sh_n btn-success"
                                    @click="$router.push('/add-cost-payment')"
                                  >
                                    <i class="icofont-plus"></i>
                                    <span class="mob">Add Payment</span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div
                          class="card-body table-responsive p-0"
                          id="datatable-padding"
                        >
                          <table
                            class="table text-nowrap table-hover paymentsTable"
                            id="paymentsTable"
                          >
                            <thead>
                              <tr>
                                <th>Payment ID</th>
                                <th>Facility ID</th>
                                <th>Cost Name</th>
                                <th>Paid Amount</th>
                                <th>Converted Amount</th>
                                <th>Balance Amount</th>
                                <th>Payment Currency</th>
                                <th>Fund Currency</th>
                                <th>Payment Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                id="tdrow"
                                v-for="cp in costPayments"
                                :key="cp.id"
                              >
                                <template
                                  v-if="
                                    loggedInUserGroupId == 'Borrower' ||
                                    loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)
                                  "
                                >
                                  <td class="ln">
                                    <router-link
                                      active-link="active"
                                      :to="{
                                        name: 'CostPaymentOverview',
                                        params: {
                                          id: cp.id,
                                          borrower_name: cp.id,
                                        },
                                      }"
                                      >{{ cp.id }}</router-link
                                    >
                                  </td>
                                  <td v-if="cp.facility_registration_id">
                                    {{ cp.facility_registration_id }}
                                  </td>
                                  <td v-else>-</td>
                                  <td v-if="cp.loan_expense_name">
                                    {{ cp.loan_expense_name }}
                                  </td>
                                  <td v-else>-</td>
                                  <td class="right" v-if="cp.amount_paid">
                                    {{ cp.amount_paid }}
                                  </td>
                                  <td v-else class="right">-</td>
                                  <td class="right" v-if="cp.converted_paid_amt">
                                    {{ cp.converted_paid_amt }}
                                  </td>
                                  <td class="right" v-else>-</td>
                                  <td class="right" v-if="cp.balance_amount">
                                    {{ cp.balance_amount }}
                                  </td>
                                  <td class="right" v-else>-</td>
                                  <td v-if="cp.currency_short_name">
                                    {{ cp.currency_short_name }}
                                  </td>
                                  <td v-else>-</td>
                                  <td v-if="cp.fund_currency_short_name">
                                    {{ cp.fund_currency_short_name }}
                                  </td>
                                  <td v-else>-</td>
                                  <td v-if="cp.payment_date">
                                    {{ cp.payment_date }}
                                  </td>
                                  <td v-else>-</td>
                                </template>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <span style="font-size: 14px; color: red; margin-left:10px !important; margin-bottom: 10px !important; margin-top: -10px !important;">
                      Note: All calculations are displayed in Fund Currency.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.content-->
      </div>
      <!-- /.content-wrapper -->
    </div>
    <!-- ./wrapper -->
  </div>
  
</template>

<script>
import $ from "jquery";
// //Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function

export default {
  data() {
    return {
      facilities: [],
      filteredFacilities: [],
      borrowers: [],
      costs: [],
      currencies: [],
      loanExpenses: [],
      filteredCosts: [],
      disbursements: [],
      // disbursements: [
      //   {
      //     id: 1,
      //     facilities: [
      //       { id: 101, facility_registration_id: 'FAC001' },
      //       { id: 102, facility_registration_id: 'FAC002' },
      //     ],
      //     collateral_name: 'Collateral 1',
      //     disbursement_on: 'Collateral',
      //     actual_disbursement_amount: 5000,
      //     available_amount: 3000,
      //   },
      //   {
      //     id: 2,
      //     facilities: [
      //       { id: 103, facility_registration_id: 'FAC003' },
      //     ],
      //     collateral_name: 'Collateral 2',
      //     disbursement_on: 'Collateral',
      //     actual_disbursement_amount: 6000,
      //     available_amount: 4000,
      //   },
      //   // Add more disbursement objects as needed
      // ],
      costPayments: [],
      ReqStatus: null,
      // ---------------------------------------
      // facility filter
      startDate: null,
      endDate: null,
      selectedPeriod: null,
      selectedStatus: "All",
      filter: "date",
      startDatePeriod: null, //for period filter
      endDatePeriod: null, //for period filter
      // ---------------------------------------
       // Cost & Disbursment filter
      facilitiesCost: [],
      startDate1: null,
      endDate1: null,
      selectedPeriod1: null,
      filter1: "date1",
      startDatePeriod1: null, //for period filter
      endDatePeriod1: null, //for period filter
      selectedBorrower: null,
      selectedFacility: null,
      selectedCostStatus: null,
      selectedCurrency:null,
      selectedCost:null,
      // ---------------------------------------
      BorRegDate: null,
      borrower_id: null,
      registration_date: null,
      inActiveToggle: false,
      costsDisbursementTabs: false,
      costsDisbursementTabContent: null,
    };
  },
  components: {
    Datepicker,
  },
  methods: {
    getAllFacilitySetupByBorrower() {
      if (this.selectedBorrower === null) {
        this.facilitiesCost=[];
        this.selectedFacility = null;
        Utils.make_alert("warning", "Borrower Filter is required");
        return;
      }
      this.selectedFacility = null
      //this function is called to get list of day count
      const path = "/all-facility-setup-borrower/" + this.selectedBorrower;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.facilitiesCost = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    destroyTable() {
      $("#myTable1").dataTable().fnDestroy();
      if (!this.inActiveToggle) {
        this.$store.commit("setinactive", "facility_active");
        this.$router.go();
      } else {
        this.$store.commit("setinactive", "facility_inactive");
        this.getFacility();
      }
    },
    onPeriodSelect() {
      if (this.selectedPeriod === "all") {
        this.selectedStatus = "All";
      } else {
        this.selectedStatus = "Open";
      }
    },
    onChangeStatus(updatedStatus, facilityId, prevStatus, index) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            //this function is called when user changes the facility registration status. The status is updated in the database for that specific facility.
            const path = "facility-overview/" + facilityId;
            axios
              .put(
                path,
                {
                  updateType: "ReqStatus",
                  updatedFacStatus: updatedStatus.target.value,
                  facId: this.facilityId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 4,
                  tabId: 14,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("registration update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Registration Status Updated Successfully!"
                  );
                  this.facilities[index].status = updatedStatus.target.value;
                } else {
                  console.log("Something went wrong from server!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeStatus close
    onChangeCostStatus(updatedStatus, costId, prevStatus, index) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            //this function is called when user changes the cost status. The status is updated in the database for that specific facility.
            const path = "cost-overview/" + costId;
            axios
              .put(
                path,
                {
                  updateType: "CostStatus",
                  updatedCostStatus: updatedStatus.target.value,
                  costId: costId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 4,
                  tabId: 34,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("registration update status: " + res.data.status);
                console.log("data", res.data);
                if (res.data.status == "success") {
                  this.costs[index].status = updatedStatus.target.value;
                  let cost_id = res.data.cost_id
                  let user_id = this.$store.getters.getuserId
                  let status = res.data.cost_status

                  console.log(cost_id)
                  console.log(user_id)
                  console.log(status)
                  this.costMail(cost_id,user_id,status)
                  // if(updatedStatus.target.value == 'Approved'){
                  //   this.deductionAmountAPI(cost_id)
                  //   this.paymentTrigger(facility_id,borrower_id,expense_id,cost_id)
                  // }
                  if(updatedStatus.target.value != 'Approved'){
                    Utils.make_alert(
                      "success",
                      "Cost Status updated successfully!"
                    );
                  }

                } else {
              console.log("Something went wrong from server!");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
            Utils.make_alert("warning", "Something went wrong!");
          });
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeCostStatus close

    deductionAmountAPI(facility_id,borrower_id,expense_id,cost_id){
      // For triggering payment when we chnage status
      const path = "deduction_amount/" + cost_id + "/" + null;
          Utils.start_loading();
          axios
            .get(path, { params: { clientId: this.$store.state.client_id } })
            .then((res) => {
              console.log(res)
              Utils.stop_loading();
              if(res.data.status == 'success') {
                let loanIDs = res.data.loan_ids
                console.log("loanIDs",loanIDs)
                if(loanIDs.length > 0) {
                  this.getReportLoanTransaction(facility_id,borrower_id,expense_id,cost_id,loanIDs);
                } else {
                  this.paymentTrigger(facility_id,borrower_id,expense_id,cost_id)
                }
              }
            })
            .catch((error) => {
              Utils.stop_loading();
              //this is when response is not received from server
              console.error("error: " + error);
              Utils.make_alert("warning", "Something went wrong!");
            });
    },

    getReportLoanTransaction(facility_id,borrower_id,expense_id,cost_id,loanIDs){
      console.log("getReportLoanTransaction")
      const path = "/report_loan_transaction/" + loanIDs + "/" + this.$store.state.client_id;
      Utils.start_loading();
      axios
      .post(path)
      .then((res) => {
        Utils.stop_loading();
        this.paymentTrigger(facility_id,borrower_id,expense_id,cost_id)
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    paymentTrigger(facility_id,borrower_id,expense_id,cost_id){
      // For triggering payment when we chnage status
      const path = "payment-trigger/" +
          facility_id +
          "/" +
          borrower_id +
          "/" +
          expense_id +
          "/" +
          cost_id;
          Utils.start_loading();
          axios
            .get(path, { params: { clientId: this.$store.state.client_id } })
            .then((res) => {
              console.log(res)
              Utils.stop_loading();
              // this.$router.go(this.$router.currentRoute);
              Utils.make_alert(
                "success",
                "Cost Status updated successfully!"
              );
            })
            .catch((error) => {
              Utils.stop_loading();
              //this is when response is not received from server
              console.error("error: " + error);
              Utils.make_alert("warning", "Something went wrong!");
            });
    },

    costMail(cost_id,user_id,status){
      // For triggering payment when we chnage status
      const path = "cost_change_status_mail/" +
          cost_id +
          "/" +
          user_id +
          "/" +
          status;
          Utils.start_loading();
          axios
            .get(path, { params: { clientId: this.$store.state.client_id } })
            .then((res) => {
              console.log(res)
              Utils.stop_loading();
              if (res.data.status == "success") {
                  let facility_id = res.data.facility_id
                  let borrower_id = res.data.borrower_id
                  let expense_id = res.data.expense_id
                  let cost_id = res.data.cost_id
                  let user_id = this.$store.getters.getuserId
                  let status = res.data.cost_status
                  console.log(facility_id)
                  console.log(borrower_id)
                  console.log(expense_id)
                  console.log(cost_id)
                  console.log(user_id)
                  console.log(status)
                if(res.data.updated_status == 'Approved'){
                  this.deductionAmountAPI(facility_id,borrower_id,expense_id,cost_id)
                  // this.paymentTrigger(facility_id,borrower_id,expense_id,cost_id)
                }
              }
            })
            .catch((error) => {
              Utils.stop_loading();
              //this is when response is not received from server
              console.error("error: " + error);
              Utils.make_alert("warning", "Something went wrong!");
            });
    },

    getFacility() {
      //this function will be called to get list of funds getFacility For the Facility tab
      const path = "facility";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.facilities = this.inActiveToggle
              ? res.data.facility_inactive
              : res.data.facility_active;
            if (this.selectedStatus !== "All") {
              var filteredFacilities = this.facilities.filter(
                (fac) => fac.status === this.selectedStatus
              );
              this.facilities = filteredFacilities;
            }
            setTimeout(
              () =>
                $("#myTable1").DataTable({
                  aaSorting: [],
                  columnDefs: this.inActiveToggle
                    ? []
                    : [
                        {
                          orderable: false,
                          targets:
                            this.loggedInUserGroupId === "Admin"
                            ? [5, 6]
                            :(this.loggedInUserGroupId == 'Analyst' && this.loggedInUserSubGroupId == 0)
                            ? [5]
                            : [],
                        },
                      ],
                }),
              100
            );
            this.borrower_id = this.facilities.borrower_name;
            this.registration_date = this.facilities.registration_date;
            console.log("get facilities is successful.");
          } else {
            Utils.make_alert("warning", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          Utils.make_alert("warning", "Something went wrong!");
        });
    },

    deleteFacility(facilityId) {
      //this function will be called to delete a specific fund. Parameter required - fundID: fundid which needs to be deleted
      this.$confirm({
        auth: false,
        message: "Confirm Delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete("facility-overview/" + facilityId, {
                data: {
                  deleteType: "facility",
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 4,
                  tabId: 14,
                },
                headers: {
                  "Content-type": "application/json",
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert("success", "Facility deleted successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(
                    () => this.$router.go(this.$router.currentRoute),
                    1500
                  );
                } else if (response.data.status == "deletion_not_allowed") {
                  Utils.make_alert(
                    "warning",
                    "Delete operation not permitted."
                  );
                } else {
                  Utils.make_alert(
                    "warning",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.log("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
                //this.$router.go(this.$router.currentRoute)
              });
          }
        },
      });
    }, //deleteFacility()  close

    deleteCost(costId) {
      //this function will be called to delete a specific fund. Parameter required - fundID: fundid which needs to be deleted
      this.$confirm({
        auth: false,
        message: "Confirm Delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete("cost-overview/" + costId, {
                data: {
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 4,
                  tabId: 34,
                },
                headers: {
                  "Content-type": "application/json",
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  this.filterData();
                  Utils.make_alert("success", "Cost deleted successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  // setTimeout(
                    // () => this.$router.go(this.$router.currentRoute),
                    // 1500
                  // );
                } else if (response.data.status == "deletion_not_allowed") {
                  Utils.make_alert(
                    "warning",
                    "Delete operation not permitted."
                  );
                } else {
                  Utils.make_alert(
                    "warning",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.log("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
                //this.$router.go(this.$router.currentRoute)
              });
          }
        },
      });
    }, //deleteCost()  close

    transformData(disbursements, facilityMappings, collateralMappings) {
        return disbursements.map(disb => {
            // Find matching facilities for each disbursement
            const facilities = facilityMappings
                .filter(fac => fac.facility_disbursement_transaction_id === disb.id)
                .map(fac => {
                    return {
                        id: fac.facility_id,
                        facility_registration_id: fac.facility_registration_id
                    };
                });
            const collaterals = collateralMappings
                .filter(col => col.facility_disbursement_transaction_id === disb.id)
                .map(col => {
                    return {
                        collateral_id: col.collateral_id,
                        collateral_name: col.collateral_name
                    };
                });
            

            // Construct the final object
            return {
                id: disb.id,
                fund_name: disb.fund_name,
                facilities: facilities,
                collaterals: collaterals,
                disbursement_on: disb.disbursement_on,
            };
        });
    },

    getAllFacility() {
      $("#myTable1").dataTable().fnDestroy();
      if (this.filter === "date") {
        this.selectedPeriod = null;
        if (this.startDate != null) {
          this.startDate = moment(this.startDate).format("MM-DD-YYYY");
        }
        if (this.endDate != null) {
          this.endDate = moment(this.endDate).format("MM-DD-YYYY");
        }
      }

      if (this.filter === "period") {
        this.startDate = null;
        this.endDate = null;
      }

      const path =
        "facility-details/" +
        this.startDate +
        "/" +
        this.endDate +
        "/" +
        this.selectedPeriod +
        "/" +
        this.selectedStatus;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.facilities = this.inActiveToggle
              ? res.data.facility_inactive
              : res.data.facility_active;
            if (this.selectedStatus !== "All") {
              var filteredFacilities = this.facilities.filter(
                (fac) => fac.status === this.selectedStatus
              );
              this.facilities = filteredFacilities;
            }
            setTimeout(
              () =>
                $("#myTable1").DataTable({
                  aaSorting: [],
                  columnDefs: this.inActiveToggle
                    ? []
                    : [
                        {
                          orderable: false,
                          targets:
                            this.loggedInUserGroupId === "Admin"
                            ? [5, 6]
                            : [],
                        },
                      ],
                }),
              100
            );
            this.selectedPeriod = res.data.period;
            this.selectedStatus = res.data.fac_status;
            //set the start and end date according to filter selected
            if (this.filter === "period") {
              this.startDatePeriod = res.data.start_date;
              this.endDatePeriod = res.data.end_date;
              this.startDate = null;
              this.endDate = null;
            } else if (this.filter === "date") {
              this.startDate = res.data.start_date;
              this.endDate = res.data.end_date;
              this.startDatePeriod = null;
              this.endDatePeriod = null;
            }
            console.log("get facility by filter is successful.");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
        });
    }, //getAllFacility() close

    resetFacility() {
      this.startDate = null;
      this.endDate = null;
      this.startDatePeriod = null;
      this.endDatePeriod = null;
      this.selectedPeriod = null;
      this.selectedStatus = "Open";
      this.filter = "date";
      this.getAllFacility();
    }, //resetFacility() close

    // getSummary is for conditionaly calling particular summary Data API
    getSummary(){
      console.log("inside get summary")
      console.log("localStorage.getItem('costsDisbursementSubTab')",localStorage.getItem("costsDisbursementSubTab"))
      if (
        localStorage.getItem("costsDisbursementSubTab") == "custom-tabs-one-costs-tab"
      ) {
          $("#costTable").dataTable().fnDestroy();
          $("#paymentsTable").dataTable().fnDestroy();
          $("#disbursementTable").dataTable().fnDestroy();
          console.log("destroy all table 1")
          this.getCostSummary();
      } else if (
        localStorage.getItem("costsDisbursementSubTab") == "custom-tabs-one-disbursement-tab"
      ) {
        $("#costTable").dataTable().fnDestroy();
        $("#paymentsTable").dataTable().fnDestroy();
        $("#disbursementTable").dataTable().fnDestroy();
        console.log("destroy all table 2")
        this.getDisbursementSummary();                                                                                                                                                                                                                        
      } else if (
        localStorage.getItem("costsDisbursementSubTab") == "custom-tabs-one-cost-payment-tab"
      ) {
        $("#costTable").dataTable().fnDestroy();
        $("#paymentsTable").dataTable().fnDestroy();
        $("#disbursementTable").dataTable().fnDestroy();
        console.log("destroy all table 3")
        this.getCostPaymentSummary();
      }
    }, // getSummary() close

    // FilterData is for filtering Cost&Disbursement tab
    filterData() {
      if(this.filter1 === 'date1'){
        console.log("Filter date")
        if (
          (this.startDate1 !== null && this.endDate1 === null) ||
          (this.startDate1 === null && this.endDate1 !== null)
        ) {
          Utils.make_alert(
            "warning",
            "Both From & To Dates are required to perform Date Filter"
          );
          return;
        } else{
        console.log("Filter")
        this.getSummary();
      }
      } else{
        console.log("Filter")
        this.getSummary();
      }
    }, // filterData() close

  // The below code is used to fetch cost summary data from the API
    getCostSummary() {
      console.log("inside cost summary")
      //this function will be called to get list of cost summary getCostSummary
      if (this.filter1 === "date1") {
        this.selectedPeriod1 = null;
        if (this.startDate1 != null) {
          this.startDate1 = moment(this.startDate1).format("MM-DD-YYYY");
        }
        if (this.endDate1 != null) {
          this.endDate1 = moment(this.endDate1).format("MM-DD-YYYY");
        }
      }

      if (this.filter1 === "period1") {
        this.startDate1 = null;
        this.endDate1 = null;
      }

      const path =
        "cost-summary/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility +
        "/" +
        this.startDate1 +
        "/" +
        this.endDate1 +
        "/" +
        this.selectedPeriod1 +
        "/" +
        this.selectedCurrency +
        "/" +
        this.selectedCost
      

      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.costs = res.data.cost_summary
            if(this.costs.length > 0) {
              this.costs.forEach(item => {
                if (item.expense_date != '-' && item.expense_date != null) {
                  item.expense_date = moment(item.expense_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.expense_date = '-';
                }
              });
            }

            setTimeout(
              () =>
                $("#costTable").DataTable({
                  scrollX: true,
                  aaSorting: [],
                  columnDefs:[
                  {
                          orderable: false,
                          targets:
                            this.loggedInUserGroupId == 'Admin' ||
                                    (this.loggedInUserGroupId == 'Analyst' &&
                                      this.loggedInUserSubGroupId == 0)
                              ? [12, 13]
                              : [],
                        },
                  ],
                }),
              100
            );

            //set the start and end date according to filter selected
            if (this.filter1 === "period1") {
              this.startDatePeriod1 = res.data.start_date;
              this.endDatePeriod1 = res.data.end_date;
              this.startDate1 = null;
              this.endDate1 = null;
            } else if (this.filter1 === "date1") {
              this.startDate1 = res.data.start_date;
              this.endDate1 = res.data.end_date;
              this.startDatePeriod1 = null;
              this.endDatePeriod1 = null;
            }
            console.log("get cost by filter is successful.");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          this.costs = [];
          setTimeout(
              () =>
                $("#costTable").DataTable({
                  scrollX: true,
                  aaSorting: [],
                  columnDefs:[
                  {
                          orderable: false,
                          targets:
                            this.loggedInUserGroupId === "Admin"
                              ? [12, 13]
                              : [],
                        },
                  ],
                }),
              100
            );
        });
    }, //getCostSummary() close

    // The below code is used to fetch cost summary data from the API
    getDisbursementSummary() {
      console.log("inside disbursement summary")
      //this function will be called to get list of cost summary getDisbursementSummary
      const path =
        "disbursement-summary/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility;
      
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            let disbursement_summary = res.data.disbursement_summary
            let facility_disbursement_mapping = res.data.facility_disbursement_mapping
            let collateral_disbursement_mapping = res.data.collateral_disbursement_mapping
            this.disbursements = this.transformData(disbursement_summary,facility_disbursement_mapping,collateral_disbursement_mapping);

            console.log("disbursements",this.disbursements)
            
            // Modify the disbursements array based on the facilities and collaterals length
            this.disbursements.forEach(disbursement => {
              if (disbursement.facilities.length > 1) {
                disbursement.facility_registration_id = 'Multiple Facilities';
                disbursement.facilityString = disbursement.facilities
                    .map(facility => facility.facility_registration_id)
                    .join(', ');
              } else if (disbursement.facilities.length === 1) {
                disbursement.facility_registration_id = disbursement.facilities[0].facility_registration_id;
                disbursement.facilityString = disbursement.facilities[0].facility_registration_id;
              } else if (disbursement.facilities.length === 0) {
                disbursement.facility_registration_id = null;
                disbursement.facilityString = null;
              }

              if (disbursement.collaterals.length > 1) {
                disbursement.collateral_name = 'Multiple Collaterals';
                disbursement.collateralString = disbursement.collaterals
                    .map(collateral => collateral.collateral_name)
                    .join(', ');
              } else if (disbursement.collaterals.length === 1) {
                disbursement.collateral_name = disbursement.collaterals[0].collateral_name;
                disbursement.collateralString = disbursement.collaterals[0].collateral_name;
              } else if (disbursement.collaterals.length === 0) {
                disbursement.collateral_name = null;
                disbursement.collateralString = null;
              }
            });
            console.log("final disbursements",this.disbursements)

            setTimeout(
              () =>
                $("#disbursementTable").DataTable({
                  // scrollX: true,
                  aaSorting: [],
                  columnDefs:[
                  {
                          orderable: false,
                          targets:
                            this.loggedInUserGroupId === "Admin"
                              ? []
                              : [],
                        },
                  ],
                }),
              100
            );

            //set the start and end date according to filter selected
            if (this.filter1 === "period1") {
              this.startDatePeriod1 = res.data.start_date;
              this.endDatePeriod1 = res.data.end_date;
              this.startDate1 = null;
              this.endDate1 = null;
            } else if (this.filter1 === "date1") {
              this.startDate1 = res.data.start_date;
              this.endDate1 = res.data.end_date;
              this.startDatePeriod1 = null;
              this.endDatePeriod1 = null;
            }
            console.log("get disbursement by filter is successful.");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          this.disbursements = [];
          setTimeout(
              () =>
                $("#disbursementTable").DataTable({
                  // scrollX: true,
                  aaSorting: [],
                  columnDefs:[
                  {
                          orderable: false,
                          targets:
                            this.loggedInUserGroupId === "Admin"
                              ? []
                              : [],
                        },
                  ],
                }),
              100
            );
          console.log("error: " + error);
          console.error("error: " + error);
        });
    }, //getDisbursementSummary() close

  // The below code is used to fetch cost payment summary data from the API
    getCostPaymentSummary() {
      console.log("inside cost payment summary")
      //this function will be called to get list of cost summary getCostPaymentSummary
      if (this.filter1 === "date1") {
        this.selectedPeriod1 = null;
        if (this.startDate1 != null) {
          this.startDate1 = moment(this.startDate1).format("MM-DD-YYYY");
        }
        if (this.endDate1 != null) {
          this.endDate1 = moment(this.endDate1).format("MM-DD-YYYY");
        }
      }

      if (this.filter1 === "period1") {
        this.startDate1 = null;
        this.endDate1 = null;
      }

      const path =
        "cost-payment-summary/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility +
        "/" +
        this.startDate1 +
        "/" +
        this.endDate1 +
        "/" +
        this.selectedPeriod1 +
        "/" +
        this.selectedCurrency +
        "/" +
        this.selectedCost


      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.costPayments = res.data.cost_payment_summary
            if(this.costPayments.length > 0) {
              this.costPayments.forEach(item => {
                if (item.payment_date != '-' && item.payment_date != null) {
                  item.payment_date = moment(item.payment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.payment_date = '-';
                }
              });
            }

            setTimeout(
              () =>
                $("#paymentsTable").DataTable({
                  aaSorting: [],
                  columnDefs:[
                  {orderable: false,},
                  ],
                }),
              100
            );
            //set the start and end date according to filter selected
            if (this.filter1 === "period1") {
              this.startDatePeriod1 = res.data.start_date;
              this.endDatePeriod1 = res.data.end_date;
              this.startDate1 = null;
              this.endDate1 = null;
            } else if (this.filter1 === "date1") {
              this.startDate1 = res.data.start_date;
              this.endDate1 = res.data.end_date;
              this.startDatePeriod1 = null;
              this.endDatePeriod1 = null;
            }
            console.log("get cost payment by filter is successful.");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          this.costPayments = [];
          setTimeout(
              () =>
                $("#paymentsTable").DataTable({
                  aaSorting: [],
                  columnDefs:[
                  {orderable: false,},
                  ],
                }),
              100
            );
        });
    }, //getCostPaymentSummary() close

    resetCostDisbursementPaymentSummary() {
      console.log("RESET")
      this.onBorrowerChange()
      this.selectedFacility = null;
      this.startDate1 = null;
      this.endDate1 = null;
      this.startDatePeriod1 = null;
      this.endDatePeriod1 = null;
      this.selectedPeriod1 = null;
      this.selectedCurrency = null;
      this.selectedCost = null;
      this.filter1 = "date1";
      this.getSummary();
    }, //resetCostDisbursementPaymentSummary() close

    setTab(tab, type) {
      //handling costs & disbursement subtabs operations
      if (type === "costsDisbursement") {
        if (localStorage.getItem("costsDisbursementSubTab") !== tab) {
          // localStorage.setItem("facilityTab", tab);
          localStorage.setItem("costsDisbursementSubTab", tab);
          this.costsDisbursementTabContent = tab;
          if (tab === "custom-tabs-one-costs-tab") {
            this.resetCostDisbursementPaymentSummary();
          } else if (tab === "custom-tabs-one-disbursement-tab") {
            this.resetCostDisbursementPaymentSummary();
          } else if (tab === "custom-tabs-one-cost-payment-tab") {
            this.resetCostDisbursementPaymentSummary();
          }
        }
      } else {
        if (tab === "costs-disbursement-tab") {
          localStorage.setItem("facilityTab", tab);
          var subTab = localStorage.getItem("costsDisbursementSubTab");
          if (
            subTab === "custom-tabs-one-costs-tab" ||
            subTab === "custom-tabs-one-disbursement-tab" ||
            subTab === "custom-tabs-one-cost-payment-tab"
          ) {
            this.costsDisbursementTabContent = subTab;
          } else {
            subTab = "custom-tabs-one-costs-tab"; // Set a default sub tab if none is stored in localStorage
            this.costsDisbursementTabContent = subTab;
          }
          localStorage.setItem("costsDisbursementSubTab", subTab);
          this.costsDisbursementTabs = true;
          if(this.selectedBorrower !== null){
            this.resetCostDisbursementPaymentSummary();
          }
        } else {
          if(tab === "facility-tab"){
            localStorage.setItem("facilityTab", tab);
          }
          this.costsDisbursementTabs = false;
        }
      }
    },

    getLoanExpenses() {
      //this function is called to get list of loan expenses
      const path = "loan-expense-master";
      axios
        .get(path)
        .then((res) => {
          this.loanExpenses = res.data.loan_expenses;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    onBorrowerChange() {
      if (this.selectedBorrower) {
        localStorage.setItem("costDisBorrowerFilter", this.selectedBorrower);
      }
    },

    getBorrowers() {
      console.log("get Borrowers being called")
      const path = "borrower-master";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.borrowers = res.data.borrowers;
          if (this.loggedInUserGroupId === "Borrower") {
            this.borrowers.filter((bor) => {
              if (bor.borrower_id.toString() === this.loggedInUserSubGroupId)
              this.selectedBorrower = bor.borrower_id;
            });
          }else{
            this.selectedBorrower = res.data.borrowers[0].borrower_id;
          }
          let borrowerFilter = localStorage.getItem("costDisBorrowerFilter");
          if (borrowerFilter === null || borrowerFilter === "null") {
            localStorage.setItem(
              "costDisBorrowerFilter",
              this.selectedBorrower
            );
          } else {
            this.selectedBorrower = borrowerFilter;
          }
          this.onBorrowerChange();
          this.getAllFacilitySetupByBorrower();
          this.getSummary();
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
        });
    },
  },
  mounted() {
    var activeTab = localStorage.getItem("facilityTab");
    var costsDisbursementSubTab = localStorage.getItem("costsDisbursementSubTab");
    console.log(activeTab);
    if (activeTab) {
      document.getElementById(activeTab).click();
    }
  },
  created() {
    axios.defaults.headers.common["Authorisation"] =
      "Bearer " + sessionStorage.getItem("token");
    this.inActiveToggle =
    this.$store.state.inactive === "facility_inactive" ? true : false;
    this.getFacility();
    this.getBorrowers();
    this.getCurrencies();
    this.getLoanExpenses();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style scoped>
th,
td {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}

.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
.wrapper {
  margin-bottom: -20px;
  min-height: 680px !important;
}
</style>
