<!-- This is add Loan page. -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-plus"></i> Add Cost
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Basic Details</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-12">
                    <span style="font-size: 12px; color: red">Note: The Date selected is back capped from the respective facility and cost selected date . </span>
                  </div>  
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedBorrower" class="required-field"
                        >Borrower Name</label
                      >
                      <select
                        id="selectedBorrower"
                        class="form-control"
                        v-model="selectedBorrower"
                        @change="getAllFacilitySetupByBorrower()"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedBorrower.$error,
                        }"
                        :disabled="loggedInUserGroupId == 'Borrower' || flow == 'Loan'"
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="borrower in borrowers"
                          :value="borrower.borrower_id"
                          :key="borrower.borrower_id"
                        >
                          {{ borrower.borrower_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedBorrower.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedBorrower.required"
                          >Borrower Name is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedFacility" class="required-field"
                        >Facility ID</label
                      >
                      <select
                        id="selectedFacility"
                        class="form-control"
                        v-model="selectedFacility"
                        @change="getFundCurrencyByFacility() ; getLoan(selectedFacility)"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedFacility.$error,
                        }"
                        :disabled="flow == 'Loan'"
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="fac in facilities"
                          :value="fac.id"
                          :key="fac.id"
                        >
                          {{ fac.facility_registration_id }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedFacility.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedFacility.required"
                          >Facility ID is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedCost" class="required-field"
                        >Cost Name</label
                      >
                      <select
                        id="selectedCost"
                        class="form-control"
                        v-model="selectedCost"
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedCost.$error,
                        }"
                      >
                        <option :value="null">-- Select a Cost --</option>
                        <option
                          v-for="cost in loanExpenses"
                          :value="cost.id"
                          :key="cost.id"
                        >
                          {{ cost.loan_expense_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedCost.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedCost.required"
                          >Cost Name is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="amount" class="required-field">Amount</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="amount"
                        :class="{
                          'is-invalid': isSubmitted && $v.amount.$error,
                        }"
                        id="amount"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                      />
                      <div
                        v-if="isSubmitted && $v.amount.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.amount.required"
                          >Amount is required<br
                        /></span>
                        <span v-if="!$v.amount.maxLength"
                          >Max length of 15 digits exceeded</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Currency</label>
                      <select
                        id="selectedCurrency"
                        class="form-control"
                        v-model="selectedCurrency"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedCurrency.$error,
                        }"
                      >
                        <option :value="null">-- Select a Currency --</option>
                        <option
                          v-for="curr in currencies"
                          :value="curr.id"
                          :key="curr.id"
                        >
                          {{ curr.currency_short_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedCurrency.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedCurrency.required"
                          >Currency is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="date" class="required-field">Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        placeholder="Select Date"
                        id="date"
                        v-model="date"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{ to: new Date(backCapDate), from: new Date() }"
                        :class="{
                          'is-invalid': isSubmitted && $v.date.$error,
                        }"
                      ></datepicker>
                      <div
                        v-if="isSubmitted && $v.date.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.date.required">Date is required</span>
                      </div>
                    </div>
                  </div>

                  <div v-if="this.currencyConversionFlag" class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="currencyConversionDate">Conversion Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        placeholder="Select Date"
                        @input="getFundCurrencyExchangeRateByCurrencyDate()"
                        id="currencyConversionDate"
                        v-model="currencyConversionDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{ from: new Date(futureCapDate) }"
                      ></datepicker>
                    </div>
                  </div>

                  <!-- <div v-if="fundCurrency !== selectedCurrency && fundCurrency !== null && fundCurrency !== null" class="col-md-2 col-sm-5 col-6"> -->
                  <div v-if="this.currencyConversionFlag" class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="fundCurrencyExchangeRate">Currency Exchange Rate</label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="fundCurrencyExchangeRate"
                        id="fundCurrencyExchangeRate"
                        @keypress="isDecimal($event)"
                        disabled
                      />
                    </div>
                  </div>

                  <!-- <div v-if="fundCurrency !== selectedCurrency && fundCurrency !== null && fundCurrency !== null" class="col-md-2 col-sm-5 col-6"> -->
                  <div v-if="this.currencyConversionFlag" class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="fundConvertedAmount">Converted Amount</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="fundConvertedAmount"
                        id="fundConvertedAmount"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                        disabled
                      />
                    </div>
                  </div>

                  <div v-if="this.currencyConversionFlag" class="col-md-8 col-sm-5 col-6">
                    <span style="font-size: 12px; color: red">Note: Amount has been converted into Fund Currency {{ getCurrencyShortNameById(this.fundCurrency)}}. </span>
                  </div>  
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Banking Details</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedBank">Beneficiary Bank</label>
                      <select
                        id="selectedBank"
                        class="form-control"
                        v-model="selectedBank"
                      >
                        <option :value="null">-- Select a Bank --</option>
                        <option
                          v-for="bank in banks"
                          :value="bank.id"
                          :key="bank.id"
                        >
                          {{ bank.bank_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="bic">Bank Identifier Code</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="bic"
                        id="bic"
                        :class="{
                          'is-invalid': isSubmitted && $v.bic.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.bic.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.bic.required"
                          >Bank Identifier Code cannot be 0</span
                        >
                        <span v-if="!$v.bic.minLength && $v.bic.required"
                          >Min length of 8 characters required<br
                        /></span>
                        <span v-if="!$v.bic.maxLength"
                          >Max length of 11 characters exceeded<br
                        /></span>
                        <span v-if="!$v.bic.alphaNum"
                          >Bank Identifier Code should be alphanumeric
                          only</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="iban">International Bank A/c No.</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="iban"
                        id="iban"
                        :class="{
                          'is-invalid': isSubmitted && $v.iban.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.iban.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.iban.required"
                          >IBAN cannot be 0</span
                        >
                        <span v-if="!$v.iban.maxLength"
                          >Max length of 35 characters exceeded<br
                        /></span>
                        <span v-if="!$v.iban.alphaNum"
                          >IBAN should be alphanumeric only</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="accountNumber">Account No.</label>
                      <input
                        type="text"
                        id="accountNumber"
                        class="form-control"
                        v-model="accountNumber"
                        :class="{
                          'is-invalid': isSubmitted && $v.accountNumber.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.accountNumber.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.accountNumber.required"
                          >Account No. cannot be 0</span
                        >
                        <span v-if="!$v.accountNumber.maxLength"
                          >Max length of 17 characters exceeded<br
                        /></span>
                        <span v-if="!$v.accountNumber.numeric"
                          >Account No. should be numeric only</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="accountName">Account Name </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="accountName"
                        id="accountName"
                        :class="{
                          'is-invalid': isSubmitted && $v.accountName.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.accountName.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.accountName.required"
                          >Account Name cannot be 0</span
                        >
                        <span v-if="!$v.accountName.maxLength"
                          >Max length of 100 characters exceeded<br
                        /></span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="reference">Reference </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="reference"
                        id="reference"
                        :class="{
                          'is-invalid': isSubmitted && $v.reference.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.reference.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.reference.required"
                          >Reference cannot be 0</span
                        >
                        <span v-if="!$v.reference.maxLength"
                          >Max length of 100 characters exceeded<br
                        /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documents</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addCostDoc()"
                              :disabled="CostDocumentsAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(doc, index) in CostDocuments"
                      :key="index"
                    >
                      <div class="col-md-4 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="customFile">Upload Document</label>
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="customFile"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              v-on:change="
                                handleFileUploadCostDocuments(index, $event)
                              "
                              :class="{
                                'is-invalid': doc.errors.filesize,
                              }"
                            />
                            <small>
                              <p style="background-color: powderblue">
                                <b>{{ doc.displayFileName }}</b>
                                <small>
                                  <u
                                    style="color: blue; cursor: pointer"
                                    v-if="
                                      doc.CostDocumentsRemoveDisabled === false
                                    "
                                    v-on:click="removeCostDocuments(index)"
                                    >Remove</u
                                  >
                                </small>
                              </p>
                            </small>
                            <div
                              v-if="doc.errors.filesize"
                              class="invalid-feedback"
                            >
                              <span>{{ doc.errors.filesize }}</span>
                            </div>
                            <label class="custom-file-label" for="customFile"
                              >Choose file</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="comments">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="doc.comments"
                          id="comments"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.CostDocuments.$each[index].comments
                                .$error,
                          }"
                          :disabled="doc.displayFileName == null"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.CostDocuments.$each[index].comments.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.CostDocuments.$each[index].comments
                                .maxLength
                            "
                            >Max length of 100 characters exceeded.</span
                          >
                        </div>
                      </div>
                    </div>

                      <div class="col-md-1 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            v-on:click="deleteCostDocuments(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onCostDocumentSaveInfo()"
                          :disabled="isDisabledCostDocumentSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid" v-if="loanMappingList.length">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <span style="font-size: 12px; color: red" v-if="loanMappingList.length"
                  >Note: The Selection for the below questions will be applicable to all the loans mapped above or in future</span
                >
                <div class="row">
                  <div class="col-12">
                    <div class="form-group fc">
                      <h1 class="headingt">
                        Do you want to consider the above cost for interest
                        calculations?
                        <label
                          id="interestCalculationLabelYes"
                          for="interestCalculationToggleYes"
                          >Yes</label
                        >
                        <input
                          type="radio"
                          :value="true"
                          v-model="interestCalculationToggle"
                          id="interestCalculationToggleYes"
                          class="toggle-padding"
                        />
                        <label
                          id="interestCalculationLabelNo"
                          for="interestCalculationToggleNo"
                          >No</label
                        >
                        <input
                          type="radio"
                          :value="false"
                          v-model="interestCalculationToggle"
                          id="interestCalculationToggleNo"
                          class="toggle-padding"
                        />
                      </h1>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group fc">
                      <h1 class="headingt">
                        Do you want to consider the above added cost to be paid
                        by the Offtaker repayments?
                        <label
                          id="offtakerRepaymentLabelYes"
                          for="offtakerRepaymentToggleYes"
                          >Yes</label
                        >
                        <input
                          type="radio"
                          :value="true"
                          v-model="offtakerRepaymentToggle"
                          id="offtakerRepaymentToggleYes"
                          class="toggle-padding"
                        />
                        <label
                          id="offtakerRepaymentLabelNo"
                          for="offtakerRepaymentToggleNo"
                          >No</label
                        >
                        <input
                          type="radio"
                          :value="false"
                          v-model="offtakerRepaymentToggle"
                          id="offtakerRepaymentToggleNo"
                          class="toggle-padding"
                        />
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Loan Mapping</h1>
                      </div>
                      <!-- /.col -->
                      <div v-if="flow != 'Loan'" class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addLoanMapping"
                              :disabled="loanMappingAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->
                    <span style="font-size: 12px; color: red" v-if="loanMappingList.length"
                        >Note: Before conversion amount needs to be used for Loan Mapping.</span
                      >
                    <div
                      class="row"
                      v-for="(lm, index) in loanMappingList"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="selectedLoan">Loan Reg. ID</label>
                          <select
                            id="selectedLoan"
                            class="form-control"
                            name="selectedLoan"
                            v-model="lm.selectedLoan"
                            @change="onLoanSelectGetDetails(index)"
                            :disabled="flow == 'Loan'"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="loan in loans"
                              :value="loan.id"
                              :key="loan.id"
                            >
                              {{ loan.loan_registration_id }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div v-if="offtakerRepaymentToggle == true" class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="loanCostDate">Cost Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            placeholder="Select Date"
                            id="loanCostDate"
                            v-model="lm.loanCostDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabled="!lm.selectedLoan" 
                            :disabledDates="{ to: new Date(lm.backCapDate), from: new Date() }"
                          ></datepicker>
                          <!-- <div
                            v-if="
                              isSubmitted &&
                              $v.loanMappingList.$each[index].loanCostDate
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanCostDate.required
                              "
                              >Cost Date is required</span
                            >
                          </div> -->
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="loanMappingToggleRatio">Ratio (%)</label>
                          <input
                            type="radio"
                            :value="true"
                            v-model="loanMappingToggle"
                            id="loanMappingToggleRatio"
                            class="toggle-padding"
                            @change="onLoanMappingToggle('ratio')"
                            v-if="index === 0"
                          />
                          <input
                            type="text"
                            class="form-control"
                            v-model="lm.loanMappingRatio"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.loanMappingList.$each[index].loanMappingRatio
                                  .$error,
                            }"
                            id="loanMappingRatio"
                            :disabled="!loanMappingToggle || !lm.selectedLoan"
                          />
                          <div
                            v-if="
                              isSubmitted &&
                              $v.loanMappingList.$each[index].loanMappingRatio
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanMappingRatio.required
                              "
                              >Ratio is required</span
                            >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanMappingRatio.maxLength
                              "
                              >Max value of 3 digits exceeded.</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="loanMappingToggle">Amount</label>
                          <input
                            type="radio"
                            :value="false"
                            v-model="loanMappingToggle"
                            id="loanMappingToggleAmount"
                            class="toggle-padding"
                            @change="onLoanMappingToggle('amount')"
                            v-if="index === 0"
                          />
                          <input
                            type="text"
                            class="form-control"
                            v-model="lm.loanMappingAmount"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.loanMappingList.$each[index]
                                  .loanMappingAmount.$error,
                            }"
                            id="loanMappingAmount"
                            :disabled="loanMappingToggle || !lm.selectedLoan"
                          />
                          <div
                            v-if="
                              isSubmitted &&
                              $v.loanMappingList.$each[index].loanMappingAmount
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanMappingAmount.required
                              "
                              >Amount is required</span
                            >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanMappingAmount.maxLength
                              "
                              >Max length of 15 digits exceeded.</span
                            >
                          </div>
                        </div>
                      </div>

                      <div v-if="flow != 'Loan'" class="col-md-1 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label
                            for="deleteLoanMapping"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            v-on:click="deleteLoanMapping(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="flow != 'Loan'" class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          name="saveInfo"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onLoanMappingSaveInfo()"
                          :disabled="isDisabledLoanMappingSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-success sh"
                      v-on:click="onSubmit()"
                    >
                      Submit
                    </button>
                  </li>
                  <li>
                    <a @click="$router.go(-1)">
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>
    <!-- ./wrapper -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {
  required,
  maxLength,
  minLength,
  numeric,
  alphaNum,
  decimal,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      borrowers: [],
      facilities: [],
      loanExpenses: [],
      currencies: [],
      banks: [],
      loans: [],
      // loans: [
      //     {
      //       "currency_id": 2, 
      //       "facility_setup_id": 790, 
      //       "id": 735, 
      //       "loan_registration_id": "LM001PB0", 
      //       "cost_date": "01-04-2024", 
      //       "transaction_level_outstanding_amount": "77,241.29"
      //     }, 
      //     {
      //       "currency_id": 1, 
      //       "facility_setup_id": 790, 
      //       "id": 736, 
      //       "loan_registration_id": "LM002PB0", 
      //       "cost_date": "06-15-2023", 
      //       "transaction_level_outstanding_amount": null,
      //     },
      //     {
      //       "currency_id": 1, 
      //       "facility_setup_id": 790, 
      //       "id": 737, 
      //       "loan_registration_id": "LM003PB0", 
      //       "cost_date": "06-15-2023", 
      //       "transaction_level_outstanding_amount": "2.00"
      //     }
      //   ],
      selectedBorrower: null,
      selectedFacility: null,
      selectedCost: null,
      amount: null,
      selectedCurrency: null,
      date: null,
      selectedBank: null,
      bic: null,
      iban: null,
      accountNumber: null,
      accountName: null,
      reference: null,
      backCapDate:new Date(moment(new Date()).subtract(10, "year")),

      // currency conversion related fields
      currencyConversionFlag:false,
      currencyConversionDate:new Date(),
      fundCurrency:null,
      fundCurrencyExchangeRate:1,
      fundConvertedAmount:null,
      futureCapDate:null,


      //list for Loan Documents
      CostDocuments: [],
      CostDocumentFile: null,
      // CostDocumentsButtonDisabled: true,
      CostDocumentsDisabled: true,
      CostDocumentSaveInfoDisabled: true, //save info button is disabled
      CostDocumentsAddMoreDisabled: true,

      //list for Loan Mapping
      loanMappingList: [],
      loanMappingSaveInfoDisabled: true, //save info button is disabled
      loanMappingAddMoreDisabled: false,
      loanMappingToggle: true,
      totalPerc: 0,
      totalLoanAmount: 0,

      flow:'Normal Cost',
      loanID:null,

      interestCalculationToggle: null,
      offtakerRepaymentToggle: null,
      isSubmitted: false,
    };
  },

  validations: {
    selectedBorrower: {
      required,
    },
    selectedFacility: {
      required,
    },
    selectedCost: {
      required,
    },
    amount: {
      required: function () {
        if (!this.amount || this.amount == 0) return false;
        else return true;
      },
      maxLength: maxLength(15),
    },
    selectedCurrency: {
      required,
    },
    date: {
      required,
    },
    bic: {
      alphaNum,
      required: function () {
        if (this.bic === "0") return false;
        else return true;
      },
      minLength: minLength(8),
      maxLength: maxLength(11),
    },
    iban: {
      required: function () {
        if (this.iban === "0") return false;
        else return true;
      },
      alphaNum,
      maxLength: maxLength(35),
    },
    accountNumber: {
      required: function () {
        if (this.accountNumber === "0") return false;
        else return true;
      },
      numeric,
      maxLength: maxLength(17),
    },
    accountName: {
      required: function () {
        if (this.accountName === "0") return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    reference: {
      required: function () {
        if (this.reference === "0") return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    CostDocuments: {
      $each: {
        comments: {
          maxLength: maxLength(100),
        },
      },
    },
    loanMappingList: {
      $each: {
        loanMappingRatio: {
          required: function (val) {
            if ((!val || val == 0) && this.loanMappingToggle) return false;
            else return true;
          },
          maxLength: function (val) {
            if (val ? val.length > 5 : false && !this.loanMappingToggle)
              return false;
            else return true;
          },
        },
        loanMappingAmount: {
          maxLength: function (val) {
            if (val ? val.length > 15 : false && !this.loanMappingToggle)
              return false;
            else return true;
          },
          required: function (val) {
            if ((!val || val == 0) && !this.loanMappingToggle) return false;
            else return true;
          },
        },
      },
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },

    //this is to computed disabled property of CostDocuments Save Info button.
    isDisabledCostDocumentSaveInfo: function () {
      let checkEmptyCostDocumentsDocName = this.CostDocuments.filter(
        (line) => line.displayFileName === null
      );
      //if the line is empty then disable save info button
      if (
        this.CostDocuments.length === 0 &&
        this.CostDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyCostDocumentsDocName.length >= 1 &&
        this.CostDocuments.length > 0
      ) {
        this.CostDocumentsDisabled = true;
      } else {
        this.CostDocumentsDisabled = false;
      }
      //CostDocumentsDisabled variable is to check whther all input fields except file upload are filled. CostDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.CostDocumentsDisabled === true ||
        this.CostDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledCostDocumentSaveInfo() close

    //this is to computed disabled property of Loan Mapping Save Info button.
    isDisabledLoanMappingSaveInfo: function () {
      let checkEmptyLoanMappingID = this.loanMappingList.filter(
        (line) => line.selectedLoan === null
      );
      let checkEmptyLoanMappingRatio = this.loanMappingList
        .slice(-1)
        .filter((line) => line.shareHoldingPattern === null);
      let checkEmptyLoanMappingAmount = this.loanMappingList
        .slice(-1)
        .filter((line) => line.shareHoldingValue === null);
      //if the line is empty then disable save info button
      if (
        this.loanMappingList.length === 0 &&
        this.loanMappingSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled. If condition is satisfied then only save info button will be enable
      if (
        (checkEmptyLoanMappingID.length >= 1 &&
          this.loanMappingList.length > 0) ||
        (checkEmptyLoanMappingRatio.length >= 1 &&
          this.loanMappingList.length > 0 &&
          checkEmptyLoanMappingAmount.length >= 1 &&
          this.loanMappingList.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledLoanMappingSaveInfo() close
  }, //main computed close

  components: {
    Datepicker,
  },
  methods: {
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },

    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },

    getCurrencyShortNameById(id) {
      if (id === null) {
        return '';
      }
      const currency = this.currencies.find((currency) => currency.id === id);
      return currency ? currency.currency_short_name : '';
    },

    //this method will make disabled property as false for Director Save Info
    onCostDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.CostDocumentSaveInfoDisabled = false;
      this.CostDocumentsAddMoreDisabled = false;
    },

    //this method will make disabled property as false for Loan Mapping Save Info
    onLoanMappingSaveInfo() {
      if(this.loanMappingToggle){
        this.calcTotalPerc();
        if (this.totalPerc > parseFloat(100.0)) {
          Utils.make_alert(
            "warning",
            "Total Loan Mapping Ratio should not be greater than 100%"
          );
        } else {
          Utils.make_alert("success", "Record saved.");
          this.loanMappingSaveInfoDisabled = false;
          this.loanMappingAddMoreDisabled = false;
        }
        this.totalPerc = 0;
      }
      if(!this.loanMappingToggle){
        this.calcTotalLoanAmount();
        if (this.totalLoanAmount > this.amount) {
          Utils.make_alert(
            "warning",
            "The sum of Loan Mapping Cost Amount cannot be greater than the Facility Cost Amount"
          );
        } else {
          Utils.make_alert("success", "Record saved.");
          this.loanMappingSaveInfoDisabled = false;
          this.loanMappingAddMoreDisabled = false;
        }
        this.totalLoanAmount = 0;
      }
    },

    calcTotalPerc() {
      for (let list in this.loanMappingList) {
        this.totalPerc += parseFloat(
          this.loanMappingList[list].loanMappingRatio
            ? this.loanMappingList[list].loanMappingRatio
            : 0
        );
      }
    },

    calcTotalLoanAmount() {
      for (let list in this.loanMappingList) {
        this.totalLoanAmount += parseFloat(
          this.loanMappingList[list].loanMappingAmount
            ? this.loanMappingList[list].loanMappingAmount
            : 0
        );
      }
    },

    // remove either loan mapping amount or ratio depending on selected radio button
    onLoanMappingToggle(type) {
      if (type === "ratio") {
        this.loanMappingList.map((lm) => (lm.loanMappingAmount = null));
      } else {
        this.loanMappingList.map((lm) => (lm.loanMappingRatio = null));
      }
    },

    // this method will check if existing loan mapping is added again
    getLoanBackcappingDate(index) {
      console.log("getLoanBackcappingDate")
      let loanID = this.loanMappingList[index].selectedLoan
      console.log("this.loanMappingList[index].selectedLoan",this.loanMappingList[index].selectedLoan)
      
      const path = "/get_loan_backcap_date/" + loanID;
      Utils.start_loading();
      axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
      .then((res) => {
        Utils.stop_loading();
          console.log(res.data.status)
          if (res.data.status == 'success'){
            this.loanMappingList[index].backCapDate = res.data.transaction_date;
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
      
    },

    // this method will check if existing loan mapping is added again
    onLoanSelectGetDetails(index) {
      console.log("this.loanID",this.loanID)
      console.log("this.offtakerRepaymentToggle",this.offtakerRepaymentToggle)
      console.log("this.interestCalculationToggle",this.interestCalculationToggle)
      if(this.flow == 'Normal Cost'){
        if ((this.loanMappingList.length > 0) && (this.interestCalculationToggle === null || this.offtakerRepaymentToggle === null)) {
          this.loanMappingList[index].selectedLoan = null;
          Utils.make_alert(
            "warning",
            "Kindly select the Yes or No option for both questions."
          );
          return;
        }
      }
      if(this.interestCalculationToggle === true && this.offtakerRepaymentToggle === false){
        Utils.make_alert(
            "warning",
            `The Interest calculation without including in offtaker repayment Feature/ function is currently under development ; will be available soon.<br/> Apologies for the Inconvenience caused.`
          );
        return
      }
      if(this.flow == 'Loan'){
        this.loanMappingList[index].selectedLoan = this.loanID;
      }
      console.log("this.loanMappingList[index].selectedLoan",this.loanMappingList[index].selectedLoan)
      let loan_count = this.loanMappingList.filter(
        (col) => col.selectedLoan === this.loanMappingList[index].selectedLoan
      ).length;
      // delete if existing loan is found
      if (loan_count > 1) {
        this.deleteLoanMapping(index);
        Utils.make_alert("warning", "Loan Mapping already exists");
      }
      this.getLoanBackcappingDate(index)
    },

    removeCostDocuments(id) {
      this.CostDocumentFile = null;
      this.CostDocuments[id].selectedFile = null;
      this.CostDocuments[id].selectedFileName = null;
      this.CostDocuments[id].displayFileName = null;
      this.CostDocuments[id].comments = null;
      this.CostDocuments[id].CostDocumentsRemoveDisabled = true;
    },

    deleteCostDocuments(index) {
      this.CostDocumentFile = this.CostDocumentFile
        ? this.CostDocumentFile
        : "file deleted";
      this.CostDocuments.splice(index, 1);
      if (
        typeof this.CostDocuments !== "undefined" &&
        this.CostDocuments.length == 0
      ) { 
        this.CostDocumentsAddMoreDisabled = false;
        this.CostDocumentSaveInfoDisabled = true;
      }
    },

    deleteLoanMapping(index) {
      this.loanMappingList.splice(index, 1);
      console.log(typeof this.loanMappingList)
      if (
        typeof this.loanMappingList !== "undefined" &&
        this.loanMappingList.length == 0
      ) {
        console.log("HelloWorld")
        this.offtakerRepaymentToggle= null;
        this.interestCalculationToggle= null;
        this.loanMappingAddMoreDisabled = false;
        this.loanMappingSaveInfoDisabled = true;
      }
    },

    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.totalPerc = 0;
        this.totalLoanAmount = 0;
        return;
      }
      this.calcTotalPerc();
      // check if total loan mapping ratio is not greater than 100
      if (this.totalPerc > parseFloat(100.0)) {
        Utils.make_alert(
          "warning",
          "Total Loan Mapping Ratio cannot be more than 100%"
        );
        this.totalPerc = 0;
        return;
      }
      
      // if (this.loanMappingToggle && this.loanMappingList.length === 1) {
      //   Utils.make_alert(
      //     "warning",
      //     "Kindly add more than one loan to Loan Mapping Card as Ratio cannot be calculated for a single loan."
      //   );
      //   this.totalPerc = 0;
      //   return;
      // }

      this.calcTotalLoanAmount();
      // check if total loan mapping amount is not greater than facility cost amount
      if (this.totalLoanAmount > this.amount) {
          Utils.make_alert(
            "warning",
            "The sum of Loan Mapping Cost Amount cannot be greater than the Facility Cost Amount"
          );
          this.totalLoanAmount = 0;
          return;
        }
        


      // if(this.loanMappingToggle){
      //   this.calcTotalPerc();
      //   if (this.totalPerc > parseFloat(100.0)) {
      //     Utils.make_alert(
      //       "warning",
      //       "Total Loan Mapping Ratio should not be greater than 100%"
      //     );
      //   }
      //   this.totalPerc = 0;
      //   return;
      // }
      // if(!this.loanMappingToggle){
      //   this.calcTotalLoanAmount();
      //   if (this.totalLoanAmount > this.amount) {
      //     Utils.make_alert(
      //       "warning",
      //       "The sum of Loan Mapping Cost Amount cannot be greater than the Facility Cost Amount"
      //     );
      //   }
      //   this.totalLoanAmount = 0;
      //   return;
      // }
      if ((this.loanMappingList.length > 0) && (this.interestCalculationToggle === null || this.offtakerRepaymentToggle === null)) {
        Utils.make_alert(
          "warning",
          "Kindly select the Yes or No option for both questions."
        );
        this.totalPerc = 0;
        this.totalLoanAmount = 0;
        return;
      }

      if(this.interestCalculationToggle === true && this.offtakerRepaymentToggle === false){
        Utils.make_alert(
            "warning",
            `The Interest calculation without including in offtaker repayment Feature/ function is currently under development ; will be available soon.<br/> Apologies for the Inconvenience caused.`
          );
        return
      }

      if(this.offtakerRepaymentToggle == false){
        this.loanMappingList.forEach((loan)=>{
          loan.loanCostDate = null;
        })
      }

      this.loanMappingList.forEach((loan)=>{
          if(loan.loanCostDate != null){
            loan.loanCostDate = moment(loan.loanCostDate).format("MM-DD-YYYY");
          }
        })

      console.log(this.loanMappingList)

      let ExpenseDate = null;
      let ConversionDate = null;
      if (this.date != null) {
        ExpenseDate = moment(this.date).format("MM-DD-YYYY");
      }
      if (this.currencyConversionDate != null) {
        ConversionDate = moment(this.currencyConversionDate).format("MM-DD-YYYY");
      }
      this.CostDocuments.map((doc, index) => {
        if (doc.displayFileName === null) this.CostDocuments.splice(index, 1);
      });


      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("selectedBorrower", this.selectedBorrower);
      formData.append("selectedFacility", this.selectedFacility);
      formData.append("selectedCost", this.selectedCost);
      formData.append("amount", this.amount);
      formData.append("selectedCurrency", this.selectedCurrency);
      formData.append("date", ExpenseDate);
      formData.append("currencyConversionDate", ConversionDate);
      formData.append("fundCurrencyExchangeRate", this.fundCurrencyExchangeRate);
      formData.append("fundConvertedAmount", this.fundConvertedAmount);
      formData.append("selectedBank", this.selectedBank);
      formData.append("bic", this.bic);
      formData.append("iban", this.iban);
      formData.append("accountNumber", this.accountNumber);
      formData.append("accountName", this.accountName);
      formData.append("reference", this.reference);
      formData.append("interestCalculationToggle",this.interestCalculationToggle);
      formData.append("offtakerRepaymentToggle", this.offtakerRepaymentToggle);
      //append CostDocuments information to formdata
      formData.append("CostDocuments", JSON.stringify(this.CostDocuments));
      //append loan mapping information to formdata
      formData.append("loanMappingList", JSON.stringify(this.loanMappingList));
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 4);
      formData.append("tabId", 34);
      const path = "add-cost"
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          if (response.data.status == "success") {
            Utils.make_alert(
              "success",
              "Costs Details saved successfully!"
            );
            console.log("SUCCESS!!");
            if(this.flow == "Normal Cost"){
              this.$router.push("/facility");
            } else {
              this.$router.push("/loan-util-overview-by-id/" + this.selectedBorrower + "/" + this.loanID);
            }
            // this.$router.push("/cost-overview/" + this.borrower_id);
          } else {
            Utils.make_alert("success", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
          Utils.make_alert("success", "Something went wrong!");
        });
    }, //onSubmit() close

    //this method will add new line for additional Cost Documents
    addCostDoc() {
      //when new line is added then CostDocumentFile variable and CostDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.CostDocumentFile = null),
        (this.CostDocumentSaveInfoDisabled = true),
        (this.CostDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.CostDocuments.push({
          displayFileName: null,
          comments: null,
          errors: { filesize: "" },
          CostDocumentsRemoveDisabled:true,

        });
    }, //addCostDoc() close

    //this method will add new line for additional loan mapping
    addLoanMapping() {
      //when new line is added then loanMappingSaveInfoDisabled (Save info button disable property) should be re initialised to true.
      (this.loanMappingSaveInfoDisabled = true),
        (this.loanMappingAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.loanMappingList.push({
          selectedLoan: null,
          loanCostDate: new Date(),
          loanMappingRatio: null,
          loanMappingAmount: null,
          backCapDate: new Date(),
        });
    }, //addLoanMapping() close

    //handles a change on the file upload
    handleFileUploadCostDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.CostDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.CostDocumentFile.size > 104857600) {
          e.preventDefault();
          self.CostDocuments[id].errors.filesize =
            "File size should be less than 100MB";
          self.CostDocuments[id].selectedFile = null;
          self.CostDocuments[id].selectedFileName = null;
          self.CostDocuments[id].displayFileName = null;
          self.CostDocuments[id].comments = null;
          self.CostDocuments[id].CostDocumentsRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.CostDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.CostDocuments[id].selectedFile = reader.result;
          self.CostDocuments[id].selectedFileName = e.target.files[0].name;
          self.CostDocuments[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.CostDocuments[id].errors.filesize = "";
          self.CostDocuments[id].CostDocumentsRemoveDisabled = false;

        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadCostDocuments() close

    getAllFacilitySetupByBorrower() {
      //this function is called to get list of day count
      const path = "/all-facility-setup-borrower/" + this.selectedBorrower;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // console.log(res.data.Facility_Setup);
          this.facilities = res.data.Facility_Setup;
          this.loanMappingList = [];
          this.offtakerRepaymentToggle = null;
          this.interestCalculationToggle = null;
          this.loanMappingAddMoreDisabled = false;
          this.currencyConversionFlag = false;
          this.date = null;
          if(this.flow == 'Normal Cost'){
            this.selectedFacility = null;
            this.fundCurrencyExchangeRate = 1;
            this.fundConvertedAmount = null;
            this.fundCurrency = null;
            this.selectedCurrency = null;
          }
          this.currencyConversionFlag = false;
          this.currencyConversionDate = new Date();
          this.backCapDate = new Date(moment(new Date()).subtract(10, "year"));
          if(this.flow == 'Loan'){
            this.addLoanMapping()
            this.getLoan(this.selectedFacility)
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFundCurrencyByFacility() {
      //this function is called to get fund currency of that facility
      console.log("inside getFundCurrencyByFacility")
      this.fundCurrencyExchangeRate = 1;
      this.currencyConversionDate = new Date();
      this.fundConvertedAmount = null;
      this.fundCurrency = null;
      this.selectedCurrency = null;
      this.currencyConversionFlag = false;
      const path = "/currency-id/" + this.selectedFacility;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          console.log(res);
          this.selectedCurrency = res.data.currency[0].fund_currency_id;
          this.fundCurrency =  res.data.currency[0].fund_currency_id;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBackcappingCostPaymentDate() {
      //this function is called to get BackcapingDate for expense Date
      console.log("inside getBackcappingCostPaymentDate")

      const path = "/cost-payment-date-backcapping/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility +
        "/" +
        this.selectedCost +
        "/" +
        1;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          console.log(res.data)
          let backDate = new Date(res.data.cost_date);
          this.backCapDate = backDate;
          if (this.date !== null) {
          let dateObj = new Date(this.date);
          if (dateObj < backDate) {
            this.date = null;
          }
        }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    getFundCurrencyExchangeRateByCurrencyDate() {
      //this function is called to get fund currency of that facility
      console.log("inside getFundCurrencyExchangeRateByCurrencyDate")
      let currencyDate = null;
      if (this.currencyConversionDate != null) {
        currencyDate = moment(this.currencyConversionDate).format("MM-DD-YYYY");
      }
      // if(this.selectedCurrency === null || this.fundCurrency === null || this.selectedFacility === null){
      //   Utils.make_alert("warning", "Please select Facility and Currency before selecting date");
      //   this.date = null;
      //   return;
      // }
      const path = "/currency-exchange-rate/" +
        this.fundCurrency +
        "/" +
        this.selectedCurrency +
        "/" +
        currencyDate
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log(res.data)
          let data = res.data.currency_rate[0]
          if(data != null){
            this.currencyConversionFlag = true
            let currency_conversion_date = data.date;
            let conversionDate = null
            if (this.currencyConversionDate != null) {
              conversionDate = moment(this.currencyConversionDate).format("MM-DD-YYYY");
            }
            // if(conversionDate != currency_conversion_date){
            //   let message = "For the chosen conversion date, there is no currency conversion rate available.<br/> Hence, showing the earliest date for conversion that is available.";
            // Utils.make_alert("warning", message);
            // }
            this.fundCurrencyExchangeRate =  data.currency_value;
            this.currencyConversionDate =  data.date;
            this.futureCapDate = data.futureCappingDate;
          }else{
            this.currencyConversionFlag = false
            this.selectedCurrency = this.fundCurrency;
            this.fundCurrencyExchangeRate = 1;
            this.currencyConversionDate = new Date();
            this.fundConvertedAmount = null;
            this.futureCapDate = null;
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    getFundCurrencyExchangeRateByDate() {
      //this function is called to get fund currency of that facility
      console.log("inside getFundCurrencyExchangeRateByDate")

      // let message = "The Currency conversion rate for the selected Cost Currency and Facility Currency is not available.<br/><br/>So, the system auto selected the Cost Currency as the Facility Currency to proceed.";

      // Utils.make_alert("warning", message);
      let costDate = null;
      if (this.date != null) {
        costDate = moment(this.date).format("MM-DD-YYYY");
      }
      // if(this.selectedCurrency === null || this.fundCurrency === null || this.selectedFacility === null){
      //   Utils.make_alert("warning", "Please select Facility and Currency before selecting date");
      //   this.date = null;
      //   return;
      // }
      const path = "/currency-exchange-rate/" +
        this.fundCurrency +
        "/" +
        this.selectedCurrency +
        "/" +
        costDate
        Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log(res.data)
          let data = res.data.currency_rate[0]
          if(data == null){
            this.currencyConversionFlag = true
            let message = "The Currency conversion rate for the selected Cost Currency and Facility Currency is not available.<br/>So, the system auto selected the Cost Currency as the Facility Currency to proceed.";
            Utils.make_alert("warning", message);
            this.selectedCurrency = this.fundCurrency;
            this.fundCurrencyExchangeRate = 1;
            this.currencyConversionDate = new Date();
            this.fundConvertedAmount = null;
            }else{
              this.currencyConversionFlag = true
              this.fundCurrencyExchangeRate =  data.currency_value;
              this.currencyConversionDate =  data.date;
              this.futureCapDate = data.futureCappingDate;
            }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    getLoan(id) {
      //this function is called to get list of loans
      const path = "/get-loan-by-facility/" + id;
      Utils.start_loading()
      axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
      .then((res) => {
        Utils.stop_loading()
        this.loans = res.data.loans;
          this.loanMappingAddMoreDisabled = false;
          if(this.flow == 'Normal Cost'){
            this.loanMappingList = [];
            this.offtakerRepaymentToggle = null;
            this.interestCalculationToggle = null;
          }
          if(this.flow == 'Loan'){
            this.offtakerRepaymentToggle = true;
            this.onLoanSelectGetDetails(0)
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBorrowers() {
      const path = "borrower-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.borrowers = res.data.borrowers;
          if (this.loggedInUserGroupId === "Borrower") {
            this.borrowers.filter((bor) => {
              if (bor.borrower_id.toString() === this.loggedInUserSubGroupId)
                this.selectedBorrower = bor.borrower_id;
                this.getAllFacilitySetupByBorrower();
              });
            }
          if(this.flow == 'Loan'){
            this.getAllFacilitySetupByBorrower();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLoanExpenses() {
      //this function is called to get list of loan expenses
      const path = "loan-expense-master";
      axios
        .get(path)
        .then((res) => {
          this.loanExpenses = res.data.loan_expenses;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBanks() {
      //this function is called to get list of banks
      const path = "bank-detail";
      axios
        .get(path)
        .then((res) => {
          console.log(res)
          console.log(res.data.bank_details)
          this.banks = res.data.bank_details;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close
  watch: {
  amount: {
    handler(newAmount) {
      if (this.fundCurrency !== this.selectedCurrency  && this.fundCurrency !== null) {
        this.fundConvertedAmount = (Number(newAmount) * Number(this.fundCurrencyExchangeRate)).toFixed(2);
      }
    },
    immediate: true,
  },
  fundCurrencyExchangeRate: {
    handler(newExchangeRate) {
      if (this.fundCurrency !== this.selectedCurrency  && this.fundCurrency !== null) {
        this.fundConvertedAmount = (Number(newExchangeRate) * Number(this.amount)).toFixed(2);
      }
    },
    immediate: true,
  },
  selectedBorrower: {
    handler(newExchangeRate) {
      if (this.selectedFacility !== null  &&  this.selectedCost !== null &&  this.selectedBorrower !== null) {
        this.getBackcappingCostPaymentDate();
      }
    },
    immediate: true,
  },
  selectedCost: {
    handler(newExchangeRate) {
      if (this.selectedFacility !== null  &&  this.selectedCost !== null &&  this.selectedBorrower !== null) {
        this.getBackcappingCostPaymentDate();
      }
    },
    immediate: true,
  },
  selectedFacility: {
    handler(newExchangeRate) {
      if (this.fundCurrency !== this.selectedCurrency && this.selectedFacility !== null  &&  this.fundCurrency !== null &&  this.selectedCurrency !== null && this.date !== null) {
        this.getFundCurrencyExchangeRateByDate();
      }
      if (this.selectedFacility !== null  &&  this.selectedCost !== null &&  this.selectedBorrower !== null) {
        this.getBackcappingCostPaymentDate();
      }
    },
    immediate: true,
  },
  selectedCurrency: {
    handler(newExchangeRate) {
      if (this.fundCurrency !== this.selectedCurrency && this.selectedFacility !== null  &&  this.fundCurrency !== null &&  this.selectedCurrency !== null && this.date !== null) {
        this.getFundCurrencyExchangeRateByDate();
      }
      else{
        this.fundCurrencyExchangeRate = 1;
        this.currencyConversionDate = new Date();
        this.fundConvertedAmount = null;
        this.currencyConversionFlag = false;
      }
    },
    immediate: true,
  },
  date: {
    handler(newExchangeRate) {
      if (this.fundCurrency !== this.selectedCurrency && this.selectedFacility !== null  &&  this.fundCurrency !== null &&  this.selectedCurrency !== null && this.date !== null) {
        this.getFundCurrencyExchangeRateByDate();
      }
    },
    immediate: true,
  },
  },

  mounted() {
    this.addCostDoc();
  },

  created() {
    let data = this.$route.params;
    let data1 = {
        "flow": "Loan",
        "selectedBorrower": "932",
        "selectedFacility": 785,
        "fundCurrency": 2,
        "loanID": "730"
    }

    if (Object.keys(data).length === 0) {
        // If data is an empty object
        console.log('Data is empty');
    } else {
        // If data is not empty
        console.log("from Loan to Cost", data);

        // Assign properties after checking if they exist in 'data'
        this.flow = 'flow' in data ? data.flow : null;
        this.loanID = 'loanID' in data ? data.loanID : null;
        this.selectedBorrower = 'selectedBorrower' in data ? data.selectedBorrower : null;
        this.selectedFacility = 'selectedFacility' in data ? data.selectedFacility : null;
        this.selectedCurrency = 'fundCurrency' in data ? data.fundCurrency : null;
        this.fundCurrency = 'fundCurrency' in data ? data.fundCurrency : null;
      }
    this.getCurrencies();
    this.getBorrowers();
    this.getLoanExpenses();
    this.getBanks();
  },
};
</script>

<style scoped>
.error {
  color: red;
  font-size: 13px;
  padding-left: 40px;
}
.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -75px;
}

.vdp-datepicker >>> .form-control[disabled] {
  background-color:#EEEEEE !important;
  opacity: 0.7;
  pointer-events: none !important;
  cursor: not-allowed !important;
}
.toggle-padding {
  margin-left: 5px;
}
.headingt #interestCalculationLabelYes {
  margin-left: 250px;
}
.headingt #interestCalculationLabelNo {
  margin-left: 20px;
}
.headingt #offtakerRepaymentLabelYes {
  margin-left: 95px;
}
.headingt #offtakerRepaymentLabelNo {
  margin-left: 20px;
}
.wrapper {
  height: auto; 
  min-height: 700px !important;
}
</style>
