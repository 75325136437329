<template>
  <div class="container-fluid h-100">
    <div class="row justify-content-center h-100">
      <div class="col-lg-5 col-md-5" id="side_view">
        <div
          class="blue_portion"
          style="background-image: url('/asset/images/sidemenu_bg.png')"
        >
          <div class="tx">
            <h3>LUNDY INVESTORS</h3>
            <h4>Tailored Solution for Wealth Creation</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-7" id="grey">
        <div class="login_mid-Portion">
          <center>
            <img
              src="/asset/images/AdminLTELogo.png"
              class="img-fluid login_logo"
            />
          </center>

          <form @submit.prevent="handleSubmit()">
            <div v-if="message" class="alert alert-success" role="alert">
              {{ message }}
            </div>
            <div v-if="error" class="alert alert-danger" role="alert">
              {{ error }}
              <!-- <error v-if="error" :error="error"/> -->
            </div>
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                v-model="email"
                id="email"
                name="email"
                :class="{ 'is-invalid': isSubmitted && $v.email.$error }"
                placeholder="Enter Email Address.."
              />
              <div
                v-if="isSubmitted && !$v.email.error"
                class="invalid-feedback"
              >
                <span v-if="!$v.email.email">Please provide valid email</span>
                <span v-if="!$v.email.required">Email field is required</span>
              </div>
            </div>

            <!-- <router-link
              to="/login"
              type="button"
              class="btn btn-block btn-primary btn-lg sh lg_btn"
            >Send</router-link>-->
            <a
              type="button"
              class="btn btn-block btn-primary btn-lg sh lg_btn"
              style="color: white"
              v-on:keyup.enter="handleSubmit"
              @click="handleSubmit"
              >Send</a
            >
          </form>
        </div>
        <p class="bt_text">
          © 2022, Design & Development by
          <a href="https://www.decimalpointanalytics.com/"
            >Decimal Point Analytics Pvt Ltd.</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
import Error from "./Error.vue";
// import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function

export default {
  name: "ForgotPassword",
  components: {
    Error,
  },
  data() {
    return {
      email: "",
      message: "",
      error: "",
      isSubmitted: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    handleSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const response = axios
        .post("forgot_password", {
          email: this.email,
        })
        .then((response) => {
          // Utils.make_alert("success", 'The Email Request Was sent');
          console.log(this.email);
          // this.$router.push({ name: "login" });
          console.log(response);
          this.message = "Email has been sent";
          this.error = "";
        })
        .catch((error) => {
          // Utils.make_alert("success", "Incorrect Email!");
          this.error = "Invalid Email";
          this.message = "";
          //this.$router.push({ name: "ForgotPassword" });
        });
    },
  },
};
</script>

<style scoped>
.login_img {
  background-color: #d2d2d2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60%;
}

.login_mid-Portion .forgot_pass a {
  color: #949494 !important;
  text-decoration: none;
}

.login_mid-Portion .forgot_pass {
  font-size: 15px !important;
  font-weight: 600;
  text-align: center;
  margin-top: 6%;
  letter-spacing: 4px;
}

.bt_text a {
  color: #949494 !important;
  text-decoration: none;
}

.bt_text {
  font-size: 13px !important;
  font-weight: 600;
  text-align: center;
}

.blue_portion {
  background-color: #173751;
  height: 100%;
  padding-top: 10%;
  padding-left: 7%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.blue_portion .tx {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.blue_portion .tx h3 {
  color: rgba(255, 255, 255, 0.2);
  font-size: 5rem;
  font-weight: bold;
  line-height: 4.5rem;
}

.blue_portion .tx h4 {
  font-size: 20px;
  color: #fff;
}

.blue_portion .dpa_logo {
  width: 190px;
}

.headingt {
  color: #183750;
  font-size: 18px;
  padding-top: 6px;
  margin: 0px;
}

#side_view {
  height: 100%;
  padding: 0px;
}
</style>
