<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6 col-sm-6">
                      <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="custom-tabs-one-home-tab"
                            data-toggle="tab"
                            href="#custom-tabs-one-home"
                            role="tab"
                            aria-controls="custom-tabs-one-home"
                            aria-selected="true"
                          >Fund Overview</a>
                        </li>
                        <li
                          class="nav-item"
                          v-if="loggedInUserGroupId == 'Admin' || loggedInUserGroupId == 'Analyst' && loggedInUserSubGroupId == 0"
                        >
                          <a
                            class="nav-link"
                            id="custom-tabs-one-profile-tab"
                            data-toggle="tab"
                            href="#custom-tabs-one-profile"
                            role="tab"
                            aria-controls="custom-tabs-one-profile"
                            aria-selected="false"
                          >Expenses</a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6 col-sm-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor:pointer;">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <div class="tab-content" id="custom-tabs-one-tabContent">
          <div
            class="tab-pane fade show active"
            id="custom-tabs-one-home"
            role="tabpanel"
            aria-labelledby="custom-tabs-one-home-tab"
          >
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-3 col-6 mb-2">
                  <div class="card card-body h-100 c_1 sh">
                    <h4>Fund Name</h4>
                    <h3>{{fund.fund_name}}</h3>
                  </div>
                </div>
                <div class="col-md-3 col-6 mb-2">
                  <div class="card card-body h-100 c_2 sh">
                    <h4>FUND INCEPTION DATE</h4>
                    <h3>{{fund.fund_inception_date}}</h3>
                  </div>
                </div>
                <div class="col-md-3 col-6 mb-2">
                  <div class="card card-body h-100 c_3 sh">
                    <h4>COUNTRY</h4>
                    <h3>{{fund.country_name}}</h3>
                  </div>
                </div>
                <div class="col-md-3 col-6 mb-2">
                  <div class="card card-body h-100 c_4 sh">
                    <h4>CURRENCY</h4>
                    <h3>{{fund.currency_short_name}}</h3>
                  </div>
                </div>
              </div>
              
            </div>
            <div
              class="container-fluid ot_tb pt-3"
              v-if="loggedInUserGroupId == 'Admin' || loggedInUserGroupId == 'Analyst' && loggedInUserSubGroupId == 0"
            >
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt">
                            <i class="icofont-handshake-deal"></i> Fund Fees
                          </h1>
                        </div>
                        <!-- /.col -->
                      </div>
                    </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="card-body br_overview">
                            <table class="table table-hover table-head-fixed text-nowrap">
                              <tbody>
                                <tr>
                                  <td class="wg">Performance Fees %:</td>
                                  <td v-if="fund.performance_fees">
                                    {{ fund.performance_fees + '%'}} 
                                  </td>
                                  <td v-else>-</td>
                                </tr>

                                <tr>
                                  <td class="wg">Performance Fees Period:</td>
                                  <td v-if="fund.performance_fees_period">
                                    {{ fund.performance_fees_period }}
                                  </td>
                                  <td v-else>-</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card-body br_overview">
                            <table class="table table-hover">
                              <tbody>
                                <tr>
                                  <td class="wg">Hurdle Rate % :</td>
                                  <td v-if="fund.hurdle_rate">
                                    {{ fund.hurdle_rate + '%'}} 
                                  </td>
                                  <td v-else>-</td>
                                </tr>

                                <tr>
                                  <td class="wg">Management Fees % :</td>
                                  <td v-if="fund.management_fees">
                                    {{ fund.management_fees + '%'}} 
                                  </td>
                                  <td v-else>-</td>
                                </tr>

                                <!-- <tr>
                                  <td class="wg">Management Fees Period:</td>
                                  <td v-if="fund.management_fees_period">
                                    {{ fund.management_fees_period }}
                                  </td>
                                  <td v-else>-</td>
                                </tr> -->
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
            <div
              class="container-fluid ot_tb pt-3"
              v-if="loggedInUserGroupId == 'Admin' || loggedInUserGroupId == 'Analyst' && loggedInUserSubGroupId == 0"
            >
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt">
                            <i class="icofont-handshake-deal"></i> Borrowers Details
                          </h1>
                        </div>
                        <!-- /.col -->
                      </div>
                    </div>
                    <div class="card-body table-responsive p-0">
                      <!------menton here style="height: 300px;"-------->
                      <table class="table table-head-fixed text-nowrap" style="width:100%">
                        <thead>
                          <tr>
                            <th>Borrowers Name</th>
                            <th>Util. Req. Id</th>
                            <th>Loan Amount</th>
                            <th>Currency</th>
                            <th class="c">Tenure</th>
                            <th>Interest Rate(%)</th>
                            <th class="c">Start Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="fund in fund.borrower_list" :key="fund.borrower_id">
                            <template v-if="fund.status === 'Open'">
                              <td v-if="fund.borrower_name !== null ">{{fund.borrower_name}}</td>
                              <td v-else>-</td>
                              <td v-if="fund.loan_registration_id !== null ">{{fund.loan_registration_id}}</td>
                              <td v-else>-</td>
                              <td
                                v-if="fund.loan_converted_amount !== null "
                              >{{ fund.loan_converted_amount | numeral('0,0.00') }}</td>
                              <td v-else>-</td>
                              <td v-if="fund.currency_short_name !== null ">{{fund.currency_short_name}}</td>
                              <td v-else>-</td>
                              <td v-if="fund.loan_tenure !== null " class="c">{{fund.loan_tenure}} {{ fund.period }}</td>
                              <td v-else>-</td>
                              <td
                              
                                v-if="fund.loan_interest_type_master_id == 1 || fund.loan_interest_type_master_id == 2"
                              >{{ fund.interest_rate }}</td>
                              <td
                                v-else-if="fund.loan_interest_type_master_id == 3 || fund.loan_interest_type_master_id == 4"
                              >{{ fund.interest_spread }}+{{ fund.benchmark_name }}</td>
                              <td v-else>-</td>
                              <!-- <td
                              v-if="fund.interest_spread!=null"
                            
                              >{{fund.interest_spread | numeral('0,0.00') }}</td>-->
                              <td v-if="fund.borrower_name !== null " class="c">{{fund.loan_registration_date}}</td>
                              <td v-else>-</td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
            <div
              class="container-fluid ot_tb pt-3"
              v-if="loggedInUserGroupId == 'Admin' || loggedInUserGroupId == 'Analyst' && loggedInUserSubGroupId == 0"
            >
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt">
                            <i class="icofont-coins"></i> Investors Details
                          </h1>
                        </div>
                        <!-- /.col -->
                      </div>
                    </div>
                    <div class="card-body table-responsive p-0">
                      <!------menton here style="height: 300px;"-------->
                      <table class="table table-head-fixed text-nowrap" style="width:100%">
                        <thead>
                          <tr>
                            <th>Investor Name</th>
                            <th>Investment Category</th>
                            <th>Issue Date</th>
                            <th class="c">Maturity Date</th>
                            <th>Principal</th>
                            <th class="c">Currency</th>
                            <th>Interest Rate(%)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="fund in fund.investor_list" :key="fund.investor_id">
                            <template v-if="fund.status === 'Open'">
                              <td v-if="fund.investor_name !== null ">{{fund.investor_name}}</td>
                              <td v-else>-</td>
                              <td v-if="fund.investment_category_name !== null " class="c">{{fund.investment_category_name}}</td>
                              <td v-else>-</td>
                              <td v-if="fund.investment_date !== null " class="c">{{fund.investment_date}}</td>
                              <td v-else>-</td>
                              <td v-if="fund.maturity_date !== null " class="c">{{fund.maturity_date}}</td>
                              <td v-else>-</td>
                              <td
                                v-if="fund.principal_amount !== null "
                              
                              >{{ fund.principal_amount | numeral('0,0.00') }}</td>
                              <td v-else>-</td>
                              <td v-if="fund.currency_short_name !== null " class="c">{{fund.currency_short_name}}</td>
                              <td
                              
                                v-if="fund.interest_type_id === 1"
                              >{{ fund.coupon }}</td>
                              <td
                              
                                v-else
                              >{{ fund.interest_spread }}+{{ fund.benchmark_name }}</td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
          </div>
          <!-- /.Tabpane first end-->
          <!-- expense type logic start here-->
          <div
            class="tab-pane fade"
            id="custom-tabs-one-profile"
            role="tabpanel"
            aria-labelledby="custom-tabs-one-profile-tab"
          >
            <div class="tab-content" id="custom-tabs-one-tabContent">
              <div class="container-fluid tabtopa" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-12">
                      <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                        <li class="nav-item ot_i">
                          <a
                            class="ot_t nav-link"
                            :class="{ 'active': activeTab === 'total_payments' }"
                            id="custom-tabs-one-home-tab"
                            data-toggle="pill"
                            href="#total_payments"
                            role="tab"
                            aria-controls="custom-tabs-one-home"
                            aria-selected="true"
                            @click.prevent="setActiveTab('total_payments')"
                          >Total Payments</a>
                        </li>
                        <li class="nav-item ot_i">
                          <a
                            class="ot_t nav-link"
                            :class="{ 'active': activeTab === 'total_expenses' }"
                            id="custom-tabs-one-profile-tab"
                            data-toggle="pill"
                            href="#total_expenses"
                            role="tab"
                            aria-controls="custom-tabs-one-profile"
                            aria-selected="false"
                            @click.prevent="setActiveTab('total_expenses')"
                          >Total Expenses</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'total_payments' }"
                id="total_payments"
                role="tabpanel"
                aria-labelledby="kyc1"
              >
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group fc">
                        <label>Period</label>
                        <select
                          id="selectedPaymentPeriod"
                          class="form-control"
                          name="selectedPaymentPeriod"
                          v-model="selectedPaymentPeriod"
                          required
                        >
                          <option value="ytd">YTD</option>
                          <option value="mtd">MTD</option>
                          <option value="last_month">Last Month</option>
                          <option value="qtd">QTD</option>
                          <option value="last_quarter">Last Quarter</option>
                          <option value="last_2_quarters">Last 2 Quarters</option>
                          <option value="last_fy_1">Last 1 FY Year</option>
                          <option value="last_fy_2">Last 2 FY Years</option>
                          <option value="all">All</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-12">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn-sm btn btn-primary sh_n mt"
                            v-on:click="onFilterExpensePayment()"
                          >Filter</button>
                        </li>
                        <li>
                          <button
                            type="button"
                            class="btn-sm btn btn-dark sh_n mt"
                            v-on:click="resetPayment()"
                          >Reset</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="container-fluid ot_tb pt-3">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="container-fluid" id="al_tb">
                        <div class="tp_mid">
                          <div class="row">
                            <div class="col-lg-6">
                              <h1 class="headingt">
                                Total:
                                <span
                                  v-if="totalPaymentAmountSum !== null && totalExpenseAmountSum !== undefined"
                                >{{totalPaymentAmountSum | numeral('0,0.00')}} {{this.selectedPaymentPeriod!=='all'?'('+totalPaymentStartDate + ' to ' + totalPaymentEndDate+')':null}}</span>
                              </h1>
                            </div>
                            <!-- /.col -->
                            <div class="col-lg-6">
                              <ul id="sr_btn">
                                <li v-if="!inActive && loggedInUserGroupId == 'Admin' ||
                                  (loggedInUserGroupId == 'Analyst' &&
                                    loggedInUserSubGroupId == 0)">
                                  <button
                                    type="button"
                                    class="btn btn-sm sh_n btn-success"
                                    v-on:click="showAddModal('payment')"
                                  >
                                    <i class="icofont-plus"></i>
                                    <span class="mob">Add Payment</span>
                                  </button>
                                </li>
                                <!--<li>
                                                   <div class="form-group has-search m-0">
                                                      <span class="fa fa-search form-control-feedback"></span>
                                                      <input type="text" class="form-control" placeholder="Search" v-model="filter">
                                                   </div>
                                </li>-->
                              </ul>
                            </div>
                            <!-- /.col -->
                          </div>
                        </div>
                        <div class="card-body table-responsive p-0">
                          <!------menton here style="height: 300px;"-------->
                          <table
                            class="table table-head-fixed text-nowrap table-hover myTable1"
                            id="myTable1"
                          >
                            <thead>
                              <tr>
                                <th onclick="sortTable('myTable1',  0)">
                                  Expense Name
                                  <i class="icofont-sort sortdd"></i>
                                </th>
                                <th onclick="sortTable('myTable1', 2)">
                                  Currency
                                  <i class="icofont-sort sortdd"></i>
                                </th>
                                <th class="right" onclick="sortTable('myTable1', 1)">
                                  Amount
                                  <i class="icofont-sort sortdd"></i>
                                </th>
                              </tr>
                            </thead>
                            <div v-if="fundExpensePayment.length === 0">No items to display</div>
                            <tbody>
                              <tr v-for="(fundexp, index) in fundExpensePayment" :key="index">
                                <!--to avoid duplicate key error use index format -->
                                <td class="ln">
                                  <router-link
                                    :to="{name:'FundExpense',params:{type:'payment',fund_id:id,expense_id:fundexp.expense_id,inactive:inActive}}"
                                  >{{fundexp.expense_name}}</router-link>
                                </td>
                                <!-- ex_master_id is expense_master id as we need to pass expense_id in expense_transaction table to filter records for that particular expense_id -->
                                <td v-if="fundexp.currency_short_name !== null ">{{fundexp.currency_short_name}}</td>
                                <td v-else>-</td>
                                <td class="right" v-if="fundexp.total_amount !== null ">{{fundexp.total_amount | numeral('0,0.00')}}</td>
                                <td v-else>-</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- /.card -->
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'total_expenses' }"
                id="total_expenses"
                role="tabpanel"
                aria-labelledby="covenant1"
              >
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group fc">
                        <label>Period</label>
                        <select
                          id="selectedPeriod"
                          class="form-control"
                          name="selectedPeriod"
                          v-model="selectedPeriod"
                          required
                        >
                          <option value="ytd">YTD</option>
                          <option value="mtd">MTD</option>
                          <option value="last_month">Last Month</option>
                          <option value="qtd">QTD</option>
                          <option value="last_quarter">Last Quarter</option>
                          <option value="last_2_quarters">Last 2 Quarters</option>
                          <option value="last_fy_1">Last 1 FY Year</option>
                          <option value="last_fy_2">Last 2 FY Years</option>
                          <option value="all">All</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-12">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn-sm btn btn-primary sh_n mt"
                            v-on:click="getExpenseByFundId()"
                          >Filter</button>
                        </li>
                        <li>
                          <button
                            type="button"
                            class="btn-sm btn btn-dark sh_n mt"
                            v-on:click="resetExpense()"
                          >Reset</button>
                        </li>
                      </ul>
                    </div>
                    <div class="container-fluid ot_tb pt-3">
                      <div class="card card-primary card-tabs">
                        <div class="card-body">
                          <div class="container-fluid" id="al_tb">
                            <div class="tp_mid">
                              <div class="row">
                                <div class="col-lg-6">
                                  <h1 class="headingt">
                                    Total:
                                    <span
                                      v-if="totalExpenseAmountSum !== null && totalExpenseAmountSum !== undefined"
                                    >{{totalExpenseAmountSum | numeral('0,0.00')}} {{this.selectedPeriod!=='all'?'('+totalExpenseFinStartDate + ' to ' + totalExpenseFinEndDate+')':null}}</span>
                                  </h1>
                                </div>
                                <!-- /.col -->
                                <div class="col-lg-6">
                                  <ul id="sr_btn">
                                    <li v-if="!inActive">
                                      <button
                                        type="button"
                                        class="btn btn-sm sh_n btn-success"
                                        v-on:click="showAddModal('expense')"
                                        v-if="loggedInUserGroupId === 'Analyst' || loggedInUserGroupId === 'Admin'"
                                      >
                                        <i class="icofont-plus"></i>
                                        <span class="mob">Add Expense</span>
                                      </button>
                                    </li>
                                    <!--<li>
                                                         <div class="form-group has-search m-0">
                                                            <span class="fa fa-search form-control-feedback"></span>
                                                            <input type="text" class="form-control" placeholder="Search">
                                                         </div>
                                    </li>-->
                                  </ul>
                                </div>
                                <!-- /.col -->
                              </div>
                            </div>
                            <div class="card-body table-responsive p-0">
                              <!------menton here style="height: 300px;"-------->
                              <table
                                class="table table-head-fixed text-nowrap table-hover myTable2"
                                id="myTable2"
                              >
                                <thead>
                                  <tr>
                                    <th onclick="sortTable('myTable2',  0)">
                                      Expense Name
                                      <i class="icofont-sort sortdd"></i>
                                    </th>
                                    <th class="c right" onclick="sortTable('myTable2',  1)">
                                      Amount
                                      <i class="icofont-sort sortdd"></i>
                                    </th>
                                    <th class="c right" onclick="sortTable('myTable2',  2)">
                                      Paid Amount
                                      <i class="icofont-sort sortdd"></i>
                                    </th>
                                    <th class="c right" onclick="sortTable('myTable2',  3)">
                                      Outstanding Amount
                                      <i class="icofont-sort sortdd"></i>
                                    </th>
                                    <!--<th class="c right" onclick="sortTable('myTable2',  0)">Overdue Amount<i class="icofont-sort sortdd"></i></th>-->
                                    <th class="c right" onclick="sortTable('myTable2',  4)">
                                      Prepaid Amount
                                      <i class="icofont-sort sortdd"></i>
                                    </th>
                                    <th class="c right" onclick="sortTable('myTable2',  5)">
                                      Opening Balance
                                      <i class="icofont-sort sortdd"></i>
                                    </th>
                                    <th class="c right" onclick="sortTable('myTable2',  6)">
                                      Closing Balance
                                      <i class="icofont-sort sortdd"></i>
                                    </th>
                                    <!--<th class="c right" onclick="sortTable('myTable2',  0)">Overdue Amount<i class="icofont-sort sortdd"></i></th>-->
                                    <th onclick="sortTable('myTable2',  7)">
                                      Currency
                                      <i class="icofont-sort sortdd"></i>
                                    </th>
                                  </tr>
                                </thead>
                                <div v-if="!fundExpenses.length">No items to display</div>
                                <tbody>
                                  <tr v-for="(fundamexp, index) in fundExpenses" :key="index">
                                    <!--to avoid duplicate key error use index format -->
                                    <td class="ln">
                                      <router-link
                                        :to="{name:'FundExpense',params:{type:'expense',fund_id:id,expense_id:fundamexp.expense_id, inception_date: fund_inception_date, inactive:inActive}}"
                                      >{{fundamexp.expense_name}}</router-link>
                                    </td>
                                    <td class="c right" v-if="fundamexp.total_amount !== null ">{{fundamexp.total_amount | numeral('0,0.00')}}</td>
                                    <td class="c right" v-else>-</td>
                                    <td class="c right" v-if="fundamexp.paid_amount !== null ">{{fundamexp.paid_amount | numeral('0,0.00')}}</td>
                                    <td class="c right" v-else>-</td>
                                    <td class="c right" v-if="fundamexp.outstanding_amount !== null ">{{fundamexp.outstanding_amount | numeral('0,0.00')}}</td>
                                    <td class="c right" v-else>-</td>
                                    <!--<td >{{fundamexp.overdue_amount}}</td>-->
                                    <td class="c right" v-if="fundamexp.prepaid_amount !== null ">{{fundamexp.prepaid_amount.toString().startsWith('-')?fundamexp.prepaid_amount.toString().slice(1):fundamexp.prepaid_amount | numeral('0,0.00')}}</td>
                                    <td class="c right" v-else>-</td>
                                    <td class="c right" v-if="fundamexp.opening_balance !== null ">{{fundamexp.opening_balance | numeral('0,0.00')}}</td>
                                    <td class="c right" v-else>-</td>
                                    <td class="c right" v-if="fundamexp.closing_balance !== null ">{{fundamexp.closing_balance | numeral('0,0.00')}}</td>
                                    <td class="c right" v-else>-</td>
                                    <td>{{fundamexp.currency_short_name?fundamexp.currency_short_name:fund.currency_short_name}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <!-- /.card -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.Tabpane second end-->
      </div>
      <!-- /.content-->
    </div>
    <!-- /.content-wrapper -->
    <!-- ./modal start -->
    <div class="modal fade" id="add_payment">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Add {{type}}</h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <p v-if="errors.length">
              <b>Please correct the following field(s):</b>
              <ul>
                  <li v-for="error in errors" :key="error.id">{{ error }}</li>
              </ul>
            </p>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group fc">
                  <label class="required-field">Expense Type</label>
                  <select
                    id="selectedExpense"
                    class="form-control"
                    name="selectedExpense"
                    v-model="selectedExpense"
                    :disabled="this.index !== undefined"
                    required
                  >
                    <option :value="null">-- Select an Expense --</option>
                    <option
                      v-for="expense in type==='expense'?expenses:paymentExpenses"
                      :value="expense.id" :key="expense.id"
                    >{{ expense.expense_name }}</option>
                  </select>
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-6" v-show="type === 'expense'">
                <div class="form-group fc">
                  <label class="required-field">Payment Type</label>
                  <select
                    id="selectedExpenseType"
                    class="form-control"
                    name="selectedExpenseType"
                    v-model="selectedExpenseType"
                    :disabled="this.index !== undefined"
                    required
                  >
                    <option :value="null">-- Select a Payment Type --</option>
                    <option
                      v-for="exptype in expenseTypes"
                      :value="exptype.expense_type_name" :key="exptype.id"
                    >{{ exptype.expense_type_name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field">Amount</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Type Amount"
                    v-model="amount"
                    :disabled="this.index !== undefined && this.ptype !== 'fund_onetime'"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-6" v-show="type === 'payment'">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">Description</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Type Description"
                    v-model="description"
                    :class="{
                      'is-invalid':
                        isSubmitted && $v.description.$error,
                    }"
                  />
                  <div
                    v-if="isSubmitted && $v.description.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.description.maxLength"
                      >Max length of 50 characters exceeded</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label class="required-field">Currency</label>
                  <select
                    id="selectedCurrency"
                    class="form-control"
                    name="selectedCurrency"
                    v-model="selectedCurrency"
                    required
                  >
                    <option :value="null">-- Select a Currency --</option>
                    <option
                      v-for="curr in currencies"
                      :value="curr.id" :key="curr.id"
                    >{{ curr.currency_short_name }}</option>
                  </select>
                </div>
              </div>
              <div
                class="col-md-6 col-sm-6 col-6"
                v-show="type === 'payment' || this.selectedExpenseType === 'Onetime'"
              >
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field">Payment Date</label>
                  <datepicker
                    class="datepop"
                    :bootstrap-styling="true"
                    name="endDate"
                    placeholder="Select Date"
                    v-model="paymentDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :disabled="this.index !== undefined"
                    required
                  ></datepicker>
                </div>
              </div>
              <div
                class="col-md-6 col-sm-6 col-6"
                v-show="type === 'expense' && this.selectedExpenseType === 'Amortized'"
              >
                <div class="form-group fc">
                  <label class="required-field">Amortization Schedule</label>
                  <select
                    id="selectedInterestFrequency"
                    class="form-control"
                    name="selectedInterestFrequency"
                    v-model="selectedInterestFrequency"
                    required
                  >
                    <option :value="null">-- Select a Schedule --</option>
                    <option
                      v-for="intfreq in interestFrequencies"
                      :value="intfreq.id" :key="intfreq.id"
                    >{{ intfreq.interest_frequency_name }}</option>
                  </select>
                </div>
              </div>
              <div
                class="col-md-6 col-sm-6 col-6"
                v-show="type === 'expense' && this.selectedExpenseType === 'Amortized'"
              >
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field">No. of Instalments</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Type Installments"
                    v-model="installments"
                    required
                  />
                </div>
              </div>
              <div
                class="col-md-6 col-sm-6 col-6"
                v-show="type === 'expense' && this.selectedExpenseType === 'Amortized'"
              >
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field">Start Date</label>
                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="startDate"
                    placeholder="Select Date"
                    v-model="startDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    required
                  ></datepicker>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onExpenseSubmit()"
                  >Submit</button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    data-dismiss="modal"
                    @click="$router.go(-1)"
                  >Cancel</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
    <!-- ./modal start -->
    <div class="modal fade" id="add_expenses" data-backdrop="static">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Add {{type}}</h4>
            <button type="button" class="close" v-on:click="onCloseExpensePaymentModal()">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group fc">
                  <label class="required-field">Expense Type</label>
                  <select
                    id="selectedExpense"
                    class="form-control"
                    name="selectedExpense"
                    v-model="selectedExpense"
                    @change="backcapStartDate(selectedExpense,type)"
                    :disabled="this.index !== undefined"
                    :class="{
                      'is-invalid': isSubmitted && $v.selectedExpense.$error,
                    }"
                  >
                    <option :value="null">-- Select an Expense --</option>
                    <option
                      v-for="expense in type==='expense'?expenses:paymentExpenses"
                      :value="expense.id"
                      :key="expense.id"
                    >{{ expense.expense_name }}</option>
                  </select>
                  <div
                    v-if="isSubmitted && $v.selectedExpense.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.selectedExpense.required"
                      >Expense Type is required</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-6" v-show="type === 'expense'">
                <div class="form-group fc">
                  <label class="required-field">Payment Type</label>
                  <select
                    id="selectedExpenseType"
                    class="form-control"
                    name="selectedExpenseType"
                    v-model="selectedExpenseType"
                    @change="getCurrencyExchangeRateDateForFundExpensePayment()"
                    :disabled="this.index !== undefined"
                    :class="{
                      'is-invalid': isSubmitted && $v.selectedExpenseType.$error,
                    }"
                  >
                    <option :value="null">-- Select a Payment Type --</option>
                    <option
                      v-for="exptype in expenseTypes"
                      :value="exptype.expense_type_name" :key="exptype.id"
                    >{{ exptype.expense_type_name }}</option>
                  </select>
                  <div
                    v-if="isSubmitted && $v.selectedExpenseType.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.selectedExpenseType.required"
                      >Payment Type is required</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field">Amount</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Type Amount"
                    v-model="amount"
                    @keypress="isDecimal($event)"
                    @keyup="calculateFundConvertedAmount()"
                    :disabled="this.index !== undefined && this.ptype !== 'fund_onetime'"
                    :class="{
                      'is-invalid':
                        isSubmitted && $v.amount.$error,
                    }"
                  />
                  <div
                    v-if="isSubmitted && $v.amount.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.amount.required"
                      >Amount is required</span
                    >
                    <span v-if="!$v.amount.maxLength"
                      >Max length of 15 characters exceeded</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-6" v-show="type === 'payment'">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">Description</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Type Description"
                    v-model="description"
                    @keypress="isAlphaNum($event)"
                    :class="{
                      'is-invalid':
                        isSubmitted && $v.description.$error,
                    }"
                  />
                  <div
                    v-if="isSubmitted && $v.description.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.description.maxLength"
                      >Max length of 50 characters exceeded</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label class="required-field">Currency</label>
                  <select
                    id="selectedCurrency"
                    class="form-control"
                    name="selectedCurrency"
                    v-model="selectedCurrency"
                    @change="getCurrencyExchangeRateDateForFundExpensePayment()"
                    :disabled="this.index !== undefined"
                    :class="{
                      'is-invalid': isSubmitted && $v.selectedCurrency.$error,
                    }"
                  >
                    <option :value="null">-- Select a Currency --</option>
                    <option
                      v-for="curr in currencies"
                      :value="curr.id" :key="curr.id"
                    >{{ curr.currency_short_name }}</option>
                  </select>
                  <div
                    v-if="isSubmitted && $v.selectedCurrency.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.selectedCurrency.required"
                      >Currency is required</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 col-sm-6 col-6"
                v-show="type === 'payment' || this.selectedExpenseType === 'Onetime'"
              >
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field">Payment Date</label>
                  <datepicker
                    class="datepop"
                    :bootstrap-styling="true"
                    name="endDate"
                    placeholder="Select Date"
                    v-model="paymentDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    @input="getCurrencyExchangeRateDateForFundExpensePayment()"
                    :disabledDates="{to: expenseBackCapDate?new Date(expenseBackCapDate):fund_inception_date, from: type==='payment'?new Date():null}"
                    :disabled="this.index !== undefined"
                    :class="{
                      'is-invalid': isSubmitted && $v.paymentDate.$error,
                    }"
                  ></datepicker>
                  <div
                    v-if="isSubmitted && $v.paymentDate.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.paymentDate.required"
                      >Payment Date is required</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 col-sm-6 col-6"
                v-show="type === 'expense' && this.selectedExpenseType === 'Amortized'"
              >
                <div class="form-group fc">
                  <label class="required-field">Amortization Schedule</label>
                  <select
                    id="selectedInterestFrequency"
                    class="form-control"
                    name="selectedInterestFrequency"
                    v-model="selectedInterestFrequency"
                    required
                    :class="{
                      'is-invalid': isSubmitted && $v.selectedInterestFrequency.$error,
                    }"
                  >
                    <option :value="null">-- Select a Schedule --</option>
                    <option
                      v-for="intfreq in interestFrequencies.slice(1,5)"
                      :value="intfreq.id" :key="intfreq.id"
                    >{{ intfreq.interest_frequency_name }}</option>
                  </select>
                  <div
                    v-if="isSubmitted && $v.selectedInterestFrequency.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.selectedInterestFrequency.required"
                      >Amortization Schedule is required</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 col-sm-6 col-6"
                v-show="type === 'expense' && this.selectedExpenseType === 'Amortized'"
              >
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field">No. of Instalments</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Type Installments"
                    v-model="installments"
                    :class="{
                      'is-invalid':
                        isSubmitted && $v.installments.$error,
                    }"
                  />
                  <div
                    v-if="isSubmitted && $v.installments.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.installments.required"
                      >No. of Instalments are required</span
                    >
                    <span v-if="!$v.installments.maxLength"
                      >Max length of 15 characters exceeded</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 col-sm-6 col-6"
                v-show="type === 'expense' && this.selectedExpenseType === 'Amortized'"
              >
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field">Start Date</label>
                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="startDate"
                    placeholder="Select Date"
                    v-model="startDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    @input="getCurrencyExchangeRateDateForFundExpensePayment()"
                    :disabledDates="{to: new Date(expenseBackCapDate)}"
                    required
                    :class="{
                      'is-invalid':
                        isSubmitted && $v.startDate.$error,
                    }"
                  ></datepicker>
                  <div
                    v-if="isSubmitted && $v.startDate.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.startDate.required"
                      >Start Date is required</span
                    >
                  </div>
                </div>
              </div>

              <div v-if="fundConverionFlag" class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">Conversion Date</label>
                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="fundConversionDate"
                    placeholder="Select Date"
                    v-model="fundConversionDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    disabled
                  ></datepicker>
                </div>
              </div>

              <div v-if="fundConverionFlag" class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">Conversion Rate</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="fundConversionRate"
                    disabled
                  />
                </div>
              </div>
              
              <div v-if="fundConverionFlag" class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">Converted Amount</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="fundConvertedAmount"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <ul id="sr_btn">
                <li >
                  <u @click="goToAddExpensePayment()" class="additionalLink">Additional Details</u>
                </li>
              </ul>
            </div>
          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onExpenseSubmit()"
                  >Submit</button>
                </li>
                <li>
                  <button type="button" class="btn-sm btn btn-dark sh" v-on:click="onCloseExpensePaymentModal()">Cancel</button>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- ./modal end -->
  </div>
  <!-- ./wrapper -->
</template>
<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {required,maxLength,alphaNum,minLength,numeric} from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      activeTab: 'total_payments', // default active tab
      id: null,
      inActive: false,
      index: null,
      ptype: null,
      fund: [],
      expenses: [],
      paymentExpenses: [],
      selectedExpense: null,
      currencies: [],
      selectedCurrency: null,
      expenseTypes: [],
      selectedExpenseType: null,
      interestFrequencies: [],
      selectedInterestFrequency: null,
      installments: null,
      startDate: null,
      startDate1: null,
      endDate: null,
      endDate1: null,
      expenseBackCapDate: null,
      paymentDate: new Date(),
      paymentDate1: null,
      amount: null,
      description: null,
      errors: [],
      fundExpensePayment: [],
      totalPaymentAmountSum: "",
      fundExpenses: [],
      fundExpensesDates:[],
      paymentExpensesDates:[],
      totalExpenseAmountSum: "",
      //filter expense payments
      totalPaymentStartDate: "",
      totalPaymentEndDate: "",
      totalExpenseFinStartDate: "",
      totalExpenseFinEndDate: "",
      //filter amortized expenses
      fromDateFundExpense: null,
      toDateFundExpense: null,
      //search filter
      filter: null,
      type: null,
      selectedPeriod: "qtd", //selecting default as 1 year for fund expense filter
      selectedPaymentPeriod: "qtd",
      borrower_id: null,
      fund_inception_date: null,

      fundCurrency: null,
      fundConversionRate:1,
      fundConvertedAmount:null,
      fundConversionDate:new Date(),
      fundConverionFlag:false,
      nameOfEntity:null,
      InvoiceNumber:null,
      Comments:null,
      // bank
      selectedBank: null,
      bic: null,
      iban: null,
      accountNumber: null,
      accountName: null,
      reference: null,
      transactionID: null,
      // docs
      ExpensePaymentDocuments: [],

      isSubmitted: false,
    };
  },
  components: {
    Datepicker,
  },
  validations: {
    selectedExpense:{
      required
    },
    selectedExpenseType:{
      required: function(){
        if(this.type === "expense" && !this.selectedExpenseType)
          return false
        else
          return true
      }
    },
    amount: {
      required: function(){
        if(this.amount === null || this.amount == 0)
          return false
        else
          return true
      },
      maxLength: maxLength(15),
    },
    selectedCurrency: {
      required
    },
    selectedInterestFrequency:{
      required: function(){
        if(this.type ==='expense' && this.selectedExpenseType === 'Amortized' && !this.selectedInterestFrequency)
          return false
        else
          return true
      }
    },
    installments:{
      maxLength: maxLength(15),
      required: function(){
        if(this.type ==='expense' && this.selectedExpenseType === 'Amortized' && !this.installments)
          return false
        else
          return true
      }
    },
    startDate:{
      required: function(){
        if(this.type ==='expense' && this.selectedExpenseType === 'Amortized' && !this.startDate)
          return false
        else
          return true
      }
    },
    paymentDate: {
      required: function(){
        if((this.type ==='payment' || this.selectedExpenseType === "Onetime") && !this.paymentDate)
          return false
        else
          return true
      }
    },
    description: {
      maxLength: function(){
        if(this.type === 'payment' && this.description ? this.description.length > 50 : false)
          return false
        else 
          return true
      }
    },
    bic: {
      alphaNum,
      required: function () {
        if (this.bic === "0") return false;
        else return true;
      },
      minLength: minLength(8),
      maxLength: maxLength(11),
    },
    iban: {
      required: function () {
        if (this.iban === "0") return false;
        else return true;
      },
      alphaNum,
      maxLength: maxLength(35),
    },
    accountNumber: {
      required: function () {
        if (this.accountNumber === "0") return false;
        else return true;
      },
      numeric,
      maxLength: maxLength(17),
    },
    accountName: {
      required: function () {
        if (this.accountName === "0") return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    reference: {
      required: function () {
        if (this.reference === "0") return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    transactionID: {
      numeric,
      required: function () {
        if (this.transactionID === "0") return false;
        else return true;
      },
      maxLength: maxLength(25),
    },
    InvoiceNumber: {
      alphaNum,
      maxLength: maxLength(100),
    },
    nameOfEntity: {
      alphaNum,
      maxLength: maxLength(100),
    },
    Comments: {
      maxLength: maxLength(100),
    },
    ExpensePaymentDocuments: {
      $each: {
        comments: {
          maxLength: maxLength(100),
        },
      },
    },

  },
  methods: {
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },
    isAlpha(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isAlphaNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    //to open add payment modal from action redirection
    showActionModal(type) {
      this.type = type;
      $("#add_payment").modal("show");
    }, //showAddModal() close
    //to open add expense modal
    showAddModal(type) {
      this.type = type;
      $("#add_expenses").modal("show");
    }, //showAddModal() close

    goToAddExpensePayment(){
      $("#add_expenses").modal("hide");
      console.log("fund_inception_date",this.fund_inception_date)
      this.$router.push({
          name: 'AddExpensePayment',
          params: 
            { 
              fundID: this.id,
              type: this.type,
              id: 0,
              ptype: this.ptype,
              index: this.index,
              selectedExpense: this.selectedExpense,
              selectedExpenseType: this.selectedExpenseType,
              amount: this.amount,
              description: this.description,
              selectedCurrency: this.selectedCurrency,
              fundCurrency: this.fundCurrency,
              expenseBackCapDate: this.expenseBackCapDate, 
              selectedInterestFrequency: this.selectedInterestFrequency, 
              fund_inception_date: this.fund_inception_date,
              paymentDate: this.paymentDate,
              installments: this.installments, 
              startDate: this.startDate ,
              fundConversionRate: this.fundConversionRate,                        
              fundConvertedAmount: this.fundConvertedAmount, 
              fundConversionDate: this.fundConversionDate ,
              fundConverionFlag: this.fundConverionFlag ,
            },
      });
    },

    backcapStartDate(expense_id,type) {
      console.log("backcapStartDate")
      console.log("expense_id",expense_id)
      console.log("type",type)
      console.log("fundExpensesDates",this.fundExpensesDates)
      console.log("paymentExpensesDates",this.paymentExpensesDates)
      if(expense_id == null || type == null){
        return;
      }
      if(type==="expense"){  
        if(this.fundExpensesDates){
          let date = this.fundExpensesDates.filter(exp=> exp.expense_id === expense_id);
          if(date.length){
            date = moment(date[0].date).add(1,"days").format("MM-DD-YYYY")
            console.log("expense date",date)
            this.expenseBackCapDate = date;
            this.paymentDate = date;
            this.startDate = date;
            this.fundConversionDate = date;
          } else {
            console.log("else expense fund_inception_date date",this.fund_inception_date)
            date = this.fund_inception_date;
            this.expenseBackCapDate = date;
            this.paymentDate = date;
            this.startDate = date;
            this.fundConversionDate = date;
          }
        }
      }
      else if(type==="payment"){
        if(this.paymentExpensesDates){
          let date = this.paymentExpensesDates.filter(exp=> exp.expense_id === expense_id);
          if(date.length){
            console.log("payment date",date)
            date = moment(date[0].payment_date).add(1,"days").format("MM-DD-YYYY")
            this.expenseBackCapDate = date;
            this.paymentDate = date;
            this.fundConversionDate = date;
          } else {
            console.log("else payment fund_inception_date date",this.fund_inception_date)
            date = this.fund_inception_date;
            this.expenseBackCapDate = date;
            this.paymentDate = date;
            this.startDate = date;
            this.fundConversionDate = date;
          }
        }
      }
      console.log("expenseBackCapDate",this.expenseBackCapDate)
      // let expense_name = this.expenses.find((exp) => exp.id === expense_id);
      // expense_name = expense_name.expense_name;
      // console.log("type", expense_id, expense_type);
      // if (expense_type === "Amortized") {
      //   this.fundExpenses.filter((exp) => {
      //     if (exp.expense_name === expense_name) {
      //       const path =
      //         "fund-selected-expense-transaction/" +
      //         this.id +
      //         "/" +
      //         exp.expense_id +
      //         "/" +
      //         "expense";
      //       axios
      //         .get(path)
      //         .then((res) => {
      //           let expenseDetails = res.data.selected_expense_details;
      //           let amortized = [];
      //           expenseDetails.filter((e) => {
      //             if (e.expense_type_id === 2) {
      //               amortized.push(e);
      //             }
      //           });
      //           console.log("aaa", amortized);
      //           if (amortized.length) {
      //             let date = new Date(amortized[0].instalment_date);
      //             amortized.filter((a) => {
      //               if (date < new Date(a.instalment_date)) {
      //                 date = new Date(a.instalment_date);
      //               }
      //             });
      //             this.expenseBackCapDate = date;
      //           }
      //           if (res.data.status == "success") {
      //             console.log(
      //               "get fund selected expense details is successful."
      //             );
      //           } else {
      //             console.log("Something went wrong from server!");
      //           }
      //         })
      //         .catch((error) => {
      //           console.log("error: " + error);
      //         });
      //     }
      //   });
      // }
    },

    onCloseExpensePaymentModal(){
      this.type = null;
      this.selectedExpense = null;
      this.selectedExpenseType = null;
      this.amount = null;
      this.description = null;
      this.selectedCurrency =  this.fundCurrency;
      this.expenseBackCapDate = null;
      this.selectedInterestFrequency = null;
      this.paymentDate = new Date();
      this.installments = null;
      this.startDate = null;
      this.fundConversionRate = 1;
      this.fundConvertedAmount = null;
      this.fundConversionDate = new Date();
      this.fundConverionFlag = false;
      this.isSubmitted = false;
      $("#add_expenses").modal("hide");
    },

    calculateFundConvertedAmount(){
      this.fundConvertedAmount = (Number(this.amount) * Number(this.fundConversionRate)).toFixed(2);
    },

    getCurrencyExchangeRateDateForFundExpensePayment() {
      //this function is called to get fund currency of that facility
      console.log("inside getCurrencyExchangeRateDateForRepayment")
      let currencyDate = null;
      console.log("this.selectedExpenseType", this.selectedExpenseType)
      if(this.selectedExpenseType == 'Amortized'){
        if (this.startDate != null) {
          currencyDate = moment(this.startDate).format("MM-DD-YYYY");
        }
      }else if(this.selectedExpenseType == 'Onetime'){
        if (this.paymentDate != null) {
          currencyDate = moment(this.paymentDate).format("MM-DD-YYYY");
        }
      } else {
        if (this.paymentDate != null) {
          currencyDate = moment(this.paymentDate).format("MM-DD-YYYY");
        }
      }
      if(this.fundCurrency == this.selectedCurrency){
        this.fundConverionFlag= false;
        this.fundConversionRate = 1;
        if(currencyDate != null){
          this.fundConversionDate = currencyDate;
        } else {
          this.fundConversionDate = new Date();
        }
        console.log("this.fundConversionDate",this.fundConversionDate)
        this.calculateFundConvertedAmount()
        return
      }

      console.log("this.fundCurrency", this.fundCurrency)
      console.log("this.selectedCurrency", this.selectedCurrency)
      console.log("currencyDate", currencyDate)
      
      if(this.fundCurrency == null || this.selectedCurrency == null || currencyDate == null){
        this.fundConverionFlag= false;
        return;
      }

      const path = "/currency-exchange-rate/" +
        this.fundCurrency +
        "/" +
        this.selectedCurrency +
        "/" +
        currencyDate
        Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log(res.data)
          let data = res.data.currency_rate[0]
          console.log(data)
          if(res.data.status == "success"){
            if(data == null){
            this.currencyConversionFlag = true
            let message = "The Currency conversion rate for the selected Currency and Fund Currency is not available.<br/>So, the system auto selected the Currency as the Fund Currency to proceed.";
            Utils.make_alert("warning", message);
            this.selectedCurrency = this.fundCurrency;
            this.fundConverionFlag= false;
            this.fundConversionRate = 1;
            if(currencyDate != null){
              this.fundConversionDate = currencyDate;
            } else {
              this.fundConversionDate = new Date();
            }
            this.calculateFundConvertedAmount()
            return
            } else {
              let rate =  data.currency_value;
              let date =  data.date;
              this.fundConversionRate = rate;
              this.fundConversionDate = date;
              this.fundConverionFlag= true;
              this.calculateFundConvertedAmount()
            }
            } else {
            Utils.make_alert("success", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    getFund() {
      Utils.start_loading();
      //this function is called on page load and created events. it will show details on fund with fundid equal to this.id
      const path = "fund/" + this.id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          if (res.data.status == "success") {
            this.fund = this.inActive
              ? res.data.fund_inactive
              : res.data.fund_active;
              console.log("this.$store.state.moment_date_format",this.$store.state.moment_date_format)
              if(this.fund.borrower_list.length > 0) {
                this.fund.borrower_list.forEach(item => {
                  if (item.loan_registration_date != '-' && item.loan_registration_date != null) {
                    item.loan_registration_date = moment(item.loan_registration_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.loan_registration_date = '-';
                  }
                });
              }
              if(this.fund.investor_list.length > 0) {
                this.fund.investor_list.forEach(item => {
                  if (item.maturity_date != '-' && item.maturity_date != null) {
                    item.maturity_date = moment(item.maturity_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.maturity_date = '-';
                  }
                  if (item.investment_date != '-' && item.investment_date != null) {
                    item.investment_date = moment(item.investment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.investment_date = '-';
                  }
                });
              }
            this.selectedCurrency = this.fund.fund_currency_id;
            this.fundCurrency = this.fund.fund_currency_id;
            console.log("this.fund.management_fees",this.fund.management_fees)
            console.log("this.fund.performance_fees",this.fund.performance_fees)
            this.expenses = this.fund.management_fees ? this.expenses.filter(exp=> exp.expense_name != 'Management Fees') : this.expenses
            this.expenses = this.fund.performance_fees ? this.expenses.filter(exp=> exp.expense_name != 'Performance Fees') : this.expenses
            console.log("this.expenses",this.expenses)
            console.log("this.fund.fund_inception_date",this.fund.fund_inception_date)
            if(this.fund.fund_inception_date != '-' && this.fund.fund_inception_date != null){
              this.fund.fund_inception_date = moment(this.fund.fund_inception_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              this.fund_inception_date = this.fund.fund_inception_date;
              this.fund_inception_date = moment(this.fund_inception_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.fund_inception_date = '-';
            }
            console.log("this.fund_inception_date",this.fund_inception_date)
            this.expenseBackCapDate = new Date(this.fund_inception_date);
            console.log("get fund details is successful.", this.fund);
          Utils.stop_loading();
          } else if (res.data.status == "unauthorised") {
            Utils.stop_loading();
            this.$router.push("/fund");
          } else {
            Utils.stop_loading();
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getFund close
    getExpenses() {
      //this function is called to get list of expenses
      const path = "expense";
      axios
        .get(path)
        .then((res) => {
          this.paymentExpenses = res.data.expenses;
          this.expenses = res.data.expenses;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getExpenseTypes() {
      //this function is called to get list of expense types
      const path = "expense-type";
      axios
        .get(path)
        .then((res) => {
          this.expenseTypes = res.data.expense_types;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getInterestFrequencies() {
      //this function is called to get list of interest frequencies
      const path = "interest-frequency";
      axios
        .get(path)
        .then((res) => {
          this.interestFrequencies = res.data.interest_frequencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onExpenseSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      
      let currency = "";
      this.currencies.filter((cur) => {
        if (cur.id === this.fundCurrency) {
          currency = cur.currency_short_name;
        }
      });

      this.$confirm({
        auth: false,
        message: `Are you sure you want to make ${this.type} of ${Number(
          Number(this.fundConvertedAmount).toFixed(2)
        ).toLocaleString(
          "en-US"
        )} ${currency}? This is onetime process, Changes cannot be reverted.`,
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            let paymentDate = null;
            let startDate = null;
            let endDate = null;
            let fundConversionDate = null;
            if (this.paymentDate != null) {
              paymentDate = moment(this.paymentDate).format(
                "MM-DD-YYYY"
              );
            }
            if (this.startDate != null) {
              startDate = moment(this.startDate).format("MM-DD-YYYY");
            }
            // NOT IN USE
            if (this.endDate != null) {
              endDate = moment(this.endDate).format("MM-DD-YYYY");
            }
            if (this.fundConversionDate != null) {
              fundConversionDate = moment(this.fundConversionDate).format("MM-DD-YYYY");
            }
      
            this.ExpensePaymentDocuments.map((doc, index) => {
              if (doc.displayFileName === null) this.ExpensePaymentDocuments.splice(index, 1);
            });
            console.log("fundCurrency",this.fundCurrency)
            //initialize the form data
            let formData = new FormData();
            //append company information to formdata
            formData.append("selectedExpense", this.selectedExpense);
            formData.append("selectedCurrency", this.selectedCurrency);
            formData.append("fundCurrency", this.fundCurrency);
            formData.append("paymentDate", paymentDate);
            formData.append("amount", this.amount);
            formData.append("fundConvertedAmount", this.fundConvertedAmount);
            formData.append("fundConversionRate", this.fundConversionRate);
            formData.append("fundConversionDate", fundConversionDate);
            formData.append("description", this.description);
            formData.append("selectedExpenseType", this.selectedExpenseType);
            formData.append("selectedInterestFrequency", this.selectedInterestFrequency);
            formData.append("installments", this.installments);
            formData.append("startDate", startDate);
            formData.append("endDate", endDate);
            formData.append("type", this.type);
            formData.append("nameOfEntity", this.nameOfEntity);
            formData.append("InvoiceNumber", this.InvoiceNumber);
            formData.append("Comments", this.Comments);

            formData.append("selectedBank", this.selectedBank);
            formData.append("bic", this.bic);
            formData.append("iban", this.iban);
            formData.append("accountNumber", this.accountNumber);
            formData.append("accountName", this.accountName);
            formData.append("reference", this.reference);
            formData.append("transactionID", this.transactionID);
            //append ExpensePaymentDocuments information to formdata
            formData.append("ExpensePaymentDocuments", JSON.stringify(this.ExpensePaymentDocuments));
            formData.append("clientId", JSON.stringify(this.$store.state.client_id));
            formData.append("userId", this.$store.getters.getuserId);
            formData.append("moduleId", 1);
            formData.append("tabId", 1);
            console.log(formData);
            const path = "fund-expense-operation/" + this.id;
            Utils.start_loading();
            axios
              .post(path, formData, {
                headers: {
                  enctype: "multipart/form-data",
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("status",response.data.status)
                if (response.data.status == "success") {
                  this.type = this.type.replace(/\w\S*/g, function (txt) {
                    return (
                      txt.charAt(0).toUpperCase() +
                      txt.substr(1).toLowerCase()
                    );
                  });
                  Utils.make_alert(
                    "success",
                    this.type + " added successfully!"
                  );
                  //if you want to send any data into server before redirection then you can do it here
                  // $(".close").click();
                  $("#add_expenses").modal("hide");
                  if (this.index === undefined) {
                    setTimeout(
                      () => this.$router.go(this.$router.currentRoute),
                      1500
                    );
                  } else {
                    this.$router.go(-1);
                  }
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.log("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          }
        },
      });
    }, //onExpenseSubmit() close

    getExpensePaymentByFundId() {
      const path =
        "expense-payment-filter/" +
        this.$route.params.id +
        "/" +
        this.selectedPaymentPeriod;
        Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log('status',res.data.status)
          if (res.data.status == "success") {
          this.fundExpensePayment = this.inActive
            ? res.data.fund_expenses_payment_inactive
            : res.data.fund_expenses_payment_active;
          this.totalPaymentAmountSum = this.inActive
            ? res.data.total_amount_inactive
            : res.data.total_amount_active;
          if (this.totalPaymentAmountSum === null) {
            this.totalPaymentAmountSum = "";
          }
          this.totalPaymentStartDate = moment(res.data.start_date).format(this.$store.state.moment_date_format);
          this.totalPaymentEndDate = moment(res.data.end_date).format(this.$store.state.moment_date_format);
          
            console.log("get fund expense payment by id is successful.");
          } else {
            console.log("error in get fund expense payment by id.");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
        });
    }, //getExpensePaymentByFundId() close

    onFilterExpensePayment() {
      const path =
      "expense-payment-filter/" +
      this.$route.params.id +
      "/" +
      this.selectedPaymentPeriod ;
      Utils.start_loading();
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            Utils.stop_loading();
            console.log('status',res.data.status)
            if (res.data.status == "success") {
            this.fundExpensePayment = this.inActive
              ? res.data.fund_expenses_payment_inactive
              : res.data.fund_expenses_payment_active;
              this.totalPaymentStartDate = moment(res.data.start_date).format(this.$store.state.moment_date_format);
              this.totalPaymentEndDate = moment(res.data.end_date).format(this.$store.state.moment_date_format);
              this.totalPaymentAmountSum = this.inActive
                ? res.data.total_amount_inactive
                : res.data.total_amount_active;
            if (this.totalPaymentAmountSum === null) {
              this.totalPaymentAmountSum = "";
            }
            console.log("moment(this.fund_inception_date, this.$store.state.moment_date_format)",moment(this.fund_inception_date, this.$store.state.moment_date_format))
            console.log("(moment(this.totalPaymentEndDate, this.$store.state.moment_date_format))",(moment(this.totalPaymentEndDate, this.$store.state.moment_date_format)))

            if (moment(this.fund_inception_date, this.$store.state.moment_date_format).isAfter(moment(this.totalPaymentEndDate, this.$store.state.moment_date_format))) {
              Utils.make_alert("warning", "The fund inception date is after the end date of following filter!");
            }
              console.log("get filter expense payment is successful.");
            } else {
              console.log("error in get filter expense payment.");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
          });
      
    }, //onFilterExpensePayment() close

    getExpenseByFundId(e) {
      if (this.inActive) {
        const path =
          "expense-inactive-filter/" +
          this.$route.params.id +
          "/" +
          this.selectedPeriod;
          Utils.start_loading();
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            Utils.stop_loading();
            console.log("status",res.data.status)
            if (res.data.status == "success") {
            this.fundExpenses = res.data.fund_expenses;
            this.totalExpenseFinStartDate = moment(res.data.start_date).format(this.$store.state.moment_date_format);
            this.totalExpenseFinEndDate = moment(res.data.end_date).format(this.$store.state.moment_date_format);
              this.totalExpenseAmountSum =
                res.data.total_amount_fund_expense_inactive;
              if (this.totalExpenseAmountSum === null) {
                this.totalExpenseAmountSum = "";
              }
              console.log("get fund expense by id is successful.");
            } else {
              console.log("error in get fund expense by id");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
          });
      } else {
        Utils.start_loading();
        const path =
          "expense-filter/" + this.$route.params.id + "/" + this.selectedPeriod;
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            console.log('status',res.data.status)
            Utils.stop_loading();
            if (res.data.status == "success") {
            this.fundExpenses = res.data.fund_expenses;
            
            this.totalExpenseFinStartDate = moment(res.data.start_date).format(this.$store.state.moment_date_format);
            this.totalExpenseFinEndDate = moment(res.data.end_date).format(this.$store.state.moment_date_format);
            this.totalExpenseAmountSum = res.data.total_amount_fund_expense;
            if (this.totalExpenseAmountSum === null) {
              this.totalExpenseAmountSum = "";
            }
            if (moment(this.fund_inception_date, this.$store.state.moment_date_format).isAfter(moment(this.totalPaymentEndDate, this.$store.state.moment_date_format))) {
              Utils.make_alert("warning", "The fund inception date is after the end date of following filter!");
            }
              console.log("get fund expense by id is successful.");
            } else {
              console.log("error in get fund expense by id");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
          });
      }
    }, //getExpenseByFundId() close

    getExpenseBackcapDates(){
      const path =
          "filter-by-fund-id/" + this.$route.params.id;
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            this.fundExpensesDates = res.data.expense_dates;
            if (res.data.status == "success") {
              console.log("get fund expense dates by id is successful.");
            } else {
              console.log("error in get fund expense dates by id");
            }
          })
          .catch((error) => {
            console.error("error: " + error);
          });
    },

    getPaymentBackcapDates(){
      const path =
          "filter-by-fund-id-payment/" + this.$route.params.id;
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            this.paymentExpensesDates = res.data.expense_dates;
            if (res.data.status == "success") {
              console.log("get fund expense dates by id is successful.");
            } else {
              console.log("error in get fund expense dates by id");
            }
          })
          .catch((error) => {
            console.error("error: " + error);
          });
    },

    resetPayment() {
      this.selectedPaymentPeriod = "qtd";
      this.getExpensePaymentByFundId();
    }, //resetPayment() close

    resetExpense() {
      this.selectedPeriod = "qtd";
      this.getExpenseByFundId();
    }, //resetExpense() close

    setActiveTab(tabName) {
      this.activeTab = tabName;
    },

    getActionUpcoming(type, index) {
      var path2 = "upcoming-payments";
      var fund_list;
      axios
        .get(path2, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          if (type == "fund_expense") {
            fund_list = res.data.fund_expense_list[index];
            this.selectedExpense = fund_list.expense_id;
            this.amount = fund_list.expenses;
            this.fundConvertedAmount = fund_list.expenses;
            this.selectedCurrency = fund_list.currency_id;
            this.fundCurrency = fund_list.currency_id;
            this.paymentDate = fund_list.instalment_date;
            this.fundConversionDate = fund_list.instalment_date;
            this.fundConversionRate = 1;
            // this.showActionModal("payment");
            this.showAddModal("payment");
          } else if (type == "fund_expense_before_current") {
            fund_list = res.data.fund_expense_before_current_list[index];
            this.selectedExpense = fund_list.expense_id;
            this.amount = fund_list.expenses;
            this.fundConvertedAmount = fund_list.expenses;
            this.selectedCurrency = fund_list.currency_id;
            this.fundCurrency = fund_list.currency_id;
            this.paymentDate = fund_list.instalment_date;
            this.fundConversionDate = fund_list.instalment_date;
            this.fundConversionRate = 1;
            this.paymentDate = fund_list.instalment_date;
            // this.showActionModal("payment");
            this.showAddModal("payment");
          }
        })
        .catch((error) => {
          console.error("error: " + error);
        });
    },
  }, //methods close

  mounted() {
    this.id = this.$route.params.id;
    this.inActive =
      this.$store.state.inactive === "fund_inactive" ? true : false;
    this.getFund();
    this.getExpenseByFundId();
    this.getExpensePaymentByFundId();
    this.getExpenseBackcapDates();
    this.getPaymentBackcapDates()
    this.index = this.$route.params.index;
    this.ptype = this.$route.params.type;
    console.log("this.index", this.index)
    console.log("this.ptype", this.ptype)

    if (localStorage.currentTab) {
      this.currentTab = localStorage.currentTab;
    }
    //keep current tab active
    $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
      localStorage.setItem("outer_activeTab", $(e.target).attr("href"));
    });
    $('a[data-toggle="pill"]').on("shown.bs.tab", function (e) {
      localStorage.setItem("inner_activeTab", $(e.target).attr("href"));
    });
    var outer_activeTab = localStorage.getItem("outer_activeTab");
    var inner_activeTab = localStorage.getItem("inner_activeTab");

    //first open the outer active tab then the inner active tab
    if (outer_activeTab) {
      $('.nav-tabs a[href="' + outer_activeTab + '"]').tab("show");
      if (inner_activeTab) {
        // $('.nav-tabs a[href="' + inner_activeTab + '"]').tab("show");
        this.setActiveTab(inner_activeTab);
      } else {
        this.setActiveTab('total_payments');
        $('a[data-toggle="pill"]').on("shown.bs.tab", function (e) {
          localStorage.setItem("inner_activeTab", $(e.target).attr("href"));
        });
      }
    }

    if (this.index >= 0) {
      $('.nav-tabs a[href="#custom-tabs-one-profile"]').tab("show");
      // $('.nav-tabs a[href="#total_payments"]').tab("show");
      this.setActiveTab('total_payments');

      this.getActionUpcoming(this.ptype, this.index);
    }
    // if(true){
    //    const path = "upcoming-payments";
    //    axios
    //      .get(path)
    //      .then((res) => {

    //      })
    //      .catch((error) => {
    //        console.error("error: " + error);
    //      });
    //    selectedExpenseType
    //    amount
    //    description
    //    selectedCurrency
    //    paymentDate
    //    selectedInterestFrequency
    // }
  },

  watch: {
    currentTab(newTab) {
      localStorage.currentTab = this.currentTab;
    },
  },

  created() {
    axios.defaults.headers.common["Authorisation"] =
      "Bearer " + sessionStorage.getItem("token");
    this.getExpenses();
    this.getCurrencies();
    this.getExpenseTypes();
    this.getInterestFrequencies();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>
<style scoped>
.required-field::after {
  content: "*";
  color: red;
}
.datepop {
  width: 220px;
}
.additionalLink{
  margin-right: 10px !important;
  color: blue !important;
  cursor: pointer !important;
}
th,
td {
  text-align: left;
}
.center {
  text-align: center !important;
}
.right {
  text-align: right !important;
}
.wg{
  font-weight: bold !important;
}
</style>
