<!-- This is add Investor III page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Edit Investment
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor:pointer;">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field">Investor Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="investment.investor_name"
                      :disabled="true"
                    />
                  </div>
                </div>

                <div class="col-md-3 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field">Investment Category</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="investment.investment_category_name"
                      :disabled="true"
                    />
                  </div>
                </div>

                <div class="col-md-3 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label class="required-field">Fund Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="investment.fund_name"
                      :disabled="true"
                    />
                  </div>
                </div>

                <div class="col-md-3 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field">Issue Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="IssueDate"
                      placeholder="Select Date"
                      v-model="investment.investment_date"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{from: new Date()}"
                    ></datepicker>
                  </div>
                </div>

                <div class="col-md-3 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field">Amount</label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder
                      v-model="investment.principal_amount"
                    />
                  </div>
                </div>

                <div class="col-md-3 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field">Currency</label>
                    <select
                      id="selectedCurrency"
                      class="form-control"
                      name="selectedCurrency"
                      v-model="investment.currency_id"
                      :disabled="true"
                    >
                      <option :value="null">-- Select a Currency --</option>
                      <option
                        v-for="curr in currencies"
                        :value="curr.id"
                      >{{ curr.currency_short_name }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Maturity Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="MaturityDate"
                      placeholder="Select Date"
                      v-model="investment.maturity_date"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{to: new Date(this.IssueDate - 8640000)}"
                    ></datepicker>
                  </div>
                </div>

                <!--<div
                  v-if="investment.investment_category_name != 'Participation'"
                  class="col-md-3 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label>Maturity Extendible</label>
                    <select
                      id="selectedMaturityExtendible"
                      class="form-control"
                      name="selectedMaturityExtendible"
                      v-model="investment.maturity_extendible"
                    >
                      <option :value="null">--- Select ---</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>-->

                <!-- <div
                  v-if="investment.maturity_extendible === 'Yes' "
                  class="col-md-3 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Extended Maturity Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="extendedMaturityDate"
                      placeholder="Select Date"
                      v-model="investment.extended_maturity_date"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      
                    ></datepicker>
                  </div>
                </div> -->

                <div class="col-md-3 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label class="required-field">Interest Rate Type</label>
                    <select
                      id="selectedInterestRateType"
                      class="form-control"
                      name="selectedInterestRateType"
                      v-model="investment.interest_type_name"
                    >
                      <option :value="null">-- Select an Interest Type --</option>
                      <option
                        v-for="interest in interestTypes"
                        :value="interest.interest_type_name"
                      >{{ interest.interest_type_name }}</option>
                    </select>
                  </div>
                </div>

                <div
                  v-if="investment.interest_type_name === 'Fixed' "
                  class="col-md-3 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field">Interest Rate (%)</label>
                    <input
                      type="number"
                      class="form-control allow-only-numbers"
                      placeholder
                      v-model="investment.coupon"
                      step=".01"
                      min="0"
                      oninput="validity.valid||(value='');"
                    />
                  </div>
                </div>

                <div
                  v-if="investment.interest_type_name === 'Floating' "
                  class="col-md-3 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field">Spread (%)</label>
                    <input
                      type="number"
                      class="form-control allow-only-numbers"
                      placeholder
                      v-model="investment.interest_spread"
                      step=".01"
                      min="0"
                      oninput="validity.valid||(value='');"
                    />
                  </div>
                </div>

                <div
                  v-if="investment.interest_type_name === 'Floating' "
                  class="col-md-3 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field">Benchmark</label>
                    <select
                      id="tokens"
                      class="form-control"
                      name="selectedBenchmark"
                      v-model="investment.benchmark_id"
                    >
                      <option
                        v-for="benchmark in benchmarks"
                        :value="benchmark.id"
                      >{{ benchmark.benchmark_name }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label class="required-field">Interest Payment Schedule</label>
                    <select
                      id="selectedInterestPaymentSchedule"
                      class="form-control"
                      name="selectedInterestPaymentSchedule"
                      v-model="investment.interest_frequency_id"
                    >
                      <option :value="null">-- Select a Payment Schedule --</option>
                      <option
                        v-for="payment in paymentTypes"
                        :value="payment.id"
                      >{{ payment.interest_frequency_name }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field">Day Count</label>
                    <select
                      id="selectedDayCount"
                      class="form-control"
                      name="selectedDayCount"
                      v-model="investment.day_count_id"
                    >
                      <option :value="null">-- Select a Count --</option>
                      <option v-for="dc in dayCounts" :value="dc.id">{{ dc.day_count_name }}</option>
                    </select>
                  </div>
                </div>

                <div
                  v-if="investment.investment_category_name !== 'Notes' "
                  class="col-md-3 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Penalty (%)</label>
                    <input
                      type="number"
                      class="form-control allow-only-numbers"
                      placeholder
                      v-model="investment.penalty_interest"
                      step=".01"
                      min="0"
                      oninput="validity.valid||(value='');"
                    />
                  </div>
                </div>

                <div
                  v-if="investment.investment_category_name !== 'Notes' "
                  class="col-md-3 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Admin Fees (%)</label>
                    <input
                      type="number"
                      class="form-control allow-only-numbers"
                      placeholder
                      v-model="investment.admin_fees"
                      step=".01"
                      min="0"
                      oninput="validity.valid||(value='');"
                    />
                  </div>
                </div>

                <div
                  v-if="investment.investment_category_name !== 'Notes' "
                  class="col-md-3 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Mgmt Fees (%)</label>
                    <input
                      type="number"
                      class="form-control allow-only-numbers"
                      placeholder
                      v-model="investment.management_fees"
                      step=".01"
                      min="0"
                      oninput="validity.valid||(value='');"
                    />
                  </div>
                </div>

                <div class="col-md-3 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Tranche no</label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder
                      v-model="investment.tranche_no"
                    />
                  </div>
                </div>

                <!--<div class="col-md-3 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Borrower Name</label>
                    <select
                      id="tokens"
                      class="selectpicker form-control"
                      name="selectedBorrowerName"
                      v-model="selectedBorrowerName"
                    >
                      <option
                        v-for="borrower in BorrowerName"
                        :value="borrower.id"
                      >{{ borrower.borrower_name }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Loan</label>
                    <select
                      id="tokens"
                      class="selectpicker form-control"
                      name="selectedLoanId"
                      v-model="selectedLoanId"
                    >
                      <option v-for="type in UtilizationReqID" :value="type.id">{{ type.Uti_ReqID }}</option>
                    </select>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <button type="button" class="btn-sm btn btn-success sh" v-on:click="onSubmit()">Submit</button>
              <a @click="$router.go(-1)" style="cursor:pointer;">
                <button type="button" class="btn-sm btn btn-dark sh" data-dismiss="modal">Cancel</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function

export default {
  data() {
    return {
      benchmarks: [],
      selectedDayCount: null,
      currencies: [],
      paymentTypes: [],
      dayCounts: [],
      interestTypes: [],
      investment: []
    };
  },
  computed: {},
  components: {
    Datepicker
  },

  methods: {
    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then(res => {
          this.currencies = res.data.currencies;
        })
        .catch(error => {
          console.error(error);
        });
    },

    getDayCount() {
      //this function is called to get list of day count
      const path = "day-count-master";
      axios
        .get(path)
        .then(res => {
          this.dayCounts = res.data.day_counts;
        })
        .catch(error => {
          console.error(error);
        });
    },

    getPaymentTypes() {
      //this function is called to get list of payment types
      const path = "interest-frequency";
      axios
        .get(path)
        .then(res => {
          this.paymentTypes = res.data.interest_frequencies;
        })
        .catch(error => {
          console.error(error);
        });
    },

    getInterestTypes() {
      //this function is called to get list of interest types
      const path = "interest-type";
      axios
        .get(path)
        .then(res => {
          this.interestTypes = res.data.interest_types;
        })
        .catch(error => {
          console.error(error);
        });
    },

    getBenchmarks() {
      //this function is called to get list of benchmarks
      const path = "benchmark";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          this.benchmarks = res.data.benchmarks;
        })
        .catch(error => {
          console.error(error);
        });
    },

    getInvestmentByTranId(e) {
      const path =
        "investment-by-transaction-id/" +
        this.investor_id +
        "/" +
        this.transaction_id;
      axios
        .get(path)
        .then(res => {
          console.log(res.data);
          this.investment = res.data.investment;
          if (res.data.status == "success") {
            console.log("get investment by investor id is successful.");
          }
        })
        .catch(error => {
          console.error("error: " + error);
        });
    }, //getInvestmentByTranId() close

    onSubmit() {
      //this function is for updating investment details
      if (
        this.investment.investor_name === null ||
        this.investment.investment_date === null ||
        this.investment.investment_category_name === null ||
        this.investment.fund_name === null ||
        this.investment.principal_amount === null ||
        this.investment.currency_id === null ||
        this.investment.interest_type_name === null ||
        this.investment.interest_frequency_id === null ||
        this.investment.day_count_id === null
      ) {
        Utils.make_alert("warning", "Mandatory fields are required.");
        return;
      }

      if (this.investment.interest_type_name === "Fixed") {
        if (this.investment.coupon === null) {
          Utils.make_alert("warning", "Mandatory fields are required.");
          return;
        }
      }

      if (this.investment.interest_type_name === "Floating") {
        if (
          this.investment.interest_spread === null ||
          this.investment.benchmark_id === null
        ) {
          Utils.make_alert("warning", "Mandatory fields are required.");
          return;
        }
      }

      if (confirm("Confirm submit?")) {
        if (this.investment.investment_date != null) {
          this.investment.investment_date = moment(
            this.investment.investment_date
          ).format("MM-DD-YYYY");
        }
        if (this.investment.maturity_date != null) {
          this.investment.maturity_date = moment(
            this.investment.maturity_date
          ).format("MM-DD-YYYY");
        }
        if (this.investment.extended_maturity_date != null) {
          this.investment.extended_maturity_date = moment(
            this.investment.extended_maturity_date
          ).format("MM-DD-YYYY");
        }
        const path =
          "investment-by-transaction-id/" +
          this.investor_id +
          "/" +
          this.transaction_id;
        axios
          .put(
            path,
            {
              investmentUpdatedDetails: this.investment
            },
            {
              headers: {
                "Content-type": "application/json"
              }
            }
          )
          .then(response => {
            if (response.data.status == "success") {
              Utils.make_alert("success", "Investment updated successfully!");
              this.$router.push("/investor-overview/" + this.investor_id);
            } else {
              Utils.make_alert("warning", "Something went wrong!");
            }
          })
          .catch(error => {
            console.log("error: " + error);
            Utils.make_alert("warning", "Something went wrong!");
          });
      }
    }
  }, //main function close

  mounted() {
    var numberOfDecimals = 2;
    $(document).on("input", ".allow-only-numbers", function() {
      var regExp = new RegExp("(\\.[\\d]{" + numberOfDecimals + "}).", "g");
      this.value = this.value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1")
        .replace(regExp, "$1");
    });
  },
  created() {
    this.investor_id = this.$route.params.investor_id;
    this.transaction_id = this.$route.params.transaction_id;
    this.getCurrencies();
    this.getDayCount();
    this.getInterestTypes();
    this.getPaymentTypes();
    this.getBenchmarks();
    this.getInvestmentByTranId();
  }
};
</script>

<style scoped>
.required-field::after {
  content: "*";
  color: red;
}
</style>
