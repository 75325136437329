<template>
  <div class="container-fluid h-100">
    <div class="row justify-content-center h-100">
      <div class="col-lg-5 col-md-5" id="side_view">
        <div
          class="blue_portion"
          style="background-image: url('/asset/images/sidemenu_bg.png')"
        >
          <div class="tx">
            <h3>LUNDY INVESTORS</h3>
            <h4>Tailored Solution for Wealth Creation</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-7" id="grey">
        <div class="login_mid-Portion">
          <center>
            <img
              src="/asset/images/AdminLTELogo.png"
              class="img-fluid login_logo"
            />
          </center>
          <div>
            <h5></h5>
            <h5></h5>
            <h5></h5>
          </div>
          <center>
            <h2>You Are Not Authorised</h2>
          </center>
        </div>

        <p class="bt_text">
          © 2022, Design & Development by
          <a href="https://www.decimalpointanalytics.com/"
            >Decimal Point Analytics Pvt Ltd.</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Unauthorised",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.error-msg {
  color: red;
  font-weight: bold;
}
.login_img {
  background-color: #d2d2d2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60%;
}
#side_view {
  height: 100%;
  padding: 0px;
}
</style>
