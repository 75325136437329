<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-spinner-alt-3"></i> Facility Setup
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                            <!-- <a href="facility.html">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                            </a>-->
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover table-head-fixed text-nowrap">
                    <tbody>
                      <tr>
                        <td class="wg">Facility Registration Id:</td>
                        <td
                          v-if="facility.facility_registration_id !== null"
                          class
                        >
                          {{ facility.facility_registration_id }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Fund Name:</td>
                        <td v-if="facility.fund_name !== null" class>
                          {{ facility.fund_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Borrower Name:</td>
                        <td v-if="facility.borrower_name !== null" class>
                          {{ facility.borrower_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Facility Limit:</td>
                        <td
                          v-if="
                            facility.facility_limit !== null ||
                            facility.facility_limit !== undefined
                          "
                          class
                        >
                          {{ facility.facility_limit | numeral("0,0.00") }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Penalty Interest(%):</td>
                        <td
                          v-if="facility.penalty_interest_rate !== null"
                          class
                        >
                          {{ facility.penalty_interest_rate }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Type of Loan:</td>
                        <td v-if="facility.loan_type_name !== null" class>
                          {{ facility.loan_type_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Equity (%):</td>
                        <td v-if="facility.equity !== null" class>
                          {{ facility.equity }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody>
                      <!----------------------------------------------->
                      <!-- <tr>
                        <td class="wg">Pre Closure Fees(%):</td>
                        <td
                          v-if="facility.preclosure_fees !== null "
                          class
                        >{{ facility.preclosure_fees }}</td>
                        <td v-else class>-</td>
                      </tr>-->
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Loan to Value(%):</td>
                        <td v-if="facility.loan_to_value !== null" class>
                          {{ facility.loan_to_value }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Guarantor:</td>
                        <td v-if="facility.guarantor_name !== null" class>
                          {{ facility.guarantor_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Funding Type:</td>
                        <td v-if="facility.funding_type_name !== null" class>
                          {{ facility.funding_type_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Loan Disbursal:</td>
                        <td
                          v-if="facility.loan_disbursal_type_name !== null"
                          class
                        >
                          {{ facility.loan_disbursal_type_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Date of Facility:</td>
                        <td
                          v-if="facility.facility_request_date !== null"
                          class
                        >
                          {{ facility.facility_request_date }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <!-- <tr>
                      <td class="wg">Terms:</td>
                      <td class>{{ facility.terms_to_offtaker_contract }}</td>
                      </tr>-->
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Facility With:</td>
                        <td v-if="facility.facility_with == 0" class>
                          Collateral
                        </td>
                        <td v-else-if="facility.facility_with == 1" class>
                          No Collateral
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Comments:</td>
                        <td v-if="facility.comments !== null" class>
                          {{ facility.comments }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div v-if="facility.facility_with == 0" class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Collateral Mapping</h1>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <!------menton here style="height: 300px;"-------->
                    <table
                      class="table table-head-fixed text-nowrap table-hover"
                    >
                      <thead>
                        <tr>
                          <th>Collateral</th>
                          <th class="center" colspan="5">Discount Value (%)</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th>Uncleaned</th>
                          <th>Cleaned</th>
                          <th>Waste</th>
                          <th>Logistics Info</th>
                          <th>Shipping</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="col in facility.facility_collateral_transaction_details"
                          :key="col.id"
                        >
                          <td v-if="col.collateral_name !== null">
                            {{ col.collateral_name }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="col.uncleaned !== null">
                            {{ col.uncleaned }}
                          </td>
                          <td v-else class="c">-</td>
                          <td v-if="col.cleaned !== null">{{ col.cleaned }}</td>
                          <td v-else class="c">-</td>
                          <td v-if="col.waste !== null">{{ col.waste }}</td>
                          <td v-else class="c">-</td>
                          <td v-if="col.logistics_info !== null">
                            {{ col.logistics_info }}
                          </td>
                          <td v-else class="c">-</td>
                          <td v-if="col.shipping !== null">
                            {{ col.shipping }}
                          </td>
                          <td v-else class="c">-</td>
                        </tr>
                        <!----------------------------------------------->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Covenant Compliance</h1>
                      </div>
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <router-link
                              active-class="active"
                              :to="{
                                name: 'FacilityCovenantHistory',
                                params: {
                                  id: id,
                                },
                              }"
                            >
                              <a>
                                <u>Covenant History</u>
                              </a>
                            </router-link>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <!------menton here style="height: 300px;"-------->
                    <table
                      class="table table-head-fixed text-nowrap table-hover"
                    >
                      <thead>
                        <tr>
                          <th>Covenant Compliance</th>
                          <th class="c">Review Date</th>
                          <th>Expected Value</th>
                          <th>Actual Value</th>
                          <th class="c">Compliant</th>
                          <th class="c center">Comments</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="cov in facility.facility_covenants_details"
                          :key="cov.id"
                        >
                          <td v-if="cov.covenants_name !== null">
                            {{ cov.covenants_name }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="cov.upload_date !== null" class="c">
                            {{ cov.upload_date }}
                          </td>
                          <td v-else class="c">-</td>
                          <td v-if="cov.expected_value !== null">
                            {{ cov.sign }} {{ cov.expected_value }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="cov.actual_value !== null">
                            {{ cov.actual_value }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="cov.compliant !== null" class="c">
                            {{ cov.compliant }}
                          </td>
                          <td v-else class="c">-</td>
                          <td
                            v-if="!inactive"
                            class="c center"
                            v-on:click="
                              showCommentModal(
                                cov.facility_id,
                                cov.covenants_name,
                                'covenant_comment',
                                cov.comments
                              )
                            "
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-comment text-info"></i>
                          </td>
                          <td class="c center" v-else>
                            <i
                              style="opacity: 0.5"
                              class="icofont-comment text-info"
                            ></i>
                          </td>
                          <!-- <td class="c"> cov.covenants_master_id,
                          <a href="#" data-toggle="modal" data-target="#comm">
                            <i class="icofont-comment text-info"></i>
                          </a>
                          </td>-->
                        </tr>
                        <!----------------------------------------------->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">CP Checklist</h1>
                      </div>
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <router-link
                              active-class="active"
                              :to="{
                                name: 'FacilityCPChecklistHistory',
                                params: {
                                  id: id,
                                },
                              }"
                            >
                              <a>
                                <u>CP Checklist History</u>
                              </a>
                            </router-link>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <!------menton here style="height: 300px;"-------->
                    <table class="table table-head-fixed text-nowrap">
                      <thead>
                        <tr>
                          <th>Documents</th>
                          <th class="c">Issue Date</th>
                          <th class="c">Expiry Date</th>
                          <th class="c">Status</th>
                          <th
                            class="c"
                            v-if="
                              loggedInUserGroupId == 'Admin' &&
                              loggedInUserSubGroupId == 0
                            "
                          >
                            Change Status
                          </th>
                          <th class="c center">Comments</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(cp, index) in facility_documents_details"
                          :key="cp.id"
                        >
                          <td
                            v-on:click="downloadFacDocument(cp.document_path)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-download text-info"></i>
                            {{ cp.document_name }}
                          </td>
                          <td v-if="cp.uploading_date !== null" class="c">
                            {{ cp.uploading_date }}
                          </td>
                          <td v-else class="c">-</td>
                          <td v-if="cp.expiry_date !== null" class="c">
                            {{ cp.expiry_date }}
                          </td>
                          <td v-else class="c">-</td>
                          <td
                            class="c"
                            :class="{
                              'c text-success': cp.status === 'Approved',
                              'c text-warning': cp.status === 'Received',
                              'c text-danger': cp.status === 'Rejected',
                              'c text-dark': cp.status === 'Expired',
                              'c text-info': cp.status === 'Near to Expiry',
                            }"
                          >
                            {{
                              cp.status === "Received"
                                ? loggedInUserGroupId === "Borrower"
                                  ? "Uploaded"
                                  : cp.status
                                : cp.status
                            }}
                          </td>

                          <td
                            class="c status"
                            v-if="
                              loggedInUserGroupId == 'Admin' &&
                              loggedInUserSubGroupId == 0
                            "
                          >
                            <div class="status">
                              <select
                                id="docStatus"
                                name="docStatus"
                                @change="
                                  onChangeStatus(
                                    $event,
                                    cp.facility_id,
                                    cp.document_name,
                                    cp.status,
                                    index
                                  )
                                "
                                class="form-control"
                                :value="cp.status"
                                :disabled="
                                  inactive ||
                                  cp.status === 'Rejected' ||
                                  cp.status === 'Approved' ||
                                  cp.status === 'Near to Expiry' ||
                                  cp.status === 'Expired'
                                "
                              >
                                <option value="Received">Received</option>
                                <option value="Approved">Approved</option>
                                <option value="Rejected">Rejected</option>
                                <option
                                  v-if="cp.status === 'Expired'"
                                  value="Expired"
                                >
                                  Expired
                                </option>
                                <option
                                  v-if="cp.status === 'Near to Expiry'"
                                  value="Near to Expiry"
                                >
                                  Near to Expiry
                                </option>
                              </select>
                            </div>
                          </td>
                          <td
                            v-if="!inactive"
                            class="c center"
                            v-on:click="
                              showCommentModal(
                                cp.facility_id,
                                cp.document_name,
                                'CpCheck_comment',
                                cp.comments
                              )
                            "
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-comment text-info"></i>
                          </td>
                          <td class="c center" v-else>
                            <i
                              style="opacity: 0.5"
                              class="icofont-comment text-info"
                            ></i>
                          </td>
                          <!-- <td class="text-success">Received</td>
                        <td class="c">
                          <a href="#" data-toggle="modal" data-target="#comm">
                            <i class="icofont-comment text-info"></i>
                          </a>
                          </td>-->
                        </tr>
                        <!----------------------------------------------->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div
            v-if="facility.equity !== null"
            class="container-fluid ot_tb pt-3"
          >
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Enterprise Value</h1>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <!------menton here style="height: 300px;"-------->
                    <table class="table table-head-fixed text-nowrap">
                      <thead>
                        <tr>
                          <th class="c">Enterprise Value (In Millions)</th>
                          <th class="c">Valuation Date</th>
                          <th class="c"></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="enterprise in facility.EnterpriseValueList"
                          :key="enterprise.id"
                        >
                          <td
                            class="c"
                            v-if="
                              enterprise.enterprise_value !== null ||
                              enterprise.enterprise_value !== undefined
                            "
                          >
                            {{
                              enterprise.enterprise_value | numeral("0,0.00")
                            }}
                          </td>
                          <td class="c" v-else>-</td>
                          <td
                            v-if="enterprise.valuation_date !== null"
                            class="c"
                          >
                            {{ enterprise.valuation_date }}
                          </td>
                          <td v-else class>-</td>
                          <td class="c"></td>
                        </tr>
                        <!----------------------------------------------->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>

    <!-- ./modal start -->
    <div class="modal fade" id="comm">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-comment"></i> Comments
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <textarea
                  rows="4"
                  cols="60"
                  style="outline: none"
                  v-model="comment"
                  :class="{
                    'is-invalid': $v.comment.$error,
                  }"
                ></textarea>
                <div v-if="$v.comment.$error" class="invalid-feedback">
                  <span v-if="!$v.comment.maxLength"
                    >Max length of 50 characters exceeded</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="saveComment()"
                  >
                    Save
                  </button>
                </li>
                <li>
                  <router-link :to="{ name: 'FacilityOverview' }">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->

    <!-- ./modal start -->
    <div class="modal fade" id="view_doc">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-eye-alt"></i> View Document
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table">
                    <tbody>
                      <tr v-for="doc in selectedCpCheckDocumentsList" :key="doc.document_id">
                        <td class="col-10">{{ doc.document_name }}</td>
                        <td
                          class="c col-1"
                          v-on:click="downloadFacDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-download text-info"></i>
                        </td>
                        <!-- <td class="c col-1">
                          <a href="asset/images/document.jpg" target="_blank">
                            <i class="icofont-file-pdf text-danger"></i>
                          </a>
                        </td>
                        <td class="c col-1">
                          <a href="asset/images/document.jpg" download>
                            <i class="icofont-download text-info"></i>
                          </a>
                        </td>-->
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <router-link :to="{ name: 'FacilityOverview' }">
                <button
                  type="button"
                  class="btn-sm btn btn-dark sh"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->

    <!-- ./modal start -->
    <div class="modal fade" id="comm">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-comment"></i> Comments
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <textarea
                  rows="4"
                  cols="60"
                  style="outline: none"
                  v-model="comment"
                ></textarea>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="saveComment()"
                  >
                    Save
                  </button>
                </li>
                <li>
                  <router-link :to="{ name: 'FacilityOverview' }">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { maxLength } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  data() {
    return {
      id: null,
      // tabIndex: 0,
      // tabs: ["#one", "#two", "#three"],
      facility: [],
      collaterals: [],
      // Payment: [],
      facilityId: null,
      documentId: null, //required for comment modals
      comment: null, //required for comment modal
      docStatus: null,
      CpCheckCommentFlag: false,
      CovenantCommentFlag: false,
      selectedCpCheckDocumentsList: [],
      facility_documents_details: [],
    };
  },
  validations: {
    comment: {
      maxLength: maxLength(50),
    },
  },
  components: {
    Datepicker,
  },
  methods: {
    onChangeStatus(updatedStatus, facilityId, docId, prevStatus, index) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            //this function is called when user changes the facility registration status. The status is updated in the database for that specific facility.
            const path = "facility-overview/" + facilityId;
            axios
              .put(
                path,
                {
                  updateType: "docStatus",
                  updatedCPStatus: updatedStatus.target.value,
                  documentId: docId,
                  facId: facilityId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 4,
                  tabId: 14,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("registration update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert("success", "Status updated successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  // this.$router.go(this.$router.currentRoute);
                  this.facility_documents_details[index].status =
                    updatedStatus.target.value;
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeStatus close

    downloadFacDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },

    showCommentModal(facId, docId, mgmtType, comm) {
      //this function opens the comment modal. 'docId' will take input for CpCheck document id and covenant id both.
      this.facilityId = facId;
      this.documentId = docId;
      this.comment = comm;
      if (mgmtType === "CpCheck_comment") {
        this.CpCheckCommentFlag = true;
        this.CovenantCommentFlag = false;
      }
      if (mgmtType === "covenant_comment") {
        this.CovenantCommentFlag = true;
        this.CpCheckCommentFlag = false;
      }
      $("#comm").modal("show");
    }, //showCommentModal() close

    handleInput: function (e) {
      //this function sets the 'comment' variable with comment inserted in <p> tag of comment modal.
      this.comment = e.target.innerText;
    },

    saveComment() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      //this function saves the comment from comment modal.
      this.$confirm({
        auth: false,
        message: "Confirm submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            let commentUpdateType;
            if (this.CpCheckCommentFlag === true) {
              commentUpdateType = "CpCheck_comment";
            }
            if (this.CovenantCommentFlag === true) {
              commentUpdateType = "covenant_comment";
            }
            const path = "facility-overview/" + this.facilityId;
            axios
              .put(
                path,
                {
                  updateType: commentUpdateType,
                  comments: this.comment,
                  docId: this.documentId,
                  facId: this.facilityId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 4,
                  tabId: 14,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                console.log("comment update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert("success", "Comment updated successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(() => this.$router.go(), 1500);
                } else {
                  console.log("Something went wrong from server!");
                }
              })
              .catch((error) => {
                console.error("error: " + error);
                console.log("Something went wrong!");
              });
          }
        },
      });
    },

    getFacility() {
      Utils.start_loading();
      //this function is called on page load and created events. it will show details on facility with facilityid equal to this.id
      const path = "facility-overview/" + this.id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.facility = this.inactive
              ? res.data.facility_inactive
              : res.data.facility_active;
            if (this.facility.facility_request_date != '-' && this.facility.facility_request_date != null) {
              this.facility.facility_request_date = moment(this.facility.facility_request_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.facility.facility_request_date = '-';
            }
            if(this.facility.facility_covenants_details.length > 0) {
              this.facility.facility_covenants_details.forEach(item => {
                if (item.upload_date != '-' && item.upload_date != null) {
                  item.upload_date = moment(item.upload_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.upload_date = '-';
                }
              });
            }
            this.facility_documents_details =
            this.facility.facility_documents_details;
            if(this.facility_documents_details.length > 0) {
              this.facility_documents_details.forEach(item => {
                if (item.uploading_date != '-' && item.uploading_date != null) {
                  item.uploading_date = moment(item.uploading_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.uploading_date = '-';
                }
                if (item.expiry_date != '-' && item.expiry_date != null) {
                  item.expiry_date = moment(item.expiry_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.expiry_date = '-';
                }
              });
            }
            console.log("get facility details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/facility");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getFacility close
  },

  mounted() {
    this.id = this.$route.params.id;
    this.inactive =
      this.$store.state.inactive === "facility_inactive" ? true : false;
    this.getFacility();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style>
textarea {
  border-style: none;
  border-color: Transparent;
  overflow: auto;
}
th,
td {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right !important;
}
</style>
