<!-- This is add collateral page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i>
                        {{
                          id ? "Add Cleaned Received Info" : "Add Received Info"
                        }}
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a
                            @click="
                              id
                                ? $router.push({
                                    name: 'Collateral',
                                    params: { underProcess: true },
                                  })
                                : $router.go(-1)
                            "
                            style="cursor: pointer"
                          >
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Request ID</label
                    >
                    <input
                      id="requestId"
                      class="form-control"
                      name="requestId"
                      v-model="requestId"
                      maxlength="20"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.requestId.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.requestId.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.requestId.required"
                        >Request ID is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Utilisation ID</label
                    >
                    <input
                      id="utilId"
                      class="form-control"
                      name="utilId"
                      v-model="utilId"
                      maxlength="20"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.utilId.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.utilId.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.utilId.required"
                        >Utilisation ID is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Borrower Name</label
                    >
                    <input
                      id="borrowerName"
                      class="form-control"
                      name="borrowerName"
                      v-model="borrowerName"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.borrowerName.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.borrowerName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.borrowerName.required"
                        >Borrower Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="date"
                      id="date"
                      placeholder="Select Date"
                      v-model="date"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{
                        from: new Date(),
                        to: new Date(requestDate),
                      }"
                      :class="{
                        'is-invalid': isSubmitted && $v.date.$error,
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.date.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.date.required">Date is required</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Collateral Name</label
                    >
                    <select
                      id="selectedCollateral"
                      class="form-control"
                      name="selectedCollateral"
                      v-model="selectedCollateral"
                      :disabled="id !== null"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedCollateral.$error,
                      }"
                    >
                      <option :value="null">-- Select --</option>
                      <option v-for="col in collaterals" :value="col.id">
                        {{ col.collateral_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCollateral.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCollateral.required"
                        >Collateral Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Warrant Ref.</label
                    >
                    <input
                      id="warrantRef"
                      class="form-control"
                      name="warrantRef"
                      v-model="warrantRef"
                      :disabled="id !== null"
                      :class="{
                        'is-invalid': isSubmitted && $v.warrantRef.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.warrantRef.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.warrantRef.required"
                        >Warrant Ref is required</span
                      >
                      <span v-if="!$v.warrantRef.maxLength"
                        >Max length of 25 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Collateral Qty</label
                    >
                    <input
                      type="number"
                      min="0"
                      id="collateralQty"
                      class="form-control"
                      name="collateralQty"
                      v-model="collateralQty"
                      @keypress="isDecimal($event)"
                      @keyup="calculateValue()"
                      :disabled="id !== null"
                      :class="{
                        'is-invalid': isSubmitted && $v.collateralQty.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.collateralQty.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.collateralQty.required"
                        >Collateral Qty is required</span
                      >
                      <span v-if="!$v.collateralQty.maxLength"
                        >Max length of 15 characters exceeded<br
                      /></span>
                      <span v-if="!$v.collateralQty.collateralQtyLimit">
                        Collateral Qty cannot exceed {{ quantityLimit }}!
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Collateral Price</label
                    >
                    <input
                      type="number"
                      min="0"
                      id="collateralPrice"
                      class="form-control"
                      name="collateralPrice"
                      v-model="collateralPrice"
                      @keypress="isDecimal($event)"
                      @keyup="calculateValue()"
                      :disabled="id !== null"
                      :class="{
                        'is-invalid': isSubmitted && $v.collateralPrice.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.collateralPrice.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.collateralPrice.required"
                        >Collateral Price is required</span
                      >
                      <span v-if="!$v.collateralPrice.maxLength"
                        >Max length of 15 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Collateral Value</label
                    >
                    <input
                      type="number"
                      min="0"
                      id="collateralValue"
                      class="form-control"
                      name="collateralValue"
                      v-model="collateralValue"
                      @keypress="isDecimal($event)"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.collateralValue.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.collateralValue.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.collateralValue.required"
                        >Collateral Value is required</span
                      >
                      <span v-if="!$v.collateralValue.maxLength"
                        >Max length of 15 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Gross Weight</label
                    >
                    <input
                      type="number"
                      min="0"
                      id="grossWeight"
                      class="form-control"
                      name="grossWeight"
                      v-model="grossWeight"
                      @keypress="isDecimal($event)"
                      :disabled="id !== null"
                      :class="{
                        'is-invalid': isSubmitted && $v.grossWeight.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.grossWeight.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.grossWeight.required"
                        >Gross Weight is required</span
                      >
                      <span v-if="!$v.grossWeight.maxLength"
                        >Max length of 15 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Unit</label
                    >
                    <select
                      id="selectedUnit"
                      class="form-control"
                      name="selectedUnit"
                      v-model="selectedUnit"
                      :disabled="id !== null"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedUnit.$error,
                      }"
                    >
                      <option :value="null">-- Select --</option>
                      <option v-for="unit in UnitTypes" :value="unit.id">
                        {{ unit.name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedUnit.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedUnit.required"
                        >Unit is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >No. of Bags</label
                    >
                    <input
                      id="bags"
                      class="form-control"
                      name="bags"
                      v-model="bags"
                      @keypress="isNum($event)"
                      :disabled="id !== null"
                      :class="{
                        'is-invalid': isSubmitted && $v.bags.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.bags.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.bags.required"
                        >No. of Bags is required</span
                      >
                      <span v-if="!$v.bags.maxLength"
                        >Max length of 15 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >GRN Number</label
                    >
                    <input
                      id="grnNo"
                      class="form-control"
                      name="grnNo"
                      v-model="grnNo"
                      @keypress="isNum($event)"
                      :disabled="id !== null"
                      :class="{
                        'is-invalid': isSubmitted && $v.grnNo.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.grnNo.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.grnNo.required">GRN is required</span>
                      <span v-if="!$v.grnNo.maxLength"
                        >Max length of 15 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6">
                  <h1 class="headingt">Quality Parameters</h1>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Bean Count</label
                    >
                    <input
                      id="beanCount"
                      type="number"
                      min="0"
                      class="form-control"
                      name="beanCount"
                      v-model="beanCount"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.beanCount.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.beanCount.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.beanCount.required"
                        >Bean Count is required</span
                      >
                      <span v-if="!$v.beanCount.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Moisture Content %</label
                    >
                    <input
                      id="moistureContent"
                      type="number"
                      min="0"
                      class="form-control"
                      name="moistureContent"
                      v-model="moistureContent"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.moistureContent.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.moistureContent.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.moistureContent.required"
                        >Moisture Content % is required</span
                      >
                      <span v-if="!$v.moistureContent.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Total Mould %</label
                    >
                    <input
                      id="totalMould"
                      type="number"
                      min="0"
                      class="form-control"
                      name="totalMould"
                      v-model="totalMould"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.totalMould.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.totalMould.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.totalMould.required"
                        >Total Mould % is required</span
                      >
                      <span v-if="!$v.totalMould.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Insect Damaged %</label
                    >
                    <input
                      id="insectDamaged"
                      type="number"
                      min="0"
                      class="form-control"
                      name="insectDamaged"
                      v-model="insectDamaged"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.insectDamaged.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.insectDamaged.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.insectDamaged.required"
                        >Insect Damaged % is required</span
                      >
                      <span v-if="!$v.insectDamaged.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Total Defect %</label
                    >
                    <input
                      id="totalDefect"
                      type="number"
                      min="0"
                      class="form-control"
                      name="totalDefect"
                      v-model="totalDefect"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.totalDefect.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.totalDefect.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.totalDefect.required"
                        >Total Defect % is required</span
                      >
                      <span v-if="!$v.totalDefect.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Sieving %</label
                    >
                    <input
                      id="sieving"
                      type="number"
                      min="0"
                      class="form-control"
                      name="sieving"
                      v-model="sieving"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.sieving.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.sieving.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.sieving.required"
                        >Sieving % is required</span
                      >
                      <span v-if="!$v.sieving.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Double Cluster %</label
                    >
                    <input
                      id="doubleCluster"
                      type="number"
                      min="0"
                      class="form-control"
                      name="doubleCluster"
                      v-model="doubleCluster"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.doubleCluster.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.doubleCluster.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.doubleCluster.required"
                        >Double Cluster % is required</span
                      >
                      <span v-if="!$v.doubleCluster.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Triple Cluster %</label
                    >
                    <input
                      id="tripleCluster"
                      type="number"
                      min="0"
                      class="form-control"
                      name="tripleCluster"
                      v-model="tripleCluster"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.tripleCluster.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.tripleCluster.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.tripleCluster.required"
                        >Triple Cluster % is required</span
                      >
                      <span v-if="!$v.tripleCluster.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Total Cluster %</label
                    >
                    <input
                      id="totalCluster"
                      type="number"
                      min="0"
                      class="form-control"
                      name="totalCluster"
                      v-model="totalCluster"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.totalCluster.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.totalCluster.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.totalCluster.required"
                        >Total Cluster % is required</span
                      >
                      <span v-if="!$v.totalCluster.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Broken Beans %</label
                    >
                    <input
                      id="brokenBeans"
                      type="number"
                      min="0"
                      class="form-control"
                      name="brokenBeans"
                      v-model="brokenBeans"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.brokenBeans.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.brokenBeans.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.brokenBeans.required"
                        >Broken Beans % is required</span
                      >
                      <span v-if="!$v.brokenBeans.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Fragment %</label
                    >
                    <input
                      id="fragment"
                      type="number"
                      min="0"
                      class="form-control"
                      name="fragment"
                      v-model="fragment"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.fragment.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.fragment.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.fragment.required"
                        >Fragment % is required</span
                      >
                      <span v-if="!$v.fragment.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Total Cocoa Related Matter %</label
                    >
                    <input
                      id="totalCocoaRelatedMatter"
                      type="number"
                      min="0"
                      class="form-control"
                      name="totalCocoaRelatedMatter"
                      v-model="totalCocoaRelatedMatter"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.totalCocoaRelatedMatter.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.totalCocoaRelatedMatter.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.totalCocoaRelatedMatter.required"
                        >Total Cocoa Related Matter % is required</span
                      >
                      <span v-if="!$v.totalCocoaRelatedMatter.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Foreign Matter %</label
                    >
                    <input
                      id="foreignMatter"
                      type="number"
                      min="0"
                      class="form-control"
                      name="foreignMatter"
                      v-model="foreignMatter"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.foreignMatter.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.foreignMatter.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.foreignMatter.required"
                        >Foreign Matter % is required</span
                      >
                      <span v-if="!$v.foreignMatter.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Flat Beans %</label
                    >
                    <input
                      id="flatBeans"
                      type="number"
                      min="0"
                      class="form-control"
                      name="flatBeans"
                      v-model="flatBeans"
                      @keypress="isNum($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.flatBeans.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.flatBeans.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.flatBeans.required"
                        >Flat Beans % is required</span
                      >
                      <span v-if="!$v.flatBeans.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Slate %</label
                    >
                    <input
                      id="slate"
                      type="number"
                      min="0"
                      class="form-control"
                      name="slate"
                      v-model="slate"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.slate.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.slate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.slate.required">Slate % is required</span>
                      <span v-if="!$v.slate.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Age of Before Shipment</label
                    >
                    <input
                      id="ageBeforeShipment"
                      type="number"
                      min="0"
                      class="form-control"
                      name="ageBeforeShipment"
                      v-model="ageBeforeShipment"
                      @keypress="isNum($event)"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.ageBeforeShipment.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.ageBeforeShipment.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.ageBeforeShipment.required"
                        >Age of Before Shipment is required</span
                      >
                      <span v-if="!$v.ageBeforeShipment.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Age of Stock Bal</label
                    >
                    <input
                      id="ageStockBal"
                      type="number"
                      min="0"
                      class="form-control"
                      name="ageStockBal"
                      v-model="ageStockBal"
                      @keypress="isNum($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.ageStockBal.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.ageStockBal.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.ageStockBal.required"
                        >Age of Stock Bal is required</span
                      >
                      <span v-if="!$v.ageStockBal.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label class="required-field">Period</label>
                    <select
                      id="selectedPeriod"
                      class="form-control"
                      name="selectedPeriod"
                      v-model="selectedPeriod"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedPeriod.$error,
                      }"
                    >
                      <option :value="null">-- Select a Period --</option>
                      <option
                        v-for="period in Period"
                        :value="period.period_name"
                      >
                        {{ period.period_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedPeriod.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedPeriod.required"
                        >Period is required</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid" v-if="id">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6">
                  <h1 class="headingt">Quantity Derived After Cleaning</h1>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Net Weight</label
                    >
                    <input
                      type="number"
                      min="0"
                      id="quantityDerivedQty"
                      class="form-control"
                      name="quantityDerivedQty"
                      v-model="quantityDerivedQty"
                      @keypress="isDecimal($event)"
                      @keyup="calculateWaste() & calculateValue(true)"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.quantityDerivedQty.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.quantityDerivedQty.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quantityDerivedQty.required"
                        >Net Weight is required</span
                      >
                      <span v-if="!$v.quantityDerivedQty.maxLength"
                        >Max length of 15 characters exceeded<br
                      /></span>

                      <span
                        v-if="!$v.quantityDerivedQty.quantityDerivedQtyLimit"
                      >
                        Net Weight cannot exceed taken for cleaning limit!
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Gross Weight</label>
                    <input
                      type="number"
                      min="0"
                      id="quantityDerivedGross"
                      class="form-control"
                      name="quantityDerivedGross"
                      v-model="quantityDerivedGross"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.quantityDerivedGross.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.quantityDerivedGross.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quantityDerivedGross.maxLength"
                        >Max length of 15 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Unit</label
                    >
                    <select
                      id="selectedQuantityDerivedUnit"
                      class="form-control"
                      name="selectedQuantityDerivedUnit"
                      v-model="selectedQuantityDerivedUnit"
                      disabled
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedQuantityDerivedUnit.$error,
                      }"
                    >
                      <option :value="null">-- Select --</option>
                      <option v-for="unit in UnitTypes" :value="unit.id">
                        {{ unit.name }}
                      </option>
                    </select>
                    <div
                      v-if="
                        isSubmitted && $v.selectedQuantityDerivedUnit.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedQuantityDerivedUnit.required"
                        >Unit is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >No. of Bags</label
                    >
                    <input
                      id="quantityDerivedBags"
                      class="form-control"
                      name="quantityDerivedBags"
                      v-model="quantityDerivedBags"
                      @keypress="isNum($event)"
                      @keyup="calculateWaste()"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.quantityDerivedBags.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.quantityDerivedBags.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quantityDerivedBags.required"
                        >No. of Bags is required</span
                      >
                      <span v-if="!$v.quantityDerivedBags.maxLength"
                        >Max length of 15 characters exceeded<br
                      /></span>

                      <span
                        v-if="!$v.quantityDerivedBags.quantityDerivedBagsLimit"
                      >
                        No. of Bags cannot exceed taken for cleaning limit!
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1"
                      >Collateral Price (Cleaned)</label
                    >
                    <input
                      type="number"
                      min="0"
                      id="quantityDerivedPrice"
                      class="form-control"
                      name="quantityDerivedPrice"
                      v-model="quantityDerivedPrice"
                      @keypress="isDecimal($event)"
                      @keyup="calculateValue(true)"
                    />
                    <!-- <div
                      v-if="isSubmitted && $v.quantityDerivedPrice.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quantityDerivedPrice.required"
                        >Collateral Price (Cleaned) is required</span
                      ><br />
                      <span v-if="!$v.quantityDerivedPrice.maxLength"
                        >Max length of 15 characters exceeded</span
                      >
                    </div> -->
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1"
                      >Collateral Value (Cleaned)</label
                    >
                    <input
                      id="quantityDerivedValue"
                      class="form-control"
                      name="quantityDerivedValue"
                      v-model="quantityDerivedValue"
                      disabled
                      @keypress="isNum($event)"
                    />
                    <!-- <div
                      v-if="isSubmitted && $v.quantityDerivedValue.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quantityDerivedValue.required"
                        >Collateral Value (Cleaned) is required</span
                      ><br />
                      <span v-if="!$v.quantityDerivedValue.maxLength"
                        >Max length of 15 characters exceeded</span
                      >
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <h1 class="headingt">Waste</h1>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Quantity</label
                    >
                    <input
                      type="number"
                      min="0"
                      id="wasteQty"
                      class="form-control"
                      name="wasteQty"
                      v-model="wasteQty"
                      @keypress="isDecimal($event)"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.wasteQty.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.wasteQty.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.wasteQty.required"
                        >Quantity is required</span
                      >
                      <!-- <span v-if="!$v.wasteQty.maxLength"
                        >Max length of 15 characters exceeded</span
                      > -->
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Unit</label
                    >
                    <select
                      id="selectedWasteUnit"
                      class="form-control"
                      name="selectedWasteUnit"
                      v-model="selectedWasteUnit"
                      disabled
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedWasteUnit.$error,
                      }"
                    >
                      <option :value="null">-- Select --</option>
                      <option v-for="unit in UnitTypes" :value="unit.id">
                        {{ unit.name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedWasteUnit.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedWasteUnit.required"
                        >Unit is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >No. of Bags</label
                    >
                    <input
                      id="wasteBags"
                      class="form-control"
                      name="wasteBags"
                      v-model="wasteBags"
                      @keypress="isNum($event)"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.wasteBags.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.wasteBags.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.wasteBags.required"
                        >No. of Bags is required</span
                      >
                      <!-- <span v-if="!$v.wasteBags.maxLength"
                        >Max length of 15 characters exceeded</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addReceivedDoc()"
                            :disabled="ReceivedDocumentsAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(doc, index) in ReceivedDocuments"
                    :key="index"
                  >
                    <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf"
                            v-on:change="
                              handleFileUploadReceivedDocuments(index, $event)
                            "
                          />
                          <!-- <div
                            v-if="isSubmitted && doc.displayFileName === null"
                            class="invalid-feedback"
                          >
                            <span v-if="doc.displayFileName === null"
                              >Document Name is required</span
                            >
                          </div> -->
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ doc.displayFileName }}</b>
                              <small>
                                <u
                                  style="color: blue; cursor: pointer"
                                  v-if="
                                    doc.ReceivedDocumentsRemoveDisabled ===
                                    false
                                  "
                                  v-on:click="removeReceivedDocumentsDoc(index)"
                                  >Remove</u
                                >
                              </small>
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.errors.filesize">
                              {{ doc.errors.filesize }}
                            </p>
                          </small>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Uploading Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="UploadingDate"
                          id="UploadingDate"
                          placeholder="Select Date"
                          v-model="doc.UploadingDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabledDates="{ from: new Date() }"
                          :calendar-button="true"
                        ></datepicker>
                        <!-- <div
                          v-if="
                            isSubmitted &&
                            $v.ReceivedDocuments.$each[index]
                              .UploadingDate.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ReceivedDocuments.$each[index]
                                .UploadingDate.required
                            "
                            >Uploading Date is required</span
                          >
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @click="receiptValidation(doc.displayFileName)"
                      >
                        <label for="exampleInputEmail1">Receipt No.</label>
                        <input
                          id="receipt_number"
                          class="form-control"
                          name="receipt_number"
                          v-model="doc.receipt_number"
                          @keypress="isAlphaNum($event)"
                          :disabled="doc.displayFileName === null"
                          :class="{
                            'is-invalid':
                              (receiptValidate &&
                                doc.displayFileName === null) ||
                              (ReceivedDocuments[index].receipt_number
                                ? ReceivedDocuments[index].receipt_number
                                    .length > 20
                                : false),
                          }"
                        />
                        <div
                          v-if="doc.displayFileName === null"
                          class="invalid-feedback"
                        >
                          <span v-if="receiptValidate"
                            >Please upload the Document</span
                          >
                        </div>
                        <div
                          v-if="
                            ReceivedDocuments[index].receipt_number
                              ? ReceivedDocuments[index].receipt_number.length >
                                20
                              : false
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              ReceivedDocuments[index].receipt_number
                                ? ReceivedDocuments[index].receipt_number
                                    .length > 20
                                : false
                            "
                            >Max length of 20 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          id="docDelete"
                          v-on:click="deleteReceivedDocuments(index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onReceivedDocumentSaveInfo()"
                        :disabled="isDisabledReceivedDocumentSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    id="submit"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a
                    @click="
                      id
                        ? $router.push({
                            name: 'Collateral',
                            params: { underProcess: true },
                          })
                        : $router.go(-1)
                    "
                    style="cursor: pointer"
                  >
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: null,
      requestId: null,
      utilId: null,
      borrowerName: null,
      borrowerId: null,
      requestDate: null,
      date: new Date(),
      selectedCollateral: null,
      collaterals: [],
      collateral_manager_id: null,
      warrantRef: null,
      collateralQty: null,
      collateralPrice: null,
      collateralValue: null,
      grossWeight: null,
      UnitTypes: [],
      Period: [],
      bags: null,
      grnNo: null,
      selectedUnit: null,

      //quality params
      beanCount: null,
      moistureContent: null,
      totalMould: null,
      insectDamaged: null,
      totalDefect: null,
      sieving: null,
      doubleCluster: null,
      tripleCluster: null,
      totalCluster: null,
      brokenBeans: null,
      fragment: null,
      totalCocoaRelatedMatter: null,
      foreignMatter: null,
      flatBeans: null,
      slate: null,
      ageBeforeShipment: null,
      ageStockBal: null,
      selectedPeriod: null,
      quantityLimit: null,

      //cleaned received info details
      quantityDerivedQty: null,
      selectedQuantityDerivedUnit: null,
      quantityDerivedGross: null,
      quantityDerivedBags: null,
      quantityDerivedPrice: null,
      quantityDerivedValue: null,
      wasteQty: null,
      selectedWasteUnit: null,
      wasteBags: null,

      //list for Received Documents
      ReceivedDocuments: [],
      ReceivedDocumentsFile: null,
      // ReceivedDocumentsButtonDisabled: true,
      ReceivedDocumentsDisabled: true,
      ReceivedDocumentsSaveInfoDisabled: true, //save info button is disabled
      ReceivedDocumentsAddMoreDisabled: true,
      msg: [],
      error: "",
      isSubmitted: false,
      onSubmitDisabled: false,
      receiptValidate: false,
    };
  },
  validations: {
    requestId: {
      required,
    },
    utilId: {
      required,
    },
    borrowerName: {
      required,
    },
    date: {
      required,
    },
    selectedCollateral: {
      required,
    },
    warrantRef: {
      required,
      maxLength: maxLength(25),
    },
    collateralQty: {
      required,
      collateralQtyLimit: function (value) {
        let containsCollateralQtyLimit = true;
        if (!this.id) {
          containsCollateralQtyLimit =
            Number(value) <= Number(this.quantityLimit);
        }
        return containsCollateralQtyLimit;
      },
      maxLength: maxLength(15),
    },
    collateralPrice: {
      required,
      maxLength: maxLength(15),
    },
    collateralValue: {
      required,
      maxLength: maxLength(15),
    },
    grossWeight: {
      required,
      maxLength: maxLength(15),
    },

    selectedUnit: {
      required,
    },
    bags: {
      required,
      maxLength: maxLength(15),
    },
    grnNo: {
      required,
      maxLength: maxLength(15),
    },
    beanCount: {
      required,
      maxLength: maxLength(10),
    },
    moistureContent: {
      required,
      maxLength: maxLength(10),
    },
    totalMould: {
      required,
      maxLength: maxLength(10),
    },
    insectDamaged: {
      required,
      maxLength: maxLength(10),
    },
    totalDefect: {
      required,
      maxLength: maxLength(10),
    },
    sieving: {
      required,
      maxLength: maxLength(10),
    },
    doubleCluster: {
      required,
      maxLength: maxLength(10),
    },
    tripleCluster: {
      required,
      maxLength: maxLength(10),
    },
    totalCluster: {
      required,
      maxLength: maxLength(10),
    },
    brokenBeans: {
      required,
      maxLength: maxLength(10),
    },
    fragment: {
      required,
      maxLength: maxLength(10),
    },
    totalCocoaRelatedMatter: {
      required,
      maxLength: maxLength(10),
    },
    foreignMatter: {
      required,
      maxLength: maxLength(10),
    },
    flatBeans: {
      required,
      maxLength: maxLength(10),
    },
    slate: {
      required,
      maxLength: maxLength(10),
    },
    ageBeforeShipment: {
      required,
      maxLength: maxLength(10),
    },
    ageStockBal: {
      required,
      maxLength: maxLength(10),
    },
    selectedPeriod: {
      required,
    },
    quantityDerivedQty: {
      required: function () {
        if (this.id && this.quantityDerivedQty === null) {
          return false;
        } else {
          return true;
        }
      },
      quantityDerivedQtyLimit: function (value) {
        let containsCollateralQtyLimit = true;
        if (this.id) {
          containsCollateralQtyLimit =
            Number(value) <= Number(this.collateralQty);
        }
        return containsCollateralQtyLimit;
      },
      maxLength: maxLength(15),
    },
    quantityDerivedGross: {
      maxLength: maxLength(15),
    },
    // quantityDerivedPrice: {
    //   required: function () {
    //     if (this.id && this.quantityDerivedPrice === null) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   },
    // },
    // quantityDerivedValue: {
    //   required: function () {
    //     if (this.id && this.quantityDerivedValue === null) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   },
    // },
    quantityDerivedBags: {
      required: function () {
        if (this.id && this.quantityDerivedBags === null) {
          return false;
        } else {
          return true;
        }
      },
      quantityDerivedBagsLimit: function (value) {
        let containsBagsLimit = true;
        if (this.id) {
          containsBagsLimit = Number(value) <= Number(this.bags);
        }
        return containsBagsLimit;
      },
      maxLength: maxLength(15),
    },
    selectedQuantityDerivedUnit: {
      required: function () {
        if (this.id && this.selectedQuantityDerivedUnit === null) {
          return false;
        } else {
          return true;
        }
      },
    },
    wasteQty: {
      required: function () {
        if (this.id && this.wasteQty === null) {
          return false;
        } else {
          return true;
        }
      },
    },
    wasteBags: {
      required: function () {
        if (this.id && this.wasteBags === null) {
          return false;
        } else {
          return true;
        }
      },
    },
    selectedWasteUnit: {
      required: function () {
        if (this.id && this.selectedWasteUnit === null) {
          return false;
        } else {
          return true;
        }
      },
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },

    //this is to computed disabled property of ReceivedDocuments Save Info button.
    isDisabledReceivedDocumentSaveInfo: function () {
      let checkEmptyUploadingDate = this.ReceivedDocuments.filter(
        (line) => line.UploadingDate === null
      );
      //if the line is empty then disable save info button
      if (
        this.ReceivedDocuments.length === 0 &&
        this.ReceivedDocumentsSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyUploadingDate.length >= 1 &&
        this.ReceivedDocuments.length > 0
      ) {
        this.ReceivedDocumentsDisabled = true;
      } else {
        this.ReceivedDocumentsDisabled = false;
      }
      //ReceivedDocumentsDisabled variable is to check whther all input fields except file upload are filled. ReceivedDocumentsFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.ReceivedDocumentsDisabled === true ||
        this.ReceivedDocumentsFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledReceivedDocumentSaveInfo() close
  },
  components: {
    Datepicker,
  },
  methods: {
    calculateValue(cleaned) {
      if (cleaned)
        this.quantityDerivedValue = (
          this.quantityDerivedQty * this.quantityDerivedPrice
        ).toFixed(2);
      else
        this.collateralValue = (
          this.collateralQty * this.collateralPrice
        ).toFixed(2);
    },
    // This function is used to calculate waste quantity and bags
    calculateWaste() {
      this.wasteQty = (this.collateralQty - this.quantityDerivedQty).toFixed(2);
      this.wasteBags = this.bags - this.quantityDerivedBags;
    },
    // This function is used to validate null values in receipt no. field
    receiptValidation(docName) {
      this.receiptValidate = docName === null ? true : false;
    },
    isAlphaNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },

    //this method will make disabled property as false for Received Save Info
    onReceivedDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.ReceivedDocumentsSaveInfoDisabled = false;
      this.ReceivedDocumentsAddMoreDisabled = false;
    },

    //this function deletes ReceivedDocument record
    deleteReceivedDocuments(index) {
      this.ReceivedDocuments.splice(index, 1);
      if (
        typeof this.ReceivedDocuments !== "undefined" &&
        this.ReceivedDocuments.length == 0
      ) {
        this.ReceivedDocumentsAddMoreDisabled = false;
        this.ReceivedDocumentsSaveInfoDisabled = true;
      }
    },

    //this method will submit the Received form in backend.
    onSubmit() {
      this.$confirm({
        auth: false,
        message: "Confirm Submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            this.isSubmitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
              return;
            }
            if (this.date != null) {
              this.date = moment(this.date).format("MM-DD-YYYY");
            }
            for (let i = 0; i < this.ReceivedDocuments.length; i++) {
              // if(this.ReceivedDocuments[i].receipt_number.length>20)
              //   return
              if (this.ReceivedDocuments[i].displayFileName === null) {
                this.ReceivedDocuments.splice(i, 1);
              } else {
                if (this.ReceivedDocuments[i].UploadingDate !== null) {
                  this.ReceivedDocuments[i].UploadingDate = moment(
                    this.ReceivedDocuments[i].UploadingDate
                  ).format("MM-DD-YYYY");
                }
              }
            }
            //initialize the form data
            let formData = new FormData();
            formData.append("requestID", this.requestId);
            formData.append("utilisationId", this.utilId);
            formData.append("BorrowerID", this.borrowerId);
            formData.append("receivedDate", this.date);
            formData.append("CollateralID", this.selectedCollateral);
            formData.append("CollateralManagerID", this.collateral_manager_id);
            formData.append("collateralQuantity", this.collateralQty);
            formData.append("collateralPrice", this.collateralPrice);
            formData.append("collateralValue", this.collateralValue);
            formData.append("grossWeight", this.grossWeight);
            formData.append("numberBags", this.bags);
            formData.append("grnNumber", this.grnNo);
            formData.append("WarrantRef", this.warrantRef);
            formData.append("unit", this.selectedUnit);
            //quality params
            formData.append("beanCount", this.beanCount);
            formData.append("moistureContent", this.moistureContent);
            formData.append("totalMould", this.totalMould);
            formData.append("insectDamaged", this.insectDamaged);
            formData.append("totalDefect", this.totalDefect);
            formData.append("sieving", this.sieving);
            formData.append("doubleCluster", this.doubleCluster);
            formData.append("tripleCluster", this.tripleCluster);
            formData.append("totalCluster", this.totalCluster);
            formData.append("brokenBeans", this.brokenBeans);
            formData.append("fragment", this.fragment);
            formData.append(
              "totalCocoaRelatedMatter",
              this.totalCocoaRelatedMatter
            );
            formData.append("foreignMatter", this.foreignMatter);
            formData.append("flatBeans", this.flatBeans);
            formData.append("slate", this.slate);
            formData.append("ageOfBeforeShipment", this.ageBeforeShipment);
            formData.append("ageOfStockBalance", this.ageStockBal);
            formData.append("period", this.selectedPeriod);
            formData.append(
              "clientId",
              JSON.stringify(this.$store.state.client_id)
            );
            formData.append("userId", this.$store.getters.getuserId);
            //stored data in formData according to Cleaned condition
            if (this.id) {
              formData.append(
                "quantityDerivedAfterCleaning",
                this.quantityDerivedQty
              );
              formData.append(
                "grossWeightAfterCleaning",
                this.quantityDerivedGross
              );
              formData.append("cleanedUnit", this.selectedQuantityDerivedUnit);
              formData.append(
                "numberBagsAfterCleaning",
                this.quantityDerivedBags
              );
              formData.append("priceAfterCleaning", this.quantityDerivedPrice);
              formData.append("valueAfterCleaning", this.quantityDerivedValue);
              formData.append("wasteQuantity", this.wasteQty);
              formData.append("wasteUnit", this.selectedWasteUnit);
              formData.append("numberBagsWaste", this.wasteBags);
              formData.append(
                "ReceivedCleaningDocuments",
                JSON.stringify(this.ReceivedDocuments)
              );
              formData.append("moduleID", 7);
              formData.append("tabID", 23);
            } else {
              formData.append(
                "ReceivedDocuments",
                JSON.stringify(this.ReceivedDocuments)
              );
              formData.append("moduleID", 6);
              formData.append("tabID", 21);
            }

            if (this.id) {
              const path =
                "add-received-cleaned/" +
                this.requestId +
                "/" +
                this.$route.params.cp_id;
              Utils.start_loading();
              axios
                .post(path, formData, {
                  headers: {
                    enctype: "multipart/form-data",
                  },
                })
                .then((response) => {
                  Utils.stop_loading();
                  console.log("status: " + response.data.status);
                  if (response.data.status == "success") {
                    //Status Update API
                    const path =
                      "cleaning-process-summary/" +
                      null +
                      "/" +
                      null +
                      "/" +
                      null +
                      "/" +
                      null;
                    Utils.start_loading();
                    axios
                      .put(
                        path,
                        {
                          updateType: "status",
                          filtertype: "CleaningProcess",
                          updatedStatus: this.status,
                          id: this.$route.params.cp_id,
                          requestID: this.requestId,
                          clientId: this.$store.state.client_id,
                          userId: this.$store.getters.getuserId,
                          moduleID: 7,
                          tabID: 22,
                        },
                        {
                          headers: {
                            "Content-type": "application/json",
                          },
                        }
                      )
                      .then((res) => {
                        Utils.stop_loading();
                        console.log("Update status: " + res.data.status);
                        if (res.data.status == "success") {
                          localStorage.setItem(
                            "collateralTab",
                            "custom-tabs-one-inventory-tab"
                          );
                          this.$router.push({
                            name: "Collateral",
                            params: { underProcess: true },
                          });
                          Utils.make_alert(
                            "success",
                            "Cleaned Received Info Added Successfully!"
                          );
                        } else {
                          Utils.make_alert(
                            "warning",
                            "Something went wrong from server in status update!"
                          );
                        }
                      })
                      .catch((error) => {
                        Utils.stop_loading();
                        console.error("error: " + error);
                        Utils.make_alert(
                          "warning",
                          "Something went wrong in status update!"
                        );
                      });
                  } else {
                    Utils.make_alert("warning", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.error(error);
                  Utils.make_alert("warning", "Something went wrong!");
                });
            } else {
              const path = "add-received-uncleaned";
              Utils.start_loading();
              axios
                .post(path, formData, {
                  headers: {
                    enctype: "multipart/form-data",
                  },
                })
                .then((response) => {
                  Utils.stop_loading();
                  console.log("status: " + response.data.status);
                  if (response.data.status == "success") {
                    Utils.make_alert(
                      "success",
                      "Received Info added successfully!"
                    );
                    console.log("SUCCESS!!");
                    localStorage.setItem(
                      "collateralTab",
                      "custom-tabs-one-inventory-tab"
                    );
                    this.$router.push("/collateral");
                  } else {
                    Utils.make_alert("warning", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.error(error);
                  Utils.make_alert("warning", "Something went wrong!");
                });
            }
          }
        },
      });
    }, //onSubmit() close

    //this method will add new line for additional Collateral Documents
    addReceivedDoc() {
      //when new line is added then ReceivedDocumentsFile variable and ReceivedDocumentsSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.ReceivedDocumentsFile = null),
        (this.ReceivedDocumentsSaveInfoDisabled = true),
        (this.ReceivedDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.ReceivedDocuments.push({
          id: null,
          Received_document_id: null,
          ReceivedDocName: null,
          UploadingDate: new Date(),
          displayFileName: null,
          errors: { filesize: "", docName: "" },
          ReceivedDocumentsRemoveDisabled: true,
          receipt_number: null,
        });
    }, //addReceivedDoc() close
    //handles a change on the file upload
    handleFileUploadReceivedDocuments(id, e) {
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.ReceivedDocumentsFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.ReceivedDocumentsFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors.filesize = "File size should be less than 100MB";
          self.ReceivedDocuments[id].errors.filesize = JSON.stringify(
            response.errors.filesize
          ).replace(/\"/g, "");
          self.ReceivedDocuments[id].selectedFile = null;
          self.ReceivedDocuments[id].selectedFileName = null;
          self.ReceivedDocuments[id].displayFileName = null;
          self.ReceivedDocuments[id].ReceivedDocumentsRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.ReceivedDocumentsFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.ReceivedDocuments[id].selectedFile = reader.result;
          self.ReceivedDocuments[id].selectedFileName = e.target.files[0].name;
          self.ReceivedDocuments[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.ReceivedDocuments[id].errors.filesize = "";
          self.ReceivedDocuments[id].ReceivedDocumentsRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadReceivedDocuments() close

    ////this method handles remove doc attachment
    removeReceivedDocumentsDoc(id) {
      this.ReceivedDocumentsFile = null;
      this.ReceivedDocuments[id].selectedFile = null;
      this.ReceivedDocuments[id].selectedFileName = null;
      this.ReceivedDocuments[id].displayFileName = null;
      this.ReceivedDocuments[id].ReceivedDocumentsRemoveDisabled = true;
    },

    getFacilitySetupByBorrower(id) {
      //this function is called to get list of open facilities
      const path = "/facility-setup-borrower/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // console.log(res.data.Facility_Setup);
          this.facilities = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLoan(id) {
      //this function is called to get list of loans
      const path = "/get-loan/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // console.log(res.data.Facility_Setup);
          this.loans = res.data.Loans;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of benchmarks
    getUnitTypes() {
      const path = "unit_types";
      axios
        .get(path)
        .then((res) => {
          this.UnitTypes = res.data.UnitTypes;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCollateral() {
      //this function is called to get list of Collateral
      const path = "collateral";
      axios
        .get(path)
        .then((res) => {
          this.collaterals = res.data.collateral;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getPeriod() {
      //this function is called to get list of Period
      const path = "period-master";
      axios
        .get(path)
        .then((res) => {
          this.Period = res.data.periods;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getUncleanedDetails() {
      //this function is called on page load and created events. it will show details on Uncleaned CleaningProcess Info
      const path =
        "received-info-uncleaned-details/" +
        this.id +
        "/" +
        this.$route.params.cp_id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.requestId = res.data.received_info_id_details[0]["request_id"];
            this.utilId =
              res.data.received_info_id_details[0]["loan_registration_id"];
            this.borrowerName =
              res.data.received_info_id_details[0]["borrower_name"];
            this.borrowerId =
              res.data.received_info_id_details[0]["borrower_id"];
            this.collateralQty =
              res.data.received_info_id_details[0]["collateral_quantity"];
            this.collateralPrice =
              res.data.received_info_id_details[0]["collateral_price"];
            this.calculateValue();
            this.selectedCollateral =
              res.data.received_info_id_details[0]["collateral_id"];
            this.collateralName =
              res.data.received_info_id_details[0]["collateral_name"];
            this.warrantRef =
              res.data.received_info_id_details[0]["warrant_reference"];
            this.selectedUnit = res.data.received_info_id_details[0]["unit"];
            this.selectedQuantityDerivedUnit =
              res.data.received_info_id_details[0]["unit"];
            this.selectedWasteUnit =
              res.data.received_info_id_details[0]["unit"];
            this.grossWeight =
              res.data.received_info_id_details[0]["gross_weight"];
            this.bags = res.data.received_info_id_details[0]["number_of_bags"];
            this.collateralManagerId =
              res.data.received_info_id_details[0]["collateral_manager_id"];
            this.grnNo = res.data.received_info_id_details[0]["grn"];
            this.beanCount = res.data.received_info_id_details[0]["bean_count"];
            this.moistureContent =
              res.data.received_info_id_details[0]["moisture_content"];
            this.totalMould =
              res.data.received_info_id_details[0]["total_mould"];
            this.beanCount = res.data.received_info_id_details[0]["bean_count"];
            this.insectDamaged =
              res.data.received_info_id_details[0]["insect_damaged"];
            this.totalDefect =
              res.data.received_info_id_details[0]["total_defect"];
            this.sieving = res.data.received_info_id_details[0]["sieving"];
            this.doubleCluster =
              res.data.received_info_id_details[0]["double_cluster"];
            this.tripleCluster =
              res.data.received_info_id_details[0]["triple_cluster"];
            this.totalCluster =
              res.data.received_info_id_details[0]["total_cluster"];
            this.brokenBeans =
              res.data.received_info_id_details[0]["broken_beans"];
            this.fragment = res.data.received_info_id_details[0]["fragment"];
            this.totalCocoaRelatedMatter =
              res.data.received_info_id_details[0][
                "total_cocoa_related_matter"
              ];
            this.foreignMatter =
              res.data.received_info_id_details[0]["foreign_matter"];
            this.flatBeans = res.data.received_info_id_details[0]["flat_beans"];
            this.slate = res.data.received_info_id_details[0]["slate"];
            this.ageBeforeShipment =
              res.data.received_info_id_details[0]["age_of_before_shipment"];
            this.ageStockBal =
              res.data.received_info_id_details[0]["age_of_stock_balance"];
            this.selectedPeriod =
              res.data.received_info_id_details[0]["period"];
            this.collateral_manager_id =
              res.data.received_info_id_details[0]["collateral_manager_id"];
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getUncleanedDetails close
  },

  mounted() {
    this.addReceivedDoc();
  },

  created() {
    console.log("manager", this.$route.params);
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.status = this.$route.params.status;
      this.requestDate = this.$route.params.received_date;
      this.getUncleanedDetails();
    } else {
      if (
        !this.$route.params.collateral_manager_id ||
        this.$route.params.collateral_manager_id === "-"
      )
        this.$router.go(-1);
      this.requestId = this.$route.params.release_id;
      this.utilId = this.$route.params.util_id;
      this.borrowerName = this.$route.params.borrower_name;
      this.borrowerId = this.$route.params.borrower_id;
      this.requestDate = this.$route.params.request_date;
      this.collateral_manager_id = this.$route.params.collateral_manager_id;
      this.quantityLimit = this.$route.params.quantity;
    }
    this.getCollateral();
    this.getUnitTypes();
    this.getPeriod();
  },
};
</script>

<style scoped>
.error {
  color: red;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -70px;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>
