var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('nav-bar'),_c('aside-bar'),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid tabtop pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-6"},[_c('ul',{attrs:{"id":"bck_btn"}},[_c('li',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"icofont-caret-left text-dark"}),_c('span',[_vm._v("Back")])])])])])])])])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card-body br_overview"},[_c('table',{staticClass:"table table-hover table-head-fixed text-nowrap"},[_c('tbody',[_c('tr',[_c('td',{staticClass:"wg",attrs:{"id":"requestId"}},[_vm._v("Request ID:")]),(_vm.request.request_id !== null)?_c('td',[_vm._v(" "+_vm._s(_vm.request.request_id)+" ")]):_c('td',[_vm._v("-")])]),_c('tr',[_c('td',{staticClass:"wg",attrs:{"id":"utilId"}},[_vm._v("Utilisation ID:")]),(_vm.request.loan_master_id !== null)?_c('td',[_vm._v(" "+_vm._s(_vm.request.loan_master_id)+" ")]):_c('td',[_vm._v("-")])]),_c('tr',[_c('td',{staticClass:"wg",attrs:{"id":"borrowerName"}},[_vm._v("Borrower Name:")]),(_vm.request.borrower_name !== null)?_c('td',[_vm._v(" "+_vm._s(_vm.request.borrower_name)+" ")]):_c('td',[_vm._v("-")])]),_c('tr',[_c('td',{staticClass:"wg",attrs:{"id":"collateralManagerName"}},[_vm._v(" Collateral Manager Name: ")]),(
                        _vm.request.collateral_manager_name !== null &&
                        _vm.request.status === 'Approved'
                      )?_c('td',[_vm._v(" "+_vm._s(_vm.request.collateral_manager_name)+" ")]):_c('td',[_vm._v("-")])])])])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card-body br_overview"},[_c('table',{staticClass:"table table-hover"},[_c('tbody',[_c('tr',[_c('td',{staticClass:"wg",attrs:{"id":"offtakerName"}},[_vm._v("Offtaker Name:")]),(_vm.request.offtaker_name !== null)?_c('td',[_vm._v(" "+_vm._s(_vm.request.offtaker_name)+" ")]):_c('td',[_vm._v("-")])]),_c('tr',[_c('td',{staticClass:"wg",attrs:{"id":"requestDate"}},[_vm._v("Request Date:")]),(_vm.request.request_date !== null)?_c('td',[_vm._v(" "+_vm._s(_vm.request.request_date)+" ")]):_c('td',[_vm._v("-")])]),_c('tr',[_c('td',{staticClass:"wg",attrs:{"id":"collateralQuantity"}},[_vm._v(" Collateral Qty: ")]),(
                        _vm.request.release_quantity !== null ||
                        _vm.request.verification_quantity != null
                      )?_c('td',[_vm._v(" "+_vm._s(_vm.request.release_quantity || _vm.request.verification_quantity)+" "+_vm._s(_vm.request.unitname)+" ")]):_c('td',[_vm._v("-")])])])])])])])]),_c('div',{staticClass:"container-fluid ot_tb pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_vm._m(1),_c('div',{staticClass:"card-body table-responsive p-0"},[_c('table',{staticClass:"table table-head-fixed text-nowrap"},[_c('thead',[_c('tr',[_c('th'),_c('th',{attrs:{"id":"srNo"}},[_vm._v("Sr. no.")]),_c('th',{attrs:{"id":"doc"}},[_vm._v("Documents")]),_c('th',{staticClass:"c",attrs:{"id":"uploadDate"}},[_vm._v("Uploading Date")]),_c('th',{staticClass:"c",attrs:{"id":"status"}},[_vm._v("Status")]),(
                          _vm.loggedInUserGroupId == 'Analyst' ||
                          (_vm.loggedInUserGroupId == 'Admin' &&
                            _vm.loggedInUserSubGroupId == 0)
                        )?_c('th',{staticClass:"c"},[_vm._v(" Change Status ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.releaseDocuments),function(doc,index){return _c('tr',{key:doc.id},[_c('td',{staticClass:"center",style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.downloadDocument(doc.document_path)}}},[_c('i',{staticClass:"icofont-download text-info"})]),(
                          doc.ReleaseDocName !== null ||
                          doc.VerificationDocName !== null
                        )?_c('td',[_vm._v(" "+_vm._s(index + 1)+" ")]):_c('td',{staticClass:"c"},[_vm._v("-")]),(
                          doc.ReleaseDocName !== null ||
                          doc.VerificationDocName !== null
                        )?_c('td',[_vm._v(" "+_vm._s(doc.ReleaseDocName || doc.VerificationDocName)+" ")]):_c('td',{staticClass:"c"},[_vm._v("-")]),(
                          doc.ReleaseDocumentsDate !== null ||
                          doc.VerificationDocumentsDate !== null
                        )?_c('td',{staticClass:"c"},[_vm._v(" "+_vm._s(doc.ReleaseDocumentsDate || doc.VerificationDocumentsDate)+" ")]):_c('td',{staticClass:"c"},[_vm._v("-")]),(doc.document_status !== null)?_c('td',{class:{
                          'c text-success':
                            doc.document_status === 'Approved',
                          'c text-warning':
                            doc.document_status === 'Received',
                          'c text-danger': doc.document_status === 'Rejected',
                          'c text-info':
                            doc.document_status === 'Near to Expiry',
                          'c text-dark': doc.document_status === 'Expired',
                        }},[_vm._v(" "+_vm._s(doc.document_status === "Received" ? _vm.loggedInUserGroupId === "Borrower" ? "Uploaded" : doc.document_status : doc.document_status)+" ")]):_c('td',{staticClass:"c"},[_vm._v("-")]),(
                          _vm.loggedInUserGroupId == 'Analyst' ||
                          (_vm.loggedInUserGroupId == 'Admin' &&
                            _vm.loggedInUserSubGroupId == 0)
                        )?_c('td',{staticClass:"c status"},[_c('div',{staticClass:"status"},[_c('select',{staticClass:"form-control",attrs:{"id":"docStatus","name":"docStatus","disabled":doc.document_status !== 'Received'},domProps:{"value":doc.document_status},on:{"change":function($event){return _vm.onChangeDocStatus(
                                $event,
                                doc.release_document_id ||
                                  doc.verification_document_id,
                                doc.document_status,
                                index,
                                'RequestDocStatus'
                              )}}},[_c('option',{attrs:{"value":"Received"}},[_vm._v("Received")]),_c('option',{attrs:{"value":"Approved"}},[_vm._v("Approved")]),_c('option',{attrs:{"value":"Rejected"}},[_vm._v("Rejected")]),(doc.document_status === 'Near to Expiry')?_c('option',{attrs:{"value":"Near to Expiry"}},[_vm._v(" Near to Expiry ")]):_vm._e(),(doc.document_status === 'Expired')?_c('option',{attrs:{"value":"Expired"}},[_vm._v(" Expired ")]):_vm._e()])])]):_vm._e()])}),0)])])])])])])])]),_vm._m(2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"headingt",attrs:{"id":"pageHeading"}},[_c('i',{staticClass:"fas fa-tachometer-alt"}),_vm._v(" Request Overview ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('h1',{staticClass:"headingt"},[_vm._v("Documents")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"view_doc"}},[_c('div',{staticClass:"modal-dialog modal-md-8"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h4',{staticClass:"modal-title"},[_c('i',{staticClass:"icofont-eye-alt"}),_vm._v(" View Document ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"}},[_c('i',{staticClass:"icofont-close-circled"})])]),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"mx-auto"})])])])])}]

export { render, staticRenderFns }