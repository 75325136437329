<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop" id="al_tb">
            <div class="tp_mid">
              <div class="row">
                <div class="col-lg-10">
                  <ul
                    class="nav nav-tabs"
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="pill"
                        style="cursor: default !important;"
                        >{{ selectedBorrowerName }}</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="col-lg-2">
                  <ul id="bck_btn">
                    <li>
                      <a @click="$router.go(-1)" style="cursor: pointer">
                        <i class="icofont-caret-left text-dark"></i>
                        <span>Back</span>
                      </a>
                    </li>
                  </ul>
                  <!-- /.col -->
                </div>
                <!-- /.col -->
              </div>
            </div>
          </div>

          <div class="container-fluid tabtop" id="al_tb">
            <div class="tp_mid">
              <div class="row">
                <div class="col-lg-12">
                  <ul
                    class="nav nav-tabs"
                    id="custom-tabs-one-tab"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="custom-tabs-one-home-tab"
                        data-toggle="pill"
                        href="#one"
                        role="tab"
                        aria-controls="custom-tabs-one-home"
                        aria-selected="true"
                        >Utilisation Request</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="custom-tabs-one-profile-tab"
                        data-toggle="pill"
                        href="#two"
                        role="tab"
                        aria-controls="custom-tabs-one-profile"
                        aria-selected="false"
                        >Drawdown</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="custom-tabs-one-profile-tab"
                        data-toggle="pill"
                        href="#three"
                        role="tab"
                        aria-controls="custom-tabs-one-profile"
                        aria-selected="false"
                        >Repayment</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="custom-tabs-one-profile-tab"
                        data-toggle="pill"
                        href="#four"
                        role="tab"
                        aria-controls="custom-tabs-one-profile"
                        aria-selected="false"
                        >Costs</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-content" id="custom-tabs-one-tabContent">
            <div
              class="tab-pane fade show active"
              id="one"
              role="tabpanel"
              aria-labelledby="cost1"
            >
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-6 col-sm-5 col-6">
                    <div class="form-group fc">
                      <div class="form-field">
                        <label
                          style="margin-right: 10px"
                          class="exampleInputEmail1"
                          >Filter By:</label
                        >
                        <input type="radio" v-model="filterLoan" value="date" />
                        <label style="margin: 5px; padding-right: 10px"
                          >Date</label
                        >

                        <input type="radio" v-model="filterLoan" value="period" />
                        <label style="margin: 5px; padding-right: 10px"
                          >Period</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedFacilityLoan"
                        >Facility ID</label
                      >
                      <select
                        id="selectedFacilityLoan"
                        class="form-control"
                        v-model="selectedFacilityLoan"
                      >
                        <!-- :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedFacility.$error,
                        }" -->
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="fac in facilities"
                          :value="fac.id"
                          :key="fac.id"
                        >
                          {{ fac.facility_registration_id }}
                        </option>
                      </select>
                      <!-- <div
                        v-if="isSubmitted && $v.selectedFacility.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedFacility.required"
                          >Facility ID is required</span
                        >
                      </div> -->
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6" v-if="filterLoan === 'date'">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">From Date</label>

                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="startDateLoan"
                        placeholder="Select Date"
                        v-model="startDateLoan"
                        :format="$store.state.date_format"
                        calendar-button-icon="fas fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{from: new Date()}"
                      />
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6" v-if="filterLoan === 'date'">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">To Date</label>

                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDateLoan"
                        placeholder="Select Date"
                        v-model="endDateLoan"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :disabledDates="{ to: new Date(startDateLoan - 8640000) , from: new Date() }"
                        :calendar-button="true"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="col-md-2" v-if="filterLoan === 'period'">
                    <div class="form-group fc">
                      <label>Period</label>
                      <select
                        id="selectedPeriodLoan"
                        class="form-control"
                        name="selectedPeriodLoan"
                        v-model="selectedPeriodLoan"
                        @change="onPeriodSelect('Utilisation')"
                        required
                      >
                        <option value="ytd">YTD</option>
                        <option value="mtd">MTD</option>
                        <option value="last_month">Last Month</option>
                        <option value="qtd">QTD</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="last_2_quarters">Last 2 Quarters</option>
                        <option value="last_fy_1">Last 1 FY Year</option>
                        <option value="last_fy_2">Last 2 FY Years</option>
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>

                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="filterLoan === 'period'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">From Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDateLoan"
                        placeholder
                        v-model="startDateLoanPeriod"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabled="true"
                      ></datepicker>
                    </div>
                  </div>

                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="filterLoan === 'period'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">To Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDateLoan"
                        placeholder
                        v-model="endDateLoanPeriod"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabled="true"
                        :disabledDates="{
                          to: new Date(startDateLoanPeriod - 8640000),
                        }"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group fc">
                      <label>Status</label>
                      <select
                        id="selectedStatus"
                        class="form-control"
                        name="selectedStatus"
                        v-model="selectedStatus"
                        required
                      >
                        <option value="Open">Open</option>
                        <option value="Closed">Closed</option>
                        <!-- <option value="Pending">Pending</option> -->
                        <!-- <option value="Penalty Due">Penalty Due</option> -->
                        <option value="All">All</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-2 col-12">
                    <ul id="sr_btn">
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh_n mt"
                          v-on:click="getAllLoans('Utilisation')"
                        >
                          Filter
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-dark sh_n mt"
                          v-on:click="resetLoan('Utilisation')"
                        >
                          Reset
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="container-fluid ot_tb pt-3">
                <div class="card card-primary card-tabs">
                  <div class="card-body">
                    <div class="container-fluid" id="al_tb">
                      <div class="tp_mid">
                        <div class="row">
                          <div class="col-lg-6 col-sm-6">
                            <h1 class="headingt">
                              <i class="icofont-pie-chart"></i>
                              Utilisation Request
                            </h1>
                            <!-- <small>**Risk classification:-</small>
                            <small style="color: Green">Low Risk</small>,
                            <small style="color: Orange">Medium Risk</small>,
                            <small style="color: Red">High Risk</small> -->
                          </div>
                          <!-- /.col -->
                          <div class="col-lg-6 col-sm-6">
                            <ul id="sr_btn">
                              <!-- <li v-if="loggedInUserGroupId == 'Admin'">
                                <input
                                  type="checkbox"
                                  id="checkbox"
                                  v-model="inActiveToggle"
                                  @change="destroyTable"
                                />
                                <label for="checkbox"
                                  >InActive&nbsp;&nbsp;</label
                                >
                              </li> -->
                              <!--<li><button  onclick="window.location.href='add_investment.html';" type="button" class="btn btn-sm sh_n btn-success"><i class="icofont-plus"></i><span class="mob">Add Investment</span></button></li>-->
                              <router-link
                                v-if="
                                  !inActiveToggle &&
                                  (loggedInUserGroupId == 'Analyst' ||
                                    (loggedInUserGroupId == 'Admin' &&
                                      loggedInUserSubGroupId == 0))
                                "
                                style="text-decoration: none"
                                :to="{
                                  name: 'AddLoan',
                                  params: {
                                    id: id,
                                    facilityId: facility_id,
                                    type: 'loan',
                                    facRegDate: facRegDate,
                                  },
                                }"
                              >
                                <li>
                                  <button
                                    type="button"
                                    class="btn btn-sm sh_n btn-success"
                                  >
                                    <i class="icofont-plus"></i>
                                    <span class="mob">Add Uti Req</span>
                                  </button>
                                </li>
                              </router-link>
                            </ul>
                          </div>
                          <!-- /.col -->
                          <!--<div class="col-lg-6 col-sm-6">
                            <ul id="sr_btn">
                              <li>
                                <div class="form-group has-search m-0">
                                  <span
                                    class="fa fa-search form-control-feedback"
                                  ></span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search"
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>-->
                          <!-- <div
                            class="col-lg-6 col-sm-6"
                            v-if="loggedInUserGroupId == 'Admin'"
                          >
                            <input
                              id="active"
                              type="radio"
                              :value="false"
                              @change="destroyTable"
                              v-model="inActiveToggle"
                            />
                            <label for="active" style="padding-right: 20px"
                              >Active</label
                            >
                            <input
                              id="inactive"
                              type="radio"
                              :value="true"
                              @change="destroyTable"
                              v-model="inActiveToggle"
                            />
                            <label for="inactive">InActive</label>
                          </div> -->
                          <!-- /.col -->
                        </div>
                      </div>

                      <div
                        class="card-body table-responsive p-0"
                        id="datatable-padding"
                      >
                        <!------menton here style="height: 300px;"-------->
                        <table
                          id="myTable1"
                          class="table table-hover text-nowrap table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Util. ID</th>
                              <!-- <th>Borrower Name</th> -->
                              <th>Facility ID</th>
                              <th>Req. Date</th>
                              <!-- <th class="">Approval Date</th> -->
                              <th class="">Loan Amount</th>
                              <th class="">Drawdown Amount</th>
                              <th class="">Repaid Amount</th>
                              <th class="">Cost Amount</th>
                              <th class="">Outstanding Amount</th>
                              <th class="">Profit Return</th>
                              <th class="">Status</th>
                              <th
                                class="c"
                                v-if="
                                  !inActiveToggle &&
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0
                                "
                              >
                                Change Status
                              </th>

                              <!-- <th class="c">Add Drawdown</th>
                              <th class="c">Add Repay</th>-->
                              <!-- <th
                                class="c"
                                v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"
                              >Add Cost</th>-->
                              <th v-if="!inActiveToggle" class="c center">
                                Report
                              </th>
                              <th
                                class="c"
                                v-if="
                                  !inActiveToggle &&
                                    (loggedInUserGroupId == 'Analyst' ||
                                    (loggedInUserGroupId == 'Admin' &&
                                      loggedInUserSubGroupId == 0))
                                "
                              ></th>
                              <!-- <th
                                class="c"
                                v-if="
                                  !inActiveToggle &&
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0
                                "
                              ></th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(loan, index) in LoansUtiliReq"
                              :key="loan.util_id"
                            >
                              <template>
                                <td
                                  class="ln"
                                >
                                  <!-- :class="{
                                    'c text-success': loan.risk === 3,
                                    'c text-warning': loan.risk === 2,
                                    'c text-danger': loan.risk === 1,
                                  }" -->
                                  <router-link
                                    active-link="active"
                                    :to="{
                                      name: 'LoanUtilisationRequestOverview',
                                      params: {
                                        borrower_id: loan.borrower_id,
                                        id: loan.util_id,
                                        inactive: inActiveToggle,
                                      },
                                    }"
                                    >{{
                                      loan.loan_registration_id
                                    }}</router-link
                                  >
                                </td>
                                <!-- <td>
                                <router-link
                                  active-link="active"
                                  :to="{
                                    name: 'LoanUtilisationRequestOverview',
                                    params: {
                                      borrower_id: loan.borrower_id,
                                      id: loan.loan_registration_id,
                                    },
                                  }"
                                >
                                  <u>{{ loan.borrower_name }}</u>
                                </router-link>
                                </td>-->
                                <td
                                  v-if="loan.facility_registration_id !== null"
                                >
                                  {{ loan.facility_registration_id }}
                                </td>
                                <td v-else>-</td>
                                <td class="" v-if="loan.requested_date !== null">
                                  {{ loan.requested_date }}
                                </td>
                                <td class="" v-else>-</td>
                                <!-- <td class="c left" v-if="loan.approval_date !== null">
                                  {{ loan.approval_date }}
                                </td>
                                <td class="c left" v-else>-</td> -->
                                <td class="c right" v-if="loan.loan_converted_amount !== null">
                                  {{ loan.loan_converted_amount }}
                                </td>
                                <td class="c right" v-else>-</td>
                                <!-- <td
                                  class="c right"
                                  v-if="
                                    loan.requested_amt !== null ||
                                    loan.approved_amount !== null
                                  "
                                >
                                  {{
                                    loan.approved_flag === 1
                                      ? loan.approved_amount
                                      : loan.requested_amt
                                  }}
                                </td>
                                <td class="c right" v-else>-</td> -->
                                <!--<td class="c right" v-if="loan.approved_amount !== null">
                                {{ loan.approved_amount }}
                                </td>
                                <td class="c right" v-else>-</td>-->
                                <td
                                  class="c right"
                                  v-if="loan.drawdown_amount !== null"
                                >
                                  {{
                                    loan.drawdown_amount
                                      
                                  }}
                                </td>
                                <td class="c right" v-else>-</td>

                                <td
                                  class="c right"
                                  v-if="loan.repayment_amount !== null"
                                >
                                  {{ loan.repayment_amount  }}
                                </td>
                                <td class="c right" v-else>-</td>
                                <td
                                  class="c right"
                                  v-if="loan.cost_amount !== null"
                                >
                                  {{ loan.cost_amount  }}
                                </td>
                                <td class="c right" v-else>-</td>
                                <td
                                  class="c right"
                                  v-if="loan.transaction_level_outstanding_amount !== null"
                                >
                                  {{
                                    loan.transaction_level_outstanding_amount 
                                  }}
                                </td>
                                <td class="c right" v-else>-</td>
                                <td
                                  class="c right"
                                  v-if="loan.profit_return_amount !== null"
                                >
                                  {{ loan.profit_return_amount  }}
                                </td>
                                <td class="c right" v-else>-</td>
                                <td
                                  class="c center"
                                  :class="{
                                    'text-success': loan.status === 'Open',
                                    'text-danger': loan.status === 'Closed',
                                    'text-warning': loan.status === 'Pending',
                                    // 'text-info': loan.status === 'Penalty Due',
                                  }"
                                >
                                  {{ loan.status }}
                                </td>
                                <template
                                  v-if="
                                    !inActiveToggle &&
                                    loggedInUserGroupId == 'Admin' &&
                                    loggedInUserSubGroupId == 0
                                  "
                                >
                                  <td class="c center status">
                                    <div class="status">
                                      <select
                                        id="util_status"
                                        name="util_status"
                                        @change="openChangeLoanOverviewStatus($event,index)"
                                        class="form-control"
                                        :value="loanStatuses[loan.util_id]"
                                        :disabled="loan.transaction_level_outstanding_amount !== null || loan.status == 'Closed'"
                                      >
                                        <option value="Open">Open</option>
                                        <!-- <option
                                          v-if="loan.status === 'Pending'"
                                          value="Pending"
                                        >
                                          Pending
                                        </option> -->
                                        <!-- <option
                                          v-if="loan.status === 'Penalty Due'"
                                          value="Penalty Due"
                                        >
                                          Penalty Due
                                        </option> -->
                                        <option value="Closed">
                                          Closed
                                        </option>
                                      </select>
                                    </div>
                                          <!-- @change="
                                            openChangeLoanOverviewStatus(
                                              $event,
                                              loan.util_id,
                                              'LoanStatus',
                                              loan.status,
                                              index,
                                              loan.approved_amount,
                                              loan.requested_amt,
                                              loan.collateral_id,
                                              loan.loan_registration_id
                                            )
                                          " -->
                                  </td>
                                </template>
                                
                                <!-- <td class="c">
                                  <router-link
                                    style="text-decoration: none"
                                    :to="{
                                    name: 'AddDrawdownRequest',
                                    params: {
                                      borrower_id: loan.borrower_id,
                                      facility_id: loan.facility_id,
                                      loan_registration_id: loan.loan_registration_id,
                                    },
                                  }"
                                  >
                                    <i class="icofont-plus text-success"></i>
                                  </router-link>
                                </td>

                                <td class="c">
                                  <router-link
                                    style="text-decoration: none"
                                    :to="{
                                    name: 'AddLoanPayment',
                                    params: {
                                      borrower_id: loan.borrower_id,
                                      loan_registration_id:
                                        loan.loan_registration_id,
                                    },
                                  }"
                                  >
                                    <i class="icofont-plus text-success"></i>
                                  </router-link>
                                </td>

                                <td
                                  class="c"
                                  v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"
                                >
                                  <a style="text-decoration: none" v-on:click="showAddModal()">
                                    <i class="icofont-plus text-success"></i>
                                  </a>
                                </td>-->

                                <td
                                  v-if="!inActiveToggle"
                                  class="c center"
                                  v-on:click="
                                    showReportModal(loan.util_id,loan.requested_date,loan.loan_registration_id)
                                  "
                                  :style="{ cursor: 'pointer' }"
                                >
                                  <i class="icofont-download text-primary"></i>
                                </td>
                                <template v-if="!inActiveToggle &&
                                      (loggedInUserGroupId == 'Analyst' ||
                                        (loggedInUserGroupId == 'Admin' &&
                                          loggedInUserSubGroupId == 0))">
                                  <td
                                    class="c center"
                                    v-if="
                                      loan.status !== 'Closed'
                                    "
                                  >
                                    <router-link
                                      style="text-decoration: none"
                                      :to="{
                                        name: 'EditLoan',
                                        params: {
                                          id: loan.util_id,
                                          type: 'edit_loan',
                                          repaid_amount: loan.repayment_amount,
                                        },
                                      }"
                                    >
                                      <i
                                        class="icofont-ui-edit text-warning"
                                      ></i>
                                    </router-link>
                                  </td>
                                  <td class="c center" v-else>
                                    <i
                                      style="opacity: 0.5"
                                      class="icofont-ui-edit text-warning"
                                    ></i>
                                  </td>
                                  <!-- <template
                                    v-if="
                                      loggedInUserGroupId == 'Admin' &&
                                      loggedInUserSubGroupId == 0
                                    "
                                  >
                                    <td
                                      v-if="loan.status === 'Pending'"
                                      v-on:click="
                                        deleteUtilisationRequest(
                                          loan.util_id,
                                          loan.loan_registration_id
                                        )
                                      "
                                      :style="{ cursor: 'pointer' }"
                                    >
                                      <i class="icofont-trash text-danger"></i>
                                    </td>
                                    <td v-else>
                                      <i
                                        style="opacity: 0.5"
                                        class="icofont-trash text-danger"
                                      ></i>
                                    </td>
                                  </template> -->
                                </template>
                              </template>
                            </tr>
                            <!----------------------------------------------->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <span style="font-size: 14px; color: red; margin-left:10px !important; margin-bottom: 10px !important;">
                      Note: All calculations are displayed in Fund Currency.</span>
                  <!-- /.card -->
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="two"
              role="tabpanel"
              aria-labelledby="covenant1"
            >
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-6 col-sm-5 col-6">
                    <div class="form-group fc">
                      <div class="form-field">
                        <label
                          style="margin-right: 10px"
                          class="exampleInputEmail1"
                          >Filter By:</label
                        >
                        <input type="radio" v-model="filterDisbursement" value="date" />
                        <label style="margin: 5px; padding-right: 10px"
                          >Date</label
                        >

                        <input type="radio" v-model="filterDisbursement" value="period" />
                        <label style="margin: 5px; padding-right: 10px"
                          >Period</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedFacilityDisbursement"
                        >Facility ID</label
                      >
                      <select
                        id="selectedFacilityDisbursement"
                        class="form-control"
                        v-model="selectedFacilityDisbursement"
                      >
                        <!-- :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedFacilityDisbursement.$error,
                        }" -->
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="fac in facilities"
                          :value="fac.id"
                          :key="fac.id"
                        >
                          {{ fac.facility_registration_id }}
                        </option>
                      </select>
                      <!-- <div
                        v-if="isSubmitted && $v.selectedFacility.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedFacility.required"
                          >Facility ID is required</span
                        >
                      </div> -->
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6" v-if="filterDisbursement === 'date'">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">From Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="startDateDisbursement"
                        placeholder="Select Date"
                        v-model="startDateDisbursement"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{from: new Date()}"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6" v-if="filterDisbursement === 'date'">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">To Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDateDisbursement"
                        placeholder="Select Date"
                        v-model="endDateDisbursement"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{ to: new Date(startDateDisbursement - 8640000) , from: new Date() }"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="col-md-2" v-if="filterDisbursement === 'period'">
                    <div class="form-group fc">
                      <label>Period</label>
                      <select
                        id="selectedPeriodDisbursement"
                        class="form-control"
                        name="selectedPeriodDisbursement"
                        v-model="selectedPeriodDisbursement"
                        @change="onPeriodSelect('Drawdown')"
                        required
                      >
                        <option value="ytd">YTD</option>
                        <option value="mtd">MTD</option>
                        <option value="last_month">Last Month</option>
                        <option value="qtd">QTD</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="last_2_quarters">Last 2 Quarters</option>
                        <option value="last_fy_1">Last 1 FY Year</option>
                        <option value="last_fy_2">Last 2 FY Years</option>
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>

                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="filterDisbursement === 'period'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">From Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDateDisbursement"
                        placeholder
                        v-model="startDateDisbursementPeriod"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabled="true"
                        :disabledDates="{from: new Date()}"
                      ></datepicker>
                    </div>
                  </div>

                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="filterDisbursement === 'period'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">To Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDateDisbursement"
                        placeholder
                        v-model="endDateDisbursementPeriod"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabled="true"
                        :disabledDates="{
                          to: new Date(startDateDisbursementPeriod - 8640000),
                        }"
                      ></datepicker>
                    </div>
                  </div>

                  <!-- <div class="col-md-2">
                    <div class="form-group fc">
                      <label>Status</label>
                      <select
                        id="selectedStatus"
                        class="form-control"
                        name="selectedStatus"
                        v-model="selectedStatus"
                        required
                      >
                        <option value="Open">Open</option>
                        <option value="Closed">Closed</option>
                        <option value="All">All</option>
                      </select>
                    </div>
                  </div>-->

                  <div class="col-md-2 col-sm-2 col-12">
                    <ul id="sr_btn">
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh_n mt"
                          v-on:click="getAllLoans('Drawdown')"
                        >
                          Filter
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-dark sh_n mt"
                          v-on:click="resetLoan('Drawdown')"
                        >
                          Reset
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="container-fluid ot_tb pt-3">
                <div class="card card-primary card-tabs">
                  <div class="card-body">
                    <div class="container-fluid" id="al_tb">
                      <div class="tp_mid">
                        <div class="row">
                          <div class="col-lg-6 col-sm-6">
                            <h1 class="headingt">Drawdown Details</h1>
                          </div>
                          <!-- /.col -->
                          <!--<div class="col-lg-6 col-sm-6">
                            <ul id="sr_btn">
                              <li>
                                <div class="form-group has-search m-0">
                                  <span
                                    class="fa fa-search form-control-feedback"
                                  ></span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search"
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>-->
                          <!-- /.col -->
                        </div>
                      </div>

                      <div class="card-body table-responsive p-0">
                        <!------menton here style="height: 300px;"-------->
                        <table
                          id="example"
                          class="table table-head-fixed text-nowrap table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Utilisation ID</th>
                              <!--<th>Borrower Name</th>-->
                              <th>Facility ID</th>
                              <th>Drawdown Date</th>
                              <th>Drawdown Amount</th>
                              <!-- <th>Currency</th> -->
                              <!--<th>Status</th>-->
                              <!--<th
                                v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"
                              >Change Status</th>-->
                            </tr>
                          </thead>

                          <div v-if="!DrawdownReq.length">
                            No items to display
                          </div>
                          <tbody>
                            <tr v-for="ddn in DrawdownReq" :key="ddn.id">
                              <!-- <template
                                v-if="selectedStatus === 'All' || ddn.status === selectedStatus"
                              >-->
                              <td v-if="ddn.loan_registration_id !== null">
                                {{ ddn.loan_registration_id }}
                              </td>
                              <td v-else>-</td>
                              <!--<td v-if="ddn.borrower_name !== null">
                                {{ ddn.borrower_name }}
                              </td>
                              <td v-else>-</td>-->
                              <td v-if="ddn.facility_registration_id !== null">
                                {{ ddn.facility_registration_id }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="ddn.disbursement_date !== null">
                                {{ ddn.disbursement_date }}
                              </td>
                              <td v-else>-</td>
                              <td class="" v-if="ddn.disbursement_amount !== null">
                                {{ ddn.disbursement_amount }}
                              </td>
                              <td class="" v-else>-</td>
                              <!-- <td v-if="ddn.currency_short_name !== null">
                                {{ ddn.currency_short_name }}
                              </td>
                              <td v-else>-</td> -->
                              <!--<td
                                :class="{
                                  'text-success': ddn.status === 'Open',
                                  'text-warning': ddn.status === 'Closed',
                                }"
                              >{{ ddn.status }}</td>-->
                              <!--<template
                                v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"
                              >
                              <td class="c status">
                                <div>
                                  <select
                                    id="docStatus"
                                    name="docStatus"
                                    @change="
                                      onChangeLoanOverviewStatus(
                                        $event,
                                        ddn.loan_registration_id,
                                        ddn.facility_registration_id,
                                        'DrawdownStatus'
                                      )
                                    "
                                    class="form-control"
                                    v-model="ddn.status"
                                    :disabled="ddn.status === 'Closed'"
                                  >
                                    <option :value="null"></option>
                                    <option value="Open">Open</option>
                                    <option value="Closed">Closed</option>
                                  </select>
                                </div>
                              </td>
                              </template>-->
                              <!-- </template> -->
                            </tr>
                            <!----------------------------------------------->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <span v-if="DrawdownReq.length > 0" style="font-size: 14px; color: red; margin-left:10px !important; margin-bottom: 10px !important;">
                      Note: All calculations are displayed in Fund Currency.</span>
                  <!-- /.card -->
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="three"
              role="tabpanel"
              aria-labelledby="loan1"
            >
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-6 col-sm-5 col-6">
                    <div class="form-group fc">
                      <div class="form-field">
                        <label
                          style="margin-right: 10px"
                          class="exampleInputEmail1"
                          >Filter By:</label
                        >
                        <input type="radio" v-model="filterRepayment" value="date" />
                        <label style="margin: 5px; padding-right: 10px"
                          >Date</label
                        >

                        <input type="radio" v-model="filterRepayment" value="period" />
                        <label style="margin: 5px; padding-right: 10px"
                          >Period</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedFacilityRepayment"
                        >Facility ID</label
                      >
                      <select
                        id="selectedFacilityRepayment"
                        class="form-control"
                        v-model="selectedFacilityRepayment"
                      >
                        <!-- :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedFacilityRepayment.$error,
                        }" -->
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="fac in facilities"
                          :value="fac.id"
                          :key="fac.id"
                        >
                          {{ fac.facility_registration_id }}
                        </option>
                      </select>
                      <!-- <div
                        v-if="isSubmitted && $v.selectedFacility.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedFacility.required"
                          >Facility ID is required</span
                        >
                      </div> -->
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6" v-if="filterRepayment === 'date'">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">From Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="startDateRepayment"
                        placeholder="Select Date"
                        v-model="startDateRepayment"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{from: new Date()}"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6" v-if="filterRepayment === 'date'">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">To Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDateRepayment"
                        placeholder="Select Date"
                        v-model="endDateRepayment"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{ to: new Date(startDateRepayment - 8640000) , from: new Date() }"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="col-md-2" v-if="filterRepayment === 'period'">
                    <div class="form-group fc">
                      <label>Period</label>
                      <select
                        id="selectedPeriodRepayment"
                        class="form-control"
                        name="selectedPeriodRepayment"
                        v-model="selectedPeriodRepayment"
                        @change="onPeriodSelect('Repayment')"
                        required
                      >
                        <option value="ytd">YTD</option>
                        <option value="mtd">MTD</option>
                        <option value="last_month">Last Month</option>
                        <option value="qtd">QTD</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="last_2_quarters">Last 2 Quarters</option>
                        <option value="last_fy_1">Last 1 FY Year</option>
                        <option value="last_fy_2">Last 2 FY Years</option>
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>

                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="filterRepayment === 'period'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">From Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDateRepayment"
                        placeholder
                        v-model="startDateRepaymentPeriod"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabled="true"
                      ></datepicker>
                    </div>
                  </div>

                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="filterRepayment === 'period'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">To Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDateRepayment"
                        placeholder
                        v-model="endDateRepaymentPeriod"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabled="true"
                        :disabledDates="{
                          to: new Date(startDateRepaymentPeriod - 8640000),
                        }"
                      ></datepicker>
                    </div>
                  </div>

                  <!-- <div class="col-md-2">
                    <div class="form-group fc">
                      <label>Status</label>
                      <select
                        id="selectedStatus"
                        class="form-control"
                        name="selectedStatus"
                        v-model="selectedStatus"
                        required
                      >
                        <option value="Open">Open</option>
                        <option value="Closed">Closed</option>
                        <option value="All">All</option>
                      </select>
                    </div>
                  </div>-->

                  <div class="col-md-2 col-sm-2 col-12">
                    <ul id="sr_btn">
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh_n mt"
                          v-on:click="getAllLoans('Repayment')"
                        >
                          Filter
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-dark sh_n mt"
                          v-on:click="resetLoan('Repayment')"
                        >
                          Reset
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="container-fluid ot_tb pt-3">
                <div class="card card-primary card-tabs">
                  <div class="card-body">
                    <div class="container-fluid" id="al_tb">
                      <div class="tp_mid">
                        <div class="row">
                          <div class="col-lg-6 col-sm-6">
                            <h1 class="headingt">Repayment Details</h1>
                          </div>
                          <!-- /.col -->
                          <!--<div class="col-lg-6 col-sm-6">
                            <ul id="sr_btn">
                              <li>
                                <div class="form-group has-search m-0">
                                  <span
                                    class="fa fa-search form-control-feedback"
                                  ></span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search"
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>-->
                          <!-- /.col -->
                        </div>
                      </div>

                      <div class="card-body table-responsive p-0">
                        <!------menton here style="height: 300px;"-------->
                        <table
                          id="example"
                          class="table table-head-fixed text-nowrap table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Utilisation ID</th>
                              <th>Facility ID</th>
                              <th>Repayment Date</th>
                              <th>Repaid Amount</th>
                              <!-- <th>Currency</th> -->
                              <!--<th>Repayment Type</th>-->
                              <!--<th>Received from</th>-->
                              <!--<th>Status</th>-->
                              <!--<th
                                v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"
                              >Change Status</th>-->
                            </tr>
                          </thead>

                          <div v-if="!RepaymentDetails.length">
                            No items to display
                          </div>
                          <tbody>
                            <tr
                              v-for="repay in RepaymentDetails"
                              :key="repay.id"
                            >
                              <td v-if="repay.loan_registration_id !== null">
                                {{ repay.loan_registration_id }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="repay.facility_registration_id !== null">
                                {{ repay.facility_registration_id }}
                              </td>
                              <td v-else>-</td>
                              <!--<td v-if="repay.borrower_name !== null">
                                {{ repay.borrower_name }}
                              </td>
                              <td v-else>-</td>-->
                              <td v-if="repay.repayment_date !== null">
                                {{ repay.repayment_date }}
                              </td>
                              <td v-else>-</td>
                              <td class="" v-if="repay.repayment_amount !== null">
                                {{ repay.repayment_amount  }}
                              </td>
                              <td class="" v-else>-</td>
                              <!-- <td v-if="repay.currency_short_name !== null">
                                {{ repay.currency_short_name }}
                              </td>
                              <td v-else>-</td> -->
                              <!--<td v-if="repay.Repayment_type !== null">
                                {{ repay.Repayment_type }}
                              </td>
                              <td v-else>-</td>-->
                              <!--<td v-if="repay.Received_from !== null">
                                {{ repay.Received_from }}
                              </td>
                              <td v-else>-</td>-->
                              <!--<td
                                :class="{
                                  'text-success': repay.status === 'Open',
                                  'text-warning': repay.status === 'Closed',
                                }"
                              >{{ repay.status }}</td>-->
                              <!--<template
                                v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"
                              >
                              <td class="c status">
                                <div>
                                  <select
                                    id="docStatus"
                                    name="docStatus"
                                    @change="
                                      onChangeLoanOverviewStatus(
                                        $event,
                                        repay.loan_registration_id,
                                        repay.facility_registration_id,
                                        'RepaymentStatus'
                                      )
                                    "
                                    class="form-control"
                                    v-model="repay.status"
                                    :disabled="repay.status === 'Closed'"
                                  >
                                    <option :value="null"></option>
                                    <option value="Open">Open</option>
                                    <option value="Closed">Closed</option>
                                  </select>
                                </div>
                              </td>
                              </template>-->
                            </tr>
                            <!----------------------------------------------->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <span v-if="RepaymentDetails.length > 0" style="font-size: 14px; color: red; margin-left:10px !important; margin-bottom: 10px !important;">
                      Note: All calculations are displayed in Fund Currency.</span>
                  <!-- /.card -->
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="four"
              role="tabpanel"
              aria-labelledby="loan1"
            >
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-6 col-sm-5 col-6">
                    <div class="form-group fc">
                      <div class="form-field">
                        <label
                          style="margin-right: 10px"
                          class="exampleInputEmail1"
                          >Filter By:</label
                        >
                        <input type="radio" v-model="filterCost" value="date" />
                        <label style="margin: 5px; padding-right: 10px"
                          >Date</label
                        >

                        <input type="radio" v-model="filterCost" value="period" />
                        <label style="margin: 5px; padding-right: 10px"
                          >Period</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedFacilityCost"
                        >Facility ID</label
                      >
                      <select
                        id="selectedFacilityCost"
                        class="form-control"
                        v-model="selectedFacilityCost"
                      >
                        <!-- :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedFacilityCost.$error,
                        }" -->
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="fac in facilities"
                          :value="fac.id"
                          :key="fac.id"
                        >
                          {{ fac.facility_registration_id }}
                        </option>
                      </select>
                      <!-- <div
                        v-if="isSubmitted && $v.selectedFacility.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedFacility.required"
                          >Facility ID is required</span
                        >
                      </div> -->
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6" v-if="filterCost === 'date'">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">From Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="startDateCost"
                        placeholder="Select Date"
                        v-model="startDateCost"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{from: new Date()}"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6" v-if="filterCost === 'date'">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">To Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDateCost"
                        placeholder="Select Date"
                        v-model="endDateCost"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{ to: new Date(startDateCost - 8640000) , from: new Date()}"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="col-md-2" v-if="filterCost === 'period'">
                    <div class="form-group fc">
                      <label>Period</label>
                      <select
                        id="selectedPeriodCost"
                        class="form-control"
                        name="selectedPeriodCost"
                        v-model="selectedPeriodCost"
                        @change="onPeriodSelect('Cost')"
                        required
                      >
                        <option value="ytd">YTD</option>
                        <option value="mtd">MTD</option>
                        <option value="last_month">Last Month</option>
                        <option value="qtd">QTD</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="last_2_quarters">Last 2 Quarters</option>
                        <option value="last_fy_1">Last 1 FY Year</option>
                        <option value="last_fy_2">Last 2 FY Years</option>
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>

                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="filterCost === 'period'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">From Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDateCost"
                        placeholder
                        v-model="startDateCostPeriod"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabled="true"
                      ></datepicker>
                    </div>
                  </div>

                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="filterCost === 'period'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">To Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDateCost"
                        placeholder
                        v-model="endDateCostPeriod"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabled="true"
                        :disabledDates="{
                          to: new Date(startDateCostPeriod - 8640000),
                        }"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label>Status</label>
                      <select
                        id="selectedCostStatus"
                        class="form-control"
                        name="selectedCostStatus"
                        v-model="selectedCostStatus"
                        required
                      >
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                        <option value="All">All</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-2 col-12">
                    <ul id="sr_btn">
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh_n mt"
                          v-on:click="getAllLoans('Cost')"
                        >
                          Filter
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-dark sh_n mt"
                          v-on:click="resetLoan('Cost')"
                        >
                          Reset
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="container-fluid ot_tb pt-3">
                <div class="card card-primary card-tabs">
                  <div class="card-body">
                    <div class="container-fluid" id="al_tb">
                      <div class="tp_mid">
                        <div class="row">
                          <div class="col-lg-6">
                            <h1 class="headingt">
                              Costs Details
                              <span></span>
                            </h1>
                          </div>
                          <!-- /.col -->
                          <div class="col-lg-6"></div>
                          <!-- /.col -->
                        </div>
                      </div>

                      <div class="card-body table-responsive p-0">
                        <!------menton here style="height: 300px;"-------->
                        <table
                          id="example"
                          class="table table-head-fixed text-nowrap table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Utilisation ID</th>
                              <th>Facility ID</th>
                              <th>Cost ID</th>

                              <th>Cost Date</th>
                              <th>Description</th>
                              <th>Cost Amount</th>
                              <!-- <th>Currency</th> -->
                              <th>Amount Paid</th>
                              <!-- <th>Payment Date</th> -->
                              <th>Outstanding Amount</th>
                              <th>Status</th>
                              <!--<th class="c"></th>-->
                              <!--<th class="c"></th>-->
                            </tr>
                          </thead>

                          <div v-if="!Costs.length">No items to display</div>
                          <tbody>
                            <tr v-for="cost in Costs" :key="cost.id">
                              <template
                              >
                                <!-- v-if="
                                  selectedStatus === 'All' ||
                                  cost.status === selectedStatus
                                " -->
                                <td v-if="cost.loan_registration_id !== null">
                                  {{ cost.loan_registration_id }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="cost.facility_registration_id !== null">
                                  {{ cost.facility_registration_id }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="cost.id !== null">
                                  {{ cost.id }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="cost.expense_date !== null">
                                  {{ cost.expense_date }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="cost.loan_expense_name !== null">
                                  {{ cost.loan_expense_name }}
                                </td>
                                <td v-else>-</td>
                                <td class="" v-if="cost.expense_amount !== null">
                                  {{ cost.expense_amount  }}
                                </td>
                                <td class="" v-else>-</td>
                                <!-- <td v-if="cost.currency_short_name !== null">
                                  {{ cost.currency_short_name }}
                                </td>
                                <td v-else>-</td> -->
                                <td class="" v-if="cost.amount_paid !== null">
                                  {{ cost.amount_paid  }}
                                </td>
                                <td class="" v-else>-</td>
                                <!-- <td v-if="cost.payment_date !== null">
                                  {{ cost.payment_date }}
                                </td>
                                <td v-else>-</td> -->
                                <td class="" v-if="cost.outstanding_amount !== null">
                                  {{
                                    cost.outstanding_amount 
                                  }}
                                </td>
                                <td class="" v-else>-</td>
                                <td
                                  :class="{
                                    'text-success': cost.status === 'Approved',
                                    'text-danger': cost.status === 'Rejected',
                                  }"
                                >
                                  {{ cost.status }}
                                </td>
                              </template>
                            </tr>
                            <!----------------------------------------------->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <span v-if="Costs.length > 0" style="font-size: 14px; color: red; margin-left:10px !important; margin-bottom: 10px !important;">
                      Note: All calculations are displayed in Fund Currency.</span>
                  <!-- /.card -->
                </div>
              </div>
            </div>
          </div>
          <!-- /.Tabpane second end-->
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>

    <div>
      <custom-loan-report-modal ref="customLoanReportModal">
        <template v-slot:header>
              <h4>Download Report</h4>
        </template>
        <div class="row">
              <div class="col-md-6">
                <div class="form-group fc">
                  <label>Select Report</label>
                  <select
                    id="selectedReport"
                    class="form-control"
                    name="selectedReport"
                    v-model="selectedReport"
                    disabled
                  >
                    <option :value="null">--- Select ---</option>
                    <option v-for="report in reports" :key="report.id" :value="report.id">
                      {{ report.report_name }}
                    </option>
                  </select>
                </div>
              </div>
  
              <div class="col-md-6">
                <div class="form-group fc">
                  <label>Period Type</label>
                  <select
                    id="selectedPeriodType"
                    class="form-control"
                    name="selectedPeriodType"
                    v-model="selectedPeriodType"
                  >
                    <option :value="null" disabled>--- Select ---</option>
                    <option v-for="report in report_type" :key="report.id" :value="report.id">
                      {{ report.report_type_name }}
                    </option>
                  </select>
                </div>
              </div>
  
              <div v-if="selectedPeriodType == 0" class="col-md-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field"
                    >Monthly Period</label
                  >
                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="selectedRegularReportDate"
                    placeholder="Select Period"
                    v-model="selectedRegularReportDate"
                    minimum-view="month"
                    format="MM-yyyy"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :disabledDates="{
                      to: new Date(this.backcapLoanReportDate),
                      from: lastMonth,
                    }"
                  ></datepicker>
                </div>
              </div>
  
              <div v-if="selectedPeriodType == 1" class="col-md-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field"
                    >Till Date</label
                  >
                  <datepicker
                    class="custom-width-datepicker1 datepicker"
                    :bootstrap-styling="true"
                    name="selectedCustomReportDate"
                    placeholder="Select Date"
                    v-model="selectedCustomReportDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fas fa-calendar"
                    :calendar-button="true"
                    :disabledDates="{
                      to: new Date(this.backcapLoanReportDate),
                      from: new Date(),
                    }"
                  ></datepicker>
                </div>
              </div>
  
            </div>
        <template v-slot:footer>
          <div class="">
              <button
                style="margin-right: 6px"
                type="button"
                class="btn-sm btn btn-success sh"
                v-on:click="onClickExcel('excel')"
              >
                <i class="icofont-file-excel"></i> Excel
              </button>
              <button
                type="button"
                style="margin-right: 6px"
                class="btn-sm btn btn-danger sh"
                v-on:click="onClickExcel('pdf')"
              >
                <i class="icofont-file-pdf"></i> PDF
              </button>
              <button
                type="button"
                name="saveInfo"
                class="btn-sm btn btn-primary sh"
                style="margin-right: 10px;"
                v-on:click="onCloseReportModal()"
              >
                <i class="icofont-close-circled"></i> Close
              </button>
            </div>
        </template>
      </custom-loan-report-modal>
    </div>

    <!-- ./modal start -->
    <modal name="report">
      <div class="modal-content" style="height: 500px">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Download Report</h4>
          <button
            @click="$modal.hide('report')"
            type="button"
            class="close"
            data-dismiss="modal"
          >
            <i class="icofont-close-circled"></i>
          </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 col-sm-5 col-6">
              <div class="form-group fc">
                <label>Select Report</label>
                <select
                  id="selectedReport"
                  class="form-control"
                  name="selectedReport"
                  v-model="selectedReport"
                  disabled
                >
                  <option :value="null">--- Select ---</option>
                  <option v-for="report in reports" :key="report.id" :value="report.id">
                    {{ report.report_name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-6 col-sm-5 col-6">
              <div class="form-group fc">
                <label>Period Type</label>
                <select
                  id="selectedPeriodType"
                  class="form-control"
                  name="selectedPeriodType"
                  v-model="selectedPeriodType"
                >
                  <option :value="null" disabled>--- Select ---</option>
                  <option v-for="report in report_type" :key="report.id" :value="report.id">
                    {{ report.report_type_name }}
                  </option>
                </select>
              </div>
            </div>

            <div v-if="selectedPeriodType == 0" class="col-md-6 col-sm-5 col-6">
              <div class="form-group fc">
                <label for="exampleInputEmail1" class="required-field"
                  >Monthly Period</label
                >
                <datepicker
                  class="datepicker"
                  :bootstrap-styling="true"
                  name="selectedCustomReportDate"
                  placeholder="Select Period"
                  v-model="selectedCustomReportDate"
                  minimum-view="month"
                  format="MM-yyyy"
                  calendar-button-icon="fa fa-calendar"
                  :calendar-button="true"
                  :disabledDates="{
                    to: new Date(this.backcapLoanReportDate),
                    from: lastMonth,
                  }"
                ></datepicker>
              </div>
            </div>

            <div v-if="selectedPeriodType == 1" class="col-md-6 col-sm-5 col-6">
              <div class="form-group fc">
                <label for="exampleInputEmail1" class="required-field"
                  >Till Date</label
                >
                <datepicker
                  class="custom-width-datepicker datepicker"
                  :bootstrap-styling="true"
                  name="selectedCustomReportDate"
                  placeholder="Select Date"
                  v-model="selectedCustomReportDate"
                  :format="$store.state.date_format"
                  calendar-button-icon="fas fa-calendar"
                  :calendar-button="true"
                  :disabledDates="{
                    to: new Date(this.backcapLoanReportDate),
                    from: new Date(),
                  }"
                ></datepicker>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer" style="padding-top: 80px">
          <div class="mx-auto">
            <button
              style="margin-right: 6px"
              type="button"
              class="btn-sm btn btn-success sh"
              v-on:click="onClickExcel('excel')"
            >
              <i class="icofont-file-excel"></i> Excel
            </button>
            <button
              type="button"
              style="margin-right: 6px"
              class="btn-sm btn btn-danger sh"
              v-on:click="onClickExcel('pdf')"
            >
              <i class="icofont-file-pdf"></i> PDF
            </button>
            <button
              @click="$modal.hide('report')"
              style="margin-right: 6px"
              type="button"
              class="btn-sm btn btn-dark sh"
              data-dismiss="modal"
            >
              <i class="icofont-close-circled"></i> Close
            </button>
          </div>
        </div>
      </div>
    </modal>
    <!-- ./modal end -->

    <!-- ./modal start -->
    <div class="modal fade" id="add_expenses">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Add Cost</h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group fc">
                  <label>Cost Type</label>
                  <select
                    id="selectedCost"
                    class="form-control"
                    name="selectedCost"
                    v-model="selectedCost"
                    required
                  >
                    <option :value="null">-- Select a Cost --</option>
                    <option v-for="cost in loanExpenses" :key="cost.id" :value="cost.id">
                      {{ cost.loan_expense_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">Amount</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder
                    v-model="amount"
                    required
                  />
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label>Currency</label>
                  <select
                    id="selectedCurrency"
                    class="form-control"
                    name="selectedCurrency"
                    v-model="selectedCurrency"
                    required
                  >
                    <option :value="null">-- Select a Currency --</option>
                    <option v-for="curr in currencies" :key="curr.id" :value="curr.id">
                      {{ curr.currency_short_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">Date</label>
                  <datepicker
                    class="datepop datepicker"
                    :bootstrap-styling="true"
                    name="CostPayDate"
                    placeholder="Select Date"
                    v-model="CostPayDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    required
                  ></datepicker>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onCostSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <!-- <a @click="$router.go(-1)"> -->
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </li>
              </ul>
              <!-- </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->

  <div>
    <!-- ... other template content ... -->

    <custom-modal ref="customModal">
      <!-- Provide content for the header slot -->
      <template v-slot:header>
            <h4>Change Status</h4>
      </template>

      <!-- Provide content for the default slot (body) -->
        <div class="row">
          <div class="col-12">
            <div class="form-group fc">
              <label for="selectedLoanClosingDate">Loan Closing Date</label>

              <datepicker
                class="datepicker custom-width-datepicker"
                :bootstrap-styling="true"
                name="selectedLoanClosingDate"
                placeholder="Select Date"
                v-model="selectedLoanClosingDate"
                :format="$store.state.date_format"
                calendar-button-icon="fa fa-calendar"
                :disabledDates="{ to: new Date(backcapLoanClosingDate) , from: new Date() }"
                :calendar-button="true"
              ></datepicker>
            </div>
          </div>
        </div>

      <!-- Provide content for the footer slot -->
      <template v-slot:footer>
        <div>
          <button
            type="button"
            name="saveInfo"
            class="btn-sm btn btn-primary sh"
            style="margin-right: 10px;"
            v-on:click="onCloseModal()"
          >
            Cancel
          </button>
          <button
            type="button"
            name="submit"
            class="btn-sm btn btn-success sh"
            v-on:click="onSubmitModal()"
          >
            Submit
          </button>
      </div>
      </template>
    </custom-modal>
  </div>

  </div>
  <!-- ./wrapper -->
</template>



<script>
import $ from "jquery";
// //Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import CustomModal from './CustomModal.vue';
import CustomLoanReportModal from './CustomLoanReportModal.vue';


export default {
  data() {
    return {
      id: null,
      Loans: [],
      LoansUtiliReq: [],
      loanStatuses: {}, // Object to store statuses for each loan
      DrawdownReq: [],
      RepaymentDetails: [],
      facilities:[],
      loanIndex:null,
      updatedStatus:null,
      prevStatus:null,
      selectedLoanClosingDate:new Date(),
      backcapLoanClosingDate:new Date(),
      selectedFacilityLoan:null,
      selectedFacilityDisbursement:null,
      selectedFacilityCost:null,
      selectedFacilityRepayment:null,
      selectedCost: null,
      Costs: [],
      selectedCurrency: null,
      currencies: [],
      // startDate: null,
      // endDate: null,
      // selectedPeriod: null,
      filterLoan: "date",
      startDateLoan: null,
      endDateLoan: null,
      selectedPeriodLoan: null,
      startDateLoanPeriod: null, //for period filter
      endDateLoanPeriod: null, //for period filter
      filterDisbursement: "date",
      startDateDisbursement: null,
      endDateDisbursement: null,
      selectedPeriodDisbursement: null,
      startDateDisbursementPeriod: null, //for period filter
      endDateDisbursementPeriod: null, //for period filter
      filterRepayment: "date",
      startDateRepayment: null,
      endDateRepayment: null,
      selectedPeriodRepayment: null,
      startDateRepaymentPeriod: null, //for period filter
      endDateRepaymentPeriod: null, //for period filter
      filterCost: "date",
      startDateCost: null,
      endDateCost: null,
      selectedPeriodCost: null,
      startDateCostPeriod: null, //for period filter
      endDateCostPeriod: null, //for period filter
      amount: null,
      errors: [],
      CostDocuments: [],
      costDocName: null,
      CostPayDate: null,
      CostPayDate1: null,
      receipt_no: null,
      selectedCost: null,
      costs: [],
      display_file_name: null,
      CostRemoveDisabled: null,
      selectedStatus: "Open",
      selectedCostStatus: "Approved",
      selectedBorrowerName: null,
      filtertype: null,
      loanExpenses: [],
      loanId: null, //for reports
      loanRegId: null, //for reports
      selectedReport: 6,
      reports: null,
      borrower_id: null,
      facRegDate: null,
      facility_id: null,
      inActiveToggle: false,

      selectedPeriodType: null,
      report_type: [
        { id: 0 , report_type_name: "Monthly"},
        { id: 1 , report_type_name: "Custom"},
      ],

      backcapLoanReportDate: null,
      selectedRegularReportDate: null,
      selectedCustomReportDate: null,
      lastMonth: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1
      ),
    };
  },
  components: {
    Datepicker,
    CustomModal,
    CustomLoanReportModal,
  },
  methods: {
    destroyTable() {
      this.LoansUtiliReq = [];
      this.DrawdownReq = [];
      this.RepaymentDetails = [];
      this.Costs = [];
      $("#myTable1").dataTable().fnDestroy();
      if (!this.inActiveToggle) {
        this.$store.commit("setinactive", "loan_active");
        this.$router.go();
      } else {
        this.$store.commit("setinactive", "loan_inactive");
        this.getLoan();
      }
    },
    onPeriodSelect(filtertype) {
      if(filtertype === 'Utilisation'){
        if (this.selectedPeriodLoan === "all") {
        this.selectedStatus = "All";
        } else {
          this.selectedStatus = "Open";
        }
      } else if(filtertype === 'Cost'){
        if (this.selectedPeriodCost === "all") {
        this.selectedCostStatus = "All";
        } else {
          this.selectedCostStatus = "Approved";
        }
      } 
    },
    //to open add expense modal
    showAddModal() {
      $("#add_expenses").modal("show");
    }, //showAddModal() close

    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },

    showReportModal(loanId,date,loanRegId) {
      this.loanId = loanId;
      this.loanRegId = loanRegId;
      this.backcapLoanReportDate = date;
      // this.$modal.show("report");
      this.$refs.customLoanReportModal.open();
    },

    onClickExcel(fileType) {
      if (this.selectedReport === null) {
        Utils.make_alert("warning", "Please select report type.");
        return;
      }
      if(this.selectedPeriodType == null){
        Utils.make_alert("warning", "Please select period type.");
        return;
      }
      let date = null
      if(this.selectedPeriodType != null){
        if(this.selectedPeriodType == 0){
          if (this.selectedRegularReportDate === null) {
            Utils.make_alert("warning", "Please select Monthly Period.");
            return;
          } else {
            console.log("selectedRegularReportDate",this.selectedRegularReportDate)
            date = moment(this.selectedRegularReportDate).endOf("month").format("MM-DD-YYYY");
          }
        } else if(this.selectedPeriodType == 1){
          if (this.selectedCustomReportDate === null) {
            Utils.make_alert("warning", "Please select till date.");
            return;
          } else {
            console.log("selectedCustomReportDate",this.selectedCustomReportDate)
            date = moment(this.selectedCustomReportDate).format("MM-DD-YYYY");
          }
        }
      }
      console.log("date",date)
      const path =
        "/loan_reports/" +
        this.selectedReport +
        "/" +
        this.selectedPeriodType +
        "/" +
        this.loanId +
        "/" +
        fileType +
        "/" + 
        date;
        console.log("path",path)
        this.$refs.customLoanReportModal.close();
        Utils.start_loading();
      axios({
        params: { clientId: this.$store.state.client_id , userId: this.$store.getters.getuserId},
        url: path,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          Utils.stop_loading();
          console.log("API CALL DONE")
          console.log("response.data.type",response.data.type)
          if (response.data.type == "application/json") {
            console.log("failed")
            Utils.make_alert(
              "warning",
              "Something went wrong! Unable to download report."
            );
            this.selectedPeriodType = null;
            this.selectedRegularReportDate = null;
            this.selectedCustomReportDate = null;
            this.loanId = null;
            this.loanRegId = null;
            this.backcapLoanReportDate = null;
          } else {
            //give report type names as per selectedReport
            console.log("Download")
            console.log("response.data",response.data)
            var reportName;
            var extension;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            // Filename seems to have to be changed unless otherwise provided
            // the extension can be automatically decided
            if (fileType === "excel") {
              extension = ".xlsx";
            } else if (fileType === "pdf") {
              extension = ".pdf";
            }
            reportName = this.loanRegId;
            link.setAttribute("download", reportName + extension);
            document.body.appendChild(link);
            link.click();
            this.selectedPeriodType = null;
            this.selectedRegularReportDate = null;
            this.selectedCustomReportDate = null;
            this.loanId = null;
            this.loanRegId = null;
            this.backcapLoanReportDate = null;
            //this.$router.go(this.$router.currentRoute)
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.log("error");
          this.selectedPeriodType = null;
          this.selectedRegularReportDate = null;
          this.selectedCustomReportDate = null;
          this.loanId = null;
          this.loanRegId = null;
          this.backcapLoanReportDate = null;
        });
    },

    getReports() {
      //this function is called to get list of fund reports
      const path = "loan-reports";
      axios
        .get(path)
        .then((res) => {
          this.reports = res.data.reports;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // this method will check if existing loan mapping is added again
    getLoanBackcappingDate() {
      console.log("getLoanBackcappingDate")
      let loan = this.LoansUtiliReq[this.loanIndex]
      const path = "/get_loan_backcap_date/" + loan.util_id;
      Utils.start_loading();
      axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
      .then((res) => {
        Utils.stop_loading();
          console.log(res.data.status)
          if (res.data.status == 'success'){
            this.backcapLoanClosingDate = res.data.transaction_date;
            this.$refs.customModal.open();
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
      
    },

    onCloseReportModal() {
      console.log("onCloseReportModal");
      // let loan = this.LoansUtiliReq[this.loanIndex]
      // console.log("onCLose",loan.status)
      // this.loanStatuses[loan.util_id] = this.prevStatus;
      // this.prevStatus = null;
      // this.updatedStatus = null;
      this.selectedPeriodType = null;
      this.selectedRegularReportDate = null;
      this.selectedCustomReportDate = null;
      this.loanId = null;
      this.loanRegId = null;
      this.backcapLoanReportDate = null;
      this.$refs.customLoanReportModal.close();
    },
    onCloseModal() {
      console.log("onCloseModal");
      let loan = this.LoansUtiliReq[this.loanIndex]
      console.log("onCLose",loan.status)
      this.loanStatuses[loan.util_id] = this.prevStatus;
      this.prevStatus = null;
      this.updatedStatus = null;
      this.loanIndex = null;
      this.$refs.customModal.close();
    },

    onSubmitModal() {
      console.log("onSubmitModal");
      let loan = this.LoansUtiliReq[this.loanIndex]
      this.$refs.customModal.close();
      let closingDate = null;
      if(this.selectedLoanClosingDate != null){
        closingDate = moment(this.selectedLoanClosingDate).format("MM-DD-YYYY");
      }
      this.onChangeLoanOverviewStatus(
        this.updatedStatus,
        loan.util_id,
        'LoanStatus',
        loan.status,
        this.loanIndex,
        closingDate,
        loan.approved_amount,
        loan.requested_amt,
        loan.collateral_id,
        loan.loan_registration_id
      )
      
    },

    openChangeLoanOverviewStatus(event, index){
      let loan = this.LoansUtiliReq[index]
      console.log(loan)
      this.loanIndex = index
      this.updatedStatus = event.target.value;
      this.prevStatus = loan.status
      console.log("updatedStatus",this.updatedStatus)
      this.loanStatuses[loan.util_id] = this.updatedStatus;
      if (this.updatedStatus == 'Closed') {
        this.getLoanBackcappingDate()
        return;
      } else {
        this.selectedLoanClosingDate = null;
      }
      if(this.selectedLoanClosingDate != null){
        let selectedLoanClosingDate = moment(this.selectedLoanClosingDate).format("MM-DD-YYYY");
      }

      this.onChangeLoanOverviewStatus(
        this.updatedStatus,
        loan.util_id,
        'LoanStatus',
        loan.status,
        index,
        selectedLoanClosingDate,
        loan.approved_amount,
        loan.requested_amt,
        loan.collateral_id,
        loan.loan_registration_id
      )
    },

    onChangeLoanOverviewStatus(
      updatedStatus,
      loanId,
      filterdata,
      prevStatus,
      index,
      closingDate,
      approvedAmount,
      requestedAmount,
      collateralId,
      loanRegistrationId
    ) {
      this.$confirm({
          auth: false,
          message: `Are you sure you want to ${updatedStatus} this Utilisation/Loan Request?`,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              Utils.start_loading();
              //this function is called when user changes the loan registration status. The status is updated in the database for that specific loan.
              const path = "loan-overview/" + this.id;
              axios
                .put(
                  path,
                  {
                    updateType: "status",
                    updatedStatus: updatedStatus,
                    transactionId: loanId,
                    filtertype: filterdata,
                    closingDate: closingDate,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    approvedFlag: confirm,
                    approvedReqDiff: "lesser",
                    moduleId: 5,
                    tabId: 16,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("registration update status: " + res.data.status);
                  if (res.data.status == "success") {
                    Utils.make_alert("success", "Status updated successfully!");
                    //if you want to send any data into server before redirection then you can do it here
                    this.LoansUtiliReq[index].status = updatedStatus;
                    let loan = this.LoansUtiliReq[index]
                    this.loanStatuses[loan.util_id] = updatedStatus;
                  } else {
                    console.log("Something went wrong from server!");
                  }
                  setTimeout(() => this.$router.go(), 1000);
                  this.prevStatus = null;
                  this.updatedStatus = null;
                  this.loanIndex = null;
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.error("error: " + error);
                  console.log("Something went wrong!");
                  this.prevStatus = null;
                  this.updatedStatus = null;
                  this.loanIndex = null;
                });
            } else {
              let loan = this.LoansUtiliReq[this.loanIndex]
              this.loanStatuses[loan.util_id] = this.prevStatus;
              this.prevStatus = null;
              this.updatedStatus = null;
              this.loanIndex = null;
            }
          },
        });
    }, //onChangeLoanOverviewStatus close

    getAllLoans(filtertype) {
      console.log("getAllLoans")
      this.filtertype = filtertype;
      console.log("filtertype",filtertype)
      let datePeriodFilter = null;
      let status = null;
      let startDate = null;
      let endDate = null;
      let selectedPeriod = null;
      let selectedFacility = null;
      if(filtertype === 'Utilisation'){
        console.log("hello")
        console.log("hello 2")
        datePeriodFilter = this.filterLoan
        if (this.filterLoan === "date") {
          if (
            (this.startDateLoan !== null && this.endDateLoan === null) ||
            (this.startDateLoan === null && this.endDateLoan !== null)
            ) {
            Utils.make_alert(
              "warning",
              "Both From & To Dates are required to perform Date Filter"
            );
            return;
          }
          this.selectedPeriodLoan = null;
          if (this.startDateLoan != null) {
            this.startDateLoan = moment(this.startDateLoan).format("MM-DD-YYYY");
          }
          if (this.endDateLoan != null) {
            this.endDateLoan = moment(this.endDateLoan).format("MM-DD-YYYY");
          }
        }
        if (this.filterLoan === "period") {
          this.startDateLoan = null;
          this.endDateLoan = null;
        }
        $("#myTable1").dataTable().fnDestroy();
        status = this.selectedStatus
        startDate = this.startDateLoan
        endDate = this.endDateLoan
        selectedPeriod = this.selectedPeriodLoan
        selectedFacility = this.selectedFacilityLoan
      } else if(filtertype === 'Drawdown'){
          console.log("getAllLoans Drawdown")
          datePeriodFilter = this.filterDisbursement
        if (this.filterDisbursement === "date") {
          if (
            (this.startDateDisbursement !== null && this.endDateDisbursement === null) || 
            (this.startDateDisbursement === null && this.endDateDisbursement !== null)
          ) {
            Utils.make_alert(
              "warning",
              "Both From & To Dates are required to perform Date Filter"
            );
            return;
          }
          this.selectedPeriodDisbursement = null;
          if (this.startDateDisbursement != null) {
            this.startDateDisbursement = moment(this.startDateDisbursement).format("MM-DD-YYYY");
          }
          if (this.endDateDisbursement != null) {
            this.endDateDisbursement = moment(this.endDateDisbursement).format("MM-DD-YYYY");
          }
        }
        if (this.filterDisbursement === "period") {
          this.startDateDisbursement = null;
          this.endDateDisbursement = null;
        }
        status = null;
        startDate = this.startDateDisbursement
        endDate = this.endDateDisbursement
        selectedPeriod = this.selectedPeriodDisbursement
        selectedFacility = this.selectedFacilityDisbursement
      } else if(filtertype === 'Repayment'){
        datePeriodFilter = this.filterRepayment
        if (this.filterRepayment === "date") {
          if (
            (this.startDateRepayment !== null && this.endDateRepayment === null) ||
            (this.startDateRepayment === null && this.endDateRepayment !== null)
          ) {
            Utils.make_alert(
              "warning",
              "Both From & To Dates are required to perform Date Filter"
            );
            return;
          }
          this.selectedPeriodRepayment = null;
          if (this.startDateRepayment != null) {
            this.startDateRepayment = moment(this.startDateRepayment).format("MM-DD-YYYY");
          }
          if (this.endDateRepayment != null) {
            this.endDateRepayment = moment(this.endDateRepayment).format("MM-DD-YYYY");
          }
        }
        if (this.filterRepayment === "period") {
          this.startDateRepayment = null;
          this.endDateRepayment = null;
        }
        status = null;
        startDate = this.startDateRepayment
        endDate = this.endDateRepayment
        selectedPeriod = this.selectedPeriodRepayment
        selectedFacility = this.selectedFacilityRepayment
      } else if(filtertype === 'Cost'){
        datePeriodFilter = this.filterCost
        if (this.filterCost === "date") {
          if (
            (this.startDateCost !== null && this.endDateCost === null) || 
            (this.startDateCost === null && this.endDateCost !== null)
          ) {
            Utils.make_alert(
              "warning",
              "Both From & To Dates are required to perform Date Filter"
            );
            return;
          }
          this.selectedPeriodCost = null;
          if (this.startDateCost != null) {
            this.startDateCost = moment(this.startDateCost).format("MM-DD-YYYY");
          }
          if (this.endDateCost != null) {
            this.endDateCost = moment(this.endDateCost).format("MM-DD-YYYY");
          }
        }
        if (this.filterCost === "period") {
          this.startDateCost = null;
          this.endDateCost = null;
        }
        status = this.selectedCostStatus
        startDate = this.startDateCost
        endDate = this.endDateCost
        selectedPeriod = this.selectedPeriodCost
        selectedFacility = this.selectedFacilityCost
      } 
      if(datePeriodFilter == 'date') { 
        if (
            (this.startDate !== null && this.endDate === null) || 
            (this.startDate === null && this.endDate !== null)
          ) {
            console.log("FINAL")
            console.log("Both From & To Dates are required to perform Date Filter")
            return;
          }
      }
      // console.log("why here?")
      const path =
        "/loan-overview-by-id/" +
        this.id +
        "/" +
        selectedFacility +
        "/" +
        startDate +
        "/" +
        endDate +
        "/" +
        selectedPeriod +
        "/" +
        status +
        "/" +
        this.filtertype;
      console.log(path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // Utils.stop_loading();
          if (res.data.t_status == "success") {
            console.log("get loan by loan id is successful.");
            this.filtertype = filtertype;
            if (this.filtertype === "Utilisation") {
              this.LoansUtiliReq = this.inActiveToggle
                ? res.data.utilisation_inactive
                : res.data.utilisation_active;
                if(this.LoansUtiliReq.length > 0) {
                  this.LoansUtiliReq.forEach(item => {
                    if (item.requested_date != '-' && item.requested_date != null) {
                      item.requested_date = moment(item.requested_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                    } else {
                      item.requested_date = '-';
                    }
                  });
                }
                this.LoansUtiliReq.forEach(loan => {
                  this.$set(this.loanStatuses, loan.util_id, loan.status);
                });
                console.log("this.LoansUtiliReq",this.LoansUtiliReq)
                console.log("this.loanStatuses",this.loanStatuses)
              this.selectedPeriodLoan = res.data.period;
              this.selectedStatus = res.data.status;
              //set the start and end date according to filter selected
              if (this.filterLoan === "period") {
                this.startDateLoanPeriod = res.data.start_date;
                this.endDateLoanPeriod = res.data.end_date;
                this.startDateLoan = null;
                this.endDateLoan = null;
              } else if (this.filterLoan === "date") {
                this.startDateLoan = res.data.start_date;
                this.endDateLoan = res.data.end_date;
                this.startDateLoanPeriod = null;
                this.endDateLoanPeriod = null;
              }
                  setTimeout(
                  () =>
                    $("#myTable1").DataTable({
                      scrollX: true,
                      aaSorting: [],
                      columnDefs: this.inActiveToggle
                        ? [{ orderable: false, targets: [] }]
                        : [
                            {
                              orderable: false,
                              targets:
                              this.loggedInUserGroupId === "Borrower"
                              ? [9, 10] // Updated targets for Borrower
                              : this.loggedInUserGroupId === "Analyst"
                              ? [9, 10, 11] // Keeps existing targets for Analyst
                              : [9, 10, 11, 12],
                            },
                          ],
                    }),
                  500
                );
              if (this.LoansUtiliReq.length) {
                // this.facRegDate = this.LoansUtiliReq[0].facility_request_date;
                // this.facRegDate = moment(String(this.facRegDate)).format(
                //   "MM/DD/YYYY"
                // );
                this.facility_id = this.LoansUtiliReq[0].facility_id;
              }
            } else if (this.filtertype === "Drawdown") {
              this.DrawdownReq = this.inActiveToggle
                ? res.data.drawdown_inactive
                : res.data.drawdown_active;
              if(this.DrawdownReq.length > 0) {
                this.DrawdownReq.forEach(item => {
                  if (item.disbursement_date != '-' && item.disbursement_date != null) {
                    item.disbursement_date = moment(item.disbursement_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.disbursement_date = '-';
                  }
                });
              }
              this.selectedPeriodDisbursement = res.data.period;
              //set the start and end date according to filter selected
              if (this.filterDisbursement === "period") {
                this.startDateDisbursementPeriod = res.data.start_date;
                this.endDateDisbursementPeriod = res.data.end_date;
                this.startDateDisbursement = null;
                this.endDateDisbursement = null;
              } else if (this.filterDisbursement === "date") {
                this.startDateDisbursement = res.data.start_date;
                this.endDateDisbursement = res.data.end_date;
                this.startDateDisbursementPeriod = null;
                this.endDateDisbursementPeriod = null;
              }
            } else if (this.filtertype === "Repayment") {
              console.log("this.inActiveToggle",this.inActiveToggle)
              this.RepaymentDetails = this.inActiveToggle
                ? res.data.repayment_inactive
                : res.data.repayment_active;
              if(this.RepaymentDetails.length > 0) {
                this.RepaymentDetails.forEach(item => {
                  if (item.repayment_date != '-' && item.repayment_date != null) {
                    item.repayment_date = moment(item.repayment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.repayment_date = '-';
                  }
                });
              }
              this.selectedPeriodRepayment = res.data.period;
              //set the start and end date according to filter selected
              if (this.filterRepayment === "period") {
                this.startDateRepaymentPeriod = res.data.start_date;
                this.endDateRepaymentPeriod = res.data.end_date;
                this.startDateRepayment = null;
                this.endDateRepayment = null;
              } else if (this.filterRepayment === "date") {
                this.startDateRepayment = res.data.start_date;
                this.endDateRepayment = res.data.end_date;
                this.startDateRepaymentPeriod = null;
                this.endDateRepaymentPeriod = null;
              }
            } else if (this.filtertype === "Cost") {
              this.Costs = this.inActiveToggle
                ? res.data.loan_cost_inactive
                : res.data.loan_cost_active;
              if(this.Costs.length > 0) {
                this.Costs.forEach(item => {
                  if (item.expense_date != '-' && item.expense_date != null) {
                    item.expense_date = moment(item.expense_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.expense_date = '-';
                  }
                });
              }
              this.selectedPeriodCost = res.data.period;
              this.selectedCostStatus = res.data.status;
              //set the start and end date according to filter selected
              if (this.filterCost === "period") {
                this.startDateCostPeriod = res.data.start_date;
                this.endDateCostPeriod = res.data.end_date;
                this.startDateCost = null;
                this.endDateCost = null;
              } else if (this.filterCost === "date") {
                this.startDateCost = res.data.start_date;
                this.endDateCost = res.data.end_date;
                this.startDateCostPeriod = null;
                this.endDateCostPeriod = null;
              }
            }
            // console.log(this.LoansUtiliReq);
            // if (this.selectedStatus !== "All" && this.LoansUtiliReq.length) {
            //   var filteredLoans = this.LoansUtiliReq.filter(
            //     (loan) => loan.status === this.selectedStatus
            //   );
            //   this.LoansUtiliReq = filteredLoans;
            // }
            // console.log(this.LoansUtiliReq);

            Utils.stop_loading();
          } else if (res.data.status == "unauthorised") {
            Utils.stop_loading();
            this.$router.push("/loan");
          } else {
            Utils.stop_loading();
            console.log("Something went wrong.");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          setTimeout(
              () =>
                $("#myTable1").DataTable({
                  scrollX: true,
                  aaSorting: [],
                  columnDefs: this.inActiveToggle
                    ? [{ orderable: false, targets: [] }]
                    : [
                        {
                          orderable: false,
                          targets:
                          this.loggedInUserGroupId === "Borrower"
                          ? [9, 10] // Updated targets for Borrower
                          : this.loggedInUserGroupId === "Analyst"
                          ? [9, 10, 11] // Keeps existing targets for Analyst
                          : [9, 10, 11, 12],
                        },
                      ],
                }),
              500
            );
        });
    }, //getAllLoans() close

    onCostSubmit() {
      this.$confirm({
        auth: false,
        message: "Confirm submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            if (this.CostPayDate != null) {
              this.CostPayDate1 = moment(this.CostPayDate).format("MM-DD-YYYY");
            }
            axios
              .post(
                "add_loan_cost/" + this.id,
                {
                  //following fields are required for cost payment and cost
                  loanRegId: this.id,
                  selectedCost: this.selectedCost,
                  selectedCurrency: this.selectedCurrency,
                  CostPayDate: this.CostPayDate1,
                  amount: this.amount,
                  costDocName: this.costDocName,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((response) => {
                if (response.data.status == "success") {
                  Utils.make_alert("success", "Cost added successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  this.$router.go(this.$router.currentRoute);
                } else {
                  console.log("Something went wrong from server!");
                }
              })
              .catch((error) => {
                console.log("error: " + error);
                console.log("Something went wrong!");
                this.$router.go(this.$router.currentRoute);
              });
          }
        },
      });
    }, //onCostSubmit() close

    getLoan() {
      Utils.start_loading();
      //this function is called on page load and created events. it will show details on loan with loanid equal to this.id
      const path = "loan-overview/" + this.id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // Utils.stop_loading();
          if (res.data.status == "success") {
            if (this.inActiveToggle) {
              if (Object.keys(res.data.loans_inactive).length) {
                this.LoansUtiliReq = res.data.loans_inactive
                  .loan_utilisation_details
                  ? res.data.loans_inactive.loan_utilisation_details
                  : [];
                  if(this.LoansUtiliReq.length > 0) {
                    this.LoansUtiliReq.forEach(item => {
                      if (item.requested_date != '-' && item.requested_date != null) {
                        item.requested_date = moment(item.requested_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                      } else {
                        item.requested_date = '-';
                      }
                    });
                  }
                  this.LoansUtiliReq.forEach(loan => {
                    this.$set(this.loanStatuses, loan.util_id, loan.status);
                  });
                this.DrawdownReq = res.data.loans_inactive.loan_drawdown_details
                  ? res.data.loans_inactive.loan_drawdown_details
                  : [];
                if(this.DrawdownReq.length > 0) {
                  this.DrawdownReq.forEach(item => {
                    if (item.disbursement_date != '-' && item.disbursement_date != null) {
                      item.disbursement_date = moment(item.disbursement_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                    } else {
                      item.disbursement_date = '-';
                    }
                  });
                }
                this.RepaymentDetails = res.data.loans_inactive
                .loan_repayment_details
                ? res.data.loans_inactive.loan_repayment_details
                : [];
                if(this.RepaymentDetails.length > 0) {
                  this.RepaymentDetails.forEach(item => {
                    if (item.repayment_date != '-' && item.repayment_date != null) {
                      item.repayment_date = moment(item.repayment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                    } else {
                      item.repayment_date = '-';
                    }
                  });
                }
                this.Costs = res.data.loans_inactive.loan_cost_details
                ? res.data.loans_inactive.loan_cost_details
                : [];
                if(this.Costs.length > 0) {
                  this.Costs.forEach(item => {
                    if (item.expense_date != '-' && item.expense_date != null) {
                      item.expense_date = moment(item.expense_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                    } else {
                      item.expense_date = '-';
                    }
                  });
                }
                this.facRegDate = this.LoansUtiliReq[0].facility_request_date;
                this.facility_id = this.LoansUtiliReq[0].facility_id;
              }
            } else {
              if (Object.keys(res.data.loans_active).length) {
                this.LoansUtiliReq = res.data.loans_active
                  .loan_utilisation_details
                  ? res.data.loans_active.loan_utilisation_details
                  : [];
                  if(this.LoansUtiliReq.length > 0) {
                    this.LoansUtiliReq.forEach(item => {
                      if (item.requested_date != '-' && item.requested_date != null) {
                        item.requested_date = moment(item.requested_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                      } else {
                        item.requested_date = '-';
                      }
                    });
                  }
                  this.LoansUtiliReq.forEach(loan => {
                    this.$set(this.loanStatuses, loan.util_id, loan.status);
                  });
                this.DrawdownReq = res.data.loans_active.loan_drawdown_details
                  ? res.data.loans_active.loan_drawdown_details
                  : [];
                if(this.DrawdownReq.length > 0) {
                  this.DrawdownReq.forEach(item => {
                    if (item.disbursement_date != '-' && item.disbursement_date != null) {
                      item.disbursement_date = moment(item.disbursement_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                    } else {
                      item.disbursement_date = '-';
                    }
                  });
                }
                this.RepaymentDetails = res.data.loans_active
                  .loan_repayment_details
                  ? res.data.loans_active.loan_repayment_details
                  : [];
                if(this.RepaymentDetails.length > 0) {
                  this.RepaymentDetails.forEach(item => {
                    if (item.repayment_date != '-' && item.repayment_date != null) {
                      item.repayment_date = moment(item.repayment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                    } else {
                      item.repayment_date = '-';
                    }
                  });
                }
                this.Costs = res.data.loans_active.loan_cost_details
                  ? res.data.loans_active.loan_cost_details
                  : [];
                if(this.Costs.length > 0) {
                  this.Costs.forEach(item => {
                    if (item.expense_date != '-' && item.expense_date != null) {
                      item.expense_date = moment(item.expense_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                    } else {
                      item.expense_date = '-';
                    }
                  });
                }
                this.facRegDate = this.LoansUtiliReq[0].facility_request_date;
                this.facility_id = this.LoansUtiliReq[0].facility_id;
              }
            }
            this.facRegDate = moment(String(this.facRegDate)).format(
              "MM/DD/YYYY"
            );
            if (this.selectedStatus !== "All") {
              var filteredLoans = this.LoansUtiliReq.filter(
                (loan) => loan.status === this.selectedStatus
              );
              this.LoansUtiliReq = filteredLoans;
            }
            setTimeout(
              () =>
                $("#myTable1").DataTable({
                  scrollX: true,
                  aaSorting: [],
                  columnDefs: this.inActiveToggle
                    ? [{ orderable: false, targets: [] }]
                    : [
                        {
                          orderable: false,
                          targets:
                          this.loggedInUserGroupId === "Borrower"
                          ? [9, 10] // Updated targets for Borrower
                          : this.loggedInUserGroupId === "Analyst"
                          ? [9, 10, 11] // Keeps existing targets for Analyst
                          : [9, 10, 11, 12],
                        },
                      ],
                }),
              500
            );
            console.log("get loan details is successful.");
            Utils.stop_loading();
          } else if (res.data.status == "unauthorised") {
            Utils.stop_loading();
            this.$router.push("/loan");
          } else {
            Utils.stop_loading();
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
          setTimeout(
              () =>
                $("#myTable1").DataTable({
                  scrollX: true,
                  aaSorting: [],
                  columnDefs: this.inActiveToggle
                    ? [{ orderable: false, targets: [] }]
                    : [
                        {
                          orderable: false,
                          targets:
                          this.loggedInUserGroupId === "Borrower"
                          ? [9, 10] // Updated targets for Borrower
                          : this.loggedInUserGroupId === "Analyst"
                          ? [9, 10, 11] // Keeps existing targets for Analyst
                          : [9, 10, 11, 12],
                        },
                      ],
                }),
              500
            );
        });
    }, //getLoan close

    deleteUtilisationRequest(loanId, loanRegId) {
      console.log(loanId, loanRegId);
      //this function will be called to delete a specific fund. Parameter required - fundID: fundid which needs to be deleted
      this.$confirm({
        auth: false,
        message: "Confirm Delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path = "loan-overview/" + this.id;
            axios
              .delete(path, {
                headers: {
                  "Content-type": "application/json",
                },
                data: {
                  deleteType: "Loan",
                  loanId: loanId,
                  loanRegId: loanRegId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 5,
                  tabId: 16,
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert("success", "Request deleted successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(
                    () => this.$router.go(this.$router.currentRoute),
                    1500
                  );
                } else if (response.data.status == "deletion_not_allowed") {
                  Utils.make_alert(
                    "success",
                    "Delete operation not permitted."
                  );
                } else {
                  Utils.make_alert(
                    "success",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.log("error: " + error);
                Utils.make_alert("success", "Something went wrong!");
              });
          }
        },
      });
    }, //deleteUtilisationRequestAndCost()  close

    //handles a change on the file upload
    handleFileUploadCost(id, e) {
      let self = this;
      let reader = new FileReader();
      this.CostFile = e.target.files[0];

      //check for filesize. if it is larger than threshold then return following error response
      if (this.CostFile.size > 1024 * 1024) {
        e.preventDefault();
        var response = { errors: null };
        response.errors = "File size should be less than 1MB";
        self.CostList[id].errors = JSON.stringify(response.errors).replace(
          /\"/g,
          ""
        );
        self.CostList[id].selectedFile = null;
        self.CostList[id].selectedFileName = null;
        self.CostList[id].display_file_name = null;
        self.CostList[id].CostRemoveDisabled = true;
        return;
      }

      reader.readAsDataURL(this.CostFile);
      console.log("id: " + id);
      reader.onload = function () {
        self.CostList[id].selectedFile = reader.result;
        self.CostList[id].selectedFileName = e.target.files[0].name;
        self.CostList[id].display_file_name = e.target.files[0].name; //to show filename in file input
        self.CostList[id].errors = "";
        self.CostList[id].CostRemoveDisabled = false;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }, //handleFileUploadCost() close

    ////this method handles remove cost attachment
    removeCostDoc(id) {
      this.CostFile = null;
      this.CostList[id].selectedFile = null;
      this.CostList[id].selectedFileName = null;
      this.CostList[id].display_file_name = null;
      this.CostList[id].CostRemoveDisabled = true;
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLoanExpenses() {
      //this function is called to get list of loan expenses
      const path = "loan-expense-master";
      axios
        .get(path)
        .then((res) => {
          this.loanExpenses = res.data.loan_expenses;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    resetLoan(filtertype) {
      this.id = this.$route.params.id;
      if(filtertype === 'Utilisation'){
        this.startDateLoan = null;
        this.endDateLoan = null;
        this.startDateLoanPeriod = null;
        this.endDateLoanPeriod = null;
        this.selectedPeriodLoan = null;
        this.selectedStatus = "Open";
        this.filtertype = filtertype;
        this.filterLoan = "date";
      } else if(filtertype === 'Drawdown'){
        this.startDateDisbursement = null;
        this.endDateDisbursement = null;
        this.startDateDisbursementPeriod = null;
        this.endDateDisbursementPeriod = null;
        this.selectedPeriodDisbursement = null;
        this.filtertype = filtertype;
        this.filterDisbursement = "date";
      } else if(filtertype === 'Repayment'){
        this.startDateRepayment = null;
        this.endDateRepayment = null;
        this.startDateRepaymentPeriod = null;
        this.endDateRepaymentPeriod = null;
        this.selectedPeriodRepayment = null;
        this.filtertype = filtertype;
        this.filterRepayment = "date";
      } else if(filtertype === 'Cost'){
        this.startDateCost = null;
        this.endDateCost = null;
        this.startDateCostPeriod = null;
        this.endDateCostPeriod = null;
        this.selectedPeriodCost = null;
        this.selectedCostStatus = "Approved";
        this.filtertype = filtertype;
        this.filterCost = "date";
      } 
      this.getAllLoans(filtertype);
    }, //resetLoan() close

    getFacilitySetupByBorrower() {
      //this function is called to get list of day count
      const path = "/facility-setup-borrower/" + this.id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.facilities = res.data.Facility_Setup;
          // this.selectedFacility = this.facilities[0].id
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function will be called to get list of borrowers
    getBorrowers() {
      const path = "borrower";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          let borrowers = res.data.borrowers_active
          console.log(borrowers)
          if (res.data.status == "success") {
          borrowers.filter((bor) => {
              if (bor.borrower_id.toString() === this.id)
                this.selectedBorrowerName = bor.borrower_name;
            });
            if (this.loggedInUserGroupId === "Borrower") {
              borrowers.filter((bor) => {
                if (bor.borrower_id.toString() === this.loggedInUserSubGroupId)
                this.selectedBorrowerName = bor.borrower_name;
              });
            }
            console.log("get borrowers is successful.");
            console.log(this.selectedBorrowerName)
            this.getFacilitySetupByBorrower();
          } else {
            Utils.stop_loading();
            console.log("Something went wrong from server!");
          }
          Utils.stop_loading();
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          Utils.stop_loading();
          console.log("Something went wrong!");
        });
    },
  },

  mounted() {
    this.id = this.$route.params.id;
    this.selectedBorrowerName = this.$route.params.borrower_name;
    this.getLoan();
    this.getBorrowers();


    //keep current tab active
    $('a[data-toggle="pill"]').on("shown.bs.tab", function (e) {
      localStorage.setItem("loanOverTab", $(e.target).attr("href"));
    });
    var activeTab = localStorage.getItem("loanOverTab");
    if (activeTab) {
      $('.nav-tabs a[href="' + activeTab + '"]').tab("show");
    }
    // to make column header ascending Descending
    // $(document).ready(function() {
    //   $("#example").DataTable({
    //     order: [[3, "desc"]]
    //   });
    // });
  },
  created() {
    this.inActiveToggle =
      this.$store.state.inactive === "loan_inactive" ? true : false;
    this.getCurrencies();
    this.getLoanExpenses();
    this.getReports();
    // this.filter = "date";
    // this.selectedStatus = "Open";
    //this.getAllLoans(this.filtertype);
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style>
.vdp-datepicker__calendar {
  width: 150% !important;
  font-size: small;
  /* position: relative; */
  top: auto;
  z-index: 9999 !important;
}

.custom-width-datepicker .vdp-datepicker__calendar {
  width: 100% !important;
  font-size: small;
  /* position: relative; */
  top: auto;
  z-index: 9999 !important;
}

textarea {
  border-style: none;
  border-color: Transparent;
  overflow: auto;
}
.datepop {
  width: 220px;
}
th,
td {
  text-align: left;
}
.center {
  text-align: center !important;
}
.right {
  text-align: right !important;
}
.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
.wrapper {
  margin-bottom: -20px;
  min-height: 700px !important;
}
</style>
