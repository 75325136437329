<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="container-fluid tabtop" id="al_tb">
        <div class="tp_mid">
          <div class="row">
            <div class="col-lg-12">
              <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                <li
                  class="nav-item"
                  v-if="
                    loggedInUserGroupId == 'Collateral Manager' ||
                    loggedInUserGroupId == 'Admin' ||
                    (loggedInUserGroupId == 'Analyst' &&
                      loggedInUserSubGroupId == 0)
                  "
                >
                  <a
                    :class="[
                      loggedInUserGroupId === 'Borrower'
                        ? 'nav-link'
                        : 'nav-link active',
                    ]"
                    id="custom-tabs-one-home-tab"
                    data-toggle="pill"
                    href="#one"
                    @click="setTab('custom-tabs-one-home-tab')"
                    role="tab"
                    aria-controls="custom-tabs-one-home"
                    aria-selected="true"
                    >Collateral Manager Reg.</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    loggedInUserGroupId == 'Borrower' ||
                    loggedInUserGroupId == 'Collateral Manager' ||
                    loggedInUserGroupId == 'Admin' ||
                    (loggedInUserGroupId == 'Analyst' &&
                      loggedInUserSubGroupId == 0)
                  "
                >
                  <a
                    :class="[
                      loggedInUserGroupId === 'Borrower'
                        ? 'nav-link active'
                        : 'nav-link',
                    ]"
                    id="custom-tabs-one-profile-tab"
                    data-toggle="pill"
                    href="#two"
                    @click="setTab('custom-tabs-one-profile-tab')"
                    role="tab"
                    aria-controls="custom-tabs-one-profile"
                    aria-selected="false"
                    >Warehouse Details</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    loggedInUserGroupId == 'Borrower' ||
                    loggedInUserGroupId == 'Analyst' ||
                    loggedInUserGroupId == 'Collateral Manager' ||
                    (loggedInUserGroupId == 'Admin' &&
                      loggedInUserSubGroupId == 0)
                  "
                >
                  <a
                    class="nav-link"
                    id="custom-tabs-one-inventory-tab"
                    data-toggle="pill"
                    href="#three"
                    @click="setTab('custom-tabs-one-inventory-tab')"
                    role="tab"
                    aria-controls="custom-tabs-one-inventory"
                    aria-selected="false"
                    >Inventory Management</a
                  >
                </li>
              </ul>
            </div>
            <!-- /.col -->
          </div>
        </div>
      </div>
      <div class="col-lg-12" v-if="inventoryFilter">
        <ul
          class="nav nav-tabs"
          id="custom-tabs-one-tab"
          role="tablist"
          style="border-bottom: 1px solid #f4f6f9"
        >
          <li class="nav-item ot_i">
            <a
              class="ot_t nav-link active"
              id="verification-tab"
              data-toggle="pill"
              href="#verification"
              @click="setTab('verification-tab', 'inventory')"
              role="tab"
              aria-controls="verification"
              aria-selected="true"
              >Verification</a
            >
          </li>
          <li class="nav-item ot_i">
            <a
              class="ot_t nav-link"
              id="uncleaned-tab"
              data-toggle="pill"
              href="#uncleaned"
              @click="setTab('uncleaned-tab', 'inventory')"
              role="tab"
              aria-controls="uncleaned"
              aria-selected="false"
              >Uncleaned</a
            >
          </li>
          <li class="nav-item ot_i">
            <a
              class="ot_t nav-link"
              id="cleaning-process-tab"
              data-toggle="pill"
              href="#cleaningProcess"
              @click="setTab('cleaning-process-tab', 'inventory')"
              role="tab"
              aria-controls="cleaning-process"
              aria-selected="false"
              >Cleaning Process</a
            >
          </li>
          <li class="nav-item ot_i">
            <a
              class="ot_t nav-link"
              id="cleaned-tab"
              data-toggle="pill"
              href="#cleaned"
              @click="setTab('cleaned-tab', 'inventory')"
              role="tab"
              aria-controls="cleaned"
              aria-selected="false"
              >Cleaned</a
            >
          </li>
          <li class="nav-item ot_i">
            <a
              class="ot_t nav-link"
              id="waste-tab"
              data-toggle="pill"
              href="#waste"
              @click="setTab('waste-tab', 'inventory')"
              role="tab"
              aria-controls="waste"
              aria-selected="false"
              >Waste</a
            >
          </li>
          <li
            class="nav-item ot_i"
            v-if="
              loggedInUserGroupId == 'Admin' ||
              (loggedInUserGroupId == 'Analyst' &&
              loggedInUserSubGroupId == 0)"
          >
            <a
              class="ot_t nav-link"
              id="warrant-info-tab"
              data-toggle="pill"
              href="#warrantInfo"
              @click="setTab('warrant-info-tab', 'inventory')"
              role="tab"
              aria-controls="warrant-info"
              aria-selected="false"
              >Warrant Info</a
            >
          </li>
          <li class="nav-item ot_i">
            <a
              class="ot_t nav-link"
              id="release-request-tab"
              data-toggle="pill"
              href="#releaseRequest"
              @click="setTab('release-request-tab', 'inventory')"
              role="tab"
              aria-controls="release-request"
              aria-selected="false"
              >Release Request</a
            >
          </li>
          <!-- <li class="nav-item ot_i">
            <a
              class="ot_t nav-link"
              id="release-info-tab"
              data-toggle="pill"
              href="#releaseInfo"
              @click="setTab('release-info-tab', 'inventory')"
              role="tab"
              aria-controls="release-info"
              aria-selected="false"
              >Release Info</a
            >
          </li> -->
        </ul>
      </div>
      <div class="col-lg-12" v-if="inventoryFilter">
        <div class="row">
          <div
            class="col-md-2 col-sm-5 col-6"
            v-if="loggedInUserGroupId !== 'Borrower'"
          >
            <div class="form-group fc">
              <label for="exampleInputEmail1" class="required-field"
                >Borrower Name</label
              >
              <select
                id="selectedBorrower"
                class="form-control"
                name="selectedBorrower"
                v-model="selectedBorrower"
                @change="onBorrowerChange()"
              >
                <option :value="null">--- Select ---</option>
                <option
                  v-for="bor in borrowers"
                  :value="bor.borrower_id"
                  :key="bor.borrower_id"
                >
                  {{ bor.borrower_name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-2 col-sm-5 col-6">
            <div class="form-group fc">
              <label for="exampleInputEmail1">Facility</label>
              <select
                id="selectedFacility"
                class="form-control"
                name="selectedFacility"
                v-model="selectedFacility"
                @change="getFilteredCollaterals()"
              >
                <option :value="null">--- Select ---</option>
                <option v-for="fac in facilities" :value="fac.id" :key="fac.id">
                  {{ fac.facility_registration_id }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-2 col-sm-5 col-6">
            <div class="form-group fc">
              <label for="exampleInputEmail1">Collateral</label>
              <select
                id="selectedCollateralName"
                class="form-control"
                name="selectedCollateralName"
                v-model="selectedCollateralName"
              >
                <option :value="null">--- Select ---</option>
                <option v-for="col in Collateral" :value="col.id" :key="col.id">
                  {{ col.collateral_name }}
                </option>
              </select>
            </div>
          </div>

          <div
            class="col-md-2 col-sm-5 col-6"
            v-if="inventoryTabContent !== 'warrant-info-tab'"
          >
            <div class="form-group fc">
              <label for="exampleInputEmail1">From Date</label>

              <datepicker
                class="datepicker"
                :bootstrap-styling="true"
                name="startDate"
                placeholder="Select Date"
                v-model="startDate"
                :format="$store.state.date_format"
                calendar-button-icon="fas fa-calendar"
                :calendar-button="true"
              />
            </div>
          </div>

          <div
            class="col-md-2 col-sm-5 col-6"
            v-if="inventoryTabContent !== 'warrant-info-tab'"
          >
            <div class="form-group fc">
              <label for="exampleInputEmail1">To Date</label>
              <datepicker
                class="datepicker"
                :bootstrap-styling="true"
                name="endDate"
                placeholder="Select Date"
                v-model="endDate"
                :format="$store.state.date_format"
                calendar-button-icon="fa fa-calendar"
                :calendar-button="true"
                :disabledDates="{ to: new Date(startDate) }"
              ></datepicker>
            </div>
          </div>

          <div
            class="col-md-2 col-sm-5 col-6"
            v-if="
              statusFilter ||
              (warehouseFilter && inventoryTabContent !== 'warrant-info-tab')
            "
          ></div>
          <div
            class="col-md-2 col-sm-5 col-6"
            v-if="
              loggedInUserGroupId === 'Borrower' &&
              (statusFilter ||
                (warehouseFilter && inventoryTabContent !== 'warrant-info-tab'))
            "
          ></div>

          <div class="col-md-2 col-sm-5 col-6" v-if="statusFilter">
            <div class="form-group fc">
              <label>Status</label>
              <select
                id="selectedStatus"
                class="form-control"
                name="selectedStatus"
                v-model="selectedStatus"
              >
                <option :value="null">All</option>
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Verification Pending">
                  Verification Pending
                </option>
                <option value="Verified">Verified</option>
              </select>
            </div>
          </div>

          <div class="col-md-4 col-sm-5 col-6" v-if="warehouseFilter">
            <div class="form-group fc">
              <label for="selectedWarehouse">Warehouse</label>
              <select
                id="selectedWarehouse"
                class="form-control"
                name="selectedWarehouse"
                v-model="selectedWarehouse"
              >
                <option :value="null">--- Select ---</option>
                <option
                  v-for="war in warehousesFiltered"
                  :value="war.id"
                  :key="war.id"
                >
                  {{ war.warehouse_registration_id }}: {{ war.address }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-2 col-sm-2 col-12">
            <ul id="sr_btn">
              <li>
                <button
                  type="button"
                  class="btn-sm btn btn-primary sh_n mt"
                  @click="filterData"
                >
                  Filter
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="btn-sm btn btn-dark sh_n mt"
                  @click="resetFilter()"
                >
                  Reset
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="tab-content" id="custom-tabs-one-tabContent">
        <div
          :class="[
            loggedInUserGroupId === 'Borrower'
              ? 'tab-pane fade'
              : 'tab-pane fade show active',
          ]"
          id="one"
          role="tabpanel"
          aria-labelledby="kyc1"
        >
          <!-- <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 col-sm-5 col-6">
                <div class="form-group fc">
                  <div class="form-field">
                    <label style="margin-right: 10px" class="exampleInputEmail1"
                      >Filter By:</label
                    >
                    <input type="radio" v-model="filter" value="date" />
                    <label style="margin: 5px; padding-right: 10px">
                      Date
                    </label>

                    <input type="radio" v-model="filter" value="period" />
                    <label style="margin: 5px; padding-right: 10px">
                      Period
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6 col-sm-6">
                        <h1 class="headingt" id="collateralCardHeading">
                          <i class="nav-icon fas fa-tachometer-alt"></i>
                          Collateral Manager Registration
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div
                        class="col-lg-6 col-sm-6"
                        v-if="
                          (loggedInUserGroupId == 'Admin' &&
                            loggedInUserSubGroupId == 0) ||
                          (loggedInUserGroupId == 'Analyst' &&
                            loggedInUserSubGroupId == 0)
                        "
                      >
                        <ul id="sr_btn">
                          <li>
                            <button
                              id="addCollateralButton"
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="$router.push('/add-collateral')"
                            >
                              <i class="icofont-plus"></i>
                              <span class="mob">Add Collateral</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div
                        class="col-lg-6 col-sm-6"
                        v-if="
                          loggedInUserGroupId == 'Admin' ||
                          loggedInUserGroupId == 'Analyst'
                        "
                      >
                        <input
                          id="collateralActive"
                          type="radio"
                          :value="false"
                          @change="destroyTable('collateral')"
                          v-model="inActiveToggleCollateral"
                        />
                        <label
                          for="collateralActive"
                          style="padding-right: 20px"
                          >Active</label
                        >
                        <input
                          id="collateralInActive"
                          type="radio"
                          :value="true"
                          @change="destroyTable('collateral')"
                          v-model="inActiveToggleCollateral"
                        />
                        <label for="collateralInActive">InActive</label>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div
                    class="card-body table-responsive p-0"
                    id="datatable-padding"
                  >
                    <!------menton here style="height: 300px;"-------->
                    <table
                      class="table table-head-fixed text-nowrap table-hover myTable1"
                      id="myTable1"
                    >
                      <thead>
                        <tr>
                          <th>Company Name</th>
                          <th>Reg. Date</th>
                          <th>Status</th>
                          <template
                            v-if="
                              loggedInUserGroupId == 'Admin' &&
                              loggedInUserSubGroupId == 0 &&
                              !inActiveToggleCollateral
                            "
                          >
                            <th class="c">Change Status</th>
                          </template>
                          <th
                            class="center"
                            v-if="loggedInUserGroupId == 'Admin'"
                          >
                            Disputed Flag
                          </th>
                          <template
                            v-if="
                              (loggedInUserGroupId == 'Admin' ||
                                loggedInUserGroupId == 'Analyst' ||
                                loggedInUserGroupId == 'Collateral Manager') &&
                              !inActiveToggleCollateral
                            "
                          >
                            <th class="c center"></th>
                          </template>
                          <th
                            v-if="
                              (loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)) &&
                              !inActiveToggleCollateral
                            "
                            class="c"
                          ></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          id="tdrow"
                          v-for="(collateral, index) in collaterals"
                          :key="collateral.collateral_manager_id"
                        >
                          <template
                            v-if="
                              loggedInUserGroupId == 'Collateral Manager' ||
                              loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0)
                            "
                          >
                            <td
                              v-if="collateral.manager_name !== null"
                              class="ln"
                            >
                              <router-link
                                active-link="active"
                                :to="{
                                  name: 'CollateralManagerOverview',
                                  params: {
                                    id: collateral.collateral_manager_id,
                                  },
                                }"
                                >{{ collateral.company_name }}</router-link
                              >
                            </td>
                            <td class="c" v-else>-</td>
                            <td
                              v-if="collateral.registration_date !== null"
                              :data-sort="
                                new Date(collateral.registration_date).getTime()
                              "
                            >
                              {{ collateral.registration_date }}
                            </td>
                            <td class="c" v-else>-</td>
                            <td
                              v-if="collateral.status !== null"
                              :class="{
                                'c text-success':
                                  collateral.status === 'Approved',
                                'c text-danger':
                                  collateral.status === 'Rejected',
                                'c text-warning':
                                  collateral.status === 'Pending',
                              }"
                            >
                              {{ collateral.status }}
                            </td>
                            <td class="c" v-else>-</td>
                            <td
                              class="c"
                              v-if="
                                loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0 &&
                                !inActiveToggleCollateral
                              "
                            >
                              <div class="status">
                                <select
                                  id="regStatus"
                                  name="regStatus"
                                  class="form-control"
                                  :value="collateral.status"
                                  @change="
                                    onChangeStatus(
                                      $event,
                                      collateral.collateral_manager_id,
                                      collateral.status,
                                      index,
                                      'collateral'
                                    )
                                  "
                                  :disabled="collateral.status !== 'Pending'"
                                >
                                  <option value="Pending">Pending</option>
                                  <option value="Approved">Approved</option>
                                  <option value="Rejected">Rejected</option>
                                </select>
                              </div>
                            </td>
                            <template v-if="loggedInUserGroupId == 'Admin'">
                              <td
                                class="center"
                                v-if="collateral.disputed_flag"
                              >
                                <i
                                  class="icofont-flag"
                                  style="font-size: 20px; color: crimson"
                                ></i>
                              </td>
                              <td class="center" v-else>-</td>
                            </template>
                            <td
                              class="c center"
                              v-if="
                                (loggedInUserGroupId == 'Admin' ||
                                  loggedInUserGroupId == 'Analyst' ||
                                  loggedInUserGroupId ==
                                    'Collateral Manager') &&
                                !inActiveToggleCollateral
                              "
                            >
                              <router-link
                                style="text-decoration: none"
                                :to="{
                                  name: 'EditCollateral',
                                  params: {
                                    id: collateral.collateral_manager_id,
                                  },
                                }"
                              >
                                <i class="icofont-ui-edit text-warning"></i>
                              </router-link>
                            </td>
                            <template
                              v-if="
                                (loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)) &&
                                !inActiveToggleCollateral
                              "
                            >
                              <td
                                v-if="collateral.status != 'Approved'"
                                class="center"
                                :style="{ cursor: 'pointer' }"
                                @click="
                                  deleteCollateral(
                                    collateral.collateral_manager_id
                                  )
                                "
                              >
                                <i class="icofont-trash text-danger"></i>
                              </td>
                              <td class="center" v-else>
                                <i
                                  style="opacity: 0.5"
                                  class="icofont-trash text-danger"
                                ></i>
                              </td>
                            </template>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>
        <!-- /.Tabpane first end-->

        <div
          :class="[
            loggedInUserGroupId === 'Borrower'
              ? 'tab-pane fade show active'
              : 'tab-pane fade',
          ]"
          id="two"
          role="tabpanel"
          aria-labelledby="kyc1"
        >
          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6 col-sm-6">
                        <h1 class="headingt" id="warehouseCardHeading">
                          <i class="nav-icon fas fa-tachometer-alt"></i>
                          Warehouse Details
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div
                        class="col-lg-6 col-sm-6"
                        v-if="
                          loggedInUserGroupId == 'Admin' ||
                          loggedInUserGroupId == 'Analyst'
                        "
                      >
                        <ul id="sr_btn">
                          <li>
                            <button
                              id="addWarehouseButton"
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="$router.push('/add-warehouse')"
                            >
                              <i class="icofont-plus"></i>
                              <span class="mob">Add Warehouse</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div
                        class="col-lg-6 col-sm-6"
                        v-if="
                          loggedInUserGroupId == 'Admin' ||
                          loggedInUserGroupId == 'Analyst'
                        "
                      >
                        <input
                          id="warehouseActive"
                          type="radio"
                          :value="false"
                          @change="destroyTable('warehouse')"
                          v-model="inActiveToggleWarehouse"
                        />
                        <label for="warehouseActive" style="padding-right: 20px"
                          >Active</label
                        >
                        <input
                          id="warehouseInActive"
                          type="radio"
                          :value="true"
                          @change="destroyTable('warehouse')"
                          v-model="inActiveToggleWarehouse"
                        />
                        <label for="warehouseInActive">InActive</label>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div
                    class="card-body table-responsive p-0"
                    id="datatable-padding"
                  >
                    <!------menton here style="height: 300px;"-------->
                    <table
                      class="table table-head-fixed text-nowrap table-hover myTable2"
                      id="myTable2"
                    >
                      <thead>
                        <tr>
                          <th>Warehouse ID</th>
                          <th>Borrower Name</th>
                          <th>Owner Name</th>
                          <th>Status</th>
                          <template
                            v-if="
                              loggedInUserGroupId == 'Admin' &&
                              loggedInUserSubGroupId == 0 &&
                              !inActiveToggleWarehouse
                            "
                          >
                            <th class="c">Change Status</th>
                          </template>
                          <th
                            v-if="
                              (
                                loggedInUserGroupId == 'Admin' ||
                                loggedInUserGroupId == 'Analyst') &&
                              !inActiveToggleWarehouse
                            "
                            class="c center"
                          ></th>
                          <th
                            v-if="
                              (loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)) &&
                              !inActiveToggleWarehouse
                            "
                            class="c"
                          ></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          id="tdrow"
                          v-for="(warehouse, index) in warehouses"
                          :key="warehouse.warehouse_master_id"
                        >
                          <template
                            v-if="
                              loggedInUserGroupId == 'Collateral Manager' ||
                              loggedInUserGroupId == 'Borrower' ||
                              loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0)
                            "
                          >
                            <td class="ln">
                              <router-link
                                active-link="active"
                                :to="{
                                  name: 'WarehouseOverview',
                                  params: {
                                    id: warehouse.warehouse_master_id,
                                  },
                                }"
                                >{{
                                  warehouse.warehouse_registration_id
                                }}</router-link
                              >
                            </td>
                            <td>{{ warehouse.borrower_name }}</td>
                            <td>{{ warehouse.owner_name }}</td>
                            <td
                              :class="{
                                'c text-success':
                                  warehouse.status === 'Approved',
                                'c text-danger':
                                  warehouse.status === 'Rejected',
                                'c text-warning':
                                  warehouse.status === 'Pending',
                              }"
                            >
                              {{ warehouse.status }}
                            </td>
                            <td
                              class="c"
                              v-if="
                                loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0 &&
                                !inActiveToggleWarehouse
                              "
                            >
                              <div class="status">
                                <select
                                  id="regStatus"
                                  name="regStatus"
                                  class="form-control"
                                  :value="warehouse.status"
                                  @change="
                                    onChangeStatus(
                                      $event,
                                      warehouse.warehouse_master_id,
                                      warehouse.status,
                                      index,
                                      'warehouse'
                                    )
                                  "
                                  :disabled="warehouse.status !== 'Pending'"
                                >
                                  <option value="Pending">Pending</option>
                                  <option value="Approved">Approved</option>
                                  <option value="Rejected">Rejected</option>
                                </select>
                              </div>
                            </td>
                            <td
                              class="c center"
                              v-if="
                                (
                                  loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                    loggedInUserSubGroupId == 0)) &&
                                !inActiveToggleWarehouse
                              "
                            >
                              <router-link
                                style="text-decoration: none"
                                :to="{
                                  name: 'EditWarehouse',
                                  params: {
                                    id: warehouse.warehouse_master_id,
                                  },
                                }"
                              >
                                <i class="icofont-ui-edit text-warning"></i>
                              </router-link>
                            </td>
                            <template
                              v-if="
                                (loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)) &&
                                !inActiveToggleWarehouse
                              "
                            >
                              <td
                                v-if="warehouse.status !== 'Rejected'"
                                class="center"
                                :style="{ cursor: 'pointer' }"
                                @click="
                                  deleteWarehouse(
                                    warehouse.warehouse_master_id,
                                    warehouse.status
                                  )
                                "
                              >
                                <i class="icofont-trash text-danger"></i>
                              </td>
                              <td class="center" v-else>
                                <i
                                  style="opacity: 0.5"
                                  class="icofont-trash text-danger"
                                ></i>
                              </td>
                            </template>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="three"
          role="tabpanel"
          aria-labelledby="kyc1"
        >
          <div
            v-if="inventoryTabContent === 'verification-tab'"
            role="tabpanel"
          >
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt" id="inventoryCardHeading">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            Verification
                          </h1>
                        </div>
                        <div class="col-lg-6">
                          <ul id="sr_btn"
                             
                            >
                            <li
                              v-if="
                                  loggedInUserGroupId == 'Admin' ||
                                  (loggedInUserGroupId == 'Analyst' &&
                                    loggedInUserSubGroupId == 0)
                                "
                              >
                              <button
                                id="reportGeneration"
                                type="button"
                                class="btn btn-sm sh_n btn-success"
                                @click="showReportModal()"
                              >
                                <i class="icofont-plus"></i>
                                <span class="mob">Report Generation</span>
                              </button>
                            </li>
                            <li
                              v-if="
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                              ">
                              <button
                                id="addVerification"
                                type="button"
                                class="btn btn-sm sh_n btn-success"
                                @click="
                                  $router.push({
                                    name: 'AddRequest',
                                    params: { id: 0 },
                                  })
                                "
                              >
                                <i class="icofont-plus"></i>
                                <span class="mob">Add Verification</span>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div
                      class="card-body table-responsive p-0"
                      id="datatable-padding"
                    >
                      <table
                        class="table table-head-fixed text-nowrap table-hover verificationTable"
                        id="verificationTable"
                      >
                        <thead>
                          <tr>
                            <th class="col left">Verification ID</th>
                            <th class="col center">Date</th>
                            <th class="col center">Quantity (MT)</th>
                            <th class="col center">Bags</th>
                            <th class="col center">Status</th>

                            <th
                              v-if="
                                loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0
                              "
                              class="col100 center"
                            >
                              Change Status
                            </th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            id="tdrow"
                            v-for="(req, index) in verificationRequests"
                            :key="req.id"
                          >
                            <template
                              v-if="
                                loggedInUserGroupId == 'Collateral Manager' ||
                                loggedInUserGroupId == 'Borrower' ||
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                              "
                            >
                              <td class="ln col left">
                                <router-link
                                  active-link="active"
                                  :to="{
                                    name: 'AddRequest',
                                    params: {
                                      id: req.id,
                                    },
                                  }"
                                  >{{ req.request_id }}</router-link
                                >
                              </td>
                              <td class="col center">{{ req.request_date }}</td>
                              <td class="col right">
                                {{ req.verification_quantity }}
                              </td>
                              <td class="col right" v-if="req.verification_bags">
                                {{ req.verification_bags }}
                              </td>
                              <td class="col right" v-else>0.0</td>
                              <td
                                :class="{
                                  'c text-warning':
                                    req.status === 'Pending' ||
                                    req.status === 'Verification Pending',
                                  'c text-success':
                                    req.status === 'Approved' ||
                                    req.status === 'Verified',
                                }"
                                class="col center"
                              >
                                {{ req.status }}
                              </td>
                              <td
                                class="col100 center"
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0
                                "
                              >
                                <div class="status col100 center">
                                  <select
                                    id="regStatus"
                                    name="regStatus"
                                    class="form-control"
                                    :value="req.status"
                                    @change="
                                      onChangeRequestStatus(
                                        $event,
                                        req.id,
                                        req.request_id,
                                        req.status,
                                        req.warehouse_master_id,
                                        req.mapped_cm_company_id,
                                        index,
                                        'verification_request'
                                      )
                                    "
                                    :disabled="
                                      req.status === 'Approved' ||
                                      req.status === 'Verified'
                                    "
                                  >
                                    <option
                                      v-if="req.status === 'Pending'"
                                      value="Pending"
                                    >
                                      Pending
                                    </option>
                                    <option
                                      v-if="
                                        req.status === 'Pending' ||
                                        req.status === 'Approved'
                                      "
                                      value="Approved"
                                    >
                                      Approved
                                    </option>
                                    <option
                                      v-if="
                                        req.status === 'Verification Pending'
                                      "
                                      value="Verification Pending"
                                    >
                                      Verification Pending
                                    </option>
                                    <option
                                      v-if="
                                        req.status === 'Verification Pending' ||
                                        req.status === 'Verified'
                                      "
                                      value="Verified"
                                    >
                                      Verified
                                    </option>
                                  </select>
                                </div>
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="inventoryTabContent === 'uncleaned-tab'" role="tabpanel">
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt" id="inventoryCardHeading">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            Uncleaned
                          </h1>
                        </div>
                        <div
                          class="col-lg-6"
                          style="text-align: right"
                          v-if="displayStockInHand"
                        >
                          <h1 class="headingt" id="stockInHand">
                            Stock in Hand:
                            {{
                              uncleaned.length ? uncleaned[0].quantity_sum : 0
                            }}
                            MT
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div
                      class="card-body table-responsive p-0"
                      id="datatable-padding"
                    >
                      <table
                        class="table table-head-fixed text-nowrap table-hover uncleanedTable"
                        id="uncleanedTable"
                      >
                        <thead>
                          <tr>
                            <th>Verification ID</th>
                            <th>Warrant Ref ID</th>
                            <th>Quantity (MT)</th>
                            <th>Net Weight (KG)</th>
                            <th>Gross Weight (KG)</th>
                            <th>Bags</th>
                            <th>GRN</th>
                            <th>Date</th>
                            <th>Warehouse</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr id="tdrow" v-for="un in uncleaned" :key="un.id">
                            <template
                              v-if="
                                loggedInUserGroupId == 'Collateral Manager' ||
                                loggedInUserGroupId == 'Borrower' ||
                                loggedInUserGroupId == 'Admin' ||
                                loggedInUserGroupId == 'Analyst'
                              "
                            >
                              <td>
                                {{ un.request_id }}
                              </td>
                              <td class="ln" v-if="un.reference_id">
                                <router-link
                                  active-link="active"
                                  :to="{
                                    name: 'Uncleaned',
                                    params: {
                                      id: un.verification_request_id,
                                      ref_id: un.reference_id,
                                      overview_flag: true,
                                    },
                                  }"
                                  >{{ un.reference_id }}</router-link
                                >
                              </td>
                              <td v-else>-</td>
                              <td class="right" v-if="un.quantity">
                                {{ un.quantity }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="un.net_weight">
                                {{ un.net_weight }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="un.gross_weight">
                                {{ un.gross_weight }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="un.bags">
                                {{ un.bags }}
                              </td>
                              <td class="right" v-else>0</td>
                              <td v-if="un.grn">
                                {{ un.grn }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="un.date">
                                {{ un.date }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="un.warehouse_registration_id">
                                {{ un.warehouse_registration_id }}:
                                {{ un.address }}
                              </td>
                              <td v-else>-</td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="inventoryTabContent === 'cleaning-process-tab'"
            role="tabpanel"
            aria-labelledby="cleaningProcess"
          >
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt" id="inventoryCardHeading">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            Cleaning Process
                          </h1>
                        </div>
                        <div
                          class="col-lg-6"
                          style="text-align: right"
                          v-if="displayStockInHand"
                        >
                          <h1 class="headingt" id="stockInHand">
                            Stock in Hand:
                            {{
                              cleaningProcess.length
                                ? cleaningProcess[0].quantity_sum
                                : 0
                            }}
                            MT
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div
                      class="card-body table-responsive p-0"
                      id="datatable-padding"
                    >
                      <table
                        class="table table-head-fixed text-nowrap table-hover cleaningProcessTable"
                        id="cleaningProcessTable"
                      >
                        <thead>
                          <tr>
                            <th>Verification ID</th>
                            <th>Warrant Ref ID</th>
                            <th>Quantity (MT)</th>
                            <th>Net Weight (KG)</th>
                            <th>Gross Weight (KG)</th>
                            <th>Bags</th>
                            <th>GRN</th>
                            <th>Date</th>
                            <th>Warehouse</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            id="tdrow"
                            v-for="clp in cleaningProcess"
                            :key="clp.id"
                          >
                            <template
                              v-if="
                                loggedInUserGroupId == 'Collateral Manager' ||
                                loggedInUserGroupId == 'Borrower' ||
                                loggedInUserGroupId == 'Admin' ||
                                loggedInUserGroupId == 'Analyst'
                              "
                            >
                              <td>
                                {{ clp.request_id }}
                              </td>
                              <td class="ln" v-if="clp.reference_id">
                                <router-link
                                  active-link="active"
                                  :to="{
                                    name: 'CleaningProcess',
                                    params: {
                                      id: clp.verification_request_id,
                                      ref_id: clp.reference_id,
                                      overview_flag: true,
                                    },
                                  }"
                                  >{{ clp.reference_id }}</router-link
                                >
                              </td>
                              <td v-else>-</td>
                              <td class="right" v-if="clp.quantity">
                                {{ clp.quantity }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="clp.net_weight">
                                {{ clp.net_weight }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="clp.gross_weight">
                                {{ clp.gross_weight }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="clp.bags">
                                {{ clp.bags }}
                              </td>
                              <td class="right" v-else>0</td>
                              <td v-if="clp.grn">
                                {{ clp.grn }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="clp.date">
                                {{ clp.date }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="clp.warehouse_registration_id">
                                {{ clp.warehouse_registration_id }}:
                                {{ clp.address }}
                              </td>
                              <td v-else>-</td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="inventoryTabContent === 'cleaned-tab'" role="tabpanel">
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt" id="inventoryCardHeading">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            Cleaned
                          </h1>
                        </div>
                        <div
                          class="col-lg-6"
                          style="text-align: right"
                          v-if="displayStockInHand"
                        >
                          <h1 class="headingt" id="stockInHand">
                            Stock in Hand:
                            {{ cleaned.length ? cleaned[0].quantity_sum : 0 }}
                            MT
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div
                      class="card-body table-responsive p-0"
                      id="datatable-padding"
                    >
                      <table
                        class="table table-head-fixed text-nowrap table-hover cleanedTable"
                        id="cleanedTable"
                      >
                        <thead>
                          <tr>
                            <th>Verification ID</th>
                            <th>Warrant Ref ID</th>
                            <th>Quantity (MT)</th>
                            <th>Net Weight (KG)</th>
                            <th>Gross Weight (KG)</th>
                            <th>Bags</th>
                            <th>GRN</th>
                            <th>Date</th>
                            <th>Warehouse</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr id="tdrow" v-for="cl in cleaned" :key="cl.id">
                            <template
                              v-if="
                                loggedInUserGroupId == 'Collateral Manager' ||
                                loggedInUserGroupId == 'Borrower' ||
                                loggedInUserGroupId == 'Admin' ||
                                loggedInUserGroupId == 'Analyst'
                              "
                            >
                              <td>
                                {{ cl.request_id }}
                              </td>
                              <td class="ln" v-if="cl.reference_id">
                                <router-link
                                  active-link="active"
                                  :to="{
                                    name: 'Cleaned',
                                    params: {
                                      id: cl.verification_request_id,
                                      ref_id: cl.reference_id,
                                      overview_flag: true,
                                    },
                                  }"
                                  >{{ cl.reference_id }}</router-link
                                >
                              </td>
                              <td v-else>-</td>
                              <td class="right" v-if="cl.quantity">
                                {{ cl.quantity }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="cl.net_weight">
                                {{ cl.net_weight }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="cl.gross_weight">
                                {{ cl.gross_weight }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="cl.bags">
                                {{ cl.bags }}
                              </td>
                              <td class="right" v-else>0</td>
                              <td v-if="cl.grn">
                                {{ cl.grn }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="cl.date">
                                {{ cl.date }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="cl.warehouse_registration_id">
                                {{ cl.warehouse_registration_id }}:
                                {{ cl.address }}
                              </td>
                              <td v-else>-</td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="inventoryTabContent === 'waste-tab'" role="tabpanel">
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt" id="inventoryCardHeading">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            Waste
                          </h1>
                        </div>
                        <div
                          class="col-lg-6"
                          style="text-align: right"
                          v-if="displayStockInHand"
                        >
                          <h1 class="headingt" id="stockInHand">
                            Stock in Hand:
                            {{ waste.length ? waste[0].quantity_sum : 0 }}
                            MT
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div
                      class="card-body table-responsive p-0"
                      id="datatable-padding"
                    >
                      <table
                        class="table table-head-fixed text-nowrap table-hover wasteTable"
                        id="wasteTable"
                      >
                        <thead>
                          <tr>
                            <th>Verification ID</th>
                            <th>Warrant Ref ID</th>
                            <th>Quantity (MT)</th>
                            <th>Net Weight (KG)</th>
                            <th>Gross Weight (KG)</th>
                            <th>Bags</th>
                            <th>GRN</th>
                            <th>Date</th>
                            <th>Warehouse</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr id="tdrow" v-for="wt in waste" :key="wt.id">
                            <template
                              v-if="
                                loggedInUserGroupId == 'Collateral Manager' ||
                                loggedInUserGroupId == 'Borrower' ||
                                loggedInUserGroupId == 'Admin' ||
                                loggedInUserGroupId == 'Analyst'
                              "
                            >
                              <td>
                                {{ wt.request_id }}
                              </td>
                              <td class="ln" v-if="wt.reference_id">
                                <router-link
                                  active-link="active"
                                  :to="{
                                    name: 'Waste',
                                    params: {
                                      id: wt.verification_request_id,
                                      ref_id: wt.reference_id,
                                      overview_flag: true,
                                    },
                                  }"
                                  >{{ wt.reference_id }}</router-link
                                >
                              </td>
                              <td v-else>-</td>
                              <td class="right" v-if="wt.quantity">
                                {{ wt.quantity }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="wt.net_weight">
                                {{ wt.net_weight }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="wt.gross_weight">
                                {{ wt.gross_weight }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="wt.bags">
                                {{ wt.bags }}
                              </td>
                              <td class="right" v-else>0</td>
                              <td v-if="wt.grn">
                                {{ wt.grn }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="wt.date">
                                {{ wt.date }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="wt.warehouse_registration_id">
                                {{ wt.warehouse_registration_id }}:
                                {{ wt.address }}
                              </td>
                              <td v-else>-</td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="inventoryTabContent === 'warrant-info-tab'"
            role="tabpanel"
          >
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt" id="inventoryCardHeading">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            Warrant Info
                          </h1>
                        </div>
                        <div
                          class="col-lg-6"
                          style="text-align: right"
                          v-if="displayStockInHand"
                        >
                          <h1 class="headingt" id="stockInHand">
                            Stock in Hand:
                            {{
                              warrantInfo.length
                                ? warrantInfo[0].quantity_sum
                                : 0
                            }}
                            MT
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div
                      class="card-body table-responsive p-0"
                      id="datatable-padding"
                    >
                      <table
                        class="table table-head-fixed text-nowrap table-hover table-bordered warrantInfoTable"
                        id="warrantInfoTable"
                      >
                        <thead>
                          <tr>
                            <th>Warrant Ref ID</th>
                            <th class="center">Uncleaned</th>
                            <th class="center">Cleaning Process</th>
                            <th class="center">Cleaned</th>
                            <th class="center">Waste</th>
                            <th class="center">Logistics</th>
                            <th class="center">Shipping</th>
                            <th class="center">Release to Offtaker/Market</th>
                            <th class="center">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr id="tdrow" v-for="wi in warrantInfo" :key="wi.id">
                            <template
                              v-if="
                                loggedInUserGroupId == 'Collateral Manager' ||
                                loggedInUserGroupId == 'Borrower' ||
                                loggedInUserGroupId == 'Admin' ||
                                loggedInUserGroupId == 'Analyst'
                              "
                            >
                              <td>
                                {{ wi.reference_id }}
                              </td>
                              <td class="right" v-if="wi.uncleaned">
                                {{ wi.uncleaned }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="wi.cleaning_process">
                                {{ wi.cleaning_process }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="wi.cleaned">
                                {{ wi.cleaned }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="wi.waste">
                                {{ wi.waste }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="wi.logistics_info">
                                {{ wi.logistics_info }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="wi.shipping">
                                {{ wi.shipping }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="wi.delivered">
                                {{ wi.delivered }}
                              </td>
                              <td class="right" v-else>-</td>
                              <td class="right" v-if="wi.total">
                                {{ wi.total }}
                              </td>
                              <td class="right" v-else>-</td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="inventoryTabContent === 'release-request-tab'"
            role="tabpanel"
          >
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt" id="inventoryCardHeading">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            Release Request
                          </h1>
                        </div>
                        <div
                          class="col-lg-6"
                          v-if="
                            loggedInUserGroupId == 'Admin' ||
                            loggedInUserGroupId == 'Analyst'
                          "
                        >
                          <ul id="sr_btn">
                            <li>
                              <button
                                id="addReleaseRequest"
                                type="button"
                                class="btn btn-sm sh_n btn-success"
                                @click="
                                  $router.push({
                                    name: 'ReleaseRequest',
                                    params: {
                                      id: 0,
                                      borrower_id: selectedBorrower,
                                    },
                                  })
                                "
                              >
                                <i class="icofont-plus"></i>
                                <span class="mob">Add Release Request</span>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div
                      class="card-body table-responsive p-0"
                      id="datatable-padding"
                    >
                      <table
                        class="table table-head-fixed text-nowrap table-hover releaseRequestTable"
                        id="releaseRequestTable"
                      >
                        <thead>
                          <tr>
                            <th>Release Req ID</th>
                            <th>Quantity (MT)</th>
                            <th>Collateral</th>
                            <th>Collateral Type</th>
                            <th>Date</th>
                            <th>Release To</th>
                            <th>Status</th>
                            <th
                              v-if="
                                loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0
                              "
                              class="c"
                            >
                              Change Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            id="tdrow"
                            v-for="(rr, index) in releaseRequests"
                            :key="rr.id"
                          >
                            <template
                              v-if="
                                loggedInUserGroupId == 'Collateral Manager' ||
                                loggedInUserGroupId == 'Borrower' ||
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                              "
                            >
                              <td class="ln">
                                <router-link
                                  active-link="active"
                                  :to="{
                                    name: 'ReleaseRequest',
                                    params: {
                                      id: rr.id,
                                    },
                                  }"
                                  >{{ rr.request_id }}</router-link
                                >
                              </td>
                              <td class="right">{{ rr.quantity }}</td>
                              <td>{{ rr.collateral_name }}</td>
                              <td>{{ rr.stage_name }}</td>
                              <td>{{ rr.request_date }}</td>
                              <td>{{ rr.release_to }}</td>
                              <td
                                :class="{
                                  'c text-warning': rr.status === 'Pending',
                                  'c text-success': rr.status === 'Approved',
                                  'c text-danger': rr.status === 'Rejected',
                                }"
                              >
                                {{ rr.status }}
                              </td>
                              <td
                                class="c"
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0
                                "
                              >
                                <div class="status">
                                  <select
                                    id="regStatus"
                                    name="regStatus"
                                    class="form-control"
                                    :value="rr.status"
                                    @change="
                                      onChangeStatus(
                                        $event,
                                        rr.id,
                                        rr.status,
                                        index,
                                        'release_request'
                                      )
                                    "
                                    :disabled="rr.status !== 'Pending'"
                                  >
                                    <option value="Pending">Pending</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                  </select>
                                </div>
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            v-if="inventoryTabContent === 'release-info-tab'"
            role="tabpanel"
          >
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt" id="inventoryCardHeading">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            Release Info
                          </h1>
                        </div>
                        <div
                          class="col-lg-6"
                          v-if="
                            loggedInUserGroupId == 'Borrower' ||
                            loggedInUserGroupId == 'Admin' ||
                            loggedInUserGroupId == 'Analyst' ||
                            loggedInUserGroupId == 'Collateral Manager'
                          "
                        >
                          <ul id="sr_btn">
                            <li>
                              <button
                                id="addReleaseInfo"
                                type="button"
                                class="btn btn-sm sh_n btn-success"
                                @click="
                                  $router.push({
                                    name: 'AddRelease',
                                    params: {
                                      borrower_id: selectedBorrower,
                                    },
                                  })
                                "
                              >
                                <i class="icofont-plus"></i>
                                <span class="mob">Add Release Info</span>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div
                      class="card-body table-responsive p-0"
                      id="datatable-padding"
                    >
                      <table
                        class="table table-head-fixed text-nowrap table-hover releaseInfoTable"
                        id="releaseInfoTable"
                      >
                        <thead>
                          <tr>
                            <th>Release Info ID</th>
                            <th>Released Quantity (MT)</th>
                            <th>Bags</th>
                            <th>Collateral</th>
                            <th>Collateral Type</th>
                            <th>Offtaker Name</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr id="tdrow" v-for="ri in releaseInfo" :key="ri.id">
                            <template
                              v-if="
                                loggedInUserGroupId == 'Collateral Manager' ||
                                loggedInUserGroupId == 'Borrower' ||
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                              "
                            >
                              <td class="ln">
                                <router-link
                                  active-link="active"
                                  :to="{
                                    name: 'ReleaseOverview',
                                    params: {
                                      id: ri.id,
                                    },
                                  }"
                                  >{{ ri.release_id }}</router-link
                                >
                              </td>
                              <td>{{ ri.quantity }}</td>
                              <td>{{ ri.total_bags }}</td>
                              <td>{{ ri.collateral_name }}</td>
                              <td>{{ ri.stage_name }}</td>
                              <td v-if="ri.offtaker_name">
                                {{ ri.offtaker_name }}
                              </td>
                              <td v-else>-</td>
                              <td>{{ ri.date }}</td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- /.content-wrapper -->
      <!-- verification request modal start -->
      <modal name="verification-request">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Assign to name</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="hideModal('assign') & $router.go()"
          >
            <i class="icofont-close-circled"></i>
          </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div class="col-md-12">
            <div class="form-group fc">
              <label for="exampleInputEmail1" class="required-field"
                >Collateral Manager Company</label
              >
              <select
                id="selectedCMCompany"
                class="form-control"
                name="selectedCMCompany"
                v-model="selectedCMCompany"
                @change="onCMCompanyNameChange()"
                :class="{
                  'is-invalid': isSubmitted && selectedCMCompany === null,
                }"
              >
                <option :value="null">--- Select ---</option>
                <option
                  v-for="col in mappedCM"
                  :value="col.collateral_manager_master_id"
                  :key="col.collateral_manager_master_id"
                >
                  {{ col.collateral_id }}: {{ col.company_name }}
                </option>
              </select>
              <div
                v-if="isSubmitted && selectedCMCompany === null"
                class="invalid-feedback"
              >
                <span v-if="selectedCMCompany === null"
                  >Collateral Manager Company is required</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <label>Employees</label>
            <multiselect
              v-model="selectedCMEmployees"
              placeholder="--- Select ---"
              :custom-label="getEmployeeLabel"
              track-by="id"
              :options="collateralManagerEmployees"
              :multiple="true"
              :limit="2"
              ><span slot="noResult"
                >No CM Employee found with given input.</span
              ></multiselect
            >
          </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <div class="mx-auto">
            <button
              type="button"
              class="btn-sm btn btn-success sh"
              @click="onSubmitRequestModal()"
            >
              Submit
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              class="btn-sm btn btn-dark sh"
              @click="hideModal('assign') & $router.go()"
            >
              Cancel
            </button>
          </div>
        </div>
      </modal>
      <!-- ./modal end -->

      <div>
        <custom-loan-report-modal ref="customLoanReportModal">
          <template v-slot:header>
            <h4>Collateral Report Generation</h4>
          </template>
          <div class="row">
                <div class="col-md-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Borrower Name</label
                    >
                    <select
                      id="selectedReportBorrower"
                      class="form-control"
                      name="selectedReportBorrower"
                      v-model="selectedReportBorrower"
                      @change="getCollateralByBorrower()"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedReportBorrower.$error,
                      }"
                      >
                      <option :value="null" disabled>--- Select ---</option>
                      <option
                        v-for="bor in borrowers"
                        :value="bor.borrower_id"
                        :key="bor.borrower_id"
                      >
                        {{ bor.borrower_name }}
                      </option>
                    </select>
                      <div
                        v-if="isSubmitted && $v.selectedReportBorrower.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.selectedReportBorrower.required"
                        >Borrower Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Collateral</label
                      >
                      <select
                        id="selectedReportCollateral"
                        class="form-control"
                        name="selectedReportCollateral"
                        v-model="selectedReportCollateral"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedReportCollateral.$error,
                        }"
                      >
                        <option :value="null" disabled>-- Select --</option>
                        <option 
                          v-for="col in reportCollaterals" 
                          :value="col.id"
                          :key="col.id"
                        >
                          {{ col.collateral_name }}
                        </option>
                      </select>
                        <div
                          v-if="isSubmitted && $v.selectedReportCollateral.$error"
                          class="invalid-feedback"
                        >
                        <span v-if="!$v.selectedReportCollateral.required"
                          >Collateral is required</span
                        >
                      </div>
                    </div>
                  </div>

                <div class="col-md-6">
                  <div class="form-group fc">
                    <label for="selectedCollateralType" class="required-field"
                      >Report Type</label
                    >
                    <select
                      id="selectedCollateralType"
                      class="form-control"
                      name="selectedCollateralType"
                      v-model="selectedCollateralType"
                      disabled
                    >
                      <option :value="null" disabled>--- Select ---</option>
                      <option
                        v-for="col in collateralStages"
                        :value="col.id"
                        :key="col.id"
                      >
                        {{ col.stage_name }}
                      </option>
                    </select>
                  </div>
                </div>
    
                <div class="col-md-6">
                  <div class="form-group fc">
                    <label  class="required-field">Period Type</label>
                    <select
                      id="selectedPeriodType"
                      class="form-control"
                      name="selectedPeriodType"
                      v-model="selectedPeriodType"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedPeriodType.$error,
                      }"
                    >
                      <option :value="null" disabled>--- Select ---</option>
                      <option v-for="report in report_type" :key="report.id" :value="report.id">
                        {{ report.report_type_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedPeriodType.$error"
                      class="invalid-feedback"
                    >
                    <span v-if="!$v.selectedPeriodType.required"
                      >Period Type is required</span
                    >
                  </div>
                  </div>
                </div>
    
                <div v-if="selectedPeriodType == 0" class="col-md-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Monthly Period</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="selectedRegularReportDate"
                      placeholder="Select Period"
                      v-model="selectedRegularReportDate"
                      minimum-view="month"
                      format="MM-yyyy"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{
                        to: new Date(this.backcapBorrowerReportDate),
                        from: lastMonth,
                      }"
                    ></datepicker>
                  </div>
                </div>
    
                <div v-if="selectedPeriodType == 1" class="col-md-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >As of today</label
                    >
                    <datepicker
                      class="custom-width-datepicker1 datepicker"
                      :bootstrap-styling="true"
                      name="selectedCustomReportDate"
                      placeholder="Select Date"
                      v-model="selectedCustomReportDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fas fa-calendar"
                      :calendar-button="true"
                      disabled
                      :disabledDates="{
                        to: new Date(this.backcapBorrowerReportDate),
                        from: new Date(),
                      }"
                    ></datepicker>
                  </div>
                </div>
    
              </div>
          <template v-slot:footer>
            <div class="">
                <button
                  style="margin-right: 6px"
                  type="button"
                  class="btn-sm btn btn-success sh"
                  v-on:click="onClickExcel('excel')"
                >
                  <i class="icofont-file-excel"></i> Excel
                </button>
                <!-- <button
                  type="button"
                  style="margin-right: 6px"
                  class="btn-sm btn btn-danger sh"
                  v-on:click="onClickExcel('pdf')"
                >
                  <i class="icofont-file-pdf"></i> PDF
                </button> -->
                <button
                  type="button"
                  name="saveInfo"
                  class="btn-sm btn btn-primary sh"
                  style="margin-right: 10px;"
                  v-on:click="onCloseReportModal()"
                >
                  <i class="icofont-close-circled"></i> Close
                </button>
              </div>
          </template>
        </custom-loan-report-modal>
      </div>

    </div>
  </div>
  
  <!-- ./wrapper -->
</template>

<script>
import $ from "jquery";
//Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {required} from "vuelidate/lib/validators";
import CustomLoanReportModal from '../loan/CustomLoanReportModal.vue';


export default {
  data() {
    return {
      collaterals: [],
      collateralsApproved: [],
      collateralManagerEmployees: [],
      selectedCMEmployees: [],
      warehouses: [],
      verificationRequests: [],
      releaseRequests: [],
      releaseInfo: [],
      borrowers: [],
      facilities: [],
      Collateral: [],
      uncleaned: [],
      cleaningProcess: [],
      selectedDocs: [],
      cleaned: [],
      waste: [],
      warrantInfo: [],
      warrantInfoTotal: [],
      mappedCM: [],
      warehousesFiltered: [],
      CMUserLoginID: null,
      // set default borrower
      selectedBorrower: null,
      selectedFacility: null,
      selectedStatus: null,
      selectedCMCompany: null,
      selectedWarehouse: null,
      selectedCollateralName: null,
      selectedReportCollateralName: null,
      startDate: null,
      endDate: null,
      changeReqData: null,
      inventoryFilter: false,
      statusFilter: true,
      warehouseFilter: false,
      inventoryTabContent: "verification-tab",
      selectedPeriod: null,
      selectedReport: null,
      reportToDate: new Date(moment(new Date()).subtract(1, "months")),
      reportFromDate: null,
      selectedBorrowerId: null,
      isSubmitted: false,
      inActiveToggleCollateral: false,
      inActiveToggleWarehouse: false,
      displayStockInHand: false,

      selectedReportBorrower: null,
      selectedReportBorrowerName: '',
      selectedReportCollateral: null,
      reportCollaterals: [],
      selectedPeriodType: null,
      selectedCollateralType: 'All',
      collateralStages: [],
      report_type: [
        { id: 0 , report_type_name: "Monthly"},
        { id: 1 , report_type_name: "As of today"},
      ],

      backcapBorrowerReportDate: null,
      selectedRegularReportDate: null,
      selectedCustomReportDate: new Date(),
      lastMonth: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1
      ),
      isSubmitted: false,
    };
  },
  components: {
    Datepicker,
    Multiselect: window.VueMultiselect.default,
    CustomLoanReportModal,
  },
  validations: {
    selectedReportBorrower: {
      required,
    },
    selectedReportCollateral: {
      required,
    },
    selectedPeriodType: {
      required,
    },
  },
  methods: {
    getEmployeeLabel({ employee_registration_id, employee_name }) {
      return `${employee_registration_id} - ${employee_name}`;
    },

    destroyTable(tab) {
      if (tab === "collateral") {
        $("#myTable1").dataTable().fnDestroy();
        if (!this.inActiveToggleCollateral) {
          this.$store.commit("setinactive", "collateral_active");
          this.$router.go();
        } else {
          this.$store.commit("setinactive", "collateral_inactive");
          this.getCollaterals();
        }
      } else if (tab === "warehouse") {
        $("#myTable2").dataTable().fnDestroy();
        if (!this.inActiveToggleWarehouse) {
          this.$store.commit("setinactive", "warehouse_active");
          this.$router.go();
        } else {
          this.$store.commit("setinactive", "warehouse_inactive");
          this.getWarehouses();
        }
      }
    },
    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },

    onCloseReportModal() {
      console.log("onCloseReportModal");
      this.selectedPeriodType = null;
      this.selectedRegularReportDate = null;
      this.selectedCustomReportDate = new Date();
      this.backcapBorrowerReportDate = null;
      this.selectedReportCollateral = null;
      this.reportCollaterals = [];
      this.selectedReportBorrowerName= '';
      this.isSubmitted = false;
      this.$refs.customLoanReportModal.close();
    },

    showReportModal() {
      this.selectedPeriodType = null;
      this.selectedRegularReportDate = null;
      this.selectedCustomReportDate = new Date();
      this.backcapBorrowerReportDate = null;
      this.selectedReportCollateral = null;
      this.reportCollaterals = [];
      this.selectedReportBorrowerName= '';
      this.isSubmitted = false;
      this.getCollateralByBorrower()
      this.$refs.customLoanReportModal.open();
    },

    onClickExcel(fileType) {
      // if(this.selectedReportBorrower == null){
      //   Utils.make_alert("warning", "Please select borrower.");
      //   return;
      // }
      // if(this.selectedReportCollateral == null){
      //   Utils.make_alert("warning", "Please select collateral.");
      //   return;
      // }
      // if(this.selectedCollateralType == null){
      //   Utils.make_alert("warning", "Please select exposure.");
      //   return;
      // }
      // if(this.selectedPeriodType == null){
      //   Utils.make_alert("warning", "Please select period type.");
      //   return;
      // }
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let date = null
      if(this.selectedPeriodType != null){
        if(this.selectedPeriodType == 0){
          if (this.selectedRegularReportDate === null) {
            Utils.make_alert("warning", "Please select Monthly Period.");
            return;
          } else {
            console.log("selectedRegularReportDate",this.selectedRegularReportDate)
            date = moment(this.selectedRegularReportDate).endOf("month").format("MM-DD-YYYY");
          }
        } else if(this.selectedPeriodType == 1){
          if (this.selectedCustomReportDate === null) {
            Utils.make_alert("warning", "Please select As of today date.");
            return;
          } else {
            console.log("selectedCustomReportDate",this.selectedCustomReportDate)
            date = moment(this.selectedCustomReportDate).format("MM-DD-YYYY");
          }
        }
      }
      console.log("date",date)
      const path =
        "/collateral_reports/" +
        this.selectedReportBorrower +
        "/" +
        this.selectedReportCollateral +
        "/" +
        this.selectedPeriodType +
        "/" +
        fileType +
        "/" + 
        date;
        console.log("path",path)
        this.$refs.customLoanReportModal.close();
        Utils.start_loading();
      axios({
        params: { clientId: this.$store.state.client_id , userId: this.$store.getters.getuserId},
        url: path,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          Utils.stop_loading();
          console.log("API CALL DONE")
          console.log("response.data.type",response.data.type)
          if (response.data.type == "application/json") {
            console.log("failed")
            Utils.make_alert(
              "warning",
              "Something went wrong! Unable to download report."
            );
            this.selectedPeriodType = null;
            this.selectedRegularReportDate = null;
            this.selectedCustomReportDate = new Date();
            this.backcapBorrowerReportDate = null;
            this.selectedReportCollateral = null;
            this.selectedCollateralType = 'All';
            this.reportCollaterals = [];
            this.selectedReportBorrowerName= '';
          } else {
            //give report type names as per selectedReport
            console.log("Download")
            console.log("response.data",response.data)
            var reportName;
            var extension;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            // Filename seems to have to be changed unless otherwise provided
            // the extension can be automatically decided
            if (fileType === "excel") {
              extension = ".xlsx";
            } else if (fileType === "pdf") {
              extension = ".pdf";
            }
            reportName = `${this.selectedReportBorrowerName} - Collateral Summary Report`;
            link.setAttribute("download", reportName + extension);
            document.body.appendChild(link);
            link.click();
            this.selectedPeriodType = null;
            this.selectedRegularReportDate = null;
            this.selectedCustomReportDate = new Date();
            this.backcapBorrowerReportDate = null;
            this.selectedReportCollateral = null;
            this.reportCollaterals = [];
            this.selectedReportBorrowerName= '';
            this.selectedCollateralType = 'All';
            //this.$router.go(this.$router.currentRoute)
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.log("error");
          this.selectedPeriodType = null;
          this.selectedRegularReportDate = null;
          this.selectedCustomReportDate = null;
          this.loanId = null;
          this.loanRegId = null;
          this.backcapBorrowerReportDate = null;
        });
    },

    getCollateralByBorrower() {
      console.log("getCollateralByBorrower")
      if(this.selectedReportBorrower == null){
        this.reportCollaterals = [];
        this.selectedReportCollateral = null;
        return;
      }
      let borrower = this.borrowers.find(borrower => borrower.borrower_id == this.selectedReportBorrower)
      this.selectedReportBorrowerName = borrower.borrower_name;
      this.backcapBorrowerReportDate = borrower.registration_date;
      console.log("selectedReportBorrowerName",this.selectedReportBorrowerName)
      console.log("backcapBorrowerReportDate",this.backcapBorrowerReportDate)
      const path = 
      "/get-collateral-by-borrower" +
      "/" +
      this.selectedReportBorrower;
      console.log(path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            this.reportCollaterals = res.data.collateral;
          } else {
            Utils.make_alert("warning","Something went wrong from server!")
          }
          
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
        });
    },

    showModal(type, id, collateral) {
      if (type === "assign") this.$modal.show("verification-request");
      else if (type === "doc") {
        this.selectedDocs = this.cleaningProcessDocs.filter((doc) => {
          if (doc.cleaning_process_id === id) {
            return doc;
          }
        });
        this.$modal.show("doc");
      } else if (type === "uncleanedReport") {
        this.selectedBorrowerId = id;
        this.selectedReportCollateralName = collateral;
        //this function is called on page load and created events. it will show details on Uncleaned Received Info
        const path =
          "received-info-uncleaned-overview/" + id + "/" + collateral;
        Utils.start_loading();
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            Utils.stop_loading();
            if (res.data.status == "success") {
              let data = res.data.received_uncleaned_overview_active_list;
              data.sort((a, b) => {
                return new Date(a.received_date) - new Date(b.received_date);
              });
              this.reportFromDate = new Date(data[0].received_date);
            } else if (res.data.status == "unauthorised") {
              this.$router.push("/collateral");
            } else {
              console.log("Something went wrong from server!");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
            console.log("Something went wrong!");
          });
        this.selectedReport = "Uncleaned Collateral Summary Report";
        this.$modal.show("report");
      } else if (type === "cleanedReport") {
        this.selectedBorrowerId = id;
        this.selectedReportCollateralName = collateral;
        //this function is called on page load and created events. it will show details on Cleaned Received Info
        const path = "received-info-cleaned-overview/" + id + "/" + collateral;
        Utils.start_loading();
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            Utils.stop_loading();
            if (res.data.status == "success") {
              let data = res.data.received_cleaned_overview_active_list;
              data.sort((a, b) => {
                return new Date(a.received_date) - new Date(b.received_date);
              });
              this.reportFromDate = new Date(data[0].received_date);
            } else if (res.data.status == "unauthorised") {
              this.$router.push("/collateral");
            } else {
              console.log("Something went wrong from server!");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
            console.log("Something went wrong!");
          });
        this.selectedReport = "Cleaned Collateral Summary Report";
        this.$modal.show("report");
      }
    },
    hideModal(type) {
      if (type === "assign") this.$modal.hide("verification-request");
      else this.$modal.hide("report");
    },
    onCMCompanyNameChange() {
      let selectedCMCompany = [];
      this.mappedCM.filter((cm) => {
        if (cm.collateral_manager_master_id === this.selectedCMCompany)
          selectedCMCompany = cm;
      });
      let selectedWarehouseCMId = selectedCMCompany.id;
      // this function is used to get mapped CM Company Employees for a particular mapped Warehouse
      Utils.start_loading();
      const path = "get-mapped-cm-employees/" + selectedWarehouseCMId;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.collateralManagerEmployees =
              res.data.warehouse_cm_emp_mapping_list;
            console.log(
              "get mapped CM Company Employees for a particular mapped Warehouse is successful."
            );
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },
    onBorrowerChange() {
      this.selectedReportBorrower = this.selectedBorrower;
      if (this.selectedBorrower) {
        localStorage.setItem("inventoryBorrowerFilter", this.selectedBorrower);
        this.getFilteredFacilities(this.selectedBorrower);
        this.getFilteredWarehouses(this.selectedBorrower);
        this.getFilteredCollaterals();
      }
    },
    filterData() {
      if (this.selectedBorrower === null) {
        Utils.make_alert("warning", "Borrower Filter is required");
        return;
      }
      if (
        (this.startDate !== null && this.endDate === null) ||
        (this.startDate === null && this.endDate !== null)
      ) {
        Utils.make_alert(
          "warning",
          "Both From & To Dates are required to perform Date Filter"
        );
        return;
      } else {
        if (localStorage.getItem("inventoryTab") === "verification-tab") {
          $("#verificationTable").dataTable().fnDestroy();
          this.getRequests();
        } else if (localStorage.getItem("inventoryTab") === "uncleaned-tab") {
          $("#uncleanedTable").dataTable().fnDestroy();
          this.getUncleaned();
        } else if (
          localStorage.getItem("inventoryTab") === "cleaning-process-tab"
        ) {
          $("#cleaningProcessTable").dataTable().fnDestroy();
          this.getCleaningProcesses();
        } else if (localStorage.getItem("inventoryTab") === "cleaned-tab") {
          $("#cleanedTable").dataTable().fnDestroy();
          this.getCleaned();
        } else if (localStorage.getItem("inventoryTab") === "waste-tab") {
          $("#wasteTable").dataTable().fnDestroy();
          this.getWaste();
        } else if (
          localStorage.getItem("inventoryTab") === "warrant-info-tab"
        ) {
          $("#warrantInfoTable").dataTable().fnDestroy();
          this.getWarrantInfo();
        } else if (
          localStorage.getItem("inventoryTab") === "release-request-tab"
        ) {
          $("#releaseRequestTable").dataTable().fnDestroy();
          this.getReleaseRequest();
        } 
        // else if (
        //   localStorage.getItem("inventoryTab") === "release-info-tab"
        // ) {
        //   $("#releaseInfoTable").dataTable().fnDestroy();
        //   this.getReleaseInfo();
        // }
      }
    },
    // reset all filters to null
    resetFilter() {
      // set default borrower
      this.selectedBorrower = localStorage.getItem("inventoryBorrowerFilter");
      if (this.loggedInUserGroupId === "Borrower") {
        this.selectedBorrower = Number(this.loggedInUserSubGroupId);
      }
      this.selectedFacility = null;
      this.onBorrowerChange();
      this.selectedCollateralName = null;
      this.startDate = null;
      this.endDate = null;
      this.selectedStatus = null;
      this.selectedWarehouse = null;
      $("#verificationTable").dataTable().fnDestroy();
      $("#uncleanedTable").dataTable().fnDestroy();
      $("#cleaningProcessTable").dataTable().fnDestroy();
      $("#cleanedTable").dataTable().fnDestroy();
      $("#wasteTable").dataTable().fnDestroy();
      $("#warrantInfoTable").dataTable().fnDestroy();
      $("#releaseRequestTable").dataTable().fnDestroy();
      // $("#releaseInfoTable").dataTable().fnDestroy();
      if (localStorage.getItem("inventoryTab") === "verification-tab") {
        this.getRequests();
      } else if (localStorage.getItem("inventoryTab") === "uncleaned-tab") {
        this.getUncleaned();
      } else if (
        localStorage.getItem("inventoryTab") === "cleaning-process-tab"
      ) {
        this.getCleaningProcesses();
      } else if (localStorage.getItem("inventoryTab") === "cleaned-tab") {
        this.getCleaned();
      } else if (localStorage.getItem("inventoryTab") === "waste-tab") {
        this.getWaste();
      } else if (localStorage.getItem("inventoryTab") === "warrant-info-tab") {
        this.getWarrantInfo();
      } else if (
        localStorage.getItem("inventoryTab") === "release-request-tab"
      ) {
        this.getReleaseRequest();
      }
    },
    onChangeStatus(updatedStatus, id, prevStatus, index, type, event) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            if (type === "collateral") {
              //this function is called when user changes the collateral registration status. The status is updated in the database for that specific collateral.
              const path = "collateral-summary-overview-active";
              Utils.start_loading();
              axios
                .put(
                  path,
                  {
                    type: "status",
                    status: updatedStatus.target.value,
                    collateral_manager_id: id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 6,
                    tabId: 25,
                    clientId: this.$store.state.client_id,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("registration update status: " + res.data.status);
                  if (res.data.status == "success") {
                    Utils.make_alert(
                      "success",
                      "Registration Status updated successfully!"
                    );
                    this.collaterals[index].status = updatedStatus.target.value;
                  } else {
                    Utils.make_alert(
                      "warning",
                      "Something went wrong from server!"
                    );
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.error("error: " + error);
                  Utils.make_alert("warning", "Something went wrong!");
                });
            } else if (type === "warehouse") {
              //this function is called when user changes the warehouse registration status. The status is updated in the database for that specific warehouse.
              const path = "warehouse-summary-overview-active";
              Utils.start_loading();
              axios
                .put(
                  path,
                  {
                    type: "status",
                    status: updatedStatus.target.value,
                    warehouse_id: id,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 9,
                    tabId: 26,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("registration update status: " + res.data.status);
                  if (res.data.status == "success") {
                    Utils.make_alert(
                      "success",
                      "Registration Status updated successfully!"
                    );
                    this.warehouses[index].status = updatedStatus.target.value;
                  } else {
                    Utils.make_alert(
                      "warning",
                      "Something went wrong from server!"
                    );
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.error("error: " + error);
                  Utils.make_alert("warning", "Something went wrong!");
                });
            } else if (type === "verification_request") {
              //this function is called when user changes the verification request registration status. The status is updated in the database for that specific request.
              const path = "update-request-status";
              Utils.start_loading();
              axios
                .put(
                  path,
                  {
                    type: "status",
                    status: updatedStatus,
                    id: id,
                    request_id: this.changeReqData.request_id,
                    mappedCMCompany: this.selectedCMCompany,
                    collateral_employees: this.selectedCMEmployees,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    tabId: 28,
                    moduleId: 11,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("registration update status: " + res.data.status);
                  if (res.data.status == "success") {
                    Utils.make_alert(
                      "success",
                      "Registration Status updated successfully!"
                    );
                    this.verificationRequests[index].status = updatedStatus;
                  } else {
                    Utils.make_alert(
                      "warning",
                      "Something went wrong from server!"
                    );
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.error("error: " + error);
                });
            } else if (type === "release_request") {
              //this function is called when user changes the release request registration status. The status is updated in the database for that specific request.
              const path =
                "release-request-summary/" +
                null +
                "/" +
                null +
                "/" +
                null +
                "/" +
                null +
                "/" +
                null;
              Utils.start_loading();
              axios
                .put(
                  path,
                  {
                    type: "status",
                    status: updatedStatus.target.value,
                    id: id,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    tabId: 29,
                    moduleId: 11,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("registration update status: " + res.data.status);
                  if (res.data.status == "success") {
                    Utils.make_alert(
                      "success",
                      "Registration Status updated successfully!"
                    );
                    this.releaseRequests[index].status =
                      updatedStatus.target.value;
                  } else {
                    Utils.make_alert(
                      "warning",
                      "Something went wrong from server!"
                    );
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.error("error: " + error);
                });
            }
          } else {
            if (type === "verification_request") {
              event.target.value = prevStatus;
            } else {
              updatedStatus.target.value = prevStatus;
            }
          }
        },
      });
    }, //onChangeStatus close

    onChangeRequestStatus(
      updatedStatus,
      id,
      request_id,
      prevStatus,
      warehouse_id,
      collateral_manager_id,
      index,
      type
    ) {
      // get mapped CM Companies for a particular Warehouse
      Utils.start_loading();
      const path = "get-mapped-cm/" + warehouse_id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.mappedCM = res.data.warehouse_cm_mapping_list;
            console.log(
              "get mapped CM Companies for a particular Warehouse is successful."
            );
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });

      this.changeReqData = {
        updatedStatus: updatedStatus.target.value,
        updatedStatusEvent: updatedStatus,
        id: id,
        request_id: request_id,
        prevStatus: prevStatus,
        index: index,
        type: type,
      };
      if (collateral_manager_id) {
        this.selectedCMCompany = collateral_manager_id;
        //this function is called on page load and created events. it will show details on Request with Requestid equal to this.id
        const path = "single-request-summary-overview/" + id;
        Utils.start_loading();
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            Utils.stop_loading();
            if (res.data.status == "success") {
              this.selectedCMEmployees =
                res.data.verification_request_details.verification_cm_emp_mapping;
            } else if (res.data.status == "unauthorised") {
              this.$router.push("/collateral");
            } else {
              console.log("Something went wrong from server!");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            Utils.make_alert(
              "warning",
              error.response.status === 400
                ? error.response.data.status
                : "Something went wrong!"
            );
            console.error("error: " + error);
          });
      }
      if (updatedStatus.target.value === "Approved") {
        this.showModal("assign");
      } else {
        this.onSubmitRequestModal();
      }
    }, //onChangeRequestStatus close
    onSubmitRequestModal() {
      this.isSubmitted = true;
      if (
        this.selectedCMCompany !== null ||
        this.changeReqData.updatedStatus === "Rejected"
      ) {
        this.hideModal("assign");
        this.onChangeStatus(
          this.changeReqData.updatedStatus,
          this.changeReqData.id,
          this.changeReqData.prevStatus,
          this.changeReqData.index,
          this.changeReqData.type,
          this.changeReqData.updatedStatusEvent
        );
      }
    },
    deleteCollateral(collateralId) {
      //this function will be called to delete a specific Collateral
      this.$confirm({
        auth: false,
        message: "Are you sure you want to permanently delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete("collateral-summary-overview-active", {
                headers: {
                  "Content-type": "application/json",
                },
                data: {
                  collateral_manager_id: collateralId,
                  userId: this.$store.getters.getuserId,
                  moduleId: 6,
                  tabId: 25,
                  clientId: this.$store.state.client_id,
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Collateral Manager deleted successfully!"
                  );
                  setTimeout(() => this.$router.go(), 1500);

                  //if you want to send any data into server before redirection then you can do it here
                } else if (response.data.status == "Deletion not permitted") {
                  Utils.make_alert(
                    "warning",
                    "Delete operation not permitted."
                  );
                } else {
                  Utils.make_alert(
                    "success",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                Utils.make_alert(
                  "warning",
                  error.response.status === 400
                    ? error.response.data.status
                    : "Something went wrong!"
                );
                console.log("error: " + error);
              });
          }
        },
      });
    }, //deleteCollateral()  close

    deleteWarehouse(warehouseId, status) {
      //this function will be called to delete a specific warehouse
      this.$confirm({
        auth: false,
        message: "Are you sure you want to permanently delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete("warehouse-summary-overview-active", {
                headers: {
                  "Content-type": "application/json",
                },
                data: {
                  warehouse_master_id: warehouseId,
                  status: status,
                  userId: this.$store.getters.getuserId,
                  moduleId: 9,
                  tabId: 26,
                  clientId: this.$store.state.client_id,
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Warehouse deleted successfully!"
                  );
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(() => this.$router.go(), 1500);
                } else if (response.data.status == "deletion_not_allowed") {
                  Utils.make_alert(
                    "warning",
                    "Delete operation not permitted."
                  );
                } else {
                  Utils.make_alert(
                    "success",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.log("error: " + error);
                Utils.make_alert(
                  "warning",
                  error.response.status === 400
                    ? error.response.data.status
                    : "Something went wrong!"
                );
              });
          }
        },
      });
    }, //deleteWarehouse()  close

    deleteRequest(id, request_id, status, requestType) {
      //this function will be called to delete a specific Request
      this.$confirm({
        auth: false,
        message: "Are you sure you want to permanently delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete("delete-request", {
                headers: {
                  "Content-type": "application/json",
                },
                data: {
                  id: id,
                  request_id: request_id,
                  status: status,
                  requestType: requestType,
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert("success", "Request deleted successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(() => this.$router.go(), 1500);
                } else if (response.data.status == "deletion_not_allowed") {
                  Utils.make_alert(
                    "warning",
                    "Delete operation not permitted."
                  );
                } else {
                  Utils.make_alert(
                    "success",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.log("error: " + error);
                Utils.make_alert("success", "Something went wrong!");
                //this.$router.go(this.$router.currentRoute)
              });
          }
        },
      });
    }, //deleteRequest()  close

    deleteRelease(id, request_id) {
      //this function will be called to delete a specific Release
      this.$confirm({
        auth: false,
        message: "Are you sure you want to permanently delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete("delete-release", {
                headers: {
                  "Content-type": "application/json",
                },
                data: {
                  id: id,
                  request_id: request_id,
                  userId: this.$store.getters.getuserId,
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert("success", "Release deleted successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(() => this.$router.go(), 1500);
                } else if (response.data.status == "deletion_not_allowed") {
                  Utils.make_alert(
                    "warning",
                    "Delete operation not permitted."
                  );
                } else {
                  Utils.make_alert(
                    "success",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.log("error: " + error);
                Utils.make_alert("success", "Something went wrong!");
                //this.$router.go(this.$router.currentRoute)
              });
          }
        },
      });
    }, //deleteRelease()  close

    getCollaterals() {
      //this function will be called to get list of collaterals
      const path = this.inActiveToggleCollateral
        ? "collateral-summary-overview-inactive"
        : "collateral-summary-overview-active";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.collaterals = this.inActiveToggleCollateral
              ? res.data.collateral_inactive_overview
              : res.data.collateral_active_overview;
            if(this.collaterals.length > 0) {
              this.collaterals.forEach(item => {
                if (item.registration_date != '-' && item.registration_date != null) {
                  item.registration_date = moment(item.registration_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.registration_date = '-';
                }
              });
            }
            if (this.loggedInUserGroupId === "Collateral Manager") {
              this.collaterals = this.collaterals.filter(
                (col) =>
                  col.collateral_manager_id.toString() ===
                  this.loggedInUserSubGroupId
              );
            } else {
              setTimeout(
                () =>
                  $("#myTable1").DataTable({
                    aaSorting: [],
                    columnDefs: [
                      {
                        orderable: false,
                        targets: this.inActiveToggleCollateral
                          ? []
                          : this.loggedInUserGroupId === "Analyst"
                          ? [3,4]
                          : [3, 4, 5, 6],
                      },
                    ],
                  }),
                100
              );
            }
            console.log("get collaterals is successful.");
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },

    getWarehouses() {
      //this function will be called to get list of warehouses
      const path = this.inActiveToggleWarehouse
        ? "warehouse-summary-overview-inactive"
        : "warehouse-summary-overview-active";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.warehouses = this.inActiveToggleWarehouse
              ? res.data.warehouse_overview_inactive_list
              : res.data.warehouse_overview_active_list;
              console.log("warehouses before filter",this.warehouses)
              console.log("this.loggedInUserSubGroupId",this.loggedInUserSubGroupId)
            let mapped_cm = res.data.warehouse_cm_mapping_active_list;
            console.log("mapped_cm before filter",mapped_cm)
            if (this.loggedInUserGroupId === "Borrower")
              this.warehouses = this.warehouses.filter(
                (war) =>
                  war.borrower_id.toString() === this.loggedInUserSubGroupId
              );
            if (this.loggedInUserGroupId === "Collateral Manager") {
              mapped_cm = mapped_cm.filter(
                (war) =>
                  war.collateral_manager_master_id.toString() ===
                  this.loggedInUserSubGroupId
              );
              console.log("mapped_cm after filter collateral manager",mapped_cm)
              mapped_cm = mapped_cm.map(({ id }) => id);
              console.log("mapped_cm after map",mapped_cm)
              this.warehouses = this.warehouses.filter((war) =>
              mapped_cm.includes(war.warehouse_master_id)
              );
              console.log("warehouses final",this.warehouses)
            }
            setTimeout(
              () =>
                $("#myTable2").DataTable({
                  aaSorting: [],
                  columnDefs: [
                    {
                      orderable: false,
                      targets:
                        this.inActiveToggleWarehouse ||
                        this.loggedInUserGroupId === "Borrower" ||
                        this.loggedInUserGroupId === "Collateral Manager"
                          ? []
                          : this.loggedInUserGroupId === "Analyst"
                          ? [4,5]
                          : [4, 5, 6],
                    },
                  ],
                }),
              100
            );
            console.log("get warehouses is successful.");
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },
    getRequests() {
      //this function will be called to get list of Verification Requests
      if (this.startDate != null) {
        this.startDate = moment(this.startDate).format("MM-DD-YYYY");
      }
      if (this.endDate != null) {
        this.endDate = moment(this.endDate).format("MM-DD-YYYY");
      }
      const path =
        "request-summary-overview/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility +
        "/" +
        this.selectedCollateralName +
        "/" +
        this.startDate +
        "/" +
        this.endDate +
        "/" +
        this.selectedStatus;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();

          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.verificationRequests = res.data.request_summary;
            if(this.verificationRequests.length > 0) {
              this.verificationRequests.forEach(item => {
                if (item.request_date != '-' && item.request_date != null) {
                  item.request_date = moment(item.request_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.request_date = '-';
                }
              });
            }
            if (this.loggedInUserGroupId === "Collateral Manager") {
              let reqs = [];
              this.verificationRequests.filter((req) => {
                if (req.mapped_cm_company_id) {
                  if (
                    req.mapped_cm_company_id.toString() ===
                    this.loggedInUserSubGroupId
                  ) {
                    reqs.push(req);
                  }
                }
              });
              this.verificationRequests = reqs;
            }
            setTimeout(
              () =>
                $("#verificationTable").DataTable({
                  aaSorting: [],
                  columnDefs: [
                    {
                      orderable: false,
                      targets:
                        this.loggedInUserGroupId === "Admin"
                          ? [5]
                          : this.loggedInUserGroupId === "Collateral Manager"
                          ? [4]
                          : [4],
                    },
                  ],
                }),
              100
            );
            console.log("get verification requests is successful.");
            // this.showAddProfitReturnModal();
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },
    getUncleaned() {
      //this function will be called to get list of Uncleaned details
      if (this.startDate != null) {
        this.startDate = moment(this.startDate).format("MM-DD-YYYY");
      }
      if (this.endDate != null) {
        this.endDate = moment(this.endDate).format("MM-DD-YYYY");
      }
      const path =
        "uncleaned-summary/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility +
        "/" +
        this.selectedCollateralName +
        "/" +
        this.startDate +
        "/" +
        this.endDate +
        "/" +
        this.selectedWarehouse;
      Utils.start_loading();
      axios
        .get(path, {
          params: {
            clientId: this.$store.state.client_id,
          },
        })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.uncleaned = res.data.uncleaned_summary;
            if(this.uncleaned.length > 0) {
              this.uncleaned.forEach(item => {
                if (item.date != '-' && item.date != null) {
                  item.date = moment(item.date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.date = '-';
                }
              });
            }
            this.displayStockInHand = this.selectedWarehouse ? true : false;
            // if (this.loggedInUserGroupId === "Borrower")
            //   this.uncleaned = this.uncleaned.filter(
            //     (un) =>
            //       un.borrower_id.toString() === this.loggedInUserSubGroupId
            //   );
            if (this.loggedInUserGroupId === "Collateral Manager") {
              this.uncleaned = this.uncleaned.filter(
                (un) =>
                  un.mapped_cm_company_id.toString() ===
                  this.loggedInUserSubGroupId
              );
            }
            setTimeout(
              () =>
                $("#uncleanedTable").DataTable({
                  aaSorting: [],
                  // columnDefs: [{ orderable: false, targets: [1, 3, 4, 5] }],
                }),
              100
            );
            console.log("get uncleaned info is successful.");
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },
    getCleaningProcesses() {
      //this function will be called to get list of Cleaning Process details
      if (this.startDate != null) {
        this.startDate = moment(this.startDate).format("MM-DD-YYYY");
      }
      if (this.endDate != null) {
        this.endDate = moment(this.endDate).format("MM-DD-YYYY");
      }
      const path =
        "cleaning-process-summary/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility +
        "/" +
        this.selectedCollateralName +
        "/" +
        this.startDate +
        "/" +
        this.endDate +
        "/" +
        this.selectedWarehouse;
      Utils.start_loading();
      axios
        .get(path, {
          params: {
            clientId: this.$store.state.client_id,
            user: this.loggedInUserGroupId,
            subgroupId: this.loggedInUserSubGroupId,
          },
        })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.cleaningProcess = res.data.cleaning_process_summary;
            if(this.cleaningProcess.length > 0) {
              this.cleaningProcess.forEach(item => {
                if (item.date != '-' && item.date != null) {
                  item.date = moment(item.date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.date = '-';
                }
              });
            }
            this.displayStockInHand = this.selectedWarehouse ? true : false;
            // if (this.loggedInUserGroupId === "Borrower")
            //   this.cleaningProcess = this.cleaningProcess.filter(
            //     (un) =>
            //       un.borrower_id.toString() === this.loggedInUserSubGroupId
            //   );
            if (this.loggedInUserGroupId === "Collateral Manager") {
              this.cleaningProcess = this.cleaningProcess.filter(
                (cp) =>
                  cp.mapped_cm_company_id.toString() ===
                  this.loggedInUserSubGroupId
              );
            }
            setTimeout(
              () =>
                $("#cleaningProcessTable").DataTable({
                  aaSorting: [],
                  // columnDefs: [{ orderable: false, targets: [1, 3, 4, 5] }],
                }),
              100
            );
            console.log("get cleaning process is successful.");
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },
    getCleaned() {
      //this function will be called to get list of Cleaned details
      if (this.startDate != null) {
        this.startDate = moment(this.startDate).format("MM-DD-YYYY");
      }
      if (this.endDate != null) {
        this.endDate = moment(this.endDate).format("MM-DD-YYYY");
      }
      const path =
        "cleaned-summary/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility +
        "/" +
        this.selectedCollateralName +
        "/" +
        this.startDate +
        "/" +
        this.endDate +
        "/" +
        this.selectedWarehouse;
      Utils.start_loading();
      axios
        .get(path, {
          params: {
            clientId: this.$store.state.client_id,
            user: this.loggedInUserGroupId,
            subgroupId: this.loggedInUserSubGroupId,
          },
        })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.cleaned = res.data.cleaned_summary;
            if(this.cleaned.length > 0) {
              this.cleaned.forEach(item => {
                if (item.date != '-' && item.date != null) {
                  item.date = moment(item.date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.date = '-';
                }
              });
            }
            this.displayStockInHand = this.selectedWarehouse ? true : false;
            // if (this.loggedInUserGroupId === "Borrower")
            //   this.cleaned = this.cleaned.filter(
            //     (un) =>
            //       un.borrower_id.toString() === this.loggedInUserSubGroupId
            //   );
            if (this.loggedInUserGroupId === "Collateral Manager") {
              this.cleaned = this.cleaned.filter(
                (cl) =>
                  cl.mapped_cm_company_id.toString() ===
                  this.loggedInUserSubGroupId
              );
            }
            setTimeout(
              () =>
                $("#cleanedTable").DataTable({
                  aaSorting: [],
                  // columnDefs: [{ orderable: false, targets: [1, 3, 4, 5] }],
                }),
              100
            );
            console.log("get cleaned info is successful.");
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },
    getWaste() {
      //this function will be called to get list of Waste details
      if (this.startDate != null) {
        this.startDate = moment(this.startDate).format("MM-DD-YYYY");
      }
      if (this.endDate != null) {
        this.endDate = moment(this.endDate).format("MM-DD-YYYY");
      }
      const path =
        "waste-summary/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility +
        "/" +
        this.selectedCollateralName +
        "/" +
        this.startDate +
        "/" +
        this.endDate +
        "/" +
        this.selectedWarehouse;
      Utils.start_loading();
      axios
        .get(path, {
          params: {
            clientId: this.$store.state.client_id,
            user: this.loggedInUserGroupId,
            subgroupId: this.loggedInUserSubGroupId,
          },
        })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.waste = res.data.waste_summary;
            if(this.waste.length > 0) {
              this.waste.forEach(item => {
                if (item.date != '-' && item.date != null) {
                  item.date = moment(item.date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.date = '-';
                }
              });
            }
            this.displayStockInHand = this.selectedWarehouse ? true : false;
            // if (this.loggedInUserGroupId === "Borrower")
            //   this.waste = this.waste.filter(
            //     (un) =>
            //       un.borrower_id.toString() === this.loggedInUserSubGroupId
            //   );
            if (this.loggedInUserGroupId === "Collateral Manager") {
              this.waste = this.waste.filter(
                (wt) =>
                  wt.mapped_cm_company_id.toString() ===
                  this.loggedInUserSubGroupId
              );
            }
            setTimeout(
              () =>
                $("#wasteTable").DataTable({
                  aaSorting: [],
                  // columnDefs: [{ orderable: false, targets: [1, 3, 4, 5] }],
                }),
              100
            );
            console.log("get waste info is successful.");
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },
    getWarrantInfo() {
      //this function will be called to get list of Warrant Info details
      const path =
        "warrant-info-summary/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility +
        "/" +
        this.selectedCollateralName +
        "/" +
        this.selectedWarehouse;
      Utils.start_loading();
      axios
        .get(path, {
          params: {
            clientId: this.$store.state.client_id,
          },
        })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.warrantInfo = res.data.warrant_summary;
            this.displayStockInHand = this.selectedWarehouse ? true : false;
            let borrower_name = "";
            if (this.warrantInfo.length) {
              borrower_name = this.warrantInfo[0].borrower_name;
            }
            // if (this.loggedInUserGroupId === "Borrower")
            //   this.waste = this.waste.filter(
            //     (un) =>
            //       un.borrower_id.toString() === this.loggedInUserSubGroupId
            //   );
            // if (this.loggedInUserGroupId === "Collateral Manager") {
            //   this.waste = this.waste.filter(
            //     (un) =>
            //       un.collateral_manager_id.toString() ===
            //       this.loggedInUserSubGroupId
            //   );
            // }
            setTimeout(
              () =>
                $("#warrantInfoTable").DataTable({
                  aaSorting: [],
                  dom: "l<'float-right'B>frtip",
                  buttons: [
                    {
                      extend: "excel",
                      className: "btn btn-primary",
                      title: `${borrower_name} - Warrant Info`,
                      customize: function (xlsx) {
                        var sheet = xlsx.xl.worksheets["sheet1.xml"];
                        $("row:not(:first)", sheet).each(function (index) {
                          $("c", this).attr("s", "25");
                        });
                        $("row:eq(1) c", sheet).attr("s", "2");
                      },
                    },
                  ],
                  // columnDefs: [{ orderable: false, targets: [1, 3, 4, 5] }],
                }),
              100
            );
            console.log("get warrant info is successful.");
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },
    getReleaseRequest() {
      //this function will be called to get list of Release Request details
      if (this.startDate != null) {
        this.startDate = moment(this.startDate).format("MM-DD-YYYY");
      }
      if (this.endDate != null) {
        this.endDate = moment(this.endDate).format("MM-DD-YYYY");
      }
      const path =
        "release-request-summary/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility +
        "/" +
        this.selectedCollateralName +
        "/" +
        this.startDate +
        "/" +
        this.endDate;
      Utils.start_loading();
      axios
        .get(path, {
          params: {
            clientId: this.$store.state.client_id,
          },
        })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.releaseRequests = res.data.release_request_summary;
            if(this.releaseRequests.length > 0) {
              this.releaseRequests.forEach(item => {
                if (item.request_date != '-' && item.request_date != null) {
                  item.request_date = moment(item.request_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.request_date = '-';
                }
              });
            }
            // if (this.loggedInUserGroupId === "Borrower")
            //   this.waste = this.waste.filter(
            //     (un) =>
            //       un.borrower_id.toString() === this.loggedInUserSubGroupId
            //   );
            if (this.loggedInUserGroupId === "Collateral Manager") {
              this.releaseRequests = this.releaseRequests.filter(
                (rr) =>
                  (rr.collateral_manager_id !== null
                    ? rr.collateral_manager_id.toString()
                    : rr.collateral_manager_id) === this.loggedInUserSubGroupId
              );
            }
            setTimeout(
              () =>
                $("#releaseRequestTable").DataTable({
                  aaSorting: [],
                  columnDefs: [
                    {
                      orderable: false,
                      targets: this.loggedInUserGroupId === "Admin" ? [7] : [],
                    },
                  ],
                }),
              100
            );
            console.log("get release requests is successful.");
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },
    getReleaseInfo() {
      //this function will be called to get list of Release Info details
      if (this.startDate != null) {
        this.startDate = moment(this.startDate).format("MM-DD-YYYY");
      }
      if (this.endDate != null) {
        this.endDate = moment(this.endDate).format("MM-DD-YYYY");
      }
      const path =
        "release-info-summary/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility +
        "/" +
        this.selectedCollateralName +
        "/" +
        this.startDate +
        "/" +
        this.endDate;
      Utils.start_loading();
      axios
        .get(path, {
          params: {
            clientId: this.$store.state.client_id,
          },
        })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.releaseInfo = res.data.release_info_summary;
            // if (this.loggedInUserGroupId === "Borrower")
            //   this.waste = this.waste.filter(
            //     (un) =>
            //       un.borrower_id.toString() === this.loggedInUserSubGroupId
            //   );
            // if (this.loggedInUserGroupId === "Collateral Manager") {
            //   this.waste = this.waste.filter(
            //     (un) =>
            //       un.collateral_manager_id.toString() ===
            //       this.loggedInUserSubGroupId
            //   );
            // }
            setTimeout(
              () =>
                $("#releaseInfoTable").DataTable({
                  aaSorting: [],
                  // columnDefs: [{ orderable: false, targets: [1, 3, 4, 5] }],
                }),
              100
            );
            console.log("get release info is successful.");
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },
    // this function will be called to get list of borrowers
    getBorrowers() {
      let path = null;
      if (this.loggedInUserGroupId === "Collateral Manager") {
        path = "borrower-master-with-cm/" + this.loggedInUserSubGroupId;
      } else {
        path = "borrower-master-with-cm/" + null;
      }
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.borrowers = res.data.borrowers;
          if (this.borrowers.length) {
            this.selectedBorrower = this.borrowers[0].borrower_id;
          }
          if (this.loggedInUserGroupId === "Borrower") {
            this.selectedBorrower = Number(this.loggedInUserSubGroupId);
          }
          console.log("borrowers",this.borrowers)
          let borrowerFilter = localStorage.getItem("inventoryBorrowerFilter");
          if (borrowerFilter === null || borrowerFilter === "null") {
            localStorage.setItem(
              "inventoryBorrowerFilter",
              this.selectedBorrower
            );
          } else {
            this.selectedBorrower = borrowerFilter;
          }
          this.onBorrowerChange();
          $("#verificationTable").dataTable().fnDestroy();
          $("#uncleanedTable").dataTable().fnDestroy();
          $("#cleaningProcessTable").dataTable().fnDestroy();
          $("#cleanedTable").dataTable().fnDestroy();
          $("#wasteTable").dataTable().fnDestroy();
          $("#warrantInfoTable").dataTable().fnDestroy();
          $("#releaseRequestTable").dataTable().fnDestroy();
          if (localStorage.getItem("inventoryTab") === "verification-tab") {
            this.getRequests();
          } else if (localStorage.getItem("inventoryTab") === "uncleaned-tab") {
            this.getUncleaned();
          } else if (
            localStorage.getItem("inventoryTab") === "cleaning-process-tab"
          ) {
            this.getCleaningProcesses();
          } else if (localStorage.getItem("inventoryTab") === "cleaned-tab") {
            this.getCleaned();
          } else if (localStorage.getItem("inventoryTab") === "waste-tab") {
            this.getWaste();
          } else if (localStorage.getItem("inventoryTab") === "warrant-info-tab") {
            this.getWarrantInfo();
          } else if (
            localStorage.getItem("inventoryTab") === "release-request-tab"
          ) {
            this.getReleaseRequest();
          } else {
            this.getRequests();
          }
          if (res.data.status == "success") {
            console.log("get borrowers is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    getFilteredFacilities(id) {
      //this function is called to get list of open facilities by borrower id
      let path = null;
      if (this.loggedInUserGroupId === "Collateral Manager") {
        path = "/facility-setup-borrower-cm/" + id + "/" + this.loggedInUserSubGroupId;
      } else {
        path = "/facility-setup-borrower-cm/" + id + "/" + null;
      }
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.facilities = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFilteredWarehouses(id) {
      //this function is called to get list of filtered warehouses
      const path = "/warehouse-filter/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.warehousesFiltered = res.data.warehouses;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFilteredCollaterals() {
      //this function is called to get list of filtered collaterals
      const path =
        "/collateral-filter/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.Collateral = res.data.collaterals;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of collateral stages
    getCollateralStages() {
      const path = "collateral-stage-master";
      axios
        .get(path)
        .then((res) => {
          this.collateralStages = res.data.collateral_stages;
          this.collateralStages = this.collateralStages.slice(0, 4);
          this.collateralStages.push({
            id:'All',
            stage_name:'All Exposures'
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    setTab(tab, type) {
      //handling inventory management subtabs operations
      if (type === "inventory") {
        if (localStorage.getItem("inventoryTab") !== tab) {
          let prevTab = localStorage.getItem("inventoryTab");
          localStorage.setItem("inventoryTab", tab);
          this.inventoryTabContent = tab;
          this.displayStockInHand = false;
          if (tab === "verification-tab") {
            this.statusFilter = true;
            this.warehouseFilter = false;
            this.resetFilter();
          } else if (tab === "uncleaned-tab") {
            this.statusFilter = false;
            this.warehouseFilter = true;
            this.resetFilter();
          } else if (tab === "cleaning-process-tab") {
            this.statusFilter = false;
            this.warehouseFilter = true;
            this.resetFilter();
          } else if (tab === "cleaned-tab") {
            this.statusFilter = false;
            this.warehouseFilter = true;
            this.resetFilter();
          } else if (tab === "waste-tab") {
            this.statusFilter = false;
            this.warehouseFilter = true;
            this.resetFilter();
          } else if (tab === "warrant-info-tab") {
            this.statusFilter = false;
            this.warehouseFilter = true;
            this.resetFilter();
          } else if (tab === "release-request-tab") {
            if (prevTab === "verification-tab") this.$router.go();
            this.statusFilter = false;
            this.warehouseFilter = false;
            this.resetFilter();
          }
        } else {
          if (tab === "verification-tab") {
            this.statusFilter = true;
            this.warehouseFilter = false;
          } else if (tab === "uncleaned-tab") {
            this.statusFilter = false;
            this.warehouseFilter = true;
          } else if (tab === "cleaning-process-tab") {
            this.statusFilter = false;
            this.warehouseFilter = true;
          } else if (tab === "cleaned-tab") {
            this.statusFilter = false;
            this.warehouseFilter = true;
          } else if (tab === "waste-tab") {
            this.statusFilter = false;
            this.warehouseFilter = true;
          } else if (tab === "warrant-info-tab") {
            this.statusFilter = false;
            this.warehouseFilter = true;
          } else if (tab === "release-request-tab") {
            this.statusFilter = false;
            this.warehouseFilter = false;
          }
        }
      } //handling collateral tabs operations
      else {
        if (tab === "custom-tabs-one-inventory-tab") {
          this.inventoryFilter = true;
          let inventoryTab = localStorage.getItem("inventoryTab");
          if (inventoryTab === null) {
            localStorage.setItem("inventoryTab", "verification-tab");
          } else {
            if (inventoryTab === "release-request-tab") {
              setTimeout(() => {
                if (document.getElementById("warrant-info-tab")) {
                  document.getElementById("warrant-info-tab").click();
                }
              }, 100);
              setTimeout(() => {
                if (document.getElementById("release-request-tab")) {
                  document.getElementById("release-request-tab").click();
                }
              }, 100);
              this.inventoryTabContent = "release-request-tab";
            } else {
              setTimeout(() => {
                if (document.getElementById(inventoryTab)) {
                  document.getElementById(inventoryTab).click();
                }
              }, 100);
              this.inventoryTabContent = inventoryTab;
              this.resetFilter();
            }
          }
        } else this.inventoryFilter = false;
        localStorage.setItem("collateralTab", tab);
      }
    },
  },
  mounted() {
    // keep current tab active
    // $('a[data-toggle="pill"]').on("shown.bs.tab", function (e) {
    //   localStorage.setItem("collateralTab", $(e.target).attr("href"));
    // });
    var activeTab = localStorage.getItem("collateralTab");
    if (activeTab) {
      document.getElementById(activeTab).click();
    }
  },
  created() {
    axios.defaults.headers.common["Authorisation"] =
      "Bearer " + sessionStorage.getItem("token");
    this.inActiveToggleCollateral =
      this.$store.state.inactive === "collateral_inactive" ? true : false;
    this.inActiveToggleWarehouse =
      this.$store.state.inactive === "warehouse_inactive" ? true : false;
    this.getBorrowers();
    this.getCollaterals();
    this.getWarehouses();
    this.getCollateralStages();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
      // return this.$store.getters.getuserGroup;
    },
    loggedInUserSubGroupId() {
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
      // return this.$store.getters.getuserSubGroup;
    },
  },
};
</script>

<style scoped>
.top-wrapper {
  padding-left: 10px !important;
}

.col{
  width: 120px !important;
}

.col100{
  width: 100px !important;
  margin-left:20px !important;
}
.left {
  text-align: left !important;
}
.center {
  text-align: center !important;
}
.right {
  text-align: right !important;
}
</style>
