<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div
                      class="col-lg-6"
                      v-if="loggedInUserGroupId == 'Borrower'"
                    >
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Edit Profile
                      </h1>
                    </div>
                    <div class="col-lg-6" v-else>
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Edit Borrower
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <span style="font-size: 12px; color: red"
            >Note: Only single operation can be handled at a time (Edit or
            Delete)</span
          >
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="companyName"
                      :class="{
                        'is-invalid': isSubmitted && $v.companyName.$error,
                      }"
                      id="companyName"
                      name="companyName"
                      disabled
                    />
                    <div
                      v-if="isSubmitted && $v.companyName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.companyName.required"
                        >Name is required</span
                      >
                      <span v-if="!$v.companyName.maxLength"
                        >Max length of 100 characters is exceeded!</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="regNo" class="required-field"
                      >Registration No.</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="regNo"
                      :class="{
                        'is-invalid': isSubmitted && $v.regNo.$error,
                      }"
                      id="regNo"
                      name="regNo"
                      disabled
                      @keypress="isAlphaNum($event)"
                    />
                    <div
                      v-if="isSubmitted && $v.regNo.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.regNo.required"
                        >Registration No. is required</span
                      >
                      <span v-if="!$v.regNo.maxLength"
                        >Max length of 15 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Incorporation Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="incorporationDate"
                      id="incorporationDate"
                      placeholder="Select Date"
                      v-model="incorporationDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.incorporationDate.$error,
                      }"
                      disabled
                      :disabledDates="{ from: new Date() }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.incorporationDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.incorporationDate.required"
                        >Incorporation Date is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Address</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="companyAddress"
                      :class="{
                        'is-invalid': isSubmitted && $v.companyAddress.$error,
                      }"
                      id="companyAddress"
                      name="companyAddress"
                    />
                    <div
                      v-if="isSubmitted && $v.companyAddress.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.companyAddress.required"
                        >Address is required</span
                      >
                      <span v-if="!$v.companyAddress.maxLength"
                        >Max length of 250 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label class="required-field">Country</label>
                    <select
                      id="selectedBusinessOppRegion"
                      class="form-control"
                      name="selectedBusinessOppRegion"
                      v-model="selectedBusinessOppRegion"
                      @change="getStatesByCountry(true)"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedBusinessOppRegion.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option v-for="country in countries" :key='country.id' :value="country.id">
                        {{ country.country_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedBusinessOppRegion.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedBusinessOppRegion.required"
                        >Country is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label
                      class="required-field"
                      >State</label>
                    <select
                      id="selectedState"
                      class="form-control"
                      name="selectedState"
                      v-model="selectedState"
                      @change="getCitiesByState(true)"
                      :class="{
                        'is-invalid':
                        isSubmitted && $v.selectedState.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option v-for="state in states" :key='state.id' :value="state.id">
                        {{ state.state_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedState.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedState.required"
                        >State is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label class="required-field">City</label>
                    <select
                      id="selectedCity"
                      class="form-control"
                      name="selectedCity"
                      v-model="selectedCity"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedCity.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option v-for="city in cities" :key='city.id' :value="city.id">
                        {{ city.city_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCity.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCity.required"
                        >City is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="companyWebsite" class="required-field"
                      >Website</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="companyWebsite"
                      id="companyWebsite"
                      name="companyWebsite"
                      :class="{
                        'is-invalid':
                          (isSubmitted && $v.companyWebsite.$error) ||
                          msg.companyWebsite,
                      }"
                    />
                    <div class="invalid-feedback" v-if="msg.companyWebsite">
                      <span>{{ msg.companyWebsite }}</span>
                    </div>
                    <div
                      v-if="isSubmitted && $v.companyWebsite.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.companyWebsite.required"
                        >Website is required</span
                      >
                      <span v-if="!$v.companyWebsite.maxLength"
                        >Max length of 100 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Registration Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      id="regDate"
                      name="regDate"
                      placeholder="Select Date"
                      v-model="regDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabled="true"
                      :disabledDates="{ from: new Date() }"
                      :class="{
                        'is-invalid': isSubmitted && $v.regDate.$error,
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.regDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.regDate.required"
                        >Registration Date is required</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Contact Details</h1>
                    </div>
                  </div>
                </div>
                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->
                  <div class="row">
                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="phoneNumber" class="required-field"
                          >Contact No</label
                        ><br />
                        <select
                          id="phoneNumberCode"
                          class="contact_code"
                          name="phoneNumberCode"
                          v-model="phoneNumberCode"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.phoneNumberCode.$error,
                          }"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="code in countryCodes"
                            :key="code.id"
                            :value="code.id"
                          >
                            {{ code.country_code }}
                          </option>
                        </select>
                        <input
                          class="contact"
                          placeholder
                          v-model="phoneNumber"
                          id="phoneNumber"
                          @keypress="isNum($event)"
                          :class="{
                            'is-invalid': isSubmitted && $v.phoneNumber.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.phoneNumberCode.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.phoneNumberCode.required"
                            >Contact Number Code is required</span
                          >
                        </div>
                        <div
                          v-if="isSubmitted && $v.phoneNumber.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.phoneNumber.required"
                            >Contact Number is required</span
                          >
                          <span v-if="!$v.phoneNumber.minLength"
                            >Min length of 10 characters required</span
                          >
                          <span v-if="!$v.phoneNumber.maxLength"
                            >Max length of 13 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="phoneNumber">Secondary Contact No</label
                        ><br />
                        <select
                          id="secondaryPhoneNumberCode"
                          class="contact_code"
                          name="secondaryPhoneNumberCode"
                          v-model="secondaryPhoneNumberCode"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.secondaryPhoneNumberCode.$error,
                          }"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="code in countryCodes"
                            :key="code.id"
                            :value="code.id"
                          >
                            {{ code.country_code }}
                          </option>
                        </select>
                        <input
                          class="contact"
                          placeholder
                          v-model="secondaryPhoneNumber"
                          id="secondaryPhoneNumber"
                          @keypress="isNum($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.secondaryPhoneNumber.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted && $v.secondaryPhoneNumberCode.$error
                          "
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.secondaryPhoneNumberCode.required"
                            >Secondary Contact Number Code is required</span
                          >
                        </div>
                        <div
                          v-if="isSubmitted && $v.secondaryPhoneNumber.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.secondaryPhoneNumber.required"
                            >Secondary Contact Number is required</span
                          >
                          <span v-if="!$v.secondaryPhoneNumber.minLength"
                            >Min length of 10 characters required</span
                          >
                          <span v-if="!$v.secondaryPhoneNumber.maxLength"
                            >Max length of 13 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="email" class="required-field"
                          >Email ID</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          placeholder
                          v-model="email"
                          id="email"
                          name="email"
                          :class="{
                            'is-invalid':
                              (isSubmitted && $v.email.$error) || msg.email,
                          }"
                        />
                        <div class="invalid-feedback" v-if="msg.email">
                          <span>{{ msg.email }}</span>
                        </div>
                        <div
                          v-if="isSubmitted && $v.email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.email.required"
                            >Email ID is required</span
                          >
                          <span v-if="!$v.email.maxLength"
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="secondaryEmail">Secondary Email ID</label>
                        <input
                          type="email"
                          class="form-control"
                          placeholder
                          v-model="secondaryEmail"
                          id="secondaryEmail"
                          name="secondaryEmail"
                          :class="{
                            'is-invalid':
                              (isSubmitted && $v.secondaryEmail.$error) ||
                              msg.secondaryEmail,
                          }"
                        />
                        <div class="invalid-feedback" v-if="msg.secondaryEmail">
                          <span>{{ msg.secondaryEmail }}</span>
                        </div>
                        <div
                          v-if="isSubmitted && $v.secondaryEmail.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.secondaryEmail.maxLength"
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <span class="required-field">Business Types</span>
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <multiselect
                        style="margin-block: 10px"
                        v-model="selectedBusinessType"
                        placeholder="--- Select ---"
                        label="business_type_name"
                        track-by="business_type_name"
                        :options="businessTypes"
                        :multiple="true"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedBusinessType.$error,
                        }"
                        ><span slot="noResult"
                          >No Business Type found with given input.</span
                        ></multiselect
                      >
                      <div
                        v-if="isSubmitted && $v.selectedBusinessType.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedBusinessType.required"
                          >At least 1 Business Type is required</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- until here -->
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Directors</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addDirector"
                            :disabled="dirAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(dir, index) in directorsList"
                    :key="index"
                  >
                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Name</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="dir.director_name"
                          id="director_name"
                          name="director_name"
                          @keypress="isAlpha($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.directorsList.$each[index].director_name
                                .$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.directorsList.$each[index].director_name.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.directorsList.$each[index].director_name
                                .required
                            "
                            >Name is required</span
                          >
                          <span
                            v-if="
                              !$v.directorsList.$each[index].director_name
                                .maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Designation</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="dir.designation"
                          @keypress="isAlpha($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.directorsList.$each[index].designation.$error,
                          }"
                          id="designation"
                          name="designation"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.directorsList.$each[index].designation.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.directorsList.$each[index].designation
                                .required
                            "
                            >Designation is required</span
                          >
                          <span
                            v-if="
                              !$v.directorsList.$each[index].designation
                                .maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Upload Document</label
                        >
                        <div class="custom-file">
                          <button
                            type="button"
                            class="btn btn-primary"
                            id="FileName"
                            name="FileName"
                            v-on:click="
                              showDirDocModal(dir.director_name, index)
                            "
                            :disabled="uploadDisabled"
                          >
                            Upload
                          </button>
                          <div
                            v-if="isSubmitted && dir.documents_list.length < 1"
                          >
                            <span class="error">Document is required</span>
                          </div>
                          <!-- <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >-->
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          v-on:click="deleteDirector(index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- until here -->

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onDirectorSaveInfo()"
                        :disabled="isDisabledDirectorSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Key Management</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addKeyMgmt"
                            :disabled="keyMgmtAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <div
                    class="row"
                    v-for="(km, index) in keyMgmtList"
                    :key="index"
                  >
                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Name</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="km.manager_name"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.keyMgmtList.$each[index].manager_name.$error,
                          }"
                          id="manager_name"
                          name="manager_name"
                          @change="addEditedFlag(index, 'keyMgmt')"
                          @keypress="isAlpha($event)"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.keyMgmtList.$each[index].manager_name.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.keyMgmtList.$each[index].manager_name.required
                            "
                            >Name is required</span
                          >
                          <span
                            v-if="
                              !$v.keyMgmtList.$each[index].manager_name
                                .maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Designation</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="km.designation"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.keyMgmtList.$each[index].designation.$error,
                          }"
                          id="designation"
                          name="designation"
                          @change="addEditedFlag(index, 'keyMgmt')"
                          @keypress="isAlpha($event)"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.keyMgmtList.$each[index].designation.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.keyMgmtList.$each[index].designation.required
                            "
                            >Designation is required</span
                          >
                          <span
                            v-if="
                              !$v.keyMgmtList.$each[index].designation.maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Description</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="km.description"
                          id="description"
                          name="description"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.keyMgmtList.$each[index].description.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.keyMgmtList.$each[index].description.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.keyMgmtList.$each[index].description.maxLength
                            "
                            >Max length of 500 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          v-on:click="deleteKeyMgmt(index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onKeyMgmtSaveInfo()"
                        :disabled="isDisabledKeyMgmtSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Shareholders</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addShareHolder"
                            :disabled="sHAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(sh, index) in shareHoldersList"
                    :key="index"
                  >
                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Name</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="sh.shareholder_name"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.shareHoldersList.$each[index].shareholder_name
                                .$error,
                          }"
                          id="shareholder_name"
                          name="shareholder_name"
                          @change="addEditedFlag(index, 'shareHolder')"
                          @keypress="isAlpha($event)"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.shareHoldersList.$each[index].shareholder_name
                              .$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.shareHoldersList.$each[index].shareholder_name
                                .required
                            "
                            >Name is required</span
                          >
                          <span
                            v-if="
                              !$v.shareHoldersList.$each[index].shareholder_name
                                .maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Shareholding Patterns (%)</label
                        >
                        <input
                          type="radio"
                          :value="true"
                          v-model="shareholding_toggle"
                          id="shareholding_toggle_perc"
                          @change="onShareHolderToggle('percentage')"
                          v-if="index === 0"
                        />
                        <input
                          type="text"
                          class="form-control"
                          v-model="sh.shareholding_pattern"
                          min="0"
                          @keypress="isDecimalTwoFixed($event)"
                          @paste="truncateDecimalPlacesUptoTwo($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.shareHoldersList.$each[index]
                                .shareholding_pattern.$error,
                          }"
                          id="shareholding_pattern"
                          name="shareholding_pattern"
                          @change="addEditedFlag(index, 'shareHolder')"
                          :disabled="!shareholding_toggle"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.shareHoldersList.$each[index]
                              .shareholding_pattern.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.shareHoldersList.$each[index]
                                .shareholding_pattern.required
                            "
                            >Shareholding Patterns is required</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Shareholding Value</label
                        >
                        <input
                          type="radio"
                          :value="false"
                          v-model="shareholding_toggle"
                          id="shareholding_toggle_val"
                          @change="onShareHolderToggle('value')"
                          v-if="index === 0"
                        />
                        <input
                          type="text"
                          class="form-control"
                          v-model="sh.shareholding_value"
                          min="1"
                          @keypress="isDecimalTwoFixed($event)"
                          @paste="truncateDecimalPlacesUptoTwo($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.shareHoldersList.$each[index]
                                .shareholding_value.$error,
                          }"
                          id="shareholding_value"
                          name="shareholding_value"
                          :disabled="shareholding_toggle"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.shareHoldersList.$each[index].shareholding_value
                              .$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.shareHoldersList.$each[index]
                                .shareholding_value.required
                            "
                            >Shareholding Value is required</span
                          >
                          <span
                            v-if="
                              !$v.shareHoldersList.$each[index]
                                .shareholding_value.maxLength
                            "
                            >Max length of 15 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          v-on:click="deleteShareHolder(index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        name="saveInfo"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onShareHolderSaveInfo()"
                        :disabled="isDisabledShareHolderSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">KYC Corporate Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addKYC"
                            :disabled="KYCAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div class="row" v-for="(kyc, index) in KYCList" :key="index">
                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label class="required-field">Document</label>
                        <select
                          id="document_id"
                          class="form-control"
                          name="document_id"
                          v-model="kyc.document_id"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.KYCList.$each[index].document_id.$error,
                          }"
                          @change="addEditedFlag(index, 'kyc')"
                          :disabled="index < KYCListLength"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="document in KYCDocuments"
                            :value="document.id"
                            :key="document.id"
                          >
                            {{ document.document_name }}
                          </option>
                        </select>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.KYCList.$each[index].document_id.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.KYCList.$each[index].document_id.required"
                            >Document Name is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Upload New Document</label
                        >
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="customFile"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            v-on:change="
                              handleFileUploadKYC(
                                index,
                                $event,
                                kyc.document_id
                              ) & addEditedFlag(index, 'kyc')
                            "
                            :class="{
                              'is-invalid':
                                (isSubmitted &&
                                  kyc.display_file_name === null) ||
                                kyc.errors,
                            }"
                          />
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ kyc.display_file_name }}</b>
                            </p>
                          </small>
                          <div v-if="kyc.errors" class="invalid-feedback">
                            <span>{{ kyc.errors }}</span>
                          </div>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                          <div
                            v-if="isSubmitted && kyc.display_file_name === null"
                            class="invalid-feedback"
                          >
                            <span>Document is required</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Issue Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          id="uploading_date"
                          name="uploading_date"
                          placeholder="Select Date"
                          v-model="kyc.uploading_date"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          :disabledDates="{
                            from: new Date(),
                          }"
                          @input="addEditedFlag(index, 'kyc')"
                        ></datepicker>
                        <!-- <div
                          v-if="
                            isSubmitted &&
                            $v.KYCList.$each[index].uploading_date.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.KYCList.$each[index].uploading_date.required
                            "
                            >KYC Date is required</span
                          >
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Expiry Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="expiry_date"
                          id="expiry_date"
                          placeholder="Select Date"
                          v-model="kyc.expiry_date"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          :disabledDates="{
                            to: new Date(kyc.uploading_date),
                          }"
                          @input="addEditedFlag(index, 'kyc')"
                        ></datepicker>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="kyc.comments"
                          id="kyc_comments"
                          @change="addEditedFlag(index, 'kyc')"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.KYCList.$each[index].comments.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.KYCList.$each[index].comments.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.KYCList.$each[index].comments.maxLength"
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-md-1 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          v-on:click="deleteKYC(index, kyc.kyc_tran_id)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div> -->
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onKYCSaveInfo()"
                        :disabled="isDisabledKYCSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Financial Covenants</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addCovenant"
                            :disabled="covAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(cov, index) in covenantList"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label class="required-field">Covenants</label>
                        <select
                          id="covenants_master_id"
                          class="form-control"
                          name="covenants_master_id"
                          v-model="cov.covenants_master_id"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.covenantList.$each[index].covenants_master_id
                                .$error,
                          }"
                          @change="addEditedFlag(index, 'covenant')"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="covenant in covenants"
                            :value="covenant.id"
                          >
                            {{ covenant.covenants_name }}
                          </option>
                        </select>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.covenantList.$each[index].covenants_master_id
                              .$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.covenantList.$each[index].covenants_master_id
                                .required
                            "
                            >Covenants is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Review Date</label
                        >
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          id="upload_date"
                          name="upload_date"
                          placeholder="Select Date"
                          v-model="cov.upload_date"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          :disabledDates="{ from: new Date() }"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.covenantList.$each[index].upload_date.$error,
                          }"
                          @input="addEditedFlag(index, 'covenant')"
                        ></datepicker>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.covenantList.$each[index].upload_date.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.covenantList.$each[index].upload_date.required
                            "
                            >Review Date is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label class="required-field">Symbols</label>
                        <select
                          class="form-control"
                          v-model="cov.sign"
                          id="sign"
                          name="sign"
                          @change="addEditedFlag(index, 'covenant')"
                        >
                          <option :value="null">--- Select ---</option>
                          <option value="<">Less than</option>
                          <option value=">">Greater than</option>
                          <option value="<=">Less than equal to</option>
                          <option value=">=">Greater than equal to</option>
                          <option value="=">Equal to</option>
                        </select>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.covenantList.$each[index].sign.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.covenantList.$each[index].sign.required"
                            >Symbols is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Expected Value</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="cov.expected_value"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              ($v.covenantList.$each[index].expected_value
                                .$error ||
                                !$v.covenantList.$each[index].expected_value
                                  .maxDigit),
                          }"
                          id="expected_value"
                          name="expected_value"
                          @change="addEditedFlag(index, 'covenant')"
                          @keypress="isDecimalTwoFixed($event)"
                          @paste="truncateDecimalPlacesUptoTwo($event)"
                          min="1"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            ($v.covenantList.$each[index].expected_value
                              .$error ||
                              !$v.covenantList.$each[index].expected_value
                                .maxDigit)
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.covenantList.$each[index].expected_value
                                .required
                            "
                            >Expected Value is required</span
                          >
                          <span
                            v-if="
                              !$v.covenantList.$each[index].expected_value
                                .maxDigit
                            "
                            >Max value of 8 digits exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Actual Value</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="cov.actual_value"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              ($v.covenantList.$each[index].actual_value
                                .$error ||
                                !$v.covenantList.$each[index].actual_value
                                  .maxDigit),
                          }"
                          id="actual_value"
                          name="actual_value"
                          @change="addEditedFlag(index, 'covenant')"
                          @keypress="isDecimalTwoFixed($event)"
                          @paste="truncateDecimalPlacesUptoTwo($event)"
                          min="1"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            ($v.covenantList.$each[index].actual_value.$error ||
                              !$v.covenantList.$each[index].actual_value
                                .maxDigit)
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.covenantList.$each[index].actual_value
                                .required
                            "
                            >Actual Value is required</span
                          >
                          <span
                            v-if="
                              !$v.covenantList.$each[index].actual_value
                                .maxDigit
                            "
                            >Max value of 8 digits exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label class="required-field">Compliant</label>
                        <input
                          name="compliant"
                          id="compliant"
                          v-model="cov.compliant"
                          class="form-control"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.covenantList.$each[index].compliant.$error,
                          }"
                          @change="addEditedFlag(index, 'covenant')"
                        />{{
                          getCompliant(
                            cov.sign,
                            cov.actual_value,
                            cov.expected_value,
                            index
                          )
                        }}
                        <div
                          v-if="
                            isSubmitted &&
                            $v.covenantList.$each[index].compliant.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.covenantList.$each[index].compliant.required
                            "
                            >Please select symbol and enter the values</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          id="cov_comments"
                          placeholder
                          v-model="cov.comments"
                          @change="addEditedFlag(index, 'covenant')"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.covenantList.$each[index].comments.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.covenantList.$each[index].comments.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.covenantList.$each[index].comments.maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-md-1 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          v-on:click="deleteCovenant(index, cov.cov_tran_id)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div> -->
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onCovSaveInfo()"
                        :disabled="isDisabledCovenantSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a @click="$router.go(-1)" style="cursor: pointer">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->

    <!-- ./modal start -->
    <div class="modal fade" id="view_doc">
      <div class="modal-dialog modal-lg">
        <div class="modal-content" style="width: 120%">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              Add Document -
              <b>{{ currentDirName }}</b>
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table" v-if="directorsList.length != 0">
                    <thead>
                      <tr>
                        <th class="required-field">Document Name</th>
                        <th class="required-field">Document</th>
                        <th class="required-field">Issue Date</th>
                        <th>Expiry Date</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(d, index) in directorsList[currentDirId]
                          .documents_list"
                        :key="index"
                      >
                        <td>
                          <select
                            id="selectedDirectorsDocument"
                            class="form-control"
                            name="selectedDirectorsDocument"
                            v-model="d.document_id"
                            :disabled="
                              index !==
                              directorsList[currentDirId].documents_list
                                .length -
                                1
                            "
                            @change="
                              addEditedFlag(index, 'dirDoc', currentDirId) &
                                checkSameDoc(d.document_id, index)
                            "
                            :class="{
                              'is-invalid': d.error,
                            }"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="document in DirDocuments"
                              :value="document.id"
                              :key="document.id"
                            >
                              {{ document.document_name }}
                            </option>
                          </select>
                          <div v-if="d.error" class="invalid-feedback">
                            <span>Document Name already exists</span>
                          </div>
                        </td>
                        <td>
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="file"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              :disabled="d.document_id === null || d.error"
                              :class="{
                                'is-invalid':
                                  d.errors || d.document_id === null,
                              }"
                              v-on:change="
                                handleFileUploadDirector(
                                  $event,
                                  currentDirId,
                                  index,
                                  d.document_id
                                ) & addEditedFlag(index, 'dirDoc', currentDirId)
                              "
                            />
                            <small>
                              <p style="background-color: powderblue">
                                {{ d.display_file_name }}
                              </p>
                            </small>
                            <div class="invalid-feedback" v-if="d.errors">
                              <span>
                                {{ d.errors }}
                              </span>
                            </div>
                            <div
                              class="invalid-feedback"
                              v-if="d.document_id === null"
                            >
                              <span> Document Name is not selected </span>
                            </div>
                            <label class="custom-file-label" for="file"
                              >Choose File</label
                            >
                          </div>
                        </td>
                        <td>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="directorDocumentIssueDate"
                            id="directorDocumentIssueDate"
                            placeholder="Select Date"
                            v-model="d.directorDocumentIssueDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabled="
                              index !==
                              directorsList[currentDirId].documents_list
                                .length -
                                1
                            "
                            :disabledDates="{
                              from: new Date(),
                            }"
                            :class="{
                              'is-invalid':
                                d.directorDocumentIssueDate === null,
                            }"
                            @input="
                              addDirDocument(currentDirId) &
                                deleteDirDoc(
                                  currentDirId,
                                  directorsList[currentDirId].documents_list
                                    .length - 1,
                                  null
                                )
                            "
                          ></datepicker>
                          <div
                            class="invalid-feedback"
                            v-if="d.directorDocumentIssueDate === null"
                          >
                            <span>Issue Date is required </span>
                          </div>
                        </td>
                        <td>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="directorDocumentExpiryDate"
                            id="directorDocumentExpiryDate"
                            placeholder="Select Date"
                            v-model="d.directorDocumentExpiryDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabled="
                              index !==
                              directorsList[currentDirId].documents_list
                                .length -
                                1
                            "
                            :disabledDates="{
                              to: new Date(d.directorDocumentIssueDate),
                            }"
                          ></datepicker>
                        </td>
                        <td
                          v-on:click="
                            deleteDirDoc(currentDirId, index, d.dir_doc_tran_id)
                          "
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-primary sh"
                    v-on:click="addDirDocument(currentDirId)"
                  >
                    Add
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="closeDirDocModal()"
                  >
                    Save
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength, minLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      //master tables
      DirDocuments: [],
      KYCDocuments: [],
      //variables for company information
      companyName: null,
      regNo: null,
      companyAddress: null,
      companyWebsite: null,
      email: null,
      secondaryEmail: null,
      incorporationDate: null,
      incorporationDate1: null,
      regDate: null,
      regDate1: null,
      countries: [],
      businessTypes: [],
      selectedBusinessType: [],
      prevBusinessType: [],
      selectedBusinessOppRegion: null,
      selectedState: null,
      selectedCity: null,
      phoneNumber: null,
      phoneNumberCode: null,
      secondaryPhoneNumberCode: null,
      secondaryPhoneNumber: null,
      states: [],
      cities: [],
      countryCodes: [],

      //list for directors
      currentDirId: 0,
      directorsList: [],
      dirFile: null,
      dirDisabled: true,
      dirSaveInfoDisabled: false, //save info button is disabled default is false as it is edit page
      dirAddMoreDisabled: false,
      currentDirName: null,
      uploadDisabled: true,

      //list for key management
      keyMgmtList: [],
      keyMgmtListLength: null,
      keyMgmtSaveInfoDisabled: false, //save info button is disabled default is false as it is edit page
      keyMgmtAddMoreDisabled: false,

      //list for shareholders
      shareHoldersList: [],
      shareHoldersListLength: null,
      sHSaveInfoDisabled: false, //save info button is disabled default is false as it is edit page
      sHAddMoreDisabled: false,
      shareholding_toggle: true,

      //list for kyc documents
      KYCList: [],
      KYCListLength: null,
      KYCFile: "",
      KYCDisabled: true,
      KYCSaveInfoDisabled: false, //save info button is disabled default is false as it is edit page
      KYCAddMoreDisabled: false,
      sameDocBackcapDate: null,

      //list for covenants
      covenants: [],
      covenantList: [],
      covenantListLength: null,
      covFile: null,
      covSaveInfoDisabled: false, //save info button is disabled default is false as it is edit page
      covAddMoreDisabled: false,

      totalPerc: 0,
      counter: -1,
      msg: [],
      BorrowerDeleteData: [],
      error: "",
      isSubmitted: false,
      fileNameError: false,
      inactive: false,
      borrowerExists: false,

      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  validations: {
    companyName: {
      maxLength: maxLength(100),
      required,
    },
    regNo: {
      maxLength: maxLength(15),
      required,
    },
    incorporationDate: {
      required,
    },
    companyAddress: {
      required,
      maxLength: maxLength(250),
    },
    selectedBusinessOppRegion: {
      required,
    },
    selectedState: {
      required,
    },
    selectedCity: {
      required,
    },
    phoneNumber: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(13),
    },
    phoneNumberCode: {
      required,
    },
    secondaryPhoneNumber: {
      minLength: minLength(10),
      maxLength: maxLength(13),
      required: function () {
        if (this.secondaryPhoneNumberCode && !this.secondaryPhoneNumber)
          return false;
        else return true;
      },
    },
    secondaryPhoneNumberCode: {
      required: function () {
        if (!this.secondaryPhoneNumberCode && this.secondaryPhoneNumber)
          return false;
        else return true;
      },
    },
    companyWebsite: {
      required,
      maxLength: maxLength(100),
    },
    email: {
      required,
      maxLength: maxLength(50),
    },
    secondaryEmail: {
      maxLength: maxLength(50),
    },
    selectedBusinessType: {
      required: function () {
        if (this.selectedBusinessType.length < 1) return false;
        else return true;
      },
    },
    regDate: {
      required,
    },
    directorsList: {
      $each: {
        director_name: {
          required,
          maxLength: maxLength(50),
        },
        designation: {
          required,
          maxLength: maxLength(50),
        },
      },
    },
    keyMgmtList: {
      $each: {
        manager_name: {
          required,
          maxLength: maxLength(50),
        },
        designation: {
          required,
          maxLength: maxLength(50),
        },
        description: {
          maxLength: maxLength(500),
        },
      },
    },
    shareHoldersList: {
      $each: {
        shareholder_name: {
          required,
          maxLength: maxLength(50),
        },
        shareholding_pattern: {
          required: function (val) {
            if ((val === null || val == 0) && this.shareholding_toggle)
              return false;
            else return true;
          },
        },
        shareholding_value: {
          maxLength: function (val) {
            if (val ? val.length > 15 : false && !this.shareholding_toggle)
              return false;
            else return true;
          },
          required: function (val) {
            if ((val === null || val == 0) && !this.shareholding_toggle)
              return false;
            else return true;
          },
        },
      },
    },
    KYCList: {
      $each: {
        document_id: {
          required,
        },
        comments: {
          maxLength: maxLength(50),
        },
        // uploading_date: {
        //   required,
        // },
      },
    },
    covenantList: {
      $each: {
        covenants_master_id: {
          required,
        },
        upload_date: {
          required,
        },
        sign: {
          required,
        },
        expected_value: {
          required: function (val) {
            if (val === null || val == 0 || val === "") {
              return false;
            } else {
              return true;
            }
          },
          // validation for value greater than 8 digits
          maxDigit: function (val) {
            if (Number(val) > 99999999.99) return false;
            else return true;
          },
        },
        actual_value: {
          required: function (val) {
            if (val === null || val == 0 || val === "") {
              return false;
            } else {
              return true;
            }
          },
          // validation for value greater than 8 digits
          maxDigit: function (val) {
            if (Number(val) > 99999999.99) return false;
            else return true;
          },
        },
        compliant: {
          required,
        },
        comments: {
          maxLength: maxLength(50),
        },
      },
    },
  },
  watch: {
    email(value) {
      // binding this to the data value in the email input
      this.email = value;
      this.validateEmail(value);
    },
    secondaryEmail(value) {
      // binding this to the data value in the email input
      this.secondaryEmail = value;
      this.validateEmail(value, "secondary");
    },
    companyWebsite(value) {
      // binding this to the data value in the website input
      this.companyWebsite = value;
      this.validateWebsite(value);
    },
  },
  computed: {
    //this function returns the complaint value according to set sign value and its expected & actual values
    isCompliant: function () {
      return function (i, j, k) {
        if (i == ">") {
          if (Number(j) > Number(k)) {
            return "Yes";
          } else {
            return "No";
          }
        } else if (i == "<") {
          if (Number(j) < Number(k)) {
            return "Yes";
          } else {
            return "No";
          }
        } else if (i == ">=") {
          if (Number(j) >= Number(k)) {
            return "Yes";
          } else {
            return "No";
          }
        } else if (i == "<=") {
          if (Number(j) <= Number(k)) {
            return "Yes";
          } else {
            return "No";
          }
        } else if (i == "=") {
          if (Number(j) == Number(k)) {
            return "Yes";
          } else {
            return "No";
          }
        }
      };
    },
    //this is to computed disabled property of Key Mgmt Save Info button.
    isDisabledKeyMgmtSaveInfo: function () {
      let checkEmptyKeyMgmtName = this.keyMgmtList.filter(
        (line) => line.manager_name === null
      );
      let checkEmptyKeyMgmtDesignation = this.keyMgmtList.filter(
        (line) => line.designation === null
      );
      //if the line is empty then disable save info button
      if (
        this.keyMgmtList.length === 0 &&
        this.keyMgmtSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enable
      if (
        (checkEmptyKeyMgmtName.length >= 1 && this.keyMgmtList.length > 0) ||
        (checkEmptyKeyMgmtDesignation.length >= 1 &&
          this.keyMgmtList.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledKeyMgmtSaveInfo() close

    //this is to computed disabled property of Share Holder Save Info button.
    isDisabledShareHolderSaveInfo: function () {
      let checkEmptySHName = this.shareHoldersList.filter(
        (line) => line.shareholder_name === null
      );
      let checkEmptySHPattern = this.shareHoldersList
        .slice(-1)
        .filter((line) => line.shareholding_pattern === null);
      let checkEmptySHValue = this.shareHoldersList
        .slice(-1)
        .filter((line) => line.shareholding_value === null);
      //if the line is empty then disable save info button
      if (
        this.shareHoldersList.length === 0 &&
        this.sHSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enable
      if (
        (checkEmptySHName.length >= 1 && this.shareHoldersList.length > 0) ||
        (checkEmptySHPattern.length >= 1 &&
          this.shareHoldersList.length > 0 &&
          checkEmptySHValue.length >= 1 &&
          this.shareHoldersList.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledShareHolderSaveInfo() close

    //this is to computed disabled property of Directors Save Info button.
    isDisabledDirectorSaveInfo: function () {
      let checkEmptyDirName = this.directorsList.filter(
        (line) => line.director_name === null
      );
      let checkEmptyDesignation = this.directorsList.filter(
        (line) => line.designation === null
      );
      //if the line is empty then disable save info button
      if (
        this.directorsList.length === 0 &&
        this.dirSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether name and designation input fields are filled. If condition is satisfied then only click button for upload doc will be enabled.
      if (
        (checkEmptyDirName.length >= 1 && this.directorsList.length > 0) ||
        (checkEmptyDesignation.length >= 1 && this.directorsList.length > 0)
      ) {
        this.uploadDisabled = true;
      } else {
        this.uploadDisabled = false;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyDirName.length >= 1 && this.directorsList.length > 0) ||
        (checkEmptyDesignation.length >= 1 && this.directorsList.length > 0) ||
        this.dirFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledDirectorSaveInfo() close

    //this is to computed disabled property of KYC Save Info button.
    isDisabledKYCSaveInfo: function () {
      let checkEmptyKYCDocName = this.KYCList.filter(
        (line) => line.document_id === null
      );
      // let checkEmptyKYCDate = this.KYCList.filter(
      //   (line) => line.uploading_date === null
      // );
      //if the line is empty then disable save info button
      if (this.KYCList.length === 0 && this.KYCSaveInfoDisabled === true) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyKYCDocName.length >= 1 &&
        this.KYCList.length > 0
        // (checkEmptyKYCDate.length >= 1 && this.KYCList.length > 0)
      ) {
        this.KYCDisabled = true;
      } else {
        this.KYCDisabled = false;
      }
      //KYCDisabled variable is to check whther all input fields except file upload are filled. KYCFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (this.KYCDisabled === true || this.KYCFile === null) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledKYCSaveInfo() close

    //this is to computed disabled property of Covenants Save Info button.
    isDisabledCovenantSaveInfo: function () {
      let checkEmptyCovDocName = this.covenantList.filter(
        (line) => line.covenants_master_id === null
      );
      let checkEmptyCovDate = this.covenantList.filter(
        (line) => line.upload_date === null
      );
      let checkEmptyActualValue = this.covenantList.filter(
        (line) => line.actual_value === null
      );
      let checkEmptyExpectedValue = this.covenantList.filter(
        (line) => line.expected_value === null
      );
      let checkEmptyCompliant = this.covenantList.filter(
        (line) => line.compliant === null
      );
      let checkEmptySign = this.covenantList.filter(
        (line) => line.sign === null
      );
      //if the line is empty then disable save info button
      if (this.covenantList.length === 0 && this.covSaveInfoDisabled === true) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyCovDocName.length >= 1 && this.covenantList.length > 0) ||
        (checkEmptyCovDate.length >= 1 && this.covenantList.length > 0) ||
        (checkEmptyActualValue.length >= 1 && this.covenantList.length > 0) ||
        (checkEmptyExpectedValue.length >= 1 && this.covenantList.length > 0) ||
        (checkEmptyCompliant.length >= 1 && this.covenantList.length > 0) ||
        (checkEmptySign.length >= 1 && this.covenantList.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledCovenantSaveInfo() close

    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  }, //main computed close
  components: {
    Datepicker,
    Multiselect: window.VueMultiselect.default,
  },
  methods: {
    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },

    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },
    isAlpha(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isAlphaNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    // add error flag true to document if document id already exists else add false
    checkSameDoc(val, i) {
      let docList = this.directorsList[this.currentDirId].documents_list.map(
        (doc) => doc.document_id
      );
      docList.pop();
      if (docList.includes(val))
        this.directorsList[this.currentDirId].documents_list[i].error = true;
      else
        this.directorsList[this.currentDirId].documents_list[i].error = false;
    },
    // remove either shareholding value or percentage depending on selected radio button
    onShareHolderToggle(type) {
      if (type === "percentage") {
        this.shareHoldersList.map((sh) => (sh.shareholding_value = null));
      } else {
        this.shareHoldersList.map((sh) => (sh.shareholding_pattern = null));
      }
    },
    // checkBackcapDate(docName, index) {
    //   let sameDocName = [];
    //   if (docName !== null) {
    //     this.KYCList.slice(0, index + 1).filter((kyc) => {
    //       if (kyc.KYCDocName === docName) sameDocName.push(kyc);
    //     });
    //     if (sameDocName[sameDocName.length - 2])
    //       this.sameDocBackcapDate = sameDocName[sameDocName.length - 2]
    //         .KYCExpDate
    //         ? sameDocName[sameDocName.length - 2].KYCExpDate
    //         : sameDocName[sameDocName.length - 2].KYCDate;
    //     else this.sameDocBackcapDate = null;
    //   }
    // },
    addEditedFlag(index, type, currDirId) {
      if (type === "keyMgmt" && index < this.keyMgmtListLength) {
        this.keyMgmtList[index]["edited"] = "true";
      } else if (
        type === "shareHolder" &&
        index < this.shareHoldersListLength
      ) {
        this.shareHoldersList[index]["edited"] = "true";
      } else if (type === "kyc" && index < this.KYCListLength) {
        this.KYCList[index]["edited"] = "true";
      } else if (type === "covenant" && index < this.covenantListLength) {
        this.covenantList[index]["edited"] = "true";
      } else if (
        type === "dirDoc" &&
        index < this.directorsList[currDirId].len
      ) {
        this.directorsList[currDirId].documents_list[index]["edited"] = "true";
      }
    },
    getCompliant(sign, actual, expected, index) {
      this.covenantList[index].compliant = this.isCompliant(
        sign,
        actual,
        expected
      );
    },
    validateEmail(value, type) {
      if (type === "secondary") {
        if (/^\w+([\.-]\w+)*@\D\w+\.(\D{2,3})$/.test(value) || value === "") {
          this.msg["secondaryEmail"] = "";
        } else {
          this.msg["secondaryEmail"] = "Invalid Email Address";
        }
      } else {
        if (/^\w+([\.-]\w+)*@\D\w+\.(\D{2,3})$/.test(value)) {
          this.msg["email"] = "";
        } else {
          this.msg["email"] = "Invalid Email Address";
        }
      }
    },
    validateWebsite(value) {
      if (/^(www\.)([A-Za-z0-9-])+(\.\D{2,3})$/.test(value) || value === "") {
        this.msg["companyWebsite"] = "";
      } else {
        this.msg["companyWebsite"] = "Invalid Website";
      }
    },

    showDirDocModal(name, id) {
      //this function opens the director document modal. id: director current id, name: director current name passed.
      this.currentDirName = name;
      this.currentDirId = id;
      $("#view_doc").modal("show");
    }, //showDirDocModal() close

    closeDirDocModal() {
      //this function closes the director document modal
      $("#view_doc").modal("hide");
    },

    //this method will make disabled property as false for Director Save Info
    onDirectorSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.dirSaveInfoDisabled = false;
      this.dirAddMoreDisabled = false;
    },

    //this method will make disabled property as false for Director Save Info
    onKeyMgmtSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.keyMgmtSaveInfoDisabled = false;
      this.keyMgmtAddMoreDisabled = false;
    },
    calcTotalPerc() {
      for (let list in this.shareHoldersList) {
        this.totalPerc += parseFloat(
          this.shareHoldersList[list].shareholding_pattern
            ? this.shareHoldersList[list].shareholding_pattern
            : 0
        );
      }
    },
    //this method will make disabled property as false for Director Save Info
    onShareHolderSaveInfo() {
      this.calcTotalPerc();
      if (this.totalPerc > parseFloat(100.0)) {
        Utils.make_alert(
          "warning",
          "Total Share Holding Pattern should not be greater than 100%"
        );
        this.totalPerc -= parseFloat(
          parseFloat(
            this.shareHoldersList[this.counter].shareholding_pattern
          ).toFixed(2)
        );
      } else {
        Utils.make_alert("success", "Record saved.");
        this.sHSaveInfoDisabled = false;
        this.sHAddMoreDisabled = false;
      }
      this.totalPerc = 0;
    },

    //this method will make disabled property as false for Director Save Info
    onKYCSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.KYCSaveInfoDisabled = false;
      this.KYCAddMoreDisabled = false;
    },

    //this method will make disabled property as false for Director Save Info
    onCovSaveInfo(index) {
      Utils.make_alert("success", "Record saved.");
      this.covSaveInfoDisabled = false;
      this.covAddMoreDisabled = false;
    },

    deleteKeyMgmt(index) {
      if (this.keyMgmtList[index].bk_tran_id) {
        let deleteId = this.keyMgmtList[index].bk_tran_id;
        this.BorrowerDeleteData.push({
          id: deleteId,
          type: "KeyMgmt",
          moduleID: 2,
          tabID: 4,
          clientId: this.$store.state.client_id,
          userId: this.$store.getters.getuserId,
        });
      }
      this.keyMgmtList.splice(index, 1);

      if (
        typeof this.keyMgmtList !== "undefined" &&
        this.keyMgmtList.length == 0
      ) {
        this.keyMgmtAddMoreDisabled = false;
        this.keyMgmtSaveInfoDisabled = true;
      }
    },

    deleteShareHolder(index) {
      if (this.shareHoldersList[index].bs_tran_id) {
        let deleteId = this.shareHoldersList[index].bs_tran_id;
        this.BorrowerDeleteData.push({
          id: deleteId,
          type: "Shareholder",
          moduleID: 2,
          tabID: 4,
          clientId: this.$store.state.client_id,
          userId: this.$store.getters.getuserId,
        });
      }
      this.counter -= 1;
      this.shareHoldersList.splice(index, 1);

      if (
        typeof this.shareHoldersList !== "undefined" &&
        this.shareHoldersList !== "undefined" &&
        this.shareHoldersList.length == 0
      ) {
        this.sHAddMoreDisabled = false;
        this.sHSaveInfoDisabled = true;
      } else {
        this.sHAddMoreDisabled = false;
        this.sHSaveInfoDisabled = true;
      }
    },

    // deleteCovenant(index) {
    //   if (this.covenantList[index].cov_tran_id) {
    //     let deleteId = this.covenantList[index].cov_tran_id;
    //     let deleteDocId = this.covenantList[index].covenants_master_id;
    //     this.BorrowerDeleteData.push({
    //       id: deleteId,
    //       docId: deleteDocId,
    //       type: "Covenant",
    //       moduleID: 2,
    //       tabID: 4,
    //       clientId: this.$store.state.client_id,
    //       userId: this.$store.getters.getuserId,
    //     });
    //   }
    //   this.covenantList.splice(index, 1);
    //   if (
    //     typeof this.covenantList !== "undefined" &&
    //     this.covenantList.length == 0
    //   ) {
    //     this.covAddMoreDisabled = false;
    //     this.covSaveInfoDisabled = true;
    //   }
    // },

    deleteKYC(index, kycTranId) {
      if (kycTranId) {
        let deleteId = kycTranId;
        let deleteDocId = this.KYCList[index].document_id;
        this.BorrowerDeleteData.push({
          id: deleteId,
          docId: deleteDocId,
          type: "Document",
          moduleID: 2,
          tabID: 4,
          clientId: this.$store.state.client_id,
          userId: this.$store.getters.getuserId,
        });
      }
      this.KYCList.splice(index, 1);
      this.KYCFile = "";
      if (typeof this.KYCList !== "undefined" && this.KYCList.length == 0) {
        this.KYCAddMoreDisabled = false;
        this.KYCSaveInfoDisabled = true;
      }
    },

    deleteDirector(index) {
      if (this.directorsList[index].dir_tran_id) {
        let deleteId = this.directorsList[index].dir_tran_id;
        this.BorrowerDeleteData.push({
          id: deleteId,
          type: "Director",
          moduleID: 2,
          tabID: 4,
          clientId: this.$store.state.client_id,
          userId: this.$store.getters.getuserId,
        });
      }
      //if last element deletion this will work fine
      this.directorsList.splice(index, 1);
      this.dirFile = "";
      //if directorsList length = 0 then initialize currentDirId to 0
      if (this.directorsList.length == 1) {
        this.currentDirId = 0;
      }
      //if directorsList length > 1 then initialize currentDirId 's length - 1
      if (this.directorsList.length > 1) {
        this.currentDirId = this.directorsList.length - 1;
      }
      this.dirAddMoreDisabled = false;
      this.dirSaveInfoDisabled = true;
    },

    deleteDirDoc(currDirId, index, dirDocTranId) {
      if (dirDocTranId) {
        let deleteId = dirDocTranId;
        let deleteDocId =
          this.directorsList[currDirId].documents_list[index].document_id;
        this.BorrowerDeleteData.push({
          id: deleteId,
          docId: deleteDocId,
          type: "DirectorDocument",
          moduleID: 2,
          tabID: 4,
          clientId: this.$store.state.client_id,
          userId: this.$store.getters.getuserId,
        });
      }
      this.directorsList[currDirId].documents_list.splice(index, 1);
    },

    getBorrower() {
      //this function is called on page load and created events. it will show details on borrower with borrowerid equal to this.id
      const path = "borrower/" + this.borrower_id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status: " + res.data.status);

          if (res.data.status == "success") {
            this.companyName = res.data.borrower_active.borrower_name;
            this.regNo = res.data.borrower_active.registration_no;
            this.companyAddress = res.data.borrower_active.address;
            this.companyWebsite = res.data.borrower_active.website;
            this.email = res.data.borrower_active.email;
            this.secondaryEmail = res.data.borrower_active.secondary_email;
            this.incorporationDate =
              res.data.borrower_active.incorporation_date;
            this.regDate = res.data.borrower_active.registration_date;
            this.selectedBusinessOppRegion =
              res.data.borrower_active.country_id;
            this.selectedState = res.data.borrower_active.state_id;
            this.selectedCity = res.data.borrower_active.city_id;
            this.getStatesByCountry(false);
            this.getCitiesByState(false);
            this.phoneNumberCode = res.data.borrower_active.phone_number_code;
            this.phoneNumber = res.data.borrower_active.phone_number;
            this.secondaryPhoneNumberCode =
              res.data.borrower_active.secondary_phone_number_code;
            this.secondaryPhoneNumber =
              res.data.borrower_active.secondary_phone_number;
            if (res.data.borrower_active.business_types_list.length) {
              // set received business types to selectedBusinessType
              let business_types =
                res.data.borrower_active.business_types_list.map(
                  (b) => b.business_type_name
                );
              this.selectedBusinessType = this.businessTypes.filter((b) =>
                business_types.includes(b.business_type_name)
              );
              // create a copy of received business types
              this.prevBusinessType = this.selectedBusinessType.map(function (
                obj
              ) {
                return obj.business_type_name;
              });
            }
            this.keyMgmtList =
              res.data.borrower_active.borrower_keymanagement_list;
            this.keyMgmtListLength =
              res.data.borrower_active.borrower_keymanagement_list.length;
            if (this.keyMgmtListLength) {
              this.keyMgmtList.filter((key) => {
                key["edited"] = "false";
              });
            }
            this.shareHoldersList =
              res.data.borrower_active.borrower_shareholder_list;
            this.shareHoldersListLength =
              res.data.borrower_active.borrower_shareholder_list.length;
            if (this.shareHoldersListLength) {
              this.shareHoldersList.filter((sh) => {
                sh["edited"] = "false";
                if (sh["shareholding_value"] !== null)
                  sh["shareholding_value"] = sh[
                    "shareholding_value"
                  ].replaceAll(",", "");
              });
              this.shareholding_toggle = this.shareHoldersList[0]
                .shareholding_pattern
                ? true
                : false;
            }
            this.covenantList =
              res.data.borrower_active.covenants_transaction_list;
            this.covenantListLength =
              res.data.borrower_active.covenants_transaction_list.length;
            if (this.covenantListLength) {
              this.covenantList.filter((cov) => {
                cov["edited"] = "false";
              });
            }
            this.KYCList = res.data.borrower_active.borrower_kycdocuments_list;
            this.KYCListLength =
              res.data.borrower_active.borrower_kycdocuments_list.length;
            if (this.KYCListLength) {
              this.KYCList.filter((kyc) => {
                kyc["edited"] = "false";
              });
            }
            this.directorsList =
              res.data.borrower_active.borrower_directors_list;
            this.directorsList.filter((dir) => {
              dir["len"] = dir.documents_list.length;
              dir.documents_list.filter((d) => {
                d["edited"] = "false";
                d["directorDocumentIssueDate"] = d["issue_date"];
                delete d["issue_date"];
                d["directorDocumentExpiryDate"] = d["expiry_date"];
                delete d["expiry_date"];
              });
            });

            console.log("get borrower details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/borrower");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getBorrower() close

    //this method will submit the borrower form in backend.
    onSubmit() {
      // removing ids from selectedBusinessType
      const business_types = this.selectedBusinessType.map(
        ({ id, ...rest }) => ({ ...rest })
      );
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let isError = false;
      // Check for empty dirDocList and null properties, and open specific modal
      this.directorsList.some((director, index) => {
        if (
          director.documents_list.length === 0 ||
          director.documents_list.some(
            doc =>
              doc.document_id === null ||
              doc.selectedFileName === null ||
              doc.directorDocumentIssueDate === null
          )
        ) {
          console.log(director.director_name, index);
          this.showDirDocModal(director.director_name, index);
          console.log("Error: Found empty subArray or null property in the sub-array.");
          isError = true;
          return true; // Stop the loop after opening the modal
        }
        return false;
      });
      if(isError) {
        return;
      }
      this.calcTotalPerc();
      if (this.totalPerc > parseFloat(100.0)) {
        Utils.make_alert(
          "warning",
          "Total Share Holding Pattern cannot be more than 100%"
        );
        this.totalPerc = 0;
        return;
      }
      // checking for vuelidate validations & email and website validations
      if (this.$v.$invalid || !Object.values(this.msg).every((e) => e === "")) {
        this.totalPerc = 0;
        return;
      }
      // checking for removed business types
      let business_type_list = business_types.map(function (obj) {
        return obj.business_type_name;
      });
      business_type_list = business_type_list.filter((a) =>
        this.prevBusinessType.includes(a)
      );
      let removedBusinessType = business_type_list
        .filter((x) => !this.prevBusinessType.includes(x))
        .concat(
          this.prevBusinessType.filter((x) => !business_type_list.includes(x))
        );

      if (this.incorporationDate != null) {
        this.incorporationDate1 = moment(this.incorporationDate).format(
          "MM-DD-YYYY"
        );
      }
      if (this.regDate != null) {
        this.regDate1 = moment(this.regDate).format("MM-DD-YYYY");
      }

      for (let i = 0; i < this.covenantList.length; i++) {
        if (this.covenantList[i].upload_date !== null) {
          this.covenantList[i].upload_date = moment(
            this.covenantList[i].upload_date
          ).format("MM-DD-YYYY");
        }
      }

      for (let i = 0; i < this.KYCList.length; i++) {
        if (this.KYCList[i].uploading_date !== null) {
          this.KYCList[i].uploading_date = moment(
            this.KYCList[i].uploading_date
          ).format("MM-DD-YYYY");
        }
        if (this.KYCList[i].expiry_date !== null) {
          this.KYCList[i].expiry_date = moment(
            this.KYCList[i].expiry_date
          ).format("MM-DD-YYYY");
        }
      }
      if (this.covenantList.length) {
        this.covenantList.map((cov) => {
          cov.actual_value = Number(cov.actual_value).toFixed(2);
          cov.expected_value = Number(cov.expected_value).toFixed(2);
        });
      }
      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("borrowerName", this.companyName);
      formData.append("registrationNo", this.regNo);
      formData.append("companyAddress", this.companyAddress);
      formData.append(
        "selectedBusinessOppRegion",
        this.selectedBusinessOppRegion
      );
      formData.append("selectedBusinessType", JSON.stringify(business_types));
      formData.append(
        "removedBusinessType",
        JSON.stringify(removedBusinessType)
      );
      formData.append("phoneNumber", this.phoneNumber);
      formData.append("phoneNumberCode", this.phoneNumberCode);
      formData.append("secondaryPhoneNumber", this.secondaryPhoneNumber);
      formData.append(
        "secondaryPhoneNumberCode",
        this.secondaryPhoneNumberCode
      );
      formData.append("companyWebsite", this.companyWebsite);
      formData.append("companyEmail", this.email);
      formData.append("companySecondaryEmail", this.secondaryEmail);
      formData.append("incorporationDate1", this.incorporationDate1);
      formData.append("regDate1", this.regDate1);
      formData.append("selectedState", this.selectedState);
      formData.append("selectedCity", this.selectedCity);
      //append directors information to formdata
      formData.append("directorDetails", JSON.stringify(this.directorsList));
      //append key management information to formdata
      formData.append("keyMgmtDetails", JSON.stringify(this.keyMgmtList));
      //append shareholders information to formdata
      formData.append(
        "shareHoldersDetails",
        JSON.stringify(this.shareHoldersList)
      );
      //append covenants information to formdata
      formData.append("covenantDetails", JSON.stringify(this.covenantList));
      //append covenants information to formdata
      formData.append("KYCDetails", JSON.stringify(this.KYCList));
      formData.append(
        "BorrowerDeleteData",
        JSON.stringify(this.BorrowerDeleteData)
      );
      formData.append("clientId", this.$store.state.client_id);
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 2);
      formData.append("tabId", 4);

      const path = "borrower/" + this.borrower_id;
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          if (response.data.status == "success") {
            Utils.make_alert("success", "Borrower Updated successfully!");
            console.log("SUCCESS!!");
            this.$router.push("/borrower");
          } else if (response.data.status == "company_already_exists") {
            Utils.make_alert("warning", "Borrower already exists!");
            this.$router.push("/borrower");
          } else {
            Utils.make_alert("warning", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          Utils.make_alert(
            "warning",
            error.response.status === 400
              ? error.response.data.status
              : "Something went wrong!"
          );
          console.error(error);
        });
    }, //onSubmit() close

    //this method will add new line for additional directors
    addDirector() {
      //when new line is added then dirSaveInfoDisabled (Save info button disable property) should be re initialised
      (this.dirSaveInfoDisabled = true),
        (this.dirAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.directorsList.push({
          director_name: null,
          designation: null,
          dir_tran_id: null,
          documents_list: [],
        });
    }, //addDirector() close

    //this method will add new line for additional director documents modal
    addDirDocument(currDirId) {
      //following block is created first time on mounted property
      this.directorsList[currDirId].documents_list.push({
        document_id: null,
        selectedFile: null,
        selectedFileName: null,
        directorDocumentIssueDate: null,
        directorDocumentExpiryDate: null,
        errors: "",
        edited: "false",
        dirRemoveDisabled: true,
        display_file_name: null,
        dir_doc_tran_id: null,
      });
    }, //addDirDocument() close

    //handles a change on the file upload
    handleFileUploadDirector(e, currDirId, id, docId) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        //currDirId: current director id
        //id: document id selected
        let self = this;
        let reader = new FileReader();
        this.dirFile = e.target.files[0];
        //check for filesize. if it is larger than threshold (100 MB) then return following error response
        if (this.dirFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors = "File size should be less than 100MB";
          self.directorsList[currDirId].documents_list[id].errors =
            JSON.stringify(response.errors).replace(/\"/g, "");
          self.directorsList[currDirId].documents_list[id].selectedFile = null;
          self.directorsList[currDirId].documents_list[id].selectedFileName =
            null;
          // self.directorsList[currDirId].documents_list[id].document_id = null;
          self.directorsList[currDirId].documents_list[
            id
          ].dirRemoveDisabled = true;
          self.directorsList[currDirId].documents_list[id].display_file_name =
            null;
          return;
        }
        //save file details
        reader.readAsDataURL(this.dirFile);
        reader.onload = function () {
          self.directorsList[currDirId].documents_list[id].selectedFile =
            reader.result;
          self.directorsList[currDirId].documents_list[id].selectedFileName =
            e.target.files[0].name;
          self.directorsList[currDirId].documents_list[id].document_id = docId;
          self.directorsList[currDirId].documents_list[id].errors = "";
          self.directorsList[currDirId].documents_list[
            id
          ].dirRemoveDisabled = false;
          self.directorsList[currDirId].documents_list[id].display_file_name =
            e.target.files[0].name;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadDirector() close

    //this method will add new line for additional directors
    addKeyMgmt() {
      //when new line is added then keyMgmtSaveInfoDisabled (Save info button disable property) should be re initialised to true.
      (this.keyMgmtSaveInfoDisabled = true),
        (this.keyMgmtAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.keyMgmtList.push({
          manager_name: null,
          designation: null,
          bk_tran_id: null,
          edited: "false",
          description: null,
        });
    }, //addKeyMgmt() close

    //this method will add new line for additional directors
    addShareHolder() {
      //when new line is added then sHSaveInfoDisabled (Save info button disable property) should be re initialised to true.
      (this.sHSaveInfoDisabled = true),
        (this.sHAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.shareHoldersList.push({
          shareholder_name: null,
          shareholding_pattern: null,
          shareholding_value: null,
          bs_tran_id: null,
          edited: "false",
          // saved: false,
        });
      this.counter += 1;
    }, //addShareHolder() close

    //this method will add new line for additional directors
    addKYC() {
      //when new line is added then KYCFile variable and KYCSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.KYCFile = null),
        (this.KYCSaveInfoDisabled = true),
        (this.KYCAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.KYCList.push({
          document_id: null,
          uploading_date: null,
          expiry_date: null,
          display_file_name: null,
          kyc_tran_id: null,
          comments: null,
          errors: "",
          KYCRemoveDisabled: true,
          edited: "false",
        });
    }, //addKYC() close

    //handles a change on the file upload
    handleFileUploadKYC(id, e, doc) {
      let file_list = this.KYCList.map((file) => file.display_file_name);
      let doc_list = this.KYCList.slice(0, -1).map((doc) => doc.document_id);
      // check if file already exists for same document name, show pop if true and delete record
      if (
        file_list.includes(e.target.files[0].name) &&
        doc_list.includes(doc)
      ) {
        this.deleteKYC(this.KYCList.length - 1, null);
        Utils.make_alert("warning", "File Already Exists");
        return;
      }
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.KYCFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.KYCFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: null };
          response.errors = "File size should be less than 100MB";
          self.KYCList[id].errors = JSON.stringify(response.errors).replace(
            /\"/g,
            ""
          );
          self.KYCList[id].selectedFile = null;
          self.KYCList[id].selectedFileName = null;
          self.KYCList[id].display_file_name = null;
          self.KYCList[id].KYCRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.KYCFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.KYCList[id].selectedFile = reader.result;
          self.KYCList[id].selectedFileName = e.target.files[0].name;
          self.KYCList[id].display_file_name = e.target.files[0].name; //to show filename in file input
          self.KYCList[id].errors = "";
          self.KYCList[id].KYCRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadKYC() close

    //this method will add new line for additional covenants
    addCovenant() {
      //when new line is added then covSaveInfoDisabled (Save info button disable property) should be re initialised to true.
      (this.covSaveInfoDisabled = true),
        (this.covAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.covenantList.push({
          covenants_master_id: null,
          upload_date: null,
          actual_value: null,
          expected_value: null,
          comments: null,
          compliant: "Yes",
          sign: null,
          cov_tran_id: null,
          edited: "false",
        });
    }, //addShareHolder() close

    ///////////////////////////////////////////////////////////////////////////////////////////////

    //this function is called to get list of documents master
    getDirDocuments() {
      const path = "director-document-master";
      axios
        .get(path)
        .then((res) => {
          this.DirDocuments = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getDirDocuments() close

    //this function is called to get list of documents master
    getKYCDocuments() {
      const path = "kyc-document-master";
      axios
        .get(path)
        .then((res) => {
          this.KYCDocuments = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getKYCDocuments() close

    //this function is called to get list of countries
    getCountries() {
      const path = "country";
      axios
        .get(path)
        .then((res) => {
          this.countries = res.data.countries;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getCountries() close
    
    getStatesByCountry(flag) {
      if(this.selectedBusinessOppRegion == null){
        this.states = [];
        this.selectedState = null;
        this.cities = [];
        this.selectedCity = null;
        return;
      }
      //this function is called to get list of states
      const path = "get-state-by-country/" + this.selectedBusinessOppRegion ;
      axios
        .get(path)
        .then((res) => {
          if(flag){
            this.states = [];
            this.selectedState = null;
            this.cities = [];
            this.selectedCity = null;
          }
          this.states = res.data.states;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCitiesByState(flag) {
      if(this.selectedState == null){
        this.cities = [];
        this.selectedCity = null;
        return;
      }
      //this function is called to get list of cities
      const path = "get-city-by-state/" + this.selectedState;
      axios
        .get(path)
        .then((res) => {
          if(flag){
            this.cities = [];
            this.selectedCity = null;
          }
          this.cities = res.data.cities;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    

    //this function is called to get list of business types
    getBusinessTypes() {
      const path = "business-type";
      axios
        .get(path)
        .then((res) => {
          this.businessTypes = res.data.business_types;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getBusinessTypes() close

    getCovenants() {
      //this function is called to get list of covenants
      const path = "covenant";
      axios
        .get(path)
        .then((res) => {
          this.covenants = res.data.covenants;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getCovenants() close

    getCities() {
      //this function is called to get list of cities
      const path = "city";
      axios
        .get(path)
        .then((res) => {
          this.cities = res.data.cities;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getStates() {
      //this function is called to get list of states
      const path = "state";
      axios
        .get(path)
        .then((res) => {
          this.states = res.data.states;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCountryCodes() {
      //this function is called to get list of country codes
      const path = "country_code_master";
      axios
        .get(path)
        .then((res) => {
          this.countryCodes = res.data.country_codes;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close

  mounted() {
    this.getBorrower();
  },
  created() {
    this.inactive = this.$route.params.inactive;
    this.borrower_id = this.$route.params.id;
    this.getCountries();
    this.getBusinessTypes();
    this.getDirDocuments();
    this.getKYCDocuments();
    this.getCovenants();
    this.getCountryCodes();
  },
};
</script>

<style scoped>
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -40px;
}

.required-field::after {
  content: "*";
  color: red;
}

.error {
  color: red;
}
input[type="radio"] {
  margin-left: 5px;
}
input[type="number"]:disabled {
  background: #dddddd !important;
}
</style>
