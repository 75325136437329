<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          Cost Overview
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                            <!-- <a href="cost.html">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                            </a>-->
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <span style="font-size: 14px; color: red; margin-left:30px !important; margin-bottom: 10px !important; margin-top: -10px !important;">
                      Note: All calculations are displayed in Fund Currency.</span>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover table-head-fixed text-nowrap">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Cost ID:</td>
                        <td v-if="cost.id !== null" class>
                          {{ cost.id }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Borrower Name:</td>
                        <td v-if="cost.borrower_name !== null" class>
                          {{ cost.borrower_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Facility ID:</td>
                        <td
                          v-if="cost.facility_registration_id !== null"
                          class
                        >
                          {{ cost.facility_registration_id }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Cost Name:</td>
                        <td v-if="cost.loan_expense_name !== null" class>
                          {{ cost.loan_expense_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Cost Amount (Converted):</td>
                        <td v-if="cost.fet_converted_exp_amt !== null" class>
                          {{ cost.fet_converted_exp_amt }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <!-- <tr>
                        <td class="wg">Cost Accrued Interest:</td>
                        <td v-if="cost.cost_accrued_interest !== null" class>
                          {{ cost.cost_accrued_interest }}
                        </td>
                        <td v-else class>-</td>
                      </tr> -->
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Conversion Date:</td>
                        <td v-if="cost.conversion_date !== null" class>
                          {{ cost.conversion_date }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Conversion Rate:</td>
                        <td v-if="cost.currency_rate !== null" class>
                          {{ cost.currency_rate }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Cost Currency:</td>
                        <td v-if="cost.currency_short_name !== null" class>
                          {{ cost.currency_short_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Date:</td>
                        <td v-if="cost.expense_date !== null" class>
                          {{ cost.expense_date }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Paid Amount:</td>
                        <td v-if="cost.fet_amount_paid !== null" class>
                          {{ cost.fet_amount_paid }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Outstanding Amount:</td>
                        <td v-if="cost.fet_outstanding_amount !== null" class>
                          {{ cost.fet_outstanding_amount }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Payment Date:</td>
                        <td v-if="cost.payment_date !== null" class>
                          {{ cost.payment_date }}
                        </td>
                        <td v-else class>-</td>
                      </tr>                      
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <!-- <i class="icofont-spinner-alt-3"></i>  -->
                          Bank Details
                        </h1>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover table-head-fixed text-nowrap">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Beneficiary Bank:</td>
                        <td v-if="bank.bank_name !== null" class>
                          {{ bank.bank_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Bank Identifier Code:</td>
                        <td v-if="bank.bic !== null" class>
                          {{ bank.bic }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">IBAN (International Bank Account No):</td>
                        <td
                          v-if="bank.iban !== null"
                          class
                        >
                          {{ bank.iban }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Account No. :</td>
                        <td
                          v-if="bank.account_number !== null"
                          class
                        >
                          {{ bank.account_number }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Account Name:</td>
                        <td v-if="bank.account_name !== null" class>
                          {{ bank.account_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Reference:</td>
                        <td v-if="bank.reference !== null" class>
                          {{ bank.reference }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          <div v-if="costDocumentsDetails.length > 0" class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documentation</h1>
                      </div>
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <!------menton here style="height: 300px;"-------->
                    <table class="table table-head-fixed text-nowrap">
                      <thead>
                        <tr>
                          <th>Document</th>
                          <th>Comment</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(cp) in costDocumentsDetails"
                          :key="cp.id"
                        >
                          <td>
                          <span v-on:click="downloadCostDocument(cp.document_path)"
                            :style="{ cursor: 'pointer' }" >
                            <i class="icofont-download text-info" style="padding-right: 10px;"></i>
                            {{ cp.display_file_name }}
                          </span>
                          </td>
                          <td v-if="cp.comments !== null">
                            {{ cp.comments }}
                          </td>
                          <td v-else>-</td>
                        </tr>
                        <!----------------------------------------------->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Loan Mapping</h1>
                      </div>
                      <!-- <div
                        class="col-lg-6"
                        v-if="
                          loggedInUserGroupId == 'Admin' ||
                          (loggedInUserGroupId == 'Analyst' &&
                            loggedInUserSubGroupId == 0)
                        "
                        >
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="showLoanModal"
                              :disabled="addLoanMappingDisabled"
                            >
                              <i class="icofont-plus"></i> Add Loan Mapping
                            </button>
                          </li>
                        </ul>
                      </div> -->
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <!------menton here style="height: 300px;"-------->
                    <table
                      class="table table-head-fixed table-bordered text-nowrap table-hover"
                    >
                      <thead>
                        <tr>
                          <th class="center col">Loan Regi. ID</th>
                          <th class="center col">Ratio</th>
                          <th class="center col">Amount</th>
                          <!-- <th class="center col">Cost Accrued Interest</th> -->
                          <th class="center col">Paid Amount</th>
                          <th class="center col">Outstanding Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(loan,index) in loanMappingList"
                          :key="index"
                        >
                          <td v-if="loan.loan_registration_id !== null"  class="right col">
                            {{ loan.loan_registration_id }}
                          </td>
                          <td v-else class="right col">-</td>
                          <td v-if="loan.expense_ratio !== null" class="right col">
                            {{ loan.expense_ratio }}
                          </td>
                          <td v-else  class="right col">-</td>
                          <td v-if="loan.converted_exp_amt !== null" class="right col">
                            {{ loan.converted_exp_amt }}
                          </td>
                          <td v-else  class="right col">-</td>
                          <!-- <td v-if="loan.cost_accrued_interest !== null" class="right col">
                            {{ loan.cost_accrued_interest }}
                          </td>
                          <td v-else  class="right col">-</td> -->
                          <td v-if="loan.amount_paid !== null" class="right col">
                            {{ loan.amount_paid }}
                          </td>
                          <td v-else  class="right col">-</td>
                          <td v-if="loan.outstanding_amount !== null" class="right col">
                            {{ loan.outstanding_amount }}
                          </td>
                          <td v-else class="right col">-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
          
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>
      <div class="modal fade" id="view_doc">
        <div class="modal-dialog mw-100 w-60 custom-width">
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">
                Loan Mapping
              </h4>
              <div style = "margin-left: 10px;" class="">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn btn-sm sh_n btn-success"
                      @click="addLoanMapping()"
                      :disabled="loanMappingAddMoreDisabled"
                    >
                      <i class="icofont-plus"></i> Add More
                    </button>
                  </li>
                </ul>
              </div>
              <button type="button" class="close" data-dismiss="modal">
                <i class="icofont-close-circled"></i>
              </button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
              <div class="row"></div>
              <div class="row">
                <div class="col-md-12">
                  <div class="card-body p-0">
                    <div v-if="this.loanMappingToggle">
                      <p>
                        <span style="font-size: 14px; color: red">
                        Max. ratio available to map is {{this.maxLoanMappingRatio}}.</span>
                      </p>
                    </div>
                    <div v-else-if="!this.loanMappingToggle">
                      <p>
                        <span style="font-size: 14px; color: red">
                          Max. amount available to map is {{this.maxLoanMappingAmount}}.</span>
                      </p>
                    </div>
                    <div v-else>
                    </div>

                    <!------menton here style="height: 300px;"-------->
                    <table
                        class="table table-head-fixed text-nowrap table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Loan Regi. ID</th>
                            <th v-if="offtakerRepaymentToggle == 1">Cost Date</th>
                            <th>Ratio</th>
                            <th>Amount</th>
                            <th>Paid Amount</th>
                            <th>Outstanding Amount</th>
                            <th></th>
                          </tr>
                        </thead>
  
                        <tbody>
                          <tr
                            v-for="(loan,index) in oldLoanMappingList"
                            :key="index"
                          >
                            <td v-if="loan.loan_registration_id !== null">
                                  <!-- <select
                                    id="selectedLoan"
                                    class="form-control"
                                    name="selectedLoan"
                                    v-model="loan.id"  
                                    disabled                                  
                                  >
                                    <option :value="null">--- Select ---</option>
                                    <option
                                      v-for="lm in loans"
                                      :value="loan.id"
                                      :key="lm.id"
                                    >
                                      {{ loan.loan_registration_id }}
                                    </option>
                                  </select> -->
                                  <input
                                  type="text"
                                  class="form-control"
                                  v-model="loan.loan_registration_id"
                                  @keypress="isDecimalTwoFixed($event)"
                                  @paste="truncateDecimalPlacesUptoTwo($event)"
                                  id="loanMappingRatio"
                                  disabled   
                                  />
                            </td>
                            <td v-else>-</td>
                            <td v-if="offtakerRepaymentToggle == 1">
                              <datepicker
                                class="datepicker"
                                :bootstrap-styling="true"
                                placeholder="Select Date"
                                id="loanCostDate"
                                v-model="loan.expense_date"
                                :format="$store.state.date_format"
                                calendar-button-icon="fa fa-calendar"
                                :calendar-button="true"
                                disabled
                              ></datepicker>
                            </td>
                            <td>
                              <input
                              type="text"
                              class="form-control"
                              v-model="loan.expense_ratio"
                              @keypress="isDecimalTwoFixed($event)"
                              @paste="truncateDecimalPlacesUptoTwo($event)"
                              id="loanMappingRatio"
                              disabled   
                              />
                            </td>
                            <td>
                              <input
                              type="text"
                              class="form-control"
                              v-model="loan.converted_exp_amt"
                              @keypress="isDecimalTwoFixed($event)"
                              @paste="truncateDecimalPlacesUptoTwo($event)"
                              id="loanMappingRatio"
                              disabled   
                              />
                              <!-- {{ loan.converted_exp_amt }} -->
                            </td>
                            <td>
                              <input
                              type="text"
                              class="form-control"
                              v-model="loan.amount_paid"
                              @keypress="isDecimalTwoFixed($event)"
                              @paste="truncateDecimalPlacesUptoTwo($event)"
                              id="loanMappingRatio"
                              disabled   
                              />
                            </td>
                            <td>
                              <input
                              type="text"
                              class="form-control"
                              v-model="loan.outstanding_amount"
                              @keypress="isDecimalTwoFixed($event)"
                              @paste="truncateDecimalPlacesUptoTwo($event)"
                              id="loanMappingRatio"
                              disabled   
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr
                            v-for="(lm,index) in newLoanMappingList"
                            :key="index"
                          >
                          <td>
                            <select
                              id="selectedLoan"
                              class="form-control"
                              name="selectedLoan"
                              v-model="lm.selectedLoan"
                              @change="onLoanSelectGetDetails(index)"
                            >
                              <option :value="null">--- Select ---</option>
                              <option
                                v-for="loan in loans"
                                :value="loan.id"
                                :key="loan.id"
                              >
                                {{ loan.loan_registration_id }}
                              </option>
                            </select>
                            </td>
                            <td v-if="offtakerRepaymentToggle == 1">
                              <datepicker
                                class="datepicker"
                                :bootstrap-styling="true"
                                placeholder="Select Date"
                                id="loanCostDate"
                                v-model="loan.expense_date"
                                :format="$store.state.date_format"
                                calendar-button-icon="fa fa-calendar"
                                :calendar-button="true"
                                :disabled="!lm.selectedLoan" 
                                :disabledDates="{ to: new Date(lm.backCapDate), from: new Date() }"
                              ></datepicker>
                            </td>
                            <td class="c">
                              <input
                              type="text"
                              class="form-control"
                              v-model="lm.loanMappingRatio"
                              @keypress="isDecimalTwoFixed($event)"
                              @paste="truncateDecimalPlacesUptoTwo($event)"
                              id="loanMappingRatio"
                              @keyup="onLoanMappingRatioChange(index)"
                              :class="{
                                'is-invalid':
                                  isSubmitted &&
                                  $v.newLoanMappingList.$each[index].loanMappingRatio
                                    .$error,
                              }"
                              :disabled="!loanMappingToggle || !lm.selectedLoan"
                              />
                              <div
                                v-if="
                                  isSubmitted &&
                                  $v.newLoanMappingList.$each[index].loanMappingRatio
                                    .$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.newLoanMappingList.$each[index]
                                      .loanMappingRatio.required
                                  "
                                  >Ratio is required</span
                                >
                                <span
                                  v-if="
                                    !$v.newLoanMappingList.$each[index]
                                      .loanMappingRatio.maxLength
                                  "
                                  >Max value of 5 digits exceeded.</span
                                >
                              </div>
                            </td>
                            
                            <!-- <td v-else class="c">-</td> -->
                            <td>
                              <input
                              type="text"
                              class="form-control"
                              v-model="lm.loanMappingAmount"
                              @keypress="isDecimalTwoFixed($event)"
                              @paste="truncateDecimalPlacesUptoTwo($event)"
                              id="loanMappingAmount"
                              @keyup="onLoanMappingAmountChange(index)"
                              :class="{
                                'is-invalid':
                                  isSubmitted &&
                                  $v.newLoanMappingList.$each[index]
                                    .loanMappingAmount.$error,
                              }"
                              :disabled="loanMappingToggle || !lm.selectedLoan"
                              />
                              <div
                                v-if="
                                  isSubmitted &&
                                  $v.newLoanMappingList.$each[index].loanMappingAmount
                                    .$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.newLoanMappingList.$each[index]
                                      .loanMappingAmount.required
                                  "
                                  >Amount is required</span
                                >
                                <span
                                  v-if="
                                    !$v.newLoanMappingList.$each[index]
                                      .loanMappingAmount.maxLength
                                  "
                                  >Max length of 15 digits exceeded.</span
                                >
                              </div>
                            </td>
                            <!-- <td v-else>-</td> -->
                            <td>
                              <input
                              type="text"
                              class="form-control"
                              v-model="lm.loanMappingAmountPaid"
                              @keypress="isDecimalTwoFixed($event)"
                              @paste="truncateDecimalPlacesUptoTwo($event)"
                              id="loanMappingAmountPaid"
                              disabled
                              />
                            </td>
                            <!-- <td v-else>-</td> -->
                            <td>
                              <input
                              type="text"
                              class="form-control"
                              v-model="lm.loanMappingOSAmount"
                              @keypress="isDecimalTwoFixed($event)"
                              @paste="truncateDecimalPlacesUptoTwo($event)"
                              id="loanMappingOSAmount"
                              disabled
                              />
                              <!-- {{ lm.loanMappingOSAmount }} -->
                            </td>
                            <!-- <td v-else>-</td> -->
                            <td>
                              <div
                                v-on:click="deleteLoanMapping(index)"
                                :style="{ cursor: 'pointer' }"
                              >
                                <i class="icofont-trash text-danger"></i>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer">
              <div class="mx-auto">
                <router-link :to="{ name: 'CostOverview' }">
                  <button
                    type="button"
                    name="saveInfo"
                    class="btn-sm btn btn-primary sh"
                    style="margin-right: 10px;"
                    v-on:click="onLoanMappingSaveInfo()"
                    :disabled="isDisabledLoanMappingSaveInfo"
                  >
                    Save Info
                  </button>
                </router-link>
                <router-link :to="{ name: 'CostOverview' }">
                  <button
                    type="button"
                    name="submit"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>


  </div>
  <!-- ./wrapper -->

  
  
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import moment from "moment";


export default {
  data() {
    return {
      id: null,
      // tabIndex: 0,
      // tabs: ["#one", "#two", "#three"],
      cost: [],
      bank: [],
      // Payment: [],
      costId: null,
      selectedCpCheckDocumentsList: [],
      costDocumentsDetails: [],
      isSubmitted:false,
      //list for Loan Mapping
      loans: [],
      loanMappingList: [],
      oldLoanMappingList: [],
      newLoanMappingList: [],
      fletId: null,
      currencyConversionRate: null,
      totalCostAmount:null,
      addLoanMappingDisabled:false,
      loanMappingSaveInfoDisabled: true, //save info button is disabled
      loanMappingAddMoreDisabled: false,
      loanMappingToggle: null,
      maxLoanMappingRato: null,
      maxLoanMappingAmount: null,
      totalPerc: 0,
      totalLoanAmount: 0,

      costDate:null,

      interestCalculationToggle: null,
      offtakerRepaymentToggle: 1,
    };
  },
  components: {
    Datepicker,
  },
  validations: {
    newLoanMappingList: {
      $each: {
        loanMappingRatio: {
          required: function (val) {
            if ((!val || val == 0) && this.loanMappingToggle) return false;
            else return true;
          },
          maxLength: function (val) {
            if (val ? val.length > 5 : false && !this.loanMappingToggle)
              return false;
            else return true;
          },
        },
        loanMappingAmount: {
          maxLength: function (val) {
            if (val ? val.length > 15 : false && !this.loanMappingToggle)
              return false;
            else return true;
          },
          required: function (val) {
            if ((!val || val == 0) && !this.loanMappingToggle) return false;
            else return true;
          },
        },
      },
    },
  },
  methods: {
    // isDecimal(e) {
    //   let invalidChars = ["-", "+", "e", "E"];
    //   if (invalidChars.includes(e.key)) return e.preventDefault();
    //   else return true;
    // },

    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },

    //this method will add new line for additional loan mapping
    addLoanMapping() {
      //when new line is added then loanMappingSaveInfoDisabled (Save info button disable property) should be re initialised to true.
        this.loanMappingSaveInfoDisabled = true;
        this.loanMappingAddMoreDisabled = true;
        //following block is created first time on mounted property
        this.newLoanMappingList.push({
          selectedLoan: null,
          loanCostDate: new Date(),
          loanMappingRatio: null,
          expenseAmount: null,
          loanMappingAmount: null,
          loanMappingAmountPaid: null,
          loanMappingOSAmount: null,
          backCapDate: new Date(),
        });
    }, //addLoanMapping() close

     //this method will make disabled property as false for Loan Mapping Save Info
    onLoanMappingSaveInfo() {
      if(this.loanMappingToggle){
        this.calcTotalPerc();
        if (this.totalPerc > parseFloat(this.maxLoanMappingRatio)) {
          Utils.make_alert(
            "warning",
            `The maximum Ratio available to map is ${this.maxLoanMappingRatio}%`
          );
        } else {
          Utils.make_alert("success", "Record saved.");
          this.loanMappingSaveInfoDisabled = false;
          this.loanMappingAddMoreDisabled = false;
        }
        this.totalPerc = 0;
      }
      if(!this.loanMappingToggle){
        this.calcTotalLoanAmount();
        if (this.totalLoanAmount > this.maxLoanMappingAmount) {
          Utils.make_alert(
            "warning",
            `The maximum Amount available to map is ${this.maxLoanMappingAmount}`
          );
        } else {
          Utils.make_alert("success", "Record saved.");
          this.loanMappingSaveInfoDisabled = false;
          this.loanMappingAddMoreDisabled = false;
        }
        this.totalLoanAmount = 0;
      }
    },

    calcTotalPerc() {
      for (let list in this.newLoanMappingList) {
        this.totalPerc += parseFloat(
          this.newLoanMappingList[list].loanMappingRatio
            ? this.newLoanMappingList[list].loanMappingRatio
            : 0
        );
      }
    },

    calcTotalLoanAmount() {
      for (let list in this.newLoanMappingList) {
        this.totalLoanAmount += parseFloat(
          this.newLoanMappingList[list].loanMappingAmount
            ? this.newLoanMappingList[list].loanMappingAmount
            : 0
        );
      }
    },

    deleteLoanMapping(index) {
      this.newLoanMappingList.splice(index, 1);
      console.log(typeof this.newLoanMappingList)
      if (
        typeof this.newLoanMappingList !== "undefined" &&
        this.newLoanMappingList.length == 0
      ) {
        console.log("HelloWorld")
        this.loanMappingAddMoreDisabled = false;
        this.loanMappingSaveInfoDisabled = true;
      }
      this.isSubmitted = false;
    },

    onLoanMappingRatioChange(index){
      let ratio = this.newLoanMappingList[index].loanMappingRatio
      console.log(ratio)
      console.log(this.totalCostAmount)
      let amount = (ratio*this.totalCostAmount)/100
      console.log(amount)
      this.newLoanMappingList[index].loanMappingAmount = ((ratio*this.totalCostAmount)/100).toFixed(2);
      this.newLoanMappingList[index].loanMappingOSAmount = ((ratio*this.totalCostAmount)/100).toFixed(2);
      this.newLoanMappingList[index].expenseAmount = ((this.newLoanMappingList[index].loanMappingAmount/this.currencyConversionRate)).toFixed(2);
      this.newLoanMappingList[index].loanMappingAmountPaid = 0
      
      // Check if there are decimal places in ratio
      if (ratio % 1 !== 0 && ratio.toString().split(".")[1].length >= 2) {
        console.log("There are: " + ratio)
        this.newLoanMappingList[index].loanMappingRatio = parseFloat(ratio).toFixed(2);
      } 
      // this.newLoanMappingList[index].loanMappingRatio = parseFloat(ratio).toFixed(2);
    },
    
    onLoanMappingAmountChange(index){
      let amount = this.newLoanMappingList[index].loanMappingAmount
      this.newLoanMappingList[index].loanMappingOSAmount = amount;
      this.newLoanMappingList[index].expenseAmount = ((amount/this.currencyConversionRate)).toFixed(2);
      this.newLoanMappingList[index].loanMappingAmountPaid = 0

      if (amount % 1 !== 0 && amount.toString().split(".")[1].length >= 2) {
        console.log("There are: " + amount)
        this.newLoanMappingList[index].loanMappingAmount = parseFloat(amount).toFixed(2);
      } 
    },

    // this method will check if existing loan mapping is added again
    onLoanSelectGetDetails(i) {
      console.log(i)
      let loan = this.newLoanMappingList.filter(
        (col) => col.selectedLoan === this.newLoanMappingList[i].selectedLoan)
      let selectedLoan = loan[0].selectedLoan
      let new_loan_count = this.newLoanMappingList.filter(
        (col) => col.selectedLoan === selectedLoan
      ).length;

      // delete if existing loan is found
      if (new_loan_count > 1) {
        this.deleteLoanMapping(i);
        Utils.make_alert("warning", "Loan Mapping already exists");
        return
      }
      let loan_count = this.loanMappingList.filter(
        (col) => col.loan_id === selectedLoan
      ).length;

      // delete if existing loan is found
      if (loan_count > 0) {
        this.deleteLoanMapping(i);
        Utils.make_alert("warning", "Loan Mapping already exists");
        return
      }

      let new_loan = this.loans.find(
        (col) => col.id == this.newLoanMappingList[i].selectedLoan)
      console.log("new_loan",new_loan)
      if (new_loan.expense_date){
        this.newLoanMappingList[i].backCapDate = new_loan.expense_date;
      }
      
    },

    showLoanModal(docList) {
      this.isSubmitted = false;
      if (docList.length)
        docList.map((doc) => {
          doc.issue_date = doc.issue_date
            ? moment(doc.issue_date).format("MM-DD-YYYY")
            : null;
          doc.expiry_date = doc.expiry_date
            ? moment(doc.expiry_date).format("MM-DD-YYYY")
            : null;
        });
      this.selectedDirDocumentsList = docList;
      this.loanMappingSaveInfoDisabled = true
      this.loanMappingAddMoreDisabled = true
      if (this.newLoanMappingList.length === 0) {
        this.newLoanMappingList.push({
            selectedLoan: null,
            loanCostDate: new Date(),
            loanMappingRatio: null,
            expenseAmount: null,
            loanMappingAmount: null,
            loanMappingAmountPaid: null,
            loanMappingOSAmount: null,
            backCapDate: new Date(),
          });
      }
      $("#view_doc").modal("show");
    },

    downloadCostDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },

    getLoan(id) {
      //this function is called to get list of loans
      console.log("hello, " + id)
      const path = "/get-loan-by-facility/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.loans = res.data.loans;
          let filteredLoanArray = []
          console.log("before",this.loans)
          if(this.oldLoanMappingList.length > 0) {
            filteredLoanArray = this.loans.filter(loan => 
              !this.oldLoanMappingList.some(comparison => 
                comparison.loan_id === loan.id
              )
            );
            this.loans = filteredLoanArray
          }
          console.log("after",this.loans)
          if(res.data.loans.length == 0){
            this.addLoanMappingDisabled = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.totalPerc = 0;
        this.totalLoanAmount = 0;
        return;
      }
      this.calcTotalPerc();
      // check if total loan mapping ratio is not greater than 100
      if ((this.totalPerc > parseFloat(this.maxLoanMappingRatio)) && (this.loanMappingToggle === true)) {
        Utils.make_alert(
          "warning",
          `The maximum Ratio available to map is ${this.maxLoanMappingRatio}%`
        );
        this.totalPerc = 0;
        return;
      }

      this.calcTotalLoanAmount();
      // check if total loan mapping amount is not greater than facility cost amount
      if ((this.totalLoanAmount > this.maxLoanMappingAmount) && (this.loanMappingToggle === false)) {
          Utils.make_alert(
            "warning",
            `The maximum Amount available to map is ${this.maxLoanMappingAmount}`
          );
          this.totalLoanAmount = 0;
          return;
        }

        // adding new item in the array for ratio if there is some ratio unmapped
      if(this.loanMappingToggle === true){
        let remainingLoanRatio= (this.maxLoanMappingRatio - this.totalPerc).toFixed(2);
        let remainingLoanAmount= ((remainingLoanRatio*this.totalCostAmount)/100).toFixed(2);
        let remainingExpenseAmount= (remainingLoanAmount/this.currencyConversionRate).toFixed(2)
        if(remainingLoanRatio != 0){
          this.newLoanMappingList.push({
            selectedLoan: null,
            loanCostDate: this.costDate,
            loanMappingRatio: remainingLoanRatio,
            expenseAmount: remainingExpenseAmount,
            loanMappingAmount: remainingLoanAmount,
            loanMappingAmountPaid: 0,
            loanMappingOSAmount: remainingLoanAmount,
            backCapDate: new Date(),
          });
        }
          this.totalPerc = 0;
      }

        // adding new item in the array for amount if there is some amount unmapped
        if(this.loanMappingToggle === false) {
          let remainingLoanAmount= (this.maxLoanMappingAmount - this.totalLoanAmount).toFixed(2)
          let remainingExpenseAmount= (remainingLoanAmount/this.currencyConversionRate).toFixed(2)
          if(remainingLoanAmount != 0){
            this.newLoanMappingList.push({
              selectedLoan: null,
              loanCostDate: this.costDate,
              loanMappingRatio: null,
              loanMappingAmount: remainingLoanAmount,
              expenseAmount: remainingExpenseAmount,
              loanMappingAmountPaid: 0,
              loanMappingOSAmount: remainingLoanAmount,
              backCapDate: new Date(),
            });
          }
          this.totalLoanAmount = 0;
        }
        $("#view_doc").modal("hide");

        if(this.offtakerRepaymentToggle == 0 || this.offtakerRepaymentToggle == null){
          this.newLoanMappingList.forEach((loan)=>{
            loan.loanCostDate = this.costDate;
          })
        }

        this.newLoanMappingList.forEach((loan)=>{
          if(loan.loanCostDate != null){
            loan.loanCostDate = moment(loan.loanCostDate).format("MM-DD-YYYY");
          }
        })

        if(this.costDate != null){
          this.costDate = moment(this.costDate).format("MM-DD-YYYY");
        }

        console.log(this.newLoanMappingList)

      //initialize the form data
      let formData = new FormData();
      //append loan mapping information to formdata
      formData.append("fletId", JSON.stringify(this.fletId));
      formData.append("currencyConversionRate", JSON.stringify(this.currencyConversionRate));
      formData.append("loanMappingList", JSON.stringify(this.loanMappingList));
      formData.append("newLoanMappingList", JSON.stringify(this.newLoanMappingList));
      formData.append("costDate",this.costDate);
      formData.append("interestCalculationToggle",this.interestCalculationToggle);
      formData.append("offtakerRepaymentToggle",this.offtakerRepaymentToggle);
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 4);
      formData.append("tabId", 34);
      const path = "cost-overview/" + this.id;
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          if (response.data.status == "success") {
            Utils.make_alert(
              "success",
              "Loan Mapping Details saved successfully!"
            );
            console.log("SUCCESS!!");
            // if you want to send any data into server before redirection then you can do it here
            setTimeout(
              () => this.$router.go(this.$router.currentRoute),
              1500
            );
          } else {
            Utils.make_alert("success", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
          Utils.make_alert("success", "Something went wrong!");
        });
    }, //onSubmit() close

    getCostOverview() {
      Utils.start_loading();
      //this function is called on page load and created events. it will show details on cost with costid equal to this.id
      const path = "cost-overview/" + this.id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          if (res.data.status == "success") {
            let cost_overview = res.data.cost_overview.facility_expense_transaction[0];
            let bank_overview = res.data.cost_overview.facility_expense_transaction[0];
            let cost_doc = res.data.cost_overview.facility_transaction_document;
            let loan_overview = res.data.cost_overview.facility_transaction_loan_mapping;
            this.cost = cost_overview;
            if (this.cost.expense_date != '-' && this.cost.expense_date != null) {
              this.cost.expense_date = moment(this.cost.expense_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.cost.expense_date = '-';
            }
            if (this.cost.conversion_date != '-' && this.cost.conversion_date != null) {
              this.cost.conversion_date = moment(this.cost.conversion_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.cost.conversion_date = '-';
            }
            if (this.cost.payment_date != '-' && this.cost.payment_date != null) {
              this.cost.payment_date = moment(this.cost.payment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.cost.payment_date = '-';
            }
            this.bank = bank_overview;
            this.costDocumentsDetails = cost_doc;
            this.loanMappingList = loan_overview;
            this.fletId = res.data.cost_overview.flet_id;
            this.costDate = res.data.cost_overview.expense_date;

            this.totalCostAmount = parseFloat(cost_overview.fet_converted_exp_amt.replaceAll(",", ""));

            if(cost_overview.currency_rate === null){
              this.currencyConversionRate = 1;
            } else{
              this.currencyConversionRate = cost_overview.currency_rate;
            }
            let lastEntry = loan_overview[loan_overview.length - 1];
            if(lastEntry.loan_id === null){
              this.addLoanMappingDisabled = false;
              console.log("in if")
              console.log(lastEntry.amount_paid)
              console.log(parseFloat(lastEntry.amount_paid))
              if(parseFloat(lastEntry.amount_paid) === parseFloat(0.00) ){
                console.log("in double if")
                if(lastEntry.converted_exp_amt != null){
                  this.maxLoanMappingAmount = lastEntry.converted_exp_amt.replaceAll(",", "")
                }
                if(lastEntry.expense_ratio != null){
                  this.maxLoanMappingRatio = lastEntry.expense_ratio
                }
                this.oldLoanMappingList = loan_overview.slice(0, -1);
              }
              else{
                this.addLoanMappingDisabled = true;
              }
            } else {
              console.log("in else if")
              this.addLoanMappingDisabled = true;
              if(lastEntry.converted_exp_amt != null){
                this.maxLoanMappingAmount = lastEntry.converted_exp_amt.replaceAll(",", "")
              }
              if(lastEntry.expense_ratio != null){
                this.maxLoanMappingRatio = lastEntry.expense_ratio
              }
              this.oldLoanMappingList = loan_overview;
            }
            let selectedFacility = cost_overview.facility_id
            if(loan_overview[0].expense_ratio == null){
              this.loanMappingToggle = false;
            }
            else{
              this.loanMappingToggle = true;
            }
            if(res.data.cost_overview.interest_calculation_flag){
              this.interestCalculationToggle = res.data.cost_overview.interest_calculation_flag;
            }
            if(res.data.cost_overview.offtaker_repayment_flag){
              this.offtakerRepaymentToggle = res.data.cost_overview.offtaker_repayment_flag;
            }
            this.getLoan(selectedFacility)


            console.log("get cost details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/facility");
          } else {
            console.log("Something went wrong from server!");
          }
          Utils.stop_loading();
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getCostOverview close
  },

  mounted() {
    this.id = this.$route.params.id;
    this.inactive =
      this.$store.state.inactive === "cost_inactive" ? true : false;
    this.getCostOverview();
  },
  computed: {
      isDisabledLoanMappingSaveInfo: function () {
        let checkEmptyLoanMappingID = this.newLoanMappingList.filter(
          (line) => line.selectedLoan === null
        );
        let checkEmptyLoanMappingRatio = this.newLoanMappingList
          .slice(-1)
          .filter((line) => line.shareHoldingPattern === null);
        let checkEmptyLoanMappingAmount = this.newLoanMappingList
          .slice(-1)
          .filter((line) => line.shareHoldingValue === null);
        //if the line is empty then disable save info button
        if (
          this.newLoanMappingList.length === 0 &&
          this.loanMappingSaveInfoDisabled === true
        ) {
          return true;
        }
        //We are checking whether all the input fields are filled. If condition is satisfied then only save info button will be enable
        if (
          (checkEmptyLoanMappingID.length >= 1 &&
            this.newLoanMappingList.length > 0) ||
          (checkEmptyLoanMappingRatio.length >= 1 &&
            this.newLoanMappingList.length > 0 &&
            checkEmptyLoanMappingAmount.length >= 1 &&
            this.newLoanMappingList.length > 0)
        ) {
          return true;
        } else {
          return false;
        }
      }, //isDisabledLoanMappingSaveInfo() close
    
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style scoped>
.modal-dialog.custom-width {
    width: 65% !important;
    max-width: 1000px !important;
  }
th,
td {
  text-align: left;
}
.col{
  width: 120px !important;
}
.center {
  text-align: center;
}
.right {
  text-align: right !important;
}
</style>
