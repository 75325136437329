<!-- This is add Loan page. -->

<template>
  <div>
    <!-- ./wrapper -->
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-plus"></i> Edit Utilisation Request
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <span style="font-size: 12px; color: red"
              >Note: Only single operation can be handled at a time (Edit or
              Delete)</span
            >
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Borrower Name</label
                      >
                      <select
                        id="selectedBorrowerName"
                        class="form-control"
                        name="selectedBorrowerName"
                        v-model="selectedBorrowerName"
                        required
                        :disabled="true"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedBorrowerName.$error,
                        }"
                      >
                        <option
                          v-for="borrower in borrowers"
                          :value="borrower.borrower_name"
                          :key="borrower.borrower_id"
                        >
                          {{ borrower.borrower_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedBorrowerName.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedBorrowerName.required"
                          >Borrower Name is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Facility ID</label
                      >
                      <select
                        id="facility_registration_id"
                        class="form-control"
                        name="facility_registration_id"
                        v-model="facility_registration_id"
                        required
                        :disabled="true"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.facility_registration_id.$error,
                        }"
                      >
                        <option
                          v-for="fac in FacilitySetup"
                          :value="fac.id"
                          :key="fac.id"
                        >
                          {{ fac.facility_registration_id }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.facility_registration_id.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.facility_registration_id.required"
                          >Facility ID is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Fund name</label>
                      <select
                        id="selectedFund"
                        class="form-control"
                        name="selectedFund"
                        v-model="selectedFund"
                        :disabled="true"
                        required
                      >
                        <option
                          v-for="fund in Funds"
                          :value="fund.fund_id"
                          :key="fund.fund_id"
                        >
                          {{ fund.fund_name }}
                        </option>
                      </select>
                      <!-- <div
                        v-if="isSubmitted && $v.selectedFund.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedFund.required"
                          >Fund Name is required</span
                        >
                      </div> -->
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Request Date</label
                      >
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="RequestDate"
                        placeholder="Select Date"
                        v-model="RequestDate"
                        :format="$store.state.date_format"
                        @input="insertBackcapConversionDate()"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabled="true"
                        :class="{
                          'is-invalid': isSubmitted && $v.RequestDate.$error,
                        }"
                      ></datepicker>
                      <div
                        v-if="isSubmitted && $v.RequestDate.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.RequestDate.required"
                          >Request Date is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Loan Amount (Requested)</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder
                        v-model="Amount"
                        min="1"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                        :class="{
                          'is-invalid': isSubmitted && $v.Amount.$error,
                        }"
                        disabled
                      />
                        <!-- :disabled="amountDisable" -->
                      <div
                        v-if="isSubmitted && $v.Amount.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Amount.required"
                          >Loan Amount (Requested) is required</span
                        >
                        <span v-if="!$v.Amount.maxLength"
                          >Max length of 15 characters exceeded<br
                        /></span>
                        <!-- <span v-if="!$v.Amount.facilityLimit">
                          Requested Amount should not exceed facility limit
                        </span> -->
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Interest Rate Type</label
                      >
                      <select
                        id="selectedInterestRateType"
                        class="form-control"
                        name="selectedInterestRateType"
                        v-model="selectedInterestRateType"
                        @change="onChangeInterestRateType()"
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedInterestRateType.$error,
                        }"
                        disabled
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="irt in InterestRateType"
                          :value="irt.id"
                          :key="irt.id"
                        >
                          {{ irt.loan_interest_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedInterestRateType.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedInterestRateType.required"
                          >Interest Rate Type is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="selectedInterestRateType == 1 || selectedInterestRateType == 2"
                    class="col-md-2 col-sm-5 col-6"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Interest Rate(%)</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder
                        v-model="InterestRate"
                        id="InterestRate"
                        min="1"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                        :class="{
                          'is-invalid': isSubmitted && $v.InterestRate.$error,
                        }"
                        disabled
                      />
                      <div
                        v-if="isSubmitted && $v.InterestRate.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.InterestRate.required"
                          >Interest Rate is required</span
                        >
                        <span v-if="!$v.InterestRate.maxDigit"
                          >Interest Rate cannot exceed 100%</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="selectedInterestRateType == 3 || selectedInterestRateType == 4"
                    class="col-md-2 col-sm-5 col-6"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Spread(%)</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder
                        v-model="Spread"
                        id="Spread"
                        min="1"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                        disabled
                        :class="{
                          'is-invalid': isSubmitted && $v.Spread.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.Spread.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Spread.required">Spread is required</span>
                        <span v-if="!$v.Spread.maxDigit"
                          >Spread cannot exceed 100%</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="selectedInterestRateType == 3 || selectedInterestRateType == 4"
                    class="col-md-2 col-sm-5 col-6"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Benchmark</label
                      >
                      <select
                        id="selectedBenchmark"
                        class="form-control"
                        name="selectedBenchmark"
                        v-model="selectedBenchmark"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedBenchmark.$error,
                        }"
                        disabled
                      >
                        <option :value="null">-- Select a Benchmark --</option>
                        <option
                          v-for="benchmark in benchmarks"
                          :value="benchmark.id"
                          :key="benchmark.id"
                        >
                          {{ benchmark.benchmark_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedBenchmark.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedBenchmark.required"
                          >Benchmark is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                      >Tenure</label
                      >
                      <input
                        class="form-control"
                        placeholder
                        v-model="Tenure"
                        disabled
                        @keypress="isNum($event)"
                        :class="{
                          'is-invalid': isSubmitted && $v.Tenure.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.Tenure.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Tenure.required"
                          >Tenure is required</span
                        >
                        <span v-if="!$v.Tenure.maxLength"
                          >Max length of 3 digits exceeded.</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Period</label>
                      <select
                        id="selectedPeriod"
                        class="form-control"
                        name="selectedPeriod"
                        :value="selectedPeriod"
                        disabled
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedPeriod.$error,
                        }"
                      >
                        <option value="Days">Days</option>
                        <option value="Weeks">Weeks</option>
                        <option value="Months">Months</option>
                        <option value="Years">Years</option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedPeriod.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedPeriod.required"
                          >Period is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Day Count</label
                      >
                      <select
                        id="selectedDayCount"
                        class="form-control"
                        name="selectedDayCount"
                        v-model="selectedDayCount"
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedDayCount.$error,
                        }"
                        disabled
                      >
                        <option :value="null">-- Select a Count --</option>
                        <option v-for="dc in dayCounts" :value="dc.id" :key="dc.id">
                          {{ dc.day_count_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedDayCount.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedDayCount.required"
                          >Day Count is required</span
                        >
                      </div>
                    </div>
                  </div>


                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Loan Currency</label>
                      <select
                        id="selectedLoanCurrency"
                        class="form-control"
                        name="selectedLoanCurrency"
                        v-model="selectedLoanCurrency"
                        @change="getLoanCurrencyExchangeRateByCurrencyDate()"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedLoanCurrency.$error,
                        }"
                        disabled
                      >
                        <option :value="null">-- Select a Currency --</option>
                        <option
                          v-for="curr in currencies_loan"
                          :value="curr.id"
                          :key="curr.id"
                        >
                          {{ curr.currency_short_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedLoanCurrency.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedLoanCurrency.required"
                          >Loan Currency is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Fund Currency</label>
                      <select
                        id="selectedFundCurrency"
                        class="form-control"
                        name="selectedFundCurrency"
                        v-model="selectedFundCurrency"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedFundCurrency.$error,
                        }"
                        disabled
                      >
                        <option :value="null">-- Select a Currency --</option>
                        <option
                          v-for="curr in currencies_loan"
                          :value="curr.id"
                          :key="curr.id"
                        >
                          {{ curr.currency_short_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedFundCurrency.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedFundCurrency.required"
                          >Fund Currency is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="conversionDate" class="required-field">Conversion Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        placeholder="Select Date"
                        @input="getLoanCurrencyExchangeRateByCurrencyDate()"
                        id="conversionDate"
                        v-model="conversionDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :class="{
                        'is-invalid': isSubmitted && $v.conversionDate.$error,
                        }"
                        :calendar-button="true"
                        :disabledDates="{ to: new Date(backcapConversionDate) , from: new Date() }"
                        disabled
                        ></datepicker>
                      <div
                          v-if="isSubmitted && $v.conversionDate.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.conversionDate.required"
                            >Conversion Date is required</span>
                      </div>
                    </div>
                  </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="conversionRate" class="required-field">Conversion Rate</label>
                    <input
                      type="number"
                      class="form-control"
                      id="conversionRate"
                      v-model="conversionRate"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.conversionRate.$error,
                      }"
                      disabled
                      />
                    <div
                      v-if="isSubmitted && $v.conversionRate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.conversionRate.required"
                        >Conversion Rate is required</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="finalLoanRequestedAmount" class="required-field">Final Loan Amount</label>
                    <input
                      type="text"
                      class="form-control"
                      id="finalLoanRequestedAmount"
                      v-model="finalLoanRequestedAmount"
                      @keypress="isDecimalTwoFixed($event)"
                      @paste="truncateDecimalPlacesUptoTwo($event)"
                      @keyup="calculateContractValue()"
                      :class="{
                        'is-invalid': isSubmitted && $v.finalLoanRequestedAmount.$error,
                      }"
                      disabled
                    />
                    <div
                      v-if="isSubmitted && $v.finalLoanRequestedAmount.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.finalLoanRequestedAmount.required"
                      >Final Loan Amount is required</span>
                      <span v-if="!$v.finalLoanRequestedAmount.maxLength"
                        >Max length of 15 characters exceeded<br
                      /></span>
                    </div>
                  </div>
                </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1"
                        >Supplier</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder
                        v-model="Supplier"
                        :class="{
                          'is-invalid': isSubmitted && $v.Supplier.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.Supplier.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Supplier.maxLength"
                          >Max length of 50 characters exceeded</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-4 col-6">
                    <div class="form-group fc">
                      <label for="comments">Comments</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Type..."
                        v-model="comments"
                        id="comments"
                        :class="{
                          'is-invalid': isSubmitted && $v.comments.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted &&$v.comments.$error"
                        class="invalid-feedback"
                      >
                        <span
                          v-if="! $v.comments.maxLength"
                          >Max length of 100 characters exceeded</span
                        >
                      </div>
                    </div>
                  </div>

                  <!-- <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Modification Date</label
                      >
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="ModificationDate"
                        placeholder="Select Date"
                        v-model="ModificationDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{
                          to: new Date(RequestDate),
                          from: new Date(),
                        }"
                      ></datepicker>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <!-- 0 means collateral type -->

          <div v-if="this.facCollateralType == 0" class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Offtaker Contract</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addOfftakerContract"
                              :disabled="disableLoanContractMappingPermanently || offtakerContractAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <span style="font-size: 12px; color: red"
                        >Note: The Contract Quantity being mapped against this particular loan cannot be utilized at the time of partial contract.</span
                      >
                    <!------menton here style="height: 300px;"-------->
                    <div
                      class="row"
                      v-for="(off, index) in oldOfftakercontractList"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Offtaker Name</label
                          >
                          <input 
                            type="text"
                            id="offtakerName"
                            class="form-control"
                            name="offtakerName"
                            v-model="off.offtakerName"
                            disabled
                          />
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" :class="{'required-field':off.requiredFlag}"
                            >Contract No.</label
                          >
                          <input 
                            type="text"
                            id="contractNo"
                            class="form-control"
                            name="contractNo"
                            v-model="off.contractNo"
                            disabled
                          />
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" 
                            >Contract Date</label
                          >
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="ContractDate"
                            placeholder="Select Date"
                            v-model="off.ContractDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{ from: new Date() }"
                            disabled
                          ></datepicker>
                        </div>
                      </div>

                      <div v-if="off.partialContractNo" class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" :class="{'required-field':off.repaymentFlag == 1}"
                            >Partial Contract No.</label
                          >
                          <input
                            type="text"
                            id="partialContractNo"
                            class="form-control"
                            name="partialContractNo"
                            v-model="off.partialContractNo"
                            disabled
                          />
                        </div>
                      </div>

                      <div v-if="off.partialContractNo" class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Partial Contract Date</label
                          >
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="PartialContractDate"
                            placeholder="Select Date"
                            v-model="off.PartialContractDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            disabled
                          ></datepicker>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Total Contract Quantity</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder
                            v-model="off.TotalContractQuantity"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            @keyup="onChangeQuantityValidation(index)"
                            disabled
                          />
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Available Contract Quantity</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="off.Quantity"
                            disabled
                          />
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" 
                            >Unit</label
                          >
                          <input
                            type="text"
                            id="selectedUnit"
                            class="form-control"
                            name="selectedUnit"
                            v-model="off.selectedUnit"
                            disabled
                          />
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" 
                            >Collateral</label
                          >
                          <input
                            type="text"
                            id="selectedCollateral"
                            class="form-control"
                            name="selectedCollateral"
                            v-model="off.selectedCollateral"
                            disabled
                          />
                        </div>
                      </div>

                      <div v-if="!disableLoanContractMappingPermanently" class="col-md-1 col-sm-4 col-6">
                        <div v-if="off.repaymentDoneFlag == 0" class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            style="width: 250px"
                            v-on:click="deleteOldOfftakerContract(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- New User entry -->
                    <div
                      class="row"
                      v-for="(off, index) in offtakercontractList"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Offtaker Name</label
                          >
                          <select
                            id="offtakerNameID"
                            class="form-control"
                            name="offtakerNameID"
                            v-model="off.offtakerNameID"
                            @change="getContractNoByOfftakerName(index,off.offtakerNameID)"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="off in offtakerNameIDs"
                              :value="off.id"
                              :key="off.id"
                            >
                              {{ off.offtaker_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" :class="{'required-field':off.requiredFlag}"
                            >Contract No.</label
                          >
                          <select
                            id="contractNo"
                            class="form-control"
                            name="contractNo"
                            v-model="off.contractNo"
                            @change="getContractNoDetails(index,off.contractNo)"
                            :class="{
                              'is-invalid':
                                isSubmitted && off.requiredFlag && (!off.contractNo)
                            }"
                            :disabled="!off.offtakerNameID"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="cn in off.contractNumber"
                              :value="cn.contractId"
                              :key="cn.contractId"
                            >
                              {{ cn.contractNo }}
                            </option>
                          </select>
                          <div
                            v-if="
                              isSubmitted && off.requiredFlag
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="(!off.contractNo)"
                              >Contract No. is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" 
                            >Contract Date</label
                          >
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="ContractDate"
                            placeholder="Select Date"
                            v-model="off.ContractDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{ from: new Date() }"
                            disabled
                          ></datepicker>
                        </div>
                      </div>

                      <div v-if="off.partialContractNumber.length > 0 && off.contractNo" class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" :class="{'required-field':off.repaymentFlag == 1}"
                            >Partial Contract No.</label
                          >
                          <select
                            id="partialContractNo"
                            class="form-control"
                            name="partialContractNo"
                            v-model="off.partialContractNo"
                            @change="getPartialContractNoDetails(index,off.contractNo,off.partialContractNo)"
                            :class="{
                              'is-invalid':
                                isSubmitted && (off.repaymentFlag == 1) && (!off.partialContractNo)
                            }"
                            :disabled="!off.offtakerNameID"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="cn in off.partialContractNumber"
                              :value="cn.partialContractId"
                              :key="cn.partialContractId"
                            >
                              {{ cn.partialContractNo }}
                            </option>
                          </select>
                          <div
                            v-if="
                              isSubmitted && (off.repaymentFlag == 1)
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="(!off.partialContractNo)"
                              >Partial Contract No. is required</span
                            >
                          </div>
                          <!-- <div
                            v-if="
                              isSubmitted &&
                              $v.offtakercontractList.$each[index].contractNo
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.offtakercontractList.$each[index].contractNo
                                  .required
                              "
                              >Contract No. is required</span
                            >
                          </div> -->
                        </div>
                      </div>

                      <div v-if="off.partialContractNo" class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Partial Contract Date</label
                          >
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="PartialContractDate"
                            placeholder="Select Date"
                            v-model="off.PartialContractDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{ from: new Date() }"
                            disabled
                          ></datepicker>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Total Contract Quantity</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder
                            v-model="off.TotalContractQuantity"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            @keyup="onChangeQuantityValidation(index)"
                            disabled
                          />
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Available Contract Quantity</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="off.Quantity"
                            disabled
                          />
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" 
                            >Unit</label
                          >
                          <select
                            id="selectedUnit"
                            class="form-control"
                            name="selectedUnit"
                            v-model="off.selectedUnit"
                            disabled
                          >
                            <option :value="null">
                              -- Unit --
                            </option>
                            <option
                              v-for="unit in UnitTypes"
                              :value="unit.id"
                              :key="unit.id"
                            >
                              {{ unit.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" 
                            >Collateral</label
                          >
                          <select
                            id="selectedCollateral"
                            class="form-control"
                            name="selectedCollateral"
                            v-model="off.selectedCollateral"
                            disabled
                          >
                            <option :value="null">
                              -- Collateral --
                            </option>
                            <option
                              v-for="col in collaterals"
                              :value="col.id"
                              :key="col.id"
                            >
                              {{ col.collateral_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-1 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            style="width: 250px"
                            v-on:click="deleteOfftakerContract(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onOfftakerContractSaveInfo()"
                          :disabled="disableLoanContractMappingPermanently || isDisabledOfftakerContractSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documents</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addLoanDoc()"
                              :disabled="LoanDocumentsAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(doc, index) in LoanDocuments"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label class="required-field">Select Document</label>
                          <select
                            id="LoanDocName"
                            class="form-control"
                            name="LoanDocName"
                            v-model="doc.document_id"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.LoanDocuments.$each[index].document_id
                                  .$error,
                            }"
                            @change="addEditedFlag(index)"
                            :disabled="doc.id > 0"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="document in LoanDocs"
                              :value="document.id"
                              :key="document.id"
                            >
                              {{ document.document_name }}
                            </option>
                          </select>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.LoanDocuments.$each[index].document_id.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.LoanDocuments.$each[index].document_id
                                  .required
                              "
                              >Document Name is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div v-if="doc.id">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Download</label>
                          <div
                            id="kycDownload"
                            v-on:click="downloadDocument(doc.document_path)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-download text-info"></i>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" class="required-field"
                            >Upload New Document</label
                          >
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="customFile"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              v-on:change="
                                handleFileUploadLoanDocuments(index, $event) &
                                  addEditedFlag(index)
                              "
                              :disabled="doc.id > 0"
                              :class="{
                                'is-invalid':
                                  isSubmitted && doc.display_file_name === null,
                              }"
                            />
                            <div
                              v-if="
                                isSubmitted && doc.display_file_name === null
                              "
                              class="invalid-feedback"
                            >
                              <span v-if="doc.display_file_name === null"
                                >Document is required</span
                              >
                            </div>
                            <small>
                              <p style="background-color: powderblue">
                                <b>{{ doc.display_file_name }}</b>
                                <small>
                                  <u
                                    style="color: blue; cursor: pointer"
                                    v-if="
                                      doc.LoanDocumentRemoveDisabled === false
                                    "
                                    v-on:click="removeLoanDocumentsDoc(index)"
                                    >Remove</u
                                  >
                                </small>
                              </p>
                            </small>
                            <small>
                              <p class="error" v-if="doc.errors">
                                {{ doc.errors }}
                              </p>
                            </small>
                            <label class="custom-file-label" for="customFile"
                              >Choose file</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" class="required-field"
                            >Uploading Date</label
                          >
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="LoanDocumentsDate"
                            placeholder="Select Date"
                            v-model="doc.LoanDocumentsDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.LoanDocuments.$each[index].LoanDocumentsDate
                                  .$error,
                            }"
                            @input="addEditedFlag(index)"
                          ></datepicker>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.LoanDocuments.$each[index].LoanDocumentsDate
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.LoanDocuments.$each[index].LoanDocumentsDate
                                  .required
                              "
                              >Document Date is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Expiry Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="ExpiryDate"
                            placeholder="Select Date"
                            v-model="doc.ExpiryDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{
                              to: new Date(doc.LoanDocumentsDate - 8640000),
                            }"
                            @input="addEditedFlag(index)"
                          ></datepicker>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Comments</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder
                            v-model="doc.comments"
                            @change="addEditedFlag(index)"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.LoanDocuments.$each[index].comments.$error,
                            }"
                          />
                          <div
                            v-if="
                              isSubmitted &&
                              $v.LoanDocuments.$each[index].comments.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.LoanDocuments.$each[index].comments
                                  .maxLength
                              "
                              >Max length of 50 characters exceeded</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-1 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            style="width: 250px"
                            v-on:click="deleteLoanDocuments(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onLoanDocumentSaveInfo()"
                          :disabled="isDisabledLoanDocumentSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-12">
                        <h1 class="headingt">Interest Chargeable</h1>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body fc p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div class="row">
                      <div class="col-md-4">
                        <div class="custom-control custom-checkbox mb-3">
                          <input
                            type="radio"
                            class="custom-control-input"
                            id="customCheck"
                            name="example1"
                            v-model="InterestDrawdown"
                          />
                          <label>
                            <input
                              type="radio"
                              v-model="InterestChargeable"
                              value="InterestDrawdown"
                              :disabled="true"
                            />Interest on Drawdown Date (Drawdown Amount)
                          </label>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="custom-control custom-checkbox mb-3">
                          <input
                            type="radio"
                            class="custom-control-input"
                            id="customChecka"
                            name="example1"
                            v-model="InterestUtilisationRequest"
                          />
                          <label>
                            <input
                              type="radio"
                              v-model="InterestChargeable"
                              value="InterestUtilisationRequest"
                              :disabled="true"
                            />Interest on Request Date (Principal Amount)
                          </label>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="custom-control custom-checkbox mb-3">
                          <input
                            type="radio"
                            class="custom-control-input"
                            id="customChecka"
                            name="example1"
                            v-model="InterestUtilisationRequestDrawdownAmount"
                          />
                          <label>
                            <input
                              type="radio"
                              v-model="InterestChargeable"
                              value="InterestUtilisationRequestDrawdownAmount"
                              :disabled="true"
                            />Interest on Request Date (Drawdown Amount)
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-success sh"
                      v-on:click="onSubmit()"
                    >
                      Submit
                    </button>
                  </li>
                  <li>
                    <a @click="$router.go(-1)">
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>
    <!-- ./wrapper -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      //master tables
      id: null,
      //variables for Loan information
      selectedFund: null,
      Funds: [],
      borrower_id: null,
      selectedBorrowerName: null,
      borrowers: [],
      facility_registration_id: null,
      FacilitySetup: [],
      RequestDate: null,
      RequestDate1: null,
      // ModificationDate: new Date(),
      Tenure: null,
      selectedPeriod: null,
      selectedBuyer: null,
      Buyer: [],
      Contract_Quantity: null,
      Payment_Details: null,
      Utilisation_ID: null,
      selectedCountry: null,
      selectedInterestRateType: null,
      InterestRateType: [],
      simpleInterestFlag:0,
      compoundInterestFlag:0,
      InterestRate:null,
      Spread:null,
      selectedBenchmark:null,
      benchmark:[],
      countries: [],
      Amount: null,
      selectedCurrencyLoan: null,
      selectedCurrency: null,
      currencies_loan: [],
      Contact_no: null,
      selectedFundCurrency: null,
      selectedLoanCurrency: null,
      conversionDate:null,
      backcapConversionDate:new Date(moment(new Date()).subtract(10, "year")),
      conversionRate:null,
      finalLoanRequestedAmount: null,
      Supplier: null,
      comments: null,
      selectedDayCount: null,
      dayCounts: [],
      selectedUnit: null,
      UnitTypes: [],
      facCollateralType: null,


      //list for Loan Documents
      LoanDocuments: [],
      DeleteDocData: [],
      LoanDocumentsLength: null,
      LoanDocs: [],
      LoanDocumentFile: null,
      LoanDocumentsDisabled: true,
      LoanDocumentSaveInfoDisabled: true, //save info button is disabled
      LoanDocumentsAddMoreDisabled: false,
      collaterals: [],
      finalOldOfftakercontractList: [],
      oldOfftakercontractList: [],
      offtakercontractList: [],
      contractType: 'Offtaker',
      currencies: [],
      disableLoanContractMappingPermanently: false,
      offtakerContractSaveInfoDisabled: true, //save info button is disabled
      // offtakerOLDContractSaveInfoDisabled: true, //save info button is disabled
      offtakerContractAddMoreDisabled: false,
      //list for Interest Chargeable
      InterestChargeable: null,
      InterestDrawdown: null,
      InterestUtilisationRequest: null,
      InterestUtilisationRequestDrawdownAmount: null,

      isSubmitted: false,
      amountDisable: false,
      compare_requested_amount: null,
      facility_limit: null,
    };
  },
  validations: {
    selectedBorrowerName: {
      required,
    },
    facility_registration_id: {
      required,
    },
    selectedFund: {
      required,
    },
    RequestDate: {
      required,
    },
    Amount: {
      required,
      maxLength: maxLength(15),
      // facilityLimit: function(value) {
      //   const containsFacilityLimit =
      //     Number(value) + Number(this.compare_requested_amount) <=
      //     Number(this.facility_limit);
      //   return containsFacilityLimit;
      // },
    },
    Tenure: {
      required,
      maxLength: maxLength(3),
    },
    selectedPeriod: {
      required,
    },
    selectedInterestRateType: {
      required,
    },
    InterestRate: {
      required: function () {
        if (
          (this.selectedInterestRateType == 1 || this.selectedInterestRateType == 2) &&
          (this.InterestRate == null || this.InterestRate == '' || this.InterestRate == 0)
        ) {
          return false;
        } else {
          return true;
        }
      },
      // validation for value greater than 2 digits 3 decimals
      maxDigit: function (val) {
        if (Number(val) > 100 && (this.selectedInterestRateType == 1 || this.selectedInterestRateType == 2))
          return false;
        else return true;
      },
    },
    Spread: {
      required: function () {
        if (
          (this.selectedInterestRateType == 3 || this.selectedInterestRateType == 4) &&
          (this.Spread == null || this.Spread == '' || this.Spread == 0)
        ) {
          return false;
        } else {
          return true;
        }
      },
      // validation for value greater than 2 digits 3 decimals
      maxDigit: function (val) {
        if (Number(val) > 100 && (this.selectedInterestRateType == 3 || this.selectedInterestRateType == 4))
          return false;
        else return true;
      },
    },
    selectedBenchmark: {
      required: function () {
        if (
          (this.selectedInterestRateType == 3 || this.selectedInterestRateType == 4) &&
          this.selectedBenchmark === null
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
    selectedDayCount: {
      required,
    },
    selectedFundCurrency: {
      required,
    },
    selectedLoanCurrency: {
      required,
    },
    conversionDate: {
      required,
    },
    conversionRate: {
      required,
    },
    finalLoanRequestedAmount: {
      required,
      maxLength: maxLength(15),
      // facilityLimit: function (value) {
      //   const containsFacilityLimit =
      //     Number(value) + Number(this.compare_requested_amount) <=
      //     Number(this.facility_limit);
      //   console.log("containsFacilityLimit: ", containsFacilityLimit);
      //   return containsFacilityLimit;
      // },
    },
    Supplier: {
      maxLength: maxLength(50),
    },
    comments: {
      maxLength: maxLength(100),
    },
    oldOfftakercontractList: {
      $each: {
        Quantity: {
          maxLength: maxLength(15),
        },
      },
    },
    offtakercontractList: {
      $each: {
        TotalContractQuantity: {
          maxLength: maxLength(15),
        },
      },
    },
    LoanDocuments: {
      $each: {
        document_id: {
          required,
        },
        LoanDocumentsDate: {
          required,
        },
        comments: {
          maxLength: maxLength(50),
        },
      },
    },
  },
  computed: {
    //this is to computed disabled property of main Submit button to save information in database.
    // isDisabledOnSubmit: function () {
    //   if (
    //     this.selectedBorrowerName == null ||
    //     this.facility_registration_id == null ||
    //     this.selectedFund == null ||
    //     this.RequestDate == null ||
    //     this.Tenure == null ||
    //     this.selectedPeriod == null ||
    //     this.selectedBuyer == null ||
    //     this.Amount == null ||
    //     this.selectedCurrencyLoan == null ||
    //     this.Supplier == null ||
    //     this.InterestChargeable == null
    //     //this.LoanDocumentSaveInfoDisabled === true
    //   ) {
    //     return true;
    //   }
    // },

    //this is to computed disabled property of LoanDocuments Save Info button.
    isDisabledLoanDocumentSaveInfo: function () {
      let checkEmptyLoanDocumentsDocName = this.LoanDocuments.filter(
        (line) => line.document_id === null
      );
      let checkEmptyLoanDocumentsDate = this.LoanDocuments.filter(
        (line) => line.LoanDocumentsDate === null
      );
      //if the line is empty then disable save info button
      if (
        this.LoanDocuments.length === 0 &&
        this.LoanDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyLoanDocumentsDocName.length >= 1 &&
          this.LoanDocuments.length > 0) ||
        (checkEmptyLoanDocumentsDate.length >= 1 &&
          this.LoanDocuments.length > 0)
      ) {
        this.LoanDocumentsDisabled = true;
      } else {
        this.LoanDocumentsDisabled = false;
      }
      //LoanDocumentsDisabled variable is to check whther all input fields except file upload are filled. LoanDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.LoanDocumentsDisabled === true
        // ||
        // this.LoanDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledLoanDocumentSaveInfo() close

    //this is to computed disabled property of OfftakerContracts Save Info button.
    isDisabledOfftakerContractSaveInfo: function () {
      let checkEmptyOfftakerContractNo = this.offtakercontractList.filter(
        (line) => line.contractNo === null
      );
      let checkEmptyTotalContractQuantity = this.offtakercontractList.filter(
        (line) => line.TotalContractQuantity === null
      );
      let checkEmptyTotalContractQuantityString = this.offtakercontractList.filter(
        (line) => line.TotalContractQuantity === ''
      );
      let checkEmptyTotalContractQuantityZero = this.offtakercontractList.filter(
        (line) => line.TotalContractQuantity == 0
      );

      let checkEmptyOLDTotalContractQuantity = this.oldOfftakercontractList.filter(
        (line) => line.TotalContractQuantity === null
      );
      let checkEmptyOLDTotalContractQuantityString = this.oldOfftakercontractList.filter(
        (line) => line.TotalContractQuantity === ''
      );
      let checkEmptyOLDTotalContractQuantityZero = this.oldOfftakercontractList.filter(
        (line) => line.TotalContractQuantity == 0
      );
      //if the line is empty then disable save info button
      if (
        this.offtakercontractList.length === 0 && this.oldOfftakercontractList.length === 0 &&
        this.offtakerContractSaveInfoDisabled === true
      ) {
        return true;
      }

      // //if the line is empty then disable save info button
      // if (
      //   this.offtakercontractList.length === 0 && 
      //   this.offtakerContractSaveInfoDisabled === true
      // ) {
      //   return true;
      // }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyOfftakerContractNo.length >= 1 &&
          this.offtakercontractList.length > 0) ||
        (checkEmptyTotalContractQuantity.length >= 1 &&
          this.offtakercontractList.length > 0) ||
        (checkEmptyTotalContractQuantityString.length >= 1 &&
          this.offtakercontractList.length > 0) ||
        (checkEmptyTotalContractQuantityZero.length >= 1 &&
          this.offtakercontractList.length > 0) ||
        (checkEmptyOLDTotalContractQuantity.length >= 1 &&
          this.oldOfftakercontractList.length > 0) ||
        (checkEmptyOLDTotalContractQuantityString.length >= 1 &&
          this.oldOfftakercontractList.length > 0) ||
        (checkEmptyOLDTotalContractQuantityZero.length >= 1 &&
          this.oldOfftakercontractList.length > 0)
      ) {
        console.log("DISABLED")
        return true;
      } else {
        console.log("NON DISABLED")
        return false;
      }
    }, //isDisabledOfftakerContractSaveInfo() close

    // //this is to computed disabled property of OfftakerContracts Save Info button.
    // isDisabledOLDOfftakerContractSaveInfo: function () {
    //   let checkEmptyQuantity = this.oldOfftakercontractList.filter(
    //     (line) => line.Quantity === null
    //   );
    //   let checkEmptyQuantityString = this.oldOfftakercontractList.filter(
    //     (line) => line.Quantity === ''
    //   );
    //   let checkEmptyQuantityZero = this.oldOfftakercontractList.filter(
    //     (line) => line.Quantity == 0
    //   );
    //   //if the line is empty then disable save info button
    //   if (
    //     this.oldOfftakercontractList.length === 0 &&
    //     this.offtakerOLDContractSaveInfoDisabled === true
    //   ) {
    //     return true;
    //   }
    //   //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
    //   if (
    //     (checkEmptyQuantity.length >= 1 &&
    //       this.oldOfftakercontractList.length > 0) ||
    //     (checkEmptyQuantityString.length >= 1 &&
    //       this.oldOfftakercontractList.length > 0) ||
    //     (checkEmptyQuantityZero.length >= 1 &&
    //       this.oldOfftakercontractList.length > 0)
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }, //isDisabledOfftakerContractSaveInfo() close

  }, //main computed close

  components: {
    Datepicker,
  },
  methods: {
    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isAlphaNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },

    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },

    insertBackcapConversionDate() {
      this.backcapConversionDate = this.RequestDate
    },

    addEditedFlag(index) {
      if (index < this.LoanDocumentsLength) {
        this.LoanDocuments[index]["edited"] = "true";
      }
      console.log(this.LoanDocuments);
    },
    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },
    //this method will make disabled property as false for Director Save Info
    onLoanDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.LoanDocumentSaveInfoDisabled = false;
      this.LoanDocumentsAddMoreDisabled = false;
      console.log(this.LoanDocuments);
    },

    //this method will make disabled property as false for Director Save Info
    onOfftakerContractSaveInfo() {
      console.log("onOfftakerContractSaveInfo")
      let flag = false;
      let partialFlag = false;

      // Check for duplicates and set the flag and error message
      this.offtakercontractList.forEach((contract, index) => {
        // const isPartialContractNoDuplicate = this.offtakercontractList.some(
        //   (c, i) => i !== index && c.partialContractNo === contract.partialContractNo && c.partialContractNo !== null
        // );

        const sameComboExistsInOldList = this.oldOfftakercontractList.some(
          (oldContract) =>
            oldContract.contractNoID === contract.contractNo &&
            oldContract.partialContractNoID === null &&
            oldContract.repaymentDoneFlag == 0
        );

        if (!contract.partialContractNo && sameComboExistsInOldList) {
          // If no partialContractNo and two contractNo are the same, delete the current entry
          flag = true;
          Utils.make_alert("warning", "This Contract already exists if your wish to add this contract then map it with partial contract.");
          this.deleteOfftakerContract(index);
          return;
        }

        const isContractNoDuplicate = this.offtakercontractList.some(
          (c, i) => i !== index && c.contractNo === contract.contractNo && c.partialContractNo === null
        );

        console.log("isContractNoDuplicate",isContractNoDuplicate)

        // if (isPartialContractNoDuplicate) {
        //   // If two partialContractNo are the same, delete the current entry
        //   flag = true;
        //   Utils.make_alert("warning", "Partial Contract already exists.");
        //   this.deleteOfftakerContract(index);
        //   return;
        // } else if (!contract.partialContractNo && isContractNoDuplicate) {
        if (!contract.partialContractNo && isContractNoDuplicate) {
          // If no partialContractNo and two contractNo are the same, delete the current entry
          flag = true;
          Utils.make_alert("warning", "This Contract already exists if your wish to add this contract then map it with partial contract.");
          this.deleteOfftakerContract(index);
          return;
        }

        if (contract.repaymentFlag) {
          // Check if other fields are not null
          if (
            contract.partialContractNo === null
          ) {
            Utils.make_alert("warning", "Please map partial contract as this contract is fully utilized except partial contracts");
            partialFlag = true;
            return;
          }
        }
      });

      // If there are no errors, proceed with saving
      if (!flag && !partialFlag) {
        Utils.make_alert("success", "Record saved.");
        this.offtakerContractSaveInfoDisabled = false;
        this.offtakerContractAddMoreDisabled = false;
      }
    },

    deleteLoanDocuments(index) {
      if (this.LoanDocuments[index].id) {
        let deleteId = this.LoanDocuments[index].id;
        let deleteDocId = this.LoanDocuments[index].document_id;
        this.DeleteDocData.push({
          id: deleteId,
          docId: deleteDocId,
          type: "Document",
          moduleID: 5,
          tabID: 16,
          clientId: this.$store.state.client_id,
          userId: this.$store.getters.getuserId,
        });
      }
      this.LoanDocumentFile = this.LoanDocumentFile
        ? this.LoanDocumentFile
        : "file deleted";
      this.LoanDocuments.splice(index, 1);
      if (
        typeof this.LoanDocuments !== "undefined" &&
        this.LoanDocuments.length == 0
      ) {
        this.LoanDocumentsAddMoreDisabled = false;
        this.LoanDocumentSaveInfoDisabled = true;
      }
    },

    deleteOldOfftakerContract(index) {
      this.$confirm({
        auth: false,
        message: "Confirm Delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            const path = "/edit_loan/" + this.loan_id;
            axios
              .delete(path, {
                headers: {
                  "Content-type": "application/json",
                },
                data: {
                  type: "loanContract",
                  loanContractId: this.oldOfftakercontractList[index].id,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 5,
                  tabId: 16,
                },
              })
              .then((response) => {
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Request deleted successfully!"
                  );
                  //if you want to send any data into server before redirection then you can do it here
                  // this.$router.go(this.$router.currentRoute);
                  this.oldOfftakercontractList.splice(index, 1);
                  if (
                    typeof this.offtakercontractList !== "undefined" &&
                    this.offtakercontractList.length == 0 &&
                    typeof this.oldOfftakercontractList !== "undefined" &&
                    this.oldOfftakercontractList.length == 0
                  ) {
                    console.log("offtakercontractList is empty")
                    this.offtakerContractAddMoreDisabled = false;
                    this.offtakerContractSaveInfoDisabled = true;
                  }
                } else {
                  Utils.make_alert(
                    "success",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                console.log("error: " + error);
                Utils.make_alert("success", "Something went wrong!");
              });
          }
        },
      });
    },

    deleteOfftakerContract(index) {
      this.offtakercontractList.splice(index, 1);
      if (
        typeof this.offtakercontractList !== "undefined" &&
        this.offtakercontractList.length == 0
      ) {
        this.offtakerContractAddMoreDisabled = false;
        this.offtakerContractSaveInfoDisabled = true;
      }
    },

    onChangeInterestRateType(){
      const selectedType = this.InterestRateType.find(type => type.id === this.selectedInterestRateType);
      if (selectedType) {
        this.simpleInterestFlag = selectedType.simple_interest_flag;
        this.compoundInterestFlag = selectedType.compound_interest_flag;
      } else {
        this.simpleInterestFlag = 0
        this.compoundInterestFlag = 0
      }
      if (this.selectedInterestRateType == 1 || this.selectedInterestRateType == 2) {
        this.Spread = null;
        this.selectedBenchmark = null;
      } else if (this.selectedInterestRateType == 3 || this.selectedInterestRateType == 4) {
        this.InterestRate = null;
      } else {
        this.Spread = null;
        this.selectedBenchmark = null;
        this.InterestRate = null;
      }
    },

    // onChangeOLDQuantityValidation(index){
    //   console.log("onChangeOLDQuantityValidation")
    //   let selectedQuantity = this.oldOfftakercontractList[index].Quantity;
    //   let selectedContractQuantity = this.oldOfftakercontractList[index].ContractQuantity;
    //   let selectedPartialContractQuantity = this.oldOfftakercontractList[index].PartialContractQuantity;
    //   let contractNoID = this.oldOfftakercontractList[index].contractNo;
    //   let partialContractNoID = this.oldOfftakercontractList[index].partialContractNo;
    //   console.log(contractNoID)
    //   console.log(partialContractNoID)
    //   console.log(typeof partialContractNoID)
    //   if(selectedQuantity === '' || selectedQuantity === 0 ) {
    //     this.offtakerContractAddMoreDisabled = true;
    //     return;
    //   }
    //   if(partialContractNoID && contractNoID){
    //     if(Number(selectedQuantity) > Number(selectedPartialContractQuantity)){
    //       this.oldOfftakercontractList[index].QuantityError = true;
    //       this.oldOfftakercontractList[index].QuantityErrorMessage = `Quantity cannot be greater than Partial Contract Quantity ${selectedPartialContractQuantity}`;
    //       this.offtakerContractAddMoreDisabled = true;
    //       return;
    //     } else {
    //       this.oldOfftakercontractList[index].QuantityError = false;
    //       this.oldOfftakercontractList[index].QuantityErrorMessage = "";
    //       // this.offtakerOLDContractSaveInfoDisabled = false;
    //       return;
    //     }
    //   } else if(!partialContractNoID && contractNoID){
    //     console.log("hello")
    //     if(Number(selectedQuantity) > Number(selectedContractQuantity)){
    //       console.log("hello 1")
    //       this.oldOfftakercontractList[index].QuantityError = true;
    //       this.oldOfftakercontractList[index].QuantityErrorMessage = `Quantity cannot be greater than contract quantity ${selectedContractQuantity}`;
    //       this.offtakerContractAddMoreDisabled = true;
    //       return;
    //     } else {
    //       console.log("hello 2")
    //       this.oldOfftakercontractList[index].QuantityError = false;
    //       this.oldOfftakercontractList[index].QuantityErrorMessage = "";
    //       // this.offtakerOLDContractSaveInfoDisabled = false;
    //       return;
    //     }
    //   } else {
    //     console.log("hello 3")
    //     this.oldOfftakercontractList[index].QuantityError = false;
    //     this.oldOfftakercontractList[index].QuantityErrorMessage = "";
    //     // this.offtakerOLDContractSaveInfoDisabled = false;
    //     return;
    //   }

    // },

    // onChangeQuantityValidation(index){
    //   console.log("onChangeQuantityValidation")
    //   let selectedQuantity = this.offtakercontractList[index].Quantity;
    //   let selectedContractQuantity = this.offtakercontractList[index].ContractQuantity;
    //   let selectedPartialContractQuantity = this.offtakercontractList[index].PartialContractQuantity;
    //   let contractNoID = this.offtakercontractList[index].contractNo;
    //   let partialContractNoID = this.offtakercontractList[index].partialContractNo;
    //   console.log(contractNoID)
    //   console.log(partialContractNoID)
    //   console.log(typeof partialContractNoID)
    //   if(partialContractNoID && contractNoID){
    //     if(Number(selectedQuantity) > Number(selectedPartialContractQuantity)){
    //       this.offtakercontractList[index].QuantityError = true;
    //       this.offtakercontractList[index].QuantityErrorMessage = `Quantity cannot be greater than Partial Contract Quantity ${selectedPartialContractQuantity}`;
    //       return;
    //     } else {
    //       this.offtakercontractList[index].QuantityError = false;
    //       this.offtakercontractList[index].QuantityErrorMessage = "";
    //       return;
    //     }
    //   } else if(!partialContractNoID && contractNoID){
    //     console.log("hello")
    //     if(Number(selectedQuantity) > Number(selectedContractQuantity)){
    //       console.log("hello 1")
    //       this.offtakercontractList[index].QuantityError = true;
    //       this.offtakercontractList[index].QuantityErrorMessage = `Quantity cannot be greater than contract quantity ${selectedContractQuantity}`;
    //       return;
    //     } else {
    //       console.log("hello 2")
    //       this.offtakercontractList[index].QuantityError = false;
    //       this.offtakercontractList[index].QuantityErrorMessage = "";
    //       return;
    //     }
    //   } else {
    //     console.log("hello 3")
    //     this.offtakercontractList[index].QuantityError = false;
    //     this.offtakercontractList[index].QuantityErrorMessage = "";
    //     return;
    //   }

    // },

    //this function is called to get list of contract numbers
    getContractNoByOfftakerName(index,offtakerNameID) {
      console.log("inside get getContractNoByOfftakerName")

      if (offtakerNameID === null) {
        this.offtakercontractList[index].contractNo = null;
        this.offtakercontractList[index].contractNumber = [];
        this.offtakercontractList[index].partialContractNo = null;
        this.offtakercontractList[index].partialContractNumber = [];
        this.offtakercontractList[index].ContractDate = null;
        this.offtakercontractList[index].PartialContractDate = null;
        this.offtakercontractList[index].ContractQuantity = null;
        this.offtakercontractList[index].PartialContractQuantity = null;
        this.offtakercontractList[index].Quantity = null;
        this.offtakercontractList[index].selectedUnit = null;
        this.offtakercontractList[index].selectedCollateral = null;
        this.offtakercontractList[index].TotalContractQuantity = null;
        this.offtakercontractList[index].repaymentFlag = '0';
        this.offtakercontractList[index].requiredFlag = false;
        return
      }

      this.offtakercontractList[index].contractNo = null;
      this.offtakercontractList[index].partialContractNo = null;
      this.offtakercontractList[index].partialContractNumber = [];
      this.offtakercontractList[index].ContractDate = null;
      this.offtakercontractList[index].PartialContractDate = null;
      this.offtakercontractList[index].ContractQuantity = null;
      this.offtakercontractList[index].PartialContractQuantity = null;
      this.offtakercontractList[index].Quantity = null;
      this.offtakercontractList[index].selectedUnit = null;
      this.offtakercontractList[index].selectedCollateral = null;
      this.offtakercontractList[index].TotalContractQuantity = null;
      this.offtakercontractList[index].repaymentFlag = '0';
      this.offtakercontractList[index].requiredFlag = true;

      Utils.start_loading();
      // const path = "/get-contract-no-by-offtaker/" + offtakerNameID + "/" + this.borrower_id;
      const path = 
        "/get-contract-no-by-offtaker" + 
        "/" + 
        offtakerNameID +
        "/" + 
        this.borrower_id +
        "/" +
        null +
        "/" +
        this.contractType
      axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
      .then(res => {
          Utils.stop_loading();
          console.log("status",res.data.status)
          if(res.data.status == "success"){
            this.offtakercontractList[index].contractNo = null;
            this.offtakercontractList[index].contractNumber = res.data.contract_numbers;
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          Utils.stop_loading();
          console.error(error);
        });
    },

     //this function is called to get list of contract numbers
    getContractNoDetails(index,contractNoID) {
      console.log("inside get getContractNoDetails")
      
      console.log("selectedContract",contractNoID)
      if (contractNoID === null) {
        this.offtakercontractList[index].contractNo = null;
        this.offtakercontractList[index].partialContractNo = null;
        this.offtakercontractList[index].partialContractNumber = [];
        this.offtakercontractList[index].ContractDate = null;
        this.offtakercontractList[index].PartialContractDate = null;
        this.offtakercontractList[index].ContractQuantity = null;
        this.offtakercontractList[index].PartialContractQuantity = null;
        this.offtakercontractList[index].Quantity = null;
        this.offtakercontractList[index].selectedUnit = null;
        this.offtakercontractList[index].selectedCollateral = null;
        this.offtakercontractList[index].TotalContractQuantity = null;
        this.offtakercontractList[index].requiredFlag = false;
        return
      }

      let selectedContract = this.offtakercontractList[index].contractNumber.find(contract => contract.contractId === contractNoID);

      console.log("selectedContract",selectedContract)
      
      this.offtakercontractList[index].partialContractNo = null;
      this.offtakercontractList[index].partialContractNumber = [];
      this.offtakercontractList[index].ContractDate = selectedContract.ContractDate;
      this.offtakercontractList[index].PartialContractDate = null;
      this.offtakercontractList[index].ContractQuantity = selectedContract.ContractQuantity;
      this.offtakercontractList[index].Quantity = selectedContract.ContractQuantity;
      this.offtakercontractList[index].selectedUnit = selectedContract.unit;
      this.offtakercontractList[index].selectedCollateral = selectedContract.collateral_id;
      this.offtakercontractList[index].TotalContractQuantity = selectedContract.TotalContractQuantity;
      this.offtakercontractList[index].repaymentFlag = selectedContract.repayment_flag;
      if(this.offtakercontractList[index].Quantity == 0){
        this.offtakercontractList[index].repaymentFlag = '1';
      }


      Utils.start_loading();
      // const path = "/get-partial-contract-no/" + contractNoID;
      const path = 
        "/get-partial-contract-no" + 
        "/" + 
        contractNoID +
        "/" +
        null +
        "/" +
        this.contractType
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          Utils.stop_loading();
          console.log("status",res.data.status)
          if(res.data.status == "success") {
            this.offtakercontractList[index].partialContractNo = null;
            this.offtakercontractList[index].partialContractNumber = res.data.partial_contracts;
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          console.error(error);
        });
    },

    //this function is called to get list of contract numbers
    getPartialContractNoDetails(index,contractNoID,partialContractNoID) {
      console.log("inside get getPartialContractNoDetails");
      // Find the contract with the given partialContractNoID
      const targetContract = this.oldOfftakercontractList.find(
        (contract) => contract.partialContractNoID === partialContractNoID
      );

      // Check if the target contract exists and has repaymentDoneFlag equal to 0
      if (targetContract && targetContract.repaymentDoneFlag == 0) {
        Utils.make_alert("warning", "Partial Contract already exists.");
        this.deleteOfftakerContract(index);
        return;
      }

      let partial_contract_count = this.offtakercontractList
        .filter(col => col.partialContractNo !== null)
        .filter(col => col.partialContractNo === this.offtakercontractList[index].partialContractNo)
        .length;
        // delete if existing loan is found
        if (partial_contract_count > 1) {
          Utils.make_alert("warning", "Partial Contract already exists.");
          this.deleteOfftakerContract(index);
          return
        }
      
        if (partialContractNoID === null) {
        let selectedContract = this.offtakercontractList[index].contractNumber.find(contract => contract.contractId === contractNoID);
        this.offtakercontractList[index].partialContractNo = null;
        this.offtakercontractList[index].ContractDate = selectedContract.ContractDate;
        this.offtakercontractList[index].PartialContractDate = null;
        this.offtakercontractList[index].ContractQuantity = selectedContract.ContractQuantity;
        this.offtakercontractList[index].Quantity = selectedContract.ContractQuantity;
        this.offtakercontractList[index].selectedUnit = selectedContract.unit;
        this.offtakercontractList[index].selectedCollateral = selectedContract.collateral_id;
        this.offtakercontractList[index].TotalContractQuantity = selectedContract.TotalContractQuantity;
        this.offtakercontractList[index].repaymentFlag = selectedContract.repayment_flag;
        return;
      }

      let selectedPartialContract = this.offtakercontractList[index].partialContractNumber.find(partialContract => partialContract.partialContractId === partialContractNoID);

      this.offtakercontractList[index].PartialContractDate = selectedPartialContract.PartialContractDate;
      this.offtakercontractList[index].PartialContractQuantity = selectedPartialContract.PartialContractQuantity;
      this.offtakercontractList[index].Quantity = selectedPartialContract.PartialContractQuantity;

    },

    calculateFinalLoanAmount() {
      if (this.requestedAmount && this.conversionRate) {
          let final_requestedAmount = Number(this.requestedAmount) * Number(this.conversionRate);
          this.finalLoanRequestedAmount = final_requestedAmount.toFixed(2);
          this.finalLoanRequestedAmount = Number(this.finalLoanRequestedAmount) == 0 ? null : this.finalLoanRequestedAmount;
        } else {
        this.finalLoanRequestedAmount = null;
      }
    },

    getLoanCurrencyExchangeRateByCurrencyDate() {
      //this function is called to get fund currency of that facility
      console.log("inside getLoanCurrencyExchangeRateByCurrencyDate")
      if(this.selectedFundCurrency && this.selectedLoanCurrency && this.conversionDate){
        if (this.selectedFundCurrency == this.selectedLoanCurrency) {
          this.conversionRate = 1;
          this.calculateFinalLoanAmount();
          return;
        }
        let currencyDate = null;
        if (this.conversionDate != null) {
          currencyDate = moment(this.conversionDate).format("MM-DD-YYYY");
        }
        const path = "/currency-exchange-rate/" +
          this.selectedFundCurrency +
          "/" +
          this.selectedLoanCurrency +
          "/" +
          currencyDate;
        Utils.start_loading();
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            console.log(res.data)
            let data = res.data.currency_rate[0]
            if(data != null){
              // this.currencyConversionFlag = true
              this.conversionRate =  data.currency_value;
              this.conversionDate =  data.date;
              this.calculateFinalLoanAmount();
            }else{
              this.selectedLoanCurrency = this.selectedFundCurrency;
              this.conversionRate = 1;
              this.calculateFinalLoanAmount();
              // this.conversionDate = null;
            }
            Utils.stop_loading();
          })
          .catch((error) => {
            console.error(error);
            Utils.stop_loading();
          });
      }
    },

    getLoan() {
      //this function is called on page load and created events. it will show details on borrower with borrowerid equal to this.id
      Utils.start_loading();
      const path = "/edit_loan/" + this.loan_id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          this.InterestDrawdown = res.data.loan.interest_on_drawdown;
          this.InterestUtilisationRequest = res.data.loan.interest_on_request;
          this.InterestUtilisationRequestDrawdownAmount = res.data.loan.drawdown_on_request_flag;
          if (this.InterestDrawdown == 1) {
            this.InterestChargeable = "InterestDrawdown";
          } else if (this.InterestUtilisationRequest == 1){
            this.InterestChargeable = "InterestUtilisationRequest";
          } else if (this.InterestUtilisationRequestDrawdownAmount == 1){
            this.InterestChargeable = "InterestUtilisationRequestDrawdownAmount";
          }
          this.borrower_id = res.data.loan.borrower_id;
          this.getAllFacilitySetupByBorrower();
          this.getOfftakerNamesByBorrower();
          this.selectedBorrowerName = res.data.loan.borrower_name;
          this.selectedFund = res.data.loan.fund_id;
          this.facility_registration_id = res.data.loan.facility_id;
          this.onFacilitySelect();
          this.RequestDate = res.data.loan.loan_registration_date;
          this.Amount = res.data.loan.requested_amount;
          this.selectedInterestRateType = res.data.loan.loan_interest_type_master_id;
          this.simpleInterestFlag = res.data.loan.simple_interest;
          this.compoundInterestFlag = res.data.loan.compound_interest;
          this.InterestRate = res.data.loan.interest_rate;
          this.Spread = res.data.loan.interest_spread;
          this.selectedBenchmark = res.data.loan.benchmark_id;
          this.Tenure = res.data.loan.loan_tenure;
          this.selectedPeriod = res.data.loan.period;
          this.selectedDayCount = res.data.loan.day_count_id;
          this.selectedLoanCurrency = res.data.loan.currency_id;
          this.selectedFundCurrency = res.data.loan.fund_currency_id;
          this.conversionDate = res.data.loan.conversion_date;
          this.conversionRate = res.data.loan.conversion_rate;
          this.finalLoanRequestedAmount = res.data.loan.loan_converted_amount;
          this.Supplier = res.data.loan.supplier_name;
          this.comments = res.data.loan.comments;

          // this.oldOfftakercontractList = res.data.loan.offtakercontractList;

          
          if(res.data.loan.offtakercontractList.length > 0){
            // Transform the API data into the desired format
            let oldOfftakercontractListStatic = res.data.loan.offtakercontractList.map((apiItem, index) => {
              // const partialContractNo = apiItem.offtaker_contract_partial_fill_id !== null ? `PC ${apiItem.offtaker_contract_partial_fill_id}` : null;
              return {
                id: apiItem.loan_contract_mapping_id,
                offtakerName: apiItem.offtaker_name, // Extract first name
                offtakerNameID: apiItem.offtaker_id,
                requiredFlag: true,
                contractNo: apiItem.contractNo,
                contractNoID: apiItem.offtaker_contract_id,
                partialContractNo:apiItem.partialContractNo,
                partialContractNoID: apiItem.offtaker_contract_partial_fill_id,
                ContractDate: apiItem.contract_date,
                PartialContractDate: apiItem.Partial_contract_date,
                ContractQuantity: apiItem.ContractQuantity,
                PartialContractQuantity: apiItem.PartialContractQuantity,
                Quantity: apiItem.oustanding_contract_qty,
                QuantityStatic: apiItem.oustanding_contract_qty,
                TotalContractQuantity: apiItem.loan_contract_qty,
                QuantityError: false,
                repaymentDoneFlag: apiItem.loan_contract_flag,
                selectedUnit: apiItem.unit_name,
                selectedCollateral: apiItem.collateral_name,
              };
            });

            console.log("oldOfftakercontractListStatic",oldOfftakercontractListStatic)

            this.oldOfftakercontractList = oldOfftakercontractListStatic
          } else {
            this.oldOfftakercontractList = []
          }
          this.offtakerOLDContractSaveInfoDisabled = true;
          this.offtakerContractAddMoreDisabled = false;

          this.LoanDocuments = res.data.loan.LoanDocuments;
          this.LoanDocumentsLength = res.data.loan.LoanDocuments.length;
          if (this.LoanDocumentsLength) {
            this.LoanDocuments.filter((loan) => {
              loan["edited"] = "false";
            });
          }
          if (res.data.status == "success") {
            console.log("get facility details is successful.");
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getBorrower() close

    //this method will submit the investor form in backend.
    onSubmit() {
      let flag = false;
      let oldFlag = false;
      let partialFlag = false;
      this.isSubmitted = true;

      // // Check for duplicates and set the flag and error message
      // this.oldOfftakercontractList.forEach((contract, index) => {
      //   // Add a condition to check if requiredFlag is true
      //   if (contract.requiredFlag) {
      //     // Check if other fields are not null
      //     if (
      //       contract.Quantity === null ||  // Check for null
      //       contract.Quantity === "" ||    // Check for an empty string
      //       contract.Quantity === 0    // Check for 0
      //     ) {
      //       oldFlag = true;
      //     }
      //   }
      // });

      //   console.log("old flag onsubmit: " + oldFlag);

      // Check for duplicates and set the flag and error message
      this.offtakercontractList.forEach((contract, index) => {
        // Add a condition to check if requiredFlag is true
        if (contract.requiredFlag) {
          // Check if other fields are not null
          if (
            contract.contractNo === null
          ) {
            flag = true;
          }
        }
        
        const sameComboExistsInOldList = this.oldOfftakercontractList.some(
          (oldContract) =>
            oldContract.contractNoID === contract.contractNo &&
            oldContract.partialContractNoID === null &&
            oldContract.repaymentDoneFlag == 0
        );

        if (!contract.partialContractNo && sameComboExistsInOldList) {
          // If no partialContractNo and two contractNo are the same, delete the current entry
          flag = true;
          Utils.make_alert("warning", "This Contract already exists if your wish to add this contract then map it with partial contract.");
          this.deleteOfftakerContract(index);
          return;
        }

        const isContractNoDuplicate = this.offtakercontractList.some(
          (c, i) => i !== index && c.contractNo === contract.contractNo && c.partialContractNo === null
        );
        if (!contract.partialContractNo && isContractNoDuplicate) {
          // If no partialContractNo and two contractNo are the same, delete the current entry
          flag = true;
          Utils.make_alert("warning", "This Contract already exists if your wish to add this contract then map it with partial contract.");
          this.deleteOfftakerContract(index);
          return;
        }

        if (contract.repaymentFlag) {
          // Check if other fields are not null
          if (
            contract.partialContractNo === null
          ) {
            Utils.make_alert("warning", "Please map partial contract as this contract is fully utilized except partial contracts");
            partialFlag = true;
            return;
          }
        }
      });

      console.log("flag onsubmit: " + flag);
      console.log("partialFlag onsubmit: " + partialFlag);

      //validation for Documents display file name
      let err = false;
      this.LoanDocuments.filter((doc) => {
        if (doc.display_file_name === null) {
          err = true;
        }
      });
      console.log("err onsubmit: " + err);
      this.$v.$touch();
      if (this.$v.$invalid || err || flag || partialFlag) {
        return;
      }
      console.log("partialFlag err final onsubmit: " + partialFlag,flag,err);

      // this.finalOldOfftakercontractList = this.oldOfftakercontractList.filter((contract) => {
      //   // Convert Quantity and QuantityStatic to numbers before comparison
      //   const quantity = parseFloat(contract.Quantity);
      //   const quantityStatic = parseFloat(contract.QuantityStatic);

      //   // Compare Quantity and QuantityStatic as numbers
      //   return quantity !== quantityStatic;
      // });

      if (this.RequestDate != null) {
        this.RequestDate1 = moment(this.RequestDate).format("MM-DD-YYYY");
      }
      // if (this.ModificationDate != null) {
      //   this.ModificationDate1 = moment(this.ModificationDate).format(
      //     "MM-DD-YYYY"
      //   );
      // }
      let conversionDate = null;
      if (this.conversionDate != null) {
        conversionDate = moment(this.conversionDate).format("MM-DD-YYYY");
      }
      if (this.InterestChargeable === "InterestDrawdown") {
        this.InterestDrawdown = true;
        this.InterestUtilisationRequest = false;
        this.InterestUtilisationRequestDrawdownAmount = false;
      } else if (this.InterestChargeable === "InterestUtilisationRequest") {
        this.InterestDrawdown = false;
        this.InterestUtilisationRequest = true;
        this.InterestUtilisationRequestDrawdownAmount = false;
      } else if (this.InterestChargeable === "InterestUtilisationRequestDrawdownAmount") {
        this.InterestDrawdown = false;
        this.InterestUtilisationRequest = false;
        this.InterestUtilisationRequestDrawdownAmount = true;
      }
      console.log(this.InterestDrawdown,this.InterestUtilisationRequest,this.InterestUtilisationRequestDrawdownAmount)
      for (let i = 0; i < this.LoanDocuments.length; i++) {
        if (this.LoanDocuments[i].LoanDocumentsDate !== null) {
          this.LoanDocuments[i].LoanDocumentsDate = moment(
            this.LoanDocuments[i].LoanDocumentsDate
          ).format("MM-DD-YYYY");
        }
        if (this.LoanDocuments[i].ExpiryDate !== null) {
          this.LoanDocuments[i].ExpiryDate = moment(
            this.LoanDocuments[i].ExpiryDate
          ).format("MM-DD-YYYY");
        }
      }
      this.offtakercontractList.map((doc, index) => {
        if (doc.offtakerNameID === null) this.offtakercontractList.splice(index, 1);
      });
      for (let i = 0; i < this.offtakercontractList.length; i++) {
        if (this.offtakercontractList[i].ContractDate !== null) {
          this.offtakercontractList[i].ContractDate = moment(
            this.offtakercontractList[i].ContractDate
          ).format("MM-DD-YYYY");
        }
        if (this.offtakercontractList[i].PartialContractDate !== null) {
          this.offtakercontractList[i].PartialContractDate = moment(
            this.offtakercontractList[i].PartialContractDate
          ).format("MM-DD-YYYY");
        }
      }

      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("borrowerName", this.selectedBorrowerName);
      formData.append("fundName", this.selectedFund);
      formData.append(
        "facility_registration_id",
        this.facility_registration_id
      );
      formData.append("RequestDate", this.RequestDate1);
      formData.append("Amount", this.Amount);
      formData.append("selectedInterestRateType", this.selectedInterestRateType);
      formData.append("simpleInterestFlag", this.simpleInterestFlag);
      formData.append("compoundInterestFlag", this.compoundInterestFlag);
      formData.append("InterestRate", this.InterestRate);
      formData.append("Spread", this.Spread);
      formData.append("selectedBenchmark", this.selectedBenchmark);
      formData.append("tenure", this.Tenure);
      formData.append("period", this.selectedPeriod);
      formData.append("selectedDayCount", this.selectedDayCount);
      formData.append("selectedCurrency", this.selectedLoanCurrency);
      formData.append("conversionRate", this.conversionRate);
      formData.append("conversionDate", conversionDate);
      formData.append("finalLoanRequestedAmount", this.finalLoanRequestedAmount);
      formData.append("Supplier", this.Supplier);
      formData.append("Comments", this.comments);
      // formData.append("ModificationDate", this.ModificationDate1);
      //append covenant information to formdata
      // formData.append(
      //   "oldOfftakercontractList",
      //   JSON.stringify(this.finalOldOfftakercontractList)
      // );
      formData.append(
        "offtakercontractList",
        JSON.stringify(this.offtakercontractList)
      );
      formData.append("InterestDrawdown", this.InterestDrawdown);
      formData.append(
        "InterestUtilisationRequest",
        this.InterestUtilisationRequest
      );
      formData.append(
        "InterestUtilisationRequestDrawdownAmount",
        this.InterestUtilisationRequestDrawdownAmount
      );
      //append LoanDocuments information to formdata
      formData.append("loanDocumentList", JSON.stringify(this.LoanDocuments));
      formData.append("DeleteDocData", JSON.stringify(this.DeleteDocData));
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 5);
      formData.append("tabId", 16);
      const path = "/edit_loan/" + this.loan_id;
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          console.log("status: " + response.data.status);
          if (response.data.status == "success") {
            Utils.make_alert(
              "success",
              "Utilisation Request updated successfully!"
            );
            console.log("SUCCESS!!");
            this.$router.go(-1);
          } else {
            Utils.make_alert("danger", "Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
          Utils.make_alert("danger", "Something went wrong!");
        });
    }, //onSubmit() close

    //this method will add new line for additional covenants
    addOfftakerContract() {
      //when new line is added then offtakerContractSaveInfoDisabled (Save info button disable property) should be re initialised to true.
      (this.offtakerContractSaveInfoDisabled = true),
        (this.offtakerContractAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.offtakercontractList.push({
          id: null,
          offtakerNameID: null,    // id
          requiredFlag: false,
          contractNumber: [],
          partialContractNumber: [],
          contractNo: null,        // id
          partialContractNo: null, // id
          ContractDate: null,
          PartialContractDate: null,
          ContractQuantity: null,
          PartialContractQuantity: null,
          TotalContractQuantity: null,
          Quantity: null,
          selectedUnit: null,     // id
          selectedCollateral: null,     // id
          repaymentFlag: '0',
        });
    }, //addOfftakerContract() close

    //this method will add new line for additional Loan Documents
    addLoanDoc() {
      //when new line is added then LoanDocumentFile variable and LoanDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.LoanDocumentFile = null),
        (this.LoanDocumentSaveInfoDisabled = true),
        (this.LoanDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.LoanDocuments.push({
          id: null,
          document_id: null,
          LoanDocumentsDate: null,
          ExpiryDate: null,
          comments: null,
          display_file_name: null,
          edited: "false",
          errors: "",
          LoanDocumentRemoveDisabled: true,
        });
    }, //addLoanDoc() close

    //handles a change on the file upload
    handleFileUploadLoanDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.LoanDocumentFile = e.target.files[0];
        this.LoanDocumentFileName = e.target.files[0].name;

        //check for filesize. if it is larger than threshold then return following error response
        if (this.LoanDocumentFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors = "File size should be less than 100MB";
          self.LoanDocuments[id].errors = JSON.stringify(
            response.errors
          ).replace(/\"/g, "");
          self.LoanDocuments[id].selectedFile = null;
          self.LoanDocuments[id].selectedFileName = null;
          self.LoanDocuments[id].display_file_name = null;
          self.LoanDocuments[id].LoanDocumentRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.LoanDocumentFile);
        reader.onload = function () {
          self.LoanDocuments[id].selectedFile = reader.result;
          self.LoanDocuments[id].selectedFileName = e.target.files[0].name;
          self.LoanDocuments[id].display_file_name = e.target.files[0].name; //to show filename in file input
          self.LoanDocuments[id].errors = "";
          self.LoanDocuments[id].LoanDocumentRemoveDisabled = false;
          //self.LoanDocuments[id].LoanDocumentsDate = moment(self.LoanDocuments[id].LoanDocumentsDate).format("MM-DD-YYYY");
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadLoanDocuments() close

    ////this method handles remove doc attachment
    removeLoanDocumentsDoc(id) {
      this.LoanDocumentFile = null;
      this.LoanDocuments[id].selectedFile = null;
      this.LoanDocuments[id].selectedFileName = null;
      this.LoanDocuments[id].display_file_name = null;
      this.LoanDocuments[id].LoanDocumentRemoveDisabled = true;
    },

    //this function is called to get list of documents master
    getLoanDocuments() {
      const path = "loan-document";
      axios
        .get(path)
        .then((res) => {
          this.LoanDocs = res.data.documents;
          console.log(this.LoanDocs);
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getLoanDocumentsDocuments() close

    getDayCount() {
      //this function is called to get list of day count
      const path = "day-count-master";
      axios
        .get(path)
        .then((res) => {
          this.dayCounts = res.data.day_counts;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    onFacilitySelect() {
      const path = "get-facility-fund" + "/" + this.facility_registration_id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.facCollateralType = res.data.facility_fund.facility_with;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getAllFacilitySetupByBorrower() {
      //this function is called to get list of day count
      const path = "/all-facility-setup-borrower/" + this.borrower_id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.FacilitySetup = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBenchmarks() {
      //this function is called to get list of benchmarks
      const path = "benchmark";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.benchmarks = res.data.benchmarks;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getUnitTypes() {
      //this function is called to get list of benchmarks
      const path = "unit_types";
      axios
        .get(path)
        .then((res) => {
          this.UnitTypes = res.data.UnitTypes;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLoanType() {
      //this function is called to get list of funding
      const path = "loan-type";
      axios
        .get(path)
        .then((res) => {
          this.LoanType = res.data.loan_type;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCollateral() {
      //this function is called to get list of Collateral
      const path = "collateral";
      axios
        .get(path)
        .then((res) => {
          this.collaterals = res.data.collateral;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBorrowers() {
      const path = "borrower-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.borrowers = res.data.borrowers;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLoanInterestRateTypes() {
      const path = "get-loan-interest-type";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.InterestRateType = res.data.loan_interest_types;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getOfftakerNamesByBorrower() {
      // const path = "/get-offtaker-name-by-borrower/" + this.borrower_id;
      const path = 
      "/get-offtaker-name-by-borrower" + 
      "/" + 
      this.borrower_id +
      "/" +
      null +
      "/" +
      'Offtaker';
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.offtakerNameIDs = res.data.offtaker_names;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFunds() {
      const path = "fund-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.Funds = res.data.funds;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getInterestTypes() {
      //this function is called to get list of interest types
      const path = "interest-type";
      axios
        .get(path)
        .then((res) => {
          this.interestTypes = res.data.interest_types;
        })
        .catch((error) => {
          console.error(error);
        });
    },


    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
          this.currencies_loan = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close

  mounted() {
    this.getLoan();
    // var numberOfDecimals = 2;
    // $(document).on("input", ".allow-only-numbers", function () {
    //   var regExp = new RegExp("(\\.[\\d]{" + numberOfDecimals + "}).", "g");
    //   this.value = this.value
    //     .replace(/[^0-9.]/g, "")
    //     .replace(/(\..*)\./g, "$1")
    //     .replace(regExp, "$1");
    // });
    //this.addLoanDoc();
    //this.addOfftakerContract();
  },

  created() {
    this.loan_id = this.$route.params.id;
    this.type = this.$route.params.type;
    if (this.$route.params.repaid_amount > 0) {
      this.amountDisable = true;
    }
    this.getUnitTypes();
    this.getLoanDocuments();
    this.getBenchmarks();
    this.getLoanType();
    this.getCurrencies();
    this.getBorrowers();
    this.getLoanInterestRateTypes();
    this.getFunds();
    this.getInterestTypes();
    this.getDayCount();
    this.getCollateral();
  },
};
</script>

<style scoped>

input[type="radio"] {
  margin-right: 5px;
}

.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
.vdp-datepicker >>> .form-control[disabled] {
  background-color:#EEEEEE !important;
  opacity: 0.7;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.error {
  color: red;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>
