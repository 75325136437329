<template>
  <div class="container-fluid h-100">
    <div class="row justify-content-center h-100">
      <div class="col-lg-5 col-md-5" id="side_view">
        <div
          class="blue_portion"
          style="background-image: url('/asset/images/sidemenu_bg.png')"
        >
          <div class="tx">
            <h3>LUNDY INVESTORS</h3>
            <h4>Tailored Solution for Wealth Creation</h4>
          </div>
        </div>
      </div>

      <div class="col-lg-7 col-md-7" id="grey">
        <div class="login_mid-Portion">
          <center>
            <img
              src="/asset/images/AdminLTELogo.png"
              class="img-fluid login_logo"
            />
          </center>
          <form @submit.prevent="handleSubmit()">
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                v-model="password"
                id="password"
                name="password"
                :class="{ 'is-invalid': isSubmitted && $v.password.$error }"
                placeholder="Password"
              />

              <div class="toooltip" style="float: right">
                Password Guidelines
                <span class="tooltiptext">
                  Minimum 6 Characters <br />
                  Maximum 20 Characters <br />
                  At least 1 Number <br />
                  At least 1 Uppercase <br />
                  At least 1 Lowercase <br />
                  At least 1 Special Character <br />
                </span>
              </div>

              <div
                v-if="isSubmitted && $v.password.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.password.required"
                  >Password field is required</span
                >
                <span v-if="$v.password.required && !$v.password.minLength"
                  >Password should be at least 6 characters long</span
                >
                <span v-if="$v.password.required && !$v.password.maxLength"
                  >Password should not be more than 20 characters long</span
                >
                <span
                  v-if="
                    $v.password.required &&
                      $v.password.minLength &&
                      $v.password.maxLength &&
                      !$v.password.valid
                  "
                  >Password must have atleast One Uppercase, One Lowercase, One
                  Number and One Special Character</span
                >
              </div>
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                v-model="password_confirm"
                id="password_confirm"
                name="password_confirm"
                :class="{
                  'is-invalid': isSubmitted && $v.password_confirm.$error,
                }"
                placeholder="Confirm Password"
              />
              <div
                v-if="isSubmitted && $v.password_confirm.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.password_confirm.required"
                  >Confirm Password field is required
                </span>
                <span
                  v-if="
                    !$v.password_confirm.sameAsPassword &&
                      $v.password_confirm.required
                  "
                  >Passwords should be matched</span
                >
              </div>
            </div>

            <!-- <div class="custom-control custom-checkbox mb-3">
              <input type="checkbox" class="custom-control-input" id="customCheck" name="example1" />
              <label class="custom-control-label" for="customCheck">Remember me!</label>
            </div>-->

            <!-- <router-link
              to="/login"
              type="button"
              class="btn btn-block btn-primary btn-lg sh lg_btn"
            >Change Password</router-link>-->

            <a
              type="button"
              class="btn btn-block btn-primary btn-lg sh lg_btn"
              style="color: white"
              v-on:keyup.enter="handleSubmit"
              @click="handleSubmit"
            >
              Change Password
            </a>
          </form>
        </div>

        <p class="bt_text">
          © 2022, Design & Development by
          <a href="https://www.decimalpointanalytics.com/"
            >Decimal Point Analytics Pvt Ltd.</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  required,
  minLength,
  sameAs,
  maxLength,
} from "vuelidate/lib/validators";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
export default {
  name: "ChangePassword",
  data() {
    return {
      password: "",
      password_confirm: "",
      isSubmitted: false,
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
      valid: function(value) {
        const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSpecial = /[#?!@$%^&*-]/.test(value);
        return (
          containsUppercase &&
          containsLowercase &&
          containsNumber &&
          containsSpecial
        );
      },
    },
    password_confirm: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    async handleSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        const response = await axios.post(
          //"https://tradepulseapi.algocircle.com/change_password/" + this.token,
          "change_password/" + this.$route.params.token,
          {
            password: this.password,
            password_confirm: this.password_confirm,
            token: this.$route.params.token,
          }
        );
        if (response.data.status == "password_already_exists") {
          Utils.make_alert(
            "warning",
            "This password already exists, kindly enter a new password."
          );
        } else {
          Utils.make_alert("success", "Password Change Successful!");
          console.log(response);
          this.$router.push({ name: "login" });
        }
        // this.$router.push("/login");
      } catch (e) {
        if (e.response.status == 401) {
          Utils.make_alert("warning", e.response.data.message);
        } else {
          Utils.make_alert("success", "FAILURE!!! Something went wrong!");
        }
      }
    },
  },
};
</script>

<style scoped>
.login_img {
  background-color: #d2d2d2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60%;
}

.bt_text a {
  color: #949494 !important;
  text-decoration: none;
}

.bt_text {
  font-size: 13px !important;
  font-weight: 600;
  text-align: center;
}

.blue_portion {
  background-color: #173751;
  height: 100%;
  padding-top: 10%;
  padding-left: 7%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.blue_portion .tx {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.blue_portion .tx h3 {
  color: rgba(255, 255, 255, 0.2);
  font-size: 5rem;
  font-weight: bold;
  line-height: 4.5rem;
}

.blue_portion .tx h4 {
  font-size: 20px;
  color: #fff;
}

.blue_portion .dpa_logo {
  width: 190px;
}

.headingt {
  color: #183750;
  font-size: 18px;
  padding-top: 6px;
  margin: 0px;
}

#side_view {
  height: 100%;
  padding: 0px;
}
</style>
