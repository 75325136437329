var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid h-100"},[_c('div',{staticClass:"row justify-content-center h-100"},[_vm._m(0),_c('div',{staticClass:"col-lg-7 col-md-7",attrs:{"id":"grey"}},[_c('div',{staticClass:"login_mid-Portion"},[_c('center',[_c('img',{staticClass:"img-fluid login_logo",attrs:{"src":"/asset/images/AdminLTELogo.png"}})]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit()}}},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{ 'is-invalid': _vm.isSubmitted && _vm.$v.password.$error },attrs:{"type":"password","id":"password","name":"password","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_vm._m(1),(_vm.isSubmitted && _vm.$v.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.password.required)?_c('span',[_vm._v("Password field is required")]):_vm._e(),(_vm.$v.password.required && !_vm.$v.password.minLength)?_c('span',[_vm._v("Password should be at least 6 characters long")]):_vm._e(),(_vm.$v.password.required && !_vm.$v.password.maxLength)?_c('span',[_vm._v("Password should not be more than 20 characters long")]):_vm._e(),(
                  _vm.$v.password.required &&
                    _vm.$v.password.minLength &&
                    _vm.$v.password.maxLength &&
                    !_vm.$v.password.valid
                )?_c('span',[_vm._v("Password must have atleast One Uppercase, One Lowercase, One Number and One Special Character")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirm),expression:"password_confirm"}],staticClass:"form-control",class:{
                'is-invalid': _vm.isSubmitted && _vm.$v.password_confirm.$error,
              },attrs:{"type":"password","id":"password_confirm","name":"password_confirm","placeholder":"Confirm Password"},domProps:{"value":(_vm.password_confirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirm=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.password_confirm.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.password_confirm.required)?_c('span',[_vm._v("Confirm Password field is required ")]):_vm._e(),(
                  !_vm.$v.password_confirm.sameAsPassword &&
                    _vm.$v.password_confirm.required
                )?_c('span',[_vm._v("Passwords should be matched")]):_vm._e()]):_vm._e()]),_c('a',{staticClass:"btn btn-block btn-primary btn-lg sh lg_btn",staticStyle:{"color":"white"},attrs:{"type":"button"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit($event)},"click":_vm.handleSubmit}},[_vm._v(" Change Password ")])])],1),_vm._m(2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-5 col-md-5",attrs:{"id":"side_view"}},[_c('div',{staticClass:"blue_portion",staticStyle:{"background-image":"url('/asset/images/sidemenu_bg.png')"}},[_c('div',{staticClass:"tx"},[_c('h3',[_vm._v("LUNDY INVESTORS")]),_c('h4',[_vm._v("Tailored Solution for Wealth Creation")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toooltip",staticStyle:{"float":"right"}},[_vm._v(" Password Guidelines "),_c('span',{staticClass:"tooltiptext"},[_vm._v(" Minimum 6 Characters "),_c('br'),_vm._v(" Maximum 20 Characters "),_c('br'),_vm._v(" At least 1 Number "),_c('br'),_vm._v(" At least 1 Uppercase "),_c('br'),_vm._v(" At least 1 Lowercase "),_c('br'),_vm._v(" At least 1 Special Character "),_c('br')])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"bt_text"},[_vm._v(" © 2022, Design & Development by "),_c('a',{attrs:{"href":"https://www.decimalpointanalytics.com/"}},[_vm._v("Decimal Point Analytics Pvt Ltd.")])])}]

export { render, staticRenderFns }