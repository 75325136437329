<template>
  <transition name="fade">
    <div class="custom-modal" v-if="isVisible">
      <div class="custom-modal-backdrop"></div>
      <div class="custom-modal-content">
        <div class="custom-modal-header">
          <slot name="header">
          </slot>
        </div>
        <div class="custom-modal-body">
          <slot>
          </slot>
        </div>
        <!-- <div class="custom-modal-footer">
          <slot name="footer">
          </slot>
        </div> -->
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    open() {
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    }
  }
};
</script>


<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}
.custom-modal-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.custom-modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 2;
  width: auto; /* Adjust the width as necessary */
  max-height: 500px; /* Set max height for the modal */
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.custom-modal-header h4 {
  margin: 0;
  font-size: 1.25rem; /* Adjust the size as needed */
  color: #333; /* Adjust the color as needed */
  font-weight: 500;
  padding: 15px; /* Adjust the padding as needed */
  border-bottom: 1px solid #e5e5e5; /* Optional: if you want a line under the header */
}

.custom-modal-body {
  overflow-y: auto; /* Make body scrollable */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  padding: 10px; /* Adjust the padding as needed */
  flex-grow: 1; /* Allows body to expand to fill available space */
}


.custom-modal-footer {
  padding-top: 20px;
  text-align: center; /* Center the buttons */
  border-top: 1px solid #e5e5e5; /* Optional: if you want a line above the footer */
}
</style>

</style>