var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('nav-bar'),_c('aside-bar'),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid tabtop pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-6"},[_c('ul',{attrs:{"id":"bck_btn"}},[_c('li',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"icofont-caret-left text-dark"}),_c('span',[_vm._v("Back")])])])])])])])])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Investor Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.investorName),expression:"investorName"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.investorName.$error,
                    },attrs:{"type":"text","id":"investorName","disabled":true},domProps:{"value":(_vm.investorName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.investorName=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.investorName.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.investorName.required)?_c('span',[_vm._v("Investment Name is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Investment Category")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.investmentCategory),expression:"investmentCategory"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.isSubmitted && _vm.$v.investmentCategory.$error,
                    },attrs:{"type":"text","placeholder":"","id":"investmentCategory","disabled":true},domProps:{"value":(_vm.investmentCategory)},on:{"input":function($event){if($event.target.composing){ return; }_vm.investmentCategory=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.investmentCategory.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.investmentCategory.required)?_c('span',[_vm._v("Investment Category is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field"},[_vm._v("Fund Name")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedFund),expression:"selectedFund"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.selectedFund.$error,
                    },attrs:{"id":"selectedFund","name":"selectedFund"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedFund=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getMgmtFees()}]}},[_c('option',{domProps:{"value":null}},[_vm._v("-- Select a Fund --")]),_vm._l((_vm.funds),function(fund){return _c('option',{domProps:{"value":fund.fund_id}},[_vm._v(" "+_vm._s(fund.fund_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedFund.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedFund.required)?_c('span',[_vm._v("Fund is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Issue Date")]),_c('datepicker',{staticClass:"datepicker",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.IssueDate.$error,
                    },attrs:{"bootstrap-styling":true,"name":"IssueDate","id":"IssueDate","placeholder":"Select Date","format":_vm.$store.state.date_format,"calendar-button-icon":"fa fa-calendar","calendar-button":true,"disabledDates":{
                      to: _vm.InvRegDate,
                      from: new Date(),
                    }},on:{"input":function($event){return _vm.addMonthsToBackCapDate()}},model:{value:(_vm.IssueDate),callback:function ($$v) {_vm.IssueDate=$$v},expression:"IssueDate"}}),(_vm.isSubmitted && _vm.$v.IssueDate.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.IssueDate.required)?_c('span',[_vm._v("Issue Date is required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Amount")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.investorAmount),expression:"investorAmount"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.investorAmount.$error,
                    },attrs:{"type":"number","placeholder":"","id":"investorAmount","step":".01","min":"0"},domProps:{"value":(_vm.investorAmount)},on:{"keypress":function($event){return _vm.isDecimal($event)},"input":function($event){if($event.target.composing){ return; }_vm.investorAmount=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.investorAmount.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.investorAmount.required)?_c('span',[_vm._v("Amount is required")]):_vm._e(),(!_vm.$v.investorAmount.maxLength)?_c('span',[_vm._v("Max length of 10 characters exceeded")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Currency")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCurrency),expression:"selectedCurrency"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.selectedCurrency.$error,
                    },attrs:{"id":"selectedCurrency","name":"selectedCurrency"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedCurrency=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("-- Select a Currency --")]),_vm._l((_vm.currencies),function(curr){return _c('option',{domProps:{"value":curr.id}},[_vm._v(" "+_vm._s(curr.currency_short_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedCurrency.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedCurrency.required)?_c('span',[_vm._v("Currency is required")]):_vm._e()]):_vm._e()])]),(_vm.investmentCategory != 'Participation')?_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field"},[_vm._v("Interest Payment Sch")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedInterestPaymentSchedule),expression:"selectedInterestPaymentSchedule"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.isSubmitted &&
                        _vm.$v.selectedInterestPaymentSchedule.$error,
                    },attrs:{"id":"selectedInterestPaymentSchedule","name":"selectedInterestPaymentSchedule"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedInterestPaymentSchedule=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.addMonthsToBackCapDate()}]}},[_c('option',{domProps:{"value":null}},[_vm._v(" -- Select a Payment Schedule -- ")]),_vm._l((_vm.paymentTypes.slice(0, 3)),function(payment){return _c('option',{key:payment.id,domProps:{"value":payment.id}},[_vm._v(" "+_vm._s(payment.interest_frequency_name)+" ")])})],2),(
                      _vm.isSubmitted && _vm.$v.selectedInterestPaymentSchedule.$error
                    )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedInterestPaymentSchedule.required)?_c('span',[_vm._v("Interest Payment Schedule is required")]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.investmentCategory === 'Participation')?_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field"},[_vm._v("Interest Payment Sch")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedInterestPaymentSchedule),expression:"selectedInterestPaymentSchedule"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.isSubmitted &&
                        _vm.$v.selectedInterestPaymentSchedule.$error,
                    },attrs:{"disabled":true,"id":"selectedInterestPaymentSchedule","name":"selectedInterestPaymentSchedule"},domProps:{"value":(_vm.selectedInterestPaymentSchedule)},on:{"input":function($event){if($event.target.composing){ return; }_vm.selectedInterestPaymentSchedule=$event.target.value}}}),(
                      _vm.isSubmitted && _vm.$v.selectedInterestPaymentSchedule.$error
                    )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedInterestPaymentSchedule.required)?_c('span',[_vm._v("Interest Payment Schedule is required")]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.investmentCategory != 'Participation')?_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Maturity Date")]),_c('datepicker',{class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.MaturityDate.$error,
                    },attrs:{"bootstrap-styling":true,"name":"MaturityDate","id":"MaturityDate","placeholder":"Select Date","format":_vm.$store.state.date_format,"calendar-button-icon":"fa fa-calendar","calendar-button":true,"disabledDates":{
                      to: new Date(_vm.MaturityBackcapDate),
                    }},model:{value:(_vm.MaturityDate),callback:function ($$v) {_vm.MaturityDate=$$v},expression:"MaturityDate"}}),(_vm.isSubmitted && _vm.$v.MaturityDate.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.MaturityDate.required)?_c('span',[_vm._v("Maturity Date is required")]):_vm._e()]):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field"},[_vm._v("Interest Rate Type")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedInterestRateType),expression:"selectedInterestRateType"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.isSubmitted && _vm.$v.selectedInterestRateType.$error,
                    },attrs:{"id":"selectedInterestRateType","name":"selectedInterestRateType"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedInterestRateType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v(" -- Select an Interest Type -- ")]),_vm._l((_vm.interestTypes),function(interest){return _c('option',{domProps:{"value":interest.interest_type_name}},[_vm._v(" "+_vm._s(interest.interest_type_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedInterestRateType.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedInterestRateType.required)?_c('span',[_vm._v("Interest Rate Type is required")]):_vm._e()]):_vm._e()])]),(_vm.selectedInterestRateType === 'Fixed')?_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Interest Rate(%)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.InterestRate),expression:"InterestRate"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.InterestRate.$error,
                    },attrs:{"type":"number","placeholder":"","id":"InterestRate","min":"1"},domProps:{"value":(_vm.InterestRate)},on:{"keypress":function($event){return _vm.isDecimal($event)},"input":function($event){if($event.target.composing){ return; }_vm.InterestRate=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.InterestRate.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.InterestRate.required)?_c('span',[_vm._v("Interest Rate is required")]):_vm._e(),(!_vm.$v.InterestRate.maxDigit)?_c('span',[_vm._v("Max value of 3 digits exceeded")]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.selectedInterestRateType === 'Floating')?_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Spread(%)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Spread),expression:"Spread"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.Spread.$error,
                    },attrs:{"type":"number","placeholder":"","id":"Spread","min":"1"},domProps:{"value":(_vm.Spread)},on:{"keypress":function($event){return _vm.isDecimal($event)},"input":function($event){if($event.target.composing){ return; }_vm.Spread=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.Spread.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.Spread.required)?_c('span',[_vm._v("Spread is required")]):_vm._e(),(!_vm.$v.Spread.maxDigit)?_c('span',[_vm._v("Max value of 3 digits exceeded")]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.selectedInterestRateType === 'Floating')?_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Benchmark")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedBenchmark),expression:"selectedBenchmark"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.isSubmitted && _vm.$v.selectedBenchmark.$error,
                    },attrs:{"id":"selectedBenchmark","name":"selectedBenchmark"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedBenchmark=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("-- Select a Benchmark --")]),_vm._l((_vm.benchmarks),function(benchmark){return _c('option',{domProps:{"value":benchmark.id}},[_vm._v(" "+_vm._s(benchmark.benchmark_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedBenchmark.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedBenchmark.required)?_c('span',[_vm._v("Benchmark is required")]):_vm._e()]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Day Count")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedDayCount),expression:"selectedDayCount"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.selectedDayCount.$error,
                    },attrs:{"id":"selectedDayCount","name":"selectedDayCount"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedDayCount=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("-- Select a Count --")]),_vm._l((_vm.dayCounts),function(dc){return _c('option',{domProps:{"value":dc.id}},[_vm._v(" "+_vm._s(dc.day_count_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedDayCount.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedDayCount.required)?_c('span',[_vm._v("Day Count is required")]):_vm._e()]):_vm._e()])]),(_vm.investmentCategory !== 'Notes')?_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v("Penalty (%)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Penalty),expression:"Penalty"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.Penalty.$error,
                    },attrs:{"type":"number","placeholder":"","id":"Penalty"},domProps:{"value":(_vm.Penalty)},on:{"keypress":function($event){return _vm.isDecimal($event)},"input":function($event){if($event.target.composing){ return; }_vm.Penalty=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.Penalty.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.Penalty.maxDigit)?_c('span',[_vm._v("Max value of 3 digits exceeded")]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.investmentCategory !== 'Notes')?_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v("Mgmt Fees (%)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.MgmtFees),expression:"MgmtFees"}],staticClass:"form-control",attrs:{"type":"number","placeholder":"","id":"MgmtFees","disabled":""},domProps:{"value":(_vm.MgmtFees)},on:{"keypress":function($event){return _vm.isDecimal($event)},"input":function($event){if($event.target.composing){ return; }_vm.MgmtFees=$event.target.value}}})])]):_vm._e(),(_vm.investmentCategory != 'Notes')?_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v("Max. Investment Tenure")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.maximum_investment_tenure),expression:"maximum_investment_tenure"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.isSubmitted && _vm.$v.maximum_investment_tenure.$error,
                    },attrs:{"type":"number","placeholder":"","id":"maximum_investment_tenure"},domProps:{"value":(_vm.maximum_investment_tenure)},on:{"keypress":function($event){return _vm.isNum($event)},"input":function($event){if($event.target.composing){ return; }_vm.maximum_investment_tenure=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.maximum_investment_tenure.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.maximum_investment_tenure.maxLength)?_c('span',[_vm._v("Max length of 3 characters exceeded")]):_vm._e(),(!_vm.$v.maximum_investment_tenure.required)?_c('span',[_vm._v("Max Investment Tenure is required for Penalty")]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.investmentCategory != 'Notes')?_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',[_vm._v("Period")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.period),expression:"period"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.period.$error,
                    },attrs:{"id":"period","name":"period"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.period=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("-- Select a Period --")]),_c('option',{attrs:{"value":"Days"}},[_vm._v("Days")]),_c('option',{attrs:{"value":"Weeks"}},[_vm._v("Weeks")]),_c('option',{attrs:{"value":"Months"}},[_vm._v("Months")]),_c('option',{attrs:{"value":"Years"}},[_vm._v("Years")])]),(_vm.isSubmitted && _vm.$v.period.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.period.required)?_c('span',[_vm._v("Period is required for Penalty")]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.investmentCategory === 'Participation')?_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Borrower Name")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedBorrowerName),expression:"selectedBorrowerName"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.isSubmitted && _vm.$v.selectedBorrowerName.$error,
                    },attrs:{"id":"selectedBorrowerName","name":"selectedBorrowerName","required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedBorrowerName=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getUtilisationRequests()}]}},[_c('option',{domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.borrowers),function(borrower){return _c('option',{domProps:{"value":borrower.borrower_id}},[_vm._v(" "+_vm._s(borrower.borrower_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedBorrowerName.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedBorrowerName.required)?_c('span',[_vm._v("Borrower Name is required")]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.investmentCategory === 'Participation')?_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Utilisation Req. ID")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedLoanId),expression:"selectedLoanId"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.selectedLoanId.$error,
                    },attrs:{"id":"selectedLoanId","name":"selectedLoanId","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedLoanId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.utilisationRequests),function(util){return _c('option',{domProps:{"value":util.id}},[_vm._v(" "+_vm._s(util.loan_registration_id)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedLoanId.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedLoanId.required)?_c('span',[_vm._v("Utilisation Req. ID is required")]):_vm._e()]):_vm._e()])]):_vm._e()])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right mb-3"},[_c('ul',{attrs:{"id":"sr_btn"}},[_c('li',[_c('button',{staticClass:"btn-sm btn btn-success sh",attrs:{"type":"button"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" Submit ")])]),_c('li',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('button',{staticClass:"btn-sm btn btn-dark sh",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Cancel ")])])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"headingt"},[_c('i',{staticClass:"icofont-plus"}),_vm._v(" Add Investment ")])])}]

export { render, staticRenderFns }