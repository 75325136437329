//this is index.js -> it is entry point for all the vuejs routers.
import Vue from "vue";
import { store } from "@/store";
import VueRouter from "vue-router";
import Login from "@/components/auth/Login.vue";
import Logout from "@/components/auth/Logout.vue";
import Fund from "@/views/fund/Fund.vue";
import FundOverview from "@/views/fund/FundOverview.vue";
import FundExpense from "@/views/fund/FundExpense.vue";
import AddFund from "@/views/fund/AddFund.vue";
import EditFund from "@/views/fund/EditFund.vue";
import AddProportionateExpense from "@/views/fund/AddProportionateExpense.vue";
import AddExpensePayment from "@/views/fund/AddExpensePayment.vue";
import ForgotPassword from "@/components/auth/ForgotPassword.vue";
import ChangePassword from "@/components/auth/ChangePassword.vue";
import Unauthorised from "@/components/auth/Unauthorised.vue";
import AdminDashboard from "@/views/AdminDashboard.vue";
import AddBorrower from "@/views/borrower/AddBorrower.vue";
import Borrower from "@/views/borrower/Borrower.vue";
import BorrowerOverview from "@/views/borrower/BorrowerOverview.vue";
import CovenantHistory from "@/views/borrower/CovenantHistory.vue";
import KYCDocument from "@/views/borrower/KYCDocument.vue";
// page added 12-03-2024
import FundDisrtribution from "@/views/fund/FundDistribution.vue"
import FundInvestment from "@/views/fund/FundInvestment.vue"

// import BorrowerPreview from "@/views/borrower/BorrowerPreview.vue"
import EditBorrower from "@/views/borrower/EditBorrower.vue";
import Investor from "@/views/investor/Investor.vue";
import AddInvestor from "@/views/investor/AddInvestor.vue";
import AddInvestment from "@/views/investor/AddInvestment.vue";
import InvestorOverview from "@/views/investor/InvestorOverview.vue";
import EditInvestor from "@/views/investor/EditInvestor.vue";
import EditInvestment from "@/views/investor/EditInvestment.vue";
// import InvestorPreview from "@/views/investor/InvestorPreview.vue"
import InvestorPayment from "@/views/investor/InvestorPayment.vue";
import AddInvestorPayment from "@/views/investor/AddInvestorPayment.vue";
import KYCHistory from "@/views/investor/KYCHistory.vue";
// Facility pages routing
import Facility from "@/views/facility/Facility.vue";
import AddFacility from "@/views/facility/AddFacility.vue";
import FacilityOverview from "@/views/facility/FacilityOverview.vue";
import EditFacility from "@/views/facility/EditFacility.vue";
import FacilityCovenantHistory from "@/views/facility/FacilityCovenantHistory.vue";
import FacilityCPChecklistHistory from "@/views/facility/FacilityCPChecklistHistory.vue";
import AddCost from "@/views/facility/AddCost.vue";
import CostOverview from "@/views/facility/CostOverview.vue";
import AddCostPayment from "@/views/facility/AddCostPayment.vue";
import CostPaymentOverview from "@/views/facility/CostPaymentOverview.vue";
import AddDisbursement from "@/views/facility/AddDisbursement.vue";
import DisbursementOverview from "@/views/facility/DisbursementOverview.vue";
// Loan pages routing
import Loan from "@/views/loan/Loan.vue";
import AddLoan from "@/views/loan/AddLoan.vue";
import AddLoanPayment from "@/views/loan/AddLoanPayment.vue";
import AddDrawdownRequest from "@/views/loan/AddDrawdownRequest.vue";
import LoanOverview from "@/views/loan/LoanOverview.vue";
import EditLoan from "@/views/loan/EditLoan.vue";
import LoanUtilisationRequestOverview from "@/views/loan/LoanUtilisationRequestOverview.vue";
import EditDrawdownRequest from "@/views/loan/EditDrawdownRequest.vue";
import EditLoanPayment from "@/views/loan/EditLoanPayment.vue";
import LoanBankCharge from "@/views/loan/LoanBankCharge.vue";
import AddCostRequest from "@/views/loan/AddCostRequest.vue";
import BorrowerMonthlyStatement from "@/views/loan/BorrowerMonthlyStatement.vue";
// page added 12-03-2024
import LoanDisrtribution from "@/views/loan/LoanDistribution.vue"
import LoanManagement from "@/views/loan/LoanManagement.vue"

// Collateral pages routing
import Collateral from "@/views/collateral/Collateral.vue";
import AddCollateral from "@/views/collateral/AddCollateral.vue";
import EditCollateral from "@/views/collateral/EditCollateral.vue";
import CollateralManagerOverview from "@/views/collateral/CollateralManagerOverview.vue";
import CollateralDocHistory from "@/views/collateral/CollateralDocHistory.vue";
import CollateralAdminDocHistory from "@/views/collateral/CollateralAdminDocHistory.vue";
import CollateralAgreementHistory from "@/views/collateral/CollateralAgreementHistory.vue";
import CollateralDisputedHistory from "@/views/collateral/CollateralDisputedHistory.vue";
// page added 12-03-2024
import CollateralDistribution from "@/views/collateral/CollateralDistribution.vue";
import CollateralTracking from "@/views/collateral/CollateralTracking.vue";

import AddWarehouse from "@/views/warehouse/AddWarehouse.vue";
import EditWarehouse from "@/views/warehouse/EditWarehouse.vue";
import WarehouseOverview from "@/views/warehouse/WarehouseOverview.vue";
import WarehouseDocHistory from "@/views/warehouse/WarehouseDocHistory.vue";
import WarehouseAdminDocHistory from "@/views/warehouse/WarehouseAdminDocHistory.vue";
import WarehouseCollateralDocHistory from "@/views/warehouse/WarehouseCollateralDocHistory.vue";
import SiteInspectionHistory from "@/views/warehouse/SiteInspectionHistory.vue";
import ReleaseRequest from "@/views/request/ReleaseRequest.vue";
import Uncleaned from "@/views/inventory/Uncleaned.vue";
import CleaningProcess from "@/views/inventory/CleaningProcess.vue";
import Cleaned from "@/views/inventory/Cleaned.vue";
import Waste from "@/views/inventory/Waste.vue";
import VerificationSummary from "@/views/inventory/VerificationSummary.vue";
import AddRequest from "@/views/request/AddRequest.vue";
import EditRequest from "@/views/request/EditRequest.vue";
import RequestOverview from "@/views/request/RequestOverview.vue";
import AddRelease from "@/views/release/AddRelease.vue";
import EditRelease from "@/views/release/EditRelease.vue";
import ReleaseOverview from "@/views/release/ReleaseOverview.vue";

import AddReceivedInfo from "@/views/inventory/AddReceivedInfo.vue";
import UncleanedInventoryOverview from "@/views/inventory/UncleanedInventoryOverview.vue";
import AddCleaningProcess from "@/views/inventory/AddCleaningProcess.vue";
import CleanedInventoryOverview from "@/views/inventory/CleanedInventoryOverview.vue";

// Logistics pages routing
import Logistics from "@/views/logistics/Logistics.vue";
import AddShipping from "@/views/logistics/AddShipping.vue";
import Shipping from "@/views/logistics/Shipping.vue";
import ShippingOverview from "@/views/logistics/ShippingOverview.vue";
import EditShipping from "@/views/logistics/EditShipping.vue";
// LTV pages routing
import LTVChecker from "@/views/ltv/LTVChecker.vue";
// Offtaker pages routing
import AddOfftaker from "@/views/offtaker/AddOfftaker.vue";
import Offtaker from "@/views/offtaker/Offtaker.vue";
import OfftakerOverview from "@/views/offtaker/OfftakerOverview.vue";
import EditOfftaker from "@/views/offtaker/EditOfftaker.vue";
import AddContract from "@/views/offtaker/AddContract.vue";
import AddRepayment from "@/views/offtaker/AddRepayment.vue";
import RepaymentOverview from "@/views/offtaker/RepaymentOverview.vue";
import ContractOverview from "@/views/offtaker/ContractOverview.vue";

Vue.use(VueRouter);

const routes = [
  //main app
  {
    path: "/",
    name: "AdminDashboard",
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") == "Investor" &&
        localStorage.getItem("subgroup_id") == 0
      ) {
        next({ name: "AddInvestor" });
      } else if (
        localStorage.getItem("group_name") == "Investor" &&
        localStorage.getItem("subgroup_id") != 0
      ) {
        next({ name: "Investor" });
      } else if (
        localStorage.getItem("group_name") == "Borrower" &&
        localStorage.getItem("subgroup_id") == 0
      ) {
        next({ name: "AddBorrower" });
      } else if (
        localStorage.getItem("group_name") == "Borrower" &&
        localStorage.getItem("subgroup_id") != 0
      ) {
        next({ name: "Borrower" });
        // } else if (localStorage.getItem('group_name') == "Admin" || localStorage.getItem('group_name') == "Analyst" && localStorage.getItem('subgroup_id') == 0){
        //   next({ name: 'AdminDashboard' })
      } else if (
        localStorage.getItem("group_name") == "Collateral" &&
        localStorage.getItem("subgroup_id") == 0
      ) {
        next({ name: "AddCollateral" });
      } else if (
        localStorage.getItem("group_name") == "Collateral" &&
        localStorage.getItem("subgroup_id") != 0
      ) {
        next({ name: "Collateral" });
      } else if (
        localStorage.getItem("group_name") == "Offtaker" &&
        localStorage.getItem("subgroup_id") == 0
      ) {
        next({ name: "AddOfftaker" });
      } else if (
        localStorage.getItem("group_name") == "Offtaker" &&
        localStorage.getItem("subgroup_id") != 0
      ) {
        next({ name: "Offtaker" });
      } else {
        next();
      }
    },
  },
  // {
  //   path: '/home',
  //   name: 'AdminDashboard',
  //   component: AdminDashboard,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/unauthorised",
    name: "Unauthorised",
    component: Unauthorised,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fund-overview/:id/",
    name: "FundOverview",
    component: FundOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Investor" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/fund-overview/:id/:type/:index",
    name: "ActionFundOverview",
    component: FundOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Investor" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/fund",
    name: "Fund",
    component: Fund,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Investor" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/fund-expense/:type/:fund_id/:expense_id",
    name: "FundExpense",
    component: FundExpense,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Investor" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-fund",
    name: "AddFund",
    component: AddFund,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/edit-fund/:id",
    name: "EditFund",
    component: EditFund,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-proportionate-expense",
    name: "AddProportionateExpense",
    component: AddProportionateExpense,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-expenses-payment/:type/:fundID/:id",
    name: "AddExpensePayment",
    component: AddExpensePayment,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/forgot_password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/change_password/:token",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/summary",
    name: "AdminDashboard",
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiresVisitor: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") == "Investor" &&
        localStorage.getItem("subgroup_id") == 0
      ) {
        next({ name: "AddInvestor" });
      } else if (
        localStorage.getItem("group_name") == "Investor" &&
        localStorage.getItem("subgroup_id") != 0
      ) {
        next({ name: "Investor" });
      } else if (
        localStorage.getItem("group_name") == "Borrower" &&
        localStorage.getItem("subgroup_id") == 0
      ) {
        next({ name: "AddBorrower" });
      } else if (
        localStorage.getItem("group_name") == "Borrower" &&
        localStorage.getItem("subgroup_id") != 0
      ) {
        next({ name: "Borrower" });
      } else if (
        localStorage.getItem("group_name") == "Admin" ||
        (localStorage.getItem("group_name") == "Analyst" &&
          localStorage.getItem("subgroup_id") == 0)
      ) {
        next({ name: "AdminDashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },
  {
    path: "/add-borrower",
    name: "AddBorrower",
    component: AddBorrower,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/borrower",
    name: "Borrower",
    component: Borrower,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Admin" &&
        localStorage.getItem("group_name") != "Analyst"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/borrower-overview/:id",
    name: "BorrowerOverview",
    component: BorrowerOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/edit-borrower/:id",
    name: "EditBorrower",
    component: EditBorrower,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Admin" &&
        localStorage.getItem("group_name") != "Analyst"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  // {
  //   path: "/borrower-preview",
  //   name: "BorrowerPreview",
  //   component: BorrowerPreview,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/add-investor",
    name: "AddInvestor",
    component: AddInvestor,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Investor" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    //path: "/add-investment/:id/:InvRegDate",
    path: "/add-investment",
    name: "AddInvestment",
    component: AddInvestment,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Investor" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/investor",
    name: "Investor",
    component: Investor,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Investor" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/investor-overview/:id/",
    name: "InvestorOverview",
    component: InvestorOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Investor" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/investor-overview/:id/:type/:index/:activeTab/",
    name: "InvestorOverview",
    component: InvestorOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Investor" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/edit-investor/:id",
    name: "EditInvestor",
    component: EditInvestor,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Investor" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/edit-investment/:investor_id/:transaction_id",
    name: "EditInvestment",
    component: EditInvestment,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/investor-payment/:investor_id/:fund_id/:start_date/:end_date",
    name: "InvestorPayment",
    component: InvestorPayment,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Investor" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-investor-payment/:id",
    name: "AddInvestorPayment",
    component: AddInvestorPayment,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Investor" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  // {
  //   path: "/investor-preview",
  //   name: "InvestorPreview",
  //   component: InvestorPreview,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/add-facility/:borrower_id/:type/:BorRegDate",
    name: "AddFacility",
    component: AddFacility,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-facility",
    name: "AddFacility",
    component: AddFacility,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/facility",
    name: "Facility",
    component: Facility,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/facility-overview/:id",
    name: "FacilityOverview",
    component: FacilityOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/edit-facility/:id/:BorRegDate",
    name: "EditFacility",
    component: EditFacility,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Admin" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Borrower"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-cost",
    name: "AddCost",
    component: AddCost,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        // localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/cost-overview/:id",
    name: "CostOverview",
    component: CostOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-cost-payment",
    name: "AddCostPayment",
    component: AddCostPayment,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        // localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/cost-payment-overview/:id",
    name: "CostPaymentOverview",
    component: CostPaymentOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-disbursement",
    name: "AddDisbursement",
    component: AddDisbursement,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/disbursement-overview/:id",
    name: "DisbursementOverview",
    component: DisbursementOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-loan/:id/:facilityId/:type",
    name: "AddLoan",
    component: AddLoan,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-loan-payment/:borrower_id/:facility_id/:fund_currency_id/:loan_registration_id/:approval_date",
    name: "AddLoanPayment",
    component: AddLoanPayment,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-loan-drawdown/:borrower_id/:facility_id/:fund_currency_id/:loan_registration_id/:approval_date",
    name: "AddDrawdownRequest",
    component: AddDrawdownRequest,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/loan",
    name: "Loan",
    component: Loan,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/loan-overview/:id",
    name: "LoanOverview",
    component: LoanOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/loan-util-overview-by-id/:borrower_id/:id/",
    name: "LoanUtilisationRequestOverview",
    component: LoanUtilisationRequestOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/loan-util-overview-by-id/:borrower_id/:id/:type/:index",
    name: "LoanUtilisationRequestOverview",
    component: LoanUtilisationRequestOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/edit-loan-drawdown/:id",
    name: "EditDrawdownRequest",
    component: EditDrawdownRequest,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/edit-loan-payment/:id",
    name: "EditLoanPayment",
    component: EditLoanPayment,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/loan-bank-charges/:id",
    name: "LoanBankCharge",
    component: LoanBankCharge,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/edit-loan/:id/:type",
    name: "EditLoan",
    component: EditLoan,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Admin" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Borrower"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/borrower-monthly-statement/:id",
    name: "BorrowerMonthlyStatement",
    component: BorrowerMonthlyStatement,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/borrower-covenant-history/:id",
    name: "CovenantHistory",
    component: CovenantHistory,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/facility-covenant-history/:id",
    name: "FacilityCovenantHistory",
    component: FacilityCovenantHistory,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/facility-cp-checklist-history/:id",
    name: "FacilityCPChecklistHistory",
    component: FacilityCPChecklistHistory,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/borrower-kyc-history/:id",
    name: "KYCDocument",
    component: KYCDocument,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/investor-kyc-history/:id",
    name: "KYCHistory",
    component: KYCHistory,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Investor" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add_cost/:borrower_id/:facility_id/:fund_currency_id/:loan_registration_id/:approval_date",
    name: "AddCostRequest",
    component: AddCostRequest,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/collateral",
    name: "Collateral",
    component: Collateral,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-collateral",
    name: "AddCollateral",
    component: AddCollateral,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/collateral-manager-overview/:id/",
    name: "CollateralManagerOverview",
    component: CollateralManagerOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/collateral-agreement-history/:id",
    name: "CollateralAgreementHistory",
    component: CollateralAgreementHistory,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/collateral-doc-history/:id",
    name: "CollateralDocHistory",
    component: CollateralDocHistory,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/collateral-admin-doc-history/:id",
    name: "CollateralAdminDocHistory",
    component: CollateralAdminDocHistory,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/collateral-disputed-history/:id",
    name: "CollateralDisputedHistory",
    component: CollateralDisputedHistory,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/edit-collateral/:id",
    name: "EditCollateral",
    component: EditCollateral,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-warehouse",
    name: "AddWarehouse",
    component: AddWarehouse,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/edit-warehouse/:id/",
    name: "EditWarehouse",
    component: EditWarehouse,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/warehouse-overview/:id/",
    name: "WarehouseOverview",
    component: WarehouseOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/warehouse-doc-history/:id",
    name: "WarehouseDocHistory",
    component: WarehouseDocHistory,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/warehouse-admin-doc-history/:id",
    name: "WarehouseAdminDocHistory",
    component: WarehouseAdminDocHistory,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/warehouse-collateral-doc-history/:id",
    name: "WarehouseCollateralDocHistory",
    component: WarehouseCollateralDocHistory,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/site-inspection-history/:id",
    name: "SiteInspectionHistory",
    component: SiteInspectionHistory,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/uncleaned/:id",
    name: "Uncleaned",
    component: Uncleaned,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/cleaning-process/:id",
    name: "CleaningProcess",
    component: CleaningProcess,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/cleaned/:id",
    name: "Cleaned",
    component: Cleaned,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/waste/:id",
    name: "Waste",
    component: Waste,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/verification-summary/:id",
    name: "VerificationSummary",
    component: VerificationSummary,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/release-request/:id",
    name: "ReleaseRequest",
    component: ReleaseRequest,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-request/",
    name: "AddRequest",
    component: AddRequest,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/edit-request/:id/",
    name: "EditRequest",
    component: EditRequest,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/request-overview/:id/",
    name: "RequestOverview",
    component: RequestOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-release/",
    name: "AddRelease",
    component: AddRelease,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/edit-release/:id/",
    name: "EditRelease",
    component: EditRelease,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/release-overview/:id/",
    name: "ReleaseOverview",
    component: ReleaseOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-received-info/",
    name: "AddReceivedInfo",
    component: AddReceivedInfo,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/uncleaned-inventory-overview/:id/:collateral",
    name: "UncleanedInventoryOverview",
    component: UncleanedInventoryOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-cleaning-process/:id/",
    name: "AddCleaningProcess",
    component: AddCleaningProcess,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/cleaned-inventory-overview/:id/:collateral",
    name: "CleanedInventoryOverview",
    component: CleanedInventoryOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/logistics",
    name: "Logistics",
    component: Logistics,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-shipping",
    name: "AddShipping",
    component: AddShipping,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/shipping",
    name: "Shipping",
    component: Shipping,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/shipping-overview/:id",
    name: "ShippingOverview",
    component: ShippingOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/edit-shipping/:id",
    name: "EditShipping",
    component: EditShipping,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/ltv-checker",
    name: "LTVChecker",
    component: LTVChecker,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Collateral Manager" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/offtaker",
    name: "Offtaker",
    component: Offtaker,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Borrower" &&
        localStorage.getItem("group_name") != "Admin" &&
        localStorage.getItem("group_name") != "Offtaker"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-offtaker",
    name: "AddOfftaker",
    component: AddOfftaker,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/offtaker-overview/:id",
    name: "OfftakerOverview",
    component: OfftakerOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Offtaker" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/edit-offtaker/:id",
    name: "EditOfftaker",
    component: EditOfftaker,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-contract/:id/:partialFillFlag",
    name: "AddContract",
    component: AddContract,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/add-repayment/:id",
    name: "AddRepayment",
    component: AddRepayment,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/repayment-overview/:id",
    name: "RepaymentOverview",
    component: RepaymentOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Offtaker" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/contract-overview/:id",
    name: "ContractOverview",
    component: ContractOverview,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Offtaker" &&
        localStorage.getItem("group_name") != "Analyst" &&
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },

  // added 12-03-2024
  {
    path: "/fund-distribution",
    name: "FundDisrtribution",
    component: FundDisrtribution,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/fund-investment",
    name: "FundInvestment",
    component: FundInvestment,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/loan-distribution",
    name: "LoanDisrtribution",
    component: LoanDisrtribution,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/loan-management",
    name: "LoanManagement",
    component: LoanManagement,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/collateral-distribution",
    name: "CollateralDistribution",
    component: CollateralDistribution,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
  {
    path: "/collateral-tracking",
    name: "CollateralTracking",
    component: CollateralTracking,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (
        localStorage.getItem("group_name") != "Admin"
      ) {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: "history", //needs to be enabled on production
  base: process.env.BASE_URL,
  routes,
  // linkActiveClass: "active", // active class for non-exact links.
  // linkExactActiveClass: "active" // active class for *exact* links.
});

export default router;
