<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt" id="pageHeading">
                        <i class="fas fa-tachometer-alt"></i> Warehouse Details
                        Overview
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="card-body br_overview">
                <!------menton here style="height: 300px;"-------->
                <table class="table table-hover table-head-fixed text-nowrap">
                  <tbody>
                    <tr>
                      <td class="wg" id="warehouseId">Warehouse ID:</td>
                      <td v-if="warehouse.warehouse_registration_id">
                        {{ warehouse.warehouse_registration_id }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="ownerName">Owner Name:</td>
                      <td v-if="warehouse.owner_name">
                        {{ warehouse.owner_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="address">Address:</td>
                      <td
                        style="white-space: pre-wrap"
                        v-if="warehouse.address"
                      >
                        {{ warehouse.address }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="country">Country:</td>
                      <td v-if="warehouse.country_name !== null">
                        {{ warehouse.country_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="state">State:</td>
                      <td v-if="warehouse.state_name">
                        {{ warehouse.state_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="city">City:</td>
                      <td v-if="warehouse.city_name">
                        {{ warehouse.city_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card-body br_overview">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td class="wg" id="borrowerName">Borrower Name:</td>
                      <td v-if="warehouse.borrower_name">
                        {{ warehouse.borrower_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="contact">Contact No:</td>
                      <td v-if="warehouse.contact_number !== null">
                        <span
                          >{{ warehouse.contact_code }}
                          {{ warehouse.contact_number }}</span
                        >
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="regDate">Registration Date:</td>
                      <td v-if="warehouse.registration_date !== null">
                        {{ warehouse.registration_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="site_inspection_start_date">
                        Site Inspection Start Date:
                      </td>
                      <td v-if="warehouse.site_inspection_start_date !== null">
                        {{ warehouse.site_inspection_start_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="frequency">Frequency:</td>
                      <td v-if="warehouse.interest_frequency_name !== null">
                        <span>{{ warehouse.interest_frequency_name }}</span>
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!--========================================================================-->

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Collateral Manager Details</h1>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th>CM Company Name</th>
                        <th>Documents</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="doc in WarehouseCMCompanies" :key="doc.id">
                        <td
                          style="
                            text-decoration: underline !important;
                            color: dodgerblue !important;
                            cursor: pointer;
                          "
                          @click="
                            showEmployeeModal(
                              doc.id,
                              doc.collateral_manager_master_id,
                              doc.company_name
                            )
                          "
                          v-if="doc.company_name"
                        >
                          {{ doc.company_name }}
                        </td>
                        <td v-else class="c">-</td>
                        <td
                          class="c"
                          v-on:click="
                            showCollateralDocumentsModal(
                              doc.collateral_manager_master_id
                            )
                          "
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-file-pdf text-danger"></i>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!--========================================================================-->

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Site Inspection</h1>
                    </div>
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li id="siteInspectionHistory">
                          <router-link
                            active-class="active"
                            :to="{
                              name: 'SiteInspectionHistory',
                              params: {
                                id: id,
                              },
                            }"
                          >
                            <a>
                              <u>Site Inspection History</u>
                            </a>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th class="c">Date</th>
                        <th class="c">Status</th>
                        <th
                          class="c"
                          v-if="
                            loggedInUserGroupId == 'Admin' &&
                            loggedInUserSubGroupId == 0 &&
                            !inactive
                          "
                        >
                          Change Status
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(doc, index) in SiteInspectionDetails"
                        :key="doc.id"
                      >
                        <td
                          id="date"
                          v-if="doc.site_inspection_date !== null"
                          class="c"
                        >
                          {{ doc.site_inspection_date }}
                        </td>
                        <td v-else class="c">-</td>
                        <td
                          v-if="doc.status"
                          id="status"
                          class="c"
                          :class="{
                            'c text-warning': doc.status === 'Pending',
                            'c text-danger': doc.status === 'Closed',
                            'c text-danger': doc.status === 'Expired',
                          }"
                        >
                          {{ doc.status }}
                        </td>
                        <td v-else class="c">-</td>

                        <td
                          class="c status"
                          v-if="
                            loggedInUserGroupId == 'Admin' &&
                            loggedInUserSubGroupId == 0 &&
                            !inactive
                          "
                        >
                          <div class="status">
                            <select
                              id="docStatus"
                              name="docStatus"
                              @change="
                                onChangeDocStatus(
                                  $event,
                                  doc.id,
                                  doc.status,
                                  index,
                                  'WarehouseSiteDateStatus',
                                  null
                                )
                              "
                              :disabled="(doc.status === 'Closed' || doc.status === 'Expired')"
                              class="form-control"
                              :value="doc.status"
                            >
                              <option value="Pending">Pending</option>
                              <option value="Closed">Closed</option>
                              <option v-if="doc.status === 'Expired'" value="Expired">Expired</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!--========================================================================-->

        <div
          class="container-fluid ot_tb pt-3"
          v-if="loggedInUserGroupId !== 'Collateral Manager'"
        >
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Documents</h1>
                    </div>
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li id="siteInspectionHistory">
                          <router-link
                            active-class="active"
                            :to="{
                              name: 'WarehouseDocHistory',
                              params: {
                                id: id,
                              },
                            }"
                          >
                            <a>
                              <u>Documents History</u>
                            </a>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Sr. no.</th>
                        <th id="doc">Documents</th>
                        <th class="c" id="issueDate">Issue Date</th>
                        <th class="c" id="expiryDate">Expiry Date</th>
                        <th class="c" id="status">Status</th>
                        <th
                          class="c"
                          v-if="
                            (loggedInUserGroupId == 'Analyst' ||
                              (loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0)) &&
                            !inactive
                          "
                        >
                          Change Status
                        </th>
                        <th class="center">Comments</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(doc, index) in warehouseDocuments"
                        :key="doc.warehouse_document_id"
                      >
                        <td
                          v-if="doc.WarehouseDocName"
                          v-on:click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                          class="center"
                        >
                          <i
                            id="buttons"
                            class="icofont-download text-info"
                          ></i>
                        </td>
                        <td v-else class="c"></td>
                        <td v-if="doc.WarehouseDocName">
                          {{ index + 1 }}
                        </td>
                        <td class="c" v-else>-</td>
                        <td v-if="doc.WarehouseDocName">
                          {{ doc.WarehouseDocName }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="doc.IssueDate" class="c">
                          {{ doc.IssueDate }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="doc.ExpiryDate" class="c">
                          {{ doc.ExpiryDate }}
                        </td>
                        <td v-else class="c">-</td>
                        <td
                          v-if="doc.document_status"
                          class="c"
                          :class="{
                            'c text-success':
                              doc.document_status === 'Approved',
                            'c text-warning':
                              doc.document_status === 'Received',
                            'c text-danger': doc.document_status === 'Rejected',
                            'c text-info':
                              doc.document_status === 'Near to Expiry',
                            'c text-dark': doc.document_status === 'Expired',
                          }"
                        >
                          {{
                            doc.document_status === "Received"
                              ? loggedInUserGroupId !== "Admin"
                                ? "Uploaded"
                                : doc.document_status
                              : doc.document_status
                          }}
                        </td>
                        <td v-else class="c">-</td>

                        <td
                          class="c status"
                          v-if="
                            (loggedInUserGroupId == 'Analyst' ||
                              (loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0)) &&
                            !inactive
                          "
                        >
                          <div class="status">
                            <select
                              id="docStatus"
                              name="docStatus"
                              @change="
                                onChangeDocStatus(
                                  $event,
                                  doc.warehouse_document_id,
                                  doc.document_status,
                                  index,
                                  'WarehouseDocStatus',
                                  'Document'
                                )
                              "
                              :disabled="
                                doc.document_status !== 'Received' &&
                                doc.document_status !== 'Rejected'
                              "
                              class="form-control"
                              :value="doc.document_status"
                            >
                              <option value="Received">Received</option>
                              <option value="Approved">Approved</option>
                              <option value="Rejected">Rejected</option>
                              <option
                                v-if="doc.document_status === 'Near to Expiry'"
                                value="Near to Expiry"
                              >
                                Near to Expiry
                              </option>
                              <option
                                v-if="doc.document_status === 'Expired'"
                                value="Expired"
                              >
                                Expired
                              </option>
                            </select>
                          </div>
                        </td>
                        <td
                          class="c center"
                          v-if="!inactive"
                          v-on:click="
                            showCommentModal(
                              doc.comments,
                              doc.warehouse_document_id,
                              'Document'
                            )
                          "
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-comment text-info"></i>
                        </td>
                        <td class="c center" v-else>
                          <i
                            style="opacity: 0.5"
                            class="icofont-comment text-info"
                          ></i>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!--========================================================================-->

        <div
          class="container-fluid ot_tb pt-3"
          v-if="loggedInUserGroupId == 'Admin'"
        >
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Administrative Documents</h1>
                    </div>
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li id="warehouseAdminDocHistory">
                          <router-link
                            active-class="active"
                            :to="{
                              name: 'WarehouseAdminDocHistory',
                              params: {
                                id: id,
                              },
                            }"
                          >
                            <a>
                              <u>Admin Documents History</u>
                            </a>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Sr. no.</th>
                        <th id="doc">Documents</th>
                        <th class="c" id="issueDate">Issue Date</th>
                        <th class="c" id="expiryDate">Expiry Date</th>
                        <th class="center">Comments</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(doc, index) in warehouseAdminDocuments"
                        :key="doc.warehouse_admin_document_id"
                      >
                        <td
                          v-if="doc.WarehouseAdminDocName"
                          v-on:click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                          class="center"
                        >
                          <i
                            id="buttons"
                            class="icofont-download text-info"
                          ></i>
                        </td>
                        <td v-else class="c"></td>
                        <td v-if="doc.WarehouseAdminDocName">
                          {{ index + 1 }}
                        </td>
                        <td class="c" v-else>-</td>
                        <td v-if="doc.WarehouseAdminDocName">
                          {{ doc.WarehouseAdminDocName }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="doc.IssueDate" class="c">
                          {{ doc.IssueDate }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="doc.ExpiryDate" class="c">
                          {{ doc.ExpiryDate }}
                        </td>
                        <td v-else class="c">-</td>
                        <td
                          class="c center"
                          v-if="!inactive"
                          v-on:click="
                            showCommentModal(
                              doc.comments,
                              doc.warehouse_admin_document_id,
                              'AdminDocument'
                            )
                          "
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-comment text-info"></i>
                        </td>
                        <td class="c center" v-else>
                          <i
                            style="opacity: 0.5"
                            class="icofont-comment text-info"
                          ></i>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!--========================================================================-->

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">CM Uploaded Documents</h1>
                    </div>
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li v-if="loggedInUserGroupId === 'Collateral Manager'">
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-success"
                            @click="showAddCollateralDocModal"
                          >
                            <i class="icofont-plus"></i>
                            <span class="mob">Add Document</span>
                          </button>
                        </li>
                        <li id="warehouseCollateralDocHistory">
                          <router-link
                            active-class="active"
                            :to="{
                              name: 'WarehouseCollateralDocHistory',
                              params: {
                                id: id,
                              },
                            }"
                          >
                            <a>
                              <u>CM Uploaded Documents History</u>
                            </a>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Sr. no.</th>
                        <th id="cmCompanyName">CM Company Name</th>
                        <th id="doc">Documents</th>
                        <th class="c" id="issueDate">Issue Date</th>
                        <th class="c" id="expiryDate">Expiry Date</th>
                        <th class="c" id="status">Status</th>
                        <th
                          class="c"
                          v-if="
                            (loggedInUserGroupId == 'Analyst' ||
                              (loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0)) &&
                            !inactive
                          "
                        >
                          Change Status
                        </th>
                        <th class="center">Comments</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(
                          doc, index
                        ) in warehouseCollateralAdminDocuments"
                        :key="doc.warehouse_document_id"
                      >
                        <td
                          v-if="doc.WarehouseCMAdminDocName"
                          v-on:click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                          class="center"
                        >
                          <i
                            id="buttons"
                            class="icofont-download text-info"
                          ></i>
                        </td>
                        <td v-else class="c"></td>
                        <td v-if="doc.WarehouseCMAdminDocName">
                          {{ index + 1 }}
                        </td>
                        <td class="c" v-else>-</td>
                        <td v-if="doc.company_name">
                          {{ doc.company_name }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="doc.WarehouseCMAdminDocName">
                          {{ doc.WarehouseCMAdminDocName }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="doc.IssueDate" class="c">
                          {{ doc.IssueDate }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="doc.ExpiryDate" class="c">
                          {{ doc.ExpiryDate }}
                        </td>
                        <td v-else class="c">-</td>
                        <td
                          v-if="doc.document_status"
                          class="c"
                          :class="{
                            'c text-success':
                              doc.document_status === 'Approved',
                            'c text-warning':
                              doc.document_status === 'Received',
                            'c text-danger': doc.document_status === 'Rejected',
                            'c text-info':
                              doc.document_status === 'Near to Expiry',
                            'c text-dark': doc.document_status === 'Expired',
                          }"
                        >
                          {{
                            doc.document_status === "Received"
                              ? loggedInUserGroupId === "Collateral Manager"
                                ? "Uploaded"
                                : doc.document_status
                              : doc.document_status
                          }}
                        </td>
                        <td v-else class="c">-</td>

                        <td
                          class="c status"
                          v-if="
                            (loggedInUserGroupId == 'Analyst' ||
                              (loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0)) &&
                            !inactive
                          "
                        >
                          <div class="status">
                            <select
                              id="docStatus"
                              name="docStatus"
                              @change="
                                onChangeDocStatus(
                                  $event,
                                  doc.warehouse_document_id,
                                  doc.document_status,
                                  index,
                                  'WarehouseDocStatus',
                                  'CollateralDocument'
                                )
                              "
                              :disabled="
                                doc.document_status !== 'Received' &&
                                doc.document_status !== 'Rejected'
                              "
                              class="form-control"
                              :value="doc.document_status"
                            >
                              <option value="Received">Received</option>
                              <option value="Approved">Approved</option>
                              <option value="Rejected">Rejected</option>
                              <option
                                v-if="doc.document_status === 'Near to Expiry'"
                                value="Near to Expiry"
                              >
                                Near to Expiry
                              </option>
                              <option
                                v-if="doc.document_status === 'Expired'"
                                value="Expired"
                              >
                                Expired
                              </option>
                            </select>
                          </div>
                        </td>
                        <td
                          class="c center"
                          v-if="!inactive"
                          v-on:click="
                            showCommentModal(
                              doc.comments,
                              doc.warehouse_document_id,
                              'CollateralDocument'
                            )
                          "
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-comment text-info"></i>
                        </td>
                        <td class="c center" v-else>
                          <i
                            style="opacity: 0.5"
                            class="icofont-comment text-info"
                          ></i>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.Tabpane second end-->
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper -->

    <!-- ./wrapper -->
    <!-- ./modal start -->
    <div class="modal fade" id="comm">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-comment"></i> Comments
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <textarea
                  rows="4"
                  cols="60"
                  style="outline: none"
                  v-model="comment"
                  :class="{
                    'is-invalid': $v.comment.$error,
                  }"
                ></textarea>
                <div v-if="$v.comment.$error" class="invalid-feedback">
                  <span v-if="!$v.comment.maxLength"
                    >Max length of 100 characters exceeded</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="saveComment()"
                  >
                    Save
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
    <!-- ./modal start -->
    <div class="modal fade" id="add_doc">
      <div class="modal-dialog modal-lg">
        <div class="modal-content" style="width: 110%">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Add Document</h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="required-field">Document Name</th>
                        <th class="required-field">Document</th>
                        <th>Issue Date</th>
                        <th>Expiry Date</th>
                        <th>Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <select
                            id="selectedCollateralDoc"
                            class="form-control"
                            name="selectedCollateralDoc"
                            v-model="selectedCollateralDoc"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="document in WarehouseCollateralDocs"
                              :key="document.id"
                              :value="document.id"
                            >
                              {{ document.document_name }}
                            </option>
                          </select>
                        </td>
                        <td>
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="file"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              :disabled="selectedCollateralDoc === null"
                              :class="{
                                'is-invalid':
                                  selectedCollateralDocError ||
                                  (selectedCollateralDoc === null &&
                                    isSubmitted),
                              }"
                              v-on:change="handleFileUploadCollateral($event)"
                            />
                            <small>
                              <p style="background-color: powderblue">
                                {{ selectedCollateralDocFileName }}
                              </p>
                            </small>
                            <div
                              class="invalid-feedback"
                              v-if="selectedCollateralDocError"
                            >
                              <span>
                                {{ selectedCollateralDocError }}
                              </span>
                            </div>
                            <div
                              class="invalid-feedback"
                              v-if="
                                selectedCollateralDoc === null && isSubmitted
                              "
                            >
                              <span> Document Name is not selected </span>
                            </div>
                            <label class="custom-file-label" for="file"
                              >Choose File</label
                            >
                          </div>
                        </td>
                        <td>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="selectedCollateralDocIssueDate"
                            id="selectedCollateralDocIssueDate"
                            placeholder="Select Date"
                            v-model="selectedCollateralDocIssueDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{
                              from: new Date(),
                            }"
                          ></datepicker>
                          <!-- <div
                            class="invalid-feedback"
                            v-if="selectedCollateralDocIssueDate === null"
                          >
                            <span>Issue Date is required </span>
                          </div> -->
                        </td>
                        <td>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="selectedCollateralDocExpiryDate"
                            id="selectedCollateralDocExpiryDate"
                            placeholder="Select Date"
                            v-model="selectedCollateralDocExpiryDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{
                              to: new Date(selectedCollateralDocIssueDate),
                            }"
                          ></datepicker>
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            id="selectedCollateralDocComments"
                            v-model="selectedCollateralDocComments"
                            :class="{
                              'is-invalid':
                                $v.selectedCollateralDocComments.$error,
                            }"
                          />
                          <div
                            v-if="$v.selectedCollateralDocComments.$error"
                            class="invalid-feedback"
                          >
                            <span
                              v-if="!$v.selectedCollateralDocComments.maxLength"
                              >Max length of 100 characters exceeded</span
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onCollateralDocSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
    <!-- ./modal start -->
    <div class="modal fade" id="employee">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              Employee Details - {{ selectedCompanyName }}
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Employee Name</th>
                        <th></th>
                      </tr>
                      <tr
                        v-for="(emp, index) in selectedCMEmployees"
                        :key="index"
                      >
                        <td v-if="emp.employee_name">
                          {{ emp.employee_name }}
                        </td>
                        <td v-else>
                          <select
                            id="employee_name"
                            class="form-control"
                            name="employee_name"
                            v-model="emp.employee_name"
                            @change="checkDuplicateEmployee(emp.employee_name)"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="comp in collateralEmployees"
                              :key="comp.id"
                            >
                              {{ comp.employee_name }}
                            </option>
                          </select>
                        </td>
                        <td
                          @click="deleteEmployeeMapping(emp.id, index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-primary sh"
                    @click="addEmployee()"
                  >
                    Add
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    @click="onEmployeeSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
    <!-- ./modal start -->
    <div class="modal fade" id="view_doc">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-eye-alt"></i> View Document
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="col-3">Document Name</th>
                        <th class="col-3">Issue Date</th>
                        <th class="col-3">Expiry Date</th>
                        <th class="col-3">Comments</th>
                        <th class="col-1">Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="doc in selectedCollateralDocumentsList"
                        :key="doc.id"
                      >
                        <td class="col-3">{{ doc.WarehouseDocName }}</td>
                        <td v-if="doc.IssueDate" class="col-3">
                          {{ doc.IssueDate }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="doc.ExpiryDate" class="col-3">
                          {{ doc.ExpiryDate }}
                        </td>
                        <td v-else>-</td>
                        <td
                          v-if="doc.comments"
                          class="col-3"
                          style="word-wrap: break-word; max-width: 250px"
                        >
                          {{ doc.comments }}
                        </td>
                        <td v-else>-</td>
                        <td
                          class="center col-1"
                          v-on:click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-download text-info"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <button
                type="button"
                class="btn-sm btn btn-dark sh"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { maxLength } from "vuelidate/lib/validators";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
export default {
  data() {
    return {
      id: null,
      warehouse: [],
      WarehouseCMCompanies: [],
      WarehouseCMEmployees: [],
      SiteInspectionDetails: [],
      WarehouseCollateralDocs: [],
      warehouseDocuments: [],
      warehouseAdminDocuments: [],
      warehouseAdminCollateralDocuments: [],
      warehouseCollateralAdminDocuments: [],
      selectedCMEmployees: [],
      collateralEmployees: [],
      selectedCompanyName: null,
      selectedCollateralDocumentsList: [],
      selectedCollateralManager: null,
      selectedWarehouseCMMappingId: null,
      // Collateral Doc
      CollateralDocFile: null,
      selectedCollateralDoc: null,
      selectedCollateralDocFileName: null,
      selectedCollateralDocFile: null,
      selectedCollateralDocIssueDate: null,
      selectedCollateralDocExpiryDate: null,
      selectedCollateralDocComments: null,
      selectedCollateralDocError: null,
      isSubmitted: false,
      commentDocId: null,
      commentType: null,
      comment: null,
      inactive: false,
    };
  },
  validations: {
    comment: {
      maxLength: maxLength(100),
    },
    selectedCollateralDocComments: {
      maxLength: maxLength(100),
    },
  },
  components: {
    Datepicker,
  },

  methods: {
    showAddCollateralDocModal() {
      $("#add_doc").modal("show");
    },

    showCollateralDocumentsModal(companyId) {
      this.selectedCollateralDocumentsList =
        this.warehouseAdminCollateralDocuments.filter(
          (doc) => doc.collateral_manager_id == companyId
        );
      $("#view_doc").modal("show");
    },

    showEmployeeModal(id, companyId, companyName) {
      this.selectedCompanyName = companyName;
      this.selectedWarehouseCMMappingId = id;
      this.selectedCollateralManager = companyId;
      // this function is used to get disputes for a particular collateral manager
      Utils.start_loading();
      const path = "employee-details/" + companyId;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            if (res.data.collateral_employees.length)
              this.collateralEmployees = res.data.collateral_employees;
            console.log("get Collateral Employee details is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });

      // shows list of selected employee for a specific company
      this.selectedCMEmployees = this.WarehouseCMEmployees.filter((emp) => {
        if (emp.warehouse_cm_mapping_id === id) return emp;
      });
      $("#employee").modal("show");
    },

    showCommentModal(comm, docId, type) {
      this.commentDocId = docId;
      this.commentType = type;
      //this function opens the comment modal.
      this.comment = comm;
      $("#comm").modal("show");
    }, //showCommentModal() close

    saveComment() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      //this function saves the comment from comment modal.
      this.$confirm({
        auth: false,
        message: "Confirm submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            const path = "single-warehouse-summary-overview-active/" + this.id;
            axios
              .put(
                path,
                {
                  filtertype: "WarehouseComment",
                  updateType: this.commentType,
                  comments: this.comment,
                  docId: this.commentDocId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 9,
                  tabId: 26,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                console.log("comment update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert("success", "Comment updated successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(() => this.$router.go(), 1500);
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          }
        },
      });
    },

    addEmployee() {
      this.selectedCMEmployees.push({
        collateral_employee_id: null,
        employee_id: null,
        employee_name: null,
        id: null,
        warehouse_cm_mapping_id: this.selectedWarehouseCMMappingId,
      });
    },

    checkDuplicateEmployee(employee) {
      let count = 0;
      this.selectedCMEmployees.map((emp) => {
        if (emp.employee_name === employee) count++;
      });
      if (count > 1) {
        this.selectedCMEmployees.pop();
        Utils.make_alert("warning", "Employee Already exists");
      }
    },

    onEmployeeSubmit() {
      const path = "single-warehouse-summary-overview-active/" + this.id;
      axios
        .put(
          path,
          {
            filtertype: "WarehouseEmployeeMapping",
            employees: this.selectedCMEmployees,
            collateraManagerID: this.selectedCollateralManager,
            clientId: this.$store.state.client_id,
            userId: this.$store.getters.getuserId,
            moduleId: 9,
            tabId: 26,
          },
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            Utils.make_alert(
              "success",
              "Employee Mapping updated successfully!"
            );
            //if you want to send any data into server before redirection then you can do it here
            setTimeout(() => this.$router.go(), 1500);
          } else {
            Utils.make_alert("warning", "Something went wrong!");
          }
        })
        .catch((error) => {
          console.error("error: " + error);
          Utils.make_alert(
            "warning",
            error.response.status === 400
              ? error.response.data.status
              : "Something went wrong!"
          );
        });
    },

    deleteEmployeeMapping(id, i) {
      if (id === null) {
        this.selectedCMEmployees.splice(i, 1);
      } else {
        //this function is called when document needs to be deleted from backend database.
        const path = "single-warehouse-summary-overview-active/" + this.id;
        this.$confirm({
          auth: false,
          message: "Are you sure you want to permanently delete?",
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              Utils.start_loading();
              axios
                .delete(path, {
                  data: {
                    deleteType: "EmployeeMapping",
                    id: id,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 9,
                    tabId: 26,
                  },
                  headers: { "Content-type": "application/json" },
                })
                .then((res) => {
                  Utils.stop_loading();
                  if (res.data.status == "success") {
                    Utils.make_alert("success", "Record deleted");
                    this.selectedCMEmployees.splice(i, 1);
                    setTimeout(() => this.$router.go(), 1500);
                  } else {
                    Utils.make_alert("warning", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.error("error: " + error);
                  Utils.make_alert("warning", "Something went wrong!");
                });
            }
          },
        });
      }
    },

    onCollateralDocSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.selectedCollateralDocIssueDate != null) {
        this.selectedCollateralDocIssueDate = moment(
          this.selectedCollateralDocIssueDate
        ).format("MM-DD-YYYY");
      }
      if (this.selectedCollateralDocExpiryDate != null) {
        this.selectedCollateralDocExpiryDate = moment(
          this.selectedCollateralDocExpiryDate
        ).format("MM-DD-YYYY");
      }
      //initialize the form data
      let formData = new FormData();
      //append warehouse information to formdata
      formData.append("collateralDoc", this.selectedCollateralDoc);
      formData.append("collateralDocFile", this.selectedCollateralDocFile);
      formData.append(
        "collateralDocFileName",
        this.selectedCollateralDocFileName
      );
      formData.append(
        "collateralDocIssueDate",
        this.selectedCollateralDocIssueDate
      );
      formData.append(
        "collateralDocExpiryDate",
        this.selectedCollateralDocExpiryDate
      );
      formData.append(
        "collateralDocComments",
        this.selectedCollateralDocComments
      );
      formData.append("collateralManagerId", this.loggedInUserSubGroupId);
      formData.append("warehouseId", this.id);
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", JSON.stringify(this.$store.getters.getuserId));
      formData.append("moduleId", 9);
      formData.append("tabId", 26);
      const path = "single-warehouse-summary-overview-active/" + this.id;
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          console.log("status: " + response.data.status);
          if (response.data.status == "success") {
            Utils.make_alert("success", "Document added successfully!");
            setTimeout(() => {
              this.$router.go();
            }, 1500);
            console.log("SUCCESS!!");
          } else {
            Utils.make_alert("warning", "Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          Utils.make_alert(
            "warning",
            error.response.status === 400
              ? error.response.data.status
              : "Something went wrong!"
          );
          console.error(error);
        });
    },

    onChangeDocStatus(
      updatedStatus,
      docId,
      prevStatus,
      index,
      filtertype,
      updateType
    ) {
      //this function is called when user changes the document status. The status is updated in the database for that specific document.
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path = "single-warehouse-summary-overview-active/" + this.id;
            if (filtertype === "WarehouseDocStatus") {
              axios
                .put(
                  path,
                  {
                    filtertype: filtertype,
                    updateType: updateType,
                    warehouse_id: this.id,
                    warehouse_document_id: docId,
                    updatedStatus: updatedStatus.target.value,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 9,
                    tabId: 26,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("kyc update status: " + res.data.status);
                  if (res.data.status == "success") {
                    if (updateType === "Document")
                      this.warehouseDocuments[index].document_status =
                        updatedStatus.target.value;
                    else if (updateType === "CollateralDocument")
                      this.warehouseCollateralAdminDocuments[
                        index
                      ].document_status = updatedStatus.target.value;
                    Utils.make_alert(
                      "success",
                      "Document Status updated successfully!"
                    );
                    //if you want to send any data into server before redirection then you can do it here
                  } else {
                    updatedStatus.target.value = prevStatus;
                    Utils.make_alert("warning", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  updatedStatus.target.value = prevStatus;
                  console.error("error: " + error);
                  Utils.make_alert("warning", "Something went wrong!");
                });
            } else if (filtertype === "WarehouseSiteDateStatus") {
              axios
                .put(
                  path,
                  {
                    filtertype: filtertype,
                    updateType: "status",
                    warehouse_id: this.id,
                    id: docId,
                    updatedStatus: updatedStatus.target.value,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 9,
                    tabId: 26,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("kyc update status: " + res.data.status);
                  if (res.data.status == "success") {
                    this.SiteInspectionDetails[index].document_status =
                      updatedStatus.target.value;
                    Utils.make_alert(
                      "success",
                      "Site Inspection Status updated successfully!"
                    );
                    setTimeout(() => {
                      this.$router.go();
                    }, 1500);
                    //if you want to send any data into server before redirection then you can do it here
                  } else {
                    updatedStatus.target.value = prevStatus;
                    Utils.make_alert("warning", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  updatedStatus.target.value = prevStatus;
                  console.error("error: " + error);
                  Utils.make_alert("warning", "Something went wrong!");
                });
            }
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeDocStatus close

    getWarehouse() {
      //this function is called on page load and created events. it will show details on Warehouse with Warehouseid equal to this.id
      const path = this.inactive
        ? "single-warehouse-summary-overview-inactive/" + this.id
        : "single-warehouse-summary-overview-active/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.warehouse = this.inactive
              ? res.data.warehouse.warehouse_details_inactive[0]
              : res.data.warehouse.warehouse_active_details[0];
            if (this.warehouse.registration_date != '-' && this.warehouse.registration_date != null) {
              this.warehouse.registration_date = moment(this.warehouse.registration_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.warehouse.registration_date = '-';
            }
            if (this.warehouse.site_inspection_date != '-' && this.warehouse.site_inspection_date != null) {
              this.warehouse.site_inspection_date = moment(this.warehouse.site_inspection_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.warehouse.site_inspection_date = '-';
            }
            if (this.inactive) {
              if (res.data.warehouse.warehouse_cm_mapping_inactive.length) {
                this.WarehouseCMCompanies =
                  res.data.warehouse.warehouse_cm_mapping_inactive;
              }
              if (res.data.warehouse.warehouse_cm_emp_mapping_inactive.length) {
                this.WarehouseCMEmployees =
                  res.data.warehouse.warehouse_cm_emp_mapping_inactive;
              }
              if (res.data.warehouse.site_inspection_details_inactive.length) {
                this.SiteInspectionDetails =
                  res.data.warehouse.site_inspection_details_inactive.slice(
                    0,
                    3
                  );
              }
              if (
                res.data.warehouse.warehouse_admin_cm_documents_details_inactive
                  .length
              ) {
                this.warehouseAdminCollateralDocuments =
                  res.data.warehouse.warehouse_admin_cm_documents_details_inactive;
              }
              if (
                res.data.warehouse.warehouse_documents_details_inactive.length
              ) {
                this.warehouseDocuments =
                  res.data.warehouse.warehouse_documents_details_inactive;
              }
              if (
                res.data.warehouse.warehouse_admin_documents_details_inactive
                  .length
              ) {
                this.warehouseAdminDocuments =
                  res.data.warehouse.warehouse_admin_documents_details_inactive;
              }
              if (
                res.data.warehouse.warehouse_cm_admin_documents_details_inactive
                  .length
              ) {
                this.warehouseCollateralAdminDocuments =
                  res.data.warehouse.warehouse_cm_admin_documents_details_inactive;
              }
            } else {
              if (res.data.warehouse.warehouse_cm_mapping_active.length) {
                this.WarehouseCMCompanies =
                  res.data.warehouse.warehouse_cm_mapping_active;
              }
              if (res.data.warehouse.warehouse_cm_emp_mapping_active.length) {
                this.WarehouseCMEmployees =
                  res.data.warehouse.warehouse_cm_emp_mapping_active;
              }
              if (this.loggedInUserGroupId === "Collateral Manager") {
                if (
                  res.data.warehouse.site_inspection_cm_details_active.length
                ) {
                  this.SiteInspectionDetails =
                    res.data.warehouse.site_inspection_cm_details_active.slice(
                      0,
                      3
                    );
                  this.SiteInspectionDetails =
                    this.SiteInspectionDetails.filter(
                      (col) =>
                        col.collateral_manager_master_id.toString() ===
                          this.loggedInUserSubGroupId &&
                        col.site_inspection_permission === 0
                    );
                }
              } else {
                if (res.data.warehouse.site_inspection_active_details.length) {
                  this.SiteInspectionDetails =
                    res.data.warehouse.site_inspection_active_details.slice(
                      0,
                      3
                    );
                }
              }
              if (
                res.data.warehouse.warehouse_admin_cm_documents_details_active
                  .length
              ) {
                this.warehouseAdminCollateralDocuments =
                  res.data.warehouse.warehouse_admin_cm_documents_details_active;
              }
              if (
                res.data.warehouse.warehouse_documents_active_details.length
              ) {
                this.warehouseDocuments =
                  res.data.warehouse.warehouse_documents_active_details;
              }
              if (
                res.data.warehouse.warehouse_admin_documents_active_details
                  .length
              ) {
                this.warehouseAdminDocuments =
                  res.data.warehouse.warehouse_admin_documents_active_details;
              }
              if (
                res.data.warehouse.warehouse_cm_admin_documents_details_active
                  .length
              ) {
                this.warehouseCollateralAdminDocuments =
                  res.data.warehouse.warehouse_cm_admin_documents_details_active;
              }
            }

            if(this.warehouseAdminCollateralDocuments.length > 0) {
              this.warehouseAdminCollateralDocuments.forEach(item => {
              if (item.IssueDate != '-' && item.IssueDate != null) {
                item.IssueDate = moment(item.IssueDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.IssueDate = '-';
              }
              if (item.ExpiryDate != '-' && item.ExpiryDate != null) {
                item.ExpiryDate = moment(item.ExpiryDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.ExpiryDate = '-';
              }
              });
            }
            
            if(this.SiteInspectionDetails.length > 0) {
              this.SiteInspectionDetails.forEach(item => {
              if (item.site_inspection_date != '-' && item.site_inspection_date != null) {
                item.site_inspection_date = moment(item.site_inspection_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.site_inspection_date = '-';
              }
              });
            }

            if(this.warehouseDocuments.length > 0) {
              this.warehouseDocuments.forEach(item => {
              if (item.IssueDate != '-' && item.IssueDate != null) {
                item.IssueDate = moment(item.IssueDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.IssueDate = '-';
              }
              if (item.ExpiryDate != '-' && item.ExpiryDate != null) {
                item.ExpiryDate = moment(item.ExpiryDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.ExpiryDate = '-';
              }
              });
            }

            if(this.warehouseAdminDocuments.length > 0) {
              this.warehouseAdminDocuments.forEach(item => {
              if (item.IssueDate != '-' && item.IssueDate != null) {
                item.IssueDate = moment(item.IssueDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.IssueDate = '-';
              }
              if (item.ExpiryDate != '-' && item.ExpiryDate != null) {
                item.ExpiryDate = moment(item.ExpiryDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.ExpiryDate = '-';
              }
              });
            }

            if(this.warehouseCollateralAdminDocuments.length > 0) {
              this.warehouseCollateralAdminDocuments.forEach(item => {
              if (item.IssueDate != '-' && item.IssueDate != null) {
                item.IssueDate = moment(item.IssueDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.IssueDate = '-';
              }
              if (item.ExpiryDate != '-' && item.ExpiryDate != null) {
                item.ExpiryDate = moment(item.ExpiryDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.ExpiryDate = '-';
              }
              });
            }
            
            if (this.WarehouseCMCompanies.length)
              if (this.loggedInUserGroupId === "Collateral Manager") {
                this.WarehouseCMCompanies = this.WarehouseCMCompanies.filter(
                  (col) =>
                    col.collateral_manager_master_id.toString() ===
                    this.loggedInUserSubGroupId
                );
              }
            console.log("get Warehouse details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getWarehouse close

    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },
    //handles a change on the file upload
    handleFileUploadCollateral(e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.CollateralDocFile = e.target.files[0];
        //check for filesize. if it is larger than threshold (100 MB) then return following error response
        if (this.CollateralDocFile.size > 104857600) {
          e.preventDefault();
          self.selectedCollateralDocError =
            "File size should be less than 100MB";
          self.selectedCollateralDocFile = null;
          self.selectedCollateralDocFileName = null;
          return;
        }
        //save file details
        reader.readAsDataURL(this.CollateralDocFile);
        reader.onload = function () {
          console.log("heree", e.target.files[0].name);
          self.selectedCollateralDocFile = reader.result;
          self.selectedCollateralDocFileName = e.target.files[0].name;
          self.selectedCollateralDocError = "";
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadCollateral() close
    getWarehouseCollateralDocuments() {
      const path = "warehouse-collateral-document";
      axios
        .get(path)
        .then((res) => {
          this.WarehouseCollateralDocs = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getWarehouseCollateralDocuments() close
  },
  mounted() {
    this.inactive =
      this.$store.state.inactive === "warehouse_inactive" ? true : false;
    this.id = this.$route.params.id;
    this.getWarehouse();
    this.getWarehouseCollateralDocuments();
  },
  computed: {
    loggedInUserFullName() {
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style>
textarea {
  border-style: none;
  border-color: Transparent;
  overflow: auto;
}
th,
td {
  text-align: left;
}
#buttons {
  padding-inline: 15px;
}
.center {
  text-align: center;
}
.right {
  text-align: right !important;
}
</style>
