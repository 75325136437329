<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          Repayment Overview
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                            <!-- <a href="repayment.html">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                            </a>-->
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover table-head-fixed text-nowrap">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Repayment ID:</td>
                        <td v-if="repayment.id !== null" class>
                          {{ repayment.id }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Borrower Name:</td>
                        <td v-if="repayment.borrower_name !== null" class>
                          {{ repayment.borrower_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <tr>
                        <td class="wg">Facility ID:</td>
                        <td v-if="repayment.facility_registration_id !== null" class>
                          {{ repayment.facility_registration_id }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Offtaker Name:</td>
                        <td v-if="repayment.offtaker_name !== null" class>
                          {{ repayment.offtaker_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Contract No.:</td>
                        <td v-if="repayment.contract_number !== null" class>
                          {{ repayment.contract_number }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Partial Contract No.:</td>
                        <td v-if="repayment.partial_contract_number !== null" class>
                          {{ repayment.partial_contract_number }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Quantity:</td>
                        <td v-if="repayment.contract_qty !== null" class>
                          {{ repayment.contract_qty }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Unit:</td>
                        <td v-if="repayment.unit_name !== null" class>
                          {{ repayment.unit_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Repayment Amount:</td>
                        <td v-if="repayment.overall_repayment_amount !== null" class>
                          {{ repayment.overall_repayment_amount }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Repayment Currency:</td>
                        <td v-if="repayment.repayment_currency_short_name !== null" class>
                          {{ repayment.repayment_currency_short_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Conversion Date:</td>
                        <td v-if="repayment.repayment_date !== null" class>
                          {{ repayment.repayment_date }}
                        </td>
                        <td v-else class="center">-</td>
                      </tr>
                      <!----------------------------------------------->
                      <!-- <tr>
                        <td class="wg">Conversion Rate:</td>
                        <td v-if="repayment.conversion_rate !== null" class>
                          {{ repayment.conversion_rate }}
                        </td>
                        <td v-else class>-</td>
                      </tr> -->
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Fund Currency:</td>
                        <td v-if="repayment.fund_currency_short_name !== null" class>
                          {{ repayment.fund_currency_short_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!-- ------------------------------------------- -->
                      <tr>
                        <td class="wg">Converted Repayment Amount:</td>
                        <td v-if="repayment.overall_repayment_converted_amount !== null" class>
                          {{ repayment.overall_repayment_converted_amount }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <!-- <i class="icofont-spinner-alt-3"></i>  -->
                          Bank Details
                        </h1>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover table-head-fixed text-nowrap">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Beneficiary Bank:</td>
                        <td v-if="bank.bank_name !== null" class>
                          {{ bank.bank_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Bank Identifier Code:</td>
                        <td v-if="bank.bic !== null" class>
                          {{ bank.bic }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">IBAN (International Bank Account No):</td>
                        <td
                          v-if="bank.iban !== null"
                          class
                        >
                          {{ bank.iban }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Account No. :</td>
                        <td
                          v-if="bank.account_number !== null"
                          class
                        >
                          {{ bank.account_number }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Account Name:</td>
                        <td v-if="bank.account_name !== null" class>
                          {{ bank.account_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Reference:</td>
                        <td v-if="bank.reference !== null" class>
                          {{ bank.reference }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documentation</h1>
                      </div>
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <table class="table table-head-fixed text-nowrap table-hover">
                      <thead>
                        <tr>
                          <th>Document</th>
                          <th>Comment</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(cp) in repaymentDocumentsDetails"
                          :key="cp.id"
                        >
                          <td>
                          <span v-on:click="downloadDIsbursementDocument(cp.document_path)"
                            :style="{ cursor: 'pointer' }" >
                            <i class="icofont-download text-info" style="padding-right: 10px;"></i>
                            {{ cp.display_file_name }}
                          </span>
                          </td>
                          <td v-if="cp.comments !== null">
                            {{ cp.comments }}
                          </td>
                          <td v-else>-</td>
                        </tr>
                        <!----------------------------------------------->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Loan Mapping</h1>
                      </div>
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <table
                      class="table table-head-fixed text-nowrap table-hover"
                    >
                      <thead>
                        <tr>
                          <th title="Loan Registration ID">Loan Regi. ID</th>
                          <th title="Repayment Date">Repayment Date</th>
                          <th title="Repayment Amount (Fund Currency)">Repayment Amt.(Fund Currency)</th>
                          <th title="Loan Currency">Loan Currency</th>
                          <th title="Converted Repayment Amount (Loan Currency)">Conv. Repayment Amt.(Loan Currency)</th>
                          <!-- <th title="Outstanding Amount(Fund Currency)">Outstanding Amt.(Fund Currency)</th> -->
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="loan in loanMappingList"
                          :key="loan.id"
                        >
                          <td v-if="loan.loan_registration_id !== null">
                            {{ loan.loan_registration_id }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="loan.repayment_date !== null">
                            {{ loan.repayment_date }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="loan.repayment_amount !== null">
                            {{ loan.repayment_amount }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="loan.loan_currency_short_name !== null">
                            {{ loan.loan_currency_short_name }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="loan.loan_converted_repayment_amount !== null">
                            {{ loan.loan_converted_repayment_amount }}
                          </td>
                          <td v-else>-</td>
                          <!-- <td v-if="loan.transaction_level_outstanding_amount !== null">
                            {{ loan.transaction_level_outstanding_amount }}
                          </td>
                          <td v-else>-</td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Bill Of Lading</h1>
                      </div>
                      <!-- <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="showBillOfLadingModal()"
                              :disabled="BillOfLadingArray.length == 0 || RepaymentConvertedAmountToBeMapped == 0"
                            >
                              <i class="icofont-plus"></i> Add Bill Of Lading
                            </button>
                          </li>
                        </ul>
                      </div> -->
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <!------menton here style="height: 300px;"-------->
                    <table class="table table-head-fixed text-nowrap table-hover">
                      <thead>
                        <tr>
                          <th>BOL No.</th>
                          <th>Shipping ID</th>
                          <th>Repayment Quantity (MT)</th>
                          <th>Repayment Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(cp) in billOfLading"
                          :key="cp.id"
                        >
                          <td v-if="cp.bol_registration_no !== null">
                            {{ cp.bol_registration_no }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="cp.shipping_registration_id !== null">
                            {{ cp.shipping_registration_id }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="cp.bol_qty !== null">
                            {{ cp.bol_qty }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="cp.bol_amount !== null">
                            {{ cp.bol_amount }}
                          </td>
                          <td v-else>-</td>
                        </tr>
                        <!----------------------------------------------->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
          
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
      <div class="modal fade" id="view_doc">
        <div class="modal-dialog mw-100 w-60 custom-width">
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">
                Bill Of Lading
              </h4>
              <div style = "margin-left: 10px;" class="">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn btn-sm sh_n btn-success"
                      @click="addBillOfLading()"
                      :disabled="billOfLadingAddMoreDisabled"
                    >
                      <i class="icofont-plus"></i> Add More
                    </button>
                  </li>
                </ul>
              </div>
              <button type="button" class="close" data-dismiss="modal">
                <i class="icofont-close-circled"></i>
              </button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="card-body p-0">
                    <div>
                      <p>
                        <span style="font-size: 14px; color: red">
                        Available repayment amount to map is {{this.RepaymentConvertedAmountToBeMapped}} {{ this.fundCurrencyShortName }}.</span>
                      </p>
                    </div>
                    <!------menton here style="height: 300px;"-------->
                    <table
                        class="table table-head-fixed text-nowrap table-hover"
                      >
                        <thead>
                          <tr>
                            <th>BOL No.</th>
                            <th>Repayment Quantity (MT)</th>
                            <th>Repayment Amount</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="(lm, index) in billOfLadingList"
                            :key="index"
                          > 
                            <td>
                                <!-- <label for="BOLNumber">BOL No.</label> -->
                                <select
                                  id="BOLNumber"
                                  class="form-control"
                                  v-model="lm.BOLNumber"
                                  @change="onChangeBOLNumber(index)"
                                >
                                  <option :value="null">--- Select ---</option>
                                  <option
                                    v-for="b in BillOfLadingArray"
                                    :value="b.bill_of_landing_number"
                                    :key="b.bill_of_landing_number"
                                  >
                                    {{ b.bill_of_landing_number }}
                                  </option>
                                </select>
                            </td>

                            <td>
                                <!-- <label for="BOLQuantity">Quantity</label> -->
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="lm.BOLQuantity"
                                  id="BOLQuantity"
                                  disabled
                                />
                            </td>

                            <td>
                                <!-- <label for="BOLRepaymentAmount">Repayment Amount</label> -->
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="lm.BOLRepaymentAmount"
                                  id="BOLRepaymentAmount"
                                  @keypress="isDecimalTwoFixed($event)"
                                  @paste="truncateDecimalPlacesUptoTwo($event)"
                                  @keyup="onChangeBOLRepaymentAmountValidation(index)"
                                  :class="{
                                    'is-invalid': (lm.BOLRepaymentAmountError) ||
                                    (isSubmitted &&
                                    $v.billOfLadingList.$each[index]
                                    .BOLRepaymentAmount.$error)
                                  }"
                                  :disabled="!lm.BOLNumber"
                                />
                                <div
                                  v-if="(lm.BOLRepaymentAmountError) ||
                                    (isSubmitted &&
                                    $v.billOfLadingList.$each[index].BOLRepaymentAmount
                                      .$error)
                                  "
                                  class="invalid-feedback"
                                >
                                  <span
                                  class="error-message"
                                    v-if="
                                      (lm.BOLRepaymentAmountError)
                                    "
                                    >{{ lm.BOLRepaymentAmountErrorMessage }}<br/></span
                                  >
                                  <span
                                    v-if="
                                      !$v.billOfLadingList.$each[index]
                                        .BOLRepaymentAmount.maxLength
                                    "
                                    >Max length of 15 digits exceeded.<br/></span
                                  >
                                </div>
                            </td>

                            <td>
                                <div
                                  v-on:click="deleteBillOfLading(index)"
                                  :style="{ cursor: 'pointer','margin-top':'5px' }"
                                >
                                  <i class="icofont-trash text-danger"></i>
                                </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer">
              <div class="mx-auto">
                <router-link :to="{ name: 'RepaymentOverview' }">
                  <button
                    type="button"
                    name="saveInfo"
                    class="btn-sm btn btn-primary sh"
                    style="margin-right: 10px;"
                    v-on:click="onBillOfLadingSaveInfo()"
                    :disabled="billOfLadingSaveInfoDisabled"
                  >
                    Save Info
                  </button>
                </router-link>
                <router-link :to="{ name: 'RepaymentOverview' }">
                  <button
                    type="button"
                    name="submit"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";
import moment from "moment";


export default {
  data() {
    return {
      id: null,
      // tabIndex: 0,
      // tabs: ["#one", "#two", "#three"],
      repayment: [],
      bank: [],
      repaymentId: null,
      repaymentDocumentsDetails: [],
      loanMappingList: [],
      billOfLading: [],
      fundCurrencyShortName: '',
      BillOfLadingArray: [],
      billOfLadingList: [],
      billOfLadingAddMoreDisabled:false,
      billOfLadingSaveInfoDisabled: false,
      RepaymentConvertedAmount: null,
      RepaymentConvertedAmountToBeMapped: null,
      totalBOLRepaymentAmount: 0,
      isSubmitted: false,
    };
  },
  components: {
    Datepicker,
  },
  validations: {
    billOfLadingList: {
      $each: {
        BOLQuantity: {
          maxLength : maxLength(15),
        },
        BOLRepaymentAmount: {
          maxLength : maxLength(15),
        },
      },
    },
  },
  methods: {

    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      let inputValue = e.target.value;
      let decimalIndex = inputValue.indexOf(".");
      
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else if (decimalIndex !== -1 && inputValue.substring(decimalIndex + 1).length >= 2)
        return e.preventDefault();
      else return true;
    },

    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },

    downloadDIsbursementDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },

    onChangeBOLNumber(index) {
      console.log("onChangeBOLNumber")
      this.isLoanDetailsSubmitted = true;
      if(this.billOfLadingList[index].BOLNumber == null){
        this.billOfLadingList[index].BOLRepaymentAmountError = false;
        this.billOfLadingList[index].BOLRepaymentAmountErrorMessage = ``;
        this.deleteBillOfLading(index);
        return;
      }
      let bol_count = this.billOfLadingList.filter(
        (col) => col.BOLNumber === this.billOfLadingList[index].BOLNumber
        ).length;
      console.log("bol_count",bol_count)

      // delete if existing bol is found
      if (bol_count > 1) {
        this.deleteBillOfLading(index);
        Utils.make_alert("warning", "Bill Of Lading already exists");
      } else {
        let BOLArray = this.BillOfLadingArray.find(bol => bol.bill_of_landing_number === this.billOfLadingList[index].BOLNumber)
        console.log("BOLArray", BOLArray)
        this.billOfLadingList[index].ShippingID = BOLArray.id;
        this.billOfLadingList[index].BOLQuantity = BOLArray.release_quantity;
        this.billOfLadingList[index].BOLNumberError = false;
        this.billOfLadingList[index].BOLRepaymentAmountError = true;
        this.billOfLadingList[index].BOLRepaymentAmountErrorMessage = `Repayment Amount is required`;
      }
    },

     // Assuming onChange for BOLRepaymentAmount
    onChangeBOLRepaymentAmountValidation(index) {
      console.log("onChangeBOLRepaymentAmountValidation")
      // Access the values of BOLNumber, BOLRepaymentQuantity, and BOLRepaymentAmount for the given index
      const newBOLRepaymentAmount = this.billOfLadingList[index].BOLRepaymentAmount;

      // Check if BOLRepaymentAmount is not null or empty
      if (newBOLRepaymentAmount == '' || newBOLRepaymentAmount == 0 || newBOLRepaymentAmount == null) {
        this.billOfLadingList[index].BOLRepaymentAmountError = true;
        this.billOfLadingList[index].BOLRepaymentAmountErrorMessage = `Repayment Amount is required`;
        return
      } else {
        this.billOfLadingList[index].BOLRepaymentAmountError = false;
        this.billOfLadingList[index].BOLRepaymentAmountErrorMessage = ``;
      }

      if(Number(newBOLRepaymentAmount) > Number(this.RepaymentConvertedAmountToBeMapped)){
          this.billOfLadingList[index].BOLRepaymentAmountError = true;
          this.billOfLadingList[index].BOLRepaymentAmountErrorMessage = `Cannot exceed the avail. repayment \n amt. ${this.RepaymentConvertedAmountToBeMapped}.`;
          return;
        } else {
          this.billOfLadingList[index].BOLRepaymentAmountError = false;
          this.billOfLadingList[index].BOLRepaymentAmountErrorMessage = "";
          return;
        }
    },

    onBillOfLadingSaveInfo() {
      let flag = false;
      // Check if any object has a null field and set error to true
      this.billOfLadingList.forEach(item => {
        if(item.BOLRepaymentAmount == null || item.BOLRepaymentAmount == 0 || item.BOLRepaymentAmount == ''){
          item.BOLRepaymentAmountError = true;
          item.BOLRepaymentAmountErrorMessage = `Repayment Amount is required`;
          flag = true;
          return;
        }
        if(Number(item.BOLRepaymentAmount) > Number(this.RepaymentConvertedAmountToBeMapped)) {
          item.BOLRepaymentAmountError = true;
          item.BOLRepaymentAmountErrorMessage = `Cannot exceed the avail. repayment \n amt. ${this.RepaymentConvertedAmountToBeMapped}.`;
          flag = true;
          return;
        } else {
          item.BOLRepaymentAmountError = false;
          item.BOLRepaymentAmountErrorMessage = "";
          flag = false;
        }
      });

      if(!flag) {
        // Loop over the billOfLading array
        let alreadyTotalBOLRepaymentAmount = 0
        // for (let list in this.billOfLading) {
        //     alreadyTotalBOLRepaymentAmount += parseFloat(
        //       this.billOfLading[list].bol_amount || 0
        //     );
        //   }
          
          this.calcTotalBOLRepaymentAmount();
          let RepaymentConvertedAmount = parseFloat(this.RepaymentConvertedAmount)
          let finallyTotalBOLRepaymentAmount = alreadyTotalBOLRepaymentAmount + this.totalBOLRepaymentAmount 
          console.log("finallyTotalBOLRepaymentAmount", finallyTotalBOLRepaymentAmount)
          if (finallyTotalBOLRepaymentAmount > parseFloat(this.RepaymentConvertedAmountToBeMapped)) {
          // let warningMessage = `The Sum of Bill Of Lading Repayment Amount ${finallyTotalBOLRepaymentAmount} ${this.fundCurrencyShortName} should not be greater than Converted Repayment Amount ${this.RepaymentConvertedAmount} ${this.fundCurrencyShortName}`;
          let warningMessage = `The maximum Amount available to map is ${this.RepaymentConvertedAmountToBeMapped} ${this.fundCurrencyShortName}`;
          Utils.make_alert("warning", warningMessage);
          this.billOfLadingAddMoreDisabled = true;
        } else {
          Utils.make_alert("success", "Record saved.");
          this.billOfLadingSaveInfoDisabled = false;
          this.billOfLadingAddMoreDisabled = false;
        }
        this.totalBOLRepaymentAmount = 0;
      } else {
        // At least one object has error set to true, show error messages
        this.billOfLadingAddMoreDisabled = true;
      }
    },

    calcTotalBOLRepaymentAmount() {
        // Reset the total amount before calculating
        this.totalBOLRepaymentAmount = 0;
        // Loop over the billOfLadingList array
        for (let list in this.billOfLadingList) {
          // Log the current item for debugging
          // Check if BOLNumber is present
          if (this.billOfLadingList[list].BOLNumber) {
            // Only add to the total if BOLNumber is present
            this.totalBOLRepaymentAmount += parseFloat(
              this.billOfLadingList[list].BOLRepaymentAmount || 0
            );
          }
        }
      },



    deleteBillOfLading(index) {
      this.billOfLadingList.splice(index, 1);
      if (
        typeof this.billOfLadingList !== "undefined" &&
        this.billOfLadingList.length === 0
      ) {
        this.billOfLadingAddMoreDisabled = false;
        this.billOfLadingSaveInfoDisabled = true;
      }
    },

    getBOLByContractNumber(){
      const path = "/get-bol-by-contract-number/" + 
        this.repayment.offtaker_contract_id + 
        "/" + 
        this.repayment.offtaker_contract_partial_fill_id
      // const path = "/get-bol-by-contract-number/" + 'CN01'
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then(res => {
            Utils.stop_loading()
            if(res.data.status == "success"){
              this.BillOfLadingArray = res.data.bol_no;
              this.billOfLadingList = [];
              this.addBillOfLading();
            }
          })
          .catch(error => {
            Utils.stop_loading()
            console.error(error);
          });
    },


    // // this method will check if existing billOfLading mapping is illed again
    // onBillOfLadingSelect(i) {
    //   console.log(i)
    //   let billOfLading = this.newBillOfLadingMappingList.filter(
    //     (col) => col.selectedBillOfLading === this.newBillOfLadingMappingList[i].selectedBillOfLading)
    //   let selectedBillOfLading = billOfLading[0].selectedBillOfLading
    //   let new_billOfLading_count = this.newBillOfLadingMappingList.filter(
    //     (col) => col.selectedBillOfLading === selectedBillOfLading
    //   ).length;

    //   // delete if existing billOfLading is found
    //   if (new_billOfLading_count > 1) {
    //     this.deleteBillOfLading(i);
    //     Utils.make_alert("warning", "BillOfLading Mapping already exists");
    //     return
    //   }
    //   let billOfLading_count = this.billOfLadingMappingList.filter(
    //     (col) => col.billOfLading_id === selectedBillOfLading
    //   ).length;

    //   // delete if existing billOfLading is found
    //   if (billOfLading_count > 0) {
    //     this.deleteBillOfLading(i);
    //     Utils.make_alert("warning", "BillOfLading Mapping already exists");
    //     return
    //   }
      
    // },

    //this method will add new line for additional loan mapping
    addBillOfLading() {
      //when new line is added then billOfLadingAddMoreDisabled (Save info button disable property) should be re initialised to true.
        this.billOfLadingAddMoreDisabled = true;
        this.billOfLadingSaveInfoDisabled = false;
        //following block is created first time on mounted property
        this.billOfLadingList.push({
          BOLNumber: null,
          ShippingID: null,
          BOLQuantity: null,
          // BOLRepaymentQuantity :null,
          BOLRepaymentAmount: null,
          DBExistFlag: true,
          BOLNumberError:false,
          // BOLRepaymentQuantityError:false,
          BOLRepaymentAmountError:false,
          BOLRepaymentAmountErrorMessage:'',
        });
    }, //addBillOfLading() close

    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.billOfLadingList.map((doc, index) => {
        if (doc.BOLNumber === null) this.billOfLadingList.splice(index, 1);
      });
      if (this.billOfLadingList.length == 0) {
        let warningMessage = `Please Map Some Bill of Ladding`;
        this.billOfLadingList = []
        this.addBillOfLading();
        Utils.make_alert("warning", warningMessage);
        return;
      }

      let flag = false;
      // Check if any object has a null field and set error to true
      this.billOfLadingList.forEach(item => {
        if (item.BOLNumber) {
          if(item.BOLRepaymentAmount == null || item.BOLRepaymentAmount == 0 || item.BOLRepaymentAmount == ''){
            item.BOLRepaymentAmountError = true;
            item.BOLRepaymentAmountErrorMessage = `Repayment Amount is required`;
            flag = true;
            return;
          }
          if(Number(item.BOLRepaymentAmount) > Number(this.RepaymentConvertedAmountToBeMapped)) {
            item.BOLRepaymentAmountError = true;
            item.BOLRepaymentAmountErrorMessage = `Cannot exceed the avail. repayment \n amt. ${this.RepaymentConvertedAmountToBeMapped}.`;
            flag = true;
            return;
          } else {
            item.BOLRepaymentAmountError = false;
            item.BOLRepaymentAmountErrorMessage = "";
            flag = false;
          }
        }
      });

      console.log("flag",flag)

      if(!flag) {
        // Loop over the billOfLading array
        let alreadyTotalBOLRepaymentAmount = 0
        // for (let list in this.billOfLading) {
        //   if (this.billOfLading[list].bol_no) {
        //     alreadyTotalBOLRepaymentAmount += parseFloat(
        //       this.billOfLading[list].bol_amount || 0
        //     );
        //   }
        // }
          
        this.calcTotalBOLRepaymentAmount();
        let RepaymentConvertedAmount = parseFloat(this.RepaymentConvertedAmount)
        let finallyTotalBOLRepaymentAmount = alreadyTotalBOLRepaymentAmount + this.totalBOLRepaymentAmount 
        if (finallyTotalBOLRepaymentAmount > parseFloat(this.RepaymentConvertedAmountToBeMapped)) {
        // let warningMessage = `The Sum of Bill Of Lading Repayment Amount ${finallyTotalBOLRepaymentAmount} ${this.fundCurrencyShortName} should not be greater than Converted Repayment Amount ${this.RepaymentConvertedAmount} ${this.fundCurrencyShortName}`;
        let warningMessage = `The maximum Amount available to map is ${this.RepaymentConvertedAmountToBeMapped} ${this.fundCurrencyShortName}`;
        Utils.make_alert("warning", warningMessage);
        this.billOfLadingAddMoreDisabled = true;
        return;
        } else {
          this.billOfLadingSaveInfoDisabled = false;
          this.billOfLadingAddMoreDisabled = false;
        }
        this.totalBOLRepaymentAmount = 0;
      } else {
        // At least one object has error set to true, show error messages
        this.billOfLadingAddMoreDisabled = true;
        return
      }

      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("repaymentID", this.repaymentID);
      formData.append("billOfLadingList", JSON.stringify(this.billOfLadingList));

      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 4);
      formData.append("tabId", 35);
      const path = "/add-bol"
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          console.log("Status",response.data.status)
          if (response.data.status == "success") {
            Utils.make_alert(
              "success",
              "Bill Of Lading Added successfully!"
            );
            console.log("SUCCESS!!");
            this.$router.go(this.$router.currentRoute)
            } else {
            Utils.make_alert("success", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
          Utils.make_alert("success", "Something went wrong!");
        });
    }, //onSubmit() close

    showBillOfLadingModal() {
      this.billOfLadingList = []
      this.addBillOfLading();
      $("#view_doc").modal("show");
    },

    getRepaymentOverview() {
      Utils.start_loading();
      //this function is called on page load and created events. it will show details on repayment with repaymentid equal to this.id
      const path = "offtaker-repayment-overview/" + this.id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.repayment = res.data.repayment_overview.repayment[0];
            if (this.repayment.repayment_date != '-' && this.repayment.repayment_date != null) {
              this.repayment.repayment_date = moment(this.repayment.repayment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.repayment.repayment_date = '-';
            }
            if(this.repayment.overall_repayment_amount){
              this.RepaymentConvertedAmount = this.repayment.overall_repayment_amount.replaceAll(",", "");
            }
            if(this.repayment.fund_currency_short_name){
              this.fundCurrencyShortName = this.repayment.fund_currency_short_name;
            }
            this.bank = res.data.repayment_overview.repayment[0];
            this.repaymentDocumentsDetails = res.data.repayment_overview.repayment_transaction_document;
            this.loanMappingList = res.data.repayment_overview.repayment_transaction_loan_mapping;
            if(this.loanMappingList.length > 0) {
              this.loanMappingList.forEach(item => {
                if (item.repayment_date != '-' && item.repayment_date != null) {
                  item.repayment_date = moment(item.repayment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.repayment_date = '-';
                }
              });
            }
            this.billOfLading = res.data.repayment_overview.repayment_bill_of_ladding;
            if(this.billOfLading.length > 0){
              let total = this.billOfLading.reduce((sum, bol) => {
                  // Parsing the bol_amount string to a float
                  const amount = parseFloat(bol.bol_amount.replace(/,/g, ''));
                  // Adding the parsed amount to the sum, handling NaN with a fallback to 0
                  return sum + (isNaN(amount) ? 0 : amount);
                }, 0);
                console.log("total",total)
                this.RepaymentConvertedAmountToBeMapped = this.RepaymentConvertedAmount - total
              } else {
                this.RepaymentConvertedAmountToBeMapped = this.RepaymentConvertedAmount
            }
            console.log("RepaymentConvertedAmountToBeMapped",this.RepaymentConvertedAmountToBeMapped)
            // this.getBOLByContractNumber();

            console.log("get repayment details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/facility");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getRepaymentOverview close
  },

  mounted() {
    this.id = this.$route.params.id;
    this.inactive =
      this.$store.state.inactive === "repayment_inactive" ? true : false;
    this.getRepaymentOverview();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style scoped>

.error-message {
  white-space: pre-line;
}

.modal-dialog.custom-width {
  width: 50% !important;
  max-width: 800px !important;
}
.wg {
  font-weight: bold !important;
}

th,
td {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right !important;
}
</style>
