<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-file-document"></i> Warehouse CM
                        Uploaded Document History
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row"></div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table
                    class="table table-head-fixed text-nowrap myTable1"
                    id="myTable1"
                  >
                    <thead>
                      <tr>
                        <th onclick="sortTable('myTable1',  0)">
                          Document Name<i class="icofont-sort sortdd"></i>
                        </th>
                        <th onclick="sortTable('myTable1',  1)">
                          Uploading Date<i class="icofont-sort sortdd"></i>
                        </th>
                        <th onclick="sortTable('myTable1',  2)">
                          Expiry Date<i class="icofont-sort sortdd"></i>
                        </th>
                        <th class="c">Uploaded File</th>
                        <th
                          v-if="loggedInUserGroupId === 'Admin' && !inactive"
                        ></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="doc in warehouseCollateralAdminDocuments"
                        :key="doc.warehouse_document_id"
                      >
                        <td v-if="doc.WarehouseCMAdminDocName !== null">
                          {{ doc.WarehouseCMAdminDocName }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="doc.IssueDate !== null">
                          {{ doc.IssueDate }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="doc.ExpiryDate !== null" class>
                          {{ doc.ExpiryDate }}
                        </td>
                        <td v-else class>-</td>
                        <td
                          class="c"
                          v-on:click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <u>{{ doc.display_file_name }}</u>
                        </td>
                        <td
                          v-if="loggedInUserGroupId === 'Admin' && !inactive"
                          v-on:click="
                            deleteWarehouseCollateralDoc(
                              doc.warehouse_document_id
                            )
                          "
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.content-wrapper -->
</template>

<script>
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js";
import moment from "moment";

export default {
  data() {
    return {
      id: null,
      inactive: null,
      warehouseCollateralAdminDocuments: [],
    };
  },
  methods: {
    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },
    getWarehouse() {
      //this function is called on page load and created events. it will show details on Warehouse with Warehouseid equal to this.id
      const path = this.inactive
        ? "single-warehouse-summary-overview-inactive/" + this.id
        : "single-warehouse-summary-overview-active/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            if (this.inactive) {
              this.warehouseCollateralAdminDocuments =
                res.data.warehouse.warehouse_cm_admin_documents_history_details_inactive;
            } else {
              this.warehouseCollateralAdminDocuments =
                res.data.warehouse.warehouse_cm_admin_documents_history_details_active;
            }
            if(this.warehouseCollateralAdminDocuments.length > 0) {
              this.warehouseCollateralAdminDocuments.forEach(item => {
              if (item.IssueDate != '-' && item.IssueDate != null) {
                item.IssueDate = moment(item.IssueDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.IssueDate = '-';
              }
              if (item.ExpiryDate != '-' && item.ExpiryDate != null) {
                item.ExpiryDate = moment(item.ExpiryDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.ExpiryDate = '-';
              }
              });
            }
            console.log("get Warehouse details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getWarehouse close
    //this function is called when document needs to be deleted from backend database.
    deleteWarehouseCollateralDoc(id) {
      const path =
        "single-warehouse-summary-overview-active/" + this.$route.params.id;
      this.$confirm({
        auth: false,
        message: "Are you sure you want to permanently delete?",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete(path, {
                data: {
                  deleteType: "CollateralDocument",
                  id: id,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 9,
                  tabId: 26,
                },
                headers: { "Content-type": "application/json" },
              })
              .then((res) => {
                Utils.stop_loading();
                if (res.data.status == "success") {
                  Utils.make_alert("success", "Record deleted.");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(() => this.$router.go(), 1500);
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          }
        },
      });
    },
  },
  computed: {
    loggedInUserFullName() {
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
  mounted() {
    this.getWarehouse();
  },
  created() {
    this.id = this.$route.params.id;
    this.inactive =
      this.$store.state.inactive === "warehouse_inactive" ? true : false;
  },
};
</script>
