<!-- This is Logistics Tracker page. It shows list of all the Logistics Trackers and their details -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop" id="al_tb">
            <div class="tp_mid">
              <div class="row">
                <div class="col-lg-12">
                  <ul
                    class="nav nav-tabs"
                    id="custom-tabs-one-tab"
                    role="tablist"
                  >
                    <li class="nav-item" 
                    >
                      <a
                        class="nav-link active"
                        id="logistics-tab"
                        data-toggle="pill"
                        href="#one"
                        role="tab"
                        aria-selected="true"
                        style="cursor: default !important;"
                        >
                        <i class="icofont-delivery-time"></i>
                        Logistics Tracker
                        </a
                      >
                    </li>
                  </ul>
                </div>
                <!-- /.col -->
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <ul
              class="nav nav-tabs"
              id="custom-tabs-one-tab"
              role="tablist"
              style="border-bottom: 1px solid #f4f6f9"
            >
              <li class="nav-item ot_i">
                <a
                  class="ot_t nav-link  active"
                  id="release-to-offtaker-tab"
                  data-toggle="pill"
                  href="#one"
                  @click="setTab('release-to-offtaker-tab')"
                  role="tab"
                  aria-controls="custom-tabs-one-costs"
                  aria-selected="true"
                  >Release To Offtaker</a
                >
              </li>
              <li class="nav-item ot_i">
                <a
                  class="ot_t nav-link"
                  id="release-to-market-tab"
                  data-toggle="pill"
                  href="#two"
                  @click="setTab('release-to-market-tab')"
                  role="tab"
                  aria-controls="custom-tabs-one-cost-payments"
                  aria-selected="false"
                  >Release To Market</a
                >
              </li>
            </ul>
          </div>
          <div class="container-fluid">
            <div class="row">
              <div
                class="col-md-2 col-sm-5 col-6"
                v-if="loggedInUserGroupId !== 'Borrower'"
              >
                <div class="form-group fc">
                  <label for="selectedBorrower" class="required-field"
                    >Borrower Name</label
                  >
                  <select
                    id="selectedBorrower"
                    class="form-control"
                    name="selectedBorrower"
                    v-model="selectedBorrower"
                    @change="onBorrowerChange()"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="bor in borrowers"
                      :value="bor.borrower_id"
                      :key="bor.borrower_id"
                    >
                      {{ bor.borrower_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">Facility</label>
                  <select
                    id="selectedFacility"
                    class="form-control"
                    name="selectedFacility"
                    v-model="selectedFacility"
                    @change="getFilteredCollaterals()"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="fac in facilities"
                      :value="fac.id"
                      :key="fac.id"
                    >
                      {{ fac.facility_registration_id }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">Collateral</label>
                  <select
                    id="selectedCollateral"
                    class="form-control"
                    name="selectedCollateral"
                    v-model="selectedCollateral"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="col in collaterals"
                      :value="col.id"
                      :key="col.id"
                    >
                      {{ col.collateral_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="selectedWarehouse">Warehouse</label>
                  <select
                    id="selectedWarehouse"
                    class="form-control"
                    name="selectedWarehouse"
                    v-model="selectedWarehouse"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="war in warehouses"
                      :value="war.id"
                      :key="war.id"
                    >
                      {{ war.warehouse_registration_id }}: {{ war.address }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="
                (this.ReleaseToOfftakerorMarketFlag) &&
                (loggedInUserGroupId == 'Admin' ||
                (loggedInUserGroupId == 'Analyst' &&
                  loggedInUserSubGroupId == 0))
              " class="col-md-2 col-sm-5 col-6">

                <div class="form-group fc">
                  <label for="selectedLogisticsId">Logistics ID</label>
                  <select
                    id="selectedLogisticsId"
                    class="form-control"
                    name="selectedLogisticsId"
                    v-model="selectedLogisticsId"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="rel in offtakerReleaseIdList"
                      :value="rel.release_info_id"
                      :key="rel.release_info_id"
                    >
                      {{ rel.release_id }}
                    </option>
                  </select>
                </div>
              </div>
              <div  v-if="
                  (!this.ReleaseToOfftakerorMarketFlag) &&
                  (loggedInUserGroupId == 'Admin' ||
                  (loggedInUserGroupId == 'Analyst' &&
                    loggedInUserSubGroupId == 0))
                " class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="selectedLogisticsId">Logistics ID</label>
                  <select
                    id="selectedLogisticsId"
                    class="form-control"
                    name="selectedLogisticsId"
                    v-model="selectedLogisticsId"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="rel in marketReleaseIdList"
                      :value="rel.release_info_id"
                      :key="rel.release_info_id"
                    >
                      {{ rel.release_id }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">From Date</label>

                  <datepicker
                    id="startDate"
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="startDate"
                    placeholder="Select Date"
                    v-model="startDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fas fa-calendar"
                    :calendar-button="true"
                  />
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">To Date</label>

                  <datepicker
                    id="endDate"
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="endDate"
                    placeholder="Select Date"
                    v-model="endDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :disabledDates="{ to: new Date(startDate - 8640000) }"
                  ></datepicker>
                </div>
              </div>
              <div v-if="loggedInUserGroupId != 'Borrower'" class="col-md-2 col-sm-5 col-6">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-primary sh_n mt"
                      @click="filterResetLogistics(true)"
                    >
                      Filter
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh_n mt"
                      @click="filterResetLogistics()"
                    >
                      Reset
                    </button>
                  </li>
                </ul>
              </div>
              <div v-if="loggedInUserGroupId == 'Borrower'" class="col-md-2 col-sm-5 col-6">
                <ul id="sr_btn"
                style="margin-top: -25px !important"
                class="d-flex justify-content-start">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-primary sh_n mt"
                      @click="filterResetLogistics(true)"
                    >
                      Filter
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh_n mt"
                      @click="filterResetLogistics()"
                    >
                      Reset
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-4">
                        <h1 class="headingt">
                          <i class="icofont-delivery-time"></i> Logistics
                          Tracker
                        </h1>
                      </div>

                      <div class="col-lg-8">
                        <ul
                          id="sr_btn"
                          v-if="loggedInUserGroupId !== 'Borrower'"
                        >
                          <li
                            v-if="
                                (this.ReleaseToOfftakerorMarketFlag) &&
                                (loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0))
                              "
                            >
                            <button
                              id="reportGeneration"
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="showReportModal('Release to Offtaker')"
                            >
                              <i class="icofont-plus"></i>
                              <span class="mob">Report Generation</span>
                            </button>
                          </li>
                          <li
                            v-if="
                                (!this.ReleaseToOfftakerorMarketFlag) &&
                                (loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0))
                              "
                            >
                            <button
                              id="reportGeneration"
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="showReportModal('Release to Market')"
                            >
                              <i class="icofont-plus"></i>
                              <span class="mob">Report Generation</span>
                            </button>
                          </li>
                          <li v-if="this.ReleaseToOfftakerorMarketFlag &&
                                (loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0))
                              ">
                            <button
                              id="addReleaseInfo"
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="
                                $router.push({
                                  name: 'AddRelease',
                                  params: {
                                    borrower_id: selectedBorrower,
                                    type:'Release to Offtaker'
                                  },
                                })
                              "
                            >
                              <i class="icofont-plus"></i>
                              <span class="mob">Add Logistics Info</span>
                            </button>
                          </li>
                          <li v-if="
                                (!this.ReleaseToOfftakerorMarketFlag) &&
                                (loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0))
                              ">
                            <button
                              id="addReleaseInfo"
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="
                                $router.push({
                                  name: 'AddRelease',
                                  params: {
                                    borrower_id: selectedBorrower,
                                    type:'Release to Market'
                                  },
                                })
                              "
                            >
                              <i class="icofont-plus"></i>
                              <span class="mob">Add Logistics Info</span>
                            </button>
                          </li>
                          <li v-if="
                                (!this.ReleaseToOfftakerorMarketFlag) &&
                                (loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0))
                              ">
                            <button
                              id="changeSelectedStatus"
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="onMultipleChangeMarketStatus()"
                              :disabled="checkedContainers.length < 1"
                            >
                              <span class="mob">Change Selected Status</span>
                            </button>
                          </li>
                          <li  v-if="
                                (this.ReleaseToOfftakerorMarketFlag) &&
                                (loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0))
                              ">
                            <button
                              id="changeSelectedStatus"
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="onMultipleChangeOfftakerStatus()"
                              :disabled="checkedContainers.length < 1"
                            >
                              <span class="mob">Change Selected Status</span>
                            </button>
                          </li>
                          <li v-if="
                                (this.ReleaseToOfftakerorMarketFlag) &&
                                (loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0))
                              ">
                            <button
                              id="addShipping"
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="
                                $router.push({
                                  name: 'AddShipping',
                                  params: {
                                    containers: checkedContainers,
                                    borrower_id: selectedBorrower,
                                    direct_add: false,
                                  },
                                })
                              "
                              :disabled="checkedContainers.length < 1"
                            >
                              <i class="icofont-plus"></i>
                              <span class="mob">Add Shipping Info</span>
                            </button>
                          </li>
                        </ul>
                        <h1
                          class="headingt"
                          id="totalReleasedOfftakerQty"
                          style="text-align: right"
                          v-if="displayTotalOfftakerQty && this.ReleaseToOfftakerorMarketFlag"
                        >
                          Total Released Qty:
                          {{
                            logistics.length ? logistics[0].total_quantity : 0
                          }}
                          MT
                        </h1>
                        <h1
                          class="headingt"
                          id="totalReleasedMarketQty"
                          style="text-align: right"
                          v-if="displayTotalMarketQty && !this.ReleaseToOfftakerorMarketFlag"
                        >
                          Total Released Qty:
                          {{
                            logistics.length ? logistics[0].total_quantity : 0
                          }}
                          MT
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div class="tab-content" id="custom-tabs-one-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="one"
                      role="tabpanel"
                      aria-labelledby="release-to-offtaker-tab"
                    >
                      <div
                        class="card-body table-responsive p-0"
                        style="padding: 10px 0px !important"
                      >
                        <!------menton here style="height: 300px;"-------->
                        <table
                          class="table table-head-fixed text-nowrap table-hover offtakerTable"
                          id="offtakerTable"
                        >
                          <thead>
                            <tr>
                              <th>Logistics ID</th>
                              <th>Offtaker Name</th>
                              <th>Facility ID</th>
                              <th>Warehouse ID</th>
                              <th>Collateral</th>
                              <th>Collateral Type</th>
                              <th>Date</th>
                              <th>Container No.</th>
                              <template  v-if="
                                  loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                    loggedInUserSubGroupId == 0)
                                ">
                                <th>Add Repayment</th>
                              </template>
                              <th>Warrant Ref.</th>
                              <th>GDN</th>
                              <th>Truck No.</th>
                              <th>Seal No.</th>
                              <th>No. of Bags</th>
                              <th>Quantity (MT)</th>
                              <th>Status</th>
                              <th
                                class="c"
                                v-if="
                                  loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                    loggedInUserSubGroupId == 0)
                                "
                              >
                                Change Status
                              </th>
                              <th
                                v-if="
                                  loggedInUserGroupId == 'Analyst' ||
                                  loggedInUserGroupId == 'Admin'
                                "
                              >
                                <input
                                  class="center"
                                  type="checkbox"
                                  id="select"
                                  v-model="selectAllOfftaker"
                                  @click="onCheckAllContainers('offtaker')"
                                  style="margin-left: 25px"
                                />
                              </th>
                              <th class="c center">Container Doc</th>
                              <th
                                class="c center"
                                v-if="
                                  loggedInUserGroupId == 'Collateral Manager' ||
                                  loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                    loggedInUserSubGroupId == 0)
                                "
                              >
                                Docs Uploaded
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(log, index) in logisticsOfftaker" :key="log.id">
                              <template
                                v-if="
                                  loggedInUserGroupId == 'Collateral Manager' ||
                                  loggedInUserGroupId == 'Borrower' ||
                                  loggedInUserGroupId == 'Admin' ||
                                  (loggedInUserGroupId == 'Analyst' &&
                                    loggedInUserSubGroupId == 0)
                                "
                              >
                                <td v-if="log.release_id !== null">
                                  {{ log.release_id }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.offtaker_name !== null">
                                  {{ log.offtaker_name }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.facility_registration_id !== null">
                                  {{ log.facility_registration_id }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.warehouse_registration_id !== null">
                                  {{ log.warehouse_registration_id }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.collateral_name !== null">
                                  {{ log.collateral_name }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.stage_name !== null">
                                  {{ log.stage_name }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.date !== null">
                                  {{ log.date }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.container_number !== null">
                                  {{ log.container_number }}
                                </td>
                                <td v-else>-</td>
                                <template v-if="(loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0))">
                                  <td
                                    class="c text-danger center"
                                    v-if="log.status != 'Pending' && log.repayment_flag == 0"
                                  >
                                  <router-link
                                    :to="{
                                      name: 'AddRepayment',
                                      params: {
                                        id:0,
                                        repaymentType: 'Logistics',
                                        contractType: 'Offtaker',
                                        selectedBorrower: log.borrower_id,
                                        selectedFacility: log.facility_id,
                                        selectedOfftakerID: log.offtaker_id,
                                        selectedContractNumber: log.offtaker_contract_tracker_id,
                                        selectedPartialContractNumber: log.offtaker_contract_partial_fill_id,
                                        fundCurrency: log.fund_currency_id,
                                      },
                                    }"
                                  >
                                  <i class="icofont-plus text-success"></i>
                                  </router-link>
                                  </td>
                                  <td class="c text-danger center" v-else>
                                    <i
                                      style="opacity: 0.5"
                                      class="icofont-plus text-success"
                                    ></i>
                                  </td>
                                </template>
                                  <td
                                  class="center"
                                  @click="showWarrantModal(log.id)"
                                >
                                  <i class="icofont-page text-info"></i>
                                </td>
                                <td v-if="log.gdn_number !== null">
                                  {{ log.gdn_number }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.truck_number !== null">
                                  {{ log.truck_number }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.seal_number !== null">
                                  {{ log.seal_number }}
                                </td>
                                <td v-else>-</td>
                                <td class="right" v-if="log.bags !== null">
                                  {{ log.bags }}
                                </td>
                                <td v-else>-</td>
                                <td
                                  class="right"
                                  v-if="log.release_quantity !== null"
                                >
                                  {{ log.release_quantity }}
                                </td>
                                <td v-else>-</td>
                                <td
                                  :class="{
                                    'c text-warning': log.status === 'Pending',
                                    'c text-success':
                                      log.status === 'Approved' ||
                                      log.status === 'In Transit' ||
                                      log.status === 'At Port',
                                    'c text-danger':
                                      log.status === 'Shipped' ||
                                      log.status === 'Delivered',
                                  }"
                                >
                                  {{ log.status }}
                                </td>
                                <td
                                  class="c status"
                                  v-if="
                                    loggedInUserGroupId == 'Analyst' ||
                                    loggedInUserGroupId == 'Admin'
                                  "
                                >
                                  <div class="status">
                                    <select
                                      id="ReqStatus"
                                      name="ReqStatus"
                                      @change="
                                        onChangeOfftakerStatus(
                                          $event,
                                          log.status,
                                          index,
                                          log.id
                                        )
                                      "
                                      class="form-control"
                                      :value="log.status"
                                      :disabled="
                                        ((loggedInUserGroupId ==
                                          'Collateral Manager' ||
                                          loggedInUserGroupId == 'Analyst') &&
                                          log.status === 'Pending') ||
                                        log.status === 'In Transit' ||
                                        log.status === 'At Port' ||
                                        log.status === 'Shipped' ||
                                        log.status === 'Delivered'
                                      "
                                    >
                                      <option
                                        v-if="log.status === 'Pending'"
                                        value="Pending"
                                      >
                                        Pending
                                      </option>
                                      <option
                                        v-if="
                                          log.status === 'Pending' ||
                                          log.status === 'Approved'
                                        "
                                        value="Approved"
                                      >
                                        Approved
                                      </option>
                                      <option
                                        v-if="log.status !== 'Pending'"
                                        value="In Transit"
                                      >
                                        In Transit
                                      </option>
                                      <option
                                        v-if="log.status === 'At Port'"
                                        value="At Port"
                                      >
                                        At Port
                                      </option>
                                      <option
                                        v-if="log.status === 'Shipped'"
                                        value="Shipped"
                                      >
                                        Shipped
                                      </option>
                                      <option
                                        v-if="log.status === 'Delivered'"
                                        value="Delivered"
                                      >
                                        Delivered
                                      </option>
                                    </select>
                                  </div>
                                </td>
                                <td
                                  class="c center"
                                  v-if="
                                    loggedInUserGroupId == 'Analyst' ||
                                    loggedInUserGroupId == 'Admin'
                                  "
                                >
                                  <input
                                    type="checkbox"
                                    id="select"
                                    :value="log"
                                    v-model="checkedContainers"
                                    :disabled="log.status === 'Delivered'"
                                  />
                                </td>
                                <td
                                  v-on:click="downloadDocument(log.document_path)"
                                  :style="{ cursor: 'pointer' }"
                                  class="center"
                                >
                                  <i
                                    id="buttons"
                                    class="icofont-download text-info"
                                  ></i>
                                </td>
                                <td
                                  class="center"
                                  @click="showDocumentModal(log.id)"
                                  v-if="
                                    loggedInUserGroupId == 'Collateral Manager' ||
                                    loggedInUserGroupId == 'Analyst' ||
                                    (loggedInUserGroupId == 'Admin' &&
                                      loggedInUserSubGroupId == 0)
                                  "
                                  :style="{ cursor: 'pointer' }"
                                >
                                  <i class="icofont-file-pdf text-danger"></i>
                                </td>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="two"
                      role="tabpanel"
                      aria-labelledby="release-to-market-tab"
                    >
                    <!-- release to market -->
                    <div
                        class="card-body table-responsive p-0"
                        style="padding: 10px 0px !important"
                      >
                        <!------menton here style="height: 300px;"-------->
                        <table
                          class="table table-head-fixed text-nowrap table-hover marketTable"
                          id="marketTable"
                        >
                          <thead>
                            <tr>
                              <th>Logistics ID</th>
                              <th>Offtaker Name</th>
                              <th>Facility ID</th>
                              <th>Warehouse ID</th>
                              <th>Collateral</th>
                              <th>Collateral Type</th>
                              <th>Date</th>
                              <th>Container No.</th>
                              <template  v-if="
                                  loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                    loggedInUserSubGroupId == 0)
                                ">
                                <th>Add Repayment</th>
                              </template>
                              <th>Warrant Ref.</th>
                              <th>GDN</th>
                              <th>Truck No.</th>
                              <th>Seal No.</th>
                              <th>No. of Bags</th>
                              <th>Quantity (MT)</th>
                              <!-- <th>Price (Per MT)</th>
                              <th>Currency</th> -->
                              <th>Status</th>
                              <th
                                class="c"
                                v-if="
                                  loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                    loggedInUserSubGroupId == 0)
                                "
                              >
                                Change Status
                              </th>
                              <th
                                v-if="
                                  loggedInUserGroupId == 'Analyst' ||
                                  loggedInUserGroupId == 'Admin'
                                "
                              >
                                <input
                                  class="center"
                                  type="checkbox"
                                  id="select"
                                  v-model="selectAllMarket"
                                  @click="onCheckAllContainers('market')"
                                  style="margin-left: 25px"
                                />
                              </th>
                              <th class="c center">Container Doc</th>
                              <th
                                class="c center"
                                v-if="
                                  loggedInUserGroupId == 'Collateral Manager' ||
                                  loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                    loggedInUserSubGroupId == 0)
                                "
                              >
                                Docs Uploaded
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(log, index) in logisticsMarket" :key="log.id">
                              <template
                                v-if="
                                  loggedInUserGroupId == 'Collateral Manager' ||
                                  loggedInUserGroupId == 'Borrower' ||
                                  loggedInUserGroupId == 'Admin' ||
                                  (loggedInUserGroupId == 'Analyst' &&
                                    loggedInUserSubGroupId == 0)
                                "
                              >
                                <td v-if="log.release_id !== null">
                                  {{ log.release_id }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.offtaker_name !== null">
                                  {{ log.offtaker_name }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.facility_registration_id !== null">
                                  {{ log.facility_registration_id }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.warehouse_registration_id !== null">
                                  {{ log.warehouse_registration_id }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.collateral_name !== null">
                                  {{ log.collateral_name }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.stage_name !== null">
                                  {{ log.stage_name }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.date !== null">
                                  {{ log.date }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.container_number !== null">
                                  {{ log.container_number }}
                                </td>
                                <td v-else>-</td>
                                <template v-if="(loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0))">
                                  <td
                                    class="c text-danger center"
                                    v-if="(log.status != 'Pending' && log.repayment_flag == 0)"
                                  >
                                  <router-link
                                    :to="{
                                      name: 'AddRepayment',
                                      params: {
                                        id:0,
                                        repaymentType: 'Logistics',
                                        contractType: 'Offtaker',
                                        selectedBorrower: log.borrower_id,
                                        selectedFacility: log.facility_id,
                                        selectedOfftakerID: log.offtaker_id,
                                        selectedContractNumber: log.offtaker_contract_tracker_id,
                                        selectedPartialContractNumber: log.offtaker_contract_partial_fill_id,
                                        fundCurrency: log.fund_currency_id,
                                      },
                                    }"
                                  >
                                  <i class="icofont-plus text-success"></i>
                                  </router-link>
                                  </td>
                                  <td class="c text-danger center" v-else>
                                    <i
                                      style="opacity: 0.5"
                                      class="icofont-plus text-success"
                                    ></i>
                                  </td>
                                </template>
                                <td
                                  class="center"
                                  @click="showWarrantModal(log.id)"
                                >
                                  <i class="icofont-page text-info"></i>
                                </td>
                                <td v-if="log.gdn_number !== null">
                                  {{ log.gdn_number }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.truck_number !== null">
                                  {{ log.truck_number }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="log.seal_number !== null">
                                  {{ log.seal_number }}
                                </td>
                                <td v-else>-</td>
                                <td class="right" v-if="log.bags !== null">
                                  {{ log.bags }}
                                </td>
                                <td class="right" v-else>-</td>
                                <td
                                  class="right"
                                  v-if="log.release_quantity !== null"
                                >
                                  {{ log.release_quantity }}
                                </td>
                                <td class="right" v-else>-</td>
                                <!-- <td class="right" v-if="log.price !== null">
                                  {{ log.price }}
                                </td>
                                <td class="right" v-else>-</td>
                                <td v-if="log.currency_short_name !== null">
                                  {{ log.currency_short_name }}
                                </td>
                                <td v-else>-</td> -->
                                <td
                                  :class="{
                                    'c text-success':
                                      log.status === 'In Transit',
                                    'c text-danger':
                                      log.status === 'Delivered',
                                  }"
                                >
                                  {{ log.status }}
                                </td>
                                <td
                                  class="c status"
                                  v-if="
                                    loggedInUserGroupId == 'Analyst' ||
                                    loggedInUserGroupId == 'Admin'
                                  "
                                >
                                  <div class="status">
                                    <select
                                      id="ReqStatus"
                                      name="ReqStatus"
                                      @change="
                                        onChangeMarketStatus(
                                          $event,
                                          log.status,
                                          index,
                                          log.id
                                        )
                                      "
                                      class="form-control"
                                      :value="log.status"
                                      :disabled="
                                        ((loggedInUserGroupId == 'Analyst') &&
                                          log.status === 'Pending') ||
                                        log.status === 'Delivered'
                                      "
                                    >
                                      <option
                                        v-if="log.status === 'In Transit'"
                                        value="In Transit"
                                      >
                                        In Transit
                                      </option>
                                      <option
                                        v-if="
                                          log.status === 'In Transit' ||
                                          log.status === 'Delivered'
                                        "
                                        value="Delivered"
                                      >
                                        Delivered
                                      </option>
                                      <option
                                        v-if="log.status === 'Delivered'"
                                        value="Delivered"
                                      >
                                        Delivered
                                      </option>
                                    </select>
                                  </div>
                                </td>
                                <td
                                  class="c center"
                                  v-if="
                                    loggedInUserGroupId == 'Analyst' ||
                                    loggedInUserGroupId == 'Admin'
                                  "
                                >
                                  <input
                                    type="checkbox"
                                    id="select"
                                    :value="log"
                                    v-model="checkedContainers"
                                    :disabled="log.status === 'Delivered'"
                                  />
                                </td>
                                <td
                                  v-on:click="downloadDocument(log.document_path)"
                                  :style="{ cursor: 'pointer' }"
                                  class="center"
                                >
                                  <i
                                    id="buttons"
                                    class="icofont-download text-info"
                                  ></i>
                                </td>
                                <td
                                  class="center"
                                  @click="showDocumentModal(log.id)"
                                  v-if="
                                    loggedInUserGroupId == 'Collateral Manager' ||
                                    loggedInUserGroupId == 'Analyst' ||
                                    (loggedInUserGroupId == 'Admin' &&
                                      loggedInUserSubGroupId == 0)
                                  "
                                  :style="{ cursor: 'pointer' }"
                                >
                                  <i class="icofont-file-pdf text-danger"></i>
                                </td>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>
        <!-- /.content-->
      </div>
      <!-- ./modal start -->
      <modal name="view_doc">
        <div class="modal-content" style="height: 500px">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-eye-alt"></i> View Document
            </h4>
            <button
              type="button"
              class="close"
              @click="$modal.hide('view_doc')"
            >
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table">
                    <tbody>
                      <tr v-if="!availableDocuments.length">
                        <center><h5>No Documents uploaded</h5></center>
                      </tr>
                      <tr v-for="doc in availableDocuments" :key="doc.id">
                        <template>
                          <td class="col-10">{{ doc.display_file_name }}</td>
                          <td
                            class="c col-1"
                            @click="downloadDocument(doc.document_path)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-download text-info"></i>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <button
                type="button"
                class="btn-sm btn btn-dark sh"
                @click="$modal.hide('view_doc')"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </modal>
    </div>
    <!-- ./modal end -->
    <!-- ./modal start -->
    <modal name="add_doc" @closed="$router.go()">
      <div
        class="modal-content"
        style="height: 500px; max-height: 500px; overflow-y: auto"
      >
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Add Document</h4>
          <button type="button" class="close" @click="$modal.hide('add_doc')">
            <i class="icofont-close-circled"></i>
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card-body p-0">
                <!------menton here style="height: 300px;"-------->
                <table class="table">
                  <tbody>
                    <tr v-for="(d, index) in addLogisticsDocs" :key="index">
                      <td>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="file"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            @change="handleFileUploadLogistics($event, index)"
                          />
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ d.selectedFileName }}</b>
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="d.errors.filesize">
                              {{ d.errors.filesize }}
                            </p>
                          </small>
                          <label class="custom-file-label" for="customFile"
                            >Choose File</label
                          >
                        </div>
                      </td>
                      <td
                        v-if="d.logDocRemoveDisabled === false"
                        @click="removeLogDoc(index)"
                        :style="{ cursor: 'pointer' }"
                        style="text-align: center; vertical-align: middle"
                      >
                        <small>
                          <p style="background-color: powderblue">
                            <small>
                              <u style="color: blue; cursor: pointer">Remove</u>
                            </small>
                          </p>
                        </small>
                      </td>
                      <td
                        @click="deleteLogDoc(index)"
                        :style="{ cursor: 'pointer' }"
                      >
                        <i class="icofont-trash text-danger"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <div class="mx-auto">
            <ul id="sr_btn">
              <li>
                <button
                  type="button"
                  class="btn-sm btn btn-primary sh"
                  @click="addLogDocument()"
                >
                  Add
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="btn-sm btn btn-success sh"
                  @click="onSubmit()"
                >
                  Submit
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="btn-sm btn btn-dark sh"
                  @click="$modal.hide('add_doc')"
                >
                  Cancel
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </modal>
    <!-- ./modal end -->
    <!-- ./modal start -->
    <modal name="selected_change_offtaker_status">
      <div
        class="modal-content"
        style="height: 500px; max-height: 500px; overflow-y: auto"
      >
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Change Status To</h4>
          <button
            type="button"
            class="close"
            @click="$modal.hide('selected_change_offtaker_status')"
          >
            <i class="icofont-close-circled"></i>
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card-body p-0">
                <select
                  id="ReqStatus"
                  name="ReqStatus"
                  @change="onChangeOfftakerStatus($event)"
                  class="form-control"
                  :value="multipleOfftakerStatus"
                  :disabled="
                    ((loggedInUserGroupId == 'Collateral Manager' ||
                      loggedInUserGroupId == 'Analyst') &&
                      multipleOfftakerStatus === 'Pending') ||
                    multipleOfftakerStatus === 'In Transit' ||
                    multipleOfftakerStatus === 'At Port' ||
                    multipleOfftakerStatus === 'Shipped' ||
                    multipleOfftakerStatus === 'Delivered'
                  "
                >
                  <option v-if="multipleOfftakerStatus === 'Pending'" value="Pending">
                    Pending
                  </option>
                  <option
                    v-if="
                      multipleOfftakerStatus === 'Pending' ||
                      multipleOfftakerStatus === 'Approved'
                    "
                    value="Approved"
                  >
                    Approved
                  </option>
                  <option
                    v-if="multipleOfftakerStatus !== 'Pending'"
                    value="In Transit"
                  >
                    In Transit
                  </option>
                  <option v-if="multipleOfftakerStatus === 'At Port'" value="At Port">
                    At Port
                  </option>
                  <option v-if="multipleOfftakerStatus === 'Shipped'" value="Shipped">
                    Shipped
                  </option>
                  <option
                    v-if="multipleOfftakerStatus === 'Delivered'"
                    value="Delivered"
                  >
                    Delivered
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!-- ./modal end -->
    <!-- ./modal start -->

    <!-- ./modal end -->
    <!-- ./modal start -->
    <modal name="selected_change_market_status">
      <div
        class="modal-content"
        style="height: 500px; max-height: 500px; overflow-y: auto"
      >
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Change Status To</h4>
          <button
            type="button"
            class="close"
            @click="$modal.hide('selected_change_market_status')"
          >
            <i class="icofont-close-circled"></i>
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card-body p-0">
                <select
                  id="ReqStatus"
                  name="ReqStatus"
                  @change="onChangeMarketStatus($event)"
                  class="form-control"
                  :value="multipleMarketStatus"
                  :disabled="
                    ((loggedInUserGroupId == 'Collateral Manager' ||
                      loggedInUserGroupId == 'Analyst') &&
                      multipleMarketStatus === 'Pending') ||
                    multipleMarketStatus === 'Delivered'
                  "
                >
                  <option
                      v-if="multipleMarketStatus === 'In Transit'"
                      value="In Transit"
                    >
                    In Transit
                  </option>
                  <option
                    v-if="
                      multipleMarketStatus === 'In Transit' ||
                      multipleMarketStatus === 'Delivered'
                    "
                    value="Delivered"
                  >
                    Delivered
                  </option>
                  <option
                    v-if="multipleMarketStatus === 'Delivered'"
                    value="Delivered"
                  >
                    Delivered
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!-- ./modal end -->
    <!-- ./modal start -->
    <modal name="warrant_details">
      <div
        class="modal-content"
        style="height: 500px; max-height: 500px; overflow-y: auto"
      >
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Mapped Warrants</h4>
          <button
            type="button"
            class="close"
            @click="$modal.hide('warrant_details')"
          >
            <i class="icofont-close-circled"></i>
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card-body p-0">
                <!------menton here style="height: 300px;"-------->
                <table class="table">
                  <tbody>
                    <tr v-if="!selectedWarrants.length">
                      <center><h5>No Warrants Mapped</h5></center>
                    </tr>
                    <tr v-for="war in selectedWarrants" :key="war.id">
                      <td v-if="war.reference_id !== null">
                        {{ war.reference_id }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!-- ./modal end -->
    <div>
      <custom-modal-no-footer ref="customModalNoFooter">
        <!-- Provide content for the header slot -->
        <template v-slot:header>
          <div class="row">
            <div class="col-md-10">
              <h4>Mapped Warrants</h4>
            </div>
            <div class="col-md-2">
              <button
              type="button"
              class="close"
              @click="$refs.customModalNoFooter.close();"
            >
              <i class="icofont-close-circled"></i>
            </button>
            </div>
          </div>
        </template>

          <div class="row">
            <div class="col-md-12">
              <div class="card-body p-0">
                <!------menton here style="height: 300px;"-------->
                <table class="table">
                  <tbody>
                    <tr v-if="!selectedWarrants.length">
                      <center><h5>No Warrants Mapped</h5></center>
                    </tr>
                    <tr v-for="war in selectedWarrants" :key="war.id">
                      <td v-if="war.reference_id !== null">
                        {{ war.reference_id }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </custom-modal-no-footer>
    </div>
    <!-- /.content-wrapper -->
    <div>
      <custom-loan-report-modal ref="customLoanReportModal">
        <template v-slot:header>
          <h4>Logistics Report Generation</h4>
        </template>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group fc">
              <label for="exampleInputEmail1" class="required-field"
                >Borrower Name</label
              >
              <select
                id="selectedReportBorrower"
                class="form-control"
                name="selectedReportBorrower"
                v-model="selectedReportBorrower"
                @change="getCollateralByBorrower()"
                :class="{
                  'is-invalid':
                    isSubmitted && $v.selectedReportBorrower.$error,
                }"
                >
                <option :value="null" disabled>--- Select ---</option>
                <option
                  v-for="bor in borrowers"
                  :value="bor.borrower_id"
                  :key="bor.borrower_id"
                >
                  {{ bor.borrower_name }}
                </option>
              </select>
                <div
                  v-if="isSubmitted && $v.selectedReportBorrower.$error"
                  class="invalid-feedback"
                >
                <span v-if="!$v.selectedReportBorrower.required"
                  >Borrower Name is required</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group fc">
              <label for="exampleInputEmail1" class="required-field"
                >Collateral</label
              >
              <select
                id="selectedReportCollateral"
                class="form-control"
                name="selectedReportCollateral"
                v-model="selectedReportCollateral"
                :class="{
                  'is-invalid':
                    isSubmitted && $v.selectedReportCollateral.$error,
                }"
              >
                <option :value="null" disabled>-- Select --</option>
                <option 
                  v-for="col in reportCollaterals" 
                  :value="col.id"
                  :key="col.id"
                >
                  {{ col.collateral_name }}
                </option>
              </select>
                <div
                  v-if="isSubmitted && $v.selectedReportCollateral.$error"
                  class="invalid-feedback"
                >
                <span v-if="!$v.selectedReportCollateral.required"
                  >Collateral is required</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group fc">
              <label  class="required-field">Period Type</label>
              <select
                id="selectedPeriodType"
                class="form-control"
                name="selectedPeriodType"
                v-model="selectedPeriodType"
                :class="{
                  'is-invalid':
                    isSubmitted && $v.selectedPeriodType.$error,
                }"
              >
                <option :value="null" disabled>--- Select ---</option>
                <option v-for="report in report_type" :key="report.id" :value="report.id">
                  {{ report.report_type_name }}
                </option>
              </select>
              <div
                v-if="isSubmitted && $v.selectedPeriodType.$error"
                class="invalid-feedback"
              >
              <span v-if="!$v.selectedPeriodType.required"
                >Period Type is required</span
              >
            </div>
            </div>
          </div>

          <div v-if="selectedPeriodType == 0" class="col-md-6">
            <div class="form-group fc">
              <label for="exampleInputEmail1" class="required-field"
                >Monthly Period</label
              >
              <datepicker
                class="datepicker"
                :bootstrap-styling="true"
                name="selectedRegularReportDate"
                placeholder="Select Period"
                v-model="selectedRegularReportDate"
                minimum-view="month"
                format="MM-yyyy"
                calendar-button-icon="fa fa-calendar"
                :calendar-button="true"
                :disabledDates="{
                  to: new Date(this.backcapBorrowerReportDate),
                  from: lastMonth,
                }"
              ></datepicker>
            </div>
          </div>

          <div v-if="selectedPeriodType == 1" class="col-md-6">
            <div class="form-group fc">
              <label for="exampleInputEmail1" class="required-field"
                >As of today</label
              >
              <datepicker
                class="custom-width-datepicker1 datepicker"
                :bootstrap-styling="true"
                name="selectedCustomReportDate"
                placeholder="Select Date"
                v-model="selectedCustomReportDate"
                :format="$store.state.date_format"
                calendar-button-icon="fas fa-calendar"
                :calendar-button="true"
                disabled
                :disabledDates="{
                  to: new Date(this.backcapBorrowerReportDate),
                  from: new Date(),
                }"
              ></datepicker>
            </div>
          </div>

        </div>
        <template v-slot:footer>
          <div class="">
              <button
                style="margin-right: 6px"
                type="button"
                class="btn-sm btn btn-success sh"
                v-on:click="onClickExcel('excel')"
              >
                <i class="icofont-file-excel"></i> Excel
              </button>
              <!-- <button
                type="button"
                style="margin-right: 6px"
                class="btn-sm btn btn-danger sh"
                v-on:click="onClickExcel('pdf')"
              >
                <i class="icofont-file-pdf"></i> PDF
              </button> -->
              <button
                type="button"
                name="saveInfo"
                class="btn-sm btn btn-primary sh"
                style="margin-right: 10px;"
                v-on:click="onCloseReportModal()"
              >
                <i class="icofont-close-circled"></i> Close
              </button>
            </div>
        </template>
      </custom-loan-report-modal>
    </div>

  </div>
  <!-- ./wrapper -->
</template>

<script>
import $ from "jquery";
// //Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {required} from "vuelidate/lib/validators";
import CustomLoanReportModal from '../loan/CustomLoanReportModal.vue';
import CustomModalNoFooter from './CustomModalNoFooter.vue';

export default {
  data() {
    return {
      logistics: [],
      logisticsOfftaker: [],
      logisticsMarket: [],
      borrowers: [],
      facilities: [],
      // releaseIdList: [],
      offtakerReleaseIdList: [],
      marketReleaseIdList: [],
      collaterals: [],
      warehouses: [],
      logisticsDocuments: [],
      availableDocuments: [],
      addLogisticsDocs: [],
      checkedContainers: [],
      selectedWarrants: [],
      selectAllOfftaker: false,
      selectAllMarket: false,
      logisticsDocsContainerId: null,
      logisticsDocsMultiple: false,
      selectedBorrower: null,
      selectedLogisticsId: null,
      selectedFacility: null,
      selectedCollateral: null,
      selectedWarehouse: null,
      startDate: null,
      endDate: null,
      borrower_id: null,
      logFile: null,

      displayTotalOfftakerQty: false,
      displayTotalMarketQty: false,
      multipleMarketStatus: null,
      multipleOfftakerStatus: null,

      ReleaseToOfftakerorMarketFlag: true,

      releaseTo: null,
      selectedReportBorrower: null,
      selectedReportBorrowerName: '',
      selectedReportCollateral: null,
      reportCollaterals: [],
      selectedPeriodType: null,
      // selectedCollateralType: 'All',
      // collateralStages: [],
      report_type: [
        { id: 0 , report_type_name: "Monthly"},
        { id: 1 , report_type_name: "As of today"},
      ],

      backcapBorrowerReportDate: null,
      selectedRegularReportDate: null,
      selectedCustomReportDate: new Date(),
      lastMonth: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1
      ),
      isSubmitted: false,
    };
  },
  components: {
    Datepicker,
    CustomModalNoFooter,
    CustomLoanReportModal,
  },
  validations: {
    selectedReportBorrower: {
      required,
    },
    selectedReportCollateral: {
      required,
    },
    selectedPeriodType: {
      required,
    },
  },
  methods: {
    onCloseReportModal() {
      console.log("onCloseReportModal");
      this.selectedPeriodType = null;
      this.selectedRegularReportDate = null;
      this.selectedCustomReportDate = new Date();
      this.backcapBorrowerReportDate = null;
      this.selectedReportCollateral = null;
      this.reportCollaterals = [];
      this.selectedReportBorrowerName= '';
      this.isSubmitted = false;
      this.$refs.customLoanReportModal.close();
    },

    showReportModal(release_to) {
      this.releaseTo = release_to;
      this.selectedPeriodType = null;
      this.selectedRegularReportDate = null;
      this.selectedCustomReportDate = new Date();
      this.backcapBorrowerReportDate = null;
      this.selectedReportCollateral = null;
      this.reportCollaterals = [];
      this.selectedReportBorrowerName= '';
      this.isSubmitted = false;
      this.getCollateralByBorrower()
      this.$refs.customLoanReportModal.open();
    },

    onClickExcel(fileType) {
      // if(this.selectedReportBorrower == null){
      //   Utils.make_alert("warning", "Please select borrower.");
      //   return;
      // }
      // if(this.selectedReportCollateral == null){
      //   Utils.make_alert("warning", "Please select collateral.");
      //   return;
      // }
      // if(this.selectedCollateralType == null){
      //   Utils.make_alert("warning", "Please select exposure.");
      //   return;
      // }
      // if(this.selectedPeriodType == null){
      //   Utils.make_alert("warning", "Please select period type.");
      //   return;
      // }
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let date = null
      if(this.selectedPeriodType != null){
        if(this.selectedPeriodType == 0){
          if (this.selectedRegularReportDate === null) {
            Utils.make_alert("warning", "Please select Monthly Period.");
            return;
          } else {
            console.log("selectedRegularReportDate",this.selectedRegularReportDate)
            date = moment(this.selectedRegularReportDate).endOf("month").format("MM-DD-YYYY");
          }
        } else if(this.selectedPeriodType == 1){
          if (this.selectedCustomReportDate === null) {
            Utils.make_alert("warning", "Please select As of today date.");
            return;
          } else {
            console.log("selectedCustomReportDate",this.selectedCustomReportDate)
            date = moment(this.selectedCustomReportDate).format("MM-DD-YYYY");
          }
        }
      }
      console.log("date",date)
      const path =
        "/logistics_reports/" +
        this.selectedReportBorrower +
        "/" +
        this.selectedReportCollateral +
        "/" +
        this.selectedPeriodType +
        "/" +
        fileType +
        "/" + 
        date +
        "/" +
        this.releaseTo;
        console.log("path",path)
        this.$refs.customLoanReportModal.close();
        Utils.start_loading();
      axios({
        params: { clientId: this.$store.state.client_id , userId: this.$store.getters.getuserId },
        url: path,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          Utils.stop_loading();
          console.log("API CALL DONE")
          console.log("response.data.type",response.data.type)
          if (response.data.type == "application/json") {
            console.log("failed")
            Utils.make_alert(
              "warning",
              "Something went wrong! Unable to download report."
            );
            this.selectedPeriodType = null;
            this.selectedRegularReportDate = null;
            this.selectedCustomReportDate = new Date();
            this.backcapBorrowerReportDate = null;
            this.selectedReportCollateral = null;
            this.reportCollaterals = [];
            this.selectedReportBorrowerName= '';
          } else {
            //give report type names as per selectedReport
            console.log("Download")
            console.log("response.data",response.data)
            var reportName;
            var extension;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            // Filename seems to have to be changed unless otherwise provided
            // the extension can be automatically decided
            if (fileType === "excel") {
              extension = ".xlsx";
            } else if (fileType === "pdf") {
              extension = ".pdf";
            }
            reportName = `${this.selectedReportBorrowerName} - Logistics Summary Report`;
            link.setAttribute("download", reportName + extension);
            document.body.appendChild(link);
            link.click();
            this.selectedPeriodType = null;
            this.selectedRegularReportDate = null;
            this.selectedCustomReportDate = new Date();
            this.backcapBorrowerReportDate = null;
            this.selectedReportCollateral = null;
            this.reportCollaterals = [];
            this.selectedReportBorrowerName= '';
            //this.$router.go(this.$router.currentRoute)
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.log("error");
          this.selectedPeriodType = null;
          this.selectedRegularReportDate = null;
          this.selectedCustomReportDate = null;
          this.loanId = null;
          this.loanRegId = null;
          this.backcapBorrowerReportDate = null;
        });
    },

    getCollateralByBorrower() {
      console.log("getCollateralByBorrower")
      if(this.selectedReportBorrower == null){
        this.reportCollaterals = [];
        this.selectedReportCollateral = null;
        return;
      }
      let borrower = this.borrowers.find(borrower => borrower.borrower_id == this.selectedReportBorrower)
      this.selectedReportBorrowerName = borrower.borrower_name;
      this.backcapBorrowerReportDate = borrower.registration_date;
      console.log("selectedReportBorrowerName",this.selectedReportBorrowerName)
      console.log("backcapBorrowerReportDate",this.backcapBorrowerReportDate)
      const path = 
      "/get-collateral-by-borrower" +
      "/" +
      this.selectedReportBorrower;
      console.log(path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            this.reportCollaterals = res.data.collateral;
          } else {
            Utils.make_alert("warning","Something went wrong from server!")
          }
          
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
        });
    },

    showDocumentModal(docId) {
      this.availableDocuments = this.logisticsDocuments.filter(
        (doc) => doc.released_info_container_id === docId
      );
      this.$modal.show("view_doc");
    },
    showAddDocumentModal(id) {
      this.$modal.show("add_doc");
      this.addLogisticsDocs = [];
      if (id) {
        this.logisticsDocsContainerId = id;
      } else {
        this.logisticsDocsMultiple = true;
        this.logisticsDocsContainerId = this.checkedContainers;
      }
      this.addLogDocument();
    },

    showWarrantModal(id) {
      Utils.start_loading();
      //this function is used to get warrants for selected container.
      const path = "logistics-summary-details";
      axios
        .get(path, {
          params: { clientId: this.$store.state.client_id, containerId: id },
        })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.selectedWarrants = res.data.container_warrant_mapping;
            this.$refs.customModalNoFooter.open();
            // this.$modal.show("warrant_details");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
        });
    },

    onBorrowerChange() {
      this.selectedReportBorrower = this.selectedBorrower;
      if (this.selectedBorrower === null) {
        Utils.make_alert("warning", "Borrower Filter is required");
        return;
      }
      localStorage.setItem("logisticsFilter", this.selectedBorrower);
      this.offtakerReleaseIdList = []
      this.marketReleaseIdList = []
      this.getFilteredFacilities();
      this.getFilteredWarehouses();
      this.getFilteredCollaterals();
      if (this.loggedInUserGroupId === "Collateral Manager" ) {
        this.getLogisticsIdByBorrower();
      } 
    },

    onCheckAllContainers(tab) {
      if(tab == 'offtaker'){
        if (!this.selectAllOfftaker) {
          this.checkedContainers = [];
          this.checkedContainers = this.logisticsOfftaker;
        }
        else this.checkedContainers = [];
      } else if (tab == 'market'){
        if (!this.selectAllMarket) {
          this.checkedContainers = [];
          this.checkedContainers = this.logisticsMarket;
        }
        else this.checkedContainers = [];
      }
    },

    onMultipleChangeOfftakerStatus() {
      console.log("onMultipleChangeOfftakerStatus")
      // validation to check if all selected container's status are equal
      const areAllStatusEqual = this.checkedContainers.every(
        (obj) => obj["status"] === this.checkedContainers[0]["status"]
      );
      if (!areAllStatusEqual) {
        Utils.make_alert(
          "warning",
          "All status must be same for applying multiple change status"
        );
        return;
      }
      this.multipleOfftakerStatus = this.checkedContainers[0]["status"];
      this.$modal.show("selected_change_offtaker_status");
    },

    onMultipleChangeMarketStatus() {
      console.log("onMultipleChangeMarketStatus")
      // validation to check if all selected container's status are equal
      const areAllStatusEqual = this.checkedContainers.every(
        (obj) => obj["status"] === this.checkedContainers[0]["status"]
      );
      if (!areAllStatusEqual) {
        Utils.make_alert(
          "warning",
          "All status must be same for applying multiple change status"
        );
        return;
      }
      this.multipleMarketStatus = this.checkedContainers[0]["status"];
      this.$modal.show("selected_change_market_status");
    },

    onChangeOfftakerStatus(updatedStatus, prevStatus, index, containerId) {
      if (containerId) {
        this.$confirm({
          auth: false,
          message: "Are you sure you want to update the status?",
          button: {
            no: "No",
            yes: "Yes",
          },

          callback: (confirm) => {
            if (confirm) {
              Utils.start_loading();
              //this function is called when user changes the logistics registration status. The status is updated in the database for that specific logistics.
              const path = "logistics-summary-details";
              axios
                .put(
                  path,
                  {
                    type: "status",
                    requestType: "Single",
                    released_info_container_id: containerId,
                    status: updatedStatus.target.value,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 8,
                    tabId: 24,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("registration update status: " + res.data.status);
                  if (res.data.status == "success") {
                    Utils.make_alert(
                      "success",
                      "Logistics Status Updated Successfully!"
                    );
                    this.logisticsOfftaker[index].status = updatedStatus.target.value;
                    if (updatedStatus.target.value !== "Approved") {
                      this.showAddDocumentModal(containerId);
                    }
                  } else {
                    console.log("Something went wrong from server!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.error("error: " + error);
                });
              
            } else {
              updatedStatus.target.value = prevStatus;
            }
          },
        });
      } else {
        this.$confirm({
          auth: false,
          message: "Are you sure you want to update the status?",
          button: {
            no: "No",
            yes: "Yes",
          },

          callback: (confirm) => {
            if (confirm) {
              Utils.start_loading();
              //this function is called when user changes the logistics registration status. The status is updated in the database for that specific logistics.
              const path = "logistics-summary-details";
              axios
                .put(
                  path,
                  {
                    type: "status",
                    requestType: "Multiple",
                    released_info_container_id: this.checkedContainers,
                    status: updatedStatus.target.value,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 8,
                    tabId: 24,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("registration update status: " + res.data.status);
                  if (res.data.status == "success") {
                    Utils.make_alert(
                      "success",
                      "Logistics Status Updated Successfully!"
                    );
                    if (updatedStatus.target.value !== "Approved") {
                      this.$modal.hide("selected_change_offtaker_status");
                      this.showAddDocumentModal(null);
                    } else {
                      setTimeout(() => {
                        this.$router.go();
                      }, 2000);
                    }
                  } else {
                    console.log("Something went wrong from server!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.error("error: " + error);
                });
            }
          },
        });
      }
    }, //onChangeOfftakerStatus close

    onChangeMarketStatus(updatedStatus, prevStatus, index, containerId) {
      if (containerId) {
        this.$confirm({
          auth: false,
          message: "Are you sure you want to update the status?",
          button: {
            no: "No",
            yes: "Yes",
          },

          callback: (confirm) => {
            if (confirm) {
              Utils.start_loading();
              //this function is called when user changes the logistics registration status. The status is updated in the database for that specific logistics.
              const path = "/update-status/" + containerId ;
              axios
                .put(
                  path,
                  {
                    clientId: this.$store.state.client_id,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("registration update status: " + res.data.status);
                  if (res.data.status == "success") {
                    Utils.make_alert(
                      "success",
                      "Logistics Status Updated Successfully!"
                    );
                    this.logisticsMarket[index].status = updatedStatus.target.value;
                    if (updatedStatus.target.value !== "Approved") {
                      this.showAddDocumentModal(containerId);
                    }
                  } else {
                    console.log("Something went wrong from server!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.error("error: " + error);
                });

            } else {
              updatedStatus.target.value = prevStatus;
            }
          },
        });
      } else {
        let containerIds = this.checkedContainers.map(conID => conID.id);
        console.log("containerIds: " + containerId)
        this.$confirm({
          auth: false,
          message: "Are you sure you want to update the status?",
          button: {
            no: "No",
            yes: "Yes",
          },

          callback: (confirm) => {
            if (confirm) {
              Utils.start_loading();
              //this function is called when user changes the logistics registration status. The status is updated in the database for that specific logistics.
              const path = "/update-status/" + containerIds;
              axios
                .put(
                  path,
                  {
                    clientId: this.$store.state.client_id,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("registration update status: " + res.data.status);
                  if (res.data.status == "success") {
                    Utils.make_alert(
                      "success",
                      "Logistics Status Updated Successfully!"
                    );
                    if (updatedStatus.target.value !== "In Transit") {
                      this.$modal.hide("selected_change_market_status");
                      this.showAddDocumentModal(null);
                    } else {
                      setTimeout(() => {
                        this.$router.go();
                      }, 2000);
                    }
                  } else {
                    console.log("Something went wrong from server!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.error("error: " + error);
                });
            }
          },
        });
      }
    }, //onChangeMarketStatus close



    //this method will submit the Request form in backend.
    onSubmit() {
      this.$confirm({
        auth: false,
        message: "Confirm Submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            for (let i = 0; i < this.addLogisticsDocs.length; i++) {
              if (this.addLogisticsDocs[i].selectedFile === null) {
                this.addLogisticsDocs.splice(i, 1);
              }
            }
            //initialize the form data
            let formData = new FormData();
            formData.append(
              "LogisticsDocuments",
              JSON.stringify(this.addLogisticsDocs)
            );
            formData.append(
              "logisticsDocsContainerId",
              JSON.stringify(this.logisticsDocsContainerId)
            );
            formData.append(
              "logisticsDocsMultiple",
              this.logisticsDocsMultiple
            );
            formData.append(
              "clientId",
              JSON.stringify(this.$store.state.client_id)
            );
            formData.append("userId", this.$store.getters.getuserId);
            formData.append("moduleId", 8);
            formData.append("tabId", 24);
            const path = "logistics-summary-details";
            Utils.start_loading();
            axios
              .post(path, formData, {
                headers: {
                  enctype: "multipart/form-data",
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Logistics Documents added successfully!"
                  );
                  console.log("SUCCESS!!");
                  setTimeout(() => this.$router.go(), 2000);
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error(error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          }
        },
      });
    }, //onSubmit() close

    //this function will be called to get list of Logistics
    getLogistics() {
      if (this.startDate != null) {
        this.startDate = moment(this.startDate).format("MM-DD-YYYY");
      }
      if (this.endDate != null) {
        this.endDate = moment(this.endDate).format("MM-DD-YYYY");
      }
      const path =
        "logistics-summary/" +
        this.selectedBorrower +
        "/" +
        this.selectedLogisticsId +
        "/" +
        this.selectedFacility +
        "/" +
        this.selectedCollateral +
        "/" +
        this.selectedWarehouse +
        "/" +
        this.startDate +
        "/" +
        this.endDate;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("res:", res)
          console.log("res type: ", typeof res);
          console.log("data:" , res.data)
          console.log("data type: ", typeof res.data);
          console.log("status: " + res.data.status);
          console.log("status type: ", typeof res.data.status);
          
          if (res.data.status == "success") {
            this.logistics = res.data.logistics_summary;
            // this.releaseIdList = res.data.release_id_list;
            this.logisticsDocuments = res.data.logistics_documents;
            if (this.loggedInUserGroupId === "Borrower")
              this.logistics = this.logistics.filter(
                (log) =>
                  log.borrower_id.toString() === this.loggedInUserSubGroupId
              );
            if (this.loggedInUserGroupId === "Collateral Manager")
              this.logistics = this.logistics.filter(
                (log) =>
                  log.collateral_manager_id.toString() ===
                  this.loggedInUserSubGroupId
              );
              
              // split logistics array into logisticsMarket and logisticsOfftaker with price
              // this.logisticsMarket = this.logistics.filter((log) => log.price !== null);

              // this.logisticsOfftaker = this.logistics.filter((log) => log.price == null);
              this.logisticsMarket = this.logistics.filter((log) => log.release_to == 'Release to Market');

              this.logisticsOfftaker = this.logistics.filter((log) => log.release_to == 'Release to Offtaker');
              if(this.logisticsMarket.length > 0) {
                this.logisticsMarket.forEach(item => {
                  if (item.date != '-' && item.date != null) {
                    item.date = moment(item.date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.date = '-';
                  }
                });
              }
              if(this.logisticsOfftaker.length > 0) {
                this.logisticsOfftaker.forEach(item => {
                  if (item.date != '-' && item.date != null) {
                    item.date = moment(item.date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.date = '-';
                  }
                });
              }
              console.log("this.selectedLogisticsId",this.selectedLogisticsId)

              if(this.selectedLogisticsId !== null){
                if(this.logisticsMarket.length !== 0){
                  this.displayTotalMarketQty = this.selectedLogisticsId ? true : false;
                }
                else if(this.logisticsOfftaker.length !== 0){
                  this.displayTotalOfftakerQty = this.selectedLogisticsId ? true : false;
                }
              } else {
                console.log("displayTotalMarketQty false")
                  this.displayTotalMarketQty = false;
                  this.displayTotalOfftakerQty = false;
                }
              // this.logisticsMarket = this.logistics.filter((log) => log.offtaker_name === null);

              // this.logisticsOfftaker = this.logistics.filter((log) => log.offtaker_name !== null);
              var activeTab = localStorage.getItem("logisticTab");

              if(activeTab == "release-to-offtaker-tab"){
                this.createOfftakerTable();
              } else if(activeTab == "release-to-market-tab"){
                this.createMarketTable()
              } else {
                this.setTab('release-to-offtaker-tab')
              }

            console.log("get logistics is successful.");
          } else {
            Utils.make_alert("warning", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
        });
    },

    createOfftakerTable(){
      setTimeout(
        () =>
          $("#offtakerTable").DataTable({
            aaSorting: [],
            scrollX: true,
            dom: "l<'float-right'B>frtip",
            buttons: [
              {
                extend: "excel",
                exportOptions: {
                  columns: [0, 1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13, 14],
                },
                className: "btn btn-primary",
                title: `Logistics Tracker`,
                customize: function (xlsx) {
                  var sheet = xlsx.xl.worksheets["sheet1.xml"];
                  $("row:not(:first)", sheet).each(function (index) {
                    $("c", this).attr("s", "25");
                  });
                  $("row:eq(1) c", sheet).attr("s", "2");
                },
              },
            ],
            columnDefs: [
              {
                orderable: false,
                targets:
                  this.loggedInUserGroupId === "Borrower"
                  ? [0, 8, 15]
                  :this.loggedInUserGroupId === "Collateral Manager"
                  ? [0, 8, 16]
                  : [8, 9, 16, 17, 18, 19],
              },
            ],
          }),
        100
      );
    },

    createMarketTable(){
      setTimeout(
        () =>
          $("#marketTable").DataTable({
            aaSorting: [],
            scrollX: true,
            dom: "l<'float-right'B>frtip",
            buttons: [
              {
                extend: "excel",
                exportOptions: {
                  columns: [0, 1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13, 14],
                },
                className: "btn btn-primary",
                title: `Logistics Tracker`,
                customize: function (xlsx) {
                  var sheet = xlsx.xl.worksheets["sheet1.xml"];
                  $("row:not(:first)", sheet).each(function (index) {
                    $("c", this).attr("s", "25");
                  });
                  $("row:eq(1) c", sheet).attr("s", "2");
                },
              },
            ],
            columnDefs: [
              {
                orderable: false,
                targets:
                  this.loggedInUserGroupId === "Borrower"
                  ? [0, 8, 15]
                  :this.loggedInUserGroupId === "Collateral Manager"
                  ? [0, 8, 16]
                  : [8, 9, 16, 17, 18, 19],
              },
            ],
          }),
        100
      );
    },
    // filter & reset function
    filterResetLogistics(filter) {
      if (filter) {
        if (
          (this.startDate !== null && this.endDate === null) ||
          (this.startDate === null && this.endDate !== null)
        ) {
          Utils.make_alert(
            "warning",
            "Both From & To Dates are required to perform Date Filter"
          );
          return;
        } else {
          $("#offtakerTable").dataTable().fnDestroy();
          $("#marketTable").dataTable().fnDestroy();
          this.getLogistics();
        }
      } else {
        this.$router.go();
      }
    },

    //handles a change on the file upload
    handleFileUploadLogistics(e, id) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.logFile = e.target.files[0];
        //check for filesize. if it is not greater than 100MB then return following error response
        if (this.logFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors.filesize = "File size should be less than 100MB";
          self.addLogisticsDocs[id].errors.filesize = JSON.stringify(
            response.errors.filesize
          ).replace(/\"/g, "");
          self.addLogisticsDocs[id].selectedFile = null;
          self.addLogisticsDocs[id].selectedFileName = null;
          self.addLogisticsDocs[id].logDocRemoveDisabled = true;
          return;
        }
        //save file details
        reader.readAsDataURL(this.logFile);
        reader.onload = function () {
          self.addLogisticsDocs[id].selectedFile = reader.result;
          self.addLogisticsDocs[id].selectedFileName = e.target.files[0].name;
          self.addLogisticsDocs[id].errors.filesize = "";
          self.addLogisticsDocs[id].logDocRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadLogistics() close

    //this method handles remove logistics document attachment
    removeLogDoc(id) {
      this.logFile = null;
      this.addLogisticsDocs[id].selectedFile = null;
      this.addLogisticsDocs[id].selectedFileName = null;
      this.addLogisticsDocs[id].logDocRemoveDisabled = true;
    },

    deleteLogDoc(index) {
      this.addLogisticsDocs.splice(index, 1);
    },

    //this method will add new line for additional logistics documents modal
    addLogDocument() {
      //following block is created first time on mounted property
      this.addLogisticsDocs.push({
        selectedFile: null,
        selectedFileName: null,
        errors: { filesize: "" },
        logDocRemoveDisabled: true,
        client_id: this.$store.state.client_id,
        user_id: this.$store.getters.getuserId,
      });
    }, //addLogDocument() close

    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },

    setTab(tab) {
      //handling costs & disbursement subtabs operations
      if (tab === "release-to-offtaker-tab") {
        localStorage.setItem("logisticTab", tab);
        this.ReleaseToOfftakerorMarketFlag = true;
        $("#offtakerTable").dataTable().fnDestroy();
        $("#marketTable").dataTable().fnDestroy();
        if(this.selectedLogisticsId === null && this.selectedBorrower !== null){
          this.getLogistics(); 
        } else if (this.selectedBorrower !== null) {
          this.selectedLogisticsId = null;
          this.getLogistics();
        }
        this.checkedContainers = [];
      } else {
        localStorage.setItem("logisticTab", tab);
        this.ReleaseToOfftakerorMarketFlag = false;
        $("#offtakerTable").dataTable().fnDestroy();
        $("#marketTable").dataTable().fnDestroy();
        if(this.selectedLogisticsId === null  && this.selectedBorrower !== null){
          this.getLogistics();
        } else if (this.selectedBorrower !== null) {
          this.selectedLogisticsId = null;
          this.getLogistics();
        }
        this.checkedContainers = [];
        this.selectedLogisticsId = null;
        }
    },

    // this function will be called to get list of borrowers
    getBorrowers() {
      let path = null;
      if (this.loggedInUserGroupId === "Collateral Manager") {
        path = "borrower-master-with-cm/" + this.loggedInUserSubGroupId;
      } else {
        path = "borrower-master-with-cm/" + null;
      }
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.borrowers = res.data.borrowers;
          if (this.borrowers.length) {
            if (
              localStorage.getItem("logisticsFilter") !== null &&
              localStorage.getItem("logisticsFilter") !== "null"
            ) {
              this.selectedBorrower = Number(
                localStorage.getItem("logisticsFilter")
              );
            } else {
              if (this.loggedInUserGroupId === "Borrower") {
                this.selectedBorrower = this.loggedInUserSubGroupId;
              } else {
                this.selectedBorrower = this.borrowers[0].borrower_id;
              }
              localStorage.setItem("logisticsFilter", this.selectedBorrower);
            }
            this.selectedReportBorrower = this.selectedBorrower;
            this.getFilteredFacilities();
            this.getFilteredWarehouses();
            this.getFilteredCollaterals();
            if (this.loggedInUserGroupId === "Collateral Manager" ) {
              this.getLogisticsIdByBorrower();
            } 
            this.filterResetLogistics(true);
          }
          if (res.data.status == "success") {
            console.log("get borrowers is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },
    getLogisticsIdByBorrower() {
      this.offtakerReleaseIdList = []
      this.marketReleaseIdList = []
      //this function is called to get list of open facilities by borrower id
      const path = "/get-logistic-by-borrower/" + this.selectedBorrower;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.offtakerReleaseIdList = res.data.release_to_offtaker_list;
          this.marketReleaseIdList = res.data.release_to_market_list;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFilteredFacilities() {
      this.facilities = []
      this.selectedReportBorrower = this.selectedBorrower;
      //this function is called to get list of open facilities by borrower id
      let path = null;
      if (this.loggedInUserGroupId === "Collateral Manager") {
        path = "/facility-setup-borrower-cm/" + this.selectedBorrower + "/" + this.loggedInUserSubGroupId;
      } else {
        path = "/facility-setup-borrower-cm/" + this.selectedBorrower + "/" + null;
      }
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.facilities = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFilteredWarehouses() {
      //this function is called to get list of filtered warehouses
      const path = "/warehouse-filter/" + this.selectedBorrower;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.warehouses = res.data.warehouses;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFilteredCollaterals() {
      //this function is called to get list of filtered collaterals
      const path =
        "/collateral-filter/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.collaterals = res.data.collaterals;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.getBorrowers();
    var activeTab = localStorage.getItem("logisticTab");
    if (activeTab) {
      document.getElementById(activeTab).click();
    }
  },
  created() {
    axios.defaults.headers.common["Authorisation"] =
      "Bearer " + sessionStorage.getItem("token");
  },
  computed: {
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>
<style scoped>
.error {
  color: red;
}

.center {
  text-align: center;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}
</style>
