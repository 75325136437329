<!-- This is fund-disctribution page.  -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div id="new_fund" class="container-fluid ot_tb pt-3">
          <div class="inputbx">
            <div class="row align_c">
              <div class="col-md-2 col-sm-12">
                <div class="form-group fc">
                  <select
                    class="selectpicker"
                    aria-label="select borrower"
                    title="select borrower"
                    data-dropup-auto="false"
                    data-live-search="true"
                    name="selectedBorrower"
                    v-model="selectedBorrower"
                    @change="getFacilitySetupByBorrower(selectedBorrower,1)"
                  >
                    <option
                      v-for="bor in borrowers"
                      :value="bor.borrower_id"
                      :key="bor.borrower_id"
                    >
                      {{ bor.borrower_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2 col-sm-12">
                <div class="form-group fc">
                  <select
                    class="selectpicker" 
                    aria-label="select facility" 
                    title="select facility"
                    data-dropup-auto="false" 
                    data-live-search="true" 
                    name="selectedFacility"
                    v-model="selectedFacility"
                    @change="getCollateral()"
                  >
                    <option
                      v-for="fac in facilities"
                      :value="fac.id"
                      :key="fac.id"
                    >
                      {{ fac.facility_registration_id }}
                    </option>
                  </select>
                </div>
              </div>

							<div class="col-md-2 col-sm-12">
                <div class="form-group fc">
                  <select
                    class="selectpicker" 
                    aria-label="select collateral" 
                    title="select collateral"
                    data-dropup-auto="false" 
                    data-live-search="true" 
                    name="selectedCollateral"
                    v-model="selectedCollateral"
                    @change="fetchCollateralQuantityValueTNEData()"
                  >
                    <option
                      v-for="col in collaterals"
                      :value="col.id"
                      :key="col.id"
                    >
                      {{ col.collateral_name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="title">
                        <div class="row align_c">
                          <div class="col-md-12 col-sm-12">
                            <h6 class="tp_mid text2">Collateral Valuation</h6>
                          </div>
                        </div>
                      </div>
                      <div class="ht215">
                        <apexchart 
													v-if="CollateralQuantityValueTNEChartDataReady" 
													type="bar" 
													height="200"
													:options="CollateralQuantityValueTNEChartOptions" 
													:series="CollateralQuantityValueTNESeries">
												</apexchart>
                        <h6 class="tp_mid title text2" v-if="!CollateralQuantityValueTNEChartDataReady">No Data Found</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-lg-6 col-md-12 col-sm-12">
              <div id="al_tb" class="card card-primary card-tabs p-2 pt-0 ">
                <div id="datatable-padding" class="card-body table-responsive ht243">
                  <table id="collateralTable" class="table table-head-fixed text-nowrap table-hover collateralTable" style="width: 100%">
                    <thead>
                      <tr>
                        <th>Exposure</th>
                        <th>Actual Duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(exposure, index) in ExposureData" :key="index">
                        <td>{{ exposure.name }}</td>
                        <td>{{ exposure.duration }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> -->

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="inputbx">
								<div class="row align_c">
									<div class="col-md-2 col-sm-12">
										<div class="form-group fc">
											<select
												class="selectpicker"
												aria-label="select borrower"
												title="select borrower"
												data-dropup-auto="false"
												data-live-search="true"
												name="selectedBorrowerWarrent"
												v-model="selectedBorrowerWarrent"
												@change="getFacilitySetupByBorrower(selectedBorrowerWarrent,2)"
											>
												<option
													v-for="bor in borrowers"
													:value="bor.borrower_id"
													:key="bor.borrower_id"
												>
													{{ bor.borrower_name }}
												</option>
											</select>
										</div>
									</div>

									<div class="col-md-2 col-sm-12">
										<div class="form-group fc">
											<select
												class="selectpicker" 
												aria-label="select facility" 
												title="select facility"
												data-dropup-auto="false" 
												data-live-search="true" 
												name="selectedFacilityWarrent"
												v-model="selectedFacilityWarrent"
												@change="getWarrents(selectedFacilityWarrent)"
											>
												<option
													v-for="fac in facilitiesWarrent"
													:value="fac.id"
													:key="fac.id"
												>
													{{ fac.facility_registration_id }}
												</option>
											</select>
										</div>
									</div>

									<div class="col-md-2 col-sm-12">
										<div class="form-group fc">
											<select
												class="selectpicker" 
												aria-label="select warrant" 
												title="select warrant"
												data-dropup-auto="false" 
												data-live-search="true" 
												name="selectedWarrent"
												v-model="selectedWarrent"
												@change="fetchWarrentTrackingData()"
											>
												<option
													v-for="col in warrents"
													:value="col.id"
													:key="col.id"
												>
													{{ col.reference_id }}
												</option>
											</select>
										</div>
									</div>
								</div>
							</div>

							<div class="card card-primary card-tabs mt-3">
                <div class="card-body">
                  <div class="row mt-3">
                    <div class="col-md-12 col-sm-12">
                      <div class="" style="margin: 20px">
                        <div class="progress">
													<div
														v-for="(step, index) in WarrentTracker"
														:key="index"
														class="progress-bar inner-step"
														:class="[
															step.quantity != null ? 'completed' : 'in-progress',
															step.isTotal ? 'total' : '',
															index !== 0 ? 'progress-arrow' : ''
														]"
														role="progressbar"
														:style="`width: ${step.width}%`"
														:aria-valuenow="step.ariaValuenow"
														aria-valuemin="0"
														aria-valuemax="100"
													>
														<strong>{{ step.name }}</strong><br>
														{{ step.quantity != null ? `${step.quantity} MT` : '' }}
													</div>
												</div>
                      </div>
                    </div>

                    <div class="col-md-12 col-sm-12">
                      <div
                        class="jst_fe progress-label"
                        style="
                          margin: 20px;
                          margin-top: 0px;
                          margin-bottom: 10px;
                        "
                      >
                        <div class="comp">
                          <i class="bi bi-square-fill"></i> At / Transferred
                        </div>
                        <div class="inp">
                          <i class="bi bi-square-fill"></i> Not Mapped/Allocated
                        </div>
                        <div class="tt">
                          <i class="bi bi-square-fill"></i> Total
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.content-wrapper -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
// import $ from "jquery";
// //Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function

Vue.component("apexchart", VueApexCharts);

export default {
  data() {
    return {
      currencyName: "",
      lastMonthValue: 0.0,
      PercentageChange: 0.0,
      todayDate: moment().format("DD MMMM YYYY"),
      top3Flag: true, // Fund Dropdown

      Colors: ["#1930AB", "#0070C0", "#00B0F0", "#7F7F7F", "#D9D9D9"],

      borrowers: [],
      facilities: [],
      facilitiesWarrent: [],
      collaterals: [],
      warrents: [],

      selectedBorrower: null,
      selectedFacility: null,
      selectedCollateral: null,
      selectedBorrowerWarrent: null,
      selectedFacilityWarrent: null,
      selectedWarrent: null,

      // Verification In Warehouse
      CollateralQuantityValueTNEChartOptions: {},
      CollateralQuantityValueTNESeries: [],
      CollateralQuantityValueTNEChartData: {},
      // CollateralQuantityValueTNEChartData: {
      //   xAxisData: ["Borrower Name"],
      //   seriesData: [
      //     {
      //       name: "Collateral Quantity",
      //       data: [5],
      //     },
      //     {
      //       name: "Collateral Value",
      //       data: [15],
      //     },
      //     {
      //       name: "Total Net Expense",
      //       data: [20],
      //     },
      //   ],
      // },
      CollateralQuantityValueTNEChartDataReady: false,

      ExposureData: [],

      // ExposureData: [
      //   { name: 'In Warehouse', duration: '5 Days' },
      //   { name: 'In Transit', duration: '5 Days' },
      //   { name: 'Gating', duration: '10 Days' },
      //   { name: 'Shipping', duration: '0 Days' },
      // ],

			warrentTrackerData: [],

			WarrentTracker: [
        { name: 'Uncleaned', quantity: null, width: 20, ariaValuenow: 15, isTotal: false },
        { name: 'Cleaning Process', quantity: null, width: 20, ariaValuenow: 30, isTotal: false },
        { name: 'Cleaned', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
        { name: 'Waste', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
        { name: 'Logistics Info', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
        { name: 'Shipping', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
        { name: 'Delivered', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
        { name: 'Total', quantity: null, width: 20, ariaValuenow: 20, isTotal: true }
      ],

      desiredTicks: 3,
      isLoading: false,

      isBasicDetailsSubmitted: false,
      isSubmitted: false,
    };
  },
  methods: {

    fetchCollateralQuantityValueTNEData() {
      // this.CollateralQuantityValueTNESeries = this.CollateralQuantityValueTNEChartData.seriesData.map(
      //   (series) => ({
      //     name: series.name,
      //     data: series.data, // Assuming this is already in the correct format for a bar chart
      //   })
      // );

      // console.log(
      //   "CollateralQuantityValueTNESeries ",
      //   this.CollateralQuantityValueTNESeries
      // );
      // this.setCollateralQuantityValueTNEChartOptionData(
      //   this.CollateralQuantityValueTNEChartData.xAxisData
      // );
      console.log("fetchCollateralQuantityValueTNE");
      this.$nextTick(() => {
        console.log("refresh");
        $(".selectpicker").selectpicker("refresh"); // If using selectpicker, refresh it after data load
      });
			let finalSelectedFacility = null
      if(this.selectedFacility == 'all'){
        finalSelectedFacility = this.facilities
					.filter(facility => facility.id !== 'all')
					.map(facility => facility.id);
        if(finalSelectedFacility.length == 0){
          finalSelectedFacility = null
        }
      } else {
				finalSelectedFacility = this.selectedFacility;
      }
			console.log('finalSelectedFacility',finalSelectedFacility)

			let finalSelectedCollateral = null
      if(this.selectedCollateral == 'all'){
        finalSelectedCollateral = this.collaterals.filter(col => col.id !== 'all').map(col => col.id);
        if(finalSelectedCollateral.length == 0){
          finalSelectedCollateral = null
        }
      } else{
        finalSelectedCollateral = this.selectedCollateral;
      }
			console.log('finalSelectedCollateral',finalSelectedCollateral)
      // Simulated API response
      const path =
        "get-collateral-quantity-value-tne-exposure/" +
        this.selectedBorrower +
        "/" +
        finalSelectedFacility +
        "/" +
        finalSelectedCollateral;
      console.log(path);
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.CollateralQuantityValueTNEChartData =
              res.data.CollateralQuantityValueTNEChartData;
            // For a bar chart, the series data structure can stay the same if it suits your data representation
            this.CollateralQuantityValueTNESeries = this.CollateralQuantityValueTNEChartData.seriesData.map(
              (series) => ({
                name: series.name,
                data: series.data, // Assuming this is already in the correct format for a bar chart
              })
            );

            console.log(
              "CollateralQuantityValueTNESeries ",
              this.CollateralQuantityValueTNESeries
            );
            this.setCollateralQuantityValueTNEChartOptionData(
              this.CollateralQuantityValueTNEChartData.xAxisData
            );
            // this.ExposureData = res.data.ExposureData

          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },

		fetchWarrentTrackingData() {
      console.log("fetchWarrentTrackingData");
      this.$nextTick(() => {
        console.log("refresh");
        $(".selectpicker").selectpicker("refresh"); // If using selectpicker, refresh it after data load
      });
			let finalSelectedFacility = null
      if(this.selectedFacility == 'all'){
        finalSelectedFacility = this.facilities
					.filter(facility => facility.id !== 'all')
					.map(facility => facility.id);
      } else {
				finalSelectedFacility = this.selectedFacility;
      }
			console.log('finalSelectedFacility',finalSelectedFacility)

			// let finalSelectedWarrent = this.selectedWarrent;
			let finalSelectedWarrent = null
      if(this.selectedWarrent == 'all'){
          finalSelectedWarrent = this.warrents.filter(col => col.id !== 'all').map(col => col.id);
        if(finalSelectedWarrent.length == 0){
          finalSelectedWarrent = null
        }
      } else{
        finalSelectedWarrent = this.selectedWarrent;
      }
			console.log('finalSelectedWarrent',finalSelectedWarrent)
      // Simulated API response
      const path =
        "get-warrent-tracking-data/" +
        this.selectedBorrower +
        "/" +
        finalSelectedFacility +
        "/" +
        finalSelectedWarrent;
      console.log(path);
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.warrentTrackerData = res.data.warrentTrackerData
            this.WarrentTracker = this.warrentTrackerData.map((data, index) => {
              // Default width and ariaValuenow for all items
              let width = 20;
              // let ariaValuenow = (index + 1) * 15;
              let ariaValuenow = 20;

              // Special handling for 'Total'
              const isTotal = data.name === 'Total';
              if (isTotal) {
                ariaValuenow = 20; // Set a specific value if needed
              }

              return {
                name: data.name,
                quantity: data.quantity !== "null" ? data.quantity : null, // Convert "null" string to null
                width: width,
                ariaValuenow: ariaValuenow,
                isTotal: isTotal
              };
            });            
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },
		
    setCollateralQuantityValueTNEChartOptionData(xAxisData) {
      // const yAxisTitleLength = this.CollateralQuantityValueTNEChartOptions.seriesData[0].name[0].length;
      // console.log("yAxisTitleLength",yAxisTitleLength)
      // Setting up chart options for a bar chart
      this.CollateralQuantityValueTNEChartOptions = {
        chart: {
          id: "vuechart-example",
          type: "bar", // Changed from 'line' to 'bar'
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: this.Colors, // Add your custom colors here
        yaxis: {
          // Set the y-axis name
          // title: {
          //   text: 'Amounts in millions',
          //   style: {
          //     fontFamily: 'Calibri',
          //     fontSize: '14px',
          //   },
          // },
          labels: {
            style: {
              fontFamily: "Calibri",
              fontSize: "12px",
            },
          },
        },
        xaxis: {
          type: "category",
          categories: xAxisData, // your original categories
          labels: {
            style: {
              fontFamily: "Calibri",
              fontSize: "12px",
            },
          },
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 20,
            bottom: 0,
            left: 0,
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          offsetX: 0,
          offsetY: 0,
          fontFamily: "Calibri",
          fontSize: "12px",
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
              // Check series name or index to apply the correct format
              if (w.config.series[seriesIndex].name === "Collateral Value") {
                return `${value} Lcs`;
              } else if (w.config.series[seriesIndex].name === "Collateral Quantity") {
                return `${value} MT`;
              }
              return value;
            }
          },
        },
      };
      this.CollateralQuantityValueTNEChartDataReady = true;
    },

    getWarrents() {
      console.log("getWarrents")
			let finalSelectedFacility = null
      if(this.selectedFacilityWarrent == 'all'){
        finalSelectedFacility = this.facilities
					.filter(facility => facility.id !== 'all')
					.map(facility => facility.id);
        if(finalSelectedFacility.length == 0){
          finalSelectedFacility = null
        }
      } else {
				finalSelectedFacility = this.selectedFacilityWarrent;
      }
			console.log('finalSelectedFacility',finalSelectedFacility)
      //this function is called to get list of collaterals by facility id
      const path = "/get-warrent-by-facility-all/" +
			"/" +
			this.selectedBorrowerWarrent +
			"/" +
			finalSelectedFacility;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.selectedWarrent = null;
          this.warrents = [];
          this.warrents = [...res.data.warrants];
          this.warrents.unshift({
							id: 'all',
							reference_id: 'All Warrants'
						});
          this.$nextTick(() => {
            console.log("refresh");
            $(".selectpicker").selectpicker("refresh"); // If using selectpicker, refresh it after data load
          });
          if (this.warrents.length > 1 || (this.warrents.length === 1 && this.warrents[0].id !== 'all')) {
            this.selectedWarrent = this.warrents[0].id;
            this.fetchWarrentTrackingData();
          } else {
            this.selectedWarrent = 'all';
            this.WarrentTracker = [
              { name: 'Uncleaned', quantity: null, width: 20, ariaValuenow: 15, isTotal: false },
              { name: 'Cleaning Process', quantity: null, width: 20, ariaValuenow: 30, isTotal: false },
              { name: 'Cleaned', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
              { name: 'Waste', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
              { name: 'Logistics Info', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
              { name: 'Shipping', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
              { name: 'Delivered', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
              { name: 'Total', quantity: null, width: 20, ariaValuenow: 20, isTotal: true }
            ]
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCollateral() {
			let finalSelectedFacility = null
      if(this.selectedFacility == 'all'){
        finalSelectedFacility = this.facilities
					.filter(facility => facility.id !== 'all')
					.map(facility => facility.id);
        if(finalSelectedFacility.length == 0){
          finalSelectedFacility = null
        }
      } else {
        console.log(this.selectedFacility)
        finalSelectedFacility = this.selectedFacility;
      }
      //this function is called to get list of collaterals by facility id
      const path = "/get-collateral-by-facility-all/" +
			"/" +
			this.selectedBorrower +
			"/" +
			finalSelectedFacility;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.selectedCollateral = null;
          this.collaterals = [];
          this.collaterals = [...res.data.collaterals];
					this.collaterals.unshift({
						id: 'all',
						collateral_name: 'All Collaterals'
					});
          this.$nextTick(() => {
            console.log("refresh");
            $(".selectpicker").selectpicker("refresh"); // If using selectpicker, refresh it after data load
          });
          if (this.collaterals.length > 0) {
            this.selectedCollateral = this.collaterals[0].id;
            this.fetchCollateralQuantityValueTNEData();
          } else {
            this.selectedCollateral = null;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFacilitySetupByBorrower(id,type) {
      //this function is called to get list of open facilities
      const path = "/facility-setup-borrower/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
					if(type == 1){
						this.selectedFacility = null;
						this.facilities = [];
						this.facilities = [...res.data.Facility_Setup];
						this.facilities.unshift({
							id: 'all',
							facility_registration_id: 'All Facilities'
						});
						this.$nextTick(() => {
							console.log("refresh");
							$(".selectpicker").selectpicker("refresh"); // If using selectpicker, refresh it after data load
						});
						if (this.facilities.length > 1 || (this.facilities.length === 1 && this.facilities[0].id !== 'all')) {
							this.selectedFacility = this.facilities[0].id;
							this.getCollateral();
						} else {
							this.selectedFacility = 'all';
              this.selectedCollateral = 'all';
              this.collaterals = [{ id: 'all', collateral_name: 'All Collaterals' }];
              this.CollateralQuantityValueTNEChartOptions = {};
              this.CollateralQuantityValueTNESeries = [];
              this.CollateralQuantityValueTNEChartData = {};
              this.CollateralQuantityValueTNEChartDataReady = false;
						}
					}else if(type == 2){
						this.selectedFacilityWarrent = null;
						this.facilitiesWarrent = [];
						this.facilitiesWarrent = [...res.data.Facility_Setup];
						this.facilitiesWarrent.unshift({
							id: 'all',
							facility_registration_id: 'All Facilities'
						});
						this.$nextTick(() => {
							console.log("refresh");
							$(".selectpicker").selectpicker("refresh"); // If using selectpicker, refresh it after data load
						});
						if (this.facilitiesWarrent.length > 1 || (this.facilitiesWarrent.length === 1 && this.facilitiesWarrent[0].id !== 'all')) {
							this.selectedFacilityWarrent = this.facilitiesWarrent[0].id;
							this.getWarrents();
						} else {
							this.selectedFacilityWarrent = 'all';
              this.selectedWarrent = 'all';
              this.warrents = [{ id: 'all', reference_id: 'All Warrants' }];
              this.WarrentTracker = [
                { name: 'Uncleaned', quantity: null, width: 20, ariaValuenow: 15, isTotal: false },
                { name: 'Cleaning Process', quantity: null, width: 20, ariaValuenow: 30, isTotal: false },
                { name: 'Cleaned', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
                { name: 'Waste', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
                { name: 'Logistics Info', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
                { name: 'Shipping', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
                { name: 'Delivered', quantity: null, width: 20, ariaValuenow: 20, isTotal: false },
                { name: 'Total', quantity: null, width: 20, ariaValuenow: 20, isTotal: true }
              ]
						}
					}
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function will be called to get list of borrowers
    getBorrowers() {
      const path = "borrower-master";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          this.selectedBorrower = null;
					this.selectedBorrowerWarrent = null;
          this.borrowers = [];
          this.borrowers = res.data.borrowers;
          // Create a copy of res.data.borrowers for borrowersWithAll
          this.$nextTick(() => {
						console.log("refresh");
            $(".selectpicker").selectpicker("refresh"); // If using selectpicker, refresh it after data load
          });
          if (this.borrowers.length > 0) {
            this.selectedBorrower = this.borrowers[0].borrower_id;
						this.selectedBorrowerWarrent = this.borrowers[0].borrower_id;
						this.getFacilitySetupByBorrower(this.selectedBorrower,1);
						this.getFacilitySetupByBorrower(this.selectedBorrowerWarrent,2);
				} else {
						this.selectedBorrowerWarrent = null;
            this.selectedBorrower = null;
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

  },
  created() {
    this.getBorrowers();
  },
};
</script>
