<!-- This is add Facility page. -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-plus"></i> Add Facility
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Fund Name</label
                      >
                      <select
                        id="selectedFund"
                        class="form-control"
                        name="selectedFund"
                        v-model="selectedFund"
                        @change="onFundSelect"
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedFund.$error,
                        }"
                      >
                        <option :value="null">-- Select --</option>
                        <option
                          v-for="fund in Funds"
                          :value="fund.fund_id"
                          :key="fund.id"
                        >
                          {{ fund.fund_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedFund.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedFund.required"
                          >Fund Name is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Borrower Name</label
                      >
                      <select
                        id="selectedBorrower"
                        class="form-control"
                        name="selectedBorrower"
                        v-model="selectedBorrower"
                        @change="onBorrowerNameSelect()"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedBorrower.$error,
                        }"
                        :disabled="type === 'facility' || loggedInUserGroupId == 'Borrower'"
                      >
                        <option :value="null">-- Select --</option>
                        <option
                          v-for="borrower in borrowers"
                          :value="borrower.borrower_id"
                          :key="borrower.borrower_id"
                        >
                          {{ borrower.borrower_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedBorrower.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedBorrower.required"
                          >Borrower Name is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Facility Limit</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder
                        v-model="FacilityLimit"
                        id="FacilityLimit"
                        
                        :class="{
                          'is-invalid': isSubmitted && $v.FacilityLimit.$error,
                        }"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                      />
                      <div
                        v-if="isSubmitted && $v.FacilityLimit.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.FacilityLimit.required"
                          >Facility Limit is required</span
                        >
                        <span v-if="!$v.FacilityLimit.maxLength"
                          >Max length of 15 characters exceeded</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Penalty Interest Rate(%)</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder
                        v-model="PenaltyInterestRate"
                        id="PenaltyInterestRate"
                        
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.PenaltyInterestRate.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.PenaltyInterestRate.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.PenaltyInterestRate.required"
                          >Penalty Interest Rate(%) is required<br/></span
                        >
                        <span v-if="!$v.PenaltyInterestRate.maxDigit"
                          >Max value of 3 digits exceeded</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- 
                  <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label>Country</label>
                    <select
                      id="selectedCountry"
                      class="form-control"
                      name="selectedCountry"
                      v-model="selectedCountry"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="country in countries"
                        :key="country.id"
                      >{{ country.country_name }}</option>
                    </select>
                  </div>
                  </div>-->

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Loan to Value(%)</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder
                        v-model="LoantoValue"
                        id="LoantoValue"
                        
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                        :class="{
                          'is-invalid': isSubmitted && $v.LoantoValue.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.LoantoValue.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.LoantoValue.required"
                          >Loan to Value % is required</span
                        >
                        <span v-if="!$v.LoantoValue.maxDigit"
                          >Max value of 3 digits exceeded</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Guarantor</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder
                        v-model="Guarantor"
                        id="Guarantor"
                        @keypress="isAlpha($event)"
                        :class="{
                          'is-invalid': isSubmitted && $v.Guarantor.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.Guarantor.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Guarantor.required"
                          >Guarantor is required</span
                        >
                        <span v-if="!$v.Guarantor.maxLength"
                          >Max length of 50 characters exceeded</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label>Funding Type</label>
                      <select
                        id="tokens"
                        class="form-control"
                        name="selectedFundingType"
                        v-model="selectedFundingType"
                      >
                        <option :value="null">-- Select Funding --</option>
                        <option
                          v-for="fundings in FundingType"
                          :key="fundings.id"
                        >
                          {{ fundings.funding_type_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label>Loan Disbursal</label>
                      <select
                        id="tokens"
                        class="form-control"
                        name="selectedLoanDisbursal"
                        v-model="selectedLoanDisbursal"
                      >
                        <option :value="null">
                          -- Select Loan Disbursal --
                        </option>
                        <option
                          v-for="disburs in LoanDisbursal"
                          :key="disburs.id"
                        >
                          {{ disburs.loan_disbursal_type_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label>Type of Loan</label>
                      <select
                        id="tokens"
                        class="form-control"
                        name="selectedLoanType"
                        v-model="selectedLoanType"
                      >
                        <option :value="null">-- Select Loan Type --</option>
                        <option v-for="type in LoanType" :key="type.id">
                          {{ type.loan_type_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Date of Facility</label
                      >
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="FacilityDate"
                        id="FacilityDate"
                        placeholder="Select Date"
                        v-model="FacilityDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :class="{
                          'is-invalid': isSubmitted && $v.FacilityDate.$error,
                        }"
                        :calendar-button="true"
                        :disabledDates="disabledDates"
                      ></datepicker>
                      <div
                        v-if="isSubmitted && $v.FacilityDate.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.FacilityDate.required"
                          >Facility Date is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Facility With</label
                      >
                      <select
                        id="selectedFacilityWith"
                        class="form-control"
                        name="selectedFacilityWith"
                        v-model="selectedFacilityWith"
                        @change="onFacilityWithSelect()"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedFacilityWith.$error,
                        }"
                      >
                        <option :value="null">-- Select --</option>
                        <option
                          v-for="fac in facilityWith"
                          :value="fac.id"
                          :key="fac.id"
                        >
                          {{ fac.facility_with_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedFacilityWith.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedFacilityWith.required"
                          >Facility With is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">Comments</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder
                        v-model="Comments"
                        id="comments"
                        :class="{
                          'is-invalid': isSubmitted && $v.Comments.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.Comments.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Comments.required"
                          >Max length of 50 characters exceeded</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Collateral Mapping</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addCollateral"
                              :disabled="collateralAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(col, index) in collateralList"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label class="required-field">Collateral</label>
                          <select
                            id="collateral"
                            class="form-control"
                            name="collateral"
                            v-model="col.collateral"
                            @change="onCollateralSelect(index)"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.collateralList.$each[index].collateral
                                  .$error,
                            }"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="c in collaterals"
                              :key="c.id"
                              :value="c.id"
                            >
                              {{ c.collateral_name }}
                            </option>
                          </select>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.collateralList.$each[index].collateral.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.collateralList.$each[index].collateral
                                  .required
                              "
                              >Collateral is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="uncleaned" class="required-field">Uncleaned</label>
                          <input
                            id="uncleaned"
                            class="form-control"
                            type="text"
                            v-model="col.uncleaned"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.collateralList.$each[index].uncleaned.$error,
                            }"
                          />
                          <div
                            v-if="
                              isSubmitted &&
                              $v.collateralList.$each[index].uncleaned.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.collateralList.$each[index].uncleaned
                                  .required
                              "
                              >Uncleaned is required<br/></span
                            >
                            <span
                              v-if="
                                !$v.collateralList.$each[index].uncleaned
                                  .maxDigit
                              "
                              >Max value of 3 digits exceeded</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="cleaned" class="required-field" style="padding-right: 20px"
                            >Cleaned</label
                          >
                          <input
                            id="cleaned"
                            class="form-control"
                            type="text"
                            v-model="col.cleaned"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.collateralList.$each[index].cleaned.$error,
                            }"
                          />
                          <div
                            v-if="
                              isSubmitted &&
                              $v.collateralList.$each[index].cleaned.$error
                            "
                            class="invalid-feedback"
                          >   
                            <span
                              v-if="
                                !$v.collateralList.$each[index].cleaned
                                  .required
                              "
                              >Cleaned is required<br/></span
                            >
                            <span
                              v-if="
                                !$v.collateralList.$each[index].cleaned.maxDigit
                              "
                              >Max value of 3 digits exceeded</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="waste" class="required-field">Waste</label>
                          <input
                            id="waste"
                            class="form-control"
                            type="text"
                            v-model="col.waste"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.collateralList.$each[index].waste.$error,
                            }"
                          />
                          <div
                            v-if="
                              isSubmitted &&
                              $v.collateralList.$each[index].waste.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.collateralList.$each[index].waste
                                  .required
                              "
                              >Waste is required<br/></span
                            >
                            <span
                              v-if="
                                !$v.collateralList.$each[index].waste.maxDigit
                              "
                              >Max value of 3 digits exceeded</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="logistics_info" class="required-field">Logistics Info</label>
                          <input
                            id="logistics_info"
                            class="form-control"
                            type="text"
                            v-model="col.logistics_info"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.collateralList.$each[index].logistics_info
                                  .$error,
                            }"
                          />
                          <div
                            v-if="
                              isSubmitted &&
                              $v.collateralList.$each[index].logistics_info
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.collateralList.$each[index].logistics_info
                                  .required
                              "
                              >Logistics Info is required<br/></span
                            >
                            <span
                              v-if="
                                !$v.collateralList.$each[index].logistics_info
                                  .maxDigit
                              "
                              >Max value of 3 digits exceeded</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="shipping" class="required-field">Shipping</label>
                          <input
                            id="shipping"
                            class="form-control"
                            type="text"
                            v-model="col.shipping"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.collateralList.$each[index].shipping.$error,
                            }"
                          />
                          <div
                            v-if="
                              isSubmitted &&
                              $v.collateralList.$each[index].shipping.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.collateralList.$each[index].shipping
                                  .required
                              "
                              >Shipping is required<br/></span
                            >
                            <span
                              v-if="
                                !$v.collateralList.$each[index].shipping
                                  .maxDigit
                              "
                              >Max value of 3 digits exceeded</span
                            >
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-md-1 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            style="width: 250px"
                            v-on:click="deleteCovenant(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div> -->
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onCollateralSaveInfo()"
                          :disabled="isDisabledCollateralSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Covenant Compliance</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addCovenant"
                              :disabled="covAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(cov, index) in covenantList"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label class="required-field">Covenants</label>
                          <select
                            id="covDocName"
                            class="form-control"
                            name="covDocName"
                            v-model="cov.covDocName"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.covenantList.$each[index].covDocName.$error,
                            }"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="covenant in covenants"
                              :key="covenant.id"
                            >
                              {{ covenant.covenants_name }}
                            </option>
                          </select>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.covenantList.$each[index].covDocName.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.covenantList.$each[index].covDocName
                                  .required
                              "
                              >Covenants is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" class="required-field"
                            >Review Date</label
                          >
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="UploadingDate"
                            id="UploadingDate"
                            placeholder="Select Date"
                            v-model="cov.UploadingDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{ from: new Date() }"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.covenantList.$each[index].UploadingDate
                                  .$error,
                            }"
                          ></datepicker>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.covenantList.$each[index].UploadingDate.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.covenantList.$each[index].UploadingDate
                                  .required
                              "
                              >Review Date is required</span
                            >
                          </div>
                          <!-- <input id="datepickerd" class="form-control" placeholder="Select Date" /> -->
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label class="required-field">Symbols</label>
                          <select
                            class="form-control"
                            v-model="cov.sign"
                            id="sign"
                            name="sign"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.covenantList.$each[index].sign.$error,
                            }"
                          >
                            <option :value="null">--- Select ---</option>
                            <option value="<">Less than</option>
                            <option value=">">Greater than</option>
                            <option value="<=">Less than equal to</option>
                            <option value=">=">Greater than equal to</option>
                            <option value="=">Equal to</option>
                          </select>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.covenantList.$each[index].sign.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="!$v.covenantList.$each[index].sign.required"
                              >Symbols is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" class="required-field"
                            >Expected Value</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder
                            v-model.number="cov.covExpectedValue"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                ($v.covenantList.$each[index].covExpectedValue
                                  .$error ||
                                  !$v.covenantList.$each[index].covExpectedValue
                                    .maxDigit),
                            }"
                            id="covExpectedValue"
                            name="covExpectedValue"
                            
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                          />
                          <div
                            v-if="
                              isSubmitted &&
                              ($v.covenantList.$each[index].covExpectedValue
                                .$error ||
                                !$v.covenantList.$each[index].covExpectedValue
                                  .maxDigit)
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.covenantList.$each[index].covExpectedValue
                                  .required
                              "
                              >Expected Value is required</span
                            >
                            <span
                              v-if="
                                !$v.covenantList.$each[index].covExpectedValue
                                  .maxDigit
                              "
                              >Max value of 8 digits exceeded</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" class="required-field"
                            >Actual Value</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder
                            v-model="cov.covActualValue"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                ($v.covenantList.$each[index].covActualValue
                                  .$error ||
                                  !$v.covenantList.$each[index].covActualValue
                                    .maxDigit),
                            }"
                            id="covActualValue"
                            name="covActualValue"
                            
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                          />
                          <div
                            v-if="
                              isSubmitted &&
                              ($v.covenantList.$each[index].covActualValue
                                .$error ||
                                !$v.covenantList.$each[index].covActualValue
                                  .maxDigit)
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.covenantList.$each[index].covActualValue
                                  .required
                              "
                              >Actual Value is required</span
                            >
                            <span
                              v-if="
                                !$v.covenantList.$each[index].covActualValue
                                  .maxDigit
                              "
                              >Max value of 8 digits exceeded</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label class="required-field">Compliant</label>
                          <input
                            class="form-control"
                            v-model="cov.compliant"
                            id="compliant"
                            disabled
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.covenantList.$each[index].compliant.$error,
                            }"
                          />{{
                            getCompliant(
                              cov.sign,
                              cov.covActualValue,
                              cov.covExpectedValue,
                              index
                            )
                          }}
                          <div
                            v-if="
                              isSubmitted &&
                              $v.covenantList.$each[index].compliant.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.covenantList.$each[index].compliant.required
                              "
                              >Please select symbol and enter the values</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Comments</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder
                            v-model="cov.comments"
                            id="CovComments"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.covenantList.$each[index].comments.$error,
                            }"
                          />
                          <div
                            v-if="
                              isSubmitted &&
                              $v.covenantList.$each[index].comments.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.covenantList.$each[index].comments.maxLength
                              "
                              >Max length of 50 characters exceeded</span
                            >
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-md-1 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            style="width: 250px"
                            v-on:click="deleteCovenant(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div> -->
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onCovSaveInfo()"
                          :disabled="isDisabledCovenantSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">CP Checklist</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addCpChecklist"
                              :disabled="CpChecklistAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(cp, index) in CpChecklist"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label class="required-field">Select Document</label>
                          <select
                            id="CpChecklistDocName"
                            class="form-control"
                            name="CpChecklistDocName"
                            v-model="cp.CpChecklistDocName"
                            :disabled="index !== CpChecklist.length - 1"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.CpChecklist.$each[index].CpChecklistDocName
                                  .$error,
                            }"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="document in CpChecklistDocuments"
                              :key="document.id"
                            >
                              {{ document.document_name }}
                            </option>
                          </select>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.CpChecklist.$each[index].CpChecklistDocName
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.CpChecklist.$each[index].CpChecklistDocName
                                  .required
                              "
                              >CP CheckList Doc Name is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" class="required-field"
                            >Upload Document</label
                          >
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="customFile"
                              name="filename"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              :class="{
                                'is-invalid':
                                  (isSubmitted &&
                                    cp.displayFileName === null) ||
                                  cp.errors.filesize,
                              }"
                              v-on:change="
                                handleFileUploadCpChecklist(
                                  index,
                                  $event,
                                  cp.CpChecklistDocName
                                )
                              "
                            />
                            <div
                              v-if="isSubmitted && cp.displayFileName === null"
                              class="invalid-feedback"
                            >
                              <span v-if="cp.displayFileName === null"
                                >CP CheckList Document is required</span
                              >
                            </div>
                            <small>
                              <p style="background-color: powderblue">
                                <b>{{ cp.displayFileName }}</b>
                                <small>
                                  <u
                                    style="color: blue; cursor: pointer"
                                    v-if="
                                      cp.CpChecklistRemoveDisabled === false
                                    "
                                    v-on:click="removeCpChecklistDoc(index)"
                                    >Remove</u
                                  >
                                </small>
                              </p>
                            </small>
                            <div
                              class="invalid-feedback"
                              v-if="cp.errors.filesize"
                            >
                              <span>
                                {{ cp.errors.filesize }}
                              </span>
                            </div>
                            <label class="custom-file-label" for="customFile"
                              >Choose file</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Issue Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="CpChecklistDate"
                            id="CpChecklistDate"
                            placeholder="Select Date"
                            v-model="cp.CpChecklistDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{ from: new Date() }"
                          ></datepicker>
                          <!-- <div
                            v-if="
                              isSubmitted &&
                                $v.CpChecklist.$each[index].CpChecklistDate
                                  .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.CpChecklist.$each[index].CpChecklistDate
                                  .required
                              "
                              >CP CheckList Issue Date is required</span
                            >
                          </div> -->
                          <!-- <input id="datepickerb" class="form-control" placeholder="Select Date" /> -->
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Expiry Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="ExpiryDate"
                            id="ExpiryDate"
                            placeholder="Select Date"
                            v-model="cp.ExpiryDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{
                              to: new Date(cp.CpChecklistDate),
                            }"
                          ></datepicker>
                          <!-- <input id="datepickerc" class="form-control" placeholder="Select Date" /> -->
                        </div>
                      </div>

                      <!-- <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label>Status</label>
                        <select
                          id="selectedCpChecklistStatus"
                          class="form-control"
                          name="selectedCpChecklistStatus"
                          v-model="selectedCpChecklistStatus"
                        >
                          <option :value="null">-- Select --</option>
                          <option value="Received">Received</option>
                          <option value="Approved">Approved</option>
                          <option value="Rejected">Rejected</option>
                        </select>
                      </div>
                      </div>-->

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Comments</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder
                            v-model="cp.comments"
                            id="CPComments"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.CpChecklist.$each[index].comments.$error,
                            }"
                          />
                          <div
                            v-if="
                              isSubmitted &&
                              $v.CpChecklist.$each[index].comments.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.CpChecklist.$each[index].comments.maxLength
                              "
                              >Max length of 50 characters exceeded</span
                            >
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-md-1 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            style="width: 250px"
                            v-on:click="deleteCpChecklist(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div> -->
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onCpChecklistSaveInfo()"
                          :disabled="isDisabledCpChecklistSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Enterprise Value</h1>
                      </div>
                      <!-- /.col -->
                      <!-- <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addEnterpriseValue"
                              :disabled="EnterpriseValueAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div> -->
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(ent, index) in EnterpriseValueList"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Equity(%)</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder
                            v-model="equity"
                            id="equity"
                            
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            :class="{
                              'is-invalid': isSubmitted && $v.equity.$error,
                            }"
                          />
                          <div
                            v-if="isSubmitted && $v.equity.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.equity.required">Equity cannot be zero</span>
                            <span v-if="!$v.equity.maxDigit"
                              >Equity cannot exceed 100%</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" class="required-field"
                            >Enterprise Value (In Millions)</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder
                            v-model="ent.EnterpriseValue"
                            id="EnterpriseValue"
                            :disabled="!equity"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.EnterpriseValueList.$each[index]
                                  .EnterpriseValue.$error,
                            }"
                          />
                          <div
                            v-if="
                              isSubmitted &&
                              $v.EnterpriseValueList.$each[index]
                                .EnterpriseValue.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.EnterpriseValueList.$each[index]
                                  .EnterpriseValue.required
                              "
                              >Please add Enterprise Value
                            </span>
                            <span
                              v-if="
                                !$v.EnterpriseValueList.$each[index]
                                  .EnterpriseValue.maxLength
                              "
                              >Max length of 5 characters exceeded
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" class="required-field"
                            >Date of value</label
                          >
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="ValuationDate"
                            id="ValuationDate"
                            placeholder="Select Date"
                            v-model="ent.ValuationDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.EnterpriseValueList.$each[index]
                                  .ValuationDate.$error,
                            }"
                            :calendar-button="true"
                            :disabledDates="{ from: new Date() }"
                            :disabled="!equity"
                          ></datepicker>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.EnterpriseValueList.$each[index].ValuationDate
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.EnterpriseValueList.$each[index]
                                  .ValuationDate.$required
                              "
                              >Enterprise Date of Value is required
                            </span>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-md-1 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            style="width: 250px"
                            v-on:click="deleteEnterpriseValue(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div> -->
                    </div>

                    <!-- <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onEnterpriseValueSaveInfo()"
                          :disabled="isDisabledEnterpriseValueSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <ul id="sr_btn">
                  <li>
                    <!-- <button onclick="window.location.href='investment_preview.html';"> -->
                    <button
                      type="button"
                      class="btn-sm btn btn-success sh"
                      v-on:click="onSubmit()"
                    >
                      Submit
                    </button>
                  </li>
                  <li>
                    <router-link :to="{ name: 'Facility' }">
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>
    <!-- ./wrapper -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      CpChecklistDocuments: [],
      selectedCpChecklistStatus: null,
      // CpChecklistStatus: [],
      //variables for Facility information
      selectedBorrower: null,
      borrowers: [],
      selectedFund: null,
      Funds: [],
      FacilityDate: new Date(),
      FacilityDate1: null,
      FacilityLimit: '',
      PenaltyInterestRate: null,
      selectedCountry: null,
      countries: [],
      collaterals: [],
      LoantoValue: null,
      Guarantor: null,
      selectedFundingType: null,
      FundingType: [],
      selectedLoanDisbursal: null,
      LoanDisbursal: [],
      selectedLoanType: null,
      LoanType: [],
      selectedFacilityWith: null,
      selectedFundCurrency: null,
      facilityWith: [
        {
          id: 0,
          facility_with_name: "Collateral",
        },
        // {
        //   id: 1,
        //   facility_with_name: "No Collateral",
        // },
      ],
      equity: null,
      Comments: null,
      type: null,

      //list for Collateral
      collateralList: [],
      collateralSaveInfoDisabled: true, //save info button is disabled
      collateralAddMoreDisabled: false,

      //list for Covenant Compliance
      covenants: [],
      covenantList: [],
      covFile: null,
      covSaveInfoDisabled: true, //save info button is disabled
      covAddMoreDisabled: true,

      CpChecklist: [],
      CpChecklistFile: null,
      // CpChecklistButtonDisabled: true,
      CpChecklistDisabled: true,
      CpChecklistSaveInfoDisabled: true, //save info button is disabled
      CpChecklistAddMoreDisabled: true,
      // getFacilityCpChecklistStatus: []
      // comments: null,
      // CpChecklistStatus: null,

      //list for EnterpriseValueList
      EnterpriseValueList: [],
      EnterpriseValueSaveInfoDisabled: true, //save info button is disabled
      EnterpriseValueAddMoreDisabled: true,
      BorRegDate: null,
      disabledDates: { to: null, from: new Date() },
      error: "",
      isSubmitted: false,
    };
  },
  validations: {
    selectedFund: {
      required,
    },
    selectedBorrower: {
      required,
    },
    FacilityLimit: {
      required: function () {
        if (
          this.FacilityLimit === null ||
          this.FacilityLimit == 0 ||
          this.FacilityLimit === ""
        ) {
          return false;
        } else {
          return true;
        }
      },
      maxLength: maxLength(15),
    },
    PenaltyInterestRate: {
      // validation for value greater than equal to 100
      required: function () {
        if (this.PenaltyInterestRate == '' || this.PenaltyInterestRate == null || this.PenaltyInterestRate == 0) return false;
        else return true;
      },
      maxDigit: function (val) {
        if (Number(val) > 100) return false;
        else return true;
      },
    },
    LoantoValue: {
      required: function () {
        if ((this.LoantoValue == '' || this.LoantoValue == null || this.LoantoValue == 0)) return false;
        else return true;
      },
      // validation for value greater than 999
      maxDigit: function (val) {
        if (Number(val) > 100) return false;
        else return true;
      },
    },
    Guarantor: {
      required,
      maxLength: maxLength(50),
    },
    equity: {
      required: function () {
        if (this.equity === "0") return false;
        else return true;
      },
      // validation for value greater than 2 digits 3 decimals
      maxDigit: function (val) {
        if (Number(val) > 100)
          return false;
        else return true;
      },
    },
    FacilityDate: {
      required,
    },
    selectedFacilityWith: {
      required,
    },
    Comments: {
      maxLength: maxLength(50),
    },
    collateralList: {
      $each: {
        collateral: {
          required: function (val) {
            if ((!val || val == 0) && (this.selectedFacilityWith == 0)) return false;
            else return true;
          },
        },
        uncleaned: {
          required: function (val) {
            if ((!val || val == 0) && (this.selectedFacilityWith == 0)) return false;
            else return true;
          },
          // validation for value greater than 100
          maxDigit: function (val) {
            if (Number(val) > 100) return false;
            else return true;
          },
        },
        cleaned: {
          required: function (val) {
            if ((!val || val == 0) && (this.selectedFacilityWith == 0)) return false;
            else return true;
          },
          // validation for value greater than 100
          maxDigit: function (val) {
            if (Number(val) > 100) return false;
            else return true;
          },
        },
        waste: {
          required: function (val) {
            if ((val == null || val == "") && (this.selectedFacilityWith == 0)) return false;
            else return true;
          },
          // validation for value greater than 100
          maxDigit: function (val) {
            if (Number(val) > 100) return false;
            else return true;
          },
        },
        logistics_info: {
          required: function (val) {
            if ((!val || val == 0) && (this.selectedFacilityWith == 0)) return false;
            else return true;
          },
          // validation for value greater than 100
          maxDigit: function (val) {
            if (Number(val) > 100) return false;
            else return true;
          },
        },
        shipping: {
          required: function (val) {
            if ((!val || val == 0) && (this.selectedFacilityWith == 0)) return false;
            else return true;
          },
          // validation for value greater than 100
          maxDigit: function (val) {
            if (Number(val) > 100) return false;
            else return true;
          },
        },
      },
    },
    covenantList: {
      $each: {
        covDocName: {
          required,
        },
        UploadingDate: {
          required,
        },
        sign: {
          required,
        },
        covExpectedValue: {
          required: function (val) {
            if (val === null || val == 0 || val === "") {
              return false;
            } else {
              return true;
            }
          },
          // validation for value greater than 8
          maxDigit: function (val) {
            if (Number(val) > 99999999.99) return false;
            else return true;
          },
        },
        covActualValue: {
          required: function (val) {
            if (val === null || val == 0 || val === "") {
              return false;
            } else {
              return true;
            }
          },
          // validation for value greater than 8
          maxDigit: function (val) {
            if (Number(val) > 99999999.99) return false;
            else return true;
          },
        },
        compliant: {
          required,
        },
        comments: {
          maxLength: maxLength(50),
        },
      },
    },
    CpChecklist: {
      $each: {
        CpChecklistDocName: {
          required,
        },
        comments: {
          maxLength: maxLength(50),
        },
        // CpChecklistDate: {
        //   required,
        // },
      },
    },
    EnterpriseValueList: {
      $each: {
        EnterpriseValue: {
          required: function (val) {
            if (
              (val === null || val == 0 || val === "") &&
              this.equity !== null &&
              this.equity !== "" &&
              this.equity != 0
            )
              return false;
            else return true;
          },
          maxLength: function (val) {
            if (
              (val ? val.length > 5 : false) &&
              this.equity !== null &&
              this.equity !== "" &&
              this.equity != 0
            )
              return false;
            else return true;
          },
        },
        ValuationDate: {
          required: function (val) {
            if (
              !val &&
              this.equity !== null &&
              this.equity !== "" &&
              this.equity != 0
            )
              return false;
            else return true;
          },
        },
      },
    },
  },
  computed: {
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
    //this function returns the complaint value according to set sign value and its expected & actual values
    isCompliant: function () {
      return function (i, j, k) {
        if (i == ">") {
          if (Number(j) > Number(k)) {
            return "Yes";
          } else {
            return "No";
          }
        } else if (i == "<") {
          if (Number(j) < Number(k)) {
            return "Yes";
          } else {
            return "No";
          }
        } else if (i == ">=") {
          if (Number(j) >= Number(k)) {
            return "Yes";
          } else {
            return "No";
          }
        } else if (i == "<=") {
          if (Number(j) <= Number(k)) {
            return "Yes";
          } else {
            return "No";
          }
        } else if (i == "=") {
          if (Number(j) == Number(k)) {
            return "Yes";
          } else {
            return "No";
          }
        }
      };
    },
    //this is to computed disabled property of main Submit button to save information in database.
    isDisabledOnSubmit: function () {
      if (
        this.selectedBorrower == null ||
        this.selectedBorrower == "" ||
        this.CpChecklistSaveInfoDisabled === true ||
        this.covSaveInfoDisabled === true
      ) {
        //here we will check for all sections
        return true;
      }
    },

    //this is to computed disabled property of Collateral Save Info button.
    isDisabledCollateralSaveInfo: function () {
      let checkEmptyCollateralName = this.collateralList.filter(
        (line) => line.collateral === null
      );
      //if the line is empty then disable save info button
      if (
        this.collateralList.length === 0 &&
        this.collateralSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyCollateralName.length >= 1 &&
        this.collateralList.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledCollateralSaveInfo() close

    //this is to computed disabled property of Covenants Save Info button.
    isDisabledCovenantSaveInfo: function () {
      let checkEmptycovenantName = this.covenantList.filter(
        (line) => line.covDocName === null
      );
      let checkEmptyCovDate = this.covenantList.filter(
        (line) => line.UploadingDate === null
      );
      let checkEmptyActualValue = this.covenantList.filter(
        (line) => line.covActualValue === null
      );
      let checkEmptyExpectedValue = this.covenantList.filter(
        (line) => line.covExpectedValue === null
      );
      let checkEmptyCompliant = this.covenantList.filter(
        (line) => line.compliant === null
      );
      let checkEmptySign = this.covenantList.filter(
        (line) => line.sign === null
      );
      //if the line is empty then disable save info button
      if (this.covenantList.length === 0 && this.covSaveInfoDisabled === true) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptycovenantName.length >= 1 && this.covenantList.length > 0) ||
        (checkEmptyCovDate.length >= 1 && this.covenantList.length > 0) ||
        (checkEmptyActualValue.length >= 1 && this.covenantList.length > 0) ||
        (checkEmptyExpectedValue.length >= 1 && this.covenantList.length > 0) ||
        (checkEmptyCompliant.length >= 1 && this.covenantList.length > 0) ||
        (checkEmptySign.length >= 1 && this.covenantList.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledCovenantSaveInfo() close

    //this is to computed disabled property of CpChecklist Save Info button.
    isDisabledCpChecklistSaveInfo: function () {
      let checkEmptyCpChecklistDocName = this.CpChecklist.filter(
        (line) => line.CpChecklistDocName === null
      );
      // let checkEmptyCpChecklistDate = this.CpChecklist.filter(
      //   (line) => line.CpChecklistDate === null
      // );
      //if the line is empty then disable save info button
      if (
        this.CpChecklist.length === 0 &&
        this.CpChecklistSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyCpChecklistDocName.length >= 1 &&
        this.CpChecklist.length > 0
        // (checkEmptyCpChecklistDate.length >= 1 && this.CpChecklist.length > 0)
      ) {
        this.CpChecklistDisabled = true;
      } else {
        this.CpChecklistDisabled = false;
      }
      //CpChecklistDisabled variable is to check whther all input fields except file upload are filled. CpChecklistFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (this.CpChecklistDisabled === true || this.CpChecklistFile === null) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledCpChecklistSaveInfo() close

    //this is to computed disabled property of Key Mgmt Save Info button.
    isDisabledEnterpriseValueSaveInfo: function () {
      let checkEmptyEnterpriseValueName = this.EnterpriseValueList.filter(
        (line) => line.EnterpriseValue === null
      );
      let checkEmptyEnterpriseValueDate = this.EnterpriseValueList.filter(
        (line) => line.ValuationDate === null
      );
      //if the line is empty then disable save info button
      if (
        this.EnterpriseValueList.length === 0 &&
        this.EnterpriseValueSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enable
      if (
        (checkEmptyEnterpriseValueName.length >= 1 &&
          this.EnterpriseValueList.length > 0) ||
        (checkEmptyEnterpriseValueDate.length >= 1 &&
          this.EnterpriseValueList.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledEnterpriseValueSaveInfo() close
  },
  components: {
    Datepicker,
  },
  methods: {
    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },

    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    validateNumber(e) {
      const value = e.target.value;
      // Replace anything that's not a digit or a decimal point with an empty string
      e.target.value = value.replace(/[^\d.]/g, '')
                             .replace(/(\..*?)\..*/g, '$1') // Only one decimal point
                             .replace(/(\.\d{2})./g, '$1'); // Two decimal places
      // Additional validation logic to ensure the value is a number can go here
    },


    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },


    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },
    isAlpha(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    getCompliant(sign, actual, expected, index) {
      this.covenantList[index].compliant = this.isCompliant(
        sign,
        actual,
        expected
      );
    },
    onFundSelect() {
      const path = "get-fund-currency" + "/" + this.selectedFund;
      axios
        .get(path)
        .then((res) => {
          this.selectedFundCurrency = res.data.fund_currency;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onBorrowerNameSelect() {
      const path = "/get_borrower_regdate/" + this.selectedBorrower;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.BorRegDate = res.data.borrower_reg_date.registration_date;
          this.disabledDates.to = new Date(this.BorRegDate);
          // this.BorRegDate = moment.utc(this.BorRegDate, "MM-DD-YYYY").toDate();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    onFacilityWithSelect(){
      console.log("onFacilityWithSelect")
      console.log("onFacilityWithSelect",this.selectedFacilityWith)
      if(this.selectedFacilityWith == 1 || this.selectedFacilityWith == null){
        console.log("onFacilityWithSelect 1")
        this.collateralList = []
      } else {
        if (this.collateralList.length == 0){
        console.log("onFacilityWithSelect 2")
          this.addCollateral()
        }
      }
    },

    // this method will check if existing collateral is added again
    onCollateralSelect(i) {
      let collateral_count = this.collateralList.filter(
        (col) => col.collateral === this.collateralList[i].collateral
      ).length;
      // delete if existing collateral is found
      if (collateral_count > 1) {
        this.deleteCollateral(i);
        Utils.make_alert("warning", "Collateral already exists");
      }

      this.collateralList[i].uncleaned = this.LoantoValue
      this.collateralList[i].cleaned = this.LoantoValue
      this.collateralList[i].waste = '0'
      this.collateralList[i].logistics_info = this.LoantoValue
      this.collateralList[i].shipping = this.LoantoValue
    },

    //this method will make disabled property as false for Collateral Save Info
    onCollateralSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.collateralSaveInfoDisabled = false;
      this.collateralAddMoreDisabled = false;
    },

    //this method will make disabled property as false for Covenant Save Info
    onCovSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.covSaveInfoDisabled = false;
      this.covAddMoreDisabled = false;
    },

    //this method will make disabled property as false for CPChecklist Save Info
    onCpChecklistSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.CpChecklistSaveInfoDisabled = false;
      this.CpChecklistAddMoreDisabled = false;
    },

    //this method will make disabled property as false for Enterprise Save Info
    onEnterpriseValueSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.EnterpriseValueSaveInfoDisabled = false;
      this.EnterpriseValueAddMoreDisabled = false;
    },

    // deleteCovenant(index) {
    //   this.covenantList.splice(index, 1);
    //   if (
    //     typeof this.covenantList !== "undefined" &&
    //     this.covenantList.length == 0
    //   ) {
    //     this.covAddMoreDisabled = false;
    //     this.covSaveInfoDisabled = true;
    //   }
    // },

    deleteCollateral(index) {
      this.collateralList.splice(index, 1);
      if (
        typeof this.collateralList !== "undefined" &&
        this.collateralList.length == 0
      ) {
        this.collateralAddMoreDisabled = false;
        this.collateralSaveInfoDisabled = true;
      }
    },

    deleteCpChecklist(index) {
      this.CpChecklist.splice(index, 1);
      if (
        typeof this.CpChecklist !== "undefined" &&
        this.CpChecklist.length == 0
      ) {
        this.CpChecklistAddMoreDisabled = false;
        this.CpChecklistSaveInfoDisabled = true;
      }
    },

    deleteEnterpriseValue(index) {
      this.EnterpriseValueList.splice(index, 1);
      if (
        typeof this.EnterpriseValueList !== "undefined" &&
        this.EnterpriseValueList.length == 0
      ) {
        this.EnterpriseValueAddMoreDisabled = false;
        this.EnterpriseValueSaveInfoDisabled = true;
      }
    },
    //this method will submit the facility form in backend.
    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.collateralList.length == 0 && this.selectedFacilityWithName == 0) {
        let warningMessage = `Please Map Some Collateral`;
        this.addCollateral();
        Utils.make_alert("warning", warningMessage);
        return;
      }

      if (this.FacilityDate != null) {
        this.FacilityDate1 = moment(this.FacilityDate).format("MM-DD-YYYY");
      }
      if (this.covenantList.length) {
        this.covenantList.map((cov) => {
          cov.covActualValue = Number(cov.covActualValue).toFixed(2);
          cov.covExpectedValue = Number(cov.covExpectedValue).toFixed(2);
        });
      }
      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("borrowerName", this.selectedBorrower);
      formData.append("fundId", this.selectedFund);
      formData.append("selectedFundCurrency", this.selectedFundCurrency);
      formData.append("facilityLimit", this.FacilityLimit);
      formData.append("penaltyInterestRate", this.PenaltyInterestRate);
      formData.append("loanToValue", this.LoantoValue);
      formData.append("guarantorName", this.Guarantor);
      formData.append("selectedFundingType", this.selectedFundingType);
      formData.append("selectedLoanDisbursal", this.selectedLoanDisbursal);
      formData.append("selectedLoanType", this.selectedLoanType);
      formData.append("selectedFacilityWith", this.selectedFacilityWith);
      formData.append("Comments", this.Comments);
      formData.append("facilityRequestDate", this.FacilityDate1);
      formData.append("equity", this.equity);
      //append collateral information to formdata
      formData.append("collateralMapping", JSON.stringify(this.collateralList));
      //append covenant information to formdata
      formData.append("facilityCovenant", JSON.stringify(this.covenantList));
      //append CpChecklist information to formdata
      formData.append("facilityChecklist", JSON.stringify(this.CpChecklist));
      //append enterpriseValue information to formdata
      if (this.equity !== null && this.equity !== "") {
        formData.append(
          "enterpriseValue",
          JSON.stringify(this.EnterpriseValueList)
        );
      } else {
        formData.append("enterpriseValue", JSON.stringify([]));
      }
      formData.append("clientId", this.$store.state.client_id);
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 4);
      formData.append("tabId", 14);

      const path = "add_facility";
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          console.log("status: " + response.data.status);
          if (response.data.status == "success") {
            Utils.make_alert("success", "Facility added successfully!");
            console.log("SUCCESS!!");
            this.$router.push("/facility");
          } else {
            Utils.make_alert("warning", "Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          Utils.make_alert(
            "warning",
            error.response.status === 400
              ? error.response.data.status
              : "Something went wrong!"
          );
          console.error(error);
        });
    }, //onSubmit() close

    //this method will add new line for additional collaterals
    addCollateral() {
      this.isSubmitted = true;
      this.$v.LoantoValue.$touch();
      this.$v.selectedFacilityWith.$touch();
      if (
          this.$v.LoantoValue.$invalid ||
          this.$v.selectedFacilityWith.$invalid 
        ) {
          console.log("Invalid")
          return;
        }
      //when new line is added then collateralSaveInfoDisabled (Save info button disable property) should be re initialised to true.
      (this.collateralSaveInfoDisabled = true),
        (this.collateralAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.collateralList.push({
          collateral: null,
          uncleaned: null,
          cleaned: null,
          waste: null,
          logistics_info: null,
          shipping: null,
        });
    }, //addCollateral() close

    //this method will add new line for additional covenants
    addCovenant() {
      //when new line is added then covSaveInfoDisabled (Save info button disable property) should be re initialised to true.
      (this.covSaveInfoDisabled = true),
        (this.covAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.covenantList.push({
          covDocName: null,
          UploadingDate: null,
          covActualValue: null,
          covExpectedValue: null,
          comments: null,
          compliant: "Yes",
          sign: null,
        });
    }, //addCovenant() close

    //this method will add new line for additional directors
    addCpChecklist() {
      //when new line is added then CpChecklistFile variable and CpChecklistSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      // (this.CpChecklistFile = null),
      (this.CpChecklistSaveInfoDisabled = true),
        (this.CpChecklistAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.CpChecklist.push({
          CpChecklistDocName: null,
          CpChecklistDate: null,
          ExpiryDate: null,
          comments: null,
          displayFileName: null,
          errors: { filesize: "" },
          CpChecklistRemoveDisabled: true,
        });
    }, //addCpChecklist() close

    //handles a change on the file upload
    handleFileUploadCpChecklist(id, e, doc) {
      let file_list = this.CpChecklist.map((file) => file.displayFileName);
      let doc_list = this.CpChecklist.slice(0, -1).map(
        (doc) => doc.CpChecklistDocName
      );
      // check if file already exists for same document name, show pop if true and delete record
      if (
        file_list.includes(e.target.files[0].name) &&
        doc_list.includes(doc)
      ) {
        this.deleteCpChecklist(this.CpChecklist.length - 1);
        Utils.make_alert("warning", "File Already Exists");
        return;
      }
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.CpChecklistFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.CpChecklistFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors.filesize = "File size should be less than 100MB";
          self.CpChecklist[id].errors.filesize = JSON.stringify(
            response.errors.filesize
          ).replace(/\"/g, "");
          self.CpChecklist[id].selectedFile = null;
          self.CpChecklist[id].selectedFileName = null;
          self.CpChecklist[id].displayFileName = null;
          self.CpChecklist[id].CpChecklistRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.CpChecklistFile);
        reader.onload = function () {
          self.CpChecklist[id].selectedFile = reader.result;
          self.CpChecklist[id].selectedFileName = e.target.files[0].name;
          self.CpChecklist[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.CpChecklist[id].errors.filesize = "";
          self.CpChecklist[id].CpChecklistRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadCpChecklist() close

    ////this method handles remove cp attachment
    removeCpChecklistDoc(id) {
      this.CpChecklistFile = null;
      this.CpChecklist[id].selectedFile = null;
      this.CpChecklist[id].selectedFileName = null;
      this.CpChecklist[id].displayFileName = null;
      this.CpChecklist[id].CpChecklistRemoveDisabled = true;
    },

    //this method will add new line for additional directors
    addEnterpriseValue() {
      //when new line is added then EnterpriseValueSaveInfoDisabled (Save info button disable property) should be re initialised to true.
      (this.EnterpriseValueSaveInfoDisabled = true),
        (this.EnterpriseValueAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.EnterpriseValueList.push({
          EnterpriseValue: null,
          ValuationDate: null,
        });
    }, //addEnterpriseValue() close

    //this function is called to get list of documents master
    getCpChecklistDocuments() {
      const path = "facility-document";
      axios
        .get(path)
        .then((res) => {
          this.CpChecklistDocuments = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getCpChecklistDocuments() close

    getCovenants() {
      //this function is called to get list of covenants
      const path = "/covenant";
      axios
        .get(path)
        .then((res) => {
          this.covenants = res.data.covenants;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getCovenants() close

    getDayCount() {
      //this function is called to get list of day count
      const path = "day-count-master";
      axios
        .get(path)
        .then((res) => {
          this.dayCounts = res.data.day_counts;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBenchmarks() {
      //this function is called to get list of benchmarks
      const path = "benchmark";
      axios
        .get(path)
        .then((res) => {
          this.benchmarks = res.data.benchmarks;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFundingType() {
      //this function is called to get list of funding
      const path = "funding-type";
      axios
        .get(path)
        .then((res) => {
          this.FundingType = res.data.funding;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLoanDisbursal() {
      //this function is called to get list of funding
      const path = "loan-disbursal";
      axios
        .get(path)
        .then((res) => {
          this.LoanDisbursal = res.data.loan_disbursal;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLoanType() {
      //this function is called to get list of funding
      const path = "loan-type";
      axios
        .get(path)
        .then((res) => {
          this.LoanType = res.data.loan_type;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCollateral() {
      //this function is called to get list of Collateral
      const path = "collateral";
      axios
        .get(path)
        .then((res) => {
          this.collaterals = res.data.collateral;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBorrowers() {
      const path = "borrower-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.borrowers = res.data.borrowers;
          if (this.loggedInUserGroupId === "Borrower") {
            this.borrowers.filter((bor) => {
              if (bor.borrower_id.toString() === this.loggedInUserSubGroupId)
                this.selectedBorrower = bor.borrower_id;
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFunds() {
      const path = "fund-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.Funds = res.data.funds;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getInterestTypes() {
      //this function is called to get list of interest types
      const path = "interest-type";
      axios
        .get(path)
        .then((res) => {
          this.interestTypes = res.data.interest_types;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of countries
    getCountries() {
      const path = "country";
      axios
        .get(path)
        .then((res) => {
          this.countries = res.data.countries;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getCountries() close

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close

  mounted() {
    // this.addCollateral();
    this.addCpChecklist();
    this.addCovenant();
    this.addEnterpriseValue();
    this.BorRegDate = this.$route.params.BorRegDate;
    this.disabledDates.to = new Date(this.BorRegDate);
    this.selectedBorrower = this.$route.params.borrower_id
      ? this.$route.params.borrower_id
      : null;
    this.type = this.$route.params.type;
  },

  created() {
    this.getCountries();
    this.getCpChecklistDocuments();
    this.getBenchmarks();
    this.getFundingType();
    this.getLoanDisbursal();
    this.getLoanType();
    this.getCollateral();
    this.getCurrencies();
    this.getBorrowers();
    this.getFunds();
    this.getDayCount();
    this.getInterestTypes();
    this.getCovenants();
  },
};
</script>
