<!-- This is add collateral page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Add Shipping Info
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedBorrower" class="required-field"
                      >Borrower Name</label
                    >
                    <select
                      id="selectedBorrower"
                      class="form-control"
                      name="selectedBorrower"
                      v-model="selectedBorrower"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedBorrower.$error,
                      }"
                      @change="
                        getFacilitySetupByBorrower(selectedBorrower) &
                        getOfftakerNameByBorrower(selectedBorrower) &
                        getWarehouseByBorrower(selectedBorrower) 
                      "
                      :disabled="!directAdd"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="bor in borrowers"
                        :value="bor.borrower_id"
                        :key="bor.borrower_id"
                      >
                        {{ bor.borrower_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedBorrower.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedBorrower.required"
                        >Borrower Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedFacilityId" class="required-field"
                      >Facility ID</label
                    >
                    <select
                      id="selectedFacilityId"
                      class="form-control"
                      name="selectedFacilityId"
                      v-model="selectedFacilityId"
                      :disabled="!directAdd"
                      @change="getCollateral(selectedFacilityId)"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedFacilityId.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="fac in facilities"
                        :value="fac.id"
                        :key="fac.id"
                      >
                        {{ fac.facility_registration_id }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedFacilityId.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedFacilityId.required"
                        >Facility ID is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCollateral" class="required-field"
                      >Collateral</label
                    >
                    <select
                      id="selectedCollateral"
                      class="form-control"
                      name="selectedCollateral"
                      v-model="selectedCollateral"
                      :disabled="!directAdd"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedCollateral.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="col in collaterals"
                        :value="col.id"
                        :key="col.id"
                      >
                        {{ col.collateral_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCollateral.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCollateral.required"
                        >Collateral is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCollateralType" class="required-field"
                      >Collateral Type</label
                    >
                    <select
                      id="selectedCollateralType"
                      class="form-control"
                      name="selectedCollateralType"
                      v-model="selectedCollateralType"
                      @change="onCollateralTypeChange()"
                      :disabled="!directAdd"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedCollateralType.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="col in collateralStages"
                        :value="col.id"
                        :key="col.id"
                      >
                        {{ col.stage_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCollateralType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCollateralType.required"
                        >Collateral Type is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-5 col-6" v-if="directAdd">
                  <div class="form-group fc">
                    <label for="selectedWarehouse" class="required-field"
                      >Warehouse</label
                    >
                    <select
                      id="selectedWarehouse"
                      class="form-control"
                      name="selectedWarehouse"
                      v-model="selectedWarehouse"
                      @change="onWarehouseChange()"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedWarehouse.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="war in warehouses"
                        :value="war.id"
                        :key="war.id"
                      >
                        {{ war.warehouse_registration_id + ": " }}
                        {{ war.address }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedWarehouse.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedWarehouse.required"
                        >Warehouse is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCM" class="required-field"
                      >Collateral Manager Company</label
                    >
                    <select
                      id="selectedCM"
                      class="form-control"
                      name="selectedCM"
                      v-model="selectedCM"
                      :disabled="loggedInUserGroupId === 'Collateral Manager'"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedCM.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="cm in mappedCM"
                        :value="cm.collateral_manager_master_id"
                        :key="cm.collateral_manager_master_id"
                      >
                        {{ cm.collateral_id }}: {{ cm.company_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCM.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCM.required"
                        >Collateral Manager Company is required</span
                      >
                    </div>
                    <!-- <div v-if="emptyCMListError" class="invalid-feedback">
                      <span
                        >No Collateral Manager Company is available for the
                        selected warehouse</span
                      >
                    </div> -->
                  </div>
                </div>

                <!-- <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedOfftaker" class="required-field"
                      >Offtaker Name</label
                    >
                    <select
                      id="selectedOfftaker"
                      class="form-control"
                      name="selectedOfftaker"
                      v-model="selectedOfftaker"
                      :disabled="!directAdd"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedOfftaker.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="off in offtakers"
                        :value="off.id"
                        :key="off.id"
                      >
                        {{ off.offtaker_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedOfftaker.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedOfftaker.required"
                        >Offtaker Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="offtakerContract">Offtaker Contract</label>
                    <select
                      id="selectedOfftakerContract"
                      class="form-control"
                      name="selectedOfftakerContract"
                      v-model="selectedOfftakerContract"
                    >
                      <option :value="null">--- Select ---</option>
                      <option value="CN0023">CN0023</option>
                      <option value="CN0025">CN0025</option>
                    </select>
                  </div>
                </div> -->

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedOfftakerID" class="required-field"
                      >Offtaker Name</label
                    >
                    <select
                      id="selectedOfftakerID"
                      class="form-control"
                      v-model="selectedOfftakerID"
                      @change="getContractNumberByOfftakerName()"
                      :class="{
                        'is-invalid':
                        isSubmitted && $v.selectedOfftakerID.$error,
                      }"
                      :disabled="!directAdd"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="ofn in offtakerNameIDs"
                        :value="ofn.id"
                        :key="ofn.id"
                      >
                        {{ ofn.offtaker_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedOfftakerID.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedOfftakerID.required"
                        >Offtaker Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div v-if="directAdd" class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedContractNumber" class="required-field"
                      >Contract No.</label
                    >
                    <select
                      id="selectedContractNumber"
                      class="form-control"
                      v-model="selectedContractNumber"
                      @change="getContractNumberDetails()"
                      :class="{
                        'is-invalid':
                        isSubmitted && $v.selectedContractNumber.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                        <option
                          v-for="ofn in contractNumber"
                          :value="ofn.contractId"
                          :key="ofn.contractId"
                        >
                        {{ ofn.contractNo }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedContractNumber.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedContractNumber.required"
                        >Contract No. is required</span
                      >
                    </div>
                  </div>
                </div>

                <div v-if="(this.partialContractNumber.length > 0 && this.selectedContractNumber)" class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" :class="{'required-field':logisticsShippingFlag > 0}"
                      >Partial Contract No.</label
                    >
                    <select
                      id="selectedPartialContractNumber"
                      class="form-control"
                      name="selectedPartialContractNumber"
                      v-model="selectedPartialContractNumber"
                      @change="getPartialContractNumberDetails()"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedPartialContractNumber.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="cn in partialContractNumber"
                        :value="cn.partialContractId"
                        :key="cn.partialContractId"
                      >
                        {{ cn.partialContractNo }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedPartialContractNumber.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedPartialContractNumber.required"
                        >Partial Contract No. is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedShippingPort" class="required-field"
                      >Shipping Port</label
                    >
                    <select
                      id="selectedShippingPort"
                      class="form-control"
                      name="selectedShippingPort"
                      v-model="selectedShippingPort"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedShippingPort.$error,
                      }"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="port in Ports"
                        :value="port.id"
                        :key="port.id"
                      >
                        {{ port.name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedShippingPort.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedShippingPort.required"
                        >Shipping Port is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedDestinationPort" class="required-field"
                      >Destination Port</label
                    >
                    <select
                      id="selectedDestinationPort"
                      class="form-control"
                      name="selectedDestinationPort"
                      v-model="selectedDestinationPort"
                      :class="{
                        'is-invalid':
                          (isSubmitted && $v.selectedDestinationPort.$error) ||
                          (selectedShippingPort === selectedDestinationPort &&
                            selectedDestinationPort !== null),
                      }"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="port in Ports"
                        :value="port.id"
                        :key="port.id"
                      >
                        {{ port.name }}
                      </option>
                    </select>
                    <div
                      v-if="
                        (isSubmitted && $v.selectedDestinationPort.$error) ||
                        (selectedShippingPort === selectedDestinationPort &&
                          selectedDestinationPort !== null)
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedDestinationPort.required"
                        >Destination Port is required</span
                      >
                      <span
                        v-if="
                          selectedShippingPort === selectedDestinationPort &&
                          selectedDestinationPort !== null
                        "
                        >Shipping and Destination Port cannot be same</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="shippingLine" class="required-field"
                      >Shipping Line</label
                    >
                    <input
                      id="shippingLine"
                      class="form-control"
                      name="shippingLine"
                      v-model="shippingLine"
                      :class="{
                        'is-invalid': isSubmitted && $v.shippingLine.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.shippingLine.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.shippingLine.required"
                        >Shipping Line is required</span
                      >
                      <span v-if="!$v.shippingLine.maxLength"
                        >Max length of 50 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="portAcceptanceDate" class="required-field"
                      >Port Acceptance Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="portAcceptanceDate"
                      id="portAcceptanceDate"
                      placeholder="Select Date"
                      v-model="portAcceptanceDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{
                        from: new Date(),
                        to: new Date(shippingBackcapDate),
                      }"
                      :disabled="shippingDate !== null"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.portAcceptanceDate.$error,
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.portAcceptanceDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.portAcceptanceDate.required"
                        >Port Acceptance Date is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="billOfLadingNo" class="required-field"
                      >Bill of Lading No.</label
                    >
                    <input
                      id="billOfLadingNo"
                      class="form-control"
                      name="billOfLadingNo"
                      v-model="billOfLadingNo"
                      @keyup="checkBOL()"
                      @keypress="isAlphaNum($event)"
                      :class="{
                        'is-invalid': (isSubmitted && $v.billOfLadingNo.$error) || (BOLExists),
                      }"
                    />
                    <div
                      v-if="(isSubmitted && $v.billOfLadingNo.$error) || BOLExists"
                      class="invalid-feedback"
                    > 
                      <span v-if="BOLExists"
                        >Bill of Lading No. already exists!</span
                      >
                      <span v-if="!$v.billOfLadingNo.required"
                        >Bill of Lading No. is required</span
                      >
                      <span v-if="!$v.billOfLadingNo.maxLength"
                        >Max length of 25 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div
                    class="form-group fc"
                    @mouseenter="shippingDateHover()"
                    @mouseleave="shippingDateHover('exit')"
                  >
                    <input
                      type="checkbox"
                      id="enableShippingDate"
                      v-model="enableShippingDate"
                      @change="shippingDateEnable()"
                      :disabled="portAcceptanceDate === null"
                    />&nbsp;&nbsp;
                    <label for="exampleInputEmail1">Shipping Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="shippingDate"
                      id="shippingDate"
                      placeholder="Select Date"
                      v-model="shippingDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{
                        from: new Date(),
                        to: new Date(portAcceptanceDate),
                      }"
                      :disabled="!enableShippingDate"
                      :class="{
                        'is-invalid': shippingDateDisabled,
                      }"
                    ></datepicker>
                    <div v-if="shippingDateDisabled" class="invalid-feedback">
                      <span v-if="shippingDateDisabled"
                        >Please Enter Port Acceptance Date</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="vesselName" class="required-field"
                      >Vessel Name</label
                    >
                    <input
                      id="vesselName"
                      class="form-control"
                      name="vesselName"
                      v-model="vesselName"
                      :class="{
                        'is-invalid': isSubmitted && $v.vesselName.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.vesselName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.vesselName.required"
                        >Vessel Name is required</span
                      >
                      <span v-if="!$v.vesselName.maxLength"
                        >Max length of 50 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!directAdd" class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid">
                <div class="tp_mid">
                  <div class="row">
                    <div 
                      v-for="(con, index) in selectedContracts"
                      :key="index"  
                      class="col-6">
                      <h1 class="heading1">
                        <label style="font-size: 14px;">
                          Contract No. : {{ con.contract_number }} |
                          <span v-if="con.offtaker_contract_partial_fill_id != null">
                            Partial Contract No. : {{ con.partial_contract_number }} |
                          </span>
                          Contract Quantity : {{ con.release_qty }} {{ con.unit_name }}
                        </label>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="releasedQty" class="required-field"
                      >Released Quantity (MT)</label
                    >
                    <input
                      id="releasedQty"
                      class="form-control"
                      name="releasedQty"
                      v-model="releasedQty"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.releasedQty.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.releasedQty.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.releasedQty.required"
                        >Released Quantity is required</span
                      >
                      <span v-if="!$v.releasedQty.decimal"
                        >Released Quantity should be decimal only<br
                      /></span>
                      <span v-if="!$v.releasedQty.maxLength"
                        >Max length of 15 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="grossWeight" class="required-field"
                      >Gross Weight (KG)</label
                    >
                    <input
                      id="grossWeight"
                      class="form-control"
                      name="grossWeight"
                      v-model="grossWeight"
                      :class="{
                        'is-invalid': isSubmitted && $v.grossWeight.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.grossWeight.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.grossWeight.required"
                        >Gross Weight is required</span
                      >
                      <span v-if="!$v.grossWeight.decimal"
                        >Gross Weight should be decimal only<br
                      /></span>
                      <span v-if="!$v.grossWeight.maxLength"
                        >Max length of 15 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="netWeight" class="required-field"
                      >Net Weight (KG)</label
                    >
                    <input
                      id="netWeight"
                      class="form-control"
                      name="netWeight"
                      v-model="netWeight"
                      :class="{
                        'is-invalid': isSubmitted && $v.netWeight.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.netWeight.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.netWeight.required"
                        >Net Weight is required</span
                      >
                      <span v-if="!$v.netWeight.decimal"
                        >Net Weight should be decimal only<br
                      /></span>
                      <span v-if="!$v.netWeight.maxLength"
                        >Max length of 15 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="tareWeight" class="required-field"
                      >Tare Weight (KG)</label
                    >
                    <input
                      id="tareWeight"
                      class="form-control"
                      name="tareWeight"
                      v-model="tareWeight"
                      :class="{
                        'is-invalid': isSubmitted && $v.tareWeight.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.tareWeight.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.tareWeight.required"
                        >Tare Weight is required</span
                      >
                      <span v-if="!$v.tareWeight.decimal"
                        >Tare Weight should be decimal only<br
                      /></span>
                      <span v-if="!$v.tareWeight.maxLength"
                        >Max length of 15 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="bags" class="required-field">Bags</label>
                    <input
                      id="bags"
                      class="form-control"
                      name="bags"
                      v-model="bags"
                      :disabled="!directAdd"
                      :class="{
                        'is-invalid': isSubmitted && $v.bags.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.bags.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.bags.required">Bags are required</span>
                      <span v-if="!$v.bags.numeric"
                        >Bags should be numeric only<br
                      /></span>
                      <span v-if="!$v.bags.maxLength"
                        >Max length of 15 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Containers</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6" v-if="directAdd">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addContainer()"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(con, index) in Containers"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Container No.</label>
                        <input
                          id="container_number"
                          class="form-control"
                          name="container_number"
                          v-model="con.container_number"
                          :disabled="!directAdd"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.Containers.$each[index].container_number
                                .$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.Containers.$each[index].container_number.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.Containers.$each[index].container_number
                                .maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-5 col-6" v-if="directAdd">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          id="containerDelete"
                          v-on:click="deleteContainer(index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addShippingDoc()"
                            :disabled="ShippingDocumentsAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(doc, index) in ShippingDocuments"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label>Select Document</label>
                        <select
                          id="ShippingDocName"
                          class="form-control"
                          name="ShippingDocName"
                          v-model="doc.ShippingDocName"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="doc in ShippingDocumentNames"
                            :value="doc.document_name"
                            :key="doc.id"
                          >
                            {{ doc.document_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @mouseenter="mouseenter(doc.ShippingDocName, index)"
                        @mouseleave="mouseleave(index)"
                      >
                        <label for="exampleInputEmail1">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            :disabled="
                              disableUpload(index, doc.ShippingDocName)
                            "
                            v-on:change="
                              handleFileUploadShippingDocuments(index, $event)
                            "
                          />
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ doc.displayFileName }}</b>
                              <small>
                                <u
                                  style="color: blue; cursor: pointer"
                                  v-if="
                                    doc.ShippingDocumentsRemoveDisabled ===
                                    false
                                  "
                                  v-on:click="removeShippingDocumentsDoc(index)"
                                  >Remove</u
                                >
                              </small>
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.errors.filesize">
                              {{ doc.errors.filesize }}
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.errors.docName">
                              {{ doc.errors.docName }}
                            </p>
                          </small>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Uploading Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="ShippingDocumentsDate"
                          id="ShippingDocumentsDate"
                          placeholder="Select Date"
                          v-model="doc.ShippingDocumentsDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabledDates="{ from: new Date() }"
                          :calendar-button="true"
                        ></datepicker>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @click="receiptValidation(doc.displayFileName)"
                      >
                        <label for="exampleInputEmail1">Receipt No.</label>
                        <input
                          id="receipt_number"
                          class="form-control"
                          name="receipt_number"
                          v-model="doc.receipt_number"
                          @keypress="isAlphaNum($event)"
                          :disabled="doc.displayFileName === null"
                          :class="{
                            'is-invalid':
                              (receiptValidate &&
                                doc.displayFileName === null) ||
                              (ShippingDocuments[index].receipt_number
                                ? ShippingDocuments[index].receipt_number
                                    .length > 20
                                : false),
                          }"
                        />
                        <div
                          v-if="doc.displayFileName === null"
                          class="invalid-feedback"
                        >
                          <span v-if="receiptValidate"
                            >Please upload the Document</span
                          >
                        </div>
                        <div
                          v-if="
                            ShippingDocuments[index].receipt_number
                              ? ShippingDocuments[index].receipt_number.length >
                                20
                              : false
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              ShippingDocuments[index].receipt_number
                                ? ShippingDocuments[index].receipt_number
                                    .length > 20
                                : false
                            "
                            >Max length of 20 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          id="docDelete"
                          v-on:click="deleteShippingDocuments(index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onShippingDocumentSaveInfo()"
                        :disabled="isDisabledShippingDocumentSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    id="submit"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a @click="$router.go(-1)" style="cursor: pointer">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
    <!-- ./modal start -->
    <div
      class="modal fade"
      id="shipping_partial_details"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Shipping Partial Details</h4>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <b>Warrant Ref ID: </b> {{ shippingReferenceID }}
            <b>Quantity (MT): </b> {{ shippingWarrantQuantity }}
            <b>Released Quantity (MT): </b> {{ shippingReleaseQuantity
            }}<br /><br />
            As the Warrant Ref ID {{ shippingReferenceID }} has been partially
            used during Auto Warrant transfer<br />
            Changes can also be made to the fields below!<br /><br />
            <div class="row">
              <div class="col-4">
                <div class="form-group fc">
                  <label for="shipping_net_weight" class="required-field"
                    >Net Weight</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="shipping_net_weight"
                    v-model="shippingNetWeight"
                    @keypress="isDecimal($event)"
                    :class="{
                      'is-invalid':
                        Number(shippingNetWeight) >
                        Number(shippingPartialDetails.net_weight),
                    }"
                  />
                  <div
                    v-if="
                      Number(shippingNetWeight) >
                      Number(shippingPartialDetails.net_weight)
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        Number(shippingNetWeight) >
                        Number(shippingPartialDetails.net_weight)
                      "
                      >Net Weight cannot exceed the limit of
                      {{ shippingPartialDetails.net_weight }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group fc">
                  <label for="shipping_gross_weight" class="required-field"
                    >Gross Weight</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="shipping_gross_weight"
                    v-model="shippingGrossWeight"
                    @keypress="isDecimal($event)"
                    :class="{
                      'is-invalid':
                        Number(shippingGrossWeight) >
                        Number(shippingPartialDetails.gross_weight),
                    }"
                  />
                  <div
                    v-if="
                      Number(shippingGrossWeight) >
                      Number(shippingPartialDetails.gross_weight)
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        Number(shippingGrossWeight) >
                        Number(shippingPartialDetails.gross_weight)
                      "
                      >Gross Weight cannot exceed the limit of
                      {{ shippingPartialDetails.gross_weight }}</span
                    >
                  </div>
                </div>
              </div>

              <div class="col-4">
                <div class="form-group fc">
                  <label for="shipping_bags" class="required-field">Bags</label>
                  <input
                    type="number"
                    class="form-control"
                    id="shipping_bags"
                    v-model="shippingBags"
                    @keypress="isDecimal($event)"
                    :class="{
                      'is-invalid':
                        Number(shippingBags) >
                        Number(shippingPartialDetails.bags),
                    }"
                  />
                  <div
                    v-if="
                      Number(shippingBags) > Number(shippingPartialDetails.bags)
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        Number(shippingBags) >
                        Number(shippingPartialDetails.bags)
                      "
                      >Bags cannot exceed the limit of
                      {{ shippingPartialDetails.bags }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    @click="onShippingPartialDetailSubmit()"
                  >
                    Submit
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {
  required,
  maxLength,
  decimal,
  numeric,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      Ports: [],
      borrowers: [],
      facilities: [],
      collaterals: [],
      collateralStages: [],
      mappedCM: [],
      warehouses: [],
      offtakers: [],
      selectedContracts: [],
      selectedContainers: [],
      shippingPartialDetails: [],
      selectedBorrower: null,
      selectedFacilityId: null,
      selectedCollateral: null,
      selectedCollateralType: null,
      selectedWarehouse: null,
      selectedCM: null,

      contractType: 'LogisticShipping',
      // contractType: 'Offtaker',
      logisticsShippingFlag: '0',
      offtakerNameIDs:[],
      contractNumber:[],
      partialContractNumber:[],
      selectedOfftakerID:null,
      selectedContractNumber:null,
      selectedPartialContractNumber:null,
      selectedContractNumberName:null,
      selectedPartialContractNumberName:null,
      FinalContractQuantity:null,
      // selectedOfftakerContract: null,
      // selectedOfftaker: null,
      selectedShippingPort: null,
      selectedDestinationPort: null,
      shippingLine: null,
      portAcceptanceDate: null,
      billOfLadingNo: null,
      shippingDate: null,
      vesselName: null,
      // deliveryDate: null,
      shippingBackcapDate: null,
      enableShippingDate: false,
      releasedQty: null,
      netWeight: null,
      grossWeight: null,
      tareWeight: null,
      bags: null,

      BOLExists: false,
      bill_of_ladding: [],

      //list for Containers
      Containers: [],

      // shipping partial details
      shippingNewWarrantID: null,
      shippingExistingWarrantID: null,
      shippingReferenceID: null,
      shippingNetWeight: null,
      shippingGrossWeight: null,
      shippingBags: null,
      shippingWarrantQuantity: null,
      shippingReleaseQuantity: null,

      //list for Shipping Documents
      ShippingDocumentNames: [],
      ShippingDocuments: [],
      ShippingDocumentsFile: null,
      ShippingDocumentsDisabled: true,
      ShippingDocumentsSaveInfoDisabled: true, //save info button is disabled
      ShippingDocumentsAddMoreDisabled: true,
      msg: [],
      error: "",
      isSubmitted: false,
      onSubmitDisabled: false,
      receiptValidate: false,
      shippingDateDisabled: false,
      directAdd: false,
    };
  },
  validations: {
    selectedBorrower: {
      required,
    },
    selectedFacilityId: {
      required,
    },
    // selectedOfftaker: {
    //   required,
    // },
    selectedOfftakerID: {
      required,
    },
    selectedContractNumber: {
      required: function () {
        if (this.directAdd && this.selectedContractNumber === null) return false;
        else return true;
      },
    },
    selectedPartialContractNumber: {
      required: function () {
        if (this.directAdd && this.selectedPartialContractNumber == null && this.logisticsShippingFlag > 0) return false;
        else return true;
      },
    },
    selectedCollateral: {
      required: function () {
        if (this.directAdd && this.selectedCollateral === null) return false;
        else return true;
      },
    },
    selectedCollateralType: {
      required: function () {
        if (this.directAdd && this.selectedCollateralType === null)
          return false;
        else return true;
      },
    },
    selectedWarehouse: {
      required: function () {
        if (this.directAdd && this.selectedWarehouse === null) return false;
        else return true;
      },
    },
    selectedCM: {
      required,
    },
    selectedShippingPort: {
      required,
    },
    selectedDestinationPort: {
      required,
    },
    shippingLine: {
      required,
      maxLength: maxLength(50),
    },
    portAcceptanceDate: {
      required,
    },
    billOfLadingNo: {
      required,
      maxLength: maxLength(25),
    },
    vesselName: {
      required,
      maxLength: maxLength(50),
    },
    releasedQty: {
      required: function () {
        if (!this.releasedQty || this.releasedQty == 0) return false;
        else return true;
      },
      maxLength: function () {
        if (this.releasedQty > 999999999999999) return false;
        else return true;
      },
      decimal,
    },
    grossWeight: {
      required: function () {
        if (!this.grossWeight || this.grossWeight == 0) return false;
        else return true;
      },
      maxLength: function () {
        if (this.grossWeight > 999999999999999) return false;
        else return true;
      },
      decimal,
    },
    netWeight: {
      required: function () {
        if (!this.netWeight || this.netWeight == 0) return false;
        else return true;
      },
      maxLength: function () {
        if (this.netWeight > 999999999999999) return false;
        else return true;
      },
      decimal,
    },
    tareWeight: {
      required: function () {
        if (!this.tareWeight || this.tareWeight == 0) return false;
        else return true;
      },
      maxLength: function () {
        if (this.tareWeight > 999999999999999) return false;
        else return true;
      },
      decimal,
    },
    bags: {
      required: function () {
        if (!this.bags || this.bags == 0) return false;
        else return true;
      },
      maxLength: maxLength(15),
      numeric,
    },
    Containers: {
      $each: {
        container_number: {
          maxLength: maxLength(50),
        },
      },
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },

    //this is to computed disabled property of ShippingDocuments Save Info button.
    isDisabledShippingDocumentSaveInfo: function () {
      let checkEmptyShippingDocumentsDocName = this.ShippingDocuments.filter(
        (line) => line.ShippingDocName === null
      );
      let checkEmptyShippingDocumentsDate = this.ShippingDocuments.filter(
        (line) => line.ShippingDocumentsDate === null
      );
      //if the line is empty then disable save info button
      if (
        this.ShippingDocuments.length === 0 &&
        this.ShippingDocumentsSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyShippingDocumentsDocName.length >= 1 &&
          this.ShippingDocuments.length > 0) ||
        (checkEmptyShippingDocumentsDate.length >= 1 &&
          this.ShippingDocuments.length > 0)
      ) {
        this.ShippingDocumentsDisabled = true;
      } else {
        this.ShippingDocumentsDisabled = false;
      }
      //ShippingDocumentsDisabled variable is to check whther all input fields except file upload are filled. ShippingDocumentsFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.ShippingDocumentsDisabled === true ||
        this.ShippingDocumentsFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledShippingDocumentSaveInfo() close
  },
  components: {
    Datepicker,
  },
  methods: {
    shippingDateHover(e) {
      if (e && this.portAcceptanceDate === null) {
        this.shippingDateDisabled = false;
      } else if (this.portAcceptanceDate === null) {
        this.shippingDateDisabled = true;
      }
    },
    shippingDateEnable() {
      if (this.enableShippingDate)
        this.$confirm({
          auth: false,
          message:
            "Are you sure you want to add Shipping Date? This is a onetime process; Changes cannot be reverted.",
          button: {
            no: "No",
            yes: "Yes",
          },

          callback: (confirm) => {
            if (confirm) this.enableShippingDate = true;
            else this.enableShippingDate = false;
          },
        });
    },

    receiptValidation(docName) {
      this.receiptValidate = docName === null ? true : false;
    },

    isAlphaNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },

    //this method will make disabled property as false for Shipping Save Info
    onShippingDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.ShippingDocumentsSaveInfoDisabled = false;
      this.ShippingDocumentsAddMoreDisabled = false;
      console.log(this.ShippingDocuments);
    },

    //this function deletes ShippingDocument record
    deleteShippingDocuments(index) {
      this.ShippingDocumentsFile = this.ShippingDocumentsFile
        ? this.ShippingDocumentsFile
        : "file deleted";
      this.ShippingDocuments.splice(index, 1);
      if (
        typeof this.ShippingDocuments !== "undefined" &&
        this.ShippingDocuments.length == 0
      ) {
        this.ShippingDocumentsAddMoreDisabled = false;
        this.ShippingDocumentsSaveInfoDisabled = true;
      }
    },

    //this function deletes Container record
    deleteContainer(index) {
      this.Containers.splice(index, 1);
    },

    // this method will submit the data for Shipping Partial Detail Modal
    onShippingPartialDetailSubmit() {
      // check if any net weight, gross weight or bags is greater than its respective earlier values
      if (
        Number(this.shippingNetWeight) >
          Number(this.shippingPartialDetails.net_weight) ||
        Number(this.shippingGrossWeight) >
          Number(this.shippingPartialDetails.gross_weight) ||
        Number(this.shippingBags) > Number(this.shippingPartialDetails.bags)
      ) {
        return;
      }
      Utils.start_loading();
      const path = "add-shipment";
      axios
        .put(
          path,
          {
            updateType: "ShippingPartialDetails",
            new_warrant_id: this.shippingNewWarrantID,
            existing_warrant_id: this.shippingExistingWarrantID,
            net_weight: this.shippingNetWeight,
            gross_weight: this.shippingGrossWeight,
            bags: this.shippingBags,
            userId: this.$store.getters.getuserId,
            moduleId: 8,
            tabId: 24,
            clientId: this.$store.state.client_id,
          },
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            $("#shipping_partial_details").modal("hide");
            Utils.make_alert(
              "success",
              "Shipping Partial Details updated successfully!"
            );
            setTimeout(() => this.$router.push("/shipping"), 1500);
          } else {
            Utils.make_alert("warning", "Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
        });
    },

    onCollateralTypeChange() {
      if (
        this.selectedBorrower &&
        this.selectedFacilityId &&
        this.selectedCollateral &&
        this.selectedCollateralType
      ) {
        // this function is used to get shipping backup date
        Utils.start_loading();
        const path1 =
          "get-shipping-backup-date/" +
          this.selectedBorrower +
          "/" +
          this.selectedFacilityId +
          "/" +
          this.selectedCollateral +
          "/" +
          this.selectedCollateralType;
        axios
          .get(path1, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            Utils.stop_loading();
            if (res.data.status == "success") {
              this.shippingBackcapDate = res.data.shipping_backup_date;
              console.log("get shipping Backcap Date is successful.");
            } else {
              console.log("Something went wrong from server!");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
            console.log("Something went wrong!");
          });
      }
    },

     // check if BOL number already exists
    checkBOL() {
      console.log("checkBOL")
      console.log(this.bill_of_ladding.filter((con) => con.bill_of_ladding == this.billOfLadingNo).length)
      if (
        this.bill_of_ladding.filter(
          (con) => con.bill_of_ladding == this.billOfLadingNo
        ).length
      ) {
        console.log("bill of ladding already exist")
        this.BOLExists = true;
      } else{
        console.log("bill of ladding doesn't exist")
        this.BOLExists = false;
      } 
    },

    //this function will be called to get list of offtaker contract numbers
    getBOLs() {
      const path = "bill-of-ladding";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.bill_of_ladding = res.data.bill_of_ladding_list;
          if (res.data.status == "success") {
            console.log("get bill_of_ladding is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    //this method will submit the Shipping form in backend.
    onSubmit() {
      this.$confirm({
        auth: false,
        message: "Confirm Submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            this.checkBOL();
            this.isSubmitted = true;
            this.$v.$touch();
            console.log('this.BOLExists',this.BOLExists)
            if (
              this.$v.$invalid ||
              (this.selectedShippingPort === this.selectedDestinationPort) ||
              this.BOLExists
            ) {
              return;
            }
            if (this.portAcceptanceDate != null) {
              this.portAcceptanceDate = moment(this.portAcceptanceDate).format(
                "MM-DD-YYYY"
              );
            }
            if (this.shippingDate != null) {
              this.shippingDate = moment(this.shippingDate).format(
                "MM-DD-YYYY"
              );
            }
            // if(this.directAdd && this.logisticsShippingFlag == 1 && this.selectedPartialContractNumber == null){
            //   Utils.make_alert("success","Please map partial contract as this contract is already mapped in logistics");
            //   return;
            // }
            // if (this.deliveryDate != null) {
            //   this.deliveryDate = moment(this.deliveryDate).format(
            //     "MM-DD-YYYY"
            //   );
            // }
            for (let i = 0; i < this.ShippingDocuments.length; i++) {
              if (this.ShippingDocuments[i].ShippingDocName === null) {
                this.ShippingDocuments.splice(i, 1);
              } else {
                if (this.ShippingDocuments[i].ShippingDocumentsDate !== null) {
                  this.ShippingDocuments[i].ShippingDocumentsDate = moment(
                    this.ShippingDocuments[i].ShippingDocumentsDate
                  ).format("MM-DD-YYYY");
                }
              }
            }
            //initialize the form data
            let formData = new FormData();
            formData.append("borrowerID", this.selectedBorrower);
            formData.append("facilityID", this.selectedFacilityId);
            formData.append("offtakerID",this.selectedOfftakerID);
            formData.append("selectedContractNumber",this.selectedContractNumber);
            formData.append("selectedPartialContractNumber",this.selectedPartialContractNumber);
            // formData.append("offtakerID", this.selectedOfftaker);
            // formData.append("offtakerContract", this.selectedOfftakerContract);
            formData.append("selectedCollateral", this.selectedCollateral);
            formData.append("selectedCollateralType",this.selectedCollateralType);
            formData.append("selectedContracts", JSON.stringify(this.selectedContracts));
            formData.append("selectedWarehouse", this.selectedWarehouse);
            formData.append("selectedCM", this.selectedCM);
            formData.append("shippingPort", this.selectedShippingPort);
            formData.append("destinationPort", this.selectedDestinationPort);
            formData.append("shippingLine", this.shippingLine);
            formData.append("portAcceptanceDate", this.portAcceptanceDate);
            formData.append("billofLandingNumber", this.billOfLadingNo);
            formData.append("shippingDate", this.shippingDate);
            formData.append("vesselName", this.vesselName);
            formData.append("releasedQty", this.releasedQty);
            formData.append("grossWeight", this.grossWeight);
            formData.append("netWeight", this.netWeight);
            formData.append("tareWeight", this.tareWeight);
            formData.append("bags", this.bags);
            formData.append("directAdd", this.directAdd);
            // formData.append("deliveryDate", this.deliveryDate);
            formData.append("moduleId", 8);
            formData.append("tabId", 24);
            formData.append("userId", this.$store.getters.getuserId);

            formData.append(
              "clientId",
              JSON.stringify(this.$store.state.client_id)
            );
            formData.append(
              "shippingDocuments",
              JSON.stringify(this.ShippingDocuments)
            );
            formData.append("containers", JSON.stringify(this.Containers));
            const path = "add-shipment";
            Utils.start_loading();
            axios
              .post(path, formData, {
                headers: {
                  enctype: "multipart/form-data",
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "New Shipping Info added successfully!"
                  );
                  // if partial details are to be filled open Shipping Partial Details Modal else reload page
                  if (response.data.new_warrant_id) {
                    this.shippingNewWarrantID = response.data.new_warrant_id;
                    this.shippingExistingWarrantID =
                      response.data.existing_warrant_id;
                    if (response.data.partial_details) {
                      this.shippingPartialDetails =
                        response.data.partial_details;
                      this.shippingReferenceID =
                        response.data.partial_details.ref_id;
                      this.shippingNetWeight =
                        response.data.partial_details.net_weight;
                      this.shippingGrossWeight =
                        response.data.partial_details.gross_weight;
                      this.shippingBags = response.data.partial_details.bags;
                      this.shippingWarrantQuantity =
                        response.data.partial_details.quantity;
                      this.shippingReleaseQuantity =
                        response.data.partial_details.release_quantity;
                    }
                    // opens Shipping Partial Details Modal
                    $("#shipping_partial_details").modal("show");
                  } else {
                    this.$router.push("/shipping");
                    console.log("SUCCESS!!");
                  }
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error(error);
              });
          }
        },
      });
    }, //onSubmit() close

    //this method will add new line for additional Collateral Documents
    addShippingDoc() {
      //when new line is added then ShippingDocumentsFile variable and ShippingDocumentsSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.ShippingDocumentsFile = null),
        (this.ShippingDocumentsSaveInfoDisabled = true),
        (this.ShippingDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.ShippingDocuments.push({
          id: null,
          Shipping_document_id: null,
          ShippingDocName: null,
          ShippingDocumentsDate: new Date(),
          displayFileName: null,
          receipt_number: null,
          errors: { filesize: "", docName: "" },
          ShippingDocumentsRemoveDisabled: true,
        });
    }, //addShippingDoc() close

    //this method will add new line for additional Containers
    addContainer() {
      //following block is created first time on mounted property
      this.Containers.push({
        id: null,
        container_number: null,
      });
    }, //addContainer() close

    mouseenter(docName, index) {
      if (docName === null) {
        this.ShippingDocuments[index].errors.docName =
          "Document name is not selected";
      }
    },
    mouseleave(index) {
      this.ShippingDocuments[index].errors.docName = "";
    },
    disableUpload(id, docName) {
      if (docName === null) {
        return true;
      } else {
        return false;
      }
    },
    //handles a change on the file upload
    handleFileUploadShippingDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.ShippingDocumentsFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.ShippingDocumentsFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors.filesize = "File size should be less than 100MB";
          self.ShippingDocuments[id].errors.filesize = JSON.stringify(
            response.errors.filesize
          ).replace(/\"/g, "");
          self.ShippingDocuments[id].selectedFile = null;
          self.ShippingDocuments[id].selectedFileName = null;
          self.ShippingDocuments[id].displayFileName = null;
          self.ShippingDocuments[id].ShippingDocumentsRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.ShippingDocumentsFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.ShippingDocuments[id].selectedFile = reader.result;
          self.ShippingDocuments[id].selectedFileName = e.target.files[0].name;
          self.ShippingDocuments[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.ShippingDocuments[id].errors.filesize = "";
          self.ShippingDocuments[id].ShippingDocumentsRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadShippingDocuments() close

    ////this method handles remove doc attachment
    removeShippingDocumentsDoc(id) {
      this.ShippingDocumentsFile = null;
      this.ShippingDocuments[id].selectedFile = null;
      this.ShippingDocuments[id].selectedFileName = null;
      this.ShippingDocuments[id].displayFileName = null;
      this.ShippingDocuments[id].ShippingDocumentsRemoveDisabled = true;
    },

    //this function will be called to get list of borrowers
    getBorrowers() {
      const path = "borrower";
      // Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.borrowers = res.data.borrowers_active;
          //get Approved borrowers
          if (res.data.borrowers_active) {
            this.borrowers = this.borrowers.filter((bor) => {
              if (bor.status === "Approved") {
                return bor;
              }
            });
          }
          if (res.data.status == "success") {
            console.log("get borrowers is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          // Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    //this function is called to get list of ports
    getPorts() {
      const path = "port_master";
      axios
        .get(path)
        .then((res) => {
          this.Ports = res.data.Port;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of document names
    getShippingDocs() {
      const path = "shipping-documents";
      axios
        .get(path)
        .then((res) => {
          this.ShippingDocumentNames = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFacilitySetupByBorrower(id) {
      //this function is called to get list of open facilities
      if(id == null){
        this.selectedFacilityId = null
        this.facilities = []
        this.selectedCollateral = null;
        this.collaterals = []
        return;
      }
      const path = "/facility-setup-borrower/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.facilities = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCollateral() {
      if(this.selectedFacilityId == null){
        this.selectedCollateral = null;
        this.collaterals = []
        return;
      }
      //this function is called to get list of collaterals by facility id
      const path = "/get-collateral/" + this.selectedFacilityId;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.collaterals = res.data.collaterals;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of warehouses
    getWarehouseByBorrower(id) {
      if(id == null){
        this.selectedWarehouse = null
        this.warehouses = []
        this.selectedCM = null;
        this.mappedCM = [];
        return;
      }
      const path = "/warehouse-borrower/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.warehouses = res.data.warehouses;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    onWarehouseChange() {
      // this function is used to get mapped CM Companies for a particular Warehouse
      if(this.selectedWarehouse == null){
        this.selectedCM = null;
        this.mappedCM = [];
        return;
      }
      Utils.start_loading();
      const path = "get-mapped-cm/" + this.selectedWarehouse;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.mappedCM = res.data.warehouse_cm_mapping_list;
            if (this.loggedInUserGroupId === "Collateral Manager")
              this.selectedCM = this.loggedInUserSubGroupId;
            console.log(
              "get mapped CM Companies for a particular Warehouse is successful."
            );
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    getOfftakerNameByBorrower(id) {
      console.log("inside get getOfftakerNameByBorrower");
      if(id == null){
        this.selectedOfftakerID = null
        this.offtakerNameIDs = []
        this.selectedContractNumber = null;
        this.contractNumber = [];
        this.selectedPartialContractNumber = null;
        this.partialContractNumber = [];
        this.releasedQty = null;
        this.logisticsShippingFlag = '0';
        return;
      }
      // const path = "/get-offtaker-name-by-borrower/" + id;
      const path = 
        "/get-offtaker-name-by-borrower" + 
        "/" + 
        id +
        "/" +
        null +
        "/" +
        'Offtaker';
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          if(this.directAdd){
            this.selectedOfftakerID = null;
            this.releasedQty = null;
          }
          this.selectedContractNumber = null;
          this.selectedPartialContractNumber = null;
          this.partialContractNumber = [];
          this.offtakerNameIDs = res.data.offtaker_names;
          // this.getContractNumberByOfftakerName()
        })
        .catch(error => {
          console.error(error);
        });
      
    },

    getContractNumberByOfftakerName() {
      //this function is called to get list of 
      console.log("inside get getContractNumberByOfftakerName")
      // const path = "/get-contract-no-by-offtaker/" + this.selectedOfftakerID + "/" + this.selectedBorrower;
      if(this.selectedOfftakerID == null) {
          this.selectedContractNumber = null;
          this.contractNumber = [];
          this.selectedPartialContractNumber = null;
          this.partialContractNumber = [];
          this.releasedQty = null;
          this.logisticsShippingFlag = '0';
          return
      }
      const path = 
        "/get-contract-no-by-offtaker" + 
        "/" + 
        this.selectedOfftakerID +
        "/" + 
        this.selectedBorrower +
        "/" +
        null +
        "/" +
        this.contractType;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          this.contractNumber =  res.data.contract_numbers;
        })
        .catch(error => {
          console.error(error);
        });
      
    },

     //this function is called to get list of contract numbers
    getContractNumberDetails() {
      console.log("inside get getContractNumberDetails")
      if(this.selectedContractNumber == null){
        this.selectedPartialContractNumber = null;
        this.partialContractNumber = [];
        this.PartialContractDate = null;
        this.releasedQty = null;
        this.logisticsShippingFlag = '0';
        return
      }
      let selectedContract = this.contractNumber.find(contract => contract.contractId === this.selectedContractNumber);
      console.log("selectedContract",selectedContract)
      this.releasedQty = selectedContract.ContractQuantity;
      this.logisticsShippingFlag = selectedContract.logistics_shipping_flag;
      if(this.releasedQty == 0){
        this.logisticsShippingFlag = '1';
      }

      // if(this.selectedPartialContractNumber != null ){
      Utils.start_loading();
      // const path = "/get-partial-contract-no/" + this.selectedContractNumber;
      const path = 
        "/get-partial-contract-no" + 
        "/" + 
        this.selectedContractNumber +
        "/" +
        null +
        "/" +
        this.contractType;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          Utils.stop_loading();
          console.log("status",res.data.status)
          if(res.data.status == "success") {
            this.partialContractNumber = res.data.partial_contracts;
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          console.error(error);
        });
      // }
    },

    //this function is called to get list of contract numbers
    getPartialContractNumberDetails() {
      console.log("inside get getPartialContractNumberDetails")
      if(this.selectedPartialContractNumber == null){
        let selectedContract = this.contractNumber.find(contract => contract.contractId === this.selectedContractNumber);
        console.log("selectedContract",selectedContract)
        this.releasedQty = selectedContract.ContractQuantity;
        this.logisticsShippingFlag = selectedContract.logistics_shipping_flag;
        return;
      }
      let selectedPartialContract = this.partialContractNumber.find(partialContract => partialContract.partialContractId === this.selectedPartialContractNumber);
      this.releasedQty = selectedPartialContract.PartialContractQuantity;
    },

    // this function will be called to get list of offtakers
    getBuyers() {
      Utils.start_loading();
      const path = "buyer";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          this.offtakers = res.data.Buyer;
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
        });
    },

    //this function is called to get list of collateral stages
    getCollateralStages() {
      const path = "collateral-stage-master";
      axios
        .get(path)
        .then((res) => {
          this.collateralStages = res.data.collateral_stages;
          this.collateralStages = this.collateralStages.slice(0, 4);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCollateralCompany() {
      //this function will be called to get list of collateral managers
      const path = "collateral-summary-overview-active";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            let data = res.data.collateral_active_overview;
            // filter for approved records
            data = data.filter((col) => col.status === "Approved");
            data.map((col) => {
              col["collateral_manager_master_id"] = col.collateral_manager_id;
            });
            // assign only if directAdd flag is false
            if (!this.directAdd) this.mappedCM = data;
            console.log("get collaterals company is successful.");
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },

    // this function is called to get prepopulated fields from containers
    getContainerData() {
      this.Containers = this.selectedContainers;
      this.releasedQty = this.selectedContainers
        .map((con) => Number(con.release_qty))
        .reduce((a, b) => a + b);
      this.releasedQty = this.releasedQty.toFixed(8);
      this.grossWeight = this.selectedContainers
        .map((con) => Number(con.gross_weight.toString().replaceAll(",", "")))
        .reduce((a, b) => a + b);
      this.grossWeight = this.grossWeight.toFixed(8);
      this.netWeight = this.selectedContainers
        .map((con) => Number(con.net_weight.toString().replaceAll(",", "")))
        .reduce((a, b) => a + b);
      this.netWeight = this.netWeight.toFixed(8);
      this.tareWeight = this.selectedContainers
        .map((con) => Number(con.tare_weight.toString().replaceAll(",", "")))
        .reduce((a, b) => a + b);
      this.tareWeight = this.tareWeight.toFixed(8);
      this.bags = this.selectedContainers
        .map((con) => Number(con.bags.replaceAll(",", "")))
        .reduce((a, b) => a + b);
      this.selectedFacilityId = this.selectedContainers[0].facility_id;
      this.selectedCollateral = this.selectedContainers[0].collateral_master_id;
      this.selectedCollateralType = this.selectedContainers[0].collateral_stage_id;
      this.selectedOfftakerID = this.selectedContainers[0].offtaker_id;
      // this.selectedContractNumber = this.selectedContainers[0].offtaker_contract_tracker_id;
      // this.selectedPartialContractNumber = this.selectedContainers[0].offtaker_contract_partial_fill_id;
      // this.selectedContractNumberName = this.selectedContainers[0].offtaker_contract_tracker_id_name;
      // this.selectedPartialContractNumberName = this.selectedContainers[0].offtaker_contract_partial_fill_id_name;
      this.getCollateral();
      this.onCollateralTypeChange();
      this.getContractData();
    },

    getContractData() {
        let selectedContracts = this.selectedContainers.reduce((accumulator, container) => {
            // Construct a unique key
            let key = container.offtaker_contract_tracker_id + '_' + container.offtaker_contract_partial_fill_id;

            console.log("key", key);

            // Check if this unique key already exists in the accumulator
            if (accumulator[key]) {
                // If it exists, sum up the release_qty
                accumulator[key].release_qty += parseFloat(container.release_qty);
            } else {
                // If it doesn't exist, create a new entry
                accumulator[key] = {
                    offtaker_contract_tracker_id: container.offtaker_contract_tracker_id,
                    contract_number: container.contract_number,
                    offtaker_contract_partial_fill_id: container.offtaker_contract_partial_fill_id,
                    partial_contract_number: container.partial_contract_number,
                    unit_name: container.unit_name,
                    release_qty: parseFloat(container.release_qty)
                };
            }
            return accumulator;
        }, {});

        this.selectedContracts = Object.values(selectedContracts);
        console.log("this.selectedContracts",this.selectedContracts)
    },

  },

  mounted() {
    if (this.$route.params.borrower_id) {
      this.selectedBorrower = this.$route.params.borrower_id;
      this.getFacilitySetupByBorrower(this.$route.params.borrower_id);
      this.getOfftakerNameByBorrower(this.$route.params.borrower_id);
      this.getWarehouseByBorrower(this.$route.params.borrower_id);
    } else {
      this.$router.go(-1);
    }

    // this.selectedBorrower = 945;
    // this.directAdd = false
    // this.getFacilitySetupByBorrower('945');
    // this.getOfftakerNameByBorrower('945');
    // this.getWarehouseByBorrower('945');
    
    this.addShippingDoc();
    this.directAdd = this.$route.params.direct_add;
    console.log("this.directAdd",this.directAdd)
    if (!this.directAdd) {
      this.selectedContainers = this.$route.params.containers;
      console.log("this.selectedContainers",this.selectedContainers);
      const areAllCollateralCompanyEqual = this.selectedContainers.every(
        (obj) =>
          obj["collateral_manager_id"] ===
          this.selectedContainers[0]["collateral_manager_id"]
      );
      const areAllFacilityEqual = this.selectedContainers.every(
        (obj) =>
          obj["facility_id"] === this.selectedContainers[0]["facility_id"]
      );
      const areAllCollateralEqual = this.selectedContainers.every(
        (obj) =>
          obj["collateral_master_id"] ===
          this.selectedContainers[0]["collateral_master_id"]
      );
      const areAllCollateralStageEqual = this.selectedContainers.every(
        (obj) => obj["stage_name"] === this.selectedContainers[0]["stage_name"]
      );
      const areAllOfftakerEqual = this.selectedContainers.every(
        (obj) =>
          obj["offtaker_id"] === this.selectedContainers[0]["offtaker_id"]
      );
      if (
        !areAllFacilityEqual ||
        !areAllCollateralEqual ||
        !areAllCollateralStageEqual ||
        !areAllOfftakerEqual
      ) {
        this.$router.go(-1);
        Utils.make_alert(
          "warning",
          "Facility ID, Collateral, Collateral Type & Offtaker of All Containers must be same!"
        );
        return;
      }
      let statusError = false;
      this.selectedContainers.map((con) => {
        if (con.status !== "In Transit" && !statusError) {
          this.$router.go(-1);
          Utils.make_alert(
            "warning",
            "Adding a shipment can only be performed for In Transit Containers!"
          );
          statusError = true;
        }
      });
      // assign container CM to selected CM if all container CM are same
      if (areAllCollateralCompanyEqual) {
        this.selectedCM = this.selectedContainers[0]["collateral_manager_id"];
      }
      this.getContainerData();
    }
  },

  created() {
    this.getBorrowers();
    this.getPorts();
    this.getBOLs();
    this.getShippingDocs();
    this.getCollateralStages();
    this.getCollateralCompany();
  },
};
</script>

<style scoped>
.error {
  color: red;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -70px;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>
