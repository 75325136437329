<!-- This is release request page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Release Request
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedBorrower" class="required-field"
                      >Borrower Name</label
                    >
                    <select
                      id="selectedBorrower"
                      class="form-control"
                      name="selectedBorrower"
                      v-model="selectedBorrower"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedBorrower.$error,
                      }"
                      @change="
                        getFacilitySetupByBorrower(selectedBorrower) &
                          getWarehouseByBorrower(selectedBorrower)
                      "
                      :disabled="loggedInUserGroupId === 'Borrower' || id > 0"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="bor in borrowers"
                        :value="bor.borrower_id"
                        :key="bor.borrower_id"
                      >
                        {{ bor.borrower_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedBorrower.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedBorrower.required"
                        >Borrower Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedFacilityId" class="required-field"
                      >Facility ID</label
                    >
                    <select
                      id="selectedFacilityId"
                      class="form-control"
                      name="selectedFacilityId"
                      v-model="selectedFacilityId"
                      @change="getCollateral(selectedFacilityId)"
                      :disabled="id > 0"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedFacilityId.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="fac in facilities"
                        :value="fac.id"
                        :key="fac.id"
                      >
                        {{ fac.facility_registration_id }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedFacilityId.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedFacilityId.required"
                        >Facility ID is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCollateral" class="required-field"
                      >Collateral</label
                    >
                    <select
                      id="selectedCollateral"
                      class="form-control"
                      name="selectedCollateral"
                      v-model="selectedCollateral"
                      :disabled="id > 0"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedCollateral.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="col in collaterals"
                        :value="col.id"
                        :key="col.id"
                      >
                        {{ col.collateral_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCollateral.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCollateral.required"
                        >Collateral is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedWarehouse" class="required-field"
                      >Warehouse</label
                    >
                    <select
                      id="selectedWarehouse"
                      class="form-control"
                      name="selectedWarehouse"
                      v-model="selectedWarehouse"
                      @change="onWarehouseChange()"
                      :disabled="id > 0"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedWarehouse.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="war in warehouses"
                        :value="war.id"
                        :key="war.id"
                      >
                        {{ war.warehouse_registration_id + ": " }}
                        {{ war.address }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedWarehouse.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedWarehouse.required"
                        >Warehouse is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCollateralType" class="required-field"
                      >Collateral Type</label
                    >
                    <select
                      id="selectedCollateralType"
                      class="form-control"
                      name="selectedCollateralType"
                      v-model="selectedCollateralType"
                      :disabled="id > 0"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedCollateralType.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="col in collateralStages"
                        :value="col.id"
                        :key="col.id"
                      >
                        {{ col.stage_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCollateralType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCollateralType.required"
                        >Collateral Type is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCM" class="required-field"
                      >Collateral Manager Company</label
                    >
                    <select
                      id="selectedCM"
                      class="form-control"
                      name="selectedCM"
                      v-model="selectedCM"
                      :disabled="id > 0"
                      @change="onCMChange()"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedCM.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="cm in mappedCM"
                        :value="cm.collateral_manager_master_id"
                        :key="cm.collateral_manager_master_id"
                      >
                        {{ cm.collateral_id }}: {{ cm.company_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCM.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCM.required"
                        >Collateral Manager Company is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4 col-6">
                  <label>Employees</label>
                  <multiselect
                    v-model="selectedMappedCMEmployees"
                    placeholder="--- Select ---"
                    :custom-label="getEmployeeLabel"
                    track-by="id"
                    :options="mappedCMEmployees"
                    :multiple="true"
                    :disabled="loggedInUserGroupId !== 'Admin'"
                    ><span slot="noResult"
                      >No Mapped CM Employee found with given input.</span
                    ></multiselect
                  >
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="quantity" class="required-field"
                      >Quantity (MT)</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="quantity"
                      v-model="quantity"
                      @keypress="removeMinus($event)"
                      :disabled="id > 0"
                      :class="{
                        'is-invalid': isSubmitted && $v.quantity.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.quantity.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quantity.required"
                        >Quantity is required</span
                      >
                      <span v-if="!$v.quantity.decimal"
                        >Quantity should be decimal only<br
                      /></span>
                      <span v-if="!$v.quantity.maxLength"
                        >Max length of 10 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="requestDate" class="required-field"
                      >Request Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="requestDate"
                      id="requestDate"
                      placeholder="Select Date"
                      v-model="requestDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabled="id > 0"
                      :disabledDates="{
                        from: new Date(),
                        to: new Date(releaseReqBackcapDate),
                      }"
                      :class="{
                        'is-invalid': isSubmitted && $v.requestDate.$error,
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.requestDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.requestDate.required"
                        >Request Date is required</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addReleaseReqDoc()"
                            :disabled="ReleaseReqDocumentAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(doc, index) in ReleaseReqDocuments"
                    :key="index"
                  >
                    <div
                      class="col-1"
                      v-if="doc.document_path !== null && id > 0"
                    >
                      <div class="form-group fc">
                        <label>Download</label>
                        <div
                          @click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i
                            id="buttons"
                            class="icofont-download text-info"
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            v-on:change="
                              handleFileUploadReleaseReqDocuments(index, $event)
                            "
                            :class="{
                              'is-invalid': doc.errors,
                            }"
                          />
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ doc.display_file_name }}</b>
                            </p>
                          </small>
                          <div v-if="doc.errors" class="invalid-feedback">
                            <span>{{ doc.errors }}</span>
                          </div>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Issue Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="IssueDate"
                          id="IssueDate"
                          placeholder="Select Date"
                          v-model="doc.IssueDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabledDates="{ from: new Date() }"
                          :calendar-button="true"
                        ></datepicker>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="comments">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="doc.comments"
                          id="comments"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ReleaseReqDocuments.$each[index].comments
                                .$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ReleaseReqDocuments.$each[index].comments.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ReleaseReqDocuments.$each[index].comments
                                .maxLength
                            "
                            >Max length of 100 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          v-on:click="deleteReleaseReqDocuments(index, doc.id)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        @click="onReqDocumentSaveInfo()"
                        :disabled="isDisabledReleaseReqDocumentSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-12">
                      <h1 class="headingt">Release To</h1>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body fc p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div class="row">
                    <div class="col-md-6">
                      <div class="custom-control custom-checkbox mb-3">
                        <label>
                          <input
                            id="releaseTo"
                            type="radio"
                            v-model="releaseTo"
                            value="logisticsInfo"
                            :disabled="id > 0"
                            :class="{
                              'is-invalid': isSubmitted && $v.releaseTo.$error,
                            }"
                          />
                          Logistics Info
                        </label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="custom-control custom-checkbox mb-3">
                        <label>
                          <input
                            type="radio"
                            v-model="releaseTo"
                            value="directShipping"
                            :disabled="id > 0"
                            :class="{
                              'is-invalid': isSubmitted && $v.releaseTo.$error,
                            }"
                          />
                          Direct Shipping
                        </label>
                      </div>
                    </div>
                    <div
                      v-if="isSubmitted && $v.releaseTo.$error"
                      class="error"
                    >
                      <span v-if="!$v.releaseTo.required"
                        >Please select Release To</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    id="submit"
                    class="btn-sm btn btn-success sh"
                    @click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    data-dismiss="modal"
                    style="cursor: pointer"
                    @click="$router.go(-1)"
                  >
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {
  required,
  maxLength,
  numeric,
  decimal,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: null,
      borrowers: [],
      facilities: [],
      collaterals: [],
      warehouses: [],
      collateralStages: [],
      mappedCM: [],
      mappedCMEmployees: [],
      selectedMappedCMEmployees: [],
      prevCMEmployeeMapping: [],
      removedCMEmployees: [],
      //list for Release Request Documents
      ReleaseReqDocuments: [],
      ReleaseReqDocumentFile: null,
      ReleaseReqDocumentsDisabled: true,
      ReleaseReqDocumentSaveInfoDisabled: true, //save info button is disabled
      ReleaseReqDocumentAddMoreDisabled: false,
      //variables for request information
      selectedBorrower: null,
      selectedFacilityId: null,
      selectedCollateral: null,
      selectedCollateralType: null,
      requestDate: new Date(),
      releaseReqBackcapDate: null,
      quantity: null,
      selectedWarehouse: null,
      selectedCM: null,
      releaseTo: null,
      isSubmitted: false,
    };
  },
  validations: {
    selectedBorrower: {
      required,
    },
    selectedFacilityId: {
      required,
    },
    selectedCollateral: {
      required,
    },
    selectedCollateralType: {
      required,
    },
    requestDate: {
      required,
    },
    quantity: {
      required: function () {
        if (!this.quantity || this.quantity == 0) return false;
        else return true;
      },
      decimal,
      maxLength: maxLength(10),
    },
    bags: {
      numeric,
      maxLength: maxLength(10),
    },
    selectedWarehouse: {
      required,
    },
    selectedCM: {
      required,
    },
    releaseTo: {
      required,
    },
    ReleaseReqDocuments: {
      $each: {
        comments: {
          maxLength: maxLength(100),
        },
      },
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
    //this is to computed disabled property of ReleaseReqDocuments Save Info button.
    isDisabledReleaseReqDocumentSaveInfo: function () {
      let checkEmptyReleaseReqDocumentsDocFileName =
        this.ReleaseReqDocuments.filter(
          (line) => line.display_file_name === null
        );
      //if the line is empty then disable save info button
      if (
        this.ReleaseReqDocuments.length === 0 &&
        this.ReleaseReqDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyReleaseReqDocumentsDocFileName.length >= 1 &&
        this.ReleaseReqDocuments.length > 0
      ) {
        this.ReleaseReqDocumentsDisabled = true;
      } else {
        this.ReleaseReqDocumentsDisabled = false;
      }
      //ReleaseReqDocumentsDisabled variable is to check whther all input fields except file upload are filled. ReleaseReqDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.ReleaseReqDocumentsDisabled === true ||
        this.ReleaseReqDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledReleaseReqDocumentSaveInfo() close
  },
  components: {
    Datepicker,
    Multiselect: window.VueMultiselect.default,
  },
  methods: {
    removeMinus(e) {
      let invalidChars = ["-"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true; // If not match, don't add to input text
    },

    getEmployeeLabel({ employee_registration_id, employee_name }) {
      return `${employee_registration_id} - ${employee_name}`;
    },

    calculateValue() {
      if (this.quantity) this.value = this.quantity * this.price;
      this.value = Number(this.value) == 0 ? null : this.value;
    },

    onWarehouseChange(requestData) {
      // this function is used to get mapped CM Companies for a particular Warehouse
      Utils.start_loading();
      const path = "get-mapped-cm/" + this.selectedWarehouse;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.mappedCM = res.data.warehouse_cm_mapping_list;
            if (this.id) {
              this.onCMChange();
              this.selectedMappedCMEmployees = requestData;
            }
            console.log(
              "get mapped CM Companies for a particular Warehouse is successful."
            );
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });

      if (
        this.selectedBorrower &&
        this.selectedFacilityId &&
        this.selectedCollateral &&
        this.selectedWarehouse
      ) {
        // this function is used to get release req backup date
        Utils.start_loading();
        const path1 =
          "get-release-req-backup-date/" +
          this.selectedBorrower +
          "/" +
          this.selectedFacilityId +
          "/" +
          this.selectedCollateral +
          "/" +
          this.selectedWarehouse;
        axios
          .get(path1, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            Utils.stop_loading();
            if (res.data.status == "success") {
              this.releaseReqBackcapDate = res.data.release_req_backup_date;
              console.log("get release Request Backcap Date is successful.");
            } else {
              console.log("Something went wrong from server!");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
            console.log("Something went wrong!");
          });
      }
    },

    onCMChange() {
      this.removedCMEmployees = this.selectedMappedCMEmployees;
      this.selectedMappedCMEmployees = [];
      let selectedCMCompany = [];
      this.mappedCM.filter((cm) => {
        if (cm.collateral_manager_master_id === this.selectedCM)
          selectedCMCompany = cm;
      });
      let selectedWarehouseCMId = selectedCMCompany.id;
      if (selectedWarehouseCMId) {
        // this function is used to get mapped CM Company Employees for a particular mapped Warehouse
        Utils.start_loading();
        const path = "get-mapped-cm-employees/" + selectedWarehouseCMId;
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            Utils.stop_loading();
            if (res.data.status == "success") {
              this.mappedCMEmployees = res.data.warehouse_cm_emp_mapping_list;
              console.log(
                "get mapped CM Company Employees for a particular mapped Warehouse is successful."
              );
            } else {
              console.log("Something went wrong from server!");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
            console.log("Something went wrong!");
          });
      }
    },

    // downloads document with given file path
    downloadDocument: function (filePath) {
      console.log("filePath: ", filePath);
      Utils.downloadDocument(filePath);
    },

    //this method will make disabled property as false for Release Request Document Save Info
    onReqDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.ReleaseReqDocumentSaveInfoDisabled = false;
      this.ReleaseReqDocumentAddMoreDisabled = false;
    },

    deleteReleaseReqDocuments(index, id) {
      if (id) {
        this.$confirm({
          auth: false,
          message: "Are you sure you want to permanently delete?",
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              Utils.start_loading();
              axios
                .delete("edit-release-request/" + this.id, {
                  headers: {
                    "Content-type": "application/json",
                  },
                  data: {
                    deleteType: "Document",
                    id: id,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 11,
                    tabId: 29,
                  },
                })
                .then((response) => {
                  Utils.stop_loading();
                  console.log("delete status: " + response.data.status);
                  if (response.data.status == "success") {
                    Utils.make_alert("success", "Document deleted");
                    setTimeout(() => this.$router.go(), 1500);
                  } else {
                    Utils.make_alert("success", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.log("error: " + error);
                  Utils.make_alert("success", "Something went wrong!");
                });
            } else {
              this.$router.go();
            }
          },
        });
      }
      this.ReleaseReqDocuments.splice(index, 1);
      this.ReleaseReqDocumentFile
        ? this.ReleaseReqDocumentFile
        : "file deleted";
      if (
        typeof this.ReleaseReqDocuments !== "undefined" &&
        this.ReleaseReqDocuments.length == 0
      ) {
        this.ReleaseReqDocumentAddMoreDisabled = false;
        this.ReleaseReqDocumentSaveInfoDisabled = true;
      }
    },

    //this method will submit the Request form in backend.
    onSubmit() {
      this.$confirm({
        auth: false,
        message: "Confirm Submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            this.isSubmitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
              return;
            }
            if (this.requestDate != null) {
              this.requestDate = moment(this.requestDate).format("MM-DD-YYYY");
            }
            this.selectedMappedCMEmployees.map((emp) => {
              if (!emp.release_mapped_cm_emp_id) {
                emp["release_mapped_cm_emp_id"] = null;
              }
            });
            this.ReleaseReqDocuments.map((doc) => {
              if (doc.IssueDate !== null) {
                doc.IssueDate = moment(doc.IssueDate).format("MM-DD-YYYY");
              }
            });
            //initialize the form data
            let formData = new FormData();
            formData.append("borrowerId", this.selectedBorrower);
            formData.append("facilityId", this.selectedFacilityId);
            formData.append("collateralId", this.selectedCollateral);
            formData.append(
              "selectedCollateralType",
              this.selectedCollateralType
            );
            formData.append("collateralQuantity", this.quantity);
            formData.append("requestDate", this.requestDate);
            formData.append("warehouseId", this.selectedWarehouse);
            formData.append("collateralManagerId", this.selectedCM);
            formData.append(
              "mappedCMCompanyEmployees",
              JSON.stringify(this.selectedMappedCMEmployees)
            );
            formData.append("releaseTo", this.releaseTo);
            if (this.id) {
              let removedCMEmployeeMapping = this.prevCMEmployeeMapping.filter(
                ({ employee_id: id1 }) =>
                  !this.selectedMappedCMEmployees.some(
                    ({ employee_id: id2 }) => id2 === id1
                  )
              );
              removedCMEmployeeMapping = removedCMEmployeeMapping.concat(
                this.removedCMEmployees
              );
              formData.append(
                "removedCMEmployeeMapping",
                JSON.stringify(removedCMEmployeeMapping)
              );
            }
            formData.append(
              "ReleaseReqDocuments",
              JSON.stringify(this.ReleaseReqDocuments)
            );
            formData.append(
              "clientId",
              JSON.stringify(this.$store.state.client_id)
            );
            formData.append("userId", this.$store.getters.getuserId);
            formData.append("moduleId", 11);
            formData.append("tabId", 29);
            const path = this.id
              ? "edit-release-request/" + this.id
              : "add-release-request";
            Utils.start_loading();
            axios
              .post(path, formData, {
                headers: {
                  enctype: "multipart/form-data",
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Release Request details saved successfully!"
                  );
                  console.log("SUCCESS!!");
                  this.$router.push("/collateral");
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                Utils.make_alert(
                  "warning",
                  error.response.status === 400
                    ? error.response.data.status
                    : "Something went wrong!"
                );
                console.error("error: " + error);
              });
          }
        },
      });
    }, //onSubmit() close

    //this method will add new line for additional Release Request Documents
    addReleaseReqDoc() {
      //when new line is added then ReleaseReqDocumentFile variable and ReleaseReqDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.ReleaseReqDocumentFile = null),
        (this.ReleaseReqDocumentSaveInfoDisabled = true),
        (this.ReleaseReqDocumentAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.ReleaseReqDocuments.push({
          id: null,
          IssueDate: new Date(),
          comments: null,
          display_file_name: null,
          errors: "",
        });
    }, //addReleaseReqDoc() close

    //handles a change on the file upload
    handleFileUploadReleaseReqDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.ReleaseReqDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.ReleaseReqDocumentFile.size > 104857600) {
          e.preventDefault();
          self.ReleaseReqDocuments[id].errors =
            "File size should be less than 100MB";
          self.ReleaseReqDocuments[id].selectedFile = null;
          self.ReleaseReqDocuments[id].selectedFileName = null;
          self.ReleaseReqDocuments[id].display_file_name = null;
          return;
        }

        reader.readAsDataURL(this.ReleaseReqDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.ReleaseReqDocuments[id].selectedFile = reader.result;
          self.ReleaseReqDocuments[id].selectedFileName =
            e.target.files[0].name;
          self.ReleaseReqDocuments[id].display_file_name =
            e.target.files[0].name; //to show filename in file input
          self.ReleaseReqDocuments[id].errors = "";
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadReleaseReqDocuments() close

    getReleaseRequest() {
      //this function is called on page load and created events. it will show details on Release Request with Release Requestid equal to this.id
      const path = "single-release-request-overview/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            let releaseRequest =
              res.data.release_request_details.request_details[0];
            this.selectedBorrower = releaseRequest.borrower_id;
            this.getFacilitySetupByBorrower(this.selectedBorrower);
            this.getWarehouseByBorrower(this.selectedBorrower);
            this.selectedFacilityId = releaseRequest.facility_id;
            this.getCollateral(this.selectedFacilityId);
            this.selectedCollateral = releaseRequest.collateral_master_id;
            this.selectedCollateralType = releaseRequest.collateral_stage_id;
            this.quantity = releaseRequest.quantity;
            this.selectedPriceType = releaseRequest.price_type;
            this.price = releaseRequest.price;
            this.value = releaseRequest.value;
            this.requestDate = releaseRequest.request_date;
            this.selectedWarehouse = releaseRequest.warehouse_master_id;
            let release_to = releaseRequest.release_to;
            if (release_to) {
              this.releaseTo =
                release_to === "Logistics" ? "logisticsInfo" : "directShipping";
            }
            this.prevCMEmployeeMapping =
              res.data.release_request_details.release_cm_emp_mapping;
            this.onWarehouseChange(this.prevCMEmployeeMapping);
            this.selectedCM = releaseRequest.collateral_manager_id;
            this.ReleaseReqDocuments =
              res.data.release_request_details.release_request_docs;
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          Utils.make_alert(
            "warning",
            error.response.status === 400
              ? error.response.data.status
              : "Something went wrong!"
          );
          console.error("error: " + error);
        });
    }, //getRequest close

    getFacilitySetupByBorrower(id) {
      //this function is called to get list of open facilities
      const path = "/facility-setup-borrower/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.facilities = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getWarehouseByBorrower(id) {
      //this function is called to get list of warehouses
      const path = "/warehouse-borrower/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.warehouses = res.data.warehouses;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCollateral(id) {
      //this function is called to get list of collaterals by facility id
      const path = "/get-collateral/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.collaterals = res.data.collaterals;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of collateral stages
    getCollateralStages() {
      const path = "collateral-stage-master";
      axios
        .get(path)
        .then((res) => {
          this.collateralStages = res.data.collateral_stages;
          this.collateralStages = this.collateralStages.slice(0, 4);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function will be called to get list of borrowers
    getBorrowers() {
      const path = "borrower";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.borrowers = res.data.borrowers_active;
          //get Approved borrowers
          if (res.data.borrowers_active) {
            this.borrowers = this.borrowers.filter((bor) => {
              if (bor.status === "Approved") {
                return bor;
              }
            });
          }
          if (res.data.status == "success") {
            console.log("get borrowers is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },
  },

  mounted() {
    this.id = Number(this.$route.params.id);
    if (this.id) this.getReleaseRequest();
    if (this.loggedInUserGroupId === "Borrower") {
      this.selectedBorrower = Number(this.loggedInUserSubGroupId);
      this.getFacilitySetupByBorrower(this.selectedBorrower);
      this.getWarehouseByBorrower(this.selectedBorrower);
    } else this.selectedBorrower = null;
    if (this.$route.params.borrower_id) {
      this.selectedBorrower = this.$route.params.borrower_id;
      this.getFacilitySetupByBorrower(this.selectedBorrower);
      this.getWarehouseByBorrower(this.selectedBorrower);
    }
  },

  created() {
    this.getBorrowers();
    this.getCollateralStages();
  },
};
</script>
<style scoped>
.error {
  font-size: 13px;
  color: red;
  padding-left: 40px;
}
</style>
