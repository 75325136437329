<!-- This is add fund page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Add Fund
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="fundName" class="required-field"
                      >Fund Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Type Fund Name..."
                      v-model="fundName"
                      :class="{
                        'is-invalid': isSubmitted && $v.fundName.$error,
                      }"
                      id="fundName"
                      name="fundName"
                    />
                    <div
                      v-if="isSubmitted && $v.fundName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.fundName.required"
                        >Fund Name is required</span
                      >
                      <span v-if="!$v.fundName.maxLength"
                        >Max length of 50 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="inceptionDate" class="required-field"
                      >Fund Inception Date</label
                    >
                    <!-- <input id="datepicker" class="form-control " placeholder="Select Date"> -->
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      id="inceptionDate"
                      name="inceptionDate"
                      placeholder="Select Date"
                      v-model="inceptionDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{ from: new Date() }"
                      :class="{
                        'is-invalid': isSubmitted && $v.inceptionDate.$error,
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.inceptionDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.inceptionDate.required"
                        >Inception Date is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCountry" class="required-field"
                      >Country</label
                    >
                    <select
                      id="selectedCountry"
                      class="form-control"
                      name="selectedCountry"
                      v-model="selectedCountry"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedCountry.$error,
                      }"
                    >
                      <option :value="null">-- Select a Country --</option>
                      <option
                        v-for="country in countries"
                        :value="country.id"
                        :key="country.id"
                      >
                        {{ country.country_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCountry.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCountry.required"
                        >Country is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCurrency" class="required-field"
                      >Currency</label
                    >
                    <select
                      id="selectedCurrency"
                      class="form-control"
                      name="selectedCurrency"
                      v-model="selectedCurrency"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedCurrency.$error,
                      }"
                    >
                      <option :value="null">-- Select a Currency --</option>
                      <option
                        v-for="curr in currencies"
                        :value="curr.id"
                        :key="curr.id"
                      >
                        {{ curr.currency_short_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCurrency.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCurrency.required"
                        >Currency is required</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-5 col-6"></div>
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <input
                      type="checkbox"
                      id="performanceCheck"
                      v-model="performanceCheck"
                    />&nbsp;&nbsp;
                    <label for="performanceCheck">Performance fees %</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="performanceFees"
                      id="performanceFees"
                      name="performanceFees"
                      min="0"
                      :disabled="!performanceCheck"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.performanceFees.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.performanceFees.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.performanceFees.required"
                        >Performance Fees % is required</span
                      >
                      <span v-if="!$v.performanceFees.maxDigit"
                        >Should not exceed 100 %</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="HurdleRate">Hurdle Rate %</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="HurdleRate"
                      id="HurdleRate"
                      name="HurdleRate"
                      min="0"
                      :disabled="!performanceCheck"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.HurdleRate.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.HurdleRate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.HurdleRate.required"
                        >Hurdle Rate % is required</span
                      >
                      <span v-if="!$v.HurdleRate.maxDigit"
                        >Should not exceed 100 %</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedPerformancePeriod">Period</label>
                    <select
                      id="selectedPerformancePeriod"
                      class="form-control"
                      name="selectedPerformancePeriod"
                      v-model="selectedPerformancePeriod"
                      :disabled="!performanceCheck"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedPerformancePeriod.$error,
                      }"
                    >
                      <option :value="null">-- Select a Period --</option>
                      <option
                        v-for="per in Period"
                        :value="per.interest_frequency_name"
                        :key="per.id"
                      >
                        {{ per.interest_frequency_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedPerformancePeriod.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedPerformancePeriod.required"
                        >Performance Fees Period is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <input
                      type="checkbox"
                      id="mgtCheck"
                      v-model="mgtCheck"
                    />&nbsp;&nbsp;
                    <label for="mgtCheck">Management fees %</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="mgtFees"
                      id="mgtFees"
                      name="mgtFees"
                      min="0"
                      :disabled="!mgtCheck"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.mgtFees.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.mgtFees.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.mgtFees.required"
                        >Management Fees % is required</span
                      >
                      <span v-if="!$v.mgtFees.maxDigit"
                        >Should not exceed 100 %</span
                      >
                    </div>
                  </div>
                </div>

                <!-- <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedMgmtPeriod">Period</label>
                    <select
                      id="selectedMgmtPeriod"
                      class="form-control"
                      name="selectedMgmtPeriod"
                      v-model="selectedMgmtPeriod"
                      :disabled="!mgtCheck"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedMgmtPeriod.$error,
                      }"
                    >
                      <option :value="null">-- Select a Period --</option>
                      <option
                        v-for="per in Period"
                        :value="per.interest_frequency_name"
                        :key="per.id"
                      >
                        {{ per.interest_frequency_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedMgmtPeriod.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedMgmtPeriod.required"
                        >Management Fees Period is required</span
                      >
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a @click="$router.go(-1)" style="cursor: pointer">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      currencies: [],
      countries: [],
      Period: [],
      selectedCurrency: null,
      selectedCountry: null,
      fundName: null,
      performanceFees: null,
      mgtFees: null,
      selectedPerformancePeriod: null,
      selectedMgmtPeriod: null,
      HurdleRate: null,
      performanceCheck: false,
      mgtCheck: false,
      inceptionDate: null,
      inceptionDate1: null,
      // msg: [],
      error: "",
      isSubmitted: false,
    };
  },
  validations: {
    fundName: {
      required,
      maxLength: maxLength(50),
    },
    inceptionDate: {
      required,
    },
    selectedCurrency: {
      required,
    },
    selectedCountry: {
      required,
    },
    performanceFees: {
      maxDigit: function (val) {
        if (Number(val) > 100) return false;
        else return true;
      },
      required: function () {
        if (
          (this.performanceCheck && (this.performanceFees == null || this.performanceFees == ''))
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
    HurdleRate: {
      maxDigit: function (val) {
        if (Number(val) > 100) return false;
        else return true;
      },
      required: function () {
        if (
          (this.performanceCheck && (this.HurdleRate == null || this.HurdleRate == ''))
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
    selectedPerformancePeriod: {
      required: function () {
        if (this.performanceCheck && this.selectedPerformancePeriod === null) {
          return false;
        } else {
          return true;
        }
      },
    },
    mgtFees: {
      maxDigit: function (val) {
        if (Number(val) > 100) return false;
        else return true;
      },
      required: function () {
        if (
          (this.mgtCheck && (this.mgtFees == null || this.mgtFees == ''))
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
    // selectedMgmtPeriod: {
    //   required: function () {
    //     if (this.mgtCheck && this.selectedMgmtPeriod === null) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   },
    // },
  },
  components: {
    Datepicker,
  },
  methods: {
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },
    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCountries() {
      //this function is called to get list of countries
      const path = "country";
      axios
        .get(path)
        .then((res) => {
          this.countries = res.data.countries;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      //this function will be called on submit event called on 'Submit' button in template.
      if (
        this.fundName == null ||
        this.inceptionDate == null ||
        this.selectedCurrency == null ||
        this.selectedCountry == null
      ) {
        Utils.make_alert("success", "Mandatory values are required.");
      } else {
        this.$confirm({
          auth: false,
          message: "Confirm Submit?",
          button: {
            no: "No",
            yes: "Yes",
          },

          callback: (confirm) => {
            if (confirm) {
              Utils.start_loading();
              if (this.inceptionDate != null) {
                this.inceptionDate1 = moment(this.inceptionDate).format(
                  "MM-DD-YYYY"
                );
              }
              axios
                .post(
                  "fund",
                  {
                    fundName: this.fundName,
                    inceptionDate: this.inceptionDate1,
                    selectedCountry: this.selectedCountry,
                    selectedCurrency: this.selectedCurrency,
                    performanceFees: this.performanceFees,
                    HurdleRate: this.HurdleRate,
                    selectedPerformancePeriod: this.selectedPerformancePeriod,
                    selectedMgmtPeriod: this.selectedMgmtPeriod,
                    mgtFees: this.mgtFees,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 1,
                    tabId: 1,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((response) => {
                  Utils.stop_loading();
                  if (response.data.status == "success") {
                    Utils.make_alert("success", "Fund added successfully!");
                    //setTimeout( () => this.$router.push("/fund"), 1000);
                    this.$router.push("/fund");
                  } else if (response.data.status == "fund_already_exists") {
                    Utils.make_alert("success", "Fund already exists!");
                    //setTimeout( () => this.$router.push("/fund"), 1000);
                    this.$router.push("/fund");
                  } else {
                    Utils.make_alert("warning", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.log("error: " + error);
                  Utils.make_alert("warning", "Something went wrong!");
                });
            }
          },
        });
      }
    }, //onSubmit() close
    getPeriod() {
      //this function is called to get list of Period
      const path = "interest-frequency";
      axios
        .get(path)
        .then((res) => {
          if (res.data.status == "success") {
            // this.Period = res.data.interest_frequencies.slice(1, 5);
            // this.Period.splice(2, 1);
            // Use the filter method to get all items where interest_frequency_name is "Monthly"
            const monthlyInterestFrequencies = res.data.interest_frequencies.filter(frequency => frequency.interest_frequency_name == "Monthly");
            // If there are no items with interest_frequency_name "Monthly", this.Period will be an empty array
            this.Period = monthlyInterestFrequencies;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close
  created() {
    this.getPeriod();
    this.getCurrencies();
    this.getCountries();
  },
};
</script>

<style scoped>
.required-field::after {
  content: "*";
  color: red;
}
.error-msg {
  color: red;
  font-weight: bold;
}
.login_img {
  background-color: #d2d2d2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60%;
}
#side_view {
  height: 100%;
  padding: 0px;
}
</style>
