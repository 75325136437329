<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-file-document"></i> Agreement History
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row"></div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table
                    class="table table-head-fixed text-nowrap agreementHistoryTable"
                    id="agreementHistoryTable"
                  >
                    <thead>
                      <tr>
                        <th>Agreement Start Date</th>
                        <th>Agreement End Date</th>
                        <th>Fees</th>
                        <th>Currency</th>
                        <th>Uploaded File</th>
                        <th
                          v-if="
                            (loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)) &&
                            !inactive
                          "
                        ></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="agg in AgreementDetails" :key="agg.id">
                        <td v-if="agg.agreement_start_date !== null">
                          {{ agg.agreement_start_date }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="agg.agreement_end_date !== null" class="c">
                          {{ agg.agreement_end_date }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="agg.fees !== null">
                          {{ agg.fees }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="agg.currency_short_name !== null">
                          {{ agg.currency_short_name }}
                        </td>
                        <td v-else>-</td>
                        <td
                          class="c"
                          v-on:click="downloadDocument(agg.document_path)"
                          v-if="agg.document_path"
                          :style="{ cursor: 'pointer' }"
                        >
                          <u>{{ agg.display_file_name }}</u>
                        </td>
                        <td v-else>-</td>
                        <td
                          v-if="
                            (loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0))  &&
                            !inactive
                          "
                          v-on:click="deleteCollateralAgreement(agg.id)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.content-wrapper -->
</template>

<script>
import $ from "jquery";
// //Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods";
import moment from "moment";

export default {
  data() {
    return {
      id: null,
      inactive: null,
      AgreementDetails: [],
    };
  },
  methods: {
    downloadDocument: function (filePath) {
      if (filePath === null) {
        Utils.make_alert("warning", "No Document Available");
        return;
      }
      Utils.downloadDocument(filePath);
    },
    getCollateralManager() {
      const path = this.inactive
        ? "single-collateral-summary-overview-inactive/" + this.id
        : "single-collateral-summary-overview-active/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            if (this.inactive) {
              this.AgreementDetails =
                res.data.collateral.collateral_agreement_details_inactive;
            } else {
              this.AgreementDetails =
                res.data.collateral.collateral_agreement_details_active;
            }
            if(this.AgreementDetails.length > 0) {
              this.AgreementDetails.forEach(item => {
                if (item.agreement_start_date != '-' && item.agreement_start_date != null) {
                  item.agreement_start_date = moment(item.agreement_start_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.agreement_start_date = '-';
                }
                if (item.agreement_end_date != '-' && item.agreement_end_date != null) {
                  item.agreement_end_date = moment(item.agreement_end_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.agreement_end_date = '-';
                }
              });
            }
            setTimeout(
              () =>
                $("#agreementHistoryTable").DataTable({
                  searching: false,
                  aaSorting: [],
                  columnDefs: [
                    {
                      orderable: false,
                      targets: this.inactive
                        ? []
                        : this.loggedInUserGroupId === "Admin"
                        ? [5]
                        : [],
                    },
                  ],
                }),
              100
            );
            console.log("get CollateralManager details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getCollateralManager close
    //this function is called when document needs to be deleted from backend database.
    deleteCollateralAgreement(id) {
      const path =
        "single-collateral-summary-overview-active/" + this.$route.params.id;
      this.$confirm({
        auth: false,
        message: "Are you sure you want to permanently delete?",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete(path, {
                data: {
                  deleteType: "Agreement",
                  id: id,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 6,
                  tabId: 25,
                },
                headers: { "Content-type": "application/json" },
              })
              .then((res) => {
                Utils.stop_loading();
                if (res.data.status == "success") {
                  Utils.make_alert("success", "Record deleted.");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(() => this.$router.go(), 1500);
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          }
        },
      });
    },
  },
  computed: {
    loggedInUserFullName() {
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
  mounted() {
    this.getCollateralManager();
  },
  created() {
    this.inactive =
      this.$store.state.inactive === "collateral_inactive" ? true : false;
    this.id = this.$route.params.id;
  },
};
</script>
