<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt" id="pageHeading">
                        <i class="icofont-truck-loaded"></i> Shipping Overview
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="card-body br_overview">
                <!------menton here style="height: 300px;"-------->
                <table class="table table-hover table-head-fixed text-nowrap">
                  <tbody>
                    <tr>
                      <td class="wg" id="borrowerName">Borrower Name:</td>
                      <td v-if="shippingDetails.borrower_name">
                        {{ shippingDetails.borrower_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="shippingId">Shipping Info ID:</td>
                      <td v-if="shippingDetails.shipping_registration_id">
                        {{ shippingDetails.shipping_registration_id }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="collateral">Collateral:</td>
                      <td v-if="shippingDetails.collateral_name">
                        {{ shippingDetails.collateral_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="collateralType">Collateral Type:</td>
                      <td v-if="shippingDetails.stage_name">
                        {{ shippingDetails.stage_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="shippingPort">Shipping Port:</td>
                      <td v-if="shippingDetails.shipping_port">
                        {{ shippingDetails.shipping_port }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="destinationPort">Destination Port:</td>
                      <td v-if="shippingDetails.destination_port">
                        {{ shippingDetails.destination_port }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="portAcceptanceDate">
                        Port Acceptance Date:
                      </td>
                      <td v-if="shippingDetails.port_acceptance_date">
                        {{ shippingDetails.port_acceptance_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="shippingDate">Shipping Date:</td>
                      <td v-if="shippingDetails.shipping_date">
                        {{ shippingDetails.shipping_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card-body br_overview">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td class="wg" id="facilityId">Facility ID:</td>
                      <td v-if="shippingDetails.facility_registration_id">
                        {{ shippingDetails.facility_registration_id }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="billOfLadingNo">
                        Bill of Lading No.:
                      </td>
                      <td v-if="shippingDetails.bill_of_landing_number">
                        {{ shippingDetails.bill_of_landing_number }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="offtakerName">Offtaker Name:</td>
                      <td v-if="shippingDetails.offtaker_name">
                        {{ shippingDetails.offtaker_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <!-- <tr>
                      <td class="wg" id="offtakerContract">
                        Offtaker Contract:
                      </td>
                      <td v-if="shippingDetails.offtaker_contract">
                        {{ shippingDetails.offtaker_contract }}
                      </td>
                      <td v-else>-</td>
                    </tr> -->

                    <tr>
                      <td class="wg" id="shippingLine">Shipping Line:</td>
                      <td v-if="shippingDetails.shipping_line">
                        {{ shippingDetails.shipping_line }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="vesselName">Vessel Name:</td>
                      <td v-if="shippingDetails.vessel_name">
                        {{ shippingDetails.vessel_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="deliveryDate">Delivery Date:</td>
                      <td v-if="shippingDetails.delivery_date">
                        {{ shippingDetails.delivery_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="companyName">CM Company Name:</td>
                      <td v-if="shippingDetails.collateralIdCompany">
                        {{ shippingDetails.collateralIdCompany }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!--========================================================================-->

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Quantity</h1>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody>
                      <tr>
                        <td class="wg" id="totalReleasedQty">
                          Released Quantity (MT):
                        </td>
                        <td v-if="shippingDetails.release_quantity !== null">
                          {{ shippingDetails.release_quantity }}
                        </td>
                        <td v-else>-</td>
                      </tr>

                      <tr>
                        <td class="wg" id="totalBags">Total Bags:</td>
                        <td v-if="shippingDetails.bags !== null">
                          {{ shippingDetails.bags }}
                        </td>
                        <td v-else>-</td>
                      </tr>

                      <tr>
                        <td class="wg" id="totalGrossWeight">
                          Gross Weight (KG):
                        </td>
                        <td v-if="shippingDetails.gross_weight !== null">
                          {{ shippingDetails.gross_weight }}
                        </td>
                        <td v-else>-</td>
                      </tr>

                      <tr>
                        <td class="wg" id="totalNetWeight">Net Weight (KG):</td>
                        <td v-if="shippingDetails.net_weight !== null">
                          {{ shippingDetails.net_weight }}
                        </td>
                        <td v-else>-</td>
                      </tr>

                      <tr>
                        <td class="wg" id="totalTareWeight">
                          Tare Weight (KG):
                        </td>
                        <td v-if="shippingDetails.tare_weight !== null">
                          {{ shippingDetails.tare_weight }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!--========================================================================-->

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Containers</h1>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody>
                      <tr v-for="con in shippingContainerDetails" :key="con.id">
                        <td class="wg" id="containerNo">Container No.:</td>
                        <td v-if="con.container_number !== null">
                          {{ con.container_number }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                      <tr v-if="shippingContainerDetails.length === 0">
                        <td class="wg col1">Container No.:</td>
                          <td class="">-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!--========================================================================-->

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-12">
                      <h1 class="headingt">Contracts</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th>Contract No.</th>
                        <th>Partial Contract No.</th>
                        <th>Shipping Quantity</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(con) in shippingContractDetails"
                        :key="con.id"
                      >
                        <td v-if="con.contract_number">
                          {{ con.contract_number }}
                        </td>
                        <td class="c" v-else>-</td>
                        <td v-if="con.partial_contract_number" class="c">
                          {{ con.partial_contract_number }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="con.total_quantity" class="c">
                          {{ con.total_quantity }}
                        </td>
                        <td v-else class="c">-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-12">
                      <h1 class="headingt">Documents</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th></th>
                        <th id="srNo">Sr. no.</th>
                        <th id="doc">Documents</th>
                        <th class="c" id="uploadDate">Uploading Date</th>
                        <th class="c" id="receiptNumber">Receipt No.</th>
                        <th class="c" id="status">Status</th>
                        <th
                          class="c"
                          v-if="
                            loggedInUserGroupId == 'Admin' &&
                            loggedInUserSubGroupId == 0
                          "
                        >
                          Change Status
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(doc, index) in shippingDocuments"
                        :key="doc.id"
                      >
                        <td
                          class="center"
                          v-on:click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-download text-info"></i>
                        </td>
                        <td v-if="doc.document_name">
                          {{ index + 1 }}
                        </td>
                        <td class="c" v-else>-</td>
                        <td v-if="doc.document_name">
                          {{ doc.document_name }}
                        </td>
                        <td class="c" v-else>-</td>
                        <td v-if="doc.uploading_date" class="c">
                          {{ doc.uploading_date }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="doc.receipt_number" class="c">
                          {{ doc.receipt_number }}
                        </td>
                        <td v-else class="c">-</td>
                        <td
                          v-if="doc.document_status"
                          :class="{
                            'c text-success':
                              doc.document_status === 'Approved',
                            'c text-warning':
                              doc.document_status === 'Received',
                            'c text-danger': doc.document_status === 'Rejected',
                            'c text-info':
                              doc.document_status === 'Near to Expiry',
                            'c text-dark': doc.document_status === 'Expired',
                          }"
                        >
                          {{
                            doc.document_status === "Received"
                              ? loggedInUserGroupId === "Borrower"
                                ? "Uploaded"
                                : doc.document_status
                              : doc.document_status
                          }}
                        </td>
                        <td v-else class="c">-</td>

                        <td
                          class="c status"
                          v-if="
                            loggedInUserGroupId == 'Admin' &&
                            loggedInUserSubGroupId == 0
                          "
                        >
                          <div class="status">
                            <select
                              id="docStatus"
                              name="docStatus"
                              @change="
                                onChangeDocStatus(
                                  $event,
                                  doc.id,
                                  doc.document_status,
                                  index,
                                  'ShippingDocStatus'
                                )
                              "
                              :disabled="doc.document_status !== 'Received'"
                              class="form-control"
                              :value="doc.document_status"
                            >
                              <option value="Received">Received</option>
                              <option value="Approved">Approved</option>
                              <option value="Rejected">Rejected</option>
                              <option
                                v-if="doc.document_status === 'Near to Expiry'"
                                value="Near to Expiry"
                              >
                                Near to Expiry
                              </option>
                              <option
                                v-if="doc.document_status === 'Expired'"
                                value="Expired"
                              >
                                Expired
                              </option>
                            </select>
                          </div>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.Tabpane second end-->
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper -->

    <!-- ./wrapper -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
export default {
  data() {
    return {
      id: null,
      shippingDetails: [],
      shippingContainerDetails: [],
      shippingContractDetails: [],
      shippingDocuments: [],
    };
  },

  components: {
    Datepicker,
  },

  methods: {
    onChangeDocStatus(updatedStatus, docId, prevStatus, index, filtertype) {
      //this function is called when user changes the document status. The status is updated in the database for that specific document.
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path = "shipment-overview/" + this.id;
            if (filtertype === "ShippingDocStatus") {
              axios
                .put(
                  path,
                  {
                    filtertype: filtertype,
                    updateType: "status",
                    updatedStatus: updatedStatus.target.value,
                    shipping_doc_id: docId,
                    userId: this.$store.getters.getuserId,
                    clientId: this.$store.state.client_id,
                    tabId: 24,
                    moduleId: 8,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("doc update status: " + res.data.status);
                  if (res.data.status == "success") {
                    this.shippingDocuments[index].document_status =
                      updatedStatus.target.value;
                    Utils.make_alert("success", "Status updated successfully!");
                  } else {
                    updatedStatus.target.value = prevStatus;
                    Utils.make_alert("warning", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  updatedStatus.target.value = prevStatus;
                  Utils.stop_loading();
                  Utils.make_alert(
                    "warning",
                    error.response.status === 400
                      ? error.response.data.status
                      : "Something went wrong!"
                  );
                });
            }
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeDocStatus close

    getShipment() {
      //this function is called on page load and created events. it will show details on Shipping with current ShippingId
      const path = "shipment-overview/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.shippingDetails = res.data.shipping_list[0];
            this.shippingContainerDetails =
              res.data.shipping_container_mapping_list;
            this.shippingContractDetails =
              res.data.offtaker_contract_list;
            console.log("this.shippingContractDetails",this.shippingContractDetails)
            this.shippingDocuments = res.data.shipping_documents_list;
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
        });
    }, //getShipment close

    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getShipment();
  },
  computed: {
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style scoped>
.col1{
  width: 200px !important;
}
.center {
  text-align: center !important;
}
</style>