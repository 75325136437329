<!-- this is sidebar view -->
<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <router-link :to="loggedInUserGroupId === 'Collateral Manager'
      ? '/collateral'
      : '/summary'
      " class="brand-link">
      <!-- <a href="home.html" class="brand-link"> -->
      <img :src="require(`@/asset/images/${logoFolder}/main_logo.png`)" alt="Main Logo" class="brand-image" />
      <span class="brand-text logo">
        <img :src="require(`@/asset/images/${logoFolder}/text_logo.png`)" class="img-fluid" />
      </span>
      <!-- </a> -->
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar" style="background-image: url(/asset/images/sidebar_bg.png)">



      <!-- Sidebar Menu -->
      <nav class="aside-nav">

        <div class="accordion" id="accordionMain">
          <template v-if="
              loggedInUserGroupId == 'Admin'"
          >
            <div class="card">
              <div id="main-header">
                <div class="card-header" id="headingOne">
                  <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                      data-target="#collapseOne" :aria-expanded="activeClassAnalytics == 'show'"  aria-controls="collapseOne">
                      <i class="bi bi-graph-up icon"></i>
                      <p>Analytics</p> <i class="fas fa-chevron-down"></i>
                    </button>
                  </h2>
                </div>
              </div>
  
  
              <div id="collapseOne" class="collapse" :class="activeClassAnalytics" aria-labelledby="headingOne" data-parent="#accordionMain">
                <div class="card-body">
                  <div class="accordion" id="accordionExample">
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                          <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                            data-target="#collapseTwo" :aria-expanded="activeClassAnalyticsFund == 'show'"  aria-controls="collapseTwo">
                            <i class="bi bi-bar-chart-line icon"></i> Fund Analytics <i class="fas fa-chevron-down"></i>
                          </button>
                        </h2>
                      </div>
  
                      <div id="collapseTwo" class="collapse" :class="activeClassAnalyticsFund" aria-labelledby="headingOne"
                        data-parent="#accordionExample">
                        <div class="card-body">
                          <ul class="navbar-nav pl47">
                            <router-link to="/fund-distribution">
                              <li class="nav-item" :class="fund_distribution">
                                <a class="nav-link">Fund Distribution</a>
                              </li>
                            </router-link>
                            <router-link to="/fund-investment">
                              <li class="nav-item" :class="fund_investment">
                                <a class="nav-link">Investment / Investors</a>
                              </li>
                            </router-link>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingTwo">
                        <h2 class="mb-0">
                          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseThree" :aria-expanded="activeClassAnalyticsLoan == 'show'"  aria-controls="collapseThree">
                            <i class="bi bi-pie-chart icon"></i> Loan Analytics <i class="fas fa-chevron-down"></i>
                          </button>
                        </h2>
                      </div>
  
                      <div id="collapseThree" class="collapse" :class="activeClassAnalyticsLoan" aria-labelledby="headingTwo"
                        data-parent="#accordionExample">
                        <div class="card-body">
                          <ul class="navbar-nav pl47">
                            <router-link to="/loan-distribution">
                              <li class="nav-item" :class="loan_distribution">
                                <a class="nav-link">Loan Distribution</a>
                              </li>
                            </router-link>
                            <router-link to="/loan-management">
                              <li class="nav-item" :class="loan_management">
                                <a class="nav-link">Loan Management</a>
                              </li>
                            </router-link>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h2 class="mb-0">
                          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseFour" :aria-expanded="activeClassAnalyticsCollateral == 'show'"  aria-controls="collapseFour">
                            <i class="bi bi-speedometer icon"></i> Collateral Analytics <i
                              class="fas fa-chevron-down"></i>
                          </button>
                        </h2>
                      </div>
  
                      <div id="collapseFour" class="collapse" :class="activeClassAnalyticsCollateral" aria-labelledby="headingThree"
                        data-parent="#accordionExample">
                        <div class="card-body">
                          <ul class="navbar-nav pl47">
                            <router-link to="/collateral-distribution">
                              <li class="nav-item" :class="collateral_distribution">
                                <a class="nav-link">Collateral Distribution</a>
                              </li>
                            </router-link>
                            <router-link to="/collateral-tracking">
                              <li class="nav-item" :class="collateral_tracking">
                                <a class="nav-link">Collateral Tracking</a>
                              </li>
                            </router-link>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-if="
            loggedInUserGroupId == 'Admin' ||
            (loggedInUserGroupId == 'Analyst' && loggedInUserSubGroupId == 0)"
          >
            <div class="card">
              <div id="main-header">
                <div class="card-header" id="headingOne">
                  <h2 class="mb-0">
                    <router-link to="/summary">
                      <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                      data-target="#collapseFive" :aria-expanded="activeClassDashboard == 'show'"  aria-controls="collapseFive">
                      <i class="icofont-dashboard-web icon"></i>
                      <p>Action Summary</p>
                    </button>
                  </router-link>
                  </h2>
                </div>
              </div>

              <div id="collapseFive" class="collapse" aria-labelledby="headingFour" data-parent="#accordionMain">
              </div>
            </div>
          </template>

          <div class="card">
            <template v-if="
                loggedInUserGroupId == 'Admin' ||
                (loggedInUserGroupId == 'Analyst' && loggedInUserSubGroupId == 0)"
            >
              <div id="main-header">
                <div class="card-header" id="headingFive">
                  <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                      data-target="#collapseSix" :aria-expanded="activeClassFund == 'show'" aria-controls="collapseSix">
                      <i class="icofont-list icon"></i>
                      <p>Fund Management</p> <i class="fas fa-chevron-down"></i>
                    </button>
                  </h2>
                </div>
              </div>
            </template>
            <template v-else-if="
              loggedInUserGroupId == 'Borrower'"
            >
              <div id="main-header">
                <div class="card-header" id="headingFive">
                  <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                      data-target="#collapseSix" :aria-expanded="activeClassFund == 'show'" aria-controls="collapseSix">
                      <i class="icofont-list icon"></i>
                      <p>Borrower Management</p> <i class="fas fa-chevron-down"></i>
                    </button>
                  </h2>
                </div>
              </div>
            </template>

            <div id="collapseSix" class="collapse" :class="activeClassFund" aria-labelledby="headingFive" data-parent="#accordionMain">
              <div class="card-body">
                <ul class="navbar-nav">
                  <template v-if="
                    loggedInUserGroupId == 'Admin' ||
                    (loggedInUserGroupId == 'Analyst' && loggedInUserSubGroupId == 0)"
                  >
                  <router-link to="/fund">
                      <li class="nav-item" :class="fund">
                        <a class="nav-link link_bg"><i class="icofont-money-bag icon"></i> Fund</a>
                      </li>
                    </router-link>
                  </template>
                  <template v-if="
                    loggedInUserGroupId == 'Admin' ||
                    (loggedInUserGroupId == 'Analyst' && loggedInUserSubGroupId == 0)"
                  >
                  <router-link to="/borrower">
                    <li class="nav-item" :class="borrowers">
                      <a class="nav-link link_bg"><i class="icofont-handshake-deal icon"></i> Borrowers</a>
                    </li>
                    </router-link>
                  </template>
                  <template v-else-if="loggedInUserGroupId == 'Borrower'">
                    <router-link to="/borrower">
                      <li class="nav-item" :class="borrowers">
                        <a class="nav-link link_bg"><i class="icofont-user-alt-7 icon"></i>Profile</a>
                      </li>
                    </router-link>
                  </template>
                  <template v-if="
                    loggedInUserGroupId == 'Admin' ||
                    (loggedInUserGroupId == 'Analyst' && loggedInUserSubGroupId == 0)"
                  >
                    <router-link to="/investor">
                      <li class="nav-item" :class="investor">
                        <a class="nav-link link_bg"><i class="icofont-coins icon"></i> Investors</a>
                      </li>
                    </router-link>
                  </template>
                  <template v-else-if="loggedInUserGroupId == 'Investor'">
                    <router-link to="/investor">
                      <li class="nav-item" :class="investor">
                        <a class="nav-link link_bg"><i class="icofont-user-alt-7 icon"></i>Profile</a>
                      </li>
                    </router-link>
                  </template>
                  <template v-if="
                    loggedInUserGroupId == 'Admin' ||
                    (loggedInUserGroupId == 'Analyst' && loggedInUserSubGroupId == 0) ||
                    loggedInUserGroupId == 'Borrower'"
                  >
                  <router-link to="/facility">
                    <li class="nav-item" :class="facility">
                      <a class="nav-link link_bg"><i class="icofont-spinner-alt-3 icon"></i> Facility Setup</a>
                    </li>
                  </router-link>
                    <router-link to="/loan">
                      <li class="nav-item" :class="loan">
                        <a class="nav-link link_bg"><i class="icofont-pie-chart icon"></i> Loans</a>
                      </li>
                    </router-link>
                  </template>
                </ul>
              </div>
            </div>
          </div>

          <div class="card">
            <template
              v-if="
                loggedInUserGroupId == 'Collateral Manager' ||
                  loggedInUserGroupId == 'Analyst' ||
                  loggedInUserGroupId == 'Admin' ||
                  loggedInUserGroupId == 'Borrower'
              "
            >
              <div id="main-header">
                <div class="card-header" id="headingSix">
                  <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                      data-target="#collapseSeven" :aria-expanded="activeClassCollateral == 'show'"  aria-controls="collapseSeven">
                      <i class="bi bi-speedometer2 icon"></i>
                      <p>Collateral Management</p> <i class="fas fa-chevron-down"></i>
                    </button>
                  </h2>
                </div>
              </div>
            </template>


            <div id="collapseSeven" class="collapse" :class="activeClassCollateral" aria-labelledby="headingSix" data-parent="#accordionMain">
              <div class="card-body">
                <ul class="navbar-nav">
                  <template
                    v-if="
                      loggedInUserGroupId == 'Collateral Manager' ||
                        loggedInUserGroupId == 'Analyst' ||
                        loggedInUserGroupId == 'Admin' ||
                        loggedInUserGroupId == 'Borrower'
                    "
                  >
                    <router-link to="/collateral">
                      <li class="nav-item" :class="collateral">
                        <a class="nav-link link_bg"><i class="icofont-notebook icon"></i> Collateral</a>
                      </li>
                    </router-link>
                  </template>
                  <template
                    v-if="
                      loggedInUserGroupId == 'Collateral Manager' ||
                        loggedInUserGroupId == 'Analyst' ||
                        loggedInUserGroupId == 'Admin' ||
                        loggedInUserGroupId == 'Borrower'
                    "
                  >
                    <router-link to="/logistics">
                      <li class="nav-item" :class="logistics">
                        <a class="nav-link link_bg"><i class="icofont-delivery-time icon"></i> Logistics</a>
                      </li>
                    </router-link>
                    <router-link to="/shipping">
                      <li class="nav-item" :class="shipping">
                        <a class="nav-link link_bg"><i class="icofont-ship icon"></i> Shipping</a>
                      </li>
                    </router-link>
                  </template>
                  <template
                  v-if="
                        loggedInUserGroupId == 'Analyst' ||
                        loggedInUserGroupId == 'Admin'
                    "
                  >
                    <router-link to="/ltv-checker">
                      <li class="nav-item" :class="ltvChecker">
                        <a class="nav-link link_bg"><i class="icofont-check-circled icon"></i> LTV Checker</a>
                      </li>
                    </router-link>
                  </template>
                </ul>
              </div>
            </div>
          </div>

          <template
            v-if="
                loggedInUserGroupId == 'Analyst' ||
                  loggedInUserGroupId == 'Admin' ||
                  loggedInUserGroupId == 'Borrower' ||
                  loggedInUserGroupId == 'Offtaker'"
          >
            <div class="card">
              <div id="main-header">
                <div class="card-header" id="headingSeven">
                  <h2 class="mb-0">
                    <router-link to="/offtaker">
                      <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                        data-target="#collapseEight" :aria-expanded="activeClassOfftaker == 'show'"  aria-controls="collapseEight">
                        <i class="bi bi-person-gear icon"></i>
                        <p>Offtaker Management</p>
                      </button>
                    </router-link>
                  </h2>
                </div>
              </div>
              <div id="collapseEight" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionMain">
              </div>
            </div>
          </template>

        </div>


      </nav>
    </div>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      activeClassDashboard: "",
      activeClassAnalytics: "",
      activeClassAnalyticsLoan: "",
      activeClassAnalyticsFund: "",
      activeClassAnalyticsCollateral: "",
      activeClassFund: "",
      activeClassCollateral: "",
      activeClassOfftaker: "",
      fund_distribution: "",
      fund_investment: "",
      loan_distribution: "",
      loan_management: "",
      collateral_distribution: "",
      collateral_tracking: "",
      fund: "",
      borrowers: "",
      investor: "",
      facility: "",
      loan: "",
      collateral: "",
      logistics: "",
      shipping: "",
      ltvChecker: "",
      tabCollapseReload: true,
      logoFolder: null,
    };
  },
  methods: {
    tabCollapse() {
      if (this.$route.path === "/" && this.tabCollapseReload) {
        this.$router.go();
      }
    },
  },
  // created() {
  //   // if (this.$store.getters.getuser) {
  //   //   console.log("ALREADY PRESENT!!!");
  //   // } else {
  //     // console.log("Calling API??", this.$store.getters.getuser);
  //     this.$store
  //       .dispatch("getLoggedInUser", this.$store.state.loggedInUser.username)
  //       .then((response) => {
  //         console.log(response);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   // }
  // },
  // computed: {
  //   usrDetails() {
  //       // console.log(this.$store.getters.getuserProfessional)
  //       return this.$store.state.loggedInUser;
  //   },
  // },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      var loggedInUserName = localStorage.getItem("username");
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
  mounted() {
    var currPath = this.$route.path;
    if (currPath.includes("summary")) {
      this.activeClassDashboard = "show";
    } else if (currPath.includes("fund-distribution")) {
      this.fund_distribution = "active";
      this.activeClassAnalytics = "show";
      this.activeClassAnalyticsFund = "show";
    } else if (currPath.includes("fund-investment")) {
      this.fund_investment = "active";
      this.activeClassAnalytics = "show";
      this.activeClassAnalyticsFund = "show";
    } else if (currPath.includes("loan-distribution")) {
      this.loan_distribution = "active";
      this.activeClassAnalytics = "show"; // Adjust if there's a specific class for loan
      this.activeClassAnalyticsLoan = "show";
    } else if (currPath.includes("loan-management")) {
      this.loan_management = "active";
      this.activeClassAnalytics = "show"; // Adjust if needed
      this.activeClassAnalyticsLoan = "show";
    } else if (currPath.includes("collateral-distribution")) {
      this.collateral_distribution = "active";
      this.activeClassAnalytics = "show";
      this.activeClassAnalyticsCollateral = "show";
    } else if (currPath.includes("collateral-tracking")) {
      this.collateral_tracking = "active";
      this.activeClassAnalyticsCollateral = "show";
      this.activeClassAnalytics = "show";
    } else if (
      currPath.includes("fund") ||
      currPath.includes("add-expenses-payment") ||
      currPath.includes("add-proportionate-expense") 
      ) {
      this.fund = "active";
      this.activeClassFund = "show";
    } else if (currPath.includes("borrower")) {
      this.borrowers = "active";
      this.activeClassFund = "show";
    } else if (currPath.includes("investor")) {
      this.investor = "active";
      this.activeClassFund = "show";
    } else if (
      currPath.includes("facility") ||
      currPath.includes("cost") ||
      currPath.includes("disbursement")
      ) {
      this.facility = "active";
      this.activeClassFund = "show";
    } else if (
      currPath.includes("loan") ||
      currPath.includes("repayment")
      ) {
      this.loan = "active";
      this.activeClassFund = "show";
    } else if (
      currPath.includes("collateral") ||
      currPath.includes("warehouse") || 
      currPath.includes("request") ||
      currPath.includes("verification") ||
      currPath.includes("uncleaned") ||
      currPath.includes("cleaning") ||
      currPath.includes("cleaned") ||
      currPath.includes("waste") ||
      currPath.includes("release-request") 
      ) {
      this.activeClassCollateral = "show";
      this.collateral = "active";
    } else if (
      currPath.includes("logistics") ||
      currPath.includes("add-release")
      ) {
      this.activeClassCollateral = "show";
      this.logistics = "active";
    } else if (currPath.includes("shipping")) {
      this.activeClassCollateral = "show";
      this.shipping = "active";
    } else if (currPath.includes("ltv-checker")) {
      this.activeClassCollateral = "show";
      this.ltvChecker = "active";
    } else if (currPath.includes("offtaker")) {
      this.activeClassOfftaker = "show";
    } else {
      this.activeClassDashboard = "show";
    }
    if (window.performance.navigation.type === 1) {
      this.tabCollapseReload = false;
    }
  },
  created() {
    this.logoFolder = this.$store.state.client_id;
  },
};
</script>

<style>
.coll-disable {
  pointer-events: none;
  cursor: default;
}
</style>
