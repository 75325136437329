<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt" id="pageHeading">
                        <i class="fas fa-tachometer-alt"></i> Release Overview
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="card-body br_overview">
                <!------menton here style="height: 300px;"-------->
                <table class="table table-hover table-head-fixed text-nowrap">
                  <tbody>
                    <tr>
                      <td class="wg" id="borrowerName">Release Info ID:</td>
                      <td v-if="releaseInfoDetails.release_id">
                        {{ releaseInfoDetails.release_id }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="facilityId">Facility ID:</td>
                      <td v-if="releaseInfoDetails.facility_registration_id">
                        {{ releaseInfoDetails.facility_registration_id }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="collateral">Collateral:</td>
                      <td v-if="releaseInfoDetails.collateral_name">
                        {{ releaseInfoDetails.collateral_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="priceName">Price Type:</td>
                      <td v-if="releaseInfoDetails.price_name !== null">
                        {{ releaseInfoDetails.price_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="value">Value:</td>
                      <td v-if="releaseInfoDetails.value">
                        {{ releaseInfoDetails.value }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="offtakerName">Offtaker Name:</td>
                      <td v-if="releaseInfoDetails.offtaker_name">
                        {{ releaseInfoDetails.offtaker_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="quantity">Quantity (MT):</td>
                      <td v-if="releaseInfoDetails.quantity">
                        {{ releaseInfoDetails.quantity }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card-body br_overview">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td class="wg" id="borrowerName">Borrower Name:</td>
                      <td v-if="releaseInfoDetails.borrower_name">
                        {{ releaseInfoDetails.borrower_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="warehouse">Warehouse:</td>
                      <td
                        v-if="
                          releaseInfoDetails.warehouse_registration_id !== null
                        "
                      >
                        <span
                          >{{ releaseInfoDetails.warehouse_registration_id }}:
                          {{ releaseInfoDetails.address }}</span
                        >
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="collateralType">Collateral Type:</td>
                      <td v-if="releaseInfoDetails.stage_name !== null">
                        {{ releaseInfoDetails.stage_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="price">Price:</td>
                      <td v-if="releaseInfoDetails.price !== null">
                        {{ releaseInfoDetails.price }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="date">Date:</td>
                      <td v-if="releaseInfoDetails.date !== null">
                        <span>{{ releaseInfoDetails.date }}</span>
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="contractNo">Contract No:</td>
                      <td v-if="releaseInfoDetails.offtaker_contract !== null">
                        <span>{{ releaseInfoDetails.offtaker_contract }}</span>
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="releaseTo">Release To:</td>
                      <td v-if="releaseInfoDetails.release_to !== null">
                        <span>{{ releaseInfoDetails.release_to }}</span>
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!--========================================================================-->

        <div class="container-fluid">
          <div
            class="row"
            v-for="(rel, index) in releaseInfoContainers"
            :key="rel.id"
          >
            <div class="col-md-6">
              <div class="card-body br_overview">
                <!------menton here style="height: 300px;"-------->
                <table class="table table-hover table-head-fixed text-nowrap">
                  <tbody>
                    <tr id="container">
                      <td class="wg">Container No: {{ index + 1 }}</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td class="wg" id="containerNo">Container No.:</td>
                      <td v-if="rel.container_number !== null">
                        {{ rel.container_number }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="releasedQty">Released Qty (MT):</td>
                      <td v-if="rel.release_quantity !== null">
                        {{ rel.release_quantity }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="truckNo">Truck No.:</td>
                      <td v-if="rel.truck_number !== null">
                        {{ rel.truck_number }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="sealNo">Seal No.:</td>
                      <td v-if="rel.seal_number !== null">
                        {{ rel.seal_number }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="date">Date:</td>
                      <td v-if="rel.date !== null">
                        {{ rel.date }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card-body br_overview">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td><br /></td>
                    </tr>

                    <tr>
                      <td class="wg" id="grossWeight">Gross Weight (KG):</td>
                      <td v-if="rel.gross_weight !== null">
                        {{ rel.gross_weight }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="netWeight">Net Weight (KG):</td>
                      <td v-if="rel.net_weight !== null">
                        {{ rel.net_weight }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="tareWeight">Tare Weight (KG):</td>
                      <td v-if="rel.tare_weight !== null">
                        {{ rel.tare_weight }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="bags">Bags:</td>
                      <td v-if="rel.bags !== null">
                        {{ rel.bags }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="gdnNo">GDN:</td>
                      <td v-if="rel.gdn_number !== null">
                        {{ rel.gdn_number }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div
                      class="card-body table-responsive p-0"
                      style="padding: 15px 0px !important"
                    >
                      <h1 class="headingt" id="warrantDetails">
                        Warrant Details
                      </h1>
                      <!------menton here style="height: 300px;"-------->
                      <table
                        class="table table-bordered table-head-fixed text-nowrap table-striped warrantTable"
                        id="warrantTable"
                      >
                        <thead>
                          <tr>
                            <th>Sr. no.</th>
                            <th class="center">Warrant Refernce ID</th>
                            <th class="center">Total Quantity</th>
                            <th class="center">Released Quantity</th>
                            <th class="center">Balance Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(war, index) in rel.warrants"
                            :key="war.id"
                          >
                            <td>{{ index + 1 }}</td>
                            <td class="center" v-if="war.reference_id">
                              {{ war.reference_id }}
                            </td>
                            <td class="center" v-else>-</td>
                            <td class="center" v-if="war.total_quantity">
                              {{ war.total_quantity }}
                            </td>
                            <td class="center" v-else>0</td>
                            <td class="center" v-if="war.released_quantity">
                              {{ war.released_quantity }}
                            </td>
                            <td class="center" v-else>0</td>
                            <td class="center" v-if="war.balance_quantity">
                              {{ war.balance_quantity }}
                            </td>
                            <td class="center" v-else>0</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
          </div>
        </div>

        <!--========================================================================-->

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Total Released</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody>
                      <tr>
                        <td class="wg" id="totalGrossWeight">Gross Weight:</td>
                        <td
                          v-if="releaseInfoDetails.total_gross_weight !== null"
                        >
                          {{ releaseInfoDetails.total_gross_weight }}
                          KG
                        </td>
                        <td v-else>-</td>
                      </tr>

                      <tr>
                        <td class="wg" id="totalNetWeight">Net Weight:</td>
                        <td v-if="releaseInfoDetails.total_net_weight !== null">
                          {{ releaseInfoDetails.total_net_weight }}
                          KG
                        </td>
                        <td v-else>-</td>
                      </tr>

                      <tr>
                        <td class="wg" id="totalTareWeight">Tare Weight:</td>
                        <td
                          v-if="releaseInfoDetails.total_tare_weight !== null"
                        >
                          {{ releaseInfoDetails.total_tare_weight }}
                          KG
                        </td>
                        <td v-else>-</td>
                      </tr>

                      <tr>
                        <td class="wg" id="totalBags">Total Bags:</td>
                        <td v-if="releaseInfoDetails.total_bags !== null">
                          {{ releaseInfoDetails.total_bags }}
                        </td>
                        <td v-else>-</td>
                      </tr>

                      <tr>
                        <td class="wg" id="totalContainers">
                          Total Containers:
                        </td>
                        <td v-if="releaseInfoDetails.total_containers !== null">
                          {{ releaseInfoDetails.total_containers }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!--========================================================================-->

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-12">
                      <h1 class="headingt">Documents</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th></th>
                        <th id="srNo">Sr. no.</th>
                        <th class="c" id="issueDate">Issue Date</th>
                        <th class="c" id="comments">Comments</th>
                        <!-- <th class="c" id="status">Status</th>
                        <th
                          class="c"
                          v-if="
                            loggedInUserGroupId == 'Analyst' ||
                            (loggedInUserGroupId == 'Admin' &&
                              loggedInUserSubGroupId == 0)
                          "
                        >
                          Change Status
                        </th> -->
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(doc, index) in releaseDocuments"
                        :key="doc.release_document_id"
                      >
                        <td
                          class="center"
                          v-if="doc.display_file_name !== null"
                          v-on:click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-download text-info"></i>
                        </td>
                        <td v-else>-</td>
                        <td v-if="doc.display_file_name !== null">
                          {{ index + 1 }}
                        </td>
                        <td class="c" v-else>-</td>
                        <td v-if="doc.issue_date !== null" class="c">
                          {{ doc.issue_date }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="doc.comments !== null" class="c">
                          {{ doc.comments }}
                        </td>
                        <td v-else class="c">-</td>
                        <!-- <td
                          v-if="doc.document_status !== null"
                          :class="{
                            'c text-success':
                              doc.document_status === 'Approved',
                            'c text-warning':
                              doc.document_status === 'Received',
                            'c text-danger': doc.document_status === 'Rejected',
                            'c text-info':
                              doc.document_status === 'Near to Expiry',
                            'c text-dark': doc.document_status === 'Expired',
                          }"
                        >
                          {{
                            doc.document_status === "Received"
                              ? loggedInUserGroupId === "Borrower"
                                ? "Uploaded"
                                : doc.document_status
                              : doc.document_status
                          }}
                        </td>
                        <td v-else class="c">-</td>

                        <td
                          class="c status"
                          v-if="
                            loggedInUserGroupId == 'Analyst' ||
                            (loggedInUserGroupId == 'Admin' &&
                              loggedInUserSubGroupId == 0)
                          "
                        >
                          <div class="status">
                            <select
                              id="docStatus"
                              name="docStatus"
                              @change="
                                onChangeDocStatus(
                                  $event,
                                  doc.release_document_id,
                                  doc.document_status,
                                  index,
                                  'ReleaseDocStatus'
                                )
                              "
                              :disabled="
                                doc.document_status !== 'Received' &&
                                doc.document_status !== 'Rejected'
                              "
                              class="form-control"
                              :value="doc.document_status"
                            >
                              <option value="Received">Received</option>
                              <option value="Approved">Approved</option>
                              <option value="Rejected">Rejected</option>
                              <option
                                v-if="doc.document_status === 'Near to Expiry'"
                                value="Near to Expiry"
                              >
                                Near to Expiry
                              </option>
                              <option
                                v-if="doc.document_status === 'Expired'"
                                value="Expired"
                              >
                                Expired
                              </option>
                            </select>
                          </div>
                        </td> -->
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.Tabpane second end-->
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper -->

    <!-- ./wrapper -->
    <!-- ./modal start -->
    <div class="modal fade" id="view_doc">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-eye-alt"></i> View Document
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <!-- <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <table class="table">
                    <tbody>
                      <tr v-for="doc in selectedDirDocumentsList">
                        <td class="col-10">{{ doc.document_name }}</td>
                        <td
                          class="c col-1"
                          v-on:click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-download text-info"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> -->
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <!-- <router-link :to="{ name: 'BorrowerOverview' }">
                <button
                  type="button"
                  class="btn-sm btn btn-dark sh"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
  </div>
  <!-- ./modal end -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
export default {
  data() {
    return {
      id: null,
      offtakers: [],
      releaseInfoDetails: [],
      releaseInfoContainers: [],
      releaseDocuments: [],
      unit: null,
    };
  },

  components: {
    Datepicker,
  },

  methods: {
    showDirDocumentModal(docList) {
      this.selectedDirDocumentsList = docList;
      $("#view_doc").modal("show");
    },

    getOfftakerName(id) {
      let offtaker_name;
      this.offtakers.filter((off) => {
        if (id === off.id) {
          offtaker_name = off.offtaker_name;
        }
      });
      return offtaker_name;
    },

    onChangeDocStatus(updatedStatus, id, prevStatus, index, filtertype) {
      console.log(id);
      //this function is called when user changes the document status. The status is updated in the database for that specific document.
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path = "single-release-summary-overview/" + this.id;
            if (filtertype === "ReleaseDocStatus") {
              axios
                .put(
                  path,
                  {
                    filtertype: filtertype,
                    updateType: "status",
                    updatedStatus: updatedStatus.target.value,
                    userId: this.$store.getters.getuserId,
                    clientId: this.$store.state.client_id,
                    moduleID: 10,
                    tabID: 27,
                    id: id,
                    request_id: this.id,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("kyc update status: " + res.data.status);
                  if (res.data.status == "success") {
                    this.releaseDocuments[index].document_status =
                      updatedStatus.target.value;
                    Utils.make_alert("success", "Status updated successfully!");
                    //if you want to send any data into server before redirection then you can do it here
                  } else {
                    updatedStatus.target.value = prevStatus;
                    Utils.make_alert("warning", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  updatedStatus.target.value = prevStatus;
                  console.error("error: " + error);
                  Utils.make_alert("warning", "Something went wrong!");
                });
            }
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeDocStatus close

    getRelease() {
      //this function is called on page load and created events. it will show details on Release with Releaseid equal to this.id
      const path = "single-release-summary-overview/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.releaseInfoDetails = res.data.release.release_info_details[0];
            this.releaseInfoContainers =
              res.data.release.release_info_container_details;
            this.releaseDocuments =
              res.data.release.release_info_documents_details;
            console.log("get Release details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getRelease close

    getBuyers() {
      const path = "buyer";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.offtakers = res.data.Buyer;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getRelease();
    this.getBuyers();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style>
textarea {
  border-style: none;
  border-color: Transparent;
  overflow: auto;
}
th,
td {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right !important;
}
#container {
  background-color: #d2d2d2;
  padding-left: 10px !important;
}
</style>
