<!-- MTis is fund-disctribution page.  -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div id="new_fund" class="container-fluid ot_tb pt-3">
          <div class="inputbx">
            <div class="row align_c">
              <div class="col-12 col-md col-sm">
                <div class="form-group fc">
                  <select
                    class="selectpicker" 
                    aria-label="select borrower" 
                    title="select borrower"
                    data-dropup-auto="false" 
                    data-live-search="true"  
                    name="selectedBorrower"
                    v-model="selectedBorrower"
                    @change="
                      getFacilitySetupByBorrower(selectedBorrower);
                      getWarehouseByBorrower(selectedBorrower)"
                  >
                    <option
                      v-for="bor in borrowers"
                      :value="bor.borrower_id"
                      :key="bor.borrower_id"
                    >
                      {{ bor.borrower_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-12 col-md col-sm">
                <div class="form-group fc">
                  <select
                    class="selectpicker" 
                    aria-label="select facility" 
                    title="select facility"
                    data-dropup-auto="false" 
                    data-live-search="true" 
                    name="selectedFacilityId"
                    v-model="selectedFacilityId"
                    @change="getCollateral(selectedFacilityId)"
                  >
                    <option
                      v-for="fac in facilities"
                      :value="fac.id"
                      :key="fac.id"
                    >
                      {{ fac.facility_registration_id }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-12 col-md col-sm">
                <div class="form-group fc">
                  <select
                    class="selectpicker" 
                    aria-label="select warehouse" 
                    title="select warehouse"
                    data-dropup-auto="false" 
                    data-live-search="true" 
                    name="selectedWarehouse"
                    v-model="selectedWarehouse"
                    @change="onWarehouseChange()"
                  >
                    <option
                      v-for="war in warehouses"
                      :value="war.id"
                      :key="war.id"
                    >
                      {{ war.warehouse_registration_id + ": " }}
                      {{ war.address }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-12 col-md col-sm">
                <div class="form-group fc">
                  <select
                      class="selectpicker" 
                      aria-label="select CM" 
                      title="select CM"
                      data-dropup-auto="false" 
                      data-live-search="true" 
                      name="selectedCM"
                      v-model="selectedCM"
                      @change="fetchVerificationInWarehouseData()"
                    >
                      <option
                        v-for="cm in mappedCM"
                        :value="cm.collateral_manager_master_id"
                        :key="cm.collateral_manager_master_id"
                      >
                        {{ cm.collateral_id }}: {{ cm.company_name }}
                      </option>
                    </select>
                </div>
              </div>

              <div class="col-12 col-md col-sm">
                <div class="form-group fc">
                  <select
                    class="selectpicker" 
                    aria-label="select collateral" 
                    title="select collateral"
                    data-dropup-auto="false" 
                    data-live-search="true" 
                    name="selectedCollateral"
                    v-model="selectedCollateral"
                    @change="fetchVerificationInWarehouseData();fetchCocoaData();"
                  >
                    <option
                      v-for="col in collaterals"
                      :value="col.id"
                      :key="col.id"
                    >
                      {{ col.collateral_name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-lg-5 col-md-6 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="title">
                    <div class="row align_c">
                      <div class="col-md-12 col-sm-12">
                        <h6 class="tp_mid text2">
                          Verification – In warehouse
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div class="ht218">
                    <apexchart 
                      v-if="VerificationInWarehouseChartDataReady" 
                      type="bar" 
                      height="200"
                      :options="VerificationInWarehouseChartOptions" 
                      :series="VerificationInWarehouseSeries">
                    </apexchart>
                    <h6 class="tp_mid title text2" v-if="!VerificationInWarehouseChartDataReady">No Data Found</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-7 col-md-6 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="title">
                    <div class="row align_c">
                      <div class="col-md-8 col-sm-8">
                        <h6 class="tp_mid text2">
                          Collateral over exposures
                        </h6>
                      </div>
                      <div class="col-md-4 col-sm-4">
                        <div class="form-group fc">
                          <select
                            class="selectpicker" 
                            aria-label="select exposure" 
                            title="select exposure"
                            data-dropup-auto="false" 
                            data-live-search="true" 
                            name="selectedCollateralType"
                            v-model="selectedCollateralType"
                            @change="fetchCollateralOverExposureData()"
                          >
                            <option
                              v-for="col in collateralStages"
                              :value="col.id"
                              :key="col.id"
                            >
                              {{ col.stage_name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ht215">
                    <apexchart 
                      v-if="CollateralOverExposureChartDataReady" 
                      type="bar" 
                      height="200"
                      :options="CollateralOverExposureChartOptions" 
                      :series="CollateralOverExposureSeries">
                    </apexchart>
                    <h6 class="tp_mid title text2" v-if="!CollateralOverExposureChartDataReady">No Data Found</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="title">
                    <div class="row align_c">
                      <div class="col-md-12 col-sm-12">
                        <h6 class="tp_mid text2">
                          Market price - {{ selectedCollateralName }}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div class="ht280">
                    <apexchart 
                      v-if="CocoaChartDataReady" 
                      type="line" 
                      height="265"
                      :options="CocoaChartOptions" 
                      :series="CocoaSeries">
                    </apexchart>
                    <h6 class="tp_mid title text2" v-if="!CocoaChartDataReady">No Data Found</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="inputbx">
                <div class="row align_c">
                  <div class="col-md-4 col-sm">
                    <div class="form-group fc">
                      <select
                        class="selectpicker" 
                        aria-label="select borrower" 
                        title="select borrower"
                        data-dropup-auto="false" 
                        data-live-search="true"  
                        name="selectedBorrowerWithAll"
                        v-model="selectedBorrowerWithAll"
                        @change="fetchWarehouseOfftakerQuantityData()"
                      >
                        <option
                          v-for="bor in borrowersWithAll"
                          :value="bor.borrower_id"
                          :key="bor.borrower_id"
                        >
                          {{ bor.borrower_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="title">
                        <div class="row align_c">
                          <div class="col-md-12 col-sm-12">
                            <h6 class="tp_mid text2">
                              Warehouse wise quantity
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="ht215">
                        <apexchart 
                          v-if="WarehouseWiseQuantityChartDataReady" 
                          type="donut" 
                          height="200"
                          :options="WarehouseWiseQuantityChartOptions" 
                          :series="WarehouseWiseQuantitySeries">
                        </apexchart>
                        <h6 class="tp_mid title text2" v-if="!WarehouseWiseQuantityChartDataReady">No Data Found</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="title">
                        <div class="row align_c">
                          <div class="col-md-12 col-sm-12">
                            <h6 class="tp_mid text2">
                              Offtaker mapped quantity
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="ht215">
                        <apexchart 
                          v-if="OfftakerMappedQuantityChartDataReady" 
                          type="donut" 
                          height="200"
                          :options="OfftakerMappedQuantityChartOptions" 
                          :series="OfftakerMappedQuantitySeries">
                        </apexchart>
                        <h6 class="tp_mid title text2" v-if="!OfftakerMappedQuantityChartDataReady">No Data Found</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.content-wrapper -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";
Vue.component("apexchart", VueApexCharts);

export default {
  data() {
    return {
      currencyName: "",
      lastMonthValue: 0.0,
      PercentageChange: 0.0,
      todayDate: moment().format("DD MMMM YYYY"),
      top3Flag: true, // Fund Dropdown

      Colors: ["#1930AB", "#0070C0", "#00B0F0", "#7F7F7F", "#D9D9D9"],

      borrowers: [],
      borrowersWithAll: [],
      facilities: [],
      collaterals: [],
      warehouses: [],
      mappedCM: [],

      selectedBorrower: null,
      selectedBorrowerWithAll: 'all',
      selectedFacilityId: null,
      selectedCollateral: null,
      selectedCollateralName: 'Collateral',
      selectedWarehouse: null,
      selectedCM: null,

      emptyCMListError: false,

      selectedCollateralType: null,
      collateralStages: [],

      // Collateral Over Exposure
      CollateralOverExposureChartOptions: {},
      CollateralOverExposureSeries: [],
      CollateralOverExposureChartData: {},
      // CollateralOverExposureChartData: {
      //   xAxisData: ["Release", "In Transit", "At Port", "Shipped" , "Delivered"],
      //   seriesData: [
      //     {
      //       name: "Uncleaned",
      //       data: [15, 7, 20, 25,12],
      //     },
      //   ],
      // },
      CollateralOverExposureChartDataReady: false,

      // Verification In Warehouse
      VerificationInWarehouseChartOptions: {},
      VerificationInWarehouseSeries: [],
      VerificationInWarehouseChartData: {},
      // VerificationInWarehouseChartData: {
      //   xAxisData: ["Borrower Name"],
      //   seriesData: [
      //     {
      //       name: "Uncleaned",
      //       data: [5],
      //     },
      //     {
      //       name: "Cleaned",
      //       data: [15],
      //     },
      //     {
      //       name: "Cleaning Process",
      //       data: [10],
      //     },
      //     {
      //       name: "Waste",
      //       data: [1],
      //     },
      //   ],
      // },
      VerificationInWarehouseChartDataReady: false,

      // Cocoa
      CocoaChartOptions: {},
      CocoaSeries: [],
      CocoaChartData: {},
      // CocoaChartData: {
      //   xAxisData: ["Jan","Feb","Mar","Apr","Jun"],
      //   seriesData: [
      //     {
      //       name: "Market Price Cocoa",
      //       data: [15, 7, 20, 25,12],
      //     },
      //   ],
      // },
      CocoaChartDataReady: false,

      // Warehouse Wise Quantity
      WarehouseWiseQuantityChartOptions: {},
      WarehouseWiseQuantitySeries: [],
      WarehouseWiseQuantityChartData: {},
      // WarehouseWiseQuantityChartData: {
      //   "labels": [
      //     "Warehouse 1", 
      //     "Warehouse 2", 
      //     "Warehouse 3", 
      //     "Warehouse 4", 
      //   ], 
      //   "seriesData": [
      //     {
      //       "data": 23.06, 
      //       "name": "Warehouse 1"
      //     }, 
      //     {
      //       "data": 0.0, 
      //       "name": "Warehouse 2"
      //     }, 
      //     {
      //       "data": 0.0, 
      //       "name": "Warehouse 3"
      //     }, 
      //     {
      //       "data": 3.52, 
      //       "name": "Warehouse 4"
      //     }, 
      //   ],
      // },
      WarehouseWiseQuantityChartDataReady: false,

      // Offtaker Mapped Quantity
      OfftakerMappedQuantityChartOptions: {},
      OfftakerMappedQuantitySeries: [],
      OfftakerMappedQuantityChartData: {},
      // OfftakerMappedQuantityChartData: {
      //   "labels": [
      //     "Offtaker 1", 
      //     "Offtaker 2", 
      //     "Offtaker 3", 
      //     "Offtaker 4", 
      //   ], 
      //   "seriesData": [
      //     {
      //       "data": 23.06, 
      //       "name": "Offtaker 1"
      //     }, 
      //     {
      //       "data": 0.0, 
      //       "name": "Offtaker 2"
      //     }, 
      //     {
      //       "data": 0.0, 
      //       "name": "Offtaker 3"
      //     }, 
      //     {
      //       "data": 3.52, 
      //       "name": "Offtaker 4"
      //     }, 
      //   ],
      // },
      OfftakerMappedQuantityChartDataReady: false,

      desiredTicks: 3,
      isLoading: false,

      isBasicDetailsSubmitted: false,
      isSubmitted: false,
    };
  },
  methods: {
    fetchVerificationInWarehouseData() {
      console.log("fetchVerificationInWarehouse");
      this.$nextTick(() => {
        console.log("refresh");
        $(".selectpicker").selectpicker("refresh"); // If using selectpicker, refresh it after data load
      });
      // Simulated API response
      const path =
        "get-verfication-in-warehouse/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacilityId +
        "/" +
        this.selectedWarehouse +
        "/" +
        this.selectedCM +
        "/" +
        this.selectedCollateral;
      console.log(path);
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.VerificationInWarehouseChartData =
              res.data.VerificationInWarehouseChartData;
            // For a bar chart, the series data structure can stay the same if it suits your data representation
            this.VerificationInWarehouseSeries = this.VerificationInWarehouseChartData.seriesData.map(
              (series) => ({
                name: series.name,
                data: series.data, // Assuming this is already in the correct format for a bar chart
              })
            );

            console.log(
              "VerificationInWarehouseSeries ",
              this.VerificationInWarehouseSeries
            );
            this.setVerificationInWarehouseChartOptionData(
              this.VerificationInWarehouseChartData.xAxisData
            );
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },


    fetchCollateralOverExposureData() {
      this.$nextTick(() => {
        console.log("refresh");
        $(".selectpicker").selectpicker("refresh"); // If using selectpicker, refresh it after data load
      });
      const path =
        "get-collateral-over-exposure/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacilityId +
        "/" +
        this.selectedWarehouse +
        "/" +
        this.selectedCM +
        "/" +
        this.selectedCollateral +
        "/" +
        this.selectedCollateralType
        ;
      console.log(path);
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.CollateralOverExposureChartData =
              res.data.CollateralOverExposureChartData;
            // For a bar chart, the series data structure can stay the same if it suits your data representation
            this.CollateralOverExposureSeries = this.CollateralOverExposureChartData.seriesData.map(
              (series) => ({
                name: series.name,
                data: series.data, // Assuming this is already in the correct format for a bar chart
              })
            );

            console.log(
              "CollateralOverExposureSeries ",
              this.CollateralOverExposureSeries
            );
            this.setCollateralOverExposureChartOptionData(
              this.CollateralOverExposureChartData.xAxisData
            );
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },

    fetchCocoaData() {
      this.$nextTick(() => {
        console.log("refresh");
        $(".selectpicker").selectpicker("refresh"); // If using selectpicker, refresh it after data load
      });
      const path = "get-cocoa-data/" + this.selectedCollateral;
      console.log(path);
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.CocoaChartData =
              res.data.CocoaChartData;
            // For a bar chart, the series data structure can stay the same if it suits your data representation
            this.CocoaSeries = this.CocoaChartData.seriesData.map(
              (series) => ({
                name: series.name,
                data: series.data, // Assuming this is already in the correct format for a bar chart
              })
            );

            console.log(
              "CocoaSeries ",
              this.CocoaSeries
            );
            this.setCocoaChartOptionData(
              this.CocoaChartData.xAxisData
            );
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },

    fetchWarehouseOfftakerQuantityData() {
      console.log("fetchWarehouseOfftakerQuantityData")
      let selectedBorrower = null;
      let selectedAllBorrower = 0;
      if (this.selectedBorrowerWithAll == 'all') {
        selectedAllBorrower = 1;
      } else {
        selectedBorrower = this.selectedBorrowerWithAll
      }
      // Simulated API response
      const path = "get-warehouse-offtaker-quantity" +
        "/" +
        selectedBorrower +
        "/" +
        selectedAllBorrower;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.OfftakerMappedQuantityChartData = res.data.OfftakerMappedQuantityChartData;
            this.OfftakerMappedQuantitySeries = this.OfftakerMappedQuantityChartData.seriesData.map(series => series.data);

            console.log("OfftakerMappedQuantitySeries ", this.OfftakerMappedQuantitySeries)
            if(this.OfftakerMappedQuantityChartData.labels.length > 0){
              this.setOfftakerMappedQuantityOptionData(this.OfftakerMappedQuantityChartData.labels,this.OfftakerMappedQuantitySeries)
            } else {
              this.OfftakerMappedQuantityChartOptions = {};
              this.OfftakerMappedQuantitySeries = [];
              this.OfftakerMappedQuantityChartData = {};
              this.OfftakerMappedQuantityChartDataReady = false;
            }

            this.WarehouseWiseQuantityChartData = res.data.WarehouseWiseQuantityChartData;
            this.WarehouseWiseQuantitySeries = this.WarehouseWiseQuantityChartData.seriesData.map(series => series.data);

            console.log("WarehouseWiseQuantitySeries ", this.WarehouseWiseQuantitySeries)
            if(this.WarehouseWiseQuantityChartData.labels.length > 0){
              this.setWarehouseWiseQuantityOptionData(this.WarehouseWiseQuantityChartData.labels,this.WarehouseWiseQuantitySeries)
            } else {
              this.WarehouseWiseQuantityChartOptions = {};
              this.WarehouseWiseQuantitySeries = [];
              this.WarehouseWiseQuantityChartData = {};
              this.WarehouseWiseQuantityChartDataReady = false;
            }
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });

    },

    setVerificationInWarehouseChartOptionData(xAxisData) {
      // const yAxisTitleLength = this.VerificationInWarehouseChartOptions.seriesData[0].name[0].length;
      // console.log("yAxisTitleLength",yAxisTitleLength)
      // Setting up chart options for a bar chart
      this.VerificationInWarehouseChartOptions = {
        chart: {
          id: "vuechart-example",
          type: "bar", // Changed from 'line' to 'bar'
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: this.Colors, // Add your custom colors here
        yaxis: {
          // Set the y-axis name
          // title: {
          //   text: 'Amounts in millions',
          //   style: {
          //     fontFamily: 'Calibri',
          //     fontSize: '14px',
          //   },
          // },
          labels: {
            style: {
              fontFamily: "Calibri",
              fontSize: "12px",
            },
          },
        },
        xaxis: {
          type: "category",
          categories: xAxisData, // your original categories
          labels: {
            style: {
              fontFamily: "Calibri",
              fontSize: "12px",
            },
          },
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 0,
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          offsetX: 0,
          offsetY: 0,
          fontFamily: "Calibri",
          fontSize: "12px",
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            formatter: function(value) {
              // Calculate percentage
              return `${value} MT`;
            },
          },
        },
      };
      this.VerificationInWarehouseChartDataReady = true;
    },

    setCollateralOverExposureChartOptionData(xAxisData) {
      // const yAxisTitleLength = this.CollateralOverExposureChartOptions.seriesData[0].name[0].length;
      // console.log("yAxisTitleLength",yAxisTitleLength)

      // Setting up chart options for a bar chart
      this.CollateralOverExposureChartOptions = {
        chart: {
          id: "vuechart-example",
          type: "bar", // Changed from 'line' to 'bar'
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: this.Colors, // Add your custom colors here
        yaxis: {
          // Set the y-axis name
          // title: {
          //   text: 'Amounts in millions',
          //   style: {
          //     fontFamily: 'Calibri',
          //     fontSize: '14px',
          //   },
          // },
          labels: {
            style: {
              fontFamily: "Calibri",
              fontSize: "12px",
            },
          },
        },
        xaxis: {
          categories: xAxisData, // your original categories
          labels: {
            style: {
              fontFamily: "Calibri",
              fontSize: "12px",
            },
          },
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 20,
            bottom: 0,
            left: 0,
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          offsetX: 0,
          offsetY: 0,
          fontFamily: "Calibri",
          fontSize: "12px",
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          // x: {
          //   formatter: function(val, opts) {
          //     const fullLabel = xAxisData[opts.dataPointIndex];
          //     // Insert a line break after every nth character (e.g., every 20 characters)
          //     const wrapAt = 20; // Adjust this number to suit your preference
          //     const wrappedLabel = fullLabel.replace(
          //       new RegExp(`(.{${wrapAt}})`, 'g'), '$1<br>'
          //     );
          //     return wrappedLabel;
          //   }
          // },
          y: {
            formatter: function(value) {
              // Calculate percentage
              return `${value} MT`;
            },
          },
          // x: {
          //   formatter: function(val, opts) {
          //     // Access the full label from the original categories array using the dataPointIndex
          //     return xAxisData[opts.dataPointIndex];
          //   }
          // },
        },
      };
      this.CollateralOverExposureChartDataReady = true;
    },

    setCocoaChartOptionData(xAxisData) {
      // const yAxisTitleLength = this.CocoaChartOptions.seriesData[0].name[0].length;
      // console.log("yAxisTitleLength",yAxisTitleLength)

      // Setting up chart options for a bar chart
      this.CocoaChartOptions = {
        chart: {
          id: "vuechart-example",
          type: "line", // Changed from 'line' to 'bar'
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: this.Colors, // Add your custom colors here
        markers: {
            size: 5,
            hover: {
                size: 8
            }
        },
        yaxis: {
          // Set the y-axis name
          // title: {
          //   text: 'Amounts in millions',
          //   style: {
          //     fontFamily: 'Calibri',
          //     fontSize: '14px',
          //   },
          // },
          labels: {
            style: {
              fontFamily: "Calibri",
              fontSize: "12px",
            },
          },
        },
        xaxis: {
          categories: xAxisData, // your original categories
          labels: {
            style: {
              fontFamily: "Calibri",
              fontSize: "12px",
            },
          },
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 20,
            bottom: 0,
            left: 0,
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          offsetX: 0,
          offsetY: 0,
          fontFamily: "Calibri",
          fontSize: "12px",
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          // x: {
          //   formatter: function(val, opts) {
          //     const fullLabel = xAxisData[opts.dataPointIndex];
          //     // Insert a line break after every nth character (e.g., every 20 characters)
          //     const wrapAt = 20; // Adjust this number to suit your preference
          //     const wrappedLabel = fullLabel.replace(
          //       new RegExp(`(.{${wrapAt}})`, 'g'), '$1<br>'
          //     );
          //     return wrappedLabel;
          //   }
          // },
          y: {
            formatter: function(value) {
              // Calculate percentage
              return `${value}`;
            },
          },
          // x: {
          //   formatter: function(val, opts) {
          //     // Access the full label from the original categories array using the dataPointIndex
          //     return xAxisData[opts.dataPointIndex];
          //   }
          // },
        },
      };
      this.CocoaChartDataReady = true;
    },

    setOfftakerMappedQuantityOptionData(OfftakerMappedQuantityLabels,OfftakerMappedQuantitySeries) {
      const total = OfftakerMappedQuantitySeries.reduce((acc, cur) => acc + cur, 0);
      
      this.OfftakerMappedQuantityChartOptions = {
        chart: {
          type: 'donut',
        },
        colors: this.Colors, // Add your custom colors here
        labels: OfftakerMappedQuantityLabels,
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              let percentage = (value / total * 100).toFixed(2); // Two decimal places
              return `${value} MT - ${percentage}%`;
            }
          }
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 10, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
        dataLabels: {
          enabled: false // MTis line hides the data labels on the chart
        },
        legend: {
          show: false,
        }
      };

      this.OfftakerMappedQuantityChartDataReady = true;
    },

    setWarehouseWiseQuantityOptionData(WarehouseWiseQuantityLabels,WarehouseWiseQuantitySeries) {
      const total = WarehouseWiseQuantitySeries.reduce((acc, cur) => acc + cur, 0);

      this.WarehouseWiseQuantityChartOptions = {
        chart: {
          type: 'donut',
        },
        colors: this.Colors, // Add your custom colors here
        labels: WarehouseWiseQuantityLabels,
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              let percentage = (value / total * 100).toFixed(2); // Two decimal places
              return `${value} MT - ${percentage}%`;
            }
          }
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 0, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
        dataLabels: {
          enabled: false // MTis line hides the data labels on the chart
        },
        legend: {
          show: false,
        }
      };

      this.WarehouseWiseQuantityChartDataReady = true;
    },

    

    getCollateral(id) {
      //this function is called to get list of collaterals by facility id
      const path = "/get-collateral/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.selectedCollateral = null;
          this.collaterals = [];
          this.collaterals = res.data.collaterals;
          this.$nextTick(() => {
            console.log("refresh")
            $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
          });
          if(this.collaterals.length > 0){
              this.selectedCollateral = this.collaterals[0].id;
              this.selectedCollateralName = this.collaterals[0].collateral_name;
            }else {
              this.selectedCollateral = null;
              this.selectedCollateralName = 'Collateral';
              this.CollateralOverExposureChartOptions = {};
              this.CollateralOverExposureSeries = [];
              this.CollateralOverExposureChartData = {};
              this.CollateralOverExposureChartDataReady = false;
              this.VerificationInWarehouseChartOptions = {};
              this.VerificationInWarehouseSeries = [];
              this.VerificationInWarehouseChartData = {};
              this.VerificationInWarehouseChartDataReady = false;
              this.CocoaChartOptions = {};
              this.CocoaSeries = [];
              this.CocoaChartData = {};
              this.CocoaChartDataReady = false;
            }
            this.fetchVerificationInWarehouseData()
            this.fetchCollateralOverExposureData()
            this.fetchCocoaData()
        })
        .catch((error) => {
          Utils.make_alert("warning","no collateral mapped for the selected facility")
          console.error(error);
        });
    },

    onWarehouseChange() {
      // this function is used to get mapped CM Companies for a particular Warehouse
      Utils.start_loading();
      const path = "get-mapped-cm/" + this.selectedWarehouse;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.selectedCM = null;
            this.mappedCM = [];
            this.mappedCM = res.data.warehouse_cm_mapping_list;
            this.$nextTick(() => {
              console.log("refresh")
              $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
            });
            if(this.mappedCM.length > 0){
              this.selectedCM = this.mappedCM[0].collateral_manager_master_id;
            }else {
              this.selectedCM = null;
              this.CollateralOverExposureChartOptions = {};
              this.CollateralOverExposureSeries = [];
              this.CollateralOverExposureChartData = {};
              this.CollateralOverExposureChartDataReady = false;
              this.VerificationInWarehouseChartOptions = {};
              this.VerificationInWarehouseSeries = [];
              this.VerificationInWarehouseChartData = {};
              this.VerificationInWarehouseChartDataReady = false;
            }
            this.getCollateral(this.selectedFacilityId);
            console.log("get mapped CM Companies for a particular Warehouse is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          Utils.make_alert("warning","no collateral manager mapped for the selected warehouse")
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    getWarehouseByBorrower(id) {
      //this function is called to get list of warehouses
      const path = "/warehouse-borrower/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.selectedWarehouse = null;
          this.warehouses = [];
          this.warehouses = res.data.warehouses;
          this.$nextTick(() => {
            console.log("refresh")
            $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
          });
          if(this.warehouses.length > 0){
              this.selectedWarehouse = this.warehouses[0].id;
              this.onWarehouseChange(this.selectedWarehouse);
            }else {
              this.selectedWarehouse = null;
              this.selectedCM = null;
              this.mappedCM = [];
              this.CollateralOverExposureChartOptions = {};
              this.CollateralOverExposureSeries = [];
              this.CollateralOverExposureChartData = {};
              this.CollateralOverExposureChartDataReady = false;
              this.VerificationInWarehouseChartOptions = {};
              this.VerificationInWarehouseSeries = [];
              this.VerificationInWarehouseChartData = {};
              this.VerificationInWarehouseChartDataReady = false;
            }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFacilitySetupByBorrower(id) {
      //this function is called to get list of open facilities
      const path = "/facility-setup-borrower/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.selectedFacilityId = null;
          this.facilities = [];
          this.facilities = res.data.Facility_Setup;
          this.$nextTick(() => {
            console.log("refresh")
            $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
          });
          if(this.facilities.length > 0){
              this.selectedFacilityId = this.facilities[0].id;
            }else {
              this.selectedFacilityId = null;
              this.collaterals = [];
              this.selectedCollateral = null;
              this.selectedCollateralName = 'Collateral';
              this.CollateralOverExposureChartOptions = {};
              this.CollateralOverExposureSeries = [];
              this.CollateralOverExposureChartData = {};
              this.CollateralOverExposureChartDataReady = false;
              this.VerificationInWarehouseChartOptions = {};
              this.VerificationInWarehouseSeries = [];
              this.VerificationInWarehouseChartData = {};
              this.VerificationInWarehouseChartDataReady = false;
              this.CocoaChartOptions = {};
              this.CocoaSeries = [];
              this.CocoaChartData = {};
              this.CocoaChartDataReady = false;
            }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function will be called to get list of borrowers
    getBorrowers() {
      const path = "borrower-master";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          this.selectedBorrower = null;
          this.borrowers = [];
          this.borrowers = res.data.borrowers;
          // Create a copy of res.data.borrowers for borrowersWithAll
          this.borrowersWithAll = [...res.data.borrowers];
          // Add a new object at the beginning of the borrowersWithAll array
          this.borrowersWithAll.unshift({
            borrower_id: 'all',
            borrower_name: 'All Borrowers'
          });
          this.$nextTick(() => {
            console.log("refresh")
            $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
          });
          this.fetchWarehouseOfftakerQuantityData();
          if(this.borrowers.length > 0){
              this.selectedBorrower = this.borrowers[0].borrower_id;
              this.selectedBorrowerWithAll = this.borrowersWithAll[0].borrower_id;
              this.getFacilitySetupByBorrower(this.selectedBorrower);
              this.getWarehouseByBorrower(this.selectedBorrower);
            }else {
              this.selectedBorrower = null;
              this.selectedBorrowerWithAll = null;
            }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    //this function is called to get list of collateral stages
    getCollateralStages() {
      const path = "collateral-stage-master";
      axios
        .get(path)
        .then((res) => {
          this.collateralStages = res.data.collateral_stages;
          this.collateralStages = this.collateralStages.slice(0, 4);
          this.selectedCollateralType = this.collateralStages[0].id
          this.$nextTick(() => {
            console.log("refresh");
            $(".selectpicker").selectpicker("refresh"); // If using selectpicker, refresh it after data load
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.getCollateralStages();
    this.getBorrowers();
  },
};
</script>
