import { render, staticRenderFns } from "./AddCollateral.vue?vue&type=template&id=a4670f5a&scoped=true&"
import script from "./AddCollateral.vue?vue&type=script&lang=js&"
export * from "./AddCollateral.vue?vue&type=script&lang=js&"
import style0 from "./AddCollateral.vue?vue&type=style&index=0&id=a4670f5a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4670f5a",
  null
  
)

export default component.exports