<!-- This is add collateral page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Edit Request
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <span style="font-size: 12px; color: red"
            >Note: Either delete or add operation can be done. Only single
            operation can be handled.</span
          >
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Borrower Name</label>
                    <input
                      id="selectedBorrowerName"
                      class="form-control"
                      name="selectedBorrowerName"
                      v-model="selectedBorrowerName"
                      disabled
                    />

                    <!-- <div
                      v-if="isSubmitted && $v.selectedBorrowerName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedBorrowerName.required"
                        >Borrower Name is required</span
                      >
                    </div> -->
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Facility ID</label>
                    <input
                      id="selectedFacilityId"
                      class="form-control"
                      name="selectedFacilityId"
                      v-model="selectedFacilityId"
                      disabled
                    />
                    <!-- <div
                      v-if="isSubmitted && $v.selectedFacilityId.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedFacilityId.required"
                        >Facility ID is required</span
                      >
                    </div> -->
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Utilisation ID</label>
                    <input
                      id="selectedUtilId"
                      class="form-control"
                      name="selectedUtilId"
                      v-model="selectedUtilId"
                      disabled
                    />
                    <!-- <div
                      v-if="isSubmitted && $v.selectedUtilId.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedUtilId.required"
                        >Utilisation ID is required</span
                      >
                    </div> -->
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Offtaker Name</label>
                    <input
                      id="selectedOfftakerName"
                      class="form-control"
                      name="selectedOfftakerName"
                      v-model="selectedOfftakerName"
                      disabled
                    />

                    <!-- <div
                      v-if="isSubmitted && $v.selectedOfftakerName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedOfftakerName.required"
                        >Utilisation ID is not selected/invalid</span
                      >
                    </div> -->
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Request Type</label>
                    <select
                      id="selectedRequestType"
                      class="form-control"
                      name="selectedRequestType"
                      v-model="selectedRequestType"
                      disabled
                    >
                      <option value="Release">Release</option>
                      <option value="Verification">Verification</option>
                    </select>
                    <!-- <div
                      v-if="isSubmitted && $v.selectedRequestType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedRequestType.required"
                        >Request Type is required</span
                      >
                    </div> -->
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Request Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="requestDate"
                      id="requestDate"
                      placeholder="Select Date"
                      v-model="requestDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      disabled
                    ></datepicker>
                    <!-- <div
                      v-if="isSubmitted && $v.requestDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.requestDate.required"
                        >Request Date is required</span
                      >
                    </div> -->
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Collateral Quantity</label
                    >
                    <input
                      type="number"
                      class="form-control"
                      placeholder
                      id="collateralQuantity"
                      v-model="collateralQuantity"
                      @keypress="isNum($event)"
                      disabled
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.collateralQuantity.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.collateralQuantity.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.collateralQuantity.required"
                        >Please enter Collateral Quantity</span
                      >
                      <span v-if="!$v.collateralQuantity.maxLength"
                        >Max length of 15 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Unit</label
                    >
                    <select
                      id="selectedUnit"
                      class="form-control"
                      name="selectedUnit"
                      v-model="selectedUnit"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedUnit.$error,
                      }"
                    >
                      <option :value="null">-- Select --</option>
                      <option v-for="unit in UnitTypes" :value="unit.name">
                        {{ unit.name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedUnit.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedUnit.required"
                        >Please select Unit</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addRequestDoc()"
                            :disabled="RequestDocumentsAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(doc, index) in RequestDocuments"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label>Select Document</label>
                        <select
                          id="RequestDocName"
                          class="form-control"
                          name="RequestDocName"
                          v-model="doc.RequestDocName"
                          @change="addEditedFlag(index)"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="document in RequestDocs"
                            :key="document.id"
                          >
                            {{ document.document_name }}
                          </option>
                        </select>
                        <!-- <div
                          v-if="
                            isSubmitted &&
                            $v.RequestDocuments.$each[index]
                              .RequestDocName.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.RequestDocuments.$each[index]
                                .RequestDocName.required
                            "
                            >Document Name is required</span
                          >
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @mouseenter="mouseenter(doc.RequestDocName, index)"
                        @mouseleave="mouseleave(doc.RequestDocName, index)"
                      >
                        <label for="exampleInputEmail1">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            :disabled="disableUpload(index, doc.RequestDocName)"
                            v-on:change="
                              handleFileUploadRequestDocuments(index, $event) &
                                addEditedFlag(index)
                            "
                          />
                          <!-- <div
                            v-if="isSubmitted && doc.display_file_name === null"
                            class="invalid-feedback"
                          >
                            <span v-if="doc.display_file_name === null"
                              >Document Name is required</span
                            >
                          </div> -->
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ doc.display_file_name }}</b>
                              <small>
                                <u
                                  style="color: blue; cursor: pointer"
                                  v-if="
                                    doc.RequestDocumentsRemoveDisabled === false
                                  "
                                  v-on:click="removeRequestDocumentsDoc(index)"
                                  >Remove</u
                                >
                              </small>
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.errors">
                              {{ doc.errors }}
                            </p>
                          </small>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Uploading Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="RequestDocumentsDate"
                          id="RequestDocumentsDate"
                          placeholder="Select Date"
                          v-model="doc.RequestDocumentsDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabledDates="{ from: new Date() }"
                          :calendar-button="true"
                          @input="addEditedFlag(index)"
                        ></datepicker>
                        <!-- <div
                          v-if="
                            isSubmitted &&
                            $v.RequestDocuments.$each[index]
                              .RequestDocumentsDate.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.RequestDocuments.$each[index]
                                .RequestDocumentsDate.required
                            "
                            >Uploading Date is required</span
                          >
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          id="docDelete"
                          v-on:click="deleteRequestDocuments(index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onRequestDocumentSaveInfo()"
                        :disabled="isDisabledRequestDocumentSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    id="submit"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a @click="$router.go(-1)" style="cursor: pointer">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: null,
      requestType: null,
      offtaker: [],
      UnitTypes: [],
      request: [],
      //variables for request information
      selectedBorrowerName: null,
      selectedFacilityId: null,
      selectedUtilId: null,
      utilRequestDate: null,
      selectedOfftakerName: null,
      selectedRequestType: null,
      requestDate: null,
      collateralQuantity: null,
      selectedUnit: null,

      //list for Request Documents
      RequestDocuments: [],
      RequestDocs: [],
      RequestDocumentsLength: null,
      RequestDocumentsFile: null,
      // RequestDocumentsButtonDisabled: true,
      RequestDocumentsDisabled: true,
      RequestDocumentsSaveInfoDisabled: true, //save info button is disabled
      RequestDocumentsAddMoreDisabled: true,
      RequestDelete: [],
      msg: [],
      error: "",
      RequestDelete: [],
      isSubmitted: false,
      onSubmitDisabled: false,
    };
  },
  validations: {
    collateralQuantity: {
      required,
      maxLength: maxLength(10),
    },
    selectedUnit: {
      required,
    },
    // CollateralDocuments: {
    //   $each: {
    //     CollateralDocName: {
    //       required,
    //     },
    //     CollateralDocumentsDate: {
    //       required,
    //     },
    //   },
    // },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },

    //this is to computed disabled property of RequestDocuments Save Info button.
    isDisabledRequestDocumentSaveInfo: function () {
      let checkEmptyRequestDocumentsDocName = this.RequestDocuments.filter(
        (line) => line.RequestDocName === null
      );
      let checkEmptyRequestDocumentsDate = this.RequestDocuments.filter(
        (line) => line.RequestDocumentsDate === null
      );
      //if the line is empty then disable save info button
      if (
        this.RequestDocuments.length === 0 &&
        this.RequestDocumentsSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyRequestDocumentsDocName.length >= 1 &&
          this.RequestDocuments.length > 0) ||
        (checkEmptyRequestDocumentsDate.length >= 1 &&
          this.RequestDocuments.length > 0)
      ) {
        this.RequestDocumentsDisabled = true;
      } else {
        this.RequestDocumentsDisabled = false;
      }
      //RequestDocumentsDisabled variable is to check whther all input fields except file upload are filled. RequestDocumentsFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.RequestDocumentsDisabled === true ||
        this.RequestDocumentsFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledRequestDocumentSaveInfo() close
  },
  components: {
    Datepicker,
  },
  methods: {
    addEditedFlag(index) {
      if (index < this.RequestDocumentsLength) {
        this.RequestDocuments[index]["edited"] = true;
      }
    },
    isNum(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true; // If not match, don't add to input text
    },

    //this method will make disabled property as false for Request Save Info
    onRequestDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.RequestDocumentsSaveInfoDisabled = false;
      this.RequestDocumentsAddMoreDisabled = false;
    },

    //this function deletes RequestDocument record
    deleteRequestDocuments(index) {
      let deleteDocId = this.RequestDocuments[index].document_id;
      let deleteId;
      if (this.requestType === "Release") {
        deleteId = this.RequestDocuments[index].release_document_id;
      } else {
        deleteId = this.RequestDocuments[index].verification_document_id;
      }
      this.RequestDelete.push({
        id: deleteId,
        docId: deleteDocId,
        type: "Document",
        request_type: this.requestType,
        clientId: this.$store.state.client_id,
        userId: this.$store.getters.getuserId,
        moduleID: 11,
        tabID: this.requestType === "Release" ? 29 : 28,
      });
      this.RequestDocumentsFile = "";
      this.RequestDocuments.splice(index, 1);
      if (
        typeof this.RequestDocuments !== "undefined" &&
        this.RequestDocuments.length == 0
      ) {
        this.RequestDocumentsAddMoreDisabled = false;
        this.RequestDocumentsSaveInfoDisabled = true;
      }
    },

    getRequest() {
      //this function is called on page load and created events. it will show details on Request with Requestid equal to this.id
      const path = "single-request-summary-overview/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.request = res.data.release.request_details[0];
            this.RequestDocuments =
              res.data.release.release_documents_details ||
              res.data.release.verification_documents_details;

            this.selectedBorrowerName = this.request.borrower_name;
            this.selectedFacilityId = this.request.facility_id;
            this.selectedUtilId = this.request.loan_master_id;
            this.selectedOfftakerName = this.request.offtaker_name;
            this.selectedRequestType = this.requestType;
            this.requestDate = this.request.request_date;
            this.collateralQuantity =
              this.requestType === "Release"
                ? this.request.release_quantity.replaceAll(",", "")
                : this.request.verification_quantity.replaceAll(",", "");
            this.selectedUnit = this.request.unitname;

            this.RequestDocumentsLength = this.RequestDocuments.length;
            if (this.RequestDocumentsLength) {
              this.RequestDocumentsFile = "file";
              this.RequestDocuments.filter((doc) => {
                doc["edited"] = false;
                doc["RequestDocName"] =
                  doc["VerificationDocName"] || doc["ReleaseDocName"];
                doc["RequestDocumentsDate"] =
                  doc["VerificationDocumentsDate"] ||
                  doc["ReleaseDocumentsDate"];
                doc["request_document_id"] =
                  doc["verification_document_id"] || doc["release_document_id"];
                doc["request_type"] = this.requestType;
              });
            } else {
              this.RequestDocuments = [];
              this.RequestDocumentsAddMoreDisabled = false;
            }
            console.log("get Request details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getRequest close

    //this method will submit the Request form in backend.
    onSubmit() {
      this.$confirm({
        auth: false,
        message: "Confirm Submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            this.isSubmitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
              return;
            }

            for (let i = 0; i < this.RequestDocuments.length; i++) {
              if (this.RequestDocuments[i].RequestDocName === null) {
                this.RequestDocuments.splice(i, 1);
              } else {
                if (this.RequestDocuments[i].RequestDocumentsDate !== null) {
                  this.RequestDocuments[i].RequestDocumentsDate = moment(
                    this.RequestDocuments[i].RequestDocumentsDate
                  ).format("MM-DD-YYYY");
                }
                // if (this.requestType === "Release") {
                //   console.log("Here");
                //   this.RequestDocuments[i]["release_document_id"] =
                //     this.RequestDocuments[i].request_document_id;
                // } else if (this.requestType === "Verification") {
                //   this.RequestDocuments[i]["verification_document_id"] =
                //     this.RequestDocuments[i].request_document_id;
                // }
              }
            }
            this.offtaker.filter((off) => {
              if (off.offtaker_name === this.selectedOfftakerName) {
                this.selectedOfftakerName = off.id;
              }
            });
            this.UnitTypes.filter((unit) => {
              if (unit.name === this.selectedUnit) {
                this.selectedUnit = unit.id;
              }
            });
            //initialize the form data
            let formData = new FormData();
            formData.append("BorrowerName", this.selectedBorrowerName);
            formData.append("facilityId", this.selectedFacilityId);
            formData.append("utilisationId", this.selectedUtilId);
            formData.append("offtakerId", this.selectedOfftakerName);
            formData.append("requestType", this.selectedRequestType);
            formData.append("requestId", this.id);
            formData.append("requestDate", this.requestDate);
            formData.append("collateralQuantity", this.collateralQuantity);
            formData.append("unit", this.selectedUnit);
            formData.append(
              "clientId",
              JSON.stringify(this.$store.state.client_id)
            );
            formData.append("userId", this.$store.getters.getuserId);
            formData.append("moduleId", 11);
            formData.append(
              "tabId",
              this.selectedRequestType === "Release" ? 29 : 28
            );
            formData.append(
              "RequestDocuments",
              JSON.stringify(this.RequestDocuments)
            );
            formData.append(
              "DeleteDocData",
              JSON.stringify(this.RequestDelete)
            );
            const path = "edit_request/" + this.id;
            Utils.start_loading();
            axios
              .post(path, formData, {
                headers: {
                  enctype: "multipart/form-data",
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert("success", "Request updated successfully!");
                  console.log("SUCCESS!!");
                  this.$router.push("/collateral");
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error(error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          }
        },
      });
    }, //onSubmit() close

    //this method will add new line for additional Collateral Documents
    addRequestDoc() {
      //when new line is added then RequestDocumentsFile variable and RequestDocumentsSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.RequestDocumentsFile = null),
        (this.RequestDocumentsSaveInfoDisabled = true),
        (this.RequestDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.RequestDocuments.push({
          id: null,
          document_id: null,
          document_path: null,
          release_document_id: null,
          verification_document_id: null,
          request_type: this.requestType,
          RequestDocName: null,
          RequestDocumentsDate: new Date(),
          display_file_name: null,
          errors: "",
          edited: false,
          RequestDocumentsRemoveDisabled: true,
        });
    }, //addRequestDoc() close
    mouseenter(docName, index) {
      if (docName === null) {
        this.RequestDocuments[index].errors = "Document name is not selected";
      }
    },
    mouseleave(docName, index) {
      if (docName === null) {
        this.RequestDocuments[index].errors = "";
      }
    },
    disableUpload(id, docName) {
      if (docName === null) {
        return true;
      } else {
        return false;
      }
    },
    //handles a change on the file upload
    handleFileUploadRequestDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.RequestDocumentsFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.RequestDocumentsFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors = "File size should be less than 100MB";
          self.RequestDocuments[id].errors = JSON.stringify(
            response.errors
          ).replace(/\"/g, "");
          self.RequestDocuments[id].selectedFile = null;
          self.RequestDocuments[id].selectedFileName = null;
          self.RequestDocuments[id].display_file_name = null;
          self.RequestDocuments[id].RequestDocumentsRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.RequestDocumentsFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.RequestDocuments[id].selectedFile = reader.result;
          self.RequestDocuments[id].selectedFileName = e.target.files[0].name;
          self.RequestDocuments[id].display_file_name = e.target.files[0].name; //to show filename in file input
          self.RequestDocuments[id].errors = "";
          self.RequestDocuments[id].RequestDocumentsRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadRequestDocuments() close

    ////this method handles remove doc attachment
    removeRequestDocumentsDoc(id) {
      this.RequestDocumentsFile = null;
      this.RequestDocuments[id].selectedFile = null;
      this.RequestDocuments[id].selectedFileName = null;
      this.RequestDocuments[id].display_file_name = null;
      this.RequestDocuments[id].RequestDocumentsRemoveDisabled = true;
    },
    getBuyers() {
      const path = "buyer";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.offtaker = res.data.Buyer;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    //this function is called to get list of benchmarks
    getUnitTypes() {
      const path = "unit_types";
      axios
        .get(path)
        .then((res) => {
          this.UnitTypes = res.data.UnitTypes;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of request documents
    getRequestDocuments() {
      const path = "request-document";
      axios
        .get(path)
        .then((res) => {
          this.RequestDocs = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getRequestDocuments() close
  },

  created() {
    this.id = this.$route.params.id;
    this.requestType = this.id.startsWith("V") ? "Verification" : "Release";
    this.getRequest();
    this.getBuyers();
    this.getUnitTypes();
    this.getRequestDocuments();
  },
};
</script>

<style scoped>
.error {
  color: red;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -70px;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>
