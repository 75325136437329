<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Utilisation Request Overview</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover table-head-fixed text-nowrap">
                    <tbody>
                      <tr>
                        <td class="wg">Fund Name:</td>
                        <td v-if="utilReq.fund_name !== null" class>
                          {{ utilReq.fund_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Borrower Name:</td>
                        <td v-if="utilReq.borrower_name !== null" class>
                          {{ utilReq.borrower_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->

                      <tr>
                        <td class="wg">Facility ID:</td>
                        <td
                          v-if="utilReq.facility_registration_id !== null"
                          class
                        >
                          {{ utilReq.facility_registration_id }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->

                      <tr>
                        <td class="wg">Utilisation ID:</td>
                        <td v-if="utilReq.loan_registration_id !== null" class>
                          {{ utilReq.loan_registration_id }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->


                      <!-- <tr>
                      <td class="wg">Country:</td>
                      <td v-if="utilReq.Country !== null " class>{{ utilReq.Country }}</td>
                      <td v-else class>-</td>
                      </tr>-->
                      <!----------------------------------------------->

                      <!-- <tr>
                        <td class="wg">Buyer:</td>
                        <td v-if="utilReq.offtaker_name !== null" class>
                          {{ utilReq.offtaker_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr> -->
                      <!----------------------------------------------->

                      <!-- <tr>
                        <td class="wg">Loan Modification Date:</td>
                        <td
                          v-if="utilReq.loan_modification_date !== null"
                          class
                        >
                          {{ utilReq.loan_modification_date }}
                        </td>
                        <td v-else class>-</td>
                      </tr> -->
                      <!----------------------------------------------->
                      <tr>
                      <td class="wg">Loan Requested Date:</td>
                        <td v-if="utilReq.loan_request_date !== null" class>
                          {{ utilReq.loan_request_date }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <!-- <tr>
                      <td class="wg">Loan Approval Date:</td>
                        <td v-if="utilReq.approval_date !== null" class>
                          {{ utilReq.approval_date }}
                        </td>
                        <td v-else class>-</td>
                      </tr> -->
                      <!----------------------------------------------->
                      <tr>
                      <td class="wg">Loan Amount:</td>
                      <td v-if="utilReq.request_amount !== null" class>
                        {{utilReq.request_amount}}
                      </td>
                      <td v-else class>-</td>
                    </tr>
                    <!----------------------------------------------->
                    <tr>
                      <td class="wg">Loan Currency:</td>
                      <td v-if="utilReq.currency_short_name !== null" class>
                        {{ utilReq.currency_short_name }}
                      </td>
                      <td v-else class>-</td>
                    </tr>
                    <!----------------------------------------------->
                    <tr>
                      <td class="wg">Fund Currency:</td>
                      <td v-if="utilReq.fund_currency_name !== null" class>
                        {{ utilReq.fund_currency_name }}
                      </td>
                      <td v-else class>-</td>
                    </tr>
                    <!----------------------------------------------->
                    <tr>
                      <td class="wg">Conversion Rate:</td>
                      <td v-if="utilReq.conversion_rate !== null" class>
                        {{utilReq.conversion_rate}}
                      </td>
                      <td v-else class>-</td>
                    </tr>
                    <!----------------------------------------------->


                    <tr>
                      <td class="wg">Loan Conv. Amount (Fund Currency):</td>
                      <td v-if="utilReq.loan_converted_amount !== null" class>
                        {{utilReq.loan_converted_amount}}
                      </td>
                      <td v-else class>-</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody></tbody>
                    <!-- <tr>
                      <td class="wg">Loan Amount:</td>
                      <td v-if="utilReq.request_amount !== null" class>
                        {{utilReq.request_amount}}
                      </td>
                      <td v-else class>-</td>
                    </tr>
                    <tr>
                      <td class="wg">Fund Currency:</td>
                      <td v-if="utilReq.fund_currency_short_name !== null" class>
                        {{ utilReq.fund_currency_short_name }}
                      </td>
                      <td v-else class>-</td>
                    </tr> -->
                    <!----------------------------------------------->
                    
                    
                    <tr>
                      <td class="wg">Interest Chargeable:</td>
                      <td v-if="utilReq.interest_chargeable !== null">
                        {{ utilReq.interest_chargeable }}
                      </td>
                      <td v-else>-</td>
                      <!-- <td
                      v-if="utilReq.InterestDrawdown !== null & utilReq.InterestDrawdown !== 'false' "
                      class
                    >{{ utilReq.InterestDrawdown }}</td>
                    <td
                      v-else-if="utilReq.InterestUtilisationRequest !== null & utilReq.InterestUtilisationRequest !== 'false' "
                      class
                    >{{ utilReq.InterestUtilisationRequest }}</td>
                      <td v-else class>-</td>-->
                    </tr>
                    
                    <!----------------------------------------------->
                    <tr>
                      <td class="wg">Interest Rate Type:</td>
                      <td v-if="utilReq.loan_interest_name !== null">
                        {{ utilReq.loan_interest_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <!----------------------------------------------->
                    <tr>
                      <td class="wg">Interest Rate (%):</td>
                      <td v-if="utilReq.interest_rate !== null">
                        {{ utilReq.interest_rate }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <!----------------------------------------------->
                    <tr>
                      <td class="wg">Spread (%):</td>
                      <td v-if="utilReq.interest_spread !== null">
                        {{ utilReq.interest_spread }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <!----------------------------------------------->
                    <tr>
                      <td class="wg">Benchmark Rate Type:</td>
                      <td v-if="utilReq.benchmark_name != 'None'">
                        {{ utilReq.benchmark_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    
                    <tr>
                      <td class="wg">Day Count:</td>
                      <td v-if="utilReq.day_count_name !== null " class>{{ utilReq.day_count_name }}</td>
                      <td v-else class>-</td>
                      </tr>
                    <!----------------------------------------------->
                    <!-- <tr>
                      <td class="wg">Day Count:</td>
                      <td v-if="dayCount !== null" class>
                        {{ dayCount }}
                      </td>
                      <td v-else class>-</td>
                    </tr> -->
                    <!----------------------------------------------->
                    <tr>
                      <td class="wg">Tenure & Period:</td>
                      <td v-if="utilReq.tenure !== null" class>
                        {{ utilReq.tenure }} {{ utilReq.period }}
                      </td>
                      <td v-else class>-</td>
                    </tr>
                    <!----------------------------------------------->

                    <!-- <tr>
                    <td class="wg">Bank Payment Details:</td>
                    <td
                      v-if="utilReq.bank_payment_details !== null "
                      class
                    >{{ utilReq.bank_payment_details }}</td>
                    <td v-else class>-</td>
                    </tr>-->
                    <tr>
                      <td class="wg">Supplier:</td>
                      <td v-if="utilReq.supplier_name !== null" class>
                        {{ utilReq.supplier_name }}
                      </td>
                      <td v-else class>-</td>
                    </tr>
                    <tr>
                      <td class="wg">Loan Status:</td>
                      <!--<td v-if="utilReq.status !== null " >{{ utilReq.status }}</td>-->
                      <td
                        :class="{
                          'text-success': utilReq.status === 'Open',
                          'text-danger': utilReq.status === 'Closed',
                          'text-warning': utilReq.status === 'Pending',
                        }"
                      >
                        {{ utilReq.status }}
                      </td>
                      <!--<td v-else>-</td>-->
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid tabtop" id="al_tb">
            <div class="tp_mid">
              <div class="row">
                <div class="col-lg-12">
                  <ul
                    class="nav nav-tabs"
                    id="custom-tabs-one-tab"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="custom-tabs-one-home-tab"
                        data-toggle="pill"
                        href="#one"
                        role="tab"
                        aria-controls="custom-tabs-one-home"
                        aria-selected="true"
                        >Drawdown</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="custom-tabs-one-profile-tab"
                        data-toggle="pill"
                        href="#two"
                        role="tab"
                        aria-controls="custom-tabs-one-profile"
                        aria-selected="false"
                        >Repayments</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="custom-tabs-one-profile-tab"
                        data-toggle="pill"
                        href="#three"
                        role="tab"
                        aria-controls="custom-tabs-one-profile"
                        aria-selected="false"
                        >Costs</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="custom-tabs-one-profile-tab"
                        data-toggle="pill"
                        href="#four"
                        role="tab"
                        aria-controls="custom-tabs-one-profile"
                        aria-selected="false"
                        >Profit Return</a
                      >
                    </li>
                    <!-- <li class="nav-item">
                      <a
                        class="nav-link"
                        id="custom-tabs-one-profile-tab"
                        data-toggle="pill"
                        href="#five"
                        role="tab"
                        aria-controls="custom-tabs-one-profile"
                        aria-selected="false"
                      >Loan</a>
                    </li>-->
                  </ul>
                </div>
                <!-- /.col -->
              </div>
            </div>
          </div>

          <div class="tab-content" id="custom-tabs-one-tabContent">
            <div
              class="tab-pane fade show active"
              id="one"
              role="tabpanel"
              aria-labelledby="draw1"
            >
              <div class="container-fluid ot_tb pt-3">
                <div class="card card-primary card-tabs">
                  <div class="card-body">
                    <div class="container-fluid" id="al_tb">
                      <div class="tp_mid">
                        <div class="row">
                          <div class="col-lg-6">
                            <h1 class="headingt">Drawdown Details</h1>
                          </div>
                          <!-- /.col -->
                          <div class="col-lg-6">
                            <ul id="sr_btn">
                              <li v-if="!inactive">
                                <div class="col-lg-6">
                                  <router-link
                                    :to="{
                                      name: 'AddDisbursement',
                                      params: {
                                        flow: 'Loan',
                                        selectedFund: fund_id,
                                        selectedBorrower: borrower_id,
                                        selectedFacility: [
                                            { 
                                                collateral_id: collateral_id,
                                                facility_registration_id: facility_registration_id ,
                                                id: facility_id
                                            }
                                        ],
                                        fundCurrency: fund_currency_id,
                                        loanID: id,
                                        approvalDate: approval_date,
                                        selectedCollateralID: [
                                            {
                                                collateral_name: collateral_name ,
                                                id: collateral_id
                                            }
                                        ],
                                        collateralNames: collateralNames,
                                        selectedDisbursementOn: selectedDisbursementOn,
                                        // prev_drawdown_date: DrawdownReq.length
                                        //   ? DrawdownReq[DrawdownReq.length - 1]
                                        //       .disbursement_date
                                        //   : null,
                                        // balance_amount: DrawdownReq.length
                                        //   ? DrawdownReq[DrawdownReq.length - 1]
                                        //       .balance_amount
                                        //   : null,
                                      },
                                    }"
                                  >
                                    <button
                                      v-if="(utilReq.status === 'Open') && (!this.isBalanceAmountZero) && (loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0))"
                                      type="button"
                                      class="btn btn-sm sh_n btn-success"
                                    >
                                      <i
                                        class="icofont-plus plus-icon"
                                        style="font-size: 0.97em"
                                      ></i>
                                      Add Drawdown
                                    </button>
                                  </router-link>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <!-- /.col -->
                        </div>
                      </div>

                      <div class="card-body table-responsive p-0">
                        <!------menton here style="height: 300px;"-------->
                        <table class="table table-head-fixed text-nowrap">
                          <thead>
                            <tr>
                              <th>Drawdown Date</th>
                              <th>Drawdown Amount (Fund Currency)</th>
                              <!-- <th>Disbursement Currency</th> -->
                              <th>Converted Drawdown Amount (Loan Currency)</th>
                              <th>Balance Amount</th>
                              <!--<th>Status</th>
                              <th
                                v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"
                              >Change Status</th>-->
                              <!--<th class="c" v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0 && utilReq.status !== 'Closed'"></th>-->
                              <!-- ! DELETE  -->
<!-- 
                              <th
                                class="c"
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0 &&
                                  utilReq.status !== 'Closed'
                                "
                              ></th> -->
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="ddn in DrawdownReq" :key="ddn.id">
                              <td v-if="ddn.disbursement_date !== null">
                                {{ ddn.disbursement_date }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="ddn.disbursement_amount !== null">
                                {{
                                  ddn.disbursement_amount
                                }}
                              </td>
                              <td v-else>-</td>
                              <!-- <td v-if="ddn.currency_short_name !== null">
                                {{ ddn.currency_short_name }}
                              </td>
                              <td v-else>-</td> -->
                              <!-- <td v-if="ddn.fund_currency_short_name !== null">
                                {{ ddn.fund_currency_short_name }}
                              </td>
                              <td v-else>-</td> -->
                              <td v-if="ddn.converted_disbursement_amt !== null">
                                {{ ddn.converted_disbursement_amt }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="ddn.balance_amount !== null">
                                {{ ddn.balance_amount }}
                              </td>
                              <td v-else>-</td>
                              <!--<td
                                :class="{ 'c text-success': ddn.status === 'Open','c text-warning': ddn.status === 'Closed'}"
                              >{{ddn.status}}</td>
                              <template
                                v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"
                              >
                                <td class="c status">
                                <div>
                                  <select
                                    id="docStatus"
                                    name="docStatus"
                                    @change="
                                      onChangeUtilOverviewStatus(
                                        $event,
                                        ddn.id,
                                        ddn.loan_registration_id,
                                        'DrawdownStatus'
                                      )
                                    "
                                    class="form-control"
                                    v-model="ddn.status"
                                    :disabled="ddn.status === 'Closed'"
                                  >
                                    <option :value="null"></option>
                                    <option value="Open">Open</option>
                                    <option value="Closed">Closed</option>
                                    >
                                  </select>
                                </div>
                                </td>
                              </template>-->
                              <!--<td class="c" v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0 && utilReq.status !== 'Closed'">
                                <router-link
                                  style="text-decoration: none"
                                  :to="{
                                    name: 'EditDrawdownRequest',
                                    params: { id: ddn.id },
                                  }"
                                >
                                  <i class="icofont-ui-edit text-warning"></i>
                                </router-link>
                              </td>-->
                              <!-- ! DELETE  -->
                              <!-- <td
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0 &&
                                  utilReq.status !== 'Closed' &&
                                  !RepaymentDetails.length
                                "
                                v-on:click="
                                  deleteRequest(
                                    ddn.id,
                                    ddn.loan_registration_id,
                                    'Drawdown'
                                  )
                                "
                                :style="{ cursor: 'pointer' }"
                              >
                                <i class="icofont-trash text-danger"></i>
                              </td> -->
                              <!----------------------------------------------->
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- /.card -->
                </div>
              </div>

              <div class="container-fluid ot_tb pt-3">
                <div class="card card-primary card-tabs">
                  <div class="card-body">
                    <div class="container-fluid" id="al_tb">
                      <div class="tp_mid">
                        <div class="row">
                          <div class="col-lg-12">
                            <h1 class="headingt">Drawdown Documents</h1>
                          </div>
                          <!-- /.col -->
                        </div>
                      </div>

                      <div class="card-body table-responsive p-0">
                        <!------menton here style="height: 300px;"-------->
                        <table class="table table-head-fixed text-nowrap">
                          <thead>
                            <tr>
                              <th>Documents</th>
                              <th>Comments</th>
                              <!-- <th>Expiry Date</th> -->
                              <!-- <th>Status</th>
                              <th
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0
                                "
                              >
                                Change Status
                              </th> -->
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="(dd_doc, index) in drawdownDocs"
                              :key="dd_doc.id"
                            >
                              <td
                                v-on:click="
                                  downloadFacDocument(dd_doc.document_path)
                                "
                                :style="{ cursor: 'pointer' }"
                              >
                                <i class="icofont-download text-info"></i>
                                {{ dd_doc.display_file_name }}
                              </td>
                              <td v-if="dd_doc.comments !== null">
                                {{ dd_doc.comments }}
                              </td>
                              <td v-else>-</td>
                              <!-- <td v-if="dd_doc.expiry_date !== null">{{ dd_doc.expiry_date }}</td>
                              <td v-else>-</td>-->
                              <!-- <td
                                :class="{
                                  'text-success': dd_doc.status === 'Approved',
                                  'text-warning': dd_doc.status === 'Received',
                                  'text-danger': dd_doc.status === 'Rejected',
                                }"
                              >
                                {{ dd_doc.status }}
                              </td>
                              <template
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0
                                "
                              >
                                <td class="c status">
                                  <div class="status">
                                    <select
                                      id="docStatus"
                                      name="docStatus"
                                      @change="
                                        onChangeUtilOverviewStatus(
                                          $event,
                                          dd_doc.id,
                                          null,
                                          'DrawdownDocStatus',
                                          dd_doc.status,
                                          index
                                        )
                                      "
                                      class="form-control"
                                      :value="dd_doc.status"
                                      :disabled="
                                        dd_doc.status === 'Approved' || inactive
                                      "
                                    >
                                      <option value="Received">Received</option>
                                      <option value="Approved">Approved</option>
                                      <option value="Rejected">Rejected</option>
                                    </select>
                                  </div>
                                </td>
                              </template> -->
                            </tr>
                            <!----------------------------------------------->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- /.card -->
                </div>
              </div>
              <!-- documents end -->
            </div>
            <!-- /.Tabpane first end-->

            <div
              class="tab-pane fade"
              id="two"
              role="tabpanel"
              aria-labelledby="repayment1"
            >
              <div class="container-fluid ot_tb pt-3">
                <div class="card card-primary card-tabs">
                  <div class="card-body">
                    <div class="container-fluid" id="al_tb">
                      <div class="tp_mid">
                        <div class="row">
                          <div class="col-lg-6">
                            <h1 class="headingt">Repayment Details</h1>
                          </div>
                          <!-- /.col -->
                          <div class="col-lg-6">
                            <ul id="sr_btn">
                              <li v-if="!inactive && loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)">
                                <div class="col-lg-6">
                                  <!-- <router-link
                                    :to="{
                                      name: 'AddLoanPayment',
                                      params: {
                                        borrower_id: borrower_id,
                                        facility_id: facility_id,
                                        fund_currency_id: fund_currency_id,
                                        loan_registration_id: id,
                                        approval_date: approval_date,
                                      },
                                    }"
                                  > -->
                                  <router-link
                                    :to="{
                                      name: 'AddRepayment',
                                      params: {
                                        id:0,
                                        repaymentType: 'Loan',
                                        contractType:'Offtaker',
                                        loanID: id,
                                        selectedBorrower: borrower_id,
                                        selectedFacility: facility_id,
                                        fundCurrency: fund_currency_id,
                                      },
                                    }"
                                  >
                                    <button
                                      v-if="utilReq.status === 'Open' && (loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0))"
                                      type="button"
                                      class="btn btn-sm sh_n btn-success"
                                    >
                                      <i
                                        class="icofont-plus"
                                        style="font-size: 0.97em"
                                      ></i>
                                      Add Repayment
                                    </button>
                                  </router-link>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <!-- /.col -->
                        </div>
                      </div>

                      <div class="card-body table-responsive p-0">
                        <!------menton here style="height: 300px;"-------->
                        <table class="table table-head-fixed text-nowrap">
                          <thead>
                            <tr>
                              <th>Repayment Date</th>
                              <th>Repayment Amount (Fund Currency)</th>
                              <th>Prepaid Repayment Amount (Fund Currency)</th>
                              <!-- <th>Currency</th> -->
                              <th>Converted Repayment Amount (Loan Currency)</th>
                              <!-- <th>Outstanding Amount (Fund Currency)</th> -->
                              <!-- <th>Outstanding Amount</th> -->
                              <!--<th>Status</th>
                              <th
                                v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"
                              >Change Status</th>-->
                              <!--<th class="c" v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0 && utilReq.status !== 'Closed'"></th>-->
                                                            <!-- ! DELETE -->

                              <!-- <th
                                class="c"
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0 &&
                                  utilReq.status !== 'Closed'
                                "
                              ></th> -->
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="repay in RepaymentDetails"
                              :key="repay.id"
                            >
                              <td v-if="repay.repayment_date !== null">
                                {{ repay.repayment_date }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="repay.repayment_amount !== null">
                                {{ repay.repayment_amount  }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="repay.prepaid_repayment_amount !== null">
                                {{ repay.prepaid_repayment_amount }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="repay.loan_converted_repayment_amount !== null">
                                {{ repay.loan_converted_repayment_amount  }}
                              </td>
                              <td v-else>-</td>
                              <!-- <td v-if="repay.outstanding_amount !== null">
                                {{
                                  repay.outstanding_amount
                                }}
                              </td>
                              <td v-else>-</td> -->
                              <!--<td
                                :class="{ 'c text-success': repay.status === 'Open','c text-warning': repay.status === 'Closed'}"
                              >{{repay.status}}</td>
                              <template
                                v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"
                              >
                              <td class="c status">
                                <div>
                                  <select
                                    id="docStatus"
                                    name="docStatus"
                                    @change="
                                      onChangeUtilOverviewStatus(
                                        $event,
                                        repay.id,
                                        repay.loan_registration_id,
                                        'RepaymentStatus'
                                      )
                                    "
                                    class="form-control"
                                    v-model="repay.status"
                                    :disabled="repay.status === 'Closed'"
                                  >
                                    <option :value="null"></option>
                                    <option value="Open">Open</option>
                                    <option value="Closed">Closed</option>
                                  </select>
                                </div>
                              </td>
                              </template>-->
                              <!--<td class="c" v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0 && utilReq.status !== 'Closed'">
                                <router-link
                                  style="text-decoration:none;"
                                  :to="{
                                    name: 'EditLoanPayment',
                                    params: { id: repay.id },
                                  }"
                                >
                                  <i class="icofont-ui-edit text-warning"></i>
                                </router-link>
                              </td>-->

                              <!-- ! DELETE -->
                              <!-- <td
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0 &&
                                  utilReq.status !== 'Closed' &&
                                  utilReq.status !== 'Penalty Due'
                                "
                                v-on:click="
                                  deleteRequest(
                                    repay.id,
                                    repay.loan_registration_id,
                                    'Repayment'
                                  )
                                "
                                :style="{ cursor: 'pointer' }"
                              >
                                <i class="icofont-trash text-danger"></i>
                              </td> -->
                            </tr>
                            <!----------------------------------------------->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- /.card -->
                </div>
              </div>

              <div class="container-fluid ot_tb pt-3">
                <div class="card card-primary card-tabs">
                  <div class="card-body">
                    <div class="container-fluid" id="al_tb">
                      <div class="tp_mid">
                        <div class="row">
                          <div class="col-lg-12">
                            <h1 class="headingt">Repayment Documents</h1>
                          </div>
                          <!-- /.col -->
                        </div>
                      </div>

                      <div class="card-body table-responsive p-0">
                        <!------menton here style="height: 300px;"-------->
                        <table class="table table-head-fixed text-nowrap">
                          <thead>
                            <tr>
                              <th>Documents</th>
                              <th>Comments</th>
                              <!-- <th>Status</th>
                              <th
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0
                                "
                              >
                                Change Status
                              </th> -->
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="(rp_doc, index) in repaymentDocs"
                              :key="rp_doc.id"
                            >
                              <td
                                v-on:click="
                                  downloadFacDocument(rp_doc.document_path)
                                "
                                :style="{ cursor: 'pointer' }"
                              >
                                <i class="icofont-download text-info"></i>
                                {{ rp_doc.displayFileName }}
                              </td>
                              <td v-if="rp_doc.comments !== null">
                                {{ rp_doc.comments }}
                              </td>
                              <td v-else>-</td>
                              <!-- <td
                                :class="{
                                  'text-success': rp_doc.status === 'Approved',
                                  'text-warning': rp_doc.status === 'Received',
                                  'text-danger': rp_doc.status === 'Rejected',
                                }"
                              >
                                {{ rp_doc.status }}
                              </td>
                              <template
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0
                                "
                              >
                                <td class="c status">
                                  <div class="status">
                                    <select
                                      id="docStatus"
                                      name="docStatus"
                                      @change="
                                        onChangeUtilOverviewStatus(
                                          $event,
                                          rp_doc.id,
                                          null,
                                          'RepaymentDocStatus',
                                          rp_doc.status,
                                          index
                                        )
                                      "
                                      class="form-control"
                                      :value="rp_doc.status"
                                      :disabled="
                                        rp_doc.status === 'Approved' || inactive
                                      "
                                    >
                                      <option value="Received">Received</option>
                                      <option value="Approved">Approved</option>
                                      <option value="Rejected">Rejected</option>
                                    </select>
                                  </div>
                                </td>
                              </template> -->
                            </tr>
                            <!----------------------------------------------->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- /.card -->
                </div>
              </div>
              <!-- documents end -->
            </div>
            <!-- /.Tabpane second end-->

            <div
              class="tab-pane fade"
              id="three"
              role="tabpanel"
              aria-labelledby="cost1"
            >
              <div class="container-fluid ot_tb pt-3">
                <div class="card card-primary card-tabs">
                  <div class="card-body">
                    <div class="container-fluid" id="al_tb">
                      <div class="tp_mid">
                        <div class="row">
                          <div class="col-lg-6">
                            <h1 class="headingt">Cost Summary</h1>
                          </div>
                          <!-- /.col -->
                          <div
                            class="col-lg-6"
                            v-if="
                              loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0)
                            "
                          >
                            <ul id="sr_btn">
                              <li v-if="!inactive">
                                <div class="col-lg-6">
                                  <router-link
                                    :to="{
                                      name: 'AddCost',
                                      params: {
                                        flow: 'Loan',
                                        selectedBorrower: borrower_id,
                                        selectedFacility: facility_id,
                                        fundCurrency: fund_currency_id,
                                        loanID: id,
                                      },
                                    }"
                                  >
                                    <button
                                      v-if="utilReq.status === 'Open' && (loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0))"
                                      type="button"
                                      class="btn btn-sm sh_n btn-success"
                                    >
                                      <i
                                        class="icofont-plus"
                                        style="font-size: 0.97em"
                                      ></i>
                                      Add Cost
                                    </button>
                                  </router-link>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <!-- /.col -->
                        </div>
                      </div>

                      <div class="card-body table-responsive p-0">
                        <!------menton here style="height: 300px;"-------->
                        <table class="table table-head-fixed text-nowrap">
                          <thead>
                            <tr>
                              <th>Cost Date</th>
                              <th>Description</th>
                              <th>Cost Amount (Fund Currency)</th>
                              <th>Cost Currency</th>
                              <th>Converted Amount</th>
                              <th>Amount Paid</th>
                              <!-- <th>Payment Date</th> -->
                              <th>Outstanding Amount</th>
                              <th>Status</th>
                              <th
                                v-if="loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)"
                              >Change Status</th>
                              <!--<th class="c">Document</th>-->
                              <!--<th class="c" v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0 && utilReq.status !== 'Closed'"></th>-->
                                                            <!-- ! DELETE  -->

                              <!-- <th
                                class="c"
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0 &&
                                  utilReq.status !== 'Closed'
                                "
                              ></th> -->
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(cost,index) in CostSummary" :key="cost.fet_id"  >
                              <td v-if="cost.expense_date !== null">
                                {{ cost.expense_date }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="cost.loan_expense_name !== null">
                                {{ cost.loan_expense_name }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="cost.cost_currency_expense_amount !== null">
                                {{ cost.cost_currency_expense_amount }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="cost.cost_currency !== null">
                                {{ cost.cost_currency }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="cost.converted_exp_amt !== null">
                                {{ cost.converted_exp_amt }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="cost.amount_paid !== null">
                                {{ cost.amount_paid }}
                              </td>
                              <td v-else>-</td>
                              <!-- <td v-if="cost.payment_date !== null">
                                {{ cost.payment_date }}
                              </td>
                              <td v-else>-</td> -->
                              <td v-if="cost.outstanding_amount !== null">
                                {{
                                  cost.outstanding_amount
                                }}
                              </td>
                              <td v-else>-</td>
                              <!--<td class="c">
                              <a href="#" data-toggle="modal" data-target="#view_doc">
                                <i class="icofont-file-pdf text-danger"></i>
                              </a>
                              </td>-->
                              <td
                                :class="{
                                  'text-warning': cost.status === 'Pending',
                                  'text-success': cost.status === 'Approved',
                                  'text-danger': cost.status === 'Rejected',
                                }"
                              >
                                {{ cost.status }}
                              </td>
                              <template
                                v-if="loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)"
                              >
                                <td class="c status">
                                  <div>
                                    <select
                                      id="docStatus"
                                      name="docStatus"
                                      @change="
                                        onChangeCostStatus(
                                          $event,
                                          cost.fet_id,
                                          cost.status,
                                          index,
                                        )
                                      "
                                      class="form-control"
                                      v-model="cost.status"
                                      :disabled="cost.status == 'Approved' || cost.status == 'Rejected'"
                                    >
                                      <option disabled value="Pending">Pending</option>
                                      <option value="Approved">Approved</option>
                                      <option value="Rejected">Rejected</option>
                                    </select>
                                  </div>
                                </td>
                              </template>
                              <!--<td v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0 && utilReq.status !== 'Closed'"
                                style="text-decoration: none"
                                v-on:click="
                                  showEditModal(
                                    cost.id,
                                    cost.loan_expense_date,
                                    cost.expense_amount,
                                    cost.expense_id,
                                    cost.currency_id,
                                    'LoanCostUpdate'
                                  )
                                "
                                :style="{ cursor: 'pointer' }"
                              >
                                <i class="icofont-ui-edit text-warning"></i>
                              </td>-->
                                                            <!-- ! DELETE  -->

                              <!-- <td
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0 &&
                                  utilReq.status !== 'Closed' &&
                                  cost.status != 'Closed'
                                "
                                v-on:click="
                                  deleteRequest(
                                    cost.id,
                                    cost.loan_registration_id,
                                    'LoanCost'
                                  )
                                "
                                :style="{ cursor: 'pointer' }"
                              >
                                <i class="icofont-trash text-danger"></i>
                              </td> -->
                            </tr>
                            <!----------------------------------------------->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- /.card -->
                </div>
              </div>
              <div class="container-fluid ot_tb pt-3">
                <div class="card card-primary card-tabs">
                  <div class="card-body">
                    <div class="container-fluid" id="al_tb">
                      <div class="tp_mid">
                        <div class="row">
                          <div class="col-lg-12">
                            <h1 class="headingt">Cost Documents</h1>
                          </div>
                          <!-- /.col -->
                        </div>
                      </div>

                      <div class="card-body table-responsive p-0">
                        <!------menton here style="height: 300px;"-------->
                        <table class="table table-head-fixed text-nowrap">
                          <thead>
                            <tr>
                              <th>Documents</th>
                              <th>Comments</th>
                              <!-- <th>Status</th>
                              <th
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0
                                "
                              >
                                Change Status
                              </th> -->
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="(cost_doc, index) in costDocs"
                              :key="cost_doc.id"
                            >
                              <td
                                v-on:click="
                                  downloadFacDocument(cost_doc.document_path)
                                "
                                :style="{ cursor: 'pointer' }"
                              >
                                <i class="icofont-download text-info"></i>
                                {{ cost_doc.display_file_name }}
                              </td>
                              <td v-if="cost_doc.comments !== null">
                                {{ cost_doc.comments }}
                              </td>
                              <td v-else>-</td>
                              <!-- <td
                                :class="{
                                  'text-success':
                                    cost_doc.status === 'Approved',
                                  'text-warning':
                                    cost_doc.status === 'Received',
                                  'text-danger': cost_doc.status === 'Rejected',
                                }"
                              >
                                {{ cost_doc.status }}
                              </td>
                              <template
                                v-if="
                                  loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0
                                "
                              >
                                <td class="c status">
                                  <div class="status">
                                    <select
                                      id="docStatus"
                                      name="docStatus"
                                      @change="
                                        onChangeUtilOverviewStatus(
                                          $event,
                                          cost_doc.id,
                                          null,
                                          'CostDocsStatus',
                                          cost_doc.status,
                                          index
                                        )
                                      "
                                      class="form-control"
                                      :value="cost_doc.status"
                                      :disabled="
                                        cost_doc.status === 'Approved' ||
                                        inactive
                                      "
                                    >
                                      <option value="Received">Received</option>
                                      <option value="Approved">Approved</option>
                                      <option value="Rejected">Rejected</option>
                                    </select>
                                  </div>
                                </td>
                              </template> -->
                            </tr>
                            <!----------------------------------------------->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- /.card -->
                </div>
              </div>
              <!-- documents end -->
            </div>
            <!-- /.Tabpane third end-->

            <div
              class="tab-pane fade"
              id="four"
              role="tabpanel"
              aria-labelledby="loan1"
            >
              <div class="container-fluid ot_tb pt-3">
                <div class="card card-primary card-tabs">
                  <div class="card-body" id='profit_return'>
                    <div class="container-fluid" id="al_tb">
                      <div class="tp_mid">
                        <div class="row">
                          <div class="col-lg-12">
                            <h1 class="headingt">Profit Return</h1>
                          </div>
                          <span  style="font-size: 12px; color: red ; margin-left: 16px;"
                              >Note: The Profit return amount cannot be transferred to the same loan.</span
                            >
                          <!-- /.col -->
                          <!--<div class="col-lg-6" v-if="loggedInUserGroupId == 'Admin' || loggedInUserGroupId == 'Analyst' && loggedInUserSubGroupId == 0 || utilReq.status !== 'Closed'">-->
                          <!-- <ul id="sr_btn">
                              <li>
                                <button
                                  type="button"
                                  class="btn btn-sm sh_n btn-success"
                                  v-on:click="showAddProfitReturnModal()"
                                >
                                  <i class="icofont-plus"></i> Add Profit Return
                                </button>
                              </li>
                          </ul>-->
                          <!--</div>-->
                          <!-- /.col -->
                        </div>
                      </div>

                      <div class="card-body table-responsive p-0">
                        <!------menton here style="height: 300px;"-------->
                        <table class="table table-head-fixed text-nowrap">
                          <thead>
                            <tr>
                              <th class='col'>Profit Return Date</th>
                              <th class='col'>Profit Return Amount</th>
                              <th class='col'>Status</th>
                              <template v-if="loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)">
                                <th class='col'>Change Status</th>
                              </template>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="(pftret, index) in ProfitReturn"
                              :key="pftret.profit_return_id"
                            >
                              <td v-if="pftret.payment_date !== null">
                                {{ pftret.payment_date }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="pftret.balance_amount !== null">
                                {{ pftret.balance_amount }}
                              </td>
                              <td v-else>-</td>
                              <td
                                :class="{
                                  'text-warning': pftret.status == 'Not Utilized',
                                  'text-success': pftret.status == 'Profit Return',
                                  'text-info': pftret.status == 'Utilized',
                                }"
                              >
                                {{ pftret.status }}
                              </td>
                              <template v-if="loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)">
                                <td class="status">
                                  <select
                                    id="selectedStatus"
                                    class="form-control width50"
                                    name="selectedStatus"
                                    v-model="pftret.selectedStatus"
                                    @change="openChangeProfitReturnStatus(index)"
                                    :disabled="pftret.status == 'Profit Return'"
                                  >
                                    <option :value="null">--- Select ---</option>
                                    <option value="Profit Return">Profit Return</option>
                                    <option value="Transfer" :disabled="loans.length == 0">Transfer</option>
                                  </select>
                                </td>
                              </template>

                              <!--<td class="c" v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0 && utilReq.status !== 'Closed'">
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#add_profit_return"
                                >
                                  <i class="icofont-ui-edit text-warning"></i>
                                </a>
                              </td>-->
                              <!--<td v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0 && utilReq.status !== 'Closed'"
                                v-on:click="
                                  deleteRequest(
                                    pftret.id,
                                    pftret.loan_registration_id,
                                    'ProfitReturn'
                                  )
                                "
                                :style="{ cursor: 'pointer' }"
                              >
                                <i class="icofont-trash text-danger"></i>
                              </td>-->
                            </tr>
                            <!----------------------------------------------->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- /.card -->
                </div>
              </div>
            </div>
            <!-- /.Tabpane fourth end-->
            <!-- 
            <div class="tab-pane fade" id="five" role="tabpanel" aria-labelledby="loansummary">-->
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt">Loan Summary</h1>
                        </div>
                        <!-- /.col -->
                        <div class="col-lg-6"></div>
                        <!-- /.col -->
                      </div>
                    </div>

                    <div class="card-body table-responsive p-0">
                      <!------menton here style="height: 300px;"-------->
                      <table class="table table-head-fixed text-nowrap">
                        <thead>
                          <tr>
                            <!-- <th>Approved Date</th> -->
                            <th>Drawdown Amount</th>
                            <th>Repaid Amount</th>
                            <th>Prepaid Amount</th>
                            <th>O/S Amount</th>
                            <th>Date</th>
                            <!--<th class="c">Status</th>-->
                            <!--<th class="c">Change Status</th>-->
                            <th>Interest Rate(%)</th>
                            <th>Day Count</th>
                            <th>Interest Paid</th>
                            <th>Interest Accrued</th>
                            <th>Penalty Interest</th>
                            <th>Cost Amount</th>
                            <th>Accrued Cost Interest</th>
                            <th>Total Interest</th>
                            <!-- <th>Facility Costs</th>
                            <th>Bank Charges</th> -->
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="lnsmry in LoanDailySummary" :key="lnsmry.id">
                            <!--<td v-if="lnsmry.approval_date !== null ">{{ lnsmry.approval_date }}</td>
                            <td v-else>0.00</td>
                            <td
                              v-if="lnsmry.approved_amount !== null "
                              class="c"
                            >{{ lnsmry.approved_amount }}</td>
                            <td v-else>0.00</td>-->
                            <td v-if="lnsmry.drawdown_outstanding_amount !== null">
                              {{ lnsmry.drawdown_outstanding_amount }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.repayment_amount !== null">
                              {{ lnsmry.repayment_amount }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.prepaid_repayment_amount !== null">
                              {{ lnsmry.prepaid_repayment_amount }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.transaction_level_outstanding_amount !== null">
                              {{
                                lnsmry.transaction_level_outstanding_amount
                              }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.todays_date !== null">
                              {{ lnsmry.todays_date }}
                            </td>
                            <td v-else>-</td>
                            <!--<td
                              :class="{ 'c text-success': lnsmry.status === 'Open','c text-warning': lnsmry.status === 'Closed'}"
                            >{{lnsmry.status}}</td>-->
                            <!--<td class="c">
                              <div>
                                <select
                                  id="docStatus"
                                  name="docStatus"
                                  @change="onChangeUtilOverviewStatus($event,lnsmry.id,lnsmry.loan_registration_id,'LoanTransactionSummaryTransactionStatus')"
                                  class="form-control"
                                  v-model="lnsmry.status"
                                  :disabled="lnsmry.status === 'Closed'"
                                >
                                  <option :value="null"></option>
                                  <option value="Open">Open</option>
                                  <option value="Closed">Closed</option>
                                </select>
                              </div>
                            </td>-->
                            <td v-if="lnsmry.interest_rate !== null">
                              {{ lnsmry.interest_rate }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.day_count !== null">
                              {{ lnsmry.day_count }}
                            </td>
                            <td v-else>0</td>
                            <td v-if="lnsmry.interest_paid !== null">
                              {{ lnsmry.interest_paid }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.accrued_interest !== null">
                              {{ lnsmry.accrued_interest }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.penalty_interest !== null">
                              {{ lnsmry.penalty_interest }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.cost_amount !== null">
                              {{ lnsmry.cost_amount }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.cost_accrued_interest !== null">
                              {{ lnsmry.cost_accrued_interest }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.total_accrued_interest !== null">
                              {{ lnsmry.total_accrued_interest }}
                            </td>
                            <td v-else>0.00</td>
                            <!-- <td
                              v-if="
                                lnsmry.facility_cost !== null ||
                                lnsmry.facility_cost !== undefined
                              "
                            >
                              {{ lnsmry.facility_cost | numeral("0,0.00") }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.bank_charges !== null">
                              {{ lnsmry.bank_charges | numeral("0,0.00") }}
                            </td>
                            <td v-else>0.00</td> -->
                          </tr>
                          <!----------------------------------------------->
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
            <!-- loan summary close -->

            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt">Loan Transaction Summary</h1>
                        </div>
                        <div class="col-lg-6"></div>
                      </div>
                    </div>

                    <div class="card-body table-responsive p-0">
                      <table class="table table-head-fixed text-nowrap">
                        <thead>
                          <tr>
                            <!--<th>Approved Date</th>
                            <th>Approved Amount</th>-->
                            <th>Drawdown Amount</th>
                            <th>Repaid Amount</th>
                            <th>Prepaid Amount</th>
                            <th>O/S Amount</th>
                            <th>Date</th>
                            <!--<th class="c">Status</th>-->
                            <!--<th class="c">Change Status</th>-->
                            <th>Interest Rate(%)</th>
                            <th>Day Count</th>
                            <th>Interest Paid</th>
                            <th>Interest Accrued</th>
                            <th>Penalty Interest</th>
                            <th>Cost Amount</th>
                            <th>Accrued Cost Interest</th>
                            <th>Total Interest</th>
                            <!-- <th>Facility Costs</th>
                            <th>Bank Charges</th> -->
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="lnsmry in LoanTransactionSummary" :key="lnsmry.id">
                            <!--<td v-if="lnsmry.approval_date !== null ">{{ lnsmry.approval_date }}</td>
                            <td v-else>0.00</td>
                            <td
                              v-if="lnsmry.approved_amount !== null "
                              class="c"
                            >{{ lnsmry.approved_amount }}</td>
                            <td v-else>0.00</td>-->
                            <td v-if="lnsmry.drawdown_outstanding_amount !== null">
                              {{ lnsmry.drawdown_outstanding_amount }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.repayment_amount !== null">
                              {{ lnsmry.repayment_amount }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.prepaid_repayment_amount !== null">
                              {{ lnsmry.prepaid_repayment_amount }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.transaction_level_outstanding_amount !== null">
                              {{
                                lnsmry.transaction_level_outstanding_amount
                              }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.todays_date !== null">
                              {{ lnsmry.todays_date }}
                            </td>
                            <td v-else>-</td>
                            <!--<td
                              :class="{ 'c text-success': lnsmry.status === 'Open','c text-warning': lnsmry.status === 'Closed'}"
                            >{{lnsmry.status}}</td>-->
                            <!--<td class="c">
                              <div>
                                <select
                                  id="docStatus"
                                  name="docStatus"
                                  @change="onChangeUtilOverviewStatus($event,lnsmry.id,lnsmry.loan_registration_id,'LoanTransactionSummaryTransactionStatus')"
                                  class="form-control"
                                  v-model="lnsmry.status"
                                  :disabled="lnsmry.status === 'Closed'"
                                >
                                  <option :value="null"></option>
                                  <option value="Open">Open</option>
                                  <option value="Closed">Closed</option>
                                </select>
                              </div>
                            </td>-->
                            <td v-if="lnsmry.interest_rate !== null">
                              {{ lnsmry.interest_rate }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.day_count !== null">
                              {{ lnsmry.day_count }}
                            </td>
                            <td v-else>0</td>
                            <td v-if="lnsmry.interest_paid !== null">
                              {{ lnsmry.interest_paid }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.accrued_interest !== null">
                              {{ lnsmry.accrued_interest }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.penalty_interest !== null">
                              {{ lnsmry.penalty_interest }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.cost_amount !== null">
                              {{ lnsmry.cost_amount }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.cost_accrued_interest !== null">
                              {{ lnsmry.cost_accrued_interest }}
                            </td>
                            <td v-else>0.00</td>
                            <td v-if="lnsmry.total_accrued_interest !== null">
                              {{ lnsmry.total_accrued_interest }}
                            </td>
                            <td v-else>0.00</td>
                            <!-- <td
                              v-if="
                                lnsmry.facility_cost !== null ||
                                lnsmry.facility_cost !== undefined
                              "
                            >
                              {{ lnsmry.facility_cost | numeral("0,0.00") }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="lnsmry.bank_charges !== null">
                              {{ lnsmry.bank_charges | numeral("0,0.00") }}
                            </td>
                            <td v-else>-</td> -->
                          </tr>
                          <!----------------------------------------------->
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
            <!-- loan summary close -->

            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-12">
                          <h1 class="headingt">Loan Documents</h1>
                        </div>
                        <!-- /.col -->
                      </div>
                    </div>

                    <div class="card-body table-responsive p-0">
                      <!------menton here style="height: 300px;"-------->
                      <table class="table table-head-fixed text-nowrap">
                        <thead>
                          <tr>
                            <th>Documents</th>
                            <th>Comments</th>
                            <th>Expiry Date</th>
                            <th>Status</th>
                            <th
                              v-if="
                                loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0
                              "
                            >
                              Change Status
                            </th>
                            <th class="center">Comments</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="(Ldoc, index) in LoanDocuments"
                            :key="Ldoc.id"
                          >
                            <td
                              v-on:click="
                                downloadFacDocument(Ldoc.document_path)
                              "
                              :style="{ cursor: 'pointer' }"
                            >
                              <i class="icofont-download text-info"></i>
                              {{ Ldoc.document_name }}
                            </td>
                            <td v-if="Ldoc.uploading_date !== null">
                              {{ Ldoc.uploading_date }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="Ldoc.expiry_date !== null">
                              {{ Ldoc.expiry_date }}
                            </td>
                            <td v-else>-</td>
                            <td
                              :class="{
                                'text-success': Ldoc.status === 'Approved',
                                'text-warning': Ldoc.status === 'Received',
                                'text-danger': Ldoc.status === 'Rejected',
                                'text-dark': Ldoc.status === 'Expired',
                                'text-info': Ldoc.status === 'Near to Expiry',
                              }"
                            >
                              {{
                                Ldoc.status === "Received"
                                  ? loggedInUserGroupId === "Borrower"
                                    ? "Uploaded"
                                    : Ldoc.status
                                  : Ldoc.status
                              }}
                            </td>
                            <template
                              v-if="
                                loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0
                              "
                            >
                              <td class="status">
                                <div class="status">
                                  <select
                                    id="docStatus"
                                    @change="
                                      onChangeUtilOverviewStatus(
                                        $event,
                                        Ldoc.id,
                                        null,
                                        'LoanDocStatus',
                                        Ldoc.status,
                                        index
                                      )
                                    "
                                    class="form-control"
                                    :value="Ldoc.status"
                                    :disabled="
                                      Ldoc.status === 'Approved' || inactive
                                    "
                                  >
                                    <option value="Received">Received</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                    <option
                                      v-if="Ldoc.status === 'Expired'"
                                      value="Expired"
                                    >
                                      Expired
                                    </option>
                                    <option
                                      v-if="Ldoc.status === 'Near to Expiry'"
                                      value="Near to Expiry"
                                    >
                                      Near to Expiry
                                    </option>
                                  </select>
                                </div>
                              </td>
                            </template>
                            <td
                              v-if="!inactive"
                              class="c center"
                              v-on:click="showCommentModal(Ldoc.comments)"
                              :style="{ cursor: 'pointer' }"
                            >
                              <i class="icofont-comment text-info"></i>
                            </td>
                            <td v-else class="c center">
                              <i
                                style="opacity: 0.5"
                                class="icofont-comment text-info"
                              ></i>
                            </td>
                          </tr>
                          <!----------------------------------------------->
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
            <!-- documents end -->

            <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt" id="pageHeading">
                        Offtaker Contract
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div
            class="row"
            v-for="(off, index) in Offtaker"
            :key="index"
          >
          <div class="col-md-12">
              <div class="card-body br_overview">
                <!------menton here style="height: 300px;"-------->
                <table class="table table-hover table-head-fixed text-nowrap">
                  <tbody>
                    <tr id="container">
                      <td class="wg">
                        <div class="vcenter">
                          Offtaker Contract No: {{ index + 1 }}
                        </div>
                      </td>
                      <td v-if="off.loan_contract_flag == 1">
                        <div class="">
                            <ul id="sr_btn">
                              <li v-if="!inactive && loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)">
                                <div class="">
                                  <router-link
                                    :to="{
                                      name: 'AddRepayment',
                                      params: {
                                        id:0,
                                        repaymentType: 'Contract',
                                        contractType:'Contract',
                                        loanID: id,
                                        selectedBorrower: borrower_id,
                                        selectedFacility: facility_id,
                                        fundCurrency: fund_currency_id,
                                        selectedOfftakerID: off.offtaker_id,
                                        selectedContractNumber: off.offtaker_contract_id,
                                        selectedPartialContractNumber: off.offtaker_contract_partial_fill_id
                                      },
                                    }"
                                  >
                                    <button
                                      v-if="utilReq.status === 'Open' && (loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0))"
                                      type="button"
                                      class="btn btn-sm sh_n btn-success"
                                    >
                                      <i
                                        class="icofont-plus"
                                        style="font-size: 0.97em"
                                      ></i>
                                      Add Repayment
                                    </button>
                                  </router-link>
                                </div>
                              </li>
                            </ul>
                          </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card-body br_overview">
                <!------menton here style="height: 300px;"-------->
                <table class="table table-hover table-head-fixed text-nowrap">
                  <tbody>
                    <tr>
                      <td class="wg">Offtaker Name:</td>
                      <td v-if="off.offtaker_name !== null">
                        {{ off.offtaker_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                      <!----------------------------------------------->
                    <tr>
                      <td class="wg">Contract No:</td>
                      <td v-if="off.contract_number !== null">
                        {{ off.contract_number }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <!----------------------------------------------->
                    <tr>
                      <td class="wg">Partial Contract No:</td>
                      <td v-if="off.partial_contract_number !== null">
                        {{ off.partial_contract_number }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <!----------------------------------------------->

                    <tr>
                      <td class="wg">Contract Date:</td>
                      <td v-if="off.contract_date !== null">
                        {{ off.contract_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <!----------------------------------------------->

                    <tr>
                      <td class="wg">Partial Contract Date:</td>
                      <td v-if="off.Partial_contract_date !== null">
                        {{ off.Partial_contract_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <!----------------------------------------------->
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card-body br_overview">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td class="wg">Total Contract Quantity:</td>
                      <td v-if="off.loan_contract_qty !== null">
                        {{ off.loan_contract_qty }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <tr>
                      <td class="wg">Available Contract Quantity:</td>
                      <td v-if="off.oustanding_contract_qty !== null">
                        {{ off.oustanding_contract_qty }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <tr>
                      <td class="wg">Utilized Contract Quantity:</td>
                      <td v-if="off.loan_contract_flag == 1">
                        {{ off.oustanding_contract_qty }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <!----------------------------------------------->

                    <tr>
                      <td class="wg">Unit:</td>
                      <td v-if="off.unit_name !== null">
                        {{ off.unit_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <!----------------------------------------------->

                    <tr>
                      <td class="wg">Collateral:</td>
                      <td v-if="off.collateral_name !== null">
                        {{ off.collateral_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>
 
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

          </div>

          <!-- ./modal start -->
          <div class="modal fade" id="view_doc">
            <div class="modal-dialog modal-md-8">
              <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                  <h4 class="modal-title">
                    <i class="icofont-eye-alt"></i> View Document
                  </h4>
                  <button type="button" class="close" data-dismiss="modal">
                    <i class="icofont-close-circled"></i>
                  </button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card-body p-0">
                        <!------menton here style="height: 300px;"-------->
                        <table class="table">
                          <tbody>
                            <tr>
                              <td class="col-10">Receipt</td>
                              <td class="c col-1">
                                <a
                                  href="asset/images/document.jpg"
                                  target="_blank"
                                >
                                  <i class="icofont-file-pdf text-danger"></i>
                                </a>
                              </td>
                              <td class="c col-1">
                                <a href="asset/images/document.jpg" download>
                                  <i class="icofont-download text-info"></i>
                                </a>
                              </td>
                            </tr>
                            <!----------------------------------------------->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                  <div class="mx-auto">
                    <a @click="$router.go(-1)">
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ./modal end -->

          <!-- ./modal start -->
          <div class="modal fade" id="add_expenses">
            <div class="modal-dialog modal-md-8">
              <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                  <h4 class="modal-title">Add Cost</h4>
                  <button type="button" class="close" data-dismiss="modal">
                    <i class="icofont-close-circled"></i>
                  </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group fc">
                        <label class="required-field">Cost Type</label>
                        <select
                          id="selectedCost"
                          class="form-control"
                          name="selectedCost"
                          v-model="selectedCost"
                          required
                        >
                          <option :value="null">-- Select a Cost --</option>
                          <option v-for="cost in loanExpenses" :key='cost.id' :value="cost.id">
                            {{ cost.loan_expense_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-6 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Amount</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          placeholder
                          v-model="amount"
                          required
                        />
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-6 col-6">
                      <div class="form-group fc">
                        <label class="required-field">Currency</label>
                        <select
                          id="selectedCurrency"
                          class="form-control"
                          name="selectedCurrency"
                          v-model="selectedCurrency"
                          required
                          :disabled="true"
                        >
                          <option :value="null">-- Select a Currency --</option>
                          <option v-for="curr in currencies" :key='curr.id' :value="curr.id">
                            {{ curr.currency_short_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-6 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Date</label
                        >
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="CostPayDate"
                          placeholder="Select Date"
                          v-model="CostPayDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          required
                        ></datepicker>
                      </div>
                    </div>

                    <!--<div class="col-md-6 col-sm-6 col-6">
                    <div class="form-group fc">
                      <label>Document</label>
                      <select
                        id="costDocName"
                        class="form-control"
                        name="costDocName"
                        v-model="costDocName"
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="document in CostDocuments"
                          :key="document.id"
                        >{{ document.document_name }}</option>
                      </select>
                    </div>
                    </div>-->

                    <!--<div class="col-md-12">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">Upload Document</label>
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="file"
                          name="file"
                          ref="file"
                          v-on:change="handleFileUploadCost(index,$event)"
                        />
                        <small>
                          <p style="background-color:powderblue;">
                            <b>{{ display_file_name }}</b>
                            <small>
                              <u
                                style="color:blue;cursor:pointer;"
                                v-if="CostRemoveDisabled === false"
                                v-on:click="removeCostDoc(index)"
                              >Remove</u>
                            </small>
                          </p>
                        </small>
                        <small>
                          <p class="error" v-if="errors">{{ errors }}</p>
                        </small>
                        <label class="custom-file-label" for="customFile">Choose file</label>
                      </div>
                    </div>
                    </div>-->

                    <!--<div class="col-md-6 col-sm-6 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">Date</label>
                      <datepicker
                        :bootstrap-styling="true"
                        name="endDate"
                        placeholder="Select Date"
                        v-model="endDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        required
                      ></datepicker>
                    </div>
                    </div>-->
                  </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                  <div class="mx-auto">
                    <ul id="sr_btn">
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-success sh"
                          v-on:click="onCostSubmit()"
                        >
                          Submit
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-dark sh"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ./modal end -->

          <!-- ./modal start -->
          <div class="modal fade" id="edit_costs">
            <div class="modal-dialog modal-md-8">
              <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                  <h4 class="modal-title">Edit Cost</h4>
                  <button type="button" class="close" data-dismiss="modal">
                    <i class="icofont-close-circled"></i>
                  </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group fc">
                        <label class="required-field">Cost Type</label>
                        <select
                          id="selectedCost"
                          class="form-control"
                          name="selectedCost"
                          v-model="selectedCost"
                          required
                        >
                          <option :value="null">-- Select a Cost --</option>
                          <option v-for="cost in loanExpenses" :key='cost.id' :value="cost.id">
                            {{ cost.loan_expense_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-6 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Amount</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          placeholder
                          v-model="amount"
                          required
                        />
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-6 col-6">
                      <div class="form-group fc">
                        <label class="required-field">Currency</label>
                        <select
                          id="selectedCurrency"
                          class="form-control"
                          name="selectedCurrency"
                          v-model="selectedCurrency"
                          required
                          :disabled="true"
                        >
                          <option :value="null">-- Select a Currency --</option>
                          <option v-for="curr in currencies" :key='curr.id' :value="curr.id">
                            {{ curr.currency_short_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-6 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Date</label
                        >
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="CostPayDate"
                          placeholder="Select Date"
                          v-model="CostPayDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          required
                        ></datepicker>
                      </div>
                    </div>

                    <!--<div class="col-md-6 col-sm-6 col-6">
                    <div class="form-group fc">
                      <label>Document</label>
                      <select
                        id="costDocName"
                        class="form-control"
                        name="costDocName"
                        v-model="costDocName"
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="document in CostDocuments"
                          :key="document.id"
                        >{{ document.document_name }}</option>
                      </select>
                    </div>
                    </div>-->

                    <!--<div class="col-md-12">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">Upload Document</label>
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="file"
                          name="file"
                          ref="file"
                          v-on:change="handleFileUploadCost(index,$event)"
                        />
                        <small>
                          <p style="background-color:powderblue;">
                            <b>{{ display_file_name }}</b>
                            <small>
                              <u
                                style="color:blue;cursor:pointer;"
                                v-if="CostRemoveDisabled === false"
                                v-on:click="removeCostDoc(index)"
                              >Remove</u>
                            </small>
                          </p>
                        </small>
                        <small>
                          <p class="error" v-if="errors">{{ errors }}</p>
                        </small>
                        <label class="custom-file-label" for="customFile">Choose file</label>
                      </div>
                    </div>
                    </div>-->

                    <!--<div class="col-md-6 col-sm-6 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">Date</label>
                      <datepicker
                        :bootstrap-styling="true"
                        name="endDate"
                        placeholder="Select Date"
                        v-model="endDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        required
                      ></datepicker>
                    </div>
                    </div>-->
                  </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                  <div class="mx-auto">
                    <ul id="sr_btn">
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-success sh"
                          v-on:click="onCostSubmit()"
                        >
                          Submit
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-dark sh"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ./modal end -->

          <!-- Profit return -->
          <!-- ./modal start -->
          <div class="modal fade" id="add_profit_return">
            <div class="modal-dialog modal-md-8">
              <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                  <h4 class="modal-title">Penalty Interest</h4>
                  <button type="button" class="close" data-dismiss="modal">
                    <i class="icofont-close-circled"></i>
                  </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Date</label
                        >
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="CostPayDate"
                          placeholder="Select Date"
                          v-model="profitReturnDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          :disabled="true"
                        ></datepicker>
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-6 col-6">
                      <div class="form-group fc">
                        <label class="required-field">Currency</label>
                        <select
                          id="selectedCurrency"
                          class="form-control"
                          name="selectedCurrency"
                          v-model="profitReturnCurrency"
                          :disabled="true"
                        >
                          <option :value="null">-- Select a Currency --</option>
                          <option v-for="curr in currencies" :key="curr.id">
                            {{ curr.currency_short_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-6 col-6">
                      <div class="form-group fc">
                        <label
                          class="required-field"
                          v-if="prfretModalType === 'penalty'"
                          for="exampleInputEmail1"
                          >Penalty Amount</label
                        >
                        <label v-else for="exampleInputEmail1">Amount</label>
                        <input
                          type="number"
                          class="form-control"
                          placeholder
                          v-model="profitReturnAmount"
                          :disabled="true"
                        />
                      </div>
                    </div>

                    <div
                      v-if="prfretModalType === 'penalty'"
                      class="col-md-6 col-sm-6 col-6"
                    >
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Available Amount</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          placeholder
                          v-model="profitReturnAvailableAmount"
                          :disabled="true"
                        />
                      </div>
                    </div>

                    <div
                      v-if="
                        prfretModalType === 'penalty' &&
                        !disableProfitReturnRadio
                      "
                      class="col-md-12"
                    >
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Balance Amount</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          placeholder
                          v-model="profitReturnBalanceAmount"
                          :disabled="true"
                        />
                      </div>
                    </div>

                    <div
                      v-if="
                        prfretModalType === 'penalty' &&
                        !disableProfitReturnRadio
                      "
                      class="col-md-6 col-sm-6 col-6"
                    >
                      <div class="form-group fc">
                        <input
                          type="radio"
                          name="selectedProfitReturnOption"
                          value="AddBalance"
                          v-model="selectedProfitReturnOption"
                        />
                        <label> Addition to Balance Amount</label>
                      </div>
                    </div>

                    <div
                      v-if="
                        prfretModalType === 'penalty' &&
                        selectedProfitReturnOption === 'AddBalance'
                      "
                      class="col-md-6 col-sm-6 col-6"
                    >
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Additional Amount</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          placeholder
                          v-model="profitReturnAdditionalAmount"
                        />
                      </div>
                    </div>

                    <div
                      v-if="
                        prfretModalType === 'penalty' &&
                        !disableProfitReturnRadio &&
                        !disableWaive
                      "
                      class="col-md-6 col-sm-6 col-6"
                    >
                      <div class="form-group fc">
                        <input
                          type="radio"
                          name="selectedProfitReturnOption"
                          value="WaiveRemaining"
                          v-model="selectedProfitReturnOption"
                        />
                        <label for="exampleInputEmail1">
                          Waive Remaining Penalty</label
                        >
                      </div>
                    </div>

                    <div
                      v-if="
                        prfretModalType === 'penalty' &&
                        disableProfitReturnRadio
                      "
                      class="col-md-12"
                    >
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Deduct Penalty</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          placeholder
                          v-model="profitReturnDeductPenalty"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.profitReturnDeductPenalty.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted && $v.profitReturnDeductPenalty.$error
                          "
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.profitReturnDeductPenalty.required"
                            >Deduct Penalty is required</span
                          >
                          <span v-if="!$v.profitReturnDeductPenalty.maxLength"
                            >Max length of 15 characters is exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="prfretModalType !== 'penalty'"
                      class="col-md-6 col-sm-6 col-6"
                    >
                      <div class="form-group fc">
                        <label>Status</label>
                        <select
                          id="selectedProfitRetStatus"
                          class="form-control"
                          name="selectedProfitRetStatus"
                          v-model="selectedProfitRetStatus"
                        >
                          <option :value="null">-- Select Status --</option>
                          <option value="Open">Open</option>
                          <option value="Closed">Closed</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                  <div class="mx-auto row">
                    <ul id="sr_btn">
                      <li>
                        <button
                          v-if="prfretModalType === 'penalty'"
                          type="button"
                          class="btn-sm btn btn-success sh"
                          v-on:click="onProfitReturnSubmit(prfretModalType)"
                        >
                          Submit
                        </button>
                        <button
                          v-else
                          type="button"
                          class="btn-sm btn btn-success sh"
                          v-on:click="onProfitReturnSubmit()"
                        >
                          Submit
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="btn-sm btn btn-dark sh"
                          data-dismiss="modal"
                          @click="$router.go()"
                        >
                          Cancel
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ./modal end -->
        </div>
        <!-- Main content -->
      </div>
      <!-- ./modal start -->
      <div class="modal fade" id="view_doc">
        <div class="modal-dialog modal-md-8">
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">
                <i class="icofont-eye-alt"></i> View Document
              </h4>
              <button type="button" class="close" data-dismiss="modal">
                <i class="icofont-close-circled"></i>
              </button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->
                    <table class="table">
                      <tbody>
                        <tr>
                          <td class="col-10">Passport</td>
                          <td class="c col-1">
                            <a href="asset/images/document.jpg" target="_blank">
                              <i class="icofont-file-pdf text-danger"></i>
                            </a>
                          </td>
                          <td class="c col-1">
                            <a href="asset/images/document.jpg" download>
                              <i class="icofont-download text-info"></i>
                            </a>
                          </td>
                        </tr>
                        <!----------------------------------------------->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer">
              <div class="mx-auto">
                <a @click="$router.go(-1)">
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ./modal end -->

      <!-- ./modal start -->
      <div class="modal fade" id="comm">
        <div class="modal-dialog modal-md-8">
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">
                <i class="icofont-comment"></i>Comments
              </h4>
              <button type="button" class="close" data-dismiss="modal">
                <i class="icofont-close-circled"></i>
              </button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <textarea
                    rows="4"
                    cols="60"
                    style="outline: none"
                    v-model="comment"
                  ></textarea>
                </div>
              </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer">
              <div class="mx-auto">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ./modal end -->
    </div>
    <!-- ./wrapper -->
    <div class="modal fade" id="view_profit_return_transfer" data-backdrop="static">
      <div class="modal-dialog mw-100 w-60 custom-width">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              Profit Return Mapping
            </h4>
            <button type="button" class="close" v-on:click="onCloseModal()">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <h4 class="txt black">
                    <strong>
                      <!-- Excess Amount has been received of {{ excessAmount }} {{ fundCurrencyShortName }} . -->
                      Available Profit Return amount to be mapped is  {{this.ProfitReturnAmountToBeMapped}} {{ this.fundCurrencyShortName }}.</span>
                    </strong>
                  </h4>
                  <h4 class="txt black">
                    <strong>
                      Kindly choose any Loan from the below to Allocate the amount <span style="color: red !important;">*</span>.
                    </strong>
                  </h4>

                  <table
                        class="table table-head-fixed text-nowrap table-hover"
                      >
                        <thead>
                          <tr>
                            <th class='col250'>Loan Regi. ID</th>
                            <th class=''>Outstanding Repayment Amount</th>
                            <th class=''>Transfer(Considered as Repayment)</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="(lm, index) in loanMappingList"
                            :key="index"
                          > 
                            <td>
                              <select
                                id="selectedLoan"
                                class="form-control"
                                name="selectedLoan"
                                v-model="lm.selectedLoan"
                                @change="onLoanSelectGetDetails(index)"
                                :class="{
                                    'is-invalid': (isSubmitted &&
                                    $v.loanMappingList.$each[index]
                                    .selectedLoan.$error)
                                  }"
                              >
                                <option :value="null">--- Select ---</option>
                                <option
                                  v-for="loan in loans"
                                  :value="loan.loan_master_id"
                                  :key="loan.loan_master_id"
                                >
                                  {{ loan.loan_registration_id }}
                                </option>
                              </select>
                              <div
                                  v-if="(isSubmitted &&
                                    $v.loanMappingList.$each[index].selectedLoan
                                      .$error)
                                  "
                                  class="invalid-feedback"
                                >
                                  <span
                                  class="error-message"
                                    v-if="
                                      (isSubmitted &&
                                        $v.loanMappingList.$each[index].selectedLoan
                                      .$error)
                                    "
                                    >Loan Regi. ID is required.<br/></span
                                  >
                                </div>
                            </td>

                            <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="lm.loanOSAmount"
                                  id="loanOSAmount"
                                  disabled
                                />
                            </td>

                            <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="lm.loanTransferProfitReturnAmount"
                                  id="loanTransferProfitReturnAmount"
                                  @keypress="isDecimalTwoFixed($event)"
                                  @paste="truncateDecimalPlacesUptoTwo($event)"
                                  @keyup="onChangeProfitReturnAmountValidation(index)"
                                  :class="{
                                    'is-invalid': (lm.loanTransferProfitReturnAmountError) ||
                                    (isSubmitted &&
                                    $v.loanMappingList.$each[index]
                                    .loanTransferProfitReturnAmount.$error)
                                  }"
                                  :disabled="!lm.selectedLoan"
                                />
                              <div
                                  v-if="(lm.loanTransferProfitReturnAmountError) ||
                                    (isSubmitted &&
                                    $v.loanMappingList.$each[index].loanTransferProfitReturnAmount
                                      .$error)
                                  "
                                  class="invalid-feedback"
                                >
                                  <span
                                  class="error-message"
                                    v-if="
                                      (lm.loanTransferProfitReturnAmountError)
                                    "
                                    >{{ lm.loanTransferProfitReturnAmountErrorMessage }}<br/></span
                                  >
                                  <span
                                    v-if="
                                      !$v.loanMappingList.$each[index]
                                        .loanTransferProfitReturnAmount.maxLength
                                    "
                                    >Max length of 15 digits exceeded.<br/></span
                                  >
                                </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
                <button
                  type="button"
                  name="cancel"
                  class="btn-sm btn btn-primary sh"
                  style="margin-right: 10px;"
                  v-on:click="onCloseModal()"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  name="submit"
                  class="btn-sm btn btn-success sh"
                  v-on:click="onSubmit()"
                >
                  Transfer
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: null,
      index: null,
      type: null,
      borrower_id: null,
      utilReq: [],
      DrawdownReq: [],
      CostSummary: [],
      RepaymentDetails: [],
      profitReturnID: null,
      profitReturnIndex: null,
      ProfitReturn: [],
      selectedStatus: null,
      fundCurrencyShortName: null,
      LoanTransactionSummary: [],
      LoanDailySummary: [],
      Offtaker: [],
      LoanDocuments: [],
      selectedCost: null,
      CostDocuments: [],
      costDocName: null,
      CostPayDate: null,
      CostPayDate1: null,
      receipt_no: null,
      costs: [],
      display_file_name: null,
      CostRemoveDisabled: null,
      selectedCurrency: null,
      currencies: [],
      drawdownDocs: [],
      startDate: null,
      endDate: null,
      startDate1: null,
      endDate1: null,
      amount: null,
      errors: [],
      repaymentDocs: [],
      costDocs: [],
      loanExpenses: [],
      profitReturnAmount: null,
      profitReturnDate: null,
      profitReturnCurrency: null,
      profitReturnAvailableAmount: null,
      profitReturnBalanceAmount: null,
      selectedProfitReturnOption: null,
      profitReturnAdditionalAmount: null,
      profitReturnDeductPenalty: null,
      disableProfitReturnRadio: false,
      disableWaive: false,
      prfretModalType: null,
      prfretSubmitData: [],
      costTranId: null,
      addOrUpdateCostType: null,
      showName: null,
      showAmount: null,
      showCurrency: null,
      showDate: null,
      selectedProfitRetStatus: "Closed",
      dayCount: null,
      collateralNames: [],
      fund_id:null,
      facility_id: null,
      facility_registration_id: null,
      fund_currency_id: null,
      approval_date: null,
      collateral_id: null,
      collateral_name: null,
      selectedDisbursementOn: null,
      selectedDocumentsList: [],
      isBalanceAmountZero: false,
      isSubmitted: false,
      inactive: false,
      comment: null,

      loans: [],
      loanMappingList: [],
      loanMappingAddMoreDisabled:false,
      loanMappingSaveInfoDisabled: false,
      RepaymentConvertedAmount: null,
      ProfitReturnAmountToBeMapped: null,
      transferProfitReturnAmount: null,
      totalLoanProfitReturnAmount: 0,
      isSubmitted: false,
    };
  },
  components: {
    Datepicker,
  },
  validations: {
    // profitReturnAdditionalAmount: {
    //   required: function () {
    //     if (
    //       this.prfretModalType === "penalty" &&
    //       this.selectedProfitReturnOption === "AddBalance"
    //     ) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   },
    // },
    loanMappingList: {
      $each: {
        selectedLoan: {
          required,
        },
        loanTransferProfitReturnAmount: {
          maxLength : maxLength(15),
        },
      },
    },
    profitReturnDeductPenalty: {
      required: function () {
        if (
          this.prfretModalType === "penalty" &&
          this.disableProfitReturnRadio
        ) {
          return false;
        } else {
          return true;
        }
      },
      maxLength: maxLength(15),
    },
  },
  methods: {
    showCommentModal(comm) {
      //this function opens the comment modal.
      this.comment = comm;
      $("#comm").modal("show");
    }, //showCommentModal() close
    showDocumentModal(docList) {
      this.selectedDocumentsList = docList;
      console.log(this.selectedDocumentsList);
      $("#view_doc").modal("show");
    },
    showAddProfitReturnModal() {
      $("#add_profit_return").modal("show");
    },
    // showModal() {
    //   $("#show_payment").modal("show");
    // }, //showModal() close
    //to open add expense modal
    showAddModal(add_or_update_type) {
      this.amount = null;
      this.selectedCurrency = this.fund_currency_id;
      this.CostPayDate = null;
      this.selectedCost = null;
      this.addOrUpdateCostType = add_or_update_type;
      $("#add_expenses").modal("show");
    },

    showEditModal(
      cost_tran_id,
      loan_expense_date,
      expense_amount,
      expense_id,
      currency_id,
      add_or_update_type
    ) {
      console.log(add_or_update_type);
      console.log(currency_id);
      this.amount = expense_amount;
      this.selectedCurrency = currency_id;
      this.CostPayDate = loan_expense_date;
      this.selectedCost = expense_id;
      this.costTranId = cost_tran_id;
      this.addOrUpdateCostType = add_or_update_type;
      $("#edit_costs").modal("show");
    },

    downloadFacDocument: function (filePath) {
      console.log("filePath: ", filePath);
      Utils.downloadDocument(filePath);
    },

    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },

    getCurrencyShortNameById(id) {
      if (id === null) {
        return '';
      }
      const currency = this.currencies.find((currency) => currency.id === id);
      return currency ? currency.currency_short_name : '';
    },

    onProfitReturnSubmit(type) {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (type === "penalty") {
        this.$confirm({
          auth: false,
          message:
            "Are you sure you want to update the status? This is onetime process, Changes cannot be reverted!",
          button: {
            no: "No",
            yes: "Yes",
          },

          callback: (confirm) => {
            let selectedOption = null;
            if (this.selectedProfitReturnOption === "AddBalance") {
              selectedOption = 1;
            } else if (this.selectedProfitReturnOption === "WaiveRemaining") {
              selectedOption = 2;
            } else {
              selectedOption = 3;
            }
            if (confirm) {
              //this function is called when user changes the loan registration status. The status is updated in the database for that specific loan.
              const path = "loan-overview/" + this.id;
              axios
                .put(
                  path,
                  {
                    updateType: "status",
                    updatedStatus: this.prfretSubmitData[0],
                    transactionId: this.prfretSubmitData[1],
                    loanRegId: this.prfretSubmitData[2],
                    filtertype: this.prfretSubmitData[3],
                    penaltyAmount: this.profitReturnAmount,
                    currency: this.profitReturnCurrency,
                    payment_date: this.profitReturnDate,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 5,
                    tabId: 20,
                    availableAmount:
                      this.selectedProfitReturnOption === "AddBalance"
                        ? parseFloat(this.profitReturnAvailableAmount) +
                          parseFloat(this.profitReturnAdditionalAmount)
                        : this.profitReturnAvailableAmount,
                    deductPenalty: this.disableProfitReturnRadio
                      ? this.profitReturnDeductPenalty
                      : null,
                    selectedProfitReturnOption: selectedOption,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  console.log("update status: " + res.data.status);
                  if (res.data.status == "success") {
                    Utils.make_alert("success", "Status updated successfully!");
                    //if you want to send any data into server before redirection then you can do it here
                    this.$router.go(this.$router.currentRoute);
                    if (this.prfretSubmitData[3] === "ProfitReturnStatus") {
                      this.ProfitReturn[this.prfretSubmitData[4]].status =
                        this.prfretSubmitData[0];
                    }
                  } else {
                    console.log("Something went wrong from server!");
                  }
                })
                .catch((error) => {
                  console.error("error: " + error);
                  console.log("Something went wrong!");
                });
            } else {
              // updatedStatus.target.value = prevStatus;
              this.$router.go(this.$router.currentRoute);
            }
          },
        });
      } else {
        this.$confirm({
          auth: false,
          message: "Confirm submit?",
          button: {
            no: "No",
            yes: "Yes",
          },

          callback: (confirm) => {
            if (confirm) {
              axios
                .put(
                  "add_profit_return",
                  {
                    loanRegId: this.id,
                    status: this.selectedProfitRetStatus,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((response) => {
                  if (response.data.status == "success") {
                    Utils.make_alert(
                      "success",
                      "Status Changed For Profit Return successfully!"
                    );
                    //if you want to send any data into server before redirection then you can do it here
                    $(".close").click();
                    this.$router.go(this.$router.currentRoute);
                  } else {
                    Utils.make_alert("success", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  console.log("error: " + error);
                  Utils.make_alert("success", "Something went wrong!");
                });
            }
          },
        });
      }
    }, //onProfitReturnSubmit() close

    onCostSubmit() {
      if (
        this.CostPayDate === null ||
        this.selectedCost === null ||
        this.selectedCurrency === null ||
        this.amount === null
      ) {
        return Utils.make_alert("success", "Mandatory fields are required.");
      }
      //this function is called on submit button for Add Expense page. It will first check whether all fields are filled and validated. If yes, then the post api will be called
      this.$confirm({
        auth: false,
        message: "Confirm submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            if (this.CostPayDate != null) {
              this.CostPayDate1 = moment(this.CostPayDate).format("MM-DD-YYYY");
            }
            if (this.addOrUpdateCostType == "LoanCostAdd") {
              axios
                .post(
                  "add_loan_cost",
                  {
                    //following fields are required for expense payment and expense
                    loanRegId: this.id,
                    selectedCost: this.selectedCost,
                    selectedCurrency: this.selectedCurrency,
                    CostPayDate: this.CostPayDate1,
                    amount: this.amount,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((response) => {
                  if (response.data.status == "success") {
                    Utils.make_alert("success", "Expense added successfully!");
                    //if you want to send any data into server before redirection then you can do it here
                    this.$router.go(this.$router.currentRoute);
                  } else {
                    Utils.make_alert("warning", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  console.log("error: " + error);
                  Utils.make_alert("success", "Something went wrong!");
                  this.$router.go(this.$router.currentRoute);
                });
            } else {
              if (this.addOrUpdateCostType == "LoanCostUpdate") {
                axios
                  .put(
                    "add_loan_cost",
                    {
                      //following fields are required for expense payment and expense
                      loanRegId: this.id,
                      selectedCost: this.selectedCost,
                      selectedCurrency: this.selectedCurrency,
                      CostPayDate: this.CostPayDate1,
                      amount: this.amount,
                      costTranId: this.costTranId,
                    },
                    {
                      headers: {
                        "Content-type": "application/json",
                      },
                    }
                  )
                  .then((response) => {
                    if (response.data.status == "success") {
                      Utils.make_alert(
                        "success",
                        "Expense updated successfully!"
                      );
                      //if you want to send any data into server before redirection then you can do it here
                      this.$router.go(this.$router.currentRoute);
                    } else {
                      Utils.make_alert("success", "Something went wrong!");
                    }
                  })
                  .catch((error) => {
                    console.log("error: " + error);
                    Utils.make_alert("success", "Something went wrong!");
                  });
              }
            }
          } else {
            Utils.make_alert("success", "Cancelled!");
          }
        },
      });
    }, //onCostSubmit() close

    deductionAmountAPI(facility_id,borrower_id,expense_id,cost_id){
      // For triggering payment when we chnage status
      const path = "deduction_amount/" + cost_id + "/" + null;
          Utils.start_loading();
          axios
            .get(path, { params: { clientId: this.$store.state.client_id } })
            .then((res) => {
              console.log(res)
              Utils.stop_loading();
              if(res.data.status == 'success') {
                let loanIDs = res.data.loan_ids
                console.log("loanIDs",loanIDs)
                if(loanIDs.length > 0) {
                  this.getReportLoanTransaction(facility_id,borrower_id,expense_id,cost_id,loanIDs);
                } else {
                  this.paymentTrigger(facility_id,borrower_id,expense_id,cost_id)
                }
              }
            })
            .catch((error) => {
              Utils.stop_loading();
              //this is when response is not received from server
              console.error("error: " + error);
              Utils.make_alert("warning", "Something went wrong!");
            });
    },

    getReportLoanTransaction(facility_id,borrower_id,expense_id,cost_id,loanIDs){
      console.log("getReportLoanTransaction")
      const path = "/report_loan_transaction/" + loanIDs + "/" + this.$store.state.client_id;
      Utils.start_loading();
      axios
      .post(path)
      .then((res) => {
        Utils.stop_loading();
        this.paymentTrigger(facility_id,borrower_id,expense_id,cost_id)
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    paymentTrigger(facility_id,borrower_id,expense_id,cost_id){
      // For triggering payment when we chnage status
      const path = "payment-trigger/" +
          facility_id +
          "/" +
          borrower_id +
          "/" +
          expense_id +
          "/" +
          cost_id;
          Utils.start_loading();
          axios
            .get(path, { params: { clientId: this.$store.state.client_id } })
            .then((res) => {
              console.log(res)
              Utils.stop_loading();
              Utils.make_alert(
                "success",
                "Cost Status updated successfully!"
              );
              // this.$router.go(this.$router.currentRoute);
              setTimeout(() => this.$router.go(this.$router.currentRoute), 1500);
            })
            .catch((error) => {
              Utils.stop_loading();
              //this is when response is not received from server
              console.error("error: " + error);
              Utils.make_alert("warning", "Something went wrong!");
            });
    },

    costMail(cost_id,user_id,status){
      // For triggering payment when we chnage status
      const path = "cost_change_status_mail/" +
          cost_id +
          "/" +
          user_id +
          "/" +
          status;
          Utils.start_loading();
          axios
            .get(path, { params: { clientId: this.$store.state.client_id } })
            .then((res) => {
              console.log(res)
              Utils.stop_loading();
              if (res.data.status == "success") {
                  let facility_id = res.data.facility_id
                  let borrower_id = res.data.borrower_id
                  let expense_id = res.data.expense_id
                  let cost_id = res.data.cost_id
                  let user_id = this.$store.getters.getuserId
                  let status = res.data.cost_status
                  console.log(facility_id)
                  console.log(borrower_id)
                  console.log(expense_id)
                  console.log(cost_id)
                  console.log(user_id)
                  console.log(status)
                if(res.data.updated_status == 'Approved'){
                  this.deductionAmountAPI(facility_id,borrower_id,expense_id,cost_id)
                  // this.paymentTrigger(facility_id,borrower_id,expense_id,cost_id)
                }
              }
            })
            .catch((error) => {
              Utils.stop_loading();
              //this is when response is not received from server
              console.error("error: " + error);
              Utils.make_alert("warning", "Something went wrong!");
            });
    },

    onChangeCostStatus(updatedStatus, costId, prevStatus, index) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            //this function is called when user changes the cost status. The status is updated in the database for that specific facility.
            const path = "cost-overview/" + costId;
            axios
              .put(
                path,
                {
                  updateType: "CostStatus",
                  updatedCostStatus: updatedStatus.target.value,
                  costId: costId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 4,
                  tabId: 34,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("registration update status: " + res.data.status);
                console.log("data", res.data);
                if (res.data.status == "success") {
                  this.CostSummary[index].status = updatedStatus.target.value;
                  let facility_id = res.data.facility_id
                  let borrower_id = res.data.borrower_id
                  let expense_id = res.data.expense_id
                  let cost_id = res.data.cost_id
                  let user_id = this.$store.getters.getuserId
                  let status = res.data.cost_status
                  console.log(facility_id)
                  console.log(borrower_id)
                  console.log(expense_id)
                  console.log(cost_id)
                  console.log(user_id)
                  console.log(status)
                  this.costMail(cost_id,user_id,status)
                  // if(updatedStatus.target.value == 'Approved'){
                  //   this.deductionAmountAPI(cost_id)
                  //   this.paymentTrigger(facility_id,borrower_id,expense_id,cost_id)
                  // }
                  if(updatedStatus.target.value != 'Approved'){
                    Utils.make_alert(
                      "success",
                      "Cost Status updated successfully!"
                    );
                  }

                } else {
                  updatedStatus.target.value = 'Pending';
                  this.CostSummary[index].status = 'Pending';
                  console.log("Something went wrong from server!");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
            Utils.make_alert("warning", "Something went wrong!");
          });
          } else {
            updatedStatus.target.value = 'Pending';
            this.CostSummary[index].status = 'Pending';
          }
        },
      });
    }, //onChangeCostStatus close

    getUtilizationRequest() {
      Utils.start_loading();
      //this function is called on page load and created events. it will show details on loan with loanid equal to this.id
      const path = "loan-util-overview-by-id/" + this.id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          if (res.data.status == "success") {
            this.utilReq = this.inactive
              ? res.data.loan_master_details_inactive
              : res.data.loan_master_details_active;
            if (this.utilReq.loan_request_date != '-' && this.utilReq.loan_request_date != null) {
              this.utilReq.loan_request_date = moment(this.utilReq.loan_request_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.utilReq.loan_request_date = '-';
            }
            if (this.utilReq.conversion_date != '-' && this.utilReq.conversion_date != null) {
              this.utilReq.conversion_date = moment(this.utilReq.conversion_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.utilReq.conversion_date = '-';
            }
            this.DrawdownReq = this.utilReq.loan_drawdown_details;
            if(this.DrawdownReq.length > 0) {
              this.DrawdownReq.forEach(item => {
                if (item.disbursement_date != '-' && item.disbursement_date != null) {
                  item.disbursement_date = moment(item.disbursement_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.disbursement_date = '-';
                }
              });
            }
            // console.log(res.data.loan_master_details);
            if (this.DrawdownReq.length > 0) {
              this.DrawdownReq.filter((x) => {
                if (x.balance_amount == null) {
                  this.isBalanceAmountZero = true;
                }
              });
            }
            this.CostSummary = this.utilReq.loan_costs_details;
            if(this.CostSummary.length > 0) {
              this.CostSummary.forEach(item => {
                if (item.expense_date != '-' && item.expense_date != null) {
                  item.expense_date = moment(item.expense_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.expense_date = '-';
                }
              });
            }
            this.RepaymentDetails = this.utilReq.loan_repayment_details;
            if(this.RepaymentDetails.length > 0) {
              this.RepaymentDetails.forEach(item => {
                if (item.repayment_date != '-' && item.repayment_date != null) {
                  item.repayment_date = moment(item.repayment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.repayment_date = '-';
                }
              });
            }
            if(this.utilReq.loan_profit_return_details){
              console.log("this.utilReq.loan_profit_return_details",this.utilReq.loan_profit_return_details)
              this.ProfitReturn = this.utilReq.loan_profit_return_details;
              if(this.ProfitReturn.length > 0) {
                this.ProfitReturn.forEach(item => {
                  if (item.payment_date != '-' && item.payment_date != null) {
                    item.payment_date = moment(item.payment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.payment_date = '-';
                  }
                });
              }
              if(this.ProfitReturn.length > 0){
                this.ProfitReturn = this.ProfitReturn.map(item => {
                    return { ...item, selectedStatus: null };
                  });
              }
            } 
            this.LoanTransactionSummary = this.utilReq.loan_summary_details;
            console.log(this.LoanTransactionSummary.length === 0, this.inactive);
            if (this.LoanTransactionSummary.length === 0 && this.inactive) {
              this.LoanTransactionSummary = [
                {
                  accrued_interest: null,
                  cost_amount: null,
                  todays_date: moment(new Date()).format(this.$store.state.moment_date_format),
                  day_count: null,
                  drawdown_outstanding_amount: null,
                  cost_accrued_interest: null,
                  interest_paid: null,
                  interest_rate: null,
                  loan_registration_id: "-",
                  total_accrued_interest: null,
                  transaction_level_outstanding_amount: null,
                  penalty_interest: null,
                  repayment_amount: null,
                  prepaid_repayment_amount: null,
                  total_interest: null,
                },
              ];
            }
            if(this.LoanTransactionSummary.length > 0) {
              this.LoanTransactionSummary.forEach(item => {
                if (item.todays_date != '-' && item.todays_date != null) {
                  item.todays_date = moment(item.todays_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.todays_date = '-';
                }
              });
            }
            this.Offtaker = this.utilReq.offtaker_contract_details;
            if(this.Offtaker.length > 0) {
              this.Offtaker.forEach(item => {
                if (item.contract_date != '-' && item.contract_date != null) {
                  item.contract_date = moment(item.contract_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.contract_date = '-';
                }
                if (item.Partial_contract_date != '-' && item.Partial_contract_date != null) {
                  item.Partial_contract_date = moment(item.Partial_contract_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.Partial_contract_date = '-';
                }
              });
            }
            this.LoanDocuments = this.utilReq.loan_documents_details;
            this.drawdownDocs = this.utilReq.drawdown_doc_details;
            this.repaymentDocs = this.utilReq.repayment_doc_details;
            this.costDocs = this.utilReq.cost_doc_details;
            this.collateralNames = this.utilReq.collateral_list;
            if(this.collateralNames.length > 0){
              this.collateral_id = this.collateralNames[0].id;
              this.collateral_name = this.collateralNames[0].collateral_name;
            } else {
              this.collateral_id = null
              this.collateral_name = null;
            }
            this.fund_id = this.utilReq.fund_id;
            this.facility_id = this.utilReq.facility_id;
            this.facility_registration_id = this.utilReq.facility_registration_id;
            this.fund_currency_id = this.utilReq.fund_currency_id;
            this.approval_date = this.utilReq.approval_date;
            this.fundCurrencyShortName = this.getCurrencyShortNameById(this.fund_currency_id)
            console.log(this.fundCurrencyShortName)
            if(this.utilReq.facility_with == 0){
              this.selectedDisbursementOn = 2
            } else if (this.utilReq.facility_with == 1){
              this.selectedDisbursementOn = 3
            }
            Utils.stop_loading();
            if(this.utilReq.status != 'Closed') {
              this.getDailyUtilizationRequest();
            } else {
              this.LoanDailySummary = this.LoanTransactionSummary
            }
            this.getLoan();
          } else if (res.data.status == "unauthorised") {
            Utils.stop_loading();
            this.$router.push("/loan");
          } else {
            Utils.stop_loading();
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getUtilizationRequest close

    getDailyUtilizationRequest() {
      console.log("getDailyUtilizationRequest")
      let todays_date = moment(new Date()).format("MM-DD-YYYY");
      const path = "daily_loan_summary/" + this.id + "/" + todays_date;
      console.log("path",path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          if (res.data.status == "success") {
            this.LoanDailySummary = res.data.loan_daily_summary_details;
            console.log("this.LoanDailySummary",this.LoanDailySummary)
            console.log(this.LoanDailySummary.length === 0, this.inactive);
            if (this.LoanDailySummary.length === 0 && this.inactive) {
              this.LoanDailySummary = [
                {
                  accrued_interest: null,
                  cost_amount: null,
                  todays_date: moment(new Date()).format(this.$store.state.moment_date_format),
                  day_count: null,
                  drawdown_outstanding_amount: null,
                  cost_accrued_interest: null,
                  interest_paid: null,
                  interest_rate: null,
                  loan_registration_id: "-",
                  total_accrued_interest: null,
                  transaction_level_outstanding_amount: null,
                  penalty_interest: null,
                  repayment_amount: null,
                  prepaid_repayment_amount: null,
                  total_interest: null,
                },
              ];
            }
            if(this.LoanDailySummary.length > 0) {
              this.LoanDailySummary.forEach(item => {
                if (item.todays_date != '-' && item.todays_date != null) {
                  item.todays_date = moment(item.todays_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.todays_date = '-';
                }
              });
            }
            Utils.stop_loading();
          } else if (res.data.status == "unauthorised") {
            Utils.stop_loading();
            this.$router.push("/loan");
          } else {
            Utils.stop_loading();
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getDailyUtilizationRequest close

    deleteRequest(transactionId, loanRegId, deleteType) {
      console.log(deleteType);
      //this function will be called to delete a specific drawdown request
      this.$confirm({
        auth: false,
        message: "Confirm Delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path = "loan-overview/" + this.id;
            axios
              .delete(path, {
                headers: {
                  "Content-type": "application/json",
                },
                data: {
                  deleteType: deleteType,
                  transactionId: transactionId,
                  loan_reg_id: loanRegId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 5,
                  tabId: 16,
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    deleteType + " deleted successfully!"
                  );
                  //if you want to send any data into server before redirection then you can do it here
                  this.$router.go(this.$router.currentRoute);
                } else {
                  Utils.make_alert(
                    "success",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.log("error: " + error);
                Utils.make_alert("success", "Something went wrong!");
                //this.$router.go(this.$router.currentRoute)
              });
          }
        },
      });
    }, //deleteInvestor()  close

    onChangeUtilOverviewStatus(
      updatedStatus,
      transactionId,
      loanRegId,
      filterdata,
      prevStatus,
      index,
      data
    ) {
      let penaltyStatus = ["Deduct Penalty"];
      if (
        penaltyStatus.includes(updatedStatus.target.value) &&
        filterdata === "ProfitReturnStatus"
      ) {
        this.prfretModalType = "penalty";
        this.profitReturnAmount = Math.abs(
          data.total_penalty === "-" ? (0).toFixed(2) : data.total_penalty
        ).toFixed(2);
        this.profitReturnCurrency = data.currency_short_name;
        this.profitReturnDate = data.payment_date;
        this.profitReturnAvailableAmount =
          data.amount_paid === "-"
            ? (0).toFixed(2)
            : data.amount_paid.toFixed(2);
        this.profitReturnBalanceAmount = (
          this.profitReturnAmount - this.profitReturnAvailableAmount
        ).toFixed(2);
        this.profitReturnAdditionalAmount = this.profitReturnBalanceAmount;
        //Deduct Penalty Logic
        if (
          parseFloat(this.profitReturnAvailableAmount) >=
          parseFloat(this.profitReturnAmount)
        ) {
          this.disableProfitReturnRadio = true;
        } else {
          this.selectedProfitReturnOption = "AddBalance";
        }
        if (
          parseFloat(this.profitReturnAvailableAmount) === 0 &&
          parseFloat(this.profitReturnAmount) !== 0
        ) {
          this.disableWaive = true;
        }
        this.profitReturnDeductPenalty = this.profitReturnAmount;
        this.prfretSubmitData = [
          updatedStatus.target.value,
          transactionId,
          loanRegId,
          filterdata,
          index,
        ];
        this.showAddProfitReturnModal();
      } else {
        console.log(updatedStatus.target.value);
        this.$confirm({
          auth: false,
          message:
            updatedStatus.target.value === "Waive Penalty"
              ? "Are you sure you want to update the status? This is onetime process, Changes cannot be reverted!"
              : "Are you sure you want to update the status?",
          button: {
            no: "No",
            yes: "Yes",
          },

          callback: (confirm) => {
            if (confirm) {
              Utils.start_loading();
              //this function is called when user changes the loan registration status. The status is updated in the database for that specific loan.
              const path = "loan-overview/" + this.id;
              axios
                .put(
                  path,
                  {
                    updateType: "status",
                    updatedStatus: updatedStatus.target.value,
                    transactionId: transactionId,
                    loanRegId: loanRegId,
                    filtertype: filterdata,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 5,
                    tabId: 16,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("update status: " + res.data.status);
                  if (res.data.status == "success") {
                    Utils.make_alert("success", "Status updated successfully!");
                    //if you want to send any data into server before redirection then you can do it here
                    if (filterdata === "LoanDocStatus") {
                      this.LoanDocuments[index].status =
                        updatedStatus.target.value;
                    } else if (filterdata === "RepaymentDocStatus") {
                      this.repaymentDocs[index].status =
                        updatedStatus.target.value;
                    } else if (filterdata === "DrawdownDocStatus") {
                      this.drawdownDocs[index].status =
                        updatedStatus.target.value;
                    } else if (filterdata === "ProfitReturnStatus") {
                      this.ProfitReturn[index].status =
                        updatedStatus.target.value;
                    } else if (filterdata === "CostDocsStatus") {
                      this.costDocs[index].status = updatedStatus.target.value;
                    }
                    this.$router.go(this.$router.currentRoute);
                  } else {
                    console.log("Something went wrong from server!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.error("error: " + error);
                  console.log("Something went wrong!");
                });
            } else {
              // updatedStatus.target.value = prevStatus;
              this.$router.go(this.$router.currentRoute);
            }
          },
        });
      }
    }, //onChangeLoanOverviewStatus close

    onLoanSelectGetDetails(index) {
      console.log("onLoanSelectGetDetails")
      // this.isSubmitted = true;
      if(this.loanMappingList[index].selectedLoan == null){
        this.loanMappingList[index].loanTransferProfitReturnAmount = null;
        this.loanMappingList[index].loanTransferProfitReturnAmountError = false;
        this.loanMappingList[index].loanTransferProfitReturnAmountErrorMessage = ``;
        this.deleteLoanMapping(index);
        return;
      }
      let loan_count = this.loanMappingList.filter(
        (col) => col.selectedLoan === this.loanMappingList[index].selectedLoan
        ).length;
      console.log("loan_count",loan_count)

      // delete if existing bol is found
      if (loan_count > 1) {
        this.deleteLoanMapping(index);
        Utils.make_alert("warning", "Loan Mapping already exists");
      } else {
        let loanArray = this.loans.find(loan => loan.loan_master_id == this.loanMappingList[index].selectedLoan)
        console.log("loanArray", loanArray)
        if(loanArray.transaction_level_outstanding_amount != null){
          this.loanMappingList[index].loanOSAmount = loanArray.transaction_level_outstanding_amount.replaceAll(",", "");
        } else {
          this.loanMappingList[index].loanOSAmount = '0.00'
        }
        // this.loanMappingList[index].selectedLoanError = false;
        this.loanMappingList[index].loanTransferProfitReturnAmount = null;
        this.loanMappingList[index].loanTransferProfitReturnAmountError = true;
        this.loanMappingList[index].loanTransferProfitReturnAmountErrorMessage = `Transfer(Considered as Repayment) is required`;
      }
    },

     // Assuming onChange for loanTransferProfitReturnAmount
    onChangeProfitReturnAmountValidation(index) {
      console.log("onChangeProfitReturnAmountValidation")
      // Access the values of selectedLoan, BOLRepaymentQuantity, and loanTransferProfitReturnAmount for the given index
      const newLoanProfitReturnAmount = this.loanMappingList[index].loanTransferProfitReturnAmount;

      // Check if loanTransferProfitReturnAmount is not null or empty
      if (newLoanProfitReturnAmount == '' || newLoanProfitReturnAmount == 0 || newLoanProfitReturnAmount == null) {
        this.loanMappingList[index].loanTransferProfitReturnAmountError = true;
        this.loanMappingList[index].loanTransferProfitReturnAmountErrorMessage = `Transfer(Considered as Repayment) is required`;
        return
      } else {
        this.loanMappingList[index].loanTransferProfitReturnAmountError = false;
        this.loanMappingList[index].loanTransferProfitReturnAmountErrorMessage = ``;
      }

      if(Number(newLoanProfitReturnAmount) > Number(this.ProfitReturnAmountToBeMapped)){
          this.loanMappingList[index].loanTransferProfitReturnAmountError = true;
          this.loanMappingList[index].loanTransferProfitReturnAmountErrorMessage = `Cannot exceed the avail. Profit return \n amt. ${this.ProfitReturnAmountToBeMapped} ${this.fundCurrencyShortName}.`;
          return;
        } else {
          this.loanMappingList[index].loanTransferProfitReturnAmountError = false;
          this.loanMappingList[index].loanTransferProfitReturnAmountErrorMessage = "";
          return;
        }
    },

    onLoanMappingSaveInfo() {
      let flag = false;
      // Check if any object has a null field and set error to true
      this.loanMappingList.forEach(item => {
        if(item.loanTransferProfitReturnAmount == null || item.loanTransferProfitReturnAmount == 0 || item.loanTransferProfitReturnAmount == ''){
          item.loanTransferProfitReturnAmountError = true;
          item.loanTransferProfitReturnAmountErrorMessage = `Transfer(Considered as Repayment) is required`;
          flag = true;
          return;
        }
        if(Number(item.loanTransferProfitReturnAmount) > Number(this.ProfitReturnAmountToBeMapped)) {
          item.loanTransferProfitReturnAmountError = true;
          item.loanTransferProfitReturnAmountErrorMessage = `Cannot exceed the avail. Profit return \n amt. ${this.ProfitReturnAmountToBeMapped} ${this.fundCurrencyShortName}.`;
          flag = true;
          return;
        } else {
          item.loanTransferProfitReturnAmountError = false;
          item.loanTransferProfitReturnAmountErrorMessage = "";
          flag = false;
        }
      });

      if(!flag) {          
          this.calcTotalLoanProfitReturnAmount();
          let RepaymentConvertedAmount = parseFloat(this.RepaymentConvertedAmount)
          let totalLoanProfitReturnAmount = this.totalLoanProfitReturnAmount 
          console.log("totalLoanProfitReturnAmount", totalLoanProfitReturnAmount)
          console.log("ProfitReturnAmountToBeMapped", this.ProfitReturnAmountToBeMapped)
          if (Number(totalLoanProfitReturnAmount) > Number(this.ProfitReturnAmountToBeMapped)) {
          // let warningMessage = `The Sum of Bill Of Lading Repayment Amount ${finallyTotalloanTransferProfitReturnAmount} ${this.fundCurrencyShortName} should not be greater than Converted Repayment Amount ${this.RepaymentConvertedAmount} ${this.fundCurrencyShortName}`;
          let warningMessage = `The maximum Amount available to map is ${this.ProfitReturnAmountToBeMapped} ${this.fundCurrencyShortName}`;
          Utils.make_alert("warning", warningMessage);
          this.loanMappingAddMoreDisabled = true;
        } else {
          Utils.make_alert("success", "Record saved.");
          this.loanMappingSaveInfoDisabled = false;
          this.loanMappingAddMoreDisabled = false;
        }
        this.totalLoanProfitReturnAmount = 0;
      } else {
        // At least one object has error set to true, show error messages
        this.loanMappingAddMoreDisabled = true;
      }
    },

    calcTotalLoanProfitReturnAmount() {
        // Reset the total amount before calculating
        this.totalLoanProfitReturnAmount = 0;
        // Loop over the loanMappingList array
        for (let list in this.loanMappingList) {
          // Log the current item for debugging
          // Check if selectedLoan is present
          if (this.loanMappingList[list].selectedLoan) {
            // Only add to the total if selectedLoan is present
            this.totalLoanProfitReturnAmount += parseFloat(
              this.loanMappingList[list].loanTransferProfitReturnAmount || 0
            );
          }
        }
      },



    deleteLoanMapping(index) {
      this.loanMappingList.splice(index, 1);
      if (
        typeof this.loanMappingList !== "undefined" &&
        this.loanMappingList.length === 0
      ) {
        this.loanMappingAddMoreDisabled = false;
        this.loanMappingSaveInfoDisabled = true;
      }
    },

    //this method will add new line for additional loan mapping
    addLoanMapping() {
      //when new line is added then loanMappingAddMoreDisabled (Save info button disable property) should be re initialised to true.
        this.loanMappingAddMoreDisabled = true;
        this.loanMappingSaveInfoDisabled = false;
        //following block is created first time on mounted property
        this.loanMappingList.push({
          selectedLoan: null,
          facilityID: null,
          loanOSAmount: null,
          selectedLoanCurrency:null,
          loanRepaymentAmount: null,
          loanTransferProfitReturnAmount: null,
          loanTransferProfitReturnAmountError: false,
          loanTransferProfitReturnAmountErrorMessage: "",
        });
    }, //addLoanMapping() close

    onCloseModal() {
      console.log("onCloseModal");
      this.loanMappingList = [];
      this.ProfitReturnAmountToBeMapped = null;
      this.profitReturnID = null;
      this.ProfitReturn[this.profitReturnIndex].selectedStatus = null;
      this.profitReturnIndex = null;
      $("#view_profit_return_transfer").modal("hide");
    },

    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loanMappingList.map((doc, index) => {
        if (doc.selectedLoan === null) this.loanMappingList.splice(index, 1);
      });
      if (this.loanMappingList.length == 0) {
        let warningMessage = `Please Map Some Loan`;
        Utils.make_alert("warning", warningMessage);
        return;
      }

      let flag = false;
      // Check if any object has a null field and set error to true
      this.loanMappingList.forEach(item => {
        if (item.selectedLoan) {
          if(item.loanTransferProfitReturnAmount == null || item.loanTransferProfitReturnAmount == 0 || item.loanTransferProfitReturnAmount == ''){
            item.loanTransferProfitReturnAmountError = true;
            item.loanTransferProfitReturnAmountErrorMessage = `Transfer(Considered as Repayment) is required`;
            flag = true;
            return;
          }
          if(Number(item.loanTransferProfitReturnAmount) > Number(this.ProfitReturnAmountToBeMapped)) {
            item.loanTransferProfitReturnAmountError = true;
            item.loanTransferProfitReturnAmountErrorMessage = `Cannot exceed the avail. Profit return \n amt. ${this.ProfitReturnAmountToBeMapped} ${this.fundCurrencyShortName}.`;
            flag = true;
            return;
          } else {
            item.loanTransferProfitReturnAmountError = false;
            item.loanTransferProfitReturnAmountErrorMessage = "";
            flag = false;
          }
        }
      });

      console.log("flag",flag)

      if(!flag) {
        // Loop over the loanMapping array
        let alreadyTotalloanTransferProfitReturnAmount = 0
        // for (let list in this.loanMapping) {
        //   if (this.loanMapping[list].bol_no) {
        //     alreadyTotalloanTransferProfitReturnAmount += parseFloat(
        //       this.loanMapping[list].bol_amount || 0
        //     );
        //   }
        // }
          
        this.calcTotalLoanProfitReturnAmount();
        let RepaymentConvertedAmount = parseFloat(this.RepaymentConvertedAmount)
        let finallyTotalloanTransferProfitReturnAmount = alreadyTotalloanTransferProfitReturnAmount + this.totalLoanProfitReturnAmount 
        if (finallyTotalloanTransferProfitReturnAmount > parseFloat(this.ProfitReturnAmountToBeMapped)) {
        // let warningMessage = `The Sum of Bill Of Lading Repayment Amount ${finallyTotalloanTransferProfitReturnAmount} ${this.fundCurrencyShortName} should not be greater than Converted Repayment Amount ${this.RepaymentConvertedAmount} ${this.fundCurrencyShortName}`;
        let warningMessage = `The maximum Amount available to map is ${this.ProfitReturnAmountToBeMapped} ${this.fundCurrencyShortName}`;
        Utils.make_alert("warning", warningMessage);
        this.loanMappingAddMoreDisabled = true;
        return;
        } else {
          this.loanMappingSaveInfoDisabled = false;
          this.loanMappingAddMoreDisabled = false;
        }
        this.totalLoanProfitReturnAmount = 0;
      } else {
        // At least one object has error set to true, show error messages
        this.loanMappingAddMoreDisabled = true;
        return
      }

      console.log("Done",this.loanMappingList[0])
      $("#view_profit_return_transfer").modal("hide");

      this.$router.push({
          name: 'AddRepayment',
          params: {
            id:0,
            repaymentType: 'Loan',
            contractType: 'Offtaker',
            profitReturnID: this.profitReturnID,
            loanID: this.loanMappingList[0].selectedLoan,
            selectedBorrower: this.borrower_id,
            selectedFacility: this.facility_id,
            fundCurrency: this.fund_currency_id,
            transferProfitReturnAmount: this.loanMappingList[0].loanTransferProfitReturnAmount,
          },
        });

      // //initialize the form data
      // let formData = new FormData();
      // //append company information to formdata
      // formData.append("profitReturnID", this.profitReturnID);
      // formData.append("loanMappingList", JSON.stringify(this.loanMappingList));
      // formData.append("transferProfitReturnAmount", this.transferProfitReturnAmount);

      // formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      // formData.append("userId", this.$store.getters.getuserId);
      // formData.append("moduleId", 4);
      // formData.append("tabId", 35);
      // const path = "/add-profit-return"
      // Utils.start_loading();
      // axios
      //   .post(path, formData, {
      //     headers: {
      //       enctype: "multipart/form-data",
      //     },
      //   })
      //   .then((response) => {
      //     Utils.stop_loading();
      //     console.log("Status",response.data.status)
      //     if (response.data.status == "success") {
      //       Utils.make_alert(
      //         "success",
      //         "Bill Of Lading Added successfully!"
      //       );
      //       console.log("SUCCESS!!");
      //       this.$router.go(this.$router.currentRoute)
      //       } else {
      //       Utils.make_alert("success", "Something went wrong!");
      //       console.log("FAILURE!!");
      //     }
      //   })
      //   .catch((error) => {
      //     Utils.stop_loading();
      //     console.error(error);
      //     Utils.make_alert("success", "Something went wrong!");
      //   });
    }, //onSubmit() close

    openChangeProfitReturnStatus(index){
      this.profitReturnIndex = index;
      let ProfitReturnStatus = this.ProfitReturn[index].status;
      this.profitReturnID = this.ProfitReturn[index].profit_return_id;
      let ProfitReturnAmount = this.ProfitReturn[index].balance_amount;
      let updatedStatus = this.ProfitReturn[index].selectedStatus;
      if(updatedStatus == 'Profit Return'){
        this.$confirm({
          auth: false,
          message: `Are you sure you want to ${updatedStatus} this Utilisation/Loan Request?`,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              Utils.start_loading();
              //this function is called when user changes the loan registration status. The status is updated in the database for that specific loan.
              const path = "profit-return-change-status/" + this.profitReturnID;
              axios
                .put(
                  path,
                  {
                    updatedStatus: updatedStatus,
                    ProfitReturnID: this.profitReturnID,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 5,
                    tabId: 16,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("registration update status: " + res.data.status);
                  if (res.data.status == "success") {
                    Utils.make_alert("success", "Status updated successfully!");
                    //if you want to send any data into server before redirection then you can do it here
                    this.$router.go(this.$router.currentRoute);
                    // this.ProfitReturn[index].selectedStatus = null;
                  } else {
                    console.log("Something went wrong from server!");
                  }
                  this.ProfitReturn[index].selectedStatus = null;
                  this.profitReturnIndex = null;
                  this.profitReturnID = null;
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.error("error: " + error);
                  console.log("Something went wrong!");
                  this.ProfitReturn[index].selectedStatus = null;
                  this.profitReturnIndex = null;
                  this.profitReturnID = null;
                });
              } else {
                this.ProfitReturn[index].selectedStatus = null;
                this.profitReturnIndex = null;
                this.profitReturnID = null;
            }
          },
        });
      } else if (updatedStatus == 'Transfer'){
        this.showProfitReturnMappingModal(ProfitReturnAmount)
      }
    },

    showProfitReturnMappingModal(amount) {
      this.isSubmitted = false;
      console.log("showProfitReturnMappingModal")
      console.log("amount",amount)
      if(amount != null){
        this.ProfitReturnAmountToBeMapped = amount.replaceAll(",", "");
      } else {
        this.ProfitReturnAmountToBeMapped = '0.00';
      }
      this.excessSelectedLoan = null;
      this.excessSelectedLoanError = false;
      this.loanMappingList = []
      this.addLoanMapping();
      // $("#view_profit_return").modal("show");
      $("#view_profit_return_transfer").modal("show");
    },

    // onExcessLoanSelectGetDetails(){
    //   console.log("onExcessLoanSelectGetDetails")
    //   if(this.excessSelectedLoan == null){
    //     this.excessSelectedLoanError = true;
    //     return;
    //   }
    // },


    
    // updateToggle(value) {
    //   this.profitReturnToggle = value;
    //   if(value){
    //     if(this.excessSelectedLoan == null){
    //       this.excessSelectedLoanError = true;
    //     } else {
    //       this.excessSelectedLoanError = false;
    //     }
    //   }
    // },

    // onSubmitModal() {
    //   console.log("onSubmitModal");
    //   if(this.profitReturnToggle){
    //     if(this.excessSelectedLoan == null){
    //       this.excessSelectedLoanError = true;
    //     } else {
    //       this.excessSelectedLoanError = false;
    //     }
    //   }
    //   if(this.excessSelectedLoanError){
    //     return;
    //   }
    //   if (this.profitReturnToggle){
    //     console.log("onSubmitModal profitReturnToggle true",this.profitReturnToggle);
    //     this.profitReturnToggle = true;
    //     this.profitReturnFlag = true;
    //     $("#view_profit_return").modal("hide");
    //     Utils.make_alert("warning", "Loan Mapping Done Successfully");
    //   } else {
    //     console.log("onSubmitModal profitReturnToggle false",this.profitReturnToggle);
    //     this.profitReturnToggle = true;
    //     this.profitReturnFlag = false;

    //     // Extract all loan IDs from the loans array
    //     const loanIds = this.loans.map(loan => loan.loan_master_id);
    //     console.log("loanIds",loanIds)

    //     // Extract all selectedLoan values from the loanMappingList array
    //     const selectedLoansInMapping = this.id
    //     console.log("selectedLoansInMapping",selectedLoansInMapping)

    //     // Check if there are any loan IDs not present in selectedLoansInMapping
    //     const missingLoanIds = loanIds.filter(loan_master_id => !selectedLoansInMapping.includes(loan_master_id));
    //     console.log("missingLoanIds",missingLoanIds)
    //     if (missingLoanIds.length === 0) {
    //       console.log("All available loan are already mapped")
    //       Utils.make_alert("warning", "All available loan are already mapped");
    //       this.profitReturnToggle = true;
    //     } else {
    //       console.log("add new loan")
    //       // If you suspect that the change is not reactive, you can force update
    //       $("#view_profit_return").modal("hide");
    //     }
    //   }
    // },

    getLoan() {
      console.log("get Loan")
      const path = "/get-loan-by-facilities/" + this.facility_id + "/" + "repayment";
      // const path = "/get-loan-by-facilities/" + this.selectedFacility + "/" + "loan";
      console.log("get Loan",path)
      axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          if(res.data.status == "success"){
            this.loans = res.data.loan_list;
            let filteredLoanArray = this.loans.filter(loan => 
              loan.loan_master_id != this.id
            );
            this.loans = filteredLoanArray
          }
        })
        .catch(error => {
          console.error(error);
        });
    },

    getLoanExpenses() {
      //this function is called to get list of loan expenses
      const path = "loan-expense-master";
      axios
        .get(path)
        .then((res) => {
          this.loanExpenses = res.data.loan_expenses;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
          this.currencies_drawdown = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    

    //   //handles a change on the file upload
    //   handleFileUploadCost(id, e) {
    //     let self = this;
    //     let reader = new FileReader();
    //     this.CostFile = e.target.files[0];
    //     //check for filesize. if it is larger than threshold then return following error response
    //     if (this.CostFile.size > 1024 * 1024) {
    //       e.preventDefault();
    //       var response = { errors: null };
    //       response.errors = "File size should be less than 1MB";
    //       self.CostList[id].errors = JSON.stringify(response.errors).replace(
    //         /\"/g,
    //         ""
    //       );
    //       self.CostList[id].selectedFile = null;
    //       self.CostList[id].selectedFileName = null;
    //       self.CostList[id].display_file_name = null;
    //       self.CostList[id].CostRemoveDisabled = true;
    //       return;
    //     }
    //     reader.readAsDataURL(this.CostFile);
    //     console.log("id: " + id);
    //     reader.onload = function() {
    //       self.CostList[id].selectedFile = reader.result;
    //       self.CostList[id].selectedFileName = e.target.files[0].name;
    //       self.CostList[id].display_file_name = e.target.files[0].name; //to show filename in file input
    //       self.CostList[id].errors = "";
    //       self.CostList[id].CostRemoveDisabled = false;
    //     };
    //     reader.onerror = function(error) {
    //       console.log("Error: ", error);
    //     };
    //   }, //handleFileUploadCost() close
    //   ////this method handles remove cost attachment
    //   removeCostDoc(id) {
    //     this.CostFile = null;
    //     this.CostList[id].selectedFile = null;
    //     this.CostList[id].selectedFileName = null;
    //     this.CostList[id].display_file_name = null;
    //     this.CostList[id].CostRemoveDisabled = true;
    //   },
    //   getCurrencies() {
    //     //this function is called to get list of currencies
    //     const path = "currency";
    //     axios
    //       .get(path)
    //       .then(res => {
    //         this.currencies = res.data.currencies;
    //       })
    //       .catch(error => {
    //         console.error(error);
    //       });
    //   },
    //   resetLoan() {
    //     this.startDate = null;
    //     this.endDate = null;
    //     this.startDatePeriod = null;
    //     this.endDatePeriod = null;
    //     this.selectedPeriod = null;
    //     this.selectedStatus = "Approved";
    //     // this.selectedFund = null;
    //     // this.selectedLoanCategory = null;
    //     // this.selectedInvestorName = null;
    //     this.filter = null;
    //     this.getAllLoans();
    //   } //resetLoan() close
    getActionUpcoming(type, index) {
      var path2 = "upcoming-payments";
      var fund_list;
      axios
        .get(path2, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          if (type == "profit_return") {
            fund_list = res.data.profit_return_list[index];
            //this.showName = fund_list.borrower_name;
            this.profitReturnAmount = fund_list.payment_amount;
            //this.description = fund_list.description
            this.profitReturnCurrency = fund_list.currency_short_name;
            this.profitReturnDate = fund_list.payment_date;
            this.showAddProfitReturnModal();
          } else if (type == "profit_return_before_current") {
            fund_list = res.data.profit_return_before_current_list[index];
            //this.showName = fund_list.borrower_name;
            this.profitReturnAmount = fund_list.payment_amount;
            //this.description = fund_list.description
            this.profitReturnCurrency = fund_list.currency_short_name;
            this.profitReturnDate = fund_list.payment_date;
            this.showAddProfitReturnModal();
          }
        })
        .catch((error) => {
          console.error("error: " + error);
        });
    },
  },
  mounted() {
    this.index = this.$route.params.index;
    this.type = this.$route.params.type;

    //keep current tab active
    $('a[data-toggle="pill"]').on("shown.bs.tab", function (e) {
      localStorage.setItem("loanUtilTab", $(e.target).attr("href"));
    });
    var activeTab = localStorage.getItem("loanUtilTab");
    if (activeTab) {
      $('.nav-tabs a[href="' + activeTab + '"]').tab("show");
    }

    if (this.index >= 0) {
      $('.nav-tabs a[href="#four"]').tab("show");
      this.getActionUpcoming(this.type, this.index);
    }
    // to make column header ascending Descending
  },
  created() {
    this.id = this.$route.params.id;
    console.log("id",this.id);
    this.inactive =
      this.$store.state.inactive === "loan_inactive" ? true : false;
    this.getUtilizationRequest();
    this.borrower_id = this.$route.params.borrower_id;
    console.log("borrower_id",this.borrower_id);
    this.getLoanExpenses();
    this.getCurrencies();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style scoped>

.black{
  color: black !important;
}
.datepicker {
  width: 220px;
}

.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
#profit_return #al_tb .table tbody tr td .form-control {
  width: 50% !important;
}

.required-field::after {
  content: "*";
  color: red;
}

.modal-dialog.custom-width {
    width: 50% !important;
    max-width: 900px !important;
}

th,
td {
  text-align: left;
}

.col{
  width: 120px !important;
}
.col250{
  width: 250px !important;
}
.center {
  text-align: center !important;
}
.right {
  text-align: right !important;
}
.marginRight{
  margin-right: 100px;
}

.vcenter  {
    padding-left: 10px !important; /* Adjust the padding value as needed */
    margin-top: 5px !important;
}

</style>
