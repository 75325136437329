<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div
                      class="col-lg-6 col-sm-6"
                      v-if="loggedInUserGroupId == 'Borrower'"
                    >
                      <h1 class="headingt">
                        <i class="icofont-handshake-deal"></i> Profile
                      </h1>
                    </div>
                    <div class="col-lg-6 col-sm-6" v-else>
                      <h1 class="headingt">
                        <i class="icofont-handshake-deal"></i> Borrower
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div
                      class="col-lg-6 col-sm-6"
                      v-if="
                        loggedInUserGroupId == 'Admin' ||
                        (loggedInUserGroupId == 'Analyst' &&
                          loggedInUserSubGroupId == 0)
                      "
                    >
                      <ul id="sr_btn">
                        <!-- <li v-if="loggedInUserGroupId == 'Admin'">
                          <input
                            type="checkbox"
                            id="checkbox"
                            v-model="inActiveToggle"
                            @change="destroyTable"
                          />
                          <label for="checkbox">InActive&nbsp;&nbsp;</label>
                        </li> -->
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-success"
                            @click="$router.push('/add-borrower')"
                          >
                            <i class="icofont-plus"></i>
                            <span class="mob">Add Borrower</span>
                          </button>
                        </li>
                        <!-- <li>
                          <div class="form-group has-search m-0">
                            <span
                              class="fa fa-search form-control-feedback"
                            ></span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search"
                            />
                          </div>
                        </li>-->
                      </ul>
                    </div>
                    <div
                      class="col-lg-6 col-sm-6"
                      v-if="loggedInUserGroupId == 'Admin'"
                    >
                      <input
                        type="radio"
                        :value="false"
                        @change="destroyTable"
                        v-model="inActiveToggle"
                        id="active"
                      />
                      <label for="active" style="padding-right: 20px"
                        >Active</label
                      >
                      <input
                        type="radio"
                        :value="true"
                        @change="destroyTable"
                        v-model="inActiveToggle"
                        id="inactive"
                      />
                      <label for="inactive">InActive</label>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div
                  class="card-body table-responsive p-0"
                  id="datatable-padding"
                >
                  <!------menton here style="height: 300px;"-------->
                  <table
                    id="myTable1"
                    class="table table-head-fixed text-nowrap table-hover myTable1"
                  >
                    <thead>
                      <tr>
                        <!--<th>Reg. No.</th>-->
                        <th v-if="loggedInUserGroupId == 'Borrower'">Name</th>
                        <th v-else>Borrower Name</th>
                        <th class="c">KYC Status</th>
                        <th class="c">Covenant Status</th>
                        <th class="c">Registration Status</th>
                        <th
                        class="c"
                          v-if="
                            !inActiveToggle &&
                            loggedInUserGroupId == 'Admin'
                          "
                        >
                          Change Reg. Status
                        </th>
                        <th 
                          class="c"
                          v-if="
                            loggedInUserGroupId == 'Borrower' ||
                            loggedInUserGroupId == 'Analyst' ||
                            (loggedInUserGroupId == 'Admin' &&
                            loggedInUserSubGroupId == 0)
                          "
                          >Pay Status</th>
                        <th
                          class="c"
                          v-if="
                            !inActiveToggle &&
                            loggedInUserGroupId == 'Admin' ||
                            (loggedInUserGroupId == 'Analyst' &&
                            loggedInUserSubGroupId == 0)
                          "
                        >
                          Change Pay Status
                        </th>
                        <template v-if="!inActiveToggle">
                          <th
                            class="c center"
                            v-if="
                              loggedInUserGroupId == 'Analyst' ||
                              (loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0)
                            "
                          >
                            Add Facility
                          </th>
                          <th
                            class="c"
                            v-if="
                              loggedInUserGroupId == 'Borrower' ||
                              loggedInUserGroupId == 'Analyst' ||
                              (loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0)
                            "
                          ></th>
                          <th
                            class="c"
                            v-if="
                              loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0)
                            "
                          ></th>
                        </template>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(borrower, index) in borrowers"
                        :key="borrower.borrower_id"
                      >
                        <template
                          v-if="
                            loggedInUserGroupId == 'Admin' ||
                            (loggedInUserGroupId == 'Analyst' &&
                              loggedInUserSubGroupId == 0)
                          "
                        >
                          <!--<td>{{ borrower.borrower_id }}</td>-->
                          <td class="ln">
                            <router-link
                              :to="{
                                name: 'BorrowerOverview',
                                params: {
                                  id: borrower.borrower_id,
                                  inactive: inActiveToggle,
                                },
                              }"
                              >{{ borrower.borrower_name }}</router-link
                            >
                          </td>
                          <td
                            v-if="borrower.kyc_status !== null"
                            :class="{
                              'c text-success':
                                borrower.kyc_status === 'Approved',
                              'c text-warning':
                                borrower.kyc_status === 'Received',
                              'c text-danger':
                                borrower.kyc_status === 'Rejected',
                            }"
                          >
                            {{ borrower.kyc_status }}
                          </td>
                          <td v-else>-</td>
                          <td
                            v-if="borrower.covenant_status !== null"
                            :class="{
                              'c text-success':
                                borrower.covenant_status === 'Compliant',
                              'c text-danger':
                                borrower.covenant_status === 'Non Compliant',
                            }"
                          >
                            {{ borrower.covenant_status }}
                          </td>
                          <td v-else>-</td>
                          <td
                            v-if="borrower.status !== null"
                            :class="{
                              'c text-success': borrower.status === 'Approved',
                              'c text-warning': borrower.status === 'Pending',
                              'c text-danger': borrower.status === 'Rejected',
                            }"
                          >
                            {{ borrower.status }}
                          </td>
                          <td v-else>-</td>
                          <template
                            v-if="
                              !inActiveToggle &&
                              loggedInUserGroupId == 'Admin'
                            "
                          >
                            <td class="c">
                              <div class="status">
                                <select
                                  id="regStatus"
                                  name="regStatus"
                                  @change="
                                    onChangeStatus(
                                      $event,
                                      borrower.borrower_id,
                                      borrower.status,
                                      index
                                    )
                                  "
                                  class="form-control"
                                  :value="borrower.status"
                                  :disabled="borrower.status != 'Pending'"
                                >
                                  <option value="Pending">Pending</option>
                                  <option value="Approved">Approved</option>
                                  <option value="Rejected">Rejected</option>
                                </select>
                              </div>
                            </td>
                          </template>
                          <td
                            v-if="borrower.borrower_status_master_name !== null"
                            :class="{
                              'c text-success': borrower.borrower_status_master_name === 'Live',
                              'c text-info': borrower.borrower_status_master_name === 'Recovery',
                              'c text-dark': borrower.borrower_status_master_name === '-',
                            }"
                          >
                            {{ borrower.borrower_status_master_name }}
                          </td>
                          <template
                            v-if="
                              !inActiveToggle &&
                              loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0)
                            "
                          >
                            <td class="c">
                              <div class="status">
                                <select
                                  id="Status"
                                  name="Status"
                                  @change="
                                    onChangeBorrowerStatus(
                                      $event,
                                      borrower.borrower_id,
                                      borrower.borrower_status_master_id,
                                      index
                                    )
                                  "
                                  class="form-control"
                                  :value="borrower.borrower_status_master_id"
                                  :disabled="borrower.status == 'Pending' || borrower.status == 'Rejected'"
                                >
                                  <option
                                    v-for="borr in borrower_status"
                                    :value="borr.id"
                                    :key="borr.id"
                                    :disabled="borr.id == 1"
                                  >
                                    {{ borr.borrower_status }}
                                  </option>
                                </select>
                              </div>
                            </td>
                          </template>

                          <template v-if="!inActiveToggle">
                            <td
                              class="c text-danger center"
                              v-if="borrower.status === 'Approved'"
                            >
                              <router-link
                                style="text-decoration: none"
                                :to="{
                                  name: 'AddFacility',
                                  params: {
                                    borrower_id: borrower.borrower_id,
                                    type: 'facility',
                                    BorRegDate: borrower.registration_date,
                                  },
                                }"
                              >
                                <i class="icofont-plus text-success"></i>
                              </router-link>
                            </td>
                            <td v-else class="center">
                              <i
                                style="opacity: 0.5"
                                class="icofont-plus text-success"
                              ></i>
                            </td>

                            <td
                              class="c"
                              v-if="
                                loggedInUserGroupId == 'Borrower' ||
                                loggedInUserGroupId == 'Analyst' ||
                                (loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0)
                              "
                            >
                              <router-link
                                style="text-decoration: none"
                                :to="{
                                  name: 'EditBorrower',
                                  params: {
                                    id: borrower.borrower_id,
                                    inactive: inActiveToggle,
                                  },
                                }"
                              >
                                <i class="icofont-ui-edit text-warning"></i>
                              </router-link>
                            </td>
                            <td
                              class="c"
                              v-if="
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                              "
                              v-on:click="deleteBorrower(borrower.borrower_id)"
                              :style="{ cursor: 'pointer' }"
                            >
                              <i class="icofont-trash text-danger"></i>
                            </td>
                          </template>
                        </template>
                        <template
                          v-else-if="
                            loggedInUserGroupId == 'Borrower' &&
                            loggedInUserSubGroupId == borrower.borrower_id
                          "
                        >
                          <td class="ln">
                            <router-link
                              :to="{
                                name: 'BorrowerOverview',
                                params: { id: borrower.borrower_id },
                              }"
                              >{{ borrower.borrower_name }}</router-link
                            >
                          </td>
                          <td
                            v-if="borrower.kyc_status !== null"
                            :class="{
                              'c text-success':
                                borrower.kyc_status === 'Approved',
                              'c text-warning':
                                borrower.kyc_status === 'Received',
                              'c text-danger':
                                borrower.kyc_status === 'Rejected',
                            }"
                          >
                            {{ borrower.kyc_status }}
                          </td>
                          <td v-else>-</td>
                          <td
                            v-if="borrower.covenant_status !== null"
                            :class="{
                              'c text-success':
                                borrower.covenant_status === 'Compliant',
                              'c text-danger':
                                borrower.covenant_status === 'Non Compliant',
                            }"
                          >
                            {{ borrower.covenant_status }}
                          </td>
                          <td v-else>-</td>
                          <td
                            v-if="borrower.status !== null"
                            :class="{
                              'c text-success': borrower.status === 'Approved',
                              'c text-warning': borrower.status === 'Pending',
                              'c text-danger': borrower.status === 'Rejected',
                            }"
                          >
                            {{ borrower.status }}
                          </td>
                          <td v-else>-</td>
                          <td
                            v-if="borrower.borrower_status_master_name !== null"
                            :class="{
                              'c text-success': borrower.borrower_status_master_name === 'Live',
                              'c text-info': borrower.borrower_status_master_name === 'Recovery',
                              'c text-dark': borrower.borrower_status_master_name === '-',
                            }"
                          >
                            {{ borrower.borrower_status_master_name }}
                          </td>
                          <!-- <td class="c">
                            <div>
                              <select
                                id="regStatus"
                                name="regStatus"
                                @change="onChangeStatus($event, borrower.borrower_id, borrower.status, index)"
                                class="form-control"
                                v-model="borrower.status"
                              >
                                <option :value="null"></option>
                                <option value="Pending">Pending</option>
                                <option value="Approved">Approved</option>
                                <option value="Rejected">Rejected</option>
                              </select>
                            </div>
                          </td>-->
                          <td class="c">
                            <router-link
                              style="text-decoration: none"
                              :to="{
                                name: 'EditBorrower',
                                params: { id: borrower.borrower_id },
                              }"
                            >
                              <i class="icofont-ui-edit text-warning"></i>
                            </router-link>
                          </td>
                          <!-- <td
                            class="c"
                            v-on:click="deleteBorrower(borrower.borrower_id)"
                            :style="{ cursor: 'pointer'}"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </td>-->
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
      <!-- /.content-->
    </div>
    <!-- /.content-wrapper -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import $ from "jquery";
// //Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function

export default {
  data() {
    return {
      borrowers: [],
      regStatus: null,
      BorRegDate: null,
      inActiveToggle: false,
      borrower_status:[],
    };
  },
  components: {
    Datepicker,
  },
  methods: {
    destroyTable() {
      $("#myTable1").dataTable().fnDestroy();
      if (!this.inActiveToggle) {
        this.$store.commit("setinactive", "borrower_active");
        this.$router.go();
      } else {
        this.$store.commit("setinactive", "borrower_inactive");
        this.getBorrowers();
      }
    },
    onChangeStatus(updatedStatus, borrowerId, prevStatus, index) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            //this function is called when user changes the borrower registration status. The status is updated in the database for that specific borrower.
            const path = "borrower/" + borrowerId;
            Utils.start_loading();
            axios
              .put(
                path,
                {
                  updateType: "regStatus",
                  updatedRegStatus: updatedStatus.target.value,
                  borrId: borrowerId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 2,
                  tabId: 4,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("registration update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Registration Status updated successfully!"
                  );
                  //if you want to send any data into server before redirection then you can do it here
                  this.borrowers[index].status = updatedStatus.target.value;
                  return true;
                } else {
                  Utils.make_alert(
                    "warning",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeStatus close

    onChangeBorrowerStatus(updatedStatus, borrowerId, prevStatus, index) {
      console.log("onChangeBorrowerStatus")
      console.log("updatedStatus.target.value",updatedStatus.target.value)
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            //this function is called when user changes the borrower registration status. The status is updated in the database for that specific borrower.
            const path = "borrower-change-status/" + borrowerId;
            Utils.start_loading();
            axios
              .put(
                path,
                {
                  updatedStatusID: updatedStatus.target.value,
                  borrId: borrowerId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 2,
                  tabId: 4,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("registration update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Pay Status updated successfully!"
                  );
                  //if you want to send any data into server before redirection then you can do it here
                  let borrower_status_master_name = this.borrower_status.find((bor) => bor.id == updatedStatus.target.value).borrower_status
                  console.log("borrower_status_master_name",borrower_status_master_name)
                  this.borrowers[index].borrower_status_master_id = updatedStatus.target.value;
                  this.borrowers[index].borrower_status_master_name = borrower_status_master_name
                  return true;
                } else {
                  Utils.make_alert(
                    "warning",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          } else {
            updatedStatus.target.value = prevStatus;
            //if you want to send any data into server before redirection then you can do it here
            console.log("prevStatus",prevStatus)
            let borrower_status_master_name = this.borrower_status.find((bor) => bor.id == prevStatus).borrower_status
            console.log("borrower_status_master_name",borrower_status_master_name)
            this.borrowers[index].borrower_status_master_id = prevStatus;
            this.borrowers[index].borrower_status_master_name = borrower_status_master_name
          }
        },
      });
    }, //onChangeBorrowerStatus close

    getBorrowers() {
      //this function will be called to get list of borrowers
      const path = "borrower";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (this.inActiveToggle) {
            this.borrowers = res.data.borrowers_inactive;
          } else {
            this.borrowers = res.data.borrowers_active;
          }
          console.log("borrowers",this.borrowers)
          setTimeout(
            () =>
              $("#myTable1").DataTable({
                aaSorting: [],
                columnDefs: this.inActiveToggle
                  ? []
                  : [
                      {
                        orderable: false,
                        targets:
                            this.loggedInUserGroupId === "Analyst"
                            ? [5, 6, 7, 8]
                            :this.loggedInUserGroupId === "Borrower"
                            ? []
                            : [4, 6, 7, 8, 9],
                      },
                    ],
              }),
            100
          );
          if (res.data.status == "success") {
            console.log("get borrowers is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },
    deleteBorrower(borrowerId) {
      //this function will be called to delete a specific fund. Parameter required - fundID: fundid which needs to be deleted

      this.$confirm({
        auth: false,
        message: "Confirm Delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete("borrower/" + borrowerId, {
                data: {
                  deleteType: "borrower",
                  tranId: borrowerId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 2,
                  tabId: 4,
                },
                headers: { "Content-type": "application/json" },
              })
              .then((res) => {
                Utils.stop_loading();
                console.log("delete status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert("success", "Borrower deleted successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(
                    () => this.$router.go(this.$router.currentRoute),
                    1500
                  );
                } else if (res.data.status == "deletion_not_allowed") {
                  Utils.make_alert(
                    "warning",
                    "Delete operation not permitted."
                  );
                } else {
                  console.log("Something went wrong from server!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.log("error: " + error);
                console.log("Something went wrong!");
              });
          } else {
            Utils.make_alert("success", "Cancelled!");
          }
        },
      });
    }, //deleteBorrower()  close

    //this function is called to get list of borrower_status
    getBorrowerStatus() {
      const path = "borrower-status-master";
      axios
        .get(path)
        .then(res => {
          this.borrower_status = res.data.borrower_status;
        })
        .catch(error => {
          console.error(error);
        });
    }, //getBorrowerStatus() close
  },

  created() {
    axios.defaults.headers.common["Authorisation"] =
      "Bearer " + sessionStorage.getItem("token");
    this.inActiveToggle =
      this.$store.state.inactive === "borrower_inactive" ? true : false;
    this.getBorrowers();
    this.getBorrowerStatus();
    this.BorRegDate = moment
      .utc(this.$route.params.BorRegDate, "MM-DD-YYYY")
      .toDate();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style scoped>
th,
td {
  text-align: left;
}
.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
.center {
  text-align: center;
}
textarea {
  border-style: none;
  border-color: Transparent;
  overflow: auto;
}

.wrapper {
  margin-bottom: -20px;
  min-height: 680px !important;
}
</style>
