import { render, staticRenderFns } from "./AddRelease.vue?vue&type=template&id=578a42fc&scoped=true&"
import script from "./AddRelease.vue?vue&type=script&lang=js&"
export * from "./AddRelease.vue?vue&type=script&lang=js&"
import style0 from "./AddRelease.vue?vue&type=style&index=0&id=578a42fc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "578a42fc",
  null
  
)

export default component.exports