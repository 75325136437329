<!-- This is add collateral page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Add Logistics Info
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedBorrower" class="required-field"
                      >Borrower Name</label
                    >
                    <select
                      id="selectedBorrower"
                      class="form-control"
                      name="selectedBorrower"
                      v-model="selectedBorrower"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedBorrower.$error,
                      }"
                      @change="
                          getFacilitySetupByBorrower();
                          getWarehouseByBorrower(selectedBorrower);
                          getOfftakerNameByBorrower()
                      "
                      :disabled="loggedInUserGroupId === 'Borrower'"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="bor in borrowers"
                        :value="bor.borrower_id"
                        :key="bor.borrower_id"
                      >
                        {{ bor.borrower_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedBorrower.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedBorrower.required"
                        >Borrower Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedFacilityId" class="required-field"
                      >Facility ID</label
                    >
                    <select
                      id="selectedFacilityId"
                      class="form-control"
                      name="selectedFacilityId"
                      v-model="selectedFacilityId"
                      @change="getCollateral(selectedFacilityId)"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedFacilityId.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="fac in facilities"
                        :value="fac.id"
                        :key="fac.id"
                      >
                        {{ fac.facility_registration_id }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedFacilityId.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedFacilityId.required"
                        >Facility ID is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCollateral" class="required-field"
                      >Collateral</label
                    >
                    <select
                      id="selectedCollateral"
                      class="form-control"
                      name="selectedCollateral"
                      v-model="selectedCollateral"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedCollateral.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="col in collaterals"
                        :value="col.id"
                        :key="col.id"
                      >
                        {{ col.collateral_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCollateral.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCollateral.required"
                        >Collateral is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCollateralType" class="required-field"
                      >Collateral Type</label
                    >
                    <select
                      id="selectedCollateralType"
                      class="form-control"
                      name="selectedCollateralType"
                      v-model="selectedCollateralType"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedCollateralType.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="col in collateralStages"
                        :value="col.id"
                        :key="col.id"
                      >
                        {{ col.stage_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCollateralType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCollateralType.required"
                        >Collateral Type is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedWarehouse" class="required-field"
                      >Warehouse</label
                    >
                    <select
                      id="selectedWarehouse"
                      class="form-control"
                      name="selectedWarehouse"
                      v-model="selectedWarehouse"
                      @change="onWarehouseChange()"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedWarehouse.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="war in warehouses"
                        :value="war.id"
                        :key="war.id"
                      >
                        {{ war.warehouse_registration_id + ": " }}
                        {{ war.address }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedWarehouse.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedWarehouse.required"
                        >Warehouse is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCM" class="required-field"
                      >Collateral Manager Company</label
                    >
                    <select
                      id="selectedCM"
                      class="form-control"
                      name="selectedCM"
                      v-model="selectedCM"
                      :disabled="loggedInUserGroupId === 'Collateral Manager'"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedCM.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="cm in mappedCM"
                        :value="cm.collateral_manager_master_id"
                        :key="cm.collateral_manager_master_id"
                      >
                        {{ cm.collateral_id }}: {{ cm.company_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCM.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCM.required"
                        >Collateral Manager Company is required</span
                      >
                    </div>
                    <!-- <div v-if="emptyCMListError" class="invalid-feedback">
                      <span
                        >No Collateral Manager Company is available for the
                        selected warehouse</span
                      >
                    </div> -->
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="date" class="required-field">Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="date"
                      id="date"
                      placeholder="Select Date"
                      v-model="date"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{
                        from: new Date(),
                        to: new Date(logisticsInfoBackcapDate),
                      }"
                      :class="{
                        'is-invalid': isSubmitted && $v.date.$error,
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.date.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.date.required">Date is required</span>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedPriceType">Price Type</label>
                    <select
                      id="selectedPriceType"
                      class="form-control"
                      name="selectedPriceType"
                      v-model="selectedPriceType"
                      @change="getPrice()"
                    >
                      <option :value="null">--- Select ---</option>
                      <option v-for="p in priceTypes" :value="p.id" :key="p.id">
                        {{ p.price_name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="loggedInUserGroupId === 'Admin'"
                >
                  <div class="form-group fc">
                    <label for="price">Price(Per MT)</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      id="price"
                      v-model="price"
                      @keyup="calculateValue()"
                      @keypress="isDecimal($event)"
                      :disabled="selectedPriceType != 5"
                      :class="{
                        'is-invalid':
                          (isSubmitted && $v.price.$error) ||
                          (selectedPriceType === 5 &&
                            (price === null || price == 0)),
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.price.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.price.decimal"
                        >Price should be decimal only<br
                      /></span>
                      <span v-if="!$v.price.maxLength"
                        >Max length of 10 digits exceeded</span
                      >
                    </div>
                    <div
                      v-if="
                        selectedPriceType === 5 &&
                        (price === null || price == 0)
                      "
                      class="invalid-feedback"
                    >
                      <span>Please enter a price.</span>
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="loggedInUserGroupId === 'Admin'"
                >
                  <div class="form-group fc">
                    <label for="value">Value</label>
                    <input
                      type="number"
                      class="form-control"
                      id="value"
                      v-model="value"
                      disabled
                    />
                  </div>
                </div> -->

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="releaseType" class="required-field"
                      >Release To</label
                    >
                    <select
                      id="selectedReleaseType"
                      class="form-control"
                      name="selectedReleaseType"
                      v-model="selectedReleaseType"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedReleaseType.$error,
                        }"
                      >
                      <!-- @change="onChangeReleaseType()" -->
                      <option :value="null">--- Select ---</option>
                      <option value="Release to Offtaker">
                        Release to Offtaker
                      </option>
                      <option value="Release to Market">
                        Release to Market
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedReleaseType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedReleaseType.required"
                        >Release To is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedOfftakerID" class="required-field"
                      >Offtaker Name</label
                    >
                    <select
                      id="selectedOfftakerID"
                      class="form-control"
                      v-model="selectedOfftakerID"
                      @change="getContractNumberByOfftakerName()"
                      :class="{
                        'is-invalid':
                        isSubmitted && $v.selectedOfftakerID.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="ofn in offtakerNameIDs"
                        :value="ofn.id"
                        :key="ofn.id"
                      >
                        {{ ofn.offtaker_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedOfftakerID.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedOfftakerID.required"
                        >Offtaker Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedContractNumber" class="required-field"
                      >Contract No.</label
                    >
                    <select
                      id="selectedContractNumber"
                      class="form-control"
                      v-model="selectedContractNumber"
                      @change="getContractNumberDetails()"
                      :class="{
                        'is-invalid':
                        isSubmitted && $v.selectedContractNumber.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                        <option
                          v-for="ofn in contractNumber"
                          :value="ofn.contractId"
                          :key="ofn.contractId"
                        >
                        {{ ofn.contractNo }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedContractNumber.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedContractNumber.required"
                        >Contract No. is required</span
                      >
                    </div>
                  </div>
                </div>

                <div v-if="(this.partialContractNumber.length > 0 && this.selectedContractNumber)" class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" :class="{'required-field':logisticsShippingFlag > 0}"
                      >Partial Contract No.</label
                    >
                    <select
                      id="selectedPartialContractNumber"
                      class="form-control"
                      name="selectedPartialContractNumber"
                      v-model="selectedPartialContractNumber"
                      @change="getPartialContractNumberDetails()"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedPartialContractNumber.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="cn in partialContractNumber"
                        :value="cn.partialContractId"
                        :key="cn.partialContractId"
                      >
                        {{ cn.partialContractNo }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedPartialContractNumber.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedPartialContractNumber.required"
                        >Partial Contract No. is required</span
                      >
                    </div>
                  </div>
                </div>
                
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="quantity" class="required-field"
                      >Quantity (MT)</label
                    >
                    <input
                      type="number"
                      class="form-control"
                      id="quantity"
                      v-model="quantity"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.quantity.$error,
                      }"
                      disabled
                    />
                    <div
                      v-if="isSubmitted && $v.quantity.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quantity.required"
                        >Quantity is required</span
                      >
                      <span v-if="!$v.quantity.decimal"
                        >Quantity should be decimal only<br
                      /></span>
                      <span v-if="!$v.quantity.maxLength"
                        >Max length of 10 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>
<!-- 
                <div v-if="this.selectedReleaseType === 'Release to Market'" class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="price" class="required-field">Price (Per MT)</label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder
                      id="price"
                      v-model="price"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.price.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.price.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.price.required"
                        >price is required</span
                      >
                      <span v-if="!$v.price.maxLength"
                        >Max length of 10 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div v-if="this.selectedReleaseType === 'Release to Market'" class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label class="required-field">Currency</label>
                    <select
                      id="selectedCurrency"
                      class="form-control"
                      v-model="selectedCurrency"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedCurrency.$error,
                      }"
                    >
                      <option :value="null">-- Select a Currency --</option>
                      <option
                        v-for="curr in currencies"
                        :value="curr.id"
                        :key="curr.id"
                      >
                        {{ curr.currency_short_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCurrency.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCurrency.required"
                        >Currency is required</span
                      >
                    </div>
                  </div>
                </div> -->


                <div class="col-md-12 text-right mb-3">
                  <button
                    type="button"
                    id="logisticsSaveInfo"
                    class="btn-sm btn btn-primary sh"
                    @click="onReleaseDetailSaveInfo()"
                  >
                    Save Info
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Container Info</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            id="containerAddMore"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addContainer() & setDate()"
                            :disabled="ContainerListAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <div
                    class="row"
                    id="containerRow"
                    v-for="(con, index) in ContainerList"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Container No.</label
                        >
                        <input
                          id="containerNo"
                          class="form-control"
                          name="containerNo"
                          v-model="con.containerNo"
                          @keyup="checkContainerNo(con.containerNo, index)"
                          :disabled="index < ContainerList.length - 1"
                          :class="{
                            'is-invalid':
                              (isSubmitted &&
                                $v.ContainerList.$each[index].containerNo
                                  .$error) ||
                              ContainerList[index].conExists,
                          }"
                        />
                        <div
                          v-if="
                            (isSubmitted &&
                              $v.ContainerList.$each[index].containerNo
                                .$error) ||
                            ContainerList[index].conExists
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].containerNo
                                .required
                            "
                            >Container No is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].containerNo
                                .maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                          <span v-if="ContainerList[index].conExists"
                            >Container No. already exists</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Released Qty (MT)</label
                        >
                        <input
                          type="number"
                          id="releasedQty"
                          class="form-control"
                          name="releasedQty"
                          v-model="con.releasedQty"
                          @keyup="calculateTotalReleasedQty()"
                          @keypress="isDecimal($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].releasedQty.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].releasedQty.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].releasedQty
                                .required
                            "
                            >Released Qty (MT) is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].releasedQty
                                .maxLength
                            "
                            >Max length of 10 digits exceeded<br
                          /></span>
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].releasedQty.decimal
                            "
                            >Released Qty (MT) should be decimal only</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Bags</label
                        >
                        <input
                          type="number"
                          id="bags"
                          class="form-control"
                          name="bags"
                          v-model="con.bags"
                          @keyup="calculateTotalBags()"
                          @keypress="isNoDecimal($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].bags.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].bags.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.ContainerList.$each[index].bags.required"
                            >Bags is required<br
                          /></span>
                          <span
                            v-if="!$v.ContainerList.$each[index].bags.maxLength"
                            >Max length of 10 digits exceeded<br
                          /></span>
                          <span
                            v-if="!$v.ContainerList.$each[index].bags.numeric"
                            >Bags should be numeric only</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Truck No.</label
                        >
                        <input
                          id="truckNo"
                          class="form-control"
                          name="truckNo"
                          v-model="con.truckNo"
                          @keypress="isAlphaNum($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].truckNo.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].truckNo.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].truckNo.required
                            "
                            >Truck No. is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].truckNo.maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Seal No.</label
                        >
                        <input
                          id="sealNo"
                          class="form-control"
                          name="sealNo"
                          v-model="con.sealNo"
                          @keypress="isAlphaNum($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].sealNo.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].sealNo.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].sealNo.required
                            "
                            >Seal No is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].sealNo.maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Date</label
                        >
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="date"
                          id="date"
                          placeholder="Select Date"
                          v-model="con.date"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          @input="setDate(index, con.date)"
                          :calendar-button="true"
                          :disabledDates="{
                            from: new Date(),
                            to: new Date(date),
                          }"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].date.$error,
                          }"
                        ></datepicker>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].date.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.ContainerList.$each[index].date.required"
                            >Date is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Gross Weight (KG)</label
                        >
                        <input
                          type="number"
                          id="grossWeight"
                          class="form-control"
                          name="grossWeight"
                          v-model="con.grossWeight"
                          @keyup="calculateTotalGrossWeight()"
                          @keypress="isDecimal($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].grossWeight.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].grossWeight.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].grossWeight
                                .required
                            "
                            >Gross Weight is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].grossWeight
                                .maxLength
                            "
                            >Max length of 10 digits exceeded<br
                          /></span>
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].grossWeight.decimal
                            "
                            >Gross Weight should be decimal only</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Net Weight (KG)</label
                        >
                        <input
                          type="number"
                          id="netWeight"
                          class="form-control"
                          name="netWeight"
                          v-model="con.netWeight"
                          @keyup="calculateTotalNetWeight()"
                          @keypress="isDecimal($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].netWeight.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].netWeight.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].netWeight.required
                            "
                            >Net Weight is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].netWeight.maxLength
                            "
                            >Max length of 10 digits exceeded<br
                          /></span>
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].netWeight.decimal
                            "
                            >Net Weight should be decimal only</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Tare Weight (KG)</label
                        >
                        <input
                          type="number"
                          id="tareWeight"
                          class="form-control"
                          name="tareWeight"
                          v-model="con.tareWeight"
                          @keypress="isDecimal($event)"
                          @keyup="calculateTotalTareWeight()"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].tareWeight.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].tareWeight.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].tareWeight.required
                            "
                            >Tare Weight is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].tareWeight
                                .maxLength
                            "
                            >Max length of 10 digits exceeded<br
                          /></span>
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].tareWeight.decimal
                            "
                            >Tare Weight should be decimal only</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >GDN</label
                        >
                        <input
                          id="gdnNo"
                          class="form-control"
                          name="gdnNo"
                          v-model="con.gdnNo"
                          @keypress="isAlphaNum($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].gdnNo.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].gdnNo.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.ContainerList.$each[index].gdnNo.required"
                            >GDN is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].gdnNo.maxLength
                            "
                            >Max length of 10 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="file">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            @change="
                              handleFileUploadContainerDocument(index, $event)
                            "
                          />
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ con.displayFileName }}</b>
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="con.errors">
                              {{ con.errors.filesize }}
                            </p>
                          </small>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          id="containerDelete"
                          v-on:click="deleteContainer(index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        id="containerSaveInfo"
                        class="btn-sm btn btn-primary sh"
                        @click="onContainerListSaveInfo()"
                        :disabled="isDisabledContainerListSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Total Released</h1>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="totalReleasedQty" class="required-field"
                          >Total Released Qty (MT)</label
                        >
                        <input
                          type="number"
                          id="totalReleasedQty"
                          class="form-control"
                          name="totalReleasedQty"
                          v-model="totalReleasedQty"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.totalReleasedQty.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.totalReleasedQty.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.totalReleasedQty.required"
                            >Total Released Qty is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Total Gross Weight (KG)</label
                        >
                        <input
                          type="number"
                          id="totalGrossWeight"
                          class="form-control"
                          name="totalGrossWeight"
                          v-model="totalGrossWeight"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.totalGrossWeight.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.totalGrossWeight.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.totalGrossWeight.required"
                            >Total Gross Weight is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Total Net Weight (KG)</label
                        >
                        <input
                          type="number"
                          id="totalNetWeight"
                          class="form-control"
                          name="totalNetWeight"
                          v-model="totalNetWeight"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.totalNetWeight.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.totalNetWeight.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.totalNetWeight.required"
                            >Total Net Weight Loaded is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Total Tare Weight (KG)</label
                        >
                        <input
                          type="number"
                          id="totalTareWeight"
                          class="form-control"
                          name="totalTareWeight"
                          v-model="totalTareWeight"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.totalTareWeight.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.totalTareWeight.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.totalTareWeight.required"
                            >Total Tare Weight Loaded is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Total Bags</label
                        >
                        <input
                          id="totalBags"
                          class="form-control"
                          name="totalBags"
                          v-model="totalBags"
                          disabled
                          :class="{
                            'is-invalid': isSubmitted && $v.totalBags.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.totalBags.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.totalBags.required"
                            >Total Bags is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Total Containers</label
                        >
                        <input
                          id="totalContainers"
                          class="form-control"
                          name="totalContainers"
                          v-model="totalContainers"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.totalContainers.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.totalContainers.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.totalContainers.required"
                            >Total Containers is required</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Documents</h1>
                    </div>
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addReleaseDoc()"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="card-body p-0">

                  <div
                    class="row"
                    v-for="(doc, index) in ReleaseDocuments"
                    :key="index"
                  >
                    <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            v-on:change="
                              handleFileUploadReleaseDocuments(index, $event)
                            "
                            :class="{
                              'is-invalid': doc.errors,
                            }"
                          />
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ doc.displayFileName }}</b>
                            </p>
                          </small>
                          <div v-if="doc.errors" class="invalid-feedback">
                            <span>{{ doc.errors }}</span>
                          </div>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Issue Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="IssueDate"
                          id="IssueDate"
                          placeholder="Select Date"
                          v-model="doc.IssueDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabledDates="{ from: new Date() }"
                          :calendar-button="true"
                        ></datepicker>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="comments">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="doc.comments"
                          id="comments"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ReleaseDocuments.$each[index].comments.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ReleaseDocuments.$each[index].comments.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ReleaseDocuments.$each[index].comments
                                .maxLength
                            "
                            >Max length of 100 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          v-on:click="deleteReleaseDocuments(index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        @click="onReleaseDocumentSaveInfo()"
                        :disabled="isDisabledReleaseDocumentSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    id="submit"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a @click="$router.go(-1)" style="cursor: pointer">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import Decimal from 'decimal.js';
import moment, { max } from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {
  required,
  maxLength,
  decimal,
  numeric,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      borrowers: [],
      facilities: [],
      collaterals: [],
      warehouses: [],
      offtakers: [],
      collateralStages: [],
      currencies: [],
      priceTypes: [],
      ContainerList: [],
      containers: [],
      mappedCM: [],
      cmWarehouses: [],
      selectedBorrower: null,
      selectedFacilityId: null,
      selectedCollateral: null,
      selectedCollateralType: null,
      quantity: null,
      selectedWarehouse: null,
      selectedCM: null,
      selectedPriceType: null,
      // selectedCurrency: null,
      // price: null,
      contractType: 'LogisticShipping',
      // contractType: 'Offtaker',
      logisticsShippingFlag: '0',
      offtakerNameIDs:[],
      contractNumber:[],
      partialContractNumber:[],
      selectedOfftakerID:null,
      selectedContractNumber:null,
      FinalContractQuantity:null,
      contracts: [],
      contractNumber: null,
      selectedPartialContractNumber:null,
      // selectedOfftakerName: null,
      // selectedOfftakerContract: null,
      selectedReleaseType: null,
      value: null,
      date: new Date(),
      releaseRequestSum: null,
      logisticsInfoBackcapDate: null,
      //variables for request information
      ContainerListDisabled: true,
      ContainerListSaveInfoDisabled: true, //save info button is disabled
      ContainerListAddMoreDisabled: true,
      ContainerDocumentFile: null,

      totalReleasedQty: null,
      totalGrossWeight: null,
      totalNetWeight: null,
      totalTareWeight: null,
      totalBags: null,
      totalContainers: null,

      // //list for Release Documents
      // ReleaseDocuments: [],
      // ReleaseDocumentsFile: null,
      // // ReleaseDocumentsButtonDisabled: true,
      // ReleaseDocumentsDisabled: true,
      // ReleaseDocumentsSaveInfoDisabled: true, //save info button is disabled
      // ReleaseDocumentsAddMoreDisabled: true,
      // error: "",
      isSubmitted: false,
    };
  },
  validations: {
    selectedBorrower: {
      required,
    },
    selectedFacilityId: {
      required,
    },
    selectedCollateral: {
      required,
    },
    selectedCollateralType: {
      required,
    },
    selectedWarehouse: {
      required,
    },
    selectedCM: {
      required,
    },
    selectedOfftakerID: {
      required,
    },
    selectedContractNumber: {
      required
    },
    selectedPartialContractNumber: {
      required: function () {
        if (this.selectedPartialContractNumber == null && this.logisticsShippingFlag > 0) return false;
        else return true;
      },
    },
    // price: {
    //   required: function () {
    //     if (
    //       this.selectedReleaseType === "Release to Market" &&
    //       (this.price == null || this.price == 0 || this.price == '')
    //     )
    //       return false;
    //     else return true;
    //   },
    //   maxLength: maxLength(10),
    // },
    // selectedCurrency: {
    //   required: function () {
    //     if (
    //       this.selectedReleaseType === "Release to Market" &&
    //       this.selectedCurrency === null
    //     )
    //       return false;
    //     else return true;
    //   },
    // },
    quantity: {
      required: function () {
        if (!this.quantity || this.quantity == 0) return false;
        else return true;
      },
      decimal,
      maxLength: maxLength(10),
    },
    date: {
      required,
    },
    selectedReleaseType: {
      required,
    },
    totalReleasedQty: {
      required,
    },
    totalGrossWeight: {
      required,
    },
    totalNetWeight: {
      required,
    },
    totalTareWeight: {
      required,
    },
    totalBags: {
      required,
    },
    totalContainers: {
      required,
    },
    ContainerList: {
      $each: {
        date: {
          required,
        },
        containerNo: {
          maxLength: maxLength(50),
          required,
        },
        truckNo: {
          maxLength: maxLength(50),
          required,
        },
        sealNo: {
          maxLength: maxLength(50),
          required,
        },
        releasedQty: {
          maxLength: maxLength(10),
          decimal,
          required: function (value) {
            if (!value || value == 0) return false;
            else return true;
          },
        },
        bags: {
          maxLength: maxLength(10),
          numeric,
          required: function (value) {
            if (!value || value == 0) return false;
            else return true;
          },
        },
        grossWeight: {
          maxLength: maxLength(10),
          decimal,
          required: function (value) {
            if (!value || value == 0) return false;
            else return true;
          },
        },
        netWeight: {
          maxLength: maxLength(10),
          decimal,
          required: function (value) {
            if (!value || value == 0) return false;
            else return true;
          },
        },
        tareWeight: {
          maxLength: maxLength(10),
          decimal,
          required: function (value) {
            if (!value || value == 0) return false;
            else return true;
          },
        },
        gdnNo: {
          maxLength: maxLength(10),
          required: function (value) {
            if (!value || value == 0) return false;
            else return true;
          },
        },
      },
    },
    // ReleaseDocuments: {
    //   $each: {
    //     comments: {
    //       maxLength: maxLength(100),
    //     },
    //   },
    // },
  },
  computed: {
    loggedInUserFullName() {
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },

    //this is to computed disabled property of ReleaseDocuments Save Info button.
    // isDisabledReleaseDocumentSaveInfo: function () {
    //   let checkEmptyReleaseDocumentsDocFileName = this.ReleaseDocuments.filter(
    //     (line) => line.displayFileName === null
    //   );
    //   //if the line is empty then disable save info button
    //   if (
    //     this.ReleaseDocuments.length === 0 &&
    //     this.ReleaseDocumentsSaveInfoDisabled === true
    //   ) {
    //     return true;
    //   }
    //   //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
    //   if (
    //     checkEmptyReleaseDocumentsDocFileName.length >= 1 &&
    //     this.ReleaseDocuments.length > 0
    //   ) {
    //     this.ReleaseDocumentsDisabled = true;
    //   } else {
    //     this.ReleaseDocumentsDisabled = false;
    //   }
    //   //ReleaseDocumentsDisabled variable is to check whther all input fields except file upload are filled. ReleaseDocumentsFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
    //   if (
    //     this.ReleaseDocumentsDisabled === true ||
    //     this.ReleaseDocumentsFile === null
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }, //isDisabledReleaseDocumentSaveInfo() close

    //this is to computed disabled property of ContainerLists Save Info button.
    isDisabledContainerListSaveInfo: function () {
      let checkEmptyContainerListDocName = this.ContainerList.filter(
        (line) => line.warrantRef === null
      );
      let checkEmptyContainerListDate = this.ContainerList.filter(
        (line) => line.containerNo === null
      );
      //if the line is empty then disable save info button
      if (
        this.ContainerList.length === 0 &&
        this.ContainerListSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyContainerListDocName.length >= 1 &&
          this.ContainerList.length > 0) ||
        (checkEmptyContainerListDate.length >= 1 &&
          this.ContainerList.length > 0)
      ) {
        this.ContainerListDisabled = true;
      } else {
        this.ContainerListDisabled = false;
      }
      //ContainerListDisabled variable is to check whther all input fields except file upload are filled. ContainerListFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.ContainerListDisabled === true ||
        this.ContainerListFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledContainerListSaveInfo() close
  },
  components: {
    Datepicker,
  },
  methods: {
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },
    isNoDecimal(e) {
      let invalidChars = [".","-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },
    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },
    calculateValue() {
      if (this.quantity) this.value = this.quantity * this.price;
      this.value = Number(this.value) == 0 ? null : this.value;
    },
    calculateTotalBags() {
      this.totalBags = new Decimal(0);
      this.ContainerList.map((con) => {
        if (con.bags) this.totalBags  = this.totalBags.plus(con.bags);
      });
    },
    calculateTotalReleasedQty() {
      this.totalReleasedQty = new Decimal(0);
      this.ContainerList.map((con) => {
        if (con.releasedQty) this.totalReleasedQty  = this.totalReleasedQty.plus(con.releasedQty);
      });
    },
    calculateTotalGrossWeight() {
      this.totalGrossWeight = new Decimal(0);
      this.ContainerList.map((con) => {
        if (con.grossWeight) this.totalGrossWeight = this.totalGrossWeight.plus(con.grossWeight);
      });
    },
    calculateTotalNetWeight() {
      this.totalNetWeight = new Decimal(0);
      this.ContainerList.map((con) => {
        if (con.netWeight) this.totalNetWeight = this.totalNetWeight.plus(con.netWeight);
      });
    },
    calculateTotalTareWeight() {
      this.totalTareWeight = new Decimal(0);
      this.ContainerList.map((con) => {
        if (con.tareWeight) this.totalTareWeight  = this.totalTareWeight.plus(con.tareWeight);
      });
    },

    checkContainerNo(num, i) {
      let exists = false;
      this.containers.map((con) => {
        if (con.container_number === num) exists = true;
      });
      if (exists) this.ContainerList[i].conExists = true;
      else if (
        this.ContainerList.filter((con) => con.containerNo === num).length > 1
      )
        this.ContainerList[i].conExists = true;
      else this.ContainerList[i].conExists = false;
    },
    // this fuction sets the release date of all containers to first container's release date
    setDate(i, date) {
      if (i === undefined) {
        this.ContainerList.filter((con) => {
          con.releaseDate = this.ContainerList[0].releaseDate;
        });
      }
      if (i === 0) {
        this.ContainerList.filter((con) => {
          con.releaseDate = date;
        });
      }
    },

    isAlphaNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },

    onWarehouseChange() {
      // this function is used to get mapped CM Companies for a particular Warehouse
      Utils.start_loading();
      const path = "get-mapped-cm/" + this.selectedWarehouse;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.mappedCM = res.data.warehouse_cm_mapping_list;
            if (this.loggedInUserGroupId === "Collateral Manager")
              this.selectedCM = this.loggedInUserSubGroupId;
            console.log(
              "get mapped CM Companies for a particular Warehouse is successful."
            );
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });

      if (
        this.selectedBorrower &&
        this.selectedFacilityId &&
        this.selectedCollateral &&
        this.selectedCollateralType &&
        this.selectedWarehouse
      ) {
        // this function is used to get logistics info backup date
        Utils.start_loading();
        const path =
          "get-logistics-info-backup-date/" +
          this.selectedBorrower +
          "/" +
          this.selectedFacilityId +
          "/" +
          this.selectedCollateral +
          "/" +
          this.selectedCollateralType +
          "/" +
          this.selectedWarehouse;
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            Utils.stop_loading();
            if (res.data.status == "success") {
              this.logisticsInfoBackcapDate = res.data.release_info_backup_date;
              console.log("get release info Backcap Date is successful.");
            } else {
              console.log("Something went wrong from server!");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
            console.log("Something went wrong!");
          });
      }
    },

    onReleaseDetailSaveInfo() {
      this.isSubmitted = true;
      this.$v.selectedBorrower.$touch();
      this.$v.selectedFacilityId.$touch();
      this.$v.selectedCollateral.$touch();
      this.$v.selectedCollateralType.$touch();
      this.$v.selectedWarehouse.$touch();
      this.$v.selectedCM.$touch();
      this.$v.selectedOfftakerID.$touch();
      this.$v.selectedContractNumber.$touch();
      this.$v.selectedPartialContractNumber.$touch();
      this.$v.quantity.$touch();
      if (
          this.$v.selectedBorrower.$invalid ||
          this.$v.selectedFacilityId.$invalid ||
          this.$v.selectedCollateral.$invalid ||
          this.$v.selectedCollateralType.$invalid ||
          this.$v.selectedWarehouse.$invalid ||
          this.$v.selectedCM.$invalid ||
          this.$v.selectedOfftakerID.$invalid ||
          this.$v.selectedContractNumber.$invalid ||
          this.$v.selectedPartialContractNumber.$invalid ||
          this.$v.quantity.$invalid
        ) {
          // Handle invalid fields (e.g., show an error message)
          return;
        }
        // if(this.logisticsShippingFlag == 2  && this.selectedPartialContractNumber == null){
        //   Utils.make_alert("success","Please map partial contract as this contract is already mapped in shipping");
        //   return;
        // }
      const path =
        "/get-release-request-sum/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacilityId +
        "/" +
        this.selectedCollateral +
        "/" +
        this.selectedCollateralType +
        "/" +
        this.selectedWarehouse +
        "/" +
        this.selectedCM;
        Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          this.releaseRequestSum = res.data.release_request_sum[0].quantity_sum;
          if (this.releaseRequestSum === null || this.releaseRequestSum == 0) {
            Utils.make_alert(
              "warning",
              "Kindly register Release Request first for the selected information or Approve the Pending Release Request for the given combination of Borrower, Facility ID, Collateral, Collateral type and Warehouse."
              );
            } else if (Number(this.quantity) > this.releaseRequestSum) {
            Utils.make_alert(
              "warning",
              `The selected contract Qty ${this.quantity} MT does not match with the Release request Qty ${this.releaseRequestSum} MT. <br/>
              Kindly add a new partial contract or, <br/>
              Select the contract / partial Contract with same Qty to continue with logistics transaction or, <br/>
              Generate a new release request `
              );
            } else {
            Utils.make_alert("success", "Record saved.");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this method will make disabled property as false for ContainerList Save Info
    onContainerListSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.ContainerListSaveInfoDisabled = false;
      this.ContainerListAddMoreDisabled = false;
    },

    // //this method will make disabled property as false for Request Save Info
    // onReleaseDocumentSaveInfo() {
    //   Utils.make_alert("success", "Record saved.");
    //   this.ReleaseDocumentsSaveInfoDisabled = false;
    //   this.ReleaseDocumentsAddMoreDisabled = false;
    // },

    //this function deletes ContainerList record
    deleteContainer(index) {
      this.ContainerList.splice(index, 1);
      if (
        typeof this.ContainerList !== "undefined" &&
        this.ContainerList.length == 0
      ) {
        this.totalReleasedQty = null;
        this.totalGrossWeight = null;
        this.totalNetWeight = null;
        this.totalTareWeight = null;
        this.totalBags = null;
        this.totalContainers = null;
        this.ContainerListAddMoreDisabled = false;
        this.ContainerListSaveInfoDisabled = true;
      }
      else{
        this.calculateTotalBags();
        this.calculateTotalReleasedQty();
        this.calculateTotalGrossWeight();
        this.calculateTotalNetWeight();
        this.calculateTotalTareWeight();
      }
      this.totalContainers = this.ContainerList.length;
    },

    //this function deletes RequestDocument record
    // deleteReleaseDocuments(index) {
    //   this.ReleaseDocumentsFile = this.ReleaseDocumentsFile
    //     ? this.ReleaseDocumentsFile
    //     : "file deleted";
    //   this.ReleaseDocuments.splice(index, 1);
    //   if (
    //     typeof this.ReleaseDocuments !== "undefined" &&
    //     this.ReleaseDocuments.length == 0
    //   ) {
    //     this.ReleaseDocumentsAddMoreDisabled = false;
    //     this.ReleaseDocumentsSaveInfoDisabled = true;
    //   }
    // },

    //this method will submit the Request form in backend.
    onSubmit() {
      this.isSubmitted = true;
      this.$v.selectedBorrower.$touch();
      this.$v.selectedFacilityId.$touch();
      this.$v.selectedCollateral.$touch();
      this.$v.selectedCollateralType.$touch();
      this.$v.selectedWarehouse.$touch();
      this.$v.selectedCM.$touch();
      this.$v.selectedOfftakerID.$touch();
      this.$v.selectedContractNumber.$touch();
      this.$v.selectedPartialContractNumber.$touch();
      this.$v.quantity.$touch();
      if (
          this.$v.selectedBorrower.$invalid ||
          this.$v.selectedFacilityId.$invalid ||
          this.$v.selectedCollateral.$invalid ||
          this.$v.selectedCollateralType.$invalid ||
          this.$v.selectedWarehouse.$invalid ||
          this.$v.selectedCM.$invalid ||
          this.$v.selectedOfftakerID.$invalid ||
          this.$v.selectedContractNumber.$invalid ||
          this.$v.selectedPartialContractNumber.$invalid ||
          this.$v.quantity.$invalid
        ) {
          // Handle invalid fields (e.g., show an error message)
          return;
        }
        // if(this.logisticsShippingFlag == 2 && this.selectedPartialContractNumber == null){
        //   Utils.make_alert("success","Please map partial contract as this contract is already mapped in shipping");
        //   return;
        // }
      const path =
        "/get-release-request-sum/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacilityId +
        "/" +
        this.selectedCollateral +
        "/" +
        this.selectedCollateralType +
        "/" +
        this.selectedWarehouse +
        "/" +
        this.selectedCM;
        Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          this.releaseRequestSum = res.data.release_request_sum[0].quantity_sum;
          if (this.releaseRequestSum === null || this.releaseRequestSum == 0) {
            Utils.make_alert(
              "warning",
              "Kindly register Release Request first for the selected information or Approve the Pending Release Request for the given combination of Borrower, Facility ID, Collateral, Collateral type and Warehouse."
            );
          } else if (Number(this.quantity) > this.releaseRequestSum) {
            Utils.make_alert(
              "warning",
              `The selected contract Qty ${this.quantity} MT does not match with the Release request Qty ${this.releaseRequestSum} MT. <br/>
              Kindly add a new partial contract or, <br/>
              Select the contract / partial Contract with same Qty to continue with logistics transaction or, <br/>
              Generate a new release request `
            );
          } else {
            this.$confirm({
              auth: false,
              message: "Confirm Submit?",
              button: {
                no: "No",
                yes: "Yes",
              },

              callback: (confirm) => {
                if (confirm) {
                  this.isSubmitted = true;
                  this.$v.$touch();
                  if (this.$v.$invalid) {
                    return;
                  }
                  // check if Sum of Release Qty of all containers equals to Total Qty
                  let totalReleaseQuantity = new Decimal(0);
                  let containerError = false;
                  this.ContainerList.map((con) => {
                      if (con.releasedQty) {
                        // Convert the releasedQty to a Decimal object before performing arithmetic operations
                        const releasedQtyDecimal = new Decimal(con.releasedQty);
                        totalReleaseQuantity = totalReleaseQuantity.plus(releasedQtyDecimal);
                      }

                      if (con.conExists) containerError = true;
                    });

                    // Now, totalReleaseQuantity contains the sum of all released quantities as a Decimal object
                    console.log("totalReleaseQuantity", totalReleaseQuantity.toString());

                    // Convert this.quantity to a Decimal object for comparison
                    const quantityDecimal = new Decimal(this.quantity);
                    console.log("this.quantity", quantityDecimal.toString());

                    // Check if totalReleaseQuantity is not equal to this.quantity
                    if (!totalReleaseQuantity.equals(quantityDecimal)) {
                        Utils.make_alert(
                        "warning",
                        "Sum of Release Qty of all containers should be equal to their Total Qty!",
                        () => {
                          $("#releasedQty.form-control").css(
                            "cssText",
                            "background-color: yellow !important;"
                          );
                        }
                      );
                      setTimeout(() => {
                        $("#releasedQty.form-control").css(
                          "cssText",
                          "background-color: #EEEEF0 !important;-webkit-transition: background-color 8000ms linear;-ms-transition: background-color 8000ms linear;transition: background-color 8000ms linear;"
                        );
                      }, 5000);
                      return;
                    }
                  if (containerError) {
                    return;
                  }
                  this.date = moment(this.date).format("MM-DD-YYYY");
                  for (let i = 0; i < this.ContainerList.length; i++) {
                    if (this.ContainerList[i].date === null) {
                      this.ContainerList.splice(i, 1);
                    } else {
                      if (this.ContainerList[i].date !== null) {
                        this.ContainerList[i].date = moment(
                          this.ContainerList[i].date
                        ).format("MM-DD-YYYY");
                      }
                    }
                  }
                  //initialize the form data
                  let formData = new FormData();
                  formData.append("borrowerId", this.selectedBorrower);
                  formData.append("facilityId", this.selectedFacilityId);
                  formData.append("collateralId", this.selectedCollateral);
                  formData.append("selectedCollateralType",this.selectedCollateralType);
                  formData.append("quantity", this.quantity);
                  formData.append("warehouseId", this.selectedWarehouse);
                  formData.append("selectedCM", this.selectedCM);
                  formData.append("selectedPriceType", this.selectedPriceType);
                  formData.append("date", this.date);
                  formData.append("value", this.value);
                  formData.append("selectedReleaseType",this.selectedReleaseType);
                  formData.append("selectedOfftakerName",this.selectedOfftakerID);
                  formData.append("selectedOfftakerID",this.selectedOfftakerID);
                  // formData.append("price", this.price);
                  // formData.append("selectedCurrency", this.selectedCurrency);
                  formData.append("selectedContractNumber",this.selectedContractNumber);
                  formData.append("selectedPartialContractNumber",this.selectedPartialContractNumber);
                  formData.append("totalGrossWeight", this.totalGrossWeight);
                  formData.append("totalNetWeight", this.totalNetWeight);
                  formData.append("totalTareWeight", this.totalTareWeight);
                  formData.append("totalBags", this.totalBags);
                  formData.append("totalContainers", this.totalContainers);
                  formData.append(
                    "containerDetails",
                    JSON.stringify(this.ContainerList)
                  );
                  formData.append("clientId", this.$store.state.client_id);
                  formData.append("userId", this.$store.getters.getuserId);
                  formData.append("moduleId", 10);
                  formData.append("tabId", 27);
                  const path = "add-release-info";
                  Utils.start_loading();
                  axios
                    .post(path, formData, {
                      headers: {
                        enctype: "multipart/form-data",
                      },
                    })
                    .then((response) => {
                      Utils.stop_loading();
                      console.log("status: " + response.data.status);
                      if (response.data.status == "success") {
                        Utils.make_alert(
                          "success",
                          "Logistics Info added successfully!"
                        );
                        setTimeout(() => this.$router.push("/logistics"), 1500);
                        console.log("SUCCESS!!");
                      } else {
                        Utils.make_alert("warning", "Something went wrong!");
                      }
                    })
                    .catch((error) => {
                      Utils.stop_loading();
                      console.error(error);
                    });
                }
              },
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, //onSubmit() close

    //this method will add new line for additional Container
    addContainer() {
      //when new line is added then ContainerListSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.ContainerListSaveInfoDisabled = true),
        (this.ContainerListAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.ContainerList.push({
          id: null,
          date: new Date(),
          containerNo: null,
          truckNo: null,
          sealNo: null,
          releasedQty: null,
          grossWeight: null,
          netWeight: null,
          tareWeight: null,
          gdnNo: null,
          displayFileName: null,
          errors: { filesize: "" },
          conExists: false,
        });
      this.totalContainers = this.ContainerList.length;
    }, //addReleaseDoc() close

    //this method will add new line for additional Collateral Documents
    // addReleaseDoc() {
    //   //when new line is added then ReleaseDocumentsFile variable and ReleaseDocumentsSaveInfoDisabled (Save info button disable property) should be re initialised to null.
    //   (this.ReleaseDocumentsFile = null),
    //     (this.ReleaseDocumentsSaveInfoDisabled = true),
    //     (this.ReleaseDocumentsAddMoreDisabled = true),
    //     //following block is created first time on mounted property
    //     this.ReleaseDocuments.push({
    //       id: null,
    //       IssueDate: new Date(),
    //       comments: null,
    //       displayFileName: null,
    //       errors: "",
    //     });
    // }, //addReleaseDoc() close

    //handles a change on the file upload
    // handleFileUploadReleaseDocuments(id, e) {
    //   let allowedExtensions =
    //     /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
    //   if (!allowedExtensions.exec(e.target.files[0].name)) {
    //     Utils.make_alert("warning", "Invalid File Type");
    //   } else {
    //     let self = this;
    //     let reader = new FileReader();
    //     this.ReleaseDocumentsFile = e.target.files[0];

    //     //check for filesize. if it is larger than threshold then return following error response
    //     if (this.ReleaseDocumentsFile.size > 104857600) {
    //       e.preventDefault();
    //       self.ReleaseDocuments[id].errors =
    //         "File size should be less than 100MB";
    //       self.ReleaseDocuments[id].selectedFile = null;
    //       self.ReleaseDocuments[id].selectedFileName = null;
    //       self.ReleaseDocuments[id].displayFileName = null;
    //       return;
    //     }

    //     reader.readAsDataURL(this.ReleaseDocumentsFile);
    //     console.log("id: " + id);
    //     reader.onload = function () {
    //       self.ReleaseDocuments[id].selectedFile = reader.result;
    //       self.ReleaseDocuments[id].selectedFileName = e.target.files[0].name;
    //       self.ReleaseDocuments[id].displayFileName = e.target.files[0].name; //to show filename in file input
    //       self.ReleaseDocuments[id].errors = "";
    //     };
    //     reader.onerror = function (error) {
    //       console.log("Error: ", error);
    //     };
    //   }
    // }, //handleFileUploadReleaseDocuments() close

    handleFileUploadContainerDocument(id, e) {
      let file_list = this.ContainerList.map((file) => file.displayFileName);
      // check if file already exists for same document name, show pop if true and delete record
      if (file_list.includes(e.target.files[0].name)) {
        this.deleteContainer(this.ContainerList.length - 1);
        Utils.make_alert("warning", "File Already Exists");
        return;
      }
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.ContainerDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.ContainerDocumentFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors.filesize = "File size should be less than 100MB";
          self.ContainerList[id].errors.filesize = JSON.stringify(
            response.errors.filesize
          ).replace(/\"/g, "");
          self.ContainerList[id].selectedFile = null;
          self.ContainerList[id].selectedFileName = null;
          self.ContainerList[id].displayFileName = null;
          return;
        }

        reader.readAsDataURL(this.ContainerDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.ContainerList[id].selectedFile = reader.result;
          self.ContainerList[id].selectedFileName = e.target.files[0].name;
          self.ContainerList[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.ContainerList[id].errors.filesize = "";
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadContainerDocument() close

    // this function will be called to get list of containers
    getContainers() {
      const path = "release-container-details";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.containers = res.data.release_container_details_list;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFacilitySetupByBorrower() {
      //this function is called to get list of day count
      const path = "/facility-setup-borrower/" + this.selectedBorrower;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.selectedFacilityId = null;
          this.facilities = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getOfftakerNameByBorrower() {
      console.log("inside get getOfftakerNameByBorrower");
      // if(this.selectedReleaseType == "Release to Offtaker"){
        // const path ="/get-offtaker-name-by-borrower/" + this.selectedBorrower;
        const path = 
          "/get-offtaker-name-by-borrower" + 
          "/" + 
          this.selectedBorrower +
          "/" +
          null +
          "/" +
          'Offtaker';
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then(res => {
            this.selectedOfftakerID = null;
            this.selectedContractNumber = null;
            this.selectedPartialContractNumber = null;
            this.quantity = null;
            this.partialContractNumber = [];
            this.offtakerNameIDs = res.data.offtaker_names;
            // this.getContractNumberByOfftakerName()
          })
          .catch(error => {
            console.error(error);
          });
      // }
    },

    getContractNumberByOfftakerName() {
      //this function is called to get list of LTV
      console.log("inside get getContractNumberByOfftakerName")
      // const path = "/get-contract-no-by-offtaker/" + this.selectedOfftakerID + "/" + this.selectedBorrower;
      if(this.selectedOfftakerID == null) {
          this.selectedContractNumber = null;
          this.contractNumber = [];
          this.selectedPartialContractNumber = null;
          this.partialContractNumber = [];
          this.quantity = null;
          this.logisticsShippingFlag = '0';
          return
      }
      const path = 
        "/get-contract-no-by-offtaker" + 
        "/" + 
        this.selectedOfftakerID +
        "/" + 
        this.selectedBorrower +
        "/" +
        null +
        "/" +
        this.contractType;
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then(res => {
            this.contractNumber =  res.data.contract_numbers;
          })
          .catch(error => {
            console.error(error);
          });
    },

     //this function is called to get list of contract numbers
    getContractNumberDetails() {
      console.log("inside get getContractNumberDetails")
      if(this.selectedContractNumber == null){
        this.selectedPartialContractNumber = null;
        this.partialContractNumber = [];
        this.PartialContractDate = null;
        this.quantity = null;
        this.logisticsShippingFlag = '0';
        return
      }
      let selectedContract = this.contractNumber.find(contract => contract.contractId === this.selectedContractNumber);
      console.log("selectedContract",selectedContract)
      this.quantity = selectedContract.ContractQuantity;
      this.logisticsShippingFlag = selectedContract.logistics_shipping_flag;
      if(this.quantity == 0){
        this.logisticsShippingFlag = '1';
      }

      // if(this.selectedPartialContractNumber != null ){
      Utils.start_loading();
      const path = 
        "/get-partial-contract-no" + 
        "/" + 
        this.selectedContractNumber +
        "/" +
        null +
        "/" +
        this.contractType;
        axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          Utils.stop_loading();
          console.log("status",res.data.status)
          if(res.data.status == "success") {
            this.partialContractNumber = res.data.partial_contracts;
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          console.error(error);
        });
      // }
    },

    //this function is called to get list of contract numbers
    getPartialContractNumberDetails() {
      console.log("inside get getPartialContractNumberDetails")
      if(this.selectedPartialContractNumber == null){
        let selectedContract = this.contractNumber.find(contract => contract.contractId === this.selectedContractNumber);
        console.log("selectedContract",selectedContract)
        this.quantity = selectedContract.ContractQuantity;
        this.logisticsShippingFlag = selectedContract.logistics_shipping_flag;
        return;
      }
      let selectedPartialContract = this.partialContractNumber.find(partialContract => partialContract.partialContractId === this.selectedPartialContractNumber);
      this.quantity = selectedPartialContract.PartialContractQuantity;
    },

    getWarehouseByBorrower(id) {
      this.selectedWarehouse = null;
      //this function is called to get list of warehouses
      const path = "/warehouse-borrower/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.warehouses = res.data.warehouses;
          if (this.loggedInUserGroupId === "Collateral Manager") {
            this.warehouses = this.warehouses.filter((war) =>
              this.cmWarehouses.includes(war.id)
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCollateral(id) {
      //this function is called to get list of collaterals by facility id
      const path = "/get-collateral/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.collaterals = res.data.collaterals;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of collateral stages
    getCollateralStages() {
      const path = "collateral-stage-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.collateralStages = res.data.collateral_stages;
          this.collateralStages = this.collateralStages.slice(0, 4);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of price types
    // getPriceTypes() {
    //   const path = "price-type-master";
    //   axios
    //     .get(path)
    //     .then((res) => {
    //       this.priceTypes = res.data.price_types;
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },

    //this function is called to get price by price type and collateral id
    // getPrice() {
    //   if (this.selectedPriceType !== 5 && this.selectedCollateral !== null) {
    //     const path =
    //       "/get-price/" +
    //       this.selectedPriceType +
    //       "/" +
    //       this.selectedCollateral;
    //     axios
    //       .get(path)
    //       .then((res) => {
    //         this.price = res.data.price;
    //         this.calculateValue();
    //       })
    //       .catch((error) => {
    //         console.error(error);
    //       });
    //   }
    // },

    onChangeReleaseType(){
      if(this.selectedReleaseType === 'Release to Offtaker'){
        this.selectedCurrency = null;
        this.price = null;
      }
    },

    //this function will be called to get list of offtakers
    // getBuyers() {
    //   if(this.selectedReleaseType == "Release to Market"){
    //     const path = "buyer";
    //     axios
    //       .get(path, { params: { clientId: this.$store.state.client_id } })
    //       .then((res) => {
            
    //         this.offtakers = res.data.Buyer;
    //         this.offtakerNameIDs = res.data.Buyer;
    //         this.offtakerNameIDs.filter((bor) => {
    //           if (bor.offtaker_name.toString() === 'General Offtaker')
    //           this.selectedOfftakerID = bor.id;
    //         });
    //       })
    //       .catch((error) => {
    //         console.error(error);
    //       });
    //   }
    // },

    //this function will be called to get list of offtakers
    getWarehouseBorrowerByCM(collateral_manager_id) {
      const path = "get-warehouse-borrower-by-cm/" + collateral_manager_id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.borrowers = res.data.borrower_list;
          this.cmWarehouses = res.data.warehouse_list;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function will be called to get list of borrowers
    getBorrowers() {
      const path = "borrower";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.borrowers = res.data.borrowers_active;
          //get Approved borrowers
          if (res.data.borrowers_active) {
            this.borrowers = this.borrowers.filter((bor) => {
              if (bor.status === "Approved") {
                return bor;
              }
            });
          }
          if (res.data.status == "success") {
            console.log("get borrowers is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },
  },

  mounted() {
    this.addContainer();
  },

  created() {
    // let data = this.$route.params;
    // if (Object.keys(data).length === 0) {
    // // If data is an empty object
    //   console.log('Data is empty');
    //   this.selectedReleaseType = "Release to Offtaker";
    // } else {
    //   // If data is not empty
    //   this.selectedReleaseType = data.type
    // }
    if (this.loggedInUserGroupId === "Collateral Manager") {
      this.getWarehouseBorrowerByCM(this.loggedInUserSubGroupId);
    } else {
      this.getBorrowers();
    }
    this.getContainers();
    this.getCollateralStages();
    // this.getBuyers();
    this.getCurrencies();
  },
};
</script>

<style scoped>
.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
.vdp-datepicker >>> .form-control[disabled] {
  background-color:#EEEEEE !important;
  opacity: 0.7;
  pointer-events: none !important;
  cursor: not-allowed !important;
}


.error {
  color: red;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -70px;
}
</style>
