<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Add Offtaker
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="offtakerName" class="required-field"
                      >Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="offtakerName"
                      @keypress="isAlphaNum($event)"
                      @keyup="checkOfftakerName()"
                      :class="{
                        'is-invalid':
                          (isSubmitted && $v.offtakerName.$error) ||
                          offtakerExists,
                      }"
                      id="offtakerName"
                      name="offtakerName"
                    />
                    <div
                      v-if="
                        (isSubmitted && $v.offtakerName.$error) ||
                        offtakerExists
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.offtakerName.required"
                        >Name is required</span
                      >
                      <span v-if="!$v.offtakerName.maxLength"
                        >Max length of 100 characters exceeded</span
                      >
                      <span v-if="offtakerExists"
                        >Offtaker Name already exists!</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="incorporationDate" class="required-field"
                      >Incorporation Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="incorporationDate"
                      id="incorporationDate"
                      placeholder="Select Date"
                      v-model="incorporationDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.incorporationDate.$error,
                      }"
                      :disabledDates="{ from: new Date() }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.incorporationDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.incorporationDate.required"
                        >Incorporation Date is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="address" class="required-field">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="address"
                      :class="{
                        'is-invalid': isSubmitted && $v.address.$error,
                      }"
                      id="address"
                      name="address"
                    />
                    <div
                      v-if="isSubmitted && $v.address.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.address.required"
                        >Address is required</span
                      >
                      <span v-if="!$v.address.maxLength"
                        >Max length of 250 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="selectedCountry" class="required-field"
                      >Country</label
                    >
                    <select
                      id="selectedCountry"
                      class="form-control"
                      name="selectedCountry"
                      v-model="selectedCountry"
                      @change="getStatesByCountry()"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedCountry.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="country in countries"
                        :key="country.id"
                        :value="country.id"
                      >
                        {{ country.country_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCountry.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCountry.required"
                        >Country is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label
                      class="required-field"
                      >State</label>
                    <select
                      id="selectedState"
                      class="form-control"
                      name="selectedState"
                      v-model="selectedState"
                      @change="getCitiesByState()"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedState.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="state in states"
                        :key="state.id"
                        :value="state.id"
                      >
                        {{ state.state_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedState.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedState.required"
                        >State is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="selectedCity" class="required-field"
                      >City</label
                    >
                    <select
                      id="selectedCity"
                      class="form-control"
                      name="selectedCity"
                      v-model="selectedCity"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedCity.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="city in cities"
                        :key="city.id"
                        :value="city.id"
                      >
                        {{ city.city_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCity.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCity.required"
                        >City is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="website" class="required-field">Website</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="website"
                      id="website"
                      name="website"
                      :class="{
                        'is-invalid':
                          (isSubmitted && $v.website.$error) || msg.website,
                      }"
                    />
                    <div class="invalid-feedback" v-if="msg.website">
                      <span>{{ msg.website }}</span>
                    </div>
                    <div
                      v-if="isSubmitted && $v.website.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.website.required"
                        >Website is required</span
                      >
                      <span v-if="!$v.website.maxLength"
                        >Max length of 100 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="regDate" class="required-field"
                      >Registration Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="regDate"
                      id="regDate"
                      placeholder="Select Date"
                      v-model="regDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{ from: new Date() }"
                      :class="{
                        'is-invalid': isSubmitted && $v.regDate.$error,
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.regDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.regDate.required"
                        >Registration Date is required</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Contact Details</h1>
                    </div>
                  </div>
                </div>
                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->
                  <div class="row">
                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="phoneNumber" class="required-field"
                          >Contact No</label
                        ><br />
                        <select
                          id="phoneNumberCode"
                          class="contact_code"
                          name="phoneNumberCode"
                          v-model="phoneNumberCode"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.phoneNumberCode.$error,
                          }"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="code in countryCodes"
                            :key="code.id"
                            :value="code.id"
                          >
                            {{ code.country_code }}
                          </option>
                        </select>
                        <input
                          class="contact"
                          id="phoneNumber"
                          placeholder
                          v-model="phoneNumber"
                          @keypress="isNum($event)"
                          :class="{
                            'is-invalid': isSubmitted && $v.phoneNumber.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.phoneNumberCode.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.phoneNumberCode.required"
                            >Contact Number Code is required</span
                          >
                        </div>
                        <div
                          v-if="isSubmitted && $v.phoneNumber.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.phoneNumber.required"
                            >Contact Number is required</span
                          >
                          <span v-if="!$v.phoneNumber.minLength"
                            >Min length of 10 characters required</span
                          >
                          <span v-if="!$v.phoneNumber.maxLength"
                            >Max length of 13 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="phoneNumber">Secondary Contact No</label
                        ><br />
                        <select
                          id="secondaryPhoneNumberCode"
                          class="contact_code"
                          name="secondaryPhoneNumberCode"
                          v-model="secondaryPhoneNumberCode"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.secondaryPhoneNumberCode.$error,
                          }"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="code in countryCodes"
                            :key="code.id"
                            :value="code.id"
                          >
                            {{ code.country_code }}
                          </option>
                        </select>
                        <input
                          class="contact"
                          id="secondaryPhoneNumber"
                          placeholder
                          v-model="secondaryPhoneNumber"
                          @keypress="isNum($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.secondaryPhoneNumber.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted && $v.secondaryPhoneNumberCode.$error
                          "
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.secondaryPhoneNumberCode.required"
                            >Secondary Contact Number Code is required</span
                          >
                        </div>
                        <div
                          v-if="isSubmitted && $v.secondaryPhoneNumber.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.secondaryPhoneNumber.required"
                            >Secondary Contact Number is required</span
                          >
                          <span v-if="!$v.secondaryPhoneNumber.minLength"
                            >Min length of 10 characters required</span
                          >
                          <span v-if="!$v.secondaryPhoneNumber.maxLength"
                            >Max length of 13 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="email" class="required-field"
                          >Email ID</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          placeholder
                          v-model="email"
                          id="email"
                          name="email"
                          :class="{
                            'is-invalid':
                              (isSubmitted && $v.email.$error) || msg.email,
                          }"
                        />
                        <div class="invalid-feedback" v-if="msg.email">
                          <span>{{ msg.email }}</span>
                        </div>
                        <div
                          v-if="isSubmitted && $v.email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.email.required"
                            >Email ID is required</span
                          >
                          <span v-if="!$v.email.maxLength"
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="secondaryEmail">Secondary Email ID</label>
                        <input
                          type="email"
                          class="form-control"
                          placeholder
                          v-model="secondaryEmail"
                          id="secondaryEmail"
                          name="secondaryEmail"
                          :class="{
                            'is-invalid':
                              (isSubmitted && $v.secondaryEmail.$error) ||
                              msg.secondaryEmail,
                          }"
                        />
                        <div class="invalid-feedback" v-if="msg.secondaryEmail">
                          <span>{{ msg.secondaryEmail }}</span>
                        </div>
                        <div
                          v-if="isSubmitted && $v.secondaryEmail.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.secondaryEmail.maxLength"
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- until here -->
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addOfftaker"
                            :disabled="OfftakerAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(off, index) in OfftakerList"
                    :key="index"
                  >
                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label class="required-field">Document</label>
                        <select
                          id="OfftakerDocName"
                          class="form-control"
                          name="OfftakerDocName"
                          v-model="off.OfftakerDocName"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.OfftakerList.$each[index].OfftakerDocName
                                .$error,
                          }"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="document in offtakerDocuments"
                            :key="document.id"
                            :value="document.document_name"
                          >
                            {{ document.document_name }}
                          </option>
                        </select>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.OfftakerList.$each[index].OfftakerDocName.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.OfftakerList.$each[index].OfftakerDocName
                                .required
                            "
                            >Document Name is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @mouseenter="mouseenter(off.OfftakerDocName, index)"
                        @mouseleave="mouseleave(index)"
                      >
                        <label for="exampleInputEmail1" class="required-field"
                          >Upload Document</label
                        >
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            :disabled="off.OfftakerDocName === null"
                            @change="handleFileUploadOfftaker(index, $event)"
                            :class="{
                              'is-invalid':
                                (isSubmitted && off.displayFileName === null) ||
                                off.errors.filesize ||
                                off.errors.docName,
                            }"
                          />
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ off.displayFileName }}</b>
                              <small>
                                <u
                                  style="color: blue; cursor: pointer"
                                  v-if="off.OfftakerRemoveDisabled === false"
                                  @click="removeOfftakerDoc(index)"
                                  >Remove</u
                                >
                              </small>
                            </p>
                          </small>
                          <div
                            v-if="off.errors.filesize"
                            class="invalid-feedback"
                          >
                            <span>{{ off.errors.filesize }}</span>
                          </div>
                          <div
                            v-if="off.errors.docName"
                            class="invalid-feedback"
                          >
                            <span>{{ off.errors.docName }}</span>
                          </div>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                          <div
                            v-if="isSubmitted && off.displayFileName === null"
                            class="invalid-feedback"
                          >
                            <span>Document is required</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div
                        class="form-group fc"
                        @click="docValidation(off.displayFileName, 'issueDate')"
                      >
                        <label for="exampleInputEmail1">Issue Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="UploadingDate"
                          id="UploadingDate"
                          placeholder="Select Date"
                          v-model="off.UploadingDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          :disabledDates="{
                            from: new Date(),
                          }"
                          :disabled="off.displayFileName === null"
                          :class="{
                            'is-invalid':
                              docIssueDateValidation &&
                              off.displayFileName === null,
                          }"
                        ></datepicker>
                        <div
                          v-if="off.displayFileName === null"
                          class="invalid-feedback"
                        >
                          <span v-if="docIssueDateValidation"
                            >Please upload the Document</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div
                        class="form-group fc"
                        @click="
                          docValidation(off.displayFileName, 'expiryDate')
                        "
                      >
                        <label for="exampleInputEmail1">Expiry Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="ExpiryDate"
                          id="ExpiryDate"
                          placeholder="Select Date"
                          v-model="off.ExpiryDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          :disabledDates="{
                            to: new Date(off.UploadingDate),
                          }"
                          :disabled="off.displayFileName === null"
                          :class="{
                            'is-invalid':
                              docExpiryDateValidation &&
                              off.displayFileName === null,
                          }"
                        ></datepicker>
                        <div
                          v-if="off.displayFileName === null"
                          class="invalid-feedback"
                        >
                          <span v-if="docExpiryDateValidation"
                            >Please upload the Document</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          id="OfftakerComments"
                          placeholder
                          v-model="off.comments"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.OfftakerList.$each[index].comments.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.OfftakerList.$each[index].comments.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.OfftakerList.$each[index].comments.maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          @click="deleteOfftaker(index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        @click="onOfftakerSaveInfo()"
                        :disabled="isDisabledOfftakerSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    @click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a @click="$router.go(-1)" style="cursor: pointer">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>

  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js";
import { required, maxLength, minLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      offtakerDocuments: [],
      offtakers: [],
      //variables for offtaker information
      offtakerName: null,
      address: null,
      website: null,
      incorporationDate: null,
      regDate: new Date(), //current day date
      selectedCountry: null,
      selectedState: null,
      selectedCity: null,
      phoneNumber: null,
      phoneNumberCode: null,
      secondaryPhoneNumberCode: null,
      secondaryPhoneNumber: null,
      email: null,
      secondaryEmail: null,
      countries: [],
      states: [],
      cities: [],
      countryCodes: [],

      //list for offtaker documents
      OfftakerList: [],
      OfftakerFile: null,
      OfftakerDisabled: true,
      OfftakerSaveInfoDisabled: true, //save info button is disabled
      OfftakerAddMoreDisabled: true,
      docIssueDateValidation: false,
      docExpiryDateValidation: false,

      msg: {},
      error: "",
      isSubmitted: false,
      offtakerExists: false,
    };
  },
  validations: {
    offtakerName: {
      maxLength: maxLength(100),
      required,
    },
    incorporationDate: {
      required,
    },
    address: {
      required,
      maxLength: maxLength(250),
    },
    selectedCountry: {
      required,
    },
    selectedState: {
      required,
    },
    selectedCity: {
      required,
    },
    phoneNumber: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(13),
    },
    phoneNumberCode: {
      required,
    },
    secondaryPhoneNumber: {
      minLength: minLength(10),
      maxLength: maxLength(13),
      required: function () {
        if (this.secondaryPhoneNumberCode && !this.secondaryPhoneNumber)
          return false;
        else return true;
      },
    },
    secondaryPhoneNumberCode: {
      required: function () {
        if (!this.secondaryPhoneNumberCode && this.secondaryPhoneNumber)
          return false;
        else return true;
      },
    },
    website: {
      maxLength: maxLength(100),
      required,
    },
    email: {
      required,
      maxLength: maxLength(50),
    },
    secondaryEmail: {
      maxLength: maxLength(50),
    },
    regDate: {
      required,
    },
    OfftakerList: {
      $each: {
        OfftakerDocName: {
          required,
        },
        comments: {
          maxLength: maxLength(50),
        },
      },
    },
  },
  watch: {
    email(value) {
      // binding this to the data value in the email input
      this.email = value;
      this.validateEmail(value);
    },
    secondaryEmail(value) {
      // binding this to the data value in the email input
      this.secondaryEmail = value;
      this.validateEmail(value, "secondary");
    },
    website(value) {
      // binding this to the data value in the website input
      this.website = value;
      this.validateWebsite(value);
    },
  },
  computed: {
    //this is to computed disabled property of Offtaker Save Info button.
    isDisabledOfftakerSaveInfo: function () {
      let checkEmptyOfftakerDocName = this.OfftakerList.filter(
        (line) => line.OfftakerDocName === null
      );
      if (
        this.OfftakerList.length === 0 &&
        this.OfftakerSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyOfftakerDocName.length >= 1 &&
        this.OfftakerList.length > 0
      ) {
        this.OfftakerDisabled = true;
      } else {
        this.OfftakerDisabled = false;
      }
      //OfftakerDisabled variable is to check whther all input fields except file upload are filled. OfftakerFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (this.OfftakerDisabled === true || this.OfftakerFile === null) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledOfftakerSaveInfo() close
    loggedInUserFullName() {
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  }, //main computed close
  components: {
    Datepicker,
  },
  methods: {
    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isAlpha(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isAlphaNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    // check if offtaker already exists
    checkOfftakerName() {
      if (
        this.offtakers.filter((off) => off.offtaker_name === this.offtakerName)
          .length
      ) {
        this.offtakerExists = true;
      } else this.offtakerExists = false;
    },
    validateEmail(value, type) {
      if (type === "secondary") {
        if (/^\w+([\.-]\w+)*@\D\w+\.(\D{2,3})$/.test(value) || value === "") {
          this.msg["secondaryEmail"] = "";
        } else {
          this.msg["secondaryEmail"] = "Invalid Email Address";
        }
      } else {
        if (/^\w+([\.-]\w+)*@\D\w+\.(\D{2,3})$/.test(value)) {
          this.msg["email"] = "";
        } else {
          this.msg["email"] = "Invalid Email Address";
        }
      }
    },
    validateWebsite(value) {
      if (/^(www\.)([A-Za-z0-9-])+(\.\D{2,3})$/.test(value) || value === "") {
        this.msg["website"] = "";
      } else {
        this.msg["website"] = "Invalid Website";
      }
    },

    //this method will make disabled property as false for Documents Save Info
    onOfftakerSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.OfftakerSaveInfoDisabled = false;
      this.OfftakerAddMoreDisabled = false;
    },

    deleteOfftaker(index) {
      this.OfftakerFile = this.OfftakerFile
        ? this.OfftakerFile
        : "file deleted";
      this.OfftakerList.splice(index, 1);
      if (
        typeof this.OfftakerList !== "undefined" &&
        this.OfftakerList.length == 0
      ) {
        this.OfftakerAddMoreDisabled = false;
        this.OfftakerSaveInfoDisabled = true;
      }
    },

    // document upload validation
    mouseenter(docName, index) {
      if (docName === null) {
        this.OfftakerList[index].errors.docName =
          "Document name is not selected";
      }
    },
    mouseleave(index) {
      this.OfftakerList[index].errors.docName = "";
    },
    // issue & expiry date doc validation
    docValidation(doc, type) {
      if (type === "issueDate") {
        this.docIssueDateValidation = doc === null;
      } else if (type === "expiryDate") {
        this.docExpiryDateValidation = doc === null;
      }
    },

    //this method will submit the offtaker form in backend.
    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      // checking for vuelidate validations & email and website validations
      if (
        this.$v.$invalid ||
        this.offtakerExists ||
        !Object.values(this.msg).every((e) => e === "")
      ) {
        return;
      }
      if (this.incorporationDate != null) {
        this.incorporationDate = moment(this.incorporationDate).format(
          "MM-DD-YYYY"
        );
      }
      if (this.regDate != null) {
        this.regDate = moment(this.regDate).format("MM-DD-YYYY");
      }
      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("OfftakerName", this.offtakerName);
      formData.append("Address", this.address);
      formData.append("selectedCountry", this.selectedCountry);
      formData.append("contact", this.phoneNumber);
      formData.append("contactCode", this.phoneNumberCode);
      formData.append("secondaryContact", this.secondaryPhoneNumber);
      formData.append("secondaryContactCode", this.secondaryPhoneNumberCode);
      formData.append("Website", this.website);
      formData.append("EmailID", this.email);
      formData.append("secondaryEmailID", this.secondaryEmail);
      formData.append("IncorporationDate", this.incorporationDate);
      formData.append("RegistrationDate", this.regDate);
      formData.append("selectedState", this.selectedState);
      formData.append("selectedCity", this.selectedCity);
      //append offtaker information to formdata
      formData.append("OfftakerDocuments", JSON.stringify(this.OfftakerList));
      formData.append("clientId", this.$store.state.client_id);
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 12);
      formData.append("tabId", 30);

      const path = "offtaker";
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          if (response.data.status == "success") {
            Utils.make_alert("success", "New Offtaker added successfully!");
            console.log("SUCCESS!!");
            this.$router.push("/offtaker");
          } else {
            Utils.make_alert("warning", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch(function (error) {
          Utils.stop_loading();
          Utils.make_alert(
            "warning",
            error.response.status === 400
              ? error.response.data.status
              : "Something went wrong!"
          );
          console.error(error);
        });
      // }
    }, //onSubmit() close

    //this method will add new line for additional offtaker docs
    addOfftaker() {
      //when new line is added then OfftakerFile variable and OfftakerSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.OfftakerFile = null),
        (this.OfftakerSaveInfoDisabled = true),
        (this.OfftakerAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.OfftakerList.push({
          OfftakerDocName: null,
          UploadingDate: null,
          ExpiryDate: null,
          comments: null,
          displayFileName: null,
          errors: { filesize: "", docName: "" },
          OfftakerRemoveDisabled: true,
        });
    }, //addOfftaker() close

    //handles a change on the file upload
    handleFileUploadOfftaker(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.OfftakerFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.OfftakerFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors.filesize = "File size should be less than 100MB";
          self.OfftakerList[id].errors.filesize = JSON.stringify(
            response.errors.filesize
          ).replace(/\"/g, "");
          self.OfftakerList[id].selectedFile = null;
          self.OfftakerList[id].selectedFileName = null;
          self.OfftakerList[id].displayFileName = null;
          self.OfftakerList[id].OfftakerRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.OfftakerFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.OfftakerList[id].selectedFile = reader.result;
          self.OfftakerList[id].selectedFileName = e.target.files[0].name;
          self.OfftakerList[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.OfftakerList[id].errors.filesize = "";
          self.OfftakerList[id].OfftakerRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadOfftaker() close

    ////this method handles remove offtaker attachment
    removeOfftakerDoc(id) {
      this.OfftakerFile = null;
      this.OfftakerList[id].selectedFile = null;
      this.OfftakerList[id].selectedFileName = null;
      this.OfftakerList[id].displayFileName = null;
      this.OfftakerList[id].OfftakerRemoveDisabled = true;
    },

    //this function is called to get list of documents master
    getOfftakerDocuments() {
      const path = "offtaker-document";
      axios
        .get(path)
        .then((res) => {
          this.offtakerDocuments = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getOfftakerDocuments() close

    //this function is called to get list of countries
    getCountries() {
      const path = "country";
      axios
        .get(path)
        .then((res) => {
          this.countries = res.data.countries;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getCountries() close

    getStatesByCountry() {
      if(this.selectedCountry == null){
        this.states = [];
        this.selectedState = null;
        this.cities = [];
        this.selectedCity = null;
        return;
      }
      //this function is called to get list of states
      const path = "get-state-by-country/" + this.selectedCountry ;
      axios
        .get(path)
        .then((res) => {
          this.states = [];
          this.selectedState = null;
          this.cities = [];
          this.selectedCity = null;
          this.states = res.data.states;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCitiesByState() {
      if(this.selectedState == null){
        this.cities = [];
        this.selectedCity = null;
        return;
      }
      //this function is called to get list of cities
      const path = "get-city-by-state/" + this.selectedState;
      axios
        .get(path)
        .then((res) => {
          this.cities = [];
          this.selectedCity = null;
          this.cities = res.data.cities;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCities() {
      //this function is called to get list of cities
      const path = "city";
      axios
        .get(path)
        .then((res) => {
          this.cities = res.data.cities;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getStates() {
      //this function is called to get list of states
      const path = "state";
      axios
        .get(path)
        .then((res) => {
          this.states = res.data.states;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCountryCodes() {
      //this function is called to get list of country codes
      const path = "country_code_master";
      axios
        .get(path)
        .then((res) => {
          this.countryCodes = res.data.country_codes;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function will be called to get list of offtakers
    getOfftakers() {
      const path = "buyer";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.offtakers = res.data.Buyer;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close

  mounted() {
    this.addOfftaker();
  },
  created() {
    this.getCountries();
    this.getCountryCodes();
    this.getOfftakers();
    this.getOfftakerDocuments();
  },
};
</script>
