<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div
                      class="col-lg-6"
                      v-if="loggedInUserGroupId == 'Investor'"
                    >
                      <h1 class="headingt">
                        <i class="icofont-coins"></i> Overview
                      </h1>
                    </div>
                    <div class="col-lg-6" v-else>
                      <h1 class="headingt">
                        <i class="icofont-coins"></i> Investor Overview
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid tabtop" id="al_tb">
          <div class="tp_mid">
            <div class="row">
              <div class="col-lg-12">
                <ul
                  class="nav nav-tabs"
                  id="custom-tabs-one-tab"
                  role="tablist"
                >
                <!-- <ul
                  class="nav nav-tabs"
                  v-model="tabIndex"
                  id="custom-tabs-one-tab"
                  role="tablist"
                > -->
                  <li class="nav-item" v-if="loggedInUserGroupId == 'Investor'">
                    <a
                      class="nav-link active"
                      id="custom-tabs-one-home-tab"
                      data-toggle="pill"
                      href="#one"
                      role="tab"
                      aria-controls="custom-tabs-one-home"
                      aria-selected="true"
                      >Profile Information</a
                    >
                  </li>
                  <li class="nav-item" v-else>
                    <a
                      class="nav-link active"
                      id="custom-tabs-one-home-tab"
                      data-toggle="pill"
                      href="#one"
                      role="tab"
                      aria-controls="custom-tabs-one-home"
                      aria-selected="true"
                      >Investors Information</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="custom-tabs-one-profile-tab"
                      data-toggle="pill"
                      href="#two"
                      role="tab"
                      aria-controls="custom-tabs-one-profile"
                      aria-selected="false"
                      >Investments</a
                    >
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="custom-tabs-one-profile-tab"
                      data-toggle="pill"
                      href="#three"
                      role="tab"
                      aria-controls="custom-tabs-one-profile"
                      aria-selected="false"
                      >Payments</a
                    >
                  </li>
                </ul>
              </div>
              <!-- /.col -->
            </div>
          </div>
        </div>

        <div class="tab-content" id="custom-tabs-one-tabContent">
          <div
            class="tab-pane fade show active"
            id="one"
            role="tabpanel"
            aria-labelledby="kyc1"
          >
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <div class="card-body br_overview">
                    <!------menton here style="height: 300px;"-------->
                    <table
                      class="table table-hover table-head-fixed text-nowrap"
                      style="table-layout: fixed"
                    >
                      <tbody>
                        <tr>
                          <td class="wg">Name:</td>
                          <td class="wrap_text">
                            {{ investor.investor_name }}
                          </td>
                        </tr>
                        <!----------------------------------------------->

                        <tr>
                          <td class="wg">Investment Category:</td>
                          <td>{{ investor.investment_category_name }}</td>
                        </tr>
                        <!----------------------------------------------->

                        <tr
                          v-if="
                            investor.investment_category_name != 'Participation'
                          "
                        >
                          <td class="wg">Investor Type:</td>
                          <td class>{{ investor.investor_type_name }}</td>
                        </tr>
                        <!----------------------------------------------->

                        <tr>
                          <td class="wg">Address:</td>
                          <td class="wrap_text">
                            {{ investor.address }}
                          </td>
                        </tr>
                        <!----------------------------------------------->

                        <tr
                          v-if="
                            investor.investment_category_name !=
                              'Participation' &&
                            investor.investor_type_name != 'Individual' &&
                            investor.investor_type_name != 'Nominee'
                          "
                        >
                          <td class="wg">Website:</td>
                          <td class="wrap_text" v-if="investor.website">
                            {{ investor.website }}
                          </td>
                          <td v-else>-</td>
                        </tr>
                        <!----------------------------------------------->

                        <tr>
                          <td class="wg">Email ID:</td>
                          <td>{{ investor.email }}</td>
                        </tr>
                        <!----------------------------------------------->

                        <tr>
                          <td class="wg">Secondary Email ID:</td>
                          <td v-if="investor.secondary_email">
                            {{ investor.secondary_email }}
                          </td>
                          <td v-else>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card-body br_overview">
                    <!------menton here style="height: 300px;"-------->
                    <table class="table table-hover">
                      <tbody>
                        <tr>
                          <td class="wg">Contact:</td>
                          <td>
                            {{ investor.primary_code }}
                            {{ investor.phone_number }}
                          </td>
                        </tr>
                        <!----------------------------------------------->

                        <tr>
                          <td class="wg">Secondary Contact:</td>
                          <td v-if="investor.secondary_phone_number">
                            {{ investor.secondary_code }}
                            {{ investor.secondary_phone_number }}
                          </td>
                          <td v-else>-</td>
                        </tr>
                        <!----------------------------------------------->

                        <tr>
                          <td class="wg">Country:</td>
                          <td>{{ investor.country_name }}</td>
                        </tr>
                        <!----------------------------------------------->
                        
                        <tr>
                          <td class="wg">State :</td>
                          <td v-if="investor.state_name !== null" class>
                            {{ investor.state_name }}
                          </td>
                          <td v-else>-</td>
                        </tr>
                        <!----------------------------------------------->
                        <tr>
                          <td class="wg">City:</td>
                          <td v-if="investor.city_name !== null" class>
                            {{ investor.city_name }}
                          </td>
                          <td v-else>-</td>
                        </tr>
                        <!----------------------------------------------->

                        <tr
                          v-if="
                            investor.investment_category_name !=
                              'Participation' &&
                            investor.investor_type_name != 'Individual' &&
                            investor.investor_type_name != 'Nominee'
                          "
                        >
                          <td class="wg">Incorporation Date:</td>
                          <td v-if="investor.incorporation_date !== null" class>
                            {{ investor.incorporation_date }}
                          </td>
                          <td v-else>-</td>
                        </tr>

                        <tr>
                          <td class="wg">Registration Date:</td>
                          <td>{{ investor.registration_date }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="investor.investment_category_name !== 'Participation'"
              class="container-fluid ot_tb pt-3"
            >
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt">KYC Tracker</h1>
                        </div>
                        <div class="col-lg-6">
                          <ul id="sr_btn">
                            <li>
                              <router-link
                                active-class="active"
                                :to="{
                                  name: 'KYCHistory',
                                  params: { id: id, inactive: inactive },
                                }"
                              >
                                <a>
                                  <u>KYC History</u>
                                </a>
                              </router-link>
                            </li>
                          </ul>
                        </div>
                        <!-- /.col -->
                      </div>
                    </div>

                    <div class="card-body table-responsive p-0">
                      <!------menton here style="height: 300px;"-------->
                      <table class="table table-head-fixed text-nowrap">
                        <thead>
                          <tr>
                            <th>Document Name</th>
                            <th>Issue Date</th>
                            <th>Expiry Date</th>
                            <th class="c">Status</th>
                            <th
                              class="c"
                              v-if="
                                loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0
                              "
                            >
                              Change Status
                            </th>
                            <th class="c center">Comments</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="(kyc, index) in investor_kycdocuments_list"
                            :key="kyc.kyc_tran_id"
                          >
                            <td
                              v-on:click="
                                downloadInvDocument(kyc.document_path)
                              "
                              :style="{ cursor: 'pointer' }"
                            >
                              <i class="icofont-download text-info"></i>
                              {{ kyc.document_name }}
                            </td>
                            <td v-if="kyc.uploading_date">
                              {{ kyc.uploading_date }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="kyc.expiry_date">
                              {{ kyc.expiry_date }}
                            </td>
                            <td v-else>-</td>
                            <td
                              v-if="kyc.document_status"
                              :class="{
                                'c text-success':
                                  kyc.document_status === 'Approved',
                                'c text-warning':
                                  kyc.document_status === 'Received',
                                'c text-danger':
                                  kyc.document_status === 'Rejected',
                                'c text-dark':
                                  kyc.document_status === 'Expired',
                                'c text-info':
                                  kyc.document_status === 'Near to Expiry',
                              }"
                            >
                              {{
                                kyc.document_status === "Received"
                                  ? loggedInUserGroupId === "Investor"
                                    ? "Uploaded"
                                    : kyc.document_status
                                  : kyc.document_status
                              }}
                            </td>
                            <td v-else>-</td>
                            <template
                              v-if="
                                loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0
                              "
                            >
                              <td class="c">
                                <div class="status">
                                  <select
                                    id="docStatus"
                                    name="docStatus"
                                    @change="
                                      onChangeKYCDocStatus(
                                        $event,
                                        kyc.investor_id,
                                        kyc.document_id,
                                        kyc.document_status,
                                        index
                                      )
                                    "
                                    class="form-control"
                                    :value="kyc.document_status"
                                    :disabled="
                                      inactive ||
                                      kyc.document_status !== 'Received'
                                    "
                                  >
                                    <option value="Received">Received</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                    <option
                                      v-if="kyc.document_status === 'Expired'"
                                      value="Expired"
                                    >
                                      Expired
                                    </option>
                                    <option
                                      v-if="
                                        kyc.document_status === 'Near to Expiry'
                                      "
                                      value="Near to Expiry"
                                    >
                                      Near to Expiry
                                    </option>
                                  </select>
                                </div>
                              </td>
                            </template>
                            <td
                              v-if="!inactive"
                              class="c center"
                              v-on:click="
                                showCommentModal(
                                  kyc.investor_id,
                                  kyc.document_id,
                                  kyc.comments
                                )
                              "
                              :style="{ cursor: 'pointer' }"
                            >
                              <i class="icofont-comment text-info"></i>
                            </td>
                            <td v-else class="c center">
                              <i
                                style="opacity: 0.5"
                                class="icofont-comment text-info"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
          </div>
          <!-- /.Tabpane first end-->

          <div
            class="tab-pane fade"
            id="two"
            role="tabpanel"
            aria-labelledby="covenant1"
          >
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6 col-sm-5 col-6">
                  <div class="form-group fc">
                    <div class="form-field">
                      <label
                        style="margin-right: 10px"
                        class="exampleInputEmail1"
                        >Filter By:</label
                      >
                      <input type="radio" v-model="filter" value="date" />
                      <label style="margin: 5px; padding-right: 10px"
                        >Issue Date</label
                      >

                      <input type="radio" v-model="filter" value="period" />
                      <label style="margin: 5px; padding-right: 10px"
                        >Period</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container-fluid">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6" v-if="filter === 'date'">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">From Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="startDate"
                      placeholder="Select Date"
                      v-model="startDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                    ></datepicker>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6" v-if="filter === 'date'">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">To Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="endDate"
                      placeholder="Select Date"
                      v-model="endDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{ to: new Date(startDate - 8640000) }"
                    ></datepicker>
                  </div>
                </div>

                <div class="col-md-2" v-if="filter === 'period'">
                  <div class="form-group fc">
                    <label>Period</label>
                    <select
                      id="selectedPeriod"
                      class="form-control"
                      name="selectedPeriod"
                      v-model="selectedPeriod"
                      @change="onPeriodSelect"
                      required
                    >
                      <option :value="null">-- Select --</option>
                      <option value="mtd">MTD</option>
                      <option value="qtd">QTD</option>
                      <option value="ytd">YTD</option>
                      <option value="last_month">Last Month</option>
                      <option value="last_quarter">Last 3 Months</option>
                      <option value="last_2_quarters">Last 6 Months</option>
                      <option value="last_fy_1">Last 12 Months</option>
                      <!-- <option value="last_fy_2">Last 2 FY Years</option> -->
                      <option value="all">All</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6" v-if="filter === 'period'">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">From Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="endDate"
                      placeholder
                      v-model="startDatePeriod"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabled="true"
                    ></datepicker>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6" v-if="filter === 'period'">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">To Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="endDate"
                      placeholder
                      v-model="endDatePeriod"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabled="true"
                      :disabledDates="{
                        to: new Date(startDatePeriod - 8640000),
                      }"
                    ></datepicker>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group fc">
                    <label>Status</label>
                    <select
                      id="selectedStatus"
                      class="form-control"
                      name="selectedStatus"
                      v-model="selectedStatus"
                      required
                    >
                      <!--<option :value="null">-- Select Period --</option>-->
                      <option value="Open">Open</option>
                      <option value="Closed">Closed</option>
                      <option value="Matured">Matured</option>
                      <option value="All">All</option>
                    </select>
                  </div>
                </div>

                <!-- <div class="col-md-2">
                  <div class="form-group fc">
                    <label>Currency</label>
                    <select
                      id="selectedCurrency"
                      class="form-control"
                      name="selectedCurrency"
                      v-model="selectedCurrency"
                      required
                    >
                      <option :value="null">-- Select a Currency --</option>
                      <option
                        v-for="curr in currencies"
                        :key="curr.id"
                        :value="curr.id"
                      >
                        {{ curr.currency_short_name }}
                      </option>
                    </select>
                  </div>
                </div> -->

                <div class="col-md-0.3 col-sm-2 col-1">
                  <ul id="sr_btn">
                    <li>
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh_n mt"
                        v-on:click="filterData('investment')"
                      >
                        Filter
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh_n mt"
                        v-on:click="resetData('investment')"
                      >
                        Reset
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <h1 class="headingt">
                            <i class="icofont-coins"></i> Investments
                          </h1>
                        </div>
                        <!-- /.col -->
                        <div
                          class="col-lg-6 col-sm-6"
                          v-if="this.investor.status === 'Approved'"
                        >
                          <ul id="sr_btn">
                            <!-- <li v-if="loggedInUserGroupId == 'Admin' && !inactive">
                            <input
                              type="checkbox"
                              id="checkbox"
                              v-model="inActiveToggle"
                              @change="destroyTable"
                            />
                            <label for="checkbox">InActive&nbsp;&nbsp;</label>
                          </li> -->
                            <!--<li><button  onclick="window.location.href='add_investment.html';" type="button" class="btn btn-sm sh_n btn-success"><i class="icofont-plus"></i><span class="mob">Add Investment</span></button></li>-->
                            <router-link
                              v-if="
                                !inActiveToggle &&
                                !inactive &&
                                (loggedInUserGroupId === 'Admin' ||
                                  loggedInUserGroupId === 'Analyst')
                              "
                              :to="{
                                name: 'AddInvestment',
                                params: {
                                  props: {
                                    id: id,
                                    InvRegDate: registration_date,
                                    inactive: inactive,
                                  },
                                },
                              }"
                            >
                              <li>
                                <button
                                  type="button"
                                  class="btn btn-sm sh_n btn-success"
                                >
                                  <i class="icofont-plus"></i>
                                  <span class="mob">Add Investment</span>
                                </button>
                              </li>
                            </router-link>
                          </ul>
                        </div>
                        <div
                          class="col-lg-12 col-sm-6"
                          v-if="
                            (loggedInUserGroupId == 'Admin' ||
                              loggedInUserGroupId == 'Analyst') &&
                            !inactive
                          "
                        >
                          <input
                            id="active"
                            type="radio"
                            :value="false"
                            @change="destroyTable"
                            v-model="inActiveToggle"
                          />
                          <label for="active" style="padding-right: 20px"
                            >Active</label
                          >
                          <input
                            id="inactive"
                            type="radio"
                            :value="true"
                            @change="destroyTable"
                            v-model="inActiveToggle"
                          />
                          <label for="inactive">InActive</label>
                        </div>
                        <!-- /.col -->
                      </div>
                    </div>

                    <div class="card-body table-responsive p-0">
                      <!------menton here style="height: 300px;"-------->
                      <table
                        class="table table-head-fixed text-nowrap table-hover investmentDetails"
                        id="investmentDetails"
                      >
                        <thead>
                          <tr>
                            <th>Fund Name</th>
                            <th>Investment ID</th>
                            <th>Principal</th>
                            <th>Issue Date</th>
                            <th>Maturity Date</th>
                            <th>Inv Currency</th>
                            <th>Fund Currency</th>
                            <th
                              v-if="
                                loggedInUserGroupId == 'Admin' ||
                                loggedInUserGroupId == 'Analyst'
                              "
                            >
                              Currency Value
                            </th>
                            <th>Category Name</th>
                            <th
                              v-if="
                                investor.investment_category_name ===
                                'Participation'
                              "
                            >
                              Borrower Name
                            </th>
                            <th
                              v-if="
                                investor.investment_category_name ===
                                'Participation'
                              "
                            >
                              Utilisation Req. ID
                            </th>
                            <!--<th
                              v-if="
                                investor.investment_category_name === 'Notes'
                              "
                            >
                              Maturity Extendible
                            </th>
                            <th
                              v-if="
                                investor.investment_category_name === 'Notes'
                              "
                            >
                              Extended Maturity Date
                            </th>-->
                            <!-- <th
                              v-if="
                                investor.investment_category_name ===
                                'Participation'
                              "
                            >
                              Admin Fees(%)
                            </th>
                            <th
                              v-if="
                                investor.investment_category_name ===
                                'Participation'
                              "
                            >
                              Admin Fees
                            </th> -->
                            <th
                              v-if="
                                investor.investment_category_name ===
                                'Participation'
                              "
                            >
                              Mgmt Fees(%)
                            </th>
                            <th
                              v-if="
                                investor.investment_category_name ===
                                'Participation'
                              "
                            >
                              Mgmt Fees
                            </th>
                            <th
                              v-if="
                                investor.investment_category_name ===
                                'Participation'
                              "
                            >
                              Penalty Interest(%)
                            </th>
                            <th
                              v-if="
                                investor.investment_category_name ===
                                'Participation'
                              "
                            >
                              Penalty Interest
                            </th>
                            <th>Interest Rate Type</th>
                            <th>Interest Rate(%)</th>
                            <th>Payment Schedule</th>
                            <th>Day Count</th>
                            <th>Interest Accrued</th>
                            <th>Interest Paid</th>
                            <th>Total Interest</th>
                            <th>Days of Calculation</th>
                            <th>Redemption Value</th>
                            <th
                              v-if="
                                investor.investment_category_name === 'Notes'
                              "
                            >
                              Current Value
                            </th>
                            <th
                              v-if="
                                investor.investment_category_name ===
                                'Participation'
                              "
                            >
                              Gross Amount
                            </th>
                            <th class="c">Status</th>
                            <!--<th class="c" v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"></th>-->
                            <th
                              class="c"
                              v-if="
                                loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0) &&
                                !inActiveToggle
                              "
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="inv in investments"
                            :key="inv.investment_id"
                          >
                            <template>
                              <td v-if="inv.fund_name !== null">
                                {{ inv.fund_name }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="inv.investment_id !== null">
                                {{ inv.investment_id }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="inv.principal_amount != null">
                                {{ inv.principal_amount | numeral("0,0.00") }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="inv.investment_date">
                                {{ inv.investment_date }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="inv.maturity_date">
                                {{ inv.maturity_date }}
                              </td>
                              <td v-else>
                                {{
                                  inv.investment_category_name ===
                                    "Participation" && inv.status === "Open"
                                    ? todaysDate
                                    : "-"
                                }}
                              </td>
                              <td
                                v-if="inv.inv_currency !== null"
                                :style="
                                  loggedInUserGroupId == 'Investor' &&
                                  'font-weight:bold'
                                "
                              >
                                {{ inv.inv_currency }}
                              </td>
                              <td v-else>-</td>
                              <td
                                v-if="inv.fund_currency !== null"
                                :style="
                                  (loggedInUserGroupId == 'Admin' ||
                                    loggedInUserGroupId == 'Analyst') &&
                                  'font-weight:bold'
                                "
                              >
                                {{ inv.fund_currency }}
                              </td>
                              <td v-else>-</td>
                              <template
                                v-if="
                                  loggedInUserGroupId == 'Admin' ||
                                  loggedInUserGroupId == 'Analyst'
                                "
                              >
                                <td v-if="inv.filter_currency !== null">
                                  {{ inv.filter_currency }}
                                </td>
                                <td v-else>-</td>
                              </template>
                              <td v-if="inv.investment_category_name !== null">
                                {{ inv.investment_category_name }}
                              </td>
                              <td v-else>-</td>
                              <td
                                v-if="
                                  investor.investment_category_name ===
                                  'Participation'
                                "
                              >
                                {{ inv.borrower_name }}
                              </td>
                              <td
                                v-if="
                                  investor.investment_category_name ===
                                  'Participation'
                                "
                              >
                                {{ inv.loan_registration_id }}
                              </td>
                              <!--<td
                              v-if="
                                investor.investment_category_name === 'Notes'
                              "
                            >
                              {{ inv.maturity_extendible }}
                            </td>
                            <td
                              v-if="
                                investor.investment_category_name === 'Notes'
                              "
                            >
                              {{ inv.extended_maturity_date }}
                              </td>-->
                              <!-- <td
                                v-if="
                                  investor.investment_category_name ===
                                  'Participation'
                                "
                              >
                                {{ inv.admin_fees_x + "%" }}
                              </td>
                              <td
                                v-if="
                                  investor.investment_category_name ===
                                  'Participation'
                                "
                              >
                                {{ inv.admin_fees_percent | numeral("0,0.00") }}
                              </td> -->
                              <td
                                v-if="
                                  investor.investment_category_name ===
                                  'Participation'
                                "
                              >
                                {{ inv.management_fees_percent + "%" }}
                              </td>
                              <td
                                v-if="
                                  investor.investment_category_name ===
                                  'Participation'
                                "
                              >
                                {{ inv.management_fees | numeral("0,0.00") }}
                              </td>
                              <td
                                v-if="
                                  investor.investment_category_name ===
                                  'Participation'
                                "
                              >
                                {{ inv.penalty_interest_percent + "%" }}
                              </td>
                              <td
                                v-if="
                                  investor.investment_category_name ===
                                    'Participation' &&
                                  inv.penalty_interest !== null
                                "
                              >
                                {{ inv.penalty_interest | numeral("0,0.00") }}
                              </td>
                              <!-- <td v-else>-</td> -->
                              <td v-if="inv.interest_type_name !== null">
                                {{ inv.interest_type_name }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="inv.coupon !== null">
                                {{ inv.coupon }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="inv.interest_frequency_name !== null">
                                {{ inv.interest_frequency_name }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="inv.day_count_name !== null">
                                {{ inv.day_count_name }}
                              </td>
                              <td v-else>-</td>
                              <td
                                v-if="
                                  inv.accrued_interest != null &&
                                  inv.accrued_interest != undefined
                                "
                              >
                                {{ inv.accrued_interest | numeral("0,0.00") }}
                              </td>
                              <td v-else>-</td>
                              <td
                                v-if="
                                  inv.paid_interest != null &&
                                  inv.paid_interest != undefined
                                "
                              >
                                {{ inv.paid_interest | numeral("0,0.00") }}
                              </td>
                              <td v-else>-</td>
                              <td
                                v-if="
                                  inv.total_interest != null &&
                                  inv.total_interest != undefined
                                "
                              >
                                {{ inv.total_interest | numeral("0,0.00") }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="inv.days_of_calculation !== null">
                                {{ inv.days_of_calculation }}
                              </td>
                              <td v-else>-</td>
                              <td v-if="inv.redemption_value !== null">
                                {{ inv.redemption_value | numeral("0,0.00") }}
                              </td>
                              <td v-else>-</td>
                              <td
                                v-if="
                                  investor.investment_category_name ===
                                    'Notes' &&
                                  inv.current_value != null &&
                                  inv.current_value != undefined
                                "
                              >
                                {{ inv.current_value | numeral("0,0.00") }}
                              </td>
                              <td
                                v-if="
                                  investor.investment_category_name ===
                                    'Participation' && inv.gross_amount !== null
                                "
                              >
                                {{ inv.gross_amount | numeral("0,0.00") }}
                              </td>
                              <!-- <td v-else>-</td> -->
                              <td
                                :class="{
                                  'c text-success': inv.status === 'Open',
                                  'c text-warning': inv.status === 'Matured',
                                  'c text-danger': inv.status === 'Closed',
                                }"
                              >
                                {{ inv.status }}
                              </td>

                              <!--<td class="c" v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0">
                              <router-link style="text-decoration:none;"
                                :to="{
                                  name: 'EditInvestment',
                                  params: {
                                    investor_id: inv.investor_id,
                                    transaction_id: inv.transaction_id,
                                  },
                                }"
                                ><i class="icofont-ui-edit text-warning"></i
                              ></router-link>
                              </td>-->

                              <template
                                v-if="
                                  inv.status != 'Matured' &&
                                  inv.status != 'Closed'
                                "
                              >
                                <td
                                  class="c"
                                  v-if="
                                    !inActiveToggle &&
                                    loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)
                                  "
                                  v-on:click="
                                    deleteInvestment(inv.investment_id)
                                  "
                                >
                                  <i class="icofont-trash text-danger"></i>
                                </td>
                              </template>
                              <template v-else>
                                <td></td>
                              </template>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <span class="note"
                      >Note: All calculations are displayed in
                      {{
                        loggedInUserGroupId == "Admin" ||
                        loggedInUserGroupId == "Analyst"
                          ? "Fund"
                          : "Investment"
                      }}
                      Currency.</span
                    >
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade"
            id="three"
            role="tabpanel"
            aria-labelledby="loan1"
          >
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">From Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="paymentStartDate"
                      placeholder="Select Date"
                      v-model="paymentStartDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                    ></datepicker>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">To Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="paymentEndDate"
                      placeholder="Select Date"
                      v-model="paymentEndDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{
                        to: new Date(paymentStartDate),
                      }"
                    ></datepicker>
                  </div>
                </div>

                <!-- <div class="col-md-2">
                  <div class="form-group fc">
                    <label>Currency</label>
                    <select
                      id="selectedCurrency"
                      class="form-control"
                      name="selectedCurrency"
                      v-model="selectedPaymentCurrency"
                      required
                    >
                      <option :value="null">-- Select a Currency --</option>
                      <option
                        v-for="curr in currencies"
                        :key="curr.id"
                        :value="curr.id"
                      >
                        {{ curr.currency_short_name }}
                      </option>
                    </select>
                  </div>
                </div> -->

                <div class="col-md-2 col-sm-2 col-12">
                  <ul id="sr_btn">
                    <li>
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh_n mt"
                        v-on:click="filterData('payment')"
                      >
                        Filter
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh_n mt"
                        v-on:click="resetData('payment')"
                      >
                        Reset
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <h1 class="headingt">
                            <i class="icofont-coins"></i> Payment
                          </h1>
                        </div>
                        <!-- /.col -->
                      </div>
                    </div>

                    <div
                      class="card-body table-responsive p-0"
                      style="min-height: 120px"
                    >
                      <!------menton here style="height: 300px;"-------->
                      <table
                        class="table table-head-fixed text-nowrap table-hover paymentDetails"
                        id="paymentDetails"
                      >
                        <thead>
                          <tr>
                            <th>Fund Name</th>
                            <th>Investment ID</th>
                            <th>Amount</th>
                            <th>Inv Currency</th>
                            <th>Fund Currency</th>
                            <th
                              v-if="
                                loggedInUserGroupId == 'Admin' ||
                                loggedInUserGroupId == 'Analyst'
                              "
                            >
                              Currency Val
                            </th>
                            <th>Date</th>
                            <th>Status</th>
                            <template
                              v-if="
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                              "
                            >
                              <th>Change Status</th>
                            </template>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(payment, index) in payments"
                            :key="payment.transaction_id"
                          >
                            <td v-if="payment.fund_name !== null">
                              {{ payment.fund_name }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="payment.investment_id !== null">
                              {{ payment.investment_id }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="payment.amount !== null">
                              <div v-if="payment.benchmark_id" class="toooltip">
                                {{ payment.amount | numeral("0,0.00") }}
                                <span class="tooltiptext"
                                  >Benchmark Rate: {{ payment.benchmark_rate
                                  }}<br />Date of Rate:
                                  {{ payment.benchmark_date }}</span
                                >
                              </div>
                              <div v-else>
                                {{ payment.amount | numeral("0,0.00") }}
                              </div>
                            </td>
                            <td v-else>-</td>
                            <td
                              v-if="payment.inv_currency !== null"
                              :style="
                                loggedInUserGroupId == 'Investor' &&
                                'font-weight:bold'
                              "
                            >
                              {{ payment.inv_currency }}
                            </td>
                            <td v-else>-</td>
                            <td
                              v-if="payment.fund_currency !== null"
                              :style="
                                (loggedInUserGroupId == 'Admin' ||
                                  loggedInUserGroupId == 'Analyst') &&
                                'font-weight:bold'
                              "
                            >
                              {{ payment.fund_currency }}
                            </td>
                            <td v-else>-</td>
                            <template
                              v-if="
                                loggedInUserGroupId == 'Admin' ||
                                loggedInUserGroupId == 'Analyst'
                              "
                            >
                              <td v-if="payment.filter_currency_value !== null">
                                {{ payment.filter_currency_value }}
                              </td>
                              <td v-else>-</td>
                            </template>
                            <td v-if="payment.date !== null">
                              {{ payment.date }}
                            </td>
                            <td v-else>-</td>
                            <td
                              :class="{
                                'c text-success': payment.status === 'Open',
                                'c text-danger': payment.status === 'Closed',
                              }"
                            >
                              {{ payment.status }}
                            </td>
                            <template
                              v-if="
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                              "
                            >
                              <td class="c">
                                <div class="status">
                                  <select
                                    id="paymentStatus"
                                    name="paymentStatus"
                                    @change="
                                      onChangePaymentStatusOverview(
                                        $event,
                                        payment.transaction_id,
                                        payment.status,
                                        index,
                                        payment.date,
                                        payment.amount,
                                        payment.inv_currency,
                                        payment.fund_currency,
                                        payment.investor_transaction_id
                                      )
                                    "
                                    class="form-control"
                                    :value="payment.status"
                                    :disabled="isPaymentDisabled(payment, investor, inActiveToggle)"
                                  >
                                    <option value="Open">Open</option>
                                    <option value="Closed">Closed</option>
                                  </select>
                                </div>
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <span class="note"
                      >Note: All calculations are displayed in
                      {{
                        loggedInUserGroupId == "Admin" ||
                        loggedInUserGroupId == "Analyst"
                          ? "Fund"
                          : "Investment"
                      }}
                      Currency.</span
                    >
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
          </div>
        </div>
        <!-- /.Tabpane second end-->
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->

    <!-- ./wrapper -->

    <!-- ./modal start -->
    <div class="modal fade" id="show_payment">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Add Payment</h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <!-- <p v-if="errors.length">
                     <b>Please correct the following field(s):</b>
                  <ul>
                     <li v-for="error in errors">{{ error }}</li>
                  </ul>
            </p>-->
            <div class="row">
              <div class="col-md-12">
                <div class="form-group fc">
                  <label class="required-field">Investor Name</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    v-model="selectedExpense"
                    required
                  />
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field"
                    >Amount</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    disabled
                    v-model="amount"
                  />
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label class="required-field">Currency</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    v-model="selectedCurrency"
                  />
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field"
                    >Date</label
                  >
                  <datepicker
                    class="datepop"
                    :bootstrap-styling="true"
                    name="endDate"
                    disabled
                    v-model="paymentDate1"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                  ></datepicker>
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field"
                    >Status</label
                  >
                  <!-- <input type="number" class="form-control" placeholder="Type Amount" v-model="showStatus" required> -->
                  <select
                    id="editStatus"
                    class="form-control"
                    name="editStatus"
                    v-model="editStatus"
                    disabled
                  >
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onChangePaymentStatus()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    data-dismiss="modal"
                  >
                    <a @click="$router.push('/')">Cancel</a>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
    <!-- ./modal start -->
    <div class="modal fade" id="comm">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title"><i class="icofont-comment"></i>Comments</h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <textarea
                  rows="4"
                  cols="60"
                  style="outline: none"
                  v-model="comment"
                  :class="{
                    'is-invalid': isSubmitted && $v.comment.$error,
                  }"
                ></textarea>
                <div
                  v-if="isSubmitted && $v.comment.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.comment.maxLength"
                    >Max length of 50 characters exceeded</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="saveComment()"
                  >
                    Save
                  </button>
                </li>
                <li>
                  <router-link :to="{ name: 'InvestorOverview' }">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./modal end -->
</template>

<script>
// //Bootstrap and jQuery libraries
// import "jquery/dist/jquery.min.js";
// //Datatable Modules
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import "datatables.net-buttons/js/dataTables.buttons.js";
// import "datatables.net-buttons/js/buttons.colVis.js";
// import "datatables.net-buttons/js/buttons.flash.js";
// import "datatables.net-buttons/js/buttons.html5.js";
// import "datatables.net-buttons/js/buttons.print.js";

// //Datatable Modules
import "jquery/dist/jquery.min.js";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import moment from "moment";
import { maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: null,
      index: null,
      type: null,
      tabIndex: 0,
      tabs: ["#one", "#two", "#three"],
      investor: [],
      investorId: null,
      documentId: null, //required for comment modals
      comment: null, //required for comment modal
      startDate: null,
      endDate: null,
      selectedPeriod: null,
      selectedStatus: null,
      investments: [],
      paymentStartDate: null,
      paymentEndDate: null,
      payments: [],
      filter: null,
      startDatePeriod: null, //for period filter
      endDatePeriod: null, //for period filter
      selectedCurrency: null,
      selectedPaymentCurrency: null,
      currencies: [],
      selectedExpense: null,
      paymentDate1: null,
      showCurrency: null,
      amount: null,
      investor_kycdocuments_list: [],
      editStatus: "Closed",
      registration_date: null,
      InvRegDate: null,
      alertAmountCurrency: null,
      alertAmount: null,
      tranId: null,
      investorTranId: null,
      inactive: false,
      inActiveToggle: false,
      isSubmitted: false,
      todaysDate: moment().format(this.$store.state.moment_date_format),
    };
  },
  components: {
    Datepicker,
  },
  validations: {
    comment: {
      maxLength: maxLength(50),
    },
  },
  methods: {
    destroyTable() {
      var $ = require("jquery");
      $("#investmentDetails").DataTable().destroy();
      $("#paymentDetails").DataTable().destroy();
      if (!this.inActiveToggle) {
        this.$router.go();
      } else {
        this.getInvestmentByInvestorId();
        this.getPaymentByInvestorId();
      }
    },
    onPeriodSelect() {
      if (this.selectedPeriod === "all") {
        this.selectedStatus = "All";
      } else {
        this.selectedStatus = "Open";
      }
    },
    showModal() {
      // this.type = type;
      $("#show_payment").modal("show");
    }, //showModal() close

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    isPaymentDisabled(payment, investor, inActiveToggle) {
      const isFutureDate = moment(payment.date, this.$store.state.moment_date_format).isAfter(moment());
      console.log("isFutureDate",this.$store.state.moment_date_format)
      return (
        (investor.investment_category_name === 'Participation' && payment.investment_status === 'Open') ||
        isFutureDate ||
        payment.status === 'Closed' ||
        inActiveToggle
      );
    },
    getInvestor() {
      //this function is called on page load and created events. it will show details on investor with investorid equal to this.id
      const path = "investor/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.investor = this.inactive
              ? res.data.investor_inactive
              : res.data.investor_active;
            if (this.investor.registration_date != '-' && this.investor.registration_date != null) {
              this.investor.registration_date = moment(this.investor.registration_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.investor.registration_date = '-';
            }
            this.registration_date = this.investor.registration_date;
            this.investor_kycdocuments_list =
              this.investor.investor_kycdocuments_list;
            if(this.investor_kycdocuments_list.length > 0) {
              this.investor_kycdocuments_list.forEach(item => {
                if (item.uploading_date != '-' && item.uploading_date != '' && item.uploading_date != null) {
                  item.uploading_date = moment(item.uploading_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.uploading_date = '-';
                }
                if (item.expiry_date != '-' && item.expiry_date != '' && item.expiry_date != null) {
                  item.expiry_date = moment(item.expiry_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.expiry_date = '-';
                }
              });
            }
            console.log("get investor details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/investor");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getinvestor close

    onChangePaymentStatus() {
      console.log("onChangePaymentStatus")
      if (this.paymentDate1 != null) {
        this.paymentDate1 = moment(this.paymentDate1).format("MM-DD-YYYY");
      }
      console.log("this.paymentDate1",this.paymentDate1)
      this.$confirm({
        auth: false,
        message: `Are you sure you want to make payment of ${Number(
          this.alertAmount.toFixed(2)
        ).toLocaleString()} ${
          this.alertAmountCurrency
        }? This is onetime process, Changes cannot be reverted. ?`,
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path =
              "payment-by-investor-id/" +
              this.$route.params.id +
              "/" +
              null +
              "/" +
              null;
            axios
              .put(
                path,
                {
                  updatedStatus: this.editStatus,
                  transactionId: this.tranId,
                  investorTransactionId: this.investorTranId,
                  paymentDate: this.paymentDate1,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 3,
                  tabId: 13,
                  amount: this.alertAmount,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                if (res.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Payment Status updated successfully!"
                  );
                  setTimeout(
                    () => this.$router.go(this.$router.currentRoute),
                    1500
                  );
                } else {
                  Utils.make_alert("success", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                Utils.make_alert("success", "Something went wrong!");
              });
          } else {
            this.$router.go(this.$router.currentRoute);
            // updatedStatus.target.value = prevStatus;
          }
        },
      });
    },

    onChangePaymentStatusOverview(
      updatedStatus,
      paymentId,
      prevStatus,
      index,
      payment_date,
      amount,
      inv_currency_name,
      fund_currency_name,
      invTranID
    ) {
      if (payment_date != null) {
        payment_date = moment(payment_date,this.$store.state.moment_date_format).format("MM-DD-YYYY");
      }
      console.log("payment_date",payment_date)
      this.$confirm({
        auth: false,
        message: `Are you sure you want to make payment of ${Number(
          amount.toFixed(2)
        ).toLocaleString()} ${
          this.loggedInUserGroupId === "Investor"
            ? inv_currency_name
            : fund_currency_name
        }? This is onetime process, Changes cannot be reverted.`,
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            //this function is called when user changes the payment registration status. The status is updated in the database for that specific payment.
            const path =
              "payment-by-investor-id/" +
              this.$route.params.id +
              "/" +
              null +
              "/" +
              null;
            axios
              .put(
                path,
                {
                  updatedStatus: updatedStatus.target.value,
                  transactionId: paymentId,
                  investorTransactionId: invTranID,
                  paymentDate: payment_date,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 3,
                  tabId: 13,
                  amount: amount,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("registration update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Payment Status Updated Successfully!"
                  );
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(
                    () => this.$router.go(this.$router.currentRoute),
                    1500
                  );
                  this.payments[index].status = updatedStatus.target.value;
                } else {
                  console.log("Something went wrong from server!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeStatus close

    onChangeKYCDocStatus(updatedStatus, investorId, docId, prevStatus, index) {
      //this function is called when user changes the borrower kyc document status. The status is updated in the database for that specific kyc document.
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path = "investor/" + investorId;
            axios
              .put(
                path,
                {
                  updateType: "kycDocStatus",
                  updatedKYCDocStatus: updatedStatus.target.value,
                  KYCDocId: docId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 3,
                  tabId: 11,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("kyc update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Document Status updated successfully!"
                  );
                  setTimeout(() => this.$router.go(), 1500);
                  //if you want to send any data into server before redirection then you can do it here
                  // this.$router.go(this.$router.currentRoute);
                  this.investor_kycdocuments_list[index].document_status =
                    updatedStatus.target.value;
                } else {
                  updatedStatus.target.value = prevStatus;
                  Utils.make_alert(
                    "warning",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                updatedStatus.target.value = prevStatus;
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          } else {
            // this.$router.go(this.$router.currentRoute);
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeKYCDocStatus close

    downloadInvDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },

    showCommentModal(invId, docId, comm) {
      //this function opens the comment modal. 'docId' will take input for kyc document id and covenant id both.
      this.investorId = invId;
      this.documentId = docId;
      this.comment = comm;
      $("#comm").modal("show");
    }, //showCommentModal() close

    saveComment() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      //this function saves the comment from comment modal.
      this.$confirm({
        auth: false,
        message: "Confirm submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            const path = "investor/" + this.investorId;
            axios
              .put(
                path,
                {
                  updateType: "comment",
                  comments: this.comment,
                  docId: this.documentId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 3,
                  tabId: 11,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                console.log("comment update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert("success", "Comment updated successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(() => this.$router.go(), 1500);
                } else {
                  console.log("Something went wrong from server!");
                }
              })
              .catch((error) => {
                console.error("error: " + error);
                console.log("Something went wrong!");
              });
          }
        },
      });
    },

    getPaymentByInvestorId() {
      Utils.start_loading();
      if (this.paymentStartDate != null) {
        this.paymentStartDate = moment(this.paymentStartDate).format(
          "MM-DD-YYYY"
        );
      }
      if (this.paymentEndDate != null) {
        this.paymentEndDate = moment(this.paymentEndDate).format("MM-DD-YYYY");
      }
      const path =
        "payment-by-investor-id/" +
        this.$route.params.id +
        "/" +
        this.paymentStartDate +
        "/" +
        this.paymentEndDate;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          this.payments = this.inactive
            ? res.data.payments_inactive
            : res.data.payments_active;
          if(this.payments.length > 0) {
              this.payments.forEach(item => {
                if (item.date != '-' && item.date != null) {
                  item.date = moment(item.date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.date = '-';
                }
              });
            }
          if (this.alertAmount) {
            this.payments.filter((pay) => {
              if (pay.amount)
                if (
                  pay.amount.toFixed(2) ===
                  this.alertAmount.toFixed(2).toString()
                ) {
                  this.tranId = pay.transaction_id;
                  this.investorTranId = pay.investor_transaction_id;
                }
            });
          }
          this.payments.filter((pay) => {
            if (pay.filter_currency_value === undefined)
              pay.filter_currency_value = "-";
            if (pay.benchmark_date)
              pay.benchmark_date = moment(pay.benchmark_date).format(
                "MM-DD-YYYY"
              );
          });
          let isAdmin = this.loggedInUserGroupId == 'Admin' || (this.loggedInUserGroupId == 'Analyst' && this.loggedInUserSubGroupId == 0);
          console.log(isAdmin)
          setTimeout(function () {
            var $ = require("jquery");
            $("#paymentDetails").DataTable({
              paging: true,
              pageLength: 10,
              dom: "Blfrtip",
              buttons: [],
              order: [],
              columnDefs: [
                {
                orderable: false,
                targets: isAdmin
                    ? [8]
                    : [],
              },
              ],
            });
          }, 500);
          this.paymentStartDate = res.data.start_date;
          this.paymentEndDate = res.data.end_date;

          if (res.data.status == "success") {
            console.log("get payment by investor id is successful.");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
        });
    }, //getPaymentByInvestorId() close

    getInvestmentByInvestorId() {
      Utils.start_loading();
      if (this.filter === "date") {
        this.selectedPeriod = null;
        if (this.startDate != null) {
          this.startDate = moment(this.startDate).format("MM-DD-YYYY");
        }
        if (this.endDate != null) {
          this.endDate = moment(this.endDate).format("MM-DD-YYYY");
        }
      }

      if (this.filter === "period") {
        this.startDate = null;
        this.endDate = null;
      }
      const path = this.inActiveToggle
        ? "investment-by-investor-id-active-inactive/" +
          this.$route.params.id +
          "/" +
          this.startDate +
          "/" +
          this.endDate +
          "/" +
          this.selectedPeriod +
          "/" +
          this.selectedStatus
        : "investment-by-investor-id/" +
          this.$route.params.id +
          "/" +
          this.startDate +
          "/" +
          this.endDate +
          "/" +
          this.selectedPeriod +
          "/" +
          this.selectedStatus;

      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.investments = res.data.investments;
          this.investments.filter((inv) => {
            if (inv.filter_currency === undefined) inv.filter_currency = "-";
          });
          if(this.investments.length > 0) {
              this.investments.forEach(item => {
                if (item.investment_date != '-' && item.investment_date != null && item.investment_date != '0.0') {
                  item.investment_date = moment(item.investment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.investment_date = '-';
                }
                if (item.maturity_date != '-' && item.maturity_date != null && item.maturity_date != '0.0') {
                  item.maturity_date = moment(item.maturity_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.maturity_date = null;
                }
              });
            }
          let isAdmin = this.loggedInUserGroupId === "Admin" ||
                        (this.loggedInUserGroupId == 'Analyst' &&
                          this.loggedInUserSubGroupId == 0);
          var $ = require("jquery");
          if (this.inActiveToggle)
            setTimeout(function () {
              $("#investmentDetails").DataTable({
                paging: true,
                pageLength: 10,
                dom: "Blfrtip",
                buttons: [],
                order: [],
                scrollX: true,
              });
            }, 500);
          else
            setTimeout(function () {
              $("#investmentDetails").DataTable({
                paging: true,
                pageLength: 10,
                dom: "Blfrtip",
                buttons: [],
                order: [],
                scrollX: true,
                columnDefs: [
                  {
                    orderable: false,
                    targets: isAdmin ? [20] : [],
                  },
                ],
              });
            }, 500);
          this.selectedPeriod = res.data.period;
          this.selectedStatus = res.data.t_status;
          //set the start and end date according to filter selected
          if (this.filter === "period") {
            this.startDatePeriod = res.data.start_date;
            this.endDatePeriod = res.data.end_date;
            this.startDate = null;
            this.endDate = null;
          } else if (this.filter === "date") {
            this.startDate = res.data.start_date;
            this.endDate = res.data.end_date;
            this.startDatePeriod = null;
            this.endDatePeriod = null;
          }
          if (res.data.status == "success") {
            console.log("get investment by investor id is successful.");
          } else if (res.data.status == "conversion_rate_not_available") {
            Utils.make_alert("success", "Conversion rate not available.");
          }
          Utils.stop_loading();
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
        });
    }, //getInvestmentByInvestorId() close

    // filter function
    filterData(type) {
      if (type === "investment") {
        if (
          (this.startDate !== null && this.endDate === null) ||
          (this.startDate === null && this.endDate !== null)
        ) {
          Utils.make_alert(
            "warning",
            "Both From & To Dates are required to perform Date Filter"
          );
          return;
        } else {
          var $ = require("jquery");
          $("#investmentDetails").DataTable().destroy();
          this.getInvestmentByInvestorId();
        }
      } else {
        var $ = require("jquery");
        $("#paymentDetails").DataTable().destroy();
        this.getPaymentByInvestorId();
      }
    },

    resetData(type) {
      if (type === "investment") {
        //resets investment filters
        this.startDate = null;
        this.endDate = null;
        this.startDatePeriod = null;
        this.endDatePeriod = null;
        this.selectedPeriod = null;
        this.selectedStatus = "Open";
        this.filter = "date";
        this.selectedCurrency = null;
        var $ = require("jquery");
        $("#investmentDetails").DataTable().destroy();
        this.getInvestmentByInvestorId();
      } else {
        //resets payment filters
        this.paymentStartDate = null;
        this.paymentEndDate = null;
        this.selectedPaymentCurrency = null;
        var $ = require("jquery");
        $("#paymentDetails").DataTable().destroy();
        this.getPaymentByInvestorId();
      }
    },

    deleteInvestment(transactionId) {
      //this function will be called to delete a specific investor
      this.$confirm({
        auth: false,
        message: "Confirm Delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path =
              "investment-by-transaction-id/" + this.id + "/" + transactionId;
            axios
              .delete(path, {
                data: {
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 3,
                  tabId: 12,
                },
                headers: {
                  "Content-type": "application/json",
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log(
                  "delete investment status: " + response.data.status
                );
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Investment deleted successfully!"
                  );
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(
                    () => this.$router.go(this.$router.currentRoute),
                    1500
                  );
                } else if (response.data.status == "deletion_not_allowed") {
                  Utils.make_alert("success", "Deletion not allowed");
                  setTimeout(
                    () => this.$router.go(this.$router.currentRoute),
                    1500
                  );
                } else {
                  Utils.make_alert("success", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.log("error: " + error);
                Utils.make_alert("success", "Something went wrong!");
              });
          }
        },
      });
    }, //deleteInvestment() close

    getActionUpcoming(type, index) {
      var path2 = "upcoming-payments";
      var fund_list;
      axios
        .get(path2, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          if (type == "investor_transaction_scheduled") {
            fund_list = res.data.investor_transaction_scheduled_list[index];
            this.selectedExpense = fund_list.investor_name;
            this.amount = fund_list.amount;
            this.description = fund_list.description
              ? fund_list.description
              : null;
            this.selectedCurrency = fund_list.currency_short_name;
            this.paymentDate1 = fund_list.date;
            this.id = fund_list.id;
            this.showModal();
          } else if (type == "interest_seven_days") {
            fund_list = res.data.interest_df_seven_days_list[index];
            this.selectedExpense = fund_list.investor_name;
            this.amount = fund_list.amount;
            this.description = fund_list.description
              ? fund_list.description
              : null;
            this.selectedCurrency = fund_list.currency_short_name;
            this.paymentDate1 = fund_list.date;
            this.id = fund_list.id;
            this.showModal();
          } else if (type == "maturity_seven_days") {
            fund_list = res.data.maturity_df_seven_days_list[index];
            this.selectedExpense = fund_list.investor_name;
            this.amount = fund_list.amount;
            this.description = fund_list.description
              ? fund_list.description
              : null;
            this.selectedCurrency = fund_list.currency_short_name;
            this.paymentDate1 = fund_list.date;
            this.id = fund_list.id;
            this.showModal();
          } else if (type == "interest_before_current_days") {
            fund_list = res.data.interest_df_before_current_days_list[index];
            this.selectedExpense = fund_list.investor_name;
            this.amount = fund_list.amount;
            this.description = fund_list.description
              ? fund_list.description
              : null;
            this.selectedCurrency = fund_list.currency_short_name;
            this.paymentDate1 = fund_list.date;
            this.id = fund_list.id;
            this.tranId = fund_list.id;
            this.investorTranId = fund_list.investor_transaction_id;
            this.showModal();
          } else if (type == "maturity_before_current_days") {
            fund_list = res.data.maturity_df_before_current_days_list[index];
            this.selectedExpense = fund_list.investor_name;
            this.amount = fund_list.amount;
            this.description = fund_list.description
              ? fund_list.description
              : null;
            this.selectedCurrency = fund_list.currency_short_name;
            this.paymentDate1 = fund_list.date;
            this.id = fund_list.id;
            this.tranId = fund_list.id;
            this.investorTranId = fund_list.investor_transaction_id;
            this.showModal();
          }
        })
        .catch((error) => {
          console.error("error: " + error);
        });
    },
  }, //methods close

  mounted() {
    this.index = this.$route.params.index;
    this.type = this.$route.params.type;
    this.alertAmount = this.$route.params.amount;
    this.alertAmountCurrency = this.$route.params.currency;
    //keep current tab active
    if (this.$route.params.activeTab) {
      activeTab = this.$route.params.activeTab;
      if (activeTab) {
        $('.nav-tabs a[href="' + activeTab + '"]').tab("show");
      }
      if (this.index >= 0) {
        $('.nav-tabs a[href="#three"]').tab("show");

        this.getActionUpcoming(this.type, this.index);
      }
    } else {
      $('a[data-toggle="pill"]').on("shown.bs.tab", function (e) {
        localStorage.setItem("investOverTab", $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("investOverTab");

      //first open the outer active tab then the inner active tab
      if (activeTab) {
        $('.nav-tabs a[href="' + activeTab + '"]').tab("show");
      }
      if (this.index >= 0) {
        $('.nav-tabs a[href="#three"]').tab("show");
        this.getActionUpcoming(this.type, this.index);
      }
    }

    (this.tabIndex = this.tabs.findIndex((tab) => tab === this.$route.hash)),
      this.getInvestor();
    this.getInvestmentByInvestorId();
    this.getPaymentByInvestorId();
  },
  created() {
    this.id = this.$route.params.id;
    this.inactive =
      this.$store.state.inactive === "investor_inactive" ? true : false;
    this.selectedStatus = "Open";
    this.filter = "date";
    this.getCurrencies();
    //this.getInvestmentByInvestorId();
    //this.getPaymentByInvestorId();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style scoped>
.wg {
  font-weight: bold !important;
}
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}
.toooltip {
  font-size: 14px;
}
.toooltip .tooltiptext {
  top: -5px;
}
.note {
  font-size: 12px;
  color: red;
}
</style>
