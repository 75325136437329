<!-- This is Investor page. It shows list of all the Investors and their details -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-file-text"></i> Collateral Charges
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-3 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">From Date</label>
                  <input id="datepicker" class="datepicker form-control" placeholder="Select Date" />
                </div>
              </div>

              <div class="col-md-3 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">To Date</label>
                  <input id="datepickera" class="datepicker form-control" placeholder="Select Date" />
                </div>
              </div>

              <div class="col-md-3 col-sm-2 col-12">
                <button type="button" class="btn-sm btn btn-dark sh_n mt">Reset</button>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          Total:
                          <span>10,000</span>
                        </h1>
                      </div>
                      <!-- /.col -->
                      <!-- <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <div class="form-group has-search m-0">
                              <span class="fa fa-search form-control-feedback"></span>
                              <input type="text" class="form-control" placeholder="Search" />
                            </div>
                          </li>
                        </ul>
                      </div> -->
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <!------menton here style="height: 300px;"-------->
                    <table class="table table-head-fixed text-nowrap">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Amount</th>
                          <th class="c">Currency</th>
                          <th class="c">Document</th>
                          <!-- <th class="c">Receipt no.</th> -->
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="loan in LoansUtiliReq" :key="loan.util_id">
                          <td v-if="loan.requested_date !== null ">{{ loan.requested_date }}</td>
                          <td v-else>-</td>
                          <td v-if="loan.requested_amt !== null ">{{ loan.requested_amt }}</td>
                          <td v-else>-</td>
                          <td v-if="ddn.currency_short_name !== null ">{{ ddn.currency_short_name }}</td>
                          <td v-else>-</td>
                          <td class="c">
                            <a href="#" data-toggle="modal" data-target="#view_doc">
                              <i class="icofont-file-pdf text-danger"></i>
                            </a>
                          </td>
                          <!-- <td v-if="loan.Receipt_no !== null ">{{ loan.Receipt_no }}</td>
                          <td v-else>-</td>-->
                        </tr>
                        <!----------------------------------------------->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>
    <!-- ./wrapper -->

    <!-- ./modal start -->
    <div class="modal fade" id="view_doc">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-eye-alt"></i> View Document
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table">
                    <tbody>
                      <tr>
                        <td class="col-10">Receipt</td>
                        <td class="c col-1">
                          <a href="asset/images/document.jpg" target="_blank">
                            <i class="icofont-file-pdf text-danger"></i>
                          </a>
                        </td>
                        <td class="c col-1">
                          <a href="asset/images/document.jpg" download>
                            <i class="icofont-download text-info"></i>
                          </a>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="col-10">Receipt</td>
                        <td class="c col-1">
                          <a href="asset/images/document.jpg" target="_blank">
                            <i class="icofont-file-pdf text-danger"></i>
                          </a>
                        </td>
                        <td class="c col-1">
                          <a href="asset/images/document.jpg" download>
                            <i class="icofont-download text-info"></i>
                          </a>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="col-10">Receipt</td>
                        <td class="c col-1">
                          <a href="asset/images/document.jpg" target="_blank">
                            <i class="icofont-file-pdf text-danger"></i>
                          </a>
                        </td>
                        <td class="c col-1">
                          <a href="asset/images/document.jpg" download>
                            <i class="icofont-download text-info"></i>
                          </a>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="col-10">Receipt</td>
                        <td class="c col-1">
                          <a href="asset/images/document.jpg" target="_blank">
                            <i class="icofont-file-pdf text-danger"></i>
                          </a>
                        </td>
                        <td class="c col-1">
                          <a href="asset/images/document.jpg" download>
                            <i class="icofont-download text-info"></i>
                          </a>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <a @click="$router.go(-1)">
                <button type="button" class="btn-sm btn btn-dark sh" data-dismiss="modal">Cancel</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>