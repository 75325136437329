<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-file-document"></i> Site Inspection
                        History
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row"></div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th class="c" id="expiryDate">Date</th>
                        <th class="c" id="status">Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="insp in siteInspections" :key="insp.id">
                        <td v-if="insp.site_inspection_date !== null">
                          {{ insp.site_inspection_date }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="insp.status !== null" class="c">
                          {{ insp.status }}
                        </td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.content-wrapper -->
</template>

<script>
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import moment from "moment";

export default {
  data() {
    return {
      id: null,
      inactive: null,
      siteInspections: [],
    };
  },
  methods: {
    getWarehouse() {
      //this function is called on page load and created events. it will show details on Warehouse with Warehouseid equal to this.id
      const path = this.inactive
        ? "single-warehouse-summary-overview-inactive/" + this.id
        : "single-warehouse-summary-overview-active/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            if (this.inactive) {
              this.siteInspections =
                res.data.warehouse.site_inspection_details_inactive;
            } else {
              if (this.loggedInUserGroupId === "Collateral Manager") {
                if (
                  res.data.warehouse.site_inspection_cm_details_active.length
                ) {
                  this.siteInspections =
                    res.data.warehouse.site_inspection_cm_details_active;
                  this.siteInspections = this.siteInspections.filter(
                    (col) =>
                      col.collateral_manager_master_id.toString() ===
                        this.loggedInUserSubGroupId &&
                      col.site_inspection_permission === 0
                  );
                }
              } else {
                if (res.data.warehouse.site_inspection_active_details.length) {
                  this.siteInspections =
                    res.data.warehouse.site_inspection_active_details;
                }
              }
            }
            if(this.siteInspections.length > 0) {
              this.siteInspections.forEach(item => {
              if (item.site_inspection_date != '-' && item.site_inspection_date != null) {
                item.site_inspection_date = moment(item.site_inspection_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.site_inspection_date = '-';
              }
              });
            }
            console.log("get Warehouse details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getWarehouse close
  },
  mounted() {
    this.getWarehouse();
  },
  created() {
    this.id = this.$route.params.id;
    this.inactive =
      this.$store.state.inactive === "warehouse_inactive" ? true : false;
  },
};
</script>
