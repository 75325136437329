<template>
  <div>
    <!-- <router-link to="/login"></router-link> -->
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("destroyToken");
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.removeItem(key);
    this.$router.push({ name: "login" });
  }
};
</script>

<style>
</style>