<!-- This is fund-disctribution page.  -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div id="new_fund" class="container-fluid ot_tb pt-3">

          <div class="row">
            <div class="col-lg-7 col-md-12 col-sm-12">


              <div class="inputbx">
                <div class="row align_c">
                  <div class="col-md-4 col-sm-12">
                    <div class="form-group fc">
                      <select 
                        class="selectpicker" 
                        aria-label="select loan by" 
                        title="select loan by"
                        data-dropup-auto="false" 
                        data-live-search="true"  
                        v-model="selectedLoanBy"
                        @change="getMasterDropdownBy()"
                      >
                        <option
                          v-for="lb in LoanBy"
                          :value="lb.id"
                          :key="lb.id"
                        >
                          {{ lb.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-4 col-sm-12">
                    <div class="form-group fc">
                      <select 
                        class="selectpicker" 
                        aria-label="select year" 
                        title="select year"
                        data-dropup-auto="false" 
                        data-live-search="true"  
                        v-model="selectedLoanYear"
                        @change="fetchLoanLevelTransactionData()"
                        :disabled="disabledLastTenYears"
                      >
                        <option
                          v-for="lty in LastTenYears"
                          :value="lty.id"
                          :key="lty.id"
                        >
                          {{ lty.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-4 col-sm-12">
                    <div class="form-group fc">
                      <select 
                        class="selectpicker" 
                        aria-label="select amount by" 
                        title="select amount by"
                        data-dropup-auto="false" 
                        data-live-search="true"  
                        v-model="selectedAmountBy"
                        @change="fetchLoanLevelTransactionData()"
                      >
                        <option
                          v-for="amt in AmountBy"
                          :value="amt.id"
                          :key="amt.id"
                        >
                          {{ amt.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>
              </div>


              <div class="row mt-3">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="title">
                        <div class="row align_c">
                          <div class="col-md-8 col-sm-8">
                            <h6 class="tp_mid text2">
                              {{ selectedLoanByName }} wise {{ selectedAmountByName }} for {{ selectedLoanYearName }} 
                            </h6>
                          </div>
                          <div class="col-md-4 col-sm-4">
                            <h6 v-if="selectedLoanByName != 'Currency'" class="jst_fe">
                              (Amt In Lakhs and convt. in Std. USD)
                            </h6>
                            <h6 v-else-if="selectedLoanByName == 'Currency'" class="jst_fe">
                              (Amt In Lakhs)
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="ht315">
                        <apexchart 
                          v-if="LoanLevelTransactionChartDataReady" 
                          type="bar" 
                          height="300"
                          :options="LoanLevelTransactionChartOptions" 
                          :series="LoanLevelTransactionSeries">
                        </apexchart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-lg-5 col-md-12 col-sm-12">

              <div class="inputbx">
                <div class="row align_c">
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group fc">
                      <select 
                        class="selectpicker" 
                        aria-label="select" 
                        title="select"
                        data-dropup-auto="false" 
                        data-live-search="true"  
                        v-model="selectedMasterDropdown"
                        @change="fetchMasterLevelTransactionData()"
                      >
                        <option
                          v-for="md in MasterDropdown"
                          :value="md.id"
                          :key="md.id"
                        >
                          {{ md.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12">
                    <div class="form-group fc">
                      <select 
                        class="selectpicker" 
                        aria-label="select year" 
                        title="select year"
                        data-dropup-auto="false" 
                        data-live-search="true"  
                        v-model="selectedYearWithoutAll"
                        @change="fetchMasterLevelTransactionData()"
                      >
                        <option
                          v-for="lty in LastTenYearsWithoutAll"
                          :value="lty.id"
                          :key="lty.id"
                        >
                          {{ lty.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>
              </div>

              <div class="row mt-3">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="title">
                        <div class="row align_c">
                          <div class="col-md-6 col-sm-6">
                            <h6 class="tp_mid text2">
                              {{ selectedMasterDropdownName }}
                            </h6>
                          </div>
                          <div class="col-md-6 col-sm-6">
                            <h6 v-if="selectedLoanByName != 'Currency'" class="jst_fe">
                              (Amt In Lakhs and convt. in Std. USD)
                            </h6>
                            <h6 v-else-if="selectedLoanByName == 'Currency'" class="jst_fe">
                              (Amt In Lakhs)
                            </h6>
                          </div>

                        </div>
                      </div>
                      <div class="ht315">
                        <apexchart 
                          v-if="MasterLevelTransactionChartDataReady" 
                          type="line" 
                          height="300"
                          :options="MasterLevelTransactionChartOptions" 
                          :series="MasterLevelTransactionSeries">
                        </apexchart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- /.content-wrapper -->

  </div>
  <!-- ./wrapper -->
</template>


<script>
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";
Vue.component('apexchart', VueApexCharts);

export default {
  data() {
    return {
      currencyName: "",
      lastMonthValue: 0.00,
      PercentageChange: 0.00,
      todayDate: moment().format('DD MMMM YYYY'),
      top3Flag: true,      // Fund Dropdown

      LoanBy : [
        { id: "Borrower", name: "Borrower" },
        { id: "Interest Rate Type", name: "Interest Rate Type" },
        { id: "Country", name: "Country" },
        { id: "Currency", name: "Currency" },
        { id: "Methodology", name: "Methodology" }
      ],
      selectedLoanBy: 'Borrower',
      selectedLoanByName: 'Borrower',

      MasterDropdown : [],
      selectedMasterDropdown : null,
      selectedMasterDropdownName : null,

      LastTenYears : [],
      disabledLastTenYears : false,
      LastTenYearsWithoutAll : [],
      selectedLoanYear : null,
      selectedLoanYearName : null,
      selectedYearWithoutAll : null,

      // Amount By Dropdown
      AmountBy: [
        { id: "Disbursement", name: "Disbursements" },
        { id: "Repayment", name: "Repayments" },
        { id: "Interest", name: "Interest" },
        { id: "Penalty", name: "Penalty" },
      ],
      selectedAmountBy: 'Disbursement',
      selectedAmountByName: 'Disbursements',

      Colors: ['#1930AB', '#0070C0', '#00B0F0', '#7F7F7F', '#D9D9D9'],
      
      // Master Level Transaction
      MasterLevelTransactionChartOptions: {},
      MasterLevelTransactionSeries: [],
      MasterLevelTransactionChartData: {},
      // MasterLevelTransactionChartData: {
      //   xAxisData: ['Jan', 'Feb', 'Mar','Apr'],
      //   seriesData: [
      //     {
      //       name: 'Disbursement',
      //       data: [15, 7, 20 , 25]
      //     },
      //     {
      //       name: 'Repayments',
      //       data: [25, 17, 30 , 15]
      //     },
      //   ]
      // },
      MasterLevelTransactionChartDataReady: false,


      // Loan Level Transaction
      LoanLevelTransactionChartOptions: {},
      LoanLevelTransactionSeries: [],
      LoanLevelTransactionChartData: {},
      // LoanLevelTransactionChartData: {
      //   xAxisData: ['Borrower 1', 'Borrower 2', 'Borrower 3'],
      //   seriesData: [
      //     {
      //       name: 'Disbursement',
      //       data: [5, 8, 3]
      //     }
      //   ]
      // },
      LoanLevelTransactionChartDataReady: false,

      desiredTicks: 3,
      isLoading: false,

      isBasicDetailsSubmitted: false,
      isSubmitted: false,
    };
  },
  methods: {
    fetchMasterLevelTransactionData() {
      // Simulated API response
      // For a bar chart, the series data structure can stay the same if it suits your data representation
      // this.MasterLevelTransactionSeries = this.MasterLevelTransactionChartData.seriesData.map(series => ({
      //   name: series.name,
      //   data: series.data // Assuming this is already in the correct format for a bar chart
      // }));

      // console.log("MasterLevelTransactionSeries ", this.MasterLevelTransactionSeries)
      // this.setMasterLevelTransactionChartOptionData(this.MasterLevelTransactionChartData.xAxisData)
      const path = "get-all-loan-level-transactions" +
        "/" +
        this.selectedLoanBy +
        "/" +
        this.selectedYearWithoutAll +
        "/" +
        this.selectedMasterDropdown;
      this.$nextTick(() => {
        console.log("refresh")
        $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
      });
      console.log(path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.MasterLevelTransactionChartData = res.data.MasterLevelTransactionChartData;
            // For a bar chart, the series data structure can stay the same if it suits your data representation
            this.MasterLevelTransactionSeries = this.MasterLevelTransactionChartData.seriesData.map(series => ({
              name: series.name,
              data: series.data // Assuming this is already in the correct format for a bar chart
            }));

            console.log("MasterLevelTransactionSeries ", this.MasterLevelTransactionSeries)
            this.setMasterLevelTransactionChartOptionData(this.MasterLevelTransactionChartData.xAxisData)
            let name = this.MasterDropdown.find(item => item.id == this.selectedMasterDropdown).name
            this.selectedMasterDropdownName = name;

          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });

    },

    fetchLoanLevelTransactionData() {
      console.log("fetchLoanLevelTransactionData")
      if(this.selectedAmountBy == "Interest" || this.selectedAmountBy == "Penalty"){
        const currentYear = moment().year();
        this.disabledLastTenYears = true;
        this.selectedLoanYear = currentYear.toString();
      } else {
        this.disabledLastTenYears = false;
      }
      console.log("fetchLoanLevelTransaction",this.selectedLoanBy)
      // Simulated API response
      const path = "get-loan-level-transactions/" + 
      this.selectedLoanBy + 
      "/" + 
      this.selectedLoanYear +
      "/" +
      this.selectedAmountBy;
      console.log(path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.selectedLoanByName = this.selectedLoanBy
            this.selectedAmountByName = this.selectedAmountBy
            this.selectedLoanYearName = this.selectedLoanYear
            this.LoanLevelTransactionChartData = res.data.top_borrower_data;
            // For a bar chart, the series data structure can stay the same if it suits your data representation
            this.LoanLevelTransactionSeries = this.LoanLevelTransactionChartData.seriesData.map(series => ({
              name: series.name,
              data: series.data // Assuming this is already in the correct format for a bar chart
            }));

            console.log("LoanLevelTransactionSeries ", this.LoanLevelTransactionSeries)
            this.setLoanLevelTransactionChartOptionData(this.LoanLevelTransactionChartData.xAxisData)


          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });

    },

    setLoanLevelTransactionChartOptionData(xAxisData) {
      console.log("setLoanLevelTransactionChartOptionData")
      // const yAxisTitleLength = this.LoanLevelTransactionChartOptions.seriesData[0].name[0].length;
      // Setting up chart options for a bar chart
      this.LoanLevelTransactionChartOptions = {
        chart: {
          id: 'vuechart-example',
          type: 'bar', // Changed from 'line' to 'bar'
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: this.Colors, // Add your custom colors here
        yaxis: {
          // Set the y-axis name
          // title: {
          //   text: 'Amounts in millions',
          //   style: {
          //     fontFamily: 'Calibri',
          //     fontSize: '14px',
          //   },
          // },
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
          }
        },
        xaxis: {
          type: 'category',
          categories: xAxisData, // your original categories
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
            formatter: function(val) {
              // Truncate string to a maximum of 20 characters when there are more than 2 bars
              if (xAxisData.length > 1 && xAxisData.length < 4  && val.length > 20) {
                return val.substring(0, 20) + '...'; // Adjust the number of characters as needed
              } else if (xAxisData.length >= 4) {
                return val.substring(0, 10) + '...'; // Adjust the number of characters as needed
              } else {
                return val; // Full label for 2 or fewer bars
              }
            }
          },
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 0, 
            bottom: 0,
            left: 0
          }
        },
        legend: {
          position: 'bottom', 
          horizontalAlign: 'center', 
          offsetX: 0, 
          offsetY: 0, 
          fontFamily: 'Calibri',
          fontSize: '12px'
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              return `${value} Lcs`;
            }
          },
          x: {
            formatter: function(val, opts) {
              // Access the full label from the original categories array using the dataPointIndex
              let fullText = xAxisData[opts.dataPointIndex];
              // Find spaces to insert line breaks, but do not exceed a certain length (e.g., 40 characters)
              let breakPoint = fullText.lastIndexOf(' ', 40);
              if (breakPoint === -1 || fullText.length <= 40) {
                return fullText; // No need to break if short enough or no space found
              } else {
                return fullText.substring(0, breakPoint) + '<br>' + fullText.substring(breakPoint + 1);
              }
            }
          },
        },
      };
      this.LoanLevelTransactionChartDataReady = true;
    },

    setMasterLevelTransactionChartOptionData(xAxisData) {
      // const yAxisTitleLength = this.MasterLevelTransactionChartOptions.seriesData[0].name[0].length;
      // console.log("yAxisTitleLength",yAxisTitleLength)
      
      // Setting up chart options for a bar chart
      this.MasterLevelTransactionChartOptions = {
        chart: {
          id: 'vuechart-example',
          type: 'line', // Changed from 'line' to 'bar'
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: this.Colors, // Add your custom colors here
        // markers: {
        //   size: 5,
        //   hover: {
        //     size: 8
        //   }
        // },
        yaxis: {
          // Set the y-axis name
          // title: {
          //   text: 'Amounts in millions',
          //   style: {
          //     fontFamily: 'Calibri',
          //     fontSize: '14px',
          //   },
          // },
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
          }
        },
        xaxis: {
          categories: xAxisData, // your original categories
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
          },
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 5, 
            bottom: 0,
            left: 0
          }
        },
        legend: {
          position: 'bottom', 
          horizontalAlign: 'center', 
          offsetX: 0, 
          offsetY: 0, 
          fontFamily: 'Calibri',
          fontSize: '12px'
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          // x: {
          //   formatter: function(val, opts) {
          //     const fullLabel = xAxisData[opts.dataPointIndex];
          //     // Insert a line break after every nth character (e.g., every 20 characters)
          //     const wrapAt = 20; // Adjust this number to suit your preference
          //     const wrappedLabel = fullLabel.replace(
          //       new RegExp(`(.{${wrapAt}})`, 'g'), '$1<br>'
          //     );
          //     return wrappedLabel;
          //   }
          // },
          y: {
            formatter: function (value) {
              // Calculate percentage
              return `${value} Lcs`;
            }
          }
          // x: {
          //   formatter: function(val, opts) {
          //     // Access the full label from the original categories array using the dataPointIndex
          //     return xAxisData[opts.dataPointIndex];
          //   }
          // },
        },
      };
      this.MasterLevelTransactionChartDataReady = true;
    },

    getMasterDropdownBy(){
      console.log("get Masters being called")
      const path = "get-all-master-dropdown-by/" + this.selectedLoanBy;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          this.selectedMasterDropdown = null
          this.selectedMasterDropdownName = null
          // this.MasterDropdown = []
          this.MasterDropdown = res.data.data;
          if(this.MasterDropdown.length > 0){
            this.selectedMasterDropdown = this.MasterDropdown[0].id;
            this.selectedMasterDropdownName = this.MasterDropdown[0].name;
          }
          console.log("this.selectedMasterDropdown",this.selectedMasterDropdown)
          console.log("this.selectedMasterDropdownName",this.selectedMasterDropdownName)
          this.$nextTick(() => {
            console.log("refresh");
            $('.selectpicker').selectpicker('refresh'); // Refresh selectpicker if you're using it
          });
          this.fetchLoanLevelTransactionData();
          this.fetchMasterLevelTransactionData();
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
        });
    },

    getYears() {
      const currentYear = moment().year();
      const startYear = 2000;

      const yearsDifference = currentYear - startYear + 1; // +1 to include both current and start year

      const lastYears = Array.from({ length: yearsDifference }, (value, index) => ({
        id: (currentYear - index).toString(),
        name: (currentYear - index).toString()
      }));

      // Prepend the "All" option to the list of years
      const yearsWithOptions = [...lastYears];
      let yearsWithOptionsWithoutAll = [ ...lastYears];
      this.LastTenYears = yearsWithOptions;
      this.LastTenYearsWithoutAll = yearsWithOptionsWithoutAll;

      // Optionally, if you need a selected year and its name without the "All" option
      this.selectedLoanYear = currentYear.toString();
      this.selectedLoanYearName = currentYear.toString();
      this.selectedYearWithoutAll = currentYear.toString();

      // Ensure the dropdown is refreshed to display the updated years list
        this.$nextTick(() => {
          console.log("refresh");
          $('.selectpicker').selectpicker('refresh'); // Refresh selectpicker if you're using it
        });

      // Fetch data or perform other actions as needed
      this.getMasterDropdownBy()
    },
    
  },
  created() {
    this.getYears();
  }
};
</script>