<template>
  <div class="container-fluid h-100">
    <div class="row justify-content-center h-100">
      <div class="col-lg-5 col-md-5" id="side_view">
        <div
          class="blue_portion"
          style="background-image: url('/asset/images/sidemenu_bg.png')"
        >
          <div class="tx">
            <h3>LUNDY INVESTORS</h3>
            <h4>Tailored Solution for Wealth Creation</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-7" id="grey">
        <div class="login_mid-Portion">
          <center>
            <img
              src="/asset/images/AdminLTELogo.png"
              class="img-fluid login_logo"
            />
          </center>
          <form @submit.prevent="submitLogin()">
            <div v-if="error" class="alert alert-danger" role="alert">
              {{ error }}
              <!-- <error v-if="error" :error="error"/> -->
            </div>
            <div class="form-group">
              <input
                type="email"
                v-model="email"
                id="email"
                name="email"
                class="form-control"
                :class="{ 'is-invalid': isSubmitted && $v.email.$error }"
                placeholder="Email"
                autocomplete="off"
              />

              <div
                v-if="isSubmitted && !$v.email.error"
                class="invalid-feedback"
              >
                <span v-if="!$v.email.email">Please provide valid email</span>
                <span v-if="!$v.email.required">Email field is required</span>
              </div>
            </div>
            <div class="form-group">
              <input
                type="password"
                v-model="password"
                id="password"
                name="password"
                class="form-control"
                :class="{ 'is-invalid': isSubmitted && $v.password.$error }"
                placeholder="Password"
              />

              <div
                v-if="isSubmitted && $v.password.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.password.required"
                  >Password field is required</span
                >
                <span v-if="$v.password.required && !$v.password.minLength"
                  >Password should be at least 6 characters long</span
                >
                <span v-if="$v.password.required && !$v.password.maxLength"
                  >Password should not be more than 20 characters long</span
                >
                <span
                  v-if="
                    $v.password.required &&
                      $v.password.minLength &&
                      $v.password.maxLength &&
                      !$v.password.valid
                  "
                  >Password must have atleast One Uppercase, One Lowercase, One
                  Number and One Special Character</span
                >
              </div>
            </div>

            <!-- <div class="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                class="custom-control-input"
                id="remember_me"
                name="remember_me"
                v-model="remember_me"
                @click="rememberMe"
              />
              <label class="custom-control-label" for="remember_me">Remember me!</label>
            </div>-->
            <!-- 
            <router-link
              to="/admin-dashboard"
              type="button"
              class="btn btn-block btn-primary btn-lg sh lg_btn"
            >Sign In</router-link>-->
            <button
              type="button"
              id="login_btn"
              class="btn btn-block btn-primary btn-lg sh lg_btn"
              style="color: white"
              @click="submitLogin"
            >
              Sign In
            </button>
          </form>
          <p class="forgot_pass">
            <router-link to="/forgot_password">
              <i class="icofont-key"></i> Forgot Password?
            </router-link>
            <!-- <a href="forgot_password.html">
              <i class="icofont-key"></i> Forgot
              Password?
            </a>-->
          </p>
        </div>

        <p class="bt_text">
          © 2022, Design & Development by
          <a href="https://www.decimalpointanalytics.com/"
            >Decimal Point Analytics Pvt Ltd.</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import { reactive } from "vue";
// import { useVuelidate } from "@vuelidate/core";
// import { required, email, minLength } from "@vuelidate/validators";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
// import Error from './Error.vue'
export default {
  name: "Login",
  // components: { Error },
  data() {
    return {
      email: "",
      password: "",
      // remember_me: false,
      error: "",
      authError: false,
      isSubmitted: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
      valid: function(value) {
        const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSpecial = /[#?!@$%^&*-]/.test(value);
        return (
          containsUppercase &&
          containsLowercase &&
          containsNumber &&
          containsSpecial
        );
      },
    },
  },
  methods: {
    submitLogin() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store
        .dispatch("retrieveToken", {
          username: this.email,
          password: this.password,
        })
        .then((response) => {
          // alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.email));
          if (this.loggedInUserGroupId === "Collateral Manager")
            this.$router.push({ name: "Collateral" });
          else if (this.loggedInUserGroupId === "Offtaker")
            this.$router.push({ name: "Offtaker" });
          else this.$router.push({ name: "AdminDashboard" });
        })
        .catch((error) => {
          // this.$router.go(this.$router.currentRoute);
          // alert("Email or Password Incorrect!");
          // if (this.$router.path !== "login") {
          //   this.$router.push("login");
          // }
          this.error = "Invalid Email or Password!";
          this.$store.commit("setLoginFailed", { error });
          this.logginError = error;
          this.$router.push({ name: "login" });
        });

      // rememberMe() {
      //   if ((this.remember_me = true)) {
      //     (localStorage.username = this.email),
      //       (localStorage.password = this.password);
      //   } else {
      //     (localStorage.username = ""), (localStorage.password = "");
      //   }
      // }
    },
  },
  mounted() {
    $("#email , #password").keypress(function(e) {
      var key = e.which;
      if (key == 13) {
        // the enter key code
        console.log("LOGIN...");
        $("#login_btn").click();
      }
    });
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style scoped>
.error-msg {
  color: red;
  font-weight: bold;
}
.login_img {
  background-color: #d2d2d2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60%;
}
#side_view {
  height: 100%;
  padding: 0px;
}
</style>
