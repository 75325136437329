<!-- This is Shipping Tracker page. It shows list of all the Shipping Trackers and their details -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid">
            <div class="row">
              <div
                class="col-md-2 col-sm-5 col-6"
                v-if="loggedInUserGroupId !== 'Borrower'"
              >
                <div class="form-group fc">
                  <label for="selectedBorrower" class="required-field"
                    >Borrower Name</label
                  >
                  <select
                    id="selectedBorrower"
                    class="form-control"
                    name="selectedBorrower"
                    v-model="selectedBorrower"
                    @change="getFilteredFacilities()"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="bor in borrowers"
                      :value="bor.borrower_id"
                      :key="bor.borrower_id"
                    >
                      {{ bor.borrower_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="selectedFacility">Facility</label>
                  <select
                    id="selectedFacility"
                    class="form-control"
                    name="selectedFacility"
                    v-model="selectedFacility"
                    @change="getFilteredCollaterals()"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="fac in facilities"
                      :value="fac.id"
                      :key="fac.id"
                    >
                      {{ fac.facility_registration_id }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="selectedCollateral">Collateral</label>
                  <select
                    id="selectedCollateral"
                    class="form-control"
                    name="selectedCollateral"
                    v-model="selectedCollateral"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="col in collaterals"
                      :value="col.id"
                      :key="col.id"
                    >
                      {{ col.collateral_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="selectedOfftaker">Offtaker</label>
                  <select
                    id="selectedOfftaker"
                    class="form-control"
                    name="selectedOfftaker"
                    v-model="selectedOfftaker"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="off in offtakers"
                      :value="off.offtaker_id"
                      :key="off.offtaker_id"
                    >
                      {{ off.offtaker_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="loggedInUserGroupId != 'Borrower'" class="col-3"></div>
              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">From Date</label>

                  <datepicker
                    id="startDate"
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="startDate"
                    placeholder="Select Date"
                    v-model="startDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fas fa-calendar"
                    :calendar-button="true"
                  />
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">To Date</label>

                  <datepicker
                    id="endDate"
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="endDate"
                    placeholder="Select Date"
                    v-model="endDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :disabledDates="{ to: new Date(startDate - 8640000) }"
                  ></datepicker>
                </div>
              </div>
              <div class="col-md-2 col-sm-5 col-6">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-primary sh_n mt"
                      @click="filterResetShipping(true)"
                    >
                      Filter
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh_n mt"
                      @click="filterResetShipping()"
                    >
                      Reset
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-ship"></i> Shipping
                        </h1>
                      </div>

                      <div class="col-lg-6">
                        <ul
                          id="sr_btn"
                          
                        >
                        <li
                            v-if="
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                              "
                            >
                            <button
                              id="reportGeneration"
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="showReportModal()"
                            >
                              <i class="icofont-plus"></i>
                              <span class="mob">Report Generation</span>
                            </button>
                          </li>
                          <li
                            v-if="
                              loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0)
                            "
                            >
                            <button
                              id="addDirectShipping"
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="
                                $router.push({
                                  name: 'AddShipping',
                                  params: {
                                    borrower_id: selectedBorrower,
                                    direct_add: true,
                                  },
                                })
                              "
                            >
                              <i class="icofont-plus"></i>
                              <span class="mob">Add Direct Shipping Info</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card-body table-responsive p-0"
                    style="padding: 10px 0px !important"
                  >
                    <!------menton here style="height: 300px;"-------->
                    <table
                      class="table table-head-fixed text-nowrap table-hover myTable1"
                      id="myTable1"
                    >
                      <thead>
                        <tr>
                          <th>Shipping Info ID</th>
                          <th>Offtaker Name</th>
                          <th>Facility ID</th>
                          <th>Collateral</th>
                          <th>Bill of Lading No.</th>
                          <th>Released Quantity (MT)</th>
                          <th>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Port
                            Acceptance Date
                          </th>
                          <th>Shipping Port</th>
                          <th>Shipping Date</th>
                          <th>Destination Port</th>
                          <th>Delivery Date</th>
                          <th>Status</th>
                          <th v-if="loggedInUserGroupId == 'Admin'">
                            Change Status
                          </th>
                          <th v-if="loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0)">
                            Add Repayment
                          </th>
                          <th>Warrant Ref.</th>
                          <th
                            class="c center"
                            v-if="
                              loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                            "
                          ></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="(ship, index) in shipping" :key="ship.id">
                          <template
                            v-if="
                              loggedInUserGroupId == 'Collateral Manager' ||
                              loggedInUserGroupId == 'Borrower' ||
                              loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0)
                            "
                          >
                            <td class="ln" v-if="ship.shipping_registration_id">
                              <router-link
                                active-link="active"
                                :to="{
                                  name: 'ShippingOverview',
                                  params: {
                                    id: ship.id,
                                  },
                                }"
                              >
                                {{ ship.shipping_registration_id }}</router-link
                              >
                            </td>
                            <td v-else>-</td>
                            <td v-if="ship.offtaker_name">
                              {{ ship.offtaker_name }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="ship.facility_registration_id">
                              {{ ship.facility_registration_id }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="ship.collateral_name">
                              {{ ship.collateral_name }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="ship.bill_of_landing_number">
                              {{ ship.bill_of_landing_number }}
                            </td>
                            <td v-else>-</td>
                            <td class="right" v-if="ship.release_quantity">
                              {{ ship.release_quantity }} {{ ship.unit }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="ship.port_acceptance_date">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                                ship.port_acceptance_date
                              }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="ship.shipping_port">
                              {{ ship.shipping_port }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="ship.shipping_date">
                              {{ ship.shipping_date }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="ship.destination_port">
                              {{ ship.destination_port }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="ship.delivery_date">
                              {{ ship.delivery_date }}
                            </td>
                            <td
                              :class="{
                                'c text-warning': ship.status === 'Pending',
                                'c text-success':
                                  ship.status === 'In Transit' ||
                                  ship.status === 'At Port',
                                'c text-danger':
                                  ship.status === 'Shipped' ||
                                  ship.status === 'Delivered',
                              }"
                            >
                              {{ ship.status }}
                            </td>
                            <td
                              class="c status"
                              v-if="loggedInUserGroupId == 'Admin'"
                            >
                              <div class="status">
                                <select
                                  id="ReqStatus"
                                  name="ReqStatus"
                                  @change="
                                    onChangeStatus(
                                      $event,
                                      ship.status,
                                      index,
                                      ship.id
                                    )
                                  "
                                  class="form-control"
                                  :value="ship.status"
                                  :disabled="
                                    ship.status === 'At Port' ||
                                    ship.status === 'Shipped' ||
                                    ship.status === 'Delivered'
                                  "
                                >
                                  <option
                                    v-if="ship.status === 'Pending'"
                                    value="Pending"
                                  >
                                    Pending
                                  </option>
                                  <option
                                    v-if="
                                      ship.status === 'Pending' ||
                                      ship.status === 'Approved'
                                    "
                                    value="Approved"
                                  >
                                    Approved
                                  </option>
                                  <option
                                    v-if="ship.status === 'At Port'"
                                    value="At Port"
                                  >
                                    At Port
                                  </option>
                                  <option
                                    v-if="ship.status === 'Shipped'"
                                    value="Shipped"
                                  >
                                    Shipped
                                  </option>
                                  <option
                                    v-if="ship.status === 'Delivered'"
                                    value="Delivered"
                                  >
                                    Delivered
                                  </option>
                                </select>
                              </div>
                            </td>
                            <template
                              v-if="loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)"
                              >
                              <td
                                class="c text-danger center"
                                v-if="ship.status != 'Pending' && ship.repayment_flag == 0"
                              >
                              <router-link
                                :to="{
                                  name: 'AddRepayment',
                                  params: {
                                    id:0,
                                    repaymentType: ship.shippingType,
                                    // contractType: ship.shippingType == 'Direct Shipping' ? 'Offtaker' : 'Offtaker',
                                    contractType: 'Offtaker',
                                    selectedBorrower: ship.borrower_id,
                                    selectedFacility: ship.facility_id,
                                    selectedOfftakerID: ship.offtaker_id,
                                    selectedContractNumber: ship.shippingType == 'Direct Shipping' ? ship.selectedContracts[0][0].offtaker_contract_tracker_id : null,
                                    selectedPartialContractNumber: ship.shippingType == 'Direct Shipping' ? ship.selectedContracts[0][0].offtaker_contract_partial_fill_id : null,
                                    contractNumberList: ship.shippingType == 'Normal Shipping' ? ship.contractNumberList : [],
                                    partialContractNumberList: ship.shippingType == 'Normal Shipping' ? ship.partialContractNumberList : [],
                                    fundCurrency: ship.fund_currency_id,
                                    // fundCurrency: 2,
                                  },
                                }"
                              >
                              <i class="icofont-plus text-success"></i>
                              </router-link>
                              </td>
                              <td class="c text-danger center" v-else>
                                <i
                                  style="opacity: 0.5"
                                  class="icofont-plus text-success"
                                ></i>
                              </td>
                            </template>
                            <td
                              class="center"
                              @click="showWarrantModal(ship.id)"
                            >
                              <i class="icofont-page text-info"></i>
                            </td>
                            <template
                              v-if="
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                              "
                            >
                              <td
                                class="c text-danger center"
                                v-if="ship.delivery_date === '-' && ship.status !== 'Pending'"
                              >
                                <router-link
                                  style="text-decoration: none"
                                  :to="{
                                    name: 'EditShipping',
                                    params: {
                                      id: ship.id,
                                    },
                                  }"
                                >
                                  <i class="icofont-ui-edit text-warning"></i>
                                </router-link>
                              </td>
                              <td class="c text-danger center" v-else>
                                <i
                                  style="opacity: 0.5"
                                  class="icofont-ui-edit text-warning"
                                ></i>
                              </td>
                            </template>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>
        <!-- /.content-->
      </div>
    </div>
    <!-- /.content-wrapper -->

    <div>
      <custom-modal-no-footer ref="customModalNoFooter">
        <!-- Provide content for the header slot -->
        <template v-slot:header>
          <div class="row">
            <div class="col-md-10">
              <h4>Mapped Warrants</h4>
            </div>
            <div class="col-md-2">
              <button
              type="button"
              class="close"
              @click="$refs.customModalNoFooter.close();"
            >
              <i class="icofont-close-circled"></i>
            </button>
            </div>
          </div>
        </template>

          <div class="row">
            <div class="col-md-12">
              <div class="card-body p-0">
                <!------menton here style="height: 300px;"-------->
                <table class="table">
                  <tbody>
                    <tr v-if="!selectedWarrants.length">
                      <center><h5>No Warrants Mapped</h5></center>
                    </tr>
                    <tr v-for="war in selectedWarrants" :key="war.id">
                      <td v-if="war.reference_id !== null">
                        {{ war.reference_id }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </custom-modal-no-footer>
    </div>
    <!-- /.content-wrapper -->
    <div>
      <custom-loan-report-modal ref="customLoanReportModal">
        <template v-slot:header>
          <h4>Shipping Report Generation</h4>
        </template>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group fc">
              <label for="exampleInputEmail1" class="required-field"
                >Borrower Name</label
              >
              <select
                id="selectedReportBorrower"
                class="form-control"
                name="selectedReportBorrower"
                v-model="selectedReportBorrower"
                @change="getCollateralByBorrower()"
                :class="{
                  'is-invalid':
                    isSubmitted && $v.selectedReportBorrower.$error,
                }"
                >
                <option :value="null" disabled>--- Select ---</option>
                <option
                  v-for="bor in borrowers"
                  :value="bor.borrower_id"
                  :key="bor.borrower_id"
                >
                  {{ bor.borrower_name }}
                </option>
              </select>
                <div
                  v-if="isSubmitted && $v.selectedReportBorrower.$error"
                  class="invalid-feedback"
                >
                <span v-if="!$v.selectedReportBorrower.required"
                  >Borrower Name is required</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group fc">
              <label for="exampleInputEmail1" class="required-field"
                >Collateral</label
              >
              <select
                id="selectedReportCollateral"
                class="form-control"
                name="selectedReportCollateral"
                v-model="selectedReportCollateral"
                :class="{
                  'is-invalid':
                    isSubmitted && $v.selectedReportCollateral.$error,
                }"
              >
                <option :value="null" disabled>-- Select --</option>
                <option 
                  v-for="col in reportCollaterals" 
                  :value="col.id"
                  :key="col.id"
                >
                  {{ col.collateral_name }}
                </option>
              </select>
                <div
                  v-if="isSubmitted && $v.selectedReportCollateral.$error"
                  class="invalid-feedback"
                >
                <span v-if="!$v.selectedReportCollateral.required"
                  >Collateral is required</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group fc">
              <label  class="required-field">Period Type</label>
              <select
                id="selectedPeriodType"
                class="form-control"
                name="selectedPeriodType"
                v-model="selectedPeriodType"
                :class="{
                  'is-invalid':
                    isSubmitted && $v.selectedPeriodType.$error,
                }"
              >
                <option :value="null" disabled>--- Select ---</option>
                <option v-for="report in report_type" :key="report.id" :value="report.id">
                  {{ report.report_type_name }}
                </option>
              </select>
              <div
                v-if="isSubmitted && $v.selectedPeriodType.$error"
                class="invalid-feedback"
              >
              <span v-if="!$v.selectedPeriodType.required"
                >Period Type is required</span
              >
            </div>
            </div>
          </div>

          <div v-if="selectedPeriodType == 0" class="col-md-6">
            <div class="form-group fc">
              <label for="exampleInputEmail1" class="required-field"
                >Monthly Period</label
              >
              <datepicker
                class="datepicker"
                :bootstrap-styling="true"
                name="selectedRegularReportDate"
                placeholder="Select Period"
                v-model="selectedRegularReportDate"
                minimum-view="month"
                format="MM-yyyy"
                calendar-button-icon="fa fa-calendar"
                :calendar-button="true"
                :disabledDates="{
                  to: new Date(this.backcapBorrowerReportDate),
                  from: lastMonth,
                }"
              ></datepicker>
            </div>
          </div>

          <div v-if="selectedPeriodType == 1" class="col-md-6">
            <div class="form-group fc">
              <label for="exampleInputEmail1" class="required-field"
                >As of today</label
              >
              <datepicker
                class="custom-width-datepicker1 datepicker"
                :bootstrap-styling="true"
                name="selectedCustomReportDate"
                placeholder="Select Date"
                v-model="selectedCustomReportDate"
                :format="$store.state.date_format"
                calendar-button-icon="fas fa-calendar"
                :calendar-button="true"
                disabled
                :disabledDates="{
                  to: new Date(this.backcapBorrowerReportDate),
                  from: new Date(),
                }"
              ></datepicker>
            </div>
          </div>

        </div>
        <template v-slot:footer>
          <div class="">
              <button
                style="margin-right: 6px"
                type="button"
                class="btn-sm btn btn-success sh"
                v-on:click="onClickExcel('excel')"
              >
                <i class="icofont-file-excel"></i> Excel
              </button>
              <!-- <button
                type="button"
                style="margin-right: 6px"
                class="btn-sm btn btn-danger sh"
                v-on:click="onClickExcel('pdf')"
              >
                <i class="icofont-file-pdf"></i> PDF
              </button> -->
              <button
                type="button"
                name="saveInfo"
                class="btn-sm btn btn-primary sh"
                style="margin-right: 10px;"
                v-on:click="onCloseReportModal()"
              >
                <i class="icofont-close-circled"></i> Close
              </button>
            </div>
        </template>
      </custom-loan-report-modal>
    </div>

  </div>
  <!-- ./wrapper -->
</template>

<script>
import $ from "jquery";
// //Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {required} from "vuelidate/lib/validators";
import CustomLoanReportModal from '../loan/CustomLoanReportModal.vue';
import CustomModalNoFooter from './CustomModalNoFooter.vue';

export default {
  data() {
    return {
      shipping: [],
      offtaker_contract_list: [],
      borrowers: [],
      facilities: [],
      collaterals: [],
      offtakers: [],
      selectedBorrower: null,
      selectedFacility: null,
      selectedCollateral: null,
      selectedOfftaker: null,
      startDate: null,
      endDate: null,
      borrower_id: null,

      selectedWarrants: [],
      selectedReportBorrower: null,
      selectedReportBorrowerName: '',
      selectedReportCollateral: null,
      reportCollaterals: [],
      selectedPeriodType: null,
      // selectedCollateralType: 'All',
      // collateralStages: [],
      report_type: [
        { id: 0 , report_type_name: "Monthly"},
        { id: 1 , report_type_name: "As of today"},
      ],

      backcapBorrowerReportDate: null,
      selectedRegularReportDate: null,
      selectedCustomReportDate: new Date(),
      lastMonth: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1
      ),
      isSubmitted: false,
    };
  },
  components: {
    Datepicker,
    CustomModalNoFooter,
    CustomLoanReportModal,
  },
  validations: {
    selectedReportBorrower: {
      required,
    },
    selectedReportCollateral: {
      required,
    },
    selectedPeriodType: {
      required,
    },
  },
  methods: {
    onCloseReportModal() {
      console.log("onCloseReportModal");
      this.selectedPeriodType = null;
      this.selectedRegularReportDate = null;
      this.selectedCustomReportDate = new Date();
      this.backcapBorrowerReportDate = null;
      this.selectedReportCollateral = null;
      this.reportCollaterals = [];
      this.selectedReportBorrowerName= '';
      this.isSubmitted = false;
      this.$refs.customLoanReportModal.close();
    },

    showReportModal() {
      this.selectedPeriodType = null;
      this.selectedRegularReportDate = null;
      this.selectedCustomReportDate = new Date();
      this.backcapBorrowerReportDate = null;
      this.selectedReportCollateral = null;
      this.reportCollaterals = [];
      this.selectedReportBorrowerName= '';
      this.isSubmitted = false;
      this.getCollateralByBorrower()
      this.$refs.customLoanReportModal.open();
    },

    onClickExcel(fileType) {
      // if(this.selectedReportBorrower == null){
      //   Utils.make_alert("warning", "Please select borrower.");
      //   return;
      // }
      // if(this.selectedReportCollateral == null){
      //   Utils.make_alert("warning", "Please select collateral.");
      //   return;
      // }
      // if(this.selectedCollateralType == null){
      //   Utils.make_alert("warning", "Please select exposure.");
      //   return;
      // }
      // if(this.selectedPeriodType == null){
      //   Utils.make_alert("warning", "Please select period type.");
      //   return;
      // }
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let date = null
      if(this.selectedPeriodType != null){
        if(this.selectedPeriodType == 0){
          if (this.selectedRegularReportDate === null) {
            Utils.make_alert("warning", "Please select Monthly Period.");
            return;
          } else {
            console.log("selectedRegularReportDate",this.selectedRegularReportDate)
            date = moment(this.selectedRegularReportDate).endOf("month").format("MM-DD-YYYY");
          }
        } else if(this.selectedPeriodType == 1){
          if (this.selectedCustomReportDate === null) {
            Utils.make_alert("warning", "Please select As of today date.");
            return;
          } else {
            console.log("selectedCustomReportDate",this.selectedCustomReportDate)
            date = moment(this.selectedCustomReportDate).format("MM-DD-YYYY");
          }
        }
      }
      console.log("date",date)
      const path =
        "/shipping_reports/" +
        this.selectedReportBorrower +
        "/" +
        this.selectedReportCollateral +
        "/" +
        this.selectedPeriodType +
        "/" +
        fileType +
        "/" + 
        date;
        console.log("path",path)
        this.$refs.customLoanReportModal.close();
        Utils.start_loading();
      axios({
        params: { clientId: this.$store.state.client_id , userId: this.$store.getters.getuserId},
        url: path,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          Utils.stop_loading();
          console.log("API CALL DONE")
          console.log("response.data.type",response.data.type)
          if (response.data.type == "application/json") {
            console.log("failed")
            Utils.make_alert(
              "warning",
              "Something went wrong! Unable to download report."
            );
            this.selectedPeriodType = null;
            this.selectedRegularReportDate = null;
            this.selectedCustomReportDate = new Date();
            this.backcapBorrowerReportDate = null;
            this.selectedReportCollateral = null;
            this.reportCollaterals = [];
            this.selectedReportBorrowerName= '';
          } else {
            //give report type names as per selectedReport
            console.log("Download")
            console.log("response.data",response.data)
            var reportName;
            var extension;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            // Filename seems to have to be changed unless otherwise provided
            // the extension can be automatically decided
            if (fileType === "excel") {
              extension = ".xlsx";
            } else if (fileType === "pdf") {
              extension = ".pdf";
            }
            reportName = `${this.selectedReportBorrowerName} - Shipping Summary Report`;
            link.setAttribute("download", reportName + extension);
            document.body.appendChild(link);
            link.click();
            this.selectedPeriodType = null;
            this.selectedRegularReportDate = null;
            this.selectedCustomReportDate = new Date();
            this.backcapBorrowerReportDate = null;
            this.selectedReportCollateral = null;
            this.reportCollaterals = [];
            this.selectedReportBorrowerName= '';
            //this.$router.go(this.$router.currentRoute)
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.log("error");
          this.selectedPeriodType = null;
          this.selectedRegularReportDate = null;
          this.selectedCustomReportDate = null;
          this.loanId = null;
          this.loanRegId = null;
          this.backcapBorrowerReportDate = null;
        });
    },

    getCollateralByBorrower() {
      console.log("getCollateralByBorrower")
      if(this.selectedReportBorrower == null){
        this.reportCollaterals = [];
        this.selectedReportCollateral = null;
        return;
      }
      let borrower = this.borrowers.find(borrower => borrower.borrower_id == this.selectedReportBorrower)
      this.selectedReportBorrowerName = borrower.borrower_name;
      this.backcapBorrowerReportDate = borrower.registration_date;
      console.log("selectedReportBorrowerName",this.selectedReportBorrowerName)
      console.log("backcapBorrowerReportDate",this.backcapBorrowerReportDate)
      const path = 
      "/get-collateral-by-borrower" +
      "/" +
      this.selectedReportBorrower;
      console.log(path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            this.reportCollaterals = res.data.collateral;
          } else {
            Utils.make_alert("warning","Something went wrong from server!")
          }
          
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
        });
    },

    showWarrantModal(id) {
      //this function is used to get warrants for selected container.
      const path = "shipping-warrant/" + id;
      Utils.start_loading();
      axios
        .get(path, {
          params: { clientId: this.$store.state.client_id },
        })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.selectedWarrants = res.data.container_warrant_mapping;
            this.$refs.customModalNoFooter.open();
            // this.$modal.show("warrant_details");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
        });
    },

    onChangeStatus(updatedStatus, prevStatus, index, id) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            //this function is called when user changes the logistics registration status. The status is updated in the database for that specific logistics.
            const path =
              "shipment-summary/" +
              null +
              "/" +
              null +
              "/" +
              null +
              "/" +
              null +
              "/" +
              null +
              "/" +
              null;
            axios
              .put(
                path,
                {
                  type: "status",
                  shipping_id: id,
                  status: updatedStatus.target.value,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 8,
                  tabId: 24,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("registration update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Shipping Status Updated Successfully!"
                  );
                  this.shipping[index].status = updatedStatus.target.value;
                  setTimeout(() => this.$router.go(), 1500);
                } else {
                  console.log("Something went wrong from server!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
              });
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeStatus close
    //this function will be called to get list of Shipping
    getShipping() {
      if (this.startDate != null) {
        this.startDate = moment(this.startDate).format("MM-DD-YYYY");
      }
      if (this.endDate != null) {
        this.endDate = moment(this.endDate).format("MM-DD-YYYY");
      }
      const path =
        "shipment-summary/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility +
        "/" +
        this.selectedCollateral +
        "/" +
        this.selectedOfftaker +
        "/" +
        this.startDate +
        "/" +
        this.endDate;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          if (res.data.status == "success") {
            this.shipping = res.data.shipment_summary;

            if(this.shipping.length > 0) {
                this.shipping.forEach(item => {
                  if (item.port_acceptance_date != '-' && item.port_acceptance_date != null) {
                    item.port_acceptance_date = moment(item.port_acceptance_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.port_acceptance_date = '-';
                  }
                  if (item.shipping_date != '-' && item.shipping_date != null) {
                    item.shipping_date = moment(item.shipping_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.shipping_date = '-';
                  }
                  if (item.delivery_date != '-' && item.delivery_date != null) {
                    item.delivery_date = moment(item.delivery_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.delivery_date = '-';
                  }
                });
              }

            this.offtakers = res.data.offtaker_list;
            this.offtaker_contract_list = res.data.offtaker_contract_list;
            this.shipping = this.shipping.map(shipItem => {
            const matchingContracts = this.offtaker_contract_list.filter(oc => oc.shipping_id == shipItem.id);
            const contractNumbers = matchingContracts
              .map(contract => contract.offtaker_contract_tracker_id)
              .filter(id => id != null);

            // Add logic to determine the correct logistics_shipping_flag
            const logisticsShippingFlag = matchingContracts.map(contract => {
              return contract.offtaker_contract_partial_fill_id == null 
                    ? contract.oct_logistics_shipping_flag 
                    : contract.ocpf_logistics_shipping_flag;
            });

            const partialContractNumbers = matchingContracts
              .map(contract => contract.offtaker_contract_partial_fill_id)
              .filter(id => id != null);
            
            console.log("shipItem.id",shipItem.id);
            console.log("shipItem.id",shipItem.shipping_registration_id);
            console.log("matchingContracts",matchingContracts);
            console.log("contractNumbers",contractNumbers);
            console.log("partialContractNumbers",partialContractNumbers);
            console.log("logisticsShippingFlag",logisticsShippingFlag);
                return {
                  ...shipItem,
                  selectedContracts: matchingContracts ? [matchingContracts] : [],
                  // repayment_flag: 0,
                  contractNumberList: contractNumbers,
                  partialContractNumberList: partialContractNumbers,
                  shippingType: logisticsShippingFlag == 2 ? 'Direct Shipping' : 'Normal Shipping'
                  // shippingType: 'Normal Shipping'
                };
              });

            console.log("this.shipping first",this.shipping);
            console.log("this.shipping[0].selectedContracts[0][0] first",this.shipping[0].selectedContracts[0][0]);
            if (this.loggedInUserGroupId === "Borrower")
              this.shipping = this.shipping.filter(
                (log) =>
                  log.borrower_id.toString() === this.loggedInUserSubGroupId
              );
            if (this.loggedInUserGroupId === "Collateral Manager")
              this.shipping = this.shipping.filter(
                (log) =>
                  log.collateral_manager_id !== "-" &&
                  log.collateral_manager_id.toString() ===
                    this.loggedInUserSubGroupId
              );
            setTimeout(
              () =>
                $("#myTable1").DataTable({
                  aaSorting: [],
                  scrollX: true,
                  dom: "l<'float-right'B>frtip",
                  buttons: [
                    {
                      extend: "excel",
                      exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                      },
                      className: "btn btn-primary",
                      title: `Shipping`,
                      customize: function (xlsx) {
                        var sheet = xlsx.xl.worksheets["sheet1.xml"];
                        $("row:not(:first)", sheet).each(function (index) {
                          $("c", this).attr("s", "25");
                        });
                        $("row:eq(1) c", sheet).attr("s", "2");
                      },
                    },
                  ],
                  columnDefs: [
                    {
                      orderable: false,
                      targets:
                        this.loggedInUserGroupId === "Admin"
                          ? [0, 12, 13 ,14 ,15]
                          : this.loggedInUserGroupId === "Analyst"
                          ? [0, 12, 13 ,14]
                          : this.loggedInUserGroupId === "Collateral Manager"
                          ? [0, 12]
                          : this.loggedInUserGroupId === "Borrower"
                          ? [0, 12]
                          : [],
                    },
                  ],
                }),
              100
            );
            console.log("get shipping is successful.");
          } else {
            Utils.make_alert("warning", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
        });
    },

    // filter & reset function
    filterResetShipping(filter) {
      if (filter) {
        if (
          (this.startDate !== null && this.endDate === null) ||
          (this.startDate === null && this.endDate !== null)
        ) {
          Utils.make_alert(
            "warning",
            "Both From & To Dates are required to perform Date Filter"
          );
          return;
        } else {
          $("#myTable1").dataTable().fnDestroy();
          this.getShipping();
        }
      } else {
        this.$router.go();
      }
    },

    // this function will be called to get list of borrowers
    getBorrowers() {
      let path = null;
      if (this.loggedInUserGroupId === "Collateral Manager") {
        path = "borrower-master-with-cm/" + this.loggedInUserSubGroupId;
      } else {
        path = "borrower-master-with-cm/" + null;
      }
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.borrowers = res.data.borrowers;
          if (this.borrowers.length) {
            if (this.loggedInUserGroupId === "Borrower") {
              this.selectedBorrower = this.loggedInUserSubGroupId;
            } else {
              this.selectedBorrower = this.borrowers[0].borrower_id;
            }
            let borrowerFilter = localStorage.getItem("shippingBorrowerFilter");
            if (borrowerFilter === null || borrowerFilter === "null") {
              localStorage.setItem(
                "shippingBorrowerFilter",
                this.selectedBorrower
              );
            } else {
              this.selectedBorrower = borrowerFilter;
            }
            this.selectedReportBorrower = this.selectedBorrower;
            this.getFilteredFacilities();
            this.getFilteredCollaterals();
            this.filterResetShipping(true);
          }
          if (res.data.status == "success") {
            console.log("get borrowers is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },
    getFilteredFacilities() {
      this.selectedReportBorrower = this.selectedBorrower;
      if (this.selectedBorrower === null) {
        Utils.make_alert("warning", "Borrower Filter is required");
        return;
      }
      localStorage.setItem("shippingBorrowerFilter", this.selectedBorrower);
      //this function is called to get list of open facilities by borrower id
      let path = null;
      if (this.loggedInUserGroupId === "Collateral Manager") {
        path = "/facility-setup-borrower-cm/" + this.selectedBorrower + "/" + this.loggedInUserSubGroupId;
      } else {
        path = "/facility-setup-borrower-cm/" + this.selectedBorrower + "/" + null;
      }      
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.facilities = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFilteredCollaterals() {
      //this function is called to get list of filtered collaterals
      const path =
        "/collateral-filter/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.collaterals = res.data.collaterals;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  mounted() {
    this.getBorrowers();
  },
  created() {
    axios.defaults.headers.common["Authorisation"] =
      "Bearer " + sessionStorage.getItem("token");
  },
  computed: {
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
  font-size: 15px;
}
</style>
