<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-file-document"></i> KYC Document
                        History
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row"></div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th>Document Name</th>
                        <th>Issue Date</th>
                        <th>Expiry Date</th>
                        <th class="c">Uploaded File</th>
                        <th
                          class="c"
                          v-if="
                            loggedInUserGroupId == 'Admin' ||
                            (loggedInUserGroupId == 'Analyst' &&
                              loggedInUserSubGroupId == 0) &&
                            !inactive
                          "
                        ></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="kyc in KYCHistory" :key="kyc.kyc_tran_id">
                        <td v-if="kyc.document_name !== null">
                          {{ kyc.document_name }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="kyc.uploading_date">
                          {{ kyc.uploading_date }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="kyc.expiry_date" class>
                          {{ kyc.expiry_date }}
                        </td>
                        <td v-else class>-</td>
                        <!--<td><a href="#"><i class="icofont-link"></i></a> - {{kyc.display_file_name}}</td>-->
                        <td
                          class="c"
                          v-on:click="downloadDocument(kyc.document_path)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <u>{{ kyc.display_file_name }}</u>
                        </td>
                        <td
                          v-if="
                            loggedInUserGroupId == 'Admin' ||
                            (loggedInUserGroupId == 'Analyst' &&
                            loggedInUserSubGroupId == 0) &&
                            !inactive
                          "
                          v-on:click="deleteKYC(kyc.kyc_tran_id)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.content-wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import moment from "moment";

export default {
  data() {
    return {
      KYCHistory: [],
      inactive: false,
    };
  },
  components: {
    Datepicker,
  },
  methods: {
    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },
    getInvestorDocs(id) {
      //this function is called on page load and created events. it will show details on investor with investorid equal to this.id
      const path = "investor/" + id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.KYCHistory = this.inactive
              ? res.data.investor_inactive.investor_kyc_history_documents_list
              : res.data.investor_active.investor_kyc_history_documents_list;
            if(this.KYCHistory.length > 0) {
              this.KYCHistory.forEach(item => {
                if (item.uploading_date != '-' && item.uploading_date != null) {
                  item.uploading_date = moment(item.uploading_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.uploading_date = '-';
                }
                if (item.expiry_date != '-' && item.expiry_date != null) {
                  item.expiry_date = moment(item.expiry_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.expiry_date = '-';
                }
              });
            }
            this.KYCHistory.sort((a, b) => {
              var nameA = a.document_name.toUpperCase(); // ignore upper and lowercase
              var nameB = b.document_name.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
            });
            console.log("get investor docs is successful.", this.KYCHistory);
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/investor");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },
    deleteKYC(tranId) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to permanently delete?",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete("investor/" + this.$route.params.id, {
                headers: {
                  "Content-type": "application/json",
                },
                data: {
                  type: "delete_kyc_record",
                  kyc_tran_id: tranId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 3,
                  tabId: 11,
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert("success", "Record deleted.");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(() => this.$router.go(), 1000);
                } else {
                  Utils.make_alert("success", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.log("error: " + error);
                Utils.make_alert("success", "Something went wrong!");
              });
          } else {
            this.$router.go(this.$router.currentRoute);
          }
        },
      });
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
  created() {
    this.inactive =
      this.$store.state.inactive === "investor_inactive" ? true : false;
    this.getInvestorDocs(this.$route.params.id);
  },
};
</script>
