<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Add Payment
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" :style="{ cursor: 'pointer'}"> 
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <!--<div class="col-md-3 col-sm-5 col-6">
              <div class="form-group fc">
                <label>Payment Type</label>
                <select
                  id="selectedPayment"
                  class="form-control"
                  name="selectedPayment"
                  v-model="selectedPaymentType"
                >
                  <option :value="null">-- Select --</option>
                  <option
                    v-for="payment in expenseTypes"
                    :vaue="payment.id"
                  >{{ payment.payment_name }}</option>
                </select>
              </div>
            </div>-->

            <div class="col-md-3 col-sm-5 col-6">
              <div class="form-group fc">
                <label class="required-field">Fund Name</label>
                <select
                  id="selectedFund"
                  class="form-control"
                  name="selectedFund"
                  v-model="selectedFund"
                >
                  <option :value="null">-- Select a Fund --</option>
                  <option v-for="fund in funds" :value="fund.fund_id">{{ fund.fund_name }}</option>
                </select>
              </div>
            </div>

            <div class="col-md-2 col-sm-5 col-6">
              <div class="form-group fc">
                <label for="exampleInputEmail1" class="required-field">Amount</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder=""
                  v-model="amount"
                />
              </div>
            </div>

            <div class="col-md-3 col-sm-5 col-6">
              <div class="form-group fc">
                <label for="exampleInputEmail1" class="required-field">Date</label>
                <datepicker class="datepicker"
                  :bootstrap-styling="true"
                  name="paymentDate"
                  placeholder="Select Date"
                  v-model="paymentDate"
                  :format="$store.state.date_format"
                  calendar-button-icon="fa fa-calendar"
                  :calendar-button="true"
                  :disabledDates="{from: new Date()}"
                ></datepicker>
              </div>
            </div>

            <div class="col-md-2 col-sm-5 col-6">
              <div class="form-group fc">
                <label for="exampleInputEmail1" class="required-field">Currency</label>
                <select
                  id="selectedCurrency"
                  class="form-control"
                  name="selectedCurrency"
                  v-model="selectedCurrency"
                >
                  <option :value="null">-- Select --</option>
                  <option
                    v-for="currency in currencies"
                    :value="currency.id"
                  >{{ currency.currency_short_name }}</option>
                </select>
              </div>
            </div>

            <div class="col-md-2 col-sm-5 col-6">
              <div class="form-group fc">
                <label for="exampleInputEmail1">Tranche</label>
                <input type="text" class="form-control" placeholder="" v-model="tranche" />
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <button
                type="button"
                class="btn-sm btn btn-success sh"
                v-on:click="onSubmit()"
              >Submit</button>
              <button type="button" class="btn-sm btn btn-dark sh" data-dismiss="modal" @click="$router.go(-1)" style="cursor:pointer;">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- /.content-wrapper -->

  <!-- /.wrapper -->
</template>


<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function

export default {
  data() {
    return {
      id: null,
      selectedPaymentType: null,
      expenseTypes: [],
      currencies: [],
      amount: null,
      paymentDate: null,
      paymentDate1: null,
      tranche: null,
      selectedCurrency: null,
      startDate: null,
      endDate: null,
      selectedFund: null,
      funds: null,
    };
  },
  components: {
    Datepicker
  },
  methods: {
    //get list of payment types
    getExpenseTypes() {
			//this function is called to get list of expense types
			const path = 'expense-type';
			axios.get(path)
				.then((res) => {
				this.expenseTypes = res.data.expense_types;
				})
				.catch((error) => {
				console.error(error);
				});
			},

    getCurrencies() {
		  //this function is called to get list of currencies
      const path = 'currency';
      axios.get(path)
        .then((res) => {
        this.currencies = res.data.currencies;
        })
        .catch((error) => {
        console.error(error);
        });
    },

    //this function is called to get list of documents master
    getFund() {
      const path = "fund-master";
      axios
        .get(path)
        .then(res => {
          this.funds = res.data.funds;
        })
        .catch(error => {
          console.error(error);
        });
    }, //getFund() close

    //this method will submit the borrower form in backend.
    onSubmit() {
      if(this.amount === null || this.paymentDate === null || this.selectedCurrency === null || this.selectedFund === null){
        Utils.make_alert("warning", "Mandatory fields are required.")
        return
      }
      if(confirm("Confirm submit?")){
        if (this.paymentDate != null) {
          this.paymentDate1 = moment(this.paymentDate).format("MM-DD-YYYY");
        }
        const path = "payment-by-investor-id/"+this.id+'/'+this.startDate+'/'+this.endDate+'/'+this.selectedCurrency;
        axios
          .post(path,
            {
              "amount": this.amount,
              "paymentDate": this.paymentDate1,
              "currency": this.selectedCurrency,
              "tranche": this.tranche,
              "fund": this.selectedFund,
            },
          {
            headers: {
            'Content-type': 'application/json',
            }
          }).then((response) => {
            if(response.data.status == 'success') {
            Utils.make_alert("success", 'Payment added successfully!');
            //if you want to send any data into server before redirection then you can do it here
            this.$router.push('/investor-overview/'+this.id);
            }
            else {
            Utils.make_alert("warning", 'Something went wrong!');
            }
          }).catch(error => { 
            console.log('error: ' + error); 
            Utils.make_alert("warning", 'Something went wrong!');
          });
      }
    } //onSubmit() close
  }, //main method close

  created() {
    this.id = this.$route.params.id;
    this.getExpenseTypes();
    this.getCurrencies();
    this.getFund();
  }
};
</script>

<style scoped>
.required-field::after {
    content: "*";
    color: red;
}
</style>