var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('nav-bar'),_c('aside-bar'),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid tabtop pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-6"},[_c('ul',{attrs:{"id":"bck_btn"}},[_c('li',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"icofont-caret-left text-dark"}),_c('span',[_vm._v("Back")])])])])])])])])])])]),_c('div',{staticClass:"container-fluid ot_tb pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_vm._m(1),_c('div',{staticClass:"card-body table-responsive p-0"},[_c('table',{staticClass:"table table-head-fixed text-nowrap"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Actual Value")]),_c('th',[_vm._v("Expected Value")]),_c('th',[_vm._v("Compliant")]),(
                          _vm.loggedInUserGroupId == 'Admin' ||
                          (_vm.loggedInUserGroupId == 'Analyst' &&
                            _vm.loggedInUserSubGroupId == 0) &&
                          !_vm.inactive
                        )?_c('th'):_vm._e()])]),_c('tbody',_vm._l((_vm.CovenantHistory),function(cov){return _c('tr',[(cov.covenants_name !== null)?_c('td',[_vm._v(" "+_vm._s(cov.covenants_name)+" ")]):_c('td',[_vm._v("-")]),(cov.upload_date !== null)?_c('td',[_vm._v(" "+_vm._s(cov.upload_date)+" ")]):_c('td',[_vm._v("-")]),(cov.actual_value !== null)?_c('td',[_vm._v(" "+_vm._s(cov.actual_value)+" ")]):_c('td',[_vm._v("-")]),(cov.expected_value !== null)?_c('td',[_vm._v(" "+_vm._s(cov.sign)+" "+_vm._s(cov.expected_value)+" ")]):_c('td',[_vm._v("-")]),(cov.compliant !== null)?_c('td',[_vm._v(" "+_vm._s(cov.compliant)+" ")]):_c('td',[_vm._v("-")]),(
                          _vm.loggedInUserGroupId == 'Admin' ||
                          (_vm.loggedInUserGroupId == 'Analyst' &&
                            _vm.loggedInUserSubGroupId == 0) &&
                          !_vm.inactive
                        )?_c('td',{style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.deleteCovenant(cov.fc_tran_id)}}},[_c('i',{staticClass:"icofont-trash text-danger"})]):_vm._e()])}),0)])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"headingt"},[_c('i',{staticClass:"icofont-history"}),_vm._v(" Covenant History ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"})])}]

export { render, staticRenderFns }