<!-- This is add Investor page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Add Investor
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label class="required-field">Investment Category</label>
                    <select
                      id="selectedInvestmentCategory"
                      class="form-control"
                      name="selectedInvestmentCategory"
                      v-model="selectedInvestmentCategory"
                      required
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedInvestmentCategory.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="category in investmentCategory"
                        :value="category.investment_category_name"
                      >
                        {{ category.investment_category_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedInvestmentCategory.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedInvestmentCategory.required"
                        >Investment Category is required</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  v-if="selectedInvestmentCategory !== 'Participation'"
                  class="col-md-2 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label class="required-field">Investor Type</label>
                    <select
                      id="selectedInvestorType"
                      class="form-control"
                      name="selectedInvestorType"
                      v-model="selectedInvestorType"
                      required
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedInvestorType.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="type in investorType"
                        :value="type.investor_type_name"
                      >
                        {{ type.investor_type_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedInvestorType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedInvestorType.required"
                        >Investor Type is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="InvestorName"
                      id="InvestorName"
                      @keyup="checkInvestorName()"
                      :class="{
                        'is-invalid':
                          (isSubmitted && $v.InvestorName.$error) ||
                          investorExists,
                      }"
                    />
                    <div
                      v-if="
                        (isSubmitted && $v.InvestorName.$error) ||
                        investorExists
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.InvestorName.required"
                        >Investor Name is required</span
                      >
                      <span v-if="!$v.InvestorName.maxLength"
                        >Max length of 100 characters exceeded</span
                      >
                      <span v-if="investorExists">Investor already exists</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Address</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="investorAddress"
                      id="investorAddress"
                      :class="{
                        'is-invalid': isSubmitted && $v.investorAddress.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.investorAddress.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.investorAddress.required"
                        >Address is required</span
                      >
                      <span v-if="!$v.investorAddress.maxLength"
                        >Max length of 250 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Country</label
                    >
                    <select
                      id="selectedinvestorCountry"
                      class="form-control"
                      name="selectedinvestorCountry"
                      v-model="selectedinvestorCountry"
                      @change="getStatesByCountry()"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedinvestorCountry.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="country in countries"
                        :value="country.id"
                        :key="country.id"
                      >
                        {{ country.country_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedinvestorCountry.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedinvestorCountry.required"
                        >Country is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">State</label>
                    <select
                      class="form-control"
                      name="selectedState"
                      id="selectedState"
                      v-model="selectedState"
                      @change="getCitiesByState()"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="state in states"
                        :value="state.id"
                        :key="state.id"
                      >
                        {{ state.state_name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label>City</label>
                    <select
                      id="selectedCity"
                      class="form-control"
                      name="selectedCity"
                      v-model="selectedCity"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="city in cities"
                        :value="city.id"
                        :key="city.id"
                      >
                        {{ city.city_name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div
                  v-if="
                    selectedInvestorType != 'Individual' &&
                    selectedInvestorType != 'Nominee'
                  "
                  class="col-md-2 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label for="investorWebsite">Website</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="investorWebsite"
                      id="investorWebsite"
                      name="investorWebsite"
                      :class="{
                        'is-invalid':
                          (isSubmitted && $v.investorWebsite.$error) ||
                          msg.investorWebsite,
                      }"
                    />
                    <div
                      v-if="
                        (isSubmitted && $v.investorWebsite.$error) ||
                        msg.investorWebsite
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.investorWebsite.maxLength"
                        >Max length of 100 characters exceeded</span
                      >
                    </div>
                    <div class="invalid-feedback" v-if="msg.investorWebsite">
                      <span>{{ msg.investorWebsite }}</span>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    selectedInvestorType != 'Individual' &&
                    selectedInvestorType != 'Nominee'
                  "
                  class="col-md-2 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Incorporation Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="incorporationDate"
                      id="incorporationDate"
                      placeholder="Select Date"
                      v-model="incorporationDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{ from: new Date() }"
                    ></datepicker>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Registration Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="regDate"
                      id="regDate"
                      placeholder="Select Date"
                      v-model="regDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{ from: new Date() }"
                      :class="{
                        'is-invalid': isSubmitted && $v.regDate.$error,
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.regDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.regDate.required"
                        >Registration Date is required</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Contact Details</h1>
                    </div>
                  </div>
                </div>
                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->
                  <div class="row">
                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="phoneNumber" class="required-field"
                          >Contact No</label
                        ><br />
                        <select
                          id="investorContactCode"
                          class="contact_code"
                          name="investorContactCode"
                          v-model="investorContactCode"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.investorContactCode.$error,
                          }"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="code in countryCodes"
                            :key="code.id"
                            :value="code.id"
                          >
                            {{ code.country_code }}
                          </option>
                        </select>
                        <input
                          class="contact"
                          placeholder
                          v-model="investorContact"
                          id="investorContact"
                          @keypress="isNum($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.investorContact.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.investorContactCode.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.investorContactCode.required"
                            >Contact Number Code is required</span
                          >
                        </div>
                        <div
                          v-if="isSubmitted && $v.investorContact.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.investorContact.required"
                            >Contact Number is required</span
                          >
                          <span v-if="!$v.investorContact.minLength"
                            >Min length of 10 characters required</span
                          >
                          <span v-if="!$v.investorContact.maxLength"
                            >Max length of 13 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="secondaryInvestorContact"
                          >Secondary Contact No</label
                        ><br />
                        <select
                          id="secondaryInvestorContactCode"
                          class="contact_code"
                          name="secondaryInvestorContactCode"
                          v-model="secondaryInvestorContactCode"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.secondaryInvestorContactCode.$error,
                          }"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="code in countryCodes"
                            :key="code.id"
                            :value="code.id"
                          >
                            {{ code.country_code }}
                          </option>
                        </select>
                        <input
                          class="contact"
                          placeholder
                          v-model="secondaryInvestorContact"
                          id="secondaryInvestorContact"
                          @keypress="isNum($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.secondaryInvestorContact.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.secondaryInvestorContactCode.$error
                          "
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.secondaryInvestorContactCode.required"
                            >Secondary Contact Number Code is required</span
                          >
                        </div>
                        <div
                          v-if="
                            isSubmitted && $v.secondaryInvestorContact.$error
                          "
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.secondaryInvestorContact.required"
                            >Secondary Contact Number is required<br
                          /></span>

                          <span v-if="!$v.secondaryInvestorContact.minLength"
                            >Min length of 10 characters required</span
                          >
                          <span v-if="!$v.secondaryInvestorContact.maxLength"
                            >Max length of 13 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="investorEmail" class="required-field"
                          >Email ID</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          placeholder
                          v-model="investorEmail"
                          id="investorEmail"
                          name="investorEmail"
                          :class="{
                            'is-invalid':
                              (isSubmitted && $v.investorEmail.$error) ||
                              msg.investorEmail,
                          }"
                        />
                        <div class="invalid-feedback" v-if="msg.investorEmail">
                          <span>{{ msg.investorEmail }}</span>
                        </div>
                        <div
                          v-if="isSubmitted && $v.investorEmail.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.investorEmail.required"
                            >Email ID is required</span
                          >
                          <span v-if="!$v.investorEmail.maxLength"
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="secondaryInvestorEmail"
                          >Secondary Email ID</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          placeholder
                          v-model="secondaryInvestorEmail"
                          id="secondaryInvestorEmail"
                          name="secondaryInvestorEmail"
                          :class="{
                            'is-invalid':
                              (isSubmitted &&
                                $v.secondaryInvestorEmail.$error) ||
                              msg.secondaryInvestorEmail,
                          }"
                        />
                        <div
                          class="invalid-feedback"
                          v-if="msg.secondaryInvestorEmail"
                        >
                          <span>{{ msg.secondaryInvestorEmail }}</span>
                        </div>
                        <div
                          v-if="isSubmitted && $v.secondaryInvestorEmail.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.secondaryInvestorEmail.maxLength"
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- until here -->
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div
          v-if="selectedInvestmentCategory !== 'Participation'"
          class="container-fluid ot_tb pt-0"
        >
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">KYC Corporate Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addKYC"
                            :disabled="KYCAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div class="row" v-for="(kyc, index) in KYCList" :key="index">
                    <div class="col-md-3 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label class="required-field">Select Document</label>
                        <select
                          id="KYCDocName"
                          class="form-control"
                          name="KYCDocName"
                          v-model="kyc.KYCDocName"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              kyc.KYCDocName === null &&
                              selectedInvestmentCategory !== 'Participation',
                          }"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="document in KYCDocuments"
                            :value="document.id"
                            :key="document.id"
                          >
                            {{ document.document_name }}
                          </option>
                        </select>
                        <div
                          v-if="
                            isSubmitted &&
                            kyc.KYCDocName === null &&
                            selectedInvestmentCategory !== 'Participation'
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              kyc.KYCDocName === null &&
                              selectedInvestmentCategory !== 'Participation'
                            "
                            >KYC Document Name is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Upload Document</label
                        >
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="customFile"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            v-on:change="
                              handleFileUploadKYC(index, $event, kyc.KYCDocName)
                            "
                            :class="{
                              'is-invalid':
                                isSubmitted && kyc.displayFileName === null,
                            }"
                          />
                          <div
                            v-if="isSubmitted && kyc.displayFileName === null"
                            class="invalid-feedback"
                          >
                            <span v-if="kyc.displayFileName === null"
                              >KYC Document is required</span
                            >
                          </div>
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ kyc.displayFileName }}</b>
                              <small>
                                <u
                                  style="color: blue; cursor: pointer"
                                  v-if="kyc.KYCRemoveDisabled === false"
                                  v-on:click="removeKYCDoc(index)"
                                  >Remove</u
                                >
                              </small>
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="kyc.errors.filesize">
                              {{ kyc.errors.filesize }}
                            </p>
                          </small>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @click="docValidation(kyc.displayFileName, 'issueDate')"
                      >
                        <label for="exampleInputEmail1">Issue Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="KYCDate"
                          id="KYCDate"
                          placeholder="Select Date"
                          v-model="kyc.KYCDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          :disabledDates="{ from: new Date() }"
                          :disabled="kyc.displayFileName === null"
                          :class="{
                            'is-invalid':
                              docIssueDateValidation &&
                              kyc.displayFileName === null,
                          }"
                        ></datepicker>
                        <div
                          v-if="kyc.displayFileName === null"
                          class="invalid-feedback"
                        >
                          <span v-if="docIssueDateValidation"
                            >Please upload the Document</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @click="
                          docValidation(kyc.displayFileName, 'expiryDate')
                        "
                      >
                        <label for="exampleInputEmail1">Expiry Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="KYCExpDate"
                          placeholder="Select Date"
                          v-model="kyc.KYCExpDate"
                          id="KYCExpDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          :disabledDates="{
                            to: new Date(kyc.KYCDate - 8640000),
                          }"
                          :disabled="kyc.displayFileName === null"
                          :class="{
                            'is-invalid':
                              docExpiryDateValidation &&
                              kyc.displayFileName === null,
                          }"
                        ></datepicker>
                        <div
                          v-if="kyc.displayFileName === null"
                          class="invalid-feedback"
                        >
                          <span v-if="docExpiryDateValidation"
                            >Please upload the Document</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="kyc.comments"
                          id="comments"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.KYCList.$each[index].comments.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.KYCList.$each[index].comments.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.KYCList.$each[index].comments.maxLength"
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          v-on:click="deleteKYC(index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onKYCSaveInfo()"
                        :disabled="isDisabledKYCSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a @click="$router.go(-1)" style="cursor: pointer">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      //master tables
      id: null,
      KYCDocuments: [],
      KycStatus: [],
      investmentCategory: [],
      investorstates: [],
      investorType: [],
      cities: [],
      states: [],
      countries: [],
      countryCodes: [],
      investors: [],
      //variables for Investor information
      selectedInvestmentCategory: null,
      selectedInvestorType: null,
      InvestorName: null,
      investorAddress: null,
      selectedinvestorCountry: null,
      investorContact: null,
      investorContactCode: null,
      secondaryInvestorContact: null,
      secondaryInvestorContactCode: null,
      investorWebsite: null,
      investorEmail: null,
      secondaryInvestorEmail: null,
      selectedState: null,
      selectedCity: null,
      regDate: new Date(), //default current date
      regDate1: null,
      incorporationDate: null,
      //list for kyc documents
      KYCList: [],
      KYCFile: null,
      KYCButtonDisabled: true,
      KYCDisabled: true,
      KYCSaveInfoDisabled: true, //save info button is disabled
      KYCAddMoreDisabled: true,
      msg: [],
      error: "",
      isSubmitted: false,
      onSubmitDisabled: false,
      investorExists: false,
      docIssueDateValidation: false,
      docExpiryDateValidation: false,
    };
  },
  watch: {
    investorEmail(value) {
      // binding this to the data value in the investorEmail input
      this.investorEmail = value;
      this.validateEmail(value);
    },
    secondaryInvestorEmail(value) {
      // binding this to the data value in the email input
      this.secondaryInvestorEmail = value;
      this.validateEmail(value, "secondary");
    },
    investorWebsite(value) {
      // binding this to the data value in the website input
      this.investorWebsite = value;
      this.validateWebsite(value);
    },
  },
  validations: {
    selectedInvestmentCategory: {
      required,
    },
    selectedInvestorType: {
      required: function () {
        if (
          (this.selectedInvestmentCategory === "Notes" ||
            this.selectedInvestmentCategory === null) &&
          this.selectedInvestorType === null
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
    InvestorName: {
      required,
      maxLength: maxLength(100),
    },
    investorAddress: {
      required,
      maxLength: maxLength(250),
    },
    selectedinvestorCountry: {
      required,
    },
    investorContactCode: {
      required,
    },
    investorContact: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(13),
    },
    secondaryInvestorContact: {
      required: function () {
        if (this.secondaryInvestorContactCode && !this.secondaryInvestorContact)
          return false;
        else return true;
      },
      minLength: minLength(10),
      maxLength: maxLength(13),
    },
    secondaryInvestorContactCode: {
      required: function () {
        if (!this.secondaryInvestorContactCode && this.secondaryInvestorContact)
          return false;
        else return true;
      },
    },
    investorEmail: {
      required,
      maxLength: maxLength(50),
    },
    secondaryInvestorEmail: {
      maxLength: maxLength(50),
    },
    investorWebsite: {
      maxLength: maxLength(100),
    },
    regDate: {
      required,
    },
    KYCList: {
      $each: {
        comments: {
          maxLength: maxLength(50),
        },
      },
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
    //this is to computed disabled property of KYC Save Info button.
    isDisabledKYCSaveInfo: function () {
      let checkEmptyKYCDocName = this.KYCList.filter(
        (line) => line.KYCDocName === null
      );
      //if the line is empty then disable save info button
      if (this.KYCList.length === 0 && this.KYCSaveInfoDisabled === true) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (checkEmptyKYCDocName.length >= 1 && this.KYCList.length > 0) {
        this.KYCDisabled = true;
      } else {
        this.KYCDisabled = false;
      }
      //KYCDisabled variable is to check whther all input fields except file upload are filled. KYCFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (this.KYCDisabled === true || this.KYCFile === null) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledKYCSaveInfo() close
  },
  components: {
    Datepicker,
  },
  methods: {
    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isAlpha(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    validateEmail(value, type) {
      if (type === "secondary") {
        if (/^\w+([\.-]\w+)*@\D\w+\.(\D{2,3})$/.test(value) || value === "") {
          this.msg["secondaryInvestorEmail"] = "";
        } else {
          this.msg["secondaryInvestorEmail"] = "Invalid Email Address";
        }
      } else {
        if (/^\w+([\.-]\w+)*@\D\w+\.(\D{2,3})$/.test(value)) {
          this.msg["investorEmail"] = "";
        } else {
          this.msg["investorEmail"] = "Invalid Email Address";
        }
      }
    },
    validateWebsite(value) {
      if (/^(www\.)([A-Za-z0-9-])+(\.\D{2,3})$/.test(value) || value === "") {
        this.msg["investorWebsite"] = "";
      } else {
        this.msg["investorWebsite"] = "Invalid Website";
      }
    },
    // check if borrower already exists
    checkInvestorName() {
      if (this.investors.filter((inv) => inv === this.InvestorName).length)
        this.investorExists = true;
      else this.investorExists = false;
    },
    //this method will make disabled property as false for Director Save Info
    onKYCSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.KYCSaveInfoDisabled = false;
      this.KYCAddMoreDisabled = false;
    },

    //this function deletes KYC record
    deleteKYC(index) {
      this.KYCList.splice(index, 1);
      this.KYCFile = "";
      if (typeof this.KYCList !== "undefined" && this.KYCList.length == 0) {
        this.KYCAddMoreDisabled = false;
        this.KYCSaveInfoDisabled = true;
      }
    },

    kycValidation() {
      let err = false;
      this.KYCList.filter((kyc) => {
        if (kyc.KYCDocName === null || kyc.displayFileName === null) {
          err = true;
        }
      });
      return err;
    },

    // issue & expiry date doc validation
    docValidation(doc, type) {
      if (type === "issueDate") {
        this.docIssueDateValidation = doc === null;
      } else if (type === "expiryDate") {
        this.docExpiryDateValidation = doc === null;
      }
    },

    //this method will submit the investor form in backend.
    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.selectedInvestmentCategory !== "Participation") {
        if (
          this.$v.$invalid ||
          this.kycValidation() ||
          this.investorExists ||
          !Object.values(this.msg).every((e) => e === "")
        ) {
          return;
        }
      } else {
        if (
          this.$v.$invalid ||
          this.investorExists ||
          !Object.values(this.msg).every((e) => e === "")
        ) {
          return;
        }
      }
      // if (this.selectedInvestmentCategory !== "Participation") {
      //   if (
      //     this.InvestorName == null ||
      //     this.investorAddress == null ||
      //     this.selectedinvestorCountry == null ||
      //     this.selectedInvestmentCategory == null ||
      //     this.selectedInvestorType == null ||
      //     this.investorContact == null ||
      //     this.investorEmail == null ||
      //     this.regDate == null ||
      //     this.KYCSaveInfoDisabled === true
      //   ) {
      //     //here we will check for all mandatory fields
      //     Utils.make_alert("warning", "Mandatory fields are required.");
      //     return;
      //   }
      // } else {
      //   if (
      //     this.InvestorName == null ||
      //     this.investorAddress == null ||
      //     this.selectedinvestorCountry == null ||
      //     this.selectedInvestmentCategory == null ||
      //     this.investorContact == null ||
      //     this.investorEmail == null ||
      //     this.regDate == null
      //   ) {
      //     //here we will check for all mandatory fields
      //     Utils.make_alert("warning", "Mandatory fields are required.");
      //     return;
      //   }
      // }
      if (this.regDate != null) {
        this.regDate1 = moment(this.regDate).format("MM-DD-YYYY");
      }
      if (this.incorporationDate != null) {
        this.incorporationDate = moment(this.incorporationDate).format(
          "MM-DD-YYYY"
        );
      }
      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("InvestorName", this.InvestorName);
      formData.append("Address", this.investorAddress);
      formData.append(
        "selectedInvestmentCategory",
        this.selectedInvestmentCategory
      );
      formData.append("selectedInvestmenttype", this.selectedInvestorType);
      formData.append("selectedCountry", this.selectedinvestorCountry);
      formData.append("contact", this.investorContact);
      formData.append("contactCode", this.investorContactCode);
      formData.append("secondaryContact", this.secondaryInvestorContact);
      formData.append(
        "secondaryContactCode",
        this.secondaryInvestorContactCode
      );
      formData.append("EmailID", this.investorEmail);
      formData.append("secondaryEmailID", this.secondaryInvestorEmail);
      formData.append("RegistrationDate", this.regDate1);
      formData.append("selectedState", this.selectedState);
      formData.append("selectedCity", this.selectedCity);
      formData.append("Website", this.investorWebsite);
      formData.append("IncorporationDate", this.incorporationDate);
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 3);
      formData.append("tabId", 11);

      //append KYCList information to formdata
      if (this.selectedInvestmentCategory != "Participation") {
        formData.append("KYCDetails", JSON.stringify(this.KYCList));
      }
      const path = "investor";
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          console.log("status: " + response.data.status);
          if (response.data.status == "success") {
            Utils.make_alert("success", "Investor added successfully!");
            console.log("SUCCESS!!");
            this.$router.push("/investor");
          } else {
            Utils.make_alert("warning", "Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
          Utils.make_alert(
            "warning",
            error.response.status === 400
              ? error.response.data.status
              : "Something went wrong!"
          );
        });
    }, //onSubmit() close

    //this method will add new line for additional directors
    addKYC() {
      //when new line is added then KYCFile variable and KYCSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.KYCFile = null),
        (this.KYCSaveInfoDisabled = true),
        (this.KYCAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.KYCList.push({
          KYCDocName: null,
          KYCDate: null,
          KYCExpDate: null,
          comments: null,
          displayFileName: null,
          errors: { filesize: "" },
          KYCRemoveDisabled: true,
        });
    }, //addKYC() close

    //handles a change on the file upload
    handleFileUploadKYC(id, e, doc) {
      let file_list = this.KYCList.map((file) => file.displayFileName);
      let doc_list = this.KYCList.slice(0, -1).map((doc) => doc.KYCDocName);
      // check if file already exists for same document name, show pop if true and delete record
      if (
        file_list.includes(e.target.files[0].name) &&
        doc_list.includes(doc)
      ) {
        this.deleteKYC(this.KYCList.length - 1);
        Utils.make_alert("warning", "File Already Exists");
        return;
      }
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.KYCFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.KYCFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors.filesize = "File size should be less than 100MB";
          self.KYCList[id].errors.filesize = JSON.stringify(
            response.errors.filesize
          ).replace(/\"/g, "");
          self.KYCList[id].selectedFile = null;
          self.KYCList[id].selectedFileName = null;
          self.KYCList[id].displayFileName = null;
          self.KYCList[id].KYCRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.KYCFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.KYCList[id].selectedFile = reader.result;
          self.KYCList[id].selectedFileName = e.target.files[0].name;
          self.KYCList[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.KYCList[id].errors.filesize = "";
          self.KYCList[id].KYCRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadKYC() close

    ////this method handles remove kyc attachment
    removeKYCDoc(id) {
      this.KYCFile = null;
      this.KYCList[id].selectedFile = null;
      this.KYCList[id].selectedFileName = null;
      this.KYCList[id].displayFileName = null;
      this.KYCList[id].KYCRemoveDisabled = true;
    },

    //this function is called to get list of investment category
    getInvestmentCategory() {
      const path = "investmentcategory";
      axios
        .get(path)
        .then((res) => {
          this.investmentCategory = res.data.investmentcategories;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getInvestmentCategory() close

    //this function is called to get list of investment category
    getInvestorTypes() {
      const path = "investortype";
      axios
        .get(path)
        .then((res) => {
          this.investorType = res.data.investortypes;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getInvestorTypes() close

    getCities() {
      //this function is called to get list of cities
      const path = "city";
      axios
        .get(path)
        .then((res) => {
          this.cities = res.data.cities;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getStates() {
      //this function is called to get list of states
      const path = "state";
      axios
        .get(path)
        .then((res) => {
          this.states = res.data.states;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of documents master
    getKYCDocuments() {
      const path = "kyc-document-master";
      axios
        .get(path)
        .then((res) => {
          this.KYCDocuments = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getKYCDocuments() close

    //this function is called to get list of countries
    getCountries() {
      const path = "country";
      axios
        .get(path)
        .then((res) => {
          this.countries = res.data.countries;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getCountries() close

    getStatesByCountry() {
      if(this.selectedinvestorCountry == null){
        this.states = [];
        this.selectedState = null;
        this.cities = [];
        this.selectedCity = null;
        return;
      }
      //this function is called to get list of states
      const path = "get-state-by-country/" + this.selectedinvestorCountry ;
      axios
        .get(path)
        .then((res) => {
          this.states = [];
          this.selectedState = null;
          this.cities = [];
          this.selectedCity = null;
          this.states = res.data.states;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCitiesByState() {
      if(this.selectedState == null){
        this.cities = [];
        this.selectedCity = null;
        return;
      }
      //this function is called to get list of cities
      const path = "get-city-by-state/" + this.selectedState;
      axios
        .get(path)
        .then((res) => {
          this.cities = [];
          this.selectedCity = null;
          this.cities = res.data.cities;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCountryCodes() {
      //this function is called to get list of country codes
      const path = "country_code_master";
      axios
        .get(path)
        .then((res) => {
          this.countryCodes = res.data.country_codes;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getInvestors() {
      //this function will be called to get list of investors
      const path = "investor";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            if (res.data.investors_active.length)
              this.investors = res.data.investors_active.map(
                (inv) => inv.investor_name
              );

            console.log("get investors is successful.");
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },
  }, //main function close

  mounted() {
    this.selectedInvestmentCategory === "Notes";
    this.addKYC();
  },

  created() {
    this.getInvestmentCategory();
    this.getInvestorTypes();
    this.getCountries();
    this.getKYCDocuments();
    this.getCountryCodes();
    this.getInvestors();
  },
};
</script>

<style scoped>
.error {
  color: red;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -70px;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>
