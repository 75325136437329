<template>
  <div class="wrapper">
    <nav-bar></nav-bar>

    <aside-bar></aside-bar>

    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->

        <div class="container-fluid ot_tb pt-3">
          <div class="row">
            <div class="container-fluid tabtop pt-3 col-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt">Borrower Monthly Statement</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- /.card -->
              </div>
            </div>

            <div class="container-fluid tabtop col-lg-6 col-md-6 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="col">
                    <div class="row">
                      <div class="col-md-6 col-sm-6 col-12">
                        <div class="form-group fc">
                          <label class="required-field">Borrower Name</label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="borrowerName"
                            id="borrowerName"
                            disabled
                            :class="{
                              'is-invalid':
                                isSubmitted && $v.borrowerName.$error,
                            }"
                          />

                          <div
                            v-if="isSubmitted && $v.borrowerName.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.borrowerName.required">
                              Borrower Name is required
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-6 col-12">
                        <div class="form-group fc">
                          <label class="required-field">Date</label>

                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="date"
                            id="date"
                            placeholder="Select Date"
                            v-model="date"
                            format="MM-yyyy"
                            minimum-view="month"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{
                              to: new Date(backcapDate),
                              from: new Date(lastMonth),
                            }"
                            :class="{
                              'is-invalid': isSubmitted && $v.date.$error,
                            }"
                          ></datepicker>

                          <div
                            v-if="isSubmitted && $v.date.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.date.required">
                              Date is required
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-sm-12 col-12">
                        <div class="form-group fc">
                          <label class="required-field">Borrower Address</label>

                          <textarea
                            class="form-control"
                            rows="2"
                            style="height: 110px !important; resize: none"
                            v-model="borrowerAddress"
                            id="borrowerAddress"
                            :class="{
                              'is-invalid':
                                isSubmitted && $v.borrowerAddress.$error,
                            }"
                          ></textarea>

                          <div
                            v-if="isSubmitted && $v.borrowerAddress.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.borrowerAddress.required">
                              Borrower Address is required
                            </span>

                            <span v-if="!$v.borrowerAddress.maxLength">
                              Max length of 250 characters exceeded
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-6 col-12">
                        <div class="form-group fc">
                          <label class="required-field">To The </label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="recipient"
                            id="recipient"
                            @keypress="isAlpha($event)"
                            :class="{
                              'is-invalid': isSubmitted && $v.recipient.$error,
                            }"
                          />

                          <div
                            v-if="isSubmitted && $v.recipient.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.recipient.required">
                              To The is required
                            </span>

                            <span v-if="!$v.recipient.maxLength">
                              Max length of 100 characters exceeded
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-6 col-12">
                        <div class="form-group fc">
                          <label class="required-field">Fund Name </label>

                          <select
                            id="fundName"
                            class="form-control"
                            name="fundName"
                            v-model="fundName"
                            @change="updateSubject()"
                            :class="{
                              'is-invalid': isSubmitted && $v.fundName.$error,
                            }"
                          >
                            <option :value="null">--- Select ---</option>

                            <option
                              v-for="fund in funds"
                              :key="fund.id"
                              :value="fund.fund_name"
                            >
                              {{ fund.fund_name }}
                            </option>
                          </select>

                          <div
                            v-if="isSubmitted && $v.fundName.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.fundName.required">
                              Fund Name is required
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- /.card -->
              </div>
            </div>

            <div class="container-fluid tabtop col-lg-6 col-md-6 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="col">
                    <div class="row">
                      <div class="col-md-6 col-sm-6 col-12">
                        <div class="form-group fc">
                          <label class="required-field">Company Name</label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="companyName"
                            id="companyName"
                            disabled
                            :class="{
                              'is-invalid':
                                isSubmitted && $v.companyName.$error,
                            }"
                          />

                          <div
                            v-if="isSubmitted && $v.companyName.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.companyName.required">
                              Company Name is required
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-6 col-12">
                        <div class="form-group fc">
                          <label for="phoneNumber" class="required-field">
                            Contact No
                          </label>

                          <br />

                          <select
                            id="contactNoCode"
                            class="contact_code"
                            name="contactNoCode"
                            v-model="contactNoCode"
                            :class="{
                              'is-invalid':
                                isSubmitted && $v.contactNoCode.$error,
                            }"
                          >
                            <option :value="null">--Select--</option>

                            <option
                              v-for="code in countryCodes"
                              :key="code.id"
                              :value="code.country_code"
                            >
                              {{ code.country_code }}
                            </option>
                          </select>

                          <input
                            class="contact"
                            placeholder
                            v-model="contactNo"
                            id="contactNo"
                            @keypress="isNum($event)"
                            :class="{
                              'is-invalid': isSubmitted && $v.contactNo.$error,
                            }"
                          />

                          <div
                            v-if="isSubmitted && $v.contactNoCode.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.contactNoCode.required">
                              Contact Number Code is required
                            </span>
                          </div>

                          <div
                            v-if="isSubmitted && $v.contactNo.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.contactNo.required">
                              Contact Number is required
                            </span>

                            <span v-if="!$v.contactNo.minLength">
                              Min length of 10 characters required
                            </span>

                            <span v-if="!$v.contactNo.maxLength">
                              Max length of 13 characters exceeded
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-sm-12 col-12">
                        <div class="form-group fc">
                          <label class="required-field">Company Address</label>

                          <textarea
                            class="form-control"
                            style="resize: none"
                            v-model="companyAddress"
                            id="companyAddress"
                            :class="{
                              'is-invalid':
                                isSubmitted && $v.companyAddress.$error,
                            }"
                          ></textarea>

                          <div
                            v-if="isSubmitted && $v.companyAddress.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.companyAddress.required">
                              Company Address is required
                            </span>

                            <span v-if="!$v.companyAddress.maxLength">
                              Max length of 250 characters exceeded
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-6 col-12">
                        <div class="form-group fc">
                          <label class="required-field">
                            Designation Name of Company Person
                          </label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="companyDesignation"
                            id="companyDesignation"
                            @keypress="isAlpha($event)"
                            :class="{
                              'is-invalid':
                                isSubmitted && $v.companyDesignation.$error,
                            }"
                          />

                          <div
                            v-if="isSubmitted && $v.companyDesignation.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.companyDesignation.required">
                              Designation Name of Company Person is required
                            </span>

                            <span v-if="!$v.companyDesignation.maxLength">
                              Max length of 50 characters exceeded
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-6 col-12">
                        <div class="form-group fc">
                          <label class="required-field">Email ID</label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="email"
                            id="email"
                            :class="{
                              'is-invalid':
                                (isSubmitted && $v.email.$error) || msg.email,
                            }"
                          />

                          <div class="invalid-feedback" v-if="msg.email">
                            <span>{{ msg.email }}</span>
                          </div>

                          <div
                            v-if="isSubmitted && $v.email.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.email.required">
                              Email ID is required
                            </span>

                            <span v-if="!$v.email.maxLength">
                              Max length of 50 characters exceeded
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-6 col-12">
                        <div class="form-group fc">
                          <label class="required-field">Website</label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="website"
                            id="website"
                            :class="{
                              'is-invalid':
                                (isSubmitted && $v.website.$error) ||
                                msg.website,
                            }"
                          />

                          <div class="invalid-feedback" v-if="msg.website">
                            <span>{{ msg.website }}</span>
                          </div>

                          <div
                            v-if="isSubmitted && $v.website.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.website.required">
                              Website is required
                            </span>

                            <span v-if="!$v.website.maxLength">
                              Max length of 100 characters exceeded
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- /.card -->
              </div>
            </div>

            <div class="container-fluid tabtop col-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="form-group">
                            <label>Subject</label>

                            <textarea
                              class="form-control"
                              rows="2"
                              v-model="subject"
                              id="subject"
                              :class="{
                                'is-invalid': isSubmitted && $v.subject.$error,
                              }"
                            ></textarea>

                            <div
                              v-if="isSubmitted && $v.subject.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.subject.maxLength">
                                Max length of 500 characters exceeded
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="form-group">
                            <label>Body</label>

                            <textarea
                              class="form-control"
                              rows="2"
                              v-model="body"
                              id="body"
                              :class="{
                                'is-invalid': isSubmitted && $v.body.$error,
                              }"
                            ></textarea>

                            <div
                              v-if="isSubmitted && $v.body.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.body.maxLength">
                                Max length of 500 characters exceeded
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- /.card -->
              </div>
            </div>

            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 text-center mb-3">
                  <ul id="sr_btn" style="text-align: center !important">
                    <li>
                      <button
                        type="button"
                        class="btn-sm btn btn-success sh"
                        @click="onSubmit()"
                      >
                        Save
                      </button>
                    </li>

                    <li>
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        @click="$router.go(-1)"
                      >
                        Cancel
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div v-if="saved" class="statement">
              <table
                class="maintb"
                border="0"
                align="center"
                cellpadding="0"
                cellspacing="0"
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        class="midtb"
                        border="0"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <img
                                :src="
                                  require(`@/asset/images/${logoFolder}/statement_logo.png`)
                                "
                                width="115"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <table
                                width="620"
                                border="0"
                                align="center"
                                cellpadding="0"
                                cellspacing="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="300"
                                      align="left"
                                      valign="top"
                                      style="padding-right: 30px !important"
                                    >
                                      <p class="txt">
                                        <strong>{{ formattedDate }}</strong>
                                      </p>

                                      <p class="txt">
                                        <strong>
                                          <i>Private & Confidential</i>
                                        </strong>
                                      </p>

                                      <p class="txt">
                                        {{ recipient }}
                                        <br />
                                        {{ borrowerName }}
                                        <br />
                                        {{ borrowerAddress }}
                                      </p>
                                    </td>

                                    <td width="300" align="left" valign="top">
                                      <p class="txt">
                                        <strong>{{ companyName }}</strong>
                                      </p>

                                      <p class="txt">
                                        {{ companyAddress }}
                                        <br />
                                      </p>

                                      <p class="txt">
                                        <strong>T:</strong>
                                        {{ contactNoCode }} {{ contactNo }}
                                        <br />

                                        <strong>E:</strong>
                                        {{ email }}
                                      </p>

                                      <p>
                                        <a>{{ website }}</a>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>

                          <tr>
                            <td
                              style="
                                background-color: #fff;
                                height: 30px;
                                font-size: 1px;
                              "
                            >
                              &nbsp;
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p
                                class="txt"
                                style="margin-top: 6px; margin-bottom: 6px"
                              >
                                BY EMAIL
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p
                                class="txt"
                                style="margin-top: 6px; margin-bottom: 6px"
                              >
                                <strong>{{ formattedDate }}</strong>
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p
                                class="txt"
                                style="margin-top: 6px; margin-bottom: 6px"
                              >
                                <strong>{{ subject }}</strong>
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p
                                class="txt"
                                style="margin-top: 6px; margin-bottom: 6px"
                              >
                                Please examine the below summary statement of
                                {{ borrowerName }}’s facility with the
                                {{ fundName }} as at {{ formattedDate }}. The
                                full statement of account accompanies this
                                summary.
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td
                              style="
                                background-color: #fff;
                                height: 10px;
                                font-size: 1px;
                              "
                            >
                              &nbsp;
                            </td>
                          </tr>

                          <!------------------------Table start-------------------------->

                          <tr>
                            <td>
                              <table width="600">
                                <tbody>
                                  <tr bgcolor="3b3838">
                                    <td class="h_header">Bucket</td>

                                    <td class="h_header">Drawdown</td>
                                    <td class="h_header">Costs</td>
                                    <td class="h_header">Interest Charged (Paid)</td>

                                    <td class="h_header">Offtaker Payment</td>
                                    <td class="h_header">Equity Investment</td>
                                    <td class="h_header">Profit Return</td>

                                    <td class="h_header">Accrued Interest</td>
                                    <td class="h_header">Total</td>
                                  </tr>

                                  <tr bgcolor="#ffffff">
                                    <td class="v_header">Drawdown</td>

                                    <td
                                      class="tcell"
                                      v-if="statementTableData.total_drawdown"
                                    >
                                      {{ statementTableData.total_drawdown }}
                                    </td>

                                    <td class="tcell" v-else>-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="statementTableData.total_drawdown"
                                    >
                                      {{ statementTableData.total_drawdown }}
                                    </td>

                                    <td class="tcell" v-else>-</td>
                                  </tr>

                                  <tr bgcolor="#F0EEF5">
                                    <td class="v_header">Facility Costs</td>

                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.total_facility_costs
                                      "
                                    >
                                      {{
                                        statementTableData.total_facility_costs
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.total_facility_costs
                                      "
                                    >
                                      {{
                                        statementTableData.total_facility_costs
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>
                                  </tr>

                                  <tr bgcolor="#ffffff">
                                    <td class="v_header">Bank Charges</td>

                                    <td class="tcell">-</td>

                                    

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.total_bank_charges
                                      "
                                    >
                                      {{
                                        statementTableData.total_bank_charges
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.total_bank_charges
                                      "
                                    >
                                      {{
                                        statementTableData.total_bank_charges
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>
                                  </tr>

                                  <tr bgcolor="#F0EEF5">
                                    <td class="v_header">Fees</td>

                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="statementTableData.total_fees"
                                    >
                                      {{ statementTableData.total_fees }}
                                    </td>

                                    <td class="tcell" v-else>-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="statementTableData.total_fees"
                                    >
                                      {{ statementTableData.total_fees }}
                                    </td>

                                    <td class="tcell" v-else>-</td>
                                  </tr>

                                  <tr bgcolor="#ffffff">
                                    <td class="v_header">Loan Interest</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.loan_interest_paid
                                      "
                                    >
                                      {{
                                        statementTableData.loan_interest_paid
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.loan_interest_paid
                                      "
                                    >
                                      {{
                                        statementTableData.loan_interest_paid
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>
                                  </tr>

                                  <tr bgcolor="#F0EEF5">
                                    <td class="v_header">Cost Interest</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.cost_interest_paid
                                      "
                                    >
                                      {{
                                        statementTableData.cost_interest_paid
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.cost_interest_paid
                                      "
                                    >
                                      {{
                                        statementTableData.cost_interest_paid
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>
                                  </tr>

                                  <tr bgcolor="#ffffff">
                                    <td class="v_header">Penalty Interest</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.penalty_interest_paid
                                      "
                                    >
                                      {{
                                        statementTableData.penalty_interest_paid
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.penalty_interest_paid
                                      "
                                    >
                                      {{
                                        statementTableData.penalty_interest_paid
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>
                                  </tr>

                                  <tr bgcolor="#F0EEF5">
                                    <td class="v_header">Offtaker Payment</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.offtaker_repayment
                                      "
                                    >
                                      {{ statementTableData.offtaker_repayment }}
                                    </td>

                                    <td class="tcell" v-else>-</td>

                                    <td class="tcell">-</td>
                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.offtaker_repayment
                                      "
                                    >
                                      {{ statementTableData.offtaker_repayment }}
                                    </td>

                                    <td class="tcell" v-else>-</td>
                                  </tr>

                                  <tr bgcolor="#ffffff">
                                    <td class="v_header">Equity Investment</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>
                                    <td class="tcell">-</td>
                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.equity_investment
                                      "
                                    >
                                      {{
                                        statementTableData.equity_investment
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>

                                    

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.equity_investment
                                      "
                                    >
                                      {{
                                        statementTableData.equity_investment
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>
                                  </tr>

                                  <tr bgcolor="#F0EEF5">
                                    <td class="v_header">Profit Return</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.total_profit_return
                                      "
                                    >
                                      {{
                                        statementTableData.total_profit_return
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>
                                    <td class="tcell">-</td>
                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.total_profit_return
                                      "
                                    >
                                      {{
                                        statementTableData.total_profit_return
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>
                                  </tr>

                                  <tr bgcolor="#ffffff">
                                    <td class="v_header">Accrued Loan Interest</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.accrued_loan_interest
                                      "
                                    >
                                      {{
                                        statementTableData.accrued_loan_interest
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.accrued_loan_interest
                                      "
                                    >
                                      {{
                                        statementTableData.accrued_loan_interest
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>
                                  </tr>

                                  <tr bgcolor="#F0EEF5">
                                    <td class="v_header">Accrued Cost Interest</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.accrued_cost_interest
                                      "
                                    >
                                      {{
                                        statementTableData.accrued_cost_interest
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.accrued_cost_interest
                                      "
                                    >
                                      {{
                                        statementTableData.accrued_cost_interest
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>
                                  </tr>

                                  <tr bgcolor="#ffffff">
                                    <td class="v_header">Accrued Penalty Interest</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    <td class="tcell">-</td>

                                    

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.accrued_penalty_interest
                                      "
                                    >
                                      {{
                                        statementTableData.accrued_penalty_interest
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>

                                    <td
                                      class="tcell"
                                      v-if="
                                        statementTableData.accrued_penalty_interest
                                      "
                                    >
                                      {{
                                        statementTableData.accrued_penalty_interest
                                      }}
                                    </td>

                                    <td class="tcell" v-else>-</td>
                                  </tr>

                                  <tr bgcolor="#808080">
                                    <td></td>

                                    <td></td>

                                    <td></td>

                                    <td></td>

                                    <td></td>

                                    <td></td>

                                    <td></td>

                                    <td></td>

                                    <td height="2"></td>
                                  </tr>

                                  <tr bgcolor="#ffffff">
                                    <td class="v_header"></td>

                                    <td
                                      class="v_header right"
                                      v-if="statementTableData.total_drawdown"
                                    >
                                      {{ statementTableData.total_drawdown }}
                                    </td>

                                    <td class="v_header right" v-else>-</td>

                                    <td
                                      class="v_header right"
                                      v-if="
                                        statementTableData.total_costs
                                      "
                                    >
                                      {{
                                        statementTableData.total_costs
                                      }}
                                    </td>

                                    <td class="v_header right" v-else>-</td>

                                    <td
                                      class="v_header right"
                                      v-if="
                                        statementTableData.total_interest_paid
                                      "
                                    >
                                      {{
                                        statementTableData.total_interest_paid
                                      }}
                                    </td>

                                    <td class="v_header right" v-else>-</td>

                                    <td
                                      class="v_header right"
                                      v-if="statementTableData.offtaker_repayment"
                                    >
                                      {{ statementTableData.offtaker_repayment }}
                                    </td>

                                    <td class="v_header right" v-else>-</td>

                                    <td
                                      class="v_header right"
                                      v-if="
                                        statementTableData.equity_investment
                                      "
                                    >
                                      {{
                                        statementTableData.equity_investment
                                      }}
                                    </td>

                                    <td class="v_header right" v-else>-</td>

                                    <td
                                      class="v_header right"
                                      v-if="statementTableData.total_profit_return"
                                    >
                                      {{ statementTableData.total_profit_return }}
                                    </td>

                                    <td class="v_header right" v-else>-</td>

                                    <td
                                      class="v_header right"
                                      v-if="
                                        statementTableData.total_accrued_interest
                                      "
                                    >
                                      {{
                                        statementTableData.total_accrued_interest
                                      }}
                                    </td>

                                    <td class="v_header right" v-else>-</td>

                                    <td
                                      class="v_header right"
                                      v-if="statementTableData.total_sum"
                                    >
                                      {{ statementTableData.total_sum }}
                                    </td>

                                    <td class="v_header right" v-else>-</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>

                          <tr>
                            <td
                              style="
                                background-color: #fff;
                                height: 30px;
                                font-size: 1px;
                              "
                            >
                              &nbsp;
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p
                                class="txt"
                                style="margin-top: 6px; margin-bottom: 6px"
                              >
                                {{ body }}
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td
                              style="
                                background-color: #fff;
                                height: 10px;
                                font-size: 1px;
                              "
                            >
                              &nbsp;
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <table
                                width="600"
                                border="0"
                                align="center"
                                cellpadding="0"
                                cellspacing="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="300"
                                      align="left"
                                      valign="top"
                                      style="padding-right: 30px !important"
                                    >
                                      <p class="txt">
                                        <strong>Yours Faithfully </strong>
                                      </p>

                                      <p class="txt" style="margin-top: 50px">
                                        {{ companyDesignation }}
                                        <br />
                                        Sent on behalf of
                                        <br />

                                        <strong>
                                          {{ companyName }}
                                          <br />
                                          (acting for and on behalf of
                                          <br />
                                          {{ fundName }})
                                        </strong>

                                        <br />
                                      </p>
                                    </td>

                                    <td width="300" align="left" valign="top">
                                      <p class="txt">
                                        <strong>
                                          Signed on for and on behalf of
                                        </strong>
                                      </p>

                                      <p class="txt" style="margin-top: 50px">
                                        ........................................
                                      </p>

                                      <p class="txt">
                                        {{ borrowerName }}
                                        <br />
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>

                          <tr>
                            <td
                              style="
                                background-color: #fff;
                                height: 30px;
                                font-size: 1px;
                              "
                            >
                              &nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td
                      style="
                        background-color: #1c3750;
                        height: 20px;
                        font-size: 1px;
                      "
                    >
                      &nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-if="saved" class="container-fluid" style="margin-top: 20px">
              <div class="row">
                <div class="col-md-12 text-center mb-3">
                  <ul id="sr_btn" style="text-align: center !important">
                    <li>
                      <button
                        type="button"
                        class="btn-sm btn btn-success sh"
                        @click="downloadReport()"
                      >
                        Approve & Download
                      </button>
                    </li>

                    <li>
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        @click="$router.go()"
                      >
                        Cancel
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Main content -->
    </div>

    <!-- ./wrapper -->
  </div>
</template>

<script>
//window.jsPDF = window.jspdf.jsPDF;
import { jsPDF } from 'jspdf';
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength, minLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      countryCodes: [],
      statementDetails: [],
      funds: [],
      statementTableData: [],
      borrowerName: null,
      date: null,
      formattedDate: null,
      borrowerAddress: null,
      recipient: null,
      fundName: null,
      fundId: null,
      companyName: null,
      contactNo: null,
      contactNoCode: null,
      companyAddress: null,
      companyDesignation: null,
      email: null,
      website: null,
      subject: null,
      body: null,
      msg: {},
      saved: false,
      filePath: null,
      isSubmitted: false,
      backcapDate: null,
      lastMonth: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1
      ),
      // lastMonth: moment()
      //   .subtract(1, "months")
      //   .endOf("month")
      //   .format("MM-DD-YYYY"),
      logoFolder: null,
    };
  },
  components: {
    Datepicker,
  },
  validations: {
    borrowerName: {
      required,
    },
    date: {
      required,
    },
    borrowerAddress: {
      required,
      maxLength: maxLength(250),
    },
    recipient: {
      required,
      maxLength: maxLength(100),
    },
    fundName: {
      required,
    },
    companyName: {
      required,
    },
    contactNoCode: {
      required,
    },
    contactNo: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(13),
    },
    companyAddress: {
      required,
      maxLength: maxLength(250),
    },
    companyDesignation: {
      required,
      maxLength: maxLength(50),
    },
    email: {
      required,
      maxLength: maxLength(50),
    },
    website: {
      required,
      maxLength: maxLength(100),
    },
    subject: {
      maxLength: maxLength(500),
    },
    body: {
      maxLength: maxLength(500),
    },
  },
  watch: {
    email(value) {
      // binding this to the data value in the email input
      this.email = value;
      this.validateEmail(value);
    },
    website(value) {
      // binding this to the data value in the website input
      this.website = value;
      this.validateWebsite(value);
    },
    fundName(value) {
      this.fundName = value;
    },
  },
  methods: {
    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isAlpha(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    validateEmail(value) {
      if (
        /^\w+([\.-]\w+)*@\w+([\.-]\w+)*(\.\w{2,3})+$/.test(value) ||
        value === ""
      ) {
        this.msg["email"] = "";
      } else {
        this.msg["email"] = "Invalid Email Address";
      }
    },
    validateWebsite(value) {
      if (/^(www\.)+([A-Za-z0-9-])*(\.\w{2,3})+$/.test(value) || value === "") {
        this.msg["website"] = "";
      } else {
        this.msg["website"] = "Invalid Website";
      }
    },
    // update subject's fund name on fund change
    updateSubject() {
      let selectedFund = this.funds.filter(
        (f) => f.fund_name === this.fundName
      );
      this.fundId = selectedFund[0].id;
      this.subject =
        "MONTHLY STATEMENT CONFIRMATION between " +
        this.companyName +
        " (acting for and on behalf of " +
        this.fundName +
        ") and " +
        this.borrowerName;
    },
    downloadReport: function () {
      var HTML_Width = $(".statement").width();
      var HTML_Height = $(".statement").height();
      var top_left_margin = 15;
      var PDF_Width = HTML_Width + top_left_margin * 3;
      var PDF_Height = PDF_Width * 1.5 + top_left_margin * 4;
      var canvas_image_width = HTML_Width;
      var canvas_image_height = HTML_Height;

      html2canvas($(".statement")[0]).then(function (canvas) {
        canvas.getContext("2d");

        var imgData = canvas.toDataURL("image/jpeg", 1.0);
        var pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          top_left_margin,
          canvas_image_width,
          canvas_image_height
        );

        pdf.addPage(PDF_Width, PDF_Height.toString());
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          -(PDF_Height * 1) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height
        );
        pdf.deletePage(2);
        pdf.save("Borrower Monthly Statement.pdf");
      });
      this.borrowerStatementApprove();
    },

    borrowerStatementApprove() {
      let formatted_date;
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid || !Object.values(this.msg).every((e) => e === "")) {
        return;
      }
      if (this.date != null) {
        formatted_date = moment(this.date).endOf("month").format("YYYY-MM-DD");
      }
      Utils.start_loading();
      const path =
        "borrower-statement-approved/" +
        this.$route.params.id +
        "/" +
        this.fundId +
        "/" +
        formatted_date;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          Utils
          if (res.data.status == "success") {
            console.log("borrower statement Approved is successful.");
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
        });
    },

    //this function will be called to get borrower statement details
    getStatementDetails() {
      Utils.start_loading();
      const path = "borrower-statement-details/" + this.$route.params.id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          this.statementDetails = res.data.borrower_statement_details[0];
          this.funds = res.data.funds;
          this.borrowerName = this.statementDetails.borrower_name;
          this.borrowerAddress = this.statementDetails.borrower_address;
          this.companyName = this.statementDetails.client_name;
          this.companyAddress = this.statementDetails.company_address;
          this.email = this.statementDetails.email;
          this.website = this.statementDetails.website;
          this.backcapDate = this.statementDetails.backcap_date;
          this.fundName = this.funds.length ? this.funds[0].fund_name : null;
          this.fundId = this.funds.length ? this.funds[0].id : null;
          this.subject =
            "MONTHLY STATEMENT CONFIRMATION between " +
            this.companyName +
            " (acting for and on behalf of " +
            this.fundName +
            ") and " +
            this.borrowerName;
          if (res.data.status == "success") {
            console.log("get borrower statement details is successful.");
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
        });
    },
    onSubmit() {
      let formatted_date;
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid || !Object.values(this.msg).every((e) => e === "")) {
        return;
      }
      if (this.date != null) {
        formatted_date = moment(this.date).endOf("month").format("YYYY-MM-DD");
        this.formattedDate = moment(this.date)
          .endOf("month")
          .format("Do MMM YYYY");
      }
      this.saved = true;
      $("html, body").animate({ scrollTop: $(document).height() }, "slow");

      // var doc = new jsPDF();
      // var specialElementHandlers = {
      //   "#statement": function(element, renderer) {
      //     return true;
      //   },
      // };
      // doc.html($(".wrapper").html(), {
      //   x: 15,
      //   y: 15,
      //   width: 170,
      //   elementHandlers: specialElementHandlers,
      // });
      // doc.save("sample-file.pdf");

      Utils.start_loading();
      //this function will be called to get borrower statement details
      const path =
        "borrower-statement-data/" +
        this.$route.params.id +
        "/" +
        this.fundId +
        "/" +
        formatted_date;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          //this will be when response is received from server
          Utils.stop_loading();
          this.statementTableData = res.data.data[0];
          console.log(this.statementTableData);
          if (res.data.status == "success") {
            console.log("get borrower statement details is successful.");
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          //this is when response is not received from server
          Utils.stop_loading();
          console.error("error: " + error);
        });
    }, //onSubmit() close
    //this function is called to get list of country codes
    getCountryCodes() {
      const path = "country_code_master";
      axios
        .get(path)
        .then((res) => {
          this.countryCodes = res.data.country_codes;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.logoFolder = this.$store.state.client_id;
    axios.defaults.headers.common["Authorisation"] =
      "Bearer " + sessionStorage.getItem("token");
    this.getStatementDetails();
    this.getCountryCodes();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
      // return this.$store.getters.getuserGroup;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
      // return this.$store.getters.getuserSubGroup;
    },
  },
};
</script>

<style>
.statement {
  margin-left: 25%;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  background-color: #e2e2e2;
  font-family: Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;
}

.maintb {
  background-color: #ffffff;
  width: 700px;
}

.midtb {
  background-color: #ffffff;
  width: 600px;
}

.txt {
  font-size: 15px;
  line-height: 19px;
  color: #002060;
  text-align: justify;
}
.h_header {
  font-size: 13px;
  color: #ffffff;
  font-weight: normal;
  padding-left: 4px;
  text-align: center !important;
}
.v_header {
  font-size: 11px;
  color: #002060;
  font-weight: bold;
  padding-left: 4px;
  text-align: left;
  min-width: 88px;
}
.v_header.right {
  font-size: 11px;
  color: #002060;
  font-weight: bold;
  padding-left: 4px;
  text-align: right;
  min-width: 50px;
}
.tcell {
  font-size: 11px;
  color: #002060;
  font-weight: normal;
  text-align: right !important;
}
</style>
