<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="fas fa-tachometer-alt"></i> Inventory Overview
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="card-body br_overview">
                <!------menton here style="height: 300px;"-------->
                <table class="table table-hover table-head-fixed text-nowrap">
                  <tbody>
                    <tr>
                      <td class="wg" id="borrowerName">Borrower Name:</td>
                      <td v-if="uncleanedInfo.length || borrower_name">
                        {{
                          uncleanedInfo.length
                            ? uncleanedInfo[0].borrower_name
                            : borrower_name
                        }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <!-- <tr>
                      <td class="wg" id="date">Date:</td>
                      <td v-if="date !== null">
                        {{ date }}
                      </td>
                      <td v-else>-</td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card-body br_overview">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td class="wg" id="collateral">Inventory:</td>
                      <td v-if="uncleanedInfo.length || collateral">
                        {{
                          uncleanedInfo.length
                            ? uncleanedInfo[0].collateral_name
                            : collateral
                        }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-12">
                      <h1 class="headingt" id="pageHeading">
                        UnCleaned Received Info
                      </h1>
                    </div>
                    <div
                      class="col-lg-6 col-sm-6"
                      v-if="loggedInUserGroupId == 'Admin'"
                      id="activeInactive"
                    >
                      <input
                        id="uncleanedActive"
                        type="radio"
                        :value="false"
                        @change="destroyTable"
                        v-model="inactive"
                      />
                      <label for="uncleanedActive" style="padding-right: 20px"
                        >Active</label
                      >
                      <input
                        id="uncleanedInActive"
                        type="radio"
                        :value="true"
                        @change="destroyTable"
                        v-model="inactive"
                      />
                      <label for="uncleanedInActive">InActive</label>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table
                    class="table table-head-fixed text-nowrap"
                    id="myTable"
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Utilisation ID</th>
                        <th>Request ID</th>
                        <th>Warrant Ref.</th>
                        <th>Collateral Quantity</th>
                        <th>Collateral Price</th>
                        <th>Collateral Value</th>
                        <th>Gross Weight</th>
                        <th>Unit</th>
                        <th>No. of Bags</th>
                        <th>Status</th>
                        <th
                          v-if="
                            !inactive &&
                            (loggedInUserGroupId === 'Admin' ||
                              loggedInUserGroupId == 'Analyst' ||
                              loggedInUserGroupId === 'Collateral Manager')
                          "
                          class="c"
                        >
                          Change Status
                        </th>
                        <th>GRN</th>
                        <th>Bean Count</th>
                        <th>Moisture Cont. %</th>
                        <th>Total Mould %</th>
                        <th>Insect Damaged %</th>
                        <th>Total Defect %</th>
                        <th>Sieving %</th>
                        <th>Double Cluster %</th>
                        <th>Triple Cluster %</th>
                        <th>Total Cluster</th>
                        <th>Broken Beans %</th>
                        <th>Fragment</th>
                        <th>Cocoa Rel Matter</th>
                        <th>Foreign Matter %</th>
                        <th>Flat Beans %</th>
                        <th>Slate %</th>
                        <th>Age Before Shipment</th>
                        <th>Age of Stock Bal</th>
                        <th class="c" style="width: 90px"></th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        id="tdrow"
                        v-for="un in uncleanedInfo"
                        :key="un.received_info_id"
                      >
                        <template
                          v-if="
                            loggedInUserGroupId == 'Borrower' ||
                            loggedInUserGroupId == 'Collateral Manager' ||
                            loggedInUserGroupId == 'Admin' ||
                            loggedInUserGroupId == 'Analyst'
                          "
                        >
                          <td v-if="un.received_date">
                            {{ un.received_date }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.loan_registration_id">
                            {{ un.loan_registration_id }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.request_id">{{ un.request_id }}</td>
                          <td v-else>-</td>
                          <td v-if="un.warrant_reference">
                            {{ un.warrant_reference }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.collateral_quantity">
                            {{ un.collateral_quantity }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.collateral_price">
                            {{ un.collateral_price }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.collateral_value">
                            {{ un.collateral_value }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.gross_weight">{{ un.gross_weight }}</td>
                          <td v-else>-</td>
                          <td v-if="un.unit_name">{{ un.unit_name }}</td>
                          <td v-else>-</td>
                          <td v-if="un.number_of_bags">
                            {{ un.number_of_bags }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.status">{{ un.status }}</td>
                          <td v-else>-</td>
                          <td
                            class="c status"
                            v-if="
                              !inactive &&
                              (loggedInUserGroupId == 'Collateral Manager' ||
                                loggedInUserGroupId == 'Analyst' ||
                                loggedInUserGroupId == 'Admin')
                            "
                          >
                            <div class="status">
                              <select
                                id="status"
                                name="status"
                                @change="
                                  onChangeStatus(un.received_info_id, $event)
                                "
                                @click="
                                  onChangeStatus(
                                    un.received_info_id,
                                    $event,
                                    'same'
                                  )
                                "
                                class="form-control"
                                :value="un.status"
                              >
                                <option
                                  v-if="un.status === 'Uncleaned'"
                                  value="Uncleaned"
                                >
                                  Uncleaned
                                </option>
                                <option value="Partially Taken for Cleaning">
                                  Partially Taken for Cleaning
                                </option>
                                <option value="Fully Taken for Cleaning">
                                  Fully Taken for Cleaning
                                </option>
                              </select>
                            </div>
                          </td>
                          <td v-if="un.grn">{{ un.grn }}</td>
                          <td v-else>-</td>
                          <td v-if="un.bean_count">{{ un.bean_count }}</td>
                          <td v-else>-</td>
                          <td v-if="un.moisture_content">
                            {{ un.moisture_content }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.total_mould">{{ un.total_mould }}</td>
                          <td v-else>-</td>
                          <td v-if="un.insect_damaged">
                            {{ un.insect_damaged }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.total_defect">{{ un.total_defect }}</td>
                          <td v-else>-</td>
                          <td v-if="un.sieving">{{ un.sieving }}</td>
                          <td v-else>-</td>
                          <td v-if="un.double_cluster">
                            {{ un.double_cluster }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.triple_cluster">
                            {{ un.triple_cluster }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.total_cluster">
                            {{ un.total_cluster }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.broken_beans">{{ un.broken_beans }}</td>
                          <td v-else>-</td>
                          <td v-if="un.fragment">{{ un.fragment }}</td>
                          <td v-else>-</td>
                          <td v-if="un.total_cocoa_related_matter">
                            {{ un.total_cocoa_related_matter }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.foreign_matter">
                            {{ un.foreign_matter }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.flat_beans">{{ un.flat_beans }}</td>
                          <td v-else>-</td>
                          <td v-if="un.slate">{{ un.slate }}</td>
                          <td v-else>-</td>
                          <td v-if="un.age_of_before_shipment">
                            {{ un.age_of_before_shipment }} {{ un.period }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="un.age_of_stock_balance">
                            {{ un.age_of_stock_balance }} {{ un.period }}
                          </td>
                          <td v-else>-</td>
                          <td
                            class="c"
                            v-on:click="
                              showDirDocumentModal(un.received_info_id)
                            "
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-file-pdf text-danger"></i>
                          </td>
                          <template
                            v-if="
                              !inactive &&
                              (loggedInUserGroupId === 'Admin' ||
                                loggedInUserGroupId === 'Collateral Manager')
                            "
                          >
                            <td
                              class="c"
                              v-if="
                                un.status !== 'Partially Taken for Cleaning' &&
                                (loggedInUserGroupId === 'Admin' ||
                                  loggedInUserGroupId === 'Collateral Manager')
                              "
                              @click="
                                deleteUncleaned(
                                  un.received_info_id,
                                  un.request_id,
                                  'ReceivedUncleaned',
                                  un.status
                                )
                              "
                              :style="{ cursor: 'pointer' }"
                            >
                              <i class="icofont-trash text-danger"></i>
                            </td>
                            <td v-else>
                              <i
                                style="opacity: 0.5"
                                class="icofont-trash text-danger"
                              ></i>
                            </td>
                          </template>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.Tabpane second end-->
      </div>
      <!-- Main content -->
      <!-- ./modal start -->
      <modal name="view_doc">
        <div class="modal-content" style="height: 500px">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-eye-alt"></i> View Document
            </h4>
            <button
              type="button"
              class="close"
              @click="$modal.hide('view_doc')"
            >
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table">
                    <tbody>
                      <tr v-if="!availableDocuments.length">
                        <center><h5>No Documents uploaded</h5></center>
                      </tr>
                      <tr v-for="doc in availableDocuments" :key="doc.id">
                        <template>
                          <td class="col-10">
                            {{ doc.document_name }}
                          </td>
                          <td
                            class="c col-1"
                            v-on:click="downloadDocument(doc.document_path)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-download text-info"></i>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <button
                type="button"
                class="btn-sm btn btn-dark sh"
                @click="$modal.hide('view_doc')"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </modal>
      <!-- ./modal end -->
    </div>
    <!-- /.content-wrapper -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
export default {
  data() {
    return {
      id: null,
      borrower_name: null,
      date: moment(new Date()).format("MM-DD-YYYY"),
      collateral: null,
      uncleanedInfo: [],
      uncleanedDocuments: [],
      inactive: false,
      availableDocuments: [],
      count: 0,
    };
  },

  components: {
    Datepicker,
  },

  methods: {
    showDirDocumentModal(docId) {
      this.availableDocuments = this.uncleanedDocuments.filter(
        (doc) => doc.received_info_id === docId
      );
      this.$modal.show("view_doc");
    },

    destroyTable() {
      // $("#myTable1").dataTable().fnDestroy();
      if (!this.inactive) {
        this.$store.commit("setinactive", "uncleaned_active");
        this.getUncleaned();
      } else {
        this.$store.commit("setinactive", "uncleaned_inactive");
        this.getUncleaned();
      }
    },
    deleteUncleaned(uncleanedId, requestId, filterType, status) {
      //this function will be called to delete a specific uncleaned info. Parameter required - uncleanedID: uncleanedid which needs to be deleted

      this.$confirm({
        auth: false,
        message: "Are you sure you want to permanently delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete(
                "received-info-uncleaned-overview/" +
                  this.id +
                  "/" +
                  this.collateral,
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                  data: {
                    filtertype: filterType,
                    updateType: "delete",
                    id: uncleanedId,
                    request_id: requestId,
                    status: status,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 6,
                    tabId: 21,
                  },
                }
              )
              .then((response) => {
                Utils.stop_loading();
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Uncleaned Info deleted successfully!"
                  );
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(
                    () =>
                      this.$router.go(
                        this.$router.go(this.$router.currentRoute)
                      ),
                    1500
                  );
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                console.log("Something went wrong!");
                Utils.make_alert("warning", "Something went wrong!");
              });
          }
        },
      });
    }, //deleteUncleaned()  close

    getUncleaned() {
      //this function is called on page load and created events. it will show details on Uncleaned Received Info
      const path =
        "received-info-uncleaned-overview/" + this.id + "/" + this.collateral;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.uncleanedInfo = this.inactive
              ? res.data.received_uncleaned_overview_inactive_list
              : res.data.received_uncleaned_overview_active_list;
            this.uncleanedDocuments = this.inactive
              ? res.data.received_documents_inactive_list
              : res.data.received_documents_active_list;
            if (this.loggedInUserGroupId === "Collateral Manager") {
              this.uncleanedInfo = this.uncleanedInfo.filter(
                (un) =>
                  un.collateral_manager_id.toString() ===
                  this.loggedInUserSubGroupId
              );
            }
            this.uncleanedInfo.sort((a, b) => {
              return new Date(a.received_date) - new Date(b.received_date);
            });
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getUncleaned close

    onChangeStatus(receivedInfoId, e, type) {
      if (type === "same") {
        if (e.target.value === "Partially Taken for Cleaning") {
          this.count += 1;
          if (this.count === 2)
            this.$router.push({
              name: "AddCleaningProcess",
              params: {
                id: receivedInfoId,
                status: e.target.value,
              },
            });
        }
      } else {
        this.$router.push({
          name: "AddCleaningProcess",
          params: {
            id: receivedInfoId,
            status: e.target.value,
          },
        });
      }
    }, //onChangeStatus close

    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.collateral = this.$route.params.collateral;
    this.borrower_name = this.$route.params.borrower_name;
    this.getUncleaned();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>
