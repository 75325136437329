<!-- This is fund page. It shows list of all the funds and their details -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6 col-sm-6">
                      <h1 class="headingt">
                        <i class="icofont-money-bag"></i> Funds
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div
                      class="col-lg-6 col-sm-6"
                      v-if="
                        loggedInUserGroupId == 'Admin' ||
                        (loggedInUserGroupId == 'Analyst' &&
                          loggedInUserSubGroupId == 0)
                      "
                    >
                      <ul id="sr_btn">
                        <!--<li><button  onclick="window.location.href='add_fund.html';" type="button" class="btn btn-sm sh_n btn-success"><i class="icofont-plus"></i><span class="mob">Add Fund</span></button></li>-->
                        <!-- <li v-if="loggedInUserGroupId == 'Admin'">
                          <input
                            type="checkbox"
                            id="checkbox"
                            v-model="inActiveToggle"
                            @change="destroyTable"
                          />
                          <label for="checkbox">InActive&nbsp;&nbsp;</label>
                        </li> -->
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-success"
                            @click="$router.push('/add-proportionate-expense')"
                          >
                            <i class="icofont-plus"></i>
                            <span class="mob">Add Proportionate Expense</span>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-success"
                            @click="$router.push('/add-fund')"
                          >
                            <i class="icofont-plus"></i>
                            <span class="mob">Add Fund</span>
                          </button>
                        </li>
                        <!-- <li>
                          <div class="form-group has-search m-0">
                            <span
                              class="fa fa-search form-control-feedback"
                            ></span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search"
                            />
                          </div>
                        </li>-->
                        <!--<li>
                          <div class="form-group has-search m-0">
                          <span class="fa fa-search form-control-feedback"></span>
                          <input type="text" class="form-control" placeholder="Search">
                          </div>
                        </li>-->
                      </ul>
                    </div>
                    <div
                      class="col-lg-6 col-sm-6"
                      v-if="loggedInUserGroupId == 'Admin'"
                    >
                      <input
                        type="radio"
                        :value="false"
                        @change="destroyTable"
                        v-model="inActiveToggle"
                        id="active"
                      />
                      <label for="active" style="padding-right: 20px"
                        >Active</label
                      >
                      <input
                        type="radio"
                        :value="true"
                        @change="destroyTable"
                        v-model="inActiveToggle"
                        id="inactive"
                      />
                      <label for="inactive">InActive</label>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div
                  class="card-body table-responsive p-0"
                  id="datatable-padding"
                >
                  <!------menton here style="height: 300px;"-------->
                  <table
                    class="table table-head-fixed text-nowrap table-hover myTable1"
                    id="myTable1"
                  >
                    <thead>
                      <tr>
                        <th>Fund Name</th>
                        <th class="right">Total AUM (000's)</th>
                        <!--<th>Returns (%)</th>-->
                        <th>NAV (000's)</th>
                        <th>As On Date</th>
                        <th>Currency</th>
                        <th v-if="!inActiveToggle">Total Investors</th>
                        <th v-if="!inActiveToggle">Total Borrowers</th>
                        <th v-if="!inActiveToggle" class="c center">Reports</th>
                        <th
                          class="c"
                          v-if="
                            !inActiveToggle &&
                            (loggedInUserGroupId == 'Analyst' ||
                              (loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0))
                          "
                        ></th>
                        <th
                          class="c"
                          v-if="
                            !inActiveToggle &&
                            loggedInUserGroupId == 'Admin' ||
                            (loggedInUserGroupId == 'Analyst' &&
                              loggedInUserSubGroupId == 0)
                          "
                        ></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="fund in funds" :key="fund.fund_id">
                        <td>
                          <router-link
                            class="ln"
                            active-link="active"
                            :to="{
                              name: 'FundOverview',
                              params: {
                                inActive: inActiveToggle,
                                id: fund.fund_id,
                              },
                            }"
                          >
                            <u>{{ fund.fund_name }}</u>
                          </router-link>
                        </td>
                        <td class="right" v-if="fund.total_aum !== null">
                          {{ fund.total_aum | numeral("0,0.00") }}
                        </td>
                        <td v-else>-</td>
                        <!--<td>{{fund.total_returns}}</td>-->
                        <td v-if="fund.nav !== null">
                          {{ fund.nav | numeral("0,0.00") }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="fund.max_date !== null">
                          {{ fund.max_date }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="fund.currency_short_name !== null">
                          {{ fund.currency_short_name }}
                        </td>
                        <td v-else>-</td>
                        <td
                          v-if="!inActiveToggle && fund.investor_count !== null"
                          class="c"
                        >
                          {{ fund.investor_count }}
                        </td>
                        <td
                          v-if="!inActiveToggle && fund.borrower_count !== null"
                          class="c"
                        >
                          {{ fund.borrower_count }}
                        </td>
                        <td
                          v-if="!inActiveToggle"
                          class="c center"
                          v-on:click="
                            showReportModal(
                              fund.fund_id,
                              fund.fund_inception_date
                            )
                          "
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-download text-primary"></i>
                        </td>
                        <template v-if="!inActiveToggle">
                          <td
                            v-if="
                              loggedInUserGroupId == 'Analyst' ||
                              loggedInUserGroupId == 'Admin'
                            "
                            class="c"
                          >
                            <router-link
                              style="text-decoration: none"
                              :to="{
                                name: 'EditFund',
                                params: {
                                  id: fund.fund_id,
                                  inActive: inActiveToggle,
                                },
                              }"
                            >
                              <i class="icofont-ui-edit text-warning"></i>
                            </router-link>
                          </td>
                          <td v-else>
                            <i
                              style="opacity: 0.5"
                              class="icofont-ui-edit text-warning"
                            ></i>
                          </td>
                          <td
                            class="c"
                            v-if="
                              loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0)
                            "
                            v-on:click="deleteFund(fund.fund_id)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
      <!-- /.content-->
    </div>
    <!-- /.content-wrapper -->

    <!-- ./report modal start -->
    <modal name="report">
      <div class="modal-content" style="height: 500px">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Download Report</h4>
          <button
            @click="$modal.hide('report')"
            type="button"
            class="close"
            data-dismiss="modal"
          >
            <i class="icofont-close-circled"></i>
          </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group fc">
                <label class="required-field">Select Report</label>
                <select
                  id="selectedReport"
                  class="form-control"
                  name="selectedReport"
                  v-model="selectedReport"
                  required
                >
                  <option :value="null">--- Select ---</option>
                  <option
                    v-for="report in reports"
                    :key="report.id"
                    :value="report.id"
                  >
                    {{ report.report_name }}
                  </option>
                </select>
              </div>
            </div>

            <div
              v-if="
                selectedReport === 1 ||
                selectedReport === 2 ||
                selectedReport === 4
              "
              class="col-md-6"
            >
              <div class="form-group fc">
                <label for="exampleInputEmail1" class="required-field"
                  >Period</label
                >
                <!--<datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="selectedPeriod"
                    placeholder="Select Period"
                    v-model="selectedPeriod"
                    minimum-view="month"
                    format="MM-yyyy"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :disabledDates="{to: new Date(2021, 6, 1), from: new Date(lastMonth)}"
                  ></datepicker>-->
                <datepicker
                  class="datepicker"
                  :bootstrap-styling="true"
                  name="selectedPeriod"
                  placeholder="Select Period"
                  v-model="selectedPeriod"
                  minimum-view="month"
                  format="MM-yyyy"
                  calendar-button-icon="fa fa-calendar"
                  :calendar-button="true"
                  :disabledDates="{
                    to: new Date(this.fundInceptionDate),
                    from: lastMonth,
                  }"
                ></datepicker>
              </div>
            </div>

            <div
              v-if="
                selectedReport !== 1 &&
                selectedReport !== 2 &&
                selectedReport !== 4
              "
              class="col-md-12"
            >
              <div class="form-group fc">
                <label class="required-field">Period</label>
                <select
                  id="selectedPeriod"
                  class="form-control"
                  name="selectedPeriod"
                  v-model="selectedPeriod"
                  required
                >
                  <option value="null">--- Select ---</option>
                  <option value="mtd">MTD</option>
                  <option value="ytd">YTD</option>
                  <option value="lastmonth">Last Month</option>
                  <option value="lastquarter">Last Quarter</option>
                  <option value="last2quarters">Last 2 Quarters</option>
                  <option value="lastfy1">Last 1 Financial Year</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer" style="margin-top: -15px">
          <div class="mx-auto">
            <button
              style="margin-right: 6px"
              type="button"
              class="btn-sm btn btn-success sh"
              v-on:click="onClickExcel('excel')"
            >
              <i class="icofont-file-excel"></i> Excel
            </button>
            <button
              type="button"
              style="margin-right: 6px"
              class="btn-sm btn btn-danger sh"
              v-on:click="onClickExcel('pdf')"
              :style="{ cursor: 'pointer' }"
            >
              <i class="icofont-file-pdf"></i> PDF
            </button>
            <button
              @click="$modal.hide('report')"
              style="margin-right: 6px"
              type="button"
              class="btn-sm btn btn-dark sh"
              data-dismiss="modal"
            >
              <i class="icofont-close-circled"></i> Close
            </button>
          </div>
        </div>
      </div>
    </modal>
    <!-- ./report modal end -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
// //Bootstrap and jQuery libraries
// import "jquery/dist/jquery.min.js";
// import $ from "jquery";
// //Datatable Modules
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import "datatables.net-buttons/js/dataTables.buttons.js";
// import "datatables.net-buttons/js/buttons.colVis.js";
// import "datatables.net-buttons/js/buttons.flash.js";
// import "datatables.net-buttons/js/buttons.html5.js";
// import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
// //Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
export default {
  data() {
    return {
      funds: [],
      fundsActive: [],
      fundsInActive: [],
      reports: [],
      selectedReport: null,
      selectedPeriod: null,
      fundId: null,
      fundInceptionDate: null,
      inActiveToggle: false,
      lastMonth: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1
      ),
    };
  },
  components: {
    Datepicker,
  },
  methods: {
    showReportModal(fundId, fundInceptionDate) {
      this.fundId = fundId;
      this.fundInceptionDate = fundInceptionDate;
      this.$modal.show("report");
    },
    getFunds() {
      Utils.start_loading();
      //this function will be called to get list of funds
      const path = "fund";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          //this will be when response is received from server
          Utils.stop_loading();
          console.log("res.data: ", res.data);
          if (this.inActiveToggle) {
            this.funds = res.data.funds_inactive;
          } else {
            this.funds = res.data.funds_active;
          }

          if(this.funds.length > 0) {
            this.funds.forEach(item => {
              if (item.fund_inception_date != '-' && item.fund_inception_date != null) {
                item.fund_inception_date = moment(item.fund_inception_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.fund_inception_date = '-';
              }
              if (item.date_y != '-' && item.date_y != null) {
                item.date_y = moment(item.date_y, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.date_y = '-';
              }
              if (item.max_date != '-' && item.max_date != null) {
                item.max_date = moment(item.max_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.max_date = '-';
              }
            });
          }

          setTimeout(
            () =>
              $("#myTable1").DataTable({
                aaSorting: [],
                columnDefs: this.inActiveToggle
                  ? [{ orderable: false, targets: [] }]
                  : [
                      {
                        orderable: false,
                        targets:
                        this.loggedInUserGroupId == 'Admin' ||
                        (this.loggedInUserGroupId == 'Analyst' &&
                          this.loggedInUserSubGroupId == 0)
                            ? [7, 8, 9]
                            : [7, 8, 9],
                      },
                    ],
              }),
            100
          );
          if (res.data.status == "success") {
            console.log("get funds is successful.");
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          //this is when response is not received from server
          Utils.stop_loading();
          console.error("error: " + error);
        });
    },
    destroyTable() {
      $("#myTable1").dataTable().fnDestroy();
      if (!this.inActiveToggle) {
        this.$store.commit("setinactive", "fund_active");
        this.$router.go();
      } else {
        this.$store.commit("setinactive", "fund_inactive");
        this.getFunds();
      }
    },
    getReports() {
      //this function is called to get list of fund reports
      const path = "fund-reports";
      axios
        .get(path)
        .then((res) => {
          this.reports = res.data.reports;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteFund(fundId) {
      let self = this;

      //this function will be called to delete a specific fund. Parameter required - fundID: fundid which needs to be deleted
      //if(confirm("Confirm delete?")){
      this.$confirm({
        auth: false,
        message: "Confirm Delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete("fund/" + fundId, {
                headers: {
                  "Content-type": "application/json",
                },
                data: {
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 1,
                  tabId: 1,
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert("success", "Fund deleted successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(
                    () => this.$router.go(self.$router.currentRoute),
                    1500
                  );
                } else if (response.data.status == "deletion_not_allowed") {
                  Utils.make_alert(
                    "warning",
                    "Delete operation not permitted."
                  );
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              });
          }
        },
      });
    }, //deleteFund()  close
    onClickExcel(fileType) {
      if (this.selectedPeriod === null || this.selectedReport === null) {
        Utils.make_alert("warning", "Please select report type and period.");
        return;
      }
      if (
        this.selectedReport === 1 ||
        this.selectedReport === 2 ||
        this.selectedReport === 4
      ) {
        this.selectedPeriod = moment(this.selectedPeriod).format("MM-DD-YYYY");
      }
      Utils.start_loading();
      const path =
        "/fund_reports/" +
        this.selectedPeriod +
        "/" +
        this.selectedReport +
        "/" +
        this.fundId +
        "/" +
        fileType;
      axios({
        url: path,
        params: { clientId: this.$store.state.client_id , userId: this.$store.getters.getuserId},
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          Utils.stop_loading();
          if (response.data.type == "application/json") {
            Utils.make_alert(
              "warning",
              "Something went wrong! Unable to download report."
            );
          } else {
            //give report type names as per selectedReport
            var reportName;
            var extension;
            if (this.selectedReport == 1) {
              reportName = "FundAUM";
            } else if (this.selectedReport == 2) {
              reportName = "FundNAV";
            } else if (this.selectedReport == 3) {
              reportName = "FundExpenses";
            } else if (this.selectedReport == 4) {
              reportName = "FundProfitandLoss";
            } else if (this.selectedReport == 5) {
              reportName = "FundCashLegder";
            }
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            // Filename seems to have to be changed unless otherwise provided
            // the extension can be automatically decided
            if (fileType === "excel") {
              extension = ".xlsx";
            } else if (fileType === "pdf") {
              extension = ".pdf";
            }
            link.setAttribute("download", reportName + extension);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.log("error.data: ", error.response.status);
          console.log("error: " + error);
          // if (error.response.status == 400) {
          //   Utils.make_alert(
          //     "warning",
          //     "No data available for the selected report period!"
          //   );
          // } else if (error.response.status == 406) {
          //   Utils.make_alert("warning", "No matching reports.");
          // } else {
          //   Utils.make_alert(
          //     "warning",
          //     "Something went wrong! Unable to download report."
          //   );
          // }
        });
    },
  },
  created() {
    axios.defaults.headers.common["Authorisation"] =
      "Bearer " + sessionStorage.getItem("token");
    this.inActiveToggle =
      this.$store.state.inactive === "fund_inactive" ? true : false;
    this.getFunds();
    this.getReports();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
      // return this.$store.getters.getuserGroup;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
      // return this.$store.getters.getuserSubGroup;
    },
  },
};
</script>

<style>
.required-field::after {
  content: "*";
  color: red;
}

th,
td {
  text-align: left;
}
.center {
  text-align: center;
}
.vm--modal {
  height: 500px !important;
}
/* .active {
   background-color: rgb(251, 18, 18);
   cursor: pointer;
 }
 .router-link-active,
 .router-link-exact-active{
  background: yellow;
  border-radius: 5px;
  color: red;
  font-variant: italic;
} */
/* a:link {
  color: red;
} */
</style>
