<!-- This is add Investor page. -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-plus"></i> Add Repayment
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Borrower Name</label
                      >
                      <select
                        id="borrower_id"
                        class="form-control"
                        name="borrower_id"
                        v-model="selectedBorrowerName"
                        :disabled="true"
                        required
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="borrower in borrowers"
                          :value="borrower.borrower_id"
                          :key="borrower.borrower_id"
                        >
                          {{ borrower.borrower_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!--<div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Utilisation ID</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Type..."
                      v-model="utilisation_id"
                    />
                  </div>
                  </div>-->

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Facility ID</label
                      >
                      <select
                        id="facility_registration_id"
                        class="form-control"
                        name="facility_registration_id"
                        v-model="facility_registration_id"
                        disabled
                        required
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="fac in FacilitySetup"
                          :value="fac.id"
                          :key="fac.id"
                        >
                          {{ fac.facility_registration_id }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Repayment Date</label
                      >
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="RepaymentDate"
                        placeholder="Select Date"
                        v-model="RepaymentDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{
                          to: new Date(approval_date),
                          from: new Date(),
                        }"
                        :class="{
                          'is-invalid': isSubmitted && $v.RepaymentDate.$error,
                        }"
                      ></datepicker>
                      <div
                        v-if="isSubmitted && $v.RepaymentDate.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.RepaymentDate.required"
                          >Repayment Date is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Repaid Amount</label
                      >
                      <input
                        type="number"
                        class="form-control"
                        placeholder
                        v-model="repaid_amount"
                        min="1"
                        @keypress="isDecimal($event)"
                        :class="{
                          'is-invalid': isSubmitted && $v.repaid_amount.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.repaid_amount.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.repaid_amount.required"
                          >Repaid Amount is required</span
                        >
                        <span v-if="!$v.repaid_amount.maxLength"
                          >Max length of 15 characters exceeded</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Currency</label>
                      <select
                        id="selectedCurrencyPayment"
                        class="form-control"
                        name="selectedCurrencyPayment"
                        v-model="selectedCurrencyPayment"
                        :disabled="true"
                      >
                        <option :value="null">-- Select a Currency --</option>
                        <option
                          v-for="curr in currencies"
                          :value="curr.id"
                          :key="curr.id"
                        >
                          {{ curr.currency_short_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documents</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addPaymentDoc()"
                              :disabled="PaymentDocumentsAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(doc, index) in PaymentDocuments"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label>Select Document</label>
                          <select
                            id="PaymentDocName"
                            class="form-control"
                            name="PaymentDocName"
                            v-model="doc.PaymentDocName"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="document in PaymentDocs"
                              :value="document.id"
                              :key="document.id"
                            >
                              {{ document.document_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Upload Document</label
                          >
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="file"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              v-on:change="
                                handleFileUploadPaymentDocuments(index, $event)
                              "
                            />
                            <small>
                              <p style="background-color: powderblue">
                                <b>{{ doc.displayFileName }}</b>
                                <small>
                                  <u
                                    style="color: blue; cursor: pointer"
                                    v-if="
                                      doc.PaymentDocumentRemoveDisabled ===
                                      false
                                    "
                                    v-on:click="
                                      removePaymentDocumentsDoc(index)
                                    "
                                    >Remove</u
                                  >
                                </small>
                              </p>
                            </small>
                            <small>
                              <p class="error" v-if="doc.errors.filesize">
                                {{ doc.errors.filesize }}
                              </p>
                            </small>
                            <label class="custom-file-label" for="customFile"
                              >Choose file</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Uploading Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="PaymentDocumentsDate"
                            placeholder="Select Date"
                            v-model="doc.PaymentDocumentsDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{
                              to: new Date(approval_date),
                              from: new Date(),
                            }"
                          ></datepicker>
                        </div>
                      </div>

                      <!-- <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Receipt no.</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Type..."
                          v-model="Receipt_no"
                        />
                      </div>
                      </div>-->

                      <div class="col-md-1 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            style="width: 250px"
                            v-on:click="deletePaymentDocuments(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onPaymentDocumentSaveInfo()"
                          :disabled="isDisabledPaymentDocumentSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-success sh"
                      v-on:click="onSubmit()"
                      :disabled="isDisabledOnSubmit"
                    >
                      Submit
                    </button>
                  </li>
                  <li>
                    <a @click="$router.go(-1)">
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>
    <!-- ./wrapper -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      //master tables
      id: null,
      loan_registration_id: null,
      //variables for Payment information
      selectedBorrowerName: null,
      borrowers: [],
      FacilitySetup: [],
      utilisation_id: null,
      facility_registration_id: null,
      currencies: [],
      RepaymentDate: null,
      RepaymentDate1: null,
      Payment_amount: null,
      repaid_amount: null,
      Receipt_no: null,
      selectedCurrencyPayment: null,
      approval_date: null,

      //list for Payment Documents
      PaymentDocuments: [],
      PaymentDocs: [],
      PaymentDocumentFile: null,
      // PaymentDocumentsButtonDisabled: true,
      PaymentDocumentsDisabled: true,
      PaymentDocumentSaveInfoDisabled: true, //save info button is disabled
      PaymentDocumentsAddMoreDisabled: true,
      isSubmitted: false,
    };
  },
  validations: {
    RepaymentDate: {
      required,
    },
    repaid_amount: {
      required,
      maxLength: maxLength(15),
    },
  },
  computed: {
    //this is to computed disabled property of main Submit button to save information in database.
    isDisabledOnSubmit: function () {
      if (
        this.selectedBorrowerName == null
        //  ||
        // this.PaymentDocumentSaveInfoDisabled === true
      ) {
        //here we will check for all sections
        return true;
      }
    },

    //this is to computed disabled property of PaymentDocuments Save Info button.
    isDisabledPaymentDocumentSaveInfo: function () {
      let checkEmptyPaymentDocumentsDocName = this.PaymentDocuments.filter(
        (line) => line.PaymentDocName === null
      );
      let checkEmptyPaymentDocumentsDate = this.PaymentDocuments.filter(
        (line) => line.PaymentDocumentsDate === null
      );
      //if the line is empty then disable save info button
      if (
        this.PaymentDocuments.length === 0 &&
        this.PaymentDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyPaymentDocumentsDocName.length >= 1 &&
          this.PaymentDocuments.length > 0) ||
        (checkEmptyPaymentDocumentsDate.length >= 1 &&
          this.PaymentDocuments.length > 0)
      ) {
        this.PaymentDocumentsDisabled = true;
      } else {
        this.PaymentDocumentsDisabled = false;
      }
      //PaymentDocumentsDisabled variable is to check whther all input fields except file upload are filled. PaymentDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.PaymentDocumentsDisabled === true ||
        this.PaymentDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledPaymentDocumentSaveInfo() close
  }, //main computed close

  components: {
    Datepicker,
  },
  methods: {
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },
    //this method will make disabled property as false for Director Save Info
    onPaymentDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.PaymentDocumentSaveInfoDisabled = false;
      this.PaymentDocumentsAddMoreDisabled = false;
    },

    deletePaymentDocuments(index) {
      this.PaymentDocumentFile = this.PaymentDocumentFile
        ? this.PaymentDocumentFile
        : "file deleted";
      this.PaymentDocuments.splice(index, 1);
      if (
        typeof this.PaymentDocuments !== "undefined" &&
        this.PaymentDocuments.length == 0
      ) {
        this.PaymentDocumentsAddMoreDisabled = false;
        this.PaymentDocumentSaveInfoDisabled = true;
      }
    },

    //getRepayment() {
    //  //this function is called on page load and created events. it will show details on borrower with borrowerid equal to this.id
    //  const path = "/add_repayment/" + this.payment_id;
    //  axios
    //    .get(path)
    //    .then(res => {
    //      console.log("status: " + res.data.status);
    //      this.selectedBorrowerName = res.data.repayment.borrower_name;
    //      this.utilisation_id = res.data.repayment.utilisation_id;
    //      this.facility_registration_id =
    //        res.data.repayment.facility_registration_id;

    //      if (res.data.status == "success") {
    //        console.log("get facility details is successful.");
    //      } else {
    //        Utils.make_alert("success", "Something went wrong from server!");
    //      }
    //    })
    //    .catch(error => {
    //      console.error("error: " + error);
    //      Utils.make_alert("success", "Something went wrong!");
    //    });
    //}, //getRepayment() close

    //this method will submit the investor form in backend.
    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.RepaymentDate != null) {
        this.RepaymentDate1 = moment(this.RepaymentDate).format("MM-DD-YYYY");
      }
      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("borrowerId", this.selectedBorrowerName);
      formData.append(
        "facility_registration_id",
        this.facility_registration_id
      );
      formData.append("RepaymentDate", this.RepaymentDate1);
      formData.append("loan_registration_id", this.loan_registration_id);
      formData.append("selectedCurrencyPayment", this.selectedCurrencyPayment);
      formData.append("repaid_amount", this.repaid_amount);

      //append PaymentDocuments information to formdata
      formData.append(
        "repaymentDocumentList",
        JSON.stringify(this.PaymentDocuments)
      );
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 5);
      formData.append("tabId", 18);

      const path = "/add_repayment"; // /" + this.loan_registration_id;
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          console.log("status: " + response.data.status);
          if (response.data.status == "success") {
            Utils.make_alert("success", "Payment Request added successfully!");
            console.log("SUCCESS!!");
            // this.$router.push({ name: "LoanOverview" });
            this.$router.go(-1);
          } else {
            Utils.make_alert(
              "success",
              "FAILURE!!! Something went wrong from server!"
            );
            console.log("Something went wrong from server!");
            console.log("FAILURE!!");
          }
        })
        .catch(function () {
          Utils.stop_loading();
          console.log("FAILURE!!");
        });
    }, //onSubmit() close

    //this method will add new line for additional Payment Documents
    addPaymentDoc() {
      //when new line is added then PaymentDocumentFile variable and PaymentDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.PaymentDocumentFile = null),
        (this.PaymentDocumentSaveInfoDisabled = true),
        (this.PaymentDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.PaymentDocuments.push({
          PaymentDocName: null,
          PaymentDocumentsDate: null,
          ExpiryDate: null,
          comments: null,
          displayFileName: null,
          errors: { filesize: "" },
          PaymentDocumentRemoveDisabled: true,
        });
    }, //addPaymentDoc() close

    //handles a change on the file upload
    handleFileUploadPaymentDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.PaymentDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.PaymentDocumentFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors.filesize = "File size should be less than 100MB";
          self.PaymentDocuments[id].errors.filesize = JSON.stringify(
            response.errors.filesize
          ).replace(/\"/g, "");
          self.PaymentDocuments[id].selectedFile = null;
          self.PaymentDocuments[id].selectedFileName = null;
          self.PaymentDocuments[id].displayFileName = null;
          self.PaymentDocuments[id].PaymentDocumentRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.PaymentDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.PaymentDocuments[id].selectedFile = reader.result;
          self.PaymentDocuments[id].selectedFileName = e.target.files[0].name;
          self.PaymentDocuments[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.PaymentDocuments[id].errors.filesize = "";
          self.PaymentDocuments[id].PaymentDocumentRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadPaymentDocuments() close

    ////this method handles remove doc attachment
    removePaymentDocumentsDoc(id) {
      this.PaymentDocumentFile = null;
      this.PaymentDocuments[id].selectedFile = null;
      this.PaymentDocuments[id].selectedFileName = null;
      this.PaymentDocuments[id].displayFileName = null;
      this.PaymentDocuments[id].PaymentDocumentRemoveDisabled = true;
    },

    //this function is called to get list of documents master
    getPaymentDocuments() {
      const path = "repayment-document";
      axios
        .get(path)
        .then((res) => {
          this.PaymentDocs = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getPaymentDocuments() close

    getBorrowers() {
      const path = "borrower-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.borrowers = res.data.borrowers;
          console.log(this.borrowers);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
          this.currencies_payment = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFacilitySetupByBorrower() {
      //this function is called to get list of day count
      const path = "/facility-setup-borrower/" + this.selectedBorrowerName;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.FacilitySetup = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close

  mounted() {
    this.addPaymentDoc();
  },

  created() {
    this.loan_registration_id = this.$route.params.loan_registration_id;
    this.selectedBorrowerName = this.$route.params.borrower_id;
    this.facility_registration_id = this.$route.params.facility_id;
    this.selectedCurrencyPayment = this.$route.params.fund_currency_id;
    this.approval_date = moment
      .utc(this.$route.params.approval_date, "MM-DD-YYYY")
      .toDate();
    //this.getRepayment();
    this.getBorrowers();
    this.getPaymentDocuments();
    this.getCurrencies();
    this.getFacilitySetupByBorrower();
  },
};
</script>

<style scoped>
/* add some style for dropdown working in Chrome */
/* .cell.day.selected {
  background: $blue !important;
}
.cell.day-header,
.day__month_btn.up {
  font-weight: bold !important;
}
.vdp-datepicker input[type="text"]:read-only {
  width: 200px;
  cursor: pointer;
} */
.vdp-datepicker__calendar {
  width: 130% !important;
  font-size: x-small;
}
.error {
  color: red;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>
