var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('nav-bar'),_c('aside-bar'),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid tabtop pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-6"},[_c('ul',{attrs:{"id":"bck_btn"}},[_c('li',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"icofont-caret-left text-dark"}),_c('span',[_vm._v("Back")])])])])])])])])])])]),_c('div',{staticClass:"container-fluid ot_tb pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_vm._m(1),_c('div',{staticClass:"card-body table-responsive p-0"},[_c('table',{staticClass:"table table-head-fixed text-nowrap myTable1",attrs:{"id":"myTable1"}},[_c('thead',[_c('tr',[_vm._m(2),_vm._m(3),_vm._m(4),_c('th',{staticClass:"c"},[_vm._v("Uploaded File")]),(
                          (_vm.loggedInUserGroupId == 'Admin' ||
                                  (_vm.loggedInUserGroupId == 'Analyst' &&
                                    _vm.loggedInUserSubGroupId == 0))  &&
                          !_vm.inactive
                        )?_c('th'):_vm._e()])]),_c('tbody',_vm._l((_vm.CollateralDocuments),function(doc){return _c('tr',{key:doc.collateral_document_id},[(doc.CollateralDocName !== null)?_c('td',[_vm._v(" "+_vm._s(doc.CollateralDocName)+" ")]):_c('td',[_vm._v("-")]),(doc.CollateralDocumentsDate !== null)?_c('td',[_vm._v(" "+_vm._s(doc.CollateralDocumentsDate)+" ")]):_c('td',[_vm._v("-")]),(doc.ExpiryDate !== null)?_c('td',{},[_vm._v(" "+_vm._s(doc.ExpiryDate)+" ")]):_c('td',{},[_vm._v("-")]),_c('td',{staticClass:"c",style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.downloadDocument(doc.document_path)}}},[_c('u',[_vm._v(_vm._s(doc.display_file_name))])]),(
                          (_vm.loggedInUserGroupId == 'Admin' ||
                                  (_vm.loggedInUserGroupId == 'Analyst' &&
                                    _vm.loggedInUserSubGroupId == 0))  &&
                          !_vm.inactive
                        )?_c('td',{style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.deleteCollateralDoc(doc.collateral_document_id)}}},[_c('i',{staticClass:"icofont-trash text-danger"})]):_vm._e()])}),0)])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"headingt"},[_c('i',{staticClass:"icofont-file-document"}),_vm._v(" Collateral Document History ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{attrs:{"onclick":"sortTable('myTable1',  0)"}},[_vm._v(" Document Name"),_c('i',{staticClass:"icofont-sort sortdd"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{attrs:{"onclick":"sortTable('myTable1',  1)"}},[_vm._v(" Issue Date"),_c('i',{staticClass:"icofont-sort sortdd"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{attrs:{"onclick":"sortTable('myTable1',  2)"}},[_vm._v(" Expiry Date"),_c('i',{staticClass:"icofont-sort sortdd"})])}]

export { render, staticRenderFns }