<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-12">
                      <h1 class="headingt">
                        Action Summary:
                        <span class="t_dt">{{ today }}</span>
                      </h1>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid" id="new_tab_lundy">
            <!-- <div class="container-fluid" id="select-action"> -->
            <div class="row">
              <div class="col-lg-12 col-md-12 col-xs-12">
                <ul class="nav nav-tabs my_tabs">
                  <li class="nav-item">
                    <a
                      href="#NewEvents"
                      class="nav-link active color_2"
                      data-toggle="tab"
                    >
                      New Events
                      <span class="count-cls-two">{{ new_events_count }}</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="#UpcomingEvents"
                      class="nav-link color_6"
                      data-toggle="tab"
                    >
                      Upcoming Events
                      <span class="count-cls-six">{{
                        upcoming_events_count
                      }}</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="#Upcoming"
                      class="nav-link color_3"
                      data-toggle="tab"
                    >
                      Upcoming Payments
                      <span class="count-cls-three">{{ upcoming_count }}</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="#ReceivedPayments"
                      class="nav-link color_5"
                      data-toggle="tab"
                    >
                      Received Payments
                      <span class="count-cls-five">{{
                        received_payment_count
                      }}</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="#ExpiryDocument"
                      class="nav-link color_1"
                      data-toggle="tab"
                    >
                      Documents Expiry
                      <span class="count-cls">{{ expiry_docs_count }}</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="#OverduePayments"
                      class="nav-link color_4"
                      data-toggle="tab"
                    >
                      Overdue payments
                      <span class="count-cls-four">{{ overdue_count }}</span>
                    </a>
                  </li>
                </ul>
                <div class="tab-content top_margin">
                  <div
                    class="tab-pane active tab-pane-custom-two"
                    id="NewEvents"
                  >
                    <div v-for="fund in fundList" :key="fund.fund_id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Fund</p>
                          <p>{{ fund.fund_name }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <!--<a href="javascript:void(0);" class="text_btn_two">View</a>-->
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'FundOverview',
                              params: {
                                id: fund.fund_id,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ fund.fund_inception_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-for="borr in borrowerList" :key="borr.borrower_id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Borrower</p>
                          <p>{{ borr.borrower_name }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <!--<a href="javascript:void(0);" class="text_btn_two">View</a>-->
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'BorrowerOverview',
                              params: {
                                id: borr.borrower_id,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ borr.registration_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(inv, index) in investorList"
                      :key="inv.investor_id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Investor</p>
                          <p>{{ inv.investor_name }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <!--<a href="javascript:void(0);" class="text_btn_two">View</a>-->
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'InvestorOverview',
                              params: {
                                id: inv.investor_id,
                                index: index,
                                activeTab: '#one',
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ inv.registration_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-for="fac in facilityList" :key="fac.facility_id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Facility</p>
                          <p>Borrower Name: - {{ fac.borrower_name }}</p>
                          <p>{{ fac.facility_registration_id }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <!--<a href="javascript:void(0);" class="text_btn_two">View</a>-->
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'FacilityOverview',
                              params: {
                                id: fac.facility_id,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ fac.facility_request_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="loan in loanList"
                      :key="loan.loan_registration_id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Loan</p>
                          <p>Borrower Name: - {{ loan.borrower_name }}</p>
                          <p>
                            {{ loan.loan_registration_id }}: -
                            {{ loan.loan_amount | numeral("0,0.00") }}
                            {{ loan.currency_short_name }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <!--<a href="javascript:void(0);" class="text_btn_two">View</a>-->
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'LoanUtilisationRequestOverview',
                              params: {
                                borrower_id: loan.borrower_id,
                                id: loan.loan_master_id,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ loan.loan_registration_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-for="draw in drawdownList" :key="draw.draw_id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">{{ draw.borrower_name }}</p>
                          <p>
                            Loan {{ draw.loan_registration_id }} - Drawdown
                            Request: -
                            {{ draw.disbursement_amount | numeral("0,0.00") }}
                            {{ draw.currency_short_name }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <!--<a href="javascript:void(0);" class="text_btn_two">View</a>-->
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'LoanUtilisationRequestOverview',
                              params: {
                                borrower_id: draw.borrower_id,
                                id: draw.loan_master_id,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ draw.disbursement_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(inv, index) in investmentList"
                      :key="inv.inv_tran_id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Investment</p>
                          <p>Investor Name: - {{ inv.investor_name }}</p>
                          <p>
                            {{ inv.principal_amount | numeral("0,0.00") }}
                            {{ inv.currency_short_name }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <!--<a href="javascript:void(0);" class="text_btn_two">View</a>-->
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'InvestorOverview',
                              params: {
                                id: inv.investor_id,
                                index: index,
                                activeTab: '#two',
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ inv.investment_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-for="col in collateralList" :key="col.id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Collateral Manager</p>
                          <p>
                            Collateral Manager Company: {{ col.company_name }}
                          </p>
                          <p>Collateral Manager ID: {{ col.collateral_id }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'CollateralManagerOverview',
                              params: {
                                id: col.id,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ col.registration_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-for="war in warehouseList" :key="war.id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Warehouse</p>
                          <p>Borrower Name: {{ war.borrower_name }}</p>
                          <p>Warehouse ID: {{ war.warehouse_id }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'WarehouseOverview',
                              params: {
                                id: war.id,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ war.registration_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-for="ver in verificationList" :key="ver.id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Verification Request</p>
                          <p>Borrower Name: {{ ver.borrower_name }}</p>
                          <p>Facility ID: {{ ver.facility_registration_id }}</p>
                          <p>{{ ver.request_id }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'AddRequest',
                              params: {
                                id: ver.id,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ ver.request_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-for="rel in releaseList" :key="rel.id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Release Request</p>
                          <p>Borrower Name: {{ rel.borrower_name }}</p>
                          <p>Facility ID: {{ rel.facility_registration_id }}</p>
                          <p>{{ rel.request_id }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'ReleaseRequest',
                              params: {
                                id: rel.id,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ rel.request_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-for="release in releaseInfoList" :key="release.id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Logistics Info</p>
                          <p>Borrower Name: {{ release.borrower_name }}</p>
                          <p>{{ release.release_id }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'Logistics',
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ release.date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="received in receivedInfoList"
                      :key="received.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Received Info</p>
                          <p>Borrower Name: {{ received.borrower_name }}</p>
                          <p>
                            Utilisation ID: {{ received.loan_registration_id }}
                          </p>
                          <p>{{ received.request_id }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'UncleanedInventoryOverview',
                              params: {
                                id: received.borrower_id,
                                collateral: received.collateral_name,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ received.received_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="received in offtakerContractList"
                      :key="received.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Contract Info</p>
                          <p>Offtaker Name: {{ received.offtaker_name }}</p>
                          <p>Borrower Name: {{ received.borrower_name }}</p>
                          <p>Contract No.: {{ received.contract_number }}</p>
                          <p>{{ received.request_id }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'ContractOverview',
                              params: {
                                id: received.id,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ received.contract_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-for="cp in cleaningProcessList" :key="cp.id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Cleaning Process</p>
                          <p>Borrower Name: {{ cp.borrower_name }}</p>
                          <p>Utilisation ID: {{ cp.loan_registration_id }}</p>
                          <p>{{ cp.request_id }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'Collateral',
                              params: {
                                underProcess: true,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ cp.received_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-for="ship in shippingList" :key="ship.id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Shipping Info</p>
                          <p>Borrower Name: {{ ship.borrower_name }}</p>
                          <p>{{ ship.shipping_registration_id }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'ShippingOverview',
                              params: {
                                id: ship.id,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ ship.port_acceptance_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-for="off in offtakerList" :key="off.offtaker_id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Offtaker</p>
                          <p>{{ off.offtaker_name }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'OfftakerOverview',
                              params: {
                                id: off.offtaker_id,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ off.registration_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade tab-pane-custom-six"
                    id="UpcomingEvents"
                  >
                    <div
                      v-for="insp in site_inspection_current_list"
                      :key="insp.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">Site Inspection</p>
                          <p>Borrower Name: {{ insp.borrower_name }}</p>
                          <p>
                            Warehouse ID: {{ insp.warehouse_registration_id }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'WarehouseOverview',
                              params: {
                                id: insp.warehouse_id,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>

                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ insp.site_inspection_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade tab-pane-custom-three"
                    id="Upcoming"
                  >
                    <div v-for="(fa, index) in fund_expense_list" :key="fa.id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">{{ fa.fund_name }}</p>
                          <p>
                            {{ fa.expense_name }}: -
                            {{ fa.expenses | numeral("0,0.00") }}
                            {{ fa.currency_short_name }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            v-if="loggedInUserGroupId === 'Admin'"
                            class="text_btn_three"
                            :to="{
                              name: 'FundOverview',
                              params: {
                                id: fa.fund_id,
                                type: 'fund_expense',
                                index: index,
                              },
                            }"
                          >
                            <u>Pay</u>
                          </router-link>

                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ fa.instalment_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(
                        int, index
                      ) in investor_transaction_scheduled_list"
                      :key="int.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">{{ int.investor_name }}</p>
                          <p>
                            Payment: - {{ int.amount | numeral("0,0.00") }}
                            {{ int.currency_short_name }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            v-if="loggedInUserGroupId === 'Admin'"
                            class="text_btn_three"
                            :to="{
                              name: 'InvestorOverview',
                              params: {
                                id: int.investor_id,
                                type: 'investor_transaction_scheduled',
                                index: index,
                                activeTab: '#three',
                                amount: int.amount,
                                currency: int.currency_short_name,
                              },
                            }"
                          >
                            <u>Pay</u>
                          </router-link>

                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ int.date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(int, index) in interest_df_seven_days_list"
                      :key="int.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">{{ int.investor_name }}</p>
                          <p>
                            Interest payment: -
                            {{ int.amount | numeral("0,0.00") }}
                            {{ int.currency_short_name }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            v-if="loggedInUserGroupId === 'Admin'"
                            class="text_btn_three"
                            :to="{
                              name: 'InvestorOverview',
                              params: {
                                id: int.investor_id,
                                type: 'interest_seven_days',
                                index: index,
                                activeTab: '#three',
                                amount: int.amount,
                                currency: int.currency_short_name,
                              },
                            }"
                          >
                            <u>Pay</u>
                          </router-link>

                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ int.date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(int, index) in maturity_df_seven_days_list"
                      :key="int.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">{{ int.investor_name }}</p>
                          <p>
                            Maturity amount: -
                            {{ int.amount | numeral("0,0.00") }}
                            {{ int.currency_short_name }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            v-if="loggedInUserGroupId === 'Admin'"
                            class="text_btn_three"
                            :to="{
                              name: 'InvestorOverview',
                              params: {
                                id: int.investor_id,
                                type: 'maturity_seven_days',
                                index: index,
                                activeTab: '#three',
                                amount: int.amount,
                                currency: int.currency_short_name,
                              },
                            }"
                          >
                            <u>Pay</u>
                          </router-link>

                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ int.date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      v-for="(int, index) in profit_return_list"
                      :key="int.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">{{ int.borrower_name }}</p>
                          <p>
                            {{ int.loan_registration_id }} Profit return: -
                            {{ int.payment_amount | numeral("0,0.00") }}
                            {{ int.currency_short_name }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'LoanUtilisationRequestOverview',
                              params: {
                                borrower_id: int.borrower_id,
                                id: int.loan_registration_id,
                                type: 'profit_return',
                                index: index,
                              },
                            }"
                          >
                            <u>Pay</u>
                          </router-link>

                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ int.payment_date }}
                          </p>
                        </div>
                      </div>
                    </div>-->
                  </div>
                  <div
                    class="tab-pane fade tab-pane-custom-five"
                    id="ReceivedPayments"
                  >
                    <div v-for="pay in paymentsReceived" :key="pay.id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">{{ pay.borrower_name }}</p>
                          <p>
                            {{ pay.loan_registration_id }}: -
                            {{ pay.repaid_amount | numeral("0,0.00") }}
                            {{ pay.currency_short_name }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn_five"
                            :to="{
                              name: 'LoanUtilisationRequestOverview',
                              params: {
                                borrower_id: pay.borrower_id,
                                id: pay.loan_master_id,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <!-- <a href="javascript:void(0);" class="text_btn_five">View</a> -->
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ pay.repayment_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade show tab-pane-custom"
                    id="ExpiryDocument"
                  >
                    <div
                      v-for="(borr, index) in borrowerExpiryDocs"
                      :key="borr.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">
                            Borrower:- {{ borr.borrower_name }}
                          </p>
                          <p>{{ borr.document_name }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn"
                            :to="{
                              name: 'BorrowerOverview',
                              params: { id: borr.borrower_id },
                              index: index,
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <!-- <a href="javascript:void(0);" class="text_btn">View</a> -->
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ borr.expiry_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(borr, index) in borrowerDirectorExpiryDocs"
                      :key="borr.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">
                            Borrower Director:- {{ borr.director_name }}
                          </p>
                          <p>{{ borr.document_name }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn"
                            :to="{
                              name: 'BorrowerOverview',
                              params: { id: borr.borrower_id },
                              index: index,
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ borr.expiry_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(fac, index) in facilityExpiryDocs"
                      :key="fac.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">
                            Facility:- {{ fac.facility_registration_id }}
                          </p>
                          <p>{{ fac.document_name }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn"
                            :to="{
                              name: 'FacilityOverview',
                              params: { id: fac.facility_id },
                              index: index,
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <!-- <a href="javascript:void(0);" class="text_btn">View</a> -->
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ fac.expiry_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-for="(loan, index) in loanExpiryDocs" :key="loan.id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">
                            Loan:- {{ loan.loan_registration_id }}
                          </p>
                          <p>{{ loan.document_name }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn"
                            :to="{
                              name: 'LoanUtilisationRequestOverview',
                              params: {
                                borrower_id: loan.borrower_id,
                                id: loan.loan_master_id,
                                index: index,
                              },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <!-- <a href="javascript:void(0);" class="text_btn">View</a> -->
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ loan.expiry_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(inv, index) in investorExpiryDocs"
                      :key="inv.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">
                            Investor:- {{ inv.investor_name }}
                          </p>
                          <p>{{ inv.document_name }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn"
                            :to="{
                              name: 'InvestorOverview',
                              params: { id: inv.investor_id },
                              index: index,
                              activeTab: '#one',
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <!-- <a href="javascript:void(0);" class="text_btn">View</a> -->
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ inv.expiry_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(col, index) in collateralExpiryDocs"
                      :key="col.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">
                            Collateral Company Name:- {{ col.company_name }}
                          </p>
                          <p>
                            Collateral Manager Document: {{ col.document_name }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn"
                            :to="{
                              name: 'CollateralManagerOverview',
                              params: { id: col.collateral_manager_id },
                              index: index,
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <!-- <a href="javascript:void(0);" class="text_btn">View</a> -->
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ col.expiry_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <template v-if="loggedInUserGroupId === 'Admin'">
                      <div
                        v-for="(col, index) in collateralExpiryAdminDocs"
                        :key="col.id"
                      >
                        <div class="bottomBorder row">
                          <div class="col-lg-10">
                            <p class="body_title">
                              Collateral Company Name:- {{ col.company_name }}
                            </p>
                            <p>
                              Collateral Administrative Document:
                              {{ col.document_name }}
                            </p>
                          </div>
                          <div class="col-lg-2 text-lg-right">
                            <router-link
                              class="text_btn"
                              :to="{
                                name: 'CollateralManagerOverview',
                                params: { id: col.collateral_manager_id },
                                index: index,
                              }"
                            >
                              <u>View</u>
                            </router-link>
                            <p class="datecls">
                              <i class="far fa-calendar"></i>
                              {{ col.expiry_date }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div
                      v-for="(war, index) in warehouseExpiryDocs"
                      :key="war.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">
                            Warehouse:- {{ war.warehouse_registration_id }}
                          </p>
                          <p>Borrower Name: {{ war.borrower_name }}</p>
                          <p>Warehouse Document: {{ war.document_name }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn"
                            :to="{
                              name: 'WarehouseOverview',
                              params: { id: war.id },
                              index: index,
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ war.expiry_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <template v-if="loggedInUserGroupId === 'Admin'">
                      <div
                        v-for="(war, index) in warehouseExpiryAdminDocs"
                        :key="war.id"
                      >
                        <div class="bottomBorder row">
                          <div class="col-lg-10">
                            <p class="body_title">
                              Warehouse:- {{ war.warehouse_registration_id }}
                            </p>
                            <p>Borrower Name: {{ war.borrower_name }}</p>
                            <p>
                              Warehouse Administrative Document:
                              {{ war.document_name }}
                            </p>
                          </div>
                          <div class="col-lg-2 text-lg-right">
                            <router-link
                              class="text_btn"
                              :to="{
                                name: 'WarehouseOverview',
                                params: { id: war.id },
                                index: index,
                              }"
                            >
                              <u>View</u>
                            </router-link>
                            <p class="datecls">
                              <i class="far fa-calendar"></i>
                              {{ war.expiry_date }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div v-for="off in offtakerExpiryDocs" :key="off.id">
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">
                            Offtaker Name:- {{ off.offtaker_name }}
                          </p>
                          <p>{{ off.document_name }}</p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn"
                            :to="{
                              name: 'OfftakerOverview',
                              params: { id: off.offtaker_master_id },
                            }"
                          >
                            <u>View</u>
                          </router-link>
                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ off.expiry_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade tab-pane-custom-four"
                    id="OverduePayments"
                  >
                    <div
                      v-for="(fa, index) in fund_expense_before_current_list"
                      :key="fa.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">{{ fa.fund_name }}</p>
                          <p>
                            {{ fa.expense_name }}: -
                            {{ fa.expenses | numeral("0,0.00") }}
                            {{ fa.currency_short_name }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            v-if="loggedInUserGroupId === 'Admin'"
                            class="text_btn_three"
                            :to="{
                              name: 'FundOverview',
                              params: {
                                id: fa.fund_id,
                                type: 'fund_expense_before_current',
                                index: index,
                              },
                            }"
                          >
                            <u>Pay</u>
                          </router-link>

                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ fa.instalment_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(
                        int, index
                      ) in interest_df_before_current_days_list"
                      :key="int.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">{{ int.investor_name }}</p>
                          <p>
                            Interest payment: -
                            {{ int.amount | numeral("0,0.00") }}
                            {{ int.currency_short_name }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            v-if="loggedInUserGroupId === 'Admin'"
                            class="text_btn_three"
                            :to="{
                              name: 'InvestorOverview',
                              params: {
                                id: int.investor_id,
                                type: 'interest_before_current_days',
                                index: index,
                                amount: int.amount,
                                currency: int.currency_short_name,
                              },
                            }"
                          >
                            <u>Pay</u>
                          </router-link>

                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ int.date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(
                        int, index
                      ) in maturity_df_before_current_days_list"
                      :key="int.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">{{ int.investor_name }}</p>
                          <p>
                            Maturity payment: -
                            {{ int.amount | numeral("0,0.00") }}
                            {{ int.currency_short_name }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            v-if="loggedInUserGroupId === 'Admin'"
                            class="text_btn_three"
                            :to="{
                              name: 'InvestorOverview',
                              params: {
                                id: int.investor_id,
                                type: 'maturity_before_current_days',
                                index: index,
                                amount: int.amount,
                                currency: int.currency_short_name,
                              },
                            }"
                          >
                            <u>Pay</u>
                          </router-link>

                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ int.date }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      v-for="(int, index) in profit_return_before_current_list"
                      :key="int.id"
                    >
                      <div class="bottomBorder row">
                        <div class="col-lg-10">
                          <p class="body_title">{{ int.borrower_name }}</p>
                          <p>
                            {{ int.loan_registration_id }} Profit return
                            payment: -
                            {{ int.payment_amount | numeral("0,0.00") }}
                            {{ int.currency_short_name }}
                          </p>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                          <router-link
                            class="text_btn_three"
                            :to="{
                              name: 'LoanUtilisationRequestOverview',
                              params: {
                                borrower_id: int.borrower_id,
                                id: int.loan_registration_id,
                                type: 'profit_return_before_current',
                                index: index,
                              },
                            }"
                          >
                            <u>Pay</u>
                          </router-link>

                          <p class="datecls">
                            <i class="far fa-calendar"></i>
                            {{ int.payment_date }}
                          </p>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <!-- <select
                  class="ui dropdown"
                  name="action"
                  v-model="selectedOption"
                  @change="setActionTab()"
                >
                  <option :value="null">-- Select an Option --</option>
                  <option
                    v-for="option in options"
                    :key="option.id"
                    v-bind:value="option.id"
                  >{{ option.name }}</option>
                </select>
              </div>-->
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12" v-if="selectedOption === 1">
              <div class="new_mid br_1">
                <h3>New Events</h3>
                <ul v-for="fund in fundList">
                  <li style="font-size: 15px">
                    <b>{{ fund.fund_name }}</b>
                    has been successfully registered
                    on {{ fund.fund_inception_date }}
                  </li>
                </ul>
                <ul v-for="borr in borrowerList">
                  <li style="font-size: 15px">
                    Borrower
                    <b>{{ borr.borrower_name }}</b>
                    has been
                    successfully registered on {{ borr.registration_date }}
                  </li>
                </ul>
                <ul v-for="inv in investorList">
                  <li style="font-size: 15px">
                    Investor
                    <b>{{ inv.investor_name }}</b>
                    has been
                    successfully registered on {{ inv.registration_date }}
                  </li>
                </ul>
                <ul v-for="fac in facilityList">
                  <li style="font-size: 15px">
                    Borrower
                    <b>{{ fac.borrower_name }}</b> has successfully
                    registered a new facility having ID as
                    <b>{{ fac.facility_registration_id }}</b>
                    on
                    {{ fac.facility_request_date }}
                  </li>
                </ul>
                <ul v-for="loan in loanList">
                  <li style="font-size: 15px">
                    Borrower
                    <b>{{ loan.borrower_name }}</b> have added a new
                    utilisation request
                    <b>{{ loan.loan_registration_id }}</b>
                    of
                    {{ loan.loan_amount }} {{ loan.currency_short_name }} on
                    {{ loan.loan_registration_date }}
                  </li>
                </ul>
                <ul v-for="draw in drawdownList">
                  <li style="font-size: 15px">
                    Borrower
                    <b>{{ draw.borrower_name }}</b>
                    has made a drawdown
                    request of {{ draw.disbursement_amount }}
                    {{ draw.currency_short_name }} on
                    {{ draw.disbursement_date }}
                  </li>
                </ul>
                <ul v-for="inv in investmentList">
                  <li style="font-size: 15px">
                    Investor
                    <b>{{ inv.investor_name }}</b>
                    have made an
                    investment of {{ inv.principal_amount }}
                    {{ inv.currency_short_name }} on {{ inv.investment_date }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-sm-12" v-if="selectedOption === 2">
              <div class="new_mid br_1">
                <h3>Upcoming Payments</h3>
                <ul v-for="(fa, index) in fund_amortised_list" :key="index">
                  <li style="font-size: 15px" class="bxx">
                    {{ fa.expense_name }} of {{ fa.instalment_amount }}
                    {{ fa.currency_short_name }} is due for
                    <b>
                      <router-link
                        :to="{
                          name: 'ActionFundOverview',
                          params: {
                            id: fa.fund_id,
                            type: 'fund_amortised',
                            index: index,
                          },
                        }"
                      >
                        <u>{{ fa.fund_name }}</u>
                      </router-link>
                    </b>
                    on {{ fa.instalment_date }}
                  </li>
                </ul>
                <ul v-for="(fa, index) in fund_onetime_list">
                  <li style="font-size: 15px" class="bxx">
                    {{ fa.expense_name }} of {{ fa.total_amount }}
                    {{ fa.currency_short_name }} is due for
                    <b>
                      <router-link
                        :to="{
                          name: 'FundOverview',
                          params: {
                            id: fa.fund_id,
                            type: 'fund_onetime',
                            index: index,
                          },
                        }"
                      >
                        <u>{{ fa.fund_name }}</u>
                      </router-link>
                    </b>
                    on {{ fa.instalment_date }}
                  </li>
                </ul>
                <ul v-for="(int, index) in investor_transaction_scheduled_list">
                  <li style="font-size: 15px" class="bxx">
                    Payment of {{ int.amount }} {{ int.currency_short_name }} is
                    due for Investor
                    <b>
                      <router-link
                        :to="{
                          name: 'InvestorOverview',
                          params: {
                            id: int.investor_id,
                            type: 'investor_transaction_scheduled',
                            index: index,
                          },
                        }"
                        replace
                      >
                        <u>{{ int.investor_name }}</u>
                      </router-link>
                    </b>
                    on {{ int.date }}
                  </li>
                </ul>
                <ul v-for="(int, index) in interest_df_seven_days_list">
                  <li style="font-size: 15px" class="bxx">
                    Interest payment of {{ int.amount }}
                    {{ int.currency_short_name }} is due for Investor
                    <b>
                      <router-link
                        :to="{
                          name: 'InvestorOverview',
                          params: {
                            id: int.investor_id,
                            type: 'interest_seven_days',
                            index: index,
                          },
                        }"
                        replace
                      >
                        <u>{{ int.investor_name }}</u>
                      </router-link>
                    </b>
                    on {{ int.date }}
                  </li>
                </ul>
                <ul v-for="(int, index) in maturity_df_seven_days_list">
                  <li style="font-size: 15px" class="bxx">
                    Maturity amount of {{ int.amount }}
                    {{ int.currency_short_name }} is due for Investor
                    <b>
                      <router-link
                        :to="{
                          name: 'InvestorOverview',
                          params: {
                            id: int.investor_id,
                            type: 'maturity_seven_days',
                            index: index,
                          },
                        }"
                        replace
                      >
                        <u>{{ int.investor_name }}</u>
                      </router-link>
                    </b>
                    on {{ int.date }}
                  </li>
                </ul>
                <ul v-for="(int, index) in profit_return_list">
                  <li style="font-size: 15px" class="bxx">
                    Profit return payment of {{ int.payment_amount }}
                    {{ int.currency_short_name }} is due for Borrower
                    <b>
                      <router-link
                        :to="{
                          name: 'LoanUtilisationRequestOverview',
                          params: {
                            borrower_id: int.borrower_id,
                            id: int.loan_registration_id,
                            type:'profit_return',
                            index: index,
                          },
                        }"
                      >
                        <u>{{ int.borrower_name }}</u>
                      </router-link>
                    </b>
                    on {{ int.payment_date }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-sm-12" v-if="selectedOption === 3">
              <div class="new_mid br_1">
                <h3>Received Payments</h3>
                <ul v-for="pay in paymentsReceived">
                  <li style="font-size: 15px" class="bxx">
                    Received payment of {{ pay.repaid_amount }}
                    {{ pay.currency_short_name }} for the utilisation request
                    <b>{{ pay.loan_registration_id }}</b> of
                    <b>
                      <router-link
                        :to="{
                          name: 'LoanUtilisationRequestOverview',
                          params: {
                            borrower_id: pay.borrower_id,
                            id: pay.loan_registration_id,
                          },
                        }"
                      >
                        <u>{{ pay.borrower_name }}</u>
                      </router-link>
                    </b>
                    on {{ pay.repayment_date }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-sm-12" v-if="selectedOption === 4">
              <div class="new_mid br_1">
                <h3>Expiry Documents</h3>
                <ul v-for="borr in borrowerExpiryDocs">
                  <li style="font-size: 18px">
                    <b>Borrower:</b>
                    <router-link
                      :to="{
                        name: 'BorrowerOverview',
                        params: { id: borr.borrower_id },
                      }"
                    >
                      <u>{{ borr.borrower_name }}</u>
                    </router-link>
                  </li>
                  <li style="color: grey; font-size: 15px" class="bxx">
                    <b>KYC Document:</b>
                    {{ borr.document_name }} will be
                    expired on {{ borr.expiry_date }}
                  </li>
                </ul>

                <ul v-for="fac in facilityExpiryDocs">
                  <li style="font-size: 18px">
                    <b>Borrower:</b>
                    <router-link
                      :to="{
                        name: 'FacilityOverview',
                        params: { id: fac.facility_id },
                      }"
                    >{{ fac.borrower_name }}</router-link>
                  </li>
                  <li style="color: grey; font-size: 15px" class="bxx">
                    <b>Facility Document:</b>
                    {{ fac.document_name }} will be
                    expired on {{ fac.expiry_date }}
                  </li>
                </ul>

                <ul v-for="loan in loanExpiryDocs">
                  <li style="font-size: 18px">
                    <b>Borrower:</b>
                    <router-link
                      :to="{
                        name: 'LoanUtilisationRequestOverview',
                        params: {
                          borrower_id: loan.borrower_id,
                          id: loan.loan_registration_id,
                        },
                      }"
                    >{{ loan.borrower_name }}</router-link>
                  </li>
                  <li style="color: grey; font-size: 15px" class="bxx">
                    <b>Loan Document:</b>
                    {{ loan.document_name }} will be
                    expired on {{ loan.expiry_date }}
                  </li>
                </ul>

                <ul v-for="inv in investorExpiryDocs">
                  <li style="font-size: 18px">
                    <b>Investor:</b>
                    <router-link
                      :to="{
                        name: 'InvestorOverview',
                        params: { id: inv.investor_id },
                      }"
                      replace
                    >{{ inv.investor_name }}</router-link>
                  </li>
                  <li style="color: grey; font-size: 15px" class="bxx">
                    <b>KYC Document:</b>
                    {{ inv.document_name }} will be expired
                    on {{ inv.expiry_date }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-sm-12" v-if="selectedOption === 5">
              <div class="new_mid br_1">
                <h3>Overdue Payments</h3>
                <ul v-for="(fa, index) in fund_amortised_before_current_list">
                  <li style="font-size: 15px" class="bxx">
                    {{ fa.expense_name }} of {{ fa.instalment_amount }}
                    {{ fa.currency_short_name }} was due for
                    <b>
                      <router-link
                        :to="{
                          name: 'FundOverview',
                          params: {
                            id: fa.fund_id,
                            type: 'fund_amortised_before_current',
                            index: index,
                          },
                        }"
                      >
                        <u>{{ fa.fund_name }}</u>
                      </router-link>
                    </b>
                    on {{ fa.instalment_date }}
                  </li>
                </ul>
                <ul v-for="(fa, index) in fund_onetime_before_current_list">
                  <li style="font-size: 15px" class="bxx">
                    {{ fa.expense_name }} of {{ fa.total_amount }}
                    {{ fa.currency_short_name }} was due for
                    <b>
                      <router-link
                        :to="{
                          name: 'FundOverview',
                          params: {
                            id: fa.fund_id,
                            type: 'fund_onetime_before_current',
                            index: index,
                          },
                        }"
                      >
                        <u>{{ fa.fund_name }}</u>
                      </router-link>
                    </b>
                    on {{ fa.instalment_date }}
                  </li>
                </ul>
                <ul v-for="(int,index) in interest_df_before_current_days_list">
                  <li style="font-size: 15px" class="bxx">
                    Interest payment of {{ int.amount }}
                    {{ int.currency_short_name }} was due for investor
                    <b>
                      <router-link
                        :to="{
                          name: 'InvestorOverview',
                          params: {
                            id: int.investor_id,
                            type: 'interest_before_current_days',
                            index: index,
                          },
                        }"
                        replace
                      >
                        <u>{{ int.investor_name }}</u>
                      </router-link>
                    </b>
                    on {{ int.date }}
                  </li>
                </ul>
                <ul v-for="(int,index) in maturity_df_before_current_days_list">
                  <li style="font-size: 15px" class="bxx">
                    Maturity payment of {{ int.amount }}
                    {{ int.currency_short_name }} was due for investor
                    <b>
                      <router-link
                        :to="{
                          name: 'InvestorOverview',
                          params: {
                            id: int.investor_id,
                            type: 'maturity_before_current_days',
                            index: index,
                          },
                        }"
                        replace
                      >
                        <u>{{ int.investor_name }}</u>
                      </router-link>
                    </b>
                    on {{ int.date }}
                  </li>
                </ul>
                <ul v-for="(int,index) in profit_return_before_current_list">
                  <li style="font-size: 15px" class="bxx">
                    Profit return payment of {{ int.payment_amount }}
                    {{ int.currency_short_name }} was due for borrower
                    <b>
                      <router-link
                        :to="{
                          name: 'LoanUtilisationRequestOverview',
                          params: {
                            borrower_id: int.borrower_id,
                            id: int.loan_registration_id,
                            type:'profit_return_before_current',
                            index: index,
                          },
                        }"
                      >
                        <u>{{ int.borrower_name }}</u>
                      </router-link>
                    </b>
                    on {{ int.payment_date }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>-->
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
    <!-- <footer-bar></footer-bar> -->
  </div>
  <!-- /.content-wrapper -->
</template>

<script>
import axios from "axios";
import moment from "moment";
import { Utils } from "../utils/CommonMethods.js"; //import commonMethods utils function

export default {
  data() {
    return {
      ActionTab: 1,
      today: new Date(),
      options: [
        { id: 1, name: "New Events" },
        { id: 2, name: "Upcoming Events" },
        { id: 3, name: "Upcoming Payments" },
        { id: 4, name: "Received Payments" },
        { id: 5, name: "Expiry Documents" },
        { id: 6, name: "Overdue Payments" },
      ],
      selectedOption: 1,
      //received payments
      paymentsReceived: [],
      //expiry documents
      borrowerExpiryDocs: [],
      borrowerDirectorExpiryDocs: [],
      facilityExpiryDocs: [],
      investorExpiryDocs: [],
      loanExpiryDocs: [],
      collateralExpiryDocs: [],
      collateralExpiryAdminDocs: [],
      warehouseExpiryDocs: [],
      warehouseExpiryAdminDocs: [],
      offtakerExpiryDocs: [],
      //new events
      borrowerList: [],
      facilityList: [],
      investorList: [],
      loanList: [],
      fundList: [],
      drawdownList: [],
      investmentList: [],
      collateralList: [],
      warehouseList: [],
      releaseList: [],
      verificationList: [],
      releaseInfoList: [],
      receivedInfoList: [],
      cleaningProcessList: [],
      shippingList: [],
      offtakerList: [],
      offtakerContractList: [],
      //upcoming payments
      fund_expense_before_current_list: [],
      fund_expense_list: [],
      interest_df_before_current_days_list: [],
      interest_df_seven_days_list: [],
      investor_transaction_scheduled_list: [],
      maturity_df_before_current_days_list: [],
      maturity_df_seven_days_list: [],
      // profit_return_before_current_list: [],
      // profit_return_list: [],
      //upcoming events
      site_inspection_current_list: [],
      //count
      new_events_count: null,
      upcoming_events_count: null,
      upcoming_count: null,
      received_payment_count: null,
      expiry_docs_count: null,
      overdue_count: null,
    };
  },
  methods: {
    format_date() {
      this.today = moment(this.today).format(this.$store.state.moment_date_format);
    },
    getReceivedPayments() {
      //this function will be called to get list of payments received
      const path = "received-payments";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.paymentsReceived = res.data.repayment_df_list;
          if(this.paymentsReceived.length > 0) {
              this.paymentsReceived.forEach(item => {
                if (item.repayment_date != '-' && item.repayment_date != null) {
                  item.repayment_date = moment(item.repayment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.repayment_date = '-';
                }
              });
            }
          this.received_payment_count = this.paymentsReceived.length;
          if (res.data.status == "success") {
            console.log("get received payments is successful.");
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          console.error("error: " + error);
        });
    },
    getUpcomingEvents() {
      //this function will be called to get list of events upcoming
      const path = "upcoming-events";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.site_inspection_current_list =
            res.data.site_inspection_current_list;
          this.site_inspection_current_list.filter((site) => {
            site.site_inspection_date = moment(
              site.site_inspection_date
            ).format(this.$store.state.moment_date_format);
          });
          this.upcoming_events_count = this.site_inspection_current_list.length;
          if (res.data.status == "success") {
            console.log("get upcoming events is successful.");
          } else {
            console.log("Something went wrong in upcoming events!");
          }
        })
        .catch((error) => {
          console.error("error: " + error);
        });
    },
    getUpcomingPayments() {
      //this function will be called to get list of payments upcoming
      const path = "upcoming-payments";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          console.log("status",res.data.status)
          if (res.data.status == "success") {
          this.fund_expense_before_current_list =
            res.data.fund_expense_before_current_list;
            if(this.fund_expense_before_current_list.length > 0) {
              this.fund_expense_before_current_list.forEach(item => {
                if (item.instalment_date != '-' && item.instalment_date != null) {
                  item.instalment_date = moment(item.instalment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.instalment_date = '-';
                }
              });
            }
          this.fund_expense_list = res.data.fund_expense_list;
          if(this.fund_expense_list.length > 0) {
              this.fund_expense_list.forEach(item => {
                if (item.instalment_date != '-' && item.instalment_date != null) {
                  item.instalment_date = moment(item.instalment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.instalment_date = '-';
                }
              });
            }
          this.interest_df_before_current_days_list =
            res.data.interest_df_before_current_days_list;
          if(this.interest_df_before_current_days_list.length > 0) {
            this.interest_df_before_current_days_list.forEach(item => {
              if (item.date != '-' && item.date != null) {
                item.date = moment(item.date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.date = '-';
              }
            });
          }
          this.interest_df_seven_days_list =
            res.data.interest_df_seven_days_list;
          if(this.interest_df_seven_days_list.length > 0) {
            this.interest_df_seven_days_list.forEach(item => {
              if (item.date != '-' && item.date != null) {
                item.date = moment(item.date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.date = '-';
              }
            });
          }
          this.investor_transaction_scheduled_list =
            res.data.investor_transaction_scheduled_list;
          if(this.investor_transaction_scheduled_list.length > 0) {
            this.investor_transaction_scheduled_list.forEach(item => {
              if (item.date != '-' && item.date != null) {
                item.date = moment(item.date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.date = '-';
              }
            });
          }
          this.maturity_df_before_current_days_list =
            res.data.maturity_df_before_current_days_list;
          if(this.maturity_df_before_current_days_list.length > 0) {
            this.maturity_df_before_current_days_list.forEach(item => {
              if (item.date != '-' && item.date != null) {
                item.date = moment(item.date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.date = '-';
              }
            });
          }
          this.maturity_df_seven_days_list =
            res.data.maturity_df_seven_days_list;
          if(this.maturity_df_seven_days_list.length > 0) {
            this.maturity_df_seven_days_list.forEach(item => {
              if (item.date != '-' && item.date != null) {
                item.date = moment(item.date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.date = '-';
              }
            });
          }
          // this.profit_return_before_current_list =
          //   res.data.profit_return_before_current_list;
          // this.profit_return_list = res.data.profit_return_list;
          this.upcoming_count =
            this.fund_expense_list.length +
            this.investor_transaction_scheduled_list.length +
            this.interest_df_seven_days_list.length +
            this.maturity_df_seven_days_list.length;
          // this.profit_return_list.length;

          this.overdue_count =
            this.fund_expense_before_current_list.length +
            this.interest_df_before_current_days_list.length +
            this.maturity_df_before_current_days_list.length;
          // this.profit_return_before_current_list.length;

            console.log("get upcoming payments is successful.");
          } else {
            console.log("Something went wrong in upcoming payments!");
          }
        })
        .catch((error) => {
          console.error("error: " + error);
        });
    },
    getExpirydocs() {
      //this function will be called to get list of expiry documents
      const path = "expiry-documents";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.borrowerExpiryDocs = res.data.borrower_kyc_list;
          if(this.borrowerExpiryDocs.length > 0) {
            this.borrowerExpiryDocs.forEach(item => {
              if (item.expiry_date != '-' && item.expiry_date != null) {
                item.expiry_date = moment(item.expiry_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.expiry_date = '-';
              }
            });
          }
          this.borrowerDirectorExpiryDocs = res.data.borrower_director_doc_list;
          if(this.borrowerDirectorExpiryDocs.length > 0) {
            this.borrowerDirectorExpiryDocs.forEach(item => {
              if (item.expiry_date != '-' && item.expiry_date != null) {
                item.expiry_date = moment(item.expiry_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.expiry_date = '-';
              }
            });
          }
          this.facilityExpiryDocs = res.data.facility_list;
          if(this.facilityExpiryDocs.length > 0) {
            this.facilityExpiryDocs.forEach(item => {
              if (item.expiry_date != '-' && item.expiry_date != null) {
                item.expiry_date = moment(item.expiry_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.expiry_date = '-';
              }
            });
          }
          this.investorExpiryDocs = res.data.investor_doc_list;
          if(this.investorExpiryDocs.length > 0) {
            this.investorExpiryDocs.forEach(item => {
              if (item.expiry_date != '-' && item.expiry_date != null) {
                item.expiry_date = moment(item.expiry_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.expiry_date = '-';
              }
            });
          }
          this.loanExpiryDocs = res.data.loan_doc_list;
          if(this.loanExpiryDocs.length > 0) {
            this.loanExpiryDocs.forEach(item => {
              if (item.expiry_date != '-' && item.expiry_date != null) {
                item.expiry_date = moment(item.expiry_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.expiry_date = '-';
              }
            });
          }
          this.collateralExpiryDocs = res.data.collateral_doc_list;
          if(this.collateralExpiryDocs.length > 0) {
            this.collateralExpiryDocs.forEach(item => {
              if (item.expiry_date != '-' && item.expiry_date != null) {
                item.expiry_date = moment(item.expiry_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.expiry_date = '-';
              }
            });
          }
          this.collateralExpiryAdminDocs = res.data.collateral_admin_doc_list;
          if(this.collateralExpiryAdminDocs.length > 0) {
            this.collateralExpiryAdminDocs.forEach(item => {
              if (item.expiry_date != '-' && item.expiry_date != null) {
                item.expiry_date = moment(item.expiry_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.expiry_date = '-';
              }
            });
          }
          this.warehouseExpiryDocs = res.data.warehouse_doc_list;
          if(this.warehouseExpiryDocs.length > 0) {
            this.warehouseExpiryDocs.forEach(item => {
              if (item.expiry_date != '-' && item.expiry_date != null) {
                item.expiry_date = moment(item.expiry_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.expiry_date = '-';
              }
            });
          }
          this.warehouseExpiryAdminDocs = res.data.warehouse_admin_doc_list;
          if(this.warehouseExpiryAdminDocs.length > 0) {
            this.warehouseExpiryAdminDocs.forEach(item => {
              if (item.expiry_date != '-' && item.expiry_date != null) {
                item.expiry_date = moment(item.expiry_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.expiry_date = '-';
              }
            });
          }
          this.offtakerExpiryDocs = res.data.offtaker_doc_list;
          if(this.offtakerExpiryDocs.length > 0) {
            this.offtakerExpiryDocs.forEach(item => {
              if (item.expiry_date != '-' && item.expiry_date != null) {
                item.expiry_date = moment(item.expiry_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.expiry_date = '-';
              }
            });
          }
          this.expiry_docs_count =
            this.borrowerExpiryDocs.length +
            this.borrowerDirectorExpiryDocs.length +
            this.facilityExpiryDocs.length +
            this.loanExpiryDocs.length +
            this.investorExpiryDocs.length +
            this.collateralExpiryDocs.length +
            this.warehouseExpiryDocs.length +
            this.offtakerExpiryDocs.length;
          if (this.loggedInUserGroupId === "Admin") {
            this.expiry_docs_count += this.collateralExpiryAdminDocs.length;
            this.expiry_docs_count += this.warehouseExpiryAdminDocs.length;
          }
          if (res.data.status == "success") {
            console.log("get expiry documents list is successful.");
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          console.error("error: " + error);
        });
    },
    getNewEvents() {
      //this function will be called to get list of expiry documents
      const path = "new-events";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.borrowerList = res.data.borrower_master_list;
          if(this.borrowerList.length > 0) {
            this.borrowerList.forEach(item => {
              if (item.registration_date != '-' && item.registration_date != null) {
                item.registration_date = moment(item.registration_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.registration_date = '-';
              }
            });
          }
          this.facilityList = res.data.facility_setup_list;
          if(this.facilityList.length > 0) {
            this.facilityList.forEach(item => {
              if (item.facility_request_date != '-' && item.facility_request_date != null) {
                item.facility_request_date = moment(item.facility_request_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.facility_request_date = '-';
              }
            });
          }
          this.investorList = res.data.new_investor_list;
          if(this.investorList.length > 0) {
            this.investorList.forEach(item => {
              if (item.registration_date != '-' && item.registration_date != null) {
                item.registration_date = moment(item.registration_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.registration_date = '-';
              }
            });
          }
          this.loanList = res.data.loan_utilisation_list;
          if(this.loanList.length > 0) {
            this.loanList.forEach(item => {
              if (item.loan_registration_date != '-' && item.loan_registration_date != null) {
                item.loan_registration_date = moment(item.loan_registration_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.loan_registration_date = '-';
              }
            });
          }
          this.fundList = res.data.fund_master_list;
          if(this.fundList.length > 0) {
            this.fundList.forEach(item => {
              if (item.fund_inception_date != '-' && item.fund_inception_date != null) {
                item.fund_inception_date = moment(item.fund_inception_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.fund_inception_date = '-';
              }
            });
          }
          this.drawdownList = res.data.drawdown_list;
          if(this.drawdownList.length > 0) {
            this.drawdownList.forEach(item => {
              if (item.disbursement_date != '-' && item.disbursement_date != null) {
                item.disbursement_date = moment(item.disbursement_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.disbursement_date = '-';
              }
            });
          }
          this.investmentList = res.data.investor_transaction_list;
          if(this.investmentList.length > 0) {
            this.investmentList.forEach(item => {
              if (item.investment_date != '-' && item.investment_date != null) {
                item.investment_date = moment(item.investment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.investment_date = '-';
              }
            });
          }
          this.collateralList = res.data.collateral_detail_list;
          if(this.collateralList.length > 0) {
            this.collateralList.forEach(item => {
              if (item.registration_date != '-' && item.registration_date != null) {
                item.registration_date = moment(item.registration_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.registration_date = '-';
              }
            });
          }
          this.warehouseList = res.data.warehouse_detail_list;
          if(this.warehouseList.length > 0) {
            this.warehouseList.forEach(item => {
              if (item.registration_date != '-' && item.registration_date != null) {
                item.registration_date = moment(item.registration_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.registration_date = '-';
              }
            });
          }
          this.releaseList = res.data.release_detail_list;
          if(this.releaseList.length > 0) {
            this.releaseList.forEach(item => {
              if (item.release_date != '-' && item.release_date != null) {
                item.release_date = moment(item.release_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.release_date = '-';
              }
            });
          }
          this.verificationList = res.data.verification_detail_list;
          if(this.verificationList.length > 0) {
            this.verificationList.forEach(item => {
              if (item.request_date != '-' && item.request_date != null) {
                item.request_date = moment(item.request_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.request_date = '-';
              }
            });
          }
          this.releaseInfoList = res.data.release_info_detail_list;
          if(this.releaseInfoList.length > 0) {
            this.releaseInfoList.forEach(item => {
              if (item.date != '-' && item.date != null) {
                item.date = moment(item.date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.date = '-';
              }
            });
          }
          this.receivedInfoList = res.data.received_info_detail_list;
          if(this.receivedInfoList.length > 0) {
            this.receivedInfoList.forEach(item => {
              if (item.received_date != '-' && item.received_date != null) {
                item.received_date = moment(item.received_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.received_date = '-';
              }
            });
          }
          this.cleaningProcessList = res.data.cleaning_process_detail_list;
          if(this.cleaningProcessList.length > 0) {
            this.cleaningProcessList.forEach(item => {
              if (item.received_date != '-' && item.received_date != null) {
                item.received_date = moment(item.received_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.received_date = '-';
              }
            });
          }
          this.shippingList = res.data.shipping_info_detail_list;
          if(this.shippingList.length > 0) {
            this.shippingList.forEach(item => {
              if (item.port_acceptance_date != '-' && item.port_acceptance_date != null) {
                item.port_acceptance_date = moment(item.port_acceptance_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.port_acceptance_date = '-';
              }
            });
          }
          this.offtakerList = res.data.offtaker_detail_list;
          if(this.offtakerList.length > 0) {
            this.offtakerList.forEach(item => {
              if (item.registration_date != '-' && item.registration_date != null) {
                item.registration_date = moment(item.registration_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.registration_date = '-';
              }
            });
          }
          this.offtakerContractList = res.data.offtaker_contract_detail_list;
          if(this.offtakerContractList.length > 0) {
            this.offtakerContractList.forEach(item => {
              if (item.contract_date != '-' && item.contract_date != null) {
                item.contract_date = moment(item.contract_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.contract_date = '-';
              }
            });
          }
          this.new_events_count =
            this.fundList.length +
            this.borrowerList.length +
            this.investorList.length +
            this.facilityList.length +
            this.loanList.length +
            this.drawdownList.length +
            this.investmentList.length +
            this.collateralList.length +
            this.warehouseList.length +
            this.releaseList.length +
            this.verificationList.length +
            this.releaseInfoList.length +
            this.receivedInfoList.length +
            this.cleaningProcessList.length +
            this.shippingList.length +
            this.offtakerList.length +
            this.offtakerContractList.length;
          if (res.data.status == "success") {
            console.log("get new event list is successful.");
          } else {
            console.log("Something went wrong in new events!");
          }
        })
        .catch((error) => {
          console.error("error: " + error);
        });
    },
    setActionTab() {
      localStorage.setItem("ActionTab", this.selectedOption);
    },
  },
  created() {
    axios.defaults.headers.common["Authorisation"] =
      "Bearer " + sessionStorage.getItem("token");
    this.format_date();
    this.getNewEvents();
    this.getReceivedPayments();
    this.getExpirydocs();
    this.getUpcomingEvents();
    this.getUpcomingPayments();
  },
  computed: {
    ReceivedPayments: function () {
      return this.paymentsReceived.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
    },
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
    // Payments: function() {
    //   return this.all_overdue.sort(
    //     (a, b) => new Date(a.date) - new Date(b.date)
    //   );
    // }
  },
  mounted() {
    //keep current tab active
    $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
      localStorage.setItem("activeTab", $(e.target).attr("href"));
    });
    var activeTab = localStorage.getItem("activeTab");
    if (activeTab) {
      $('.nav-tabs a[href="' + activeTab + '"]').tab("show");
    }
    //keep current tab active
    this.ActionTab = parseInt(localStorage.getItem("ActionTab")) || 1;
    this.selectedOption = this.ActionTab;
  },
};
</script>
