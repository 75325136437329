<!-- This is add Investor page. -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-plus"></i> Add Cost
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Cost Type</label
                      >
                      <select
                        id="selectedCost"
                        class="form-control"
                        name="selectedCost"
                        v-model="selectedCost"
                        required
                      >
                        <option :value="null">-- Select a Cost --</option>
                        <option
                          v-for="cost in loanExpenses"
                          :value="cost.id"
                          :key="cost.id"
                        >
                          {{ cost.loan_expense_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Amount</label
                      >
                      <input
                        type="number"
                        class="form-control"
                        placeholder
                        v-model="amount"
                        min="1"
                        @keypress="isDecimal($event)"
                        :class="{
                          'is-invalid': isSubmitted && $v.amount.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.amount.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.amount.required"
                          >Amount is required</span
                        >
                        <span v-if="!$v.amount.maxLength"
                          >Max length of 15 characters exceeded</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Currency</label
                      >
                      <select
                        id="selectedCurrency"
                        class="form-control"
                        name="selectedCurrency"
                        v-model="selectedCurrency"
                        required
                        :disabled="true"
                      >
                        <option :value="null">-- Select a Currency --</option>
                        <option
                          v-for="curr in currencies"
                          :value="curr.id"
                          :key="curr.id"
                        >
                          {{ curr.currency_short_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Date</label
                      >
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="CostPayDate"
                        placeholder="Select Date"
                        v-model="CostPayDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{
                          to: new Date(approval_date),
                          from: new Date(),
                        }"
                        required
                        :class="{
                          'is-invalid': isSubmitted && $v.CostPayDate.$error,
                        }"
                      ></datepicker>
                      <div
                        v-if="isSubmitted && $v.CostPayDate.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.CostPayDate.required"
                          >Date is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <!-- <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label>Status</label>
                    <select id="tokens" class="selectpicker form-control">
                      <option data-tokens="first">Option 1</option>
                      <option data-tokens="second">Option 2</option>
                      <option data-tokens="last">Option 3</option>
                    </select>
                  </div>
                  </div>-->
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documents</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addCostDoc()"
                              :disabled="CostDocumentsAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->
                    <div
                      class="row"
                      v-for="(doc, index) in CostDocuments"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label>Select Document</label>
                          <select
                            id="CostDocName"
                            class="form-control"
                            name="CostDocName"
                            v-model="doc.CostDocName"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="document in CostDocs"
                              :value="document.id"
                              :key="document.id"
                            >
                              {{ document.document_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Upload Document</label
                          >
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="file"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              v-on:change="
                                handleFileUploadCostDocuments(index, $event)
                              "
                            />
                            <small>
                              <p style="background-color: powderblue">
                                <b>{{ doc.displayFileName }}</b>
                                <small>
                                  <u
                                    style="color: blue; cursor: pointer"
                                    v-if="
                                      doc.CostDocumentRemoveDisabled === false
                                    "
                                    v-on:click="removeCostDocuments(index)"
                                    >Remove</u
                                  >
                                </small>
                              </p>
                            </small>
                            <small>
                              <p class="error" v-if="doc.errors.filesize">
                                {{ doc.errors.filesize }}
                              </p>
                            </small>
                            <label class="custom-file-label" for="file"
                              >Choose File</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="CostDocumentsDate"
                            placeholder="Select Date"
                            v-model="doc.CostDocumentsDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{
                              to: new Date(approval_date),
                              from: new Date(),
                            }"
                          ></datepicker>
                        </div>
                      </div>

                      <!-- <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Expiry Date</label>
                          <datepicker class="datepicker"
                            :bootstrap-styling="true"
                            name="ExpiryDate"
                            placeholder="Select Date"
                            v-model="doc.ExpiryDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                          ></datepicker>
                        </div>
                      </div>-->

                      <!-- <div class="col-md-3 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label>Status</label>
                        <select id="tokens" class="selectpicker form-control">
                          <option data-tokens="first">Option 1</option>
                          <option data-tokens="second">Option 2</option>
                          <option data-tokens="last">Option 3</option>
                        </select>
                      </div>
                      </div>-->

                      <!-- <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Comments</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Type..."
                            v-model="doc.comments"
                          />
                        </div>
                      </div>-->

                      <div class="col-md-1 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            style="width: 250px"
                            v-on:click="deleteCostDocuments(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onCostDocumentSaveInfo()"
                          :disabled="isDisabledCostDocumentSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-success sh"
                      v-on:click="onSubmit()"
                      :disabled="isDisabledOnSubmit"
                    >
                      Submit
                    </button>
                  </li>
                  <li>
                    <a @click="$router.go(-1)">
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>
    <!-- ./wrapper -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      //master tables
      loan_registration_id: null,
      borrower_id: null,
      facility_registration_id: null,
      utilisation_id: null,
      //variables for Cost information
      loanExpenses: [],
      amount: null,
      selectedCost: null,
      selectedCurrency: null,
      currencies: [],
      CostPayDate: null,
      CostPayDate1: null,
      currencies_drawdown: null,
      //list for Cost Documents
      CostDocuments: [],
      CostDocs: [],
      CostDocumentFile: null,
      // DrawdownDocumentsButtonDisabled: true,
      CostDocumentsDisabled: true,
      CostDocumentSaveInfoDisabled: true, //save info button is disabled
      CostDocumentsAddMoreDisabled: true,
      approval_date: null,
      isSubmitted: false,
    };
  },
  validations: {
    CostPayDate: {
      required,
    },
    amount: {
      required,
      maxLength: maxLength(15),
    },
  },
  computed: {
    //this is to computed disabled property of main Submit button to save information in database.
    isDisabledOnSubmit: function () {
      if (this.selectedCost === null || this.selectedCurrency === null) {
        //here we will check for all sections
        return true;
      }
    },

    //this is to computed disabled property of CostDocuments Save Info button.
    isDisabledCostDocumentSaveInfo: function () {
      let checkEmptyCostDocName = this.CostDocuments.filter(
        (line) => line.CostDocName === null
      );
      let checkEmptyCostDocumentsDate = this.CostDocuments.filter(
        (line) => line.CostDocumentsDate === null
      );
      //if the line is empty then disable save info button
      if (
        this.CostDocuments.length === 0 &&
        this.CostDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyCostDocName.length >= 1 && this.CostDocuments.length > 0) ||
        (checkEmptyCostDocumentsDate.length >= 1 &&
          this.CostDocuments.length > 0)
      ) {
        this.CostDocumentsDisabled = true;
      } else {
        this.CostDocumentsDisabled = false;
      }
      //CostDocumentsDisabled variable is to check whther all input fields except file upload are filled. CostDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.CostDocumentsDisabled === true ||
        this.CostDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledCostDocumentSaveInfo() close
  }, //main computed close

  components: {
    Datepicker,
  },
  methods: {
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },
    //this method will make disabled property as false for Director Save Info
    onCostDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.CostDocumentSaveInfoDisabled = false;
      this.CostDocumentsAddMoreDisabled = false;
    },

    deleteCostDocuments(index) {
      this.CostDocumentFile = this.CostDocumentFile
        ? this.CostDocumentFile
        : "file deleted";
      this.CostDocuments.splice(index, 1);
      if (
        typeof this.CostDocuments !== "undefined" &&
        this.CostDocuments.length == 0
      ) {
        this.CostDocumentsAddMoreDisabled = false;
        this.CostDocumentSaveInfoDisabled = true;
      }
    },

    //this method will submit the investor form in backend.
    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$confirm({
        auth: false,
        message: "Confirm submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            if (this.CostPayDate != null) {
              this.CostPayDate1 = moment(this.CostPayDate).format("MM-DD-YYYY");
            }
            //initialize the form data
            let formData = new FormData();
            //append company information to formdata
            formData.append("selectedCost", this.selectedCost);
            formData.append("selectedCurrency", this.selectedCurrency);
            formData.append("CostPayDate", this.CostPayDate1);
            formData.append("loanRegId", this.loan_registration_id);
            formData.append("selectedCurrency", this.selectedCurrency);
            formData.append("amount", this.amount);
            //append CostDocuments information to formdata
            formData.append(
              "CostDocuments",
              JSON.stringify(this.CostDocuments)
            );
            formData.append(
              "clientId",
              JSON.stringify(this.$store.state.client_id)
            );
            formData.append("userId", this.$store.getters.getuserId);
            formData.append("moduleId", 5);
            formData.append("tabId", 19);
            const path = "/add_loan_cost"; // /" + this.loan_registration_id;
            axios
              .post(path, formData, {
                headers: {
                  enctype: "multipart/form-data",
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert("success", "Expense added successfully!");
                  // this.$router.push({ name: "LoanOverview" });
                  this.$router.go(-1);
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                  console.log("Something went wrong from server!");
                  console.log("FAILURE!!");
                }
              })
              .catch(function () {
                Utils.stop_loading();
                console.log("FAILURE!!");
                Utils.make_alert("success", "Something went wrong!");
              });
          }
        },
      });
    }, //onSubmit() close

    //this method will add new line for additional Cost Documents
    addCostDoc() {
      //when new line is added then CostDocumentFile variable and CostDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.CostDocumentFile = null),
        (this.CostDocumentSaveInfoDisabled = true),
        (this.CostDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.CostDocuments.push({
          CostDocName: null,
          CostDocumentsDate: null,
          ExpiryDate: null,
          comments: null,
          displayFileName: null,
          errors: { filesize: "" },
          CostDocumentRemoveDisabled: true,
        });
    }, //addCostDoc() close

    //handles a change on the file upload
    handleFileUploadCostDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.CostDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.CostDocumentFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors.filesize = "File size should be less than 100MB";
          self.CostDocuments[id].errors.filesize = JSON.stringify(
            response.errors.filesize
          ).replace(/\"/g, "");
          self.CostDocuments[id].selectedFile = null;
          self.CostDocuments[id].selectedFileName = null;
          self.CostDocuments[id].displayFileName = null;
          self.CostDocuments[id].CostDocumentRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.CostDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.CostDocuments[id].selectedFile = reader.result;
          self.CostDocuments[id].selectedFileName = e.target.files[0].name;
          self.CostDocuments[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.CostDocuments[id].errors.filesize = "";
          self.CostDocuments[id].CostDocumentRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadCostDocuments() close

    ////this method handles remove doc attachment
    removeCostDocuments(id) {
      this.CostDocumentFile = null;
      this.CostDocuments[id].selectedFile = null;
      this.CostDocuments[id].selectedFileName = null;
      this.CostDocuments[id].displayFileName = null;
      this.CostDocuments[id].CostDocumentRemoveDisabled = true;
    },

    //this function is called to get list of documents master
    getCostDocuments() {
      const path = "/cost-document";
      axios
        .get(path)
        .then((res) => {
          this.CostDocs = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getCostDocuments() close

    getLoanExpenses() {
      //this function is called to get list of loan expenses
      const path = "loan-expense-master";
      axios
        .get(path)
        .then((res) => {
          this.loanExpenses = res.data.loan_expenses;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
          this.currencies_drawdown = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFacilitySetupByBorrower() {
      //this function is called to get list of day count
      const path = "/facility-setup-borrower/" + this.borrower_id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.FacilitySetup = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close

  mounted() {
    this.addCostDoc();
  },

  created() {
    this.loan_registration_id = this.$route.params.loan_registration_id;
    this.borrower_id = this.$route.params.borrower_id;
    this.facility_registration_id = this.$route.params.facility_id;
    this.selectedCurrency = this.$route.params.fund_currency_id;
    this.approval_date = moment
      .utc(this.$route.params.approval_date, "MM-DD-YYYY")
      .toDate();
    this.getCostDocuments();
    this.getCurrencies();
    this.getFacilitySetupByBorrower();
    this.getLoanExpenses();
  },
};
</script>

<style scoped>
.error {
  color: red;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>
