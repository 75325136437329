<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-handshake-deal"></i> Borrower Overview
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="card-body br_overview">
                <!------menton here style="height: 300px;"-------->
                <table
                  class="table table-hover table-head-fixed text-nowrap"
                  style="table-layout: fixed"
                >
                  <tbody>
                    <tr>
                      <td class="wg">Name:</td>
                      <td class="wrap_text" v-if="borrower.borrower_name">
                        {{ borrower.borrower_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg">Incorporation Date:</td>
                      <td v-if="borrower.incorporation_date">
                        {{ borrower.incorporation_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg">Business Type :</td>
                      <td class="wrap_text" v-if="business_types">
                        {{ business_types }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg">Address:</td>
                      <td class="wrap_text" v-if="borrower.address">
                        {{ borrower.address }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg">Website:</td>
                      <td class="wrap_text" v-if="borrower.website">
                        {{ borrower.website }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg">Registration Date:</td>
                      <td v-if="borrower.registration_date">
                        {{ borrower.registration_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg">Registration No:</td>
                      <td v-if="borrower.registration_no">
                        {{ borrower.registration_no }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card-body br_overview">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td class="wg">Country:</td>
                      <td v-if="borrower.country_name">
                        {{ borrower.country_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg">City:</td>
                      <td v-if="borrower.city_name">
                        {{ borrower.city_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg">State:</td>
                      <td v-if="borrower.state_name">
                        {{ borrower.state_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg">Contact No:</td>
                      <td v-if="borrower.phone_number">
                        <span
                          >{{ borrower.primary_code }}
                          {{ borrower.phone_number }}</span
                        >
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg">Secondary Contact No:</td>
                      <td v-if="borrower.secondary_phone_number">
                        <span
                          >{{ borrower.secondary_code }}
                          {{ borrower.secondary_phone_number }}</span
                        >
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg">Email:</td>
                      <td v-if="borrower.email">
                        {{ borrower.email }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg">Secondary Email:</td>
                      <td v-if="borrower.secondary_email">
                        {{ borrower.secondary_email }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!--========================================================================-->

        <div class="container-fluid tabtop" id="al_tb">
          <div class="tp_mid">
            <div class="row">
              <div class="col-lg-12">
                <ul
                  class="nav nav-tabs"
                  id="custom-tabs-one-tab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="custom-tabs-one-home-tab"
                      data-toggle="pill"
                      href="#four"
                      role="tab"
                      aria-controls="custom-tabs-one-home"
                      aria-selected="true"
                      >Directors</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="custom-tabs-one-profile-tab"
                      data-toggle="pill"
                      href="#five"
                      role="tab"
                      aria-controls="custom-tabs-one-profile"
                      aria-selected="false"
                      >Key Management</a
                    >
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="custom-tabs-one-profile-tab"
                      data-toggle="pill"
                      href="#six"
                      role="tab"
                      aria-controls="custom-tabs-one-profile"
                      aria-selected="false"
                      >Shareholders</a
                    >
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="custom-tabs-one-profile-tab"
                      data-toggle="pill"
                      href="#seven"
                      role="tab"
                      aria-controls="custom-tabs-one-profile"
                      aria-selected="false"
                      >Funds</a
                    >
                  </li>
                </ul>
              </div>
              <!-- /.col -->
            </div>
          </div>
        </div>

        <div class="tab-content" id="custom-tabs-one-tabContent">
          <div
            class="tab-pane fade show active"
            id="four"
            role="tabpanel"
            aria-labelledby="kyc1"
          >
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-12">
                          <h1 class="headingt">Directors</h1>
                        </div>
                        <!-- /.col -->
                      </div>
                    </div>

                    <div class="card-body table-responsive p-0">
                      <!------menton here style="height: 300px;"-------->
                      <table class="table table-head-fixed text-nowrap">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Designation</th>
                            <th class="c">Documents</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="dir in borrower.borrower_directors_list" :key="dir.id">
                            <td v-if="dir.director_name !== null">
                              {{ dir.director_name }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="dir.designation !== null">
                              {{ dir.designation }}
                            </td>
                            <td v-else>-</td>
                            <td
                              class="c"
                              v-on:click="
                                showDirDocumentModal(dir.documents_list)
                              "
                              :style="{ cursor: 'pointer' }"
                            >
                              <i class="icofont-file-pdf text-danger"></i>
                            </td>
                          </tr>
                          <!----------------------------------------------->
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
          </div>
          <!-- /.Tabpane first end-->

          <div
            class="tab-pane fade"
            id="five"
            role="tabpanel"
            aria-labelledby="covenant1"
          >
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-12">
                          <h1 class="headingt">Key Management</h1>
                        </div>
                        <!-- /.col -->
                      </div>
                    </div>

                    <div class="card-body table-responsive p-0">
                      <!------menton here style="height: 300px;"-------->
                      <table class="table table-head-fixed text-nowrap">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Description</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="km in borrower.borrower_keymanagement_list" :key="km.id"
                          >
                            <td v-if="km.manager_name">
                              {{ km.manager_name }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="km.designation">
                              {{ km.designation }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="km.description">
                              {{ km.description }}
                            </td>
                            <td v-else>-</td>
                          </tr>
                          <!----------------------------------------------->
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade"
            id="six"
            role="tabpanel"
            aria-labelledby="loan1"
          >
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-12">
                          <h1 class="headingt">Shareholders</h1>
                        </div>
                        <!-- /.col -->
                      </div>
                    </div>

                    <div class="card-body table-responsive p-0">
                      <!------menton here style="height: 300px;"-------->
                      <table class="table table-head-fixed text-nowrap">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th class="right">Shareholding Patterns (%)</th>
                            <th width="150px"></th>
                            <th class="right">Shareholding Value</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="sh in borrower.borrower_shareholder_list" :key="sh.id">
                            <td v-if="sh.shareholder_name">
                              {{ sh.shareholder_name }}
                            </td>
                            <td v-else>-</td>
                            <td class="right" v-if="sh.shareholding_pattern">
                              {{ sh.shareholding_pattern }}
                            </td>
                            <td class="right" v-else>-</td>
                            <td></td>
                            <td class="right" v-if="sh.shareholding_value">
                              {{ sh.shareholding_value }}
                            </td>
                            <td class="right" v-else>-</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade"
            id="seven"
            role="tabpanel"
            aria-labelledby="fund1"
          >
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-12">
                          <h1 class="headingt">Funds</h1>
                        </div>
                        <!-- /.col -->
                      </div>
                    </div>

                    <div class="card-body table-responsive p-0">
                      <table class="table table-head-fixed text-nowrap">
                        <thead>
                          <tr>
                            <th>Name</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="fm in borrower.fund_list" :key="fm.id">
                            <td v-if="fm.fund_name !== null">
                              {{ fm.fund_name }}
                            </td>
                            <td v-else>-</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
          </div>
        </div>

        <!-- /.Tabpane second end-->

        <!--========================================================================-->

        <div class="container-fluid tabtop mt-3" id="al_tb">
          <div class="tp_mid">
            <div class="row">
              <div class="col-lg-12">
                <ul
                  class="nav nav-tabs"
                  id="custom-tabs-one-tab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="custom-tabs-one-home-tab"
                      data-toggle="pill"
                      href="#one"
                      role="tab"
                      aria-controls="custom-tabs-one-home"
                      aria-selected="true"
                      >KYC Tracker</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="custom-tabs-one-profile-tab"
                      data-toggle="pill"
                      href="#two"
                      role="tab"
                      aria-controls="custom-tabs-one-profile"
                      aria-selected="false"
                      >Financial Covenants</a
                    >
                  </li>
                </ul>
              </div>
              <!-- /.col -->
            </div>
          </div>
        </div>

        <div class="tab-content" id="custom-tabs-one-tabContent">
          <div
            class="tab-pane fade show active"
            id="one"
            role="tabpanel"
            aria-labelledby="kyc1"
          >
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt">KYC Tracker</h1>
                        </div>
                        <div class="col-lg-6">
                          <ul id="sr_btn">
                            <li>
                              <router-link
                                active-class="active"
                                :to="{
                                  name: 'KYCDocument',
                                  params: { id: id },
                                }"
                              >
                                <a>
                                  <u>KYC History</u>
                                </a>
                              </router-link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="card-body table-responsive p-0">
                      <!------menton here style="height: 300px;"-------->
                      <table class="table table-head-fixed text-nowrap">
                        <thead>
                          <tr>
                            <th>Document Name</th>
                            <th>Issue Date</th>
                            <th>Expiry Date</th>
                            <th class="c">Status</th>
                            <th
                              class="c"
                              v-if="
                                loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0
                              "
                            >
                              Change Status
                            </th>
                            <th class="c center">Comments</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="(kyc, index) in borrower_kycdocuments_list" :key='kyc.id'
                          >
                            <td
                              v-on:click="downloadDocument(kyc.document_path)"
                              :style="{ cursor: 'pointer' }"
                            >
                              <i class="icofont-download text-info"></i>
                              {{ kyc.document_name }}
                            </td>
                            <td v-if="kyc.uploading_date !== null">
                              {{ kyc.uploading_date }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="kyc.expiry_date !== null">
                              {{ kyc.expiry_date }}
                            </td>
                            <td v-else>-</td>
                            <td
                              :class="{
                                'c text-success':
                                  kyc.document_status === 'Approved',
                                'c text-warning':
                                  kyc.document_status === 'Received',
                                'c text-danger':
                                  kyc.document_status === 'Rejected',
                                'c text-dark':
                                  kyc.document_status === 'Expired',
                                'c text-info':
                                  kyc.document_status === 'Near to Expiry',
                              }"
                            >
                              {{
                                kyc.document_status === "Received"
                                  ? loggedInUserGroupId === "Borrower"
                                    ? "Uploaded"
                                    : kyc.document_status
                                  : kyc.document_status
                              }}
                            </td>
                            <template
                              v-if="
                                loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0
                              "
                            >
                              <td class="c">
                                <div class="status">
                                  <select
                                    id="docStatus"
                                    name="docStatus"
                                    @change="
                                      onChangeKYCDocStatus(
                                        $event,
                                        kyc.borrower_id,
                                        kyc.document_id,
                                        kyc.document_status,
                                        index
                                      )
                                    "
                                    class="form-control"
                                    :value="kyc.document_status"
                                    :disabled="
                                      inactive ||
                                      kyc.document_status !== 'Received'
                                    "
                                  >
                                    <option value="Received">Received</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                    <option
                                      v-if="kyc.document_status === 'Expired'"
                                      value="Expired"
                                    >
                                      Expired
                                    </option>
                                    <option
                                      v-if="
                                        kyc.document_status === 'Near to Expiry'
                                      "
                                      value="Near to Expiry"
                                    >
                                      Near to Expiry
                                    </option>
                                  </select>
                                </div>
                              </td>
                            </template>
                            <td
                              class="c center"
                              v-if="!inactive"
                              v-on:click="
                                showCommentModal(
                                  kyc.borrower_id,
                                  kyc.document_id,
                                  'kyc_comment',
                                  kyc.comments
                                )
                              "
                              :style="{ cursor: 'pointer' }"
                            >
                              <i class="icofont-comment text-info"></i>
                            </td>
                            <td class="c center" v-else>
                              <i
                                style="opacity: 0.5"
                                class="icofont-comment text-info"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
          </div>
          <!-- /.Tabpane first end-->

          <div
            class="tab-pane fade"
            id="two"
            role="tabpanel"
            aria-labelledby="covenant1"
          >
            <div class="container-fluid ot_tb pt-3">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6">
                          <h1 class="headingt">Financial Covenants</h1>
                        </div>
                        <div class="col-lg-6">
                          <ul id="sr_btn">
                            <li>
                              <router-link
                                active-class="active"
                                :to="{
                                  name: 'CovenantHistory',
                                  params: { id: id },
                                }"
                              >
                                <a>
                                  <u>Covenant History</u>
                                </a>
                              </router-link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="card-body table-responsive p-0">
                      <!------menton here style="height: 300px;"-------->
                      <table class="table table-head-fixed text-nowrap">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Review Date</th>
                            <th>Expected Value</th>
                            <th>Actual Value</th>
                            <th>Compliant</th>
                            <th class="c center">Comments</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="cov in borrower.covenants_transaction_list" :key="cov.id"
                          >
                            <td v-if="cov.covenants_name !== null">
                              {{ cov.covenants_name }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cov.upload_date !== null">
                              {{ cov.upload_date }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cov.expected_value !== null">
                              {{ cov.sign }} {{ cov.expected_value }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cov.actual_value !== null">
                              {{ cov.actual_value }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cov.compliant !== null">
                              {{ cov.compliant }}
                            </td>
                            <td v-else>-</td>
                            <td
                              class="c center"
                              v-if="!inactive"
                              v-on:click="
                                showCommentModal(
                                  cov.borrower_id,
                                  cov.covenants_master_id,
                                  'covenant_comment',
                                  cov.comments
                                )
                              "
                              :style="{ cursor: 'pointer' }"
                            >
                              <i class="icofont-comment text-info"></i>
                            </td>
                            <td class="c center" v-else>
                              <i
                                style="opacity: 0.5"
                                class="icofont-comment text-info"
                              ></i>
                            </td>
                          </tr>
                          <!----------------------------------------------->
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
          </div>
        </div>
        <!-- /.Tabpane second end-->
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper -->

    <!-- ./wrapper -->
    <!-- ./modal start -->
    <div class="modal fade" id="view_doc">
      <div class="modal-dialog mw-100 w-50">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-eye-alt"></i> View Document
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="col-4">Document Name</th>
                        <th class="col-4">Issue Date</th>
                        <th class="col-4">Expiry Date</th>
                        <th class="col-4">Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="doc in selectedDirDocumentsList" :key="doc.id">
                        <td class="col-6">{{ doc.document_name }}</td>
                        <td v-if="doc.issue_date" class="col-3">
                          {{ doc.issue_date }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="doc.expiry_date" class="col-3">
                          {{ doc.expiry_date }}
                        </td>
                        <td v-else>-</td>
                        <td
                          class="center col-1"
                          v-on:click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-download text-info"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <router-link :to="{ name: 'BorrowerOverview' }">
                <button
                  type="button"
                  class="btn-sm btn btn-dark sh"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->

    <!-- ./modal start -->
    <div class="modal fade" id="comm">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title"><i class="icofont-comment"></i>Comments</h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <textarea
                  rows="4"
                  cols="60"
                  style="outline: none"
                  v-model="comment"
                  :class="{
                    'is-invalid': $v.comment.$error,
                  }"
                ></textarea>
                <div v-if="$v.comment.$error" class="invalid-feedback">
                  <span v-if="!$v.comment.maxLength"
                    >Max length of 50 characters exceeded</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="saveComment()"
                  >
                    Save
                  </button>
                </li>
                <li>
                  <router-link :to="{ name: 'BorrowerOverview' }">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./modal end -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { maxLength } from "vuelidate/lib/validators";
import moment from "moment";
export default {
  data() {
    return {
      id: null,
      borrower: [],
      borrowerId: null, //required for modals
      documentId: null, //required for modals
      comment: null, //required for comment modal
      KYCCommentFlag: false,
      CovenantCommentFlag: false,
      selectedDirDocumentsList: [],
      borrower_kycdocuments_list: [],
      business_types: "",
      inactive: false,
    };
  },

  components: {
    Datepicker,
  },
  validations: {
    comment: {
      maxLength: maxLength(50),
    },
  },

  methods: {
    showDirDocumentModal(docList) {
      if (docList.length)
        docList.map((doc) => {
          doc.issue_date = doc.issue_date
            ? moment(doc.issue_date).format(this.$store.state.moment_date_format)
            : null;
            doc.expiry_date = doc.expiry_date
            ? moment(doc.expiry_date).format(this.$store.state.moment_date_format)
            : null;
        });
      this.selectedDirDocumentsList = docList;
      $("#view_doc").modal("show");
    },

    showCommentModal(borrId, docId, mgmtType, comm) {
      //this function opens the comment modal. 'docId' will take input for kyc document id and covenant id both.
      this.borrowerId = borrId;
      this.documentId = docId;
      this.comment = comm;
      if (mgmtType === "kyc_comment") {
        this.KYCCommentFlag = true;
        this.CovenantCommentFlag = false;
      }
      if (mgmtType === "covenant_comment") {
        this.CovenantCommentFlag = true;
        this.KYCCommentFlag = false;
      }
      $("#comm").modal("show");
    }, //showCommentModal() close

    handleInput: function (e) {
      //this function sets the 'comment' variable with comment inserted in <p> tag of comment modal.
      this.comment = e.target.innerText;
    },

    saveComment() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      //this function saves the comment from comment modal.
      this.$confirm({
        auth: false,
        message: "Confirm submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            let commentUpdateType;
            if (this.KYCCommentFlag === true) {
              commentUpdateType = "kyc_comment";
            }
            if (this.CovenantCommentFlag === true) {
              commentUpdateType = "covenant_comment";
            }
            const path = "borrower/" + this.borrowerId;
            axios
              .put(
                path,
                {
                  updateType: commentUpdateType,
                  comments: this.comment,
                  docId: this.documentId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 2,
                  tabId: 4,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                console.log("comment update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert("success", "Comment updated successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(
                    () => this.$router.go(this.$router.currentRoute),
                    1500
                  );
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          }
        },
      });
    },

    onChangeKYCDocStatus(updatedStatus, borrowerId, docId, prevStatus, index) {
      //this function is called when user changes the borrower kyc document status. The status is updated in the database for that specific kyc document.
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path = "borrower/" + borrowerId;
            axios
              .put(
                path,
                {
                  updateType: "kycDocStatus",
                  updatedKYCDocStatus: updatedStatus.target.value,
                  KYCDocId: docId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 2,
                  tabId: 9,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("kyc update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Document Status updated successfully!"
                  );
                  setTimeout(() => this.$router.go(), 1500);
                  //if you want to send any data into server before redirection then you can do it here
                  this.borrower_kycdocuments_list[index].document_status =
                    updatedStatus.target.value;
                } else {
                  updatedStatus.target.value = prevStatus;
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                updatedStatus.target.value = prevStatus;
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeKYCDocStatus close

    getBorrower() {
      //this function is called on page load and created events. it will show details on borrower with borrowerid equal to this.id
      const path = "borrower/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            if (this.inactive) {
              this.borrower = res.data.borrower_inactive;
            } else {
              this.borrower = res.data.borrower_active;
            }
            if (this.borrower.incorporation_date != '-' && this.borrower.incorporation_date != null) {
              this.borrower.incorporation_date = moment(this.borrower.incorporation_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.borrower.incorporation_date = '-';
            }
            if (this.borrower.registration_date != '-' && this.borrower.registration_date != null) {
              this.borrower.registration_date = moment(this.borrower.registration_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.borrower.registration_date = '-';
            }
            this.borrower_kycdocuments_list =
              this.borrower.borrower_kycdocuments_list;
            if(this.borrower_kycdocuments_list.length > 0) {
              this.borrower_kycdocuments_list.forEach(item => {
                if (item.uploading_date != '-' && item.uploading_date != null) {
                  item.uploading_date = moment(item.uploading_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.uploading_date = '-';
                }
                if (item.expiry_date != '-' && item.expiry_date != null) {
                  item.expiry_date = moment(item.expiry_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.expiry_date = '-';
                }
              });
            }
            if(this.borrower.covenants_transaction_list.length > 0) {
              this.borrower.covenants_transaction_list.forEach(item => {
                if (item.upload_date != '-' && item.upload_date != null) {
                  item.upload_date = moment(item.upload_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.upload_date = '-';
                }
              });
            }

            // creating string for all available business types
            for (let i in this.borrower.business_types_list) {
              if (this.borrower.business_types_list[i])
                this.business_types +=
                  this.borrower.business_types_list[i].business_type_name +
                  ", ";
            }
            this.business_types = this.business_types.slice(0, -2);
            console.log("get borrower details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/borrower");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getBorrower close

    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.inactive =
      this.$store.state.inactive === "borrower_inactive" ? true : false;
    this.getBorrower();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style>
.wg {
  font-weight: bold !important;
}
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}
</style>
