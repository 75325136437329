<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-history"></i> Covenant History
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row"></div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Actual Value</th>
                        <th>Expected Value</th>
                        <th>Compliant</th>
                        <th
                          v-if="
                            loggedInUserGroupId == 'Admin' ||
                            (loggedInUserGroupId == 'Analyst' &&
                            loggedInUserSubGroupId == 0)&&
                            !inactive
                          "
                        ></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="cov in CovenantHistory" :key="cov.id">
                        <td v-if="cov.covenants_name !== null">
                          {{ cov.covenants_name }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="cov.upload_date !== null">
                          {{ cov.upload_date }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="cov.actual_value !== null">
                          {{ cov.actual_value }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="cov.expected_value !== null">
                          {{ cov.sign }} {{ cov.expected_value }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="cov.compliant !== null">
                          {{ cov.compliant }}
                        </td>
                        <td v-else>-</td>
                        <td
                          v-if="
                            loggedInUserGroupId == 'Admin' ||
                            (loggedInUserGroupId == 'Analyst' &&
                            loggedInUserSubGroupId == 0) &&
                            !inactive
                          "
                          v-on:click="deleteCovenant(cov.id)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
      <!-- /.content-->
    </div>
    <!-- /.content-wrapper -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods";
import moment from "moment";

export default {
  data() {
    return {
      inactive: false,
      CovenantHistory: [],
    };
  },
  components: {
    Datepicker,
  },
  methods: {
    getCovenantHistory() {
      const path = "borrower-covenants-kyc-history/" + this.$route.params.id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.CovenantHistory = this.inactive
            ? res.data.borrower_covenant_inactive_list
            : res.data.borrower_covenant_list;
          
          if(this.CovenantHistory.length > 0) {
            this.CovenantHistory.forEach(item => {
              if (item.upload_date != '-' && item.upload_date != null) {
                item.upload_date = moment(item.upload_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.upload_date = '-';
              }
            });
          }
          if (res.data.status == "success") {
            console.log("get Covenant History is successful.");
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          console.error("error: " + error);
        });
    },
    deleteCovenant(tranId) {
      //this function is called when document needs to be deleted from backend database.
      const path = "borrower/" + this.$route.params.id;
      this.$confirm({
        auth: false,
        message: "Are you sure you want to permanently delete?",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete(path, {
                data: {
                  deleteType: "covenant",
                  tranId: tranId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 2,
                  tabId: 4,
                },
                headers: { "Content-type": "application/json" },
              })
              .then((res) => {
                Utils.stop_loading();
                if (res.data.status == "success") {
                  Utils.make_alert("success", "Record deleted.");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(() => this.$router.go(), 1000);
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          } else {
            this.$router.go(this.$router.currentRoute);
          }
        },
      });
    }, //deleteCovenant close
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
  created() {
    this.inactive =
      this.$store.state.inactive === "borrower_inactive" ? true : false;
    this.getCovenantHistory();
  },
};
</script>
