var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('nav-bar'),_c('aside-bar'),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid ot_tb pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[(_vm.loggedInUserGroupId == 'Borrower')?_c('div',{staticClass:"col-lg-6 col-sm-6"},[_vm._m(0)]):_c('div',{staticClass:"col-lg-6 col-sm-6"},[_vm._m(1)]),(
                      _vm.loggedInUserGroupId == 'Admin' ||
                      (_vm.loggedInUserGroupId == 'Analyst' &&
                        _vm.loggedInUserSubGroupId == 0)
                    )?_c('div',{staticClass:"col-lg-6 col-sm-6"},[_c('ul',{attrs:{"id":"sr_btn"}},[_c('li',[_c('button',{staticClass:"btn btn-sm sh_n btn-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push('/add-borrower')}}},[_c('i',{staticClass:"icofont-plus"}),_c('span',{staticClass:"mob"},[_vm._v("Add Borrower")])])])])]):_vm._e(),(_vm.loggedInUserGroupId == 'Admin')?_c('div',{staticClass:"col-lg-6 col-sm-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inActiveToggle),expression:"inActiveToggle"}],attrs:{"type":"radio","id":"active"},domProps:{"value":false,"checked":_vm._q(_vm.inActiveToggle,false)},on:{"change":[function($event){_vm.inActiveToggle=false},_vm.destroyTable]}}),_c('label',{staticStyle:{"padding-right":"20px"},attrs:{"for":"active"}},[_vm._v("Active")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inActiveToggle),expression:"inActiveToggle"}],attrs:{"type":"radio","id":"inactive"},domProps:{"value":true,"checked":_vm._q(_vm.inActiveToggle,true)},on:{"change":[function($event){_vm.inActiveToggle=true},_vm.destroyTable]}}),_c('label',{attrs:{"for":"inactive"}},[_vm._v("InActive")])]):_vm._e()])]),_c('div',{staticClass:"card-body table-responsive p-0",attrs:{"id":"datatable-padding"}},[_c('table',{staticClass:"table table-head-fixed text-nowrap table-hover myTable1",attrs:{"id":"myTable1"}},[_c('thead',[_c('tr',[(_vm.loggedInUserGroupId == 'Borrower')?_c('th',[_vm._v("Name")]):_c('th',[_vm._v("Borrower Name")]),_c('th',{staticClass:"c"},[_vm._v("KYC Status")]),_c('th',{staticClass:"c"},[_vm._v("Covenant Status")]),_c('th',{staticClass:"c"},[_vm._v("Registration Status")]),(
                          !_vm.inActiveToggle &&
                          _vm.loggedInUserGroupId == 'Admin'
                        )?_c('th',{staticClass:"c"},[_vm._v(" Change Reg. Status ")]):_vm._e(),(
                          _vm.loggedInUserGroupId == 'Borrower' ||
                          _vm.loggedInUserGroupId == 'Analyst' ||
                          (_vm.loggedInUserGroupId == 'Admin' &&
                          _vm.loggedInUserSubGroupId == 0)
                        )?_c('th',{staticClass:"c"},[_vm._v("Pay Status")]):_vm._e(),(
                          !_vm.inActiveToggle &&
                          _vm.loggedInUserGroupId == 'Admin' ||
                          (_vm.loggedInUserGroupId == 'Analyst' &&
                          _vm.loggedInUserSubGroupId == 0)
                        )?_c('th',{staticClass:"c"},[_vm._v(" Change Pay Status ")]):_vm._e(),(!_vm.inActiveToggle)?[(
                            _vm.loggedInUserGroupId == 'Analyst' ||
                            (_vm.loggedInUserGroupId == 'Admin' &&
                              _vm.loggedInUserSubGroupId == 0)
                          )?_c('th',{staticClass:"c center"},[_vm._v(" Add Facility ")]):_vm._e(),(
                            _vm.loggedInUserGroupId == 'Borrower' ||
                            _vm.loggedInUserGroupId == 'Analyst' ||
                            (_vm.loggedInUserGroupId == 'Admin' &&
                              _vm.loggedInUserSubGroupId == 0)
                          )?_c('th',{staticClass:"c"}):_vm._e(),(
                            _vm.loggedInUserGroupId == 'Admin' ||
                            (_vm.loggedInUserGroupId == 'Analyst' &&
                              _vm.loggedInUserSubGroupId == 0)
                          )?_c('th',{staticClass:"c"}):_vm._e()]:_vm._e()],2)]),_c('tbody',_vm._l((_vm.borrowers),function(borrower,index){return _c('tr',{key:borrower.borrower_id},[(
                          _vm.loggedInUserGroupId == 'Admin' ||
                          (_vm.loggedInUserGroupId == 'Analyst' &&
                            _vm.loggedInUserSubGroupId == 0)
                        )?[_c('td',{staticClass:"ln"},[_c('router-link',{attrs:{"to":{
                              name: 'BorrowerOverview',
                              params: {
                                id: borrower.borrower_id,
                                inactive: _vm.inActiveToggle,
                              },
                            }}},[_vm._v(_vm._s(borrower.borrower_name))])],1),(borrower.kyc_status !== null)?_c('td',{class:{
                            'c text-success':
                              borrower.kyc_status === 'Approved',
                            'c text-warning':
                              borrower.kyc_status === 'Received',
                            'c text-danger':
                              borrower.kyc_status === 'Rejected',
                          }},[_vm._v(" "+_vm._s(borrower.kyc_status)+" ")]):_c('td',[_vm._v("-")]),(borrower.covenant_status !== null)?_c('td',{class:{
                            'c text-success':
                              borrower.covenant_status === 'Compliant',
                            'c text-danger':
                              borrower.covenant_status === 'Non Compliant',
                          }},[_vm._v(" "+_vm._s(borrower.covenant_status)+" ")]):_c('td',[_vm._v("-")]),(borrower.status !== null)?_c('td',{class:{
                            'c text-success': borrower.status === 'Approved',
                            'c text-warning': borrower.status === 'Pending',
                            'c text-danger': borrower.status === 'Rejected',
                          }},[_vm._v(" "+_vm._s(borrower.status)+" ")]):_c('td',[_vm._v("-")]),(
                            !_vm.inActiveToggle &&
                            _vm.loggedInUserGroupId == 'Admin'
                          )?[_c('td',{staticClass:"c"},[_c('div',{staticClass:"status"},[_c('select',{staticClass:"form-control",attrs:{"id":"regStatus","name":"regStatus","disabled":borrower.status != 'Pending'},domProps:{"value":borrower.status},on:{"change":function($event){return _vm.onChangeStatus(
                                    $event,
                                    borrower.borrower_id,
                                    borrower.status,
                                    index
                                  )}}},[_c('option',{attrs:{"value":"Pending"}},[_vm._v("Pending")]),_c('option',{attrs:{"value":"Approved"}},[_vm._v("Approved")]),_c('option',{attrs:{"value":"Rejected"}},[_vm._v("Rejected")])])])])]:_vm._e(),(borrower.borrower_status_master_name !== null)?_c('td',{class:{
                            'c text-success': borrower.borrower_status_master_name === 'Live',
                            'c text-info': borrower.borrower_status_master_name === 'Recovery',
                            'c text-dark': borrower.borrower_status_master_name === '-',
                          }},[_vm._v(" "+_vm._s(borrower.borrower_status_master_name)+" ")]):_vm._e(),(
                            !_vm.inActiveToggle &&
                            _vm.loggedInUserGroupId == 'Admin' ||
                            (_vm.loggedInUserGroupId == 'Analyst' &&
                              _vm.loggedInUserSubGroupId == 0)
                          )?[_c('td',{staticClass:"c"},[_c('div',{staticClass:"status"},[_c('select',{staticClass:"form-control",attrs:{"id":"Status","name":"Status","disabled":borrower.status == 'Pending' || borrower.status == 'Rejected'},domProps:{"value":borrower.borrower_status_master_id},on:{"change":function($event){return _vm.onChangeBorrowerStatus(
                                    $event,
                                    borrower.borrower_id,
                                    borrower.borrower_status_master_id,
                                    index
                                  )}}},_vm._l((_vm.borrower_status),function(borr){return _c('option',{key:borr.id,attrs:{"disabled":borr.id == 1},domProps:{"value":borr.id}},[_vm._v(" "+_vm._s(borr.borrower_status)+" ")])}),0)])])]:_vm._e(),(!_vm.inActiveToggle)?[(borrower.status === 'Approved')?_c('td',{staticClass:"c text-danger center"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
                                name: 'AddFacility',
                                params: {
                                  borrower_id: borrower.borrower_id,
                                  type: 'facility',
                                  BorRegDate: borrower.registration_date,
                                },
                              }}},[_c('i',{staticClass:"icofont-plus text-success"})])],1):_c('td',{staticClass:"center"},[_c('i',{staticClass:"icofont-plus text-success",staticStyle:{"opacity":"0.5"}})]),(
                              _vm.loggedInUserGroupId == 'Borrower' ||
                              _vm.loggedInUserGroupId == 'Analyst' ||
                              (_vm.loggedInUserGroupId == 'Admin' &&
                                _vm.loggedInUserSubGroupId == 0)
                            )?_c('td',{staticClass:"c"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
                                name: 'EditBorrower',
                                params: {
                                  id: borrower.borrower_id,
                                  inactive: _vm.inActiveToggle,
                                },
                              }}},[_c('i',{staticClass:"icofont-ui-edit text-warning"})])],1):_vm._e(),(
                              _vm.loggedInUserGroupId == 'Admin' ||
                              (_vm.loggedInUserGroupId == 'Analyst' &&
                                _vm.loggedInUserSubGroupId == 0)
                            )?_c('td',{staticClass:"c",style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.deleteBorrower(borrower.borrower_id)}}},[_c('i',{staticClass:"icofont-trash text-danger"})]):_vm._e()]:_vm._e()]:(
                          _vm.loggedInUserGroupId == 'Borrower' &&
                          _vm.loggedInUserSubGroupId == borrower.borrower_id
                        )?[_c('td',{staticClass:"ln"},[_c('router-link',{attrs:{"to":{
                              name: 'BorrowerOverview',
                              params: { id: borrower.borrower_id },
                            }}},[_vm._v(_vm._s(borrower.borrower_name))])],1),(borrower.kyc_status !== null)?_c('td',{class:{
                            'c text-success':
                              borrower.kyc_status === 'Approved',
                            'c text-warning':
                              borrower.kyc_status === 'Received',
                            'c text-danger':
                              borrower.kyc_status === 'Rejected',
                          }},[_vm._v(" "+_vm._s(borrower.kyc_status)+" ")]):_c('td',[_vm._v("-")]),(borrower.covenant_status !== null)?_c('td',{class:{
                            'c text-success':
                              borrower.covenant_status === 'Compliant',
                            'c text-danger':
                              borrower.covenant_status === 'Non Compliant',
                          }},[_vm._v(" "+_vm._s(borrower.covenant_status)+" ")]):_c('td',[_vm._v("-")]),(borrower.status !== null)?_c('td',{class:{
                            'c text-success': borrower.status === 'Approved',
                            'c text-warning': borrower.status === 'Pending',
                            'c text-danger': borrower.status === 'Rejected',
                          }},[_vm._v(" "+_vm._s(borrower.status)+" ")]):_c('td',[_vm._v("-")]),(borrower.borrower_status_master_name !== null)?_c('td',{class:{
                            'c text-success': borrower.borrower_status_master_name === 'Live',
                            'c text-info': borrower.borrower_status_master_name === 'Recovery',
                            'c text-dark': borrower.borrower_status_master_name === '-',
                          }},[_vm._v(" "+_vm._s(borrower.borrower_status_master_name)+" ")]):_vm._e(),_c('td',{staticClass:"c"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
                              name: 'EditBorrower',
                              params: { id: borrower.borrower_id },
                            }}},[_c('i',{staticClass:"icofont-ui-edit text-warning"})])],1)]:_vm._e()],2)}),0)])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"headingt"},[_c('i',{staticClass:"icofont-handshake-deal"}),_vm._v(" Profile ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"headingt"},[_c('i',{staticClass:"icofont-handshake-deal"}),_vm._v(" Borrower ")])}]

export { render, staticRenderFns }