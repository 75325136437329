<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 v-if="id == 0" class="headingt">
                        <i class="icofont-plus"></i> Add New Contract
                      </h1>
                      <h1 v-else class="headingt">
                        <i class="icofont-plus"></i> Edit Contract
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedOfftaker" class="required-field"
                      >Offtaker Name</label
                    >
                    <select
                      id="selectedOfftaker"
                      class="form-control"
                      name="selectedOfftaker"
                      v-model="selectedOfftaker"
                      :disabled="id > 0 || partialFillFlag == 1"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedOfftaker.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="off in offtakers"
                        :value="off.offtaker_master_id"
                        :key="off.offtaker_master_id"
                      >
                        {{ off.offtaker_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedOfftaker.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedOfftaker.required"
                        >Offtaker Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedBorrower" class="required-field"
                      >Borrower Name</label
                    >
                    <select
                      id="selectedBorrower"
                      class="form-control"
                      v-model="selectedBorrower"
                      :disabled="id > 0 || partialFillFlag == 1"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedBorrower.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="bor in borrowers"
                        :value="bor.borrower_id"
                        :key="bor.borrower_id"
                      >
                        {{ bor.borrower_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedBorrower.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedBorrower.required"
                        >Borrower Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCollateral" class="required-field"
                      >Collateral</label
                    >
                    <select
                      id="selectedCollateral"
                      class="form-control"
                      name="selectedCollateral"
                      v-model="selectedCollateral"
                      @change="getPriceTypesByCollateral()"
                      :disabled="id > 0 || partialFillFlag == 1"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedCollateral.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="col in collaterals"
                        :value="col.id"
                        :key="col.id"
                      >
                        {{ col.collateral_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCollateral.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCollateral.required"
                        >Collateral is required</span
                      >
                    </div>
                  </div>
                </div>
                
                <!-- <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCollateralType" class="required-field"
                      >Collateral Type</label
                    >
                    <select
                      id="selectedCollateralType"
                      class="form-control"
                      name="selectedCollateralType"
                      v-model="selectedCollateralType"
                      :disabled="id > 0 || partialFillFlag == 1"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedCollateralType.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="col in collateralStages"
                        :value="col.id"
                        :key="col.id"
                      >
                        {{ col.stage_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCollateralType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCollateralType.required"
                        >Collateral Type is required</span
                      >
                    </div>
                  </div>
                </div> -->

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="contractNumber" class="required-field"
                      >Contract No.</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="contractNumber"
                      v-model="contractNumber"
                      @keyup="checkContractNumber()"
                      :disabled="id > 0 || partialFillFlag == 1"
                      :class="{
                        'is-invalid':
                          (isSubmitted && $v.contractNumber.$error) ||
                          contractNumberExists,
                      }"
                    />
                    <div
                      v-if="
                        (isSubmitted && $v.contractNumber.$error) ||
                        contractNumberExists
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="contractNumberExists"
                        >Contract No. already exists!</span
                      >
                      <span v-if="!$v.contractNumber.required"
                        >Contract No. is required</span
                      >
                      <span v-if="!$v.contractNumber.maxLength"
                        >Max length of 25 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="contractDate" class="required-field"
                      >Contract Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="contractDate"
                      id="contractDate"
                      placeholder="Select Date"
                      v-model="contractDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      @input="getPrice('date');getBackcapDate()"
                      :class="{
                        'is-invalid': isSubmitted && $v.contractDate.$error,
                      }"
                      :disabledDates="{ from: new Date() }"
                      :disabled="id > 0 || partialFillFlag == 1"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.contractDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.contractDate.required"
                        >Contract Date is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="totalQuantity" class="required-field"
                      >Total Quantity</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="totalQuantity"
                      v-model="totalQuantity"
                      @keypress="isDecimalTwoFixed($event)"
                      @paste="truncateDecimalPlacesUptoTwo($event)"
                      @keyup="calculateAllDuringTotalQuantityUpdate()"
                      :disabled="id > 0 || partialFillFlag == 1"
                      :class="{
                        'is-invalid': isSubmitted && $v.totalQuantity.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.totalQuantity.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.totalQuantity.required"
                        >Total Quantity is required</span
                      >
                      <span v-if="!$v.totalQuantity.decimal"
                        >Total Quantity should be decimal only<br
                      /></span>
                      <span v-if="!$v.totalQuantity.maxLength"
                        >Max length of 10 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedUnit" class="required-field"
                      >Unit</label
                    >
                    <select
                      id="selectedUnit"
                      class="form-control"
                      name="selectedUnit"
                      v-model="selectedUnit"
                      :disabled="id > 0 || partialFillFlag == 1"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedUnit.$error,
                      }"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="unit in unitTypes"
                        :value="unit.id"
                        :key="unit.id"
                      >
                        {{ unit.name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedUnit.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedUnit.required"
                        >Unit is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="details" class="required-field">Details</label>
                    <select
                      id="selectedDetails"
                      class="form-control"
                      name="selectedDetails"
                      v-model="selectedDetails"
                      @change="onDetailsChange()"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedDetails.$error,
                      }"
                      :disabled="(partialFillFlag == 1) || id > 0"
                    >
                      <option :value="null">--- Select ---</option>
                      <option value="Variable">Variable</option>
                      <option value="Fixed">Fixed</option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedDetails.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedDetails.required"
                        >Details is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="deliveryMonth" :class="{'required-field':this.selectedDetails == 'Fixed'}">Delivery Month</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="deliveryMonth"
                      placeholder="Select Date"
                      v-model="deliveryMonth"
                      minimum-view="month"
                      format="MM-yyyy"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :class="{
                        'is-invalid': isSubmitted && $v.deliveryMonth.$error,
                      }"
                      :disabledDates="{ to: new Date(backcapDeliveryDate) }"
                      :disabled="(partialFillFlag == 1) || deliveryMonthFlag"
                    ></datepicker>
                    <div
                        v-if="isSubmitted && $v.deliveryMonth.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.deliveryMonth.required"
                          >Delivery Month is required</span
                        >
                      </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedPriceType" :class="{'required-field':this.selectedDetails == 'Fixed'}">Price Type</label>
                    <select
                      id="selectedPriceType"
                      class="form-control"
                      name="selectedPriceType"
                      v-model="selectedPriceType"
                      @change="getPrice('price')"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedPriceType.$error,
                      }"
                      :disabled="(partialFillFlag == 1) || selectedPriceTypeFlag"
                    >
                      <option :value="null">--- Select ---</option>
                      <option v-for="p in priceTypes" :value="p.id" :key="p.id">
                        {{ p.price_name }}
                      </option>
                    </select>
                    <div
                        v-if="isSubmitted && $v.selectedPriceType.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedPriceType.required"
                          >Price Type is required</span
                        >
                      </div>
                  </div>
                </div>

                <div v-if="!priceSpecialFlag" class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Contract Price Currency</label>
                      <select
                        id="selectedCurrency"
                        class="form-control"
                        v-model="selectedCurrency"
                        @change="getContractCurrencyExchangeRateByCurrencyDate()"
                        :class="{
                          'is-invalid':
                            (isSubmitted && $v.selectedCurrency.$error) || ((priceSpecialFlag == false) && (selectedCurrency == null)),
                        }"
                        :disabled="(partialFillFlag == 1) || selectedPriceTypeFlag"
                      >
                        <option :value="null">-- Select a Currency --</option>
                        <option
                          v-for="curr in currencies"
                          :value="curr.id"
                          :key="curr.id"
                        >
                          {{ curr.currency_short_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedCurrency.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedCurrency.required"
                          >Contract Price Currency is required</span
                        >
                      </div>
                      <div
                        v-if="(priceSpecialFlag == false) && (selectedCurrency == null) && (!isSubmitted)"
                        class="invalid-feedback"
                      >
                        <span>Please select a currency.</span>
                      </div>
                    </div>
                  </div>

                  <div v-if="!priceSpecialFlag" class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="conversionDate" class="required-field">Conversion Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        placeholder="Select Date"
                        @input="getContractCurrencyExchangeRateByCurrencyDate()"
                        id="conversionDate"
                        v-model="conversionDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :class="{
                        'is-invalid': (isSubmitted && $v.conversionDate.$error) || ((!priceSpecialFlag) && (conversionDate == null)),
                        }"
                        :calendar-button="true"
                        :disabledDates="{ to: new Date(backcapConversionDate) , from: new Date() }"
                        :disabled="(partialFillFlag == 1) || selectedPriceTypeFlag"
                        ></datepicker>
                      <div
                          v-if="isSubmitted && $v.conversionDate.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.conversionDate.required"
                            >Conversion Date is required</span>
                      </div>
                        <div
                          v-if="(!priceSpecialFlag) && (conversionDate == null) && (!isSubmitted)"
                          class="invalid-feedback"
                        >
                          <span>Please select a conversion date.</span>
                        </div>
                      </div>
                  </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="price" :class="{'required-field':this.selectedDetails == 'Fixed'}">Price (Per MT)</label>
                    <input
                      type="text"
                      class="form-control"
                      id="price"
                      v-model="price"
                      @keyup="calculateAllDuringPriceUpdate()"
                      @keypress="isDecimalTwoFixed($event)"
                      @paste="truncateDecimalPlacesUptoTwo($event)"
                      :disabled="(partialFillFlag == 1) || priceSpecialFlag || priceFlag"
                      :class="{
                        'is-invalid':
                          (isSubmitted && $v.price.$error) ||
                          ((selectedPriceTypeName == 'Other Price (Input)') &&
                            (price === null || price == 0)),
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.price.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.price.required"
                        >Price is required</span>
                      <span v-if="!$v.price.decimal"
                        >Price (Per MT) should be decimal only<br
                      /></span>
                      <span v-if="!$v.price.maxLength"
                        >Max length of 10 digits exceeded</span
                      >
                    </div>
                    <div
                      v-if="
                        (selectedPriceTypeName == 'Other Price (Input)') &&
                        (price === null || price == 0) && (!isSubmitted)
                      "
                      class="invalid-feedback"
                    >
                      <span>Please enter a price.</span>
                    </div>
                  </div>
                </div>


                <div v-if="!priceSpecialFlag" class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="conversionRate" :class="{'required-field':this.selectedDetails == 'Fixed'}">Conversion Rate</label>
                    <input
                      type="number"
                      class="form-control"
                      id="conversionRate"
                      v-model="conversionRate"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.conversionRate.$error,
                      }"
                      disabled
                      />
                      <!-- :disabled="(partialFillFlag == 1) || conversionRateFlag" -->
                    <div
                      v-if="isSubmitted && $v.conversionRate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.conversionRate.required"
                        >Conversion Rate is required</span>
                      <span v-if="!$v.conversionRate.decimal"
                        >Conversion Rate should be decimal only<br
                      /></span>
                      <span v-if="!$v.conversionRate.maxLength"
                        >Max length of 10 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div v-if="!priceSpecialFlag" class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="finalPrice" :class="{'required-field':this.selectedDetails == 'Fixed'}">Final Price</label>
                    <input
                      type="text"
                      class="form-control"
                      id="finalPrice"
                      v-model="finalPrice"
                      @keypress="isDecimalTwoFixed($event)"
                      @paste="truncateDecimalPlacesUptoTwo($event)"
                      @keyup="calculateContractValue()"
                      disabled
                    />
                    <!-- :disabled="(partialFillFlag == 1) || finalPriceFlag" -->
                    <!-- <div
                      v-if="isSubmitted && $v.finalPrice.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.finalPrice.required"
                      >Final Price is required</span>
                      <span v-if="!$v.finalPrice.decimal"
                        >Final Price should be decimal only<br
                      /></span>
                      <span v-if="!$v.finalPrice.maxLength"
                        >Max length of 20 digits exceeded</span
                      >
                    </div> -->
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="contractValue" :class="{'required-field':this.selectedDetails == 'Fixed'}">Contract Value</label>
                    <input
                      type="text"
                      class="form-control"
                      id="contractValue"
                      v-model="contractValue"
                      @keypress="isDecimalTwoFixed($event)"
                      @paste="truncateDecimalPlacesUptoTwo($event)"
                      disabled
                    />
                    <!-- :disabled="(partialFillFlag == 1) || contractValueFlag" -->
                    <!-- <div
                      v-if="isSubmitted && $v.contractValue.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.contractValue.required"
                        >Contract Value is required</span>
                      <span v-if="!$v.contractValue.decimal"
                        >Contract Value should be decimal only<br
                      /></span>
                      <span v-if="!$v.contractValue.maxLength"
                        >Max length of 20 digits exceeded</span
                      >
                    </div> -->
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="paymentTerms">Payment Terms</label>
                    <input
                      type="text"
                      class="form-control"
                      id="paymentTerms"
                      v-model="paymentTerms"
                      @keypress="isNum($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.paymentTerms.$error,
                      }"
                      :disabled="(partialFillFlag == 1) || paymentTermsFlag"
                    />
                    <div
                      v-if="isSubmitted && $v.paymentTerms.$error"
                      class="invalid-feedback"
                    >
                    <span v-if="!$v.paymentTerms.customValidation"
                        >Payment Terms should not be zero<br
                      /></span>
                      <span v-if="!$v.paymentTerms.numeric"
                        >Payment Terms should be numeric only<br
                      /></span>
                      <span v-if="!$v.paymentTerms.maxDigit"
                        >Payment terms cannot exceed 100.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="agent">Agent</label>
                    <input
                      type="text"
                      class="form-control"
                      id="agent"
                      v-model="agent"
                      :class="{
                        'is-invalid': isSubmitted && $v.agent.$error,
                      }"
                      :disabled="(partialFillFlag == 1) || agentFlag"
                    />
                    <div
                      v-if="isSubmitted && $v.agent.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.agent.customValidation"
                        >Agent should not be zero<br
                      /></span>
                      <span v-if="!$v.agent.maxLength"
                        >Max length of 100 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="quality">Quality</label>
                    <input
                      type="text"
                      class="form-control"
                      id="quality"
                      v-model="quality"
                      :class="{
                        'is-invalid': isSubmitted && $v.quality.$error,
                      }"
                      :disabled="(partialFillFlag == 1) || qualityFlag"
                    />
                    <div
                      v-if="isSubmitted && $v.quality.$error"
                      class="invalid-feedback"
                    >
                    <span v-if="!$v.quality.customValidation"
                        >Quality should not be zero<br
                      /></span>
                      <span v-if="!$v.quality.maxLength"
                        >Max length of 100 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="terms">Terms</label>
                    <input
                      type="text"
                      class="form-control"
                      id="terms"
                      v-model="terms"
                      :class="{
                        'is-invalid': isSubmitted && $v.terms.$error,
                      }"
                      :disabled="(partialFillFlag == 1) || termsFlag"
                    />
                    <div
                      v-if="isSubmitted && $v.terms.$error"
                      class="invalid-feedback"
                    >
                    <span v-if="!$v.terms.customValidation"
                        >Terms should not be zero<br
                      /></span>
                      <span v-if="!$v.terms.maxLength"
                        >Max length of 100 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="arbitration">Arbitration</label>
                    <input
                      type="text"
                      class="form-control"
                      id="arbitration"
                      v-model="arbitration"
                      :class="{
                        'is-invalid': isSubmitted && $v.arbitration.$error,
                      }"
                      :disabled="(partialFillFlag == 1) || arbitrationFlag"
                    />
                    <div
                      v-if="isSubmitted && $v.arbitration.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.arbitration.customValidation"
                        >Arbitration should not be zero<br
                      /></span>
                      <span v-if="!$v.arbitration.maxLength"
                        >Max length of 100 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="comments">Comments</label>
                    <input
                      type="text"
                      class="form-control"
                      id="comments"
                      v-model="comments"
                      :class="{
                        'is-invalid': isSubmitted && $v.comments.$error,
                      }"
                      :disabled="(partialFillFlag == 1) || commentsFlag"
                    />
                    <div
                      v-if="isSubmitted && $v.comments.$error"
                      class="invalid-feedback"
                    >
                    <span v-if="!$v.comments.customValidation"
                        >Comments should not be zero<br
                      /></span>
                      <span v-if="!$v.comments.maxLength"
                        >Max length of 500 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="(OfftakerContractDocList.length > 0 && (partialFillFlag == 1)) || (partialFillFlag == 0)" class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div v-if="(partialFillFlag == 0)" class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addOfftakerContractDoc()"
                            :disabled="OfftakerContractDocAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(off, index) in OfftakerContractDocList"
                    :key="index"
                  >
                    <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="customFile">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            @change="handleFileUploadOfftaker(index, $event)"
                            :class="{
                              'is-invalid': off.errors,
                            }"
                            :disabled="(partialFillFlag == 1) || off.id"
                          />
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ off.displayFileName }}</b>
                            </p>
                          </small>
                          <div v-if="off.errors" class="invalid-feedback">
                            <span>{{ off.errors }}</span>
                          </div>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="IssueDate">Issue Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          id="IssueDate"
                          placeholder="Select Date"
                          v-model="off.IssueDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          :disabledDates="{
                            from: new Date(),
                          }"
                          :disabled="(partialFillFlag == 1) || off.id"
                        ></datepicker>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          id="contractComments"
                          placeholder
                          v-model="off.comments"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.OfftakerContractDocList.$each[index].comments
                                .$error,
                          }"
                          :disabled="(partialFillFlag == 1) || off.id"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.OfftakerContractDocList.$each[index].comments
                              .$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.OfftakerContractDocList.$each[index].comments
                                .maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div 
                          v-if="(partialFillFlag == 0)"
                          style="width: 250px"
                          @click="deleteOfftakerContract(index, off.id)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="(partialFillFlag == 0)" class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        @click="onOfftakerContractDocSaveInfo()"
                        :disabled="isDisabledOfftakerContractDocSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3" v-if="id > 0 && partialFillFlag == 1">
          <div
            class="card card-primary card-tabs"
            v-for="(partial, index1) in staticContractPartialFill"
            :key="index1"
          >
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Partial Fill {{ index1 + 1 }}</h1>
                    </div>
                  </div>
                </div>

                <span v-if="!selectedCurrency" style="font-size: 12px; color: red"
                  >Note: To add a partial contract, the Contract Price Currency will be required.</span
                >

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div class="row">
                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="fixation_date" class="required-field"
                          >Fixation Date</label
                        >
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="fixation_date"
                          id="fixation_date"
                          placeholder="Select Date"
                          v-model="partial.fixation_date"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          disabled
                        ></datepicker>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="fixed_quantity" class="required-field"
                          >Fixed Quantity</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          id="fixed_quantity"
                          v-model="partial.fixed_quantity"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="balance_to_be_fixed" class="required-field"
                          >Balance to be Fixed</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          id="balance_to_be_fixed"
                          v-model="partial.balance_to_be_fixed"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="price" class="required-field">Price</label>
                        <input
                          type="number"
                          class="form-control"
                          id="price"
                          v-model="partial.price"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="differential" class="required-field"
                          >Differential</label
                        ><br/>
                        <select
                          id="differential_value"
                          class="contact_code"
                          v-model="partial.differential_value"
                          disabled                        
                          >
                        <option
                            v-for="curr in differentialValue"
                            :value="curr.id"
                            :key="curr.id"
                          >
                            {{ curr.differential_value }}
                          </option>
                        </select>
                        <input
                          type="number"
                          class="contact"
                          id="differential"
                          v-model="partial.differential"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="month">Month</label>
                        <select
                          id="month"
                          class="form-control"
                          v-model="partial.month"
                          disabled
                        >
                        <option :value="null">--- Select ---</option>
                        <option
                            v-for="curr in Months"
                            :value="curr.id"
                            :key="curr.id"
                          >
                            {{ curr.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label class="required-field">Base Currency</label>
                        <select
                          id="base_currency"
                          class="form-control"
                          v-model="partial.base_currency"
                          disabled
                        >
                          <option :value="null">-- Select a Currency --</option>
                          <option
                            v-for="curr in currencies"
                            :value="curr.id"
                            :key="curr.id"
                          >
                            {{ curr.currency_short_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label class="required-field" title="Conversion Price Currency">Conv. Price Currency</label>
                        <select
                          id="currency"
                          class="form-control"
                          v-model="partial.currency"
                          disabled
                        >
                          <option :value="null">-- Select a Currency --</option>
                          <option
                            v-for="curr in currencies"
                            :value="curr.id"
                            :key="curr.id"
                          >
                            {{ curr.currency_short_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="conversion_date" class=""
                          >Conversion Date</label
                        >
                        <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        placeholder="Select Date"
                        name="conversion_date"
                        id="conversion_date"
                        v-model="partial.conversion_date"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{ from: new Date() }"
                        disabled
                        ></datepicker>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="currency_rate" class=""
                          >Conversion Rate</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          id="currency_rate"
                          v-model="partial.currency_rate"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="final_price" class=""
                          >Final Price (Conv.)</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          id="final_price"
                          v-model="partial.final_price"
                          disabled
                          />
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="partial_contract_value"
                          >Partial Contract Value</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          id="partial_contract_value"
                          v-model="partial.partial_contract_value"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card card-primary card-tabs"
            v-for="(partial, index) in contractPartialFill"
            :key="index"
          >
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Partial Fill {{ latestPartialFill + 1 }}</h1>
                    </div>
                  </div>
                </div>

                <span v-if="!selectedCurrency" style="font-size: 12px; color: red"
                  >Note: To add a partial contract, the Contract Price Currency will be required.</span
                >

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div class="row">
                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="fixation_date" class="required-field"
                          >Fixation Date</label
                        >
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="fixation_date"
                          id="fixation_date"
                          placeholder="Select Date"
                          v-model="partial.fixation_date"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          @input="getCurrencyRate(index)"
                          :disabledDates="{
                            from: new Date(),
                            to: fixationBackcapDate(),
                          }"
                          :disabled="index !== contractPartialFill.length - 1"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.contractPartialFill.$each[index].fixation_date
                                .$error,
                          }"
                        ></datepicker>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.contractPartialFill.$each[index].fixation_date
                              .$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].fixation_date
                                .required
                            "
                            >Fixation Date is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="fixed_quantity" class="required-field"
                          >Fixed Quantity</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="fixed_quantity"
                          v-model="partial.fixed_quantity"
                          @keypress="isDecimalTwoFixed($event)"
                          @paste="truncateDecimalPlacesUptoTwo($event)"
                          @keyup="calculateBalanceToBeFixed(index);calculatePartialContractValue(index)"
                          :disabled="index !== contractPartialFill.length - 1"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.contractPartialFill.$each[index].fixed_quantity
                                .$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.contractPartialFill.$each[index].fixed_quantity
                              .$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index]
                                .fixed_quantity.required
                            "
                            >Fixed Quantity is required</span
                          >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index]
                                .fixed_quantity.decimal
                            "
                            >Fixed Quantity should be decimal only<br
                          /></span>
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index]
                                .fixed_quantity.maxLength
                            "
                            >Max length of 10 digits exceeded <br
                          /> </span
                          >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index]
                                .fixed_quantity.quantityLimitFirst
                            "
                            >Fixed Qty cannot exceed the Remaining Contract Qty. (after loan and partial contract utilization):
                            {{ outstandingContractQuantityStatic }}</span
                          >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index]
                                .fixed_quantity.quantityLimit
                            "
                            >Fixed Qty cannot exceed the Remaining Contract Qty. (after loan and partial contract utilization): 
                            {{ outstandingContractQuantityStatic  }}
                            <!-- {{
                              contractPartialFill[
                                contractPartialFill.length - 2
                              ].balance_to_be_fixed
                            }} -->
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="balance_to_be_fixed" class="required-field"
                          >Balance to be Fixed</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          id="balance_to_be_fixed"
                          v-model="partial.balance_to_be_fixed"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.contractPartialFill.$each[index]
                                .balance_to_be_fixed.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.contractPartialFill.$each[index]
                              .balance_to_be_fixed.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index]
                                .balance_to_be_fixed.required
                            "
                            >Balance to be Fixed is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="month">Month</label>
                        <input
                          type="text"
                          class="form-control"
                          id="month"
                          v-model="partial.month"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.contractPartialFill.$each[index].month.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.contractPartialFill.$each[index].month.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].month
                                .required
                            "
                            >Month is required</span
                          >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].month
                                .maxLength
                            "
                            >Max length of 10 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div> -->

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="price" class="required-field">Price</label>
                        <input
                          type="text"
                          class="form-control"
                          id="price"
                          v-model="partial.price"
                          @keypress="isDecimalTwoFixed($event)"
                          @paste="truncateDecimalPlacesUptoTwo($event)"
                          @keyup="calculateFinalPrice(index)"
                          :disabled="index !== contractPartialFill.length - 1"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.contractPartialFill.$each[index].price.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.contractPartialFill.$each[index].price.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].price
                                .required
                            "
                            >Price is required</span
                          >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].price.decimal
                            "
                            >Price should be decimal only<br
                          /></span>
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].price
                                .maxLength
                            "
                            >Max length of 10 digits exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label class="required-field">Differential Value</label>
                        <select
                          id="differential_value"
                          class="form-control"
                          v-model="partial.differential_value"
                          @change="calculateFinalPrice(index)"
                          :disabled="index !== contractPartialFill.length - 1"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.contractPartialFill.$each[index].differential_value
                                .$error,
                          }"
                        >
                        <option
                            v-for="curr in differentialValue"
                            :value="curr.id"
                            :key="curr.id"
                          >
                            {{ curr.differential_value }}
                          </option>
                          
                        </select>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.contractPartialFill.$each[index].differential_value.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].differential_value
                                .required
                            "
                            >Differential Value is required</span
                          >
                        </div>
                      </div>
                    </div> -->

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="differential" class="required-field"
                          >Differential</label
                        ><br/>
                        <select
                          id="differential_value"
                          class="contact_code"
                          v-model="partial.differential_value"
                          @change="calculateFinalPrice(index)"
                          :disabled="index !== contractPartialFill.length - 1"
                        >
                        <option
                            v-for="curr in differentialValue"
                            :value="curr.id"
                            :key="curr.id"
                          >
                            {{ curr.differential_value }}
                          </option>
                        </select>
                        <input
                          type="text"
                          class="contact"
                          id="differential"
                          v-model="partial.differential"
                          @keypress="isDecimalTwoFixed($event)"
                          @paste="truncateDecimalPlacesUptoTwo($event)"
                          @keyup="calculateFinalPrice(index)"
                          :disabled="index !== contractPartialFill.length - 1"
                          :class="{
                            'is-invalid': (partial.differential_error) ||
                              (isSubmitted &&
                              $v.contractPartialFill.$each[index].differential
                                .$error),
                          }"
                        />
                        <div
                          v-if=" (partial.differential_error) ||  
                            (isSubmitted &&
                            $v.contractPartialFill.$each[index].differential
                              .$error)
                          "
                          class="invalid-feedback"
                        >
                        <span
                          v-if="(partial.differential_error)"
                        >
                        Differential value should not be greater than or equal price.
                        </span>
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].differential
                                .required
                            "
                            >Differential is required</span
                          >
                          
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].differential
                                .decimal
                            "
                            >Differential should be decimal only<br
                          /></span>
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].differential
                                .maxLength
                            "
                            >Max length of 10 digits exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="month">Month</label>
                        <!-- <input
                          type="text"
                          class="form-control"
                          id="month"
                          v-model="partial.month"
                          disabled
                        /> -->
                        <select
                          id="month"
                          class="form-control"
                          v-model="partial.month"
                          :disabled="index !== contractPartialFill.length - 1"
                        >
                        <option :value="null">--- Select ---</option>
                        <option
                            v-for="curr in Months"
                            :value="curr.id"
                            :key="curr.id"
                          >
                            {{ curr.name }}
                          </option>
                        </select>
                          <!-- :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.contractPartialFill.$each[index].month.$error,
                          }"
                        <div
                          v-if="
                            isSubmitted &&
                            $v.contractPartialFill.$each[index].month.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].month
                                .required
                            "
                            >Month is required</span
                          >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].month
                                .maxLength
                            "
                            >Max length of 10 characters exceeded</span
                          >
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label class="required-field">Base Currency</label>
                        <select
                          id="base_currency"
                          class="form-control"
                          v-model="partial.base_currency"
                          @change="getCurrencyRate(index)"
                          :disabled="index !== contractPartialFill.length - 1"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.contractPartialFill.$each[index].base_currency
                                .$error,
                          }"
                        >
                          <option :value="null">-- Select a Currency --</option>
                          <option
                            v-for="curr in currencies"
                            :value="curr.id"
                            :key="curr.id"
                          >
                            {{ curr.currency_short_name }}
                          </option>
                        </select>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.contractPartialFill.$each[index].base_currency
                              .$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].base_currency
                                .required
                            "
                          >
                            Base Currency is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label class="required-field" title="Conversion Price Currency">Conv. Price Currency</label>
                        <select
                          id="currency"
                          class="form-control"
                          v-model="partial.currency"
                          @change="getCurrencyRate(index)"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.contractPartialFill.$each[index].currency
                                .$error,
                          }"
                        >
                          <option :value="null">-- Select a Currency --</option>
                          <option
                            v-for="curr in currencies"
                            :value="curr.id"
                            :key="curr.id"
                          >
                            {{ curr.currency_short_name }}
                          </option>
                        </select>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.contractPartialFill.$each[index].currency.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].currency
                                .required
                            "
                            >Currency is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="conversion_date" class=""
                          >Conversion Date</label
                        >
                        <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        placeholder="Select Date"
                        name="conversion_date"
                        id="conversion_date"
                        v-model="partial.conversion_date"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{ from: new Date() }"
                        disabled
                        ></datepicker>
                        <!-- <div
                          v-if="
                            isSubmitted &&
                            $v.contractPartialFill.$each[index].conversion_date
                              .$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].conversion_date
                                .required
                            "
                            >Conversion Date is required</span
                          >
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="currency_rate" class=""
                          >Conversion Rate</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          id="currency_rate"
                          v-model="partial.currency_rate"
                          @keypress="isDecimal($event)"
                          @keyup="calculateFinalPrice(index)"
                          disabled
                        />
                          <!-- :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.contractPartialFill.$each[index].currency_rate
                                .$error,
                          }"
                        <div
                          v-if="
                            isSubmitted &&
                            $v.contractPartialFill.$each[index].currency_rate
                              .$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].currency_rate
                                .required
                            "
                            >Conversion Rate is required</span
                          >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].currency_rate
                                .decimal
                            "
                            >Conversion Rate should be decimal only<br
                          /></span>
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].currency_rate
                                .maxLength
                            "
                            >Max length of 10 digits exceeded</span
                          >
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="final_price" class=""
                          >Final Price (Conv.)</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="final_price"
                          v-model="partial.final_price"
                          @keypress="isDecimalTwoFixed($event)"
                          @paste="truncateDecimalPlacesUptoTwo($event)"
                          @keyup="calculatePartialContractValue(index)"
                          disabled
                          />
                          <!-- :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.contractPartialFill.$each[index].final_price
                                .$error,
                          }"
                        <div
                          v-if="
                            isSubmitted &&
                            $v.contractPartialFill.$each[index].final_price
                              .$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].final_price
                                .required
                            "
                            >Final Price required</span
                          >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].final_price
                                .decimal
                            "
                            >Final Price should be decimal only<br
                          /></span>
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index].final_price
                                .maxLength
                            "
                            >Max length of 10 digits exceeded</span
                          >
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="partial_contract_value"
                          >Partial Contract Value</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="partial_contract_value"
                          v-model="partial.partial_contract_value"
                          @keypress="isDecimalTwoFixed($event)"
                          @paste="truncateDecimalPlacesUptoTwo($event)"
                          disabled
                        />
                        <!-- <div
                          v-if="
                            isSubmitted &&
                            $v.contractPartialFill.$each[index]
                              .partial_contract_value.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index]
                                .partial_contract_value.decimal
                            "
                            >Partial Contract Value should be decimal only<br
                          /></span>
                          <span
                            v-if="
                              !$v.contractPartialFill.$each[index]
                                .partial_contract_value.maxLength
                            "
                            >Max length of 10 digits exceeded</span
                          >
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    @click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a @click="$router.go(-1)" style="cursor: pointer">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>

  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js";
import {
  required,
  maxLength,
  decimal,
  numeric,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: null,
      offtakers: [],
      contracts: [],
      borrowers: [],
      collaterals: [],
      unitTypes: [],
      priceTypes: [],
      currencies: [],
      contractPartialFill: [],
      staticContractPartialFill: [],
      collateralStages: [],
      differentialValue: [
        {id:"1" , differential_value: "-"},
        {id:"0" , differential_value: "+"},
      ],
      // Create an array of objects representing months with name and id
      Months : [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' }
      ],
      //variables for offtaker contract tracker
      selectedOfftaker: null,
      selectedBorrower: null,
      selectedCollateral: null,
      selectedCollateralType: null,
      contractCurrency:null,
      selectedCurrency: null,
      contractNumber: null,
      contractDate: null,
      totalQuantity: null,
      selectedUnit: null,
      deliveryMonth: null,
      selectedPriceType: null,
      selectedPriceTypeName: null,
      price: null,
      conversionDate:null,
      conversionRate: null,
      finalPrice: null,
      contractValue: null,
      paymentTerms: null,
      agent: null,
      quality: null,
      terms: null,
      arbitration: null,
      comments: null,
      selectedDetails: null,

      priceSpecialFlag: true,  // as we are handling disabled during price in add contract
      outstandingContractQuantity:null,
      outstandingContractQuantityStatic:null,
      backcapDeliveryDate:new Date(moment(new Date()).subtract(10, "year")),
      backcapConversionDate:new Date(moment(new Date()).subtract(10, "year")),
      
      // disabled field flag 
      deliveryMonthFlag: false,
      selectedPriceTypeFlag: false,
      selectedPriceTypeNameFlag: false,
      priceFlag: false,   
      conversionRateFlag: false,
      finalPriceFlag: false,
      contractValueFlag: false,
      paymentTermsFlag: false,
      agentFlag: false,
      qualityFlag: false,
      termsFlag: false,
      arbitrationFlag: false,
      commentsFlag: false,

      //list for offtaker documents
      OfftakerContractDocList: [],
      OfftakerContractDocFile: null,
      OfftakerContractDocDisabled: true,
      OfftakerContractDocSaveInfoDisabled: true, //save info button is disabled
      OfftakerContractDocAddMoreDisabled: true,

      isSubmitted: false,
      contractNumberExists: false,

      latestPartialFill: null,
      partialFillFlag:0,
      EditFlag:false,
    };
  },
  validations: {
    selectedOfftaker: {
      required,
    },
    selectedBorrower: {
      required,
    },
    selectedCollateral: {
      required,
    },
    // selectedCollateralType: {
    //   required,
    // },
    contractNumber: {
      required: function () {
        if (!this.contractNumber || this.contractNumber == 0 || this.contractNumber == '') return false;
        else return true;
      },
      maxLength: maxLength(25),
    },
    contractDate: {
      required,
    },
    totalQuantity: {
      required: function () {
        if (!this.totalQuantity || this.totalQuantity == 0 || this.totalQuantity == '') return false;
        else return true;
      },
      decimal,
      maxLength: maxLength(10),
    },
    selectedUnit: {
      required,
    },
    selectedDetails: {
      required,
    },
    deliveryMonth: {
      required: function (value) {
        if (this.selectedDetails === 'Fixed' && (value == null)) return false;
        else return true;
      },
    },
    selectedCurrency: {
      required: function (value) {
        if (!this.priceSpecialFlag && value == null) return false;
        else return true;
      },
    },
    conversionDate: {
      required: function (value) {
        if (!this.priceSpecialFlag && value == null) return false;
        else return true;
      },
    },
    selectedPriceType: {
      required: function (value) {
        if (this.selectedDetails === 'Fixed' && (value == null || value == 0 || value == '')) return false;
        else return true;
      },
    },
    price: {
      required: function (value) {
        if ((this.selectedDetails === 'Fixed' && (value == null || value == 0 || value == '')) || (!this.priceSpecialFlag && (value == null || value == 0 || value == ''))) return false;
        // if (this.selectedDetails === 'Fixed' && (value == null || value == 0 || value == '')) return false;
        else return true;
      },
      decimal,
      maxLength: maxLength(10),
    },
    conversionRate: {
      required: function (value) {
        // if ((this.selectedDetails === 'Fixed' && (value == null || value == 0 || value == '')) || (!this.priceSpecialFlag && (value == null || value == 0 || value == ''))) return false;
        if (this.selectedDetails === 'Fixed' && (value == null || value == 0 || value == '')) return false;
        else return true;
      },
      decimal,
      maxLength: maxLength(10),
    },
    // finalPrice: {
    //   required: function (value) {
    //     if (this.selectedDetails === 'Fixed' && (value == null || value == 0 || value == '')) return false;
    //     else return true;
    //   },
    //   decimal,
    //   maxLength: maxLength(10),
    // },
    // contractValue: {
    //   required: function (value) {
    //     if (this.selectedDetails === 'Fixed' && (value == null || value == 0 || value == '')) return false;
    //     else return true;
    //   },
    //   decimal,
    //   maxLength: maxLength(20),
    // },
    paymentTerms: {
      customValidation: function (value) {
        if (value != "" && value == 0) return false;
        else return true;
      },
      numeric,
      maxDigit: function () {
        if (this.paymentTerms && Number(this.paymentTerms) > 100) return false;
        else return true;
      },
    },
    agent: {
      customValidation: function (value) {
        if (value != "" && value == 0) return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    quality: {
      customValidation: function (value) {
        if (value != "" && value == 0) return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    terms: {
      customValidation: function (value) {
        if (value != "" && value == 0) return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    arbitration: {
      customValidation: function (value) {
        if (value != "" && value == 0) return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    comments: {
      customValidation: function (value) {
        if (value != "" && value == 0) return false;
        else return true;
      },
      maxLength: maxLength(500),
    },
    OfftakerContractDocList: {
      $each: {
        comments: {
          maxLength: maxLength(50),
        },
      },
    },
    contractPartialFill: {
      $each: {
        fixation_date: {
          required: function (value) {
            if (this.id > 0 && value == null) return false;
            else return true;
          },
        },
        fixed_quantity: {
          required: function (value) {
            if (this.id > 0 && (value == null || value == 0 || value == '')) return false;
            else return true;
          },
          // validation for fixed quantity limit for first partial fill
          quantityLimitFirst: function (value) {
            if (
              this.id > 0 &&
              this.contractPartialFill.length == 1 &&
              // value > Number(this.totalQuantity)
              value > Number(this.outstandingContractQuantityStatic)
            )
              return false;
            else return true;
          },
          // validation for fixed quantity limit for all partial fills except first
          quantityLimit: function (value) {
            if (this.id > 0 && this.contractPartialFill.length > 1) {
              if (
                value > Number(this.outstandingContractQuantityStatic)
              )
                return false;
              // if (
              //   value >
              //   Number(
              //     this.contractPartialFill[this.contractPartialFill.length - 2]
              //       .balance_to_be_fixed
              //   )
              // )
              //   return false;
              else return true;
            } else return true;
          },
          decimal,
          maxLength: maxLength(10),
        },
        balance_to_be_fixed: {
          required: function (value) {
            if (this.id > 0 && (value == null || value == '')) return false;
            else return true;
          },
        },
        month: {
          maxLength: maxLength(10),
        },
        price: {
          required: function (value) {
            if (this.id > 0 && (value == null || value == 0 || value == '')) return false;
            else return true;
          },
          decimal,
          maxLength: maxLength(10),
        },
        // differential_value: {
        //   required: function (value) {
        //     if (this.id > 0 && (value == null || value == '')) return false;
        //     else return true;
        //   },
        // },
        differential: {
          required: function (value) {
            if (this.id > 0 && (value == null || value == 0 || value == '')) return false;
            else return true;
          },
          decimal,
          maxLength: maxLength(10),
        },
        base_currency: {
          required: function (value) {
            if (this.id > 0 && (value == null || value == 0 || value == '')) return false;
            else return true;
          },
        },
        currency: {
          required: function (value) {
            if (this.id > 0 && (value == null || value == 0 || value == '')) return false;
            else return true;
          },
        },
        conversion_date: {
          required: function (value) {
            if (this.id > 0 && value === null) return false;
            else return true;
          },
        },
        currency_rate: {
          required: function (value) {
            if (this.id > 0 && (value == null || value == 0 || value == '')) return false;
            else return true;
          },
          decimal,
          maxLength: maxLength(10),
        },
        // final_price: {
        //   required: function (value) {
        //     if (this.id > 0 && (value == null || value == 0 || value == '')) return false;
        //     else return true;
        //   },
        //   decimal,
        //   maxLength: maxLength(10),
        // },
        // partial_contract_value: {
        //   decimal,
        //   maxLength: maxLength(20),
        // },
      },
    },
  },
  computed: {
    //this is to computed disabled property of Offtaker Save Info button.
    isDisabledOfftakerContractDocSaveInfo: function () {
      let checkEmptyOfftakerDocName = this.OfftakerContractDocList.filter(
        (line) => line.displayFileName === null
      );
      if (
        this.OfftakerContractDocList.length === 0 &&
        this.OfftakerContractDocSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyOfftakerDocName.length >= 1 &&
        this.OfftakerContractDocList.length > 0
      ) {
        this.OfftakerContractDocDisabled = true;
      } else {
        this.OfftakerContractDocDisabled = false;
      }
      //OfftakerContractDocDisabled variable is to check whther all input fields except file upload are filled. OfftakerContractDocFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.OfftakerContractDocDisabled === true ||
        this.OfftakerContractDocFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledOfftakerContractDocSaveInfo() close
    loggedInUserFullName() {
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  }, //main computed close
  components: {
    Datepicker,
  },
  methods: {
    // removeMinus(e) {
    //   let invalidChars = ["-"];
    //   if (invalidChars.includes(e.key)) return e.preventDefault();
    //   else return true; // If not match, don't add to input text
    // },

    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },

    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },

    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },

    // check if contract number already exists
    checkContractNumber() {
      if (
        this.contracts.filter(
          (con) => con.contract_number === this.contractNumber
        ).length
      ) {
        this.contractNumberExists = true;
      } else this.contractNumberExists = false;
    },

    getBackcapDate(){
    const contractDateMoment = moment(this.contractDate);
    // Set backcapDeliveryDate to the start of the month
    this.backcapDeliveryDate = contractDateMoment.startOf('month');      
    this.backcapConversionDate = this.contractDate;

    },

    calculateAllDuringTotalQuantityUpdate(){
      if (this.totalQuantity && this.price && this.conversionRate) {
      this.calculateContractFinalPrice();
      this.calculateContractValue();
      } else {
        this.contractValue = null;
      }
    },

    calculateAllDuringPriceUpdate(){
      if (this.price && this.conversionRate) {
      this.calculateContractFinalPrice();
      this.calculateContractValue();
      } else {
        this.finalPrice = null;
        this.contractValue = null;
      }
    },

    calculateContractFinalPrice() {
      if (this.price && this.conversionRate) {
          let final_price = Number(this.price) * Number(this.conversionRate);
          this.finalPrice = final_price.toFixed(2);
          // this.finalPrice = Number(this.finalPrice) == 0 ? null : this.finalPrice;
          this.finalPrice = this.finalPrice;
        } else {
        this.finalPrice = null;
      }
    },

    // calculates contract value based on available price
    calculateContractValue() {
      if (this.finalPrice) {
        if (this.totalQuantity) {
          let contract_value = Number(this.totalQuantity) * Number(this.finalPrice);
          this.contractValue = contract_value.toFixed(2);
          // this.contractValue = Number(this.contractValue) == 0 ? null : this.contractValue;
          this.contractValue =  this.contractValue;
        } else {
          this.contractValue = null;
        }
      } else {
        if (this.totalQuantity) {
          let contract_value = Number(this.totalQuantity) * Number(this.price);
          this.contractValue = contract_value.toFixed(2);
          // this.contractValue = Number(this.contractValue) == 0 ? null : this.contractValue;
          this.contractValue =  this.contractValue;
        } else {
          this.contractValue = null;
        }
      }
    },

    formattedMonth(i) {
      let fixationDate = moment(this.contractPartialFill[i].fixation_date);
      this.contractPartialFill[i].month = fixationDate.format('MMMM');
    },

    getContractCurrencyExchangeRateByCurrencyDate() {
      //this function is called to get fund currency of that facility
      console.log("inside getContractCurrencyExchangeRateByCurrencyDate")
      console.log(this.contractCurrency)
      if(this.selectedCurrency && this.contractCurrency && this.conversionDate){

        let currencyDate = null;
        if (this.conversionDate != null) {
          currencyDate = moment(this.conversionDate).format("MM-DD-YYYY");
        }
        if(this.selectedCurrency == this.contractCurrency){
          this.conversionRate = 1;
          this.calculateContractFinalPrice();
          this.calculateContractValue();
          return;
        }
        const path = "/currency-exchange-rate/" +
          this.contractCurrency +
          "/" +
          this.selectedCurrency +
          "/" +
          currencyDate;
        Utils.start_loading();
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            console.log(res.data)
            let data = res.data.currency_rate[0]
            if(data != null){
              let conversionDate = moment(data.date).format("MM-DD-YYYY");
              let contractDate = moment(this.contractDate).format("MM-DD-YYYY");

              // this.currencyConversionFlag = true;
              this.conversionRate =  data.currency_value;
              this.conversionDate =  data.date;
              if (moment(conversionDate, "MM-DD-YYYY").isBefore(moment(contractDate, "MM-DD-YYYY"))){
                this.backcapConversionDate = data.date;
              } else {
                this.backcapConversionDate = contractDate;
              }
              this.calculateContractFinalPrice();
              this.calculateContractValue();
            }else{
              // this.currencyConversionFlag = false
              this.selectedCurrency = this.contractCurrency;
              this.conversionRate = 1;
              this.conversionDate = this.contractDate;
              this.calculateContractFinalPrice();
              this.calculateContractValue();
            }
            Utils.stop_loading();
          })
          .catch((error) => {
            console.error(error);
            Utils.stop_loading();
          });
      }
    },

    // calculates balance to be fixed
    calculateBalanceToBeFixed(i) {
      let fixedQuantitySum = 0;
      console.log(this.contractPartialFill[i].fixed_quantity)
      console.log(this.contractPartialFill[i].fixed_quantity != '')
      if(this.contractPartialFill[i].fixed_quantity != '') {
        // calculate sum of the available fixed quantity if partial fills are multiple else consider first partial fixed quantity
        // if (this.contractPartialFill.length > 1) {
        //   fixedQuantitySum = this.contractPartialFill.reduce(
        //     (a, b) => a + Number(b.fixed_quantity),
        //     0
        //     );
        // } else {
        //   fixedQuantitySum = this.contractPartialFill[i].fixed_quantity;
        // }
        // let balanceToBeFixed = Number(this.totalQuantity) - Number(fixedQuantitySum);
        let fixedQuantity = this.contractPartialFill[i].fixed_quantity;

        let balanceToBeFixed = Number(this.outstandingContractQuantityStatic) - Number(fixedQuantity);
        this.contractPartialFill[i].balance_to_be_fixed = balanceToBeFixed.toFixed(2);
        let outstandingContractQuantityValue = this.contractPartialFill[i].fixed_quantity
        let finaloutstandingContractQuantityValue = Number(this.outstandingContractQuantityStatic) - Number(outstandingContractQuantityValue);
        this.outstandingContractQuantity = finaloutstandingContractQuantityValue.toFixed(2);
      } else {
        console.log("contract:")
        this.contractPartialFill[i].balance_to_be_fixed = null;
        this.outstandingContractQuantity = null;
      }
    },

    onDetailsChange(){
      if(this.selectedDetails == 'Fixed'){
        Utils.make_alert("success", "The Details under Fixed will no longer be changeable.");
      } else if  (this.selectedDetails == 'Variable'){
        Utils.make_alert("success", "The Details under Variable can be entered only once now/ in future.");
      }
    },

    // this method calculates the final price for a particular partial fill
    calculateFinalPrice(i) {
      console.log(this.contractPartialFill[i].differential_value)
      console.log(typeof this.contractPartialFill[i].differential_value)
      if(this.contractPartialFill[i].price && this.contractPartialFill[i].differential){
        if((Number(this.contractPartialFill[i].price) <= Number(this.contractPartialFill[i].differential))){
          this.contractPartialFill[i].differential_error = true;
          if (this.contractPartialFill[i].currency_rate) {            
            this.contractPartialFill[i].final_price = null;
            this.contractPartialFill[i].partial_contract_value = null;
            // return;
          }
          // return;
        } else {
          this.contractPartialFill[i].differential_error = false;
        }
      }
      let finalPrice
      if (this.contractPartialFill[i].currency_rate) {
        if (this.contractPartialFill[i].differential_value == "0") {
          finalPrice =
            (Number(this.contractPartialFill[i].price) +
            Number(this.contractPartialFill[i].differential) )*
              Number(this.contractPartialFill[i].currency_rate);
        }  else {
          finalPrice =
            (Number(this.contractPartialFill[i].price) -
            Number(this.contractPartialFill[i].differential) )*
              Number(this.contractPartialFill[i].currency_rate)
        }
        console.log("price",Number(this.contractPartialFill[i].price))
        console.log("differential",Number(this.contractPartialFill[i].differential))
        console.log(finalPrice)
        this.contractPartialFill[i].final_price = finalPrice.toFixed(2);
        this.calculatePartialContractValue(i);
      }
    },

    // this method calculates the partial contract value for a particular partial fill
    calculatePartialContractValue(i) {
      if (this.contractPartialFill[i].final_price) {
        let partialContractValue = 
          Number(this.contractPartialFill[i].final_price) *
          Number(this.contractPartialFill[i].fixed_quantity);
        this.contractPartialFill[i].partial_contract_value = partialContractValue.toFixed(2);
      }
    },

    // this method returns backcapping date from fixation date
    fixationBackcapDate() {
      if (this.staticContractPartialFill.length == 0) {
        return new Date(this.contractDate);
      } else {
        // Access the last element in the staticContractPartialFill array
        var lastElementIndex = this.staticContractPartialFill.length - 1;
        return new Date(this.staticContractPartialFill[lastElementIndex].fixation_date);
      }
    },

    //this method will make disabled property as false for Documents Save Info
    onOfftakerContractDocSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.OfftakerContractDocSaveInfoDisabled = false;
      this.OfftakerContractDocAddMoreDisabled = false;
    },

    deleteOfftakerContract(index, id) {
      if (id) {
        this.$confirm({
          auth: false,
          message: "Are you sure you want to permanently delete?",
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              Utils.start_loading();
              axios
                .delete("offtaker-contract-overview/" + this.id, {
                  headers: {
                    "Content-type": "application/json",
                  },
                  data: {
                    type: "Document",
                    id: id,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 10,
                    tabId: 33,
                  },
                })
                .then((response) => {
                  Utils.stop_loading();
                  console.log("delete status: " + response.data.status);
                  if (response.data.status == "success") {
                    Utils.make_alert("success", "Record deleted.");
                    setTimeout(() => this.$router.go(), 1500);
                  } else {
                    Utils.make_alert("success", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.log("error: " + error);
                });
            } else {
              this.$router.go();
            }
          },
        });
      }
      this.OfftakerContractDocFile = this.OfftakerContractDocFile
        ? this.OfftakerContractDocFile
        : "file deleted";
      this.OfftakerContractDocList.splice(index, 1);
      if (
        typeof this.OfftakerContractDocList !== "undefined" &&
        this.OfftakerContractDocList.length == 0
      ) {
        this.OfftakerContractDocAddMoreDisabled = false;
        this.OfftakerContractDocSaveInfoDisabled = true;
      }
    },

    //this method will submit the offtaker form in backend.
    onSubmit() {
      if(this.id == 0){
        this.checkContractNumber();
      }
      console.log("onSubmit");
      console.log("id",this.id);
      this.isSubmitted = true;
      this.$v.$touch();
      // checking for vuelidate validations & email and website validations
      if (this.id > 0) {
        if(this.partialFillFlag == 1) {
          let hasDifferentialError = this.contractPartialFill.some(item => item.differential_error);
          if (hasDifferentialError) {
            console.log("onSubmit 5");
            return;
          }
        }
      }
      if (this.$v.$invalid || this.contractNumberExists) {
        console.log("this.$v.$invalid",this.$v.$invalid )
        console.log("this.$v.$this.contractNumberExists",this.contractNumberExists )
        console.log("onSubmit 1");
        return;
      }
      console.log("onSubmit 2",this.partialFillFlag);
      if (this.contractDate != null) {
        this.contractDate = moment(this.contractDate).format("MM-DD-YYYY");
      }
      if (this.deliveryMonth != null) {
        this.deliveryMonth = moment(this.deliveryMonth).format("MM-DD-YYYY");
      }
      if (this.conversionDate != null) {
        this.conversionDate = moment(this.conversionDate).format("MM-DD-YYYY");
      }
      this.OfftakerContractDocList.map((doc, index) => {
        if (doc.displayFileName === null) {
          this.OfftakerContractDocList.splice(index, 1);
        } else {
          if (doc.IssueDate !== null) {
            doc.IssueDate = moment(doc.IssueDate).format("MM-DD-YYYY");
          }
        }
      });

      if (this.id > 0) {
        if(this.partialFillFlag == 1) {
          this.contractPartialFill.map((doc, index) => {
              if (doc.fixation_date !== null) {
                doc.fixation_date = moment(doc.fixation_date).format("MM-DD-YYYY");
              }
              if (doc.conversion_date !== null) {
                doc.conversion_date = moment(doc.conversion_date).format("MM-DD-YYYY");
              }
          });
        }
      }

      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      if(this.partialFillFlag == 0) {
        formData.append("selectedOfftaker", this.selectedOfftaker);
        formData.append("selectedBorrower", this.selectedBorrower);
        formData.append("selectedCollateral", this.selectedCollateral);
        formData.append("selectedCollateralType", this.selectedCollateralType);
        formData.append("contractNumber", this.contractNumber);
        formData.append("contractDate", this.contractDate);
        formData.append("totalQuantity", this.totalQuantity);
        formData.append("selectedUnit", this.selectedUnit);
        formData.append("deliveryMonth", this.deliveryMonth);
        formData.append("selectedPriceType", this.selectedPriceType);
        formData.append("price", this.price);
        formData.append("selectedCurrency", this.selectedCurrency);
        formData.append("conversionDate", this.conversionDate);
        formData.append("conversionRate", this.conversionRate);
        formData.append("finalPrice", this.finalPrice);
        formData.append("contractValue", this.contractValue);
        formData.append("paymentTerms", this.paymentTerms);
        formData.append("agent", this.agent);
        formData.append("quality", this.quality);
        formData.append("terms", this.terms);
        formData.append("arbitration", this.arbitration);
        formData.append("comments", this.comments);
        formData.append("selectedDetails", this.selectedDetails);
        formData.append(
          "OfftakerContractDocuments",
          JSON.stringify(this.OfftakerContractDocList)
        );
        if (this.id > 0) {
            formData.append("offtaker_contract_tracker_id",this.id);
          }
      }
      if (this.id > 0) {
        if(this.partialFillFlag == 1) {
        formData.append("contractPartialFill",JSON.stringify(this.contractPartialFill));
        formData.append("outstandingContractQuantity",this.outstandingContractQuantity);
        }
      }
      formData.append("clientId", this.$store.state.client_id);
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 12);
      formData.append("tabId", 33);

      const path =
      this.id > 0
        ? this.partialFillFlag == 1
          ? "add-partial-fill/" + this.id
          : "edit-offtaker-contract"
        : "add-contract";

      // const path = this.id > 0 ? "add-partial-fill/" + this.id : "add-contract";
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          if (response.data.status == "success") {
            Utils.make_alert(
              "success",
              this.id > 0
              ? this.partialFillFlag == 1
                ? "New Partial Fill added successfully!"
                : "Offtaker Contract edited successfully!"
              : "Offtaker Contract added successfully!"
            );
            console.log("SUCCESS!!");
            this.$router.push("/offtaker");
          } else {
            Utils.make_alert("warning", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch(function (error) {
          Utils.stop_loading();
          console.error(error);
        });
      // }
    }, //onSubmit() close

    //this method will add new line for additional offtaker docs
    addOfftakerContractDoc() {
      //when new line is added then OfftakerContractDocFile variable and OfftakerContractDocSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.OfftakerContractDocFile = null),
        (this.OfftakerContractDocSaveInfoDisabled = true),
        (this.OfftakerContractDocAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.OfftakerContractDocList.push({
          id: null,
          IssueDate: null,
          comments: null,
          displayFileName: null,
          errors: "",
          OfftakerRemoveDisabled: true,
        });
    }, //addOfftakerContractDoc() close

    addOfftakerContractPartialFill() {
      this.contractPartialFill.push({
        id: null,
        fixation_date: null,
        fixed_quantity: null,
        balance_to_be_fixed: null,
        month: null,
        price: null,
        differential_value: 1,
        differential: null,
        differential_error: false,
        base_currency: null,
        currency: this.selectedCurrency,
        conversion_date: null,
        currency_rate: null,
        final_price: null,
        partial_contract_value: null,
      });
    },

    //handles a change on the file upload
    handleFileUploadOfftaker(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.OfftakerContractDocFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.OfftakerContractDocFile.size > 104857600) {
          e.preventDefault();
          self.OfftakerContractDocList[id].errors =
            "File size should be less than 100MB";
          self.OfftakerContractDocList[id].selectedFile = null;
          self.OfftakerContractDocList[id].selectedFileName = null;
          self.OfftakerContractDocList[id].displayFileName = null;
          self.OfftakerContractDocList[id].OfftakerRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.OfftakerContractDocFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.OfftakerContractDocList[id].selectedFile = reader.result;
          self.OfftakerContractDocList[id].selectedFileName =
            e.target.files[0].name;
          self.OfftakerContractDocList[id].displayFileName =
            e.target.files[0].name; //to show filename in file input
          self.OfftakerContractDocList[id].errors = "";
          self.OfftakerContractDocList[id].OfftakerRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadOfftaker() close

    //this function is called to get Conversion Rates from given inputs
    getCurrencyRate(i) {
      if (
        this.contractPartialFill[i].base_currency &&
        this.contractPartialFill[i].currency &&
        this.contractPartialFill[i].fixation_date
      ) {
        let fixationDate = moment(
          this.contractPartialFill[i].fixation_date
        ).format("MM-DD-YYYY");
        if (this.contractPartialFill[i].base_currency == this.contractPartialFill[i].currency) {
          this.contractPartialFill[i].currency_rate = 1;
          this.contractPartialFill[i].conversion_date = fixationDate;
          this.calculateFinalPrice(i);
          return;
        }
        const path = "/currency-exchange-rate/" +
          this.contractPartialFill[i].currency +
          "/" +
          this.contractPartialFill[i].base_currency +
          "/" +
          fixationDate;
        Utils.start_loading();
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            Utils.stop_loading();
            let data = res.data.currency_rate[0]
            if(data == null){
              this.contractPartialFill[i].currency_rate = null;
              this.contractPartialFill[i].conversion_date = null;
            } else {
              this.contractPartialFill[i].currency_rate = data.currency_value;
              this.contractPartialFill[i].conversion_date = data.date;
            }
            // this.contractPartialFill[i].currency_rate = res.data.currency_rate;
            this.calculateFinalPrice(i);

          })
          .catch((error) => {
            Utils.stop_loading();
            console.error(error);
          });
      }
    },

    //this function is called to get list of price types
    getPriceTypesByCollateral() {
      const path = "/get-price-type-by-collateral/" + this.selectedCollateral;
      this.price= null;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.priceTypes = res.data.price_types;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get price by price type and collateral id
    getPrice(parameter) {
      if (this.selectedPriceType == null){
        this.price = null;
        this.contractCurrency = null;
        this.selectedCurrency = null;
        this.conversionDate = null;
        this.finalPrice = null;
        this.contractValue = null;
        this.selectedPriceTypeName = null;
        if(parameter == 'price'){
          this.priceSpecialFlag = true;
        }
        console.log("getPrice")
        return;
      }
      let price = this.priceTypes.find(p => p.id === this.selectedPriceType);
      this.selectedPriceTypeName = price.price_name;
      this.contractCurrency = price.currency_id;
      this.selectedCurrency = price.currency_id;
      if(this.selectedCollateral && this.selectedPriceType){
        if (this.selectedPriceTypeName !== "Other Price (Input)") {
          this.priceSpecialFlag = true;
          this.price = null;
          this.conversionRate = null;
          this.conversionDate = null;
          this.finalPrice = null;
          this.contractValue = null;
          if(this.contractDate){
            let contract_date = moment(this.contractDate).format("MM-DD-YYYY");
            const path =
              "/get-price-for-contract/" +
              this.selectedPriceType +
              "/" +
              this.selectedCollateral +
              "/" +
              contract_date;
              Utils.start_loading();
              axios
              .get(path)
              .then((res) => {
                Utils.stop_loading();
                this.price = res.data.price[0].price;
                this.conversionDate = res.data.price[0].date;
                this.conversionRate = 1;
                this.calculateContractFinalPrice();
                this.calculateContractValue();
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error(error);
              });
          }
      } else {
          this.price = null;
          this.conversionRate = null;
          this.conversionDate = null;
          this.finalPrice = null;
          this.contractValue = null;
          this.priceSpecialFlag = false;
        }
      }
    },

    getCollateral() {
      //this function is called to get list of Collateral
      const path = "collateral";
      axios
        .get(path)
        .then((res) => {
          this.collaterals = res.data.collateral;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getUnitTypes() {
      //this function is called to get list of unit types
      const path = "unit_types";
      axios
        .get(path)
        .then((res) => {
          this.unitTypes = res.data.UnitTypes;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of currencies
    getCurrencies() {
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function will be called to get list of offtakers
    getOfftakers() {
      const path = "offtaker-summary";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.offtakers = res.data.offtaker_active_list;
          this.offtakers = this.offtakers.filter(
            (off) => off.status === "Approved"
          );
          if (res.data.status == "success") {
            console.log("get offtakers is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    //this function will be called to get list of offtaker contract numbers
    getOfftakerContracts() {
      const path = "contract-tracker-summary/null/null/null/null/null/0";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.contracts = res.data.contract_tracker_summary;
          if (res.data.status == "success") {
            console.log("get offtaker contracts is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    //this function will be called to get details of a particular offtaker contract
    getOfftakerContract() {
      const path = "offtaker-contract-overview/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          let contract_details =
            res.data.offtaker_contract.offtaker_contract_details[0];
          this.selectedOfftaker = contract_details.offtaker_id;
          this.selectedBorrower = contract_details.borrower_id;
          this.selectedCollateral = contract_details.collateral_id;
          this.getPriceTypesByCollateral();
          // this.selectedCollateralType = contract_details.collateral_stage_id;
          this.contractNumber = contract_details.contract_number;
          this.contractDate = contract_details.contract_date;
          this.getBackcapDate();
          if (contract_details.total_quantity)
          this.totalQuantity = contract_details.total_quantity.replaceAll(",","");
          this.selectedUnit = contract_details.unit;
          if(contract_details.delivery_month) {
            this.deliveryMonth = contract_details.delivery_month;
            this.deliveryMonthFlag = true;
          }
          if(contract_details.price_type) {
            this.selectedPriceType = contract_details.price_type;
            this.selectedPriceTypeFlag = true;
          }
          if(contract_details.price_name) {
            this.selectedPriceTypeName = contract_details.price_name;
            if (this.selectedPriceTypeName == "Other Price (Input)") {
              this.priceSpecialFlag = false;
            } else {
              this.priceSpecialFlag = true;
            }
          }
          if (contract_details.price) {
            this.price = contract_details.price.replaceAll(",", "");
            this.priceFlag = true;
          }
          if (contract_details.currency_id) {
            this.selectedCurrency = contract_details.currency_id
            console.log(this.selectedCurrency)
          }
          if (contract_details.conversion_date) {
            this.conversionDate = contract_details.conversion_date.replaceAll(",","")
          }
          if (contract_details.conversion_rate) {
            this.conversionRate = contract_details.conversion_rate.replaceAll(",","");
            this.conversionRateFlag = true;
          }
          if (contract_details.final_price) {
            this.finalPrice = contract_details.final_price.replaceAll(",", "");
            this.finalPriceFlag = true;
          }
          if (contract_details.contract_value) {
            this.contractValue = contract_details.contract_value.replaceAll(",","");
            this.contractValueFlag = true;
          }
          if (contract_details.payment_terms) {
            this.paymentTerms = contract_details.payment_terms;
            this.paymentTermsFlag = true;
          }
          if (contract_details.agent) {
            this.agent = contract_details.agent;
            this.agentFlag = true;
          }
          if (contract_details.quality) {
            this.quality = contract_details.quality;
            this.qualityFlag = true;
          }
          if (contract_details.terms) {
            this.terms = contract_details.terms;
            this.termsFlag = true;
          }
          if (contract_details.arbitration) {
            this.arbitration = contract_details.arbitration;
            this.arbitrationFlag = true;
          }
          if (contract_details.comments) {
            this.comments = contract_details.comments;
            this.commentsFlag = true;
          }
          this.selectedDetails = contract_details.details;
          
          if (contract_details.outstanding_contract_quantity) {
            this.outstandingContractQuantityStatic = contract_details.outstanding_contract_quantity.replaceAll(",", "");
          }

          this.OfftakerContractDocList =
            res.data.offtaker_contract.offtaker_contract_documents_details;
          if (this.OfftakerContractDocList.length) {
            this.OfftakerContractDocFile = "file";
            this.OfftakerContractDocAddMoreDisabled = false;
          } else {
            this.OfftakerContractDocAddMoreDisabled = false;
          }
          if (this.partialFillFlag == 1) {
            this.staticContractPartialFill =  
              res.data.offtaker_contract.offtaker_contract_partial_fill_details;
            if (this.staticContractPartialFill.length) {
              this.staticContractPartialFill.map((con) => {
                con.fixed_quantity = con.fixed_quantity.replaceAll(",", "");
                con.balance_to_be_fixed = con.balance_to_be_fixed.replaceAll(",", "");
                con.price = con.price.replaceAll(",", "");
                con.final_price = con.final_price.replaceAll(",", "");
                con.differential = con.differential.replaceAll(",", "");
                con.currency_rate = con.currency_rate.replaceAll(",", "");
                con.partial_contract_value =
                  con.partial_contract_value.replaceAll(",", "");
              });
            this.latestPartialFill = this.staticContractPartialFill.length
            }
            this.addOfftakerContractPartialFill();
          }
          if (res.data.status == "success") {
            console.log("get offtaker contracts is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    //this function is called to get list of collateral stages
    getCollateralStages() {
      const path = "collateral-stage-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.collateralStages = res.data.collateral_stages;
          this.collateralStages = this.collateralStages.slice(0, 4);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function will be called to get list of borrowers
    getBorrowers() {
      const path = "borrower";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.borrowers = res.data.borrowers_active;
          //get Approved borrowers
          if (res.data.borrowers_active) {
            this.borrowers = this.borrowers.filter((bor) => {
              if (bor.status === "Approved") {
                return bor;
              }
            });
          }
          if (res.data.status == "success") {
            console.log("get borrowers is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },
  }, //main function close

  mounted() {
    if (this.id > 0) {
      this.getOfftakerContract();
    }
    this.addOfftakerContractDoc();
  },
  created() {
    this.id = this.$route.params.id;
    this.partialFillFlag = this.$route.params.partialFillFlag;
    this.getOfftakers();
    this.getOfftakerContracts();
    this.getBorrowers();
    this.getCollateral();
    this.getUnitTypes();
    this.getCurrencies();
    // this.getCollateralStages();
  },
};
</script>

<style scoped>
.error {
  color: red;
  font-size: 13px;
  padding-left: 40px;
}
.contact:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -75px;
}

.vdp-datepicker >>> .form-control[disabled] {
  background-color:#EEEEEE !important;
  opacity: 0.7;
  pointer-events: none !important;
  cursor: not-allowed !important;
}
.toggle-padding {
  margin-left: 5px;
}
</style>

