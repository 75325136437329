<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-file-text"></i>
                        {{ this.expenseName }}
                        <label class="sub-heading"></label>Currency:
                        {{ currency }}
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div v-if="type === 'expense'" class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div
                  class="card-body table-responsive p-0"
                  style="padding: 10px 0px !important"
                >
                  <table
                    class="table table-head-fixed text-nowrap table-hover expenseTable"
                    id="expenseTable"
                  >
                    <thead>
                      <tr>
                        <th>Expense ID</th>
                        <th>Due Date</th>
                        <th class="right">Amount</th>
                        <th class="right">Outstanding Amount</th>
                        <th class="right">Paid Amount</th>
                        <th style="padding-left: 30px">Expense Type Name</th>
                        <th>Created Date</th>
                        <template v-if="!inActive">
                          <th>Edit</th>
                        </template>
                        <th>Docs Uploaded</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="exp in expenseDetails" :key="exp.id">
                        <td v-if="exp.id !== null">
                          {{ exp.id }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="exp.instalment_date !== null">
                          {{ exp.instalment_date }}
                        </td>
                        <td v-else>-</td>
                        <td class="right" v-if="exp.expenses !== null">
                          {{ exp.expenses | numeral("0,0.00") }}
                        </td>
                        <td v-else>-</td>
                        <td
                          class="right"
                          v-if="exp.outstanding_amount !== null"
                        >
                          {{ exp.outstanding_amount }}
                        </td>
                        <td v-else>-</td>
                        <td class="right" v-if="exp.paid_amount !== null">
                          {{ exp.paid_amount }}
                        </td>
                        <td v-else>-</td>
                        <td
                          style="padding-left: 30px"
                          v-if="exp.expense_type_name !== null"
                        >
                          {{ exp.expense_type_name }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="exp.created_date !== null">
                          {{ exp.created_date }}
                        </td>
                        <td v-else>-</td>
                        <template v-if="!inActive">
                          <td>
                            <router-link
                              style="text-decoration: none"
                              :to="{
                                name: 'AddExpensePayment',
                                params: {
                                  fundID: fund_id,
                                  type: type,
                                  id: exp.id,
                                },
                              }"
                            >
                              <i
                                class="icofont-ui-edit text-warning"
                              ></i>
                            </router-link>
                          </td>
                        </template>
                        <td
                          @click="showExpensePaymentDocumentModal(exp.id)"
                        >
                          <i class="icofont-file-pdf text-danger"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="type === 'payment'" class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div
                  class="card-body table-responsive p-0"
                  style="padding: 10px 0px !important"
                >
                  <table
                    class="table table-head-fixed text-nowrap table-hover paymentTable"
                    id="paymentTable"
                  >
                    <thead>
                      <tr>
                        <th>Payment ID</th>
                        <th>Payment Date</th>
                        <th>Description</th>
                        <th class="right">Amount</th>
                        <template v-if="!inActive">
                          <th>Edit</th>
                        </template>
                        <th>Docs Uploaded</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="fundexp in expenseDetails" :key="fundexp.id">
                        <td v-if="fundexp.id !== null">
                          {{ fundexp.id }}
                        </td>
                        <td v-if="fundexp.payment_date !== null">
                          {{ fundexp.payment_date }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="fundexp.description !== null">
                          {{ fundexp.description }}
                        </td>
                        <td v-else>-</td>
                        <td class="right" v-if="fundexp.amount !== null">
                          {{ fundexp.amount | numeral("0,0.00") }}
                        </td>
                        <td v-else>-</td>
                        <template v-if="!inActive">
                          <td>
                            <router-link
                              style="text-decoration: none"
                              :to="{
                                name: 'AddExpensePayment',
                                params: {
                                  fundID: fund_id,
                                  type: type,
                                  id: fundexp.id,
                                },
                              }"
                            >
                              <i
                                class="icofont-ui-edit text-warning"
                              ></i>
                            </router-link>
                          </td>
                        </template>
                        <td
                          @click="showExpensePaymentDocumentModal(fundexp.id)"
                        >
                          <i class="icofont-file-pdf text-danger"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
    <div>
      <custom-modal-no-footer ref="customModalNoFooter">
        <!-- Provide content for the header slot -->
        <template v-slot:header>
          <div class="row">
            <div class="col-md-10">
              <h5><i class="icofont-eye-alt"></i> View Document</h5>
            </div>
            <div class="col-md-2">
              <button
              type="button"
              class="close"
              @click="$refs.customModalNoFooter.close();"
            >
              <i class="icofont-close-circled"></i>
            </button>
            </div>
          </div>
        </template>

          <div class="row">
            <div class="col-md-12">
              <div class="card-body p-0">
                <!------menton here style="height: 300px;"-------->
                <table class="table">
                  <tbody>
                      <tr v-if="!ExpensePaymentDocuments.length">
                        <center><h5>No Documents uploaded</h5></center>
                      </tr>
                      <tr v-for="doc in ExpensePaymentDocuments" :key="doc.id">
                        <template>
                          <td class="col-10">{{ doc.displayFileName }}</td>
                          <td
                            class="c col-1"
                            @click="downloadDocument(doc.document_path)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-download text-info"></i>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
          </div>
      </custom-modal-no-footer>
    </div>
  </div>
  <!-- /.wrapper -->
</template>

<script>
import $ from "jquery";
// //Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import CustomModalNoFooter from '../logistics/CustomModalNoFooter';

import { required } from "vuelidate/lib/validators";

export default {
  //props: ['fund_id'],
  data() {
    return {
      expense_id: null,
      expenseType: null,
      fund_id: null,
      fund_inception_date: null,
      expenseDetails: [],
      totalAmountSum: "",
      expenseName: null,
      ex_type_id: null,
      editingTransId: null,
      amount: null,
      paymentDate: null,
      dueDate: null,
      editFundId: null,
      description: null,
      currencyId: null,
      currency: null,
      start_date: null,
      interest_frequency_name: null,
      currency_short_name: null,
      ExpensePaymentDocuments:[],
      ExpensePaymentDocumentsLength:null,
      isSubmitted: null,
      inActive: false,
    };
  },
  components: {
    Datepicker,
    CustomModalNoFooter,
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },

  methods: {
    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },

    showExpensePaymentDocumentModal(id) {
      Utils.start_loading();
      //this function is used to get warrants for selected container.
      const path = "get-single-fund-expense-payment-operation" + 
      "/" + 
      this.fund_id +
      "/" + 
      this.type +
      "/" + 
      id;
      
      axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
      .then((res) => {
        Utils.stop_loading();
        if (res.data.status == "success") {
          let ExpensePaymentDocuments = [];
          if(this.type === 'expense'){
            ExpensePaymentDocuments = res.data.expense_payment.expense_payment_document;
          }
          else if(this.type === 'payment'){
            ExpensePaymentDocuments = res.data.expense_payment.payment_document;
          }
            this.ExpensePaymentDocumentsLength = ExpensePaymentDocuments.length;
            if(this.ExpensePaymentDocumentsLength){
              this.ExpensePaymentDocuments = [];
              ExpensePaymentDocuments.map((doc) => {
                this.ExpensePaymentDocuments.push({
                  id: doc.id,
                  displayFileName: doc.displayFileName,
                  comments: doc.comments,
                  document_path: doc.document_path,
                });
              });
            } else {
              this.ExpensePaymentDocuments = [];
            }
            console.log("get doc details is successful.",this.ExpensePaymentDocuments);
            this.$refs.customModalNoFooter.open();
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/fund");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
        });
    },

    getExpenseDetails() {
      Utils.start_loading();
      //this function get details of expense transaction for given fund_id and ex_master_id. ex_master_id is expense_master id as we need to pass expense_id in expense_transaction table to filter records for that particular expense_id and fund_id
      const path = this.inActive
        ? "fund-selected-expense-inactive-transaction/" +
          this.fund_id +
          "/" +
          this.expense_id +
          "/" +
          this.type
        : "fund-selected-expense-transaction/" +
          this.fund_id +
          "/" +
          this.expense_id +
          "/" +
          this.type;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          this.expenseDetails = this.inActive
            ? res.data.selected_expense_details_inactive
            : res.data.selected_expense_details;
          if (this.type === "expense") {
            if(this.expenseDetails.length > 0) {
                this.expenseDetails.forEach(item => {
                  if (item.instalment_date != '-' && item.instalment_date != null) {
                    item.instalment_date = moment(item.instalment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.instalment_date = '-';
                  }
                  if (item.created_date != '-' && item.created_date != null) {
                    // Convert created_date (only the date part)
                    if (item.created_date && item.created_date.includes(' ')) {
                      let parts = item.created_date.split(' ');
                          let datePart = parts[0];
                          let timePart = parts[1];
                          let formattedDatePart = moment(datePart, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                          item.created_date = formattedDatePart + ' ' + timePart;
                        }  
                    } else {
                      item.created_date = '-';
                    }
                });
              }
            setTimeout(
              () =>
                $("#expenseTable").DataTable({
                  aaSorting: [],
                  columnDefs: this.inActive
                  ? []
                  : [
                    {
                      orderable: false,
                      targets: [7,8],
                    },
                    ],
                }),
              100
            );
          } else {
            if(this.expenseDetails.length > 0) {
                this.expenseDetails.forEach(item => {
                  if (item.payment_date != '-' && item.payment_date != null) {
                    item.payment_date = moment(item.payment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.payment_date = '-';
                  }
                });
              }
            setTimeout(
              () =>
                $("#paymentTable").DataTable({
                  aaSorting: [],
                  columnDefs: this.inActive
                  ? []
                  : [
                    {
                      orderable: false,
                      targets: [4,5],
                    },
                    ],
                }),
              100
            );
          }

          this.currency = this.expenseDetails[0].currency_short_name;
          this.expenseName = this.inActive
            ? res.data.expense_name_inactive
            : res.data.expense_name;
          if (res.data.status == "success") {
            console.log("get fund selected expense details is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.log("error: " + error);
        });
    }, //getExpenseDetails() close
  }, //main method close

  created() {
    this.expense_id = this.$route.params.expense_id;
    this.fund_inception_date = this.$route.params.inception_date;
    this.type = this.$route.params.type; //this indicates 'payment' or 'expense'
    this.inActive = this.$route.params.inactive;
    this.fund_id = this.$route.params.fund_id;
    console.log("expense_id",this.expense_id)
    console.log("type",this.type)
    console.log("fund_id",this.fund_id)
    this.getExpenseDetails();
  },
};
</script>

<style scoped>
.sub-heading {
  margin-left: 50px;
}
</style>
