<!-- This is Investor page. It shows list of all the Investors and their details -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="container-fluid tabtop" id="al_tb">
        <div class="tp_mid">
          <div class="row">
            <div class="col-lg-12">
              <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                <li
                  class="nav-item"
                  v-if="
                    loggedInUserGroupId == 'Investor' &&
                    loggedInUserSubGroupId !== 0
                  "
                >
                  <a
                    class="nav-link active"
                    id="custom-tabs-one-home-tab"
                    data-toggle="pill"
                    href="#one"
                    role="tab"
                    aria-controls="custom-tabs-one-home"
                    aria-selected="true"
                    >Profile</a
                  >
                </li>
                <li class="nav-item" v-else>
                  <a
                    class="nav-link active"
                    id="custom-tabs-one-home-tab"
                    data-toggle="pill"
                    href="#one"
                    role="tab"
                    aria-controls="custom-tabs-one-home"
                    aria-selected="true"
                    >Investor</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    loggedInUserGroupId == 'Admin' ||
                    (loggedInUserGroupId == 'Analyst' &&
                      loggedInUserSubGroupId == 0)
                  "
                >
                  <a
                    class="nav-link"
                    id="custom-tabs-one-profile-tab"
                    data-toggle="pill"
                    href="#two"
                    role="tab"
                    aria-controls="custom-tabs-one-profile"
                    aria-selected="false"
                    >Investor Activity</a
                  >
                </li>
              </ul>
            </div>
            <!-- /.col -->
          </div>
        </div>
      </div>

      <div class="tab-content" id="custom-tabs-one-tabContent">
        <div
          class="tab-pane fade show active"
          id="one"
          role="tabpanel"
          aria-labelledby="kyc1"
        >
          <!-- <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 col-sm-5 col-6">
                <div class="form-group fc">
                  <div class="form-field">
                    <label style="margin-right: 10px" class="exampleInputEmail1"
                      >Filter By:</label
                    >
                    <input type="radio" v-model="filter" value="date" />
                    <label style="margin: 5px; padding-right: 10px">
                      Date
                    </label>

                    <input type="radio" v-model="filter" value="period" />
                    <label style="margin: 5px; padding-right: 10px">
                      Period
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div
                        class="col-lg-6 col-sm-6"
                        v-if="loggedInUserGroupId == 'Investor'"
                      >
                        <h1 class="headingt">
                          <i class="icofont-coins"></i> Profile
                        </h1>
                      </div>
                      <div class="col-lg-6 col-sm-6" v-else>
                        <h1 class="headingt">
                          <i class="icofont-coins"></i> Investor
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div
                        class="col-lg-6 col-sm-6"
                        v-if="
                          loggedInUserGroupId == 'Admin' ||
                          (loggedInUserGroupId == 'Analyst' &&
                            loggedInUserSubGroupId == 0)
                        "
                      >
                        <ul id="sr_btn">
                          <!-- <li v-if="loggedInUserGroupId == 'Admin'">
                            <input
                              type="checkbox"
                              id="checkbox"
                              v-model="inActiveToggle"
                              @change="destroyTable"
                            />
                            <label for="checkbox">InActive&nbsp;&nbsp;</label>
                          </li> -->
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="$router.push('/add-investor')"
                            >
                              <i class="icofont-plus"></i>
                              <span class="mob">Add Investor</span>
                            </button>
                          </li>
                          <!-- <li>
                            <div class="form-group has-search m-0">
                              <span
                                class="fa fa-search form-control-feedback"
                              ></span>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Search"
                              />
                            </div>
                          </li>-->
                        </ul>
                      </div>
                      <div
                        class="col-lg-6 col-sm-6"
                        v-if="loggedInUserGroupId == 'Admin'"
                      >
                        <input
                          id="active"
                          type="radio"
                          :value="false"
                          @change="destroyTable"
                          v-model="inActiveToggle"
                        />
                        <label for="active" style="padding-right: 20px"
                          >Active</label
                        >
                        <input
                          id="inactive"
                          type="radio"
                          :value="true"
                          @change="destroyTable"
                          v-model="inActiveToggle"
                        />
                        <label for="inactive">InActive</label>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div
                    class="card-body table-responsive p-0"
                    id="datatable-padding"
                  >
                    <!------menton here style="height: 300px;"-------->
                    <table
                      class="table table-head-fixed text-nowrap table-hover myTable1"
                      id="myTable1"
                    >
                      <thead>
                        <tr>
                          <!--<th>Reg. No.</th>-->
                          <th v-if="loggedInUserGroupId == 'Investor'">Name</th>
                          <th v-else>Investor Name</th>
                          <!--<th >Investor Type</th>-->
                          <!--<th >Investment Category</th>-->
                          <th>KYC Status</th>
                          <th>Reg. Status</th>
                          <template
                            v-if="
                              !inActiveToggle &&
                              loggedInUserGroupId == 'Admin' &&
                              loggedInUserSubGroupId == 0
                            "
                          >
                            <th class="c">Change Reg. Status</th>
                          </template>
                          <th
                            v-if="
                              !inActiveToggle &&
                              (loggedInUserGroupId == 'Analyst' ||
                                loggedInUserGroupId == 'Admin')
                            "
                            class="c center"
                          >
                            Add Investment
                          </th>
                          <th v-if="!inActiveToggle" class="c center">
                            Report
                          </th>
                          <template
                            v-if="
                              !inActiveToggle &&
                              (loggedInUserGroupId == 'Investor' ||
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0))
                            "
                          >
                            <th class="c center"></th>
                          </template>
                          <th
                            v-if="
                              !inActiveToggle && loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0)
                            "
                            class="c"
                          ></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(investor, index) in investors"
                          :key="investor.id"
                        >
                          <template
                            v-if="
                              loggedInUserGroupId == 'Investor' ||
                              loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0)
                            "
                          >
                            <!--<td>{{ investor.investor_id }}</td>-->
                            <td class="ln">
                              <router-link
                                active-link="active"
                                :to="{
                                  name: 'InvestorOverview',
                                  params: {
                                    id: investor.investor_id,
                                    status: investor.registration_status,
                                    inactive: inActiveToggle,
                                  },
                                }"
                                >{{ investor.investor_name }}</router-link
                              >
                            </td>
                            <!--<td>{{ investor.investor_type }}</td>-->
                            <!--<td>{{ investor.Investment_Category }}</td>-->
                            <td
                              :class="{
                                'c text-success':
                                  investor.kyc_status === 'Approved',
                                'c text-warning':
                                  investor.kyc_status === 'Received',
                                'c text-danger':
                                  investor.kyc_status === 'Rejected',
                              }"
                            >
                              {{ investor.kyc_status }}
                            </td>
                            <td
                              :class="{
                                'c text-success':
                                  investor.registration_status === 'Approved',
                                'c text-warning':
                                  investor.registration_status === 'Pending',
                                'c text-danger':
                                  investor.registration_status === 'Rejected',
                              }"
                            >
                              {{ investor.registration_status }}
                            </td>
                            <td
                              class="c"
                              v-if="
                                !inActiveToggle &&
                                loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0
                              "
                            >
                              <div class="status">
                                <select
                                  id="regStatus"
                                  name="regStatus"
                                  @change="
                                    onChangeStatus(
                                      $event,
                                      investor.investor_id,
                                      investor.registration_status,
                                      index
                                    )
                                  "
                                  class="form-control"
                                  :value="investor.registration_status"
                                  :disabled="
                                    investor.registration_status !== 'Pending'
                                  "
                                >
                                  <option value="Pending">Pending</option>
                                  <option value="Approved">Approved</option>
                                  <option value="Rejected">Rejected</option>
                                </select>
                              </div>
                            </td>
                            <td
                              v-if="
                                !inActiveToggle &&
                                (loggedInUserGroupId == 'Analyst' ||
                                  loggedInUserGroupId == 'Admin')
                              "
                              class="c text-danger center"
                            >
                              <router-link
                                v-if="
                                  investor.registration_status === 'Approved' &&
                                  (loggedInUserGroupId == 'Analyst' ||
                                    (loggedInUserGroupId == 'Admin' &&
                                      loggedInUserSubGroupId == 0))
                                "
                                style="text-decoration: none"
                                :to="{
                                  name: 'AddInvestment',
                                  params: {
                                    props: {
                                      id: investor.investor_id,
                                      InvRegDate: investor.registration_date,
                                      inactive: inActiveToggle,
                                    },
                                  },
                                }"
                              >
                                <i class="icofont-plus text-success"></i>
                              </router-link>
                            </td>
                            <td
                              v-if="!inActiveToggle"
                              class="c center"
                              v-on:click="showReportModal(investor.investor_id)"
                              :style="{ cursor: 'pointer' }"
                            >
                              <i class="icofont-download text-primary"></i>
                            </td>

                            <td
                              class="c center"
                              v-if="
                                !inActiveToggle &&
                                (loggedInUserGroupId == 'Investor' ||
                                  loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                    loggedInUserSubGroupId == 0))
                              "
                            >
                              <router-link
                                style="text-decoration: none"
                                :to="{
                                  name: 'EditInvestor',
                                  params: {
                                    id: investor.investor_id,
                                    inactive: inActiveToggle,
                                  },
                                }"
                              >
                                <i class="icofont-ui-edit text-warning"></i>
                              </router-link>
                            </td>
                            <td
                              class="c"
                              v-if="
                                !inActiveToggle &&
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                              "
                              v-on:click="deleteInvestor(investor.investor_id)"
                              :style="{ cursor: 'pointer' }"
                            >
                              <i class="icofont-trash text-danger"></i>
                            </td>
                          </template>
                          <template
                            v-else-if="
                              loggedInUserGroupId == 'Investor' &&
                              loggedInUserSubGroupId == investor.investor_id
                            "
                          >
                            <td class="ln">
                              <router-link
                                :to="{
                                  name: 'InvestorOverview',
                                  params: { id: investor.investor_id },
                                }"
                                replace
                                >{{ investor.investor_name }}</router-link
                              >
                            </td>
                            <!--<td>{{ investor.investor_type }}</td>-->
                            <!--<td>{{ investor.Investment_Category }}</td>-->
                            <td
                              :class="{
                                'c text-success':
                                  investor.kyc_status === 'Approved',
                                'c text-warning':
                                  investor.kyc_status === 'Received',
                                'c text-danger':
                                  investor.kyc_status === 'Rejected',
                              }"
                            >
                              {{ investor.kyc_status }}
                            </td>
                            <td
                              :class="{
                                'c text-success':
                                  investor.registration_status === 'Approved',
                                'c text-warning':
                                  investor.registration_status === 'Pending',
                                'c text-danger':
                                  investor.registration_status === 'Rejected',
                              }"
                            >
                              {{ investor.registration_status }}
                            </td>
                            <!-- <td class="c">
                                <div>
                                  <select
                                    id="regStatus"
                                    name="regStatus"
                                    @change="
                                  onChangeStatus($event, investor.investor_id)
                                "
                                    class="form-control"
                                    v-model="investor.registration_status"
                                  >
                                    <option :value="null"></option>
                                    <option value="Pending">Pending</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                  </select>
                                </div>
                            </td>-->
                            <td
                              v-if="
                                loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)
                              "
                              class="c text-danger"
                            >
                              <router-link
                                :to="{
                                  name: 'AddInvestment',
                                  params: {
                                    id: investor.investor_id,
                                    InvRegDate: investor.registration_date,
                                  },
                                }"
                              >
                                <i class="icofont-plus text-success"></i>
                              </router-link>
                            </td>
                            <td
                              class="c"
                              v-on:click="showReportModal(investor.investor_id)"
                              :style="{ cursor: 'pointer' }"
                            >
                              <i class="icofont-download text-primary"></i>
                            </td>

                            <td class="c">
                              <router-link
                                :to="{
                                  name: 'EditInvestor',
                                  params: { id: investor.investor_id },
                                }"
                              >
                                <i class="icofont-ui-edit text-warning"></i>
                              </router-link>
                            </td>
                            <!-- <td
                                class="c"
                                v-on:click="deleteInvestor(investor.investor_id)"
                                :style="{ cursor: 'pointer' }"
                              >
                                <i class="icofont-trash text-danger"></i>
                            </td>-->
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>
        <!-- /.Tabpane first end-->

        <div
          class="tab-pane fade"
          id="two"
          role="tabpanel"
          aria-labelledby="covenant1"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 col-sm-5 col-6">
                <div class="form-group fc">
                  <div class="form-field">
                    <label style="margin-right: 10px" class="exampleInputEmail1"
                      >Filter By:</label
                    >
                    <input type="radio" v-model="filter" value="date" />
                    <label style="margin: 5px; padding-right: 10px">Date</label>

                    <input type="radio" v-model="filter" value="period" />
                    <label style="margin: 5px; padding-right: 10px"
                      >Period</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-2 col-sm-4 col-6">
                <div class="form-group fc">
                  <label>Fund Name</label>
                  <select
                    id="selectedFund"
                    class="form-control"
                    name="selectedFund"
                    v-model="selectedFund"
                    v-on:change="eventChangeFund()"
                  >
                    <option :value="null">-- Select --</option>
                    <option v-for="fund in funds" :key="fund.fund_id" :value="fund.fund_id">
                      {{ fund.fund_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2 col-sm-4 col-6">
                <div class="form-group fc">
                  <label>Investment Category</label>
                  <select
                    id="selectedInvestmentCategory"
                    class="form-control"
                    name="selectedInvestmentCategory"
                    v-model="selectedInvestmentCategory"
                    v-on:change="eventChangeInvestmentCategory()"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="category in investmentCategory"
                      :key="category.id"
                      :value="category.id"
                    >
                      {{ category.investment_category_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2 col-sm-4 col-6">
                <div class="form-group fc">
                  <label>Investor Name</label>
                  <select
                    id="selectedInvestorName"
                    class="form-control"
                    name="selectedInvestorName"
                    v-model="selectedInvestorName"
                    v-on:change="eventChangeInvestorName()"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="investor in investorMaster"
                      :key="investor.id"
                      :value="investor.id"
                    >
                      {{ investor.investor_name }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- <div class="col-md-6 col-sm-5 col-6">
                <div class="form-group fc">
                  <label class="exampleInputEmail1">Filter By </label>
                  <div class="form-field">
                    <input type="radio" v-model="filter" value="date" />
                    <label style="margin: 5px; padding-right:10px">Date</label>
                    <input type="radio" v-model="filter" value="period" />
                    <label style="margin: 5px;padding-right:10px">Period</label>
                  </div>
                </div>
              </div>-->
            </div>

            <div class="row">
              <div class="col-md-2 col-sm-5 col-6" v-if="filter === 'date'">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">From Date</label>
                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="startDate"
                    placeholder="Select Date"
                    v-model="startDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                  ></datepicker>
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6" v-if="filter === 'date'">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">To Date</label>
                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="endDate"
                    placeholder="Select Date"
                    v-model="endDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :disabledDates="{ to: new Date(startDate - 8640000) }"
                  ></datepicker>
                </div>
              </div>

              <div class="col-md-2" v-if="filter === 'period'">
                <div class="form-group fc">
                  <label>Period</label>
                  <select
                    id="selectedPeriod"
                    class="form-control"
                    name="selectedPeriod"
                    v-model="selectedPeriod"
                    @change="onPeriodSelect"
                    required
                  >
                    <option :value="null">-- Select --</option>
                    <option value="mtd">MTD</option>
                    <option value="qtd">QTD</option>
                    <option value="ytd">YTD</option>
                    <option value="last_month">Last Month</option>
                    <option value="last_quarter">Last 3 Months</option>
                    <option value="last_2_quarters">Last 6 Months</option>
                    <option value="last_fy_1">Last 12 Months</option>
                    <!-- <option value="last_fy_2">Last 2 FY Years</option> -->
                    <option value="all">All</option>
                  </select>
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6" v-if="filter === 'period'">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">From Date</label>
                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="endDate"
                    placeholder
                    v-model="startDatePeriod"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :disabled="true"
                  ></datepicker>
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6" v-if="filter === 'period'">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">To Date</label>
                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="endDate"
                    placeholder
                    v-model="endDatePeriod"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :disabled="true"
                    :disabledDates="{ to: new Date(startDatePeriod - 8640000) }"
                  ></datepicker>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group fc">
                  <label>Status</label>
                  <select
                    id="selectedStatus"
                    class="form-control"
                    name="selectedStatus"
                    v-model="selectedStatus"
                    v-on:change="eventChangeStatus()"
                    required
                  >
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                    <option value="Matured">Matured</option>
                    <option value="All">All</option>
                  </select>
                </div>
              </div>

              <div class="col-md-2 col-sm-2 col-12">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-primary sh_n mt"
                      v-on:click="getAllInvestments(true)"
                    >
                      Filter
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh_n mt"
                      v-on:click="resetInvestment()"
                    >
                      Reset
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            class="container-fluid ot_tb pt-3"
            v-if="
              loggedInUserGroupId == 'Admin' ||
              (loggedInUserGroupId == 'Analyst' && loggedInUserSubGroupId == 0)
            "
          >
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6 col-sm-6">
                        <h1 class="headingt">
                          <i class="icofont-coins"></i> Investment
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6 col-sm-6">
                        <ul id="sr_btn">
                          <li></li>
                          <li>
                            <!--<div class="form-group has-search m-0">
                              <span class="fa fa-search form-control-feedback"></span>
                              <input type="text" class="form-control" placeholder="Search" />
                            </div>-->
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <!------menton here style="height: 300px;"-------->
                    <table
                      class="table table-head-fixed text-nowrap table-hover myTable2"
                      id="myTable2"
                    >
                      <thead>
                        <tr>
                          <th>Fund Name</th>
                          <th>Investor Name</th>
                          <th>Principal</th>
                          <th>Investment ID</th>
                          <th>Issue Date</th>
                          <th>Maturity Date</th>
                          <th>Inv Currency</th>
                          <th>Category Name</th>
                          <!-- <th>Admin Fees(%)</th>
                          <th>Admin Fees</th>
                          <th>Mgmt Fees(%)</th>
                          <th>Mgmt Fees</th>
                          <th>Penalty Interest(%)</th>
                          <th>Penalty Interest</th> -->
                          <!--<th" v-if="investor.investment_category_name === 'Notes'">Maturity Ex</th>
                            <th" v-if="investor.investment_category_name === 'Notes'">Extended Matur</th>
                            <th" v-if="investor.investment_category_name === 'Participation'">Ad</th>
                            <th" v-if="investor.investment_category_name === 'Participation'">M</th>
                          <th" v-if="investor.investment_category_name === 'Participation'">Penalty </th>-->
                          <!-- <th>Interest Rate Type</th> -->
                          <th>Interest %</th>
                          <!-- <th>Payment Schedule</th>
                          <th>Day Count</th> -->
                          <th>Interest Accrued</th>
                          <th>Interest Paid</th>
                          <th>Total Interest</th>
                          <th>Days of Calculation</th>
                          <th>Redemption Value</th>
                          <th>Status</th>
                        </tr>
                      </thead>
<!-- 
                      <div
                        v-if="
                          investments == undefined || investments.length === 0
                        "
                      >
                        No items to display
                      </div> -->
                      <tbody>
                        <tr
                          v-for="inv in investments"
                          :key="inv.transaction_id"
                        >
                          <template
                            v-if="
                              selectedStatus === 'All' ||
                              inv.status === selectedStatus
                            "
                          >
                            <td v-if="inv.fund_name !== null">
                              {{ inv.fund_name }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="inv.investor_name !== null">
                              {{ inv.investor_name }}
                            </td>
                            <td v-if="inv.principal_amount != null">
                              {{ inv.principal_amount | numeral("0,0.00") }}
                            </td>
                            <td v-if="inv.investment_id !== null">
                              {{ inv.investment_id }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="inv.investment_date !== null">
                              {{ inv.investment_date }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="inv.maturity_date !== null">
                              {{ inv.maturity_date }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="inv.inv_currency !== null">
                              {{ inv.inv_currency }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="inv.investment_category_name !== null">
                              {{ inv.investment_category_name }}
                            </td>
                            <td v-else>-</td>
                            <!-- <td
                              v-if="
                                inv.investment_category_name === 'Participation'
                              "
                            >
                              {{ inv.admin_fees_percent | numeral("0,0.00") }}
                            </td>
                            <td v-else>-</td>
                            <td
                              v-if="
                                inv.investment_category_name === 'Participation'
                              "
                            >
                              {{ inv.admin_fees | numeral("0,0.00") }}
                            </td>
                            <td v-else>-</td>
                            <td
                              v-if="
                                inv.investment_category_name === 'Participation'
                              "
                            >
                              {{
                                inv.management_fees_percent | numeral("0,0.00")
                              }}
                            </td>
                            <td v-else>-</td>
                            <td
                              v-if="
                                inv.investment_category_name === 'Participation'
                              "
                            >
                              {{ inv.management_fees | numeral("0,0.00") }}
                            </td>
                            <td v-else>-</td>
                            <td
                              v-if="
                                inv.investment_category_name === 'Participation'
                              "
                            >
                              {{ inv.penalty_interest | numeral("0,0.00") }}
                            </td>
                            <td v-else>-</td>
                            <td
                              v-if="
                                inv.investment_category_name ===
                                  'Participation' &&
                                inv.penalty_interest_amt !== null
                              "
                            >
                              {{ inv.penalty_interest_amt | numeral("0,0.00") }}
                            </td>
                            <td v-else>-</td> -->
                            <!--<td v-if="investor.investment_category_name === 'Notes'">{{ inv.maturity_extendible }}</td>
                            <td v-if="investor.investment_category_name === 'Notes'">{{ inv.extended_maturity_date }}</td>
                            <td v-if="investor.investment_category_name === 'Participation'">{{ inv.admin_fees }}</td>
                            <td v-if="investor.investment_category_name === 'Participation'">{{ inv.management_fees }}</td>
                            <td v-if="investor.investment_category_name === 'Participation'">{{ inv.penalty_interest }}</td>-->
                            <!-- <td v-if="inv.interest_type_name !== null">
                              {{ inv.interest_type_name }}
                            </td>
                            <td v-else>-</td> -->
                            <td v-if="inv.coupon !== null">
                              {{ inv.coupon }}
                            </td>
                            <td v-else>-</td>
                            <!-- <td v-if="inv.interest_frequency_name !== null">
                              {{ inv.interest_frequency_name }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="inv.day_count_name !== null">
                              {{ inv.day_count_name }}
                            </td>
                            <td v-else>-</td> -->
                            <td v-if="inv.accrued_interest !== null">
                              {{ inv.accrued_interest | numeral("0,0.00") }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="inv.paid_interest !== null">
                              {{ inv.paid_interest | numeral("0,0.00") }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="inv.total_interest !== null">
                              {{ inv.total_interest | numeral("0,0.00") }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="inv.days_of_calculation !== null">
                              {{ inv.days_of_calculation }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="inv.redemption_value !== null">
                              {{ inv.redemption_value | numeral("0,0.00") }}
                            </td>
                            <td v-else>-</td>
                            <td
                              :class="{
                                'c text-success': inv.status === 'Open',
                                'c text-danger': inv.status === 'Closed',
                                'c text-warning': inv.status === 'Matured',
                              }"
                            >
                              {{ inv.status }}
                            </td>
                          </template>
                        </tr>
                        <!----------------------------------------------->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>
      </div>
      <!-- /.Tabpane second end-->
    </div>
    <!-- /.content-wrapper -->

    <!-- ./modal start -->
    <modal name="report">
      <div class="modal-content" style="height: 500px">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Download Report</h4>
          <button
            @click="$modal.hide('report')"
            type="button"
            class="close"
            data-dismiss="modal"
          >
            <i class="icofont-close-circled"></i>
          </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group fc">
                <label class="required-field">Select Report</label>
                <select
                  id="selectedReport"
                  class="form-control"
                  name="selectedReport"
                  v-model="selectedReport"
                  required
                >
                  <option :value="null">--- Select ---</option>
                  <option v-for="report in reports" :value="report.id">
                    {{ report.report_name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group fc">
                <label class="required-field">Period</label>
                <select
                  id="selectedPeriod"
                  class="form-control"
                  name="selectedPeriod"
                  v-model="selectedPeriod"
                  required
                >
                  <option value="null">--- Select ---</option>
                  <option value="mtd">MTD</option>
                  <option value="ytd">YTD</option>
                  <option value="lastmonth">Last Month</option>
                  <option value="lastquarter">Last 3 Months</option>
                  <option value="last2quarters">Last 6 Months</option>
                  <option value="lastfy1">Last 12 Months</option>
                </select>
              </div>
            </div>

            <div
              v-if="selectedReport == 9"
              class="row"
              style="padding-top: 30px"
            >
              <div class="col-md-6">
                <div class="custom-control custom-checkbox mb-3">
                  <label>
                    <input
                      type="radio"
                      v-model="conversionType"
                      value="fund_to_investment"
                    />
                    Fund Currency to Inv Currency
                  </label>
                </div>
              </div>

              <div class="col-md-6">
                <div class="custom-control custom-checkbox mb-3">
                  <label>
                    <input
                      type="radio"
                      v-model="conversionType"
                      value="investment_to_fund"
                    />
                    Inv Currency to Fund Currency
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer" style="margin-top: -15px">
          <div class="mx-auto">
            <button
              style="margin-right: 6px"
              type="button"
              class="btn-sm btn btn-success sh"
              v-on:click="onClickExcel('excel')"
            >
              <i class="icofont-file-excel"></i>
              Excel
            </button>
            <button
              type="button"
              style="margin-right: 6px"
              class="btn-sm btn btn-danger sh"
              v-on:click="onClickExcel('pdf')"
              :style="{ cursor: 'pointer' }"
            >
              <i class="icofont-file-pdf"></i> PDF
            </button>
            <button
              @click="$modal.hide('report')"
              style="margin-right: 6px"
              type="button"
              class="btn-sm btn btn-dark sh"
              data-dismiss="modal"
            >
              <i class="icofont-close-circled"></i> Close
            </button>
          </div>
        </div>
      </div>
    </modal>
    <!-- ./modal end -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import $ from "jquery";
// //Datatable Modules
import "jquery/dist/jquery.min.js";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function

export default {
  data() {
    return {
      funds: [],
      investors: [],
      investmentCategory: [],
      investorMaster: [],
      selectedFund: null,
      selectedInvestmentCategory: null,
      selectedInvestorName: null,
      startDate: null,
      endDate: null,
      selectedPeriod: null,
      selectedStatus: "Open",
      investments: [],
      filter: "date",
      startDatePeriod: null, //for period filter
      endDatePeriod: null, //for period filter
      selectedReport: null,
      selectedPeriod: null,
      investorId: null,
      reports: [],
      InvRegDate: null,
      conversionType: null,
      inActiveToggle: false,
    };
  },
  components: {
    Datepicker,
  },
  methods: {
    destroyTable() {
      $("#myTable1").dataTable().fnDestroy();
      if (!this.inActiveToggle) {
        this.$store.commit("setinactive", "investor_active");
        this.$router.go();
      } else {
        this.$store.commit("setinactive", "investor_inactive");
        this.getInvestors();
      }
    },
    onPeriodSelect() {
      if (this.selectedPeriod === "all") {
        this.selectedStatus = "All";
      } else {
        this.selectedStatus = "Open";
      }
    },
    showReportModal(investorId) {
      this.investorId = investorId;
      this.$modal.show("report");
    },
    getReports() {
      //this function is called to get list of investor reports
      const path = "investor-reports";
      axios
        .get(path)
        .then((res) => {
          this.reports = res.data.reports;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onClickExcel(fileType) {
      if (this.selectedPeriod === null || this.selectedReport === null) {
        Utils.make_alert("warning", "Please select report type and period.");
        return;
      }

      if (this.selectedReport === 9) {
        //for currency conversion radio button value is required
        if (this.conversionType === null) {
          Utils.make_alert("warning", "Please select conversion type.");
          return;
        }
      }

      const path =
        "/investor_reports/" +
        this.selectedPeriod +
        "/" +
        this.selectedReport +
        "/" +
        this.investorId +
        "/" +
        fileType +
        "/" +
        this.conversionType;
      Utils.start_loading()
      axios({
        url: path,
        params: {
          clientId: this.$store.state.client_id,
          userId: this.$store.getters.getuserId,
        },
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          Utils.stop_loading()
          if (response.data.type == "application/json") {
            Utils.make_alert(
              "warning",
              "Something went wrong! Unable to download report."
            );
          } else {
            //give report type names as per selectedReport
            var reportName;
            var extension;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            // Filename seems to have to be changed unless otherwise provided
            // the extension can be automatically decided
            if (fileType === "excel") {
              extension = ".xlsx";
            } else if (fileType === "pdf") {
              extension = ".pdf";
            }
            if (this.selectedReport === 9) {
              reportName = "Investor Currency Exchange Report";
              if (this.conversionType == "fund_to_investment") {
                reportName = reportName + "(Fund to Investment Currency)";
              } else if (this.conversionType == "investment_to_fund") {
                reportName = reportName + "(Investment to Fund Currency)";
              }
            } else {
              reportName = "InvestorSummaryReport";
            }
            link.setAttribute("download", reportName + extension);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((error) => {
          Utils.stop_loading()
          console.log("error.data: ", error.response.status);
          console.log("error: " + error);
          // if (error.response.status == 400) {
          //   Utils.make_alert(
          //     "warning",
          //     "No data available for the selected report period!"
          //   );
          // } else if (error.response.status == 406) {
          //   Utils.make_alert("warning", "No matching reports.");
          // } else {
          //   Utils.make_alert(
          //     "warning",
          //     "Something went wrong! Unable to download report."
          //   );
          // }
        });
    },
    getInvestors() {
      //this function will be called to get list of funds
      const path = "investor";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            if (this.inActiveToggle) {
              this.investors = res.data.investors_inactive;
            } else {
              this.investors = res.data.investors_active;
            }
            if (
              this.loggedInUserGroupId === "Investor" &&
              this.loggedInUserSubGroupId !== 0
            ) {
              this.investors = this.investors.filter(
                (inv) =>
                  inv.investor_id.toString() === this.loggedInUserSubGroupId
              );
            }
            setTimeout(
              () =>
                $("#myTable1").DataTable({
                  aaSorting: [],
                  columnDefs: this.inActiveToggle
                    ? [{ orderable: false, targets: [] }]
                    : [
                        {
                          orderable: false,
                          targets:
                            this.loggedInUserGroupId === "Analyst"
                              ? [3, 4, 5, 6]
                              : [3, 4, 5, 6, 7],
                        },
                      ],
                }),
              100
            );
            this.InvRegDate = this.investors.registration_date;
            console.log("get investors is successful.");
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },

    deleteInvestor(investorId) {
      //this function will be called to delete a specific investor
      this.$confirm({
        auth: false,
        message: "Confirm Delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete("investor/" + investorId, {
                headers: {
                  "Content-type": "application/json",
                },
                data: {
                  type: "delete_investor",
                  moduleId: 3,
                  tabId: 11,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert("success", "Investor deleted successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(
                    () => this.$router.go(this.$router.currentRoute),
                    1500
                  );
                } else if (response.data.status == "deletion_not_allowed") {
                  Utils.make_alert(
                    "warning",
                    "Delete operation not permitted."
                  );
                } else {
                  Utils.make_alert(
                    "success",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.log("error: " + error);
                Utils.make_alert(
                  "success",
                  error.response.status === 400
                    ? error.response.data.status
                    : "Something went wrong!"
                );
              });
          }
        },
      });
    }, //deleteInvestor()  close

    onChangeStatus(updatedStatus, investorId, prevStatus, index) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            //this function is called when user changes the investor registration status. The status is updated in the database for that specific investor.
            const path = "investor/" + investorId;
            Utils.start_loading();
            axios
              .put(
                path,
                {
                  updateType: "regStatus",
                  updatedRegStatus: updatedStatus.target.value,
                  moduleId: 3,
                  tabId: 11,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("registration update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Registration Status updated successfully!"
                  );
                  //if you want to send any data into server before redirection then you can do it here
                  // this.$router.go(this.$router.currentRoute);
                  this.investors[index].registration_status =
                    updatedStatus.target.value;
                } else {
                  Utils.make_alert(
                    "warning",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeStatus close

    getAllInvestments(isLoading) {
      $("#myTable2").dataTable().fnDestroy();
      if (this.filter === "date") {
        if (
          (this.startDate !== null && this.endDate === null) ||
          (this.startDate === null && this.endDate !== null)
        ) {
          Utils.make_alert(
            "warning",
            "Both From & To Dates are required to perform Date Filter"
          );
          return;
        } else {
          this.selectedPeriod = null;
          if (this.startDate != null) {
            this.startDate = moment(this.startDate).format("MM-DD-YYYY");
          }
          if (this.endDate != null) {
            this.endDate = moment(this.endDate).format("MM-DD-YYYY");
          }
        }
      }

      if (this.filter === "period") {
        this.startDate = null;
        this.endDate = null;
      }

      const path =
        "investment-details/" +
        this.startDate +
        "/" +
        this.endDate +
        "/" +
        this.selectedPeriod +
        "/" +
        this.selectedStatus +
        "/" +
        this.selectedFund +
        "/" +
        this.selectedInvestmentCategory +
        "/" +
        this.selectedInvestorName;

      if (isLoading == true) {
        Utils.start_loading();
      }
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          if (isLoading == true) {
            Utils.stop_loading();
          }
          if (res.data.status == "success") {
            this.investments = res.data.investments;
            if(this.investments.length > 0) {
              this.investments.forEach(item => {
                if (item.investment_date != '-' && item.investment_date != null) {
                  item.investment_date = moment(item.investment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.investment_date = '-';
                }
                if (item.maturity_date != '-' && item.investment_date != null) {
                  item.maturity_date = moment(item.maturity_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.maturity_date = '-';
                }
              });
            }
            setTimeout(
              () =>
                $("#myTable2").DataTable({
                  scrollX: true,
                  aaSorting: [],
                  columnDefs:[
                  {orderable: false,}
                  ],
                }),
              100
            );
            this.selectedPeriod = res.data.period;
            this.selectedStatus = res.data.t_status;
            //set the start and end date according to filter selected
            if (this.filter === "period") {
              this.startDatePeriod = res.data.start_date;
              this.endDatePeriod = res.data.end_date;
              this.startDate = null;
              this.endDate = null;
            } else if (this.filter === "date") {
              this.startDate = res.data.start_date;
              this.endDate = res.data.end_date;
              this.startDatePeriod = null;
              this.endDatePeriod = null;
            }
            console.log("get investment by investor id is successful.");
          }
        })
        .catch((error) => {
          if (isLoading == true) {
            Utils.stop_loading();
          }
          console.error("error: " + error);
        });
    }, //getAllInvestments() close

    // getFundsFilter() {
    //   //this function is called to get list of funds
    //   const path = "fund-master-filter";
    //   axios
    //     .get(path)
    //     .then(res => {
    //       this.funds = res.data.funds;
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // },

    eventChangeFund() {
      this.getInvestmentCategory();
      this.getInvestorName();
    },

    eventChangeStatus() {
      this.getFunds();
      this.getInvestmentCategory();
      this.getInvestorName();
    },

    eventChangeInvestorName() {
      this.getFunds();
      this.getInvestmentCategory();
    },

    eventChangeInvestmentCategory() {
      this.getFunds();
      this.getInvestorName();
    },

    getFunds() {
      //this function is called to get list of funds
      const path =
        "/fund-master-filter/" +
        this.selectedStatus +
        "/" +
        this.selectedInvestorName +
        "/" +
        this.selectedInvestmentCategory;

      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.funds = res.data.funds;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of investment category
    getInvestmentCategory() {
      const path =
        "investment-category-filter/" +
        this.selectedFund +
        "/" +
        this.selectedStatus +
        "/" +
        this.selectedInvestorName;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.investmentCategory = res.data.investmentcategories;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getInvestmentCategory() close

    //this function is called to get list of investors
    getInvestorName() {
      const path =
        "investor-master-filter/" +
        this.selectedInvestmentCategory +
        "/" +
        this.selectedFund +
        "/" +
        this.selectedStatus;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.investorMaster = res.data.investors;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getInvestorName() close

    resetInvestment() {
      this.startDate = null;
      this.endDate = null;
      this.startDatePeriod = null;
      this.endDatePeriod = null;
      this.selectedPeriod = null;
      this.selectedStatus = "Open";
      this.selectedFund = null;
      this.selectedInvestmentCategory = null;
      this.selectedInvestorName = null;
      this.filter = "date";
      this.getFunds();
      this.getInvestmentCategory();
      this.getInvestorName();
      this.getAllInvestments();
    }, //resetInvestment() close
  },
  mounted() {
    //keep current tab active
    $('a[data-toggle="pill"]').on("shown.bs.tab", function (e) {
      localStorage.setItem("investorTab", $(e.target).attr("href"));
    });
    var activeTab = localStorage.getItem("investorTab");
    if (activeTab) {
      $('.nav-tabs a[href="' + activeTab + '"]').tab("show");
    }
  },
  created() {
    axios.defaults.headers.common["Authorisation"] =
      "Bearer " + sessionStorage.getItem("token");
    this.inActiveToggle =
      this.$store.state.inactive === "investor_inactive" ? true : false;
    this.getFunds();
    this.getInvestors();
    this.getInvestmentCategory();
    this.getInvestorName();
    this.selectedStatus = "Open";
    this.getAllInvestments(true);
    this.getReports();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
      // return this.$store.getters.getuserGroup;
    },
    loggedInUserSubGroupId() {
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
      // return this.$store.getters.getuserSubGroup;
    },
  },
};
</script>

<style scoped>
.required-field::after {
  content: "*";
  color: red;
}
th,
td {
  text-align: left;
}
.center {
  text-align: center !important;
}
.right {
  text-align: right !important;
}
</style>
