<template>
  <div id="app">
    <vue-confirm-dialog></vue-confirm-dialog>
    <div v-if="loggedIn">
      <auto-logout></auto-logout>
    </div>
    <!-- @endauth -->
    <router-view />
    <footer-bar v-if="loggedIn"></footer-bar>
  </div>
</template>

<script>
//Bootstrap and jQuery libraries
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import axios from "axios";
import { Utils } from "../../dpa_tradepulse_webapp_frontend/src/utils/CommonMethods.js";

export default {
  name: "App",
  data() {
    return {
      user: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  created() {
    axios.defaults.headers.common["Authorisation"] =
      "Bearer " + sessionStorage.getItem("token");
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          console.log("error.response", error.response);
          if (error.response) {
            const { status, data, headers } = error.response;
            if (data instanceof Blob && headers['content-type'].includes('application/json')) {
              // When the response is a Blob and Content-Type is application/json,
              // use FileReader to read the blob as text
              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                  try {
                    const jsonData = JSON.parse(reader.result);
                    console.log("Parsed JSON from Blob:", jsonData);
                    // You can now reject the promise with the jsonData to handle it accordingly
                    reject({ ...error, response: { ...error.response, data: jsonData } });
                    Utils.make_alert("warning", jsonData.status);
                  } catch (e) {
                    console.error("Error parsing JSON from Blob", e);
                    reject(error); // Reject with the original error if parsing fails
                  }
                };
                reader.onerror = () => {
                  console.error("Error reading the Blob");
                  reject(error); // Reject with the original error if reading fails
                };
                reader.readAsText(data); // Read the Blob as text
              });
            } else if (status === 401) {
              console.log("Token expired");
              this.$store.dispatch("destroyToken");
              localStorage.clear();
              sessionStorage.clear();
              this.$router.push({ name: "login" });
            } else if (status === 400) {
              // Directly accessible JSON error, handle accordingly
              console.log("error.response.data.status", error.response.data.status);
              Utils.make_alert("warning", error.response.data.status);
            }
          }
          // For errors not handled above, return a Promise rejecting the error
          return Promise.reject(error);
        }
      );
  },
  mounted() {
    // to hide alerts by datatable warnings and show them in console instead
    $.fn.dataTable.ext.errMode = "throw";
    this.$store
      .dispatch("getLoggedInUser", this.$store.state.username)
      .then((response) => {
        this.user = this.$store.getters.getuserFullName;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style>
html,
body {
  height: 100% !important;
}
#app {
  height: 100%;
  width: 100%;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background-color: #007bff !important;
  background: #007bff !important;
  color: white !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: white !important;
  background: white;
  color: black !important;
}
</style>
