import axios from "axios";

const config = require("../tradepulse_config_fe.json");
axios.defaults.baseURL = config["API_LINK"];
// axios.defaults.baseURL = "http://192.168.2.115:5000/";
// axios.defaults.baseURL = "http://192.168.2.18:5055/";
// axios.defaults.baseURL = "http://tradepulseapi.decimalpointanalytics.com:5055/"
// axios.defaults.baseURL = "https://tradepulseapi.algocircle.com/";

axios.defaults.headers.common["Authorisation"] =
  "Bearer " + sessionStorage.getItem("token");
