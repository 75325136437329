<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          Disbursement Overview
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                            <!-- <a href="disbursement.html">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                            </a>-->
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover table-head-fixed text-nowrap">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Disbursement ID:</td>
                        <td v-if="disbursement.id !== null" class>
                          {{ disbursement.id }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Fund Name:</td>
                        <td v-if="disbursement.fund_name !== null" class>
                          {{ disbursement.fund_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Borrower Name:</td>
                        <td v-if="disbursement.borrower_name !== null" class>
                          {{ disbursement.borrower_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <tr>
                        <td class="wg">Disbursement On:</td>
                        <td v-if="disbursement.disbursement_on !== null" class>
                          {{ disbursement.disbursement_on }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                        <!-- <tr 
                        v-for="(fac) in Facility"
                        :key="fac.id"
                        >
                            <td v-if="fac.id == null || fac.id == 1" class="wg">Facility ID:</td>
                            <td v-else class="wg"></td>
                            <td
                            v-if="fac.facility_registration_id !== null"
                            class
                            >
                            {{ fac.facility_registration_id }}
                          </td>
                          <td v-else class>-</td>
                        </tr>
                        <tr v-if="Facility.length == 0">
                            <td class="wg">Facility ID:</td>
                            <td class="">-</td>
                        </tr> -->
                      <!----------------------------------------------->
                      <!-- <tr>
                        <td class="wg">Cost Name:</td>
                        <td v-if="disbursement.loan_expense_name !== null" class>
                          {{ disbursement.loan_expense_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr> -->
                      <!----------------------------------------------->
                      <!-- <tr>
                        <td class="wg">Cost Amount:</td>
                        <td v-if="disbursement.expense_amount !== null" class>
                          {{ disbursement.expense_amount }}
                        </td>
                        <td v-else class>-</td>
                      </tr> -->
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Collateral Name:</td>
                        <td class="wrap_text" v-if="allCollateralName !== null">
                          {{ allCollateralName }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Facility ID:</td>
                        <td class="wrap_text" v-if="allFacility !== null">
                          {{ allFacility }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      
                      <!----------------------------------------------->
                      <!-- <tr>
                        <td class="wg">Actual Disbursement:</td>
                        <td v-if="disbursement.actual_disbursement_amount !== null" class>
                          {{ disbursement.actual_disbursement_amount }}
                        </td>
                        <td v-else class>-</td>
                      </tr> -->
                      <!----------------------------------------------->
                      <!-- <tr>
                        <td class="wg">Available to Disburse:</td>
                        <td v-if="disbursement.available_amount !== null" class>
                          {{ disbursement.available_amount }}
                        </td>
                        <td v-else class>-</td>
                      </tr> -->
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <!-- <i class="icofont-spinner-alt-3"></i>  -->
                          Bank Details
                        </h1>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover table-head-fixed text-nowrap">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Beneficiary Bank:</td>
                        <td v-if="bank.bank_name !== null" class>
                          {{ bank.bank_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Bank Identifier Code:</td>
                        <td v-if="bank.bic !== null" class>
                          {{ bank.bic }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">IBAN (International Bank Account No):</td>
                        <td
                          v-if="bank.iban !== null"
                          class
                        >
                          {{ bank.iban }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Account No. :</td>
                        <td
                          v-if="bank.account_number !== null"
                          class
                        >
                          {{ bank.account_number }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Account Name:</td>
                        <td v-if="bank.account_name !== null" class>
                          {{ bank.account_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Reference:</td>
                        <td v-if="bank.reference !== null" class>
                          {{ bank.reference }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          <div v-if="disbursementDocumentsDetails.length > 0" class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documentation</h1>
                      </div>
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <table class="table table-head-fixed text-nowrap">
                      <thead>
                        <tr>
                          <th>Document</th>
                          <th>Comment</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(cp) in disbursementDocumentsDetails"
                          :key="cp.id"
                        >
                          <td>
                          <span v-on:click="downloadDIsbursementDocument(cp.document_path)"
                            :style="{ cursor: 'pointer' }" >
                            <i class="icofont-download text-info" style="padding-right: 10px;"></i>
                            {{ cp.display_file_name }}
                          </span>
                          </td>
                          <td v-if="cp.comments !== null">
                            {{ cp.comments }}
                          </td>
                          <td v-else>-</td>
                        </tr>
                        <!----------------------------------------------->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Loan Mapping</h1>
                      </div>
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <table
                      class="table table-head-fixed text-nowrap table-hover"
                    >
                      <thead>
                        <tr>
                          <th>Loan Regi. ID</th>
                          <th>Disbursement Date</th>
                          <th>Disbursement Amount</th>
                          <th>Available Disbursement Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="loan in loanMappingList"
                          :key="loan.id"
                        >
                          <td v-if="loan.loan_registration_id !== null">
                            {{ loan.loan_registration_id }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="loan.disbursement_date !== null" class="c">
                            {{ loan.disbursement_date }}
                          </td>
                          <td v-else class="c">-</td>
                          <td v-if="loan.total_disbursement_amount !== null">
                            {{ loan.total_disbursement_amount }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="loan.loan_balance_converted_amount !== null">
                            {{ loan.loan_balance_converted_amount }}
                          </td>
                          <td v-else>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>


  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import moment from "moment";


export default {
  data() {
    return {
      id: null,
      // tabIndex: 0,
      // tabs: ["#one", "#two", "#three"],
      disbursement: [],
      bank: [],
      // Payment: [],
      disbursementId: null,
      disbursementDocumentsDetails: [],
      Facility:[],
      allFacility:null,
      CollateralName:[],
      allCollateralName:null,
      //list for Loan Mapping
      loanMappingList: [],
    };
  },
  components: {
    Datepicker,
  },
  methods: {

    downloadDIsbursementDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },

    getDisbursementOverview() {
      Utils.start_loading();
      //this function is called on page load and created events. it will show details on disbursement with disbursementid equal to this.id
      const path = "disbursement-overview/" + this.id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.disbursement = res.data.disbursement_overview.disbursement[0];
            this.Facility = res.data.disbursement_overview.facility_disbursement_mapping;
            if(this.Facility.length > 0){
              this.allFacility = this.Facility.map(f => f.facility_registration_id).join(', ');
            }
            this.CollateralName = res.data.disbursement_overview.collateral_disbursement_mapping;
            if(this.CollateralName.length > 0){
              this.allCollateralName = this.CollateralName.map(f => f.collateral_name).join(', ');
            }
            this.bank = res.data.disbursement_overview.disbursement[0];
            this.disbursementDocumentsDetails = res.data.disbursement_overview.disbursement_transaction_document;
            this.loanMappingList = res.data.disbursement_overview.disbursement_transaction_loan_mapping;

            if(this.loanMappingList.length > 0) {
              this.loanMappingList.forEach(item => {
                if (item.disbursement_date != '-' && item.disbursement_date != null) {
                  item.disbursement_date = moment(item.disbursement_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.disbursement_date = '-';
                }
              });
            }

            console.log("get disbursement details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/facility");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getDisbursementOverview close
  },

  mounted() {
    this.id = this.$route.params.id;
    this.inactive =
      this.$store.state.inactive === "disbursement_inactive" ? true : false;
    this.getDisbursementOverview();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style scoped>
th,
td {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right !important;
}
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}
</style>
