<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-search-1"></i> Offtaker Overview
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table
                    class="table table-hover table-head-fixed text-nowrap"
                    style="table-layout: fixed"
                  >
                    <tbody>
                      <tr>
                        <td class="wg">Name:</td>
                        <td v-if="offtaker.offtaker_name" class="wrap_text">
                          {{ offtaker.offtaker_name }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Address:</td>
                        <td v-if="offtaker.address" class="wrap_text">
                          {{ offtaker.address }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Website:</td>
                        <td v-if="offtaker.website" class="wrap_text">
                          {{ offtaker.website }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Incorporation Date:</td>
                        <td v-if="offtaker.incorporation_date">
                          {{ offtaker.incorporation_date }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody>
                      <tr>
                        <td class="wg">State:</td>
                        <td v-if="offtaker.state_name">
                          {{ offtaker.state_name }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">City:</td>
                        <td v-if="offtaker.city_name">
                          {{ offtaker.city_name }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Country:</td>
                        <td v-if="offtaker.country_name">
                          {{ offtaker.country_name }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Registration Date:</td>
                        <td v-if="offtaker.registration_date">
                          {{ offtaker.registration_date }}
                        </td>
                        <td v-else></td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!--========================================================================-->

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Contact Details</h1>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="card-body br_overview">
                        <table
                          class="table table-hover table-head-fixed text-nowrap"
                        >
                          <tbody>
                            <tr>
                              <td class="wg" id="contact">
                                Primary Contact No:
                              </td>
                              <td v-if="offtaker.phone_number">
                                {{ offtaker.primary_code }}
                                {{ offtaker.phone_number }}
                              </td>
                              <td v-else>-</td>
                            </tr>

                            <tr>
                              <td class="wg" id="secondaryContact">
                                Secondary Contact No:
                              </td>
                              <td
                                v-if="
                                  offtaker.secondary_phone_number !== null &&
                                  offtaker.secondary_phone_number !== '-'
                                "
                              >
                                {{ offtaker.secondary_code }}
                                {{ offtaker.secondary_phone_number }}
                              </td>
                              <td v-else>-</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card-body br_overview">
                        <table class="table table-hover">
                          <tbody>
                            <tr>
                              <td class="wg" id="email">Primary Email ID:</td>
                              <td v-if="offtaker.email">
                                {{ offtaker.email }}
                              </td>
                              <td v-else>-</td>
                            </tr>

                            <tr>
                              <td class="wg" id="secondaryEmail">
                                Secondary Email ID:
                              </td>
                              <td v-if="offtaker.secondary_email">
                                {{ offtaker.secondary_email }}
                              </td>
                              <td v-else>-</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <!--========================================================================-->

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documents</h1>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <!------menton here style="height: 300px;"-------->
                    <table class="table table-head-fixed text-nowrap">
                      <thead>
                        <tr>
                          <th>Document</th>
                          <th>Issue Date</th>
                          <th>Expiry Date</th>
                          <th>Status</th>
                          <th
                            v-if="
                              loggedInUserGroupId == 'Admin' &&
                              loggedInUserSubGroupId == 0
                            "
                          >
                            Change Status
                          </th>
                          <th class="center">Comments</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(off, index) in offtakerDocuments"
                          :key="off.id"
                        >
                          <td
                            v-on:click="
                              downloadOfftakerDocument(off.document_path)
                            "
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-download text-info"></i>
                            {{ off.OfftakerDocName }}
                          </td>
                          <td v-if="off.UploadingDate">
                            {{ off.UploadingDate }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="off.ExpiryDate">
                            {{ off.ExpiryDate }}
                          </td>
                          <td v-else>-</td>
                          <td
                            :class="{
                              'text-success':
                                off.document_status === 'Approved',
                              'text-warning':
                                off.document_status === 'Received',
                              'text-danger': off.document_status === 'Rejected',
                              'text-dark': off.document_status === 'Expired',
                              'text-info':
                                off.document_status === 'Near to Expiry',
                            }"
                          >
                            {{
                              off.document_status === "Received"
                                ? loggedInUserGroupId === "Offtaker"
                                  ? "Uploaded"
                                  : off.document_status
                                : off.document_status
                            }}
                          </td>

                          <td
                            class="status"
                            v-if="
                              loggedInUserGroupId == 'Admin' &&
                              loggedInUserSubGroupId == 0
                            "
                          >
                            <div class="status">
                              <select
                                id="docStatus"
                                name="docStatus"
                                @change="
                                  onChangeStatus(
                                    $event,
                                    off.offtaker_master_id,
                                    off.offtaker_document_id,
                                    off.document_status,
                                    index
                                  )
                                "
                                class="form-control"
                                :value="off.document_status"
                                :disabled="
                                  inactive || off.document_status !== 'Received'
                                "
                              >
                                <option value="Received">Received</option>
                                <option value="Approved">Approved</option>
                                <option value="Rejected">Rejected</option>
                                <option
                                  v-if="off.document_status === 'Expired'"
                                  value="Expired"
                                >
                                  Expired
                                </option>
                                <option
                                  v-if="
                                    off.document_status === 'Near to Expiry'
                                  "
                                  value="Near to Expiry"
                                >
                                  Near to Expiry
                                </option>
                              </select>
                            </div>
                          </td>
                          <td
                            v-if="!inactive"
                            class="center"
                            v-on:click="showCommentModal(off.comments)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-comment text-info"></i>
                          </td>
                          <td class="center" v-else>
                            <i
                              style="opacity: 0.5"
                              class="icofont-comment text-info"
                            ></i>
                          </td>
                        </tr>
                        <!----------------------------------------------->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>

    <!-- ./modal start -->
    <div class="modal fade" id="comm">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-comment"></i> Comments
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <textarea
                  rows="4"
                  cols="60"
                  style="outline: none"
                  v-model="comment"
                ></textarea>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->

    <!-- ./modal start -->
    <div class="modal fade" id="comm">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-comment"></i> Comments
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <textarea
                  rows="4"
                  cols="60"
                  style="outline: none"
                  v-model="comment"
                ></textarea>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js";
import moment from "moment";

export default {
  data() {
    return {
      id: null,
      offtaker: [],
      comment: null, //required for comment modal
      offtakerDocuments: [],
    };
  },
  components: {
    Datepicker,
  },
  methods: {
    onChangeStatus(updatedStatus, offtakerId, docId, prevStatus, index) {
      //this function is called when user changes the offtaker document status. The status is updated in the database for that specific offtaker document.
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path = "single-offtaker-overview-active/" + this.id;
            axios
              .put(
                path,
                {
                  updateType: "docStatus",
                  updatedStatus: updatedStatus.target.value,
                  offtaker_id: offtakerId,
                  offtaker_document_id: docId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 12,
                  tabId: 30,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("registration update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert("success", "Status updated successfully!");
                  this.offtakerDocuments[index].document_status =
                    updatedStatus.target.value;
                  setTimeout(() => this.$router.go(), 1500);
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeStatus close

    // downloads document with given file path
    downloadOfftakerDocument: function (filePath) {
      console.log("filePath: ", filePath);
      Utils.downloadDocument(filePath);
    },

    // displays comment modal
    showCommentModal(comm) {
      this.comment = comm;
      $("#comm").modal("show");
    }, //showCommentModal() close

    getOfftaker() {
      Utils.start_loading();
      //this function is called on page load and created events. it will show details on offtaker with offtakerid equal to this.id
      if (this.inactive) {
        const path = "single-offtaker-overview-inactive/" + this.id;
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            Utils.stop_loading();
            if (res.data.status == "success") {
              this.offtaker = res.data.offtaker.offtaker_inactive_details[0];
              if (this.offtaker.incorporation_date != '-' && this.offtaker.incorporation_date != null) {
                this.offtaker.incorporation_date = moment(this.offtaker.incorporation_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                this.offtaker.incorporation_date = '-';
              }
              if (this.offtaker.registration_date != '-' && this.offtaker.registration_date != null) {
                this.offtaker.registration_date = moment(this.offtaker.registration_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                this.offtaker.registration_date = '-';
              }
              this.offtakerDocuments =
                res.data.offtaker.offtaker_documents_inactive_details;
              if(this.offtakerDocuments.length > 0) {
                this.offtakerDocuments.forEach(item => {
                  if (item.UploadingDate != '-' && item.UploadingDate != null) {
                    item.UploadingDate = moment(item.UploadingDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.UploadingDate = '-';
                  }
                  if (item.ExpiryDate != '-' && item.ExpiryDate != null) {
                    item.ExpiryDate = moment(item.ExpiryDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.ExpiryDate = '-';
                  }
                });
              }
              console.log("get offtaker details is successful.");
            } else {
              console.log("Something went wrong from server!");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
            console.log("Something went wrong!");
          });
      } else {
        const path = "single-offtaker-overview-active/" + this.id;
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            Utils.stop_loading();
            if (res.data.status == "success") {
              this.offtaker = res.data.offtaker.offtaker_active_details[0];
              if (this.offtaker.incorporation_date != '-' && this.offtaker.incorporation_date != null) {
                this.offtaker.incorporation_date = moment(this.offtaker.incorporation_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                this.offtaker.incorporation_date = '-';
              }
              if (this.offtaker.registration_date != '-' && this.offtaker.registration_date != null) {
                this.offtaker.registration_date = moment(this.offtaker.registration_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                this.offtaker.registration_date = '-';
              }

              this.offtakerDocuments =
              res.data.offtaker.offtaker_documents_active_details;
              if(this.offtakerDocuments.length > 0) {
                this.offtakerDocuments.forEach(item => {
                  if (item.UploadingDate != '-' && item.UploadingDate != null) {
                    item.UploadingDate = moment(item.UploadingDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.UploadingDate = '-';
                  }
                  if (item.ExpiryDate != '-' && item.ExpiryDate != null) {
                    item.ExpiryDate = moment(item.ExpiryDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.ExpiryDate = '-';
                  }
                });
              }
              console.log("get offtaker details is successful.");
            } else {
              console.log("Something went wrong from server!");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
            console.log("Something went wrong!");
          });
      }
    }, //getOfftaker close
  },

  mounted() {
    this.id = this.$route.params.id;
    this.inactive =
      this.$store.state.inactive === "offtaker_inactive" ? true : false;
    this.getOfftaker();
  },
  computed: {
    loggedInUserFullName() {
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style>
.wg {
  font-weight: bold !important;
}
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}
</style>
