<!-- This is add collateral page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Edit Shipping Info
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedBorrower" class="required-field"
                      >Borrower Name</label
                    >
                    <input
                      id="selectedBorrower"
                      class="form-control"
                      name="selectedBorrower"
                      v-model="selectedBorrower"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedFacilityId" class="required-field"
                      >Facility ID</label
                    >
                    <input
                      id="selectedFacilityId"
                      class="form-control"
                      name="selectedFacilityId"
                      v-model="selectedFacilityId"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCollateral" class="required-field"
                      >Collateral</label
                    >
                    <input
                      id="selectedCollateral"
                      class="form-control"
                      name="selectedCollateral"
                      v-model="selectedCollateral"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCollateralType" class="required-field"
                      >Collateral Type</label
                    >
                    <input
                      id="selectedCollateralType"
                      class="form-control"
                      name="selectedCollateralType"
                      v-model="selectedCollateralType"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="CMCompany" class="required-field"
                      >Collateral Manager Company</label
                    >
                    <input
                      id="CMCompany"
                      class="form-control"
                      name="CMCompany"
                      v-model="CMCompany"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedOfftaker" class="required-field"
                      >Offtaker Name</label
                    >
                    <input
                      id="selectedOfftaker"
                      class="form-control"
                      name="selectedOfftaker"
                      v-model="selectedOfftaker"
                      disabled
                    />
                  </div>
                </div>
                <div v-if="logistics_shipping_flag == 2 && selectedContractNumber != null" class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedContractNumber" class="required-field"
                      >Contract No.</label
                    >
                    <input
                      id="selectedContractNumber"
                      class="form-control"
                      name="selectedContractNumber"
                      v-model="selectedContractNumber"
                      disabled
                    />
                  </div>
                </div>
                <div v-if="logistics_shipping_flag == 2 && this.selectedPartialContractNumber != null" class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedPartialContractNumber" class="required-field"
                      >Partial Contract No.</label
                    >
                    <input
                      id="selectedPartialContractNumber"
                      class="form-control"
                      name="selectedPartialContractNumber"
                      v-model="selectedPartialContractNumber"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedShippingPort" class="required-field"
                      >Shipping Port</label
                    >
                    <select
                      id="selectedShippingPort"
                      class="form-control"
                      name="selectedShippingPort"
                      v-model="selectedShippingPort"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedShippingPort.$error,
                      }"
                      :disabled="shippingDate !== null"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="port in Ports"
                        :value="port.id"
                        :key="port.id"
                      >
                        {{ port.name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedShippingPort.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedShippingPort.required"
                        >Shipping Port is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedDestinationPort" class="required-field"
                      >Destination Port</label
                    >
                    <select
                      id="selectedDestinationPort"
                      class="form-control"
                      name="selectedDestinationPort"
                      v-model="selectedDestinationPort"
                      :class="{
                        'is-invalid':
                          (isSubmitted && $v.selectedDestinationPort.$error) ||
                          (selectedShippingPort === selectedDestinationPort &&
                            selectedDestinationPort !== null),
                      }"
                      :disabled="deliveryDate !== null"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="port in Ports"
                        :value="port.id"
                        :key="port.id"
                      >
                        {{ port.name }}
                      </option>
                    </select>
                    <div
                      v-if="
                        (isSubmitted && $v.selectedDestinationPort.$error) ||
                        (selectedShippingPort === selectedDestinationPort &&
                          selectedDestinationPort !== null)
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedDestinationPort.required"
                        >Destination Port is required</span
                      >
                      <span
                        v-if="
                          selectedShippingPort === selectedDestinationPort &&
                          selectedDestinationPort !== null
                        "
                        >Shipping and Destination Port cannot be same</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="shippingLine" class="required-field"
                      >Shipping Line</label
                    >
                    <input
                      id="shippingLine"
                      class="form-control"
                      name="shippingLine"
                      v-model="shippingLine"
                      :disabled="shippingDate !== null"
                      :class="{
                        'is-invalid': isSubmitted && $v.shippingLine.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.shippingLine.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.shippingLine.required"
                        >Shipping Line is required</span
                      >
                      <span v-if="!$v.shippingLine.maxLength"
                        >Max length of 50 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="portAcceptanceDate" class="required-field"
                      >Port Acceptance Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="portAcceptanceDate"
                      id="portAcceptanceDate"
                      placeholder="Select Date"
                      v-model="portAcceptanceDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{
                        from: new Date(),
                        to: new Date(shippingBackcapDate),
                      }"
                      disabled
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.portAcceptanceDate.$error,
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.portAcceptanceDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.portAcceptanceDate.required"
                        >Port Acceptance Date is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="billOfLadingNo" class="required-field"
                      >Bill of Lading No.</label
                    >
                    <input
                      id="billOfLadingNo"
                      class="form-control"
                      name="billOfLadingNo"
                      v-model="billOfLadingNo"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="shippingDate">Shipping Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="shippingDate"
                      id="shippingDate"
                      placeholder="Select Date"
                      v-model="shippingDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{
                        from: new Date(),
                        to: new Date(portAcceptanceDate),
                      }"
                      :disabled="shippingDate !== null"
                    ></datepicker>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="vesselName" class="required-field"
                      >Vessel Name</label
                    >
                    <input
                      id="vesselName"
                      class="form-control"
                      name="vesselName"
                      v-model="vesselName"
                      :class="{
                        'is-invalid': isSubmitted && $v.vesselName.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.vesselName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.vesselName.required"
                        >Vessel Name is required</span
                      >
                      <span v-if="!$v.vesselName.maxLength"
                        >Max length of 50 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Delivery Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="deliveryDate"
                      id="deliveryDate"
                      placeholder="Select Date"
                      v-model="deliveryDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{
                        from: new Date(),
                        to: new Date(shippingDate),
                      }"
                      :disabled="shippingDate === null"
                    ></datepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="logistics_shipping_flag == 1" class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid">
                <div class="tp_mid">
                  <div class="row">
                    <div 
                      v-for="(con, index) in selectedContracts"
                      :key="index"  
                      class="col-6">
                      <h1 class="heading1">
                        <label style="font-size: 14px;">
                          Contract No. : {{ con.contract_number }} |
                          <span v-if="con.partial_contract_number != null">
                            Partial Contract No. : {{ con.partial_contract_number }} |
                          </span>
                          Contract Quantity : {{ con.total_quantity }} {{ con.unit_name }}
                        </label>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="releasedQty" class="required-field"
                      >Released Quantity (MT)</label
                    >
                    <input
                      id="releasedQty"
                      class="form-control"
                      name="releasedQty"
                      v-model="releasedQty"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.releasedQty.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.releasedQty.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.releasedQty.required"
                        >Released Quantity is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="grossWeight" class="required-field"
                      >Gross Weight (KG)</label
                    >
                    <input
                      id="grossWeight"
                      class="form-control"
                      name="grossWeight"
                      v-model="grossWeight"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="netWeight" class="required-field"
                      >Net Weight (KG)</label
                    >
                    <input
                      id="netWeight"
                      class="form-control"
                      name="netWeight"
                      v-model="netWeight"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="tareWeight" class="required-field"
                      >Tare Weight (KG)</label
                    >
                    <input
                      id="tareWeight"
                      class="form-control"
                      name="tareWeight"
                      v-model="tareWeight"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="bags" class="required-field">Bags</label>
                    <input
                      id="bags"
                      class="form-control"
                      name="bags"
                      v-model="bags"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Containers</h1>
                    </div>
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(con, index) in Containers"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Container No.</label>
                        <input
                          id="container_number"
                          class="form-control"
                          name="container_number"
                          v-model="con.container_number"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addShippingDoc()"
                            :disabled="ShippingDocumentsAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(doc, index) in ShippingDocuments"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label>Select Document</label>
                        <select
                          id="ShippingDocName"
                          class="form-control"
                          name="ShippingDocName"
                          v-model="doc.ShippingDocName"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="doc in ShippingDocumentNames"
                            :value="doc.document_name"
                            :key="doc.id"
                          >
                            {{ doc.document_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @mouseenter="mouseenter(doc.ShippingDocName, index)"
                        @mouseleave="mouseleave(index)"
                      >
                        <label for="exampleInputEmail1">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            :disabled="
                              disableUpload(index, doc.ShippingDocName)
                            "
                            v-on:change="
                              handleFileUploadShippingDocuments(index, $event)
                            "
                          />
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ doc.displayFileName }}</b>
                              <small>
                                <u
                                  style="color: blue; cursor: pointer"
                                  v-if="
                                    doc.ShippingDocumentsRemoveDisabled ===
                                    false
                                  "
                                  v-on:click="removeShippingDocumentsDoc(index)"
                                  >Remove</u
                                >
                              </small>
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.errors">
                              {{ doc.errors }}
                            </p>
                          </small>
                          <!-- <small>
                            <p class="error" v-if="doc.errors.filesize">
                              {{ doc.errors.filesize }}
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.errors.docName">
                              {{ doc.errors.docName }}
                            </p>
                          </small> -->
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Uploading Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="ShippingDocumentsDate"
                          id="ShippingDocumentsDate"
                          placeholder="Select Date"
                          v-model="doc.ShippingDocumentsDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabledDates="{ from: new Date() }"
                          :calendar-button="true"
                        ></datepicker>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @click="receiptValidation(doc.displayFileName)"
                      >
                        <label for="exampleInputEmail1">Receipt No.</label>
                        <input
                          id="receipt_number"
                          class="form-control"
                          name="receipt_number"
                          v-model="doc.receipt_number"
                          @keypress="isAlphaNum($event)"
                          :disabled="doc.displayFileName === null"
                          :class="{
                            'is-invalid':
                              (receiptValidate &&
                                doc.displayFileName === null) ||
                              (ShippingDocuments[index].receipt_number
                                ? ShippingDocuments[index].receipt_number
                                    .length > 20
                                : false),
                          }"
                        />
                        <div
                          v-if="doc.displayFileName === null"
                          class="invalid-feedback"
                        >
                          <span v-if="receiptValidate"
                            >Please upload the Document</span
                          >
                        </div>
                        <div
                          v-if="
                            ShippingDocuments[index].receipt_number
                              ? ShippingDocuments[index].receipt_number.length >
                                20
                              : false
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              ShippingDocuments[index].receipt_number
                                ? ShippingDocuments[index].receipt_number
                                    .length > 20
                                : false
                            "
                            >Max length of 20 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          id="docDelete"
                          v-on:click="deleteShippingDocuments(index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onShippingDocumentSaveInfo()"
                        :disabled="isDisabledShippingDocumentSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    id="submit"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a @click="$router.go(-1)" style="cursor: pointer">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: null,
      Ports: [],
      selectedBorrower: null,
      selectedFacilityId: null,
      selectedCollateral: null,
      selectedCollateralType: null,
      CMCompany:null,
      selectedContractNumber: null,
      selectedPartialContractNumber: null,
      logistics_shipping_flag: null,
      selectedContracts: [],
      selectedOfftakerContractFlag: true,
      selectedReleaseType: null,
      selectedOfftaker: null,
      selectedShippingPort: null,
      selectedDestinationPort: null,
      shippingLine: null,
      portAcceptanceDate: null,
      billOfLadingNo: null,
      shippingDate: null,
      vesselName: null,
      deliveryDate: null,
      shippingBackcapDate: null,
      enableShippingDate: false,
      releasedQty: null,
      netWeight: null,
      grossWeight: null,
      tareWeight: null,
      bags: null,

      //list for Containers
      Containers: [],

      //list for Shipping Documents
      ShippingDocumentNames: [],
      ShippingDocuments: [],
      ShippingDocDelete: [],
      ShippingDocumentsFile: null,
      ShippingDocumentsDisabled: true,
      ShippingDocumentsSaveInfoDisabled: true, //save info button is disabled
      ShippingDocumentsAddMoreDisabled: true,
      msg: [],
      error: "",
      isSubmitted: false,
      onSubmitDisabled: false,
      receiptValidate: false,
    };
  },
  validations: {
    selectedShippingPort: {
      required,
    },
    selectedDestinationPort: {
      required,
    },
    shippingLine: {
      required,
      maxLength: maxLength(50),
    },
    portAcceptanceDate: {
      required,
    },
    vesselName: {
      required,
      maxLength: maxLength(50),
    },
    releasedQty: {
      required,
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },

    //this is to computed disabled property of ShippingDocuments Save Info button.
    isDisabledShippingDocumentSaveInfo: function () {
      let checkEmptyShippingDocumentsDocName = this.ShippingDocuments.filter(
        (line) => line.ShippingDocName === null
      );
      let checkEmptyuploading_date = this.ShippingDocuments.filter(
        (line) => line.uploading_date === null
      );
      //if the line is empty then disable save info button
      if (
        this.ShippingDocuments.length === 0 &&
        this.ShippingDocumentsSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyShippingDocumentsDocName.length >= 1 &&
          this.ShippingDocuments.length > 0) ||
        (checkEmptyuploading_date.length >= 1 &&
          this.ShippingDocuments.length > 0)
      ) {
        this.ShippingDocumentsDisabled = true;
      } else {
        this.ShippingDocumentsDisabled = false;
      }
      //ShippingDocumentsDisabled variable is to check whther all input fields except file upload are filled. ShippingDocumentsFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.ShippingDocumentsDisabled === true ||
        this.ShippingDocumentsFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledShippingDocumentSaveInfo() close
  },
  components: {
    Datepicker,
  },
  methods: {
    receiptValidation(docName) {
      this.receiptValidate = docName === null ? true : false;
    },

    isAlphaNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },

    //this method will make disabled property as false for Shipping Save Info
    onShippingDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.ShippingDocumentsSaveInfoDisabled = false;
      this.ShippingDocumentsAddMoreDisabled = false;
      console.log(this.ShippingDocuments);
    },

    //this function deletes ShippingDocument record
    deleteShippingDocuments(index) {
      let deleteDocId = this.ShippingDocuments[index].document_id;
      console.log(deleteDocId);
      let deleteId = this.ShippingDocuments[index].id;
      this.ShippingDocDelete.push({
        userId: this.$store.getters.getuserId,
        clientId: this.$store.state.client_id,
        moduleId: 8,
        tabId: 24,
        id: deleteId,
        docId: deleteDocId,
        type: "Document",
      });
      this.ShippingDocumentsFile = this.ShippingDocumentsFile
        ? this.ShippingDocumentsFile
        : "file deleted";
      this.ShippingDocuments.splice(index, 1);
      if (
        typeof this.ShippingDocuments !== "undefined" &&
        this.ShippingDocuments.length == 0
      ) {
        this.ShippingDocumentsAddMoreDisabled = false;
        this.ShippingDocumentsSaveInfoDisabled = true;
      }
    },

    //this method will submit the Shipping form in backend.
    onSubmit() {
      this.$confirm({
        auth: false,
        message: "Confirm Submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            this.isSubmitted = true;
            this.$v.$touch();
            if (
              this.$v.$invalid ||
              this.selectedShippingPort === this.selectedDestinationPort
            ) {
              return;
            }
            if (this.portAcceptanceDate != null) {
              this.portAcceptanceDate = moment(this.portAcceptanceDate).format(
                "MM-DD-YYYY"
              );
            }
            if (this.shippingDate != null) {
              this.shippingDate = moment(this.shippingDate).format(
                "MM-DD-YYYY"
              );
            }
            if (this.deliveryDate != null) {
              this.deliveryDate = moment(this.deliveryDate).format(
                "MM-DD-YYYY"
              );
            }
            for (let i = 0; i < this.ShippingDocuments.length; i++) {
              if (this.ShippingDocuments[i].document_name === null) {
                this.ShippingDocuments.splice(i, 1);
              } else {
                if (this.ShippingDocuments[i].ShippingDocumentsDate !== null) {
                  this.ShippingDocuments[i].ShippingDocumentsDate = moment(
                    this.ShippingDocuments[i].ShippingDocumentsDate
                  ).format("MM-DD-YYYY");
                }
              }
            }
            //initialize the form data
            let formData = new FormData();
            formData.append("shippingPort", this.selectedShippingPort);
            formData.append("destinationPort", this.selectedDestinationPort);
            formData.append("shippingLine", this.shippingLine);
            formData.append("deliveryDate", this.deliveryDate);
            formData.append("shippingDate", this.shippingDate);
            formData.append("vesselName", this.vesselName);
            formData.append("moduleId", 8);
            formData.append("tabId", 24);
            formData.append("userId", this.$store.getters.getuserId);
            formData.append("clientId", this.$store.state.client_id);
            formData.append(
              "shippingDocuments",
              JSON.stringify(this.ShippingDocuments)
            );
            formData.append(
              "DeleteDocData",
              JSON.stringify(this.ShippingDocDelete)
            );
            const path = "edit-shipment/" + this.$route.params.id;
            Utils.start_loading();
            axios
              .post(path, formData, {
                headers: {
                  enctype: "multipart/form-data",
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Shipping Info Updated successfully!"
                  );
                  console.log("SUCCESS!!");
                  this.$router.push("/shipping");
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                console.log(error.response.data);
                Utils.stop_loading();
                console.error(error);
              });
          }
        },
      });
    }, //onSubmit() close

    //this method will add new line for additional Collateral Documents
    addShippingDoc() {
      //when new line is added then ShippingDocumentsFile variable and ShippingDocumentsSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.ShippingDocumentsFile = null),
        (this.ShippingDocumentsSaveInfoDisabled = true),
        (this.ShippingDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.ShippingDocuments.push({
          id: null,
          document_id: null,
          ShippingDocName: null,
          ShippingDocumentsDate: new Date(),
          displayFileName: null,
          receipt_number: null,
          errors: "",
          ShippingDocumentsRemoveDisabled: true,
        });
    }, //addShippingDoc() close
    mouseenter(docName, index) {
      if (docName === null) {
        this.ShippingDocuments[index].errors = "Document name is not selected";
      }
    },
    mouseleave(docName, index) {
      if (docName === null) this.ShippingDocuments[index].errors = "";
    },
    disableUpload(id, docName) {
      if (docName === null) {
        return true;
      } else {
        return false;
      }
    },
    //handles a change on the file upload
    handleFileUploadShippingDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.ShippingDocumentsFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.ShippingDocumentsFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors = "File size should be less than 100MB";
          self.ShippingDocuments[id].errors = JSON.stringify(
            response.errors
          ).replace(/\"/g, "");
          self.ShippingDocuments[id].selectedFile = null;
          self.ShippingDocuments[id].selectedFileName = null;
          self.ShippingDocuments[id].displayFileName = null;
          self.ShippingDocuments[id].ShippingDocumentsRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.ShippingDocumentsFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.ShippingDocuments[id].selectedFile = reader.result;
          self.ShippingDocuments[id].selectedFileName = e.target.files[0].name;
          self.ShippingDocuments[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.ShippingDocuments[id].errors = "";
          self.ShippingDocuments[id].ShippingDocumentsRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadShippingDocuments() close

    ////this method handles remove doc attachment
    removeShippingDocumentsDoc(id) {
      this.ShippingDocumentsFile = null;
      this.ShippingDocuments[id].selectedFile = null;
      this.ShippingDocuments[id].selectedFileName = null;
      this.ShippingDocuments[id].displayFileName = null;
      this.ShippingDocuments[id].ShippingDocumentsRemoveDisabled = true;
    },

    //this function is called to get list of ports
    getPorts() {
      const path = "port_master";
      axios
        .get(path)
        .then((res) => {
          this.Ports = res.data.Port;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of document names
    getShippingDocs() {
      const path = "shipping-documents";
      axios
        .get(path)
        .then((res) => {
          this.ShippingDocumentNames = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getShipment() {
      //this function is called on page load and created events. it will show details on Shipping with current ShippingId
      const path = "shipment-overview/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.selectedContracts = res.data.offtaker_contract_list;
            let shipping_details_array = res.data.shipping_list.map(shipItem => {
                // Assuming you want to check only the first entry of this.selectedContracts
                const firstContract = this.selectedContracts[0];
                // Determine the correct logistics_shipping_flag based on the first entry of selectedContracts
                const logisticsShippingFlag = firstContract.offtaker_contract_partial_fill_id == null 
                                              ? firstContract.oct_logistics_shipping_flag 
                                              : firstContract.ocpf_logistics_shipping_flag;

                console.log("logisticsShippingFlag", logisticsShippingFlag);
              return {
                ...shipItem,
                logistics_shipping_flag: logisticsShippingFlag
              };
            });
            console.log("shipping_details_array",shipping_details_array)
            let shipping_details = shipping_details_array[0];
            console.log("shipping_details.logistics_shipping_flag",shipping_details.logistics_shipping_flag)
              
            this.selectedBorrower = shipping_details.borrower_name;
            this.selectedFacilityId = shipping_details.facility_registration_id;
            this.selectedCollateral = shipping_details.collateral_name;
            this.selectedCollateralType = shipping_details.stage_name;
            this.CMCompany = shipping_details.collateralIdCompany;
            this.selectedOfftaker = shipping_details.offtaker_name;
            this.logistics_shipping_flag = shipping_details.logistics_shipping_flag;
            if(this.logistics_shipping_flag == 2){
              this.selectedContractNumber = this.selectedContracts[0].contract_number
              this.selectedPartialContractNumber = this.selectedContracts[0].partial_contract_number
            }
            this.selectedShippingPort = shipping_details.shipping_port_id;
            this.selectedDestinationPort = shipping_details.destination_port_id;
            this.shippingLine = shipping_details.shipping_line;
            this.vesselName = shipping_details.vessel_name;
            this.billOfLadingNo = shipping_details.bill_of_landing_number;
            this.portAcceptanceDate = shipping_details.port_acceptance_date;
            this.shippingDate = shipping_details.shipping_date;
            this.deliveryDate = shipping_details.delivery_date;
            this.releasedQty = shipping_details.release_quantity.replaceAll(
              ",",
              ""
            );
            this.bags = shipping_details.bags.replaceAll(",", "");
            this.netWeight = shipping_details.net_weight.replaceAll(",", "");
            this.grossWeight = shipping_details.gross_weight.replaceAll(
              ",",
              ""
            );
            this.tareWeight = shipping_details.tare_weight.replaceAll(",", "");
            this.Containers = res.data.shipping_container_mapping_list;
            this.ShippingDocuments = res.data.shipping_documents_list;
            this.ShippingDocumentsAddMoreDisabled = false;
            if(shipping_details.offtaker_contract !== null) {
              this.selectedOfftakerContractFlag = true;
            }
            else{
              this.selectedOfftakerContractFlag = false;
            }
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
        });
    }, //getShipment close
  },

  mounted() {
    this.getShipment();
  },

  created() {
    this.id = this.$route.params.id;
    this.getPorts();
    this.getShippingDocs();
  },
};
</script>

<style scoped>
.error {
  color: red;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -70px;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>
