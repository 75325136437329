<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt" id="pageHeading">
                        <i class="fas fa-tachometer-alt"></i> Collateral Manager
                        Overview
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="card-body br_overview">
                <!------menton here style="height: 300px;"-------->
                <table
                  class="table table-hover table-head-fixed text-nowrap"
                  style="table-layout: fixed"
                >
                  <tbody>
                    <tr>
                      <td class="wg" id="companyName">Company Name:</td>
                      <td class="wrap_text" v-if="col_manager.company_name">
                        {{ col_manager.company_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="address">Address:</td>
                      <td class="wrap_text" v-if="col_manager.address">
                        {{ col_manager.address }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="country">Country:</td>
                      <td v-if="col_manager.country_name">
                        {{ col_manager.country_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="state">State:</td>
                      <td v-if="col_manager.state_name">
                        {{ col_manager.state_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="city">City:</td>
                      <td v-if="col_manager.city_name">
                        {{ col_manager.city_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="website">Website:</td>
                      <td class="wrap_text" v-if="col_manager.website">
                        {{ col_manager.website }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card-body br_overview">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td class="wg" id="collateralId">
                        Collateral Manager ID:
                      </td>
                      <td v-if="col_manager.collateral_id">
                        <span> {{ col_manager.collateral_id }}</span>
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="regDate">Registration Date:</td>
                      <td v-if="col_manager.registration_date">
                        {{ col_manager.registration_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="contact">Contact No:</td>
                      <td v-if="col_manager.contact_number">
                        <span
                          >{{ col_manager.country_code }}
                          {{ col_manager.contact_number }}</span
                        >
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="secondaryContact">
                        Secondary Contact No:
                      </td>
                      <td v-if="col_manager.secondary_contact_number">
                        <span
                          >{{ col_manager.secondary_country_code }}
                          {{ col_manager.secondary_contact_number }}</span
                        >
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="email">Email ID:</td>
                      <td v-if="col_manager.email">
                        {{ col_manager.email }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="secondaryEmail">
                        Secondary Email ID:
                      </td>
                      <td v-if="col_manager.secondary_email">
                        {{ col_manager.secondary_email }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!--========================================================================-->

        <div
          class="container-fluid ot_tb pt-3"
          v-if="
            loggedInUserGroupId == 'Admin' || loggedInUserGroupId == 'Analyst'
          "
        >
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Disputed Details</h1>
                    </div>
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li id="disputedHistory">
                          <router-link
                            active-class="active"
                            :to="{
                              name: 'CollateralDisputedHistory',
                              params: {
                                id: id,
                                inactive: inactive,
                              },
                            }"
                          >
                            <a>
                              <u>Disputed History</u>
                            </a>
                          </router-link>
                        </li>
                        <li></li>
                        <li v-if="loggedInUserGroupId == 'Admin' && !inactive">
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-success"
                            @click="showAddDisputeModal"
                          >
                            <i class="icofont-plus"></i>
                            <span class="mob">Add Dispute</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table
                    class="table table-head-fixed text-nowrap table-hover"
                    id="disputedTable"
                  >
                    <thead>
                      <tr>
                        <th id="date">Date</th>
                        <th id="disputedNotes">Notes</th>
                        <th id="employeeName">Employee Name</th>
                        <th
                          id="removeDispute"
                          class="center"
                          v-if="loggedInUserGroupId == 'Admin' && !inactive"
                        >
                          Flag Removal
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="dis in DisputedDetails" :key="dis.id">
                        <td v-if="dis.disputed_date">
                          {{ dis.disputed_date }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="dis.notes" style="white-space: pre-wrap">
                          {{ dis.notes }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="dis.employee_name">
                          {{ dis.employee_name }}
                        </td>
                        <td v-else>-</td>
                        <template
                          v-if="loggedInUserGroupId == 'Admin' && !inactive"
                        >
                          <td
                            class="center"
                            v-if="!dis.disputed_flag"
                            @click="removeDispute(dis.id)"
                          >
                            <i class="icofont-minus-square text-danger"></i>
                          </td>
                          <td class="center" v-else>
                            <i
                              class="icofont-minus-square text-danger"
                              style="opacity: 0.5"
                            ></i>
                          </td>
                        </template>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!--========================================================================-->

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Employee Details</h1>
                    </div>
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li
                          v-if="
                            (loggedInUserGroupId == 'Admin' ||
                              loggedInUserGroupId == 'Analyst' ||
                              loggedInUserGroupId == 'Collateral Manager') &&
                            !inactive
                          "
                        >
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-success"
                            @click="showAddEmployeeModal"
                          >
                            <i class="icofont-plus"></i>
                            <span class="mob">Add Employee</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div
                  class="card-body table-responsive p-0"
                  style="max-height: 200px"
                >
                  <!------menton here style="height: 300px;"-------->
                  <table
                    class="table table-head-fixed text-nowrap table-hover"
                    id="employeeTable"
                  >
                    <thead>
                      <tr>
                        <th id="employeeName">Employee Name</th>
                        <th id="employeeId">Employee ID</th>
                        <th
                          v-if="loggedInUserGroupId == 'Admin' && !inactive"
                        ></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(emp, index) in EmployeeDetails"
                        :key="emp.id"
                        :style="
                          selectedEmployee.id === EmployeeDetails[index].id &&
                          'background: whitesmoke'
                        "
                      >
                        <td
                          v-if="emp.employee_name !== null"
                          @click="expandToggle(index)"
                        >
                          {{ emp.employee_name }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="emp.employee_id !== null" class="c">
                          {{ emp.employee_id }}
                        </td>
                        <td v-else class="c">-</td>
                        <td
                          v-if="loggedInUserGroupId === 'Admin' && !inactive"
                          v-on:click="deleteEmployee(emp.id)"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
                <div
                  class="row"
                  id="employeeContactDetails"
                  v-if="Object.keys(selectedEmployee).length"
                >
                  <div class="col-md-6">
                    <h1 class="headingt">Employee Contact Details</h1>
                    <div class="card-body br_overview">
                      <!------menton here style="height: 300px;"-------->
                      <table
                        class="table table-hover table-head-fixed text-nowrap"
                      >
                        <tbody>
                          <tr>
                            <td class="wg" id="contact">Contact No:</td>
                            <td v-if="selectedEmployee.contact_number">
                              <span
                                >{{ selectedEmployee.country_code }}
                                {{ selectedEmployee.contact_number }}</span
                              >
                            </td>
                            <td v-else>-</td>
                          </tr>

                          <tr>
                            <td class="wg" id="email">Email ID:</td>
                            <td v-if="selectedEmployee.email">
                              {{ selectedEmployee.email }}
                            </td>
                            <td v-else>-</td>
                          </tr>

                          <tr>
                            <td class="wg" id="address">Address:</td>
                            <td
                              style="white-space: pre-wrap"
                              v-if="selectedEmployee.address"
                            >
                              {{ selectedEmployee.address }}
                            </td>
                            <td v-else>-</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <br />
                    <div class="card-body br_overview">
                      <table class="table table-hover">
                        <tbody>
                          <tr>
                            <td class="wg" id="country">Country:</td>
                            <td v-if="selectedEmployee.country_name">
                              {{ selectedEmployee.country_name }}
                            </td>
                            <td v-else>-</td>
                          </tr>

                          <tr>
                            <td class="wg" id="state">State:</td>
                            <td v-if="selectedEmployee.state_name">
                              {{ selectedEmployee.state_name }}
                            </td>
                            <td v-else>-</td>
                          </tr>

                          <tr>
                            <td class="wg" id="city">City:</td>
                            <td v-if="selectedEmployee.city_name">
                              {{ selectedEmployee.city_name }}
                            </td>
                            <td v-else>-</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!--========================================================================-->

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Agreement Details</h1>
                    </div>
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li id="agreementHistory">
                          <router-link
                            active-class="active"
                            :to="{
                              name: 'CollateralAgreementHistory',
                              params: {
                                id: id,
                              },
                            }"
                          >
                            <a>
                              <u>Agreement History</u>
                            </a>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap table-hover">
                    <thead>
                      <tr>
                        <th></th>
                        <th id="aggStartDate">Agreement Start Date</th>
                        <th id="aggEndDate">Agreement Termination Date</th>
                        <th id="fees">Fees</th>
                        <th id="currency">Currency</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="agg in AgreementDetails" :key="agg.id">
                        <td
                          v-on:click="
                            downloadDocument(agg.document_path, 'agreement')
                          "
                          :style="{ cursor: 'pointer' }"
                          class="center"
                        >
                          <i
                            id="buttons"
                            class="icofont-download text-info"
                          ></i>
                        </td>
                        <td v-if="agg.agreement_start_date">
                          {{ agg.agreement_start_date }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="agg.agreement_end_date" class="c">
                          {{ agg.agreement_end_date }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="agg.fees">
                          {{ agg.fees }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="agg.currency_short_name">
                          {{ agg.currency_short_name }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!--========================================================================-->

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Documents</h1>
                    </div>
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li id="collateralDocHistory">
                          <router-link
                            active-class="active"
                            :to="{
                              name: 'CollateralDocHistory',
                              params: {
                                id: id,
                              },
                            }"
                          >
                            <a>
                              <u>Documents History</u>
                            </a>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th></th>
                        <th id="doc">Documents</th>
                        <th class="c" id="uploadDate">Issue Date</th>
                        <th class="c" id="expiryDate">Expiry Date</th>
                        <th class="c" id="status">Status</th>
                        <th
                          class="c"
                          v-if="
                            loggedInUserGroupId == 'Admin' &&
                            loggedInUserSubGroupId == 0 &&
                            !inactive
                          "
                        >
                          Change Status
                        </th>
                        <th class="c center">Comments</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(doc, index) in CollateralDocuments"
                        :key="doc.id"
                      >
                        <td
                          v-if="doc.CollateralDocName !== null"
                          v-on:click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                          class="center"
                        >
                          <i
                            id="buttons"
                            class="icofont-download text-info"
                          ></i>
                        </td>
                        <td class="c" v-else></td>
                        <td v-if="doc.CollateralDocName">
                          {{ doc.CollateralDocName }}
                        </td>
                        <td class="c" v-else>-</td>
                        <td
                          v-if="doc.CollateralDocumentsDate !== null"
                          class="c"
                        >
                          {{ doc.CollateralDocumentsDate }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="doc.ExpiryDate !== null" class="c">
                          {{ doc.ExpiryDate }}
                        </td>
                        <td v-else class="c">-</td>
                        <td
                          v-if="doc.document_status !== null"
                          class="c"
                          :class="{
                            'c text-success':
                              doc.document_status === 'Approved',
                            'c text-warning':
                              doc.document_status === 'Received',
                            'c text-danger': doc.document_status === 'Rejected',
                            'c text-info':
                              doc.document_status === 'Near to Expiry',
                            'c text-dark': doc.document_status === 'Expired',
                          }"
                        >
                          {{ doc.document_status }}
                        </td>
                        <td v-else>-</td>
                        <td
                          class="c status"
                          v-if="
                            loggedInUserGroupId == 'Admin' &&
                            loggedInUserSubGroupId == 0 &&
                            !inactive
                          "
                        >
                          <div class="status">
                            <select
                              id="docStatus"
                              name="docStatus"
                              @change="
                                onChangeDocStatus(
                                  $event,
                                  doc.collateral_document_id,
                                  doc.document_status,
                                  index
                                )
                              "
                              :disabled="doc.document_status !== 'Received'"
                              class="form-control"
                              :value="doc.document_status"
                            >
                              <option value="Received">Received</option>
                              <option value="Approved">Approved</option>
                              <option value="Rejected">Rejected</option>
                              <option
                                v-if="doc.document_status === 'Near to Expiry'"
                                value="Near to Expiry"
                              >
                                Near to Expiry
                              </option>
                              <option
                                v-if="doc.document_status === 'Expired'"
                                value="Expired"
                              >
                                Expired
                              </option>
                            </select>
                          </div>
                        </td>
                        <td
                          v-if="!inactive"
                          class="c center"
                          v-on:click="showCommentModal(doc.comments)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-comment text-info"></i>
                        </td>
                        <td v-else class="c center">
                          <i
                            style="opacity: 0.5"
                            class="icofont-comment text-info"
                          ></i>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div
          class="container-fluid ot_tb pt-3"
          v-if="loggedInUserGroupId == 'Admin'"
        >
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Administrative Documents</h1>
                    </div>
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li id="collateralAdminDocHistory">
                          <router-link
                            active-class="active"
                            :to="{
                              name: 'CollateralAdminDocHistory',
                              params: {
                                id: id,
                              },
                            }"
                          >
                            <a>
                              <u>Admin Documents History</u>
                            </a>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th></th>
                        <th id="doc">Documents</th>
                        <th class="c" id="uploadDate">Issue Date</th>
                        <th class="c" id="expiryDate">Expiry Date</th>
                        <th class="c center">Comments</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="doc in CollateralAdminDocuments" :key="doc.id">
                        <td
                          v-if="doc.CollateralAdminDocName !== null"
                          v-on:click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                          class="center"
                        >
                          <i
                            id="buttons"
                            class="icofont-download text-info"
                          ></i>
                        </td>
                        <td class="c" v-else></td>
                        <td v-if="doc.CollateralAdminDocName">
                          {{ doc.CollateralAdminDocName }}
                        </td>
                        <td class="c" v-else>-</td>
                        <td v-if="doc.IssueDate !== null" class="c">
                          {{ doc.IssueDate }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="doc.ExpiryDate !== null" class="c">
                          {{ doc.ExpiryDate }}
                        </td>
                        <td v-else class="c">-</td>
                        <td
                          v-if="!inactive"
                          class="c center"
                          v-on:click="showCommentModal(doc.comments)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-comment text-info"></i>
                        </td>
                        <td v-else class="c center">
                          <i
                            style="opacity: 0.5"
                            class="icofont-comment text-info"
                          ></i>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.Tabpane second end-->
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper -->

    <!-- ./wrapper -->
    <div class="modal fade" id="add_dispute">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Add Dispute</h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label for="disputeDate" class="required-field">Date</label>
                  <datepicker
                    class="datepop"
                    :bootstrap-styling="true"
                    id="disputeDate"
                    placeholder="Select Date"
                    v-model="disputeDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :class="{
                      'is-invalid': isSubmitted && $v.disputeDate.$error,
                    }"
                  ></datepicker>
                  <div
                    v-if="isSubmitted && $v.disputeDate.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.disputeDate.required"
                      >Date is required</span
                    >
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-6">
                <div class="form-group fc">
                  <label>Employee Name</label>
                  <select
                    id="disputeEmployeeName"
                    class="form-control"
                    v-model="disputeEmployeeName"
                  >
                    <option :value="null">-- Select an Employee Name --</option>
                    <option
                      v-for="emp in EmployeeDetails"
                      :value="emp.id"
                      :key="emp.id"
                    >
                      {{ emp.employee_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-12 col-sm-12 col-12">
                <div class="form-group fc">
                  <label for="disputeNotes" class="required-field">Notes</label>
                  <textarea
                    id="disputeNotes"
                    class="form-control"
                    style="height: 60px !important; resize: none"
                    v-model="disputeNotes"
                    :class="{
                      'is-invalid': isSubmitted && $v.disputeNotes.$error,
                    }"
                  ></textarea>
                  <div
                    v-if="isSubmitted && $v.disputeNotes.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.disputeNotes.required"
                      >Notes is required</span
                    >
                    <span v-if="!$v.disputeNotes.maxLength"
                      >Max length of 500 characters exceeded</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onDisputeSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ./wrapper -->
    <div class="modal fade" id="add_employee">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content" style="width: 120%">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Add Employee</h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-3">
                <div class="form-group fc">
                  <label class="required-field">Employee Name</label>
                  <input
                    class="form-control"
                    id="employeeName"
                    type="text"
                    v-model="employeeName"
                    required
                    :class="{
                      'is-invalid': isSubmitted && $v.employeeName.$error,
                    }"
                  />
                  <div
                    v-if="isSubmitted && $v.employeeName.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.employeeName.required"
                      >Name is required</span
                    >
                    <span v-if="!$v.employeeName.maxLength"
                      >Max length of 100 characters exceeded</span
                    >
                    <span v-if="!$v.employeeName.isAlpha"
                      >Name should be alphabets only</span
                    >
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field"
                    >Country</label
                  >
                  <select
                    id="selectedCountry"
                    class="form-control"
                    name="selectedCountry"
                    v-model="selectedCountry"
                    @change="getStatesByCountry()"
                    :class="{
                      'is-invalid': isSubmitted && $v.selectedCountry.$error,
                    }"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="country in countries"
                      :value="country.id"
                      :key="country.id"
                    >
                      {{ country.country_name }}
                    </option>
                  </select>
                  <div
                    v-if="isSubmitted && $v.selectedCountry.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.selectedCountry.required"
                      >Country is required</span
                    >
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field">State</label>
                  <select
                    class="form-control"
                    name="selectedState"
                    id="selectedState"
                    v-model="selectedState"
                    @change="getCitiesByState()"
                    :class="{
                      'is-invalid': isSubmitted && $v.selectedState.$error,
                    }"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="state in states"
                      :value="state.id"
                      :key="state.id"
                    >
                      {{ state.state_name }}
                    </option>
                  </select>
                  <div
                    v-if="isSubmitted && $v.selectedState.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.selectedState.required"
                      >State is required</span
                    >
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="form-group fc">
                  <label class="required-field">City</label>
                  <select
                    id="selectedCity"
                    class="form-control"
                    name="selectedCity"
                    v-model="selectedCity"
                    :class="{
                      'is-invalid': isSubmitted && $v.selectedCity.$error,
                    }"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="city in cities"
                      :value="city.id"
                      :key="city.id"
                    >
                      {{ city.city_name }}
                    </option>
                  </select>
                  <div
                    v-if="isSubmitted && $v.selectedCity.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.selectedCity.required"
                      >City is required</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group fc">
                  <label for="employeeAddress" class="required-field"
                    >Address</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="employeeAddress"
                    v-model="employeeAddress"
                    :class="{
                      'is-invalid': isSubmitted && $v.employeeAddress.$error,
                    }"
                  />
                  <div
                    v-if="isSubmitted && $v.employeeAddress.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.employeeAddress.required"
                      >Address is required</span
                    >
                    <span v-if="!$v.employeeAddress.maxLength"
                      >Max length of 250 characters exceeded</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group fc">
                  <label for="employeeEmail" class="required-field"
                    >Email ID</label
                  >
                  <input
                    type="email"
                    class="form-control"
                    v-model="employeeEmail"
                    id="employeeEmail"
                    name="employeeEmail"
                    @keyup="validateEmail(employeeEmail)"
                    :class="{
                      'is-invalid':
                        (isSubmitted && $v.employeeEmail.$error) ||
                        msg.employeeEmail,
                    }"
                  />
                  <div class="invalid-feedback" v-if="msg.employeeEmail">
                    <span>{{ msg.employeeEmail }}</span>
                  </div>
                  <div
                    v-if="isSubmitted && $v.employeeEmail.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.employeeEmail.required"
                      >Email ID is required</span
                    >
                    <span v-if="!$v.employeeEmail.maxLength"
                      >Max length of 50 characters exceeded</span
                    >
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group fc">
                  <label for="employeeContact" class="required-field"
                    >Contact No</label
                  ><br />
                  <select
                    id="employeeContactCode"
                    class="contact_code"
                    name="employeeContactCode"
                    v-model="employeeContactCode"
                    :class="{
                      'is-invalid':
                        isSubmitted && $v.employeeContactCode.$error,
                    }"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="code in countryCodes"
                      :key="code.id"
                      :value="code.id"
                    >
                      {{ code.country_code }}
                    </option>
                  </select>
                  <input
                    class="contact"
                    v-model="employeeContact"
                    id="employeeContact"
                    :class="{
                      'is-invalid': isSubmitted && $v.employeeContact.$error,
                    }"
                  />
                  <div
                    v-if="isSubmitted && $v.employeeContactCode.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.employeeContactCode.required"
                      >Contact Number Code is required</span
                    >
                  </div>
                  <div
                    v-if="isSubmitted && $v.employeeContact.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.employeeContact.required"
                      >Contact Number is required</span
                    >
                    <span v-if="!$v.employeeContact.minLength"
                      >Min length of 10 characters required<br
                    /></span>
                    <span v-if="!$v.employeeContact.maxLength"
                      >Max length of 13 characters exceeded<br
                    /></span>
                    <span v-if="!$v.employeeContact.numeric"
                      >Contact Number should be numeric only</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    @click="onEmployeeSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ./modal start -->
    <div class="modal fade" id="comm">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title"><i class="icofont-comment"></i>Comments</h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <textarea
                  rows="4"
                  cols="60"
                  style="outline: none"
                  v-model="comment"
                ></textarea>
                <!-- <div
                  v-if="isSubmitted && $v.comment.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.comment.maxLength"
                    >Max length of 50 characters exceeded</span
                  >
                </div> -->
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./modal end -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { helpers, numeric } from "vuelidate/lib/validators";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import moment from "moment";
export default {
  data() {
    return {
      id: null,
      col_manager: [],
      DisputedDetails: [],
      EmployeeDetails: [],
      AgreementDetails: [],
      AgreementHistoryDetails: [],
      CollateralDocuments: [],
      CollateralAdminDocuments: [],
      countries: [],
      states: [],
      cities: [],
      countryCodes: [],
      selectedEmployee: {},
      employeeName: null,
      employeeAddress: null,
      selectedCountry: null,
      selectedState: null,
      selectedCity: null,
      employeeContact: null,
      employeeContactCode: null,
      employeeEmail: null,
      disputeDate: null,
      disputeEmployeeName: null,
      disputeNotes: null,
      comment: null,
      isSubmitted: false,
      checkValidation: "",
      inactive: false,
      msg: {},
    };
  },

  validations: {
    disputeDate: {
      required: function () {
        if (this.checkValidation === "dispute" && this.disputeDate === null)
          return false;
        else return true;
      },
    },
    disputeNotes: {
      required: function () {
        if (this.checkValidation === "dispute" && this.disputeNotes === null)
          return false;
        else return true;
      },
      maxLength: function () {
        if (
          this.checkValidation === "dispute" &&
          this.disputeNotes !== null &&
          this.disputeNotes.length > 500
        )
          return false;
        else return true;
      },
    },
    employeeName: {
      isAlpha: helpers.regex("isAlpha", /^[a-zA-Z ]*$/),
      required: function () {
        if (this.checkValidation === "employee" && this.employeeName === null)
          return false;
        else return true;
      },
      maxLength: function () {
        if (
          this.checkValidation === "employee" &&
          this.employeeName !== null &&
          this.employeeName.length > 100
        )
          return false;
        else return true;
      },
    },
    selectedCountry: {
      required: function () {
        if (
          this.checkValidation === "employee" &&
          this.selectedCountry === null
        )
          return false;
        else return true;
      },
    },
    selectedState: {
      required: function () {
        if (
          this.checkValidation === "employee" &&
          this.selectedState === null
        )
          return false;
        else return true;
      },
    },
    selectedCity: {
      required: function () {
        if (this.checkValidation === "employee" && this.selectedCity === null)
          return false;
        else return true;
      },
    },
    employeeAddress: {
      required: function () {
        if (
          this.checkValidation === "employee" &&
          this.employeeAddress === null
        )
          return false;
        else return true;
      },
      maxLength: function () {
        if (
          this.checkValidation === "employee" &&
          this.employeeAddress !== null &&
          this.employeeAddress.length > 250
        )
          return false;
        else return true;
      },
    },
    employeeEmail: {
      required: function () {
        if (this.checkValidation === "employee" && this.employeeEmail === null)
          return false;
        else return true;
      },
      maxLength: function () {
        if (
          this.checkValidation === "employee" &&
          this.employeeEmail !== null &&
          this.employeeEmail.length > 50
        )
          return false;
        else return true;
      },
    },
    employeeContact: {
      numeric,
      required: function () {
        if (
          this.checkValidation === "employee" &&
          this.employeeContact === null
        )
          return false;
        else return true;
      },
      minLength: function () {
        if (
          this.checkValidation === "employee" &&
          this.employeeContact !== null &&
          this.employeeContact.length < 10
        )
          return false;
        else return true;
      },
      maxLength: function () {
        if (
          this.checkValidation === "employee" &&
          this.employeeContact !== null &&
          this.employeeContact.length > 13
        )
          return false;
        else return true;
      },
    },
    employeeContactCode: {
      required: function () {
        if (
          this.checkValidation === "employee" &&
          this.employeeContactCode === null
        )
          return false;
        else return true;
      },
    },
  },

  components: {
    Datepicker,
  },
  watch: {
    employeeEmail(value) {
      // binding this to the data value in the employeeEmail input
      this.employeeEmail = value;
      this.validateEmail(value);
    },
  },
  methods: {
    validateEmail(value) {
      if (/^\w+([\.-]\w+)*@\D\w+\.(\D{2,3})$/.test(value) || value === "") {
        this.msg["employeeEmail"] = "";
      } else {
        this.msg["employeeEmail"] = "Invalid Email Address";
      }
    },

    // opens Add Employee Modal
    showAddEmployeeModal() {
      this.checkValidation = "employee";
      $("#add_employee").modal("show");
    }, //showAddEmployeeModal() close

    // opens Add Dispute Modal
    showAddDisputeModal() {
      this.checkValidation = "dispute";
      $("#add_dispute").modal("show");
    }, //showAddDisputeModal() close

    // opens the comment modal.
    showCommentModal(comm) {
      this.comment = comm;
      $("#comm").modal("show");
    }, //showCommentModal() close

    // shows details of selected employee from employee details table
    expandToggle(i) {
      if (this.EmployeeDetails[i].id === this.selectedEmployee.id) {
        this.selectedEmployee = {};
      } else {
        this.selectedEmployee = this.EmployeeDetails[i];
      }
    },

    // this function is used to add a particular dispute
    onDisputeSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      Utils.start_loading();
      if (this.disputeDate != null) {
        this.disputeDate = moment(this.disputeDate).format("MM-DD-YYYY");
      }
      let employee_name = null;
      if (this.disputeEmployeeName) {
        let index = this.EmployeeDetails.findIndex(
          (e) => e.id === this.disputeEmployeeName
        );
        employee_name = this.EmployeeDetails[index].employee_name;
      }
      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("dispute_date", this.disputeDate);
      formData.append("dispute_employee_name", employee_name);
      formData.append("collateral_employee_id", this.disputeEmployeeName);
      formData.append("dispute_notes", this.disputeNotes);
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", JSON.stringify(this.$store.getters.getuserId));
      formData.append("moduleId", 6);
      formData.append("tabId", 25);
      const path = "disputed-details/" + this.id;
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          if (response.data.status == "success") {
            Utils.make_alert("success", "Dispute added successfully!");
            //if you want to send any data into server before redirection then you can do it here
            setTimeout(() => this.$router.go(), 1500);
          } else {
            Utils.make_alert("warning", "Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.log("error: " + error);
          Utils.make_alert("warning", "Something went wrong!");
        });
    },

    // this function is used to get disputes for a particular collateral manager
    getDisputedDetails() {
      const path = "disputed-details/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.DisputedDetails = this.inactive
              ? res.data.collateral_disputed
                  .collateral_disputed_details_inactive
              : res.data.collateral_disputed.collateral_disputed_details_active;
            if(this.DisputedDetails.length > 0) {
              this.DisputedDetails.forEach(item => {
                if (item.disputed_date != '-' && item.disputed_date != null) {
                  item.disputed_date = moment(item.disputed_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.disputed_date = '-';
                }
              });
            }
            if (this.DisputedDetails.length) {
              // showing latest 3 disputed details
              this.DisputedDetails = this.DisputedDetails.slice(0, 3);
            }
            console.log("get Collateral Disputed details is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getDisputedDetails() close

    // this function is used to remove a particular dispute
    removeDispute(disputeId) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to remove the dispute?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path = "disputed-details/" + this.id;
            axios
              .put(
                path,
                {
                  id: disputeId,
                  updateType: "flag",
                  flag: 1,
                  userId: this.$store.getters.getuserId,
                  moduleId: 6,
                  tabId: 25,
                  clientId: this.$store.state.client_id,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("dispute flag update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Dispute Flag removed successfully!"
                  );
                  setTimeout(() => {
                    this.$router.go();
                  }, 1500);
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          }
        },
      });
    },

    // this function is used to add a particular employee
    onEmployeeSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      Utils.start_loading();
      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("EmployeeName", this.employeeName);
      formData.append("selectedCountry", this.selectedCountry);
      formData.append("selectedState", this.selectedState);
      formData.append("selectedCity", this.selectedCity);
      formData.append("Address", this.employeeAddress);
      formData.append("EmailID", this.employeeEmail);
      formData.append("Contact", this.employeeContact);
      formData.append("ContactCode", this.employeeContactCode);
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", JSON.stringify(this.$store.getters.getuserId));
      formData.append("moduleId", 6);
      formData.append("tabId", 25);
      const path = "employee-details/" + this.id;
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          if (response.data.status == "success") {
            Utils.make_alert("success", "Employee added successfully!");
            //if you want to send any data into server before redirection then you can do it here
            setTimeout(() => this.$router.go(), 1500);
          } else {
            Utils.make_alert("warning", "Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.log("error: " + error);
          Utils.make_alert("warning", "Something went wrong!");
        });
    },

    deleteEmployee(empId) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to permanently delete?",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete("employee-details/" + this.id, {
                headers: {
                  "Content-type": "application/json",
                },
                data: {
                  employeeId: empId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 6,
                  tabId: 25,
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert("success", "Employee deleted successfully");
                  setTimeout(() => this.$router.go(), 1000);
                } else {
                  Utils.make_alert("success", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.log("error: " + error);
                Utils.make_alert("success", "Something went wrong!");
              });
          }
        },
      });
    },

    //this function is called when user changes the document status. The status is updated in the database for that specific document.
    onChangeDocStatus(updatedStatus, docId, prevStatus, index) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path = "single-collateral-summary-overview-active/" + this.id;
            axios
              .put(
                path,
                {
                  filtertype: "CollateralDocStatus",
                  updateType: "status",
                  collateral_document_id: docId,
                  collateral_manager_id: this.id,
                  updatedStatus: updatedStatus.target.value,
                  userId: this.$store.getters.getuserId,
                  moduleId: 6,
                  tabId: 25,
                  clientId: this.$store.state.client_id,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("kyc update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert("success", "Status updated successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  this.CollateralDocuments[index].document_status =
                    updatedStatus.target.value;
                } else {
                  updatedStatus.target.value = prevStatus;
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                updatedStatus.target.value = prevStatus;
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeDocStatus close

    //this function is called on page load and created events. it will show details on CollateralManager with CollateralManagerid equal to this.id
    getCollateralManager() {
      const path = this.inactive
        ? "single-collateral-summary-overview-inactive/" + this.id
        : "single-collateral-summary-overview-active/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.col_manager = this.inactive
              ? res.data.collateral.collateral_manager_details_inactive[0]
              : res.data.collateral.collateral_manager_details_active[0];
              if (this.col_manager.registration_date != '-' && this.col_manager.registration_date != null) {
                this.col_manager.registration_date = moment(this.col_manager.registration_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                this.col_manager.registration_date = '-';
              }
            if (this.inactive) {
              this.EmployeeDetails =
                res.data.collateral.collateral_employee_details_inactive;
              this.AgreementDetails =
                res.data.collateral.collateral_agreement_details_inactive;

              this.CollateralDocuments =
                res.data.collateral.collateral_documents_details_inactive;

              this.CollateralAdminDocuments =
                res.data.collateral.collateral_admin_documents_details_inactive;
            } else {
              this.EmployeeDetails =
                res.data.collateral.collateral_employee_details_active;

              this.AgreementDetails =
                res.data.collateral.collateral_agreement_details_active;
              this.CollateralDocuments =
                res.data.collateral.collateral_documents_details_active;
              this.CollateralAdminDocuments =
                res.data.collateral.collateral_admin_documents_details_active;
            }
            if (this.EmployeeDetails.length) {
              this.EmployeeDetails.forEach((e) => {
                e.collapsed = false;
              });
            }
            if(this.AgreementDetails.length > 0) {
              this.AgreementDetails.forEach(item => {
                if (item.agreement_start_date != '-' && item.agreement_start_date != null) {
                  item.agreement_start_date = moment(item.agreement_start_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.agreement_start_date = '-';
                }
                if (item.agreement_end_date != '-' && item.agreement_end_date != null) {
                  item.agreement_end_date = moment(item.agreement_end_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.agreement_end_date = '-';
                }
              });
            }
            if(this.CollateralDocuments.length > 0) {
              this.CollateralDocuments.forEach(item => {
                if (item.CollateralDocumentsDate != '-' && item.CollateralDocumentsDate != null) {
                  item.CollateralDocumentsDate = moment(item.CollateralDocumentsDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.CollateralDocumentsDate = '-';
                }
                if (item.ExpiryDate != '-' && item.ExpiryDate != null) {
                  item.ExpiryDate = moment(item.ExpiryDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.ExpiryDate = '-';
                }
              });
            }
            if(this.CollateralAdminDocuments.length > 0) {
              this.CollateralAdminDocuments.forEach(item => {
                if (item.IssueDate != '-' && item.IssueDate != null) {
                  item.IssueDate = moment(item.IssueDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.IssueDate = '-';
                }
                if (item.ExpiryDate != '-' && item.ExpiryDate != null) {
                  item.ExpiryDate = moment(item.ExpiryDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.ExpiryDate = '-';
                }
              });
            }
            if (this.AgreementDetails.length) {
              // showing latest first agreement details
              this.AgreementHistoryDetails = this.AgreementDetails;
              this.AgreementDetails = this.AgreementDetails.slice(0, 3);
            }
            console.log("get CollateralManager details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getCollateralManager close

    downloadDocument: function (filePath, type) {
      if (type === "agreement") {
        if (filePath === null) {
          Utils.make_alert("warning", "No Document Available");
          return;
        }
      }
      Utils.downloadDocument(filePath);
    },

    getCities() {
      //this function is called to get list of cities
      const path = "city";
      axios
        .get(path)
        .then((res) => {
          this.cities = res.data.cities;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getStates() {
      //this function is called to get list of states
      const path = "state";
      axios
        .get(path)
        .then((res) => {
          this.states = res.data.states;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of countries
    getCountries() {
      const path = "country";
      axios
        .get(path)
        .then((res) => {
          this.countries = res.data.countries;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getCountries() close

    getStatesByCountry() {
      if(this.selectedCountry == null){
        this.states = [];
        this.selectedState = null;
        this.cities = [];
        this.selectedCity = null;
        return;
      }
      //this function is called to get list of states
      const path = "get-state-by-country/" + this.selectedCountry ;
      axios
        .get(path)
        .then((res) => {
          this.states = [];
          this.selectedState = null;
          this.cities = [];
          this.selectedCity = null;
          this.states = res.data.states;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCitiesByState() {
      if(this.selectedState == null){
        this.cities = [];
        this.selectedCity = null;
        return;
      }
      //this function is called to get list of cities
      const path = "get-city-by-state/" + this.selectedState;
      axios
        .get(path)
        .then((res) => {
          this.cities = [];
          this.selectedCity = null;
          this.cities = res.data.cities;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of country codes
    getCountryCodes() {
      const path = "country_code_master";
      axios
        .get(path)
        .then((res) => {
          this.countryCodes = res.data.country_codes;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.inactive =
      this.$store.state.inactive === "collateral_inactive" ? true : false;
    this.id = this.$route.params.id;
    this.getCollateralManager();
    this.getCountries();
    this.getCountryCodes();
    this.getDisputedDetails();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style>
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}
#employeeContactDetails {
  border-style: groove;
  border-radius: 5px;
  padding-top: 5px;
}
</style>
