<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="fas fa-tachometer-alt"></i> Inventory Overview
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a
                            @click="
                              $router.push({
                                name: 'Collateral',
                                params: { cleaned: true },
                              })
                            "
                            style="cursor: pointer"
                          >
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="card-body br_overview">
                <!------menton here style="height: 300px;"-------->
                <table class="table table-hover table-head-fixed text-nowrap">
                  <tbody>
                    <tr>
                      <td class="wg" id="borrowerName">Borrower Name:</td>
                      <td v-if="cleanedInfo.length">
                        {{ cleanedInfo[0].borrower_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <!-- <tr>
                      <td class="wg" id="date">Date:</td>
                      <td v-if="date !== null">
                        {{ date }}
                      </td>
                      <td v-else>-</td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card-body br_overview">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td class="wg" id="collateral">Inventory:</td>
                      <td v-if="cleanedInfo.length">
                        {{ cleanedInfo[0].collateral_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-12">
                      <h1 class="headingt" id="pageHeading">
                        Cleaned Received Info
                      </h1>
                    </div>
                    <!-- <div
                      class="col-lg-6 col-sm-6"
                      v-if="loggedInUserGroupId == 'Admin'"
                      id="activeInactive"
                    >
                      <input
                        type="radio"
                        :value="false"
                        @change="destroyTable"
                        v-model="inactive"
                      />
                      <label for="one" style="padding-right: 20px"
                        >Active</label
                      >
                      <input
                        type="radio"
                        :value="true"
                        @change="destroyTable"
                        v-model="inactive"
                      />
                      <label for="two">InActive</label>
                    </div> -->
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table
                    class="table table-head-fixed text-nowrap"
                    id="myTable"
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Utilisation ID</th>
                        <th>Verification ID</th>
                        <!-- <th>Release Request ID</th> -->
                        <th>Warrant Ref.</th>
                        <th>Collateral Quantity</th>
                        <th>No. of Bags</th>
                        <th>Net Wgt after Cleaning</th>
                        <th>Gross Wgt after Cleaning</th>
                        <th>Bags after Cleaning</th>
                        <th>Collateral Price After Cleaning</th>
                        <th>Collateral Value After Cleaning</th>
                        <th>Waste Qty</th>
                        <th>Waste Bags</th>
                        <th>GRN</th>
                        <th>Bean Count</th>
                        <th>Moisture Cont. %</th>
                        <th>Total Mould %</th>
                        <th>Insect Damaged %</th>
                        <th>Total Defect %</th>
                        <th>Sieving %</th>
                        <th>Double Cluster %</th>
                        <th>Triple Cluster %</th>
                        <th>Total Cluster</th>
                        <th>Broken Beans %</th>
                        <th>Fragment</th>
                        <th>Cocoa Rel Matter</th>
                        <th>Foreign Matter %</th>
                        <th>Flat Beans %</th>
                        <th>Slate %</th>
                        <th>Age Before Shipment</th>
                        <th>Age of Stock Bal</th>
                        <th class="c" style="width: 90px"></th>
                        <!-- <th></th> -->
                      </tr>
                    </thead>

                    <tbody>
                      <template v-for="cl in cleanedInfo">
                        <tr id="tdrow" :key="cl.received_info_cleaned_id">
                          <template
                            v-if="
                              loggedInUserGroupId == 'Borrower' ||
                              loggedInUserGroupId == 'Collateral Manager' ||
                              loggedInUserGroupId == 'Admin' ||
                              loggedInUserGroupId == 'Analyst'
                            "
                          >
                            <td v-if="cl.received_date">
                              {{ cl.received_date }}
                            </td>
                            <td v-else>-</td>
                            <td
                              id="loanId"
                              @click="toggle(cl, cl.unit_name)"
                              v-if="cl.loan_registration_id"
                            >
                              {{ cl.loan_registration_id }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.request_id">{{ cl.request_id }}</td>
                            <td v-else>-</td>
                            <!-- <td v-if="cl.release_request_id">
                              {{ cl.release_request_id }}
                            </td>
                            <td v-else>-</td> -->
                            <td v-if="cl.warrant_reference">
                              {{ cl.warrant_reference }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.collateral_quantity">
                              {{ cl.collateral_quantity }} {{ cl.unit_name }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.number_of_bags">
                              {{ cl.number_of_bags }}
                            </td>
                            <td v-if="cl.quantity_derived_after_cleaning">
                              {{ cl.quantity_derived_after_cleaning }}
                              {{ cl.unit_name }}
                            </td>
                            <td v-else>-</td>
                            <td
                              v-if="
                                cl.gross_weight_after_cleaning &&
                                cl.gross_weight_after_cleaning !== '-'
                              "
                            >
                              {{ cl.gross_weight_after_cleaning }}
                              {{ cl.unit_name }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.number_of_bags_after_cleaning">
                              {{ cl.number_of_bags_after_cleaning }}
                            </td>
                            <td v-if="cl.collateral_price_cleaned">
                              {{ cl.collateral_price_cleaned }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.collateral_value_cleaned">
                              {{ cl.collateral_value_cleaned }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.quantity_waste">
                              {{ cl.quantity_waste }} {{ cl.unit_name }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.number_of_bags_waste">
                              {{ cl.number_of_bags_waste }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.grn">{{ cl.grn }}</td>
                            <td v-else>-</td>
                            <td v-if="cl.bean_count">{{ cl.bean_count }}</td>
                            <td v-else>-</td>
                            <td v-if="cl.moisture_content">
                              {{ cl.moisture_content }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.total_mould">{{ cl.total_mould }}</td>
                            <td v-else>-</td>
                            <td v-if="cl.insect_damaged">
                              {{ cl.insect_damaged }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.total_defect">
                              {{ cl.total_defect }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.sieving">{{ cl.sieving }}</td>
                            <td v-else>-</td>
                            <td v-if="cl.double_cluster">
                              {{ cl.double_cluster }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.triple_cluster">
                              {{ cl.triple_cluster }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.total_cluster">
                              {{ cl.total_cluster }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.broken_beans">
                              {{ cl.broken_beans }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.fragment">{{ cl.fragment }}</td>
                            <td v-else>-</td>
                            <td v-if="cl.total_cocoa_related_matter">
                              {{ cl.total_cocoa_related_matter }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.foreign_matter">
                              {{ cl.foreign_matter }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.flat_beans">{{ cl.flat_beans }}</td>
                            <td v-else>-</td>
                            <td v-if="cl.slate">{{ cl.slate }}</td>
                            <td v-else>-</td>
                            <td v-if="cl.age_of_before_shipment">
                              {{ cl.age_of_before_shipment }} {{ cl.period }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="cl.age_of_stock_balance">
                              {{ cl.age_of_stock_balance }} {{ cl.period }}
                            </td>
                            <td v-else>-</td>
                            <td
                              class="c"
                              v-on:click="
                                showDocumentModal(cl.received_info_cleaned_id)
                              "
                              :style="{ cursor: 'pointer' }"
                            >
                              <i class="icofont-file-pdf text-danger"></i>
                            </td>
                            <!-- <template
                              v-if="
                                !inactive &&
                                  (loggedInUserGroupId === 'Admin' ||
                                    loggedInUserGroupId ===
                                      'Collateral Manager')
                              "
                            >
                              <td
                                class="c"
                                v-if="
                                  un.status !==
                                    'Partially Taken for Cleaning' &&
                                    (loggedInUserGroupId === 'Admin' ||
                                      loggedInUserGroupId ===
                                        'Collateral Manager')
                                "
                                v-on:click="
                                  deleteCleaned(
                                    un.received_info_cleaned_id,
                                    un.request_id,
                                    'ReceivedCleaned'
                                  )
                                "
                                :style="{ cursor: 'pointer' }"
                              >
                                <i class="icofont-trash text-danger"></i>
                              </td>
                              <td v-else>
                                <i
                                  style="opacity: 0.5"
                                  class="icofont-trash text-danger"
                                ></i>
                              </td>
                            </template> -->
                          </template>
                        </tr>
                        <!-- <tr v-if="un.collapse === 'open'">
                          <td class="card-body newFluid" colspan="13">
                            
                          </td>
                        </tr> -->
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <div class="container-fluid ot_tb pt-3" v-if="selectedRequests.length">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div
                  class="card-body table-responsive p-0"
                  style="max-height: 270px"
                >
                  <table
                    class="table table-head-fixed text-nowrap"
                    id="requestTable"
                  >
                    <thead>
                      <tr>
                        <th>Request Date</th>
                        <th>Utilisation ID</th>
                        <th>Verification ID</th>
                        <th>Release Request ID</th>
                        <th>Release Quantity</th>
                        <th>Status</th>
                        <th
                          v-if="
                            !inactive &&
                            (loggedInUserGroupId === 'Admin' ||
                              loggedInUserGroupId == 'Analyst' ||
                              loggedInUserGroupId === 'Collateral Manager')
                          "
                          class="c"
                        >
                          Change Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="sel in selectedRequests" :key="sel.id">
                        <td v-if="sel.request_date">
                          {{ sel.request_date }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="sel.loan_registration_id">
                          {{ sel.loan_registration_id }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="sel.verification_id">
                          {{ sel.verification_id }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="sel.release_id">
                          {{ sel.release_id }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="sel.release_quantity" class="right">
                          {{ sel.release_quantity }} {{ sel.unit }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="sel.status">{{ sel.status }}</td>
                        <td v-else>-</td>
                        <td
                          class="c status"
                          v-if="
                            !inactive &&
                            (loggedInUserGroupId == 'Collateral Manager' ||
                              loggedInUserGroupId == 'Analyst' ||
                              loggedInUserGroupId == 'Admin')
                          "
                        >
                          <div class="status">
                            <select
                              id="status"
                              name="status"
                              @change="
                                onChangeStatus(
                                  sel.release_id,
                                  selectedCleaned.loan_registration_id,
                                  selectedCleaned.borrower_name,
                                  selectedCleaned.offtaker_name,
                                  sel.request_date,
                                  sel.release_quantity,
                                  selectedCleaned.number_of_bags_after_cleaning,
                                  selectedCleaned.total_gross_weight,
                                  selectedCleaned.unit_id,
                                  selectedCleaned.borrower_id,
                                  selectedCleaned.collateral_name,
                                  sel.id,
                                  selectedCleaned.received_info_cleaned_id,
                                  $event
                                )
                              "
                              @click="
                                onChangeStatus(
                                  sel.release_id,
                                  selectedCleaned.loan_registration_id,
                                  selectedCleaned.borrower_name,
                                  selectedCleaned.offtaker_name,
                                  sel.request_date,
                                  selectedCleaned.quantity_derived_after_cleaning,
                                  selectedCleaned.number_of_bags_after_cleaning,
                                  selectedCleaned.total_gross_weight,
                                  selectedCleaned.unit_id,
                                  selectedCleaned.borrower_id,
                                  selectedCleaned.collateral_name,
                                  sel.id,
                                  selectedCleaned.received_info_cleaned_id,
                                  $event,
                                  'same'
                                )
                              "
                              :disabled="
                                sel.release_id === null ||
                                sel.status === 'Released' ||
                                sel.status === 'Pending' ||
                                sel.status === 'Fully Released'
                              "
                              class="form-control"
                              :value="sel.status"
                            >
                              <option
                                v-if="sel.status == 'In Warehouse'"
                                value="In Warehouse"
                              >
                                In Warehouse
                              </option>
                              <option
                                v-if="sel.status == 'Pending'"
                                value="Pending"
                              >
                                Pending
                              </option>
                              <option
                                v-if="sel.status === 'Released'"
                                value="Released"
                              >
                                Released
                              </option>
                              <option
                                v-if="sel.status === 'Fully Released'"
                                value="Fully Released"
                              >
                                Fully Released
                              </option>
                              <option value="In Transit">In Transit</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.Tabpane second end-->
      </div>
      <!-- Main content -->
      <!-- ./modal start -->
      <modal name="view_doc">
        <div class="modal-content" style="height: 500px">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-eye-alt"></i> View Document
            </h4>
            <button
              type="button"
              class="close"
              @click="$modal.hide('view_doc')"
            >
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table">
                    <tbody>
                      <tr v-if="!availableDocuments.length">
                        <center><h5>No Documents uploaded</h5></center>
                      </tr>
                      <tr v-for="doc in availableDocuments">
                        <template>
                          <td class="col-10">{{ doc.document_name }}</td>
                          <td
                            class="c col-1"
                            v-on:click="downloadDocument(doc.document_path)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-download text-info"></i>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <button
                type="button"
                class="btn-sm btn btn-dark sh"
                @click="$modal.hide('view_doc')"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </modal>
    </div>
    <!-- ./modal end -->
  </div>
  <!-- /.content-wrapper -->
</template>

<script>
import $ from "jquery";
//Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
export default {
  data() {
    return {
      id: null,
      borrower_name: null,
      date: moment(new Date()).format("MM-DD-YYYY"),
      collateral: null,
      cleanedInfo: [],
      cleanedDocuments: [],
      inactive: false,
      availableDocuments: [],
      selectedRequests: [],
      selectedCleaned: null,
      cleanedReleaseRequests: [],
      count: 0,
    };
  },

  components: {
    Datepicker,
  },

  methods: {
    toggle(cl, unit) {
      this.selectedRequests = [];
      this.selectedCleaned = null;
      this.selectedRequests = this.cleanedReleaseRequests.filter(
        (rr) =>
          rr.loan_registration_id === cl.loan_registration_id &&
          rr.received_info_cleaned_id === cl.received_info_cleaned_id
      );
      this.selectedRequests.filter((sel) => {
        //adding main entry's unit to sub entry
        sel.unit = unit;
        if (sel.request_date !== null)
          sel.request_date = moment(sel.request_date).format("MM-DD-YYYY");
      });
      this.selectedCleaned = cl;
    },
    showDocumentModal(docId) {
      this.availableDocuments = this.cleanedDocuments.filter(
        (doc) => doc.received_info_cleaned_id === docId
      );
      this.$modal.show("view_doc");
    },

    destroyTable() {
      $("#myTable").dataTable().fnDestroy();
      if (!this.inactive) {
        this.$store.commit("setinactive", "cleaned_active");
        this.getCleaned();
      } else {
        this.$store.commit("setinactive", "cleaned_inactive");
        this.getCleaned();
      }
    },
    deleteCleaned(uncleanedId, requestId, filterType) {
      //this function will be called to delete a specific uncleaned info. Parameter required - uncleanedID: uncleanedid which needs to be deleted

      this.$confirm({
        auth: false,
        message: "Are you sure you want to permanently delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete(
                "received-info-cleaned-overview/" +
                  this.id +
                  "/" +
                  this.collateral,
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                  data: {
                    filtertype: filterType,
                    updateType: "delete",
                    id: uncleanedId,
                    request_id: requestId,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 7,
                    tabId: 23,
                  },
                }
              )
              .then((response) => {
                Utils.stop_loading();
                console.log("delete status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Cleaned Info deleted successfully!"
                  );
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(() => this.$router.go(), 1500);
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              });
          }
        },
      });
    }, //deleteCleaned()  close

    getCleaned() {
      //this function is called on page load and created events. it will show details on Cleaned Received Info
      const path =
        "received-info-cleaned-overview/" + this.id + "/" + this.collateral;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            console.log(res.data);
            this.cleanedInfo = this.inactive
              ? res.data.received_cleaned_overview_inactive_list
              : res.data.received_cleaned_overview_active_list;
            this.cleanedDocuments = this.inactive
              ? res.data.received_documents_inactive_list
              : res.data.received_documents_active_list;
            this.cleanedReleaseRequests = this.inactive
              ? res.data.release_request_inactive_list
              : res.data.release_request_active_list;
            if (this.loggedInUserGroupId === "Collateral Manager") {
              this.cleanedInfo = this.cleanedInfo.filter(
                (cl) =>
                  cl.collateral_manager_id.toString() ===
                  this.loggedInUserSubGroupId
              );
            }
            this.cleanedInfo.sort((a, b) => {
              return new Date(a.received_date) - new Date(b.received_date);
            });
            setTimeout(
              () =>
                $("#myTable").DataTable({
                  aaSorting: [],
                  lengthMenu: [
                    [5, 10, 25, -1],
                    [5, 10, 25, "All"],
                  ],
                  columnDefs: [
                    {
                      orderable: false,
                      targets: [28],
                    },
                  ],
                }),
              100
            );
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();

          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getCleaned close

    onChangeStatus(
      releaseRequestId,
      utilId,
      borrowerName,
      offtakerName,
      requestDate,
      quantityAfterCleaning,
      bagsAfterCleaning,
      grossWeight,
      unit,
      borrowerId,
      collateralName,
      releaseRequestTransId,
      receivedInfoCleanedId,
      e,
      type
    ) {
      if (type === "same") {
        if (e.target.value === "In Transit") {
          this.count += 1;
          if (this.count === 2)
            this.$router.push({
              name: "AddRelease",
              params: {
                id: receivedInfoCleanedId,
                status: e.target.value,
                release_id: releaseRequestId,
                util_id: utilId,
                borrower_name: borrowerName,
                offtaker_name: offtakerName,
                request_date: requestDate,
                quantity_after_cleaning: quantityAfterCleaning,
                bags_after_cleaning: bagsAfterCleaning,
                gross_weight: grossWeight,
                unit: unit,
                borrower_id: borrowerId,
                collateral_name: collateralName,
                release_request_trans_id: releaseRequestTransId,
              },
            });
        }
      } else {
        this.$router.push({
          name: "AddRelease",
          params: {
            id: receivedInfoCleanedId,
            status: e.target.value,
            release_id: releaseRequestId,
            util_id: utilId,
            borrower_name: borrowerName,
            offtaker_name: offtakerName,
            request_date: requestDate,
            quantity_after_cleaning: quantityAfterCleaning,
            bags_after_cleaning: bagsAfterCleaning,
            gross_weight: grossWeight,
            unit: unit,
            borrower_id: borrowerId,
            collateral_name: collateralName,
            release_request_trans_id: releaseRequestTransId,
          },
        });
      }
    }, //onChangeStatus close

    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.collateral = this.$route.params.collateral;
    this.inactive =
      this.$store.state.inactive === "uncleaned_inactive" ? true : false;
    this.getCleaned();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>
<style>
#requestTable {
  background: #f1f1f1;
}
#loanId {
  color: #3366bb;
}
#loanId:hover {
  color: #0645ad;
}
</style>
