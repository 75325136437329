<!-- This is fund-disctribution page.  -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div id="new_fund" class="container-fluid ot_tb pt-3">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx graybx1">
                          <i class="bi bi-bank"></i>
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Asset Under Management
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{FundAnalyticsData.total_aum_value}} Mn
                          </h3>
                          <h6 class="text2 col1 jst_fe mb-0">
                            as of {{FundAnalyticsData.aumDate}}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx graybx1">
                          <i class="bi bi-person"></i>
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Total Investor
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{FundAnalyticsData.total_investor_count}}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx graybx1">
                          <i class="bi bi-arrow-down-up"></i>
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Open Participation
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{FundAnalyticsData.total_open_participation}} Mn
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx graybx1">
                          <i class="bi bi-arrow-down-up"></i>
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Open Notes
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{FundAnalyticsData.total_open_notes}} Mn
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx">
                          <!-- <i class="bi bi-caret-up"></i> -->
                          <i class="bi bi-currency-rupee"></i>
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Payment Due to Investor
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{FundAnalyticsData.total_due_payment}} Mn
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx">
                          <i class="bi bi-caret-up"></i>
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Total Interest Due
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{FundAnalyticsData.total_interest_due}} Mn
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx">
                          <i class="bi bi-check2"></i>
                          <!-- <i class="bi bi-arrow-return-left"></i> -->
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Total Payment done
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{FundAnalyticsData.total_payment_made}} Mn
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx">
                          <!-- <i class="bi bi-arrow-return-left"></i> -->
                          <i class="bi bi-caret-up"></i>
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Penalty Interest
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{FundAnalyticsData.total_penalty_interest}} Mn
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="inputbx">
            <div class="row align_c">
              <div class="col-md-2 col-sm-3">
                <div class="form-group fc">
                  <select 
                    id="tokens" 
                    name="selectedFund"
                    v-model="selectedFund"
                    class="selectpicker" 
                    aria-label="select fund" 
                    title="select fund" 
                    data-live-search="true" 
                    @change="getBorrowersByFund();
                      fetchExpensePaymentData();
                      fetchAUMLoanInvestmentsData();"
                  >
                    <option
                      v-for="fund in funds"
                      :value="fund.fund_id"
                      :key="fund.fund_id"
                    >
                      {{ fund.fund_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-10 col-sm-9">
                <div class="jst_fe fc">
                  <h6 class="info col2">
                    **All Amounts are in {{ FundAnalyticsData.currencyName[0] }} | <span class="col1">As on {{todayDate}}</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <h6 class="tp_mid title text2">
                    Assets under Management over Years
                  </h6>
                  <div class="ht215">
                    <apexchart 
                      v-if="AUMChartDataReady" 
                      type="area" 
                      height="200"
                      :options="AUMChartOptions" 
                      :series="AUMSeries">
                    </apexchart>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <h6 class="tp_mid title text2">
                    Loan vs Investment
                  </h6>
                  <div class="ht215">
                    <apexchart 
                      v-if="LoanInvestmentChartDataReady"  
                      type="bar" 
                      height="200"
                      :options="LoanInvestmentChartOptions" 
                      :series="LoanInvestmentSeries">
                    </apexchart>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <h6 class="tp_mid title text2">
                    Investments
                  </h6>
                  <div class="ht215">
                    <apexchart 
                      v-if="InvestmentTypeChartDataReady" 
                      type="donut" 
                      height="220"
                      :options="InvestmentTypeChartOptions" 
                      :series="InvestmentTypeSeries">
                    </apexchart>
                    <h6 class="tp_mid title text2" v-if="!InvestmentTypeChartDataReady">No Data Found</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-9 col-md-12 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="title">
                    <div class="row align_c">
                      <div class="col-md-4">
                        <h6 class="tp_mid text2">
                          Transaction Settlement Per Month 
                        </h6>
                      </div>
                      <div class="col-md-8">
                        <div class="row jst_fe">
                          <div class="col-md-3">
                            <div class="form-group fc">
                              <select 
                                id="tokens" 
                                name="selectedBorrower"
                                v-model="selectedBorrower"
                                @change="getOfftakerNamesByBorrower()"
                                class="selectpicker" 
                                aria-label="select borrower" 
                                title="select borrower" 
                                data-live-search="true" 
                              >
                                <option
                                  v-for="bor in borrowers"
                                  :value="bor.borrower_id"
                                  :key="bor.borrower_id"
                                >
                                  {{ bor.borrower_name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group fc">
                              <select 
                                id="tokens" 
                                name="selectedOfftakerID"
                                v-model="selectedOfftakerID"
                                class="selectpicker" 
                                aria-label="select offtaker" 
                                title="select offtaker" 
                                data-live-search="true" 
                                @change="fetchTransactionSettlementRepaymentData()"
                              >
                              <option :value="null">select offtaker</option>
                              <option
                                v-for="ofn in offtakerNameIDs"
                                :value="ofn.id"
                                :key="ofn.id"
                              >
                                {{ ofn.offtaker_name }}
                              </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="ht200">
                    <apexchart 
                      v-if="TransactionSettlementRepaymentChartDataReady" 
                      type="bar" 
                      height="200"
                      :options="TransactionSettlementRepaymentChartOptions" 
                      :series="TransactionSettlementRepaymentSeries">
                    </apexchart>
                    <h6 class="tp_mid title text2" v-if="!TransactionSettlementRepaymentChartDataReady">No Data Found</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="title">
                    <div class="row align_c">
                      <div class="col-md-6">
                        <h6 class="tp_mid text2">
                          Expense vs Payment
                        </h6>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group fc left-select-dropdown">
                          <select 
                            id="tokens" 
                            name="selectedExpense"
                            v-model="selectedExpense"
                            class="selectpicker" 
                            aria-label="select expense" 
                            title="select expense" 
                            data-live-search="true" 
                            @change="fetchExpensePaymentData()"
                          >
                            <option
                              v-for="expense in expenses"
                              :value="expense.id" :key="expense.id"
                            >
                              {{ expense.expense_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="ht200">
                    <apexchart 
                      v-if="ExpensePaymentChartDataReady" 
                      type="bar" 
                      height="200"
                      width="275"
                      :options="ExpensePaymentChartOptions" 
                      :series="ExpensePaymentSeries">
                    </apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.content-->
    </div>
    <!-- /.content-wrapper -->

  </div>
  <!-- ./wrapper -->
</template>


<script>
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";
Vue.component('apexchart', VueApexCharts);

export default {
  data() {
    return {
      todayDate: moment().format('DD MMMM YYYY'),
      // Fund Dropdown
      selectedFund: 'all',
      funds: [],
      // Borrower Dropdown
      selectedBorrower: null,
      borrowers: [],
      // Offtaker Dropdown
      selectedOfftakerID: null,
      offtakerNameIDs: [],
      // Expense Dropdown
      selectedExpense: null,
      expenses: [],

      Colors: ['#1930AB', '#0070C0', '#00B0F0', '#7F7F7F', '#D9D9D9'],
      
      // 8 Box Data
      FundAnalyticsData: {
        total_aum_value: 0, 
        total_investor_count: 0, 
        total_open_participation: 0, 
        total_open_notes: 0, 
        total_due_payment: 0, 
        total_interest_due: 0, 
        total_payment_made: 0, 
        total_penalty_interest: 0, 
        currencyName: ["USD"],
        aumDate: ""
      },

      // AUM
      AUMChartOptions: {},
      AUMSeries: [],
      AUMChartData: {},
      AUMChartDataReady: false,

      // Loan VS Investment
      LoanInvestmentChartOptions: {},
      LoanInvestmentSeries: [],
      LoanInvestmentChartData: {},
      LoanInvestmentChartDataReady: false,

      // Investment Type
      InvestmentTypeChartOptions: {},
      InvestmentTypeSeries: [],
      InvestmentTypeChartData: {},
      InvestmentTypeChartDataReady: false,

      // Transaction Settlement Repayment
      TransactionSettlementRepaymentChartOptions: {},
      TransactionSettlementRepaymentSeries: [],
      TransactionSettlementRepaymentChartData: {},
      TransactionSettlementRepaymentChartDataReady: false,

      // Expense VS Payment
      ExpensePaymentChartOptions: {},
      ExpensePaymentSeries: [],
      ExpensePaymentChartData: {},
      ExpensePaymentChartDataReady: false,

      desiredTicks: 3,
      isLoading: false,

      isBasicDetailsSubmitted: false,
      isSubmitted: false,
    };
  },
  validations: {
    selectedFund: {
      required,
    },
    selectedBorrower: {
      required,
    },
    selectedExpense: {
      required,
    },
  },
  methods: {
    calculateIntervalForThreeTicks(startDateStr, endDateStr) {
      const minDate = new Date(startDateStr).getTime();
      const maxDate = new Date(endDateStr).getTime();
      const range = maxDate - minDate;

      // Dynamically calculate interval for desired number of ticks
      const interval = range / (this.desiredTicks - 1);


      return interval;
    },

    // Generalized method to dynamically calculate tick positions for date-based data
    calculateDynamicTickPositions(seriesDataArray, minTicks = 2, maxTicks = 5) {
      if (!seriesDataArray.length) {
        return {
          tickPositions: [],
          interval: 0,
          minDate: null,
          maxDate: null,
          range: 0,
          tickCount: 0
        };
      }

      // Flatten the array of series data to get all data points
      let allDataPoints = seriesDataArray.flatMap(series => series.data);

      // Assuming 'x' values are already timestamps, use them directly
      let timestamps = allDataPoints.map(point => point.x);
      
      // Calculate min and max timestamp from all data points
      let minTimestamp = Math.min(...timestamps);
      let maxTimestamp = Math.max(...timestamps);
      const range = maxTimestamp - minTimestamp;

      // Determine the optimal number of ticks based on the unique timestamps in the dataset
      const uniqueTimestamps = new Set(timestamps).size;
      const optimalTicks = Math.min(Math.max(uniqueTimestamps, minTicks), maxTicks);

      // Calculate the interval between ticks. Avoid division by zero for a single unique timestamp
      const interval = uniqueTimestamps > 1 ? range / (optimalTicks - 1) : 0;

      // Calculate and return tick positions based on the interval, converting back to date objects or date strings as needed
      let tickPositions = [];
      for (let i = 0; i < optimalTicks; i++) {
        const tickTimestamp = minTimestamp + interval * i;
        // Convert timestamp back to Date object or to a specific string format as required by your charting library
        tickPositions.push(tickTimestamp);
      }

      return {
        tickPositions, // The calculated positions for ticks on the chart
        interval, // The interval between each tick
        minDate: minTimestamp, // The minimum date (timestamp) in the data set
        maxDate: maxTimestamp, // The maximum date (timestamp) in the data set
        range, // The range between the minimum and maximum dates
        tickCount: optimalTicks // The number of ticks calculated to be optimal
      };
    },

    fetchAUMLoanInvestmentsData() {
      let selectAllFund = 0
      console.log("fetchAUMLoanInvestmentsData",this.selectedFund)
      let selectedFund = null
      if(this.selectedFund == 'all'){
        selectAllFund = 1;
      } else {
        selectedFund = this.selectedFund
      }
      // Simulated API response
      const path = "get-fund-aum-loan-investments/" + 
      selectedFund + 
      "/" + 
      selectAllFund;
      console.log(path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            // this.FundAnalyticsData = res.data.FundAnalyticsData;
            this.FundAnalyticsData = res.data;
            this.AUMChartData = res.data.AUMChartData
            this.AUMSeries = this.AUMChartData.seriesData.map(series => ({
              name: series.name,
                data: series.data.map(point => ({
                    x: point.dateX, // These are now treated as categories
                    y: point.dataY,
                })),
            }));
            console.log("AUMSeries ", this.AUMSeries);
            this.setAUMOptionData(this.AUMSeries);
            
            this.LoanInvestmentChartData = res.data.LoanInvestmentChartData;
            // For a bar chart, the series data structure can stay the same if it suits your data representation
            this.LoanInvestmentSeries = this.LoanInvestmentChartData.seriesData.map(series => ({
              name: series.name,
              data: series.data // Assuming this is already in the correct format for a bar chart
            }));
            
            console.log("LoanInvestmentSeries ",this.LoanInvestmentSeries)
            this.setLoanInvestmentsOptionData(this.LoanInvestmentChartData.xAxisData)

            this.InvestmentTypeChartData = res.data.InvestmentTypeChartData;
            this.InvestmentTypeSeries = this.InvestmentTypeChartData.seriesData.map(series => series.data);
            
            console.log("InvestmentTypeSeries ",this.InvestmentTypeSeries)
            const sumOfInvestmentTypeSeries = this.InvestmentTypeSeries.reduce((sum, current) => sum + current, 0);
            if (this.InvestmentTypeChartData.labels.length > 0 && sumOfInvestmentTypeSeries > 0) {
                this.setInvestmentTypeOptionData(this.InvestmentTypeChartData.labels, this.InvestmentTypeSeries);
            } else {
                this.InvestmentTypeChartOptions = {};
                this.InvestmentTypeSeries = [];
                this.InvestmentTypeChartData = {};
                this.InvestmentTypeChartDataReady = false;
            }
            this.isLoading = true;
          } else {
            console.log("success", "Something went wrong from server!");
          }
          Utils.stop_loading();
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
      
    },

    fetchTransactionSettlementRepaymentData() {
      let selectAllFund = 0
      console.log("fetchTransactionSettlementRepaymentData",this.selectedFund)
      let selectedFund = null
      if(this.selectedFund == 'all'){
        selectAllFund = 1;
      } else {
        selectedFund = this.selectedFund
      }
      // Simulated API response
      const path = "get-transaction-settlement-repayement" +
        "/" +
        this.selectedBorrower +
        "/" +
        this.selectedOfftakerID +
        "/" +
        selectedFund;
      // if(this.isLoading){
      //   Utils.start_loading();
      // }
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // if(this.isLoading){
          //   Utils.stop_loading();
          // }
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.TransactionSettlementRepaymentChartData = res.data.TransactionSettlementRepaymentChartData;
            // For a bar chart, the series data structure can stay the same if it suits your data representation
            this.TransactionSettlementRepaymentSeries = this.TransactionSettlementRepaymentChartData.seriesData.map(series => ({
              name: series.name,
              data: series.data // Assuming this is already in the correct format for a bar chart
            }));
            
            console.log("TransactionSettlementRepaymentSeries ",this.TransactionSettlementRepaymentSeries)
            this.setTransactionSettlementRepaymentOptionData(this.TransactionSettlementRepaymentChartData.xAxisData,this.TransactionSettlementRepaymentSeries)
            
            
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          // if(this.isLoading){
          //   Utils.stop_loading();
          // }
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
      
    },

    fetchExpensePaymentData() {
      let selectAllFund = 0
      console.log("fetchExpensePaymentData",this.selectedFund)
      let selectedFund = null
      if(this.selectedFund == 'all'){
        selectAllFund = 1;
      } else {
        selectedFund = this.selectedFund
      }
      // Simulated API response
      const path = "get-total-fund-expense-payment" +
        "/" +
        selectedFund +
        "/" +
        this.selectedExpense +
        "/" +
        selectAllFund;

      if(selectAllFund == 1){
        this.selectAllFund = 'all'
      }
      // if(this.isLoading){
      //   Utils.start_loading();
      // }
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // if(this.isLoading){
          //   Utils.stop_loading();
          // }
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.ExpensePaymentChartData = res.data.ExpensePaymentChartData;
            // For a bar chart, the series data structure can stay the same if it suits your data representation
            this.ExpensePaymentSeries = this.ExpensePaymentChartData.seriesData.map(series => ({
              name: series.name,
              data: series.data // Assuming this is already in the correct format for a bar chart
            }));
            
            console.log("ExpensePaymentSeries ",this.ExpensePaymentSeries)
            this.setExpensePaymentChartOptionData(this.ExpensePaymentChartData.xAxisData)
            
            
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          // if(this.isLoading){
          //   Utils.stop_loading();
          // }
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },

    setAUMOptionData(AUMSeries) {
        // Removed calculations related to dynamic datetime ticks as they are unnecessary for categories
        
        // Dynamically construct chartOptions based on fetched data
        this.AUMChartOptions = {
            chart: {
                id: 'vuechart-example',
                type: 'area',
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
            },
            colors: this.Colors, // Add your custom colors here
            stroke: {
                curve: 'straight' // This makes the line sharp instead of curved
            },
            // title: {
            //     text: 'Assets Under Management Over Year',
            //     align: 'center',
            //     style: {
            //         fontFamily: 'Calibri',
            //         fontSize: '16px'
            //     },
            // },
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 5,
                hover: {
                    size: 8
                }
            },
            yaxis: {
                // Set the y-axis name
                title: {
                    text: 'Amounts in millions',
                    style: {
                        fontFamily: 'Calibri',
                        fontSize: '14px',
                    },
                },
                labels: {
                    style: {
                        fontFamily: 'Calibri',
                        fontSize: '12px'
                    },
                }
            },
            xaxis: {
                type: 'category',
                // Removed datetime specific configurations like min, max, range, etc.
                labels: {
                    style: {
                        fontFamily: 'Calibri',
                        fontSize: '12px'
                    },
                },
                // Removed annotations for tick positions as it's now handled by categories
            },
            
            tooltip: {
              y: {
                formatter: function (value) {
                  // Calculate percentage
                  return `${value} Mn`;
                }
              }
            },
            grid: {
              // Add padding inside the chart
              padding: {
                top: 0,
                right: 10, // Increase right padding so the labels don't overflow
                bottom: 0,
                left: 0
              }
            },
            responsive: [{
                breakpoint: 200,
                options: {
                    chart: {
                        width: '100%',
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        };
        this.AUMChartDataReady = true;
    },

    setLoanInvestmentsOptionData(xAxisData){
      // Setting up chart options for a bar chart
      this.LoanInvestmentChartOptions = {
        chart: {
          id: 'vuechart-example',
          type: 'bar', // Changed from 'line' to 'bar'
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: this.Colors, // Add your custom colors here

        // title: {
        //   text: 'Loan vs Investment',
        //   align: 'center',
        //   style: {
        //     fontFamily: 'Calibri',
        //     fontSize: '16px'
        //   },
        // },
        yaxis: {
          // Set the y-axis name
          title: {
            text: 'Amounts in millions',
            style: {
              fontFamily: 'Calibri',
              fontSize: '14px',
            },
          },
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
          }
        },
        xaxis: {
          // Assuming your data can be categorized by dates or specific categories
          categories:xAxisData,
          tickPlacement: 'on',
          style: {
            fontFamily: 'Calibri',
            fontSize: '12px'
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top', // position of the data label
            },
          },
        },
        legend: {
          position: 'bottom', // Position the legend at the bottom
          horizontalAlign: 'center', // Center-align the legend
          offsetX: 0, // Adjust this value to fine-tune the horizontal position
          offsetY: 0, // Adjust this value if you need to fine-tune the vertical position
          fontFamily: 'Calibri',
          fontSize: '12px'
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 0, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
        dataLabels: {
          enabled: false,
          // formatter: function (val) {
          //   // This formatter function will be called for every data label.
          //   // You can format the value here to be shown as required.
          //   return val.toFixed(2); // Formats the value to 2 decimal places.
          // },
          // offsetY: -20, // Adjusts the vertical position of the data labels.
          // style: {
          //   fontSize: '12px',
          //   colors: ["#304758"]
          // },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              return `${value} Mn`;
            }
          }
        },
      };
      this.LoanInvestmentChartDataReady = true;
    },

    setInvestmentTypeOptionData(InvestmentTypeLabels,InvestmentTypeSeries){
      const total = InvestmentTypeSeries.reduce((acc, cur) => acc + cur, 0);
      console.log("total",total)

      this.InvestmentTypeChartOptions = {
        chart: {
          type: 'donut',
        },
        colors: this.Colors, // Add your custom colors here
        labels: InvestmentTypeLabels,
        dataLabels: {
          enabled: false // This line hides the data labels on the chart
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 0, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              let percentage = (value / total * 100).toFixed(2); // Two decimal places
              return `${value} Mn - ${percentage}%`;
            }
          }
        },
        legend: {
          position: 'bottom', // Position the legend at the bottom
          horizontalAlign: 'center', // Center-align the legend
          offsetX: 0, // Adjust this value to fine-tune the horizontal position
          offsetY: 0, // Adjust this value if you need to fine-tune the vertical position
          fontFamily: 'Calibri',
          fontSize: '12px'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      };

      this.InvestmentTypeChartDataReady = true;
    },

    setTransactionSettlementRepaymentOptionData(xAxisData,seriesData){
      // console.log('setTransactionSettlementRepaymentOptionData series DATA',seriesData)
      // // Find the maximum value in your seriesData
      // const maxDataValue = Math.max(...seriesData.map((dataSet) => Math.max(...dataSet.data)));

      // // Calculate a 5% buffer above the maxDataValue
      // const maxWithBuffer = maxDataValue * 1.1;
      // console.log("maxWithBuffer",maxWithBuffer)

      // Setting up chart options for a bar chart
      this.TransactionSettlementRepaymentChartOptions = {
        chart: {
          id: 'vuechart-example',
          type: 'bar', // Changed from 'line' to 'bar'
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: this.Colors, // Add your custom colors here
        // title: {
        //   text: 'Transactions Settlements Per Month',
        //   align: 'center',
        //   style: {
        //     fontFamily: 'Calibri',
        //     fontSize: '16px'
        //   },
        // },
        yaxis: {
          // Set the y-axis name
          title: {
            text: 'Amounts in millions',
            style: {
              fontFamily: 'Calibri',
              fontSize: '14px',
            },
          },
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
          }
        // // Set the y-axis max to the calculated value with the buffer
        //   min: 0,
        //   max: maxWithBuffer,
        },
        xaxis: {
          // Assuming your data can be categorized by dates or specific categories
          categories:xAxisData,
          tickPlacement: 'on',
          style: {
            fontFamily: 'Calibri',
            fontSize: '12px'
          },
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top', // position of the data label
            },
          },
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 10, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
        legend: {
          position: 'bottom', // Position the legend at the bottom
          horizontalAlign: 'center', // Center-align the legend
          offsetX: 0, // Adjust this value to fine-tune the horizontal position
          offsetY: 0, // Adjust this value if you need to fine-tune the vertical position
          fontFamily: 'Calibri',
          fontSize: '12px'
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            // This formatter function will be called for every data label.
            // You can format the value here to be shown as required.
            return `${val} Mn`; // Formats the value to 2 decimal places.
          },
          offsetY: -20, // Adjusts the vertical position of the data labels.
          style: {
            fontSize: '11px',
            colors: ["#304758"]
          },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              return `${value} Mn`;
            }
          }
        },
      };
      this.TransactionSettlementRepaymentChartDataReady = true;
    },

    setExpensePaymentChartOptionData(xAxisData){
      // Setting up chart options for a bar chart
      this.ExpensePaymentChartOptions = {
        chart: {
          id: 'vuechart-example',
          type: 'bar', // Changed from 'line' to 'bar'
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: this.Colors, // Add your custom colors here
        // title: {
        //   text: 'Expense vs Payment',
        //   align: 'center',
        //   style: {
        //     fontFamily: 'Calibri',
        //     fontSize: '16px'
        //   },
        // },
        yaxis: {
          // Set the y-axis name
          title: {
            text: 'Amounts in thousands',
            style: {
              fontFamily: 'Calibri',
              fontSize: '14px',
            },
          },
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
          }
        },
        xaxis: {
          // Assuming your data can be categorized by dates or specific categories
          categories:xAxisData,
          tickPlacement: 'on',
          style: {
            fontFamily: 'Calibri',
            fontSize: '12px'
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top', // position of the data label
            },
          },
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 20,
            right: 0, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
        legend: {
          position: 'bottom', // Position the legend at the bottom
          horizontalAlign: 'center', // Center-align the legend
          offsetX: 0, // Adjust this value to fine-tune the horizontal position
          offsetY: 0, // Adjust this value if you need to fine-tune the vertical position
          fontFamily: 'Calibri',
          fontSize: '12px'
        },
        dataLabels: {
          enabled: false,
          // formatter: function (val) {
          //   // This formatter function will be called for every data label.
          //   // You can format the value here to be shown as required.
          //   return val.toFixed(2); // Formats the value to 2 decimal places.
          // },
          // offsetY: -20, // Adjusts the vertical position of the data labels.
          // style: {
          //   fontSize: '12px',
          //   colors: ["#304758"]
          // },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              return `${value} Th`;
            }
          }
        },
      };
      this.ExpensePaymentChartDataReady = true;
    },

    getOfftakerNamesByBorrower() {
      const path = 
      "/get-offtaker-name-by-borrower" + 
      "/" + 
      this.selectedBorrower +
      "/" +
      null +
      "/" +
      'Offtaker';
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.selectedOfftakerID = null;
          this.offtakerNameIDs = res.data.offtaker_names;
          this.$nextTick(() => {
              console.log("refresh")
              $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
            });
          this.fetchTransactionSettlementRepaymentData();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBorrowersByFund() {
      console.log("getBorrowersByFund",this.selectedFund)
      if(this.selectedFund != 'all'){
        const path = "/get-borrower-by-fund/" + this.selectedFund;
        Utils.start_loading();
        axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          console.log("status", res.data.status)
          if(res.data.status == "success"){
              this.selectedBorrower = null;
              this.borrowers = res.data.borrower_list;
              this.$nextTick(() => {
                console.log("refresh")
                $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
              });
              if(this.borrowers.length > 0){
                this.selectedBorrower = this.borrowers[0].borrower_id
                this.getOfftakerNamesByBorrower();
              }else {
                this.selectedBorrower = null;
                this.TransactionSettlementRepaymentChartOptions = {};
                this.TransactionSettlementRepaymentSeries = [];
                this.TransactionSettlementRepaymentChartData = {};
                this.TransactionSettlementRepaymentChartDataReady = false;
              }
            }
            Utils.stop_loading();
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error(error);
          });
      } else {
        const path = "borrower-master";
        Utils.start_loading();
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            this.selectedBorrower = null;
            this.borrowers = res.data.borrowers;
            this.$nextTick(() => {
              console.log("refresh")
              $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
            });
            if(this.borrowers.length > 0){
              this.selectedBorrower = this.borrowers[0].borrower_id;
              this.getOfftakerNamesByBorrower();
            }else {
              this.selectedBorrower = null;
              this.TransactionSettlementRepaymentChartOptions = {};
              this.TransactionSettlementRepaymentSeries = [];
              this.TransactionSettlementRepaymentChartData = {};
              this.TransactionSettlementRepaymentChartDataReady = false;
            }
            Utils.stop_loading();
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error(error);
          });
      }
    },

    getFunds() {
      const path = "fund-master";
      Utils.start_loading();  
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();  
          this.funds = res.data.funds;
          // Add a new object at the beginning of the funds array
          this.funds.unshift({
            fund_id: 'all',
            fund_name: 'All Fund'
          });
          this.selectedFund = this.funds[0].fund_id;
          this.$nextTick(() => {
              console.log("refresh")
              $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
            });
          this.getBorrowersByFund();
          this.fetchAUMLoanInvestmentsData();
          // if(this.funds.length > 0){
          //   this.selectedFund = this.funds[0].fund_id
          // }else {
          //   this.selectedFund = null;
          // }
        })
        .catch((error) => {
          Utils.stop_loading();  
          console.error(error);
        });
      },
      
      getExpenses() {
        //this function is called to get list of expenses
        const path = "expense";
        Utils.start_loading();  
        axios
        .get(path)
        .then((res) => {
          Utils.stop_loading();  
          this.expenses = res.data.expenses;
          let adminExpense = this.expenses.filter(exp=> exp.expense_name == 'Admin Fees')
          this.selectedExpense = adminExpense[0].id
          console.log("this.selectedExpense",this.selectedExpense)
          this.$nextTick(() => {
              console.log("refresh")
              $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
            });
          this.fetchExpensePaymentData();
          // this.expenses = this.expenses.filter(exp=> exp.expense_name !== 'Management Fees')
          // this.expenses = this.expenses.filter(exp=> exp.expense_name !== 'Performance Fees')
        })
        .catch((error) => {
          Utils.stop_loading();  
          console.error(error);
        });
    },

  },
  created() {
    this.getExpenses();
    this.getFunds();
  }
};
</script>

<style scoped>
.required-field::after {
  content: "*";
  color: red;
}

.error-msg {
  color: red;
  font-weight: bold;
}

.login_img {
  background-color: #d2d2d2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60%;
}

#side_view {
  height: 100%;
  padding: 0px;
}
</style>