<!-- This is add Loan page. -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-plus"></i> Add Utilisation Request
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Borrower Name</label
                      >
                      <select
                        id="selectedBorrowerName"
                        class="form-control"
                        name="selectedBorrowerName"
                        v-model="selectedBorrowerName"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedBorrowerName.$error,
                        }"
                        disabled="true"
                        required
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="borrower in borrowers"
                          :value="borrower.borrower_name"
                          :key="borrower.borrower_id"
                        >
                          {{ borrower.borrower_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedBorrowerName.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedBorrowerName.required"
                          >Borrower Name is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Facility ID</label
                      >
                      <select
                        id="facility_registration_id"
                        class="form-control"
                        name="facility_registration_id"
                        v-model="facility_registration_id"
                        required
                        @change="onFacilitySelect"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.facility_registration_id.$error,
                        }"
                        :disabled="$route.params.type == 'facility'"
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="fac in FacilitySetup"
                          :value="fac.id"
                          :key="fac.id"
                        >
                          {{ fac.facility_registration_id }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.facility_registration_id.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.facility_registration_id.required"
                          >Facility ID is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Fund name</label>
                      <select
                        id="selectedFund"
                        class="form-control"
                        name="selectedFund"
                        v-model="selectedFund"
                        required
                        :disabled="true"
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedFund.$error,
                        }"
                      >
                        <option :value="null">-- Select --</option>
                        <option
                          v-for="fund in Funds"
                          :value="fund.fund_id"
                          :key="fund.fund_id"
                        >
                          {{ fund.fund_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedFund.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedFund.required"
                          >Fund name is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Request Date</label
                      >
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="RequestDate"
                        placeholder="Select Date"
                        v-model="RequestDate"
                        :format="$store.state.date_format"
                        @input="insertBackcapConversionDate()"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="disabledDates"
                        :class="{
                          'is-invalid': isSubmitted && $v.RequestDate.$error,
                        }"
                      ></datepicker>
                      <div
                        v-if="isSubmitted && $v.RequestDate.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.RequestDate.required"
                          >Request Date is required</span
                        >
                      </div>
                      <!-- :disabledDates="{from: new Date()}" -->
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Loan Amount (Requested)</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="requestedAmount"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.requestedAmount.$error,
                        }"
                        id="requestedAmount"
                        name="requestedAmount"
                        @keyup="calculateFinalLoanAmount()"
                      />
                      <div
                        v-if="isSubmitted && $v.requestedAmount.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.requestedAmount.required"
                          >Loan Amount (Requested) is required</span
                        >
                        <span v-if="!$v.requestedAmount.maxLength"
                          >Max length of 15 characters exceeded<br
                        /></span>
                        <!--
                        <span v-if="!$v.requestedAmount.facilityLimit">
                          Loan Amount (Requested) should not exceed facility limit
                        </span> -->
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Interest Rate Type</label
                      >
                      <select
                        id="selectedInterestRateType"
                        class="form-control"
                        name="selectedInterestRateType"
                        v-model="selectedInterestRateType"
                        @change="onChangeInterestRateType()"
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedInterestRateType.$error,
                        }"
                        required
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="irt in InterestRateType"
                          :value="irt.id"
                          :key="irt.id"
                        >
                          {{ irt.loan_interest_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedInterestRateType.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedInterestRateType.required"
                          >Interest Rate Type is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="selectedInterestRateType == 1 || selectedInterestRateType == 2"
                    class="col-md-2 col-sm-5 col-6"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Interest Rate(%)</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder
                        v-model="InterestRate"
                        id="InterestRate"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                        :class="{
                          'is-invalid': isSubmitted && $v.InterestRate.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.InterestRate.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.InterestRate.required"
                          >Interest Rate is required</span
                        >
                        <span v-if="!$v.InterestRate.maxDigit"
                          >Interest Rate cannot exceed 100%</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="selectedInterestRateType == 3 || selectedInterestRateType == 4"
                    class="col-md-2 col-sm-5 col-6"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Spread(%)</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder
                        v-model="Spread"
                        id="Spread"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                        :class="{
                          'is-invalid': isSubmitted && $v.Spread.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.Spread.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Spread.required">Spread is required</span>
                        <span v-if="!$v.Spread.maxDigit"
                          >Spread cannot exceed 100%</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="selectedInterestRateType == 3 || selectedInterestRateType == 4"
                    class="col-md-2 col-sm-5 col-6"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Benchmark</label
                      >
                      <select
                        id="selectedBenchmark"
                        class="form-control"
                        name="selectedBenchmark"
                        v-model="selectedBenchmark"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedBenchmark.$error,
                        }"
                      >
                        <option :value="null">-- Select a Benchmark --</option>
                        <option
                          v-for="benchmark in benchmarks"
                          :value="benchmark.id"
                          :key="benchmark.id"
                        >
                          {{ benchmark.benchmark_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedBenchmark.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedBenchmark.required"
                          >Benchmark is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Tenure</label
                      >
                      <input
                        class="form-control"
                        placeholder
                        v-model="Tenure"
                        @keypress="isNum($event)"
                        :class="{
                          'is-invalid': isSubmitted && $v.Tenure.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.Tenure.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Tenure.required"
                          >Tenure is required</span
                        >
                        <span v-if="!$v.Tenure.maxLength"
                          >Max length of 3 digits exceeded</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Period</label>
                      <select
                        id="selectedPeriod"
                        class="form-control"
                        name="selectedPeriod"
                        v-model="selectedPeriod"
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedPeriod.$error,
                        }"
                      >
                        <option :value="null">-- Select a Period --</option>
                        <option value="Days">Days</option>
                        <option value="Weeks">Weeks</option>
                        <option value="Months">Months</option>
                        <option value="Years">Years</option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedPeriod.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedPeriod.required"
                          >Period is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Day Count</label
                      >
                      <select
                        id="selectedDayCount"
                        class="form-control"
                        name="selectedDayCount"
                        v-model="selectedDayCount"
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedDayCount.$error,
                        }"
                      >
                        <option :value="null">-- Select a Count --</option>
                        <option v-for="dc in dayCounts" :value="dc.id" :key="dc.id">
                          {{ dc.day_count_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedDayCount.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedDayCount.required"
                          >Day Count is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Loan Currency</label>
                      <select
                        id="selectedLoanCurrency"
                        class="form-control"
                        name="selectedLoanCurrency"
                        v-model="selectedLoanCurrency"
                        @change="getLoanCurrencyExchangeRateByCurrencyDate()"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedLoanCurrency.$error,
                        }"
                      >
                        <option :value="null">-- Select a Currency --</option>
                        <option
                          v-for="curr in currencies_loan"
                          :value="curr.id"
                          :key="curr.id"
                        >
                          {{ curr.currency_short_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedLoanCurrency.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedLoanCurrency.required"
                          >Loan Currency is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Fund Currency</label>
                      <select
                        id="selectedFundCurrency"
                        class="form-control"
                        name="selectedFundCurrency"
                        v-model="selectedFundCurrency"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedFundCurrency.$error,
                        }"
                        disabled
                      >
                        <option :value="null">-- Select a Currency --</option>
                        <option
                          v-for="curr in currencies_loan"
                          :value="curr.id"
                          :key="curr.id"
                        >
                          {{ curr.currency_short_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedFundCurrency.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedFundCurrency.required"
                          >Fund Currency is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="conversionDate" class="required-field">Conversion Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        placeholder="Select Date"
                        @input="getLoanCurrencyExchangeRateByCurrencyDate()"
                        id="conversionDate"
                        v-model="conversionDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :class="{
                        'is-invalid': isSubmitted && $v.conversionDate.$error,
                        }"
                        :calendar-button="true"
                        :disabledDates="{ to: new Date(backcapConversionDate) , from: new Date() }"
                        ></datepicker>
                      <div
                          v-if="isSubmitted && $v.conversionDate.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.conversionDate.required"
                            >Conversion Date is required</span>
                      </div>
                    </div>
                  </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="conversionRate" class="required-field">Conversion Rate</label>
                    <input
                      type="number"
                      class="form-control"
                      id="conversionRate"
                      v-model="conversionRate"
                      :class="{
                        'is-invalid': isSubmitted && $v.conversionRate.$error,
                      }"
                      disabled
                      />
                        <!-- @keypress="isDecimal($event)" -->
                    <div
                      v-if="isSubmitted && $v.conversionRate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.conversionRate.required"
                        >Conversion Rate is required</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="finalLoanRequestedAmount" class="required-field">Final Loan Amount</label>
                    <input
                      type="text"
                      class="form-control"
                      id="finalLoanRequestedAmount"
                      v-model="finalLoanRequestedAmount"
                      @keypress="isDecimalTwoFixed($event)"
                      @paste="truncateDecimalPlacesUptoTwo($event)"
                      @keyup="calculateContractValue()"
                      :class="{
                        'is-invalid': isSubmitted && $v.finalLoanRequestedAmount.$error,
                      }"
                      disabled
                    />
                    <div
                      v-if="isSubmitted && $v.finalLoanRequestedAmount.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.finalLoanRequestedAmount.required"
                      >Final Loan Amount is required</span>
                      <span v-if="!$v.finalLoanRequestedAmount.maxLength"
                        >Max length of 15 characters exceeded<br
                      /></span>

                      <span v-if="!$v.finalLoanRequestedAmount.facilityLimit">
                        Final Loan Amount should not exceed facility limit
                      </span>
                    </div>
                  </div>
                </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="Supplier"
                        >Supplier</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder
                        v-model="Supplier"
                        id="Supplier"
                        :class="{
                          'is-invalid': isSubmitted && $v.Supplier.$error,
                        }"
                      />
                          <!-- @keypress="isAlpha($event)" -->
                      <div
                        v-if="isSubmitted && $v.Supplier.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Supplier.maxLength"
                          >Max length of 50 characters exceeded</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-4 col-6">
                    <div class="form-group fc">
                      <label for="comments">Comments</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Type..."
                        v-model="comments"
                        id="comments"
                        :class="{
                          'is-invalid': isSubmitted && $v.comments.$error,
                        }"
                      />
                          <!-- @keypress="isAlpha($event)" -->
                      <div
                        v-if="isSubmitted &&$v.comments.$error"
                        class="invalid-feedback"
                      >
                        <span
                          v-if="! $v.comments.maxLength"
                          >Max length of 100 characters exceeded</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 0 means collateral type -->

          <div v-if="this.facCollateralType == 0" class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Offtaker Contract</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addOfftakerContract"
                              :disabled="offtakerContractAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <span style="font-size: 12px; color: red"
                        >Note: The Contract Quantity being mapped against this particular loan cannot be utilized at the time of partial contract.</span
                      >
                    <!------menton here style="height: 300px;"-------->
                    <div
                      class="row"
                      v-for="(off, index) in offtakercontractList"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Offtaker Name</label
                          >
                          <select
                            id="offtakerNameID"
                            class="form-control"
                            name="offtakerNameID"
                            v-model="off.offtakerNameID"
                            @change="getContractNoByOfftakerName(index,off.offtakerNameID)"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="off in offtakerNameIDs"
                              :value="off.id"
                              :key="off.id"
                            >
                              {{ off.offtaker_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" :class="{'required-field':off.requiredFlag}"
                            >Contract No.</label
                          >
                          <select
                            id="contractNo"
                            class="form-control"
                            name="contractNo"
                            v-model="off.contractNo"
                            @change="getContractNoDetails(index,off.contractNo)"
                            :class="{
                              'is-invalid':
                                isSubmitted && off.requiredFlag && (!off.contractNo)
                            }"
                            :disabled="!off.offtakerNameID"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="cn in off.contractNumber"
                              :value="cn.contractId"
                              :key="cn.contractId"
                            >
                              {{ cn.contractNo }}
                            </option>
                          </select>
                          <div
                            v-if="
                              isSubmitted && off.requiredFlag
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="(!off.contractNo)"
                              >Contract No. is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Contract Date</label
                          >
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="ContractDate"
                            placeholder="Select Date"
                            v-model="off.ContractDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{ from: new Date() }"
                            disabled
                          ></datepicker>
                        </div>
                      </div>

                      <div v-if="off.partialContractNumber.length > 0 && off.contractNo" class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" :class="{'required-field':off.repaymentFlag == 1}"
                            >Partial Contract No.</label
                          >
                          <select
                            id="partialContractNo"
                            class="form-control"
                            name="partialContractNo"
                            v-model="off.partialContractNo"
                            @change="getPartialContractNoDetails(index,off.contractNo,off.partialContractNo)"
                            :class="{
                              'is-invalid':
                                isSubmitted && (off.repaymentFlag == 1) && (!off.partialContractNo)
                            }"
                            :disabled="!off.offtakerNameID"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="cn in off.partialContractNumber"
                              :value="cn.partialContractId"
                              :key="cn.partialContractId"
                            >
                              {{ cn.partialContractNo }}
                            </option>
                          </select>
                          <div
                            v-if="
                              isSubmitted && (off.repaymentFlag == 1)
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="(!off.partialContractNo)"
                              >Partial Contract No. is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div v-if="off.partialContractNo" class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Partial Contract Date</label
                          >
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="PartialContractDate"
                            placeholder="Select Date"
                            v-model="off.PartialContractDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{ from: new Date() }"
                            disabled
                          ></datepicker>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Total Contract Quantity</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder
                            v-model="off.TotalContractQuantity"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            @keyup="onChangeQuantityValidation(index)"
                            disabled
                          />
                            <!-- :class="{
                              'is-invalid':
                                (isSubmitted &&
                                $v.offtakercontractList.$each[index]
                                  .Quantity.$error) || (isSubmitted && off.requiredFlag && (!off.Quantity)) || (isSubmitted && off.requiredFlag && (off.Quantity == 0)) || (off.QuantityError),
                            }" -->
                          <!-- <div
                            v-if="
                              (isSubmitted &&
                              $v.offtakercontractList.$each[index]
                                .Quantity.$error) || (isSubmitted && off.requiredFlag && (!off.Quantity)) || (isSubmitted && off.requiredFlag && (off.Quantity == 0)) || (off.QuantityError)
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="(isSubmitted && off.requiredFlag && (!off.Quantity))  || (isSubmitted && off.requiredFlag && (off.Quantity == 0))"
                              >Quantity is required</span
                            >
                            <span
                              v-if="
                                !$v.offtakercontractList.$each[index]
                                  .Quantity.maxLength
                              "
                              >Max length of 15 characters exceeded</span
                            >
                          </div> -->
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Available Contract Quantity</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder
                            v-model="off.Quantity"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            @keyup="onChangeQuantityValidation(index)"
                            disabled
                          />
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Unit</label
                          >
                          <select
                            id="selectedUnit"
                            class="form-control"
                            name="selectedUnit"
                            v-model="off.selectedUnit"
                            disabled
                          >
                            <option :value="null">
                              -- Unit --
                            </option>
                            <option
                              v-for="unit in UnitTypes"
                              :value="unit.id"
                              :key="unit.id"
                            >
                              {{ unit.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Collateral</label
                          >
                          <select
                            id="selectedCollateral"
                            class="form-control"
                            name="selectedCollateral"
                            v-model="off.selectedCollateral"
                            disabled
                          >
                            <option :value="null">
                              -- Collateral --
                            </option>
                            <option
                              v-for="col in collaterals"
                              :value="col.id"
                              :key="col.id"
                            >
                              {{ col.collateral_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-1 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            style="width: 250px"
                            v-on:click="deleteOfftakerContract(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          @click="onOfftakerContractSaveInfo()"
                          :disabled="isDisabledOfftakerContractSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documents</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addLoanDoc()"
                              :disabled="LoanDocumentsAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(doc, index) in LoanDocuments"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label class="required-field">Select Document</label>
                          <select
                            id="LoanDocName"
                            class="form-control"
                            name="LoanDocName"
                            v-model="doc.LoanDocName"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.LoanDocuments.$each[index].LoanDocName
                                  .$error,
                            }"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="document in LoanDocs"
                              :value="document.id"
                              :key="document.id"
                            >
                              {{ document.document_name }}
                            </option>
                          </select>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.LoanDocuments.$each[index].LoanDocName.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.LoanDocuments.$each[index].LoanDocName
                                  .required
                              "
                              >Document Name is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" class="required-field"
                            >Upload Document</label
                          >
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="customFile"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              v-on:change="
                                handleFileUploadLoanDocuments(index, $event)
                              "
                              :class="{
                                'is-invalid':
                                  isSubmitted && doc.displayFileName === null,
                              }"
                            />
                            <div
                              v-if="isSubmitted && doc.displayFileName === null"
                              class="invalid-feedback"
                            >
                              <span v-if="doc.displayFileName === null"
                                >Document Name is required</span
                              >
                            </div>
                            <small>
                              <p style="background-color: powderblue">
                                <b>{{ doc.displayFileName }}</b>
                                <small>
                                  <u
                                    style="color: blue; cursor: pointer"
                                    v-if="
                                      doc.LoanDocumentRemoveDisabled === false
                                    "
                                    v-on:click="removeLoanDocumentsDoc(index)"
                                    >Remove</u
                                  >
                                </small>
                              </p>
                            </small>
                            <small>
                              <p class="error" v-if="doc.errors.filesize">
                                {{ doc.errors.filesize }}
                              </p>
                            </small>
                            <label class="custom-file-label" for="customFile"
                              >Choose file</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" class="required-field"
                            >Uploading Date</label
                          >
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="LoanDocumentsDate"
                            placeholder="Select Date"
                            v-model="doc.LoanDocumentsDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{
                              to: new Date(facRegDate - 8640000),
                              from: new Date(),
                            }"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.LoanDocuments.$each[index].LoanDocumentsDate
                                  .$error,
                            }"
                          ></datepicker>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.LoanDocuments.$each[index].LoanDocumentsDate
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.LoanDocuments.$each[index].LoanDocumentsDate
                                  .required
                              "
                              >Uploading Date is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Expiry Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="ExpiryDate"
                            placeholder="Select Date"
                            v-model="doc.ExpiryDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{
                              to: new Date(doc.LoanDocumentsDate - 8640000),
                            }"
                          ></datepicker>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Comments</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Type..."
                            v-model="doc.comments"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.LoanDocuments.$each[index].comments.$error,
                            }"
                          />
                          <div
                            v-if="
                              isSubmitted &&
                              $v.LoanDocuments.$each[index].comments.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.LoanDocuments.$each[index].comments
                                  .maxLength
                              "
                              >Max length of 50 characters exceeded</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-1 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            style="width: 250px"
                            v-on:click="deleteLoanDocuments(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onLoanDocumentSaveInfo()"
                          :disabled="isDisabledLoanDocumentSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-12">
                        <h1 class="headingt">Interest Chargeable</h1>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body fc p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div class="row">
                      <div class="col-md-4">
                        <div class="custom-control custom-checkbox mb-3">
                          <label>
                            <input
                              type="radio"
                              v-model="InterestChargeable"
                              value="InterestDrawdown"
                              :class="{
                                'is-invalid':
                                  isSubmitted && $v.InterestChargeable.$error,
                              }"
                            />Interest on Drawdown Date (Drawdown Amount)
                          </label>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="custom-control custom-checkbox mb-3">
                          <label>
                            <input
                              type="radio"
                              v-model="InterestChargeable"
                              value="InterestUtilisationRequest"
                              :class="{
                                'is-invalid':
                                  isSubmitted && $v.InterestChargeable.$error,
                              }"
                            />Interest on Request Date (Principal Amount)
                          </label>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="custom-control custom-checkbox mb-3">
                          <label>
                            <input
                              type="radio"
                              v-model="InterestChargeable"
                              value="InterestUtilisationRequestDrawdownAmount"
                              :class="{
                                'is-invalid':
                                  isSubmitted && $v.InterestChargeable.$error,
                              }"
                            />Interest on Request Date (Drawdown Amount)
                          </label>
                        </div>
                      </div>
                      <div
                        v-if="isSubmitted && $v.InterestChargeable.$error"
                        class="error"
                      >
                        <span v-if="!$v.InterestChargeable.required"
                          >Please select Interest Chargeable</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-success sh"
                      v-on:click="onSubmit()"
                      :disabled="isDisabledOnSubmit"
                    >
                      Submit
                    </button>
                  </li>
                  <li>
                    <a @click="$router.go(-1)">
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>
    <!-- ./wrapper -->
  </div>
</template>

// In your Vue component
Vue.directive('decimal-places', {
  bind(el) {
    el.addEventListener('input', (event) => {
      let value = event.target.value;
      
      // Remove any non-numeric characters except the dot (.)
      value = value.replace(/[^0-9.]/g, '');

      // Ensure that there are a maximum of two decimal places
      const parts = value.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        value = parts.join('.');
      }

      // Update the input field value
      event.target.value = value;
    });
  },
});

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  // directives: {
  //   'decimal-places': {
  //     bind(el) {
  //       el.addEventListener('input', (event) => {
  //         // Get the input value
  //         let value = event.target.value;

  //         // Remove any non-numeric characters except the dot (.)
  //         value = value.replace(/[^0-9.]/g, '');

  //         // Ensure that there are a maximum of two decimal places
  //         const parts = value.split('.');
  //         if (parts.length > 1) {
  //           parts[1] = parts[1].slice(0, 2);
  //           value = parts.join('.');
  //         }

  //         // Update the input value
  //         event.target.value = value;
  //       });
  //     },
  //   },
  // },
  data() {
    return {
      //master tables
      borrower_id: null,
      id: null,
      //variables for Loan information
      selectedFund: null,
      Funds: [],
      selectedBorrowerName: null,
      borrowers: [],
      facility_registration_id: null,
      FacilitySetup: [],
      RequestDate: null,
      RequestDate1: null,
      Tenure: null,
      selectedPeriod: null,
      selectedInterestRateType: null,
      InterestRateType: [],
      simpleInterestFlag:0,
      compoundInterestFlag:0,
      InterestRate:null,
      Spread:null,
      selectedBenchmark:null,
      benchmark:[],
      countries: [],
      requestedAmount: null,
      selectedFundCurrency: null,
      selectedLoanCurrency: null,
      conversionDate:null,
      backcapConversionDate:new Date(moment(new Date()).subtract(10, "year")),
      conversionRate:1,
      finalLoanRequestedAmount: null,
      currencies_loan: [],
      Supplier: null,
      selectedDayCount: null,
      dayCounts: [],
      comments:null,
      UnitTypes: [],
      facility_limit: null,
      facCollateralType: null,

      //list for Loan Documents
      LoanDocuments: [],
      LoanDocs: [],
      LoanDocumentFile: null,
      // LoanDocumentsButtonDisabled: true,
      LoanDocumentsDisabled: true,
      LoanDocumentSaveInfoDisabled: true, //save info button is disabled
      LoanDocumentsAddMoreDisabled: true,

      //list for offtaker contract
      // covenants: [],
      offtakerNameIDs: [],
      collaterals: [],
      offtakercontractList: [],
      contractType: 'Offtaker',
      currencies: [],
      offtakerContractSaveInfoDisabled: true, //save info button is disabled
      offtakerContractAddMoreDisabled: true,

      //list for Interest Chargeable
      InterestChargeable: null,
      InterestDrawdown: null,
      InterestUtilisationRequest: null, //save info button is disabled
      InterestUtilisationRequestDrawdownAmount: null, //save info button is disabled

      facRegDate: null,
      facility_id: null,

      error: "",
      isSubmitted: false,
      compare_requested_amount: null,
      disabledDates: { to: null, from: new Date() },
    };
  },

  validations: {
    selectedBorrowerName: {
      required,
    },
    facility_registration_id: {
      required,
    },
    selectedFund: {
      required,
    },
    RequestDate: {
      required,
    },
    requestedAmount: {
      required: function(){
        if(this.requestedAmount === null || this.requestedAmount == 0)
          return false
        else
          return true
      },
      maxLength: maxLength(15),
    },
    Tenure: {
      required: function(){
        if(this.Tenure === null || this.Tenure == 0)
          return false
        else
          return true
      },
      maxLength: maxLength(3),
    },
    selectedPeriod: {
      required,
    },
    selectedInterestRateType: {
      required,
    },
    InterestRate: {
      required: function () {
        if (
          (this.selectedInterestRateType == 1 || this.selectedInterestRateType == 2) &&
          (this.InterestRate == null || this.InterestRate == '' || this.InterestRate == 0)
        ) {
          return false;
        } else {
          return true;
        }
      },
      // validation for value greater than 2 digits 3 decimals
      maxDigit: function (val) {
        if (Number(val) > 100 && (this.selectedInterestRateType == 1 || this.selectedInterestRateType == 2))
          return false;
        else return true;
      },
    },
    Spread: {
      required: function () {
        if (
          (this.selectedInterestRateType == 3 || this.selectedInterestRateType == 4) &&
          (this.Spread == null || this.Spread == '' || this.Spread == 0)
        ) {
          return false;
        } else {
          return true;
        }
      },
      // validation for value greater than 2 digits 3 decimals
      maxDigit: function (val) {
        if (Number(val) > 100 && (this.selectedInterestRateType == 3 || this.selectedInterestRateType == 4))
          return false;
        else return true;
      },
    },
    selectedBenchmark: {
      required: function () {
        if (
          (this.selectedInterestRateType == 3 || this.selectedInterestRateType == 4) &&
          this.selectedBenchmark === null
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
    selectedFundCurrency: {
      required,
    },
    selectedLoanCurrency: {
      required,
    },
    conversionDate: {
      required,
    },
    conversionRate: {
      required,
    },
    finalLoanRequestedAmount: {
      required,
      maxLength: maxLength(15),
      facilityLimit: function (value) {
        console.log("Number(value)",Number(value))
        console.log("Number(this.compare_requested_amount)",Number(this.compare_requested_amount))
        console.log("Number(this.facility_limit)",Number(this.facility_limit))
        const containsFacilityLimit =
          Number(value) + Number(this.compare_requested_amount) <=
          Number(this.facility_limit);
        console.log("containsFacilityLimit: ", containsFacilityLimit);
        return containsFacilityLimit;
      },
    },
    selectedDayCount: {
      required,
    },
    Supplier: {
      maxLength: maxLength(50),
    },
    comments: {
      maxLength: maxLength(100),
    },
    offtakercontractList: {
      $each: {
        TotalContractQuantity: {
          maxLength: maxLength(15),
        },
      },
    },
    LoanDocuments: {
      $each: {
        LoanDocName: {
          required,
        },
        LoanDocumentsDate: {
          required,
        },
        comments: {
          maxLength: maxLength(50),
        },
      },
    },
    InterestChargeable: {
      required,
    },
  },
  computed: {
    //this is to computed disabled property of main Submit button to save information in database.
    isDisabledOnSubmit: function () {
      // if (
      //   this.selectedBorrowerName == null ||
      //   this.facility_registration_id == null ||
      //   this.selectedFund == null ||
      //   this.RequestDate == null ||
      //   this.Tenure == null ||
      //   this.selectedPeriod == null ||
      //   this.selectedInterestRateType == null ||
      //   this.requestedAmount == null ||
      //   this.selectedLoanCurrency == null ||
      //   this.Supplier == null ||
      //   this.InterestChargeable == null
      //   //this.LoanDocumentSaveInfoDisabled === true
      // ) {
      //   return true;
      // }
    },

    //this is to computed disabled property of LoanDocuments Save Info button.
    isDisabledLoanDocumentSaveInfo: function () {
      let checkEmptyLoanDocumentsDocName = this.LoanDocuments.filter(
        (line) => line.LoanDocName === null
      );
      let checkEmptyLoanDocumentsDate = this.LoanDocuments.filter(
        (line) => line.LoanDocumentsDate === null
      );
      //if the line is empty then disable save info button
      if (
        this.LoanDocuments.length === 0 &&
        this.LoanDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyLoanDocumentsDocName.length >= 1 &&
          this.LoanDocuments.length > 0) ||
        (checkEmptyLoanDocumentsDate.length >= 1 &&
          this.LoanDocuments.length > 0)
      ) {
        this.LoanDocumentsDisabled = true;
      } else {
        this.LoanDocumentsDisabled = false;
      }
      //LoanDocumentsDisabled variable is to check whther all input fields except file upload are filled. LoanDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.LoanDocumentsDisabled === true ||
        this.LoanDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledLoanDocumentSaveInfo() close

    //this is to computed disabled property of OfftakerContracts Save Info button.
    isDisabledOfftakerContractSaveInfo: function () {
      let checkEmptyOfftakerContractNo = this.offtakercontractList.filter(
        (line) => line.contractNo === null
      );
      let checkEmptyTotalContractQuantity = this.offtakercontractList.filter(
        (line) => line.TotalContractQuantity === null
      );
      let checkEmptyTotalContractQuantityString = this.offtakercontractList.filter(
        (line) => line.TotalContractQuantity === ''
      );
      let checkEmptyTotalContractQuantityZero = this.offtakercontractList.filter(
        (line) => line.TotalContractQuantity == 0
      );
      let checkEmptyUnit = this.offtakercontractList.filter(
        (line) => line.selectedUnit === null
      );
      let checkEmptyCollateral = this.offtakercontractList.filter(
        (line) => line.selectedCollateral === null
      );
      //if the line is empty then disable save info button
      if (
        this.offtakercontractList.length === 0 &&
        this.offtakerContractSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyOfftakerContractNo.length >= 1 &&
          this.offtakercontractList.length > 0) ||
        (checkEmptyTotalContractQuantity.length >= 1 &&
          this.offtakercontractList.length > 0) ||
        (checkEmptyTotalContractQuantityString.length >= 1 &&
          this.offtakercontractList.length > 0) ||
        (checkEmptyTotalContractQuantityZero.length >= 1 &&
          this.offtakercontractList.length > 0) ||
        // (checkEmptyUnit.length >= 1 &&
        //   this.offtakercontractList.length > 0)
        (checkEmptyUnit.length >= 1 &&
          this.offtakercontractList.length > 0) ||
        (checkEmptyCollateral.length >= 1 &&
          this.offtakercontractList.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledOfftakerContractSaveInfo() close
  }, //main computed close

  components: {
    Datepicker,
  },
  methods: {
    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isAlpha(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isAlphaNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    
    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },
    
    // isDecimal(e) {
    //   let invalidChars = ["-", "+", "e", "E"];
    //   if (invalidChars.includes(e.key)) return e.preventDefault();
    //   else return true;
    // },

    insertBackcapConversionDate() {
      this.backcapConversionDate = this.RequestDate
      this.conversionDate = this.RequestDate
      this.getLoanCurrencyExchangeRateByCurrencyDate()
    },

    onFundSelect() {
      const path = "get-fund-currency" + "/" + this.selectedFund;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.selectedFundCurrency = res.data.fund_currency;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onAddFacilitySelect() {
      const path = "get-facility-fund" + "/" + this.$route.params.facilityId;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.selectedFund = res.data.facility_fund.fund_id;
          this.selectedFundCurrency = res.data.facility_fund.fund_currency_id;
          this.selectedLoanCurrency = res.data.facility_fund.fund_currency_id;
          this.facRegDate = res.data.facility_fund.facility_request_date;
          this.facCollateralType = res.data.facility_fund.facility_with;
          if(this.facCollateralType == 1){
            this.offtakercontractList = [];
          }  else {
            if (this.offtakercontractList.length == 0){
              this.addOfftakerContract()
            }
          }
          // this.Tenure = res.data.facility_fund.tenure;
          // this.selectedPeriod = res.data.facility_fund.period;
          this.facRegDate = moment(String(this.facRegDate)).format(
            "MM/DD/YYYY"
          );
          this.facRegDate = moment.utc(this.facRegDate, "MM-DD-YYYY").toDate();
          this.disabledDates.to = new Date(this.facRegDate);
          console.log("fac", this.facRegDate);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onFacilitySelect() {
      const path = "get-facility-fund" + "/" + this.facility_registration_id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.selectedFund = res.data.facility_fund.fund_id;
          this.selectedFundCurrency = res.data.facility_fund.fund_currency_id;
          this.selectedLoanCurrency = res.data.facility_fund.fund_currency_id;
          this.facRegDate = res.data.facility_fund.facility_request_date;
          this.facCollateralType = res.data.facility_fund.facility_with;
          if(this.facCollateralType == 1){
            this.offtakercontractList = [];
          }  else {
            if (this.offtakercontractList.length == 0){
              this.addOfftakerContract()
            }
          }
          // this.Tenure = res.data.facility_fund.tenure;
          // this.selectedPeriod = res.data.facility_fund.period;
          this.facRegDate = moment(String(this.facRegDate)).format(
            "MM/DD/YYYY"
          );
          this.facRegDate = moment.utc(this.facRegDate, "MM-DD-YYYY").toDate();
          this.disabledDates.to = new Date(this.facRegDate);
          console.log("fac", this.facRegDate);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    
    //this method will make disabled property as false for Director Save Info
    onLoanDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.LoanDocumentSaveInfoDisabled = false;
      this.LoanDocumentsAddMoreDisabled = false;
    },
    

    onOfftakerContractSaveInfo() {
      console.log("onOfftakerContractSaveInfo")
      let flag = false;
      let partialFlag = false;

      // Check for duplicates and set the flag and error message
      this.offtakercontractList.forEach((contract, index) => {
        // const isPartialContractNoDuplicate = this.offtakercontractList.some(
        //   (c, i) => i !== index && c.partialContractNo === contract.partialContractNo && c.partialContractNo !== null
        // );

        const isContractNoDuplicate = this.offtakercontractList.some(
          (c, i) => i !== index && c.contractNo === contract.contractNo && c.partialContractNo === null
        );

        console.log("isContractNoDuplicate",isContractNoDuplicate)

        // if (isPartialContractNoDuplicate) {
        //   // If two partialContractNo are the same, delete the current entry
        //   flag = true;
        //   Utils.make_alert("warning", "Partial Contract already exists.");
        //   this.deleteOfftakerContract(index);
        //   return;
        // } else if (!contract.partialContractNo && isContractNoDuplicate) {
        if (!contract.partialContractNo && isContractNoDuplicate) {
          // If no partialContractNo and two contractNo are the same, delete the current entry
          flag = true;
          Utils.make_alert("warning", "This Contract already exists if your wish to add this contract then map it with partial contract.");
          this.deleteOfftakerContract(index);
          return;
        }

        if (contract.repaymentFlag) {
          // Check if other fields are not null
          if (
            contract.partialContractNo === null
          ) {
            Utils.make_alert("warning", "Please map partial contract as this contract is fully utilized except partial contracts");
            partialFlag = true;
            return;
          }
        }
      });

      // If there are no errors, proceed with saving
      if (!flag && !partialFlag) {
        Utils.make_alert("success", "Record saved.");
        this.offtakerContractSaveInfoDisabled = false;
        this.offtakerContractAddMoreDisabled = false;
      }
    },


    // //this method will make disabled property as false for Director Save Info
    // onOfftakerContractSaveInfo() {
    //   let flag = false;
    //   // Check the conditions and set the flag and error message
    // this.offtakercontractList.forEach(contract => {
    //   if (contract.partialContractNo) {
    //     // If there is a partial ID, check if Quantity is greater than PartialContractQuantity
    //     if (contract.Quantity > contract.PartialContractQuantity) {
    //       flag = true;
    //       contract.QuantityError = true;
    //       contract.QuantityErrorMessage =  `Quantity should not be greater than Partial Contract Quantity ${contractPartialContractQuantity}`;
    //     }
    //   } else {
    //     // If there is no partial ID, check if Quantity is greater than ContractQuantity
    //     if (contract.Quantity > contract.ContractQuantity) {
    //       flag = true;
    //       contract.QuantityError = true;
    //       contract.QuantityErrorMessage =  `Quantity should not be greater than Contract Quantity ${contract.ContractQuantity}`;
    //     }
    //   }
    // });
    //   if (!flag){
    //     Utils.make_alert("success", "Record saved.");
    //     this.offtakerContractSaveInfoDisabled = false;
    //     this.offtakerContractAddMoreDisabled = false;
    //   }
    // },

          // let selectedQuantity = this.offtakercontractList[index].Quantity;
      // let selectedContractQuantity = this.offtakercontractList[index].ContractQuantity;
      // let selectedPartialContractQuantity = this.offtakercontractList[index].PartialContractQuantity;
      // let contractNoID = this.offtakercontractList[index].contractNo;
      // let partialContractNoID = this.offtakercontractList[index].partialContractNo;
      // if(!partialContractNoID && !contractNoID){
      //   console.log("hello")
      //   let partial_contract_count = this.offtakercontractList.filter(
      //     (col) => col.partialContractNo === this.offtakercontractList[index].partialContractNo
      //   ).length;
      //   // delete if existing loan is found
      //   if (partial_contract_count > 1) {
      //     this.deleteOfftakerContract(index);
      //     Utils.make_alert("warning", "Partial Contract already exists.");
      //     return
      //   }
      //   if(Number(selectedQuantity) > Number(selectedPartialContractQuantity)){
      //     this.offtakercontractList[index].QuantityError = true;
      //     this.offtakercontractList[index].QuantityErrorMessage = `Quantity cannot be greater than Partial Contract Quantity ${selectedContractQuantity}`;
      //     return;
      //   } else {
      //     this.offtakercontractList[index].QuantityError = false;
      //   }
      // }
      // if((partialContractNoID == null) && !contractNoID){
      //   console.log("hello 1")
      //   let contract_count = this.offtakercontractList.filter(
      //     (col) => col.contractNo === this.offtakercontractList[index].contractNo
      //   ).length;
      //   // delete if existing loan is found
      //   if (contract_count > 1) {
      //     this.deleteOfftakerContract(index);
      //     Utils.make_alert("warning", "This Contract already exists if your wish to add this contract then map it with partial contract");
      //     return
      //   }
      //   if(Number(selectedQuantity) > Number(selectedContractQuantity)){
      //     this.offtakercontractList[index].QuantityError = true;
      //     this.offtakercontractList[index].QuantityErrorMessage = `Quantity cannot be greater than contract quantity ${selectedContractQuantity}`;
      //     return;
      //   } else {
      //     this.offtakercontractList[index].QuantityError = false;
      //   }
      // }
      // console.log("hello 2")

    deleteLoanDocuments(index) {
      this.LoanDocumentFile = this.LoanDocumentFile
        ? this.LoanDocumentFile
        : "file deleted";
      this.LoanDocuments.splice(index, 1);
      if (
        typeof this.LoanDocuments !== "undefined" &&
        this.LoanDocuments.length == 0
      ) {
        this.LoanDocumentsAddMoreDisabled = false;
        this.LoanDocumentSaveInfoDisabled = true;
      }
    },

    deleteOfftakerContract(index) {
      this.offtakercontractList.splice(index, 1);
      if (
        typeof this.offtakercontractList !== "undefined" &&
        this.offtakercontractList.length == 0
      ) {
        this.offtakerContractAddMoreDisabled = false;
        this.offtakerContractSaveInfoDisabled = true;
      }
    },

    onChangeInterestRateType(){
      const selectedType = this.InterestRateType.find(type => type.id === this.selectedInterestRateType);
      if (selectedType) {
        this.simpleInterestFlag = selectedType.simple_interest_flag;
        this.compoundInterestFlag = selectedType.compound_interest_flag;
      } else {
        this.simpleInterestFlag = 0
        this.compoundInterestFlag = 0
      }
      if (this.selectedInterestRateType == 1 || this.selectedInterestRateType == 2) {
        this.Spread = null;
        this.selectedBenchmark = null;
      } else if (this.selectedInterestRateType == 3 || this.selectedInterestRateType == 4) {
        this.InterestRate = null;
      } else {
        this.Spread = null;
        this.selectedBenchmark = null;
        this.InterestRate = null;
      }
    },


    // onChangeQuantityValidation(index){
    //   console.log("onChangeQuantityValidation")
    //   let selectedQuantity = this.offtakercontractList[index].Quantity;
    //   let selectedContractQuantity = this.offtakercontractList[index].ContractQuantity;
    //   let selectedPartialContractQuantity = this.offtakercontractList[index].PartialContractQuantity;
    //   let contractNoID = this.offtakercontractList[index].contractNo;
    //   let partialContractNoID = this.offtakercontractList[index].partialContractNo;
    //   console.log(contractNoID)
    //   console.log(partialContractNoID)
    //   console.log(typeof partialContractNoID)
    //   if(partialContractNoID && contractNoID){
    //     if(Number(selectedQuantity) > Number(selectedPartialContractQuantity)){
    //       this.offtakercontractList[index].QuantityError = true;
    //       this.offtakercontractList[index].QuantityErrorMessage = `Quantity cannot be greater than Partial Contract Quantity ${selectedPartialContractQuantity}`;
    //       return;
    //     } else {
    //       this.offtakercontractList[index].QuantityError = false;
    //       this.offtakercontractList[index].QuantityErrorMessage = "";
    //       return;
    //     }
    //   } else if(!partialContractNoID && contractNoID){
    //     console.log("hello")
    //     if(Number(selectedQuantity) > Number(selectedContractQuantity)){
    //       console.log("hello 1")
    //       this.offtakercontractList[index].QuantityError = true;
    //       this.offtakercontractList[index].QuantityErrorMessage = `Quantity cannot be greater than contract quantity ${selectedContractQuantity}`;
    //       return;
    //     } else {
    //       console.log("hello 2")
    //       this.offtakercontractList[index].QuantityError = false;
    //       this.offtakercontractList[index].QuantityErrorMessage = "";
    //       return;
    //     }
    //   } else {
    //     console.log("hello 3")
    //     this.offtakercontractList[index].QuantityError = false;
    //     this.offtakercontractList[index].QuantityErrorMessage = "";
    //     return;
    //   }

    // },
     //this function is called to get list of contract numbers
    getContractNoByOfftakerName(index,offtakerNameID) {
      console.log("inside get getContractNoByOfftakerName")

      if (offtakerNameID === null) {
        this.offtakercontractList[index].contractNo = null;
        this.offtakercontractList[index].contractNumber = [];
        this.offtakercontractList[index].partialContractNo = null;
        this.offtakercontractList[index].partialContractNumber = [];
        this.offtakercontractList[index].ContractDate = null;
        this.offtakercontractList[index].PartialContractDate = null;
        this.offtakercontractList[index].ContractQuantity = null;
        this.offtakercontractList[index].PartialContractQuantity = null;
        this.offtakercontractList[index].Quantity = null;
        this.offtakercontractList[index].selectedUnit = null;
        this.offtakercontractList[index].selectedCollateral = null;
        this.offtakercontractList[index].TotalContractQuantity = null;
        this.offtakercontractList[index].repaymentFlag = '0';
        this.offtakercontractList[index].requiredFlag = false;
        return
      }
      // let contractNumber = [
        //       { contractNo: 'ABC123', contractId: 1 ,ContractQuantity: 100, ContractDate:null;, unit:1},
      //       { contractNo: 'DEF456', contractId: 2 ,ContractQuantity: 200, ContractDate:null;, unit:1},
      //       { contractNo: 'GHI789', contractId: 3 ,ContractQuantity: 300, ContractDate:null;, unit:1},
      //     ]
      // this.offtakercontractList[index].contractNumber = contractNumber;

      this.offtakercontractList[index].contractNo = null;
      this.offtakercontractList[index].partialContractNo = null;
      this.offtakercontractList[index].partialContractNumber = [];
      this.offtakercontractList[index].ContractDate = null;
      this.offtakercontractList[index].PartialContractDate = null;
      this.offtakercontractList[index].ContractQuantity = null;
      this.offtakercontractList[index].PartialContractQuantity = null;
      this.offtakercontractList[index].Quantity = null;
      this.offtakercontractList[index].selectedUnit = null;
      this.offtakercontractList[index].selectedCollateral = null;
      this.offtakercontractList[index].TotalContractQuantity = null;
      this.offtakercontractList[index].repaymentFlag = '0';
      this.offtakercontractList[index].requiredFlag = true;

      Utils.start_loading();
      // const path = "/get-contract-no-by-offtaker/" + offtakerNameID + "/" + this.borrower_id;
      const path = 
        "/get-contract-no-by-offtaker" + 
        "/" + 
        offtakerNameID +
        "/" + 
        this.borrower_id +
        "/" +
        null +
        "/" +
        'Offtaker'
      axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
      .then(res => {
          Utils.stop_loading();
          console.log("status",res.data.status)
          if(res.data.status == "success"){
            this.offtakercontractList[index].contractNo = null;
            this.offtakercontractList[index].contractNumber = res.data.contract_numbers;
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          Utils.stop_loading();
          console.error(error);
        });
    },

     //this function is called to get list of contract numbers
    getContractNoDetails(index,contractNoID) {
      console.log("inside get getContractNoDetails")
      
      console.log("selectedContract",contractNoID)
      if (contractNoID === null) {
        this.offtakercontractList[index].contractNo = null;
        this.offtakercontractList[index].partialContractNo = null;
        this.offtakercontractList[index].partialContractNumber = [];
        this.offtakercontractList[index].ContractDate = null;
        this.offtakercontractList[index].PartialContractDate = null;
        this.offtakercontractList[index].ContractQuantity = null;
        this.offtakercontractList[index].PartialContractQuantity = null;
        this.offtakercontractList[index].Quantity = null;
        this.offtakercontractList[index].selectedUnit = null;
        this.offtakercontractList[index].selectedCollateral = null;
        this.offtakercontractList[index].TotalContractQuantity = null;
        this.offtakercontractList[index].repaymentFlag = '0';
        this.offtakercontractList[index].requiredFlag = false;
        return
      }

      let selectedContract = this.offtakercontractList[index].contractNumber.find(contract => contract.contractId === contractNoID);

      console.log("selectedContract",selectedContract)

      // let partialContractNumber = [
      //       { partialContractNo: 'partial ABC123', partialContractId: 1 , PartialContractDate: null;, PartialContractQuantity: 10},
      //       { partialContractNo: 'partial DEF456', partialContractId: 2 , PartialContractDate: null;, PartialContractQuantity: 20},
      //       { partialContractNo: 'partial GHI789', partialContractId: 3 , PartialContractDate: null;, PartialContractQuantity: 30},
      //     ]
      
      this.offtakercontractList[index].partialContractNo = null;
      this.offtakercontractList[index].partialContractNumber = [];
      this.offtakercontractList[index].ContractDate = selectedContract.ContractDate;
      this.offtakercontractList[index].PartialContractDate = null;
      this.offtakercontractList[index].ContractQuantity = selectedContract.ContractQuantity;
      this.offtakercontractList[index].Quantity = selectedContract.ContractQuantity;
      this.offtakercontractList[index].selectedUnit = selectedContract.unit;
      this.offtakercontractList[index].selectedCollateral = selectedContract.collateral_id;
      this.offtakercontractList[index].TotalContractQuantity = selectedContract.TotalContractQuantity;
      this.offtakercontractList[index].repaymentFlag = selectedContract.repayment_flag;
      if(this.offtakercontractList[index].Quantity == 0){
        this.offtakercontractList[index].repaymentFlag = '1';
      }


      Utils.start_loading();
      // const path = "/get-partial-contract-no/" + contractNoID;
      const path = 
        "/get-partial-contract-no" + 
        "/" + 
        contractNoID +
        "/" +
        null +
        "/" +
        this.contractType
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          Utils.stop_loading();
          console.log("status",res.data.status)
          if(res.data.status == "success") {
            this.offtakercontractList[index].partialContractNo = null;
            this.offtakercontractList[index].partialContractNumber = res.data.partial_contracts;
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          console.error(error);
        });
    },

    //this function is called to get list of contract numbers
    getPartialContractNoDetails(index,contractNoID,partialContractNoID) {
      console.log("inside get getPartialContractNoDetails")
      let partial_contract_count = this.offtakercontractList
        .filter(col => col.partialContractNo !== null)
        .filter(col => col.partialContractNo === this.offtakercontractList[index].partialContractNo)
        .length;
        // delete if existing loan is found
        if (partial_contract_count > 1) {
          Utils.make_alert("warning", "Partial Contract already exists.");
          this.deleteOfftakerContract(index);
          return
        }
      
      if (partialContractNoID === null) {
        let selectedContract = this.offtakercontractList[index].contractNumber.find(contract => contract.contractId === contractNoID);
        this.offtakercontractList[index].partialContractNo = null;
        this.offtakercontractList[index].ContractDate = selectedContract.ContractDate;
        this.offtakercontractList[index].PartialContractDate = null;
        this.offtakercontractList[index].ContractQuantity = selectedContract.ContractQuantity;
        this.offtakercontractList[index].Quantity = selectedContract.ContractQuantity;
        this.offtakercontractList[index].selectedUnit = selectedContract.unit;
        this.offtakercontractList[index].selectedCollateral = selectedContract.collateral_id;
        this.offtakercontractList[index].TotalContractQuantity = selectedContract.TotalContractQuantity;
        this.offtakercontractList[index].repaymentFlag = selectedContract.repayment_flag;
        return;
      }

      let selectedPartialContract = this.offtakercontractList[index].partialContractNumber.find(partialContract => partialContract.partialContractId === partialContractNoID);

      this.offtakercontractList[index].PartialContractDate = selectedPartialContract.PartialContractDate;
      this.offtakercontractList[index].PartialContractQuantity = selectedPartialContract.PartialContractQuantity;
      this.offtakercontractList[index].Quantity = selectedPartialContract.PartialContractQuantity;

    },

    calculateFinalLoanAmount() {
      if (this.requestedAmount && this.conversionRate) {
          let final_requestedAmount = Number(this.requestedAmount) * Number(this.conversionRate);
          this.finalLoanRequestedAmount = final_requestedAmount.toFixed(2);
          this.finalLoanRequestedAmount = Number(this.finalLoanRequestedAmount) == 0 ? null : this.finalLoanRequestedAmount;
        } else {
        this.finalLoanRequestedAmount = null;
      }
    },

    getLoanCurrencyExchangeRateByCurrencyDate() {
      //this function is called to get fund currency of that facility
      console.log("inside getLoanCurrencyExchangeRateByCurrencyDate")
      if(this.selectedFundCurrency && this.selectedLoanCurrency && this.conversionDate){
        if (this.selectedFundCurrency == this.selectedLoanCurrency) {
          this.conversionRate = 1;
          this.conversionDate = this.RequestDate;
          this.calculateFinalLoanAmount();
          return;
        }
        let currencyDate = null;
        if (this.conversionDate != null) {
          currencyDate = moment(this.conversionDate).format("MM-DD-YYYY");
        }
        const path = "/currency-exchange-rate/" +
          this.selectedFundCurrency +
          "/" +
          this.selectedLoanCurrency +
          "/" +
          currencyDate;
        Utils.start_loading();
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            console.log(res.data)
            let data = res.data.currency_rate[0]
            if(data != null){
              // this.currencyConversionFlag = true
              this.conversionRate =  data.currency_value;
              this.conversionDate =  data.date;
              this.calculateFinalLoanAmount();
            }else{
              this.selectedLoanCurrency = this.selectedFundCurrency;
              this.conversionRate = 1;
              this.conversionDate = this.RequestDate;
              this.calculateFinalLoanAmount();
              // this.conversionDate = null;
            }
            Utils.stop_loading();
          })
          .catch((error) => {
            console.error(error);
            Utils.stop_loading();
          });
      }
    },

    getLoan() {
      console.log("getLoan")
      Utils.start_loading();
      const path = "/add_loan/" + this.borrower_id + "/" + this.facility_id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          this.selectedBorrowerName = res.data.loan[0].borrower_name;
          // this.facility_registration_id = res.data.loan.facility_id;
          this.facility_registration_id = this.facility_id;
          this.selectedFund = res.data.loan[0].fund_id;
          this.RequestDate = new Date();
          this.insertBackcapConversionDate()
          this.selectedFundCurrency = res.data.loan[0].fund_currency_id;
          this.selectedLoanCurrency = res.data.loan[0].fund_currency_id;
          this.getLoanCurrencyExchangeRateByCurrencyDate()
          this.facility_limit = res.data.loan[0].facility_limit;
          this.compare_requested_amount = res.data.compare_requested_amount;
          if (res.data.status == "success") {
            console.log("get facility details is successful.");
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },


    onSubmit() {
      let flag = false;
      let partialFlag = false;

      // Check for duplicates and set the flag and error message
      this.offtakercontractList.forEach((contract, index) => {
        // Add a condition to check if requiredFlag is true
        if (contract.requiredFlag) {
          // Check if other fields are not null
          if (
            contract.contractNo === null
          ) {
            flag = true;
          }
        }
        const isContractNoDuplicate = this.offtakercontractList.some(
          (c, i) => i !== index && c.contractNo === contract.contractNo && c.partialContractNo === null
        );
        if (!contract.partialContractNo && isContractNoDuplicate) {
          // If no partialContractNo and two contractNo are the same, delete the current entry
          flag = true;
          Utils.make_alert("warning", "This Contract already exists if your wish to add this contract then map it with partial contract.");
          this.deleteOfftakerContract(index);
          return;
        }
        if (contract.repaymentFlag) {
          // Check if other fields are not null
          if (
            contract.partialContractNo === null
          ) {
            Utils.make_alert("warning", "Please map partial contract as this contract is fully utilized except partial contracts");
            partialFlag = true;
            return;
          }
        }
      });

      console.log("flag onsubmit: " + flag);
      console.log("partialFlag onsubmit: " + partialFlag);
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid || flag || partialFlag) {
        return;
      }
      console.log("flag  final onsubmit: " + flag);
      if (this.RequestDate != null) {
        this.RequestDate1 = moment(this.RequestDate).format("MM-DD-YYYY");
      }
      let conversionDate = null;
      if (this.conversionDate != null) {
        conversionDate = moment(this.conversionDate).format("MM-DD-YYYY");
      }
      if (this.InterestChargeable === "InterestDrawdown") {
        this.InterestDrawdown = true;
        this.InterestUtilisationRequest = false;
        this.InterestUtilisationRequestDrawdownAmount = false;
      } else if (this.InterestChargeable === "InterestUtilisationRequest") {
        this.InterestDrawdown = false;
        this.InterestUtilisationRequest = true;
        this.InterestUtilisationRequestDrawdownAmount = false;
      } else if (this.InterestChargeable === "InterestUtilisationRequestDrawdownAmount") {
        this.InterestDrawdown = false;
        this.InterestUtilisationRequest = false;
        this.InterestUtilisationRequestDrawdownAmount = true;
      }
      console.log(this.InterestDrawdown,this.InterestUtilisationRequest,this.InterestUtilisationRequestDrawdownAmount)
      for (let i = 0; i < this.LoanDocuments.length; i++) {
        if (this.LoanDocuments[i].LoanDocumentsDate !== null) {
          this.LoanDocuments[i].LoanDocumentsDate = moment(
            this.LoanDocuments[i].LoanDocumentsDate
          ).format("MM-DD-YYYY");
        }
        if (this.LoanDocuments[i].ExpiryDate !== null) {
          this.LoanDocuments[i].ExpiryDate = moment(
            this.LoanDocuments[i].ExpiryDate
          ).format("MM-DD-YYYY");
        }
      }
      this.offtakercontractList.map((doc, index) => {
        if (doc.offtakerNameID === null) this.offtakercontractList.splice(index, 1);
      });
      for (let i = 0; i < this.offtakercontractList.length; i++) {
        if (this.offtakercontractList[i].ContractDate !== null) {
          this.offtakercontractList[i].ContractDate = moment(
            this.offtakercontractList[i].ContractDate
          ).format("MM-DD-YYYY");
        }
        if (this.offtakercontractList[i].PartialContractDate !== null) {
          this.offtakercontractList[i].PartialContractDate = moment(
            this.offtakercontractList[i].PartialContractDate
          ).format("MM-DD-YYYY");
        }
      }
      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      // formData.append("borrowerName", this.selectedBorrowerName);
      formData.append("fundName", this.selectedFund);
      formData.append(
        "facility_registration_id",
        this.facility_registration_id
      );
      formData.append("RequestDate", this.RequestDate1);
      formData.append("Amount", this.requestedAmount);
      formData.append("selectedInterestRateType", this.selectedInterestRateType);
      formData.append("simpleInterestFlag", this.simpleInterestFlag);
      formData.append("compoundInterestFlag", this.compoundInterestFlag);
      formData.append("InterestRate", this.InterestRate);
      formData.append("Spread", this.Spread);
      formData.append("selectedBenchmark", this.selectedBenchmark);
      formData.append("tenure", this.Tenure);
      formData.append("period", this.selectedPeriod);
      formData.append("selectedDayCount", this.selectedDayCount);
      formData.append("selectedCurrency", this.selectedLoanCurrency);
      formData.append("conversionRate", this.conversionRate);
      formData.append("conversionDate", conversionDate);
      formData.append("finalLoanRequestedAmount", this.finalLoanRequestedAmount);
      formData.append("Supplier", this.Supplier);
      formData.append("Comments", this.comments);
      //append offtaker information to formdata
      formData.append(
        "offtakercontractList",
        JSON.stringify(this.offtakercontractList)
      );
      formData.append("InterestDrawdown", this.InterestDrawdown);
      formData.append("InterestUtilisationRequest",this.InterestUtilisationRequest);
      formData.append("InterestUtilisationRequestDrawdownAmount",this.InterestUtilisationRequestDrawdownAmount);
      //append LoanDocuments information to formdata
      formData.append("loanDocumentList", JSON.stringify(this.LoanDocuments));

      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 5);
      formData.append("tabId", 16);
      const path = "/add_loan/" + this.borrower_id + "/" + this.facility_id;
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          if (response.data.status == "success") {
            Utils.make_alert(
              "success",
              "Utilisation Request added successfully!"
            );
            console.log("SUCCESS!!");
            this.$router.push("/loan-overview/" + this.borrower_id);
          } else {
            Utils.make_alert("success", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
          Utils.make_alert("success", "Something went wrong!");
        });
    }, //onSubmit() close

    //this method will add new line for additional covenants
    addOfftakerContract() {
      //when new line is added then offtakerContractSaveInfoDisabled (Save info button disable property) should be re initialised to true.
      (this.offtakerContractSaveInfoDisabled = true),
        (this.offtakerContractAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.offtakercontractList.push({
          offtakerNameID: null,    // id
          requiredFlag: false,
          contractNumber: [],
          partialContractNumber: [],
          contractNo: null,        // id
          partialContractNo: null, // id
          ContractDate: null,
          PartialContractDate: null,
          ContractQuantity: null,
          PartialContractQuantity: null,
          TotalContractQuantity: null,  // 500MT loan_contract_qty
          Quantity: null, // 300MT  oustanding_contract_qty
          selectedUnit: null,     // id
          selectedCollateral: null,     // id
          repaymentFlag: '0',
        });
    }, //addOfftakerContract() close

    //this method will add new line for additional Loan Documents
    addLoanDoc() {
      //when new line is added then LoanDocumentFile variable and LoanDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.LoanDocumentFile = null),
        (this.LoanDocumentSaveInfoDisabled = true),
        (this.LoanDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.LoanDocuments.push({
          LoanDocName: null,
          LoanDocumentsDate: null,
          ExpiryDate: null,
          comments: null,
          displayFileName: null,
          errors: { filesize: "" },
          LoanDocumentRemoveDisabled: true,
        });
    }, //addLoanDoc() close

    //handles a change on the file upload
    handleFileUploadLoanDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.LoanDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.LoanDocumentFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors.filesize = "File size should be less than 100MB";
          self.LoanDocuments[id].errors.filesize = JSON.stringify(
            response.errors.filesize
          ).replace(/\"/g, "");
          self.LoanDocuments[id].selectedFile = null;
          self.LoanDocuments[id].selectedFileName = null;
          self.LoanDocuments[id].displayFileName = null;
          self.LoanDocuments[id].LoanDocumentRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.LoanDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.LoanDocuments[id].selectedFile = reader.result;
          self.LoanDocuments[id].selectedFileName = e.target.files[0].name;
          self.LoanDocuments[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.LoanDocuments[id].errors.filesize = "";
          self.LoanDocuments[id].LoanDocumentRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadLoanDocuments() close

    ////this method handles remove doc attachment
    removeLoanDocumentsDoc(id) {
      this.LoanDocumentFile = null;
      this.LoanDocuments[id].selectedFile = null;
      this.LoanDocuments[id].selectedFileName = null;
      this.LoanDocuments[id].displayFileName = null;
      this.LoanDocuments[id].LoanDocumentRemoveDisabled = true;
    },

    //this function is called to get list of documents master
    getLoanDocuments() {
      const path = "loan-document";
      axios
        .get(path)
        .then((res) => {
          this.LoanDocs = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getLoanDocumentsDocuments() close

    getDayCount() {
      //this function is called to get list of day count
      const path = "day-count-master";
      axios
        .get(path)
        .then((res) => {
          this.dayCounts = res.data.day_counts;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getAllFacilitySetupByBorrower() {
      //this function is called to get list of day count
      const path = "/all-facility-setup-borrower/" + this.borrower_id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // console.log(res.data.Facility_Setup);
          this.FacilitySetup = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBenchmarks() {
      //this function is called to get list of benchmarks
      const path = "benchmark";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.benchmarks = res.data.benchmarks;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getUnitTypes() {
      //this function is called to get list of benchmarks
      const path = "unit_types";
      axios
        .get(path)
        .then((res) => {
          this.UnitTypes = res.data.UnitTypes;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLoanType() {
      //this function is called to get list of funding
      const path = "loan-type";
      axios
        .get(path)
        .then((res) => {
          this.LoanType = res.data.loan_type;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCollateral() {
      //this function is called to get list of Collateral
      const path = "collateral";
      axios
        .get(path)
        .then((res) => {
          this.collaterals = res.data.collateral;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // getBorrowers() {
    //   const path = "borrower-master";
    //   axios
    //     .get(path, { params: { clientId: this.$store.state.client_id } })
    //     .then((res) => {
    //       this.borrowers = res.data.borrowers;
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },

    // getFacilitySetupByBorrower() {
    //   //this function is called to get list of day count
    //   const path = "/facility-setup-borrower/" + this.;borrower_id
    //   axios
    //     .get(path, { params: { clientId: this.$store.state.client_id } })
    //     .then((res) => {
    //       this.facilities = res.data.Facility_Setup;
    //       // this.selectedFacility = this.facilities[0].id
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },

    getBorrowers() {
      const path = "borrower-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.borrowers = res.data.borrowers;
          this.borrowers.filter((bor) => {
              if (bor.borrower_id.toString() == this.borrower_id)
                this.selectedBorrowerName = bor.borrower_name;
            });
          if (this.loggedInUserGroupId === "Borrower") {
            this.borrowers.filter((bor) => {
              if (bor.borrower_id.toString() == this.loggedInUserSubGroupId)
                this.selectedBorrowerName = bor.borrower_name;
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLoanInterestRateTypes() {
      const path = "get-loan-interest-type";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.InterestRateType = res.data.loan_interest_types;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getOfftakerNamesByBorrower() {
      // const path = "/get-offtaker-name-by-borrower/" + this.borrower_id;
      const path = 
      "/get-offtaker-name-by-borrower" + 
      "/" + 
      this.borrower_id +
      "/" +
      null +
      "/" +
      this.contractType;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.offtakerNameIDs = res.data.offtaker_names;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFunds() {
      const path = "fund-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.Funds = res.data.funds;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getInterestTypes() {
      //this function is called to get list of interest types
      const path = "interest-type";
      axios
        .get(path)
        .then((res) => {
          this.interestTypes = res.data.interest_types;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
          this.currencies_loan = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close
 
  mounted() {
    this.addLoanDoc();
    // this.addOfftakerContract();
    this.onAddFacilitySelect();
    // var numberOfDecimals = 2;
    // $(document).on("input", ".allow-only-numbers", function() {
    //   var regExp = new RegExp("(\\.[\\d]{" + numberOfDecimals + "}).", "g");
    //   this.value = this.value
    //     .replace(/[^0-9.]/g, "")
    //     .replace(/(\..*)\./g, "$1")
    //     .replace(regExp, "$1");
    // });
  },

  created() {
    this.borrower_id = this.$route.params.id;
    console.log(this.borrower_id)
    if(this.$route.params.facRegDate){
      this.facRegDate = moment
        .utc(this.$route.params.facRegDate, "MM-DD-YYYY")
        .toDate();
    }

    this.facility_id = this.$route.params.facilityId;
    this.getLoan();
    this.getUnitTypes();
    this.getLoanDocuments();
    this.getBenchmarks();
    this.getLoanType();
    this.getCurrencies();
    this.getBorrowers();
    this.getLoanInterestRateTypes();
    this.getFunds();
    this.getInterestTypes();
    this.getDayCount();
    this.getAllFacilitySetupByBorrower();
    this.getOfftakerNamesByBorrower();
    this.getCollateral();
  },
};
</script>

<style scoped>

input[type="radio"] {
  margin-right: 5px;
}

.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -75px;
}

.vdp-datepicker >>> .form-control[disabled] {
  background-color:#EEEEEE !important;
  opacity: 0.7;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.error {
  color: red;
  font-size: 13px;
  padding-left: 40px;
}

.required-field::after {
  content: "*";
  color: red;
}
</style>
