<!-- This is verification page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Verification
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedBorrower" class="required-field"
                      >Borrower Name</label
                    >
                    <select
                      id="selectedBorrower"
                      class="form-control"
                      name="selectedBorrower"
                      v-model="selectedBorrower"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedBorrower.$error,
                      }"
                      @change="
                        getFacilitySetupByBorrower(selectedBorrower) &
                          getWarehouseByBorrower(selectedBorrower)
                      "
                      :disabled="loggedInUserGroupId === 'Borrower' || id > 0"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="bor in borrowers"
                        :value="bor.borrower_id"
                        :key="bor.borrower_id"
                      >
                        {{ bor.borrower_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedBorrower.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedBorrower.required"
                        >Borrower Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedFacilityId" class="required-field"
                      >Facility ID</label
                    >
                    <select
                      id="selectedFacilityId"
                      class="form-control"
                      name="selectedFacilityId"
                      v-model="selectedFacilityId"
                      @change="getCollateral(selectedFacilityId)"
                      :disabled="id > 0"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedFacilityId.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="fac in facilities"
                        :value="fac.id"
                        :key="fac.id"
                      >
                        {{ fac.facility_registration_id }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedFacilityId.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedFacilityId.required"
                        >Facility ID is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedCollateral" class="required-field"
                      >Collateral</label
                    >
                    <select
                      id="selectedCollateral"
                      class="form-control"
                      name="selectedCollateral"
                      v-model="selectedCollateral"
                      :disabled="id > 0"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedCollateral.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="col in collaterals"
                        :value="col.id"
                        :key="col.id"
                      >
                        {{ col.collateral_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCollateral.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCollateral.required"
                        >Collateral is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="selectedWarehouse" class="required-field"
                      >Warehouse</label
                    >
                    <select
                      id="selectedWarehouse"
                      class="form-control"
                      name="selectedWarehouse"
                      v-model="selectedWarehouse"
                      @change="onWarehouseChange()"
                      :disabled="id > 0"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedWarehouse.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="war in warehouses"
                        :value="war.id"
                        :key="war.id"
                      >
                        {{ war.warehouse_registration_id + ": " }}
                        {{ war.address }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedWarehouse.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedWarehouse.required"
                        >Warehouse is required</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="loggedInUserGroupId === 'Admin'"
                >
                  <div class="form-group fc">
                    <label for="selectedCM" class="required-field"
                      >Collateral Manager Company</label
                    >
                    <select
                      id="selectedCM"
                      class="form-control"
                      name="selectedCM"
                      v-model="selectedCM"
                      :disabled="id > 0"
                      @change="onCMChange()"
                      :class="{
                        'is-invalid':
                          (isSubmitted && $v.selectedCM.$error) ||
                          emptyCMListError,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="cm in mappedCM"
                        :value="cm.collateral_manager_master_id"
                        :key="cm.collateral_manager_master_id"
                      >
                        {{ cm.collateral_id }}: {{ cm.company_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCM.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCM.required"
                        >Collateral Manager Company is required</span
                      >
                    </div>
                    <div v-if="emptyCMListError" class="invalid-feedback">
                      <span
                        >No Collateral Manager Company is available for the
                        selected warehouse</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="loggedInUserGroupId !== 'Admin'"
                ></div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="requestDate" class="required-field"
                      >Request Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="requestDate"
                      id="requestDate"
                      placeholder="Select Date"
                      v-model="requestDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabled="id > 0"
                      :disabledDates="{
                        from: new Date(),
                        to: new Date(verificationBackcapDate),
                      }"
                      :class="{
                        'is-invalid': isSubmitted && $v.requestDate.$error,
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.requestDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.requestDate.required"
                        >Request Date is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="quantity" class="required-field"
                      >Quantity (MT)</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="quantity"
                      v-model="quantity"
                      @keypress="removeMinus($event)"
                      :disabled="id > 0"
                      :class="{
                        'is-invalid': isSubmitted && $v.quantity.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.quantity.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quantity.required"
                        >Quantity is required</span
                      >
                      <span v-if="!$v.quantity.decimal"
                        >Quantity should be decimal only<br
                      /></span>
                      <span v-if="!$v.quantity.maxLength"
                        >Max length of 10 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="bags">Bags</label>
                    <input
                      type="text"
                      class="form-control"
                      id="bags"
                      v-model="bags"
                      :disabled="id > 0"
                      :class="{
                        'is-invalid': isSubmitted && $v.bags.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.bags.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.bags.numeric"
                        >Bags should be numeric only<br
                      /></span>
                      <span v-if="!$v.bags.maxLength"
                        >Max length of 10 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-3 col-sm-4 col-6"
                  v-if="loggedInUserGroupId === 'Admin'"
                >
                  <label>Employee Name</label>
                  <multiselect
                    v-model="selectedMappedCMEmployees"
                    placeholder="--- Select ---"
                    :custom-label="getEmployeeLabel"
                    track-by="id"
                    :options="mappedCMEmployees"
                    :multiple="true"
                    ><span slot="noResult"
                      >No Mapped CM Employee found with given input.</span
                    ></multiselect
                  >
                </div>
                <div class="col-2"></div>
                <!-- <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="loggedInUserGroupId === 'Admin'"
                >
                  <div class="form-group fc">
                    <label for="price">Price (Per MT)</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      id="price"
                      v-model="price"
                      @keyup="calculateValue()"
                      @keypress="removeMinus($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.price.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.price.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.price.decimal"
                        >Price should be decimal only<br
                      /></span>
                      <span v-if="!$v.price.maxLength"
                        >Max length of 10 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="loggedInUserGroupId === 'Admin'"
                >
                  <div class="form-group fc">
                    <label for="value">Value</label>
                    <input
                      type="number"
                      class="form-control"
                      id="value"
                      v-model="value"
                      disabled
                    />
                  </div>
                </div> -->

              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn" v-if="loggedInUserGroupId === 'Admin'">
                <li>
                  <button
                    type="button"
                    id="submit"
                    class="btn-sm btn btn-success sh"
                    @click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    style="cursor: pointer"
                    @click="onSubmit('next')"
                  >
                    Save & Next
                  </button>
                </li>
              </ul>
              <ul id="sr_btn" v-else>
                <li v-if="id === 0">
                  <button
                    type="button"
                    id="submit"
                    class="btn-sm btn btn-success sh"
                    @click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li v-if="id > 0">
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    style="cursor: pointer"
                    @click="
                      $router.push({
                        name: 'Uncleaned',
                        params: { id: id },
                      })
                    "
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {
  required,
  maxLength,
  numeric,
  decimal,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: null,
      borrowers: [],
      facilities: [],
      collaterals: [],
      warehouses: [],
      mappedCM: [],
      mappedCMEmployees: [],
      selectedMappedCMEmployees: [],
      prevCMEmployeeMapping: [],
      removedCMEmployees: [],
      //variables for request information
      selectedBorrower: null,
      selectedFacilityId: null,
      selectedCollateral: null,
      requestDate: new Date(),
      verificationBackcapDate: null,
      quantity: null,
      bags: null,
      selectedWarehouse: null,
      selectedCM: null,
      price: null,
      value: null,
      isSubmitted: false,
      emptyCMListError: false,
    };
  },
  validations: {
    selectedBorrower: {
      required,
    },
    selectedFacilityId: {
      required,
    },
    selectedCollateral: {
      required,
    },
    requestDate: {
      required,
    },
    quantity: {
      required: function () {
        if (!this.quantity || this.quantity == 0) return false;
        else return true;
      },
      decimal,
      maxLength: maxLength(10),
    },
    bags: {
      numeric,
      maxLength: maxLength(10),
    },
    selectedWarehouse: {
      required,
    },
    selectedCM: {
      required: function () {
        if (this.loggedInUserGroupId === "Admin" && this.selectedCM == null)
          return false;
        else return true;
      },
    },
    price: {
      decimal,
      maxLength: maxLength(10),
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
  components: {
    Datepicker,
    Multiselect: window.VueMultiselect.default,
  },
  methods: {
    getEmployeeLabel({ employee_registration_id, employee_name }) {
      return `${employee_registration_id} - ${employee_name}`;
    },

    removeMinus(e) {
      let invalidChars = ["-"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true; // If not match, don't add to input text
    },
    calculateValue() {
      if (this.quantity) this.value = this.quantity * this.price;
      this.value = Number(this.value) == 0 ? null : this.value;
    },

    onWarehouseChange(requestData) {
      // this function is used to get mapped CM Companies for a particular Warehouse
      Utils.start_loading();
      const path = "get-mapped-cm/" + this.selectedWarehouse;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.mappedCM = res.data.warehouse_cm_mapping_list;
            this.emptyCMListError = this.mappedCM.length ? false : true;
            if (this.id) {
              this.onCMChange();
              this.selectedMappedCMEmployees = requestData;
            }
            console.log(
              "get mapped CM Companies for a particular Warehouse is successful."
            );
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });

      if (
        this.selectedBorrower &&
        this.selectedFacilityId &&
        this.selectedCollateral &&
        this.selectedWarehouse
      ) {
        // this function is used to get verification backup date
        Utils.start_loading();
        const path1 =
          "get-verification-backup-date/" +
          this.selectedBorrower +
          "/" +
          this.selectedFacilityId +
          "/" +
          this.selectedCollateral +
          "/" +
          this.selectedWarehouse;
        axios
          .get(path1, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            Utils.stop_loading();
            if (res.data.status == "success") {
              this.verificationBackcapDate = res.data.verification_backup_date;
              console.log("get verification Backcap Date is successful.");
            } else {
              console.log("Something went wrong from server!");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
            console.log("Something went wrong!");
          });
      }
    },

    onCMChange() {
      this.removedCMEmployees = this.selectedMappedCMEmployees;
      this.selectedMappedCMEmployees = [];
      let selectedCMCompany = [];
      this.mappedCM.filter((cm) => {
        if (cm.collateral_manager_master_id === this.selectedCM)
          selectedCMCompany = cm;
      });
      let selectedWarehouseCMId = selectedCMCompany.id;
      if (selectedWarehouseCMId) {
        // this function is used to get mapped CM Company Employees for a particular mapped Warehouse
        Utils.start_loading();
        const path = "get-mapped-cm-employees/" + selectedWarehouseCMId;
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            Utils.stop_loading();
            if (res.data.status == "success") {
              this.mappedCMEmployees = res.data.warehouse_cm_emp_mapping_list;
              console.log(
                "get mapped CM Company Employees for a particular mapped Warehouse is successful."
              );
            } else {
              console.log("Something went wrong from server!");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error("error: " + error);
            console.log("Something went wrong!");
          });
      }
    },

    //this method will submit the Request form in backend.
    onSubmit(route) {
      this.$confirm({
        auth: false,
        message: "Confirm Submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            this.isSubmitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
              return;
            }
            if (this.requestDate != null) {
              this.requestDate = moment(this.requestDate).format("MM-DD-YYYY");
            }
            this.selectedMappedCMEmployees.map((emp) => {
              if (!emp.verification_mapped_cm_emp_id) {
                emp["verification_mapped_cm_emp_id"] = null;
              }
            });

            //initialize the form data
            let formData = new FormData();
            formData.append("borrowerId", this.selectedBorrower);
            formData.append("facilityId", this.selectedFacilityId);
            formData.append("collateralId", this.selectedCollateral);
            formData.append("collateralQuantity", this.quantity);
            formData.append("collateralBags", this.bags);
            formData.append("requestDate", this.requestDate);
            formData.append("warehouseId", this.selectedWarehouse);
            formData.append("mappedCMCompany", this.selectedCM);
            formData.append(
              "mappedCMCompanyEmployees",
              JSON.stringify(this.selectedMappedCMEmployees)
            );
            if (this.id) {
              let removedCMEmployeeMapping = this.prevCMEmployeeMapping.filter(
                ({ employee_id: id1 }) =>
                  !this.selectedMappedCMEmployees.some(
                    ({ employee_id: id2 }) => id2 === id1
                  )
              );
              removedCMEmployeeMapping = removedCMEmployeeMapping.concat(
                this.removedCMEmployees
              );
              formData.append(
                "removedCMEmployeeMapping",
                JSON.stringify(removedCMEmployeeMapping)
              );
            }
            formData.append("price", this.price);
            formData.append("value", this.value);
            formData.append(
              "clientId",
              JSON.stringify(this.$store.state.client_id)
            );
            formData.append("userId", this.$store.getters.getuserId);
            formData.append("moduleId", 11);
            formData.append("tabId", 28);
            const path = this.id ? "edit-request/" + this.id : "add-request";
            Utils.start_loading();
            axios
              .post(path, formData, {
                headers: {
                  enctype: "multipart/form-data",
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Verification details saved successfully!"
                  );
                  console.log("SUCCESS!!");
                  if (route === "next")
                    this.$router.push({
                      name: "Uncleaned",
                      params: { id: response.data.id },
                    });
                  else this.$router.push("/collateral");
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error(error);
              });
          }
        },
      });
    }, //onSubmit() close

    getRequest() {
      //this function is called on page load and created events. it will show details on Request with Requestid equal to this.id
      const path = "single-request-summary-overview/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.request =
              res.data.verification_request_details.request_details[0];
            this.selectedBorrower = this.request.borrower_id;
            this.getFacilitySetupByBorrower(this.selectedBorrower);
            this.getWarehouseByBorrower(this.selectedBorrower);
            this.selectedFacilityId = this.request.facility_id;
            this.getCollateral(this.selectedFacilityId);
            this.selectedCollateral = this.request.collateral_master_id;
            this.quantity = this.request.verification_quantity;
            this.bags = this.request.verification_bags;
            this.price = this.request.verification_price;
            this.value = this.request.verification_value;
            this.requestDate = this.request.request_date;
            this.selectedWarehouse = this.request.warehouse_master_id;
            this.prevCMEmployeeMapping =
              res.data.verification_request_details.verification_cm_emp_mapping;
            this.onWarehouseChange(this.prevCMEmployeeMapping);
            this.selectedCM = this.request.mapped_cm_company_id;
            this.calculateValue();
            if (this.value !== null) this.value = Number(this.value).toFixed(5);
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          Utils.make_alert(
            "warning",
            error.response.status === 400
              ? error.response.data.status
              : "Something went wrong!"
          );
          console.error("error: " + error);
        });
    }, //getRequest close

    getFacilitySetupByBorrower(id) {
      //this function is called to get list of open facilities
      const path = "/facility-setup-borrower/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.facilities = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getWarehouseByBorrower(id) {
      //this function is called to get list of warehouses
      const path = "/warehouse-borrower/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.warehouses = res.data.warehouses;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCollateral(id) {
      //this function is called to get list of collaterals by facility id
      const path = "/get-collateral/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.collaterals = res.data.collaterals;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function will be called to get list of borrowers
    getBorrowers() {
      const path = "borrower";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.borrowers = res.data.borrowers_active;
          //get Approved borrowers
          if (res.data.borrowers_active) {
            this.borrowers = this.borrowers.filter((bor) => {
              if (bor.status === "Approved") {
                return bor;
              }
            });
          }
          if (res.data.status == "success") {
            console.log("get borrowers is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },
  },

  mounted() {
    this.id = Number(this.$route.params.id);
    if (this.id) {
      this.getRequest();
    }
    if (this.loggedInUserGroupId === "Borrower") {
      this.selectedBorrower = Number(this.loggedInUserSubGroupId);
      this.getFacilitySetupByBorrower(this.loggedInUserSubGroupId);
      this.getWarehouseByBorrower(this.loggedInUserSubGroupId);
    } else this.selectedBorrower = null;
  },

  created() {
    this.getBorrowers();
  },
};
</script>
