<!-- This is add collateral page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Edit Collateral Manager
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <span style="font-size: 12px; color: red"
            >Note: Only single operation can be handled at a time (Edit or
            Delete)</span
          >
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label class="required-field">Company Name</label>
                    <input
                      class="form-control"
                      type="text"
                      id="companyName"
                      v-model="companyName"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.companyName.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.companyName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.companyName.required"
                        >Company Name is required</span
                      >
                      <span v-if="!$v.companyName.maxLength"
                        >Max length of 100 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Address</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      v-model="address"
                      :class="{
                        'is-invalid': isSubmitted && $v.address.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.address.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.address.required"
                        >Address is required</span
                      >
                      <span v-if="!$v.address.maxLength"
                        >Max length of 250 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Country</label
                    >
                    <select
                      id="selectedCountry"
                      class="form-control"
                      name="selectedCountry"
                      v-model="selectedCountry"
                      @change="getStatesByCountry(true)"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedCountry.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="country in countries"
                        :value="country.id"
                        :key="country.id"
                      >
                        {{ country.country_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCountry.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCountry.required"
                        >Country is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field">State</label>
                    <select
                      class="form-control"
                      name="selectedState"
                      id="selectedState"
                      v-model="selectedState"
                      @change="getCitiesByState(true)"
                      :class="{
                        'is-invalid':
                        isSubmitted && $v.selectedState.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="state in states"
                        :value="state.id"
                        :key="state.id"
                      >
                        {{ state.state_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedState.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedState.required"
                        >State is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label class="required-field">City</label>
                    <select
                      id="selectedCity"
                      class="form-control"
                      name="selectedCity"
                      v-model="selectedCity"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedCity.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="city in cities"
                        :value="city.id"
                        :key="city.id"
                      >
                        {{ city.city_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCity.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCity.required"
                        >City is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="website" class="required-field">Website</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="website"
                      id="website"
                      name="website"
                      maxlength="50"
                      :class="{
                        'is-invalid':
                          (isSubmitted && $v.website.$error) || msg.website,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.website.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.website.required"
                        >Website is required</span
                      >
                      <span v-if="!$v.website.maxLength"
                        >Max length of 100 characters exceeded</span
                      >
                    </div>
                    <div class="invalid-feedback" v-if="msg.website">
                      <span>{{ msg.website }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Registration Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="regDate"
                      id="regDate"
                      placeholder="Select Date"
                      v-model="regDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{ from: new Date() }"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.regDate.$error,
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.regDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.regDate.required"
                        >Registration Date is required</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Contact Details</h1>
                    </div>
                  </div>
                </div>
                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->
                  <div class="row">
                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="collateralContact" class="required-field"
                          >Contact No</label
                        ><br />
                        <select
                          id="collateralContactCode"
                          class="contact_code"
                          name="collateralContactCode"
                          v-model="collateralContactCode"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.collateralContactCode.$error,
                          }"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="code in countryCodes"
                            :key="code.id"
                            :value="code.id"
                          >
                            {{ code.country_code }}
                          </option>
                        </select>
                        <input
                          class="contact"
                          v-model="collateralContact"
                          id="collateralContact"
                          @keypress="isNum($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.collateralContact.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.collateralContactCode.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.collateralContactCode.required"
                            >Contact Number Code is required</span
                          >
                        </div>
                        <div
                          v-if="isSubmitted && $v.collateralContact.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.collateralContact.required"
                            >Contact Number is required</span
                          >
                          <span v-if="!$v.collateralContact.minLength"
                            >Min length of 10 characters required</span
                          >
                          <span v-if="!$v.collateralContact.maxLength"
                            >Max length of 13 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="secondaryCollateralContact"
                          >Secondary Contact No</label
                        ><br />
                        <select
                          id="secondaryCollateralContactCode"
                          class="contact_code"
                          name="secondaryCollateralContactCode"
                          v-model="secondaryCollateralContactCode"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.secondaryCollateralContactCode.$error,
                          }"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="code in countryCodes"
                            :key="code.id"
                            :value="code.id"
                          >
                            {{ code.country_code }}
                          </option>
                        </select>
                        <input
                          class="contact"
                          placeholder
                          v-model="secondaryCollateralContact"
                          id="secondaryCollateralContact"
                          @keypress="isNum($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.secondaryCollateralContact.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.secondaryCollateralContactCode.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.secondaryCollateralContactCode.required"
                            >Secondary Contact Number Code is required</span
                          >
                        </div>
                        <div
                          v-if="
                            isSubmitted && $v.secondaryCollateralContact.$error
                          "
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.secondaryCollateralContact.required"
                            >Secondary Contact Number is required<br
                          /></span>

                          <span v-if="!$v.secondaryCollateralContact.minLength"
                            >Min length of 10 characters required</span
                          >
                          <span v-if="!$v.secondaryCollateralContact.maxLength"
                            >Max length of 13 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="email" class="required-field"
                          >Email ID</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          v-model="email"
                          id="email"
                          name="email"
                          :class="{
                            'is-invalid':
                              (isSubmitted && $v.email.$error) || msg.email,
                          }"
                        />
                        <div class="invalid-feedback" v-if="msg.email">
                          <span>{{ msg.email }}</span>
                        </div>
                        <div
                          v-if="isSubmitted && $v.email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.email.required"
                            >Email ID is required</span
                          >
                          <span v-if="!$v.email.maxLength"
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="secondaryEmail">Secondary Email ID</label>
                        <input
                          type="email"
                          class="form-control"
                          v-model="secondaryEmail"
                          id="secondaryEmail"
                          name="secondaryEmail"
                          :class="{
                            'is-invalid':
                              (isSubmitted && $v.secondaryEmail.$error) ||
                              msg.secondaryEmail,
                          }"
                        />
                        <div class="invalid-feedback" v-if="msg.secondaryEmail">
                          <span>{{ msg.secondaryEmail }}</span>
                        </div>
                        <div
                          v-if="isSubmitted && $v.secondaryEmail.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.secondaryEmail.maxLength"
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- until here -->
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3" v-if="EmployeeList.length">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Employee Details</h1>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label class="required-field">Employee Name</label>
                        <select
                          class="form-control"
                          id="employee_name"
                          type="text"
                          v-model="selectedEmployee.id"
                          @change="
                            onEmployeeNameChange(selectedEmployee.emp_id,selectedEmployee.id)
                          "
                          required
                          :class="{
                            'is-invalid': $v.selectedEmployee.id.$error,
                          }"
                        >
                          <option
                            v-for="emp in EmployeeList"
                            :value="emp.id"
                            :key="emp.id"
                          >
                            {{ emp.employee_name }}
                          </option>
                        </select>
                        <div
                          v-if="$v.selectedEmployee.id.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedEmployee.id.required"
                            >Name is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="address" class="required-field"
                          >Address</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="address"
                          v-model="selectedEmployee.address"
                          :class="{
                            'is-invalid': $v.selectedEmployee.address.$error,
                          }"
                        />
                        <div
                          v-if="$v.selectedEmployee.address.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedEmployee.address.required"
                            >Address is required</span
                          >
                          <span v-if="!$v.selectedEmployee.address.maxLength"
                            >Max length of 250 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="country_id" class="required-field"
                          >Country</label
                        >
                        <select
                          id="country_id"
                          class="form-control"
                          name="country_id"
                          v-model="selectedEmployee.country_id"
                          @change="getEmployeeStatesByCountry(employeeIndex,true)"
                          :class="{
                            'is-invalid': $v.selectedEmployee.country_id.$error,
                          }"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="country in countries"
                            :value="country.id"
                            :key="country.id"
                          >
                            {{ country.country_name }}
                          </option>
                        </select>
                        <div
                          v-if="$v.selectedEmployee.country_id.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedEmployee.country_id.required"
                            >Country is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field">State</label>
                        <select
                          class="form-control"
                          name="state_id"
                          id="state_id"
                          v-model="selectedEmployee.state_id"
                          @change="getEmployeeCitiesByState(employeeIndex,true)"
                          :class="{
                            'is-invalid': $v.selectedEmployee.state_id.$error,
                          }"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="state in selectedEmployee.states"
                            :value="state.id"
                            :key="state.id"
                          >
                            {{ state.state_name }}
                          </option>
                        </select>
                        <div
                          v-if="$v.selectedEmployee.state_id.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedEmployee.state_id.required"
                            >State is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label class="required-field">City</label>
                        <select
                          id="city_id"
                          class="form-control"
                          name="city_id"
                          v-model="selectedEmployee.city_id"
                          :class="{
                            'is-invalid': $v.selectedEmployee.city_id.$error,
                          }"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="city in selectedEmployee.cities"
                            :value="city.id"
                            :key="city.id"
                          >
                            {{ city.city_name }}
                          </option>
                        </select>
                        <div
                          v-if="$v.selectedEmployee.city_id.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedEmployee.city_id.required"
                            >City is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="employeeEmail" class="required-field"
                          >Email ID</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          v-model="selectedEmployee.email"
                          id="email"
                          name="email"
                          @keyup="
                            validateEmail(
                              selectedEmployee.email,
                              'employee',
                              index
                            )
                          "
                          :class="{
                            'is-invalid':
                              $v.selectedEmployee.email.$error ||
                              selectedEmployee.errors,
                          }"
                        />
                        <div
                          class="invalid-feedback"
                          v-if="selectedEmployee.errors"
                        >
                          <span>{{ selectedEmployee.errors }}</span>
                        </div>
                        <div
                          v-if="$v.selectedEmployee.email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedEmployee.email.required"
                            >Email ID is required</span
                          >
                          <span v-if="!$v.selectedEmployee.email.maxLength"
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="employeeContact" class="required-field"
                          >Contact No</label
                        ><br />
                        <select
                          id="contact_number_code"
                          class="contact_code"
                          name="contact_number_code"
                          v-model="selectedEmployee.contact_number_code"
                          :class="{
                            'is-invalid':
                              $v.selectedEmployee.contact_number_code.$error,
                          }"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="code in countryCodes"
                            :key="code.id"
                            :value="code.id"
                          >
                            {{ code.country_code }}
                          </option>
                        </select>
                        <input
                          class="contact"
                          v-model="selectedEmployee.contact_number"
                          id="contact_number"
                          @keypress="isNum($event)"
                          :class="{
                            'is-invalid':
                              $v.selectedEmployee.contact_number.$error,
                          }"
                        />
                        <div
                          v-if="$v.selectedEmployee.contact_number_code.$error"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.selectedEmployee.contact_number_code.required
                            "
                            >Contact Number Code is required</span
                          >
                        </div>
                        <div
                          v-if="$v.selectedEmployee.contact_number.$error"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.selectedEmployee.contact_number.required"
                            >Contact Number is required</span
                          >
                          <span
                            v-if="!$v.selectedEmployee.contact_number.minLength"
                            >Min length of 10 characters required</span
                          >
                          <span
                            v-if="!$v.selectedEmployee.contact_number.maxLength"
                            >Max length of 13 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        id="employeeSaveInfo"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onEmployeeSaveInfo()"
                        :disabled="isDisabledEmployeeSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Agreement Details</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            id="agreementAddMore"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addAgreement"
                            :disabled="agreementAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <div
                    class="row"
                    id="agreementRow"
                    v-for="(agg, index) in AgreementList"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1"
                          >Agreement Start Date</label
                        >
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="agreement_start_date"
                          id="agreement_start_date"
                          placeholder="Select Date"
                          v-model="agg.agreement_start_date"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          @input="changeAggBackCapEndDate(index)"
                          :disabledDates="{
                            from: index ? null : aggBackCapStartDate,
                            to: index ? aggBackCapStartDate : null,
                          }"
                          :disabled="index < AgreementListLength"
                          :calendar-button="true"
                        ></datepicker>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1"
                          >Agreement Termination Date</label
                        >
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="agreement_end_date"
                          id="agreement_end_date"
                          placeholder="Select Date"
                          v-model="agg.agreement_end_date"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabled="index < AgreementListLength"
                          :disabledDates="{
                            to: new Date(agg.agreement_start_date),
                          }"
                          :calendar-button="true"
                        ></datepicker>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="file">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            v-on:change="
                              handleFileUploadCollateralAgreementDocuments(
                                index,
                                $event
                              )
                            "
                            :disabled="index < AgreementListLength"
                          />
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ agg.display_file_name }}</b>
                              <small>
                                <u
                                  style="color: blue"
                                  v-if="
                                    agg.agreementDocumentRemoveDisabled ===
                                    false
                                  "
                                  v-on:click="
                                    removeCollateralAgreementDocumentsDoc(index)
                                  "
                                  >Remove</u
                                >
                              </small>
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="agg.errors">
                              {{ agg.errors }}
                            </p>
                          </small>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="fees">Fees</label>
                        <input
                          class="form-control"
                          placeholder
                          v-model="agg.fees"
                          id="fees"
                          name="fees"
                          @keypress="isNum($event)"
                          :disabled="index < AgreementListLength"
                          :class="{
                            'is-invalid':
                              (agg.fees && !agg.currency_id) ||
                              (isSubmitted &&
                                $v.AgreementList.$each[index].fees.$error),
                          }"
                        />
                        <div
                          v-if="agg.fees && !agg.currency_id"
                          class="invalid-feedback"
                        >
                          <span>Currency is required </span>
                        </div>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.AgreementList.$each[index].fees.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.AgreementList.$each[index].fees.maxLength"
                            >Max length of 15 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label>Currency</label>
                        <select
                          id="currency_id"
                          class="form-control"
                          name="currency_id"
                          v-model="agg.currency_id"
                          :disabled="index < AgreementListLength"
                          :class="{
                            'is-invalid': !agg.fees && agg.currency_id,
                          }"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="curr in currencies"
                            :value="curr.id"
                            :key="curr.id"
                          >
                            {{ curr.currency_short_name }}
                          </option>
                        </select>
                        <div
                          v-if="!agg.fees && agg.currency_id"
                          class="invalid-feedback"
                        >
                          <span>Fees is required </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        id="agreementSaveInfo"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onAgreementSaveInfo()"
                        :disabled="isDisabledAgreementSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addCollateralDoc()"
                            :disabled="CollateralDocumentsAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(doc, index) in CollateralDocuments"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label>Select Document</label>
                        <select
                          id="CollateralDocName"
                          class="form-control"
                          name="CollateralDocName"
                          v-model="doc.CollateralDocName"
                          @change="addEditedFlag(index, null)"
                          :disabled="index < CollateralDocumentsLength"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="doc in collateralDocuments"
                            :key="doc.id"
                          >
                            {{ doc.document_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @mouseenter="
                          mouseenter(doc.CollateralDocName, index, null)
                        "
                        @mouseleave="mouseleave(index, null)"
                      >
                        <label for="exampleInputEmail1">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            :disabled="doc.CollateralDocName === null"
                            v-on:change="
                              handleFileUploadCollateralDocuments(
                                index,
                                $event,
                                doc.CollateralDocName
                              ) & addEditedFlag(index, null)
                            "
                          />
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ doc.display_file_name }}</b>
                              <!-- <small>
                                <u
                                  style="color: blue; cursor: pointer"
                                  v-if="
                                    doc.CollateralDocumentRemoveDisabled ===
                                    false
                                  "
                                  v-on:click="
                                    removeCollateralDocumentsDoc(index)
                                  "
                                  >Remove</u
                                >
                              </small> -->
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.errors">
                              {{ doc.errors }}
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.docName">
                              {{ doc.docName }}
                            </p>
                          </small>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @click="
                          docValidation(
                            doc.display_file_name,
                            'issueDate',
                            null
                          )
                        "
                      >
                        <label for="exampleInputEmail1">Issue Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="CollateralDocumentsDate"
                          id="CollateralDocumentsDate"
                          placeholder="Select Date"
                          v-model="doc.CollateralDocumentsDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabledDates="{ from: new Date() }"
                          :disabled="doc.display_file_name === null"
                          :calendar-button="true"
                          @input="addEditedFlag(index, null)"
                          :class="{
                            'is-invalid':
                              docIssueDateValidation &&
                              doc.display_file_name === null,
                          }"
                        ></datepicker>
                        <div
                          v-if="doc.display_file_name === null"
                          class="invalid-feedback"
                        >
                          <span v-if="docIssueDateValidation"
                            >Please upload the Document</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @click="
                          docValidation(
                            doc.display_file_name,
                            'expiryDate',
                            null
                          )
                        "
                      >
                        <label for="exampleInputEmail1">Expiry Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="ExpiryDate"
                          id="ExpiryDate"
                          placeholder="Select Date"
                          v-model="doc.ExpiryDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabledDates="{
                            to: new Date(doc.CollateralDocumentsDate),
                          }"
                          :disabled="doc.display_file_name === null"
                          :calendar-button="true"
                          @input="addEditedFlag(index, null)"
                          :class="{
                            'is-invalid':
                              docExpiryDateValidation &&
                              doc.display_file_name === null,
                          }"
                        ></datepicker>
                        <div
                          v-if="doc.display_file_name === null"
                          class="invalid-feedback"
                        >
                          <span v-if="docExpiryDateValidation"
                            >Please upload the Document</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="comments">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="doc.comments"
                          id="comments"
                          @input="addEditedFlag(index, null)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.CollateralDocuments.$each[index].comments
                                .$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.CollateralDocuments.$each[index].comments.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.CollateralDocuments.$each[index].comments
                                .maxLength
                            "
                            >Max length of 100 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onCollateralDocumentSaveInfo()"
                        :disabled="isDisabledCollateralDocumentSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div
          class="container-fluid ot_tb pt-3"
          v-if="loggedInUserGroupId === 'Admin'"
        >
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Administrative Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addCollateralAdminDoc()"
                            :disabled="CollateralAdminDocumentsAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(doc, index) in CollateralAdminDocuments"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label>Select Document</label>
                        <select
                          id="CollateralAdminDocName"
                          class="form-control"
                          name="CollateralAdminDocName"
                          v-model="doc.CollateralAdminDocName"
                          @change="addEditedFlag(index, 'admin')"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="doc in collateralAdminDocuments"
                            :key="doc.id"
                          >
                            {{ doc.document_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @mouseenter="
                          mouseenter(doc.CollateralAdminDocName, index, 'admin')
                        "
                        @mouseleave="mouseleave(index, 'admin')"
                      >
                        <label for="file">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            :disabled="doc.CollateralAdminDocName === null"
                            v-on:change="
                              handleFileUploadCollateralAdminDocuments(
                                index,
                                $event,
                                doc.CollateralAdminDocName
                              ) & addEditedFlag(index, 'admin')
                            "
                          />
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ doc.display_file_name }}</b>
                              <!-- <small>
                                <u
                                  style="color: blue; cursor: pointer"
                                  v-if="
                                    doc.CollateralAdminDocumentRemoveDisabled ===
                                    false
                                  "
                                  v-on:click="
                                    removeCollateralAdminDocumentsDoc(index)
                                  "
                                  >Remove</u
                                >
                              </small> -->
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.errors">
                              {{ doc.errors }}
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.docName">
                              {{ doc.docName }}
                            </p>
                          </small>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @click="
                          docValidation(
                            doc.display_file_name,
                            'issueDate',
                            'admin'
                          )
                        "
                      >
                        <label for="IssueDate">Issue Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="IssueDate"
                          id="IssueDate"
                          placeholder="Select Date"
                          v-model="doc.IssueDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabledDates="{ from: new Date() }"
                          :disabled="doc.display_file_name === null"
                          :calendar-button="true"
                          @input="addEditedFlag(index, 'admin')"
                          :class="{
                            'is-invalid':
                              adminDocIssueDateValidation &&
                              doc.display_file_name === null,
                          }"
                        ></datepicker>
                        <div
                          v-if="doc.display_file_name === null"
                          class="invalid-feedback"
                        >
                          <span v-if="adminDocIssueDateValidation"
                            >Please upload the Document</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @click="
                          docValidation(
                            doc.display_file_name,
                            'expiryDate',
                            'admin'
                          )
                        "
                      >
                        <label for="exampleInputEmail1">Expiry Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="ExpiryDate"
                          id="ExpiryDate"
                          placeholder="Select Date"
                          v-model="doc.ExpiryDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabledDates="{
                            to: new Date(doc.IssueDate),
                          }"
                          :disabled="doc.display_file_name === null"
                          :calendar-button="true"
                          @input="addEditedFlag(index, 'admin')"
                          :class="{
                            'is-invalid':
                              adminDocExpiryDateValidation &&
                              doc.display_file_name === null,
                          }"
                        ></datepicker>
                        <div
                          v-if="doc.display_file_name === null"
                          class="invalid-feedback"
                        >
                          <span v-if="adminDocExpiryDateValidation"
                            >Please upload the Document</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="comments">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="doc.comments"
                          id="comments"
                          @input="addEditedFlag(index, 'admin')"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.CollateralAdminDocuments.$each[index].comments
                                .$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.CollateralAdminDocuments.$each[index].comments
                              .$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.CollateralAdminDocuments.$each[index].comments
                                .maxLength
                            "
                            >Max length of 100 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onCollateralAdminDocumentSaveInfo()"
                        :disabled="isDisabledCollateralAdminDocumentSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    id="submit"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a @click="$router.go(-1)" style="cursor: pointer">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength, minLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: null,
      cities: [],
      states: [],
      countries: [],
      countryCodes: [],
      collateralDocuments: [],
      collateralAdminDocuments: [],
      //variables for collateral information
      companyName: null,
      address: null,
      selectedCountry: null,
      collateralContactCode: null,
      collateralContact: null,
      secondaryCollateralContactCode: null,
      secondaryCollateralContact: null,
      website: null,
      email: null,
      secondaryEmail: null,
      selectedState: null,
      selectedCity: null,
      regDate: new Date(), //default current date
      //variables for agreement details
      AgreementListLength: null,
      AgreementDocumentFile: null,
      AgreementList: [],
      currencies: [],
      currency_id: null,
      agreementSaveInfoDisabled: true, //save info button is disabled
      agreementAddMoreDisabled: true,
      //variables for employee details
      employeeIndex: 0,
      EmployeeList: [],
      selectedEmployee: {},
      employeeButtonDisabled: true,
      employeeDisabled: true,
      employeeSaveInfoDisabled: true, //save info button is disabled
      employeeAddMoreDisabled: true,
      //list for Collateral Documents
      CollateralDocuments: [],
      CollateralDocumentsLength: null,
      CollateralDocumentFile: null,
      CollateralDocumentsDisabled: true,
      CollateralDocumentSaveInfoDisabled: true, //save info button is disabled
      CollateralDocumentsAddMoreDisabled: true,
      //list for Collateral Admin Documents
      CollateralAdminDocuments: [],
      CollateralAdminDocumentsLength: null,
      CollateralAdminDocumentFile: null,
      CollateralAdminDelete: [],
      CollateralAdminDocumentsDisabled: true,
      CollateralAdminDocumentSaveInfoDisabled: true, //save info button is disabled
      CollateralAdminDocumentsAddMoreDisabled: true,
      msg: [],
      error: "",
      isSubmitted: false,
      onSubmitDisabled: false,
      aggBackCapStartDate: null,
      aggBackCapEndDate: null,
      docIssueDateValidation: false,
      docExpiryDateValidation: false,
      adminDocIssueDateValidation: false,
      adminDocExpiryDateValidation: false,
    };
  },
  validations: {
    companyName: {
      required,
      maxLength: maxLength(100),
    },
    address: {
      required,
      maxLength: maxLength(250),
    },
    selectedCountry: {
      required,
    },
    selectedState: {
      required,
    },
    selectedCity: {
      required,
    },
    collateralContact: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(13),
    },
    collateralContactCode: {
      required,
    },
    secondaryCollateralContact: {
      minLength: minLength(10),
      maxLength: maxLength(13),
      required: function () {
        if (
          this.secondaryCollateralContactCode &&
          !this.secondaryCollateralContact
        )
          return false;
        else return true;
      },
    },
    secondaryCollateralContactCode: {
      required: function () {
        if (
          !this.secondaryCollateralContactCode &&
          this.secondaryCollateralContact
        )
          return false;
        else return true;
      },
    },
    email: {
      required,
      maxLength: maxLength(50),
    },
    secondaryEmail: {
      maxLength: maxLength(50),
    },
    website: {
      required,
      maxLength: maxLength(100),
    },
    regDate: {
      required,
    },
    AgreementList: {
      $each: {
        fees: {
          maxLength: maxLength(15),
        },
      },
    },
    selectedEmployee: {
      id: {
        required,
      },
      address: {
        required,
        maxLength: maxLength(250),
      },
      country_id: {
        required,
      },
      state_id: {
        required,
      },
      city_id: {
        required,
      },
      contact_number_code: {
        required,
      },
      contact_number: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(13),
      },
      email: {
        required,
        maxLength: maxLength(50),
      },
    },
    CollateralDocuments: {
      $each: {
        comments: {
          maxLength: maxLength(100),
        },
      },
    },
    CollateralAdminDocuments: {
      $each: {
        comments: {
          maxLength: maxLength(100),
        },
      },
    },
  },
  watch: {
    email(value) {
      // binding this to the data value in the email input
      this.email = value;
      this.validateEmail(value);
    },
    secondaryEmail(value) {
      // binding this to the data value in the email input
      this.secondaryEmail = value;
      this.validateEmail(value, "secondary");
    },
    website(value) {
      // binding this to the data value in the website input
      this.website = value;
      this.validateWebsite(value);
    },
  },
  computed: {
    loggedInUserFullName() {
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },

    //this is to computed disabled property of Agreement Save Info button.
    isDisabledAgreementSaveInfo: function () {
      let checkEmptyagreement_start_date = this.AgreementList.filter(
        (line) => line.agreement_start_date === null
      );
      let checkEmptyfees = this.AgreementList.filter(
        (line) => line.fees === null
      );
      //if the line is empty then disable save info button
      if (
        this.AgreementList.length === 0 &&
        this.agreementSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enable
      if (
        (checkEmptyagreement_start_date.length >= 1 &&
          this.AgreementList.length > 0) ||
        (checkEmptyfees.length >= 1 && this.AgreementList.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledAgreementSaveInfo() close

    //this is to computed disabled property of Employee Save Info button.
    isDisabledEmployeeSaveInfo: function () {
      let checkEmptyEmployeeName = this.EmployeeList.filter(
        (line) => line.employeeName === null
      );
      let checkEmptyEmployeeAddress = this.EmployeeList.filter(
        (line) => line.employeeAddress === null
      );
      let checkEmptyEmployeeCountry = this.EmployeeList.filter(
        (line) => line.country_id === null
      );
      let checkEmptyEmployeeCity = this.EmployeeList.filter(
        (line) => line.city_id === null
      );
      let checkEmptyEmployeeEmail = this.EmployeeList.filter(
        (line) => line.employeeEmail === null
      );
      let checkEmptyEmployeeContact = this.EmployeeList.filter(
        (line) => line.employeeContact === null
      );
      //if the line is empty then disable save info button
      if (
        this.EmployeeList.length === 0 &&
        this.employeeSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enable
      if (
        (checkEmptyEmployeeName.length >= 1 && this.EmployeeList.length > 0) ||
        (checkEmptyEmployeeAddress.length >= 1 &&
          this.EmployeeList.length > 0) ||
        (checkEmptyEmployeeCountry.length >= 1 &&
          this.EmployeeList.length > 0) ||
        (checkEmptyEmployeeCity.length >= 1 && this.EmployeeList.length > 0) ||
        (checkEmptyEmployeeEmail.length >= 1 && this.EmployeeList.length > 0) ||
        (checkEmptyEmployeeContact.length >= 1 && this.EmployeeList.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledEmployeeSaveInfo() close

    //this is to computed disabled property of CollateralDocuments Save Info button.
    isDisabledCollateralDocumentSaveInfo: function () {
      let checkEmptyCollateralDocumentsDocName =
        this.CollateralDocuments.filter(
          (line) => line.CollateralDocName === null
        );
      //if the line is empty then disable save info button
      if (
        this.CollateralDocuments.length === 0 &&
        this.CollateralDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyCollateralDocumentsDocName.length >= 1 &&
        this.CollateralDocuments.length > 0
      ) {
        this.CollateralDocumentsDisabled = true;
      } else {
        this.CollateralDocumentsDisabled = false;
      }
      //CollateralDocumentsDisabled variable is to check whther all input fields except file upload are filled. CollateralDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.CollateralDocumentsDisabled === true ||
        this.CollateralDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledCollateralDocumentSaveInfo() close

    //this is to computed disabled property of CollateralAdminDocuments Save Info button.
    isDisabledCollateralAdminDocumentSaveInfo: function () {
      let checkEmptyCollateralAdminDocumentsDocName =
        this.CollateralAdminDocuments.filter(
          (line) => line.CollateralAdminDocName === null
        );
      //if the line is empty then disable save info button
      if (
        this.CollateralAdminDocuments.length === 0 &&
        this.CollateralAdminDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyCollateralAdminDocumentsDocName.length >= 1 &&
        this.CollateralAdminDocuments.length > 0
      ) {
        this.CollateralAdminDocumentsDisabled = true;
      } else {
        this.CollateralAdminDocumentsDisabled = false;
      }
      //CollateralAdminDocumentsDisabled variable is to check whther all input fields except file upload are filled. CollateralAdminDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.CollateralAdminDocumentsDisabled === true ||
        this.CollateralAdminDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledCollateralDocumentSaveInfo() close
  },
  components: {
    Datepicker,
  },
  methods: {
    addEditedFlag(index, type) {
      if (type === "admin") {
        if (index < this.CollateralAdminDocumentsLength) {
          this.CollateralAdminDocuments[index]["edited"] = true;
        }
      } else {
        if (index < this.CollateralDocumentsLength) {
          this.CollateralDocuments[index]["edited"] = true;
        }
      }
    },
    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },
    validateEmail(value, type, i) {
      if (type === "secondary") {
        if (/^\w+([\.-]\w+)*@\D\w+\.(\D{2,3})$/.test(value) || value === "") {
          this.msg["secondaryEmail"] = "";
        } else {
          this.msg["secondaryEmail"] = "Invalid Email Address";
        }
      } else if (type === "employee") {
        if (/^\w+([\.-]\w+)*@\D\w+\.(\D{2,3})$/.test(value)) {
          this.EmployeeList[i].errors["email"] = "";
        } else {
          this.EmployeeList[i].errors["email"] = "Invalid Email Address";
        }
      } else {
        if (/^\w+([\.-]\w+)*@\D\w+\.(\D{2,3})$/.test(value)) {
          this.msg["email"] = "";
        } else {
          this.msg["email"] = "Invalid Email Address";
        }
      }
    },
    validateWebsite(value) {
      if (/^(www\.)([A-Za-z0-9-])+(\.\D{2,3})$/.test(value) || value === "") {
        this.msg["website"] = "";
      } else {
        this.msg["website"] = "Invalid Website";
      }
    },
    addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    //this method will make disabled property as false for Director Save Info
    onAgreementSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.agreementSaveInfoDisabled = false;
      this.agreementAddMoreDisabled = false;
    },
    //this method will make disabled property as false for Collateral Employee Save Info
    onEmployeeSaveInfo() {
      this.$v.$touch();
      if (this.$v.$invalid || !Object.values(this.msg).every((e) => e === "")) {
        return;
      }
      Utils.make_alert("success", "Record saved.");
      this.employeeSaveInfoDisabled = false;
    },
    //this method will make disabled property as false for Collateral Document Save Info
    onCollateralDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.CollateralDocumentSaveInfoDisabled = false;
      this.CollateralDocumentsAddMoreDisabled = false;
    },
    //this method will make disabled property as false for Collateral Admin Document Save Info
    onCollateralAdminDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.CollateralAdminDocumentSaveInfoDisabled = false;
      this.CollateralAdminDocumentsAddMoreDisabled = false;
    },
    //this function deletes CollateralDocument record
    deleteCollateralDocuments(index) {
      this.CollateralDocumentFile = "file deleted";
      this.CollateralDocuments.splice(index, 1);
      if (
        typeof this.CollateralDocuments !== "undefined" &&
        this.CollateralDocuments.length == 0
      ) {
        this.CollateralDocumentsAddMoreDisabled = false;
        this.CollateralDocumentSaveInfoDisabled = true;
      }
    },

    //this function deletes CollateralAdminDocument record
    deleteCollateralAdminDocuments(index) {
      this.CollateralAdminDocumentFile = "file deleted";
      this.CollateralAdminDocuments.splice(index, 1);
      if (
        typeof this.CollateralAdminDocuments !== "undefined" &&
        this.CollateralAdminDocuments.length == 0
      ) {
        this.CollateralAdminDocumentsAddMoreDisabled = false;
        this.CollateralAdminDocumentSaveInfoDisabled = true;
      }
    },

    //this function deletes Agreement record
    deleteAgreement(index) {
      this.AgreementList.splice(index, 1);
      if (
        typeof this.AgreementList !== "undefined" &&
        this.AgreementList.length == 0
      ) {
        this.agreementAddMoreDisabled = false;
        this.agreementSaveInfoDisabled = true;
      }
    },

    getCollateralManager() {
      //this function is called on page load and created events. it will show details on CollateralManager with CollateralManagerid equal to this.id
      const path = "single-collateral-summary-overview-active/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            let collateralManagerDetails =
              res.data.collateral.collateral_manager_details_active[0];
            this.companyName = collateralManagerDetails.company_name;
            this.address = collateralManagerDetails.address;
            this.selectedCountry = collateralManagerDetails.country_id;
            this.selectedState = collateralManagerDetails.state_id;
            this.selectedCity = collateralManagerDetails.city_id;
            this.getStatesByCountry(false)
            this.getCitiesByState(false)
            this.collateralContact = collateralManagerDetails.contact_number;
            this.collateralContactCode =
              collateralManagerDetails.contact_number_code;
            this.secondaryCollateralContact =
              collateralManagerDetails.secondary_contact_number;
            this.secondaryCollateralContactCode =
              collateralManagerDetails.secondary_contact_number_code;
            this.website = collateralManagerDetails.website;
            this.email = collateralManagerDetails.email;
            this.secondaryEmail = collateralManagerDetails.secondary_email;
            this.regDate = collateralManagerDetails.registration_date;
            this.EmployeeList =
              res.data.collateral.collateral_employee_details_active;
            if (this.EmployeeList.length) {
              let updatedEmployeeList = this.EmployeeList.map(employee => {
                  return {
                    ...employee, // Spread operator to copy all existing properties
                    states: [],   // Add states array
                    cities: []    // Add cities array
                  };
                });
              this.EmployeeList = updatedEmployeeList;
              this.employeeIndex = 0;
              this.EmployeeList.map((e) => (e["emp_id"] = e.id));
              this.selectedEmployee = this.EmployeeList[0];
              this.getEmployeeStatesByCountry(this.employeeIndex,false)
              this.getEmployeeCitiesByState(this.employeeIndex,false)
            }
            if (
              res.data.collateral.collateral_agreement_details_active.length
            ) {
              this.AgreementList =
                res.data.collateral.collateral_agreement_details_active;
              this.AgreementList = this.AgreementList.slice(0, 3);
              this.AgreementListLength = this.AgreementList.length;
              this.AgreementList.filter((agg) => {
                agg.fees = agg.fees.replaceAll(",", "");
                agg.fees = agg.fees.slice(0, -3);
              });
              this.aggBackCapStartDate = new Date(
                this.AgreementList[
                  this.AgreementList.length - 1
                ].agreement_start_date
              );
              this.aggBackCapEndDate = new Date(
                moment(
                  this.AgreementList[this.AgreementList.length - 1]
                    .agreement_start_date
                )
                  .clone()
                  .add(1, "days")
              );
            } else {
              this.agreementAddMoreDisabled = false;
            }
            if (
              res.data.collateral.collateral_documents_details_active.length
            ) {
              this.CollateralDocumentsLength =
                res.data.collateral.collateral_documents_details_active.length;
              this.CollateralDocuments =
                res.data.collateral.collateral_documents_details_active;
              this.CollateralDocumentFile = "file";
              this.CollateralDocuments.map((doc) => (doc.edited = false));
            } else {
              this.CollateralDocumentsAddMoreDisabled = false;
            }
            if (
              res.data.collateral.collateral_admin_documents_details_active
                .length
            ) {
              this.CollateralAdminDocumentsLength =
                res.data.collateral.collateral_admin_documents_details_active.length;
              this.CollateralAdminDocuments =
                res.data.collateral.collateral_admin_documents_details_active;
              this.CollateralAdminDocumentFile = "file";
              this.CollateralAdminDocuments.map((doc) => (doc.edited = false));
            } else {
              this.CollateralAdminDocumentsAddMoreDisabled = false;
            }
            console.log("get CollateralManager details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/CollateralManager");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getCollateralManager close

    // validation for documents
    mouseenter(docName, index, type) {
      if (docName === null) {
        if (type === "admin")
          this.CollateralAdminDocuments[index].docName =
            "Document name is not selected";
        else
          this.CollateralDocuments[index].docName =
            "Document name is not selected";
      }
    },
    mouseleave(index, type) {
      if (type === "admin") this.CollateralAdminDocuments[index].docName = "";
      else this.CollateralDocuments[index].docName = "";
    },

    // issue & expiry date doc validation
    docValidation(doc, dateType, docType) {
      if (docType === "admin") {
        if (dateType === "issueDate") {
          this.adminDocIssueDateValidation = doc === null;
        } else if (dateType === "expiryDate") {
          this.adminDocExpiryDateValidation = doc === null;
        }
      } else {
        if (dateType === "issueDate") {
          this.docIssueDateValidation = doc === null;
        } else if (dateType === "expiryDate") {
          this.docExpiryDateValidation = doc === null;
        }
      }
    },

    onEmployeeNameChange(prev_id,id) {
      // getting index of prev employee
      console.log("onEmployeeNameChange prev_id id",prev_id ,id)
      console.log("======EmployeeList",this.EmployeeList)
      let prevIndex = this.EmployeeList.findIndex((e) => e.emp_id === prev_id);
      let index = this.EmployeeList.findIndex((e) => e.emp_id === id);
      this.employeeIndex = index;
      console.log("employeeIndex prev_id",this.employeeIndex)
      // getting id of changed employee from prev employee before changing it
      let updatedId = this.EmployeeList[prevIndex].id;
      console.log("updatedId",updatedId)
      // changing id of prev employee back to what it was earlier
      this.EmployeeList[prevIndex].id = prev_id;
      // assigning new/updated employee to selectedEmployee
      this.selectedEmployee = this.EmployeeList.filter(
        (e) => e.id === updatedId
      )[0];
      this.getEmployeeStatesByCountry(this.employeeIndex,false)
      this.getEmployeeCitiesByState(this.employeeIndex,false)
    },

    //this method will submit the collateral form in backend.
    onSubmit() {
      // assigning a dummy object to selectedEmployee to bypass employee validations if Employee List is empty
      if (this.EmployeeList.length === 0) {
        this.selectedEmployee = {
          id: 0,
          address: "-",
          country_id: "-",
          state_id: "-",
          city_id: "-",
          contact_number: "0000000000",
          contact_number_code: 0,
          email: "-",
        };
      }
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid || !Object.values(this.msg).every((e) => e === "")) {
        return;
      }
      if (this.regDate != null) {
        this.regDate = moment(this.regDate).format("MM-DD-YYYY");
      }

      for (let i = 0; i < this.AgreementList.length; i++) {
        if (!this.AgreementList[i].fees && !this.AgreementList[i].currency_id) {
          !this.AgreementList.splice(i, 1);
        } else if (
          !this.AgreementList[i].fees ||
          !this.AgreementList[i].currency_id
        ) {
          return;
        } else {
          this.AgreementList[i].currency_id =
            this.AgreementList[i].currency_id.toString();
          if (this.AgreementList[i].agreement_start_date !== null) {
            this.AgreementList[i].agreement_start_date = moment(
              this.AgreementList[i].agreement_start_date
            ).format("MM-DD-YYYY");
          }
          if (this.AgreementList[i].agreement_end_date !== null) {
            this.AgreementList[i].agreement_end_date = moment(
              this.AgreementList[i].agreement_end_date
            ).format("MM-DD-YYYY");
          }
        }
      }

      this.CollateralDocuments.map((doc, index) => {
        if (doc.CollateralDocName === null) {
          this.CollateralDocuments.splice(index, 1);
        } else {
          if (doc.CollateralDocumentsDate !== null) {
            doc.CollateralDocumentsDate = moment(
              doc.CollateralDocumentsDate
            ).format("MM-DD-YYYY");
          }
          if (doc.ExpiryDate !== null) {
            doc.ExpiryDate = moment(doc.ExpiryDate).format("MM-DD-YYYY");
          }
        }
      });

      this.CollateralAdminDocuments.map((doc, index) => {
        if (doc.CollateralAdminDocName === null) {
          this.CollateralAdminDocuments.splice(index, 1);
        } else {
          if (doc.IssueDate !== null) {
            doc.IssueDate = moment(doc.IssueDate).format("MM-DD-YYYY");
          }
          if (doc.ExpiryDate !== null) {
            doc.ExpiryDate = moment(doc.ExpiryDate).format("MM-DD-YYYY");
          }
        }
      });

      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("CompanyName", this.companyName);
      formData.append("Address", this.address);
      formData.append("selectedCountry", this.selectedCountry);
      formData.append("selectedState", this.selectedState);
      formData.append("selectedCity", this.selectedCity);
      formData.append("ContactCode", this.collateralContactCode);
      formData.append("Contact", this.collateralContact);
      formData.append(
        "SecondaryContactCode",
        this.secondaryCollateralContactCode
      );
      formData.append("SecondaryContact", this.secondaryCollateralContact);
      formData.append("EmailID", this.email);
      formData.append("SecondaryEmailID", this.secondaryEmail);
      formData.append("Website", this.website);
      formData.append("RegistrationDate", this.regDate);
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", JSON.stringify(this.$store.getters.getuserId));
      formData.append("moduleId", 6);
      formData.append("tabId", 25);
      formData.append("AgreementDetails", JSON.stringify(this.AgreementList));
      formData.append("EmployeeDetails", JSON.stringify(this.EmployeeList));
      formData.append(
        "CollateralDocuments",
        JSON.stringify(this.CollateralDocuments)
      );
      formData.append(
        "CollateralAdminDocuments",
        JSON.stringify(this.CollateralAdminDocuments)
      );
      const path = "edit_collateral/" + this.id;
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          console.log("status: " + response.data.status);
          if (response.data.status == "success") {
            Utils.make_alert(
              "success",
              "Collateral Manager updated successfully!"
            );
            console.log("SUCCESS!!");
            this.$router.push("/collateral");
          } else {
            Utils.make_alert("warning", "Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          Utils.make_alert(
            "warning",
            error.response.status === 400
              ? error.response.data.status
              : "Something went wrong!"
          );
          console.error(error);
        });
    }, //onSubmit() close

    addAgreement() {
      // If new agreement is added, then assign next agreement's start date as previous agreement's end date
      if (this.AgreementList.length) {
        (this.agreementSaveInfoDisabled = true),
          (this.agreementAddMoreDisabled = true),
          this.AgreementList.push({
            id: null,
            agreement_start_date:
              this.AgreementList[this.AgreementList.length - 1]
                .agreement_end_date,
            agreement_end_date: new Date(
              moment(
                this.AgreementList[this.AgreementList.length - 1]
                  .agreement_end_date
              )
                .clone()
                .add(1, "days")
            ),
            fees: null,
            currency_id: null,
            display_file_name: null,
            errors: "",
            agreementDocumentRemoveDisabled: true,
          });
        this.aggBackCapStartDate = new Date(
          this.AgreementList[this.AgreementList.length - 2].agreement_end_date
        );
        this.aggBackCapEndDate = new Date(
          moment(
            this.AgreementList[this.AgreementList.length - 1]
              .agreement_start_date
          )
            .clone()
            .add(1, "days")
        );
      } else {
        // set current date as start date & tommorow's date as end date for first agreement
        (this.agreementSaveInfoDisabled = true),
          (this.agreementAddMoreDisabled = true),
          this.AgreementList.push({
            id: null,
            agreement_start_date: new Date(),
            agreement_end_date: new Date(moment().clone().add(1, "days")),
            fees: null,
            currency_id: null,
            display_file_name: null,
            errors: "",
            agreementDocumentRemoveDisabled: true,
          });
        this.aggBackCapStartDate = new Date();
        this.aggBackCapEndDate = new Date();
      }
    },

    changeAggBackCapEndDate(index){
      // Ensure that moment.js is imported and available
      if (this.AgreementList && this.AgreementList[index]) {
        // Parse the start date as a Moment.js object
        let startDate = moment(this.AgreementList[index].agreement_start_date,this.$store.state.moment_date_format);
        console.log("startDate",startDate)
        // Add one day to the start date
        let endDate = startDate.add(1, 'days').toDate(); // Convert to JavaScript Date object
        // Update the end date with the new value
        console.log("endDate",endDate)
        this.aggBackCapEndDate = endDate
        this.AgreementList[index].agreement_end_date = endDate;
      }
    },

    //this method will add new line for additional Collateral Employees
    addCollateralEmployees() {
      //when new line is added then CollateralDocumentFile variable and CollateralDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.

      (this.employeeSaveInfoDisabled = true),
        (this.employeeAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.EmployeeList.push({
          id: null,
          employee_id: null,
          collateral_manager_id: null,
          employee_name: null,
          address: null,
          country_id: null,
          state_id: null,
          city_id: null,
          contact_number_code: null,
          contact_number: null,
          email: null,
          cities: [],
          states: [],
          errors: "",
          employeeButtonDisabled: true,
        });
    }, //addCollateralDoc() close

    //this method will add new line for additional Collateral Documents
    addCollateralDoc() {
      //when new line is added then CollateralDocumentFile variable and CollateralDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.CollateralDocumentFile = null),
        (this.CollateralDocumentSaveInfoDisabled = true),
        (this.CollateralDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.CollateralDocuments.push({
          id: null,
          collateral_document_id: null,
          CollateralDocName: null,
          CollateralDocumentsDate: null,
          ExpiryDate: null,
          comments: null,
          display_file_name: null,
          errors: "",
          docName: "",
          edited: false,
          CollateralDocumentRemoveDisabled: true,
        });
    }, //addCollateralDoc() close

    //this method will add new line for additional Collateral Admin Documents
    addCollateralAdminDoc() {
      //when new line is added then CollateralDocumentFile variable and CollateralDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.CollateralAdminDocumentFile = null),
        (this.CollateralAdminDocumentSaveInfoDisabled = true),
        (this.CollateralAdminDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.CollateralAdminDocuments.push({
          id: null,
          collateral_admin_document_id: null,
          CollateralAdminDocName: null,
          IssueDate: null,
          ExpiryDate: null,
          comments: null,
          display_file_name: null,
          errors: "",
          docName: "",
          edited: false,
          CollateralAdminDocumentRemoveDisabled: true,
        });
    }, //addCollateralAdminDoc() close

    //handles a change on the file upload
    handleFileUploadCollateralDocuments(id, e, doc) {
      // check if file already exists for same document name, show pop if true and delete record
      if (
        this.CollateralDocuments.filter(
          (col) =>
            col.display_file_name === e.target.files[0].name &&
            col.CollateralDocName === doc
        ).length
      ) {
        this.deleteCollateralDocuments(this.CollateralDocuments.length - 1);
        Utils.make_alert("warning", "File Already Exists");
        return;
      }
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.CollateralDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.CollateralDocumentFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors = "File size should be less than 100MB";
          self.CollateralDocuments[id].errors = JSON.stringify(
            response.errors
          ).replace(/\"/g, "");
          self.CollateralDocuments[id].selectedFile = null;
          self.CollateralDocuments[id].selectedFileName = null;
          self.CollateralDocuments[id].display_file_name = null;
          self.CollateralDocuments[id].CollateralDocumentRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.CollateralDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.CollateralDocuments[id].selectedFile = reader.result;
          self.CollateralDocuments[id].selectedFileName =
            e.target.files[0].name;
          self.CollateralDocuments[id].display_file_name =
            e.target.files[0].name; //to show filename in file input
          self.CollateralDocuments[id].errors = "";
          self.CollateralDocuments[id].CollateralDocumentRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadCollateralDocuments() close

    //handles a change on the file upload for admin doc
    handleFileUploadCollateralAdminDocuments(id, e, doc) {
      // check if file already exists for same document name, show pop if true and delete record
      if (
        this.CollateralAdminDocuments.filter(
          (col) =>
            col.display_file_name === e.target.files[0].name &&
            col.CollateralAdminDocName === doc
        ).length
      ) {
        this.deleteCollateralAdminDocuments(
          this.CollateralAdminDocuments.length - 1
        );
        Utils.make_alert("warning", "File Already Exists");
        return;
      }
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.CollateralAdminDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.CollateralAdminDocumentFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors = "File size should be less than 100MB";
          self.CollateralAdminDocuments[id].errors = JSON.stringify(
            response.errors
          ).replace(/\"/g, "");
          self.CollateralAdminDocuments[id].selectedFile = null;
          self.CollateralAdminDocuments[id].selectedFileName = null;
          self.CollateralAdminDocuments[id].display_file_name = null;
          self.CollateralAdminDocuments[
            id
          ].CollateralAdminDocumentRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.CollateralAdminDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.CollateralAdminDocuments[id].selectedFile = reader.result;
          self.CollateralAdminDocuments[id].selectedFileName =
            e.target.files[0].name;
          self.CollateralAdminDocuments[id].display_file_name =
            e.target.files[0].name; //to show filename in file input
          self.CollateralAdminDocuments[id].errors = "";
          self.CollateralAdminDocuments[
            id
          ].CollateralAdminDocumentRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadCollateralDocuments() close

    //handles a change on the file upload for agreement doc
    handleFileUploadCollateralAgreementDocuments(id, e) {
      let file_list = this.AgreementList.map((file) => file.display_file_name);
      // check if file already exists for same document name, show pop if true and delete record
      if (file_list.includes(e.target.files[0].name)) {
        this.deleteAgreement(this.AgreementList.length - 1);
        Utils.make_alert("warning", "File Already Exists");
        return;
      }
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.AgreementDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.AgreementDocumentFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors.filesize = "File size should be less than 100MB";
          self.AgreementList[id].errors.filesize = JSON.stringify(
            response.errors.filesize
          ).replace(/\"/g, "");
          self.AgreementList[id].selectedFile = null;
          self.AgreementList[id].selectedFileName = null;
          self.AgreementList[id].display_file_name = null;
          self.AgreementList[id].agreementDocumentRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.AgreementDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.AgreementList[id].selectedFile = reader.result;
          self.AgreementList[id].selectedFileName = e.target.files[0].name;
          self.AgreementList[id].display_file_name = e.target.files[0].name; //to show filename in file input
          self.AgreementList[id].errors.filesize = "";
          self.AgreementList[id].agreementDocumentRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadCollateralAgreementDocuments() close

    // //this method handles remove doc attachment
    // removeCollateralDocumentsDoc(id) {
    //   this.CollateralDocumentFile = null;
    //   this.CollateralDocuments[id].selectedFile = null;
    //   this.CollateralDocuments[id].selectedFileName = null;
    //   this.CollateralDocuments[id].display_file_name = null;
    //   this.CollateralDocuments[id].CollateralDocumentRemoveDisabled = true;
    // },

    // //this method handles remove admin doc attachment
    // removeCollateralAdminDocumentsDoc(id) {
    //   this.CollateralAdminDocumentFile = null;
    //   this.CollateralAdminDocuments[id].selectedFile = null;
    //   this.CollateralAdminDocuments[id].selectedFileName = null;
    //   this.CollateralAdminDocuments[id].display_file_name = null;
    //   this.CollateralAdminDocuments[
    //     id
    //   ].CollateralAdminDocumentRemoveDisabled = true;
    // },

    //this method handles remove agreement doc attachment
    removeCollateralAgreementDocumentsDoc(id) {
      this.AgreementDocumentFile = null;
      this.AgreementList[id].selectedFile = null;
      this.AgreementList[id].selectedFileName = null;
      this.AgreementList[id].display_file_name = null;
      this.AgreementList[id].agreementDocumentRemoveDisabled = true;
    },

    getCities() {
      //this function is called to get list of cities
      const path = "city";
      axios
        .get(path)
        .then((res) => {
          this.cities = res.data.cities;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getStates() {
      //this function is called to get list of states
      const path = "state";
      axios
        .get(path)
        .then((res) => {
          this.states = res.data.states;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of countries
    getCountries() {
      const path = "country";
      axios
        .get(path)
        .then((res) => {
          this.countries = res.data.countries;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getCountries() close

    getStatesByCountry(flag) {
      if(this.selectedCountry == null){
        this.states = [];
        this.selectedState = null;
        this.cities = [];
        this.selectedCity = null;
        return;
      }
      //this function is called to get list of states
      const path = "get-state-by-country/" + this.selectedCountry ;
      axios
        .get(path)
        .then((res) => {
          if(flag){
            this.states = [];
            this.selectedState = null;
            this.cities = [];
            this.selectedCity = null;
          }
          this.states = res.data.states;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCitiesByState(flag) {
      if(this.selectedState == null){
        this.cities = [];
        this.selectedCity = null;
        return;
      }
      //this function is called to get list of cities
      const path = "get-city-by-state/" + this.selectedState;
      axios
        .get(path)
        .then((res) => {
          if(flag){
            this.cities = [];
            this.selectedCity = null;
          }
          this.cities = res.data.cities;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getEmployeeStatesByCountry(index,flag) {
      console.log("getEmployeeStatesByCountry",index)
      if(this.EmployeeList[index].country_id == null){
        this.EmployeeList[index].states = [];
        this.EmployeeList[index].state_id = null;
        this.EmployeeList[index].cities = [];
        this.EmployeeList[index].city_id = null;
        return;
      }
      console.log("getEmployeeStatesByCountry",flag)
      //this function is called to get list of states
      const path = "get-state-by-country/" + this.EmployeeList[index].country_id ;
      axios
        .get(path)
        .then((res) => {
          if(flag){
            this.EmployeeList[index].states = [];
            this.EmployeeList[index].state_id = null;
            this.EmployeeList[index].cities = [];
            this.EmployeeList[index].city_id = null;
          }
          this.EmployeeList[index].states = res.data.states;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    
    getEmployeeCitiesByState(index,flag) {
      console.log("getEmployeeCitiesByState",index)
      if(this.EmployeeList[index].state_id == null){
        this.EmployeeList[index].cities = [];
        this.EmployeeList[index].city_id = null;  
        return;
      }
      console.log("getEmployeeCitiesByState",flag)
      //this function is called to get list of cities
      const path = "get-city-by-state/" + this.EmployeeList[index].state_id;
      axios
        .get(path)
        .then((res) => {
          if(flag){
            this.EmployeeList[index].cities = [];
            this.EmployeeList[index].city_id = null;  
          } 
          this.EmployeeList[index].cities = res.data.cities;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of country codes
    getCountryCodes() {
      const path = "country_code_master";
      axios
        .get(path)
        .then((res) => {
          this.countryCodes = res.data.country_codes;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of collateral documents master
    getCollateralDocuments() {
      const path = "collateral-document";
      axios
        .get(path)
        .then((res) => {
          this.collateralDocuments = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getCollateralDocuments() close

    //this function is called to get list of collateral admin documents master
    getCollateralAdminDocuments() {
      const path = "collateral-admin-document";
      axios
        .get(path)
        .then((res) => {
          this.collateralAdminDocuments = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getCollateralAdminDocuments() close
  }, //main function close

  mounted() {
    this.getCollateralManager();
  },

  created() {
    this.id = this.$route.params.id;
    this.getCurrencies();
    this.getCountries();
    this.getCountryCodes();
    this.getCollateralDocuments();
    this.getCollateralAdminDocuments();
  },
};
</script>

<style scoped>
.error {
  color: red;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -70px;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>
