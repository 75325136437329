const axios = require("axios");

export const Utils = {
  downloadDocument: function (filePath) {
    //this method downloads documents from server
    if (filePath == null) {
      alert('"File not uploaded."');
    } else {
      axios({
        url: "download-document",
        method: "POST",
        responseType: "blob",
        data: {
          path: filePath,
        },
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", filePath.split("/").reverse()[0]); //get the filename
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          console.error("error: " + error);
          alert('"Failed to download."');
        });
    }
  },

  make_alert: function (classes, msg, escape) {
    bootbox.alert({
      message: "<br/>" + msg,
      callback: escape ? escape() : null,
    });
  },

  start_loading: function () {
    if ((document.getElementById("id_loader").style.display = "none")) {
      document.getElementById("id_loader").style.display = "block";
    } //for start Loading..
  },

  stop_loading: function () {
    if ((document.getElementById("id_loader").style.display = "block")) {
      document.getElementById("id_loader").style.display = "none";
    } //for stop Loading..
  },
};
