<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          Payment Overview
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                            <!-- <a href="costPayment.html">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                            </a>-->
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <span style="font-size: 14px; color: red; margin-left:30px !important; margin-bottom: 10px !important; margin-top: -10px !important;">
                      Note: All calculations are displayed in Fund Currency.</span>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover table-head-fixed text-nowrap">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Payment ID:</td>
                        <td v-if="costPayment.id !== null" class>
                          {{ costPayment.id }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Borrower Name:</td>
                        <td v-if="costPayment.borrower_name !== null" class>
                          {{ costPayment.borrower_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Facility ID:</td>
                        <td
                          v-if="costPayment.facility_registration_id !== null"
                          class
                        >
                          {{ costPayment.facility_registration_id }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Paid Amount (Converted):</td>
                        <td v-if="costPayment.converted_paid_amt !== null" class>
                          {{ costPayment.converted_paid_amt }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Conversion Date:</td>
                        <td v-if="costPayment.conversion_date !== null" class>
                          {{ costPayment.conversion_date }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Conversion Rate:</td>
                        <td v-if="costPayment.currency_rate !== null" class>
                          {{ costPayment.currency_rate }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Payment Currency:</td>
                        <td v-if="costPayment.currency_short_name !== null" class>
                          {{ costPayment.currency_short_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Payment Date:</td>
                        <td v-if="costPayment.payment_date !== null" class>
                          {{ costPayment.payment_date }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Balance Amount:</td>
                        <td v-if="costPayment.balance_amount !== null" class>
                          {{ costPayment.balance_amount }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Cost ID:</td>
                        <td v-if="costPayment.facility_expense_transaction_id !== null" class>
                          {{ costPayment.facility_expense_transaction_id }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <!-- <i class="icofont-spinner-alt-3"></i>  -->
                          Bank Details
                        </h1>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover table-head-fixed text-nowrap">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Beneficiary Bank:</td>
                        <td v-if="bank.bank_name !== null" class>
                          {{ bank.bank_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Bank Identifier Code:</td>
                        <td v-if="bank.bic !== null" class>
                          {{ bank.bic }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">IBAN (International Bank Account No):</td>
                        <td
                          v-if="bank.iban !== null"
                          class
                        >
                          {{ bank.iban }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Account No. :</td>
                        <td
                          v-if="bank.account_number !== null"
                          class
                        >
                          {{ bank.account_number }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Account Name:</td>
                        <td v-if="bank.account_name !== null" class>
                          {{ bank.account_name }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Reference:</td>
                        <td v-if="bank.reference !== null" class>
                          {{ bank.reference }}
                        </td>
                        <td v-else class>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          
          <div v-if="costDocumentsDetails.length > 0" class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documentation</h1>
                      </div>
                    </div>
                  </div>

                  <div class="card-body table-responsive p-0">
                    <!------menton here style="height: 300px;"-------->
                    <table class="table table-head-fixed text-nowrap">
                      <thead>
                        <tr>
                          <th>Document</th>
                          <th>Comment</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(cp) in costDocumentsDetails"
                          :key="cp.id"
                        >
                          <td>
                          <span v-on:click="downloadCostDocument(cp.document_path)"
                            :style="{ cursor: 'pointer' }" >
                            <i class="icofont-download text-info" style="padding-right: 10px;"></i>
                            {{ cp.display_file_name }}
                          </span>
                          </td>
                          <td v-if="cp.comments !== null">
                            {{ cp.comments }}
                          </td>
                          <td v-else>-</td>
                        </tr>
                        <!----------------------------------------------->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>

  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import moment from "moment";

export default {
  data() {
    return {
      id: null,
      // tabIndex: 0,
      // tabs: ["#one", "#two", "#three"],
      costPayment: [],
      bank: [],
      // Payment: [],
      costPaymentId: null,
      selectedCpCheckDocumentsList: [],
      costPayment_documents_details: [],
    };
  },
  components: {
    Datepicker,
  },
  methods: {

    downloadCostDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },

    getCostPaymentOverview() {
      Utils.start_loading();
      //this function is called on page load and created events. it will show details on cost with costid equal to this.id
      const path = "cost-payment-overview/" + this.id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.costPayment = res.data.cost_payment_overview.facility_expense_payment_transaction[0];

            if (this.costPayment.conversion_date != '-' && this.costPayment.conversion_date != null) {
              this.costPayment.conversion_date = moment(this.costPayment.conversion_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.costPayment.conversion_date = '-';
            }
            if (this.costPayment.payment_date != '-' && this.costPayment.payment_date != null) {
              this.costPayment.payment_date = moment(this.costPayment.payment_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.costPayment.payment_date = '-';
            }

            this.bank = res.data.cost_payment_overview.facility_expense_payment_transaction[0];
            this.costDocumentsDetails = res.data.cost_payment_overview.facility_expense_payment_transaction_document;

            console.log("get cost details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/facility");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, 
  },

  mounted() {
    this.id = this.$route.params.id;
    this.inactive =
      this.$store.state.inactive === "costPayment_inactive" ? true : false;
    this.getCostPaymentOverview();
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style scoped>
th,
td {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right !important;
}

.wrapper {
  margin-bottom: -20px;
  min-height: 680px !important;
}
</style>
