<!-- This is add Loan page. -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-plus"></i> Add Repayment
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Basic Details</h1>
                      </div>

                      <!-- /.col -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="selectedBorrower" class="required-field"
                          >Borrower Name</label
                        >
                        <select
                          id="selectedBorrower"
                          class="form-control"
                          v-model="selectedBorrower"
                          @change="getFacilitySetupByBorrower();getOfftakerNameByBorrower()"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.selectedBorrower.$error,
                          }"
                          disabled
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="borrower in borrowers"
                            :value="borrower.borrower_id"
                            :key="borrower.borrower_id"
                          >
                            {{ borrower.borrower_name }}
                          </option>
                        </select>
                        <div
                          v-if="isSubmitted && $v.selectedBorrower.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedBorrower.required"
                            >Borrower Name is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="selectedFacility" class="required-field"
                          >Facility ID</label
                        >
                        <select
                          id="selectedFacility"
                          class="form-control"
                          v-model="selectedFacility"
                          @change="getLoan()"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.selectedFacility.$error,
                          }"
                          disabled
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="fac in facilities"
                            :value="fac.id"
                            :key="fac.id"
                          >
                            {{ fac.facility_registration_id }}
                          </option>
                        </select>
                        <div
                          v-if="isSubmitted && $v.selectedFacility.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedFacility.required"
                            >Facility ID is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="selectedOfftakerID" class="required-field"
                          >Offtaker Name</label
                        >
                        <select
                          id="selectedOfftakerID"
                          class="form-control"
                          v-model="selectedOfftakerID"
                          @change="getContractNumberByOfftakerName()"
                          :class="{
                            'is-invalid':
                            isSubmitted && $v.selectedOfftakerID.$error,
                          }"
                          :disabled="repaymentType != 'Loan'"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="ofn in offtakerNameIDs"
                            :value="ofn.id"
                            :key="ofn.id"
                          >
                            {{ ofn.offtaker_name }}
                          </option>
                        </select>
                        <div
                          v-if="isSubmitted && $v.selectedOfftakerID.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedOfftakerID.required"
                            >Offtaker Name is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="selectedContractNumber" class="required-field"
                          >Contract No.</label
                        >
                        <select
                          id="selectedContractNumber"
                          class="form-control"
                          v-model="selectedContractNumber"
                          @change="getContractNumberDetails()"
                          :class="{
                            'is-invalid':
                            isSubmitted && $v.selectedContractNumber.$error,
                          }"
                          :disabled="repaymentType == 'Logistics' || repaymentType == 'Direct Shipping' || repaymentType == 'Contract'"
                        >
                          <option :value="null">--- Select ---</option>
                            <option
                              v-for="ofn in contractNumber"
                              :value="ofn.contractId"
                              :key="ofn.contractId"
                            >
                            {{ ofn.contractNo }}
                          </option>
                        </select>
                        <div
                          v-if="isSubmitted && $v.selectedContractNumber.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedContractNumber.required"
                            >Contract No. is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div v-if="this.partialContractNumber.length > 0 && this.selectedContractNumber" class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1" :class="{'required-field':repaymentFlag == 1}"
                            >Partial Contract No.</label
                          >
                          <select
                            id="selectedPartialContractNumber"
                            class="form-control"
                            name="selectedPartialContractNumber"
                            v-model="selectedPartialContractNumber"
                            @change="getPartialContractNumberDetails()"
                            :class="{
                            'is-invalid':
                              isSubmitted && $v.selectedPartialContractNumber.$error,
                            }"
                            :disabled="repaymentType == 'Logistics' || repaymentType == 'Direct Shipping' || repaymentType == 'Contract'"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="cn in partialContractNumber"
                              :value="cn.partialContractId"
                              :key="cn.partialContractId"
                            >
                              {{ cn.partialContractNo }}
                            </option>
                          </select>
                          <div
                            v-if="isSubmitted && $v.selectedPartialContractNumber.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.selectedPartialContractNumber.required"
                              >Partial Contract No. is required</span
                            >
                          </div>
                        </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="Quantity">Quantity</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="Quantity"
                          id="Quantity"
                          @keypress="isDecimalTwoFixed($event)"
                          @paste="truncateDecimalPlacesUptoTwo($event)"
                          disabled
                        />
                          <!-- :class="{
                            'is-invalid': isSubmitted && $v.Quantity.$error,
                          }" -->
                        <!-- <div
                          v-if="isSubmitted && $v.Quantity.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.Quantity.required"
                            >Quantity is required<br
                          /></span>
                          <span v-if="!$v.Quantity.maxLength"
                            >Max length of 15 digits exceeded</span
                          >
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="selectedUnit"
                          >Unit</label
                        >
                        <select
                          id="selectedUnit"
                          class="form-control"
                          v-model="selectedUnit"
                          disabled
                        >
                          <!-- :class="{
                            'is-invalid':
                              isSubmitted && $v.selectedUnit.$error,
                          }" -->
                          <option :value="null">-- Select --</option>
                          <option
                            v-for="unit in UnitTypes"
                            :value="unit.id"
                            :key="unit.id"
                          >
                            {{ unit.name }}
                          </option>
                        </select>
                        <!-- <div
                          v-if="isSubmitted && $v.selectedUnit.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedUnit.required"
                            >Unit is required</span
                          >
                        </div> -->
                      </div>
                    </div>
                  </div>
                  
                  <!-- <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onBasicDetailsSaveInfo()"
                        :disabled="isDisabledBasicDetailsSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>



          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Loan Mapping</h1>
                      </div>
                      <!-- /.col -->
                      <!-- <div v-if="this.repaymentType != 'Loan'" class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addLoanMapping"
                              :disabled="loanMappingAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div> -->
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->
                    <div class="row">

                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="RepaymentAmount" class="required-field">Repayment Amount</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="RepaymentAmount"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            @keyup="onChangeRepaymentAmountDateCurrency();calculateRepaymentConvertedAmount()"
                            id="RepaymentAmount"
                            :class="{
                              'is-invalid': isLoanDetailsSubmitted && $v.RepaymentAmount.$error,
                            }"
                            :disabled="profitReturnID || excessAmountFlag"
                          />
                          <div
                            v-if="isLoanDetailsSubmitted && $v.RepaymentAmount.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.RepaymentAmount.required">Repayment Amount is required</span>
                            <span v-if="!$v.RepaymentAmount.maxLength">Max length of 15 digit exceeded</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label class="required-field">Repayment Currency</label>
                          <select
                            id="selectedRepaymentCurrency"
                            class="form-control"
                            v-model="selectedRepaymentCurrency"
                            :class="{
                              'is-invalid':
                                isLoanDetailsSubmitted && $v.selectedRepaymentCurrency.$error,
                            }"
                            @change="onChangeRepaymentAmountDateCurrency();getCurrencyExchangeRateDateForRepayment()"
                            :disabled="profitReturnID || excessAmountFlag"
                          >
                            <option :value="null">-- Select a Currency --</option>
                            <option
                              v-for="curr in currencies"
                              :value="curr.id"
                              :key="curr.id"
                            >
                              {{ curr.currency_short_name }}
                            </option>
                          </select>
                          <div
                            v-if="isLoanDetailsSubmitted && $v.selectedRepaymentCurrency.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.selectedRepaymentCurrency.required"
                              >Repayment Currency is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="RepaymentDate" class="required-field">Repayment Conversion Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            placeholder="Select Date"
                            v-model="RepaymentDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            @input="onChangeRepaymentAmountDateCurrency();getCurrencyExchangeRateDateForRepayment()"
                            :disabledDates="{ to: new Date(conversionBackCapDate), from: new Date() }"
                            :class="{
                              'is-invalid': isLoanDetailsSubmitted && $v.RepaymentDate.$error,
                            }"
                            :disabled="profitReturnID || excessAmountFlag"
                          ></datepicker>
                          <div
                            v-if="isLoanDetailsSubmitted && $v.RepaymentDate.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.RepaymentDate.required">Repayment Conversion Date is required</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label>Fund Currency</label>
                          <select
                            id="fundCurrency"
                            class="form-control"
                            v-model="fundCurrency"
                            disabled
                          >
                            <option :value="null">-- Select a Currency --</option>
                            <option
                              v-for="curr in currencies"
                              :value="curr.id"
                              :key="curr.id"
                            >
                              {{ curr.currency_short_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="RepaymentConversionRate">Repayment Conversion Rate</label>
                          <input
                            type="number"
                            class="form-control"
                            v-model="RepaymentConversionRate"
                            @keypress="isDecimal($event)"
                            id="RepaymentConversionRate"
                            disabled
                          />
                        </div>
                      </div>
                      

                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="RepaymentConvertedAmount" title="Converted Repayment Amount">Conv. Repayment Amt.</label>
                          <input
                            type="number"
                            class="form-control"
                            v-model="RepaymentConvertedAmount"
                            @keypress="isDecimal($event)"
                            id="RepaymentConvertedAmount"
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="row"
                      v-for="(lm, index) in loanMappingList"
                      :key="index"
                    >
                    
                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <!-- <label for="selectedLoan" :class="{'required-field':repaymentType != 'Loan'}">Loan Reg. ID</label> -->
                          <label for="selectedLoan" class='required-field'>Loan Reg. ID</label>
                          <select
                            id="selectedLoan"
                            class="form-control"
                            name="selectedLoan"
                            v-model="lm.selectedLoan"
                            @change="onLoanSelectGetDetails(index)"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.loanMappingList.$each[index]
                                  .selectedLoan.$error,
                            }"
                            :disabled="lm.selectedRepaymentFlag  || repaymentType == 'Loan' || repaymentType == 'Contract'"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="loan in loans"
                              :value="loan.loan_master_id"
                              :key="loan.loan_master_id"
                            >
                              {{ loan.loan_registration_id }}
                            </option>
                          </select>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.loanMappingList.$each[index].selectedLoan
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .selectedLoan.required
                              "
                              >Loan Reg. ID is required</span
                            >
                          </div>
                        </div>
                      </div>

                      

                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="loanOSAmount" title="Loan Outstanding Amount">Loan O/S Amount</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="lm.loanOSAmount"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            id="loanOSAmount"
                            disabled
                          />
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="loanRepaymentDate" class="required-field">Repayment Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            placeholder="Select Date"
                            id="loanRepaymentDate"
                            v-model="lm.loanRepaymentDate"
                            @input="getloanConversionRateByCurrencyDate(index,lm.loanRepaymentDate)"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.loanMappingList.$each[index]
                                  .loanRepaymentDate.$error,
                            }"
                            :disabled="!lm.selectedLoan || !!lm.selectedRepaymentFlag" 
                            :disabledDates="{ to: new Date(lm.backCapDate), from: new Date() }"
                          ></datepicker>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.loanMappingList.$each[index].loanRepaymentDate
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanRepaymentDate.required
                              "
                              >Repayment Date is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="loanRepaymentAmount" title="Repayment Amount (Fund Currency)">Repayment Amt.(Fund Currency)</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="lm.loanRepaymentAmount"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            id="loanRepaymentAmount"
                            disabled
                            />
                            <!-- @keyup="onChangeRepaymentAmountValidation(index)" -->
                            <!-- :disabled="!lm.selectedLoan || lm.selectedRepaymentFlag || (repaymentType == 'Loan')" -->

                            <!-- :disabled="!lm.selectedLoan" -->
                              <!-- @keyup="checkExcessAmount(index)" -->
                          <!-- <div
                            v-if="
                              (isSubmitted &&
                              $v.loanMappingList.$each[index].loanRepaymentAmount
                                .$error) || (lm.loanRepaymentAmountError)
                            "
                            class="invalid-feedback"
                          >
                          <span
                              v-if="
                                (lm.loanRepaymentAmountError)
                              "
                              >{{ lm.loanRepaymentAmountErrorMessage }}<br/></span
                            >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanRepaymentAmount.required
                              "
                              >Repayment Amount (Fund Currency) is required</span
                            >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanRepaymentAmount.maxLength
                              "
                              >Max length of 15 characters exceeded</span
                            >
                          </div> -->
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="selectedLoanCurrency">Loan Currency</label>
                          <select
                            id="selectedLoanCurrency"
                            class="form-control"
                            v-model="lm.selectedLoanCurrency"
                            disabled
                          >
                            <option :value="null">-- Loan Currency --</option>
                            <option
                              v-for="curr in currencies"
                              :value="curr.id"
                              :key="curr.id"
                            >
                              {{ curr.currency_short_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="loanConversionDate">Conversion Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            placeholder="Select Date"
                            id="loanConversionDate"
                            v-model="lm.loanConversionDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            disabled
                          ></datepicker>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="loanConversionRate">Conversion Rate</label>
                          <input
                            type="number"
                            class="form-control"
                            v-model="lm.loanConversionRate"
                            id="loanConversionRate"
                            @keypress="isDecimal($event)"
                            disabled
                          />
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="loanConvertedRepaymentAmount" title="Converted Repayment Amount (Loan Currency)">Conv. Repayment Amt.(Loan Currency)</label>
                          <input
                            type="number"
                            class="form-control"
                            v-model="lm.loanConvertedRepaymentAmount"
                            id="loanConvertedRepaymentAmount"
                            @keypress="isDecimal($event)"
                            disabled
                          />
                        </div>
                      </div>

                      <!-- <div v-if="(repaymentType == 'Logistics')" class="col-md-1 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label
                            for="deleteLoanMapping"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            v-on:click="deleteLoanMapping(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div> -->
                    </div>

                    <!-- <div v-if="this.repaymentType != 'Loan'" class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          name="saveInfo"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onLoanMappingSaveInfo()"
                          :disabled="isDisabledLoanMappingSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Bill Of Lading</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addBillOfLading"
                              :disabled="billOfLadingAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <div
                      class="row"
                      v-for="(lm, index) in billOfLadingList"
                      :key="index"
                    >

                      <div class="col-md-3 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="BOLNumber">BOL No.</label>
                          <select
                            id="BOLNumber"
                            class="form-control"
                            v-model="lm.BOLNumber"
                            @change="onChangeBOLNumber(index)"
                          >
                            <!-- :class="{
                                'is-invalid':
                                  (isSubmitted && lm.BOLNumberError)
                              }" -->
                            <!-- :class="{
                                'is-invalid':
                                  (isSubmitted &&
                                  $v.billOfLadingList.$each[index]
                                    .BOLNumber.$error) || lm.BOLNumberError
                              }" -->
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="b in BillOfLadingArray"
                              :value="b.bill_of_landing_number"
                              :key="b.bill_of_landing_number"
                            >
                              {{ b.bill_of_landing_number }}
                            </option>
                          </select>
                          <!-- <input
                            type="text"
                            class="form-control"
                            v-model="lm.BOLNumber"
                            id="BOLNumber"
                            @keyup="onChangeBOLNumber(index,lm.BOLNumber)"
                            :class="{
                                'is-invalid':
                                  (isSubmitted &&
                                  $v.billOfLadingList.$each[index]
                                    .BOLNumber.$error) || lm.BOLNumberError
                              }"
                            /> -->
                            <!-- <div
                              v-if="
                                (isSubmitted &&
                                $v.billOfLadingList.$each[index].BOLNumber
                                  .$error) || lm.BOLNumberError
                              "
                              class="invalid-feedback"
                            > -->
                            <!-- <div
                              v-if="
                                (isSubmitted && lm.BOLNumberError)
                              "
                              class="invalid-feedback"
                            >
                              <span v-if="lm.BOLNumberError"
                                >BOL Number already exists</span
                              >
                          </div> -->
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="BOLQuantity">Quantity (MT)</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="lm.BOLQuantity"
                            id="BOLQuantity"
                            disabled
                          />
                        </div>
                      </div>

                      <!-- <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="BOLRepaymentQuantity">Repayment Quantity</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="lm.BOLRepaymentQuantity"
                            id="BOLRepaymentQuantity"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            @keyup="onChangeBOLRepaymentQuantity(index)"
                            :disabled="!lm.BOLNumber || lm.BOLNumberError"
                            :class="{
                              'is-invalid': (lm.BOLRepaymentQuantityError) ||
                                (isSubmitted &&
                                $v.billOfLadingList.$each[index]
                                  .BOLRepaymentQuantity.$error)
                            }"
                          />
                          <div
                            v-if="(lm.BOLRepaymentQuantityError) ||
                              (isSubmitted &&
                              $v.billOfLadingList.$each[index].BOLRepaymentQuantity
                                .$error)
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                (lm.BOLRepaymentQuantityError && (lm.BOLRepaymentQuantity == 0 || lm.BOLRepaymentQuantity == null)) 
                              "
                              >Repayment Quantity is required</span
                            >
                            <span
                              v-if="
                                (lm.BOLRepaymentQuantityError && (lm.BOLRepaymentQuantity > lm.BOLQuantity)) 
                              "
                              >Repayment Quantity is greater than Quantity</span
                            >
                            <span
                              v-if="
                                !$v.billOfLadingList.$each[index]
                                  .BOLRepaymentQuantity.maxLength
                              "
                              >Max length of 15 digits exceeded.</span
                            >
                          </div>
                        </div>
                      </div> -->

                      <div class="col-md-3 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="BOLRepaymentAmount">BOL Repayment Amount</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="lm.BOLRepaymentAmount"
                            id="BOLRepaymentAmount"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            @keyup="onChangeBOLRepaymentAmountValidation(index)"
                            :disabled="!lm.BOLNumber"
                            :class="{
                              'is-invalid': (lm.BOLRepaymentAmountError) ||
                                (isSubmitted &&
                                $v.billOfLadingList.$each[index]
                                  .BOLRepaymentAmount.$error)
                            }"
                          />
                          <div
                            v-if="(lm.BOLRepaymentAmountError) ||
                              (isSubmitted &&
                              $v.billOfLadingList.$each[index].BOLRepaymentAmount
                                .$error)
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                (lm.BOLRepaymentAmountError)
                              "
                              >{{ lm.BOLRepaymentAmountErrorMessage }}<br/></span
                            >
                            <span
                              v-if="
                                !$v.billOfLadingList.$each[index]
                                  .BOLRepaymentAmount.maxLength
                              "
                              >Max length of 15 digits exceeded.</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-1 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label
                            for="deleteBillOfLading"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            v-on:click="deleteBillOfLading(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          name="saveInfo"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onBillOfLadingSaveInfo()"
                          :disabled="isDisabledBillOfLadingSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid pb-3" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Banking Details</h1>
                      </div>

                      <!-- /.col -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="selectedBank">Beneficiary Bank</label>
                        <select
                          id="selectedBank"
                          class="form-control"
                          v-model="selectedBank"
                        >
                          <option :value="null">-- Select a Bank --</option>
                          <option
                            v-for="bank in banks"
                            :value="bank.id"
                            :key="bank.id"
                          >
                            {{ bank.bank_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="bic">Bank Identifier Code</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="bic"
                          id="bic"
                          :class="{
                            'is-invalid': isSubmitted && $v.bic.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.bic.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.bic.minLength"
                            >Min length of 8 characters required<br
                          /></span>
                          <span v-if="!$v.bic.maxLength"
                            >Max length of 11 characters exceeded<br
                          /></span>
                          <span v-if="!$v.bic.alphaNum"
                            >Bank Identifier Code should be alphanumeric
                            only</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="iban">IBAN </label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="iban"
                          id="iban"
                          :class="{
                            'is-invalid': isSubmitted && $v.iban.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.iban.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.iban.required"
                            >IBAN cannot be 0</span
                          >
                          <span v-if="!$v.iban.maxLength"
                            >Max length of 35 characters exceeded<br
                          /></span>
                          <span v-if="!$v.iban.alphaNum"
                            >IBAN should be alphanumeric only</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="accountNumber">Account No.</label>
                        <input
                          type="text"
                          id="accountNumber"
                          class="form-control"
                          v-model="accountNumber"
                          :class="{
                            'is-invalid': isSubmitted && $v.accountNumber.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.accountNumber.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.accountNumber.required"
                            >Account No. cannot be 0</span
                          >
                          <span v-if="!$v.accountNumber.maxLength"
                            >Max length of 17 characters exceeded<br
                          /></span>
                          <span v-if="!$v.accountNumber.numeric"
                            >Account No. should be numeric only</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="accountName">Account Name </label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="accountName"
                          id="accountName"
                          :class="{
                            'is-invalid': isSubmitted && $v.accountName.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.accountName.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.accountName.required"
                            >Account Name cannot be 0</span
                          >
                          <span v-if="!$v.accountName.maxLength"
                            >Max length of 100 characters exceeded<br
                          /></span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="reference">Reference </label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="reference"
                          id="reference"
                          :class="{
                            'is-invalid': isSubmitted && $v.reference.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.reference.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.reference.required"
                            >Reference cannot be 0</span
                          >
                          <span v-if="!$v.reference.maxLength"
                            >Max length of 100 characters exceeded<br
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documents</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addRepaymentDoc()"
                              :disabled="RepaymentDocumentsAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(doc, index) in RepaymentDocuments"
                      :key="index"
                    >
                      <div class="col-md-4 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="customFile">Upload Document</label>
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="customFile"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              v-on:change="
                                handleFileUploadRepaymentDocuments(index, $event)
                              "
                              :class="{
                                'is-invalid': doc.errors.filesize,
                              }"
                            />
                            <small>
                              <p style="background-color: powderblue">
                                <b>{{ doc.displayFileName }}</b>
                                <small>
                                  <u
                                    style="color: blue; cursor: pointer"
                                    v-if="
                                      doc.RepaymentDocumentsRemoveDisabled === false
                                    "
                                    v-on:click="removeRepaymentDocuments(index)"
                                    >Remove</u
                                  >
                                </small>
                              </p>
                            </small>
                            <div
                              v-if="doc.errors.filesize"
                              class="invalid-feedback"
                            >
                              <span>{{ doc.errors.filesize }}</span>
                            </div>
                            <label class="custom-file-label" for="customFile"
                              >Choose file</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="comments">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="doc.comments"
                          id="comments"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.RepaymentDocuments.$each[index].comments
                                .$error,
                          }"
                          :disabled="(doc.displayFileName == null)"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.RepaymentDocuments.$each[index].comments.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.RepaymentDocuments.$each[index].comments
                                .maxLength
                            "
                            >Max length of 100 characters exceeded.</span
                          >
                        </div>
                      </div>
                    </div>

                      <div class="col-md-1 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            v-on:click="deleteRepaymentDocuments(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onRepaymentDocumentSaveInfo()"
                          :disabled="isDisabledRepaymentDocumentSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-success sh"
                      v-on:click="onSubmit()"
                    >
                      Submit
                    </button>
                  </li>
                  <li>
                    <a @click="$router.go(-1)">
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->  
    </div>
    <!-- ./wrapper -->
    <div class="modal fade" id="view_doc" data-backdrop="static">
      <div class="modal-dialog mw-100 w-60 custom-width">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              Loan Mapping
            </h4>
            <!-- <button type="button" class="close" v-on:click="onCloseModal()">
              <i class="icofont-close-circled"></i>
            </button> -->
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <h4 class="txt black">
                    <strong>
                      <!-- Excess Amount has been received of {{ excessAmount }} {{ fundCurrencyShortName }} . -->
                      Excess Amount has been received of {{ excessAmount }} {{ fundCurrencyShortName }} for Loan {{ excessSelectedLoanName }}.
                    </strong>
                  </h4>
                  <h4 class="txt black">
                    <strong>
                      Kindly choose the below option for the Excess Amount <span style="color: red !important;">*</span>.
                    </strong>
                  </h4>
                  <div class="row">
                    <!-- Radio Button 1: Prepaid Repayment -->
                    <div style="margin-top: 10px" class="col-md-12 col-sm-12 col-12 txt black">
                      <input
                        type="radio"
                        value="prepaidRepayment"
                        v-model="selectedOption"
                        id="prepaidRepaymentToggle"
                        name="excessOption"
                        @change="updateToggle('prepaidRepayment')"
                        class="toggle-padding"
                      />
                      Consider it as Prepaid Repayment.
                    </div>
                  </div>

                  <div class="row">
                    <!-- Radio Button 2: Profit Return -->
                    <div style="margin-top: 10px" class="col-md-12 col-sm-12 col-12 txt black">
                      <input
                        type="radio"
                        value="profitReturn"
                        v-model="selectedOption"
                        id="profitReturnToggle"
                        name="excessOption"
                        @change="updateToggle('profitReturn')"
                        class="toggle-padding"
                      />
                      Consider it as Profit Return.
                    </div>
                  </div>

                  <!-- <div v-if="(this.repaymentType == 'Logistics' && this.loanMappingOSAmount > 0)" class="row">
                    <div style="margin-top: 10px" class="col-md-12 col-sm-12 col-12 txt black">
                      <input
                        type="radio"
                        value="carryForward"
                        v-model="selectedOption"
                        id="carryForwardToggle"
                        name="excessOption"
                        @change="updateToggle('carryForward')"
                        class="toggle-padding"
                      />
                      Carry forward to the next open loan of the same facility.
                    </div>
                  </div> -->

                    <!-- <div style="margin-top: 10px" class="col-md-6 col-sm-5 col-6 txt black">
                      <input
                          type="radio"
                          :value="true"
                          v-model="profitReturnToggle"
                          id="profitReturnToggle"
                          name="excessOption"
                          @change="updateToggle(true)"
                          class="toggle-padding"
                        />
                        Consider it as Profit Return.
                    </div>
                  </div>
                  <div v-if="this.repaymentType != 'Loan'" class="row">
                    <div class="col-md-12 col-sm-12 col-12 txt black">
                      <input
                          type="radio"
                          :value="false"
                          v-model="profitReturnToggle"
                          id="carryForwardToggle"
                          name="excessOption"
                          @change="updateToggle(false)"
                          class="toggle-padding"
                        />
                      Carry forward to the next open loan of the same facility.
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <router-link :to="{ name: 'AddRepayment' }">
                <button
                  type="button"
                  name="saveInfo"
                  class="btn-sm btn btn-primary sh"
                  style="margin-right: 10px;"
                  v-on:click="onCloseModal()"
                >
                  Cancel
                </button>
              </router-link>
              <router-link :to="{ name: 'AddRepayment' }">
                <button
                  type="button"
                  name="submit"
                  class="btn-sm btn btn-success sh"
                  v-on:click="onSubmitModal()"
                >
                  Submit
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {
  required,
  maxLength,
  minLength,
  numeric,
  alphaNum,
  maxValue,
  decimal,
  between,
} from "vuelidate/lib/validators";


export default {
  data() {
    return {
      repaymentType: null,
      contractType: null,
      loanID: null,
      borrowers: [],
      facilities: [],
      currencies: [],
      banks: [],
      loans: [],
      // loans: [
      //     {
      //       "currency_id": 2, 
      //       "facility_setup_id": 790, 
      //       "loan_master_id": 735, 
      //       "loan_registration_id": "LM001PB0", 
      //       "transaction_level_outstanding_amount": "77,241.29"
      //     }, 
      //     {
      //       "currency_id": 1, 
      //       "facility_setup_id": 790, 
      //       "loan_master_id": 736, 
      //       "loan_registration_id": "LM002PB0", 
      //       "transaction_level_outstanding_amount": null,
      //     },
      //     {
      //       "currency_id": 1, 
      //       "facility_setup_id": 790, 
      //       "loan_master_id": 737, 
      //       "loan_registration_id": "LM003PB0", 
      //       "transaction_level_outstanding_amount": "2.00"
      //     }
      //   ],

      UnitTypes: [],
      // Basic details
      selectedBorrower: null,
      selectedFacility: null,
      offtakerNameIDs:[],
      contractNumber:[],
      partialContractNumber:[],
      selectedOfftakerID:null,
      selectedContractNumber:null,
      selectedPartialContractNumber:null,
      TotalContractQuantity: null,
      Quantity:null,
      selectedUnit: null,
      selectedCollateral: null,
      repaymentFlag: '0',
      repaymentDoneTwice: '0',
      ContractDate: null,
      PartialContractDate: null,
      conversionBackCapDate:new Date(moment(new Date()).subtract(10, "year")),
      fundCurrencyShortName:null,

      fundCurrency:null,

      // Bank details
      selectedBank: null,
      bic: null,
      iban: null,
      accountNumber: null,
      accountName: null,
      reference: null,
      basicDetailsSaveInfoDisabled: true, //save info button is disabled


      //list for Loan Documents
      RepaymentDocuments: [],
      RepaymentDocumentFile: null,
      // RepaymentDocumentsButtonDisabled: true,
      RepaymentDocumentsDisabled: true,
      RepaymentDocumentSaveInfoDisabled: true, //save info button is disabled
      RepaymentDocumentsAddMoreDisabled: true,
      
      //list for Loan Mapping
      RepaymentAmount:null,
      RepaymentDate: new Date(),
      selectedRepaymentCurrency: null,
      RepaymentCurrencyConversionFlag: false,
      RepaymentConversionRate:1,
      RepaymentConvertedAmount:null,
      remainderConvertedAmount:null,
      // AvailableToDisburse: null,
      loanMappingList: [],
      loanMappingSaveInfoDisabled: true, //save info button is disabled
      loanMappingAddMoreDisabled: false,
      totalRepaidAmount: 0,

      excessSelectedLoanName: null,
      excessSelectedLoanNameError: false,
      excessSelectedLoanNameName: null,
      excessSelectedCurrency: null,
      excessSelectedCurrencyName: null,
      excessConversionRate: null,
      excessAmountFlag: false,
      selectedOption: 'prepaidRepayment',
      excessAmount: null,
      loanMappingID: null,
      loanMappingOSAmount: null,
      fullLoanMappingDisabled: false,
      transferProfitReturnAmount: null,
      profitReturnID: null,

      TransactionLevelOutstandingAmount: null,
      partialContractNumberList: [],
      contractNumberList: [],

      billOfLadingList: [],
      billOfLadingSaveInfoDisabled: true, //save info button is disabled
      billOfLadingAddMoreDisabled: false,
      totalBOLRepaymentAmount: 0,

      BillOfLadingArray: [],

      firstTimePageLoad: false,

      LTVtoRepayment: false,

      isSubmitted: false,
      isProfitReturnSubmitted: false,
      isLoanDetailsSubmitted:false,
    };
  },

  validations: {
    selectedBorrower: {
      required,
    },
    // selectedFacility: {
    //   required: function () {
    //     if (this.selectedFacility.length < 1) return false;
    //     else return true;
    //   },
    // },
    selectedFacility: {
      required,
    },
    selectedOfftakerID: {
      required,
    },
    selectedContractNumber: {
      required,
    },
    selectedPartialContractNumber: {
      required: function () {
        if (this.selectedPartialContractNumber == null && this.repaymentFlag == 1) return false;
        else return true;
      },
    },
    // Quantity: {
    //   required,
    //   maxLength: maxLength(15),
    // },
    // selectedUnit: {
    //   required,
    // },
    bic: {
      alphaNum,
      minLength: minLength(8),
      maxLength: maxLength(11),
    },
    iban: {
      required: function () {
        if (this.iban === "0") return false;
        else return true;
      },
      alphaNum,
      maxLength: maxLength(35),
    },
    accountNumber: {
      required: function () {
        if (this.accountNumber === "0") return false;
        else return true;
      },
      numeric,
      maxLength: maxLength(17),
    },
    accountName: {
      required: function () {
        if (this.accountName === "0") return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    reference: {
      required: function () {
        if (this.reference === "0") return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    RepaymentDocuments: {
      $each: {
        comments: {
          maxLength: maxLength(100),
        },
      },
    },
    RepaymentAmount: {
      required,
      maxLength : maxLength(15),
    },
    RepaymentDate: {
      required,
    },
    selectedRepaymentCurrency: {
      required,
    },
    loanMappingList: {
      $each: {
        selectedLoan: {
          required,
        },
        loanRepaymentDate: {
          required,
        },
        // loanRepaymentAmount: {
        //   required: function (val) {
        //     if (!val || val == 0) return false;
        //     else return true;
        //   },
        //   maxLength: maxLength(15),
        // },
      },
    },
    billOfLadingList: {
      $each: {
        // BOLNumber: {
        //   maxLength : maxLength(15),
        // },
        // BOLRepaymentQuantity: {
        //   maxLength : maxLength(15),
        // },
        BOLRepaymentAmount: {
          maxLength : maxLength(15),
        },
      },
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
    //this is to computed disabled property of Basic Details Save Info button.
    isDisabledBasicDetailsSaveInfo: function () {
      //We are checking whether all the input fields are filled. If condition is satisfied then only save info button will be enabled.
      if (
        this.selectedBorrower
      ) {
        return false;
      } else {
        return true;
      }
    }, //isDisabledBasicDetailsSaveInfo() close

    //this is to computed disabled property of RepaymentDocuments Save Info button.
    isDisabledRepaymentDocumentSaveInfo: function () {
      let checkEmptyRepaymentDocumentsDocName = this.RepaymentDocuments.filter(
        (line) => line.displayFileName === null
      );
      //if the line is empty then disable save info button
      if (
        this.RepaymentDocuments.length === 0 &&
        this.RepaymentDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyRepaymentDocumentsDocName.length >= 1 &&
        this.RepaymentDocuments.length > 0
      ) {
        this.RepaymentDocumentsDisabled = true;
      } else {
        this.RepaymentDocumentsDisabled = false;
      }
      //RepaymentDocumentsDisabled variable is to check whther all input fields except file upload are filled. RepaymentDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.RepaymentDocumentsDisabled === true ||
        this.RepaymentDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledRepaymentDocumentSaveInfo() close

    //this is to computed disabled property of Loan Mapping Save Info button.
    isDisabledLoanMappingSaveInfo: function () {
      let checkEmptyLoanMappingID = this.loanMappingList.filter(
        (line) => line.selectedLoan === null
      );
      // let checkEmptyLoanMappingActualDisAmt = this.loanMappingList
      //   .slice(-1)
      //   .filter((line) => line.RepaymentAmount === null);
      // let checkEmptyLoanMappingAvilDis = this.loanMappingList
      //   .slice(-1)
      //   .filter((line) => line.AvailableToDisburse === null);
      // let checkEmptyLoanMappingRepaymentAmt = this.loanMappingList
      //   .slice(-1)
      //   .filter((line) => line.loanRepaymentAmount === null);
      //   let checkEmptyLoanMappingRepaymentAmtString = this.loanMappingList.slice(-1).filter(
      //   (line) => line.loanRepaymentAmount === ''
      // );
      // let checkEmptyLoanMappingRepaymentAmtZero = this.loanMappingList.slice(-1).filter(
      //   (line) => line.loanRepaymentAmount == 0
      // );
      //if the line is empty then disable save info button
      if (
        (this.loanMappingList.length === 0 &&
        this.loanMappingSaveInfoDisabled === true)
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled. If condition is satisfied then only save info button will be enable
      if (
        (
          checkEmptyLoanMappingID.length >= 1 &&
          this.loanMappingList.length > 0) 
          // (checkEmptyLoanMappingRepaymentAmt.length >= 1 &&
          // this.loanMappingList.length > 0) ||
          // (checkEmptyLoanMappingRepaymentAmtString.length >= 1 &&
          //   this.loanMappingList.length > 0) ||
          // (checkEmptyLoanMappingRepaymentAmtZero.length >= 1 &&
          //   this.loanMappingList.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledLoanMappingSaveInfo() close

    //this is to computed disabled property of Bill Of Lading Save Info button.
    isDisabledBillOfLadingSaveInfo: function () {
      let checkEmptyBillOfLadingBOLNumber = this.billOfLadingList
        .slice(-1)
        .filter((line) => line.BOLNumber === null);
      // let checkEmptyBillOfLadingBOLRepaymentQuantityError = this.billOfLadingList
      //   .slice(-1)
      //   .filter((line) => line.BOLRepaymentQuantityError == true);
      // let checkEmptyBillOfLadingBOLRepaymentAmountError = this.billOfLadingList
      //   .slice(-1)
      //   .filter((line) => line.BOLRepaymentAmountError == true);
      // let checkEmptyBillOfLadingBOLRepaymentQuantity = this.billOfLadingList
      //   .slice(-1)
      //   .filter((line) => line.BOLRepaymentQuantity  === null);
      let checkEmptyBillOfLadingBOLRepaymentAmount = this.billOfLadingList
        .slice(-1)
        .filter((line) => line.BOLRepaymentAmount === null);
      //if the line is empty then disable save info button
      console.log("checkEmptyBillOfLadingBOLNumber",checkEmptyBillOfLadingBOLNumber)
      if (
        this.billOfLadingList.length === 0 &&
        this.billOfLadingSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled. If condition is satisfied then only save info button will be enable
      if (
          (
          checkEmptyBillOfLadingBOLNumber.length >= 1 &&
          this.billOfLadingList.length > 0
          ) ||
          (
            checkEmptyBillOfLadingBOLRepaymentAmount.length >= 1 &&
            this.billOfLadingList.length > 0
          )
          // (
          //   checkEmptyBillOfLadingBOLRepaymentAmountError.length >= 1 &&
          //   this.billOfLadingList.length > 0
          // ) 
      ) {
        return true;
      } else {
        return false;

      }
    }, //isDisabledBillOfLadingSaveInfo() close
  }, //main computed close

  components: {
    Datepicker,
    Multiselect: window.VueMultiselect.default,
  },
  methods: {
    // isDecimal(e) {
    //   let invalidChars = ["-", "+", "e", "E"];
    //   if (invalidChars.includes(e.key)) return e.preventDefault();
    //   else return true;
    // },

    isAlphaNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      let inputValue = e.target.value;
      let decimalIndex = inputValue.indexOf(".");
      
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else if (decimalIndex !== -1 && inputValue.substring(decimalIndex + 1).length >= 2)
        return e.preventDefault();
      else return true;
    },

    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },

    //this method will make disabled property as false for Director Save Info
    // onBasicDetailsSaveInfo() {
    //   console.log("onBasicDetailsSaveInfo")
      // this.isSubmitted = true;
      // this.$v.selectedBorrower.$touch();
      // this.$v.selectedFacility.$touch();
      // this.$v.selectedUnit.$touch();
      // this.$v.Quantity.$touch();
      // this.$v.RepaymentDate.$touch();
      // if (
      //     this.$v.selectedBorrower.$invalid ||
      //     this.$v.selectedFacility.$invalid ||
      //     this.$v.selectedUnit.$invalid ||
      //     this.$v.Quantity.$invalid ||
      //     this.$v.RepaymentDate.$invalid
      //   ) {
      //     // Handle invalid fields (e.g., show an error message)
      //     return;
      //   }
    // },

    onChangeBOLNumber(index) {
      console.log("onChangeBOLNumber")
      this.isLoanDetailsSubmitted = true;
      this.$v.RepaymentAmount.$touch();
      if (
          this.$v.RepaymentAmount.$invalid
        ) {
          this.billOfLadingList[index].BOLNumber = null
          return;
        }
      if(this.billOfLadingList[index].BOLNumber == null){
        this.billOfLadingList[index].BOLRepaymentAmountError = false;
        this.billOfLadingList[index].BOLRepaymentAmountErrorMessage = ``;
        this.deleteBillOfLading(index);
        return;
      }
      let bol_count = this.billOfLadingList.filter(
        (col) => col.BOLNumber === this.billOfLadingList[index].BOLNumber
        ).length;
      console.log("bol_count",bol_count)

      // delete if existing bol is found
      if (bol_count > 1) {
        this.deleteBillOfLading(index);
        Utils.make_alert("warning", "Bill Of Lading already exists");
      } else {
        let BOLArray = this.BillOfLadingArray.find(bol => bol.bill_of_landing_number === this.billOfLadingList[index].BOLNumber)
        console.log("BOLArray", BOLArray)
        this.billOfLadingList[index].ShippingID = BOLArray.id;
        this.billOfLadingList[index].BOLQuantity = BOLArray.release_quantity;
        this.billOfLadingList[index].BOLNumberError = false;
        this.billOfLadingList[index].BOLRepaymentAmountError = true;
        this.billOfLadingList[index].BOLRepaymentAmountErrorMessage = `BOL Repayment Amount is required`;
      }

      // console.log("onChangeBOLNumber")
      // let exists = false;
      // this.billOfLadingList.map((bol, i) => {
      //   if (bol.BOLNumber === bol_number && i !== index) exists = true;
      // });
      // if (exists) {
      //   this.billOfLadingList[index].BOLNumberError = true;
      // }
      // else {
      //   let BOLArray = this.BillOfLadingArray.filter(bol => bol.bol_number == bol_number)
      //   this.billOfLadingList[index].ShippingID = BOLArray.shipping_id;
      //   this.billOfLadingList[index].BOLQuantity = BOLArray.quantity;
      //   this.billOfLadingList[index].BOLNumberError = false;
      // }
      // console.log("exists",exists)

      // Assuming you have an input field that allows users to edit BOLNumber, you can access its value like this:
      // const newBOLNumber = this.billOfLadingList[index].BOLNumber;
      // const newBOLRepaymentQuantity = this.billOfLadingList[index].BOLRepaymentQuantity;
      // const newBOLRepaymentAmount = this.billOfLadingList[index].BOLRepaymentAmount;

      // Check if the new value is empty (empty string or zero) and both BOLRepaymentQuantity and BOLRepaymentAmount are also empty
      // if (
      //   (newBOLNumber == null)
      // ) {
      //   this.deleteBillOfLading(index)
      //   // this.billOfLadingList[index].BOLNumberError = true;
      // }

      // // Check if BOLNumber, BOLRepaymentQuantity and BOLRepaymentAmount are also empty
      // if (
      //   (newBOLNumber == '' || newBOLNumber == null) &&
      //   (newBOLRepaymentQuantity == '' || newBOLRepaymentQuantity == null) &&
      //   (newBOLRepaymentAmount == '' || newBOLRepaymentAmount == null)
      // ) {
      //   this.billOfLadingList[index].BOLNumberError = false;
      //   this.billOfLadingList[index].BOLRepaymentQuantityError = false;
      //   this.billOfLadingList[index].BOLRepaymentAmountError = false;
      // }
    },
    // Assuming onChange for BOLRepaymentAmount
    onChangeBOLRepaymentAmountValidation(index) {
      console.log("onChangeBOLRepaymentAmountValidation")
      // Access the values of BOLNumber, BOLRepaymentQuantity, and BOLRepaymentAmount for the given index
      // const newBOLNumber = this.billOfLadingList[index].BOLNumber;
      // const newBOLRepaymentQuantity = this.billOfLadingList[index].BOLRepaymentQuantity;
      const newBOLRepaymentAmount = this.billOfLadingList[index].BOLRepaymentAmount;

      // // Check if BOLRepaymentAmount is not null and BOLNumber is null or empty
      // if (newBOLRepaymentAmount !== null && newBOLNumber === '') {
      //   this.billOfLadingList[index].BOLNumberError = true;
      // } else {
      //   this.billOfLadingList[index].BOLNumberError = false;
      // }

      // Check if BOLRepaymentAmount is not null or empty
      if (newBOLRepaymentAmount == '' || newBOLRepaymentAmount == 0 || newBOLRepaymentAmount == null) {
        this.billOfLadingList[index].BOLRepaymentAmountError = true;
        this.billOfLadingList[index].BOLRepaymentAmountErrorMessage = `BOL Repayment Amount is required`;
        return
      } else {
        this.billOfLadingList[index].BOLRepaymentAmountError = false;
        this.billOfLadingList[index].BOLRepaymentAmountErrorMessage = ``;
      }

      if(Number(newBOLRepaymentAmount) > Number(this.RepaymentConvertedAmount)){
          this.billOfLadingList[index].BOLRepaymentAmountError = true;
          this.billOfLadingList[index].BOLRepaymentAmountErrorMessage = `BOL Repayment Amount cannot be greater than Converted Repayment Amount ${this.RepaymentConvertedAmount}`;
          return;
        } else {
          this.billOfLadingList[index].BOLRepaymentAmountError = false;
          this.billOfLadingList[index].BOLRepaymentAmountErrorMessage = "";
          return;
        }
    },

    // // Assuming onChange for BOLRepaymentQuantity
    // onChangeBOLRepaymentQuantity(index) {
    //   console.log("onChangeBOLRepaymentQuantity")
    //   // Access the values of BOLNumber, BOLRepaymentQuantity, and BOLRepaymentAmount for the given index
    //   const newBOLNumber = this.billOfLadingList[index].BOLNumber;
    //   const newBOLRepaymentAmount = this.billOfLadingList[index].BOLRepaymentAmount;
    //   const newBOLRepaymentQuantity = this.billOfLadingList[index].BOLRepaymentQuantity;
    //   const newBOLQuantity = this.billOfLadingList[index].BOLQuantity;

    //   // // Check if BOLRepaymentQuantity is not null and BOLNumber is null or empty
    //   // if (newBOLRepaymentQuantity !== null && newBOLNumber === '') {
    //   //   this.billOfLadingList[index].BOLNumberError = true;
    //   // } else {
    //   //   this.billOfLadingList[index].BOLNumberError = false;
    //   // }

    //  // Check if BOLRepaymentQuantity is not null or empty
    //   if (newBOLRepaymentQuantity == '' || newBOLRepaymentQuantity == 0 || newBOLRepaymentQuantity == null) {
    //     this.billOfLadingList[index].BOLRepaymentQuantityError = true;
    //   } else {
    //     this.billOfLadingList[index].BOLRepaymentQuantityError = false;
    //   }

    //   if ((newBOLRepaymentQuantity > newBOLQuantity)){
    //     this.billOfLadingList[index].BOLRepaymentQuantityError = true;
    //   } else {
    //     this.billOfLadingList[index].BOLRepaymentQuantityError = false;
    //   }
      

    //   // Check if BOLNumber, BOLRepaymentQuantity and BOLRepaymentAmount are also empty
    //   if (
    //     (newBOLNumber == '' || newBOLNumber == null) &&
    //     (newBOLRepaymentQuantity == '' || newBOLRepaymentQuantity == null) &&
    //     (newBOLRepaymentAmount == '' || newBOLRepaymentAmount == null)
    //   ) {
    //     this.billOfLadingList[index].BOLNumberError = false;
    //     this.billOfLadingList[index].BOLRepaymentQuantityError = false;
    //     this.billOfLadingList[index].BOLRepaymentAmountError = false;
    //   }
    // },


    onBillOfLadingSaveInfo() {
      let flag = false;
      // Check if any object has a null field and set error to true
      this.billOfLadingList.forEach(item => {
        if(item.BOLRepaymentAmount == null || item.BOLRepaymentAmount == 0 || item.BOLRepaymentAmount == ''){
          item.BOLRepaymentAmountError = true;
          item.BOLRepaymentAmountErrorMessage = `BOL Repayment Amount is required`;
          flag = true;
          return;
        }
        if(Number(item.BOLRepaymentAmount) > Number(this.RepaymentConvertedAmount)) {
          item.BOLRepaymentAmountError = true;
          item.BOLRepaymentAmountErrorMessage = `BOL Repayment Amount cannot be greater than Converted Repayment Amount ${this.RepaymentConvertedAmount}`;
          flag = true;
          return;
        } else {
          item.BOLRepaymentAmountError = false;
          item.BOLRepaymentAmountErrorMessage = "";
          flag = false;
        }
      });

      if(!flag) {
          this.calcTotalBOLRepaymentAmount();
          let RepaymentConvertedAmount = parseFloat(this.RepaymentConvertedAmount)
          if (this.totalBOLRepaymentAmount > RepaymentConvertedAmount) {
          let warningMessage = `The Sum of Bill Of Lading Repayment Amount should be greater than Converted Repayment Amount: ${this.RepaymentConvertedAmount} ${this.fundCurrencyShortName}`;
          Utils.make_alert("warning", warningMessage);
          this.billOfLadingAddMoreDisabled = true;
        } else {
          Utils.make_alert("success", "Record saved.");
          this.billOfLadingSaveInfoDisabled = false;
          this.billOfLadingAddMoreDisabled = false;
        }
        this.totalBOLRepaymentAmount = 0;
      } else {
        // At least one object has error set to true, show error messages
        this.billOfLadingAddMoreDisabled = true;
      }
    },


    //this method will make disabled property as false for Director Save Info
    onRepaymentDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.RepaymentDocumentSaveInfoDisabled = false;
      this.RepaymentDocumentsAddMoreDisabled = false;
    },

    onChangeRepaymentAmountDateCurrency() {
      if(this.fundCurrencyShortName == null || this.fundCurrencyShortName == ''){
        this.fundCurrencyShortName = this.getCurrencyShortNameById(this.fundCurrency)
      }
      console.log("onChangeRepaymentAmountDateCurrency")
      // if(this.repaymentType != 'Loan'){
      //   this.loanMappingList = [];
      //   this.addLoanMapping();
      //   this.billOfLadingList = [];
      //   this.addBillOfLading();
      // }
    },

    // //this method will make disabled property as false for Loan Mapping Save Info
    // onLoanMappingSaveInfo() {
    //   // Assuming this.loanMappingList is the array of objects and this.loans is the array of loan objects
    //   let flag = false;
    //   let openModalFlag = false;

    //   // Check for duplicates and set the flag and error message
    //   this.loanMappingList.forEach((loan, index) => {
    //     console.log("loanOSAmount",loan.loanOSAmount)
    //     if(Number(loan.loanRepaymentAmount) > Number(this.RepaymentConvertedAmount)){
    //       loan.loanRepaymentAmountError = true;
    //       loan.loanRepaymentAmountErrorMessage = `Repayment Amount cannot be greater than Converted Repayment Amount ${this.RepaymentConvertedAmount}`;
    //       flag = true;
    //       this.excessAmount = null
    //       this.loanMappingID = null
    //       this.loanMappingOSAmount = null
    //       openModalFlag = false;
    //       return;
    //     } else {

    //       loan.loanRepaymentAmountError = false;
    //       loan.loanRepaymentAmountErrorMessage = "";
    //       flag = false;
    //       this.excessAmount = null
    //       this.loanMappingID = null
    //       this.loanMappingOSAmount = null
    //       openModalFlag = false;
    //     }
    //     if(loan.selectedRepaymentFlag == null && Number(loan.loanRepaymentAmount) > Number(loan.loanOSAmount)){
    //       this.excessAmount = (Number(loan.loanRepaymentAmount) - Number(loan.loanOSAmount)).toFixed(2)
    //       let loanData = this.loans.find((col) => col.loan_master_id == loan.selectedLoan)
    //       this.excessSelectedLoanName = loanData.loan_registration_id
    //       this.loanMappingID = index
    //       this.loanMappingOSAmount = loan.loanOSAmount
    //       openModalFlag = true;
    //       return;
    //     } 
    //   });

    //   if(!flag) {
    //       this.calcTotalRepaidAmount();
    //       let RepaymentConvertedAmount = parseFloat(this.RepaymentConvertedAmount)
    //       if (this.totalRepaidAmount > RepaymentConvertedAmount) {
    //         let warningMessage = `The Sum of Repayment Amount (Fund Currency) cannot be greater than Converted Repayment Amount: ${this.RepaymentConvertedAmount} ${this.fundCurrencyShortName}`;
    //         Utils.make_alert("warning", warningMessage);
    //         this.totalRepaidAmount = 0;
    //         return
    //       } else {
    //         this.totalRepaidAmount = 0;
    //         if (!openModalFlag) {
    //           Utils.make_alert("success", "Record saved.");
    //           this.loanMappingSaveInfoDisabled = false;
    //           this.loanMappingAddMoreDisabled = false;
    //         }
    //       }
    //       this.totalRepaidAmount = 0;
    //     } else {
    //     this.loanMappingAddMoreDisabled = true;
    //   }

    //   if (openModalFlag) {
    //     $("#view_doc").modal("show");
    //     return;
    //   }
    // },

    calcTotalBOLRepaymentAmount() {
        // Reset the total amount before calculating
        this.totalBOLRepaymentAmount = 0;
        // Loop over the billOfLadingList array
        for (let list in this.billOfLadingList) {
          // Log the current item for debugging
          // Check if BOLNumber is present
          if (this.billOfLadingList[list].BOLNumber) {
            // Only add to the total if BOLNumber is present
            this.totalBOLRepaymentAmount += parseFloat(
              this.billOfLadingList[list].BOLRepaymentAmount || 0
            );
          }
        }
      },

    calcTotalRepaidAmount() {
      console.log(this.loanMappingList)
      for (let list in this.loanMappingList) {
        console.log(list)
        console.log(this.loanMappingList[list])
        this.totalRepaidAmount += parseFloat(
          this.loanMappingList[list].loanRepaymentAmount
            ? this.loanMappingList[list].loanRepaymentAmount
            : 0
        );
      }
    },

    calcExcessAmount() {
      console.log(this.loanMappingList)
      for (let list in this.loanMappingList) {
        console.log(list)
        console.log(this.loanMappingList[list])
        this.totalRepaidAmount += parseFloat(
          this.loanMappingList[list].loanConvertedRepaymentAmount
            ? this.loanMappingList[list].loanConvertedRepaymentAmount
            : 0
        );
      }
    },

    calculateRepaymentConvertedAmount(){
      this.RepaymentConvertedAmount = (Number(this.RepaymentAmount) * Number(this.RepaymentConversionRate)).toFixed(2);

      // if(this.repaymentType == 'Loan') {
        this.loanMappingList.forEach((loan, index) => {
          loan.loanRepaymentAmount = this.RepaymentConvertedAmount
          loan.loanConvertedRepaymentAmount = (Number(this.RepaymentConvertedAmount)*Number(loan.loanConversionRate)).toFixed(2)
        });
      // }


      // if(Number(loan.loanRepaymentAmount) > Number(loan.loanOSAmount)){
      //   loan.loanRepaymentAmountError = true;
      //   loan.loanRepaymentAmountErrorMessage = `Repayment Amount cannot be greater than Loan Outstanding Amount ${loan.loanOSAmount}`;
      //   return;
      // } else if(Number(loan.loanRepaymentAmount) > Number(this.RepaymentConvertedAmount)){
      // if(Number(loan.loanRepaymentAmount) > Number(this.RepaymentConvertedAmount)){
      //   loan.loanRepaymentAmountError = true;
      //   loan.loanRepaymentAmountErrorMessage = `Repayment Amount cannot be greater than Converted Repayment Amount ${this.RepaymentConvertedAmount}`;
      //   return;
      // } else {
      //   loan.loanRepaymentAmountError = false;
      //   loan.loanRepaymentAmountErrorMessage = "";
      // }
    },

    // onExcessLoanSelectGetDetails(){
    //   console.log("onExcessLoanSelectGetDetails")
    //   if(this.excessSelectedLoanName == null){
    //     this.excessSelectedLoanNameError = true;
    //     return;
    //   }
    // },

    // getLoanOSAmount(index) {
    //   //this function is called to get list of loans
    //   console.log("inside get Loan")
    //   console.log(this.loanMappingList[index].selectedLoan)
    //   let loan = this.loans.find(
    //     (col) => col.loan_master_id === this.loanMappingList[index].selectedLoan)
    //   console.log("Loan",loan)
    //   Utils.start_loading()
    //   const path = "/get_overall_loan_amount/" + loan.loan_master_id;
    //   axios
    //     .get(path, { params: { clientId: this.$store.state.client_id } })
    //     .then(res => {
    //       Utils.stop_loading()
    //       if(res.data.status == "success"){
    //         this.TransactionLevelOutstandingAmount = res.data.loanOSAmount[0].transaction_level_outstanding_amount.replaceAll(",", "");
    //       } else {
    //         this.TransactionLevelOutstandingAmount = null;
    //       }
    //       let loanCurrencyID = loan.currency_id

    //       this.loanMappingList[index].facilityID = loan.facility_setup_id;
    //       if(this.TransactionLevelOutstandingAmount != null){
    //         this.loanMappingList[index].loanOSAmount = this.TransactionLevelOutstandingAmount;
    //         this.calcTotalRepaidAmount();
    //         let RepaymentConvertedAmount = parseFloat(this.RepaymentConvertedAmount)
    //         console.log('RepaymentConvertedAmount',RepaymentConvertedAmount)
    //         console.log('totalRepaidAmount',this.totalRepaidAmount)
    //         console.log('RepaymentConvertedAmount - this.totalRepaidAmount',RepaymentConvertedAmount - this.totalRepaidAmount)
    //         if(RepaymentConvertedAmount > this.totalRepaidAmount){
    //           this.remainderConvertedAmount = RepaymentConvertedAmount - this.totalRepaidAmount
    //           console.log('remainderConvertedAmount',this.remainderConvertedAmount)
    //           console.log('TransactionLevelOutstandingAmount',this.TransactionLevelOutstandingAmount)
    //           console.log('this.remainderConvertedAmount > this.TransactionLevelOutstandingAmount',this.remainderConvertedAmount > this.TransactionLevelOutstandingAmount)
    //           if(this.remainderConvertedAmount > this.TransactionLevelOutstandingAmount) {
    //             console.log("if")
    //             this.loanMappingList[index].loanRepaymentAmount = this.TransactionLevelOutstandingAmount;
    //           } else {
    //             console.log("else")
    //             this.loanMappingList[index].loanRepaymentAmount = this.remainderConvertedAmount;
    //           }
    //         } else {
    //           this.totalRepaidAmount = 0;
    //           this.remainderConvertedAmount = 0;
    //           this.deleteLoanMapping(index);
    //           Utils.make_alert("warning", "Converted Repayment amount is fully utilized");
    //           return;
    //         }
    //         this.totalRepaidAmount = 0;
    //       } else {
    //         this.loanMappingList[index].loanOSAmount = null;
    //       }
    //       this.loanMappingList[index].selectedLoanCurrency = loanCurrencyID;

    //       console.log("inside onLoanSelectGetDetails and getFundCurrencyExchangeRateByCurrencyDate")
    //       this.getloanConversionRateByCurrencyDate(index , this.RepaymentDate)
    //     })
    //     .catch(error => {
    //       Utils.stop_loading()
    //       console.error(error);
    //     });
    // },

    // this method will check if existing loan mapping is added again
    getLoanBackcappingDate(index) {
      console.log("getLoanBackcappingDate")
      let loanID = this.loanMappingList[index].selectedLoan
      console.log("this.loanMappingList[index].selectedLoan",this.loanMappingList[index].selectedLoan)
      
      const path = "/get_loan_backcap_date/" + loanID;
      Utils.start_loading();
      axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
      .then((res) => {
        Utils.stop_loading();
          console.log(res.data.status)
          if (res.data.status == 'success'){
            this.loanMappingList[index].backCapDate = res.data.transaction_date;
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
      
    },

    // this method will check if existing loan mapping is added again
    onLoanSelectGetDetails(index) {
      console.log("onLoanSelectGetDetails")
      // if(this.repaymentType != 'Loan' || this.repaymentType != 'Contract'){
      //   this.isLoanDetailsSubmitted = true;
      //   this.$v.RepaymentAmount.$touch();
      //   this.$v.RepaymentDate.$touch();
      //   this.$v.selectedRepaymentCurrency.$touch();
      //   if (
      //       this.$v.RepaymentAmount.$invalid ||
      //       this.$v.RepaymentDate.$invalid ||
      //       this.$v.selectedRepaymentCurrency.$invalid
      //     ) {
      //       this.loanMappingList[index].selectedLoan = null
      //       // Handle invalid fields (e.g., show an error message)
      //       return;
      //     }
      //   let loan_count = this.loanMappingList.filter(
      //     (col) => col.selectedLoan === this.loanMappingList[index].selectedLoan
      //     ).length;
  
      //   // delete if existing loan is found
      //   if (loan_count > 1) {
      //     this.deleteLoanMapping(index);
      //     Utils.make_alert("warning", "Loan Mapping already exists");
      //   }
      // }
      console.log(this.loanMappingList[index])
      if(this.repaymentType == 'Loan' || this.repaymentType == 'Contract'){
        this.loanMappingList[index].selectedLoan = this.loanID;
      }
      this.loanID = this.loanMappingList[index].selectedLoan
      console.log("inside get Loan")
      console.log(this.loanMappingList[index].selectedLoan)
      // if(this.loanID == null){
      //   return
      // }
      // this.getLoanOSAmount(index)
      //this function is called to get list of loans
      let loan = this.loans.find(
        (col) => col.loan_master_id == this.loanMappingList[index].selectedLoan)
      console.log("Loan",loan)
      let loanCurrencyID = loan.currency_id
      let loanRepaymentDate = this.loanMappingList[index].loanRepaymentDate
      this.loanMappingList[index].facilityID = loan.facility_setup_id;
      if(loan.transaction_level_outstanding_amount != null){
        this.TransactionLevelOutstandingAmount = loan.transaction_level_outstanding_amount.replaceAll(",", "");
      } else {
        this.TransactionLevelOutstandingAmount = null;
      }
      console.log(this.TransactionLevelOutstandingAmount)
      // if(this.repaymentType == 'Logistics') {
      //   if(this.TransactionLevelOutstandingAmount != null){
      //     this.loanMappingList[index].loanOSAmount = this.TransactionLevelOutstandingAmount;
      //     // this.loanMappingList[index].loanRepaymentAmount = this.TransactionLevelOutstandingAmount;
      //   } else {
      //     this.loanMappingList[index].loanOSAmount = '0.00';
      //     // this.loanMappingList[index].loanRepaymentAmount = null;
      //   }
      // }
      // if(this.repaymentType == 'Loan'){
        if(this.TransactionLevelOutstandingAmount != null){
          this.loanMappingList[index].loanOSAmount = this.TransactionLevelOutstandingAmount;
        } else {
          this.loanMappingList[index].loanOSAmount = '0.00';
        }
      // }
      this.loanMappingList[index].selectedLoanCurrency = loanCurrencyID;
      console.log("inside onLoanSelectGetDetails and getFundCurrencyExchangeRateByCurrencyDate")
      this.getloanConversionRateByCurrencyDate(index , loanRepaymentDate)
      this.getLoanBackcappingDate(index)
    },

    getloanConversionRateByCurrencyDate(index , date) {
      //this function is called to get loan currency of that facility
      console.log("inside getloanConversionRateByCurrencyDate")
      let loan = this.loanMappingList.find(
        (col) => col.selectedLoan === this.loanMappingList[index].selectedLoan)
      console.log("loanCurrencyID",loan)
      let loanCurrencyID = loan.selectedLoanCurrency
      console.log("loanCurrencyID",loanCurrencyID)
      let Date = null;
      if (date != null) {
        Date = moment(date).format("MM-DD-YYYY");
      }
      let currencyID = this.fundCurrency;
      console.log("currencyID",currencyID)
      if(this.RepaymentConvertedAmount != null){
        this.loanMappingList[index].loanRepaymentAmount = this.RepaymentConvertedAmount
      }
      if(loanCurrencyID === currencyID){
        this.loanMappingList[index].loanConversionRate = 1;
        this.loanMappingList[index].loanConversionDate = Date;
        this.onloanRepaymentAmountChnage(index);
        return;
      }
      // if(loanCurrencyID == null || currencyID == null || Date == null){
      //   return;
      // }
      const path = "/currency-exchange-rate/" +
        loanCurrencyID +
        "/" +
        currencyID +
        "/" +
        Date
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log(res.data)
          let data = res.data.currency_rate[0]
          if(data != null){
              this.loanMappingList[index].loanConversionRate = data.currency_value;
              this.loanMappingList[index].loanConversionDate = data.date;
              this.onloanRepaymentAmountChnage(index);
            }else{
              Utils.make_alert("warning", "No currency conversion rate available.");
              this.loanMappingList[index].loanConversionRate = null;
              this.loanMappingList[index].loanConversionDate = null;
              this.onloanRepaymentAmountChnage(index);
            }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    getCurrencyExchangeRateDateForRepayment() {
      //this function is called to get fund currency of that facility
      console.log("inside getCurrencyExchangeRateDateForRepayment")
      let repaymentDate = null;
      if (this.RepaymentDate != null) {
        repaymentDate = moment(this.RepaymentDate).format("MM-DD-YYYY");
      }
      if(this.fundCurrency == this.selectedRepaymentCurrency){
        this.RepaymentCurrencyConversionFlag = false
        this.RepaymentConversionRate = 1
        this.calculateRepaymentConvertedAmount()
        return
      }

      if(this.fundCurrency == null || this.selectedRepaymentCurrency == null || repaymentDate == null){
        return;
      }

      const path = "/currency-exchange-rate/" +
        this.fundCurrency +
        "/" +
        this.selectedRepaymentCurrency +
        "/" +
        repaymentDate
        Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log(res.data)
          let data = res.data.currency_rate[0]
          console.log(data)
          if(res.data.status == "success"){
            this.RepaymentCurrencyConversionFlag = true
            let rate =  data.currency_value;
            // let date =  data.date;
            this.RepaymentConversionRate = rate;
            this.calculateRepaymentConvertedAmount()
            } else {
            Utils.make_alert("success", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    getCurrencyExchangeRateDate(index,selectedLoanID,loanOSCurrencyData) {
      //this function is called to get fund currency of that facility
      console.log("inside getCurrencyExchangeRateDate")
      let repaymentDate = null;
      if (this.RepaymentDate != null) {
        repaymentDate = moment(this.RepaymentDate).format("MM-DD-YYYY");
      }

      console.log("loanOSCurrencyData.currency_id ",loanOSCurrencyData.currency_id)
      const path = "/currency-exchange-rate/" +
        this.selectedRepaymentCurrency +
        "/" +
        loanOSCurrencyData.currency_id +
        "/" +
        repaymentDate
        Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log(res.data)
          let data = res.data.currency_rate[0]
          console.log(data)
          if(res.data.status == "success"){
            let rate =  data.currency_value;
            let date =  data.date;
            let osAmount = loanOSCurrencyData.outstanding_amount;
            let selectedLoanCurrency =  loanOSCurrencyData.currency_id;
            let convertedOSAmount = (osAmount*rate).toFixed(2);
            const currency = this.currencies.find((currency) => currency.id == selectedLoanCurrency);
            let selectedLoanCurrencyName = currency.currency_short_name
            this.loanMappingList[index].loanOSAmount = osAmount
            this.loanMappingList[index].selectedLoanCurrency = selectedLoanCurrency
            this.loanMappingList[index].selectedLoanCurrencyName = selectedLoanCurrencyName
            this.loanMappingList[index].loanConversionRate = rate;
            this.loanMappingList[index].loanConversionDate = date;
            this.loanMappingList[index].loanConvertedRepaymentAmount = (osAmount*rate).toFixed(2)
            this.loanMappingList[index].loanRepaymentAmount = null
            // let tempLoanMappingList = []
            // tempLoanMappingList.push({
            //   selectedLoan: null,
            //   loanOSAmount: 10000,
            //   selectedLoanCurrency:2,
            //   selectedLoanCurrencyName:"USD",
            //   loanRepaymentAmount: null,
            //   loanConversionRate: 1.2,
            //   loanConversionDate: null,
            //   loanConvertedRepaymentAmount: 1200,
            // });
            // this.updateLoanMappingList(tempLoanMappingList)
            } else {
            Utils.make_alert("success", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    updateLoanMappingList(tempLoanMappingList) {
    // Assuming tempLoanMappingList is the array of objects received from the API
    this.loanMappingList = tempLoanMappingList.reduce((updatedList, apiData) => {
      const existingItemIndex = updatedList.findIndex((item) => item.selectedLoan === apiData.selectedLoan);

      if (existingItemIndex !== -1) {
        // If the object with the selectedLoan is found in the loanMappingList, update it
        updatedList.splice(existingItemIndex, 1, { ...updatedList[existingItemIndex], ...apiData });
      } else {
        // If the object with the selectedLoan is not found in the loanMappingList, add it
        updatedList.push(apiData);
      }
          return updatedList;
      }, [...this.loanMappingList]);
    },

    removeRepaymentDocuments(id) {
      this.RepaymentDocumentFile = null;
      this.RepaymentDocuments[id].selectedFile = null;
      this.RepaymentDocuments[id].selectedFileName = null;
      this.RepaymentDocuments[id].displayFileName = null;
      this.RepaymentDocuments[id].comments = null;
      this.RepaymentDocuments[id].RepaymentDocumentsRemoveDisabled = true;
    },

    deleteRepaymentDocuments(index) {
      this.RepaymentDocumentFile = this.RepaymentDocumentFile
        ? this.RepaymentDocumentFile
        : "file deleted";
      this.RepaymentDocuments.splice(index, 1);
      if (
        typeof this.RepaymentDocuments !== "undefined" &&
        this.RepaymentDocuments.length == 0
      ) { 
        this.RepaymentDocumentsAddMoreDisabled = false;
        this.RepaymentDocumentSaveInfoDisabled = true;
      }
    },

    deleteBillOfLading(index) {
      this.billOfLadingList.splice(index, 1);
      if (
        typeof this.billOfLadingList !== "undefined" &&
        this.billOfLadingList.length === 0
      ) {
        this.billOfLadingAddMoreDisabled = false;
        this.billOfLadingSaveInfoDisabled = true;
      }
    },
    deleteLoanMapping(index) {
      this.loanMappingList.splice(index, 1);
      if (
        typeof this.loanMappingList !== "undefined" &&
        this.loanMappingList.length === 0
      ) {
        this.loanMappingAddMoreDisabled = false;
        this.loanMappingSaveInfoDisabled = true;
      }
    },

    onCloseModal() {
      console.log("onCloseModal");
      this.selectedOption = 'prepaidRepayment';
      this.excessAmount = null;
      this.loanMappingID = null;
      this.loanMappingOSAmount = null;
      this.excessSelectedLoanName = null;
      this.excessAmountFlag = false;
      $("#view_doc").modal("hide");

    },
    
    updateToggle(value) {
      this.selectedOption = value;
    },

    onSubmitModal() {
      console.log("onSubmitModal");
      this.excessSelectedLoanName = null;
      if (this.selectedOption == 'profitReturn'){
        console.log("onSubmitModal selectedOption if",this.selectedOption);
        this.loanMappingList[this.loanMappingID].profitReturnRepaymentAmount = this.excessAmount;
        this.loanMappingList[this.loanMappingID].prepaidRepaymentAmount = null;
        this.loanMappingList[this.loanMappingID].selectedRepaymentFlag = this.selectedOption;
        this.loanMappingAddMoreDisabled = false;
        this.loanMappingSaveInfoDisabled = true;
        $("#view_doc").modal("hide");
        Utils.make_alert("warning", "Loan Mapping Done Successfully");
        return;
      } else if (this.selectedOption == 'prepaidRepayment'){
        console.log("onSubmitModal selectedOption if",this.selectedOption);
        this.loanMappingList[this.loanMappingID].prepaidRepaymentAmount = this.excessAmount;
        this.loanMappingList[this.loanMappingID].profitReturnRepaymentAmount = null;
        this.loanMappingList[this.loanMappingID].selectedRepaymentFlag = this.selectedOption;
        this.loanMappingAddMoreDisabled = false;
        this.loanMappingSaveInfoDisabled = true;
        $("#view_doc").modal("hide");
        Utils.make_alert("warning", "Loan Mapping Done Successfully");
        return;
      } 
      // else {
      //   console.log("onSubmitModal selectedOption else",this.selectedOption);
      //   this.loanMappingList[this.loanMappingID].selectedRepaymentFlag = this.selectedOption;

      //   // Extract all loan IDs from the loans array
      //   const loanIds = this.loans.map(loan => loan.loan_master_id);
      //   console.log("loanIds",loanIds)

      //   // Extract all selectedLoan values from the loanMappingList array
      //   const selectedLoansInMapping = this.loanMappingList.map(item => item.selectedLoan);
      //   console.log("selectedLoansInMapping",selectedLoansInMapping)

      //   // Check if there are any loan IDs not present in selectedLoansInMapping
      //   const missingLoanIds = loanIds.filter(loan_master_id => !selectedLoansInMapping.includes(loan_master_id));
      //   console.log("missingLoanIds",missingLoanIds)
      //   if (missingLoanIds.length === 0) {
      //     console.log("All available loan are already mapped")
      //     Utils.make_alert("warning", "All available loan are already mapped");
      //     this.selectedOption = 'prepaidRepayment';
      //   } else {
      //     console.log("add new loan")
      //     let calculatedRepaymentAmount = (Number(this.loanMappingList[this.loanMappingID].loanRepaymentAmount) - Number(this.excessAmount)).toFixed(2)
      //     console.log("calculatedRepaymentAmount",calculatedRepaymentAmount)
      //     this.loanMappingList[this.loanMappingID].loanRepaymentAmount = calculatedRepaymentAmount;
      //     this.onloanRepaymentAmountChnage(this.loanMappingID);
      //     if(this.loanMappingOSAmount == 0){
      //       this.deleteLoanMapping(this.loanMappingID);
      //     }
      //     // If you suspect that the change is not reactive, you can force update
      //     // this.$forceUpdate();
      //     this.addLoanMapping();
      //     $("#view_doc").modal("hide");
      //   }
      // }
    },

    onSubmit() {
      console.log("submit")
      this.isSubmitted = true;
      this.isLoanDetailsSubmitted = true;
      
      // Check if any object has error set to true
      
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.totalRepaidAmount = 0;
        this.totalBOLRepaymentAmount = 0;
        return;
      }
      console.log("field validation done")
      if (this.loanMappingList.length == 0) {
        let warningMessage = `Please Map Some loan`;
        Utils.make_alert("warning", warningMessage);
        return;
      }

      let flag = false;
      let openModalFlag = false;

      // Check for duplicates and set the flag and error message
      this.loanMappingList.forEach((loan, index) => {
        console.log("loanOSAmount",loan.loanOSAmount)
        // if(Number(loan.loanRepaymentAmount) > Number(this.RepaymentConvertedAmount)){
        //   loan.loanRepaymentAmountError = true;
        //   loan.loanRepaymentAmountErrorMessage = `Repayment Amount cannot be greater than Converted Repayment Amount ${this.RepaymentConvertedAmount}`;
        //   flag = true;
        //   this.excessAmount = null
        //   this.loanMappingID = null
        //   this.loanMappingOSAmount = null
        //   openModalFlag = false;
        //   return;
        // } else {
        //   loan.loanRepaymentAmountError = false;
        //   loan.loanRepaymentAmountErrorMessage = "";
        //   flag = false;
        //   this.excessAmount = null
        //   this.loanMappingID = null
        //   this.loanMappingOSAmount = null
        //   openModalFlag = false;
        // }
        if(loan.selectedRepaymentFlag == null && Number(loan.loanRepaymentAmount) > Number(loan.loanOSAmount)){
          this.excessAmount = (Number(loan.loanRepaymentAmount) - Number(loan.loanOSAmount)).toFixed(2)
          let loanData = this.loans.find((col) => col.loan_master_id == loan.selectedLoan)
          this.excessSelectedLoanName = loanData.loan_registration_id
          this.loanMappingID = index
          this.loanMappingOSAmount = loan.loanOSAmount
          openModalFlag = true;
          this.excessAmountFlag = true;
          return;
        } 
      });

      console.log("openModalFlag",flag)
      console.log("flag",flag)

      // if(!flag) {
      //     this.calcTotalRepaidAmount();
      //     let RepaymentConvertedAmount = parseFloat(this.RepaymentConvertedAmount)
      //     console.log("this.totalRepaidAmount",this.totalRepaidAmount)
      //     console.log("this.RepaymentConvertedAmount",RepaymentConvertedAmount)
      //     if (this.totalRepaidAmount > RepaymentConvertedAmount) {
      //       let warningMessage = `The Sum of Repayment Amount (Fund Currency) cannot be greater than Converted Repayment Amount: ${this.RepaymentConvertedAmount} ${this.fundCurrencyShortName}`;
      //       Utils.make_alert("warning", warningMessage);
      //       return
      //     } else if (this.totalRepaidAmount != RepaymentConvertedAmount) {
      //       let warningMessage = `The Sum of Repayment Amount (Fund Currency) should be equal to Converted Repayment Amount: ${this.RepaymentConvertedAmount} ${this.fundCurrencyShortName}.<br/> Kindly add another loan of the same facility to utilize the complete Conv. Repayment Amount`;
      //       Utils.make_alert("warning", warningMessage);
      //       this.loanMappingSaveInfoDisabled = false;
      //       this.loanMappingAddMoreDisabled = false;
      //       return
      //     } else {
      //       this.loanMappingSaveInfoDisabled = false;
      //       this.loanMappingAddMoreDisabled = false;
      //     }
      //     this.totalRepaidAmount = 0;
      //   } else {
      //   this.loanMappingAddMoreDisabled = true;
      // }

      if (openModalFlag) {
        $("#view_doc").modal("show");
        return;
      }

      let flagBOL = false;
      // Check if any object has a null field and set error to true
      this.billOfLadingList.forEach(item => {
        // If BOLNumber is present, perform the checks
        console.log("item.BOLRepaymentAmount",item.BOLRepaymentAmount)
        console.log("item.BOLNumber",item.BOLNumber)
        if (item.BOLNumber) {
          if(item.BOLRepaymentAmount == null || item.BOLRepaymentAmount == 0 || item.BOLRepaymentAmount == ''){
            item.BOLRepaymentAmountError = true;
            item.BOLRepaymentAmountErrorMessage = `BOL Repayment Amount is required`;
            flagBOL = true;
            return;
          }
          if (Number(item.BOLRepaymentAmount) > Number(this.RepaymentConvertedAmount)) {
            item.BOLRepaymentAmountError = true;
            item.BOLRepaymentAmountErrorMessage = `BOL Repayment Amount cannot be greater than Converted Repayment Amount ${this.RepaymentConvertedAmount}`;
            flagBOL = true; // Set the flag to true if there's an error
            return;
          } else {
            item.BOLRepaymentAmountError = false;
            item.BOLRepaymentAmountErrorMessage = "";
            // Do not reset flagBOL to false here, as it should remain true if any previous items had errors
          }
        }
      });

      console.log("flagBOL",flagBOL)

      if(!flagBOL) {
          this.calcTotalBOLRepaymentAmount();
          let RepaymentConvertedAmount = parseFloat(this.RepaymentConvertedAmount)
          if (this.totalBOLRepaymentAmount > RepaymentConvertedAmount) {
          let warningMessage = `The Sum of Bill Of Lading Repayment Amount should be greater than Converted Repayment Amount: ${this.RepaymentConvertedAmount} ${this.fundCurrencyShortName}`;
          Utils.make_alert("warning", warningMessage);
          this.billOfLadingAddMoreDisabled = true;
          return;
        } else {
          this.billOfLadingSaveInfoDisabled = false;
          this.billOfLadingAddMoreDisabled = false;
        }
        this.totalBOLRepaymentAmount = 0;
      } else {
        // At least one object has error set to true, show error messages
        this.billOfLadingAddMoreDisabled = true;
      }

      console.log("DONE")

      let repaymentDate = null;
      if (this.RepaymentDate != null) {
        repaymentDate = moment(this.RepaymentDate).format("MM-DD-YYYY");
      }
      this.billOfLadingList.map((doc, index) => {
        if (doc.BOLNumber === null) this.billOfLadingList.splice(index, 1);
      });
      this.RepaymentDocuments.map((doc, index) => {
        if (doc.displayFileName === null) this.RepaymentDocuments.splice(index, 1);
      });

      for (let i = 0; i < this.loanMappingList.length; i++) {
        if (this.loanMappingList[i].loanRepaymentDate !== null) {
          this.loanMappingList[i].loanRepaymentDate = moment(
            this.loanMappingList[i].loanRepaymentDate
          ).format("MM-DD-YYYY");
        }
        if (this.loanMappingList[i].loanConversionDate !== null) {
          this.loanMappingList[i].loanConversionDate = moment(
            this.loanMappingList[i].loanConversionDate
          ).format("MM-DD-YYYY");
        }
      }

      if (this.repaymentType == 'Contract'){
        this.repaymentDoneTwice = '1'
      } else {
        this.repaymentDoneTwice = '0'
      }

      console.log("selectedContractNumber",this.selectedContractNumber)
      console.log("selectedPartialContractNumber",this.selectedPartialContractNumber)
      console.log("TotalContractQuantity",this.TotalContractQuantity)
      console.log("Quantity",this.Quantity)
      console.log("selectedUnit",this.selectedUnit)
      console.log("selectedCollateral",this.selectedCollateral)
      console.log("ContractDate",this.ContractDate)
      console.log("PartialContractDate",this.PartialContractDate)
      console.log("repaymentFlag",this.repaymentFlag)
      console.log("repaymentDoneTwice",this.repaymentDoneTwice)

      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("selectedBorrower", this.selectedBorrower);
      formData.append("selectedFacility", this.selectedFacility);
      formData.append("selectedOfftakerID", this.selectedOfftakerID);
      formData.append("selectedContractNumber", this.selectedContractNumber);
      formData.append("selectedPartialContractNumber", this.selectedPartialContractNumber);
      formData.append("Quantity", this.Quantity);
      formData.append("selectedUnit", this.selectedUnit);
      
      formData.append("loanID", this.loanID);
      formData.append("repaymentDoneTwice", this.repaymentDoneTwice);
      
      formData.append("profitReturnID", this.profitReturnID);
      formData.append("transferProfitReturnAmount", this.transferProfitReturnAmount);
      formData.append("billOfLadingList", JSON.stringify(this.billOfLadingList));
      
      //append loan mapping information to formdata
      formData.append("RepaymentAmount", this.RepaymentAmount);
      formData.append("RepaymentDate", repaymentDate);
      formData.append("RepaymentConversionRate", this.RepaymentConversionRate);
      formData.append("selectedRepaymentCurrency", this.selectedRepaymentCurrency);
      formData.append("fundCurrency", this.fundCurrency);
      formData.append("RepaymentConvertedAmount", this.RepaymentConvertedAmount);
      formData.append("loanMappingList", JSON.stringify(this.loanMappingList));

      // profit return
      // formData.append("fullLoanMappingDisabled", this.fullLoanMappingDisabled);
      // formData.append("excessAmount", this.excessAmount);
      // formData.append("excessSelectedLoanName", this.excessSelectedLoanName);
      // formData.append("excessSelectedCurrency", this.excessSelectedCurrency);
      // formData.append("excessConversionRate", this.excessConversionRate);

      // bank
      formData.append("selectedBank", this.selectedBank);
      formData.append("bic", this.bic);
      formData.append("iban", this.iban);
      formData.append("accountNumber", this.accountNumber);
      formData.append("accountName", this.accountName);
      formData.append("reference", this.reference);

      //append RepaymentDocuments information to formdata
      formData.append("RepaymentDocuments", JSON.stringify(this.RepaymentDocuments));
      
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 4);
      formData.append("tabId", 35);
      const path = "offtaker-repayment"
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((res) => {
          Utils.stop_loading();
          console.log("Status",res.data.status)
          if (res.data.status == "success") {
            let offtaker_repayment_id = res.data.offtaker_repayment_id;
            this.deductionAmountAPI(offtaker_repayment_id);
          } else {
            Utils.make_alert("success", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
          Utils.make_alert("success", "Something went wrong!");
        });
    }, //onSubmit() close

    deductionAmountAPI(offtaker_repayment_id){
      console.log("deductionAmountAPI",offtaker_repayment_id)
      // For triggering payment when we chnage status
      const path = "deduction_amount/" + null + "/" + offtaker_repayment_id;
          Utils.start_loading();
          axios
            .get(path, { params: { clientId: this.$store.state.client_id } })
            .then((res) => {
              console.log(res)
              console.log("deductionAmountAPI data",res.data)
              console.log("deductionAmountAPI Status",res.data.status)
              Utils.stop_loading();
              if(res.data.status == 'success') {
                let loanIDs = res.data.loan_ids
                console.log("loanIDs",loanIDs)
                if(loanIDs.length > 0) {
                  this.getReportLoanTransaction(loanIDs);
                } else {
                  Utils.make_alert(
                    "success",
                    "Repayment Details saved successfully!"
                  );
                  console.log("NO LOAN ID SUCCESS!!");
                  this.$router.push("/loan-util-overview-by-id/" + this.selectedBorrower + "/" + this.loanID);
                  // if(this.repaymentType == "Logistics"){
                  //   this.$router.push("/offtaker");
                  // } else if (this.repaymentType == "Direct Shipping" || this.repaymentType == "Normal Shipping"){
                  //   this.$router.push("/offtaker");
                  // } else {
                  //   this.$router.push("/loan-util-overview-by-id/" + this.selectedBorrower + "/" + this.loanID);
                  // }
                }
              }
            })
            .catch((error) => {
              Utils.stop_loading();
              //this is when response is not received from server
              console.error("error: " + error);
              Utils.make_alert("warning", "Something went wrong!");
            });
    },

    getReportLoanTransaction(loanIDs){
      console.log("getReportLoanTransaction")
      const path = "/report_loan_transaction/" + loanIDs + "/" + this.$store.state.client_id;
      Utils.start_loading();
      axios
      .post(path)
      .then((res) => {
        Utils.stop_loading();
        if (res.data.status == "success") {
          Utils.make_alert(
            "success",
            "Repayment Details saved successfully!"
          );
          console.log("SUCCESS!!");
          this.$router.push("/loan-util-overview-by-id/" + this.selectedBorrower + "/" + this.loanID);
          // if(this.repaymentType == "Logistics"){
          //   this.$router.push("/offtaker");
          // } else if (this.repaymentType == "Direct Shipping" || this.repaymentType == "Normal Shipping"){
          //   this.$router.push("/offtaker");
          // } else {
          //   this.$router.push("/loan-util-overview-by-id/" + this.selectedBorrower + "/" + this.loanID);
          // }
        } else {
          Utils.make_alert("success", "Something went wrong!");
          console.log("FAILURE!!");
        }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    onloanRepaymentAmountChnage(index){
      let amount = this.loanMappingList[index].loanRepaymentAmount;
      let rate = this.loanMappingList[index].loanConversionRate;
      this.loanMappingList[index].loanConvertedRepaymentAmount = (Number(amount)*Number(rate)).toFixed(2)
    },

    onChangeRepaymentAmountValidation(index){
      this.onloanRepaymentAmountChnage(index);
      console.log("onChangeRepaymentAmountValidation")
      let loanRepaymentAmount = this.loanMappingList[index].loanRepaymentAmount ;
      let loanOSAmount = this.loanMappingList[index].loanOSAmount ;
      // if(Number(loanRepaymentAmount) > Number(loanOSAmount)){
        //   this.loanMappingList[index].loanRepaymentAmountError = true;
        //   this.loanMappingList[index].loanRepaymentAmountErrorMessage = `Repayment Amount cannot be greater than Loan Outstanding Amount ${loanOSAmount}`;
        //   return;
        // } else if(Number(loanRepaymentAmount) > Number(this.RepaymentConvertedAmount)){
        if(Number(loanRepaymentAmount) > Number(this.RepaymentConvertedAmount)){
          this.loanMappingList[index].loanRepaymentAmountError = true;
          this.loanMappingList[index].loanRepaymentAmountErrorMessage = `Repayment Amount cannot be greater than Converted Repayment Amount ${this.RepaymentConvertedAmount}`;
          return;
        } else {
          this.loanMappingList[index].loanRepaymentAmountError = false;
          this.loanMappingList[index].loanRepaymentAmountErrorMessage = "";
        }
    },

    //this method will add new line for additional Cost Documents
    addRepaymentDoc() {
      //when new line is added then RepaymentDocumentFile variable and RepaymentDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.RepaymentDocumentFile = null),
        (this.RepaymentDocumentSaveInfoDisabled = true),
        (this.RepaymentDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.RepaymentDocuments.push({
          displayFileName: null,
          comments: null,
          errors: { filesize: "" },
          RepaymentDocumentsRemoveDisabled:true,

        });
    }, //addRepaymentDoc() close

    getCurrencyShortNameById(id) {
      if (id == null) {
        return '';
      }
      console.log(this.currencies)
      const currency = this.currencies.find((currency) => currency.id == id);
      return currency ? currency.currency_short_name : '';
    },

    //this method will add new line for additional loan mapping
    addBillOfLading() {
      //when new line is added then billOfLadingAddMoreDisabled (Save info button disable property) should be re initialised to true.
        this.billOfLadingAddMoreDisabled = true;
        this.billOfLadingSaveInfoDisabled = false;
        //following block is created first time on mounted property
        this.billOfLadingList.push({
          BOLNumber: null,
          ShippingID: null,
          BOLQuantity: null,
          // BOLRepaymentQuantity :null,
          BOLRepaymentAmount: null,
          DBExistFlag: true,
          BOLNumberError:false,
          // BOLRepaymentQuantityError:false,
          BOLRepaymentAmountError:false,
          BOLRepaymentAmountErrorMessage:'',
        });
    }, //addBillOfLading() close


    //this method will add new line for additional loan mapping
    addLoanMapping() {
      console.log("inside addLoanMapping")
        this.loanMappingSaveInfoDisabled = true;
        this.loanMappingAddMoreDisabled = true;
        this.loanMappingList.push({
          selectedRepaymentFlag:null,
          selectedLoan: null,
          facilityID: null,
          loanOSAmount: null,
          loanRepaymentDate: new Date(),
          selectedLoanCurrency:null,
          loanRepaymentAmount: null,
          loanConversionRate: null,
          loanConversionDate: null,
          backCapDate: new Date(),
          loanConvertedRepaymentAmount: null,
          prepaidRepaymentAmount: null,
          profitReturnRepaymentAmount: null,
          loanRepaymentAmountError: false,
          loanRepaymentAmountErrorMessage: "",
        });
    }, //addLoanMapping() close

    //handles a change on the file upload
    handleFileUploadRepaymentDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.RepaymentDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.RepaymentDocumentFile.size > 104857600) {
          e.preventDefault();
          self.RepaymentDocuments[id].errors.filesize =
            "File size should be less than 100MB";
          self.RepaymentDocuments[id].selectedFile = null;
          self.RepaymentDocuments[id].selectedFileName = null;
          self.RepaymentDocuments[id].displayFileName = null;
          self.RepaymentDocuments[id].RepaymentDocumentsRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.RepaymentDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.RepaymentDocuments[id].selectedFile = reader.result;
          self.RepaymentDocuments[id].selectedFileName = e.target.files[0].name;
          self.RepaymentDocuments[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.RepaymentDocuments[id].errors.filesize = "";
          self.RepaymentDocuments[id].RepaymentDocumentsRemoveDisabled = false;

        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadRepaymentDocuments() close

    getLoan() {
      console.log("get Loan")
      // if(this.repaymentType == 'Loan'){
        const path = "/get-loan-by-facilities/" + this.selectedFacility + "/" + "repayment";
        // const path = "/get-loan-by-facilities/" + this.selectedFacility + "/" + "loan";
        console.log("get Loan",path)
        axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
          .then(res => {
            Utils.stop_loading()
            if(res.data.status == "success"){
              this.loans = res.data.loan_list;
              this.loanMappingList = [];
              this.addLoanMapping();   
              if (this.profitReturnID){
                this.RepaymentAmount = this.transferProfitReturnAmount;
                this.calculateRepaymentConvertedAmount();
              }
              this.onLoanSelectGetDetails(0);
            }
          })
          .catch(error => {
            Utils.stop_loading()
            console.error(error);
          });
      // }
      // else if (this.repaymentType == 'Logistics'){
      //   const path = "/get-loan-by-contract/" + 
      //   this.selectedContractNumber + 
      //   "/" + 
      //   this.selectedPartialContractNumber
      //   console.log("get Loan",path)
      //   axios
      //     .get(path, { params: { clientId: this.$store.state.client_id } })
      //     .then(res => {
      //       Utils.stop_loading()
      //       if(res.data.status == "success"){
      //         this.loans = res.data.loan_list;
      //         this.loanMappingList = [];
      //         this.addLoanMapping();
      //         this.getBOLByContractNumber();
      //       }
      //     })
      //     .catch(error => {
      //       Utils.stop_loading()
      //       console.error(error);
      //     });
      // }
    },

    getBOLByContractNumber(){
      if(this.selectedContractNumber == null){
        this.BillOfLadingArray = [];
        this.billOfLadingList = [];
        this.addBillOfLading();
        return
      }
      const path = "/get-bol-by-contract-number/" + 
        this.selectedContractNumber + 
        "/" + 
        this.selectedPartialContractNumber;
      // const path = "/get-bol-by-contract-number/" + 'CN01'
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then(res => {
            Utils.stop_loading()
            if(res.data.status == "success"){
              this.BillOfLadingArray = res.data.bol_no;
              this.billOfLadingList = [];
              this.addBillOfLading();
            }
          })
          .catch(error => {
            Utils.stop_loading()
            console.error(error);
          });
    },

    // getLoan() {
    //   //this function is called to get list of loans
    //   console.log("inside get Loan")
    //   const path = "/get-loan-by-facility/" + this.selectedFacility;
    //   axios
    //     .get(path, { params: { clientId: this.$store.state.client_id } })
    //     .then(res => {
    //       this.loanMappingList = [];
    //       this.addLoanMapping();
    //       this.loans = res.data.loans;
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // },

    getConversionDateBackcap() {
      //this function is called to get list of day count
      const path = "/get-conversion-date-by-facility/" + this.selectedFacility;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.conversionBackCapDate = res.data.conversionBackCapDate[0].facility_request_date;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFacilitySetupByBorrower() {
      //this function is called to get list of day count
      const path = "/facility-setup-borrower/" + this.selectedBorrower;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // console.log(res.data.Facility_Setup);
          this.facilities = res.data.Facility_Setup;
          // this.selectedFacility = null;
          // this.selectedContractNumber = null;
          // this.loanMappingList = [];
          // this.addLoanMapping();
          this.getConversionDateBackcap();
          this.getLoan();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getOfftakerNameByBorrower() {
      console.log("inside get getOfftakerNameByBorrower");
      // const path = "/get-offtaker-name-by-borrower/" + this.selectedBorrower;
      const path = 
      "/get-offtaker-name-by-borrower" + 
      "/" + 
      this.selectedBorrower +
      "/" +
      this.loanID +
      "/" +
      'Offtaker';

      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          // this.selectedOfftakerID = null;
          // this.selectedContractNumber = null;
          this.offtakerNameIDs = res.data.offtaker_names;
          if(this.repaymentType != 'Loan'){
            this.getContractNumberByOfftakerName()
          }
        })
        .catch(error => {
          console.error(error);
        });
    },

    getContractNumberByOfftakerName() {
      //this function is called to get list of LTV
      console.log("inside get getContractNumberByOfftakerName")
      // const path = "/get-contract-no-by-offtaker/" + this.selectedOfftakerID + "/" + this.selectedBorrower;
      if(this.selectedOfftakerID == null) {
          this.selectedContractNumber = null;
          this.contractNumber = [];
          this.selectedPartialContractNumber = null;
          this.partialContractNumber = [];
          this.ContractDate = null;
          this.PartialContractDate = null;
          this.Quantity = null;
          this.selectedUnit = null;
          this.selectedCollateral = null;
          this.TotalContractQuantity = null;
          this.repaymentFlag = '0';
          this.getBOLByContractNumber();
          return
      }
      const path = 
        "/get-contract-no-by-offtaker" + 
        "/" + 
        this.selectedOfftakerID +
        "/" + 
        this.selectedBorrower +
        "/" +
        null +
        "/" +
        this.contractType;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          // this.selectedContractNumber = null;
          this.contractNumber =  res.data.contract_numbers;
          console.log("this.contractNumber",this.contractNumber)
          if(this.repaymentType == "Normal Shipping"){
            this.contractNumber = this.contractNumber.filter(contract => this.contractNumberList.includes(contract.contractId))
          }
          if(this.repaymentType == 'Logistics' || this.repaymentType == "Direct Shipping" || this.repaymentType == "Contract"){
            this.getContractNumberDetails()
          }
          // if(this.repaymentType == 'Logistics'){
          //   this.loanMappingList = [];
          //   this.addLoanMapping();
          // }
        })
        .catch(error => {
          console.error(error);
        });
    },

     //this function is called to get list of contract numbers
    getContractNumberDetails() {
      console.log("inside get getContractNumberDetails")
      if(this.selectedContractNumber == null){
        this.selectedPartialContractNumber = null;
        this.partialContractNumber = [];
        this.ContractDate = null;
        this.PartialContractDate = null;
        this.Quantity = null;
        this.selectedUnit = null;
        this.selectedCollateral = null;
        this.TotalContractQuantity = null;
        this.repaymentFlag = '0';
        this.getBOLByContractNumber();
        return
      }
      let selectedContract = this.contractNumber.find(contract => contract.contractId === this.selectedContractNumber);
      console.log("selectedContract",selectedContract)
      this.Quantity = selectedContract.ContractQuantity;
      this.selectedUnit = selectedContract.unit;
      if(this.repaymentType == 'Loan'|| this.repaymentType == "Normal Shipping"){
        console.log("making partial null")
        this.selectedPartialContractNumber = null;
        this.partialContractNumber = [];
      }
      console.log("selectedPartialContractNumber",this.selectedPartialContractNumber)
      this.ContractDate = selectedContract.ContractDate;
      this.PartialContractDate = null;
      this.ContractQuantity = selectedContract.ContractQuantity;
      this.Quantity = selectedContract.ContractQuantity;
      this.selectedUnit = selectedContract.unit;
      this.selectedCollateral = selectedContract.collateral_id;
      this.TotalContractQuantity = selectedContract.TotalContractQuantity;
      this.repaymentFlag = selectedContract.repayment_flag;
      if(this.Quantity == 0){
        this.repaymentFlag = '1';
      }
      this.getBOLByContractNumber();

      // if(this.selectedPartialContractNumber != null ){
      Utils.start_loading();
      // const path = "/get-partial-contract-no/" + this.selectedContractNumber;
      const path = 
        "/get-partial-contract-no" + 
        "/" + 
        this.selectedContractNumber +
        "/" +
        null +
        "/" +
        this.contractType;
        axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          Utils.stop_loading();
          console.log("status",res.data.status)
          if(res.data.status == "success") {
            this.partialContractNumber = res.data.partial_contracts;
            if(this.repaymentType == "Normal Shipping"){
              this.partialContractNumber = this.partialContractNumber.filter(contract => this.partialContractNumberList.includes(contract.partialContractId))
              // Check if the filter operation found any matching contracts
              if (this.partialContractNumber.length > 0) {
                  this.repaymentFlag = 1;
                }
              return;
              }
              if(this.repaymentType != 'Loan'){
                if((this.repaymentType == 'Logistics' || this.repaymentType == "Direct Shipping" || this.repaymentType == "Contract") && (this.selectedPartialContractNumber != null)){
                  console.log("partial getting called as selectedPartialContractNumber is not null")
                  this.getPartialContractNumberDetails()
                } else {
                  console.log(" selectedPartialContractNumber is  null")
                  this.partialContractNumber = [];
                  this.PartialContractDate = null;
                  this.repaymentFlag = 0;
              }
              }
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          console.error(error);
        });
      // }
    },

    //this function is called to get list of contract numbers
    getPartialContractNumberDetails() {
      console.log("inside get getPartialContractNumberDetails")
      if(this.selectedPartialContractNumber == null){
        let selectedContract = this.contractNumber.find(contract => contract.contractId === this.selectedContractNumber);
        console.log("selectedContract",selectedContract)
        this.partialContractNo = null;
        this.ContractDate = selectedContract.ContractDate;
        this.PartialContractDate = null;
        this.ContractQuantity = selectedContract.ContractQuantity;
        this.Quantity = selectedContract.ContractQuantity;
        this.selectedUnit = selectedContract.unit;
        this.selectedCollateral = selectedContract.collateral_id;
        this.TotalContractQuantity = selectedContract.TotalContractQuantity;
        this.repaymentFlag = selectedContract.repayment_flag;
        this.getBOLByContractNumber();
        return;
      }
      console.log("selectedPartialContractNumber",this.selectedPartialContractNumber)
      let selectedPartialContract = this.partialContractNumber.find(partialContract => partialContract.partialContractId === this.selectedPartialContractNumber);
      console.log("selectedPartialContract",selectedPartialContract)
      this.PartialContractDate = selectedPartialContract.PartialContractDate;
      this.Quantity = selectedPartialContract.PartialContractQuantity;
      this.getBOLByContractNumber();
    },


    // getContractNumberByOfftakerName() {
    //   //this function is called to get list of LTV
    //   console.log("inside get getContractNumberByOfftakerName")

    //   const path = "/get-offtaker-contract-detail/" + this.selectedOfftakerID;
    //   axios
    //     .get(path, { params: { clientId: this.$store.state.client_id } })
    //     .then(res => {
    //       this.selectedContractNumber = null;
    //       this.ContractNumber = res.data.offtaker_contract;
    //       this.loanMappingList = [];
    //       this.addLoanMapping();
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // },

    getQuantityUnitByContractNumber(){
      //this function is called to get list of LTV
      console.log("inside get getQuantityUnitByContractNumber")

      let contractNo = this.contractNumber.find((loan) => loan.contract_id === this.selectedContractNumber);
      console.log(contractNo)
      if(contractNo.length > 0){
        this.Quantity = contractNo.total_quantity;
        this.selectedUnit = contractNo.unit_id;
      }


      // const path = "/get-quantity-unit-by-contract/" + this.selectedContractNumber;
      // axios
      //   .get(path, { params: { clientId: this.$store.state.client_id } })
      //   .then(res => {
      //     this.loanMappingList = [];
      //     this.addLoanMapping();
      //     this.Quantity = res.data.quantity;
      //     this.selectedUnit = res.data.unit;
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
    },


    

    getBorrowers() {
      const path = "borrower-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.borrowers = res.data.borrowers;
          if (this.loggedInUserGroupId === "Borrower") {
            this.borrowers.filter((bor) => {
              if (bor.borrower_id.toString() === this.loggedInUserSubGroupId)
                this.selectedBorrower = bor.borrower_id;
                this.getFacilitySetupByBorrower();
              });
            }
            this.getFacilitySetupByBorrower();
            this.getOfftakerNameByBorrower();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of benchmarks
    getUnitTypes() {
      const path = "unit_types";
      axios
        .get(path)
        .then((res) => {
          this.UnitTypes = res.data.UnitTypes;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBanks() {
      //this function is called to get list of banks
      const path = "bank-detail";
      axios
        .get(path)
        .then((res) => {
          console.log(res)
          console.log(res.data.bank_details)
          this.banks = res.data.bank_details;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close

  watch: {
      excessAmount(newVal, oldVal) {
        console.log('excessAmount changed from', oldVal, 'to', newVal);
      },
    },

  mounted() {
    this.addBillOfLading();
    // this.addLoanMapping();
    this.addRepaymentDoc();

  },

  created() {
    let data = this.$route.params;
    if(!data.repaymentType){
      // Utils.make_alert("warning", "Something went wrong!");
      this.$router.go(-1);
      return;
    }
    this.getCurrencies();
    this.getUnitTypes();
    this.getBanks();
    let data1 = {
        "id": 0,
        "repaymentType": "Loan",
        // "repaymentType": "Logistics",
        // "repaymentType": "Normal Shipping",
        // "repaymentType": "Direct Shipping",
        // "repaymentType": "Contract",
        "contractType":"Offtaker",
        // "contractType":"Shipping",
        // "contractType":"Contract",
        "loanID": "879",
        // "transferProfitReturnAmount": "700",
        // "profitReturnID": 700,
        "selectedBorrower": "943",
        "selectedFacility": 790,
        "selectedOfftakerID": 319,
        "selectedContractNumber": 157,
        "selectedPartialContractNumber": 90,
        "fundCurrency": 1,        
        // "selectedContractNumber": null,
        // "selectedPartialContractNumber": null,
        // "contractNumberList": [
        // 157,
        // ],
        // "partialContractNumberList": [
        // 90,
        // ],
    }

    // let data = {
    //     "id": 0,
    //     "repaymentType": "Normal Shipping",
    //     "contractType": "Offtaker",
    //     "selectedBorrower": 945,
    //     "selectedFacility": 794,
    //     "selectedOfftakerID": 321,
    //     "selectedContractNumber": null,
    //     "selectedPartialContractNumber": null,
    //     "contractNumberList": [],
    //     "partialContractNumberList": [],
    //     "fundCurrency": 1
    // }

//     let data = {
//     "id": 0,
//     "repaymentType": "Logistics",
//     "contractType": "Offtaker",
//     "selectedBorrower": 945,
//     "selectedFacility": "794",
//     "selectedOfftakerID": 321,
//     "selectedContractNumber": 169,
//     "selectedPartialContractNumber": 94,
//     "fundCurrency": 1
// }

//     let data = {
//     "id": 0,
//     "repaymentType": "Contract",
//     "contractType": "Contract",
//     "loanID": "1039",
//     "selectedBorrower": "945",
//     "selectedFacility": 794,
//     "fundCurrency": 1,
//     "selectedOfftakerID": 321,
//     "selectedContractNumber": 207,
//     "selectedPartialContractNumber": null
// }
    
    if (Object.keys(data).length === 0) {
      this.$router.go(-1);
      // If data is an empty object
      console.log('Data is empty');
    } else {
      // If data is not empty
      console.log("from loan,logistics to Dis",data);
      // Perform other operations with non-empty data
      this.repaymentType = 'repaymentType' in data ?  data.repaymentType : null;
      this.contractType = 'contractType' in data ?  data.contractType : null;
      this.loanID = 'loanID' in data ?  data.loanID : null;
      this.transferProfitReturnAmount = 'transferProfitReturnAmount' in data ?  data.transferProfitReturnAmount : null;
      this.profitReturnID = 'profitReturnID' in data ?  data.profitReturnID : null;
      this.selectedBorrower = 'selectedBorrower' in data ?  data.selectedBorrower : null;
      this.selectedFacility = 'selectedFacility' in data ?  data.selectedFacility  : null;
      this.selectedOfftakerID = 'selectedOfftakerID' in data ?  data.selectedOfftakerID : null;
      this.selectedContractNumber = 'selectedContractNumber' in data ?  data.selectedContractNumber : null;
      this.selectedPartialContractNumber = 'selectedPartialContractNumber' in data ?  data.selectedPartialContractNumber : null;
      this.contractNumberList = 'contractNumberList' in data ?  data.contractNumberList : [];
      this.partialContractNumberList = 'partialContractNumberList' in data ?  data.partialContractNumberList : [];
      this.selectedRepaymentCurrency = 'fundCurrency' in data ? data.fundCurrency : null;
      this.fundCurrency =  'fundCurrency' in data ? data.fundCurrency : null;
    }
    this.getBorrowers();
  },
};
</script>

<style scoped>

.black{
  color: black !important;
}

.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
.vdp-datepicker >>> .form-control[disabled] {
  background-color:#EEEEEE !important;
  opacity: 0.7;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.modal-dialog.custom-width {
    width: 50% !important;
    max-width: 450px !important;
  }
.multiselect  >>> .multiselect__tags  {
  height: 35px !important; /* Set the desired height */
  min-height: 35px !important; /* Set the desired height */
  background-color: #eeeef0 !important;
  max-height: 35px !important; /* Set the desired height */
}
.multiselect  >>> .multiselect__single  {
  background-color: #eeeef0 !important;
}

.multiselect  >>> .multiselect__input  {
  background-color: #eeeef0 !important;
}
.multiselect >>> .multiselect__select  {
  height: 35px !important; /* Set the desired height */
  min-height: 35px !important; /* Set the desired height */
  max-height: 35px !important; /* Set the desired height */
}
.multiselect >>> .multiselect__tag {
  margin-top: -2px !important;
}
.error {
  color: red;
  font-size: 13px;
  padding-left: 40px;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -75px;
}
.toggle-padding {
  margin-left: 5px;
}
.headingt #interestCalculationLabelYes {
  margin-left: 250px;
}
.headingt #interestCalculationLabelNo {
  margin-left: 20px;
}
.headingt #offtakerRepaymentLabelYes {
  margin-left: 95px;
}
.headingt #offtakerRepaymentLabelNo {
  margin-left: 20px;
}
</style>
