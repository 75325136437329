<!-- This is add Loan page. -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-plus"></i>
                          {{ type === 'expense' ? (id == 0 ? 'Add' : 'Edit') : '' }}
                          {{ type === 'payment' ? (id == 0 ? 'Add' : 'Edit') : '' }}
                          Fund {{ type === 'expense' ? 'Expense' : 'Payment' }}
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Basic Details</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Expense Type</label>
                      <select
                        id="selectedExpense"
                        class="form-control"
                        name="selectedExpense"
                        v-model="selectedExpense"
                        @change="backcapStartDate(selectedExpense,type)"
                        required
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedExpense.$error,
                        }"
                        :disabled="id > 0 || this.index !== undefined"
                      >
                        <option :value="null">-- Select an Expense --</option>
                        <option
                          v-for="expense in type==='expense'?expenses:paymentExpenses"
                          :value="expense.id"
                          :key="expense.id"
                        >{{ expense.expense_name }}</option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedExpense.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedExpense.required"
                          >Expense Type is required<br/></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-5 col-6" v-if="type === 'expense'">
                    <div class="form-group fc">
                      <label class="required-field">Payment Type</label>
                      <select
                        id="selectedExpenseType"
                        class="form-control"
                        name="selectedExpenseType"
                        v-model="selectedExpenseType"
                        @change="getCurrencyExchangeRateDateForFundExpensePayment()"
                        required
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedExpenseType.$error,
                        }"
                        :disabled="id > 0 || this.index !== undefined"
                      >
                        <option :value="null">-- Select a Payment Type --</option>
                        <option
                          v-for="exptype in expenseTypes"
                          :value="exptype.expense_type_name" :key="exptype.id"
                        >{{ exptype.expense_type_name }}</option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedExpenseType.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedExpenseType.required"
                          >Payment Type is required<br/></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field">Amount</label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Type Amount"
                        v-model="amount"
                        @keypress="isDecimal($event)"
                        @keyup="calculateFundConvertedAmount()"
                        
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.amount.$error,
                        }"
                        :disabled="id > 0 || (this.index !== undefined && this.ptype !== 'fund_onetime')"
                      />
                      <div
                        v-if="isSubmitted && $v.amount.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.amount.required"
                          >Amount is required<br/></span
                        >
                        <span v-if="!$v.amount.maxLength"
                          >Max length of 15 characters exceeded<br/></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-5 col-6" v-if="type === 'payment'">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">Description</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Type Description"
                        v-model="description"
                        @keypress="isAlphaNum($event)"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.description.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.description.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.description.maxLength"
                          >Max length of 50 characters exceeded<br/></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Currency</label>
                      <select
                        id="selectedCurrency"
                        class="form-control"
                        name="selectedCurrency"
                        v-model="selectedCurrency"
                        @change="getCurrencyExchangeRateDateForFundExpensePayment()"
                        required
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedCurrency.$error,
                        }"
                        :disabled="id > 0 || this.index !== undefined"
                      >
                        <option disabled :value="null">-- Select a Currency --</option>
                        <option
                          v-for="curr in currencies"
                          :value="curr.id" :key="curr.id"
                        >{{ curr.currency_short_name }}</option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedCurrency.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedCurrency.required"
                          >Currency is required<br/></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="type === 'payment' || this.selectedExpenseType === 'Onetime'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field">Payment Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="endDate"
                        placeholder="Select Date"
                        v-model="paymentDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        @input="getCurrencyExchangeRateDateForFundExpensePayment()"
                        :disabledDates="{to: expenseBackCapDate?new Date(expenseBackCapDate):new Date(fund_inception_date), from: type==='payment'?new Date():null}"
                        :class="{
                          'is-invalid': isSubmitted && $v.paymentDate.$error,
                        }"
                        :disabled="id > 0 || this.index !== undefined"
                      ></datepicker>
                      <div
                        v-if="isSubmitted && $v.paymentDate.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.paymentDate.required"
                          >Payment Date is required<br/></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="type === 'expense' && this.selectedExpenseType === 'Amortized'"
                  >
                    <div class="form-group fc">
                      <label class="required-field">Amortization Schedule</label>
                      <select
                        id="selectedInterestFrequency"
                        class="form-control"
                        name="selectedInterestFrequency"
                        v-model="selectedInterestFrequency"
                        required
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedInterestFrequency.$error,
                        }"
                        :disabled="id > 0"
                      >
                        <option :value="null">-- Select a Schedule --</option>
                        <option
                          v-for="intfreq in interestFrequencies.slice(1,5)"
                          :value="intfreq.id" :key="intfreq.id"
                        >{{ intfreq.interest_frequency_name }}</option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedInterestFrequency.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedInterestFrequency.required"
                          >Amortization Schedule is required<br/></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="type === 'expense' && this.selectedExpenseType === 'Amortized'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field">No. of Instalments</label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Type Installments"
                        v-model="installments"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.installments.$error,
                        }"
                        :disabled="id > 0"
                      />
                      <div
                        v-if="isSubmitted && $v.installments.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.installments.required"
                          >No. of Instalments are required<br/></span
                        >
                        <span v-if="!$v.installments.maxLength"
                          >Max length of 15 characters exceeded<br/></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-if="type === 'expense' && this.selectedExpenseType === 'Amortized'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field">Start Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="startDate"
                        placeholder="Select Date"
                        v-model="startDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        @input="getCurrencyExchangeRateDateForFundExpensePayment()"
                        :disabledDates="{to: new Date(expenseBackCapDate)}"
                        required
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.startDate.$error,
                        }"
                        :disabled="id > 0"
                      ></datepicker>
                      <div
                        v-if="isSubmitted && $v.startDate.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.startDate.required"
                          >Start Date is required<br/></span
                        >
                      </div>
                    </div>
                  </div>
                  <div v-if="fundConverionFlag" class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">Conversion Rate</label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="fundConversionRate"
                        disabled
                      />
                    </div>
                  </div>
                  <div v-if="fundConverionFlag" class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">Conversion Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="fundConversionDate"
                        placeholder="Select Date"
                        v-model="fundConversionDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        disabled
                      ></datepicker>
                    </div>
                  </div>
                  <div v-if="fundConverionFlag" class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1">Converted Amount</label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="fundConvertedAmount"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="InvoiceNumber">Invoice Number</label>
                      <input
                        type="text"
                        class="form-control"
                        id="InvoiceNumber"
                        v-model="InvoiceNumber"
                        :class="{
                          'is-invalid': isSubmitted && $v.InvoiceNumber.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.InvoiceNumber.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.InvoiceNumber.customValidation"
                          >Invoice Number should not be zero<br
                        /></span>
                        <span v-if="!$v.InvoiceNumber.alphaNum"
                          >Invoice Number should be alphanumeric only<br/></span
                        >
                        <span v-if="!$v.InvoiceNumber.maxLength"
                          >Max length of 100 characters exceeded<br/></span
                        >
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="nameOfEntity">Name Of Entity</label>
                      <input
                        type="text"
                        class="form-control"
                        id="nameOfEntity"
                        v-model="nameOfEntity"
                        :class="{
                          'is-invalid': isSubmitted && $v.nameOfEntity.$error,
                        }"
                        @keypress="isAlphaNum($event)"
                      />
                      <div
                        v-if="isSubmitted && $v.nameOfEntity.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.nameOfEntity.customValidation"
                          >Name Of Entity should not be zero<br
                        /></span>
                        <span v-if="!$v.nameOfEntity.maxLength"
                          >Max length of 100 characters exceeded<br/></span
                        >
                      </div>
                    </div>
                  </div>
                  

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="Comments">Comments</label>
                      <input
                        type="text"
                        class="form-control"
                        id="Comments"
                        v-model="Comments"
                        :class="{
                          'is-invalid': isSubmitted && $v.Comments.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.Comments.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.Comments.customValidation"
                          >Comments should not be zero<br
                        /></span>
                        <span v-if="!$v.Comments.maxLength"
                          >Max length of 100 characters exceeded<br/></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Banking Details</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedBank">Beneficiary Bank</label>
                      <select
                        id="selectedBank"
                        class="form-control"
                        v-model="selectedBank"
                      >
                        <option :value="null">-- Select a Bank --</option>
                        <option
                          v-for="bank in banks"
                          :value="bank.id"
                          :key="bank.id"
                        >
                          {{ bank.bank_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="bic">Bank Identifier Code</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="bic"
                        id="bic"
                        :class="{
                          'is-invalid': isSubmitted && $v.bic.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.bic.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.bic.required"
                          >Bank Identifier Code cannot be 0<br/></span
                        >
                        <span v-if="!$v.bic.minLength && $v.bic.required"
                          >Min length of 8 characters required<br
                        /></span>
                        <span v-if="!$v.bic.maxLength"
                          >Max length of 11 characters exceeded<br
                        /></span>
                        <span v-if="!$v.bic.alphaNum"
                          >Bank Identifier Code should be alphanumeric
                          only<br/></span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="iban">International Bank A/c No.</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="iban"
                        id="iban"
                        :class="{
                          'is-invalid': isSubmitted && $v.iban.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.iban.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.iban.required"
                          >IBAN cannot be 0<br/></span
                        >
                        <span v-if="!$v.iban.maxLength"
                          >Max length of 35 characters exceeded<br
                        /></span>
                        <span v-if="!$v.iban.alphaNum"
                          >IBAN should be alphanumeric only<br/></span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="accountNumber">Account No.</label>
                      <input
                        type="text"
                        id="accountNumber"
                        class="form-control"
                        v-model="accountNumber"
                        :class="{
                          'is-invalid': isSubmitted && $v.accountNumber.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.accountNumber.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.accountNumber.required"
                          >Account No. cannot be 0<br/></span
                        >
                        <span v-if="!$v.accountNumber.maxLength"
                          >Max length of 17 characters exceeded<br
                        /></span>
                        <span v-if="!$v.accountNumber.numeric"
                          >Account No. should be numeric only<br/></span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="accountName">Account Name </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="accountName"
                        id="accountName"
                        :class="{
                          'is-invalid': isSubmitted && $v.accountName.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.accountName.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.accountName.required"
                          >Account Name cannot be 0<br/></span
                        >
                        <span v-if="!$v.accountName.maxLength"
                          >Max length of 100 characters exceeded<br
                        /></span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="reference">Reference </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="reference"
                        id="reference"
                        :class="{
                          'is-invalid': isSubmitted && $v.reference.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.reference.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.reference.required"
                          >Reference cannot be 0<br/></span
                        >
                        <span v-if="!$v.reference.maxLength"
                          >Max length of 100 characters exceeded<br
                        /></span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="transactionID">Transaction ID</label>
                      <input
                        type="text"
                        class="form-control"
                        id="transactionID"
                        v-model="transactionID"
                        :class="{
                          'is-invalid': isSubmitted && $v.transactionID.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.transactionID.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.transactionID.required"
                          >Transaction ID should not be zero<br
                        /></span>
                        <span v-if="!$v.transactionID.numeric"
                          >Transaction ID should be numeric only<br/></span
                        >
                        <span v-if="!$v.transactionID.maxLength"
                          >Max length of 25 characters exceeded<br/></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documents</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addExpensePaymentDoc()"
                              :disabled="ExpensePaymentDocumentsAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(doc, index) in ExpensePaymentDocuments"
                      :key="index"
                    >
                      <div class="col-md-4 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="customFile">Upload Document</label>
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="customFile"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              v-on:change="
                                handleFileUploadExpensePaymentDocuments(index, $event) & addEditedFlag(index)"
                              :class="{
                                'is-invalid': doc.errors.filesize,
                              }"
                            />
                            <small>
                              <p style="background-color: powderblue">
                                <b>{{ doc.displayFileName }}</b>
                                <small>
                                  <u
                                    style="color: blue; cursor: pointer"
                                    v-if="
                                      doc.ExpensePaymentDocumentsRemoveDisabled === false
                                    "
                                    v-on:click="removeExpensePaymentDocuments(index)"
                                    >Remove</u
                                  >
                                </small>
                              </p>
                            </small>
                            <div
                              v-if="doc.errors.filesize"
                              class="invalid-feedback"
                            >
                              <span>{{ doc.errors.filesize }}</span>
                            </div>
                            <label class="custom-file-label" for="customFile"
                              >Choose file</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="comments">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="doc.comments"
                          id="comments"
                          @change="addEditedFlag(index)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ExpensePaymentDocuments.$each[index].comments
                                .$error,
                          }"
                          :disabled="doc.displayFileName == null"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ExpensePaymentDocuments.$each[index].comments.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ExpensePaymentDocuments.$each[index].comments
                                .maxLength
                            "
                            >Max length of 100 characters exceeded.<br/></span
                          >
                        </div>
                      </div>
                    </div>

                      <div class="col-md-1 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            v-on:click="deleteExpensePaymentDocuments(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onExpensePaymentDocumentSaveInfo()"
                          :disabled="isDisabledExpensePaymentDocumentSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-success sh"
                      v-on:click="onSubmit()"
                    >
                      Submit
                    </button>
                  </li>
                  <li>
                    <a @click="$router.go(-1)">
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>
    <!-- ./wrapper -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {
  required,
  maxLength,
  minLength,
  numeric,
  alphaNum,
  decimal,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: 0,
      fundID: null,
      inActive: false,
      index: null,
      ptype: null,
      fund: [],
      expenses: [],
      paymentExpenses: [],
      selectedExpense: null,
      currencies: [],
      selectedCurrency: null,
      expenseTypes: [],
      selectedExpenseType: null,
      interestFrequencies: [],
      selectedInterestFrequency: null,
      installments: null,
      startDate: null,
      startDate1: null,
      endDate: null,
      endDate1: null,
      expenseBackCapDate: null,
      paymentDate: new Date(),
      paymentDate1: null,
      amount: null,
      description: null,
      errors: [],
      fundExpensePayment: [],
      totalPaymentAmountSum: "",
      fundExpenses: [],
      fundExpensesDates:[],
      paymentExpensesDates:[],
      totalExpenseAmountSum: "",
      //filter expense payments
      totalPaymentStartDate: "",
      totalPaymentEndDate: "",
      totalExpenseFinStartDate: "",
      totalExpenseFinEndDate: "",
      //filter amortized expenses
      fromDateFundExpense: null,
      toDateFundExpense: null,
      //search filter
      filter: null,
      type: null,
      selectedPeriod: "qtd", //selecting default as 1 year for fund expense filter
      selectedPaymentPeriod: "qtd",
      borrower_id: null,
      fund_inception_date: null,

      fundCurrency: null,
      fundConversionRate:1,
      fundConvertedAmount:null,
      fundConversionDate:new Date(),
      fundConverionFlag:false,
      nameOfEntity:null,
      InvoiceNumber:null,
      Comments:null,
      // bank
      banks: [],
      selectedBank: null,
      bic: null,
      iban: null,
      accountNumber: null,
      accountName: null,
      reference: null,
      transactionID: null,
      // docs
      ExpensePaymentDocuments: [],
      DeleteDocData: [],
      //list for Loan Documents
      ExpensePaymentDocumentFile: null,
      ExpensePaymentDocumentsLength: null,
      // ExpensePaymentDocumentsButtonDisabled: true,
      ExpensePaymentDocumentsDisabled: true,
      ExpensePaymentDocumentSaveInfoDisabled: true, //save info button is disabled
      ExpensePaymentDocumentsAddMoreDisabled: true,

              isSubmitted: false,
    };
  },

  validations: {
    selectedExpense:{
      required
    },
    selectedExpenseType:{
      required: function(){
        if(this.type === "expense" && !this.selectedExpenseType)
          return false
        else
          return true
      }
    },
    amount: {
      required: function(){
        if(this.amount === null || this.amount == 0)
          return false
        else
          return true
      },
      maxLength: maxLength(15),
    },
    selectedCurrency: {
      required
    },
    selectedInterestFrequency:{
      required: function(){
        if(this.type ==='expense' && this.selectedExpenseType === 'Amortized' && !this.selectedInterestFrequency)
          return false
        else
          return true
      }
    },
    installments:{
      maxLength: maxLength(15),
      required: function(){
        if(this.type ==='expense' && this.selectedExpenseType === 'Amortized' && !this.installments)
          return false
        else
          return true
      }
    },
    startDate:{
      required: function(){
        if(this.type ==='expense' && this.selectedExpenseType === 'Amortized' && !this.startDate)
          return false
        else
          return true
      }
    },
    paymentDate: {
      required: function(){
        if((this.type ==='payment' || this.selectedExpenseType === "Onetime") && !this.paymentDate)
          return false
        else
          return true
      }
    },
    description: {
      maxLength: function(){
        if(this.type === 'payment' && this.description ? this.description.length > 50 : false)
          return false
        else 
          return true
      }
    },
    InvoiceNumber: {
      customValidation: function (value) {
        if (value != "" && value == 0) return false;
        else return true;
      },
      alphaNum,
      maxLength: maxLength(100),
    },
    nameOfEntity: {
      customValidation: function (value) {
        if (value != "" && value == 0) return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    Comments: {
      customValidation: function (value) {
        if (value != "" && value == 0) return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    bic: {
      alphaNum,
      required: function () {
        if (this.bic === "0") return false;
        else return true;
      },
      minLength: minLength(8),
      maxLength: maxLength(11),
    },
    iban: {
      required: function () {
        if (this.iban === "0") return false;
        else return true;
      },
      alphaNum,
      maxLength: maxLength(35),
    },
    accountNumber: {
      required: function () {
        if (this.accountNumber === "0") return false;
        else return true;
      },
      numeric,
      maxLength: maxLength(17),
    },
    accountName: {
      required: function () {
        if (this.accountName === "0") return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    reference: {
      required: function () {
        if (this.reference === "0") return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    transactionID: {
      numeric,
      required: function () {
        if (this.transactionID === "0") return false;
        else return true;
      },
      maxLength: maxLength(25),
    },
    ExpensePaymentDocuments: {
      $each: {
        comments: {
          maxLength: maxLength(100),
        },
      },
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
    //this is to computed disabled property of ExpensePaymentDocuments Save Info button.
    isDisabledExpensePaymentDocumentSaveInfo: function () {
      let checkEmptyExpensePaymentDocumentsDocName = this.ExpensePaymentDocuments.filter(
        (line) => line.displayFileName === null
      );
      //if the line is empty then disable save info button
      if (
        this.ExpensePaymentDocuments.length === 0 &&
        this.ExpensePaymentDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyExpensePaymentDocumentsDocName.length >= 1 &&
        this.ExpensePaymentDocuments.length > 0
      ) {
        this.ExpensePaymentDocumentsDisabled = true;
      } else {
        this.ExpensePaymentDocumentsDisabled = false;
      }
      //ExpensePaymentDocumentsDisabled variable is to check whther all input fields except file upload are filled. ExpensePaymentDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.ExpensePaymentDocumentsDisabled === true ||
        this.ExpensePaymentDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledExpensePaymentDocumentSaveInfo() close
  }, //main computed close

  components: {
    Datepicker,
  },
  methods: {
        isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },

    isAlphaNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },

    getCurrencyShortNameById(id) {
      if (id === null) {
        return '';
      }
      const currency = this.currencies.find((currency) => currency.id === id);
      return currency ? currency.currency_short_name : '';
    },

    onSubmit() {
      console.log("ADD OR EDIT id",this.id)
      if(this.id == 0){

        this.isSubmitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        
        let currency = "";
        this.currencies.filter((cur) => {
          if (cur.id === this.fundCurrency) {
            currency = cur.currency_short_name;
          }
        });

        this.$confirm({
          auth: false,
          message: `Are you sure you want to make ${this.type} of ${Number(
            Number(this.fundConvertedAmount).toFixed(2)
          ).toLocaleString(
            "en-US"
          )} ${currency}? This is onetime process, Changes cannot be reverted.`,
          button: {
            no: "No",
            yes: "Yes",
          },

          callback: (confirm) => {
            if (confirm) {
              Utils.start_loading();
              let paymentDate = null;
              let startDate = null;
              let endDate = null;
              let fundConversionDate = null;
              if (this.paymentDate != null) {
                paymentDate = moment(this.paymentDate).format(
                  "MM-DD-YYYY"
                );
              }
              if (this.startDate != null) {
                startDate = moment(this.startDate).format("MM-DD-YYYY");
              }
              // NOT IN USE
              if (this.endDate != null) {
                endDate = moment(this.endDate).format("MM-DD-YYYY");
              }
              if (this.fundConversionDate != null) {
                fundConversionDate = moment(this.fundConversionDate).format("MM-DD-YYYY");
              }
        
              this.ExpensePaymentDocuments.map((doc, index) => {
                if (doc.displayFileName === null) this.ExpensePaymentDocuments.splice(index, 1);
              });
              console.log("fundCurrency",this.fundCurrency)
              //initialize the form data
              let formData = new FormData();
              //append company information to formdata
              formData.append("selectedExpense", this.selectedExpense);
              formData.append("selectedCurrency", this.selectedCurrency);
              formData.append("fundCurrency", this.fundCurrency);
              formData.append("paymentDate", paymentDate);
              formData.append("amount", this.amount);
              formData.append("fundConvertedAmount", this.fundConvertedAmount);
              formData.append("fundConversionRate", this.fundConversionRate);
              formData.append("fundConversionDate", fundConversionDate);
              if (this.type === 'payment') {
                // Only append these form data if type is 'payment'
                formData.append("description", this.description);
              }
              if (this.type === 'expense') {
                // Only append these form data if type is 'expense'
                formData.append("selectedExpenseType", this.selectedExpenseType);
                formData.append("selectedInterestFrequency", this.selectedInterestFrequency);
                formData.append("installments", this.installments);
              }
              formData.append("startDate", startDate);
              formData.append("endDate", endDate);
              formData.append("type", this.type);
              formData.append("nameOfEntity", this.nameOfEntity);
              formData.append("InvoiceNumber", this.InvoiceNumber);
              formData.append("Comments", this.Comments);

              formData.append("selectedBank", this.selectedBank);
              formData.append("bic", this.bic);
              formData.append("iban", this.iban);
              formData.append("accountNumber", this.accountNumber);
              formData.append("accountName", this.accountName);
              formData.append("reference", this.reference);
              formData.append("transactionID", this.transactionID);
              //append ExpensePaymentDocuments information to formdata
              formData.append("ExpensePaymentDocuments", JSON.stringify(this.ExpensePaymentDocuments));
              formData.append("clientId", JSON.stringify(this.$store.state.client_id));
              formData.append("userId", this.$store.getters.getuserId);
              formData.append("moduleId", 1);
              formData.append("tabId", 1);
              console.log(formData);
              const path = "fund-expense-operation/" + this.fundID
              Utils.start_loading();
              axios
                .post(path, formData, {
                  headers: {
                    enctype: "multipart/form-data",
                  },
                })
                .then((response) => {
                  Utils.stop_loading();
                  console.log("status",response.data.status)
                  if (response.data.status == "success") {
                    this.type = this.type.replace(/\w\S*/g, function (txt) {
                      return (
                        txt.charAt(0).toUpperCase() +
                        txt.substr(1).toLowerCase()
                      );
                    });
                    Utils.make_alert(
                      "success",
                      this.type + " added successfully!"
                    );
                    //if you want to send any data into server before redirection then you can do it here
                    // $(".close").click();
                    if (this.index === undefined) {
                      setTimeout(
                        () => this.$router.push({name:"FundOverview",params:{inActive:false,id:this.fundID}}),
                        1500
                      );
                    } else {
                      this.$router.go(-2);
                    }
                  } else {
                    Utils.make_alert("warning", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.log("error: " + error);
                  Utils.make_alert("warning", "Something went wrong!");
                });
            }
          },
        });
      } else if (this.id > 0) {
        this.isSubmitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        // First, handle documents that need to be deleted or moved to DeleteDocData
        this.ExpensePaymentDocuments.forEach(doc => {
          if (doc.id !== null && doc.displayFileName === null) {
            // Add to DeleteDocData
            this.DeleteDocData.push({
              id: doc.id,
              // type: "Document",
              // moduleID: 1,
              // tabID: 1,
              // clientId: this.$store.state.client_id,
              // userId: this.$store.getters.getuserId,
            });
          }
        });

        // // Then, filter ExpensePaymentDocuments to remove items as needed
        // this.ExpensePaymentDocuments = this.ExpensePaymentDocuments.filter(doc => {
        //   // Keep the document if displayFileName is not null or if both id and displayFileName are null
        //   return doc.displayFileName !== null || (doc.id === null && doc.displayFileName === null);
        // });

        this.ExpensePaymentDocuments.map((doc, index) => {
          if (doc.displayFileName === null) this.ExpensePaymentDocuments.splice(index, 1);
        });

        //initialize the form data
        let formData = new FormData();
        //append company information to formdata
        formData.append("expensePaymentID", this.id);
        formData.append("selectedExpense", this.selectedExpense);
        if (this.type === 'payment') {
          // Only append these form data if type is 'payment'
          formData.append("description", this.description);
        }
        formData.append("type", this.type);
        formData.append("nameOfEntity", this.nameOfEntity);
        formData.append("InvoiceNumber", this.InvoiceNumber);
        formData.append("Comments", this.Comments);

        formData.append("selectedBank", this.selectedBank);
        formData.append("bic", this.bic);
        formData.append("iban", this.iban);
        formData.append("accountNumber", this.accountNumber);
        formData.append("accountName", this.accountName);
        formData.append("reference", this.reference);
        formData.append("transactionID", this.transactionID);
        //append ExpensePaymentDocuments information to formdata
        formData.append("ExpensePaymentDocuments", JSON.stringify(this.ExpensePaymentDocuments));
        formData.append("DeleteDocData", JSON.stringify(this.DeleteDocData));
        formData.append("clientId", JSON.stringify(this.$store.state.client_id));
        formData.append("userId", this.$store.getters.getuserId);
        formData.append("moduleId", 1);
        formData.append("tabId", 1);
        console.log(formData);
        const path = "fund-expense-operation/" + this.fundID
        Utils.start_loading();
        axios
          .put(path, formData, {
            headers: {
              enctype: "multipart/form-data",
            },
          })
          .then((response) => {
            Utils.stop_loading();
            console.log("status",response.data.status)
            if (response.data.status == "success") {
              let typeFinal = this.type
              this.type = this.type.replace(/\w\S*/g, function (txt) {
                return (
                  txt.charAt(0).toUpperCase() +
                  txt.substr(1).toLowerCase()
                );
              });
              Utils.make_alert(
                "success",
                this.type + " edited successfully!"
              );
              this.$router.push({name:'FundExpense',params:{
                type:typeFinal,
                fund_id:this.fundID,
                expense_id:this.selectedExpense, 
                inception_date:this.fund_inception_date,
                inactive:false
              }});

              //if you want to send any data into server before redirection then you can do it here
              // $(".close").click();
              // if (this.index === undefined) {
              //   setTimeout(
              //     () => this.$router.push({name:'FundExpense',params:{type:type,fund_id:fundID,expense_id:selectedExpense, inception_date: fund_inception_date, inactive:false}}),
              //     1500
              //   );
              // } else {
              //   this.$router.go(-1);
              // }
            } else {
              Utils.make_alert("warning", "Something went wrong!");
            }
          })
          .catch((error) => {
            Utils.stop_loading();
            console.log("error: " + error);
            // Utils.make_alert("warning", "Something went wrong!");
          });
        }
    },

    //this method will make disabled property as false for Director Save Info
    onExpensePaymentDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.ExpensePaymentDocumentSaveInfoDisabled = false;
      this.ExpensePaymentDocumentsAddMoreDisabled = false;
    },

    removeExpensePaymentDocuments(id) {
      this.ExpensePaymentDocumentFile = null;
      this.ExpensePaymentDocuments[id].selectedFile = null;
      this.ExpensePaymentDocuments[id].selectedFileName = null;
      this.ExpensePaymentDocuments[id].displayFileName = null;
      this.ExpensePaymentDocuments[id].comments = null;
      this.ExpensePaymentDocuments[id].ExpensePaymentDocumentsRemoveDisabled = true;
    },


    deleteExpensePaymentDocuments(index) {
      if (this.ExpensePaymentDocuments[index].id) {
        let deleteId = this.ExpensePaymentDocuments[index].id;
        this.DeleteDocData.push({
          id: deleteId,
          // type: "Document",
          // moduleID: 1,
          // tabID: 1,
          // clientId: this.$store.state.client_id,
          // userId: this.$store.getters.getuserId,
        });
      }
      this.ExpensePaymentDocumentFile = this.ExpensePaymentDocumentFile
        ? this.ExpensePaymentDocumentFile
        : "file deleted";
      this.ExpensePaymentDocuments.splice(index, 1);
      if (
        typeof this.ExpensePaymentDocuments !== "undefined" &&
        this.ExpensePaymentDocuments.length == 0
      ) {
        this.ExpensePaymentDocumentsAddMoreDisabled = false;
        this.ExpensePaymentDocumentSaveInfoDisabled = true;
      }
    },

    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },

    addEditedFlag(index) {
      console.log("this.ExpensePaymentDocuments[index].id ",this.ExpensePaymentDocuments[index].id )
      if(this.ExpensePaymentDocuments[index].id != null){
        console.log("edited existing document")
        this.ExpensePaymentDocuments[index]["edited"] = "true";
      }
      console.log(this.ExpensePaymentDocuments);
    },

    //this method will add new line for additional Cost Documents
    addExpensePaymentDoc() {
      //when new line is added then ExpensePaymentDocumentFile variable and ExpensePaymentDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.ExpensePaymentDocumentFile = null),
        (this.ExpensePaymentDocumentSaveInfoDisabled = true),
        (this.ExpensePaymentDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.ExpensePaymentDocuments.push({
          id: null,
          displayFileName: null,
          comments: null,
          edited: "false",
          errors: { filesize: "" },
          ExpensePaymentDocumentsRemoveDisabled:true,
        });
    }, //addExpensePaymentDoc() close

    //handles a change on the file upload
    handleFileUploadExpensePaymentDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.ExpensePaymentDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.ExpensePaymentDocumentFile.size > 104857600) {
          e.preventDefault();
          self.ExpensePaymentDocuments[id].errors.filesize =
            "File size should be less than 100MB";
          self.ExpensePaymentDocuments[id].selectedFile = null;
          self.ExpensePaymentDocuments[id].selectedFileName = null;
          self.ExpensePaymentDocuments[id].displayFileName = null;
          self.ExpensePaymentDocuments[id].comments = null;
          self.ExpensePaymentDocuments[id].ExpensePaymentDocumentsRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.ExpensePaymentDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.ExpensePaymentDocuments[id].selectedFile = reader.result;
          self.ExpensePaymentDocuments[id].selectedFileName = e.target.files[0].name;
          self.ExpensePaymentDocuments[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.ExpensePaymentDocuments[id].errors.filesize = "";
          self.ExpensePaymentDocuments[id].ExpensePaymentDocumentsRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadExpensePaymentDocuments() close

    calculateFundConvertedAmount(){
    this.fundConvertedAmount = (Number(this.amount) * Number(this.fundConversionRate)).toFixed(2);
  },

  getCurrencyExchangeRateDateForFundExpensePayment() {
    //this function is called to get fund currency of that facility
    console.log("inside getCurrencyExchangeRateDateForRepayment")
    let currencyDate = null;
    console.log("this.selectedExpenseType", this.selectedExpenseType)
    if(this.selectedExpenseType == 'Amortized'){
      if (this.startDate != null) {
        currencyDate = moment(this.startDate).format("MM-DD-YYYY");
      }
    }else if(this.selectedExpenseType == 'Onetime'){
      if (this.paymentDate != null) {
        currencyDate = moment(this.paymentDate).format("MM-DD-YYYY");
      }
    } else {
      if (this.paymentDate != null) {
        currencyDate = moment(this.paymentDate).format("MM-DD-YYYY");
      }
    }
    if(this.fundCurrency == this.selectedCurrency){
      this.fundConverionFlag= false;
      this.fundConversionRate = 1;
      if(currencyDate != null){
        this.fundConversionDate = currencyDate;
      } else {
        this.fundConversionDate = new Date();
      }
      console.log("this.fundConversionDate",this.fundConversionDate)
      this.calculateFundConvertedAmount()
      return
    }

    console.log("this.fundCurrency", this.fundCurrency)
    console.log("this.selectedCurrency", this.selectedCurrency)
    console.log("currencyDate", currencyDate)
    
    if(this.fundCurrency == null || this.selectedCurrency == null || currencyDate == null){
      this.fundConverionFlag= false;
      return;
    }

    const path = "/currency-exchange-rate/" +
      this.fundCurrency +
      "/" +
      this.selectedCurrency +
      "/" +
      currencyDate
      Utils.start_loading();
    axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
      .then((res) => {
        Utils.stop_loading();
        console.log(res.data)
        let data = res.data.currency_rate[0]
        console.log(data)
        if(res.data.status == "success"){
          if(data == null){
          this.currencyConversionFlag = true
          let message = "The Currency conversion rate for the selected Currency and Fund Currency is not available.<br/>So, the system auto selected the Currency as the Fund Currency to proceed.";
          Utils.make_alert("warning", message);
          this.selectedCurrency = this.fundCurrency;
          this.fundConverionFlag= false;
          this.fundConversionRate = 1;
          if(currencyDate != null){
            this.fundConversionDate = currencyDate;
          } else {
            this.fundConversionDate = new Date();
          }
          this.calculateFundConvertedAmount()
          return
          } else {
            let rate =  data.currency_value;
            let date =  data.date;
            this.fundConversionRate = rate;
            this.fundConversionDate = date;
            this.fundConverionFlag= true;
            this.calculateFundConvertedAmount()
          }
          } else {
          Utils.make_alert("success", "Something went wrong!");
          console.log("FAILURE!!");
        }
      })
      .catch((error) => {
        console.error(error);
        Utils.stop_loading();
      });
  },

    getExpenseBackcapDates(){
    const path =
        "filter-by-fund-id/" + this.fundID;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.fundExpensesDates = res.data.expense_dates;
          this.backcapStartDate(this.selectedExpense,this.type);
          if (res.data.status == "success") {
            console.log("get fund expense dates by id is successful.");
          } else {
            console.log("error in get fund expense dates by id");
          }
        })
        .catch((error) => {
          console.error("error: " + error);
        });
  },

  getPaymentBackcapDates(){
    const path =
        "filter-by-fund-id-payment/" + this.fundID;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.paymentExpensesDates = res.data.expense_dates;
          this.backcapStartDate(this.selectedExpense,this.type);
          if (res.data.status == "success") {
            console.log("get fund expense dates by id is successful.");
          } else {
            console.log("error in get fund expense dates by id");
          }
        })
        .catch((error) => {
          console.error("error: " + error);
        });
  },
  
    //amortized date backcapping logic
  backcapStartDate(expense_id,type) {
    console.log("backcapStartDate")
    console.log("expense_id",expense_id)
    console.log("type",type)
    console.log("fundExpensesDates",this.fundExpensesDates)
    console.log("paymentExpensesDates",this.paymentExpensesDates)
    if(expense_id == null || type == null){
      return;
    }
    if(type==="expense"){  
      if(this.fundExpensesDates){
        let date = this.fundExpensesDates.filter(exp=> exp.expense_id == expense_id);
        console.log("date",date)
        if(date.length){
          date = moment(date[0].date).add(1,"days").format("MM-DD-YYYY")
          console.log("expense date",date)
          this.expenseBackCapDate = date;
          this.paymentDate = date;
          this.startDate = date;
          this.fundConversionDate = date;
        } else {
          console.log("else expense fund_inception_date date",this.fund_inception_date)
          date = this.fund_inception_date;
          this.expenseBackCapDate = date;
          this.paymentDate = date;
          this.startDate = date;
          this.fundConversionDate = date;
        }
      }
    }
    else if(type==="payment"){
      if(this.paymentExpensesDates){
        let date = this.paymentExpensesDates.filter(exp=> exp.expense_id === expense_id);
        if(date.length){
          console.log("payment date",date)
          date = moment(date[0].payment_date).add(1,"days").format("MM-DD-YYYY")
          this.expenseBackCapDate = date;
          this.paymentDate = date;
          this.fundConversionDate = date;
        } else {
          console.log("else payment fund_inception_date date",this.fund_inception_date)
          date = this.fund_inception_date;
          this.expenseBackCapDate = date;
          this.paymentDate = date;
          this.startDate = date;
          this.fundConversionDate = date;
        }
      }
    }
    console.log("expenseBackCapDate",this.expenseBackCapDate)

  },

  getSingleFundExpensePaymentOperation() {
    const path = "get-single-fund-expense-payment-operation" + 
    "/" + 
    this.fundID +
    "/" + 
    this.type +
    "/" + 
    this.id;
    
    axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
      .then((res) => {
        if (res.data.status == "success") {
          let expensePayment = {};
          let ExpensePaymentDocuments = [];
          if(this.type === 'expense'){
            expensePayment = res.data.expense_payment.expense_payment_operation[0];
            ExpensePaymentDocuments = res.data.expense_payment.expense_payment_document;
          }
          else if(this.type === 'payment'){
            expensePayment = res.data.expense_payment.payment_operation[0];
            ExpensePaymentDocuments = res.data.expense_payment.payment_document;
          }
          this.selectedExpense = expensePayment.expense_id
          this.selectedExpenseType = expensePayment.expense_type_name
          this.amount = expensePayment.amount
          this.selectedCurrency = expensePayment.currency_id
          this.fundCurrency = expensePayment.fund_currency_id
          if(this.type === 'expense'){
            this.selectedInterestFrequency = expensePayment.selected_interest_frequency
            this.paymentDate = expensePayment.installment_date
            this.startDate = expensePayment.installment_date
            this.installments = expensePayment.installment
          }
          else if(this.type === 'payment'){
            this.paymentDate = expensePayment.payment_date
            this.description = expensePayment.description
          }
          this.fundConvertedAmount = expensePayment.fund_converted_amt
          this.fundConversionRate = expensePayment.fund_conversion_rate
          this.fundConversionDate = expensePayment.fund_conversion_date
          if((this.fundCurrency != this.selectedCurrency) && this.fundCurrency != null && this.selectedCurrency != null){
            this.fundConverionFlag= true;
          } else {
            this.fundConverionFlag= false;
          }
          this.nameOfEntity = expensePayment.name_of_entity
          this.InvoiceNumber = expensePayment.invoice_number
          this.Comments = expensePayment.comments
          this.selectedBank = expensePayment.beneficiary_bank_id
          this.bic = expensePayment.bic
          this.iban = expensePayment.iban
          this.accountNumber = expensePayment.account_number
          this.accountName = expensePayment.account_name
          this.reference = expensePayment.reference
          this.transactionID = expensePayment.transaction_id
          console.log("get fund details is successful.");
          // "ExpensePaymentDocuments": [
          //   {
          //     "id": 622, 
          //     "comments": null, 
          //     "displayFileName": "Analytics Dashboard 24-11-21 (4).xlsx", 
          //     "document_path": "D:/DPA-TradePulse/TradePulse Scipion 1.4/dpa_tradepulse_webapp/loan_documents/Blackrock co./Analytics Dashboard 24-11-21 (4).xlsx", 
          //   }
          // ], 
          this.ExpensePaymentDocumentsLength = ExpensePaymentDocuments.length;
          if(this.ExpensePaymentDocumentsLength){
            this.ExpensePaymentDocuments = [];
            this.ExpensePaymentDocumentsAddMoreDisabled = false
            ExpensePaymentDocuments.map((doc) => {
              this.ExpensePaymentDocuments.push({
                id: doc.id,
                displayFileName: doc.displayFileName,
                comments: doc.comments,
                edited: "false",
                errors: { filesize: "" },
                document_path: doc.document_path,
                ExpensePaymentDocumentsRemoveDisabled: false,
              });
            });
          }
        } else if (res.data.status == "unauthorised") {
          this.$router.push("/fund");
        } else {
          console.log("Something went wrong from server!");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  getFund() {
    // const path = "get-fund-currency" + "/" + this.fundID;
    const path = "fund/" + this.fundID;
    axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
      .then((res) => {
        if (res.data.status == "success") {
          this.fund = res.data.fund_active;
          this.fund_inception_date = this.fund.fund_inception_date;
          if((this.fundCurrency == null) && this.id == 0) {
            this.fundCurrency = this.fund.fund_currency;
            this.selectedCurrency = this.fund.fund_currency;
          }
          if((this.paymentDate == null) && this.id == 0){
            let date = this.fund_inception_date;
            this.expenseBackCapDate = date;
            this.paymentDate = date;
            this.startDate = date;
            this.fundConversionDate = date;
          }
          console.log("this.fund_inception_date",this.fund_inception_date)
          // console.log("this.fund.management_fees",this.fund.management_fees)
          // console.log("this.fund.performance_fees",this.fund.performance_fees)
          if(this.id == 0 && this.expenses.length > 0){
            console.log("remove mange or perform")
            if (this.fund.management_fees) {
              this.expenses = this.expenses.filter(exp => exp.expense_name != 'Management Fees');
            }
            if (this.fund.performance_fees) {
              this.expenses = this.expenses.filter(exp => exp.expense_name != 'Performance Fees');
            }
          }
          console.log("get fund details is successful.");
          Utils.stop_loading();
        } else {
          Utils.stop_loading();
          console.log("Something went wrong!");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },

    getExpenses() {
      //this function is called to get list of expenses
      const path = "expense";
      axios
        .get(path)
        .then((res) => {
          this.paymentExpenses = res.data.expenses;
          this.expenses = res.data.expenses;
          // if(this.expense.length == 0){
          //   this.expenses = res.data.expenses;
          // }

          // console.log("this.fund.management_fees expense",this.fund.management_fees)
          // console.log("this.fund.performance_fees expense",this.fund.performance_fees)
          if(this.id == 0 && this.expenses.length > 0){
            console.log("remove mange or perform expense")
            if (this.fund.management_fees) {
              this.expenses = this.expenses.filter(exp => exp.expense_name != 'Management Fees');
            }
            if (this.fund.performance_fees) {
              this.expenses = this.expenses.filter(exp => exp.expense_name != 'Performance Fees');
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getExpenseTypes() {
      //this function is called to get list of expense types
      const path = "expense-type";
      axios
        .get(path)
        .then((res) => {
          this.expenseTypes = res.data.expense_types;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getInterestFrequencies() {
      //this function is called to get list of interest frequencies
      const path = "interest-frequency";
      axios
        .get(path)
        .then((res) => {
          this.interestFrequencies = res.data.interest_frequencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBanks() {
      //this function is called to get list of banks
      const path = "bank-detail";
      axios
        .get(path)
        .then((res) => {
          console.log(res)
          console.log(res.data.bank_details)
          this.banks = res.data.bank_details;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close

  mounted() {
    this.addExpensePaymentDoc();
  },

  created() {
    let data = this.$route.params;
    this.getExpenses();

    // let data = {
      //     "type": "expense",
      //     "fundID": "377",
      //     "id": 911,
    //  // }
    //     "selectedExpense": 4,
    //     "selectedExpenseType": "Onetime",
    //     "amount": "1200",
    //     "description": null,
    //     "selectedCurrency": 3,
    //     "fundCurrency": 1,
    //     "expenseBackCapDate": "2013-12-31T18:30:00.000Z",
    //     "fund_inception_date" : "01-01-2014",
    //     "selectedInterestFrequency": null,
    //     "paymentDate": "2024-02-02T10:28:18.979Z",
    //     "installments": null,
    //     "startDate": null,
    //     "fundConversionRate": 0.5947,
    //     "fundConvertedAmount": "713.64",
    //     "fundConversionDate": "07-04-2023",
    //     "fundConverionFlag": true
    // }
  
  
    console.log("data",data);
    //   if (Object.keys(data).length === 0) {
    //   // this.$router.go(-1);
    //   // If data is an empty object
    //   console.log('Data is empty');
    // } else {
    //   // If data is not empty
    //   console.log("from loan,logistics to Dis",data);
      // Perform other operations with non-empty data
      this.type = 'type' in data ?  data.type : 'expense';
      this.fundID = 'fundID' in data ?  data.fundID : null;
      this.id = 'id' in data ?  data.id : 0;
      this.index = 'index' in data ?  data.index : undefined;
      this.ptype = 'ptype' in data ?  data.ptype : undefined;
      this.selectedExpense = 'selectedExpense' in data ?  data.selectedExpense : null;
      this.selectedExpenseType = 'selectedExpenseType' in data ?  data.selectedExpenseType : null;
      this.amount = 'amount' in data ?  data.amount : null;
      this.description = 'description' in data ?  data.description  : null;
      this.selectedCurrency = 'selectedCurrency' in data ?  data.selectedCurrency : null;
      this.fundCurrency = 'fundCurrency' in data ?  data.fundCurrency : null;
      this.expenseBackCapDate = 'expenseBackCapDate' in data ?  data.selectedContractNumber : null;
      this.selectedInterestFrequency = 'selectedInterestFrequency' in data ?  data.selectedInterestFrequency : null;
      this.paymentDate = 'paymentDate' in data ?  data.paymentDate : null;
      this.installments = 'installments' in data ?  data.installments : null;
      this.startDate = 'startDate' in data ? data.startDate : null;
      this.fund_inception_date = 'fund_inception_date' in data ? data.fund_inception_date : null;
      this.fundConversionRate =  'fundConversionRate' in data ? data.fundConversionRate : 1;
      this.fundConversionDate =  'fundConversionDate' in data ? data.fundConversionDate : data.fund_inception_date;
      this.fundConvertedAmount =  'fundConvertedAmount' in data ? data.fundConvertedAmount : null;
      this.fundConverionFlag =  'fundConverionFlag' in data ? data.fundConverionFlag : false;
    // }
    this.getCurrencies();
    this.getExpenseTypes();
    this.getInterestFrequencies();
    this.getBanks();
    this.getFund();
    this.getExpenseBackcapDates();
    this.getPaymentBackcapDates();
    if(this.id > 0){
      this.getSingleFundExpensePaymentOperation();
    }
    // this.backcapStartDate(this.selectedExpense,this.type);
  },
};
</script>

<style scoped>
.error {
  color: red;
  font-size: 13px;
  padding-left: 40px;
}
.form-control:disabled {
    background-color:#EEEEEE !important;
  opacity: 0.7;
}
/* .vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -75px;
} */

.vdp-datepicker >>> .form-control[disabled] {
  background-color:#EEEEEE !important;
  opacity: 0.7;
  pointer-events: none !important;
  cursor: not-allowed !important;
}.toggle-padding {
  margin-left: 5px;
}
.headingt #interestCalculationLabelYes {
  margin-left: 250px;
}
.headingt #interestCalculationLabelNo {
  margin-left: 20px;
}
.headingt #offtakerRepaymentLabelYes {
  margin-left: 95px;
}
.headingt #offtakerRepaymentLabelNo {
  margin-left: 20px;
}
</style>
