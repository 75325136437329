<!-- This is LTV Tracker page. It shows list of all the LTV Trackers and their details -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6 col-sm-6">
                        <h1 class="headingt">
                          <i class="icofont-check-circled"></i> LTV Checker
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <!-- <div
                class="col-md-2 col-sm-5 col-6"
                v-if="loggedInUserGroupId !== 'Borrower'"
              >
                <div class="form-group fc">
                  <label for="input" class="required-field"
                    >Borrower Name</label
                  >
                  <select
                    id="selectedBorrowerId"
                    class="form-control"
                    name="selectedBorrowerId"
                    v-model="selectedBorrowerId"
                    @change="onBorrowerNameChange($event)"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="bor in borrowers"
                      :value="bor.borrower_id"
                      :key="bor.borrower_id"
                    >
                      {{ bor.borrower_name }}
                    </option>
                  </select>
                </div>
              </div>
              
              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="input" class="required-field">Facility ID</label>
                  <select
                    id="selectedFacilityId"
                    class="form-control"
                    name="selectedFacilityId"
                    v-model="selectedFacilityId"
                    @change="onFacilityIdChange($event)"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="fac in facilities"
                      :value="fac.facility_id"
                      :key="fac.facility_id"
                    >
                      {{ fac.facility_registration_id }}
                    </option>
                  </select>
                </div>
              </div> -->

              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label class="required-field">Fund Name</label>
                  <select
                    id="selectedFund"
                    class="form-control"
                    name="selectedFund"
                    v-model="selectedFund"
                    @change="getBorrowersByFund()"
                    :class="{
                      'is-invalid':
                        isSubmitted && $v.selectedFund.$error,
                    }"
                    :disabled="DisbursementToLTV"
                  >
                  <option disabled :value="null">--- Select ---</option>
                    <option
                      v-for="fund in Funds"
                      :value="fund.fund_id"
                      :key="fund.fund_id"
                    >
                      {{ fund.fund_name }}
                    </option>
                  </select>
                  <div
                    v-if="isSubmitted && $v.selectedFund.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.selectedFund.required"
                      >Fund Name is required</span
                    >
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                    <input
                      type="checkbox"
                      id="selectAllBorrower"
                      v-model="selectAllBorrower"
                      title="Select All Borrowers"
                      :disabled="DisbursementToLTV"
                    />
                    <label for="selectedBorrower" class="required-field"
                      >Borrower Name</label
                    >
                    <select
                      id="selectedBorrower"
                      class="form-control"
                      v-model="selectedBorrower"
                      @change="getFacilitySetupByFundBorrower()"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedBorrower.$error,
                      }"
                      :disabled="DisbursementToLTV || selectAllBorrower"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="borrower in borrowers"
                        :value="borrower.borrower_id"
                        :key="borrower.borrower_id"
                      >
                        {{ borrower.borrower_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedBorrower.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedBorrower.required"
                        >Borrower Name is required</span
                      >
                    </div>
                </div>
              </div>

              <div class="col-md-6 col-sm-5 col-6">
                <div class="form-group fc">
                  <input
                    type="checkbox"
                    id="selectAllFacility"
                    v-model="selectAllFacility"
                    title="Select All Facilities"
                    :disabled="DisbursementToLTV || selectAllBorrower"
                  />
                  <label class="required-field"
                    >Facility ID</label
                  >
                  <multiselect
                      v-model="selectedFacility"
                      :placeholder="facilities.length ? '--- Select ---' : 'No Facilities Available'"
                      label="facility_registration_id"
                      track-by="facility_registration_id"
                      :options="facilities"
                      :multiple="true"
                      :class="{ 'is-invalid': isSubmitted && $v.selectedFacility.$error }"
                      :disabled ="DisbursementToLTV || selectAllFacility"
                      @input="getCollateralByFacility()"
                    >
                    </multiselect>
                    <div
                      v-if="isSubmitted && $v.selectedFacility.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedFacility.required"
                        >At least 1 Facility is required</span
                      >
                    </div>
                </div>
              </div>

              <div class="col-md-6 col-sm-5 col-6">
                <div class="form-group fc">
                    <input
                      type="checkbox"
                      id="selectAllCollateral"
                      v-model="selectAllCollateral"
                      title="Select All Collateral Names"
                      :disabled="DisbursementToLTV || selectAllBorrower"
                    />
                    <label class="required-field"
                        >Collateral Name</label
                      >
                      <multiselect
                          v-model="selectedCollateralID"
                          :placeholder="collateralNames.length ? '--- Select ---' : 'No Collateral Available'"
                          label="collateral_name"
                          track-by="collateral_name"
                          :options="collateralNames"
                          :multiple="true"
                          :class="{ 'is-invalid': isSubmitted && $v.selectedCollateralID.$error }"
                          :disabled="DisbursementToLTV || selectAllCollateral"
                          >
                        </multiselect>
                        <div
                          v-if="isSubmitted && $v.selectedCollateralID.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedCollateralID.required"
                            >At least 1 Collateral is required</span
                          >
                        </div>
                    <!-- <select
                      id="selectedCollateralID"
                      class="form-control"
                      v-model="selectedCollateralID"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedCollateralID.$error,
                      }"
                      :disabled="DisbursementToLTV || selectAllCollateral"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="col in collateralNames"
                        :value="col.collateral_id"
                        :key="col.collateral_id"
                      >
                        {{ col.collateral_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCollateralID.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCollateralID.required"
                        >Collateral Name is required</span
                      >
                    </div> -->
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label class="required-field">Std. Currency(Fund Currency)</label>
                    <select
                      id="selectedCurrency"
                      class="form-control"
                      v-model="selectedCurrency"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedCurrency.$error,
                      }"
                      disabled
                    >
                      <option :value="null">Std. Currency(Fund Currency)</option>
                      <option
                        v-for="curr in currencies"
                        :value="curr.id"
                        :key="curr.id"
                      >
                        {{ curr.currency_short_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCurrency.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCurrency.required"
                        >Std. Currency(Fund Currency) is required</span
                      >
                    </div>
                  </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="ConversionDate" class="required-field">Conversion Date</label>
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      placeholder="Select Date"
                      v-model="ConversionDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{ to: new Date(backCapDate), from: new Date() }"
                      :class="{
                        'is-invalid': isSubmitted && $v.ConversionDate.$error,
                      }"
                      :disabled ="DisbursementToLTV"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.ConversionDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.ConversionDate.required">Conversion Date is required</span>
                    </div>
                  </div>
              </div>

              <!-- <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="input">Loan Reg. ID</label>
                  <select
                    id="selectedLoanId"
                    class="form-control"
                    name="selectedLoanId"
                    v-model="selectedLoanId"
                    @change="onLoanIdChange($event)"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="loan in loans"
                      :value="loan.loan_id"
                      :key="loan.loan_id"
                    >
                      {{ loan.loan_registration_id }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                :class="
                  loggedInUserGroupId === 'Borrower'
                    ? 'col-md-8 col-sm-5 col-6'
                    : 'col-md-5 col-sm-5 col-6'
                "
              ></div>
              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="input">Offtaker Name</label>
                  <select
                    id="selectedOfftakerId"
                    class="form-control"
                    name="selectedOfftakerId"
                    v-model="selectedOfftakerId"
                    :disabled="selectedLoanId"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="off in offtakers"
                      :value="off.offtaker_id"
                      :key="off.offtaker_id"
                    >
                      {{ off.offtaker_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="input" class="required-field">Price</label>
                  <br />
                  <input
                    type="radio"
                    id="market"
                    :value="price_types.length ? price_types[1].id : null"
                    v-model="selectedPriceType"
                  />
                  <label for="market">Market</label>
                  <input
                    type="radio"
                    id="offtaker"
                    :value="price_types.length ? price_types[0].id : null"
                    v-model="selectedPriceType"
                  />
                  <label for="offtaker">Offtaker</label>
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="input">Date</label>
                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="selectedDate"
                    placeholder="Select Date"
                    v-model="selectedDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fas fa-calendar"
                    :calendar-button="true"
                    disabled
                  />
                </div>
              </div> -->
              <div class="col-md-2 col-sm-5 col-6">
                <ul 
                  id="sr_btn"
                  class="d-flex justify-content-start"
                >
                  <li>
                    <button
                      id="filterLTV"
                      type="button"
                      class="btn-sm btn btn-primary sh_n mt"
                      @click="getTotalNetExpense()"
                      :disabled="DisbursementToLTV"
                    >
                      Filter
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh_n mt"
                      @click="filterResetLTV()"
                      :disabled="DisbursementToLTV"
                    >
                      Reset
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- <div class="divider-line"></div> -->

            <!-- <span class="error"
              >Note: Please press reset after applying filter</span
            > -->
          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-4 col-sm-6">
                        <h1 class="heading1">
                        <label style="font-size: 17px;">
                            Total Net Expenses : {{ totalNetExpenses }}
                          </label>
                        </h1>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <h1 class="heading1">
                          <label style="font-size: 17px;">
                            LTV to Value  % : {{ LTV_to_value }}
                          </label>
                        </h1>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <h1 class="heading1">
                          <label style="font-size: 17px;">
                            LTV to Company  % : {{ LTV_to_company }}
                          </label>
                        </h1>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-sm-6">
                        <h1 class="heading1">
                        <label style="font-size: 17px;">
                            Drawdown : {{ totalDrawdown }}
                          </label>
                        </h1>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <h1 class="heading1">
                          <label style="font-size: 17px;">
                            Cost : {{ totalCost }}
                          </label>
                        </h1>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <h1 class="heading1">
                          <label style="font-size: 17px;">
                            Repayment : {{ totalRepayment }}
                          </label>
                        </h1>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-sm-6">
                        <h1 class="heading1">
                        <label style="font-size: 17px;">
                            Accrued Interest : {{ AccruedInterest }}
                          </label>
                        </h1>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <h1 class="heading1">
                          <label style="font-size: 17px;">
                            Paid Interest : {{ InterestPaid }}
                          </label>
                        </h1>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <h1 class="heading1">
                          <label style="font-size: 17px;">
                            Equity Investment : {{ totalEquity }}
                          </label>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <!-- <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="totalNetExpenses">Net Expenses</label>
                  <input
                    type="number"
                    class=""
                    id="totalNetExpenses"
                    v-model="totalNetExpenses"
                    disabled
                  />
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="LTV">LTV %</label>
                  <input
                    type="number"
                    class=""
                    id="LTV"
                    v-model="LTV"
                    disabled
                  />
                </div>
              </div> -->
            </div>
          

          <div
            v-for="(collateralData) in allCollateralData"
            :key="collateralData.collateral_id"
            >
            <div class="container-fluid ot_tb pt-1">
              <div class="divider-line"></div>
            </div>
            <div class="container-fluid ot_tb pt-3">
              <div 
                class="card card-primary card-tabs"
              >
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="row">
                      <div
                        class="col-lg-6 col-sm-6"
                      >
                        <h1 class="headingt pt-3">
                          <i class="icofont-handshake-deal"></i> {{ collateralData.collateral_name }}
                          <!-- <i class="icofont-notebook">{{ collateralData.collateral_name }}</i>  -->
                        </h1>
                      </div>
                    </div>
                    <div
                      class="card-body table-responsive p-0"
                      style="padding: 15px 0px !important"
                    >
                      <!------menton here style="height: 300px;"-------->
                      <table
                        class="table table-head-fixed text-nowrap table-hover myTable1"
                        id="myTable1"
                      >
                        <thead>
                          <tr>
                            <th class="left col80 grey" title="Exposure">Exposure</th>
                            <th class="center col180 grey" title="Type of price">Type of price</th>
                            <th class="center col80 grey" title="Price">Price</th>
                            <th class="center col80 grey" title="Quantity (MT)">Quantity (MT)</th>
                            <th class="center col80 grey" title="Bags">Bags</th>
                            <th class="center col80 grey" title="Price Currency">Price Currency</th>
                            <th class="center col80 grey" title="Standard Currency">Std. Currency</th>
                            <th class="center col80 grey" title="Conversion Rate">Conv. Rate</th>
                            <th class="center col80 grey" title="Conversion Date">Conv. Date</th>
                            <th class="center col80 grey" title="Converted Collateral Value">Collateral Value (Conv.)</th>
                            <th class="center col80 grey" title="Discount Value %">Dis. Value %</th>
                            <th class="center col80 grey" title="Company Valuation">Company Valuation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr 
                          class=""
                          v-for="(collateral) in collateralData.exposures"
                          :key="collateral.id"
                          >
                            <td class="col80">
                              {{ collateral.exposure_name }}
                            </td>
                            <td class="center col180">
                              <div class="">
                                <select
                                class="form-control"
                                name="selectedPriceType"
                                v-model="collateral.selectedPriceType"
                                @change="getPriceByPriceType(collateralData.collateral_id, collateral.selectedPriceType, collateral.exposure_name, collateral)"
                              >
                                <option :value="null" disabled>--- Select ---</option>
                                <option
                                  v-for="p in collateralData.price_types"
                                  :value="p.price_name"
                                  :key="p.id"
                                >
                                  {{ p.price_name }}
                                </option>
                                </select>
                              </div>
                            </td>
                            <td class="center col80">
                              <div class="form-group fc">
                                <input
                                  type="number"
                                  class="custom-input"
                                  id="collateral.price"
                                  v-model="collateral.price"
                                  :disabled="collateral.selectedPriceType != 'Other Price (Input)'"
                                  @keypress="isDecimal($event)"
                                  @keyup="updateExposureValuesByPrice(collateralData.collateral_id, collateral.selectedPriceType, collateral.price, collateral.exposure_name, collateral)"
                                />
                              </div>
                              <!-- {{ collateral.price }} -->
                            </td>
                            <td v-if="collateral.quantity != null" class="right col80">
                              {{ collateral.quantity }}
                            </td>
                            <td v-else class="right col80">-</td>
                            <td v-if="collateral.total_bags != null" class="right col80">
                              {{ collateral.total_bags }}
                            </td>
                            <td v-else class="right col80">-</td>
                            <td class="center col80">
                              <div 
                                >
                                <select
                                  id="collateral.currency_id"
                                  class="form-control"
                                  v-model="collateral.currency_id"
                                  :disabled="collateral.selectedPriceType != 'Other Price (Input)'"
                                  @change="getPriceByPriceCurrency(collateralData.collateral_id, collateral.selectedPriceType, collateral.currency_id, collateral)"
                                >
                                  <option disabled :value="null">-- Select a Currency --</option>
                                  <option
                                    v-for="curr in currencies"
                                    :value="curr.id"
                                    :key="curr.id"
                                  >
                                    {{ curr.currency_short_name }}
                                  </option>
                                </select>
                              </div>
                            </td>
                            <td class="center col80">
                              {{ getCurrencyShortNameById(collateral.std_currency_id) }}
                              
                            </td>
                            <td class="right col80">
                              {{ collateral.conversion_rate }}
                            </td>
                            <td class="center col80">
                              {{ collateral.conversion_date }}
                            </td>
                            <td class="right col80">
                              <!-- {{ calculatedCollateralValue(collateral) }} -->
                              {{ collateral.collateral_value }}
                            </td>
                            <td class="right col80">
                              {{ collateral.discount_value }}
                            </td>
                            <td class="right col80">
                              <!-- {{ calculatedCompanyValuation(collateral) }} -->
                              {{ collateral.available_to_disburse }}
                            </td>
                          </tr>
                          <tr class="">
                            <td>Total</td>
                            <td class="right col180">-</td>
                            <td class="right col80">-</td>
                            <td class="right col80">{{ sumOfQuantities[collateralData.collateral_id] }}</td>
                            <td class="right col80">{{ sumOfBags[collateralData.collateral_id] }}</td>
                            <td class="right col80">-</td>
                            <td class="right col80">-</td>
                            <td class="right col80">-</td>
                            <td class="right col80">-</td>
                            <td class="right col80">{{ sumOfCollateralValue[collateralData.collateral_id] }}</td>
                            <td class="right col80">-</td>
                            <td class="right col80">{{ sumOfCompanyValuation[collateralData.collateral_id] }}</td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
            <div class="container-fluid ot_tb pt-1">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid" id="al_tb">
                    <div class="tp_mid">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <!-- <h1 class="headingt">
                            <i class="icofont-check-circled"></i> Transactions of {{ collateralData.collateral_name }}
                          </h1> -->
                          <button 
                            class="btn btn-primary headingt collapsebtn" 
                            @click="onTransactionsButtonClick(collateralData.collateral_id)"
                          >
                            <i class="icofont-check-circled"></i> Transactions of {{ collateralData.collateral_name }}       
                            <i v-if="collapsedSections[collateralData.collateral_id]" class="icofont-circled-down"></i>
                            <i v-else class="icofont-circled-up"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!collapsedSections[collateralData.collateral_id]" class="container-fluid tabtop" id="al_tb">
              <div class="tp_mid">
                <div class="row">
                  <div class="col-lg-12">
                    <ul class="nav nav-tabs" id="tab" role="tablist">
                      <li
                        class="nav-item"
                        v-if="
                          loggedInUserGroupId == 'Borrower' ||
                          loggedInUserGroupId == 'Collateral Manager' ||
                          loggedInUserGroupId == 'Admin' ||
                          (loggedInUserGroupId == 'Analyst' &&
                            loggedInUserSubGroupId == 0)
                        "
                      >
                        <a
                        :class="[
                          'nav-link',
                          selectedTabs[collateralData.collateral_id] === 'Uncleaned' ? 'active' : ''
                        ]"
                          id="uncleaned-tab"
                          data-toggle="pill"
                          href="#one"
                          @click="setTab(collateralData.collateral_id,'Uncleaned')"
                          role="tab"
                          aria-controls="uncleaned-tab"
                          aria-selected="true"
                          >Uncleaned</a
                        >
                      </li>
                      <li
                        class="nav-item"
                        v-if="
                          loggedInUserGroupId == 'Borrower' ||
                          loggedInUserGroupId == 'Collateral Manager' ||
                          loggedInUserGroupId == 'Admin' ||
                          (loggedInUserGroupId == 'Analyst' &&
                            loggedInUserSubGroupId == 0)
                        "
                      >
                        <a
                        :class="[
                            'nav-link',
                            selectedTabs[collateralData.collateral_id] === 'Cleaned' ? 'active' : ''
                          ]"
                          id="cleaned-tab"
                          data-toggle="pill"
                          href="#two"
                          @click="setTab(collateralData.collateral_id,'Cleaned')"
                          role="tab"
                          aria-controls="cleaned-tab"
                          aria-selected="false"
                          >Cleaned</a
                        >
                      </li>
                      <li
                        class="nav-item"
                        v-if="
                          loggedInUserGroupId == 'Borrower' ||
                          loggedInUserGroupId == 'Collateral Manager' ||
                          loggedInUserGroupId == 'Admin' ||
                          (loggedInUserGroupId == 'Analyst' &&
                            loggedInUserSubGroupId == 0)
                        "
                      >
                        <a
                        :class="[
                            'nav-link',
                            selectedTabs[collateralData.collateral_id] === 'Waste' ? 'active' : ''
                          ]"
                          id="waste-tab"
                          data-toggle="pill"
                          href="#three"
                          @click="setTab(collateralData.collateral_id,'Waste')"
                          role="tab"
                          aria-controls="waste-tab"
                          aria-selected="false"
                          >Waste</a
                        >
                      </li>
                      <li
                        class="nav-item"
                        v-if="
                          loggedInUserGroupId == 'Borrower' ||
                          loggedInUserGroupId == 'Analyst' ||
                          loggedInUserGroupId == 'Collateral Manager' ||
                          (loggedInUserGroupId == 'Admin' &&
                            loggedInUserSubGroupId == 0)
                        "
                      >
                        <a
                        :class="[
                            'nav-link',
                            selectedTabs[collateralData.collateral_id] === 'Logistics' ? 'active' : ''
                          ]"                          
                          id="logistics-tab"
                          data-toggle="pill"
                          href="#five"
                          @click="setTab(collateralData.collateral_id,'Logistics')"
                          role="tab"
                          aria-controls="logistics-tab"
                          aria-selected="false"
                          >Logistics</a
                        >
                      </li>
                      <li
                        class="nav-item"
                        v-if="
                          loggedInUserGroupId == 'Borrower' ||
                          loggedInUserGroupId == 'Collateral Manager' ||
                          loggedInUserGroupId == 'Analyst' ||
                          (loggedInUserGroupId == 'Admin' &&
                            loggedInUserSubGroupId == 0)
                        "
                      >
                        <a
                        :class="[
                            'nav-link',
                            selectedTabs[collateralData.collateral_id] === 'Shipping' ? 'active' : ''
                          ]"                          
                          id="shipping-tab"
                          data-toggle="pill"
                          href="#four"
                          @click="setTab(collateralData.collateral_id,'Shipping')"
                          role="tab"
                          aria-controls="shipping-tab"
                          aria-selected="false"
                          >Shipping</a
                        >
                      </li>
                      <li
                        class="nav-item"
                        v-if="
                          loggedInUserGroupId == 'Borrower' ||
                          loggedInUserGroupId == 'Collateral Manager' ||
                          loggedInUserGroupId == 'Analyst' ||
                          (loggedInUserGroupId == 'Admin' &&
                            loggedInUserSubGroupId == 0)
                        "
                      >
                        <a
                        :class="[
                            'nav-link',
                            selectedTabs[collateralData.collateral_id] === 'Delivered' ? 'active' : ''
                          ]"                          
                          id="delivered-tab"
                          data-toggle="pill"
                          href="#four"
                          @click="setTab(collateralData.collateral_id,'Delivered')"
                          role="tab"
                          aria-controls="delivered-tab"
                          aria-selected="false"
                          >Delivered</a
                        >
                      </li>
                    </ul>
                  </div>
                  
                  <!-- /.col -->
                </div>
              </div>
            </div>
            <div v-if="!collapsedSections[collateralData.collateral_id]" class="container-fluid ot_tb pt-2">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                    <div class="container-fluid" id="al_tb">
                      <div
                        class="card-body table-responsive p-0"
                        style="padding: 15px 0px !important"
                      >
                        <!------menton here style="height: 300px;"-------->
                        <table
                          v-if="filteredTransactions(collateralData.collateral_id).length > 0"
                          class="table table-head-fixed text-nowrap table-hover myTable1"
                          id="myTable1"
                        >
                          <thead>
                            <tr>
                              <th 
                                v-if="
                                selectedTabs[collateralData.collateral_id] === 'Uncleaned' || 
                                selectedTabs[collateralData.collateral_id] === 'Cleaned' || 
                                selectedTabs[collateralData.collateral_id] === 'Waste'" 
                                class="center col140 grey"
                              >Verification ID</th>
                              <th 
                                v-if="
                                selectedTabs[collateralData.collateral_id] === 'Logistics'"
                                class="center col140 grey"
                              >Logistics ID</th>
                              <th 
                                v-if="
                                selectedTabs[collateralData.collateral_id] === 'Shipping'"
                                class="center col140 grey"
                              >Shipping ID</th>
                              <th 
                                v-if="
                                selectedTabs[collateralData.collateral_id] === 'Delivered'"
                                class="center col140 grey"
                              >Logistics / Shipping ID</th>
                              <th class="center col140 grey">Quantity (MT)</th>
                              <th class="center col140 grey">Price Type</th>
                              <th class="center col140 grey">Price Per MT (Conv.)</th>
                              <th class="center col140 grey">Price Currency</th>
                            </tr>
                          </thead>
                          <tbody     
                            >
                            <tr 
                            class="odd"
                            v-for="(transactions) in filteredTransactions(collateralData.collateral_id)"
                            :key="transactions.trans_id"
                            >
                              <td v-if="transactions.verification_id" class="center col140">
                                {{ transactions.verification_id }}
                              </td>
                              <td v-else class="center col140">-</td>
                              <td v-if="transactions.quantity != null" class="right col140">
                                {{ transactions.quantity }}
                              </td>
                              <td v-else class="center col140">-</td>
                              <td class="left col140">
                                <!-- <div class="row">
                                  <div class="col-1">
                                    <input
                                    type="checkbox"
                                    id="select"
                                    :value="transactions.selectedTransaction"
                                    v-model="transactions.selectedTransaction"
                                    @change="updateCheckedTransactions(collateralData.collateral_id)"
                                  />
                                  </div>
                                  <div class="col-9 center-align">
                                    {{ transactions.selectedPriceType }}
                                  </div>
                                </div> -->
                                <input
                                    type="checkbox"
                                    style="margin-right: 80px !important"
                                    id="select"
                                    :value="transactions.selectedTransaction"
                                    v-model="transactions.selectedTransaction"
                                    @change="updateCheckedTransactions(collateralData.collateral_id)"
                                  />
                                  <span>
                                    {{ transactions.selectedPriceType }}
                                  </span>
                              </td>
                              <td v-if="transactions.converted_price != null" class="right col140">
                                {{ transactions.converted_price }}
                              </td>
                              <td v-else class="center col140">-</td>
                              <td class="center col140">
                                {{ getCurrencyShortNameById(transactions.currency_id) }}
                              </td>
                            </tr>
                            <tr class="">
                              <td class="center col140">Total</td>
                              <td class="right col140">{{ sumOfSelectedTabQuantities(collateralData.collateral_id) }}</td>
                              <td class="center col140">-</td>
                              <td class="right col140">-</td>
                              <td class="center col140">-</td>
                            </tr>
                          </tbody>
                        </table>
                        <div v-else>
                          <tr>
                            No transactions available for the selected tab.
                          </tr>
                        </div>
                      </div>
                    </div>
                    <div v-if="filteredTransactions(collateralData.collateral_id).length > 0" 
                      class="container-fluid" style="margin-top: -10px !important;">
                      <div class="row">
                        <div class="col-md-2 col-sm-5 col-6">
                          <div class="form-group fc">
                            <label for="selectedPriceTypeTransaction" class="required-field"
                              >Price Type</label
                            >
                            <select
                            class="form-control"
                            name="selectedPriceTypeTransaction"
                            v-model="collateralData.selectedPriceTypeTransaction"
                            :disabled="!areAnyTransactionsChecked(collateralData.collateral_id)"
                            >
                              <option :value="null">--- Select ---</option>
                                <option
                                  v-for="p in collateralData.transaction_price_types"
                                  :value="p.price_name"
                                  :key="p.id"
                                >
                                  {{ p.price_name }}
                                </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-5 col-6">
                          <div class="form-group fc">
                            <label for="selectedCurrencyTransaction" class="required-field"
                              >Price Currency</label
                            >
                            <select
                            class="form-control"
                            name="selectedCurrencyTransaction"
                            v-model="collateralData.selectedCurrencyTransaction"
                            :disabled="!areAnyTransactionsChecked(collateralData.collateral_id)"
                            >
                            <option :value="null">-- Select a Currency --</option>
                              <option
                                v-for="curr in currencies"
                                :value="curr.id"
                                :key="curr.id"
                              >
                                {{ curr.currency_short_name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-5 col-6">
                          <ul 
                            id="sr_btn"
                            style="margin-top: 5px !important"
                          >
                            <li>
                              <button
                                type="button"
                                class="btn-sm btn btn-primary sh_n mt"
                                @click="getPriceForAllTransactionsSelected(collateralData.collateral_id)"
                              >
                                Submit
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                class="btn-sm btn btn-dark sh_n mt"
                                @click="resetTransactionPriceTypeCurrency(collateralData.collateral_id)"
                              >
                                Reset
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="DisbursementToLTV" class="container-fluid">
            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-success sh"
                      v-on:click="onSubmit(true)"
                    >
                      Submit
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      v-on:click="onSubmit(false)"
                    >
                      Cancel
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        <!-- /.content-->
      </div>
    </div>
    <!-- /.content-wrapper -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {
  required,
  maxLength,
  minLength,
  numeric,
  alphaNum,
  maxValue,
  decimal,
  between,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      checkedTransactions: [],
      selectedTabs: {}, // Initialize an empty object to store selected tabs for each collateral
      collapsedSections: {},
      // allCollateralData: [],
      activeTab: 'Uncleaned', // Initial active tab
      allCollateralData: [],
      exposures: [],
      transactions: [],
      exposureTotal: { ltv_to_value: "", ltv_dis_value: "" },
      loanSummary: [],
      borrowerSummary: [],
      shipping: [],
      borrowers: [],
      Funds:[],
      offtakers: [],
      facilities: [],
      collateralNames: [],
      currencies: [],
      loans: [],
      loanData: [],
      price_types: [],
      // Basic details
      selectedBorrower: null,
      selectedFund:null,
      selectedFacility: [],
      selectedFacilityIds:[],
      selectedCollateralID: [],
      selectedCurrency: null,
      ConversionDate: new Date(),
      totalNetExpenses:"0.00",
      AccruedInterest:"0.00",
      InterestPaid:"0.00",
      totalDrawdown:"0.00",
      totalCost:"0.00",
      totalRepayment:"0.00",
      totalEquity:"0.00",
      LTVCompany: null,
      LTVValue: null,
      
      fundCurrency:null,
      LTVfromDis: null,
      DisbursementDate: null,
      selectedDisbursementOn: null,
      collateralNamesFromLoan: [],
      approvalDate:null,
      loanID: null,
      flow:null,
      
      
      selectAllFacility:false,
      selectAllBorrower:false,
      selectAllCollateral:false,
      firstTimeFilteredCalled:false,
      
      backCapDate:new Date(moment(new Date()).subtract(10, "year")),
      selectedDate: moment().format("MM-DD-YYYY"),
      
      totalColateralValue: null,
      
      DisbursementToLTV: false,
      
      isSubmitted: false,
    };
  },
  validations : {
    selectedBorrower: {
      required: function (val) {
        if (val == null && this.selectAllBorrower == false) {
          return false;
        } else {
          return true;
        }
      },
    },
    selectedFund: {
      required,
    },
    selectedFacility: {
      required: function (val) {
        if (this.selectedFacility.length == 0 && this.selectAllFacility == false) {
          return false;
        } else {
          return true;
        }
      },
    },
    selectedCollateralID: {
      required: function (val) {
        if (this.selectedCollateralID.length == 0 && this.selectAllCollateral == false) {
          return false;
        } else {
          return true;
        }
      },
    },
    selectedCurrency: {
      required,
    },
    ConversionDate: {
      required,
    },
  },
  components: {
    Datepicker,
    Multiselect: window.VueMultiselect.default,
  },
  methods: {
    // isDecimal(e) {
    //   let invalidChars = ["-", "+", "e", "E"];
    //   if (invalidChars.includes(e.key)) return e.preventDefault();
    //   else return true;
    // },

    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      let inputValue = e.target.value;
      let decimalIndex = inputValue.indexOf(".");
      
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else if (decimalIndex !== -1 && inputValue.substring(decimalIndex + 1).length >= 2)
        return e.preventDefault();
      else return true;
    },

    // this function will be called to get last business date of selected month
    // getLastDayofMonth() {
    //   let date = moment(this.selectedDate).endOf("month").format("MM-DD-YYYY");
    //   // console.log(moment(date).day());
    //   // check if last day of selected month is Saturday or Sunday, assign last friday if true
    //   if (moment(date).day() === 0 || moment(date).day() === 6)
    //     this.selectedDate = moment(date)
    //       .subtract(6, "days")
    //       .day(5)
    //       .format("MM-DD-YYYY");
    //   else this.selectedDate = date;
    // },

    // Reset Filters
    filterResetLTV(){
      this.isSubmitted = false;
      this.selectedFund = null;
      this.selectedBorrower = null;
      this.borrowers = [];
      this.selectAllBorrower = false;
      this.selectedFacility = [];
      this.selectAllFacility = false;
      this.facilities = [];
      this.selectAllCollateral = false;
      this.selectedCollateralID = [];
      this.collateralNames = [];
      this.selectedCurrency = null;
      this.ConversionDate = null;
      this.backCapDate = null;
    },

    getPriceForAvgPrice(collateralId, selectedPriceTypeName,exposureName, exposure){
      console.log(" in getPriceForAvgPrice")
      console.log("exposure in avg",exposure)
      const selectedCollateral = this.allCollateralData.find(c => c.id === collateralId);
      const selectedPriceType = selectedCollateral.price_types.find(p => p.price_name === selectedPriceTypeName);
      console.log('Selected Price:', selectedPriceType);

      let conversionDate = null;
      if (this.ConversionDate != null) {
        conversionDate = moment(this.ConversionDate).format("MM-DD-YYYY");
      }

      console.log("exposureName",exposureName)
      const transactions = selectedCollateral.transactions.filter(transaction => transaction.exposure_name === exposureName);
      console.log("transactions",transactions)
      const path = 
        "get-avgPrice" +
        "/" +
        collateralId +
        "/" +
        selectedPriceType.price_type_id +
        "/" +
        null +
        "/" +
        this.selectedCurrency +
        "/" +
        conversionDate
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id , selectedTransaction : transactions } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            let data = res.data
              if (selectedCollateral && selectedPriceType) {
              // Update the selectedPriceType for the exposure
              exposure.selectedPriceType = selectedPriceTypeName;
              exposure.price_type_id = selectedPriceType.price_type_id;

              // Calculate and set the new price directly for the exposure
              if (exposure.selectedPriceType !== null) {
                exposure.price = data.total_average_price;
                exposure.currency_id = data.currency_id;
                exposure.conversion_rate = data.conversion_rate;
                exposure.conversion_date = data.conversion_date;
              }

              // Update other properties based on the new price for the exposure
              exposure.collateral_value = this.calculatedCollateralValue(exposure);
              exposure.available_to_disburse = this.calculatedCompanyValuation(exposure);
            }
          } else {
            Utils.make_alert("warning","Something went wrong from server!")
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });

    },

    
    getPriceByPriceType(collateralId, selectedPriceTypeName,exposureName, exposure) {
      console.log(" in getPriceByPriceType")
      const selectedCollateral = this.allCollateralData.find(c => c.id === collateralId);
      const selectedPriceType = selectedCollateral.price_types.find(p => p.price_name === selectedPriceTypeName);
      console.log('Selected Price:', selectedPriceType);
      if(selectedPriceTypeName == 'Average of All prices selected'){
        this.getPriceForAvgPrice(collateralId, selectedPriceTypeName,exposureName, exposure);
        return;
        // // Update the selectedPriceType for the exposure
        // exposure.selectedPriceType = selectedPriceTypeName;
        // // Find the transactions for the specified exposureName
        // const transactionsForExposure = selectedCollateral.transactions.filter(t => t.exposure_name === exposureName);
        // // Calculate the sum of prices from the transactions
        // const sumOfPrices = transactionsForExposure.reduce((sum, transaction) => {
        //   return sum + transaction.price;
        // }, 0);
        // if(transactionsForExposure.length > 0){
        //   let avgPrice = sumOfPrices / transactionsForExposure.length;
        //   // Update the exposure's price with the calculated sum
        //   exposure.price = parseFloat(avgPrice).toFixed(2);
        // }else {
        //   exposure.price = null;
        // }
        
        // // Update other properties based on the new price for the exposure
        // exposure.collateral_value = this.calculatedCollateralValue(exposure);
        // exposure.available_to_disburse = this.calculatedCompanyValuation(exposure);
        // return;
      }

      // if (selectedCollateral && selectedPriceType) {
      //   // Update the selectedPriceType for the exposure
      //   exposure.selectedPriceType = selectedPriceTypeName;

      //   // Calculate and set the new price directly for the exposure
      //   if (exposure.selectedPriceType !== null) {
      //     const calculatedValue = selectedPriceType.price_value;
      //     console.log('Calculated Value:', calculatedValue);
      //     exposure.price = calculatedValue;
      //     exposure.price_type_id = selectedPriceType.price_type_id;
      //   }

      //   // Update other properties based on the new price for the exposure
      //   exposure.collateral_value = this.calculatedCollateralValue(exposure);
      //   exposure.available_to_disburse = this.calculatedCompanyValuation(exposure);
      // }
      let conversionDate = null;
      if (this.ConversionDate != null) {
        conversionDate = moment(this.ConversionDate).format("MM-DD-YYYY");
      }
      
      const path = 
        "get-price-by-price-type-currency" +
        "/" +
        collateralId +
        "/" +
        selectedPriceType.price_type_id +
        "/" +
        null +
        "/" +
        this.selectedCurrency +
        "/" +
        conversionDate
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            let data = res.data.price_data[0]
              if (selectedCollateral && selectedPriceType) {
              // Update the selectedPriceType for the exposure
              exposure.selectedPriceType = selectedPriceTypeName;
              exposure.price_type_id = selectedPriceType.price_type_id;

              // Calculate and set the new price directly for the exposure
              if (exposure.selectedPriceType !== null) {
                exposure.price = data.price;
                exposure.currency_id = data.currency_id;
                exposure.conversion_rate = data.conversion_rate;
                if (data.conversion_date != '-' && data.conversion_date != null) {
                  data.conversion_date = moment(data.conversion_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  data.conversion_date = '-';
                }
                exposure.conversion_date = data.conversion_date;
              }

              // Update other properties based on the new price for the exposure
              exposure.collateral_value = this.calculatedCollateralValue(exposure);
              exposure.available_to_disburse = this.calculatedCompanyValuation(exposure);
            }
          } else {
            Utils.make_alert("warning","Something went wrong from server!")
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });

    },


    getPriceByPriceCurrency(collateralId, selectedPriceTypeName, selectedPriceCurrency, exposure) {
      console.log(" in getPriceByPriceCurrency")
      const selectedCollateral = this.allCollateralData.find(c => c.id === collateralId);
      const selectedPriceType = selectedCollateral.price_types.find(p => p.price_name === selectedPriceTypeName);
      let conversionDate = null;
      if (this.ConversionDate != null) {
        conversionDate = moment(this.ConversionDate).format("MM-DD-YYYY");
      }
      
      const path = 
        "get-price-by-price-type-currency" +
        "/" +
        collateralId +
        "/" +
        selectedPriceType.price_type_id +
        "/" +
        selectedPriceCurrency +
        "/" +
        this.selectedCurrency +
        "/" +
        conversionDate
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            let data = res.data.price_data[0]
              if (selectedCollateral && selectedPriceType) {
              // Update the selectedPriceType for the exposure
              exposure.selectedPriceType = selectedPriceTypeName;
              exposure.price_type_id = selectedPriceType.price_type_id;

              // Calculate and set the new price directly for the exposure
              if (exposure.selectedPriceType !== null) {
                // exposure.price = data.price;
                exposure.currency_id = selectedPriceCurrency;
                exposure.conversion_rate = data.conversion_rate;
                if (data.conversion_date != '-' && data.conversion_date != null) {
                  data.conversion_date = moment(data.conversion_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  data.conversion_date = '-';
                }
                exposure.conversion_date = data.conversion_date;
              }

              // Update other properties based on the new price for the exposure
              exposure.collateral_value = this.calculatedCollateralValue(exposure);
              exposure.available_to_disburse = this.calculatedCompanyValuation(exposure);
            }
          } else {
            Utils.make_alert("warning","Something went wrong from server!")
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });

    },


    updateExposureValuesByPrice(collateralId, selectedPriceTypeName, Price, exposureName , exposure) {
    console.log(" in updateExposureValuesByPrice");

    // Find selected collateral and price from the provided data
    const selectedCollateral = this.allCollateralData.find(c => c.id === collateralId);
    const selectedPriceType = selectedCollateral.price_types.find(p => p.price_name === selectedPriceTypeName);
    console.log('Selected Price:', selectedPriceType);

    // Initialize variables
    let finalPrice = Price;
    let PriceFloat = parseFloat(Price);

    // Handle the case where PriceFloat is NaN
    if (isNaN(PriceFloat)) { // Check if Price is a valid number
      finalPrice = 0
    }  

    // Check if the finalPrice string length is greater than 15 characters
    if (finalPrice.length > 15) {
      // Truncate the finalPrice to 15 characters
      finalPrice = finalPrice.slice(0, 15);
      Utils.make_alert("Warning", "Max value of 15 digits exceeded");
    }


    // Check if selectedCollateral, selectedPriceType, and finalPrice are not empty
    if (selectedCollateral && selectedPriceType && finalPrice != 0) {
      // Update the selectedPriceType for the exposure
      exposure.selectedPriceType = selectedPriceTypeName;

      // Calculate and set the new price directly for the exposure
      if (exposure.selectedPriceType !== null) {
        const calculatedValue = finalPrice;
        console.log('Calculated Value:', calculatedValue);
        exposure.price = calculatedValue;
      }

      // Update other properties based on the new price for the exposure
      exposure.collateral_value = this.calculatedCollateralValue(exposure);
      exposure.available_to_disburse = this.calculatedCompanyValuation(exposure);
    } else {
      // Handle warnings and fallback logic
      Utils.make_alert("Warning", "Cannot enter 0 or null in price for any exposure");
      this.getPriceByPriceType(collateralId, 1,exposureName, exposure);
    }
  },


    // Clear selectedPriceTypeTransaction and selectedCurrencyTransaction
    resetTransactionPriceTypeCurrency(collateralId){
      const selectedCollateral = this.allCollateralData.find(c => c.id === collateralId);
      selectedCollateral.selectedPriceTypeTransaction = null;
      selectedCollateral.selectedCurrencyTransaction = null;
    },


    // Function to change the price type and currency for selected transactions
    getPriceForAllTransactionsSelected(collateralId) {
      console.log('getPriceForAllTransactionsSelected method invoked');
      const selectedCollateral = this.allCollateralData.find(c => c.id === collateralId);
      const selectedPriceTypeTransaction = selectedCollateral.selectedPriceTypeTransaction;
      const selectedCurrencyTransaction = selectedCollateral.selectedCurrencyTransaction;
      console.log("----------------------------------------------------------------")
      console.log("selectedPriceTypeTransaction",selectedPriceTypeTransaction)
      let selectedPriceType = null;
      let priceCurrency = null;
      if(selectedPriceTypeTransaction == null && selectedCurrencyTransaction == null) {
        Utils.make_alert("warning", `Please select a price type and currency for collateral : ${selectedCollateral.collateral_name}`);
          return;
      }
      if(selectedPriceTypeTransaction != null) {
        selectedPriceType = selectedCollateral.transaction_price_types.find(price => price.price_name === selectedPriceTypeTransaction).price_type_id;
      } else{
          Utils.make_alert("warning", `Please select a price type for collateral : ${selectedCollateral.collateral_name}`);
          return;
      }
      if(selectedCurrencyTransaction != null) {
        priceCurrency = this.currencies.find(currencies => currencies.id === selectedCurrencyTransaction);
      } else{
          Utils.make_alert("warning", `Please select a currency for collateral : ${selectedCollateral.collateral_name}`);
          return;
      }

      // selectedCollateral.transactions.forEach(transaction => {
      //     if (transaction.selectedTransaction) {
      //       // Create a new transaction object with the updated properties
      //       const updatedTransaction = {
      //         ...transaction,
      //         selectedPriceType: selectedPriceTypeTransaction,
      //         price: selectedPriceType,
      //         currency_id: selectedCurrencyTransaction,
      //         conversion_rate: 1.4,
      //         selectedTransaction: false,
      //       };
            
      //       // Use Vue.set to update the transaction object
      //       this.$set(transaction, 'selectedPriceType', updatedTransaction.selectedPriceType);
      //       this.$set(transaction, 'price', updatedTransaction.price);
      //       this.$set(transaction, 'currency_id', updatedTransaction.currency_id);
      //       this.$set(transaction, 'conversion_rate', updatedTransaction.conversion_rate);
      //       this.$set(transaction, 'selectedTransaction', updatedTransaction.selectedTransaction);

      //       transaction.conversion_rate = this.calculatedConvertedPrice(transaction);

      //     }
      //   });

      // selectedCollateral.selectedPriceTypeTransaction = null;
      // selectedCollateral.selectedCurrencyTransaction = null;

      // selectedCollateral.exposures.forEach(exposure => {
      //   console.log(exposure.selectedPriceType)
      //   if(exposure.selectedPriceType == 'Average of All prices selected'){
      //       // Find the transactions for the specified exposureName
      //       const transactionsForExposure = selectedCollateral.transactions.filter(t => t.exposure_name === exposure.exposure_name);
      //       // Calculate the sum of prices from the transactions
      //       const sumOfPrices = transactionsForExposure.reduce((sum, transaction) => {
      //         return sum + transaction.price;
      //       }, 0);
      //       if(transactionsForExposure.length > 0){
      //         let avgPrice = sumOfPrices / transactionsForExposure.length;
      //         // Update the exposure's price with the calculated sum
      //         console.log(avgPrice)
      //         exposure.price = parseFloat(avgPrice).toFixed(2);
      //       }else {
      //         exposure.price = null;
      //       }

      //       // Update other properties based on the new price for the exposure
      //       exposure.collateral_value = this.calculatedCollateralValue(exposure);
      //       exposure.available_to_disburse = this.calculatedCompanyValuation(exposure);
      //       }
      // });

      let conversionDate = null;
      if (this.ConversionDate != null) {
        conversionDate = moment(this.ConversionDate).format("MM-DD-YYYY");
      }
      
      const path = 
        "get-price-by-price-type-currency" +
        "/" +
        collateralId +
        "/" +
        selectedPriceType +
        "/" +
        priceCurrency.id +
        "/" +
        this.selectedCurrency +
        "/" +
        conversionDate
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            let data = res.data.price_data[0]
            if (selectedCollateral && selectedPriceType) {
            selectedCollateral.transactions.forEach(transaction => {
            if (transaction.selectedTransaction) {
              // Create a new transaction object with the updated properties
              const updatedTransaction = {
                ...transaction,
                selectedPriceType: selectedPriceTypeTransaction,
                price: data.price,
                currency_id: priceCurrency.id,
                conversion_rate: data.conversion_rate,
                selectedTransaction: false,
              };
              
              // Use Vue.set to update the transaction object
              this.$set(transaction, 'selectedPriceType', updatedTransaction.selectedPriceType);
              this.$set(transaction, 'price', updatedTransaction.price);
              this.$set(transaction, 'currency_id', updatedTransaction.currency_id);
              this.$set(transaction, 'conversion_rate', updatedTransaction.conversion_rate);
              this.$set(transaction, 'selectedTransaction', updatedTransaction.selectedTransaction);

              transaction.converted_price = this.calculatedConvertedPrice(transaction);
            }
          });
          selectedCollateral.selectedPriceTypeTransaction = null;
          selectedCollateral.selectedCurrencyTransaction = null;
          // calculate average
          let exposureName = this.selectedTabs[collateralId];
          console.log("exposureName",exposureName)
          let exposure = selectedCollateral.exposures.find(data => data.exposure_name === exposureName);
          console.log("exposure",exposure)
            let selectedPriceTypeName = exposure.selectedPriceType
            console.log("selectedPriceTypeName",selectedPriceTypeName)
            if(selectedPriceTypeName == 'Average of All prices selected'){
              this.getPriceForAvgPrice(collateralId, selectedPriceTypeName,exposureName, exposure);
            }
          


          // selectedCollateral.exposures.forEach(exposure => {
          //   if(exposure.selectedPriceType == 'Average of All prices selected'){
          //       // Find the transactions for the specified exposureName
          //       const transactionsForExposure = selectedCollateral.transactions.filter(t => t.exposure_name === exposure.exposure_name);
          //       // Calculate the sum of prices from the transactions
          //       const sumOfPrices = transactionsForExposure.reduce((sum, transaction) => {
          //         return sum + transaction.price;
          //       }, 0);
          //       if(transactionsForExposure.length > 0){
          //         let avgPrice = sumOfPrices / transactionsForExposure.length;
          //         // Update the exposure's price with the calculated sum
          //         console.log(avgPrice)
          //         exposure.price = parseFloat(avgPrice).toFixed(2);
          //       }else {
          //         exposure.price = null;
          //       }

          //       // Update other properties based on the new price for the exposure
          //       exposure.collateral_value = this.calculatedCollateralValue(exposure);
          //       exposure.available_to_disburse = this.calculatedCompanyValuation(exposure);
          //       }
          // });
          }
        } else {
          Utils.make_alert("warning","Something went wrong from server!")
        }
      })
      .catch((error) => {
        console.error(error);
        Utils.stop_loading();
      });
    },
    
    
    // Update your filteredTransactionsForCheckbox method
    filteredTransactionsForCheckbox(collateralId) {
      const selectedTab = this.selectedTabs[collateralId];
      const selectedCollateral = this.allCollateralData.find(collateral => collateral.id === collateralId);
      
      if (selectedCollateral && selectedCollateral.transactions) {
        return selectedCollateral.transactions.filter(transaction => transaction.exposure_name === selectedTab);
      } else {
        return []; // Return an empty array if the transactions array is missing
      }
    },

    // Update your updateCheckedTransactions method
    updateCheckedTransactions(collateralId) {
      const transactions = this.filteredTransactionsForCheckbox(collateralId);
      console.log("updateCheckedTransactions",transactions)
      if (transactions && transactions.length > 0) {
        this.checkedTransactions = transactions
          .filter(trans => trans.selectedTransaction)
          .map(trans => trans.trans_id);
      } else {
        this.checkedTransactions = [];
      }
      // this.areAnyTransactionsChecked(collateralId)
      this.$forceUpdate(); // Trigger a re-render to update the table content
    },


    sumOfSelectedTabQuantities(collateralDataId) {
      const selectedTab = this.selectedTabs[collateralDataId];
      const selectedTabTransactions = this.allCollateralData.find(data => data.id === collateralDataId)?.transactions.filter(transaction => transaction.exposure_name === selectedTab) || [];
      const sum = selectedTabTransactions.reduce((sum, transaction) => {
        const quantityValue = transaction.quantity ? parseFloat(transaction.quantity.replace(/,/g, '')) : 0;
        return sum + quantityValue;
      }, 0);
      return sum.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },


    setTab(collateralId, exposureName) {
      console.log("collateralId",collateralId)
      console.log("exposureName",exposureName)
      this.$set(this.selectedTabs, collateralId, exposureName); // Use Vue's $set to ensure reactivity

      // this.selectedTabs[collateralId] = exposureName; // Update the selected tab for the collateral
      
      // Call filteredTransactions to re-evaluate the computed property
      this.filteredTransactions(collateralId);

      // let sumOfSelectedTabQuantities = this.sumOfSelectedTabQuantities(collateralId);
      // console.log(sumOfSelectedTabQuantities)

         // Update filtered transactions after changing the selected tab
      this.$forceUpdate(); // Trigger a re-render to update the table content
    },


    onTransactionsButtonClick(collateralId) {
      // Handle the click event
      console.log('Button clicked for collateral ID:', collateralId);
      // Add your logic here
      this.collapsedSections[collateralId] = !this.collapsedSections[collateralId];
      this.$forceUpdate()
      console.log(this.collapsedSections);
      console.log("this.collapsedSections[collateralId]",this.collapsedSections[collateralId]);
    },

    // setTab(collateralId, exposureName) {
    //   console.log("collateralId",collateralId)
    //   console.log("exposureName",exposureName)
    //   // this.selectedTabs[collateralId] = exposureName; // Update the selected tab for the collateral
    //   this.$set(this.selectedTabs, collateralId, exposureName); // Use Vue's $set to ensure reactivity
    //   console.log(this.selectedTabs)

    //   // Update filtered transactions after changing the selected tab
    //   // this.$forceUpdate(); // Trigger a re-render to update the table content
    // },

    calculateSumOfSelectedTabQuantities(collateralId, selectedTab) {
    const transactions = this.filteredTransactions(collateralId);
    const selectedTabTransactions = transactions.filter(transaction => transaction.exposure_name === selectedTab);
    return selectedTabTransactions.reduce((total, transaction) => total + transaction.quantity, 0);
  },

    getPriceTypeById(priceId) {
    return this.price_types.find(price => price.id === priceId) || { price_value: '-' , price_name:'-' };
    },

    getCollateralNameById(collateralId) {
      const collateralName = this.collateralNames.find(col => col.collateral_id === collateralId);
      if (collateralName) {
        return collateralName.collateral_name;
      } else {
        return '-'
      }
    },

    getCurrencyShortNameById(currencyId) {
      const foundCurrency = this.currencies.find(currency => currency.id === currencyId);
      if (foundCurrency) {
        return foundCurrency.currency_short_name;
      } else {
        return '-'
      }
    },

     // this method will check if existing loan mapping is added again
    getTotalNetExpense() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      console.log("inside getTotalNetExpense")
      let conversionDate = null;
      if (this.ConversionDate != null) {
        conversionDate = moment(this.ConversionDate).format("MM-DD-YYYY");
      }
      let finalSelectedBorrower = null
      if(this.selectAllBorrower === true){
        finalSelectedBorrower = this.borrowers.map(borrower => borrower.borrower_id);
      } else{
        finalSelectedBorrower = this.selectedBorrower
      }
      if(finalSelectedBorrower.length == 0){
        finalSelectedBorrower = null
      }
      let finalSelectedFacility = null
      if(this.selectAllFacility === true){
        finalSelectedFacility = this.facilities.map(facility => facility.id);
      } else {
        if(this.selectedFacility.length > 0){
          finalSelectedFacility = this.selectedFacility.map(facility => facility.id);
        }
        else{
          finalSelectedFacility = null;
        }
      }
      if(finalSelectedFacility.length == 0){
        finalSelectedFacility = null
      }

      if (finalSelectedFacility == null ){
        Utils.make_alert("warning","No Facilities Available for the selected Borrower")
        return
      }

      this.totalNetExpenses =  "0.00"
      this.AccruedInterest =  "0.00"
      this.totalRepayment =  "0.00"
      this.totalDrawdown =  "0.00"
      this.totalCost =  "0.00"
      this.totalEquity =  "0.00"
      this.InterestPaid =  "0.00"
      this.allCollateralData = [];
    
      const path = 
        "/total-net-expense" +
        "/" +
        finalSelectedBorrower +
        // this.selectedBorrower +
        "/" +
        finalSelectedFacility +
        "/" +
        this.selectedCurrency +
        "/" +
        conversionDate +
        "/" +
        this.selectAllBorrower 
        console.log(path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            console.log(res.data.total_net_expense)
            console.log(res.data.total_net_expense[0].net_expense)
            if(res.data.total_net_expense[0].net_expense != null){
              if(res.data.total_net_expense[0].net_expense){
                this.totalNetExpenses =  res.data.total_net_expense[0].net_expense;
              } else {
                this.totalNetExpenses =  "0.00"
              }
              if(res.data.total_net_expense[0].disbursement_amount){
                this.totalDrawdown =  res.data.total_net_expense[0].disbursement_amount;
              } else {
                this.totalDrawdown =  "0.00"
              }
              if(res.data.total_net_expense[0].outstanding_amount){
                this.totalCost =  res.data.total_net_expense[0].outstanding_amount;
              } else {
                this.totalCost =  "0.00"
              }
              if(res.data.total_net_expense[0].repaid_amount){
                this.totalRepayment =  res.data.total_net_expense[0].repaid_amount;
              } else {
                this.totalRepayment =  "0.00"
              }
              if(res.data.total_net_expense[0].accrued_interest){
                this.AccruedInterest =  res.data.total_net_expense[0].accrued_interest;
              } else {
                this.AccruedInterest =  "0.00"
              }
              if(res.data.total_net_expense[0].paid_interest){
                this.InterestPaid =  res.data.total_net_expense[0].paid_interest;
              } else {
                this.InterestPaid =  "0.00"
              }
              if(res.data.total_net_expense[0].total_equity){
                this.totalEquity =  res.data.total_net_expense[0].total_equity;
              } else {
                this.totalEquity =  "0.00"
              }
            } else {
              this.totalNetExpenses =  "0.00"
              this.AccruedInterest =  "0.00"
              this.totalRepayment =  "0.00"
              this.totalDrawdown =  "0.00"
              this.totalCost =  "0.00"
              this.totalEquity =  "0.00"
              this.InterestPaid =  "0.00"
            }
            this.getCollateralExposures();
          } else {
            Utils.make_alert("warning","Something went wrong from server!")
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });

    },

    getCollateralExposures() {
      console.log("inside getCollateralExposures")
      let conversionDate = null;
      if (this.ConversionDate != null) {
        conversionDate = moment(this.ConversionDate).format("MM-DD-YYYY");
      }
      let finalSelectedBorrower = null
      if(this.selectAllBorrower === true){
        finalSelectedBorrower = this.borrowers.map(borrower => borrower.borrower_id);
      } else{
        finalSelectedBorrower = this.selectedBorrower
      }
      if(finalSelectedBorrower.length == 0){
        finalSelectedBorrower = null
      }
      let finalSelectedFacility = null
      if(this.selectAllFacility == true){
        finalSelectedFacility = this.facilities.map(facility => facility.id);
      } else {
        if(this.selectedFacility.length > 0){
          console.log("this.selectedFacility.length",this.selectedFacility.length)
          finalSelectedFacility = this.selectedFacility.map(facility => facility.id);
        }
        else{
          finalSelectedFacility = null;
        }
      }
      if(finalSelectedFacility.length == 0){
        finalSelectedFacility = null
      }

      let finalSelectedCollateral = null
      if(this.selectAllCollateral == true){
        finalSelectedCollateral = this.collateralNames.map(col => col.collateral_id);
      } else {
        if(this.selectedCollateralID.length > 0){
          console.log("this.selectedCollateralID.length",this.selectedCollateralID.length)
          console.log("this.selectedCollateralID",this.selectedCollateralID)
          finalSelectedCollateral = this.selectedCollateralID.map(collateral => collateral.collateral_id);
        }
        else{
          finalSelectedCollateral = null;
        }
      }
      if(finalSelectedCollateral.length == 0){
        finalSelectedCollateral = null
      }
    
      console.log("finalSelectedCollateral",finalSelectedCollateral)
      const path = 
        "get-exposure-data" +
        "/" +
        finalSelectedBorrower +
        "/" +
        finalSelectedFacility +
        "/" +
        finalSelectedCollateral +
        "/" +
        this.selectedCurrency +
        "/" +
        conversionDate +
        "/" +
        this.selectAllBorrower 
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            this.exposures =  res.data.exposures;
            
            const combinedData = {};

            this.exposures.forEach(exposure => {
              if (!combinedData[exposure.collateral_id]) {
                combinedData[exposure.collateral_id] = {
                  id: exposure.collateral_id,
                  collateral_id: exposure.collateral_id,
                  collateral_name: exposure.collateral_name,
                  exposures: [],
                };
              }
              combinedData[exposure.collateral_id].exposures.push(exposure);
            });

            // Convert the combinedData object to an array of objects
            const result = Object.values(combinedData);

            // Assign the result to your data property
            this.allCollateralData = result;

            // Loop through allCollateralData and add transactions
            this.allCollateralData.forEach(collateral => {
              if(collateral.exposures.length > 0) {
                collateral.exposures.forEach(item => {
                  if (item.conversion_date != '-' && item.conversion_date != null) {
                    item.conversion_date = moment(item.conversion_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                  } else {
                    item.conversion_date = '-';
                  }
                });
              }
              collateral.price_types = this.price_types.filter(pt => pt.collateral_id === collateral.collateral_id);
              // Filter out specific price_name values from transaction_price_types
              collateral.transaction_price_types = this.price_types
                .filter(pt => pt.collateral_id === collateral.collateral_id)
                .filter(pt => pt.price_name !== 'Other Price (Input)' && pt.price_name !== 'Average of All prices selected');
            }); 
            this.allCollateralData.forEach(collateralData => {
                this.selectedTabs[collateralData.collateral_id] = 'Uncleaned'; // Initialize selected tab for each collateral
                // this.setTab(collateralData.collateral_id,'Uncleaned')
            });
            this.getCollateralTransactions();
          } else {
            Utils.make_alert("warning","Something went wrong from server!")
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });

    },

    getCollateralTransactions() {
      
      console.log("inside getCollateralTransactions")
      let conversionDate = null;
      if (this.ConversionDate != null) {
        conversionDate = moment(this.ConversionDate).format("MM-DD-YYYY");
      }
      let finalSelectedBorrower = null
      if(this.selectAllBorrower === true){
        finalSelectedBorrower = this.borrowers.map(borrower => borrower.borrower_id);
      } else{
        finalSelectedBorrower = this.selectedBorrower
      }
      if(finalSelectedBorrower.length == 0){
        finalSelectedBorrower = null
      }

      let finalSelectedFacility = null
      if(this.selectAllFacility == true){
        finalSelectedFacility = this.facilities.map(facility => facility.id);
      } else {
        if(this.selectedFacility.length > 0){
          finalSelectedFacility = this.selectedFacility.map(facility => facility.id);
        }
        else{
          finalSelectedFacility = null;
        }
      }
      if(finalSelectedFacility.length == 0){
        finalSelectedFacility = null
      }

      let finalSelectedCollateral = null
      if(this.selectAllCollateral == true){
        finalSelectedCollateral = this.collateralNames.map(col => col.collateral_id);
      } else {
        if(this.selectedCollateralID.length > 0){
          console.log("this.selectedCollateralID.length",this.selectedCollateralID.length)
          finalSelectedCollateral = this.selectedCollateralID.map(collateral => collateral.collateral_id);
        }
        else{
          finalSelectedCollateral = null;
        }
      }
      if(finalSelectedCollateral.length == 0){
        finalSelectedCollateral = null
      }
    
      console.log("finalSelectedCollateral",finalSelectedCollateral)
      const path = 
        "get-transaction-data" +
        "/" +
        finalSelectedBorrower +
        // this.selectedBorrower +
        "/" +
        finalSelectedFacility +
        "/" +
        finalSelectedCollateral +
        "/" +
        this.selectedCurrency +
        "/" +
        conversionDate +
        "/" +
        this.selectAllBorrower 
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            this.isSubmitted = false;
            this.conversionDate = res.data.date;
            this.transactions = res.data.transactions;
            let index = 0;
            // Add a unique trans_id property to each transaction
            this.transactions.forEach((transaction, index) => {
              transaction.trans_id = index + 1; // Adding 1 to start IDs from 1
              if (transaction.conversion_date != '-' && transaction.conversion_date != null) {
                transaction.conversion_date = moment(transaction.conversion_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                transaction.conversion_date = '-';
              }
            });
            // Create a map of transactions indexed by collateral id
            this.allCollateralData.forEach(collateral => {
              collateral.transactions = this.transactions.filter(pt => pt.collateral_id === collateral.collateral_id);
              collateral.selectedPriceTypeTransaction = null;
              collateral.selectedCurrencyTransaction = null;
            }); 
            this.allCollateralData.forEach(collateral => {
              this.setTab(collateral.collateral_id,'Uncleaned')
            }); 

            this.allCollateralData.forEach(collateral => {
              if (this.collapsedSections[collateral.collateral_id] === undefined) {
                this.collapsedSections[collateral.collateral_id] = true; // Start with sections collapsed
              }
            });

          } else {
            Utils.make_alert("warning","Something went wrong from server!")
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });

    },

    onSubmit(flag) {
      // let totalLTVQuantity = this.allCollateralData.reduce((sums, collateralData) => {
      //   const sum = collateralData.exposures.reduce((exposureSum, exposure) => {
      //     const quantityValue = exposure.quantity ? parseFloat(exposure.quantity.replace(/,/g, '')) : 0;
      //     return exposureSum + quantityValue;
      //   }, 0);
      //   let parsedSum = sum.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      //   return { ...sums, [collateralData.collateral_id]: parsedSum };
      // }, {});
      // let finalTotalLTVQuantity = totalLTVQuantity[this.selectedCollateralID] 
      // console.log("finalTotalLTVQuantity",finalTotalLTVQuantity)


      // let totalLTVBag = this.allCollateralData.reduce((sums, collateralData) => {
      //   const sum = collateralData.exposures.reduce((exposureSum, exposure) => {
      //     const bagsValue = exposure.total_bags ? parseFloat(exposure.total_bags.replace(/,/g, '')) : 0;
      //     return exposureSum + bagsValue;
      //   }, 0);
      //   let parsedSum = sum.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      //   return { ...sums, [collateralData.collateral_id]: parsedSum };
      // }, {});
      // let finalTotalLTVBag = totalLTVBag[this.selectedCollateralID] 
      // console.log("finalTotalLTVBag",finalTotalLTVBag)


      // let totalLTVCollateralValue = this.allCollateralData.reduce((sums, collateralData) => {
      //   const sum = collateralData.exposures
      //     .reduce((exposureSum, exposure) => exposureSum + parseFloat(this.calculatedCollateralValue(exposure).replace(/,/g, '')), 0);
          
      //   const parsedSum = parseFloat(sum).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      //   return { ...sums, [collateralData.collateral_id]: parsedSum };
      // }, {});
      // let finalTotalLTVCollateralValue = totalLTVCollateralValue[this.selectedCollateralID] 
      // console.log("finalTotalLTVCollateralValue",finalTotalLTVCollateralValue)


      // let totalLTVCompanyValuation = this.allCollateralData.reduce((sums, collateralData) => {
      //   const sum = collateralData.exposures
      //     .reduce((exposureSum, exposure) => exposureSum + parseFloat(this.calculatedCompanyValuation(exposure).replace(/,/g, '')), 0);
          
      //   const parsedSum = parseFloat(sum).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      //   return { ...sums, [collateralData.collateral_id]: parsedSum };
      // }, {});
      // let finalTotalLTVCompanyValuation = totalLTVCompanyValuation[this.selectedCollateralID] 
      // console.log("finalTotalLTVCompanyValuation",finalTotalLTVCompanyValuation)

      const updatedCollaterals = this.selectedCollateralID.map(coll => ({
            collateral_name: coll.collateral_name,
            id: coll.collateral_id
        }));

        this.$router.push({
          name: 'AddDisbursement',
          params: {
              clientId: this.$store.state.client_id,
              approvalDate:this.approvalDate,
              loanID: this.loanID,
              flow:this.flow,
              collateralNames:this.collateralNamesFromLoan,
              selectedBorrower: this.selectedBorrower,
              selectedFund: this.selectedFund,
              selectedFacility: this.selectedFacility ,
              selectedDisbursementOn: this.selectedDisbursementOn,
              selectedCollateralID: updatedCollaterals,
              // ltvConversionDate: this.ConversionDate,
              fundCurrency: this.fundCurrency,
              LTV: this.LTVfromDis,
              // LTVCompany: this.LTVCompany,
              // LTVValue: this.LTVValue,
              // totalLTVQuantity: finalTotalLTVQuantity,
              // totalLTVBag: finalTotalLTVBag,
              // totalLTVCollateralValue: finalTotalLTVCollateralValue,
              // totalLTVCompanyValuation: finalTotalLTVCompanyValuation,
              totalNetExpenses: this.totalNetExpenses,
              AccruedInterest: this.AccruedInterest,
              allCollateralData:this.allCollateralData,
              LTVtoDisbursement: flag,
          }
        });
    },

    getCollateralByFacility() {
      console.log("getCollateralByFacility")
      let finalSelectedFacility = null
      if(this.selectAllFacility === true){
        finalSelectedFacility = this.facilities.map(facility => facility.id);
      } else {
        console.log(this.selectedFacility)
        finalSelectedFacility = this.selectedFacility.map(facility => facility.id);
      }
      if(finalSelectedFacility.length == 0) {
        finalSelectedFacility = null
      }
      console.log("finalSelectedFacility",finalSelectedFacility)
      if(this.selectAllBorrower === true){
        finalSelectedFacility = null;
      }
      if(finalSelectedFacility == null && this.selectAllBorrower == false){
        return;
      }
      Utils.start_loading();
      const path = 
      "/get-collateral-by-facility" +
      "/" +
      this.selectedFund +
      "/" +
      finalSelectedFacility +
      "/" +
      this.selectAllBorrower;
      console.log(path)
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            this.collateralNames = res.data.collateral_name_id;
            this.firstTimeFilteredCalled = true;
            if(this.selectAllBorrower == true){
              this.facilities = res.data.get_facility;
            }
          } else {
            Utils.make_alert("warning","Something went wrong from server!")
          }
          
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
        });
    },

    // getFacilitySetupByBorrower() {
    //   //this function is called to get list of day count
    //   const path = "/facility-setup-borrower/" + this.selectedBorrower;
    //   axios
    //     .get(path, { params: { clientId: this.$store.state.client_id } })
    //     .then((res) => {
    //       this.facilities = res.data.Facility_Setup;
    //       this.collateralNames = [];
    //       this.selectedFacility = [];
    //       this.getCollateralByFacility();
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },

    getFacilitySetupByFundBorrower() {
      const path = 
      "/get-facility-by-fund-borrower/" + 
      this.selectedBorrower +
      "/" + 
      this.selectedFund;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            this.facilities = res.data.facility_list;
            if(!this.DisbursementToLTV){
              this.selectedFacility = [];
            }
            this.collateralNames = [];
            this.getCollateralByFacility();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    
    getBorrowersByFund() {
      if (this.selectedFund) {
        let fund = this.Funds.find(fund => fund.fund_id == this.selectedFund)
        this.selectedCurrency = fund.fund_currency_id
        this.backCapDate = fund.fund_inception_date
      }
      const path = "/get-borrower-by-fund/" + this.selectedFund;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            this.borrowers = res.data.borrower_list;
            if(!this.DisbursementToLTV){
              this.selectedBorrower = null;
              this.selectedFacility = [];
            }
            if (!this.firstTimeFilteredCalled && !this.DisbursementToLTV){
              this.selectedBorrower = res.data.borrower_list[0].borrower_id;
            }
            this.facilities = [];
            if (this.selectedFund && this.selectedBorrower){
              if(!this.DisbursementToLTV){
                this.selectAllFacility = true;
                this.selectAllCollateral = true;
              }
              this.getFacilitySetupByFundBorrower();
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFunds() {
      const path = "fund-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.Funds = res.data.funds;
          if(!this.DisbursementToLTV){
            this.selectedFund = res.data.funds[0].fund_id;
          }
          this.getBorrowersByFund();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // getBorrowers() {
    //   const path = "borrower-master";
    //   axios
    //     .get(path, { params: { clientId: this.$store.state.client_id } })
    //     .then((res) => {
    //       this.borrowers = res.data.borrowers;
    //       this.selectedFacility = [];
    //       if (this.loggedInUserGroupId === "Borrower") {
    //         this.borrowers.filter((bor) => {
    //           if (bor.borrower_id.toString() === this.loggedInUserSubGroupId)
    //             this.selectedBorrower = bor.borrower_id;
    //             this.getFacilitySetupByBorrower();
    //             this.selectAllFacility = true;
    //             this.selectAllCollateral = true;
    //         });
    //       } else{
    //         this.selectedBorrower = res.data.borrowers[0].borrower_id;
    //         this.getFacilitySetupByBorrower();
    //         this.selectAllFacility = true;
    //         this.selectAllCollateral = true;
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
           // After getting the currencies, search for USD and set selectedCurrency
          if(!this.DisbursementToLTV){
            const currencyShortNameToFind = 'USD';
            const foundCurrency = this.currencies.find(currency => currency.currency_short_name === currencyShortNameToFind);
            if (foundCurrency) {
              this.selectedCurrency = foundCurrency.id;
            } else {
              console.log(`Currency with short name ${currencyShortNameToFind} not found.`);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

     // this function will be called to get list of price_types
    getPriceTypes() {
      Utils.start_loading();
      const path = "price-type-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          this.price_types = res.data.price_types;
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
        });
    },

  },

  watch: {
    selectedTabs: {
      handler(newSelectedTabs) {
        console.log("selectedTabs updated:", newSelectedTabs);
      },
    immediate: true, // Watch for changes within nested objects
  },
    selectAllBorrower: {
      handler(selectAllBorrower) {
          if(selectAllBorrower === true){
            this.selectedBorrower = null;
            this.selectedFacility = [];
            this.selectedCollateralID = [];
            this.selectAllFacility = true;
            this.selectAllCollateral = true;
            this.getCollateralByFacility();
          } else {
            this.facilities = [];
            this.collateralNames = [];
            this.selectAllFacility = false;
            this.selectAllCollateral = false;
          }
          
      },
        immediate: true,
      },

    selectAllFacility: {
      handler(selectAllFacility) {
        if(selectAllFacility === true){
            this.selectedFacility = [];
            this.getCollateralByFacility();
          } else {
            this.collateralNames = [];
          }
        
        },
        immediate: true,
      },

    selectAllCollateral: {
      handler(selectAllCollateral) {
        if(selectAllCollateral === true){
            this.selectedCollateralID = [];
            this.getCollateralByFacility();
          } else {
            this.selectedCollateralID = [];
          }
        
        },
        immediate: true,
      },

      selectedFacility:{
        handler(selectedFacility) {
        if(selectedFacility.length > 0){
          if(selectedFacility.length === this.facilities.length){
              this.selectedFacility = [];
              this.selectAllFacility = true;
            }
        }
      
        },
        immediate: true,
      },

      selectedCollateralID:{
        handler(selectedCollateralID) {
        console.log(selectedCollateralID)
        if(selectedCollateralID.length > 0){
          if(selectedCollateralID.length === this.collateralName.length){
              this.selectedCollateralID = [];
              this.selectAllCollateral = true;
            }
        }
      
        },
        immediate: true,
      },

      firstTimeFilteredCalled:{
        handler(firstTimeFilteredCalled) {
          if(
            this.selectedFund !== null &&
            this.selectedBorrower !== null &&
            !this.selectAllBorrower &&
            this.selectedFacility.length == 0 &&
            this.selectAllFacility &&
            this.selectAllCollateral &&
            this.selectedCollateralID.length == 0 &&
            this.selectedCurrency !== null &&
            this.ConversionDate !== null &&
            firstTimeFilteredCalled
          ) {
            console.log("first timed tottal Net expense called")
            this.firstTimeFilteredCalled = true;
            this.getTotalNetExpense();
          }
          if(
            this.selectedFund !== null &&
            this.selectedBorrower !== null &&
            this.selectedFacility.length == 0 &&
            this.selectedCollateralID.length == 0 &&
            this.selectedCurrency !== null &&
            this.ConversionDate !== null &&
            this.DisbursementToLTV &&
            firstTimeFilteredCalled
          ) {
            console.log("first timed tottal Net expense called when DisbursementToLTV is true")
            this.firstTimeFilteredCalled = true;
            this.getTotalNetExpense();
          }
        },
        immediate: true,
      },
      // totalNetExpenses: function(newValue) {
      // this.$nextTick(() => {
      //   // Do nothing, just trigger a re-render
      // });
    // },
  },

  computed: {
    calculatedPrice() {
      return (exposure) => {
        if (exposure.selectedPriceType !== null) {
          const price = this.price_types.find(p => p.price_name === exposure.selectedPriceType);
          const calculatedValue = exposure.price;
          
          // Set the calculated value to the exposure
          this.$set(exposure, 'price', calculatedValue);
          
          return calculatedValue;
        }
        return 0;
      };
    },

    calculatedCollateralValue() {
      return (exposure) => {
        if (exposure.selectedPriceType !== null) {
          let calculatedValue = 0
          let price = exposure.price
          let quantity = exposure.quantity
          let Price = parseFloat(price)
          if (!isNaN(Price)) { // Check if Price is a valid number
            calculatedValue = Number(quantity.replace(/,/g, '')).toFixed(2) * (Price * exposure.conversion_rate);
          } 
          console.log("calculatedValue",calculatedValue)
          // Format calculatedValue with commas and US number format
          const formattedValue = calculatedValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          console.log("formattedValue",formattedValue)

          // Set the formatted value to the exposure
          this.$set(exposure, 'collateral_value', formattedValue);
          
          return formattedValue;
        }
        return '0.00';
      };
    },

    calculatedConvertedPrice() {
      return (transaction) => {
        if (transaction.selectedPriceType !== null) {
          let calculatedValue = 0
          let price = transaction.price
          let Price = parseFloat(price.replace(/,/g, '')); // Convert to number
          
          if (!isNaN(Price)) { // Check if Price is a valid number
            calculatedValue = Price * (transaction.conversion_rate);
          } else {
            calculatedValue = 0
          }
          const formattedValue = calculatedValue.toFixed(2)
          console.log("calculatedConvertedPrice",formattedValue)
          // Set the formatted value to the transaction
          this.$set(transaction, 'converted_price', formattedValue);
          
          return formattedValue;
        }
        return '0.00';
      };
    },
    
    calculatedCompanyValuation() {
      return (exposure) => {
        if (exposure.selectedPriceType !== null) {
          const collateralValueString = this.calculatedCollateralValue(exposure);
          const collateralValue = parseFloat(collateralValueString.replace(/,/g, '')); // Convert to number
          
          const calculatedValue = (collateralValue * exposure.discount_value) / 100;
          
          // Format calculatedValue with commas and US number format
          const formattedValue = calculatedValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          // Set the formatted value to the exposure
          this.$set(exposure, 'available_to_disburse', formattedValue);

          return formattedValue;
        }
        return '0.00'; // Return string '0.00' if no value calculated
      };
    },

    sumOfQuantities() {
      return this.allCollateralData.reduce((sums, collateralData) => {
        const sum = collateralData.exposures.reduce((exposureSum, exposure) => {
          const quantityValue = exposure.quantity ? parseFloat(exposure.quantity.replace(/,/g, '')) : 0;
          return exposureSum + quantityValue;
        }, 0);
        let parsedSum = sum.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return { ...sums, [collateralData.collateral_id]: parsedSum };
      }, {});
    },
    sumOfBags() {
      return this.allCollateralData.reduce((sums, collateralData) => {
        const sum = collateralData.exposures.reduce((exposureSum, exposure) => {
          const bagsValue = exposure.total_bags ? parseFloat(exposure.total_bags.replace(/,/g, '')) : 0;
          return exposureSum + bagsValue;
        }, 0);
        let parsedSum = sum.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return { ...sums, [collateralData.collateral_id]: parsedSum };
      }, {});
    },



    sumOfCollateralValue() {
      return this.allCollateralData.reduce((sums, collateralData) => {
        const sum = collateralData.exposures
          .reduce((exposureSum, exposure) => exposureSum + parseFloat(this.calculatedCollateralValue(exposure).replace(/,/g, '')), 0);
          
        const parsedSum = parseFloat(sum).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return { ...sums, [collateralData.collateral_id]: parsedSum };
      }, {});
    },

    sumOfCompanyValuation() {
      return this.allCollateralData.reduce((sums, collateralData) => {
        const sum = collateralData.exposures
          .reduce((exposureSum, exposure) => exposureSum + parseFloat(this.calculatedCompanyValuation(exposure).replace(/,/g, '')), 0);
          
        const parsedSum = parseFloat(sum).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return { ...sums, [collateralData.collateral_id]: parsedSum };
      }, {});
    },


    LTV_to_company() {
      const sumOfAllCompanyValuation = Object.values(this.sumOfCompanyValuation).reduce((sum, collateralData) => sum + parseFloat(collateralData.replace(/,/g, '')), 0);

      if (sumOfAllCompanyValuation === 0) {
        return '0.00'; // Avoid division by zero and format as string
      }

      this.totalCompanyValuation = sumOfAllCompanyValuation
      
      const parsedTotalNetExpenses = parseFloat(this.totalNetExpenses.replace(/,/g, ''));
      const ltv = ((parsedTotalNetExpenses / sumOfAllCompanyValuation) * 100).toFixed(2);
      this.LTVCompany = ltv;
      return ltv.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    
    LTV_to_value() {
      const sumOfAllCollateralValue = Object.values(this.sumOfCollateralValue).reduce((sum, collateralData) => sum + parseFloat(collateralData.replace(/,/g, '')), 0);

      if (sumOfAllCollateralValue === 0) {
        return '0.00'; // Avoid division by zero and format as string
      }
      this.totalCollateralValue = sumOfAllCollateralValue
      
      const parsedTotalNetExpenses = parseFloat(this.totalNetExpenses.replace(/,/g, ''));
      const parsedAccruedInterest = parseFloat(this.AccruedInterest.replace(/,/g, ''));
      const ltv = (((parsedTotalNetExpenses - parsedAccruedInterest)/ sumOfAllCollateralValue) * 100).toFixed(2);
      this.LTVValue = ltv;
      return ltv.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    
    
    areAnyTransactionsChecked() {
      return collateralId => {
        const transactions = this.filteredTransactionsForCheckbox(collateralId);
        return transactions.some(transaction => transaction.selectedTransaction);
      };
    },

    filteredTransactions() {
      return collateralId => {
        const selectedTab = this.selectedTabs[collateralId];
        const selectedCollateral = this.allCollateralData.find(collateral => collateral.id === collateralId);
        
        if (selectedCollateral && selectedCollateral.transactions) {
          return selectedCollateral.transactions.filter(transaction => transaction.exposure_name === selectedTab);
        } else {
          return []; // Return an empty array if the collateral is not found
        }
      };
    },

    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
  
  mounted() {
  },
  
  created() {
    console.log("created")
    axios.defaults.headers.common["Authorisation"] =
      "Bearer " + sessionStorage.getItem("token");
    this.getPriceTypes();
    this.getFunds();
    // this.getBorrowers();
    this.getCurrencies();
    let data = this.$route.params;
    let data1 = {
        "clientId": 2,
        "selectedBorrower": "932",
        "selectedFund": 367,
        "selectedFacility": [
            {
                "facility_registration_id": "FR003VE0",
                "id": 785
            }
        ],
        "selectedFacilityIds": [],
        "selectedCollateralID": 1,
        "fundCurrency": 2,
        "selectedDisbursementOn": 2,
        "LTV": null,
        "flow": "Loan",
        "loanID": "730",
        "approvalDate": "12-07-2023",
        "collateralNames": [
            {
                "collateral_name": "Cocoa (London Futures GBP)",
                "id": 1
            }
        ],
        "DisbursementToLTV": true
    }
    let data2 = {
    "clientId": 2,
    "selectedBorrower": 926,
    "selectedFund": 352,
    "selectedFacility": [
        {
            "facility_registration_id": "FR001KL0",
            "id": 771
        },
        {
            "facility_registration_id": "FR002KL0",
            "id": 772
        }
    ],
    "selectedFacilityIds": [],
    "selectedCollateralID": [
        {
            "collateral_name": "Cocoa (London Futures GBP)",
            "collateral_id": 1
        },
        {
            "collateral_name": "Cocoa (NY Futures USD)",
            "collateral_id": 2
        }
    ],
    "fundCurrency": 2,
    "selectedDisbursementOn": 2,
    "LTV": null,
    "flow": "Normal Disbursement",
    "loanID": null,
    "approvalDate": null,
    "collateralNames": [
        {
            "collateral_name": "Cocoa (London Futures GBP)",
            "id": 1
        },
        {
            "collateral_name": "Cocoa (NY Futures USD)",
            "id": 2
        },
        {
            "collateral_name": "Cocoa (ICCO Daily Price USD)",
            "id": 3
        },
        {
            "collateral_name": "Cocoa (ICCO Daily Price Euro)",
            "id": 4
        },
        {
            "collateral_name": "Wheat",
            "id": 5
        }
    ],
    "DisbursementToLTV": true
    }
    if (Object.keys(data).length === 0) {
    // If data is an empty object
      console.log('Data is empty');
    } else {
      // If data is not empty
      console.log("from Dis to LTV",data);
      // Assign properties after checking if they exist in 'data'
      this.flow = 'flow' in data ? data.flow : null;
      this.loanID = 'loanID' in data ? data.loanID : null;
      this.approvalDate = 'approvalDate' in data ? data.approvalDate : null;
      this.selectedBorrower = data.selectedBorrower
      this.selectedFund = data.selectedFund
      this.selectedFacility = data.selectedFacility 
      this.selectedFacilityIds = data.selectedFacilityIds 
      this.selectedCollateralID = data.selectedCollateralID
      this.selectedCurrency = data.fundCurrency
      this.fundCurrency = data.fundCurrency
      this.selectAllBorrower = false
      this.selectAllFacility = false
      this.selectAllCollateral = false
      this.selectedDisbursementOn = data.selectedDisbursementOn
      this.collateralNamesFromLoan = data.collateralNames
      this.LTVfromDis = data.LTV
      this.DisbursementDate = data.DisbursementDate
      this.DisbursementToLTV = data.DisbursementToLTV
      this.getTotalNetExpense();
    }
  },
};
</script>

<style scoped>

/* .col{
  width: 200px !important;
  white-space: normal !important; 
} */
/* .date{
  position: relative !important;
  z-index: 10000 !important;
} */

.collapsebtn {
  color: #ffffff  !important;
  background-color: #183750  !important;
  border:none !important;
  border-radius: 4px !important;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 3px 10px rgba(0,0,0,0.18) !important;
}
.center {
  text-align: center !important;
}
.right {
  text-align: right !important;
}
.left {
  text-align: left !important;
}

.grey{
  /* background-color: #dee2e6 !important; */
  background-color: #f8f8fa !important;
}

.center-align {
    display: flex;
    justify-content: center;
    align-items: center;
}

.col80{
  width: 80px !important;
}

.col140{
  width: 140px !important;
}
.col180{
  width: 180px !important;
}
.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
.vdp-datepicker >>> .form-control[disabled] {
  background-color:#EEEEEE !important;
  opacity: 0.7;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.divider-line {
  border-top: 3px solid #183750;
}

/* Style for the checkbox */
input[type="checkbox"] {
  margin-right: 10px !important; /* Add some space between the checkbox and the label */
}
.custom-input {
  width: 80px !important;
}

.multiselect  >>> .multiselect__tags  {
  min-height: 32px !important;
  max-height: 32px !important;
  background-color: #eeeef0 !important;
}
/* .multiselect  >>> .multiselect__tags-wrap  {
  height:auto !important;
  min-height: 32px !important;
  max-height: 32px !important;
  background-color: #eeeef0 !important;

} */
.multiselect  >>> .multiselect__single  {
  background-color: #eeeef0 !important;
}

.multiselect  >>> .multiselect__input  {
  background-color: #eeeef0 !important;
}
.multiselect >>> .multiselect__select  {
  height: 32px !important; /* Set the desired height */
  min-height: 32px !important; /* Set the desired height */
}

.multiselect--disabled  {
  height: 32px !important; /* Set the desired height */
  min-height: 32px !important; /* Set the desired height */
  max-height: 32px !important; /* Set the desired height */
  color: black !important; 
}
.multiselect >>> .multiselect__content-wrapper  {
  max-height: 130px !important; 
}

.multiselect >>> .multiselect__tag {
  margin-top: -3px !important;
}
.error {
  color: red;
  font-size: 13px;
  padding-left: 40px;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -75px;
}
.toggle-padding {
  margin-left: 5px;
}
.headingt #interestCalculationLabelYes {
  margin-left: 250px;
}
.headingt #interestCalculationLabelNo {
  margin-left: 20px;
}
.headingt #offtakerRepaymentLabelYes {
  margin-left: 95px;
}
.headingt #offtakerRepaymentLabelNo {
  margin-left: 20px;
}

.heading1 {
  color: #183750;
  font-size: 18px;
  padding-top: 6px;
  margin: 0px;
}

.error {
  color: red;
  font-size: 15px;
}
#myTable1 tr:last-child td {
  background: #183750;
  color: #fff;
}
#myTable1 tr td,
#myTable1 tr th {
  border: solid 1px slategrey;
  border-collapse: collapse !important;
}
h1 label {
  font-size: 15px;
}
#offtaker {
  margin-left: 15px;
}
.sub-heading {
  margin-left: 50px;
}
.wrapper {
  margin-bottom: -20px;
  min-height: 680px !important;
}
</style>
