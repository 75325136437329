<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Edit Fund
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Fund Name</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Type Fund Name..."
                          v-model="fundName"
                          :class="{
                            'is-invalid': isSubmitted && $v.fundName.$error,
                          }"
                          id="fundName"
                          name="fundName"
                          disabled
                        />
                        <div
                          v-if="isSubmitted && $v.fundName.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.fundName.required"
                            >Fund Name is required</span
                          >
                          <span v-if="!$v.fundName.maxLength"
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Fund Inception Date</label
                        >
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          id="inceptionDate"
                          name="inceptionDate"
                          placeholder="Select Date"
                          v-model="inceptionDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          disabled
                          :disabledDates="{ from: new Date() }"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.inceptionDate.$error,
                          }"
                        ></datepicker>
                        <div
                          v-if="isSubmitted && $v.inceptionDate.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.inceptionDate.required"
                            >Inception Date is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Country</label
                        >
                        <input
                          id="selectedCountry"
                          class="form-control"
                          name="selectedCountry"
                          v-model="selectedCountry"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.selectedCountry.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.selectedCountry.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedCountry.required"
                            >Country is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label class="required-field">Currency</label>
                        <input
                          id="selectedCurrency"
                          class="form-control"
                          name="selectedCurrency"
                          v-model="selectedCurrency"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.selectedCurrency.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.selectedCurrency.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedCurrency.required"
                            >Currency is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-5 col-6"></div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <input
                          type="checkbox"
                          id="performanceCheck"
                          v-model="performanceCheck"
                          :disabled="disablePerformance"
                        />&nbsp;&nbsp;
                        <label for="performanceCheck">Performance fees %</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model="performanceFees"
                          id="performanceFees"
                          name="performanceFees"
                          min="0"
                          :disabled="!performanceCheck"
                          @change="performanceEdited = true"
                          @keypress="isDecimal($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.performanceFees.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.performanceFees.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.performanceFees.required"
                            >Performance Fees % is required</span
                          >
                          <span v-if="!$v.performanceFees.maxDigit"
                            >Should not exceed 100 %</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="HurdleRate">Hurdle Rate %</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model="HurdleRate"
                          id="HurdleRate"
                          name="HurdleRate"
                          min="0"
                          :disabled="!performanceCheck"
                          @change="performanceEdited = true"
                          @keypress="isDecimal($event)"
                          :class="{
                            'is-invalid': isSubmitted && $v.HurdleRate.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.HurdleRate.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.HurdleRate.required"
                            >Hurdle Rate % is required</span
                          >
                          <span v-if="!$v.HurdleRate.maxDigit"
                            >Should not exceed 100 %</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="selectedPerformancePeriod">Period</label>
                        <select
                          id="selectedPerformancePeriod"
                          class="form-control"
                          name="selectedPerformancePeriod"
                          v-model="selectedPerformancePeriod"
                          :disabled="!performanceCheck"
                          @change="performanceEdited = true"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.selectedPerformancePeriod.$error,
                          }"
                        >
                          <option :value="null">-- Select a Period --</option>
                          <option
                            v-for="per in Period"
                            :value="per.interest_frequency_name"
                            :key="per.id"
                          >
                            {{ per.interest_frequency_name }}
                          </option>
                        </select>
                        <div
                          v-if="
                            isSubmitted && $v.selectedPerformancePeriod.$error
                          "
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedPerformancePeriod.required"
                            >Performance Fees Period is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <input
                          type="checkbox"
                          id="mgtCheck"
                          v-model="mgtCheck"
                          :disabled="disableManagement"
                        />&nbsp;&nbsp;
                        <label for="mgtCheck">Management fees %</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model="mgtFees"
                          id="mgtFees"
                          name="mgtFees"
                          min="0"
                          :disabled="!mgtCheck"
                          @keypress="isDecimal($event)"
                          @change="mgtEdited = true"
                          :class="{
                            'is-invalid': isSubmitted && $v.mgtFees.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.mgtFees.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.mgtFees.required"
                            >Management Fees % is required</span
                          >
                          <span v-if="!$v.mgtFees.maxDigit"
                            >Should not exceed 100 %</span
                          >
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="selectedMgmtPeriod">Period</label>
                        <select
                          id="selectedMgmtPeriod"
                          class="form-control"
                          name="selectedMgmtPeriod"
                          v-model="selectedMgmtPeriod"
                          :disabled="!mgtCheck"
                          @change="mgtEdited = true"
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.selectedMgmtPeriod.$error,
                          }"
                        >
                          <option :value="null">-- Select a Period --</option>
                          <option
                            v-for="per in Period"
                            :value="per.interest_frequency_name"
                            :key="per.id"
                          >
                            {{ per.interest_frequency_name }}
                          </option>
                        </select>
                        <div
                          v-if="isSubmitted && $v.selectedMgmtPeriod.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedMgmtPeriod.required"
                            >Management Fees Period is required</span
                          >
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <router-link :to="{ name: 'Fund' }">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      currencies: [],
      countries: [],
      Period: [],
      selectedCurrency: null,
      selectedCountry: null,
      fundName: null,
      inceptionDate: null,
      performanceFees: null,
      HurdleRate: null,
      mgtFees: null,
      selectedPerformancePeriod: null,
      selectedMgmtPeriod: null,
      performanceCheck: false,
      performanceEdited: false,
      mgtCheck: false,
      mgtEdited: false,
      fund_id: null,
      error: "",
      isSubmitted: false,
      inactive: false,
      disableManagement: false,
      disablePerformance: false,
    };
  },
  validations: {
    fundName: {
      required,
      maxLength: maxLength(50),
    },
    inceptionDate: {
      required,
    },
    selectedCurrency: {
      required,
    },
    selectedCountry: {
      required,
    },
    performanceFees: {
      maxDigit: function (val) {
        if (Number(val) > 100) return false;
        else return true;
      },
      required: function () {
        if (
          (this.performanceCheck && (this.performanceFees == null || this.performanceFees == ''))
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
    HurdleRate: {
      maxDigit: function (val) {
        if (Number(val) > 100) return false;
        else return true;
      },
      required: function () {
        if (
          (this.performanceCheck && (this.HurdleRate == null || this.HurdleRate == ''))
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
    selectedPerformancePeriod: {
      required: function () {
        if (this.performanceCheck && this.selectedPerformancePeriod === null) {
          return false;
        } else {
          return true;
        }
      },
    },
    mgtFees: {
      maxDigit: function (val) {
        if (Number(val) > 100) return false;
        else return true;
      },
      required: function () {
        if (
          (this.mgtCheck && (this.mgtFees == null || this.mgtFees == ''))
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
    // selectedMgmtPeriod: {
    //   required: function () {
    //     if (this.mgtCheck && this.selectedMgmtPeriod === null) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   },
    // },
  },
  components: {
    Datepicker,
  },
  methods: {
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },
    getCurrencies() {
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCountries() {
      const path = "country";
      axios
        .get(path)
        .then((res) => {
          this.countries = res.data.countries;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFund() {
      //this function is called on page load and created events. it will show details on fund with fundid equal to this.id
      const path = "fund/" + this.fund_id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          if (res.data.status == "success") {
            Utils.stop_loading();
            this.fund = this.inactive
              ? res.data.fund_inactive
              : res.data.fund_active;
            // disable management checkbox if management expense is already added for fund
            this.disableManagement = this.fund.disable_management;
            // disable performance checkbox if performance expense is already added for fund
            this.disablePerformance = this.fund.disable_performance;
            this.fundName = this.fund.fund_name;
            this.inceptionDate = this.fund.fund_inception_date;
            this.selectedCountry = this.fund.country_name;
            this.selectedCurrency = this.fund.currency_short_name;
            this.performanceFees = this.fund.performance_fees
              ? this.fund.performance_fees
              : null;
            this.performanceCheck = this.performanceFees;
            this.selectedPerformancePeriod = this.fund.performance_fees_period
              ? this.fund.performance_fees_period
              : null;
            this.HurdleRate = this.fund.hurdle_rate
              ? this.fund.hurdle_rate
              : null;
            // this.selectedMgmtPeriod = this.fund.management_fees_period
            //   ? this.fund.management_fees_period
            //   : null;
            this.mgtFees = this.fund.management_fees
              ? this.fund.management_fees
              : null;
            this.mgtCheck = this.mgtFees;
            console.log("get fund details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/fund");
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getFund() close
    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (
        this.fundName == null ||
        this.inceptionDate == null ||
        this.selectedCurrency == null ||
        this.selectedCountry == null
      ) {
        Utils.make_alert("warning", "Mandatory values are required.");
      } else {
        this.$confirm({
          auth: false,
          message: "Confirm Submit?",
          button: {
            no: "No",
            yes: "Yes",
          },

          callback: (confirm) => {
            if (confirm) {
              Utils.start_loading();
              this.inceptionDate = moment(this.inceptionDate).format(
                "YYYY-MM-DD"
              );
              axios
                .put(
                  "fund/" + this.fund_id,
                  {
                    fundName: this.fundName,
                    inceptionDate: this.inceptionDate,
                    selectedCountry: this.fund.fund_country_id,
                    selectedCurrency: this.fund.fund_currency_id,
                    performanceFees: this.performanceFees
                      ? this.performanceFees
                      : 0,
                    mgtFees: this.mgtFees ? this.mgtFees : 0,
                    selectedPerformancePeriod: this.selectedPerformancePeriod,
                    selectedMgmtPeriod: this.selectedMgmtPeriod,
                    HurdleRate:this.HurdleRate ? this.HurdleRate : 0,
                    management_flag: this.mgtEdited,
                    performance_flag: this.performanceEdited,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 1,
                    tabId: 1,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((response) => {
                  Utils.stop_loading();
                  if (response.data.status == "success") {
                    Utils.make_alert("success", "Fund updated successfully!");
                    //if you want to send any data into server before redirection then you can do it here
                    this.$router.push("/fund");
                  } else {
                    Utils.make_alert("warning", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.log("error: " + error);
                  Utils.make_alert("warning", "Something went wrong!");
                });
            }
          },
        });
      }
    }, //onSubmit() close
    getPeriod() {
      //this function is called to get list of Period
      const path = "interest-frequency";
      axios
        .get(path)
        .then((res) => {
          if (res.data.status == "success") {
            // this.Period = res.data.interest_frequencies.slice(1, 5);
            // this.Period.splice(2, 1);
            // Use the filter method to get all items where interest_frequency_name is "Monthly"
            const monthlyInterestFrequencies = res.data.interest_frequencies.filter(frequency => frequency.interest_frequency_name == "Monthly");
            // If there are no items with interest_frequency_name "Monthly", this.Period will be an empty array
            this.Period = monthlyInterestFrequencies;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close
  mounted() {},
  created() {
    this.inactive = this.$route.params.inActive;
    this.getPeriod();
    this.getCurrencies();
    this.getCountries();
    this.fund_id = this.$route.params.id;
    this.getFund();
  },
};
</script>

<style scoped>
.required-field::after {
  content: "*";
  color: red;
}
.error-msg {
  color: red;
  font-weight: bold;
}
</style>
