//import { createApp } from 'vue'
import Vue from 'vue';
import App from './App.vue'
import router from './router'
import { store } from './store'
import NavBar from '@/components/core/NavBar'
import Aside from '@/components/core/Aside'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Footer from '@/components/core/Footer.vue';
import Auto_Logout from '@/components/core/Auto_Logout.vue';
import './axios'
import VueConfirmDialog from 'vue-confirm-dialog'
import Vuelidate from 'vuelidate'
import VueCollapse from 'vue2-collapse'
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import VModal from 'vue-js-modal'

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.loggedIn) {
            next({ name: 'login' })
        }
        else {
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresVisitor)) {
        if (store.getters.loggedIn) {
            if (store.getters.getuserGroup != "Admin") {
                next({ path: '/' })
            }
            else {
                // console.log("********* In Admin *************")
                next({ path: '/' })
            }
        }
        else {
            next()
        }
    }
    else {
        next()
    }
})


Vue.use(VueSweetalert2)
Vue.use(VueConfirmDialog)
Vue.use(Vuelidate)
Vue.use(VueCollapse)
Vue.use(VModal)

Vue.component('nav-bar', NavBar)
Vue.component('aside-bar', Aside)
Vue.component('footer-bar', Footer);
Vue.component('auto-logout', Auto_Logout);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });

Vue.config.productionTip = false

//const app = createApp(App).use(router).use(store)

new Vue({
    router, store,
    render: (h) => h(App),
}).$mount('#app');

//app.mount('#app')