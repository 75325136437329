var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('nav-bar'),_c('aside-bar'),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid tabtop pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-6"},[_c('ul',{attrs:{"id":"bck_btn"}},[_c('li',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"icofont-caret-left text-dark"}),_c('span',[_vm._v("Back")])])])])])])])])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"selectedBorrower"}},[_vm._v("Borrower Name")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedBorrower),expression:"selectedBorrower"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.selectedBorrower.$error,
                    },attrs:{"id":"selectedBorrower","name":"selectedBorrower","disabled":_vm.loggedInUserGroupId === 'Borrower' || _vm.id > 0},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedBorrower=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){_vm.getFacilitySetupByBorrower(_vm.selectedBorrower) &
                        _vm.getWarehouseByBorrower(_vm.selectedBorrower)}]}},[_c('option',{domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.borrowers),function(bor){return _c('option',{key:bor.borrower_id,domProps:{"value":bor.borrower_id}},[_vm._v(" "+_vm._s(bor.borrower_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedBorrower.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedBorrower.required)?_c('span',[_vm._v("Borrower Name is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"selectedFacilityId"}},[_vm._v("Facility ID")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedFacilityId),expression:"selectedFacilityId"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.isSubmitted && _vm.$v.selectedFacilityId.$error,
                    },attrs:{"id":"selectedFacilityId","name":"selectedFacilityId","disabled":_vm.id > 0},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedFacilityId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getCollateral(_vm.selectedFacilityId)}]}},[_c('option',{domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.facilities),function(fac){return _c('option',{key:fac.id,domProps:{"value":fac.id}},[_vm._v(" "+_vm._s(fac.facility_registration_id)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedFacilityId.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedFacilityId.required)?_c('span',[_vm._v("Facility ID is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"selectedCollateral"}},[_vm._v("Collateral")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCollateral),expression:"selectedCollateral"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.isSubmitted && _vm.$v.selectedCollateral.$error,
                    },attrs:{"id":"selectedCollateral","name":"selectedCollateral","disabled":_vm.id > 0},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedCollateral=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.collaterals),function(col){return _c('option',{key:col.id,domProps:{"value":col.id}},[_vm._v(" "+_vm._s(col.collateral_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedCollateral.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedCollateral.required)?_c('span',[_vm._v("Collateral is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"selectedWarehouse"}},[_vm._v("Warehouse")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedWarehouse),expression:"selectedWarehouse"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.isSubmitted && _vm.$v.selectedWarehouse.$error,
                    },attrs:{"id":"selectedWarehouse","name":"selectedWarehouse","disabled":_vm.id > 0},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedWarehouse=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.onWarehouseChange()}]}},[_c('option',{domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.warehouses),function(war){return _c('option',{key:war.id,domProps:{"value":war.id}},[_vm._v(" "+_vm._s(war.warehouse_registration_id + ": ")+" "+_vm._s(war.address)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedWarehouse.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedWarehouse.required)?_c('span',[_vm._v("Warehouse is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"selectedCollateralType"}},[_vm._v("Collateral Type")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCollateralType),expression:"selectedCollateralType"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.isSubmitted && _vm.$v.selectedCollateralType.$error,
                    },attrs:{"id":"selectedCollateralType","name":"selectedCollateralType","disabled":_vm.id > 0},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedCollateralType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.collateralStages),function(col){return _c('option',{key:col.id,domProps:{"value":col.id}},[_vm._v(" "+_vm._s(col.stage_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedCollateralType.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedCollateralType.required)?_c('span',[_vm._v("Collateral Type is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"selectedCM"}},[_vm._v("Collateral Manager Company")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCM),expression:"selectedCM"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.selectedCM.$error,
                    },attrs:{"id":"selectedCM","name":"selectedCM","disabled":_vm.id > 0},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedCM=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.onCMChange()}]}},[_c('option',{domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.mappedCM),function(cm){return _c('option',{key:cm.collateral_manager_master_id,domProps:{"value":cm.collateral_manager_master_id}},[_vm._v(" "+_vm._s(cm.collateral_id)+": "+_vm._s(cm.company_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedCM.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedCM.required)?_c('span',[_vm._v("Collateral Manager Company is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4 col-sm-4 col-6"},[_c('label',[_vm._v("Employees")]),_c('multiselect',{attrs:{"placeholder":"--- Select ---","custom-label":_vm.getEmployeeLabel,"track-by":"id","options":_vm.mappedCMEmployees,"multiple":true,"disabled":_vm.loggedInUserGroupId !== 'Admin'},model:{value:(_vm.selectedMappedCMEmployees),callback:function ($$v) {_vm.selectedMappedCMEmployees=$$v},expression:"selectedMappedCMEmployees"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("No Mapped CM Employee found with given input.")])])],1),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"quantity"}},[_vm._v("Quantity (MT)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quantity),expression:"quantity"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.quantity.$error,
                    },attrs:{"type":"text","id":"quantity","disabled":_vm.id > 0},domProps:{"value":(_vm.quantity)},on:{"keypress":function($event){return _vm.removeMinus($event)},"input":function($event){if($event.target.composing){ return; }_vm.quantity=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.quantity.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.quantity.required)?_c('span',[_vm._v("Quantity is required")]):_vm._e(),(!_vm.$v.quantity.decimal)?_c('span',[_vm._v("Quantity should be decimal only"),_c('br')]):_vm._e(),(!_vm.$v.quantity.maxLength)?_c('span',[_vm._v("Max length of 10 digits exceeded")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"requestDate"}},[_vm._v("Request Date")]),_c('datepicker',{staticClass:"datepicker",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.requestDate.$error,
                    },attrs:{"bootstrap-styling":true,"name":"requestDate","id":"requestDate","placeholder":"Select Date","format":_vm.$store.state.date_format,"calendar-button-icon":"fa fa-calendar","calendar-button":true,"disabled":_vm.id > 0,"disabledDates":{
                      from: new Date(),
                      to: new Date(_vm.releaseReqBackcapDate),
                    }},model:{value:(_vm.requestDate),callback:function ($$v) {_vm.requestDate=$$v},expression:"requestDate"}}),(_vm.isSubmitted && _vm.$v.requestDate.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.requestDate.required)?_c('span',[_vm._v("Request Date is required")]):_vm._e()]):_vm._e()],1)])])])])]),_c('div',{staticClass:"container-fluid ot_tb pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-lg-6"},[_c('ul',{attrs:{"id":"sr_btn"}},[_c('li',[_c('button',{staticClass:"btn btn-sm sh_n btn-dark",attrs:{"type":"button","disabled":_vm.ReleaseReqDocumentAddMoreDisabled},on:{"click":function($event){return _vm.addReleaseReqDoc()}}},[_c('i',{staticClass:"icofont-plus"}),_vm._v(" Add More ")])])])])])]),_c('div',{staticClass:"card-body p-0"},[_vm._l((_vm.ReleaseReqDocuments),function(doc,index){return _c('div',{key:index,staticClass:"row"},[(doc.document_path !== null && _vm.id > 0)?_c('div',{staticClass:"col-1"},[_c('div',{staticClass:"form-group fc"},[_c('label',[_vm._v("Download")]),_c('div',{style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.downloadDocument(doc.document_path)}}},[_c('i',{staticClass:"icofont-download text-info",attrs:{"id":"buttons"}})])])]):_vm._e(),_c('div',{staticClass:"col-md-4 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v("Upload Document")]),_c('div',{staticClass:"custom-file"},[_c('input',{ref:"file",refInFor:true,staticClass:"custom-file-input",class:{
                            'is-invalid': doc.errors,
                          },attrs:{"type":"file","id":"customFile","name":"file","accept":".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"},on:{"change":function($event){return _vm.handleFileUploadReleaseReqDocuments(index, $event)}}}),_c('small',[_c('p',{staticStyle:{"background-color":"powderblue"}},[_c('b',[_vm._v(_vm._s(doc.display_file_name))])])]),(doc.errors)?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(doc.errors))])]):_vm._e(),_c('label',{staticClass:"custom-file-label",attrs:{"for":"customFile"}},[_vm._v("Choose file")])])])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v("Issue Date")]),_c('datepicker',{staticClass:"datepicker",attrs:{"bootstrap-styling":true,"name":"IssueDate","id":"IssueDate","placeholder":"Select Date","format":_vm.$store.state.date_format,"calendar-button-icon":"fa fa-calendar","disabledDates":{ from: new Date() },"calendar-button":true},model:{value:(doc.IssueDate),callback:function ($$v) {_vm.$set(doc, "IssueDate", $$v)},expression:"doc.IssueDate"}})],1)]),_c('div',{staticClass:"col-md-2 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{attrs:{"for":"comments"}},[_vm._v("Comments")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(doc.comments),expression:"doc.comments"}],staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.isSubmitted &&
                            _vm.$v.ReleaseReqDocuments.$each[index].comments
                              .$error,
                        },attrs:{"type":"text","id":"comments"},domProps:{"value":(doc.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(doc, "comments", $event.target.value)}}}),(
                          _vm.isSubmitted &&
                          _vm.$v.ReleaseReqDocuments.$each[index].comments.$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(
                            !_vm.$v.ReleaseReqDocuments.$each[index].comments
                              .maxLength
                          )?_c('span',[_vm._v("Max length of 100 characters exceeded")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-1 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticStyle:{"visibility":"hidden"},attrs:{"for":"exampleInputEmail1"}},[_vm._v("Delete")]),_c('div',{staticStyle:{"width":"250px"},style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.deleteReleaseReqDocuments(index, doc.id)}}},[_c('i',{staticClass:"icofont-trash text-danger"})])])])])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right mb-3"},[_c('button',{staticClass:"btn-sm btn btn-primary sh",attrs:{"type":"button","disabled":_vm.isDisabledReleaseReqDocumentSaveInfo},on:{"click":function($event){return _vm.onReqDocumentSaveInfo()}}},[_vm._v(" Save Info ")])])])],2)])])])]),_c('div',{staticClass:"container-fluid ot_tb pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_vm._m(2),_c('div',{staticClass:"card-body fc p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"custom-control custom-checkbox mb-3"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.releaseTo),expression:"releaseTo"}],class:{
                            'is-invalid': _vm.isSubmitted && _vm.$v.releaseTo.$error,
                          },attrs:{"id":"releaseTo","type":"radio","value":"logisticsInfo","disabled":_vm.id > 0},domProps:{"checked":_vm._q(_vm.releaseTo,"logisticsInfo")},on:{"change":function($event){_vm.releaseTo="logisticsInfo"}}}),_vm._v(" Logistics Info ")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"custom-control custom-checkbox mb-3"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.releaseTo),expression:"releaseTo"}],class:{
                            'is-invalid': _vm.isSubmitted && _vm.$v.releaseTo.$error,
                          },attrs:{"type":"radio","value":"directShipping","disabled":_vm.id > 0},domProps:{"checked":_vm._q(_vm.releaseTo,"directShipping")},on:{"change":function($event){_vm.releaseTo="directShipping"}}}),_vm._v(" Direct Shipping ")])])]),(_vm.isSubmitted && _vm.$v.releaseTo.$error)?_c('div',{staticClass:"error"},[(!_vm.$v.releaseTo.required)?_c('span',[_vm._v("Please select Release To")]):_vm._e()]):_vm._e()])])])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right mb-3"},[_c('ul',{attrs:{"id":"sr_btn"}},[_c('li',[_c('button',{staticClass:"btn-sm btn btn-success sh",attrs:{"type":"button","id":"submit"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" Submit ")])]),_c('li',[_c('button',{staticClass:"btn-sm btn btn-dark sh",staticStyle:{"cursor":"pointer"},attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel ")])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"headingt"},[_c('i',{staticClass:"icofont-plus"}),_vm._v(" Release Request ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"headingt"},[_vm._v("Documents")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('h1',{staticClass:"headingt"},[_vm._v("Release To")])])])])}]

export { render, staticRenderFns }