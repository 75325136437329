<!-- This is fund-disctribution page.  -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div id="new_fund" class="container-fluid ot_tb pt-3">

          <div class="row">
            <div class="col-lg-9 col-md-12">

              <div class="inputbx">
                <div class="row align_c">
                  <div class="col-md-3 col-sm-3">
                    <div class="form-group fc">
                      <select 
                        class="selectpicker" 
                        aria-label="select fund" 
                        title="select fund" 
                        data-live-search="true"  
                        name="selectedFund" 
                        v-model="selectedFund"
                        @change="getInvestorsByFund();
                          fetchNAVNotesMaturityData();"
                      >
                        <option 
                          v-for="fund in funds" 
                          :value="fund.fund_id" 
                          :key="fund.fund_id">
                          {{ fund.fund_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-9 col-sm-9">
                    <div class="jst_fe fc">
                      <h6 class="info col2">
                        **All Amounts are in {{ currencyName }} | <span class="col1">As on {{ todayDate }}</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-lg-8 col-md-12 col-sm-12">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="title">
                        <div class="jst_sb">
                          <h6 class="tp_mid text2">
                            Net Asset Value (NAV)
                          </h6>
                          <div class="jst_fe align_c">
                            <h6>
                              {{lastMonthValue}} Mn
                            </h6>
                            <div v-if="PercentageChange < '0.0'" class="redbx">
                              <h6>
                                <i class="bi bi-minus"></i>{{PercentageChange}}%
                              </h6>
                            </div>
                            <div v-else class="grnbx">
                              <h6>
                                <i class="bi bi-plus"></i>{{PercentageChange}}%
                              </h6>
                            </div>
                            <div v-if="PercentageChange < '0.0'">
                              <i class="bi bi-caret-down-fill red1"></i>
                            </div>
                            <div v-else>
                              <i class="bi bi-caret-up-fill grn"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="ht300">
                        <apexchart 
                          v-if="NAVChartDataReady" 
                          type="area" 
                          height="285"
                          :options="NAVChartOptions" 
                          :series="NAVSeries">
                        </apexchart> 
                        <h6 class="tp_mid title text2" v-if="!NAVChartDataReady">No Data Found</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-12 col-sm-12">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <h6 class="tp_mid title text2">
                        Notes – based on Maturity
                      </h6>
                      <div class="ht300">
                        <apexchart 
                          v-if="NotesMaturityChartDataReady"  
                          type="bar" 
                          height="285"
                          width="315"
                          :options="NotesMaturityChartOptions" 
                          :series="NotesMaturitySeries">
                        </apexchart>
                        <h6 class="tp_mid title text2" v-if="!NotesMaturityChartDataReady">No Data Found</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-8 col-md-12 col-sm-12">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="title">
                        <div class="row align_c">
                          <div class="col-md-8 col-sm-6">
                            <h6 v-if="this.top5Flag" class="tp_mid text2">
                              Top 5 Investor - Based on Investment
                            </h6>
                            <h6 v-else class="tp_mid text2">
                              5 Investor - Based on Investment
                            </h6>
                          </div>
                          <div class="col-md-4 col-sm-6 jst_fe">
                            <div class="form-group fc">
                              <select 
                                class="selectpicker" 
                                multiple 
                                aria-label="select investors" 
                                title="select investors"
                                data-dropup-auto="false" 
                                data-live-search="true"  
                                v-model="selectedInvestor"
                                @change="fetchTop5InvestorData()"
                                >
                                <option
                                  v-for="inv in investors"
                                  :title="inv.investor_name"
                                  :value="inv.investor_id"
                                  :key="inv.investor_id"
                                >
                                  {{ inv.investor_name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="ht300">
                        <apexchart 
                          v-if="Top5InvestorChartDataReady" 
                          type="bar" 
                          height="285"
                          width="560"
                          :options="Top5InvestorChartOptions" 
                          :series="Top5InvestorSeries">
                        </apexchart>
                        <h6 class="tp_mid title text2" v-if="!Top5InvestorChartDataReady">No Data Found</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-12 col-sm-12">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="title">
                        <div class="row align_c">
                          <div class="col-md-7 col-sm-7">
                            <h6 class="tp_mid text2">
                              Interest on Investment 
                            </h6>
                          </div>
                          <div class="col-md-5 col-sm-5">
                            <div class="form-group fc">
                              <select 
                                class="selectpicker" 
                                aria-label="select investor" 
                                title="select investor" 
                                data-live-search="true"  
                                name="selectedInvestorWithAll"
                                v-model="selectedInvestorWithAll"
                                @change="fetchInvestorInterestData()"
                              >
                                <option
                                  v-for="inv in investorsWithAll"
                                  :value="inv.investor_id"
                                  :key="inv.investor_id"
                                >
                                  {{ inv.investor_name }}
                                </option>
                              </select>
                              
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="ht300">
                        <apexchart 
                          v-if="InvestorInterestChartDataReady" 
                          type="donut" 
                          height="285"
                          :options="InvestorInterestChartOptions" 
                          :series="InvestorInterestSeries">
                        </apexchart>
                        <h6 class="tp_mid title text2" v-if="!InvestorInterestChartDataReady">No Data Found</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-lg-3 col-md-12">

              <div class="inputbx">
                <div class="row align_c">
                  <div class="col-md-9 col-sm-12">
                    <div class="form-group fc">
                      <select 
                        class="selectpicker" 
                        aria-label="select fund" 
                        title="select fund" 
                        data-live-search="true"
                        name="selectedFundWithAll" 
                        v-model="selectedFundWithAll"
                        @change="fetchInvestorByInvestmentFundCurrencyCountryData()"
                      >
                        <option 
                          v-for="fund in fundsWithAll" 
                          :value="fund.fund_id" 
                          :key="fund.fund_id">
                          {{ fund.fund_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="title">
                        <div class="row align_c">
                          <div class="col-md-12">
                            <h6 class="tp_mid text2">
                              Investments - Investment Currency
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="ht180">
                        <apexchart 
                          v-if="InvestorByInvestmentCurrencyChartDataReady" 
                          type="donut" 
                          height="170"
                          :options="InvestorByInvestmentCurrencyChartOptions" 
                          :series="InvestorByInvestmentCurrencySeries">
                        </apexchart>
                        <h6 class="tp_mid title text2" v-if="!InvestorByInvestmentCurrencyChartDataReady">No Data Found</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="title">
                        <div class="row align_c">
                          <div class="col-md-12">
                            <h6 class="tp_mid text2">
                              Investments - Investor Country
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="ht180">
                        <apexchart 
                          v-if="InvestorByInvestorCountryChartDataReady" 
                          type="donut" 
                          height="170"
                          :options="InvestorByInvestorCountryChartOptions" 
                          :series="InvestorByInvestorCountrySeries">
                        </apexchart>
                        <h6 class="tp_mid title text2" v-if="!InvestorByInvestorCountryChartDataReady">No Data Found</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="card card-primary card-tabs">
                    <div class="card-body">
                      <div class="title">
                        <div class="row align_c">
                          <div class="col-md-12">
                            <h6 class="tp_mid text2">
                              Investments - Fund Currency
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="ht180">
                        <apexchart 
                          v-if="InvestorByFundCurrencyChartDataReady" 
                          type="donut" 
                          height="170"
                          :options="InvestorByFundCurrencyChartOptions" 
                          :series="InvestorByFundCurrencySeries">
                        </apexchart>
                        <h6 class="tp_mid title text2" v-if="!InvestorByFundCurrencyChartDataReady">No Data Found</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
      <!-- /.content-->
    </div>
    <!-- /.content-wrapper -->

  </div>
  <!-- ./wrapper -->
</template>


<script>
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {
  required,
  maxLength,
  minLength,
  numeric,
  alphaNum,
  maxValue,
  decimal,
  between,
} from "vuelidate/lib/validators";
Vue.component('apexchart', VueApexCharts);

export default {
  data() {
    return {
      currencyName: "",
      lastMonthValue: 0.00,
      PercentageChange: 0.00,
      todayDate: moment().format('DD MMMM YYYY'),
      top5Flag: true,
      // Fund Dropdown
      selectedFund: null,
      funds: [],
      selectedFundWithAll: 'all',
      fundsWithAll: [],

      // Investor Dropdown
      selectedInvestor: [],
      lastValidSelection: [],
      investors: [],
      selectedInvestorWithAll: 'all',
      investorsWithAll: [],

      Colors: ['#1930AB', '#0070C0', '#00B0F0', '#7F7F7F', '#D9D9D9'], // Add your custom colors here


      // NAV
      NAVChartOptions: {},
      NAVSeries: [],
      NAVChartData: {},
      NAVChartDataReady: false,

      // Notes Maturity 
      NotesMaturityChartOptions: {},
      NotesMaturitySeries: [],
      NotesMaturityChartData: {},
      NotesMaturityChartDataReady: false,

      // Top 5 Investor
      Top5InvestorChartOptions: {},
      Top5InvestorSeries: [],
      Top5InvestorChartData: {},
      Top5InvestorChartDataReady: false,

      // Investor Interest
      InvestorInterestChartOptions: {},
      InvestorInterestSeries: [],
      InvestorInterestChartData: {},
      InvestorInterestChartDataReady: false,

      // Investor By Investment Currency
      InvestorByInvestmentCurrencyChartOptions: {},
      InvestorByInvestmentCurrencySeries: [],
      InvestorByInvestmentCurrencyChartData: {},
      InvestorByInvestmentCurrencyChartDataReady: false,

      // Investor By Investor Country
      InvestorByInvestorCountryChartOptions: {},
      InvestorByInvestorCountrySeries: [],
      InvestorByInvestorCountryChartData: {},
      InvestorByInvestorCountryChartDataReady: false,

      // Investor By Fund Currency
      InvestorByFundCurrencyChartOptions: {},
      InvestorByFundCurrencySeries: [],
      InvestorByFundCurrencyChartData: {},
      InvestorByFundCurrencyChartDataReady: false,


      desiredTicks: 3,

      isBasicDetailsSubmitted: false,
      isSubmitted: false,
    };
  },
  validations: {
    selectedFund: {
      required,
    },
  },
  components: {
    Datepicker,
    Multiselect: window.VueMultiselect.default,
  },
  watch: {
    selectedInvestor(newVal, oldVal) {
      if (newVal.length > 5) {
        this.$nextTick(() => {
          this.selectedInvestor = [...this.lastValidSelection];
          this.$nextTick(() => {
            // Use jQuery to update the selectpicker UI.
            $('.selectpicker').selectpicker('refresh');
          });
        });
      } else {
        this.lastValidSelection = [...newVal];
      }
    }
  },

  methods: {
    truncateLabel(label, maxLength) {
      if (label.length <= maxLength) return label;
      const trimmedString = label.substr(0, maxLength);
      if (trimmedString.lastIndexOf(' ') > 0) {
        return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))) + '...';
      }
      return trimmedString + '...';
    },
    
    calculateIntervalForThreeTicks(startDateStr, endDateStr) {
      const minDate = new Date(startDateStr).getTime();
      const maxDate = new Date(endDateStr).getTime();
      const range = maxDate - minDate;

      // Dynamically calculate interval for desired number of ticks
      const interval = range / (this.desiredTicks - 1);


      return interval;
    },

    // Generalized method to dynamically calculate tick positions for date-based data
    calculateDynamicTickPositions(seriesDataArray, minTicks = 2, maxTicks = 5) {
      if (!seriesDataArray.length) {
        return {
          tickPositions: [],
          interval: 0,
          minDate: null,
          maxDate: null,
          range: 0,
          tickCount: 0
        };
      }

      // Flatten the array of series data to get all data points
      let allDataPoints = seriesDataArray.flatMap(series => series.data);

      // Assuming 'x' values are already timestamps, use them directly
      let timestamps = allDataPoints.map(point => point.x);

      // Calculate min and max timestamp from all data points
      let minTimestamp = Math.min(...timestamps);
      let maxTimestamp = Math.max(...timestamps);
      const range = maxTimestamp - minTimestamp;

      // Determine the optimal number of ticks based on the unique timestamps in the dataset
      const uniqueTimestamps = new Set(timestamps).size;
      const optimalTicks = Math.min(Math.max(uniqueTimestamps, minTicks), maxTicks);

      // Calculate the interval between ticks. Avoid division by zero for a single unique timestamp
      const interval = uniqueTimestamps > 1 ? range / (optimalTicks - 1) : 0;

      // Calculate and return tick positions based on the interval, converting back to date objects or date strings as needed
      let tickPositions = [];
      for (let i = 0; i < optimalTicks; i++) {
        const tickTimestamp = minTimestamp + interval * i;
        // Convert timestamp back to Date object or to a specific string format as required by your charting library
        tickPositions.push(tickTimestamp);
      }

      return {
        tickPositions, // The calculated positions for ticks on the chart
        interval, // The interval between each tick
        minDate: minTimestamp, // The minimum date (timestamp) in the data set
        maxDate: maxTimestamp, // The maximum date (timestamp) in the data set
        range, // The range between the minimum and maximum dates
        tickCount: optimalTicks // The number of ticks calculated to be optimal
      };
    },

    fetchNAVNotesMaturityData() {
      console.log("fetchNAVNotesMaturityData", this.selectedFund)
      // Simulated API response
      const path = "get-fund-nav-notes-maturity/" + this.selectedFund;
      console.log(path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.NAVChartData = res.data.NAVChartData
            this.NAVSeries = this.NAVChartData.seriesData.map(series => ({
              name: series.name,
              data: series.data.map(point => ({
                  x: point.dateX, // These are now treated as categories
                  y: point.dataY,
              })),
            }));
            console.log("NAVSeries ", this.NAVSeries)
            this.setNAVOptionData(this.NAVSeries)

            this.NotesMaturityChartData = res.data.NotesMaturityChartData;
            // For a bar chart, the series data structure can stay the same if it suits your data representation
            this.NotesMaturitySeries = this.NotesMaturityChartData.seriesData.map(series => ({
              name: series.name,
              data: series.data // Assuming this is already in the correct format for a bar chart
            }));

            console.log("NotesMaturitySeries ", this.NotesMaturitySeries)
            this.setNotesMaturitysOptionData(this.NotesMaturityChartData.xAxisData)
            this.currencyName = res.data.currencyName
            this.lastMonthValue = res.data.lastMonthValue
            this.PercentageChange = res.data.PercentageChange
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });

    },

    updateSelection(newSelection) {
      console.log("updateSelection")
      if (newSelection.length <= 5) {
        this.selectedInvestor = newSelection;
        this.lastValidSelection = [...newSelection];
      } else {
        Utils.make_alert("warning", "You can only select up to 5 investors.");
      }
    },

    fetchTop5InvestorData() {
      console.log("fetchTop5InvestorData", this.selectedFund)
      console.log("selectedInvestor",this.selectedInvestor)
      this.updateSelection(this.selectedInvestor)
      let finalSelectedInvestor = null
      if(this.selectedInvestor.length > 5) {
        return;
      } else {
        if (this.selectedInvestor.length > 0) {
          finalSelectedInvestor = this.selectedInvestor
        } else {
          finalSelectedInvestor = null
        }
      }
      
      console.log("finalSelectedInvestor",finalSelectedInvestor)
      // Simulated API response
      const path = "get-top-five-investor" +
        "/" +
        this.selectedFund +
        "/" +
        finalSelectedInvestor;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if(this.selectedInvestor.length > 0){
            this.top5Flag = false
          }else {
            this.top5Flag = true
          }
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.Top5InvestorChartData = res.data.top_investor_data;
            // For a bar chart, the series data structure can stay the same if it suits your data representation
            this.Top5InvestorSeries = this.Top5InvestorChartData.seriesData.map(series => ({
              name: series.name,
              data: series.data // Assuming this is already in the correct format for a bar chart
            }));

            console.log("Top5InvestorSeries ", this.Top5InvestorSeries)
            this.setTop5InvestorChartOptionData(this.Top5InvestorChartData.xAxisData)


          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });

    },

    fetchInvestorInterestData() {
      console.log("fetchInvestorInterestData", this.selectedFund)
      let finalSingleSelectedInvestor = null;
      let selectedAllInvestor = 0;
      if (this.selectedInvestorWithAll == 'all') {
        selectedAllInvestor = 1;
      } else {
        finalSingleSelectedInvestor = this.selectedInvestorWithAll
      }
      // Simulated API response
      const path = "get-investor-interests" +
        "/" +
        this.selectedFund +
        "/" +
        finalSingleSelectedInvestor +
        "/" +
        selectedAllInvestor;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.InvestorInterestChartData = res.data.InvestorInterestChart;
            this.InvestorInterestSeries = this.InvestorInterestChartData.seriesData.map(series => series.data);

            console.log("InvestorInterestSeries ", this.InvestorInterestSeries)
            // Calculate the sum of all elements in the InvestorInterestSeries
            const sumOfSeriesData = this.InvestorInterestSeries.reduce((sum, current) => sum + current, 0);
            // Check if the labels array is not empty and the sum of series data is greater than 0
            if (this.InvestorInterestChartData.labels.length > 0 && sumOfSeriesData > 0) {
                this.setInvestorInterestOptionData(this.InvestorInterestChartData.labels, this.InvestorInterestSeries);
            } else {
                // If there are no labels or all data points are 0, reset the chart data and indicate that the data is not ready
                this.InvestorInterestChartOptions = {};
                this.InvestorInterestSeries = [];
                this.InvestorInterestChartData = {};
                this.InvestorInterestChartDataReady = false;
            }


          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });

    },

    fetchInvestorByInvestmentFundCurrencyCountryData() {
      console.log("fetchInvestorByInvestmentFundCurrencyCountryData", this.selectedFundWithAll)
      let selectedFundWithAll = null;
      let selectedAllFund = 0;
      if (this.selectedFundWithAll == 'all') {
        selectedAllFund = 1;
      } else {
        selectedFundWithAll = this.selectedFundWithAll
      }
      // Simulated API response
      const path = "get-investor-investment-fund-currency-country" +
        "/" +
        selectedFundWithAll +
        "/" +
        selectedAllFund;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.InvestorByInvestmentCurrencyChartData = res.data.inv_by_inv_donut_chart_data;
            this.InvestorByInvestmentCurrencySeries = this.InvestorByInvestmentCurrencyChartData.seriesData.map(series => series.data);

            
            console.log("InvestorByInvestmentCurrencySeries ", this.InvestorByInvestmentCurrencySeries)
            if(this.InvestorByInvestmentCurrencyChartData.labels.length > 0){
              this.setInvestorByInvestmentCurrencyOptionData(this.InvestorByInvestmentCurrencyChartData.labels,this.InvestorByInvestmentCurrencySeries)
            } else {
              this.InvestorByInvestmentCurrencyChartOptions = {};
              this.InvestorByInvestmentCurrencySeries = [];
              this.InvestorByInvestmentCurrencyChartData = {};
              this.InvestorByInvestmentCurrencyChartDataReady = false;
            }
            
            this.InvestorByInvestorCountryChartData = res.data.inv_by_country_donut_chart_data;
            this.InvestorByInvestorCountrySeries = this.InvestorByInvestorCountryChartData.seriesData.map(series => series.data);
            
            console.log("InvestorByInvestorCountrySeries ", this.InvestorByInvestorCountrySeries)
            if(this.InvestorByInvestorCountryChartData.labels.length > 0){
              this.setInvestorByInvestorCountryOptionData(this.InvestorByInvestorCountryChartData.labels,this.InvestorByInvestorCountrySeries)
            } else {
              this.InvestorByInvestorCountryChartOptions = {};
              this.InvestorByInvestorCountrySeries = [];
              this.InvestorByInvestorCountryChartData = {};
              this.InvestorByInvestorCountryChartDataReady = false;
            }

            this.InvestorByFundCurrencyChartData = res.data.inv_by_fund_cur_donut_chart_data;
            this.InvestorByFundCurrencySeries = this.InvestorByFundCurrencyChartData.seriesData.map(series => series.data);

            console.log("InvestorByFundCurrencySeries ", this.InvestorByFundCurrencySeries)
            if(this.InvestorByFundCurrencyChartData.labels.length > 0){
              this.setInvestorByFundCurrencyOptionData(this.InvestorByFundCurrencyChartData.labels,this.InvestorByFundCurrencySeries)
            } else {
              this.InvestorByFundCurrencyChartOptions = {};
              this.InvestorByFundCurrencySeries = [];
              this.InvestorByFundCurrencyChartData = {};
              this.InvestorByFundCurrencyChartDataReady = false;
            }


          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });

    },

    setNAVOptionData(NAVSeries) {

      // Dynamically construct chartOptions based on fetched data
      this.NAVChartOptions = {
        chart: {
          id: 'vuechart-example',
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
        },
        colors: this.Colors, // Add your custom colors here
        stroke: {
          curve: 'straight' // This makes the line sharp instead of curved
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5,
          hover: {
            size: 8
          }
        },
        yaxis: {
          // Set the y-axis name
          title: {
            text: 'Amounts in millions',
            style: {
              fontFamily: 'Calibri',
              fontSize: '14px',
            },
          },
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
          }
        },
        xaxis: {
            type: 'category',
            // Removed datetime specific configurations like min, max, range, etc.
            labels: {
                style: {
                    fontFamily: 'Calibri',
                    fontSize: '12px'
                },
            },
            // Removed annotations for tick positions as it's now handled by categories
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              // Calculate percentage
              return `${value} Mn`;
            }
          }
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 10, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
      };
      this.NAVChartDataReady = true;
    },

    setNotesMaturitysOptionData(xAxisData) {
      // Setting up chart options for a bar chart
      this.NotesMaturityChartOptions = {
        chart: {
          id: 'vuechart-example',
          type: 'bar', // Changed from 'line' to 'bar'
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: this.Colors, // Add your custom colors here
        yaxis: {
          // Set the y-axis name
          title: {
            text: 'Amounts in millions',
            style: {
              fontFamily: 'Calibri',
              fontSize: '14px',
            },
          },
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
          }
        },
        xaxis: {
          // Assuming your data can be categorized by dates or specific categories
          categories: xAxisData,
          tickPlacement: 'on',
          style: {
            fontFamily: 'Calibri',
            fontSize: '12px'
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top', // position of the data label
            },
          },
        },
        legend: {
          position: 'bottom', // Position the legend at the bottom
          horizontalAlign: 'left', // Center-align the legend
          offsetX: 0, // Adjust this value to fine-tune the horizontal position
          offsetY: 0, // Adjust this value if you need to fine-tune the vertical position
          fontFamily: 'Calibri',
          fontSize: '12px'
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 0, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
        dataLabels: {
          enabled: false,
            // formatter: function (val) {
          //   // This formatter function will be called for every data label.
          //   // You can format the value here to be shown as required.
          //   return val.toFixed(2); // Formats the value to 2 decimal places.
          // },
          // offsetY: -20, // Adjusts the vertical position of the data labels.
          // style: {
          //   fontSize: '12px',
          //   colors: ["#304758"]
          // },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              // Calculate percentage
              return `${value} Mn`;
            }
          },
        },
      };
      this.NotesMaturityChartDataReady = true;
    },

    setTop5InvestorChartOptionData(xAxisData) {
      // const yAxisTitleLength = this.Top5InvestorChartOptions.seriesData[0].name[0].length;
      // console.log("yAxisTitleLength",yAxisTitleLength)
      
      // Setting up chart options for a bar chart
      this.Top5InvestorChartOptions = {
        chart: {
          id: 'vuechart-example',
          type: 'bar', // Changed from 'line' to 'bar'
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: this.Colors, // Add your custom colors here
        yaxis: {
          // Set the y-axis name
          title: {
            text: 'Amounts in millions',
            style: {
              fontFamily: 'Calibri',
              fontSize: '14px',
            },
          },
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
          }
        },
        xaxis: {
          categories: xAxisData, // your original categories
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
            formatter: function(val) {
              // Truncate string to a maximum of 10 characters when there are more than 2 bars
              if (xAxisData.length > 1 && val.length > 10) {
                return val.substring(0, 10) + '...'; // Adjust the number of characters as needed
              } else {
                return val; // Full label for 2 or fewer bars
              }
            }
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top', // position of the data label
            },
          },
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 0, 
            bottom: 0,
            left: 0
          }
        },
        legend: {
          position: 'bottom', 
          horizontalAlign: 'center', 
          offsetX: 0, 
          offsetY: 0, 
          fontFamily: 'Calibri',
          fontSize: '12px'
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            // This formatter function will be called for every data label.
            // You can format the value here to be shown as required.
            return `${val} Mn`; // Formats the value to 2 decimal places.
          },
          offsetY: -20, // Adjusts the vertical position of the data labels.
          style: {
            fontSize: '11px',
            colors: ["#304758"]
          },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              return `${value} Mn`;
            }
          },
          x: {
            formatter: function(val, opts) {
              // Access the full label from the original categories array using the dataPointIndex
              let fullText = xAxisData[opts.dataPointIndex];
              // Find spaces to insert line breaks, but do not exceed a certain length (e.g., 40 characters)
              let breakPoint = fullText.lastIndexOf(' ', 40);
              if (breakPoint === -1 || fullText.length <= 40) {
                return fullText; // No need to break if short enough or no space found
              } else {
                return fullText.substring(0, breakPoint) + '<br>' + fullText.substring(breakPoint + 1);
              }
            }
          },
        },
      };
      this.Top5InvestorChartDataReady = true;
    },

    setInvestorInterestOptionData(InvestorInterestLabels,InvestorInterestSeries) {
      const total = InvestorInterestSeries.reduce((acc, cur) => acc + cur, 0);

      this.InvestorInterestChartOptions = {
        chart: {
          type: 'donut',
        },
        colors: this.Colors, // Add your custom colors here
        labels: InvestorInterestLabels,
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 0, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              let percentage = (value / total * 100).toFixed(2); // Two decimal places
              return `${value} Mn - ${percentage}%`;
            }
          }
        },
        dataLabels: {
          enabled: false // This line hides the data labels on the chart
        },
        legend: {
          position: 'bottom', // Position the legend at the bottom
          horizontalAlign: 'center', // Center-align the legend
          offsetX: 0, // Adjust this value to fine-tune the horizontal position
          offsetY: 0, // Adjust this value if you need to fine-tune the vertical position
          fontFamily: 'Calibri',
          fontSize: '12px'
        },
      };

      this.InvestorInterestChartDataReady = true;
    },

    setInvestorByInvestmentCurrencyOptionData(InvestorByInvestmentCurrencyLabels,InvestorByInvestmentCurrencySeries) {
      const total = InvestorByInvestmentCurrencySeries.reduce((acc, cur) => acc + cur, 0);
      
      this.InvestorByInvestmentCurrencyChartOptions = {
        chart: {
          type: 'donut',
        },
        colors: this.Colors, // Add your custom colors here
        labels: InvestorByInvestmentCurrencyLabels,
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              let percentage = (value / total * 100).toFixed(2); // Two decimal places
              return `${value} Mn - ${percentage}%`;
            }
          }
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 10, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
        dataLabels: {
          enabled: false // This line hides the data labels on the chart
        },
        legend: {
          show: false,
        }
      };

      this.InvestorByInvestmentCurrencyChartDataReady = true;
    },

    setInvestorByInvestorCountryOptionData(InvestorByInvestorCountryLabels,InvestorByInvestorCountrySeries) {
      const total = InvestorByInvestorCountrySeries.reduce((acc, cur) => acc + cur, 0);

      this.InvestorByInvestorCountryChartOptions = {
        chart: {
          type: 'donut',
        },
        colors: this.Colors, // Add your custom colors here
        labels: InvestorByInvestorCountryLabels,
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              let percentage = (value / total * 100).toFixed(2); // Two decimal places
              return `${value} Mn - ${percentage}%`;
            }
          }
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 0, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
        dataLabels: {
          enabled: false // This line hides the data labels on the chart
        },
        legend: {
          show: false,
        }
      };

      this.InvestorByInvestorCountryChartDataReady = true;
    },

    setInvestorByFundCurrencyOptionData(InvestorByFundCurrencyLabels,InvestorByFundCurrencySeries) {
      const total = InvestorByFundCurrencySeries.reduce((acc, cur) => acc + cur, 0);

      this.InvestorByFundCurrencyChartOptions = {
        chart: {
          type: 'donut',
        },
        colors: this.Colors, // Add your custom colors here
        labels: InvestorByFundCurrencyLabels,
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              let percentage = (value / total * 100).toFixed(2); // Two decimal places
              return `${value} Mn - ${percentage}%`;
            }
          }
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 10, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
        dataLabels: {
          enabled: false // This line hides the data labels on the chart
        },
        legend: {
          show: false,
        }
      };

      this.InvestorByFundCurrencyChartDataReady = true;
    },

    getInvestorsByFund() {
      console.log("getInvestorsByFund", this.selectedFund)
      const path = "/get-investor-by-fund/" + this.selectedFund;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          console.log("status", res.data.status)
          if (res.data.status == "success") {
            this.selectedInvestor = []
            this.lastValidSelection = []
            this.selectedInvestorWithAll = null;
            this.investors = res.data.investor_list;
            this.$nextTick(() => {
              $('.selectpicker').selectpicker('refresh');
            });
            
            // Create a copy of res.data.investor_list for investorsWithAll
            this.investorsWithAll = [...res.data.investor_list];
            // Add a new object at the beginning of the investorsWithAll array
            this.investorsWithAll.unshift({
              investor_id: 'all',
              investor_name: 'All Investor'
            });
            this.$nextTick(() => {
              console.log("refresh")
              $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
            });
            if (this.investors.length > 0) {
              this.fetchTop5InvestorData();
            } else {
              this.Top5InvestorChartOptions = {};
              this.Top5InvestorSeries = [];
              this.Top5InvestorChartData = {};
              this.Top5InvestorChartDataReady = false;
            }
            if (this.investorsWithAll.length > 0) {
              this.selectedInvestorWithAll = this.investorsWithAll[0].investor_id
              this.fetchInvestorInterestData();
            } else {
              this.selectedInvestorWithAll = null;
              this.InvestorInterestChartOptions = {};
              this.InvestorInterestSeries = [];
              this.InvestorInterestChartData = {};
              this.InvestorInterestChartDataReady = false;
            }

            this.$nextTick(() => {
              console.log("refresh")
              $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFunds() {
      const path = "fund-master";
      Utils.start_loading();
      axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
      .then((res) => {
          Utils.stop_loading();
          this.funds = res.data.funds;
          // Create a copy of res.data.funds for fundsWithAll
          this.fundsWithAll = [...res.data.funds];
          // Add a new object at the beginning of the fundsWithAll array
          this.fundsWithAll.unshift({
            fund_id: 'all',
            fund_name: 'All Fund'
          });

          // console.log("this.funds", this.funds)
          // console.log("this.fundsWithAll", this.fundsWithAll)
          this.selectedFund = this.funds[0].fund_id;
          this.selectedFundWithAll = this.fundsWithAll[0].fund_id;
          this.$nextTick(() => {
            console.log("refresh")
            $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
          });
          this.getInvestorsByFund();
          this.fetchNAVNotesMaturityData();
          this.fetchInvestorByInvestmentFundCurrencyCountryData();
          // if(this.funds.length > 0){
          //   this.selectedFund = this.funds[0].fund_id
          // }else {
            //   this.selectedFund = null;
            // }
          })
          .catch((error) => {
          Utils.stop_loading();
          console.error(error);
        });
    },

  },
  created() {
    this.getFunds();
  }
};
</script>


<style scoped>
.required-field::after {
  content: "*";
  color: red;
}

.error-msg {
  color: red;
  font-weight: bold;
}

.login_img {
  background-color: #d2d2d2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60%;
}

#side_view {
  height: 100%;
  padding: 0px;
}
</style>
