var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('nav-bar'),_c('aside-bar'),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid tabtop pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-6"},[_c('ul',{attrs:{"id":"bck_btn"}},[_c('li',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"icofont-caret-left text-dark"}),_c('span',[_vm._v("Back")])])])])])])])])])])]),_c('div',{staticClass:"container-fluid ot_tb pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_vm._m(1),_c('div',{staticClass:"card-body table-responsive p-0"},[_c('table',{staticClass:"table table-head-fixed text-nowrap agreementHistoryTable",attrs:{"id":"agreementHistoryTable"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Agreement Start Date")]),_c('th',[_vm._v("Agreement End Date")]),_c('th',[_vm._v("Fees")]),_c('th',[_vm._v("Currency")]),_c('th',[_vm._v("Uploaded File")]),(
                          (_vm.loggedInUserGroupId == 'Admin' ||
                                  (_vm.loggedInUserGroupId == 'Analyst' &&
                                    _vm.loggedInUserSubGroupId == 0)) &&
                          !_vm.inactive
                        )?_c('th'):_vm._e()])]),_c('tbody',_vm._l((_vm.AgreementDetails),function(agg){return _c('tr',{key:agg.id},[(agg.agreement_start_date !== null)?_c('td',[_vm._v(" "+_vm._s(agg.agreement_start_date)+" ")]):_c('td',[_vm._v("-")]),(agg.agreement_end_date !== null)?_c('td',{staticClass:"c"},[_vm._v(" "+_vm._s(agg.agreement_end_date)+" ")]):_c('td',{staticClass:"c"},[_vm._v("-")]),(agg.fees !== null)?_c('td',[_vm._v(" "+_vm._s(agg.fees)+" ")]):_c('td',[_vm._v("-")]),(agg.currency_short_name !== null)?_c('td',[_vm._v(" "+_vm._s(agg.currency_short_name)+" ")]):_c('td',[_vm._v("-")]),(agg.document_path)?_c('td',{staticClass:"c",style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.downloadDocument(agg.document_path)}}},[_c('u',[_vm._v(_vm._s(agg.display_file_name))])]):_c('td',[_vm._v("-")]),(
                          (_vm.loggedInUserGroupId == 'Admin' ||
                                  (_vm.loggedInUserGroupId == 'Analyst' &&
                                    _vm.loggedInUserSubGroupId == 0))  &&
                          !_vm.inactive
                        )?_c('td',{style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.deleteCollateralAgreement(agg.id)}}},[_c('i',{staticClass:"icofont-trash text-danger"})]):_vm._e()])}),0)])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"headingt"},[_c('i',{staticClass:"icofont-file-document"}),_vm._v(" Agreement History ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"})])}]

export { render, staticRenderFns }