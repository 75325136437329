<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-file-text"></i> Summary
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover table-head-fixed text-nowrap">
                    <tbody>
                      <tr>
                        <td class="wg">Borrower</td>
                        <td v-if="borrowerName !== null">
                          {{ borrowerName }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                      <tr>
                        <td class="wg">Facility</td>
                        <td v-if="facilityRegistrationId">
                          {{ facilityRegistrationId }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card-body br_overview">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-hover">
                    <tbody>
                      <tr>
                        <td class="wg">Collateral</td>
                        <td v-if="collateral">
                          {{ collateral }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                      <!----------------------------------------------->
                      <tr>
                        <td class="wg">Warehouse</td>
                        <td v-if="warehouseId">
                          {{ warehouseId }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div
                    class="card-body table-responsive p-0"
                    style="padding: 15px 0px !important"
                  >
                    <!------menton here style="height: 300px;"-------->
                    <table
                      class="table table-bordered table-head-fixed text-nowrap table-striped myTable1"
                      id="myTable1"
                    >
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th class="center">Uncleaned</th>
                          <th class="center">Cleaning</th>
                          <th class="center">Cleaned</th>
                          <th class="center">Waste</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="odd">
                          <td>Total Quantity (MT)</td>
                          <td
                            class="center"
                            v-if="uncleanedSummary.quantity_sum"
                          >
                            {{ uncleanedSummary.quantity_sum }}
                          </td>
                          <td class="center" v-else>-</td>
                          <td
                            class="center"
                            v-if="cleaningProcessSummary.quantity_sum"
                          >
                            {{ cleaningProcessSummary.quantity_sum }}
                          </td>
                          <td class="center" v-else>-</td>
                          <td class="center" v-if="cleanedSummary.quantity_sum">
                            {{ cleanedSummary.quantity_sum }}
                          </td>
                          <td class="center" v-else>-</td>
                          <td class="center" v-if="wasteSummary.quantity_sum">
                            {{ wasteSummary.quantity_sum }}
                          </td>
                          <td class="center" v-else>-</td>
                        </tr>
                        <tr class="even">
                          <td>No. of Warrants</td>
                          <td
                            class="center"
                            v-if="
                              uncleanedSummary.warrant_count &&
                              uncleanedSummary.quantity_sum
                            "
                          >
                            {{ uncleanedSummary.warrant_count }}
                          </td>
                          <td class="center" v-else>-</td>
                          <td
                            class="center"
                            v-if="
                              cleaningProcessSummary.warrant_count &&
                              cleaningProcessSummary.quantity_sum
                            "
                          >
                            {{ cleaningProcessSummary.warrant_count }}
                          </td>
                          <td class="center" v-else>-</td>
                          <td
                            class="center"
                            v-if="
                              cleanedSummary.warrant_count &&
                              cleanedSummary.quantity_sum
                            "
                          >
                            {{ cleanedSummary.warrant_count }}
                          </td>
                          <td class="center" v-else>-</td>
                          <td
                            class="center"
                            v-if="
                              wasteSummary.warrant_count &&
                              wasteSummary.quantity_sum
                            "
                          >
                            {{ wasteSummary.warrant_count }}
                          </td>
                          <td class="center" v-else>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Upload Documents</h1>
                      </div>
                      <!-- /.col -->
                      <div
                        class="col-lg-6"
                        v-if="
                          loggedInUserGroupId === 'Admin' ||
                          loggedInUserGroupId === 'Collateral Manager'
                        "
                      >
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addSummaryDoc()"
                            >
                              <i class="icofont-plus"></i> Add Document
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(doc, index) in summaryDocuments"
                      :key="index"
                    >
                      <div class="col-1">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Download</label>
                          <div
                            v-if="doc.document_path !== null"
                            v-on:click="downloadDocument(doc.document_path)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i
                              id="buttons"
                              class="icofont-download text-info"
                            ></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Upload Document</label
                          >
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="customFile"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              v-on:change="
                                handleFileUploadSummaryDoc(index, $event)
                              "
                              :disabled="
                                loggedInUserGroupId === 'Analyst' ||
                                loggedInUserGroupId === 'Borrower'
                              "
                              :class="{
                                'is-invalid': doc.errors,
                              }"
                            />
                            <small>
                              <p style="background-color: powderblue">
                                <b>{{ doc.display_file_name }}</b>
                              </p>
                            </small>
                            <div v-if="doc.errors" class="invalid-feedback">
                              <span>{{ doc.errors }}</span>
                            </div>
                            <label class="custom-file-label" for="customFile"
                              >Choose file</label
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-1 col-sm-4 col-6"
                        v-if="loggedInUserGroupId === 'Admin'"
                      >
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            style="width: 250px"
                            v-on:click="deleteSummaryDoc(index, doc.id)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="submitterName" class="required-field"
                          >Name of Submitter</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="submitterName"
                          id="submitterName"
                          name="submitterName"
                          :disabled="
                            verificationSummaryExists ||
                            loggedInUserGroupId === 'Analyst' ||
                            loggedInUserGroupId === 'Borrower'
                          "
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.submitterName.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.submitterName.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.submitterName.required"
                            >Name of Submitter is required</span
                          >
                          <span v-if="!$v.submitterName.maxLength"
                            >Max length of 50 characters is exceeded</span
                          >
                          <span v-if="!$v.submitterName.isAlpha"
                            >Name of Submitter should be alphabets only</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="date" class="required-field">Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="date"
                          id="date"
                          placeholder="Select Date"
                          v-model="date"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          :disabled="
                            verificationSummaryExists ||
                            loggedInUserGroupId === 'Analyst' ||
                            loggedInUserGroupId === 'Borrower'
                          "
                          :disabledDates="{ from: new Date() }"
                          :class="{
                            'is-invalid': isSubmitted && $v.date.$error,
                          }"
                        ></datepicker>
                        <div
                          v-if="isSubmitted && $v.date.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.date.required">Date is required</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="comments">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="comments"
                          id="comments"
                          name="comments"
                          :disabled="
                            loggedInUserGroupId === 'Analyst' ||
                            loggedInUserGroupId === 'Borrower'
                          "
                          :class="{
                            'is-invalid': isSubmitted && $v.comments.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.comments.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.comments.maxLength"
                            >Max length of 100 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <ul id="sr_btn">
                  <li
                    v-if="
                      loggedInUserGroupId === 'Admin' ||
                      loggedInUserGroupId === 'Collateral Manager'
                    "
                  >
                    <button
                      type="button"
                      class="btn-sm btn btn-success sh"
                      v-on:click="onSubmit()"
                    >
                      Submit
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                      @click="$router.push('/collateral')"
                    >
                      Cancel
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { maxLength, required, helpers } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  data() {
    return {
      verification_id: null,
      verificationSummaryId: null,
      facilityId: null,
      facilityRegistrationId: null,
      borrowerId: null,
      borrowerName: null,
      warehouseId: null,
      collateralId: null,
      collateral: null,
      submitterName: null,
      date: null,
      comments: null,
      uncleanedSummary: [],
      cleaningProcessSummary: [],
      cleanedSummary: [],
      wasteSummary: [],
      summaryDocuments: [],
      summaryDocFile: null,
      verificationSummaryExists: false,
      isSubmitted: false,
    };
  },
  validations: {
    submitterName: {
      required,
      maxLength: maxLength(50),
      isAlpha: helpers.regex("isAlpha", /^[a-zA-Z ]*$/),
    },
    date: {
      required,
    },
    comments: {
      maxLength: maxLength(100),
    },
  },
  components: {
    Datepicker,
  },
  methods: {
    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.date !== null) {
        this.date = moment(this.date).format("MM-DD-YYYY");
      }
      let formData = new FormData();
      if (this.verificationSummaryExists) {
        formData.append("verificationSummaryID", this.verificationSummaryId);
      }
      formData.append("facilityID", this.facilityId);
      formData.append("verificationID", this.verification_id);
      formData.append("submitterName", this.submitterName);
      formData.append("date", this.date);
      formData.append("comments", this.comments);
      formData.append(
        "summaryDocuments",
        JSON.stringify(this.summaryDocuments)
      );
      formData.append("clientId", this.$store.state.client_id);
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 7);
      formData.append("tabId", 32);

      const path = this.verificationSummaryExists
        ? "edit-verification-summary/" + this.verification_id
        : "add-verification-summary";
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          if (response.data.status == "success") {
            Utils.make_alert(
              "success",
              "Verification Information saved successfully!"
            );
            console.log("SUCCESS!!");
            this.$router.push("/collateral");
          } else {
            Utils.make_alert("warning", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch(function (error) {
          Utils.stop_loading();
          Utils.make_alert(
            "warning",
            error.response.status === 400
              ? error.response.data.status
              : "Something went wrong!"
          );
          console.error(error);
        });
    },

    downloadDocument: function (filePath) {
      if (filePath == undefined) {
        Utils.make_alert("warning", "No Document Available");
        return;
      }
      Utils.downloadDocument(filePath);
    },

    //this method will add new line for additional documents
    addSummaryDoc() {
      this.summaryDocFile = null;
      this.summaryDocuments.push({
        id: null,
        display_file_name: null,
        errors: "",
      });
    }, //addSummaryDoc() close

    deleteSummaryDoc(index, id) {
      if (id) {
        this.$confirm({
          auth: false,
          message: "Are you sure you want to permanently delete?",
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              Utils.start_loading();
              axios
                .delete("verification-summary/" + this.verification_id, {
                  headers: {
                    "Content-type": "application/json",
                  },
                  data: {
                    deleteType: "Document",
                    id: id,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    moduleId: 7,
                    tabId: 32,
                  },
                })
                .then((response) => {
                  Utils.stop_loading();
                  console.log("delete status: " + response.data.status);
                  if (response.data.status == "success") {
                    Utils.make_alert("success", "Document deleted");
                    setTimeout(() => this.$router.go(), 1500);
                  } else {
                    Utils.make_alert("success", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  console.log("error: " + error);
                  Utils.make_alert("success", "Something went wrong!");
                });
            } else {
              this.$router.go(this.$router.currentRoute);
            }
          },
        });
      }
      this.summaryDocuments.splice(index, 1);
      this.summaryDocFile = "";
    },

    //handles a change on the file upload
    handleFileUploadSummaryDoc(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.summaryDocFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.summaryDocFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: null };
          response.errors = "File size should be less than 100MB";
          self.summaryDocuments[id].errors = JSON.stringify(
            response.errors
          ).replace(/\"/g, "");
          self.summaryDocuments[id].selectedFile = null;
          self.summaryDocuments[id].selectedFileName = null;
          self.summaryDocuments[id].display_file_name = null;
          return;
        }

        reader.readAsDataURL(this.summaryDocFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.summaryDocuments[id].selectedFile = reader.result;
          self.summaryDocuments[id].selectedFileName = e.target.files[0].name;
          self.summaryDocuments[id].display_file_name = e.target.files[0].name; //to show filename in file input
          self.summaryDocuments[id].errors = "";
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadSummaryDoc() close

    // this function is called to get request details with verification id
    getRequest() {
      const path = "single-request-summary-overview/" + this.verification_id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            let verification_request_details =
              res.data.verification_request_details.request_details[0];
            this.borrowerId = verification_request_details.borrower_id;
            this.borrowerName = verification_request_details.borrower_name;
            this.warehouseId =
              verification_request_details.warehouse_registration_id;
            this.facilityId = verification_request_details.facility_id;
            this.facilityRegistrationId =
              verification_request_details.facility_registration_id;
            this.collateralId =
              verification_request_details.collateral_master_id;
            this.collateral = verification_request_details.collateral_name;
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getRequest close

    // this function is called to get verification summary details with verification id
    getVerificationSummary() {
      const path = "verification-summary/" + this.verification_id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            let summary = res.data.verification_summary;
            if (summary) {
              summary.map((s) => {
                if (s.collateral_stage_master_id === 1)
                  this.uncleanedSummary = s;
                else if (s.collateral_stage_master_id === 2)
                  this.cleaningProcessSummary = s;
                else if (s.collateral_stage_master_id === 3)
                  this.cleanedSummary = s;
                else if (s.collateral_stage_master_id === 4)
                  this.wasteSummary = s;
              });
            }
            let verification_summary_details =
              res.data.verification_summary_overview[0];
            if (verification_summary_details) {
              this.verificationSummaryExists = true;
              this.verificationSummaryId = verification_summary_details.id;
              this.submitterName = verification_summary_details.submitter_name;
              this.date = verification_summary_details.date;
              this.comments = verification_summary_details.comments;
              this.summaryDocuments = res.data.verification_summary_docs;
            }
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getVerificationSummary close
  },

  mounted() {
    this.getVerificationSummary();
  },
  computed: {
    loggedInUserFullName() {
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
  created() {
    this.verification_id = this.$route.params.id;
    this.getRequest();
  },
};
</script>

<style scoped>
#myTable1 tr td,
#myTable1 tr th {
  border: solid 1px slategrey;
  border-collapse: collapse !important;
}
</style>
