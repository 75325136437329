var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('nav-bar'),_c('aside-bar'),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid tabtop pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-6"},[_c('ul',{attrs:{"id":"bck_btn"}},[_c('li',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"icofont-caret-left text-dark"}),_c('span',[_vm._v("Back")])])])])])])])])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Request ID")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.requestId),expression:"requestId"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.requestId.$error,
                    },attrs:{"id":"requestId","name":"requestId","maxlength":"20","disabled":""},domProps:{"value":(_vm.requestId)},on:{"keypress":function($event){return _vm.isAlphaNum($event)},"input":function($event){if($event.target.composing){ return; }_vm.requestId=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.requestId.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.requestId.required)?_c('span',[_vm._v("Request ID is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Utilisation ID")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.utilId),expression:"utilId"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.utilId.$error,
                    },attrs:{"id":"utilId","name":"utilId","maxlength":"20","disabled":""},domProps:{"value":(_vm.utilId)},on:{"keypress":function($event){return _vm.isAlphaNum($event)},"input":function($event){if($event.target.composing){ return; }_vm.utilId=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.utilId.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.utilId.required)?_c('span',[_vm._v("Utilisation ID is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Borrower Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.borrowerName),expression:"borrowerName"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.borrowerName.$error,
                    },attrs:{"id":"borrowerName","name":"borrowerName","disabled":""},domProps:{"value":(_vm.borrowerName)},on:{"keypress":function($event){return _vm.isAlphaNum($event)},"input":function($event){if($event.target.composing){ return; }_vm.borrowerName=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.borrowerName.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.borrowerName.required)?_c('span',[_vm._v("Borrower Name is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Date")]),_c('datepicker',{staticClass:"datepicker",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.date.$error,
                    },attrs:{"bootstrap-styling":true,"name":"date","id":"date","placeholder":"Select Date","format":_vm.$store.state.date_format,"calendar-button-icon":"fa fa-calendar","calendar-button":true,"disabledDates":{
                      from: new Date(),
                      to: new Date(_vm.requestDate),
                    }},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),(_vm.isSubmitted && _vm.$v.date.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.date.required)?_c('span',[_vm._v("Date is required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Collateral Name")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCollateral),expression:"selectedCollateral"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.isSubmitted && _vm.$v.selectedCollateral.$error,
                    },attrs:{"id":"selectedCollateral","name":"selectedCollateral","disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedCollateral=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("-- Select --")]),_vm._l((_vm.collaterals),function(col){return _c('option',{domProps:{"value":col.id}},[_vm._v(" "+_vm._s(col.collateral_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedCollateral.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedCollateral.required)?_c('span',[_vm._v("Collateral Name is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Collateral Qty")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collateralQty),expression:"collateralQty"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.collateralQty.$error,
                    },attrs:{"type":"number","min":"0","id":"collateralQty","name":"collateralQty","disabled":_vm.status === 'Fully Taken for Cleaning'},domProps:{"value":(_vm.collateralQty)},on:{"keypress":function($event){return _vm.isDecimal($event)},"input":function($event){if($event.target.composing){ return; }_vm.collateralQty=$event.target.value}}}),(
                      (_vm.isSubmitted && _vm.$v.collateralQty.$error) ||
                      _vm.collateralQty > _vm.collateralQtyLimit
                    )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.collateralQty.required)?_c('span',[_vm._v("Collateral Qty is required")]):_vm._e(),(!_vm.$v.collateralQty.maxLength)?_c('span',[_vm._v("Max length of 15 characters exceeded"),_c('br')]):_vm._e(),(!_vm.$v.collateralQty.collateral_qty_limit)?_c('span',[_vm._v(" Collateral Qty cannot exceed the limit of "+_vm._s(_vm.collateralQtyLimit)+"! ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Gross Weight")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.grossWeight),expression:"grossWeight"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.grossWeight.$error,
                    },attrs:{"type":"number","min":"0","id":"grossWeight","name":"grossWeight","disabled":_vm.status === 'Fully Taken for Cleaning'},domProps:{"value":(_vm.grossWeight)},on:{"keypress":function($event){return _vm.isDecimal($event)},"input":function($event){if($event.target.composing){ return; }_vm.grossWeight=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.grossWeight.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.grossWeight.required)?_c('span',[_vm._v("Gross Weight is required")]):_vm._e(),(!_vm.$v.grossWeight.maxLength)?_c('span',[_vm._v("Max length of 15 characters exceeded"),_c('br')]):_vm._e(),(!_vm.$v.grossWeight.gross_limit)?_c('span',[_vm._v(" Gross Weight cannot exceed the limit of "+_vm._s(_vm.grossLimit)+"! ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Unit")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedUnit),expression:"selectedUnit"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.selectedUnit.$error,
                    },attrs:{"id":"selectedUnit","name":"selectedUnit","disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedUnit=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("-- Select --")]),_vm._l((_vm.UnitTypes),function(unit){return _c('option',{domProps:{"value":unit.id}},[_vm._v(" "+_vm._s(unit.name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedUnit.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedUnit.required)?_c('span',[_vm._v("Unit is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("No. of Bags")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bags),expression:"bags"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.bags.$error,
                    },attrs:{"id":"bags","name":"bags","disabled":_vm.status === 'Fully Taken for Cleaning'},domProps:{"value":(_vm.bags)},on:{"keypress":function($event){return _vm.isNum($event)},"input":function($event){if($event.target.composing){ return; }_vm.bags=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.bags.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.bags.required)?_c('span',[_vm._v("No. of Bags is required")]):_vm._e(),(!_vm.$v.bags.maxLength)?_c('span',[_vm._v("Max length of 15 characters exceeded"),_c('br')]):_vm._e(),(!_vm.$v.bags.bag_limit)?_c('span',[_vm._v(" No. of Bags cannot exceed the limit of "+_vm._s(_vm.bagLimit)+"! ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("GRN Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.grnNo),expression:"grnNo"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.grnNo.$error,
                    },attrs:{"id":"grnNo","name":"grnNo"},domProps:{"value":(_vm.grnNo)},on:{"keypress":function($event){return _vm.isNum($event)},"input":function($event){if($event.target.composing){ return; }_vm.grnNo=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.grnNo.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.grnNo.required)?_c('span',[_vm._v("GRN Number is required")]):_vm._e(),(!_vm.$v.grnNo.maxLength)?_c('span',[_vm._v("Max length of 15 characters exceeded")]):_vm._e()]):_vm._e()])])])])])]),_c('div',{staticClass:"container-fluid ot_tb pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-lg-6"},[_c('ul',{attrs:{"id":"sr_btn"}},[_c('li',[_c('button',{staticClass:"btn btn-sm sh_n btn-dark",attrs:{"type":"button","disabled":_vm.CleaningProcessDocumentsAddMoreDisabled},on:{"click":function($event){return _vm.addCleaningProcessDoc()}}},[_c('i',{staticClass:"icofont-plus"}),_vm._v(" Add More ")])])])])])]),_c('div',{staticClass:"card-body p-0"},[_vm._l((_vm.CleaningProcessDocuments),function(doc,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v("Upload Document")]),_c('div',{staticClass:"custom-file"},[_c('input',{ref:"file",refInFor:true,staticClass:"custom-file-input",attrs:{"type":"file","id":"customFile","name":"file","accept":".jpg,.jpeg,.png,.pdf"},on:{"change":function($event){return _vm.handleFileUploadCleaningProcessDocuments(
                              index,
                              $event
                            )}}}),_c('small',[_c('p',{staticStyle:{"background-color":"powderblue"}},[_c('b',[_vm._v(_vm._s(doc.displayFileName))]),_c('small',[(
                                  doc.CleaningProcessDocumentsRemoveDisabled ===
                                  false
                                )?_c('u',{staticStyle:{"color":"blue","cursor":"pointer"},on:{"click":function($event){return _vm.removeCleaningProcessDocumentsDoc(index)}}},[_vm._v("Remove")]):_vm._e()])])]),_c('small',[(doc.errors.filesize)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(doc.errors.filesize)+" ")]):_vm._e()]),_c('label',{staticClass:"custom-file-label",attrs:{"for":"customFile"}},[_vm._v("Choose file")])])])]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v("Uploading Date")]),_c('datepicker',{staticClass:"datepicker",attrs:{"bootstrap-styling":true,"name":"UploadingDate","id":"UploadingDate","placeholder":"Select Date","format":_vm.$store.state.date_format,"calendar-button-icon":"fa fa-calendar","disabledDates":{ from: new Date() },"calendar-button":true},model:{value:(doc.UploadingDate),callback:function ($$v) {_vm.$set(doc, "UploadingDate", $$v)},expression:"doc.UploadingDate"}})],1)]),_c('div',{staticClass:"col-md-2 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc",on:{"click":function($event){return _vm.receiptValidation(doc.displayFileName)}}},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v("Receipt No.")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(doc.receipt_number),expression:"doc.receipt_number"}],staticClass:"form-control",class:{
                          'is-invalid':
                            (_vm.receiptValidate &&
                              doc.displayFileName === null) ||
                            (_vm.CleaningProcessDocuments[index].receipt_number
                              ? _vm.CleaningProcessDocuments[index].receipt_number
                                  .length > 20
                              : false),
                        },attrs:{"id":"receipt_number","name":"receipt_number","disabled":doc.displayFileName === null},domProps:{"value":(doc.receipt_number)},on:{"keypress":function($event){return _vm.isAlphaNum($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(doc, "receipt_number", $event.target.value)}}}),(doc.displayFileName === null)?_c('div',{staticClass:"invalid-feedback"},[(_vm.receiptValidate)?_c('span',[_vm._v("Please upload the Document")]):_vm._e()]):_vm._e(),(
                          _vm.CleaningProcessDocuments[index].receipt_number
                            ? _vm.CleaningProcessDocuments[index].receipt_number
                                .length > 20
                            : false
                        )?_c('div',{staticClass:"invalid-feedback"},[(
                            _vm.CleaningProcessDocuments[index].receipt_number
                              ? _vm.CleaningProcessDocuments[index].receipt_number
                                  .length > 20
                              : false
                          )?_c('span',[_vm._v("Max length of 20 characters exceeded")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-1 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticStyle:{"visibility":"hidden"},attrs:{"for":"exampleInputEmail1"}},[_vm._v("Delete")]),_c('div',{staticStyle:{"width":"250px"},style:({ cursor: 'pointer' }),attrs:{"id":"docDelete"},on:{"click":function($event){return _vm.deleteCleaningProcessDocuments(index)}}},[_c('i',{staticClass:"icofont-trash text-danger"})])])])])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right mb-3"},[_c('button',{staticClass:"btn-sm btn btn-primary sh",attrs:{"type":"button","disabled":_vm.isDisabledCleaningProcessDocumentSaveInfo},on:{"click":function($event){return _vm.onCleaningProcessDocumentSaveInfo()}}},[_vm._v(" Save Info ")])])])],2)])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right mb-3"},[_c('ul',{attrs:{"id":"sr_btn"}},[_c('li',[_c('button',{staticClass:"btn-sm btn btn-success sh",attrs:{"type":"button","id":"submit"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" Submit ")])]),_c('li',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('button',{staticClass:"btn-sm btn btn-dark sh",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Cancel ")])])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"headingt"},[_c('i',{staticClass:"icofont-plus"}),_vm._v(" Add Cleaning Process ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"headingt"},[_vm._v("Documents")])])}]

export { render, staticRenderFns }