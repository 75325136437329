<!-- This is add Loan page. -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-plus"></i> Add Payment
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Basic Details</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedBorrower" class="required-field"
                        >Borrower Name</label
                      >
                      <select
                        id="selectedBorrower"
                        class="form-control"
                        v-model="selectedBorrower"
                        @change="getAllFacilitySetupByBorrower()"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedBorrower.$error,
                        }"
                        :disabled="loggedInUserGroupId == 'Borrower'"
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="borrower in borrowers"
                          :value="borrower.borrower_id"
                          :key="borrower.borrower_id"
                        >
                          {{ borrower.borrower_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedBorrower.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedBorrower.required"
                          >Borrower Name is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedFacility" class="required-field"
                        >Facility ID</label
                      >
                      <select
                        id="selectedFacility"
                        class="form-control"
                        v-model="selectedFacility"
                        @change="getFundCurrencyByFacility()"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedFacility.$error,
                        }"
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="fac in facilities"
                          :value="fac.id"
                          :key="fac.id"
                        >
                          {{ fac.facility_registration_id }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedFacility.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedFacility.required"
                          >Facility ID is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedCost" class="required-field"
                        >Cost Name</label
                      >
                      <select
                        id="selectedCost"
                        class="form-control"
                        v-model="selectedCost"
                        @change="()=>{this.selectedCostID = null}"
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedCost.$error,
                        }"
                      >
                        <option :value="null">-- Select a Cost --</option>
                        <option
                          v-for="cost in loanExpenses"
                          :value="cost.id"
                          :key="cost.id"
                        >
                          {{ cost.loan_expense_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedCost.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedCost.required"
                          >Cost Name is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedCostID"
                        >Cost ID</label
                      >
                      <select
                        id="selectedCostID"
                        class="form-control"
                        v-model="selectedCostID"
                        @change="getAmountCurrency()"
                      >

                        <option :value="null">-- Select a Cost ID --</option>
                        <option
                          v-for="cost in CostIds"
                          :value="cost.id"
                          :key="cost.id"
                        >
                          {{ cost.id }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="paidAmount" class="required-field">Paid Amount</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="paidAmount"
                        :class="{
                          'is-invalid': isSubmitted && $v.paidAmount.$error,
                        }"
                        id="paidAmount"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                        :disabled="disabledAmountCurrency"
                      />
                      <div
                        v-if="isSubmitted && $v.paidAmount.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.paidAmount.required"
                          >Amount is required<br
                        /></span>
                        <span v-if="!$v.paidAmount.maxLength"
                          >Max length of 15 digits exceeded</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Currency</label>
                      <select
                        id="selectedCurrency"
                        class="form-control"
                        v-model="selectedCurrency"
                        :class="{
                          'is-invalid':
                            isSubmitted && $v.selectedCurrency.$error,
                        }"
                        :disabled="disabledAmountCurrency"
                      >
                        <option :value="null">-- Select a Currency --</option>
                        <option
                          v-for="curr in currencies"
                          :value="curr.id"
                          :key="curr.id"
                        >
                          {{ curr.currency_short_name }}
                        </option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedCurrency.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedCurrency.required"
                          >Currency is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="date" class="required-field">Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        placeholder="Select Date"
                        v-model="date"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{ to: new Date(backCapDate), from: new Date() }"
                        :class="{
                          'is-invalid': isSubmitted && $v.date.$error,
                        }"
                      ></datepicker>
                      <div
                        v-if="isSubmitted && $v.date.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.date.required">Date is required</span>
                      </div>
                    </div>
                  </div>

                  <div v-if="this.currencyConversionFlag && !this.disabledAmountCurrency" class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="currencyConversionDate">Conversion Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        placeholder="Select Date"
                        @input="getFundCurrencyExchangeRateByCurrencyDate()"
                        id="currencyConversionDate"
                        v-model="currencyConversionDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{ from: new Date(futureCapDate) }"
                      ></datepicker>
                    </div>
                  </div>

                  <div v-if="this.currencyConversionFlag && !this.disabledAmountCurrency" class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="fundCurrencyExchangeRate">Currency Exchange Rate</label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="fundCurrencyExchangeRate"
                        id="fundCurrencyExchangeRate"
                        @keypress="isDecimal($event)"
                        disabled
                      />
                    </div>
                  </div>

                  <div v-if="this.currencyConversionFlag && !this.disabledAmountCurrency" class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="fundConvertedAmount">Converted Amount</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="fundConvertedAmount"
                        id="fundConvertedAmount"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                        disabled
                      />
                    </div>
                  </div>

                  <div v-if="this.currencyConversionFlag" class="col-md-8 col-sm-5 col-6">
                    <span style="font-size: 12px; color: red">Note: Paid Amount has been converted into Fund Currency {{ getCurrencyShortNameById(this.fundCurrency)}}. </span>
                  </div>  

                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Banking Details</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedBank">Beneficiary Bank</label>
                      <select
                        id="selectedBank"
                        class="form-control"
                        v-model="selectedBank"
                      >
                        <option :value="null">-- Select a Bank --</option>
                        <option
                          v-for="bank in banks"
                          :value="bank.id"
                          :key="bank.id"
                        >
                          {{ bank.bank_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="bic">Bank Identifier Code</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="bic"
                        id="bic"
                        :class="{
                          'is-invalid': isSubmitted && $v.bic.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.bic.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.bic.required"
                          >Bank Identifier Code cannot be 0</span
                        >
                        <span v-if="!$v.bic.minLength && $v.bic.required"
                          >Min length of 8 characters required<br
                        /></span>
                        <span v-if="!$v.bic.maxLength"
                          >Max length of 11 characters exceeded<br
                        /></span>
                        <span v-if="!$v.bic.alphaNum"
                          >Bank Identifier Code should be alphanumeric
                          only</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="iban">International Bank A/c No.</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="iban"
                        id="iban"
                        :class="{
                          'is-invalid': isSubmitted && $v.iban.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.iban.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.iban.required"
                          >IBAN cannot be 0</span
                        >
                        <span v-if="!$v.iban.maxLength"
                          >Max length of 35 characters exceeded<br
                        /></span>
                        <span v-if="!$v.iban.alphaNum"
                          >IBAN should be alphanumeric only</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="accountNumber">Account No.</label>
                      <input
                        type="text"
                        id="accountNumber"
                        class="form-control"
                        v-model="accountNumber"
                        :class="{
                          'is-invalid': isSubmitted && $v.accountNumber.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.accountNumber.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.accountNumber.required"
                          >Account No. cannot be 0</span
                        >
                        <span v-if="!$v.accountNumber.maxLength"
                          >Max length of 17 characters exceeded<br
                        /></span>
                        <span v-if="!$v.accountNumber.numeric"
                          >Account No. should be numeric only</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="accountName">Account Name </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="accountName"
                        id="accountName"
                        :class="{
                          'is-invalid': isSubmitted && $v.accountName.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.accountName.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.accountName.required"
                          >Account Name cannot be 0</span
                        >
                        <span v-if="!$v.accountName.maxLength"
                          >Max length of 100 characters exceeded<br
                        /></span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="reference">Reference </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="reference"
                        id="reference"
                        :class="{
                          'is-invalid': isSubmitted && $v.reference.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.reference.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.reference.required"
                          >Reference cannot be 0</span
                        >
                        <span v-if="!$v.reference.maxLength"
                          >Max length of 100 characters exceeded<br
                        /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documents</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addCostDoc()"
                              :disabled="CostDocumentsAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(doc, index) in CostDocuments"
                      :key="index"
                    >
                      <div class="col-md-4 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="customFile">Upload Document</label>
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="customFile"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              v-on:change="
                                handleFileUploadCostDocuments(index, $event)
                              "
                              :class="{
                                'is-invalid': doc.errors.filesize,
                              }"
                            />
                            <small>
                              <p style="background-color: powderblue">
                                <b>{{ doc.displayFileName }}</b>
                                <small>
                                  <u
                                    style="color: blue; cursor: pointer"
                                    v-if="
                                      doc.CostDocumentsRemoveDisabled === false
                                    "
                                    v-on:click="removeCostDocuments(index)"
                                    >Remove</u
                                  >
                                </small>
                              </p>
                            </small>
                            <div
                              v-if="doc.errors.filesize"
                              class="invalid-feedback"
                            >
                              <span>{{ doc.errors.filesize }}</span>
                            </div>
                            <label class="custom-file-label" for="customFile"
                              >Choose file</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="comments">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="doc.comments"
                          id="comments"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.CostDocuments.$each[index].comments
                                .$error,
                          }"
                          :disabled="doc.displayFileName == null"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.CostDocuments.$each[index].comments.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.CostDocuments.$each[index].comments
                                .maxLength
                            "
                            >Max length of 100 characters exceeded.</span
                          >
                        </div>
                      </div>
                    </div>

                      <div class="col-md-1 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            v-on:click="deleteCostDocuments(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onCostDocumentSaveInfo()"
                          :disabled="isDisabledCostDocumentSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-success sh"
                      v-on:click="onSubmit()"
                    >
                      Submit
                    </button>
                  </li>
                  <li>
                    <a @click="$router.go(-1)">
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>
    <!-- ./wrapper -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {
  required,
  maxLength,
  minLength,
  numeric,
  alphaNum,
  decimal,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      borrowers: [],
      facilities: [],
      loanExpenses: [],
      currencies: [],
      banks: [],
      loans: [],
      selectedBorrower: null,
      selectedFacility: null,
      selectedCost: null,
      paidAmount: null,
      selectedCurrency: null,
      date: null,
      selectedBank: null,
      bic: null,
      iban: null,
      accountNumber: null,
      accountName: null,
      reference: null,
      backCapDate:new Date(moment(new Date()).subtract(10, "year")),

      // cost id related fields
      CostIds : [],
      selectedCostID: null, 
      disabledAmountCurrency: false,

      // currency conversion related fields
      currencyConversionFlag:false,
      currencyConversionDate:new Date(),
      fundCurrency:null,
      fundCurrencyExchangeRate:1,
      fundConvertedAmount:null,
      futureCapDate:null,

      //list for Loan Documents
      CostDocuments: [],
      CostDocumentFile: null,
      // CostDocumentsButtonDisabled: true,
      CostDocumentsDisabled: true,
      CostDocumentSaveInfoDisabled: true, //save info button is disabled
      CostDocumentsAddMoreDisabled: true,

      isSubmitted: false,
    };
  },

  validations: {
    selectedBorrower: {
      required,
    },
    selectedFacility: {
      required,
    },
    selectedCost: {
      required,
    },
    paidAmount: {
      required: function () {
        if (!this.paidAmount || this.paidAmount == 0) return false;
        else return true;
      },
      maxLength: maxLength(15),
    },
    selectedCurrency: {
      required,
    },
    date: {
      required,
    },
    bic: {
      alphaNum,
      required: function () {
        if (this.bic === "0") return false;
        else return true;
      },
      minLength: minLength(8),
      maxLength: maxLength(11),
    },
    iban: {
      required: function () {
        if (this.iban === "0") return false;
        else return true;
      },
      alphaNum,
      maxLength: maxLength(35),
    },
    accountNumber: {
      required: function () {
        if (this.accountNumber === "0") return false;
        else return true;
      },
      numeric,
      maxLength: maxLength(17),
    },
    accountName: {
      required: function () {
        if (this.accountName === "0") return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    reference: {
      required: function () {
        if (this.reference === "0") return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    CostDocuments: {
      $each: {
        comments: {
          maxLength: maxLength(100),
        },
      },
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
    //this is to computed disabled property of CostDocuments Save Info button.
    isDisabledCostDocumentSaveInfo: function () {
      let checkEmptyCostDocumentsDocName = this.CostDocuments.filter(
        (line) => line.displayFileName === null
      );
      //if the line is empty then disable save info button
      if (
        this.CostDocuments.length === 0 &&
        this.CostDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyCostDocumentsDocName.length >= 1 &&
        this.CostDocuments.length > 0
      ) {
        this.CostDocumentsDisabled = true;
      } else {
        this.CostDocumentsDisabled = false;
      }
      //CostDocumentsDisabled variable is to check whther all input fields except file upload are filled. CostDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.CostDocumentsDisabled === true ||
        this.CostDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledCostDocumentSaveInfo() close
  }, //main computed close

  components: {
    Datepicker,
  },
  methods: {
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },

    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },

    getCurrencyShortNameById(id) {
      if (id === null) {
        return '';
      }
      const currency = this.currencies.find((currency) => currency.id === id);
      return currency ? currency.currency_short_name : '';
    },

    //this method will make disabled property as false for Director Save Info
    onCostDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.CostDocumentSaveInfoDisabled = false;
      this.CostDocumentsAddMoreDisabled = false;
    },

    removeCostDocuments(id) {
      this.CostDocumentFile = null;
      this.CostDocuments[id].selectedFile = null;
      this.CostDocuments[id].selectedFileName = null;
      this.CostDocuments[id].displayFileName = null;
      this.CostDocuments[id].comments = null;
      this.CostDocuments[id].CostDocumentsRemoveDisabled = true;
    },


    deleteCostDocuments(index) {
      this.CostDocumentFile = this.CostDocumentFile
        ? this.CostDocumentFile
        : "file deleted";
      this.CostDocuments.splice(index, 1);
      if (
        typeof this.CostDocuments !== "undefined" &&
        this.CostDocuments.length == 0
      ) {
        this.CostDocumentsAddMoreDisabled = false;
        this.CostDocumentSaveInfoDisabled = true;
      }
    },

    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      let ExpenseDate = null;
      let ConversionDate = null;
      if (this.date != null) {
        ExpenseDate = moment(this.date).format("MM-DD-YYYY");
      }
      if (this.currencyConversionDate != null) {
        ConversionDate = moment(this.currencyConversionDate).format("MM-DD-YYYY");
      }

      this.CostDocuments.map((doc, index) => {
        if (doc.displayFileName === null) this.CostDocuments.splice(index, 1);
      });
      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("selectedBorrower", this.selectedBorrower);
      formData.append("selectedFacility", this.selectedFacility);
      formData.append("selectedCost", this.selectedCost);
      formData.append("selectedCostID", this.selectedCostID);
      formData.append("amount_paid", this.paidAmount);
      formData.append("selectedCurrency", this.selectedCurrency);
      formData.append("date", ExpenseDate);
      formData.append("currencyConversionDate", ConversionDate);
      formData.append("fundCurrencyExchangeRate", this.fundCurrencyExchangeRate);
      formData.append("fundConvertedAmount", this.fundConvertedAmount);
      formData.append("selectedBank", this.selectedBank);
      formData.append("bic", this.bic);
      formData.append("iban", this.iban);
      formData.append("accountNumber", this.accountNumber);
      formData.append("accountName", this.accountName);
      formData.append("reference", this.reference);
      //append CostDocuments information to formdata
      formData.append("CostDocuments", JSON.stringify(this.CostDocuments));
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 4);
      formData.append("tabId", 36);
      console.log(formData);
      const path = "/add-payment"
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          if (response.data.status == "success") {
            Utils.make_alert(
              "success",
              "Cost Payment Details saved successfully!"
            );
            console.log("SUCCESS!!");
            this.$router.push("/facility");
          } else {
            Utils.make_alert("success", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.log(error);
          Utils.make_alert("success", "Something went wrong!");
        });
    }, //onSubmit() close

    //this method will add new line for additional Cost Documents
    addCostDoc() {
      //when new line is added then CostDocumentFile variable and CostDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.CostDocumentFile = null),
        (this.CostDocumentSaveInfoDisabled = true),
        (this.CostDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.CostDocuments.push({
          displayFileName: null,
          comments: null,
          errors: { filesize: "" },
          CostDocumentsRemoveDisabled:true,
        });
    }, //addCostDoc() close

    //handles a change on the file upload
    handleFileUploadCostDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.CostDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.CostDocumentFile.size > 104857600) {
          e.preventDefault();
          self.CostDocuments[id].errors.filesize =
            "File size should be less than 100MB";
          self.CostDocuments[id].selectedFile = null;
          self.CostDocuments[id].selectedFileName = null;
          self.CostDocuments[id].displayFileName = null;
          self.CostDocuments[id].comments = null;
          self.CostDocuments[id].CostDocumentsRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.CostDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.CostDocuments[id].selectedFile = reader.result;
          self.CostDocuments[id].selectedFileName = e.target.files[0].name;
          self.CostDocuments[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.CostDocuments[id].errors.filesize = "";
          self.CostDocuments[id].CostDocumentsRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadCostDocuments() close

    getAllFacilitySetupByBorrower() {
      //this function is called to get list of day count
      const path = "/all-facility-setup-borrower/" + this.selectedBorrower;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // console.log(res.data.Facility_Setup);
          this.facilities = res.data.Facility_Setup;
          this.loanMappingAddMoreDisabled = false;
          this.currencyConversionFlag = false;
          this.selectedFacility = null;
          this.selectedCost = null;
          this.selectedCostID = null;
          this.date = null;
          this.paidAmount = null;
          this.selectedCurrency = null;
          this.fundCurrencyExchangeRate = 1;
          this.currencyConversionDate = new Date();
          this.fundConvertedAmount = null;
          this.fundCurrency = null;
          this.backCapDate = new Date(moment(new Date()).subtract(10, "year"));
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getAmountCurrency() {
      //this function is called to get list of day count
      console.log("get Amount Currency");
      console.log(this.selectedCostID)
      if(this.selectedCostID == null){
        console.log("------------if");
        this.disabledAmountCurrency = false;
        this.paidAmount = null
        this.selectedCurrency = this.fundCurrency;
        return
      }
      else{
        console.log("------------else");
        this.disabledAmountCurrency = true;
        let cost = this.CostIds.filter(cost => cost.id == this.selectedCostID)
        this.paidAmount = cost[0].outstanding_amount;
        this.selectedCurrency = cost[0].fund_currency_id;
        this.fundCurrency = cost[0].fund_currency_id;
        this.fundCurrencyExchangeRate = 1;
        this.currencyConversionDate = new Date();
        this.fundConvertedAmount = null;
        this.currencyConversionFlag = false;
      }
    },

    getFundCurrencyByFacility() {
      //this function is called to get fund currency of that facility
      console.log("inside getFundCurrencyByFacility")
      this.fundCurrencyExchangeRate = 1;
      this.currencyConversionDate = new Date();
      this.fundConvertedAmount = null;
      this.fundCurrency = null;
      this.selectedCurrency = null;
      this.paidAmount = null;
      this.selectedCostID = null;
      this.currencyConversionFlag = false;
      const path = "/currency-id/" + this.selectedFacility;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          console.log(res);
          this.selectedCurrency = res.data.currency[0].fund_currency_id;
          this.fundCurrency =  res.data.currency[0].fund_currency_id;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBackcappingCostPaymentDate() {
      //this function is called to get BackcapingDate for expense Date
      console.log("inside getBackcappingCostPaymentDate")

      const path = "/cost-payment-date-backcapping/" +
        this.selectedBorrower +
        "/" +
        this.selectedFacility +
        "/" +
        this.selectedCost +
        "/" +
        0;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          console.log(res.data)
          let backDate = new Date(res.data.payment_date);
          this.backCapDate = backDate;
          if (this.date !== null) {
          let dateObj = new Date(this.date);
          if (dateObj < backDate) {
            this.date = null;
          }
        }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    getFundCurrencyExchangeRateByCurrencyDate() {
      //this function is called to get fund currency of that facility
      console.log("inside getFundCurrencyExchangeRateByCurrencyDate")
      let currencyDate = null;
      if (this.currencyConversionDate != null) {
        currencyDate = moment(this.currencyConversionDate).format("MM-DD-YYYY");
      }
      const path = "/currency-exchange-rate/" +
        this.fundCurrency +
        "/" +
        this.selectedCurrency +
        "/" +
        currencyDate
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log(res.data)
          let data = res.data.currency_rate[0]
          if(data != null){
            this.currencyConversionFlag = true
            let currency_conversion_date = data.date;
            let conversionDate = null
            if (this.currencyConversionDate != null) {
              conversionDate = moment(this.currencyConversionDate).format("MM-DD-YYYY");
            }
            // if(conversionDate != currency_conversion_date){
            //   let message = "For the chosen conversion date, there is no currency conversion rate available.<br/> Hence, showing the earliest date for conversion that is available.";
            // Utils.make_alert("warning", message);
            // }
            this.fundCurrencyExchangeRate =  data.currency_value;
            this.currencyConversionDate =  data.date;
            this.futureCapDate = data.futureCappingDate;
          }else{
            this.currencyConversionFlag = false
            this.selectedCurrency = this.fundCurrency;
            this.fundCurrencyExchangeRate = 1;
            this.currencyConversionDate = new Date();
            this.fundConvertedAmount = null;
            this.futureCapDate = null;
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    getFundCurrencyExchangeRateByDate() {
      //this function is called to get fund currency of that facility
      console.log("inside getFundCurrencyExchangeRateByDate")
      let costDate = null;
      if (this.date != null) {
        costDate = moment(this.date).format("MM-DD-YYYY");
      }
      const path = "/currency-exchange-rate/" +
        this.fundCurrency +
        "/" +
        this.selectedCurrency +
        "/" +
        costDate
        Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log(res.data)
          let data = res.data.currency_rate[0]
          if(data == null){
            this.currencyConversionFlag = true
            let message = "The Currency conversion rate for the selected Payment Currency and Facility Currency is not available.<br/>So, the system auto selected the Payment Currrency as the Facility Currency to proceed.";
            Utils.make_alert("warning", message);
            this.selectedCurrency = this.fundCurrency;
            this.fundCurrencyExchangeRate = 1;
            this.currencyConversionDate = new Date();
            this.fundConvertedAmount = null;
            }else{
              this.currencyConversionFlag = true
              this.fundCurrencyExchangeRate =  data.currency_value;
              this.currencyConversionDate =  data.date;
              this.futureCapDate = data.futureCappingDate;
            }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    getCostId() {
      //this function is called to get list of cost id
      console.log(" inside cost info ")
      const path = 
      "cost-info/" +
      this.selectedBorrower +
      "/" +
      this.selectedFacility +
      "/" +
      this.selectedCost;
      Utils.start_loading();
      axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
      .then((res) => {
          Utils.stop_loading();
          this.disabledAmountCurrency = false;
          this.CostIds = res.data.cost_detail;
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
        });
    },

    getBorrowers() {
      const path = "borrower-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.borrowers = res.data.borrowers;
          if (this.loggedInUserGroupId === "Borrower") {
            this.borrowers.filter((bor) => {
              if (bor.borrower_id.toString() === this.loggedInUserSubGroupId)
                this.selectedBorrower = bor.borrower_id;
                this.getAllFacilitySetupByBorrower();
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLoanExpenses() {
      //this function is called to get list of loan expenses
      const path = "loan-expense-master";
      axios
        .get(path)
        .then((res) => {
          this.loanExpenses = res.data.loan_expenses;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBanks() {
      //this function is called to get list of banks
      const path = "bank-detail";
      axios
        .get(path)
        .then((res) => {
          console.log(res)
          console.log(res.data.bank_details)
          this.banks = res.data.bank_details;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close
  watch: {
  paidAmount: {
    handler(newAmount) {
      if (this.fundCurrency !== this.selectedCurrency  && this.fundCurrency !== null) {
        this.fundConvertedAmount = (Number(newAmount) * Number(this.fundCurrencyExchangeRate)).toFixed(2);
      }
    },
    immediate: true,
  },
  fundCurrencyExchangeRate: {
    handler(newExchangeRate) {
      if (this.fundCurrency !== this.selectedCurrency  && this.fundCurrency !== null) {
        this.fundConvertedAmount = (Number(newExchangeRate) * Number(this.paidAmount)).toFixed(2);
      }
    },
    immediate: true,
  },
  selectedBorrower: {
    handler(newExchangeRate) {
      if (this.selectedFacility !== null  &&  this.selectedCost !== null &&  this.selectedBorrower !== null) {
        this.getCostId();
        this.getBackcappingCostPaymentDate();
      }
    },
    immediate: true,
  },
  selectedCost: {
    handler(newExchangeRate) {
      if (this.selectedFacility !== null  &&  this.selectedCost !== null &&  this.selectedBorrower !== null) {
        this.getCostId();
        this.getBackcappingCostPaymentDate();
      }
    },
    immediate: true,
  },
  selectedFacility: {
    handler(newExchangeRate) {
      if (this.fundCurrency !== this.selectedCurrency && !this.disabledAmountCurrency && this.selectedFacility !== null  &&  this.fundCurrency !== null &&  this.selectedCurrency !== null && this.date !== null) {
        this.getFundCurrencyExchangeRateByDate();
      }
      if (this.selectedFacility !== null  &&  this.selectedCost !== null &&  this.selectedBorrower !== null) {
        this.getCostId();
        this.getBackcappingCostPaymentDate();
      }
    },
    immediate: true,
  },
  selectedCurrency: {
    handler(newExchangeRate) {
      if (this.fundCurrency !== this.selectedCurrency && !this.disabledAmountCurrency && this.selectedFacility !== null  &&  this.fundCurrency !== null &&  this.selectedCurrency !== null && this.date !== null) {
        this.getFundCurrencyExchangeRateByDate();
      }
      else{
        this.fundCurrencyExchangeRate = 1;
        this.currencyConversionDate = new Date();
        this.fundConvertedAmount = null;
        this.currencyConversionFlag = false;
      }
    },
    immediate: true,
  },
  date: {
    handler(newExchangeRate) {
      if (this.fundCurrency !== this.selectedCurrency && !this.disabledAmountCurrency && this.selectedFacility !== null  &&  this.fundCurrency !== null &&  this.selectedCurrency !== null && this.date !== null) {
        this.getFundCurrencyExchangeRateByDate();
      }
    },
    immediate: true,
  },
  },

  mounted() {
    this.addCostDoc();
  },

  created() {
    this.getCurrencies();
    this.getBorrowers();
    this.getLoanExpenses();
    this.getBanks();
  },
};
</script>

<style scoped>
.error {
  color: red;
  font-size: 13px;
  padding-left: 40px;
}
.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
/* .vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -75px;
} */

.vdp-datepicker >>> .form-control[disabled] {
  background-color:#EEEEEE !important;
  opacity: 0.7;
  pointer-events: none !important;
  cursor: not-allowed !important;
}.toggle-padding {
  margin-left: 5px;
}
.headingt #interestCalculationLabelYes {
  margin-left: 250px;
}
.headingt #interestCalculationLabelNo {
  margin-left: 20px;
}
.headingt #offtakerRepaymentLabelYes {
  margin-left: 95px;
}
.headingt #offtakerRepaymentLabelNo {
  margin-left: 20px;
}
</style>
