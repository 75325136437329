<!-- This is add Investor page. -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-plus"></i> Add Drawdown Request
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Borrower Name</label
                      >
                      <select
                        id="selectedBorrowerId"
                        class="form-control"
                        name="selectedBorrowerId"
                        v-model="selectedBorrowerId"
                        required
                        :disabled="true"
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="borrower in borrowers"
                          :value="borrower.borrower_id"
                          :key="borrower.id"
                        >
                          {{ borrower.borrower_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Facility ID</label
                      >
                      <select
                        id="facility_registration_id"
                        class="form-control"
                        name="facility_registration_id"
                        v-model="facility_registration_id"
                        required
                        disabled
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="fac in FacilitySetup"
                          :value="fac.id"
                          :key="fac.id"
                        >
                          {{ fac.facility_registration_id }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Drawdown Date</label
                      >
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="DrawdownDate"
                        placeholder="Select Date"
                        v-model="DrawdownDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{
                          to: prevDrawdownDate
                            ? new Date(prevDrawdownDate)
                            : new Date(approval_date),
                          from: new Date(),
                        }"
                        :class="{
                          'is-invalid': isSubmitted && $v.DrawdownDate.$error,
                        }"
                      ></datepicker>
                      <div
                        v-if="isSubmitted && $v.DrawdownDate.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.DrawdownDate.required"
                          >Drawdown Date is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field"
                        >Drawdown Amount</label
                      >
                      <input
                        type="number"
                        class="form-control"
                        placeholder
                        v-model="DrawdownAmount"
                        min="1"
                        @keypress="isDecimal($event)"
                        :class="{
                          'is-invalid': isSubmitted && $v.DrawdownAmount.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.DrawdownAmount.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.DrawdownAmount.required"
                          >Drawdown Amount is required</span
                        >
                        <span v-if="!$v.DrawdownAmount.maxLength"
                          >Max length of 15 characters exceeded</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Currency</label>
                      <select
                        id="selectedCurrencyDrawdown"
                        class="form-control"
                        name="selectedCurrencyDrawdown"
                        v-model="selectedCurrencyDrawdown"
                        :disabled="true"
                      >
                        <option :value="null">-- Select a Currency --</option>
                        <option
                          v-for="curr in currencies"
                          :value="curr.id"
                          :key="curr.id"
                        >
                          {{ curr.currency_short_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label>Status</label>
                    <select id="tokens" class="selectpicker form-control">
                      <option data-tokens="first">Option 1</option>
                      <option data-tokens="second">Option 2</option>
                      <option data-tokens="last">Option 3</option>
                    </select>
                  </div>
                  </div>-->
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documents</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addDrawdownDoc()"
                              :disabled="DrawdownDocumentsAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(doc, index) in DrawdownDocuments"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label>Select Document</label>
                          <select
                            id="DrawdownDocName"
                            class="form-control"
                            name="DrawdownDocName"
                            v-model="doc.DrawdownDocName"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="document in DrawdownDocs"
                              :value="document.id"
                              :key="document.id"
                            >
                              {{ document.document_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1"
                            >Upload Document</label
                          >
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="file"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              v-on:change="
                                handleFileUploadDrawdownDocuments(index, $event)
                              "
                            />
                            <small>
                              <p style="background-color: powderblue">
                                <b>{{ doc.displayFileName }}</b>
                                <small>
                                  <u
                                    style="color: blue; cursor: pointer"
                                    v-if="
                                      doc.DrawdownDocumentRemoveDisabled ===
                                      false
                                    "
                                    v-on:click="
                                      removeDrawdownDocumentsDoc(index)
                                    "
                                    >Remove</u
                                  >
                                </small>
                              </p>
                            </small>
                            <small>
                              <p class="error" v-if="doc.errors.filesize">
                                {{ doc.errors.filesize }}
                              </p>
                            </small>
                            <label class="custom-file-label" for="file"
                              >Choose File</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Uploading Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="DrawdownDocumentsDate"
                            placeholder="Select Date"
                            v-model="doc.DrawdownDocumentsDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{
                              to: new Date(approval_date),
                              from: new Date(),
                            }"
                          ></datepicker>
                        </div>
                      </div>

                      <!-- <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Expiry Date</label>
                          <datepicker class="datepicker"
                            :bootstrap-styling="true"
                            name="ExpiryDate"
                            placeholder="Select Date"
                            v-model="doc.ExpiryDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                          ></datepicker>
                        </div>
                      </div>-->

                      <!-- <div class="col-md-3 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label>Status</label>
                        <select id="tokens" class="selectpicker form-control">
                          <option data-tokens="first">Option 1</option>
                          <option data-tokens="second">Option 2</option>
                          <option data-tokens="last">Option 3</option>
                        </select>
                      </div>
                      </div>-->

                      <!-- <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="exampleInputEmail1">Comments</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Type..."
                            v-model="doc.comments"
                          />
                        </div>
                      </div>-->

                      <div class="col-md-1 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            style="width: 250px"
                            v-on:click="deleteDrawdownDocuments(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onDrawdownDocumentSaveInfo()"
                          :disabled="isDisabledDrawdownDocumentSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-success sh"
                      v-on:click="onSubmit()"
                      :disabled="isDisabledOnSubmit"
                    >
                      Submit
                    </button>
                  </li>
                  <li>
                    <a @click="$router.go(-1)">
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>
    <!-- ./wrapper -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      //master tables
      loan_registration_id: null,
      //variables for Drawdown information
      selectedBorrowerId: null,
      borrowers: [],
      facility_registration_id: null,
      utilisation_id: null,
      selectedCurrencyDrawdown: null,
      prevDrawdownDate: null,
      currencies: [],
      DrawdownDate: null,
      DrawdownDate1: null,
      DrawdownAmount: null,
      balanceAmount: null,
      FacilitySetup: [],
      approval_date: null,
      //list for Drawdown Documents
      DrawdownDocuments: [],
      DrawdownDocs: [],
      DrawdownDocumentFile: null,
      // DrawdownDocumentsButtonDisabled: true,
      DrawdownDocumentsDisabled: true,
      DrawdownDocumentSaveInfoDisabled: true, //save info button is disabled
      DrawdownDocumentsAddMoreDisabled: true,
      isSubmitted: false,
    };
  },
  validations: {
    DrawdownDate: {
      required,
    },
    DrawdownAmount: {
      required,
      maxLength: maxLength(15),
    },
  },
  computed: {
    //this is to computed disabled property of main Submit button to save information in database.
    isDisabledOnSubmit: function () {
      if (
        this.selectedBorrowerId == null
        // ||
        // this.DrawdownDocumentSaveInfoDisabled === true
      ) {
        //here we will check for all sections
        return true;
      }
    },

    //this is to computed disabled property of DrawdownDocuments Save Info button.
    isDisabledDrawdownDocumentSaveInfo: function () {
      let checkEmptyDrawdownDocumentsDocName = this.DrawdownDocuments.filter(
        (line) => line.DrawdownDocName === null
      );
      let checkEmptyDrawdownDocumentsDate = this.DrawdownDocuments.filter(
        (line) => line.DrawdownDocumentsDate === null
      );
      //if the line is empty then disable save info button
      if (
        this.DrawdownDocuments.length === 0 &&
        this.DrawdownDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyDrawdownDocumentsDocName.length >= 1 &&
          this.DrawdownDocuments.length > 0) ||
        (checkEmptyDrawdownDocumentsDate.length >= 1 &&
          this.DrawdownDocuments.length > 0)
      ) {
        this.DrawdownDocumentsDisabled = true;
      } else {
        this.DrawdownDocumentsDisabled = false;
      }
      //DrawdownDocumentsDisabled variable is to check whther all input fields except file upload are filled. DrawdownDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.DrawdownDocumentsDisabled === true ||
        this.DrawdownDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledDrawdownDocumentSaveInfo() close
  }, //main computed close

  components: {
    Datepicker,
  },
  methods: {
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },
    //this method will make disabled property as false for Director Save Info
    onDrawdownDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.DrawdownDocumentSaveInfoDisabled = false;
      this.DrawdownDocumentsAddMoreDisabled = false;
    },

    deleteDrawdownDocuments(index) {
      this.DrawdownDocumentFile = this.DrawdownDocumentFile
        ? this.DrawdownDocumentFile
        : "file deleted";
      this.DrawdownDocuments.splice(index, 1);
      if (
        typeof this.DrawdownDocuments !== "undefined" &&
        this.DrawdownDocuments.length == 0
      ) {
        this.DrawdownDocumentsAddMoreDisabled = false;
        this.DrawdownDocumentSaveInfoDisabled = true;
      }
    },

    //this method will submit the investor form in backend.
    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.balanceAmount) {
        // Show popup is drawdown amount is greater than balance amount
        if (Number(this.DrawdownAmount) > this.balanceAmount) {
          Utils.make_alert(
            "success",
            `Drawdown amount must not exceed ${this.balanceAmount
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}!` // comma seperator formatting
          );
          return;
        }
      }
      if (this.DrawdownDate != null) {
        this.DrawdownDate1 = moment(this.DrawdownDate).format("MM-DD-YYYY");
      }
      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("borrowerId", this.selectedBorrowerId);
      formData.append(
        "facility_registration_id",
        this.facility_registration_id
      );
      formData.append("DrawdownDate", this.DrawdownDate1);
      formData.append("loan_registration_id", this.loan_registration_id);
      formData.append(
        "selectedCurrencyDrawdown",
        this.selectedCurrencyDrawdown
      );
      formData.append("Drawdown_amount", this.DrawdownAmount);

      //append DrawdownDocuments information to formdata
      formData.append(
        "DrawdownDocuments",
        JSON.stringify(this.DrawdownDocuments)
      );
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 5);
      formData.append("tabId", 17);

      const path = "/add_drawdown"; // /" + this.loan_registration_id;
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          console.log("status: " + response.data.status);
          if (response.data.status == "success") {
            Utils.make_alert("success", "Drawdown Request added successfully!");
            console.log("SUCCESS!!");
            // this.$router.push({ name: "LoanOverview" });
            this.$router.go(-1);
          } else if (response.data.status == "drawdown_amount_is_more") {
            Utils.make_alert(
              "success",
              `Drawdown amount must not exceed ${response.data.data.amount}!`
            );
          } else {
            Utils.make_alert("success", "Something went wrong!");
            console.log("Something went wrong from server!");
            console.log("FAILURE!!");
          }
        })
        .catch(function () {
          Utils.stop_loading();
          console.log("FAILURE!!");
        });
    }, //onSubmit() close

    //this method will add new line for additional Drawdown Documents
    addDrawdownDoc() {
      //when new line is added then DrawdownDocumentFile variable and DrawdownDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.DrawdownDocumentFile = null),
        (this.DrawdownDocumentSaveInfoDisabled = true),
        (this.DrawdownDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.DrawdownDocuments.push({
          DrawdownDocName: null,
          DrawdownDocumentsDate: null,
          ExpiryDate: null,
          comments: null,
          displayFileName: null,
          errors: { filesize: "" },
          DrawdownDocumentRemoveDisabled: true,
        });
    }, //addDrawdownDoc() close

    //handles a change on the file upload
    handleFileUploadDrawdownDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.DrawdownDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.DrawdownDocumentFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors.filesize = "File size should be less than 100MB";
          self.DrawdownDocuments[id].errors.filesize = JSON.stringify(
            response.errors.filesize
          ).replace(/\"/g, "");
          self.DrawdownDocuments[id].selectedFile = null;
          self.DrawdownDocuments[id].selectedFileName = null;
          self.DrawdownDocuments[id].displayFileName = null;
          self.DrawdownDocuments[id].DrawdownDocumentRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.DrawdownDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.DrawdownDocuments[id].selectedFile = reader.result;
          self.DrawdownDocuments[id].selectedFileName = e.target.files[0].name;
          self.DrawdownDocuments[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.DrawdownDocuments[id].errors.filesize = "";
          self.DrawdownDocuments[id].DrawdownDocumentRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadDrawdownDocuments() close

    ////this method handles remove doc attachment
    removeDrawdownDocumentsDoc(id) {
      this.DrawdownDocumentFile = null;
      this.DrawdownDocuments[id].selectedFile = null;
      this.DrawdownDocuments[id].selectedFileName = null;
      this.DrawdownDocuments[id].displayFileName = null;
      this.DrawdownDocuments[id].DrawdownDocumentRemoveDisabled = true;
    },

    //this function is called to get list of documents master
    getDrawdownDocuments() {
      const path = "drawdown-document";
      axios
        .get(path)
        .then((res) => {
          this.DrawdownDocs = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getDrawdownDocuments() close

    getBorrowers() {
      const path = "borrower-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.borrowers = res.data.borrowers;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
          this.currencies_drawdown = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFacilitySetupByBorrower() {
      //this function is called to get list of day count
      const path = "/facility-setup-borrower/" + this.selectedBorrowerId;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.FacilitySetup = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close

  mounted() {
    this.addDrawdownDoc();
  },

  created() {
    this.prevDrawdownDate = this.$route.params.prev_drawdown_date;
    this.loan_registration_id = this.$route.params.loan_registration_id;
    this.selectedBorrowerId = this.$route.params.borrower_id;
    this.facility_registration_id = this.$route.params.facility_id;
    this.selectedCurrencyDrawdown = this.$route.params.fund_currency_id;
    this.balanceAmount = this.$route.params.balance_amount;
    this.approval_date = moment
      .utc(this.$route.params.approval_date, "MM-DD-YYYY")
      .toDate();
    this.getBorrowers();
    this.getDrawdownDocuments();
    this.getCurrencies();
    this.getFacilitySetupByBorrower();
  },
};
</script>

<style scoped>
.error {
  color: red;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>
