<template>
  <div v-if="warningZone">You will be logged off in 10 sec.</div>
</template>

<script>
import { Utils } from "@/utils/CommonMethods.js"; //import commonMethods utils function
export default {
  name: "AutoLogout",
  data: function () {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
      warningZone: false,
    };
  },
  mounted() {
    if (sessionStorage.getItem("token") === null) {
      this.logoutUser();
    }
    // window.addEventListener("storage", function () {
    //   if (localStorage.getItem("vuex") === null) {
    //     console.log("Logging out");
    //     location.reload();
    //   }
    // });

    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer);
    }, this);
    this.setTimers();
  },
  destroyed() {
    this.events.forEach(function (event) {
      window.removeEventListener(event, this.resetTimer);
    }, this);
    this.resetTimer();
  },
  methods: {
    setTimers: function () {
      // this.warningTimer = setTimeout(this.warningMessage, 15 * 60 * 1000);
      this.logoutTimer = setTimeout(this.logoutUser, 60 * 60 * 1000);

      this.warningZone = false;
    },
    warningMessage: function () {
      // Utils.make_alert("warning", "You will be logged off in 10 sec.");
      this.warningZone = true;
    },
    logoutUser: function () {
      this.$store.dispatch("destroyToken");
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push({ name: "login" });
    },
    resetTimer: function () {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
  },
};
</script>

<style></style>
