<!-- This is fund-disctribution page.  -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div id="new_fund" class="container-fluid ot_tb pt-3">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx">
                          <i class="bi bi-database-up"></i>
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Total Loan Overdraft
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{LoanAnalyticsData.total_loan_overdraft}} Mn
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx">
                          <i class="bi bi-opencollective"></i>
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Total Open Utilization
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{LoanAnalyticsData.total_open_utilization}} Mn
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx">
                          <i class="bi bi-currency-rupee"></i>
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Unutilized Amount
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{LoanAnalyticsData.unutilized_amnt}} Mn
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx">
                          <i class="bi bi-person"></i>
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            No. of Borrower
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{LoanAnalyticsData.total_borrower_count}}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx">
                          <i class="bi bi-currency-rupee"></i>
                          <!-- <i class="bi bi-receipt-cutoff"></i> -->
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Total Repayment
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{LoanAnalyticsData.total_loan_repayment}} Mn
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx">
                          <!-- <i class="bi bi-check2"></i> -->
                          <i class="bi bi-caret-up"></i>
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Total Interest
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{LoanAnalyticsData.total_interest}} Mn
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx">
                          <i class="bi bi-arrow-return-right"></i>
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Outstanding Amount
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{LoanAnalyticsData.total_outstanding_amnt}} Mn
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div id="distribution" class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="container-fluid">
                    <div class="tp_mid">
                      <div class="jst_sb">
                        <div class="graybx">
                          <!-- <i class="bi bi-arrow-return-left"></i> -->
                          <i class="bi bi-caret-up"></i>
                        </div>
                        <div>
                          <h6 class="text2 col1 jst_fe mb-0">
                            Penalty Interest
                          </h6>
                          <h3 class="jst_fe col2 mb-0">
                            {{LoanAnalyticsData.total_penalty_interest}} Mn
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="inputbx">
            <div class="row align_c">
              <div class="col-md-2 col-sm-3">
                <div class="form-group fc">
                  <select 
                    id="tokens" 
                    name="selectedFund"
                    v-model="selectedFund"
                    class="selectpicker" 
                    aria-label="select fund" 
                    title="select fund" 
                    data-live-search="true" 
                    @change="getBorrowersByFund();
                      fetchLoanAnalytics();
                      fetchLoanByBorrowerFundCurrencyCountryData();
                      fetchGrowthOfLoan();
                      fetchDelayInLoanPayments()"
                  >
                    <option
                      v-for="fund in funds"
                      :value="fund.fund_id"
                      :key="fund.fund_id"
                    >
                      {{ fund.fund_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-10 col-sm-9">
                <div class="jst_fe fc">
                  <h6 class="info col2">
                    **All Amounts are in {{ LoanAnalyticsData.currencyName[0] }}  | <span class="col1">As on {{ todayDate }}</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <div class="title">
                    <div class="row align_c">
                      <div class="col-md-8">
                        <h6 v-if="this.top3Flag" class="tp_mid text2">
                          Top 3 Borrower – Based on open loan amount
                        </h6>
                        <h6 v-else class="tp_mid text2">
                          3 Borrower – Based on open loan amount
                        </h6>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group fc">
                          <select 
                            class="selectpicker" 
                            multiple 
                            aria-label="select borrowers" 
                            title="select borrowers"
                            data-dropup-auto="false" 
                            data-live-search="true"  
                            v-model="selectedBorrower"
                            @change="fetchTop3BorrowerData()"
                          >
                          <option
                            v-for="bor in borrowers"
                            :value="bor.borrower_id"
                            :key="bor.borrower_id"
                          >
                            {{ bor.borrower_name }}
                          </option>
                          </select>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="ht215">
                    <apexchart 
                      v-if="Top3BorrowerChartDataReady" 
                      type="bar" 
                      height="200"
                      :options="Top3BorrowerChartOptions" 
                      :series="Top3BorrowerSeries">
                    </apexchart>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <h6 class="tp_mid title text2">
                    Growth of Loans
                  </h6>
                  <div class="ht215">
                    <apexchart 
                      v-if="GrowthOfLoanChartDataReady" 
                      type="line" 
                      height="200"
                      :options="GrowthOfLoanChartOptions" 
                      :series="GrowthOfLoanSeries">
                    </apexchart>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <h6 class="tp_mid title text2">
                    Loans – Borrower Country
                  </h6>
                  <div class="ht215">
                    <apexchart 
                      v-if="LoanByLoanCountryChartDataReady" 
                      type="donut" 
                      height="200"
                      :options="LoanByLoanCountryChartOptions" 
                      :series="LoanByLoanCountrySeries">
                    </apexchart>
                    <h6 class="tp_mid title text2" v-if="!LoanByLoanCountryChartDataReady">No Data Found</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <h6 class="tp_mid title text2">
                    Loan - Fund Currency
                  </h6>
                  <div class="ht215">
                    <apexchart 
                      v-if="LoanByFundCurrencyChartDataReady" 
                      type="donut" 
                      height="200"
                      :options="LoanByFundCurrencyChartOptions" 
                      :series="LoanByFundCurrencySeries">
                    </apexchart>
                    <h6 class="tp_mid title text2" v-if="!LoanByFundCurrencyChartDataReady">No Data Found</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <h6 class="tp_mid title text2">
                    Loan - Loan Currency
                  </h6>
                  <div class="ht215">
                    <apexchart 
                      v-if="LoanByBorrowerCurrencyChartDataReady" 
                      type="donut" 
                      height="200"
                      :options="LoanByBorrowerCurrencyChartOptions" 
                      :series="LoanByBorrowerCurrencySeries">
                    </apexchart>
                    <h6 class="tp_mid title text2" v-if="!LoanByBorrowerCurrencyChartDataReady">No Data Found</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="card card-primary card-tabs">
                <div class="card-body">
                  <h6 class="tp_mid title text2 one">
                    Delay In Loan Payments - Based on Duration
                  </h6>
                  <div class="ht215">
                    <apexchart 
                      v-if="DelayInLoanPaymentsChartDataReady" 
                      type="bar" 
                      height="200"
                      :options="DelayInLoanPaymentsChartOptions" 
                      :series="DelayInLoanPaymentsSeries">
                    </apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.content-->
    </div>
    <!-- /.content-wrapper -->

  </div>
  <!-- ./wrapper -->
</template>


<script>
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";
Vue.component('apexchart', VueApexCharts);

export default {
  data() {
    return {
      currencyName: "",
      lastMonthValue: 0.00,
      PercentageChange: 0.00,
      todayDate: moment().format('DD MMMM YYYY'),
      top3Flag: true,      // Fund Dropdown

      selectedFund: 'all',
      funds: [],
      // Borrower Dropdown
      selectedBorrower: [],
      lastValidSelection: [],
      borrowers: [],

      Colors: ['#1930AB', '#0070C0', '#00B0F0', '#7F7F7F', '#D9D9D9'],
      
      // 8 Box Data
      LoanAnalyticsData: {
        total_loan_overdraft: 0,
        total_open_utilization: 0,
        unutilized_amnt:0,
        total_borrower_count: 0,
        total_loan_repayment: 0,
        total_interest: 0,
        total_outstanding_amnt: 0,
        total_penalty_interest: 0,
        currencyName: ["USD"],
        aumDate: ""
      },

      // Top 3 Borrower
      Top3BorrowerChartOptions: {},
      Top3BorrowerSeries: [],
      Top3BorrowerChartData: {},
      Top3BorrowerChartDataReady: false,

      // Growth Of Loan
      GrowthOfLoanChartOptions: {},
      GrowthOfLoanSeries: [],
      GrowthOfLoanChartData: {},
      // GrowthOfLoanChartData: {
      //   xAxisData: ['2020', '2021', '2022', '2023', '2024'],
      //   seriesData: [
      //     {
      //       name: 'All Fund',
      //       type: 'bar',
      //       data: [44, 55, 41, 64, 22]
      //     },
      //     {
      //       name: 'Growth Of Loan',
      //       type: 'line',
      //       data: [0, 32, 33, -52, -13]
      //     }
      //   ]
      // },
      GrowthOfLoanChartDataReady: false,

      // Loan By Borrower Currency
      LoanByBorrowerCurrencyChartOptions: {},
      LoanByBorrowerCurrencySeries: [],
      LoanByBorrowerCurrencyChartData: {},
      LoanByBorrowerCurrencyChartDataReady: false,

      // Loan By Loan Country
      LoanByLoanCountryChartOptions: {},
      LoanByLoanCountrySeries: [],
      LoanByLoanCountryChartData: {},
      LoanByLoanCountryChartDataReady: false,

      // Loan By Fund Currency
      LoanByFundCurrencyChartOptions: {},
      LoanByFundCurrencySeries: [],
      LoanByFundCurrencyChartData: {},
      LoanByFundCurrencyChartDataReady: false,

      // Delay In Loan Payments
      DelayInLoanPaymentsChartOptions: {},
      DelayInLoanPaymentsSeries: [],
      DelayInLoanPaymentsChartData: {},
      // DelayInLoanPaymentsChartData: {
      //   xAxisData: ['Borrower 1', 'Borrower 2', 'Borrower 3'],
      //   seriesData: [
      //     {
      //       name: 'Delay Time (days)',
      //       data: [15, 7, 20]
      //     },
      //     {
      //       name: 'No. of Loan',
      //       data: [5, 8, 3]
      //     }
      //   ]
      // },
      DelayInLoanPaymentsChartDataReady: false,

      desiredTicks: 3,
      isLoading: false,

      isBasicDetailsSubmitted: false,
      isSubmitted: false,
    };
  },
  watch: {
    selectedBorrower(newVal, oldVal) {
      if (newVal.length > 3) {
        this.$nextTick(() => {
          this.selectedBorrower = [...this.lastValidSelection];
          this.$nextTick(() => {
            // Use jQuery to update the selectpicker UI.
            $('.selectpicker').selectpicker('refresh');
          });
        });
      } else {
        this.lastValidSelection = [...newVal];
      }
    }
  },
  methods: {
    calculateIntervalForThreeTicks(startDateStr, endDateStr) {
      const minDate = new Date(startDateStr).getTime();
      const maxDate = new Date(endDateStr).getTime();
      const range = maxDate - minDate;

      // Dynamically calculate interval for desired number of ticks
      const interval = range / (this.desiredTicks - 1);


      return interval;
    },

    // Generalized method to dynamically calculate tick positions for date-based data
    calculateDynamicTickPositions(seriesDataArray, minTicks = 2, maxTicks = 5) {
      if (!seriesDataArray.length) {
        return {
          tickPositions: [],
          interval: 0,
          minDate: null,
          maxDate: null,
          range: 0,
          tickCount: 0
        };
      }

      // Flatten the array of series data to get all data points
      let allDataPoints = seriesDataArray.flatMap(series => series.data);

      // Assuming 'x' values are already timestamps, use them directly
      let timestamps = allDataPoints.map(point => point.x);
      
      // Calculate min and max timestamp from all data points
      let minTimestamp = Math.min(...timestamps);
      let maxTimestamp = Math.max(...timestamps);
      const range = maxTimestamp - minTimestamp;

      // Determine the optimal number of ticks based on the unique timestamps in the dataset
      const uniqueTimestamps = new Set(timestamps).size;
      const optimalTicks = Math.min(Math.max(uniqueTimestamps, minTicks), maxTicks);

      // Calculate the interval between ticks. Avoid division by zero for a single unique timestamp
      const interval = uniqueTimestamps > 1 ? range / (optimalTicks - 1) : 0;

      // Calculate and return tick positions based on the interval, converting back to date objects or date strings as needed
      let tickPositions = [];
      for (let i = 0; i < optimalTicks; i++) {
        const tickTimestamp = minTimestamp + interval * i;
        // Convert timestamp back to Date object or to a specific string format as required by your charting library
        tickPositions.push(tickTimestamp);
      }

      return {
        tickPositions, // The calculated positions for ticks on the chart
        interval, // The interval between each tick
        minDate: minTimestamp, // The minimum date (timestamp) in the data set
        maxDate: maxTimestamp, // The maximum date (timestamp) in the data set
        range, // The range between the minimum and maximum dates
        tickCount: optimalTicks // The number of ticks calculated to be optimal
      };
    },

    fetchLoanAnalytics() {
      let selectAllFund = 0
      console.log("fetchLoanAnalytics",this.selectedFund)
      let selectedFund = null
      if(this.selectedFund == 'all'){
        selectAllFund = 1;
      } else {
        selectedFund = this.selectedFund
      }
      // Simulated API response
      const path = "get-loan-analytics-data/" + 
      selectedFund + 
      "/" + 
      selectAllFund;
      console.log(path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.LoanAnalyticsData = res.data;
          } else {
            console.log("success", "Something went wrong from server!");
          }
          Utils.stop_loading();
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
      
    },

    updateSelection(newSelection) {
      console.log("updateSelection")
      if (newSelection.length <= 3) {
        this.selectedBorrower = newSelection;
        this.lastValidSelection = [...newSelection];
      } else {
        Utils.make_alert("warning", "You can only select up to 3 borrower.");
      }
    },

    fetchTop3BorrowerData() {
      console.log("fetchTop3BorrowerData", this.selectedFund)
      console.log("selectedBorrower",this.selectedBorrower)
      this.updateSelection(this.selectedBorrower)
      let finalSelectedBorrower = null
      if (this.selectedBorrower.length > 0) {
        finalSelectedBorrower = this.selectedBorrower
      } else {
        finalSelectedBorrower = null
      }
      console.log("finalSelectedBorrower",finalSelectedBorrower)
      let selectedAllFund = 0
      let selectedFund = null
      if(this.selectedFund == 'all'){
        selectedAllFund = 1;
      } else {
        selectedFund = this.selectedFund
      }
      // Simulated API response
      const path = "get-top-three-borrower" +
        "/" +
        selectedFund +
        "/" +
        finalSelectedBorrower +
        "/" +
        selectedAllFund;
      console.log(path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if(this.selectedBorrower.length > 0){
            this.top3Flag = false
          }else {
            this.top3Flag = true
          }
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.Top3BorrowerChartData = res.data.top_borrower_data;
            // For a bar chart, the series data structure can stay the same if it suits your data representation
            this.Top3BorrowerSeries = this.Top3BorrowerChartData.seriesData.map(series => ({
              name: series.name,
              data: series.data // Assuming this is already in the correct format for a bar chart
            }));

            console.log("Top3BorrowerSeries ", this.Top3BorrowerSeries)
            this.setTop3BorrowerChartOptionData(this.Top3BorrowerChartData.xAxisData)


          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });

    },

    fetchGrowthOfLoan() {
      // this.GrowthOfLoanSeries = this.GrowthOfLoanChartData.seriesData.map(series => ({
      //         name: series.name,
      //         type: series.type,
      //         data: series.data // Assuming this is already in the correct format for a bar chart
      //       }));

      //       console.log("GrowthOfLoanSeries ", this.GrowthOfLoanSeries)
      //       this.setGrowthOfLoanChartOptionData(this.GrowthOfLoanChartData.xAxisData)
      //       return;
      let selectAllFund = 0
      console.log("fetchGrowthOfLoan",this.selectedFund)
      let selectedFund = null
      if(this.selectedFund == 'all'){
        selectAllFund = 1;
      } else {
        selectedFund = this.selectedFund
      }
      // Simulated API response
      const path = "get-growth-of-loan/" + 
      selectedFund + 
      "/" + 
      selectAllFund;
      console.log(path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.GrowthOfLoanChartData = res.data.GrowthOfLoanChartData;
            // For a bar chart, the series data structure can stay the same if it suits your data representation
            this.GrowthOfLoanSeries = this.GrowthOfLoanChartData.seriesData.map(series => ({
              name: series.name,
              type: series.type,
              data: series.data // Assuming this is already in the correct format for a bar chart
            }));

            console.log("GrowthOfLoanSeries ", this.GrowthOfLoanSeries)
            this.setGrowthOfLoanChartOptionData(this.GrowthOfLoanChartData.xAxisData)


          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });

    },

    fetchLoanByBorrowerFundCurrencyCountryData() {
      console.log("fetchLoanByBorrowerFundCurrencyCountryData", this.selectedFundWithAll)
      let selectedFund = null;
      let selectedAllFund = 0;
      if (this.selectedFund == 'all') {
        selectedAllFund = 1;
      } else {
        selectedFund = this.selectedFund
      }
      // Simulated API response
      const path = "get-loan-borrower-fund-currency-country" +
        "/" +
        selectedFund +
        "/" +
        selectedAllFund;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.LoanByBorrowerCurrencyChartData = res.data.loan_converted_amount_donut_chart_data;
            this.LoanByBorrowerCurrencySeries = this.LoanByBorrowerCurrencyChartData.seriesData.map(series => series.data);

            console.log("LoanByBorrowerCurrencySeries ", this.LoanByBorrowerCurrencySeries)
            if(this.LoanByBorrowerCurrencyChartData.labels.length > 0){
              this.setLoanByBorrowerCurrencyOptionData(this.LoanByBorrowerCurrencyChartData.labels,this.LoanByBorrowerCurrencySeries)
            } else {
              this.LoanByBorrowerCurrencyChartOptions = {};
              this.LoanByBorrowerCurrencySeries = [];
              this.LoanByBorrowerCurrencyChartData = {};
              this.LoanByBorrowerCurrencyChartDataReady = false;
            }
            
            this.LoanByLoanCountryChartData = res.data.loan_by_country_donut_chart_data;
            this.LoanByLoanCountrySeries = this.LoanByLoanCountryChartData.seriesData.map(series => series.data);

            console.log("LoanByLoanCountrySeries ", this.LoanByLoanCountrySeries)
            if(this.LoanByLoanCountryChartData.labels.length > 0){
              this.setLoanByLoanCountryOptionData(this.LoanByLoanCountryChartData.labels,this.LoanByLoanCountrySeries)
            } else {
              this.LoanByLoanCountryChartOptions = {};
              this.LoanByLoanCountrySeries = [];
              this.LoanByLoanCountryChartData = {};
              this.LoanByLoanCountryChartDataReady = false;
            }
            this.LoanByFundCurrencyChartData = res.data.loan_by_fund_cur_donut_chart_data;
            this.LoanByFundCurrencySeries = this.LoanByFundCurrencyChartData.seriesData.map(series => series.data);

            console.log("LoanByFundCurrencySeries ", this.LoanByFundCurrencySeries)
            if(this.LoanByFundCurrencyChartData.labels.length > 0){
              this.setLoanByFundCurrencyOptionData(this.LoanByFundCurrencyChartData.labels,this.LoanByFundCurrencySeries)
            } else {
              this.LoanByFundCurrencyChartOptions = {};
              this.LoanByFundCurrencySeries = [];
              this.LoanByFundCurrencyChartData = {};
              this.LoanByFundCurrencyChartDataReady = false;
            }

          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });

    },

    fetchDelayInLoanPayments() {
      // // For a bar chart, the series data structure can stay the same if it suits your data representation
      // this.DelayInLoanPaymentsSeries = this.DelayInLoanPaymentsChartData.seriesData.map(series => ({
      //   name: series.name,
      //   data: series.data // Assuming this is already in the correct format for a bar chart
      // }));

      // console.log("DelayInLoanPaymentsSeries ", this.DelayInLoanPaymentsSeries)
      // this.setDelayInLoanPaymentsChartOptionData(this.DelayInLoanPaymentsChartData.xAxisData)
      // return;
      let selectAllFund = 0
      console.log("fetchDelayInLoanPayments",this.selectedFund)
      let selectedFund = null
      if(this.selectedFund == 'all'){
        selectAllFund = 1;
      } else {
        selectedFund = this.selectedFund
      }
      // Simulated API response
      const path = "get-delay-in-loan-payment/" + 
      selectedFund + 
      "/" + 
      selectAllFund;
      console.log(path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            this.DelayInLoanPaymentsChartData = res.data.DelayInLoanPaymentsChartData;
            // For a bar chart, the series data structure can stay the same if it suits your data representation
            this.DelayInLoanPaymentsSeries = this.DelayInLoanPaymentsChartData.seriesData.map(series => ({
              name: series.name,
              data: series.data // Assuming this is already in the correct format for a bar chart
            }));

            console.log("DelayInLoanPaymentsSeries ", this.DelayInLoanPaymentsSeries)
            this.setDelayInLoanPaymentsChartOptionData(this.DelayInLoanPaymentsChartData.xAxisData)


          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });

    },

    setTop3BorrowerChartOptionData(xAxisData) {
      // const yAxisTitleLength = this.Top3BorrowerChartOptions.seriesData[0].name[0].length;
      // console.log("yAxisTitleLength",yAxisTitleLength)
      
      // Setting up chart options for a bar chart
      this.Top3BorrowerChartOptions = {
        chart: {
          id: 'vuechart-example',
          type: 'bar', // Changed from 'line' to 'bar'
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: this.Colors, // Add your custom colors here
        yaxis: {
          // Set the y-axis name
          title: {
            text: 'Amounts in lakhs',
            style: {
              fontFamily: 'Calibri',
              fontSize: '14px',
            },
          },
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
          }
        },
        xaxis: {
          categories: xAxisData, // your original categories
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
            formatter: function(val) {
              // Truncate string to a maximum of 10 characters when there are more than 2 bars
              if (xAxisData.length > 1 && val.length > 20) {
                return val.substring(0, 20) + '...'; // Adjust the number of characters as needed
              } else {
                return val; // Full label for 2 or fewer bars
              }
            }
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top', // position of the data label
            },
          },
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 10, 
            bottom: 0,
            left: 0
          }
        },
        legend: {
          position: 'bottom', 
          horizontalAlign: 'center', 
          offsetX: 0, 
          offsetY: 0, 
          fontFamily: 'Calibri',
          fontSize: '12px'
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          // x: {
          //   formatter: function(val, opts) {
          //     const fullLabel = xAxisData[opts.dataPointIndex];
          //     // Insert a line break after every nth character (e.g., every 20 characters)
          //     const wrapAt = 20; // Adjust this number to suit your preference
          //     const wrappedLabel = fullLabel.replace(
          //       new RegExp(`(.{${wrapAt}})`, 'g'), '$1<br>'
          //     );
          //     return wrappedLabel;
          //   }
          // },
          y: {
            formatter: function (value) {
              // Calculate percentage
              return `${value} Lcs`;
            }
          }
          // x: {
          //   formatter: function(val, opts) {
          //     // Access the full label from the original categories array using the dataPointIndex
          //     return xAxisData[opts.dataPointIndex];
          //   }
          // },
        },
      };
      this.Top3BorrowerChartDataReady = true;
    },

    setGrowthOfLoanChartOptionData(xAxisData) {
      // const yAxisTitleLength = this.GrowthOfLoanChartOptions.seriesData[0].name[0].length;
      // console.log("yAxisTitleLength",yAxisTitleLength)
      // Setting up chart options for a bar chart
      this.GrowthOfLoanChartOptions = {
        chart: {
          id: 'vuechart-example',
          type: 'line', // Changed from 'line' to 'bar'
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: [0, 4]
        },
        colors: this.Colors, // Add your custom colors here
        yaxis: [
          {
          // Set the y-axis name
          title: {
            text: 'Amounts in lakhs',
            style: {
              fontFamily: 'Calibri',
              fontSize: '14px',
            },
          },
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
          }
        },
        {
          opposite: true,
          // Set the y-axis name
          title: {
            text: 'growth rate %',
            style: {
              fontFamily: 'Calibri',
              fontSize: '14px',
            },
          },
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
          }
        },
        ],
        xaxis: {
          type: 'category',
          categories: xAxisData, // your original categories
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '12px'
            },
          },
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 15, 
            bottom: 0,
            left: 15
          }
        },
        legend: {
          position: 'bottom', 
          horizontalAlign: 'center', 
          offsetX: 0, 
          offsetY: 0, 
          fontFamily: 'Calibri',
          fontSize: '12px'
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              // Check if it's the "Growth Of Loan" series and format accordingly
              if (w.config.series[seriesIndex].type === 'line') {
                return `${value} %`; // Assuming you want two decimal places for percentage
              } else {
                return `${value} Lcs`; // For "All Fund"
              }
            }
          }
        },
      };
      this.GrowthOfLoanChartDataReady = true;
    },

    setLoanByBorrowerCurrencyOptionData(LoanByBorrowerCurrencyLabels,LoanByBorrowerCurrencySeries) {
      const total = LoanByBorrowerCurrencySeries.reduce((acc, cur) => acc + cur, 0);
      
      this.LoanByBorrowerCurrencyChartOptions = {
        chart: {
          type: 'donut',
        },
        colors: this.Colors, // Add your custom colors here
        labels: LoanByBorrowerCurrencyLabels,
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              let percentage = (value / total * 100).toFixed(2); // Two decimal places
              return `${value} Mn - ${percentage}%`;
            }
          }
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 10, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
        dataLabels: {
          enabled: false // This line hides the data labels on the chart
        },
        legend: {
          show: false,
        }
      };

      this.LoanByBorrowerCurrencyChartDataReady = true;
    },

    setLoanByLoanCountryOptionData(LoanByLoanCountryLabels,LoanByLoanCountrySeries) {
      const total = LoanByLoanCountrySeries.reduce((acc, cur) => acc + cur, 0);

      this.LoanByLoanCountryChartOptions = {
        chart: {
          type: 'donut',
        },
        colors: this.Colors, // Add your custom colors here
        labels: LoanByLoanCountryLabels,
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              let percentage = (value / total * 100).toFixed(2); // Two decimal places
              return `${value} Mn - ${percentage}%`;
            }
          }
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 0, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
        dataLabels: {
          enabled: false // This line hides the data labels on the chart
        },
        legend: {
          show: false,
        }
      };

      this.LoanByLoanCountryChartDataReady = true;
    },

    setLoanByFundCurrencyOptionData(LoanByFundCurrencyLabels,LoanByFundCurrencySeries) {
      const total = LoanByFundCurrencySeries.reduce((acc, cur) => acc + cur, 0);

      this.LoanByFundCurrencyChartOptions = {
        chart: {
          type: 'donut',
        },
        colors: this.Colors, // Add your custom colors here
        labels: LoanByFundCurrencyLabels,
        tooltip: {
          y: {
            formatter: function (value) {
              // Calculate percentage
              let percentage = (value / total * 100).toFixed(2); // Two decimal places
              return `${value} Mn - ${percentage}%`;
            }
          }
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 10, // Increase right padding so the labels don't overflow
            bottom: 0,
            left: 0
          }
        },
        dataLabels: {
          enabled: false // This line hides the data labels on the chart
        },
        legend: {
          show: false,
        }
      };

      this.LoanByFundCurrencyChartDataReady = true;
    },

    setDelayInLoanPaymentsChartOptionData(xAxisData) {
      // const yAxisTitleLength = this.DelayInLoanPaymentsChartOptions.seriesData[0].name[0].length;
      // console.log("yAxisTitleLength",yAxisTitleLength)
      
      // Setting up chart options for a bar chart
      this.DelayInLoanPaymentsChartOptions = {
        chart: {
          id: 'vuechart-example',
          type: 'bar', // Changed from 'line' to 'bar'
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: this.Colors, // Add your custom colors here
        yaxis: {
          show: false, // This will hide the Y-axis completely
          // If you just want to hide the axis line and labels but keep the space, you might do:
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        xaxis: {
          categories: xAxisData, // your original categories
          labels: {
            style: {
              fontFamily: 'Calibri',
              fontSize: '10px'
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false, // position of the data label
        },
        grid: {
          // Add padding inside the chart
          padding: {
            top: 0,
            right: 20, 
            bottom: 0,
            left: 0
          }
        },
        legend: {
          position: 'bottom', 
          horizontalAlign: 'center', 
          offsetX: 0, 
          offsetY: 0, 
          fontFamily: 'Calibri',
          fontSize: '12px'
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            formatter: function (val, { seriesIndex, dataPointIndex, w }) {
              return val;
              // if (seriesIndex === 0) {
              //   return val + ' days delay';
              // } else {
              //   return val + ' loans';
              // }
            }
          },
          x: {
            formatter: function(val, opts) {
              // Access the full label from the original categories array using the dataPointIndex
              let fullText = xAxisData[opts.dataPointIndex];
              // Find spaces to insert line breaks, but do not exceed a certain length (e.g., 30 characters)
              let breakPoint = fullText.lastIndexOf(' ', 30);
              if (breakPoint === -1 || fullText.length <= 30) {
                return fullText; // No need to break if short enough or no space found
              } else {
                return fullText.substring(0, breakPoint) + '<br>' + fullText.substring(breakPoint + 1);
              }
            }
          },
        },
      };
      this.DelayInLoanPaymentsChartDataReady = true;
    },

    getBorrowersByFund() {
      console.log("getBorrowersByFund",this.selectedFund)
      if(this.selectedFund != 'all'){
        const path = "/get-borrower-by-fund/" + this.selectedFund;
        Utils.start_loading();
        axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          console.log("status", res.data.status)
          if(res.data.status == "success"){
              this.selectedBorrower = [];
              this.borrowers = res.data.borrower_list;
                this.$nextTick(() => {
                  console.log("refresh")
                  $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
                });
              this.fetchTop3BorrowerData();
            }
            Utils.stop_loading();
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error(error);
          });
      } else {
        const path = "borrower-master";
        Utils.start_loading();
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            this.selectedBorrower = [];
            this.borrowers = res.data.borrowers;
            this.fetchTop3BorrowerData();
            this.$nextTick(() => {
              console.log("refresh")
              $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
            });
            Utils.stop_loading();
          })
          .catch((error) => {
            Utils.stop_loading();
            console.error(error);
          });
      }
    },

    getFunds() {
      const path = "fund-master";
      Utils.start_loading();  
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();  
          this.funds = res.data.funds;
          // Add a new object at the beginning of the funds array
          this.funds.unshift({
            fund_id: 'all',
            fund_name: 'All Fund'
          });
          this.selectedFund = this.funds[0].fund_id;
            this.$nextTick(() => {
              console.log("refresh")
              $('.selectpicker').selectpicker('refresh'); // If using selectpicker, refresh it after data load
            });
          this.getBorrowersByFund();
          this.fetchLoanAnalytics();
          this.fetchLoanByBorrowerFundCurrencyCountryData();
          this.fetchGrowthOfLoan();
          this.fetchDelayInLoanPayments();
          // if(this.funds.length > 0){
          //   this.selectedFund = this.funds[0].fund_id
          // }else {
          //   this.selectedFund = null;
          // }
        })
        .catch((error) => {
          Utils.stop_loading();  
          console.error(error);
        });
    },
    

  },
  created() {
    this.getFunds();
  }
};
</script>

<style scoped>
.required-field::after {
  content: "*";
  color: red;
}

.error-msg {
  color: red;
  font-weight: bold;
}

.login_img {
  background-color: #d2d2d2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60%;
}

#side_view {
  height: 100%;
  padding: 0px;
}
</style>