<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Edit Release Info
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Container Info</h1>
                    </div>
                    <!-- /.col -->
                    <!-- <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            id="containerAddMore"
                            class="btn btn-sm sh_n btn-dark"
                            @click="
                              addContainer() &
                                calculateRemainingNetWeight() &
                                setDate()
                            "
                            :disabled="ContainerListAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div> -->
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <div
                    class="row"
                    id="containerRow"
                    v-for="(con, index) in ContainerList"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Release ID</label
                        >
                        <input
                          id="releaseId"
                          class="form-control"
                          name="releaseId"
                          v-model="con.releaseId"
                          @keypress="isAlphaNum($event)"
                          maxlength="50"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].releaseId.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].releaseId.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].releaseId.required
                            "
                            >Release ID is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Utilisation ID</label
                        >
                        <input
                          id="utilId"
                          class="form-control"
                          name="utilId"
                          v-model="con.utilId"
                          @keypress="isAlphaNum($event)"
                          maxlength="50"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].utilId.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].utilId.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].utilId.required
                            "
                            >Utilisation ID is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Borrower Name</label
                        >
                        <select
                          id="borrowerName"
                          class="form-control"
                          name="borrowerName"
                          v-model="con.borrowerName"
                          maxlength="50"
                          disabled
                          @keypress="isAlphaNum($event)"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].borrowerName.$error,
                          }"
                        >
                          <option :value="null">-- Select --</option>
                          <option
                            v-for="bor in borrowers"
                            :value="bor.borrower_name"
                          >
                            {{ bor.borrower_name }}
                          </option>
                        </select>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].borrowerName.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].borrowerName
                                .required
                            "
                            >Borrower Name is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Offtaker Name</label
                        >
                        <input
                          id="selectedOfftakerName"
                          class="form-control"
                          name="selectedOfftakerName"
                          v-model="selectedOfftakerName"
                          @keypress="isAlphaNum($event)"
                          maxlength="50"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.selectedOfftakerName.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.selectedOfftakerName.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedOfftakerName.required"
                            >Offtaker Name is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Release Date</label
                        >
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="releaseDate"
                          id="releaseDate"
                          placeholder="Select Date"
                          v-model="con.releaseDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :calendar-button="true"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].releaseDate.$error,
                          }"
                          disabled
                        ></datepicker>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].releaseDate.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].releaseDate
                                .required
                            "
                            >Release Date is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Warrant Ref.</label
                        >
                        <input
                          id="warrantRef"
                          class="form-control"
                          name="warrantRef"
                          v-model="con.warrantRef"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].warrantRef.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].warrantRef.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].warrantRef.required
                            "
                            >Warrant Ref is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].warrantRef
                                .maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Container No.</label
                        >
                        <input
                          id="containerNo"
                          class="form-control"
                          name="containerNo"
                          v-model="con.containerNo"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].containerNo.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].containerNo.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].containerNo
                                .required
                            "
                            >Container No is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].containerNo
                                .maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Truck No.</label
                        >
                        <input
                          id="truckNo"
                          class="form-control"
                          name="truckNo"
                          v-model="con.truckNo"
                          @keypress="isAlphaNum($event)"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].truckNo.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].truckNo.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].truckNo.required
                            "
                            >Truck No. is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].truckNo.maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Seal No.</label
                        >
                        <input
                          id="sealNo"
                          class="form-control"
                          name="sealNo"
                          v-model="con.sealNo"
                          @keypress="isAlphaNum($event)"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].sealNo.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].sealNo.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].sealNo.required
                            "
                            >Seal No is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].sealNo.maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Released Qty</label
                        >
                        <input
                          id="releasedQty"
                          class="form-control"
                          name="releasedQty"
                          v-model="con.releasedQty"
                          type="number"
                          @keypress="isDecimal($event)"
                          disabled
                          min="0"
                          :class="{
                            'is-invalid':
                              (isSubmitted &&
                                $v.ContainerList.$each[index].releasedQty
                                  .$error) ||
                              (Number(con.releasedQty) >
                                Number(containerRemReleasedQty) &&
                                index === ContainerList.length - 1),
                          }"
                        />
                        <div
                          v-if="
                            (isSubmitted &&
                              $v.ContainerList.$each[index].releasedQty
                                .$error) ||
                            (Number(con.releasedQty) >
                              Number(containerRemReleasedQty) &&
                              index === ContainerList.length - 1)
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].releasedQty
                                .required
                            "
                            >Released Qty is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].releasedQty
                                .maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                          <span
                            v-if="
                              Number(con.releasedQty) >
                                Number(containerRemReleasedQty) &&
                              index === ContainerList.length - 1
                            "
                            >Released Qty cannot exceed limit of
                            {{ containerRemReleasedQty }}!
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Bags</label
                        >
                        <input
                          id="bags"
                          class="form-control"
                          name="bags"
                          v-model="con.bags"
                          @keypress="isNum($event)"
                          disabled
                          :class="{
                            'is-invalid':
                              (isSubmitted &&
                                $v.ContainerList.$each[index].bags.$error) ||
                              (Number(con.bags) > Number(containerRemBags) &&
                                index === ContainerList.length - 1),
                          }"
                        />
                        <div
                          v-if="
                            (isSubmitted &&
                              $v.ContainerList.$each[index].bags.$error) ||
                            (Number(con.bags) > Number(containerRemBags) &&
                              index === ContainerList.length - 1)
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.ContainerList.$each[index].bags.required"
                            >Bags is required</span
                          >
                          <span
                            v-if="!$v.ContainerList.$each[index].bags.maxLength"
                            >Max length of 50 characters exceeded</span
                          >
                          <span
                            v-if="
                              Number(con.bags) > Number(containerRemBags) &&
                              index === ContainerList.length - 1
                            "
                            >Bags cannot exceed limit of {{ containerRemBags }}!
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Gross Weight</label
                        >
                        <input
                          id="grossWeight"
                          class="form-control"
                          name="grossWeight"
                          v-model="con.grossWeight"
                          type="number"
                          @keypress="isDecimal($event)"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].grossWeight.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].grossWeight.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].grossWeight
                                .required
                            "
                            >Gross Weight is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].grossWeight
                                .maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Net Weight</label
                        >
                        <input
                          id="netWeight"
                          class="form-control"
                          name="netWeight"
                          v-model="con.netWeight"
                          type="number"
                          @keypress="isDecimal($event)"
                          disabled
                          :class="{
                            'is-invalid':
                              (isSubmitted &&
                                $v.ContainerList.$each[index].netWeight
                                  .$error) ||
                              (Number(con.netWeight) >
                                Number(containerRemNetWeight) &&
                                index === ContainerList.length - 1),
                          }"
                        />
                        <div
                          v-if="
                            (isSubmitted &&
                              $v.ContainerList.$each[index].netWeight.$error) ||
                            (Number(con.netWeight) >
                              Number(containerRemNetWeight) &&
                              index === ContainerList.length - 1)
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].netWeight.required
                            "
                            >Net Weight is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].netWeight.maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                          <span
                            v-if="
                              Number(con.netWeight) >
                                Number(containerRemNetWeight) &&
                              index === ContainerList.length - 1
                            "
                            >Net Weight cannot exceed limit of
                            {{ containerRemNetWeight }}!
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Tare Weight</label
                        >
                        <input
                          id="tareWeight"
                          class="form-control"
                          name="tareWeight"
                          v-model="con.tareWeight"
                          type="number"
                          @keypress="isDecimal($event)"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].tareWeight.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].tareWeight.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].tareWeight.required
                            "
                            >Tare Weight is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].tareWeight
                                .maxLength
                            "
                            >Max length of 50 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Unit</label
                        >
                        <select
                          id="unit"
                          class="form-control"
                          name="unit"
                          v-model="con.unit"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].unit.$error,
                          }"
                        >
                          <option :value="null">-- Select --</option>
                          <option
                            v-for="unit in UnitTypes"
                            :value="unit.id"
                            :key="unit.id"
                          >
                            {{ unit.name }}
                          </option>
                        </select>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].unit.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.ContainerList.$each[index].unit.required"
                            >Unit is required</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >GDN Number</label
                        >
                        <input
                          id="gdnNo"
                          class="form-control"
                          name="gdnNo"
                          v-model="con.gdnNo"
                          @keypress="isAlphaNum($event)"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ContainerList.$each[index].gdnNo.$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ContainerList.$each[index].gdnNo.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.ContainerList.$each[index].gdnNo.required"
                            >GDN Number is required</span
                          >
                          <span
                            v-if="
                              !$v.ContainerList.$each[index].gdnNo.maxLength
                            "
                            >Max length of 15 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-md-1 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          id="containerDelete"
                          v-on:click="
                            deleteContainer(index, con.release_container_id)
                          "
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div> -->
                  </div>

                  <!-- <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        id="containerSaveInfo"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onContainerListSaveInfo()"
                        :disabled="isDisabledContainerListSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Total Released</h1>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Total Gross Weight</label
                        >
                        <input
                          id="totalGrossWeight"
                          class="form-control"
                          name="totalGrossWeight"
                          v-model="totalGrossWeight"
                          type="number"
                          @keypress="isDecimal($event)"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.totalGrossWeight.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.totalGrossWeight.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.totalGrossWeight.required"
                            >Total Gross Weight is required</span
                          >
                          <span v-if="!$v.totalGrossWeight.maxLength"
                            >Max length of 15 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Total Net Weight Loaded</label
                        >
                        <input
                          id="totalNetWeight"
                          class="form-control"
                          name="totalNetWeight"
                          v-model="totalNetWeight"
                          type="number"
                          @keypress="isDecimal($event)"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.totalNetWeight.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.totalNetWeight.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.totalNetWeight.required"
                            >Total Net Weight Loaded is required</span
                          >
                          <span v-if="!$v.totalNetWeight.maxLength"
                            >Max length of 15 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Total Net Weight Loaded in MT</label
                        >
                        <input
                          type="number"
                          id="totalReleasedQty"
                          class="form-control"
                          name="totalReleasedQty"
                          v-model="totalReleasedQty"
                          @keypress="isDecimal($event)"
                          disabled
                          :class="{
                            'is-invalid':
                              isSubmitted && $v.totalReleasedQty.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.totalReleasedQty.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.totalReleasedQty.required"
                            >Total Net Weight Loaded in MT is required</span
                          >
                          <span v-if="!$v.totalReleasedQty.maxLength"
                            >Max length of 15 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Unit</label
                        >
                        <select
                          id="totalUnit"
                          class="form-control"
                          name="totalUnit"
                          v-model="totalUnit"
                          disabled
                          :class="{
                            'is-invalid': isSubmitted && $v.totalUnit.$error,
                          }"
                        >
                          <option :value="null">-- Select --</option>
                          <option
                            v-for="unit in UnitTypes"
                            :value="unit.id"
                            :key="unit.id"
                          >
                            {{ unit.name }}
                          </option>
                        </select>
                        <div
                          v-if="isSubmitted && $v.totalUnit.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.totalUnit.required"
                            >Total Unit is required</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Total Bags</label
                        >
                        <input
                          id="totalBags"
                          class="form-control"
                          name="totalBags"
                          v-model="totalBags"
                          @keypress="isNum($event)"
                          disabled
                          :class="{
                            'is-invalid': isSubmitted && $v.totalBags.$error,
                          }"
                        />
                        <div
                          v-if="isSubmitted && $v.totalBags.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.totalBags.required"
                            >Total Bags is required</span
                          >
                          <span v-if="!$v.totalBags.maxLength"
                            >Max length of 15 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addReleaseDoc()"
                            :disabled="ReleaseDocumentsAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(doc, index) in ReleaseDocuments"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label>Select Document</label>
                        <select
                          id="ReleaseInfoDocName"
                          class="form-control"
                          name="ReleaseInfoDocName"
                          v-model="doc.ReleaseInfoDocName"
                          @change="addEditedFlag(index)"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="document in ReleaseDocs"
                            :key="document.id"
                          >
                            {{ document.document_name }}
                          </option>
                        </select>
                        <!-- <div
                          v-if="
                            isSubmitted &&
                            $v.RequestDocuments.$each[index]
                              .RequestDocName.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.RequestDocuments.$each[index]
                                .RequestDocName.required
                            "
                            >Document Name is required</span
                          >
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @mouseenter="mouseenter(doc.ReleaseInfoDocName, index)"
                        @mouseleave="mouseleave(doc.ReleaseInfoDocName, index)"
                      >
                        <label for="exampleInputEmail1">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            :disabled="
                              disableUpload(index, doc.ReleaseInfoDocName)
                            "
                            v-on:change="
                              handleFileUploadReleaseDocuments(index, $event) &
                                addEditedFlag(index)
                            "
                          />
                          <!-- <div
                            v-if="isSubmitted && doc.display_file_name === null"
                            class="invalid-feedback"
                          >
                            <span v-if="doc.display_file_name === null"
                              >Document Name is required</span
                            >
                          </div> -->
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ doc.display_file_name }}</b>
                              <small>
                                <u
                                  style="color: blue; cursor: pointer"
                                  v-if="
                                    doc.ReleaseDocumentsRemoveDisabled === false
                                  "
                                  v-on:click="removeReleaseDocumentsDoc(index)"
                                  >Remove</u
                                >
                              </small>
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.errors">
                              {{ doc.errors }}
                            </p>
                          </small>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Uploading Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="ReleaseInfoDocumentsDate"
                          id="ReleaseInfoDocumentsDate"
                          placeholder="Select Date"
                          v-model="doc.ReleaseInfoDocumentsDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabledDates="{ from: new Date() }"
                          :calendar-button="true"
                          @input="addEditedFlag(index)"
                        ></datepicker>
                        <!-- <div
                          v-if="
                            isSubmitted &&
                            $v.RequestDocuments.$each[index]
                              .RequestDocumentsDate.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.RequestDocuments.$each[index]
                                .RequestDocumentsDate.required
                            "
                            >Uploading Date is required</span
                          >
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          id="docDelete"
                          v-on:click="deleteReleaseDocuments(index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onReleaseDocumentSaveInfo()"
                        :disabled="isDisabledReleaseDocumentSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    id="submit"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a @click="$router.go(-1)" style="cursor: pointer">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: null,
      release_info_id: null,
      borrowers: [],
      offtakers: [],
      UnitTypes: [],
      ContainerList: [],
      unit: null,
      selectedReleaseId: null,
      selectedUtilId: null,
      selectedBorrowerName: null,
      selectedReleaseDate: new Date(),
      selectedOfftakerId: null,
      selectedOfftakerName: null,

      //variables for request information
      containerId: null,
      ContainerListDisabled: true,
      ContainerListSaveInfoDisabled: true, //save info button is disabled
      ContainerListAddMoreDisabled: true,

      totalGrossWeight: null,
      totalNetWeight: null,
      totalUnit: null,
      totalReleasedQty: null,
      totalBags: null,
      quantityLimit: null,
      bagLimit: null,
      containerRemNetWeight: 0,
      containerRemReleasedQty: 0,
      containerRemBags: 0,

      //list for Release Documents
      ReleaseDocuments: [],
      ReleaseDocs: [],
      ReleaseDocumentsLength: null,
      ReleaseDocumentFile: null,
      ReleaseDelete: [],
      // ReleaseDocumentsButtonDisabled: true,
      ReleaseDocumentsDisabled: true,
      ReleaseDocumentsSaveInfoDisabled: true, //save info button is disabled
      ReleaseDocumentsAddMoreDisabled: true,
      msg: [],
      error: "",
      isSubmitted: false,
      onSubmitDisabled: false,
      uploadHoverError: false,
    };
  },
  validations: {
    selectedOfftakerName: {
      required,
    },
    totalGrossWeight: {
      maxLength: maxLength(15),
      required,
    },
    totalNetWeight: {
      maxLength: maxLength(15),
      required,
    },
    totalReleasedQty: {
      maxLength: maxLength(15),
      required,
    },
    totalUnit: {
      required,
    },
    totalBags: {
      maxLength: maxLength(15),
      required,
    },
    ContainerList: {
      $each: {
        utilId: {
          required,
        },
        releaseId: {
          required,
        },
        borrowerName: {
          required,
        },
        releaseDate: {
          required,
        },
        warrantRef: {
          maxLength: maxLength(50),
          required,
        },
        containerNo: {
          maxLength: maxLength(50),
          required,
        },
        truckNo: {
          maxLength: maxLength(50),
          required,
        },
        sealNo: {
          maxLength: maxLength(50),
          required,
        },
        releasedQty: {
          maxLength: maxLength(50),
          required,
        },
        bags: {
          maxLength: maxLength(50),
          required,
        },
        grossWeight: {
          maxLength: maxLength(50),
          required,
        },
        netWeight: {
          maxLength: maxLength(50),
          required,
        },
        unit: {
          required,
        },
        tareWeight: {
          maxLength: maxLength(50),
          required,
        },
        gdnNo: {
          maxLength: maxLength(15),
          required,
        },
      },
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },

    //this is to computed disabled property of ReleaseDocuments Save Info button.
    isDisabledReleaseDocumentSaveInfo: function () {
      let checkEmptyReleaseDocumentsDocName = this.ReleaseDocuments.filter(
        (line) => line.ReleaseInfoDocName === null
      );
      let checkEmptyReleaseInfoDocumentsDate = this.ReleaseDocuments.filter(
        (line) => line.ReleaseInfoDocumentsDate === null
      );
      //if the line is empty then disable save info button
      if (
        this.ReleaseDocuments.length === 0 &&
        this.ReleaseDocumentsSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyReleaseDocumentsDocName.length >= 1 &&
          this.ReleaseDocuments.length > 0) ||
        (checkEmptyReleaseInfoDocumentsDate.length >= 1 &&
          this.ReleaseDocuments.length > 0)
      ) {
        this.ReleaseDocumentsDisabled = true;
      } else {
        this.ReleaseDocumentsDisabled = false;
      }
      //ReleaseDocumentsDisabled variable is to check whther all input fields except file upload are filled. ReleaseDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.ReleaseDocumentsDisabled === true ||
        this.ReleaseDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledReleaseDocumentSaveInfo() close

    //this is to computed disabled property of ContainerLists Save Info button.
    isDisabledContainerListSaveInfo: function () {
      let checkEmptyContainerListDocName = this.ContainerList.filter(
        (line) => line.warrantRef === null
      );
      let checkEmptyContainerListDate = this.ContainerList.filter(
        (line) => line.containerNo === null
      );
      //if the line is empty then disable save info button
      if (
        this.ContainerList.length === 0 &&
        this.ContainerListSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        (checkEmptyContainerListDocName.length >= 1 &&
          this.ContainerList.length > 0) ||
        (checkEmptyContainerListDate.length >= 1 &&
          this.ContainerList.length > 0)
      ) {
        this.ContainerListDisabled = true;
      } else {
        this.ContainerListDisabled = false;
      }
      //ContainerListDisabled variable is to check whther all input fields except file upload are filled. ContainerListFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.ContainerListDisabled === true ||
        this.ContainerListFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledContainerListSaveInfo() close
  },
  components: {
    Datepicker,
  },
  methods: {
    addEditedFlag(index) {
      if (index < this.ReleaseDocumentsLength) {
        this.ReleaseDocuments[index]["edited"] = true;
      }
    },
    isAlphaNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },

    //this method will make disabled property as false for Request Save Info
    onReleaseDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.ReleaseDocumentsSaveInfoDisabled = false;
      this.ReleaseDocumentsAddMoreDisabled = false;
    },

    //this function deletes ReleaseDocuments record
    deleteReleaseDocuments(index) {
      let deleteDocId = this.ReleaseDocuments[index].document_id;
      let deleteId = this.ReleaseDocuments[index].release_document_id;
      this.ReleaseDelete.push({
        userId: this.$store.getters.getuserId,
        id: deleteId,
        docId: deleteDocId,
        type: "Document",
        clientId: this.$store.state.client_id,
        userId: this.$store.getters.getuserId,
        moduleID: 10,
        tabID: 27,
      });
      this.ReleaseDocumentFile = "";
      this.ReleaseDocuments.splice(index, 1);
      if (
        typeof this.ReleaseDocuments !== "undefined" &&
        this.ReleaseDocuments.length == 0
      ) {
        this.ReleaseDocumentsAddMoreDisabled = false;
        this.ReleaseDocumentsSaveInfoDisabled = true;
      }
    },

    //this method will submit the Request form in backend.
    onSubmit() {
      this.$confirm({
        auth: false,
        message: "Confirm Submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            this.isSubmitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
              return;
            }
            for (let i = 0; i < this.ContainerList.length; i++) {
              if (this.ContainerList[i].warrantRef === null) {
                this.ContainerList.splice(i, 1);
              } else {
                if (this.ContainerList[i].releaseDate !== null) {
                  this.ContainerList[i].releaseDate = moment(
                    this.ContainerList[i].releaseDate
                  ).format("MM-DD-YYYY");
                }
              }
            }
            for (let i = 0; i < this.ReleaseDocuments.length; i++) {
              if (this.ReleaseDocuments[i].ReleaseInfoDocName === null) {
                this.ReleaseDocuments.splice(i, 1);
              } else {
                if (
                  this.ReleaseDocuments[i].ReleaseInfoDocumentsDate !== null
                ) {
                  this.ReleaseDocuments[i].ReleaseInfoDocumentsDate = moment(
                    this.ReleaseDocuments[i].ReleaseInfoDocumentsDate
                  ).format("MM-DD-YYYY");
                }
              }
            }
            //initialize the form data
            let formData = new FormData();

            formData.append("Released", JSON.stringify(this.ContainerList));
            formData.append("totalGrossWeight", this.totalGrossWeight);
            formData.append("totalNetWeight", this.totalNetWeight);
            formData.append("totalReleasedQuantity", this.totalReleasedQty);
            formData.append("totalUnit", this.totalUnit);
            formData.append("totalBags", this.totalBags);
            formData.append(
              "clientId",
              JSON.stringify(this.$store.state.client_id)
            );
            formData.append(
              "ReleaseDocuments",
              JSON.stringify(this.ReleaseDocuments)
            );
            formData.append("userId", this.$store.getters.getuserId);
            formData.append(
              "DeleteDocData",
              JSON.stringify(this.ReleaseDelete)
            );
            const path = "edit_release/" + this.release_info_id;
            Utils.start_loading();
            axios
              .post(path, formData, {
                headers: {
                  enctype: "multipart/form-data",
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Release Info updated successfully!"
                  );
                  console.log("SUCCESS!!");
                  this.$router.push("/collateral");
                } else if (
                  response.data.status == "collateral_already_exists"
                ) {
                  Utils.make_alert("warning", "Release Info already exists!");
                  this.$router.push("/collateral");
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error(error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          }
        },
      });
    }, //onSubmit() close

    //this method will add new line for additional Collateral Documents
    addReleaseDoc() {
      //when new line is added then ReleaseDocumentFile variable and ReleaseDocumentsSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.ReleaseDocumentFile = null),
        (this.ReleaseDocumentsSaveInfoDisabled = true),
        (this.ReleaseDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.ReleaseDocuments.push({
          userId: this.$store.getters.getuserId,
          id: null,
          release_document_id: null,
          document_id: null,
          ReleaseInfoDocName: null,
          ReleaseInfoDocumentsDate: new Date(),
          display_file_name: null,
          errors: "",
          edited: false,
          ReleaseDocumentsRemoveDisabled: true,
        });
    }, //addReleaseDoc() close
    mouseenter(docName, index) {
      if (docName === null) {
        this.ReleaseDocuments[index].errors = "Document name is not selected";
      }
    },
    mouseleave(docName, index) {
      if (docName === null) {
        this.ReleaseDocuments[index].errors = "";
      }
    },
    disableUpload(id, docName) {
      if (docName === null) {
        return true;
      } else {
        return false;
      }
    },
    //handles a change on the file upload
    handleFileUploadReleaseDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.ReleaseDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.ReleaseDocumentFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors = "File size should be less than 100MB";
          self.ReleaseDocuments[id].errors = JSON.stringify(
            response.errors
          ).replace(/\"/g, "");
          self.ReleaseDocuments[id].selectedFile = null;
          self.ReleaseDocuments[id].selectedFileName = null;
          self.ReleaseDocuments[id].display_file_name = null;
          self.ReleaseDocuments[id].ReleaseDocumentsRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.ReleaseDocumentFile);
        // console.log("id: " + id);
        reader.onload = function () {
          self.ReleaseDocuments[id].selectedFile = reader.result;
          self.ReleaseDocuments[id].selectedFileName = e.target.files[0].name;
          self.ReleaseDocuments[id].display_file_name = e.target.files[0].name; //to show filename in file input
          self.ReleaseDocuments[id].errors = "";
          self.ReleaseDocuments[id].ReleaseDocumentsRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadReleaseDocuments() close

    ////this method handles remove doc attachment
    removeReleaseDocumentsDoc(id) {
      this.ReleaseDocumentFile = null;
      this.ReleaseDocuments[id].selectedFile = null;
      this.ReleaseDocuments[id].selectedFileName = null;
      this.ReleaseDocuments[id].display_file_name = null;
      this.ReleaseDocuments[id].ReleaseDocumentsRemoveDisabled = true;
    },

    // this function will be called to get list of borrowers
    getBorrowers() {
      const path = "borrower-master";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          //get Approved borrowers
          this.borrowers = res.data.borrowers;
          // .filter((bor) => {
          //   if (bor.borrower_id === this.selectedBorrowerName) {
          //     this.selectedBorrowerName = bor.borrower_name;
          //   }
          // });
          // this.ContainerList.filter((con) => {
          //   con["borrowerName"] = this.selectedBorrowerName;
          // });
          if (res.data.status == "success") {
            console.log("get borrowers is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    getRelease() {
      //this function is called on page load and created events. it will show details on Release with Releaseid equal to this.id
      const path = "single-release-summary-overview/" + this.id;
      // Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // Utils.stop_loading();
          if (res.data.status == "success") {
            // console.log(res.data);
            this.ContainerList = res.data.release.release_info_details;
            this.containerId = this.ContainerList[0].id;
            this.selectedUtilId = this.ContainerList[0].utilId;
            this.selectedReleaseId = this.ContainerList[0].releaseId;
            this.selectedOfftakerId = this.ContainerList[0].offtakerId;
            this.offtakers.filter((off) => {
              if (off.id === this.ContainerList[0].offtakerId) {
                this.selectedOfftakerName = off.offtaker_name;
              }
            });
            this.borrowers.filter((bor) => {
              if (bor.borrower_id === this.ContainerList[0].borrowerName) {
                this.selectedBorrowerName = bor.borrower_name;
              }
            });
            if (this.selectedBorrowerName === null) {
              this.$router.go();
            }
            this.release_info_id = this.ContainerList[0].id;
            this.selectedReleaseDate = new Date(
              this.ContainerList[0].releaseDate
            );
            this.ContainerList.filter((con) => {
              con.releasedQty = con.releasedQty.replaceAll(",", "");
              con.grossWeight = con.grossWeight.replaceAll(",", "");
              con.netWeight = con.netWeight.replaceAll(",", "");
              con.tareWeight = con.tareWeight.replaceAll(",", "");
              con.bags = con.bags.replaceAll(",", "");
              con.borrowerName = this.selectedBorrowerName;
              con.clientId = JSON.stringify(this.$store.state.client_id);
              con.userId = this.$store.getters.getuserId;
              con.moduleID = 10;
              con.tabID = 27;
            });
            this.totalGrossWeight =
              res.data.release.release_info_summary_details[0].total_gross_weight.replaceAll(
                ",",
                ""
              );
            this.totalNetWeight =
              res.data.release.release_info_summary_details[0].net_weight_loaded.replaceAll(
                ",",
                ""
              );
            this.quantityLimit = Number(this.totalNetWeight);
            this.containerRemNetWeight = this.quantityLimit;
            this.containerRemReleasedQty = this.quantityLimit;
            this.totalReleasedQty =
              res.data.release.release_info_summary_details[0].total_released_quantity.replaceAll(
                ",",
                ""
              );
            this.totalBags =
              res.data.release.release_info_summary_details[0].total_bags.replaceAll(
                ",",
                ""
              );
            this.bagLimit = Number(this.totalBags);
            this.containerRemBags = this.bagLimit;
            this.totalUnit =
              res.data.release.release_info_summary_details[0].unit;
            this.ReleaseDocuments =
              res.data.release.release_info_documents_details;
            this.ReleaseDocumentsLength =
              res.data.release.release_info_documents_details.length;
            if (Object.keys(this.ReleaseDocuments).length == 0) {
              this.ReleaseDocuments = [];
              this.ReleaseDocumentsAddMoreDisabled = false;
              // this.ReleaseDocuments.push({
              //   id: null,
              //   ReleaseInfoDocName: null,
              //   ReleaseInfoDocumentsDate: new Date(),
              //   document_status: null,
              //   display_file_name: null,
              //   document_path: null,
              //   document_id: null,
              //   errors: "",
              //   release_document_id: null,
              // });
            } else {
              this.ReleaseDocumentFile = "file";
              this.ReleaseDocuments.map((doc) => (doc.edited = false));
            }
            console.log("get Release details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          // Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getRelease close

    //this function is called to get list of benchmarks
    getUnitTypes() {
      const path = "unit_types";
      axios
        .get(path)
        .then((res) => {
          this.UnitTypes = res.data.UnitTypes;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getBuyers() {
      const path = "buyer";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.offtakers = res.data.Buyer;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of release info documents
    getReleaseInfoDocuments() {
      const path = "release-info-document";
      axios
        .get(path)
        .then((res) => {
          this.ReleaseDocs = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getReleaseInfoDocuments() close
  },

  mounted() {
    this.getRelease();
    this.getUnitTypes();
  },

  created() {
    this.id = this.$route.params.id;
    this.getBorrowers();
    this.getBuyers();
    this.getReleaseInfoDocuments();
  },
};
</script>

<style scoped>
.error {
  color: red;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -70px;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>
