<!-- This is add Warehouse page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Edit Warehouse Details
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <span style="font-size: 12px; color: red"
            >Note: Only single operation can be handled at a time (Edit or
            Delete)</span
          >
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Borrower Name</label
                    >
                    <select
                      id="selectedBorrowerName"
                      class="form-control"
                      name="selectedBorrowerName"
                      v-model="selectedBorrowerName"
                      disabled
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedBorrowerName.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="bor in borrowers"
                        :value="bor.borrower_name"
                        :key="bor.borrower_id"
                      >
                        {{ bor.borrower_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedBorrowerName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedBorrowerName.required"
                        >Borrower Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Owner Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      id="ownerName"
                      v-model="ownerName"
                      :class="{
                        'is-invalid': isSubmitted && $v.ownerName.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.ownerName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.ownerName.required"
                        >Owner Name is required</span
                      >
                      <span v-if="!$v.ownerName.maxLength"
                        >Max length of 50 characters exceeded<br
                      /></span>
                      <span v-if="!$v.ownerName.isAlpha"
                        >Owner Name should be alphabets only</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Address</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      id="address"
                      v-model="address"
                      :class="{
                        'is-invalid': isSubmitted && $v.address.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.address.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.address.required"
                        >Address is required</span
                      >
                      <span v-if="!$v.address.maxLength"
                        >Max length of 250 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Country</label
                    >
                    <select
                      id="selectedCountry"
                      class="form-control"
                      name="selectedCountry"
                      v-model="selectedCountry"
                      @change="getStatesByCountry(true)"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedCountry.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="country in countries"
                        :value="country.id"
                        :key="country.id"
                      >
                        {{ country.country_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCountry.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCountry.required"
                        >Country is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label
                      class="required-field"
                      >State</label>
                    <select
                      id="selectedState"
                      class="form-control"
                      name="selectedState"
                      v-model="selectedState"
                      @change="getCitiesByState(true)"
                      :class="{
                        'is-invalid':
                        isSubmitted && $v.selectedState.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option v-for="state in states" :key='state.id' :value="state.id">
                        {{ state.state_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedState.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedState.required"
                        >State is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label class="required-field">City</label>
                    <select
                      id="selectedCity"
                      class="form-control"
                      name="selectedCity"
                      v-model="selectedCity"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedCity.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="city in cities"
                        :value="city.id"
                        :key="city.id"
                      >
                        {{ city.city_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCity.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCity.required"
                        >City is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Registration Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="regDate"
                      id="regDate"
                      placeholder="Select Date"
                      v-model="regDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      disabled
                      :disabledDates="{ from: new Date() }"
                      :calendar-button="true"
                      :class="{
                        'is-invalid': isSubmitted && $v.regDate.$error,
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.regDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.regDate.required"
                        >Registration Date is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Site Inspection Start Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="siteInspStartDate"
                      id="siteInspStartDate"
                      placeholder="Select Date"
                      v-model="siteInspStartDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      disabled
                      :calendar-button="true"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.siteInspStartDate.$error,
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.siteInspStartDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.siteInspStartDate.required"
                        >Site Inspection Start Date is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label class="required-field">Frequency</label>
                    <select
                      id="selectedFrequency"
                      class="form-control"
                      name="selectedFrequency"
                      v-model="selectedFrequency"
                      disabled
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedFrequency.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="freq in frequency"
                        :value="freq.id"
                        :key="freq.id"
                      >
                        {{ freq.interest_frequency_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedFrequency.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedFrequency.required"
                        >Frequency is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-4 col-6">
                  <label>Site Inspection Permissions</label>
                  <multiselect
                    v-model="selectedSiteInspCM"
                    placeholder="--- Select ---"
                    label="company_name"
                    track-by="collateral_manager_master_id"
                    :options="mappedCollaterals"
                    :multiple="true"
                    :limit="2"
                    ><span slot="noResult"
                      >No CM Company found with given input.</span
                    ></multiselect
                  >
                  <!-- <div
                    v-if="isSubmitted && $v.selectedSiteInspCM.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.selectedSiteInspCM.required"
                      >At least select one Collateral Manager Company Name</span
                    >
                  </div> -->
                  <span
                    v-if="
                      CollateralDetails.length === selectedSiteInspCM.length
                    "
                    style="font-size: 12px; color: red"
                    >Disclaimer - Default all Collateral Managers are mapped for
                    Site Inspection. Kindly click on the dropdown to unselect
                    the Collateral Managers.</span
                  >
                </div>

                <div class="col-md-3 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="contact" class="required-field"
                      >Contact No</label
                    ><br />
                    <select
                      id="contactCode"
                      class="contact_code"
                      name="contactCode"
                      v-model="contactCode"
                      :class="{
                        'is-invalid': isSubmitted && $v.contactCode.$error,
                      }"
                    >
                      <option :value="null">--Select--</option>
                      <option
                        v-for="code in countryCodes"
                        :key="code.id"
                        :value="code.id"
                      >
                        {{ code.country_code }}
                      </option>
                    </select>
                    <input
                      class="contact"
                      id="contact"
                      placeholder
                      v-model="contact"
                      @keypress="isNum($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.contact.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.contactCode.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.contactCode.required"
                        >Contact Number Code is required</span
                      >
                    </div>
                    <div
                      v-if="isSubmitted && $v.contact.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.contact.required"
                        >Contact Number is required</span
                      >
                      <span v-if="!$v.contact.minLength"
                        >Min length of 10 characters required</span
                      >
                      <span v-if="!$v.contact.maxLength"
                        >Max length of 13 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Collateral Manager Details</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addCollateralDetail()"
                            :disabled="CollateralDetailAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(col, index) in CollateralDetails"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label class="required-field">CM Company Name</label>
                        <select
                          id="collateral_manager_master_id"
                          class="form-control"
                          name="collateral_manager_master_id"
                          v-model="col.collateral_manager_master_id"
                          @change="onCollateralCompanySelect(index,col.id)"
                          :disabled="col.id > 0"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.CollateralDetails.$each[index]
                                .collateral_manager_master_id.$error,
                          }"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="comp in collaterals"
                            :value="comp.collateral_manager_id"
                            :key="comp.collateral_manager_id"
                          >
                            {{ comp.company_name }}
                          </option>
                        </select>
                        <div
                          v-if="
                            isSubmitted &&
                            $v.CollateralDetails.$each[index]
                              .collateral_manager_master_id.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.CollateralDetails.$each[index]
                                .collateral_manager_master_id.required
                            "
                            >CM Company Name is required</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1" class="required-field"
                          >Upload Document</label
                        >
                        <div class="custom-file">
                          <button
                            type="button"
                            class="btn btn-primary"
                            id="customFile"
                            name="filename"
                            v-on:click="
                              showCMDocModal(
                                col.collateral_manager_master_id,
                                index
                              )
                            "
                            :disabled="uploadDisabled"
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          id="collateralDelete"
                          v-on:click="deleteCollateralDetail(index, col.id)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onCollateralDetailSaveInfo()"
                        :disabled="isDisabledCollateralDetailSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addWarehouseDoc()"
                            :disabled="WarehouseDocumentsAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(doc, index) in WarehouseDocuments"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label>Select Document</label>
                        <select
                          id="WarehouseDocName"
                          class="form-control"
                          name="WarehouseDocName"
                          v-model="doc.WarehouseDocName"
                          @change="addEditedFlag(index, 'document')"
                          :disabled="index < WarehouseDocumentsLength"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="document in WarehouseDocs"
                            :key="document.id"
                          >
                            {{ document.document_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @mouseenter="
                          mouseenter(doc.WarehouseDocName, index, null)
                        "
                        @mouseleave="mouseleave(index, null)"
                      >
                        <label for="exampleInputEmail1">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            :disabled="doc.WarehouseDocName === null"
                            v-on:change="
                              handleFileUploadWarehouseDocuments(
                                index,
                                $event,
                                doc.WarehouseDocName
                              ) & addEditedFlag(index, 'document')
                            "
                          />
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ doc.display_file_name }}</b>
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.errors">
                              {{ doc.errors }}
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.docName">
                              {{ doc.docName }}
                            </p>
                          </small>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @click="
                          docValidation(
                            doc.display_file_name,
                            'issueDate',
                            null
                          )
                        "
                      >
                        <label for="exampleInputEmail1">Issue Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="IssueDate"
                          id="IssueDate"
                          placeholder="Select Date"
                          v-model="doc.IssueDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabledDates="{ from: new Date() }"
                          :calendar-button="true"
                          @input="addEditedFlag(index, 'document')"
                          :class="{
                            'is-invalid':
                              docIssueDateValidation &&
                              doc.display_file_name === null,
                          }"
                        ></datepicker>
                        <div
                          v-if="doc.display_file_name === null"
                          class="invalid-feedback"
                        >
                          <span v-if="docIssueDateValidation"
                            >Please upload the Document</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @click="
                          docValidation(
                            doc.display_file_name,
                            'expiryDate',
                            null
                          )
                        "
                      >
                        <label for="exampleInputEmail1">Expiry Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="ExpiryDate"
                          id="ExpiryDate"
                          placeholder="Select Date"
                          v-model="doc.ExpiryDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabledDates="{
                            to: new Date(doc.IssueDate),
                          }"
                          :calendar-button="true"
                          @input="addEditedFlag(index, 'document')"
                          :class="{
                            'is-invalid':
                              docExpiryDateValidation &&
                              doc.display_file_name === null,
                          }"
                        ></datepicker>
                        <div
                          v-if="doc.display_file_name === null"
                          class="invalid-feedback"
                        >
                          <span v-if="docExpiryDateValidation"
                            >Please upload the Document</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Comments</label>
                        <input
                          type="text"
                          id="comments"
                          class="form-control"
                          placeholder
                          v-model="doc.comments"
                          @input="addEditedFlag(index, 'document')"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.WarehouseDocuments.$each[index].comments
                                .$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.WarehouseDocuments.$each[index].comments.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.WarehouseDocuments.$each[index].comments
                                .maxLength
                            "
                            >Max length of 100 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onWarehouseDocumentSaveInfo()"
                        :disabled="isDisabledWarehouseDocumentSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div
          class="container-fluid ot_tb pt-3"
          v-if="loggedInUserGroupId == 'Admin'"
        >
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Administrative Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addWarehouseAdminDoc()"
                            :disabled="WarehouseAdminDocumentsAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(doc, index) in WarehouseAdminDocuments"
                    :key="index"
                  >
                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label>Select Document</label>
                        <select
                          id="WarehouseAdminDocName"
                          class="form-control"
                          name="WarehouseAdminDocName"
                          v-model="doc.WarehouseAdminDocName"
                          @change="addEditedFlag(index, 'admin_document')"
                        >
                          <option :value="null">--- Select ---</option>
                          <option
                            v-for="document in WarehouseAdminDocs"
                            :key="document.id"
                          >
                            {{ document.document_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @mouseenter="
                          mouseenter(doc.WarehouseAdminDocName, index, 'admin')
                        "
                        @mouseleave="mouseleave(index, 'admin')"
                      >
                        <label for="exampleInputEmail1">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                            :disabled="doc.WarehouseAdminDocName === null"
                            v-on:change="
                              handleFileUploadWarehouseAdminDocuments(
                                index,
                                $event,
                                doc.WarehouseAdminDocName
                              ) & addEditedFlag(index, 'admin_document')
                            "
                          />
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ doc.display_file_name }}</b>
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.errors">
                              {{ doc.errors }}
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.docName">
                              {{ doc.docName }}
                            </p>
                          </small>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @click="
                          docValidation(
                            doc.display_file_name,
                            'issueDate',
                            'admin'
                          )
                        "
                      >
                        <label for="exampleInputEmail1">Issue Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="IssueDate"
                          id="IssueDate"
                          placeholder="Select Date"
                          v-model="doc.IssueDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabled="doc.display_file_name === null"
                          :disabledDates="{ from: new Date() }"
                          :calendar-button="true"
                          @input="addEditedFlag(index, 'admin_document')"
                          :class="{
                            'is-invalid':
                              adminDocIssueDateValidation &&
                              doc.display_file_name === null,
                          }"
                        ></datepicker>
                        <div
                          v-if="doc.display_file_name === null"
                          class="invalid-feedback"
                        >
                          <span v-if="adminDocIssueDateValidation"
                            >Please upload the Document</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @click="
                          docValidation(
                            doc.display_file_name,
                            'expiryDate',
                            'admin'
                          )
                        "
                      >
                        <label for="exampleInputEmail1">Expiry Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="ExpiryDate"
                          id="ExpiryDate"
                          placeholder="Select Date"
                          v-model="doc.ExpiryDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabled="doc.display_file_name === null"
                          :disabledDates="{
                            to: new Date(doc.IssueDate),
                          }"
                          :calendar-button="true"
                          @input="addEditedFlag(index, 'admin_document')"
                          :class="{
                            'is-invalid':
                              adminDocExpiryDateValidation &&
                              doc.display_file_name === null,
                          }"
                        ></datepicker>
                        <div
                          v-if="doc.display_file_name === null"
                          class="invalid-feedback"
                        >
                          <span v-if="adminDocExpiryDateValidation"
                            >Please upload the Document</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Comments</label>
                        <input
                          id="comments"
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="doc.comments"
                          @input="addEditedFlag(index, 'admin_document')"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.WarehouseAdminDocuments.$each[index].comments
                                .$error,
                          }"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.WarehouseAdminDocuments.$each[index].comments
                              .$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.WarehouseAdminDocuments.$each[index].comments
                                .maxLength
                            "
                            >Max length of 100 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onWarehouseAdminDocumentSaveInfo()"
                        :disabled="isDisabledWarehouseAdminDocumentSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    id="submit"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a @click="$router.go(-1)" style="cursor: pointer">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
    <!-- ./modal start -->
    <div class="modal fade" id="view_doc">
      <div class="modal-dialog modal-lg">
        <div class="modal-content" style="width: 125%">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              Add Document -
              <b>{{ selectedCMCompanyName }}</b>
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table" v-if="CollateralDetails.length != 0">
                    <thead>
                      <tr>
                        <th class="required-field" style="width: 17%">
                          Document Name
                        </th>
                        <th class="required-field">Document</th>
                        <th>Issue Date</th>
                        <th>Expiry Date</th>
                        <th>Comments</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(col, index) in CollateralDetails[
                          selectedCMCompany
                        ].collateralDocs"
                        :key="col.id"
                      >
                        <td>
                          <select
                            id="selectedCollateralDocument"
                            class="form-control"
                            name="selectedCollateralDocument"
                            v-model="col.collateralDocument"
                            @change="
                              addEditedFlag(
                                index,
                                'collateral',
                                selectedCMCompany
                              ) & checkSameDoc(col.collateralDocument, index)
                            "
                            :disabled="
                              index !==
                              CollateralDetails[selectedCMCompany]
                                .collateralDocs.length -
                                1
                            "
                            :class="{
                              'is-invalid': col.error,
                            }"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="document in WarehouseCollateralDocs"
                              :key="document.id"
                              :value="document.id"
                            >
                              {{ document.document_name }}
                            </option>
                          </select>
                          <div v-if="col.error" class="invalid-feedback">
                            <span>Document Name already exists</span>
                          </div>
                        </td>
                        <td>
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="file"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              :disabled="
                                col.collateralDocument === null || col.error
                              "
                              :class="{
                                'is-invalid':
                                  col.errors || col.collateralDocument === null,
                              }"
                              v-on:change="
                                handleFileUploadCollateralDoc(
                                  $event,
                                  selectedCMCompany,
                                  index,
                                  col.collateralDocument
                                ) &
                                  addEditedFlag(
                                    index,
                                    'collateral',
                                    selectedCMCompany
                                  )
                              "
                            />
                            <small>
                              <p style="background-color: powderblue">
                                {{ col.selectedFileName }}
                              </p>
                            </small>
                            <div class="invalid-feedback" v-if="col.errors">
                              <span>
                                {{ col.errors }}
                              </span>
                            </div>
                            <div
                              class="invalid-feedback"
                              v-if="col.collateralDocument === null"
                            >
                              <span> Document Name is not selected </span>
                            </div>
                            <label class="custom-file-label" for="file"
                              >Choose File</label
                            >
                          </div>
                        </td>
                        <td>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="IssueDate"
                            id="IssueDate"
                            placeholder="Select Date"
                            v-model="col.IssueDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabled="
                              index !==
                              CollateralDetails[selectedCMCompany]
                                .collateralDocs.length -
                                1
                            "
                            :disabledDates="{
                              from: new Date(),
                            }"
                          ></datepicker>
                        </td>
                        <td>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            name="ExpiryDate"
                            id="ExpiryDate"
                            placeholder="Select Date"
                            v-model="col.ExpiryDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabled="
                              index !==
                              CollateralDetails[selectedCMCompany]
                                .collateralDocs.length -
                                1
                            "
                            :disabledDates="{
                              to: new Date(col.IssueDate),
                            }"
                          ></datepicker>
                        </td>
                        <td>
                          <input
                            id="comments"
                            type="text"
                            class="form-control"
                            v-model="col.comments"
                            :class="{
                              'is-invalid':
                                $v.CollateralDetails.$each[selectedCMCompany]
                                  .collateralDocs.$each[index].comments.$error,
                            }"
                          />
                          <div
                            v-if="
                              $v.CollateralDetails.$each[selectedCMCompany]
                                .collateralDocs.$each[index].comments.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.CollateralDetails.$each[selectedCMCompany]
                                  .collateralDocs.$each[index].comments
                                  .maxLength
                              "
                              >Max length of 100 characters exceeded</span
                            >
                          </div>
                        </td>
                        <td
                          v-on:click="
                            deleteCollateralDoc(
                              selectedCMCompany,
                              index,
                              col.warehouse_document_id
                            )
                          "
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-primary sh"
                    v-on:click="addCollateralDocument(selectedCMCompany)"
                  >
                    Add
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="closeCMDocModal()"
                  >
                    Save
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {
  required,
  minLength,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: null,
      cities: [],
      states: [],
      countries: [],
      frequency: [],
      borrowers: [],
      collaterals: [],
      countryCodes: [],
      selectedSiteInspCM: [],
      prevselectedSiteInspCM: [],
      mappedCollaterals: [],
      //variables for Warehouse information
      selectedBorrowerName: null,
      ownerName: null,
      address: null,
      selectedCountry: null,
      contact: null,
      contactCode: null,
      selectedState: null,
      selectedCity: null,
      regDate: new Date(), //default current date
      siteInspStartDate: new Date(),
      selectedFrequency: null,
      selectedCMCompanyName: null,
      selectedCMCompany: 0,
      //list for Collateral Manager Details
      CollateralDetails: [],
      WarehouseCollateralDocs: [],
      CollateralDetailSaveInfoDisabled: true,
      CollateralDetailAddMoreDisabled: true,
      CollateralFile: null,
      //list for Warehouse Documents
      WarehouseDelete: [],
      WarehouseDocuments: [],
      WarehouseDocumentsLength: null,
      WarehouseDocs: [],
      WarehouseDocumentFile: null,
      // WarehouseDocumentsButtonDisabled: true,
      WarehouseDocumentsDisabled: true,
      WarehouseDocumentSaveInfoDisabled: true, //save info button is disabled
      WarehouseDocumentsAddMoreDisabled: true,
      //list for Warehouse Admin Documents
      WarehouseAdminDocuments: [],
      WarehouseAdminDocumentsLength: null,
      WarehouseAdminDocs: [],
      WarehouseAdminDocumentFile: null,
      // WarehouseAdminDocumentsButtonDisabled: true,
      WarehouseAdminDocumentsDisabled: true,
      WarehouseAdminDocumentSaveInfoDisabled: true, //save info button is disabled
      WarehouseAdminDocumentsAddMoreDisabled: true,
      msg: [],
      error: "",
      isSubmitted: false,
      onSubmitDisabled: false,
      docIssueDateValidation: false,
      docExpiryDateValidation: false,
      adminDocIssueDateValidation: false,
      adminDocExpiryDateValidation: false,
      uploadDisabled: true,
    };
  },
  validations: {
    selectedBorrowerName: {
      required,
    },
    ownerName: {
      required,
      isAlpha: helpers.regex("isAlpha", /^[a-zA-Z ]*$/),
      maxLength: maxLength(50),
    },
    address: {
      required,
      maxLength: maxLength(250),
    },
    selectedCountry: {
      required,
    },
    selectedState: {
      required,
    },
    selectedCity: {
      required,
    },
    contact: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(13),
    },
    contactCode: {
      required,
    },
    regDate: {
      required,
    },
    siteInspStartDate: {
      required,
    },
    selectedFrequency: {
      required,
    },
    // selectedSiteInspCM: {
    //   required: function () {
    //     if (this.selectedSiteInspCM.length === 0) return false;
    //     else return true;
    //   },
    // },
    CollateralDetails: {
      $each: {
        collateral_manager_master_id: {
          required,
        },
        collateralDocs: {
          $each: {
            comments: {
              maxLength: maxLength(100),
            },
          },
        },
      },
    },
    WarehouseDocuments: {
      $each: {
        comments: {
          maxLength: maxLength(100),
        },
      },
    },
    WarehouseAdminDocuments: {
      $each: {
        comments: {
          maxLength: maxLength(100),
        },
      },
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },

    //this is to computed disabled property of Collateral Manager Detail Save Info button.
    isDisabledCollateralDetailSaveInfo: function () {
      let checkEmptyCollateralCompany = this.CollateralDetails.filter(
        (line) => line.collateral_manager_master_id === null
      );
      //if the line is empty then disable save info button
      if (
        this.CollateralDetails.length === 0 &&
        this.CollateralDetailSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enable
      if (
        checkEmptyCollateralCompany.length >= 1 &&
        this.CollateralDetails.length > 0
      ) {
        this.uploadDisabled = true;
        return true;
      } else {
        this.uploadDisabled = false;
        return false;
      }
    }, //isDisabledCollateralDetailSaveInfo() close

    //this is to computed disabled property of WarehouseDocuments Save Info button.
    isDisabledWarehouseDocumentSaveInfo: function () {
      let checkEmptyWarehouseDocumentsDocName = this.WarehouseDocuments.filter(
        (line) => line.WarehouseDocName === null
      );
      //if the line is empty then disable save info button
      if (
        this.WarehouseDocuments.length === 0 &&
        this.WarehouseDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyWarehouseDocumentsDocName.length >= 1 &&
        this.WarehouseDocuments.length > 0
      ) {
        this.WarehouseDocumentsDisabled = true;
      } else {
        this.WarehouseDocumentsDisabled = false;
      }
      //WarehouseDocumentsDisabled variable is to check whther all input fields except file upload are filled. WarehouseDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.WarehouseDocumentsDisabled === true ||
        this.WarehouseDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledWarehouseDocumentSaveInfo() close

    //this is to computed disabled property of WarehouseAdminDocuments Save Info button.
    isDisabledWarehouseAdminDocumentSaveInfo: function () {
      let checkEmptyWarehouseAdminDocumentsDocName =
        this.WarehouseAdminDocuments.filter(
          (line) => line.WarehouseAdminDocName === null
        );
      //if the line is empty then disable save info button
      if (
        this.WarehouseAdminDocuments.length === 0 &&
        this.WarehouseAdminDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyWarehouseAdminDocumentsDocName.length >= 1 &&
        this.WarehouseAdminDocuments.length > 0
      ) {
        this.WarehouseAdminDocumentsDisabled = true;
      } else {
        this.WarehouseAdminDocumentsDisabled = false;
      }
      //WarehouseAdminDocumentsDisabled variable is to check whther all input fields except file upload are filled. WarehouseAdminDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.WarehouseAdminDocumentsDisabled === true ||
        this.WarehouseAdminDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledWarehouseAdminDocumentSaveInfo() close
  },
  components: {
    Datepicker,
    Multiselect: window.VueMultiselect.default,
  },
  methods: {
    showCMDocModal(collateral_manager_id, id) {
      //this function opens the director document modal. id: director current id, name: director current name passed.
      this.collaterals.filter((col) => {
        if (col.collateral_manager_id == collateral_manager_id)
          this.selectedCMCompanyName = col.company_name;
      });
      this.selectedCMCompany = id;
      $("#view_doc").modal("show");
    }, //showCMDocModal() close

    closeCMDocModal() {
      this.$v.$touch();
      if (this.$v.CollateralDetails.$invalid) {
        return;
      }
      //this function closes the director document modal
      $("#view_doc").modal("hide");
    },
    addEditedFlag(index, type) {
      if (type === "admin_document") {
        if (index < this.WarehouseAdminDocumentsLength) {
          this.WarehouseAdminDocuments[index]["edited"] = true;
        }
      } else if (type === "document") {
        if (index < this.WarehouseDocumentsLength) {
          this.WarehouseDocuments[index]["edited"] = true;
        }
      } else if (
        type === "collateral" &&
        index < this.CollateralDetails[this.selectedCMCompany].length
      ) {
        this.CollateralDetails[this.selectedCMCompany].collateralDocs[index][
          "edited"
        ] = "true";
      }
    },
    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    //this method will make disabled property as false for Collateral Detail Save Info
    onCollateralDetailSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.CollateralDetailSaveInfoDisabled = false;
      this.CollateralDetailAddMoreDisabled = false;
    },
    //this method will make disabled property as false for Warehouse Document Save Info
    onWarehouseDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.WarehouseDocumentSaveInfoDisabled = false;
      this.WarehouseDocumentsAddMoreDisabled = false;
    },
    //this method will make disabled property as false for Warehouse Document Admin Save Info
    onWarehouseAdminDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.WarehouseAdminDocumentSaveInfoDisabled = false;
      this.WarehouseAdminDocumentsAddMoreDisabled = false;
    },
    //this function deletes CollateralDetail record
    deleteCollateralDetail(index, id) {
      if (id) {
        this.WarehouseDelete.push({
          id: id,
          type: "CollateralDetail",
          clientId: this.$store.state.client_id,
          userId: this.$store.getters.getuserId,
          moduleId: 9,
          tabId: 26,
        });
      }
      this.CollateralDetails.splice(index, 1);
      if (
        typeof this.CollateralDetails !== "undefined" &&
        this.CollateralDetails.length == 0
      ) {
        this.CollateralDetailAddMoreDisabled = false;
        this.CollateralDetailSaveInfoDisabled = true;
      }
    },
    onCollateralCompanySelect(index,id){
      console.log("onCollateralCompanySelect")
      let cm_count = this.CollateralDetails.filter(
        (col) => col.collateral_manager_master_id == this.CollateralDetails[index].collateral_manager_master_id
        ).length;
      // delete if existing loan is found
      if (cm_count > 1) {
        this.deleteCollateralDetail(index,id);
        Utils.make_alert("warning", "CM Company Name already exists");
        return;
      }
    },
    //this function deletes WarehouseDocument record
    deleteWarehouseDocuments(index) {
      this.WarehouseDocumentFile = this.WarehouseDocumentFile
        ? this.WarehouseDocumentFile
        : "file deleted";
      this.WarehouseDocuments.splice(index, 1);
      if (
        typeof this.WarehouseDocuments !== "undefined" &&
        this.WarehouseDocuments.length == 0
      ) {
        this.WarehouseDocumentAddMoreDisabled = false;
        this.WarehouseDocumentSaveInfoDisabled = true;
      }
    },
    //this function deletes WarehouseAdminDocument record
    deleteWarehouseAdminDocuments(index) {
      this.WarehouseAdminDocumentFile = this.WarehouseAdminDocumentFile
        ? this.WarehouseAdminDocumentFile
        : "file deleted";
      this.WarehouseAdminDocuments.splice(index, 1);
      if (
        typeof this.WarehouseAdminDocuments !== "undefined" &&
        this.WarehouseAdminDocuments.length == 0
      ) {
        this.WarehouseAdminDocumentAddMoreDisabled = false;
        this.WarehouseAdminDocumentSaveInfoDisabled = true;
      }
    },
    deleteCollateralDoc(selectedCMCompany, index, id) {
      if (id) {
        let deleteId = id;
        this.WarehouseDelete.push({
          id: deleteId,
          type: "CollateralDocument",
          moduleId: 2,
          tabId: 4,
          clientId: this.$store.state.client_id,
          userId: this.$store.getters.getuserId,
        });
      }
      this.CollateralDetails[selectedCMCompany].collateralDocs.splice(index, 1);
    },
    getWarehouse() {
      //this function is called on page load and created events. it will show details on Warehouse with Warehouseid equal to this.id
      const path = "single-warehouse-summary-overview-active/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          if (res.data.status == "success") {
            let warehouse = res.data.warehouse.warehouse_active_details[0];
            this.selectedBorrowerName = warehouse.borrower_name;
            this.ownerName = warehouse.owner_name;
            this.contact = warehouse.contact_number;
            this.contactCode = warehouse.contact_number_code;
            this.address = warehouse.address;
            this.regDate = warehouse.registration_date;
            this.siteInspStartDate = warehouse.site_inspection_start_date;
            this.selectedCountry = warehouse.country_id;
            this.selectedState = warehouse.state_id;
            this.selectedCity = warehouse.city_id;
            this.getStatesByCountry(false);
            this.getCitiesByState(false);
            this.selectedFrequency = warehouse.frequency;
            if (
              res.data.warehouse.warehouse_cm_mapping_siteinsp_active.length
            ) {
              this.selectedSiteInspCM =
                res.data.warehouse.warehouse_cm_mapping_siteinsp_active;
              this.prevselectedSiteInspCM =
                res.data.warehouse.warehouse_cm_mapping_siteinsp_active;
            }
            let adminCMDocuments = [];
            if (
              res.data.warehouse.warehouse_admin_cm_documents_details_active
                .length
            ) {
              this.WarehouseDocumentFile = "file";
              adminCMDocuments =
                res.data.warehouse.warehouse_admin_cm_documents_details_active;
            }
            if (res.data.warehouse.warehouse_cm_mapping_active.length) {
              this.CollateralDetails =
                res.data.warehouse.warehouse_cm_mapping_active;
              this.mappedCollaterals =
                res.data.warehouse.warehouse_cm_mapping_active.map(
                  ({ collateralDocs, ...rest }) => ({ ...rest })
                );
              this.CollateralDetails.map((col) => {
                adminCMDocuments.map((doc) => {
                  if (
                    col.collateral_manager_master_id ===
                    doc.collateral_manager_id
                  ) {
                    doc["edited"] = false;
                    col.collateralDocs.push(doc);
                  }
                });
              });
            } else {
              this.CollateralDetailAddMoreDisabled = false;
            }
            if (res.data.warehouse.warehouse_documents_active_details.length) {
              this.WarehouseDocumentsLength =
                res.data.warehouse.warehouse_documents_active_details.length;
              this.WarehouseDocuments =
                res.data.warehouse.warehouse_documents_active_details;
              this.WarehouseDocumentFile = "file";
              this.WarehouseDocuments.map((doc) => (doc.edited = false));
            } else {
              this.WarehouseDocumentsAddMoreDisabled = false;
            }
            if (
              res.data.warehouse.warehouse_admin_documents_active_details.length
            ) {
              this.WarehouseAdminDocumentsLength =
                res.data.warehouse.warehouse_admin_documents_active_details.length;
              this.WarehouseAdminDocuments =
                res.data.warehouse.warehouse_admin_documents_active_details;
              this.WarehouseAdminDocumentFile = "file";
              this.WarehouseAdminDocuments.map((doc) => (doc.edited = false));
            } else {
              this.WarehouseAdminDocumentsAddMoreDisabled = false;
            }
            console.log("get Warehouse details is successful.");
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
          Utils.stop_loading();
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getWarehouse close

    // issue & expiry date doc validation
    docValidation(doc, dateType, docType) {
      if (docType === "admin") {
        if (dateType === "issueDate") {
          this.adminDocIssueDateValidation = doc === null;
        } else if (dateType === "expiryDate") {
          this.adminDocExpiryDateValidation = doc === null;
        }
      } else {
        if (dateType === "issueDate") {
          this.docIssueDateValidation = doc === null;
        } else if (dateType === "expiryDate") {
          this.docExpiryDateValidation = doc === null;
        }
      }
    },

    //this method will submit the Warehouse form in backend.
    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.regDate != null) {
        this.regDate = moment(this.regDate).format("MM-DD-YYYY");
      }
      if (this.siteInspStartDate != null) {
        this.siteInspStartDate = moment(this.siteInspStartDate).format(
          "MM-DD-YYYY"
        );
      }

      this.CollateralDetails.map((col) => {
        col.collateralDocs.map((doc) => {
          if (doc.IssueDate !== null) {
            doc.IssueDate = moment(doc.IssueDate).format("MM-DD-YYYY");
          }
          if (doc.ExpiryDate !== null) {
            doc.ExpiryDate = moment(doc.ExpiryDate).format("MM-DD-YYYY");
          }
        });
      });

      this.CollateralDetails.forEach(item => {
          item.collateralDocs = item.collateralDocs.filter(doc => 
              doc.selectedFileName !== null && doc.collateralDocument !== null
          );
      });
      console.log("this.CollateralDetails",this.CollateralDetails)

      this.WarehouseDocuments.map((doc, index) => {
        if (doc.WarehouseDocName === null) {
          this.WarehouseDocuments.splice(index, 1);
        } else {
          if (doc.IssueDate !== null) {
            doc.IssueDate = moment(doc.IssueDate).format("MM-DD-YYYY");
          }
          if (doc.ExpiryDate !== null) {
            doc.ExpiryDate = moment(doc.ExpiryDate).format("MM-DD-YYYY");
          }
        }
      });

      this.WarehouseAdminDocuments.map((doc, index) => {
        if (doc.WarehouseAdminDocName === null) {
          this.WarehouseAdminDocuments.splice(index, 1);
        } else {
          if (doc.IssueDate !== null) {
            doc.IssueDate = moment(doc.IssueDate).format("MM-DD-YYYY");
          }
          if (doc.ExpiryDate !== null) {
            doc.ExpiryDate = moment(doc.ExpiryDate).format("MM-DD-YYYY");
          }
        }
      });

      let removedSiteInspCM = this.prevselectedSiteInspCM.filter(
        ({ collateral_manager_master_id: id1 }) =>
          !this.selectedSiteInspCM.some(
            ({ collateral_manager_master_id: id2 }) => id2 === id1
          )
      );
      //initialize the form data
      let formData = new FormData();
      //append warehouse information to formdata
      formData.append("BorrowerName", this.selectedBorrowerName);
      formData.append("OwnerName", this.ownerName);
      formData.append("Contact", this.contact);
      formData.append("ContactCode", this.contactCode);
      formData.append("Address", this.address);
      formData.append("selectedCountry", this.selectedCountry);
      formData.append("selectedState", this.selectedState);
      formData.append("selectedCity", this.selectedCity);
      formData.append("RegistrationDate", this.regDate);
      formData.append("SiteInspectionStartDate", this.siteInspStartDate);
      formData.append("Frequency", this.selectedFrequency);
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", JSON.stringify(this.$store.getters.getuserId));
      formData.append("moduleId", 9);
      formData.append("tabId", 26);
      formData.append(
        "RemovedSiteInspectionCM",
        JSON.stringify(removedSiteInspCM)
      );
      formData.append(
        "SiteInspectionCM",
        JSON.stringify(this.selectedSiteInspCM)
      );
      formData.append(
        "CollateralDetails",
        JSON.stringify(this.CollateralDetails)
      );
      formData.append(
        "WarehouseDocuments",
        JSON.stringify(this.WarehouseDocuments)
      );
      formData.append(
        "WarehouseAdminDocuments",
        JSON.stringify(this.WarehouseAdminDocuments)
      );
      formData.append(
        "WarehouseDeleteData",
        JSON.stringify(this.WarehouseDelete)
      );
      console.log(this.regDate, this.siteInspStartDate);
      const path = "edit_warehouse/" + this.id;
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          console.log("status: " + response.data.status);
          if (response.data.status == "success") {
            Utils.make_alert("success", "Warehouse updated successfully!");
            console.log("SUCCESS!!");
            this.$router.push("/collateral");
          } else if (response.data.status == "Warehouse_already_exists") {
            Utils.make_alert("warning", "Warehouse already exists!");
            this.$router.push("/collateral");
          } else {
            Utils.make_alert("warning", "Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          Utils.make_alert(
            "warning",
            error.response.status === 400
              ? error.response.data.status
              : "Something went wrong!"
          );
          console.error(error);
        });
    }, //onSubmit() close

    //this method will add new line for additional Warehouse Documents
    addWarehouseDoc() {
      //when new line is added then WarehouseDocumentFile variable and WarehouseDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.WarehouseDocumentFile = null),
        (this.WarehouseDocumentSaveInfoDisabled = true),
        (this.WarehouseDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.WarehouseDocuments.push({
          document_id: null,
          warehouse_document_id: null,
          WarehouseDocName: null,
          IssueDate: null,
          ExpiryDate: null,
          comments: null,
          display_file_name: null,
          errors: "",
          docName: "",
          edited: false,
          WarehouseDocumentRemoveDisabled: true,
        });
    }, //addWarehouseDoc() close

    //this method will add new line for additional Warehouse Admin Documents
    addWarehouseAdminDoc() {
      //when new line is added then WarehouseAdminDocumentFile variable and WarehouseAdminDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.WarehouseAdminDocumentFile = null),
        (this.WarehouseAdminDocumentSaveInfoDisabled = true),
        (this.WarehouseAdminDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.WarehouseAdminDocuments.push({
          document_id: null,
          warehouse_admin_document_id: null,
          WarehouseAdminDocName: null,
          IssueDate: null,
          ExpiryDate: null,
          comments: null,
          display_file_name: null,
          errors: "",
          docName: "",
          edited: false,
          WarehouseAdminDocumentRemoveDisabled: true,
        });
    }, //addWarehouseAdminDoc() close

    //this method will add new line for additional Collateral Manager Detail
    addCollateralDetail() {
      (this.CollateralDetailSaveInfoDisabled = true),
        (this.CollateralDetailAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.CollateralDetails.push({
          collateral_manager_master_id: null,
          company_name: null,
          id: null,
          collateralDocs: [],
        });
    }, //addCollateralDetail() close

    //this method will add new line for additional collateral documents modal
    addCollateralDocument(selectedCMCompany) {
      //following block is created first time on mounted property
      this.CollateralDetails[selectedCMCompany].collateralDocs.push({
        collateralDocument: null,
        IssueDate: null,
        ExpiryDate: null,
        comments: "",
        selectedFile: null,
        selectedFileName: null,
        errors: "",
        edited: false,
        warehouse_document_id: null,
      });
    }, //addDirDocument() close

    // add error flag true to document if document id already exists else add false
    checkSameDoc(val, i) {
      let docList = this.CollateralDetails[
        this.selectedCMCompany
      ].collateralDocs.map((doc) => doc.collateralDocument);
      docList.pop();
      if (docList.includes(val))
        this.CollateralDetails[this.selectedCMCompany].collateralDocs[
          i
        ].error = true;
      else
        this.CollateralDetails[this.selectedCMCompany].collateralDocs[
          i
        ].error = false;
    },

    // validation for documents
    mouseenter(docName, index, type) {
      console.log(docName, index, type);
      if (docName === null) {
        if (type === "admin")
          this.WarehouseAdminDocuments[index].docName =
            "Document name is not selected";
        else {
          this.WarehouseDocuments[index].docName =
            "Document name is not selected";
        }
      }
    },
    mouseleave(index, type) {
      if (type === "admin") this.WarehouseAdminDocuments[index].docName = "";
      else this.WarehouseDocuments[index].docName = "";
    },

    //handles a change on the file upload
    handleFileUploadCollateralDoc(e, selectedCMCompany, id, docId) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.CollateralFile = e.target.files[0];
        //check for filesize. if it is larger than threshold (100 MB) then return following error response
        if (this.CollateralFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors = "File size should be less than 100MB";
          self.CollateralDetails[selectedCMCompany].collateralDocs[id].errors =
            JSON.stringify(response.errors).replace(/\"/g, "");
          self.CollateralDetails[selectedCMCompany].collateralDocs[
            id
          ].selectedFile = null;
          self.CollateralDetails[selectedCMCompany].collateralDocs[
            id
          ].selectedFileName = null;
          // self.CollateralDetails[selectedCMCompany].collateralDocs[id].directorDocument = null;
          return;
        }
        //save file details
        reader.readAsDataURL(this.CollateralFile);
        reader.onload = function () {
          self.CollateralDetails[selectedCMCompany].collateralDocs[
            id
          ].selectedFile = reader.result;
          self.CollateralDetails[selectedCMCompany].collateralDocs[
            id
          ].selectedFileName = e.target.files[0].name;
          self.CollateralDetails[selectedCMCompany].collateralDocs[
            id
          ].directorDocument = docId;
          self.CollateralDetails[selectedCMCompany].collateralDocs[id].errors =
            "";
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadCollateralDoc() close

    //handles a change on the file upload
    handleFileUploadWarehouseDocuments(id, e, doc) {
      // check if file already exists for same document name, show pop if true and delete record
      if (
        this.WarehouseDocuments.filter(
          (war) =>
            war.display_file_name === e.target.files[0].name &&
            war.WarehouseDocName === doc
        ).length
      ) {
        this.deleteWarehouseDocuments(this.WarehouseDocuments.length - 1);
        Utils.make_alert("warning", "File Already Exists");
        return;
      }
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.WarehouseDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.WarehouseDocumentFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors = "File size should be less than 100MB";
          self.WarehouseDocuments[id].errors = JSON.stringify(
            response.errors
          ).replace(/\"/g, "");
          self.WarehouseDocuments[id].selectedFile = null;
          self.WarehouseDocuments[id].selectedFileName = null;
          self.WarehouseDocuments[id].display_file_name = null;
          self.WarehouseDocuments[id].WarehouseDocumentRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.WarehouseDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.WarehouseDocuments[id].selectedFile = reader.result;
          self.WarehouseDocuments[id].selectedFileName = e.target.files[0].name;
          self.WarehouseDocuments[id].display_file_name =
            e.target.files[0].name; //to show filename in file input
          self.WarehouseDocuments[id].errors = "";
          self.WarehouseDocuments[id].WarehouseDocumentRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadWarehouseDocuments() close

    //handles a change on the file upload
    handleFileUploadWarehouseAdminDocuments(id, e, doc) {
      // check if file already exists for same admin document name, show pop if true and delete record
      if (
        this.WarehouseAdminDocuments.filter(
          (war) =>
            war.display_file_name === e.target.files[0].name &&
            war.WarehouseAdminDocName === doc
        ).length
      ) {
        this.deleteWarehouseAdminDocuments(
          this.WarehouseAdminDocuments.length - 1
        );
        Utils.make_alert("warning", "File Already Exists");
        return;
      }
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.WarehouseAdminDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.WarehouseAdminDocumentFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors = "File size should be less than 100MB";
          self.WarehouseAdminDocuments[id].errors = JSON.stringify(
            response.errors
          ).replace(/\"/g, "");
          self.WarehouseAdminDocuments[id].selectedFile = null;
          self.WarehouseAdminDocuments[id].selectedFileName = null;
          self.WarehouseAdminDocuments[id].display_file_name = null;
          self.WarehouseAdminDocuments[
            id
          ].WarehouseAdminDocumentRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.WarehouseAdminDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.WarehouseAdminDocuments[id].selectedFile = reader.result;
          self.WarehouseAdminDocuments[id].selectedFileName =
            e.target.files[0].name;
          self.WarehouseAdminDocuments[id].display_file_name =
            e.target.files[0].name; //to show filename in file input
          self.WarehouseAdminDocuments[id].errors = "";
          self.WarehouseAdminDocuments[
            id
          ].WarehouseAdminDocumentRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadWarehouseAdminDocuments() close

    //this function will be called to get list of borrowers
    getBorrowers() {
      const path = "borrower-master";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.borrowers = res.data.borrowers;
          if (res.data.status == "success") {
            console.log("get borrowers is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    getCities() {
      //this function is called to get list of cities
      const path = "city";
      axios
        .get(path)
        .then((res) => {
          this.cities = res.data.cities;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getStates() {
      //this function is called to get list of states
      const path = "state";
      axios
        .get(path)
        .then((res) => {
          this.states = res.data.states;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of countries
    getCountries() {
      const path = "country";
      axios
        .get(path)
        .then((res) => {
          this.countries = res.data.countries;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getCountries() close

    getStatesByCountry(flag) {
      if(this.selectedCountry == null){
        this.states = [];
        this.selectedState = null;
        this.cities = [];
        this.selectedCity = null;
        return;
      }
      //this function is called to get list of states
      const path = "get-state-by-country/" + this.selectedCountry ;
      axios
        .get(path)
        .then((res) => {
          if(flag){
            this.states = [];
            this.selectedState = null;
            this.cities = [];
            this.selectedCity = null;
          }
          this.states = res.data.states;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCitiesByState(flag) {
      if(this.selectedState == null){
        this.cities = [];
        this.selectedCity = null;
        return;
      }
      //this function is called to get list of cities
      const path = "get-city-by-state/" + this.selectedState;
      axios
        .get(path)
        .then((res) => {
          if(flag){
            this.cities = [];
            this.selectedCity = null;
          }
          this.cities = res.data.cities;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of interest types
    getFrequencyTypes() {
      //this function is called to get list of Frequency types
      const path = "interest-frequency";
      axios
        .get(path)
        .then((res) => {
          this.frequency = res.data.interest_frequencies.slice(1, 5);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCollaterals() {
      //this function will be called to get list of collaterals
      const path = "collateral-summary-overview-active";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (res.data.status == "success") {
            let data = res.data.collateral_active_overview;
            data.filter((col) => {
              col.status === "Approved" ? this.collaterals.push(col) : null;
            });
            console.log("get collaterals is successful.");
          } else {
            console.log("success", "Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("success", "Something went wrong!");
        });
    },

    getCountryCodes() {
      //this function is called to get list of country codes
      const path = "country_code_master";
      axios
        .get(path)
        .then((res) => {
          this.countryCodes = res.data.country_codes;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of warehouse documents
    getWarehouseDocuments() {
      const path = "warehouse-document";
      axios
        .get(path)
        .then((res) => {
          this.WarehouseDocs = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getWarehouseDocuments() close
    //this function is called to get list of warehouse admin documents
    getWarehouseAdminDocuments() {
      const path = "warehouse-admin-document";
      axios
        .get(path)
        .then((res) => {
          this.WarehouseAdminDocs = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getWarehouseAdminDocuments() close
    getWarehouseAdminCollateralDocuments() {
      const path = "warehouse-admin-collateral-document";
      axios
        .get(path)
        .then((res) => {
          this.WarehouseCollateralDocs = res.data.documents;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getWarehouseCollateralDocuments() close
  }, //main function close

  mounted() {
    this.getWarehouse();
  },

  created() {
    this.id = this.$route.params.id;
    this.getBorrowers();
    this.getCurrencies();
    this.getCountries();
    this.getCities();
    this.getStates();
    this.getFrequencyTypes();
    this.getCollaterals();
    this.getWarehouseDocuments();
    this.getCountryCodes();
    this.getWarehouseAdminDocuments();
    this.getWarehouseAdminCollateralDocuments();
  },
};
</script>

<style scoped>
.error {
  color: red;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -70px;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>
