<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-file-document"></i> Disputed History
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row"></div>
                </div>

                <div
                  class="card-body table-responsive p-0"
                  style="padding: 10px 0px !important"
                >
                  <!------menton here style="height: 300px;"-------->
                  <table
                    class="table table-head-fixed text-nowrap disputedHistoryTable"
                    id="disputedHistoryTable"
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Notes</th>
                        <th>Employee Name</th>
                        <th
                          id="removeDispute"
                          class="center"
                          v-if="(loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)) && !inactive"
                        >
                          Flag Removal
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="dis in DisputedDetails" :key="dis.id">
                        <td
                          v-if="dis.disputed_date"
                          :data-sort="new Date(dis.disputed_date).getTime()"
                        >
                          {{ dis.disputed_date }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="dis.notes" style="white-space: pre-wrap">
                          {{ dis.notes }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="dis.employee_name">
                          {{ dis.employee_name }}
                        </td>
                        <td v-else>-</td>
                        <template
                          v-if="(loggedInUserGroupId == 'Admin' ||
                                    (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0)) && !inactive"
                        >
                          <td
                            class="center"
                            v-if="!dis.disputed_flag"
                            @click="removeDispute(dis.id)"
                          >
                            <i class="icofont-minus-square text-danger"></i>
                          </td>
                          <td class="center" v-else>
                            <i
                              class="icofont-minus-square text-danger"
                              style="opacity: 0.5"
                            ></i>
                          </td>
                        </template>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.content-wrapper -->
</template>

<script>
import $ from "jquery";
// //Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods";
import moment from "moment";


export default {
  data() {
    return {
      DisputedDetails: [],
      inactive: null,
    };
  },
  methods: {
    // this function is used to get disputed details of a particular collateral manager id
    getDisputedDetails() {
      const path = "disputed-details/" + this.$route.params.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.DisputedDetails = this.inactive
              ? res.data.collateral_disputed
                  .collateral_disputed_details_inactive
              : res.data.collateral_disputed.collateral_disputed_details_active;
            if(this.DisputedDetails.length > 0) {
              this.DisputedDetails.forEach(item => {
                if (item.disputed_date != '-' && item.disputed_date != null) {
                  item.disputed_date = moment(item.disputed_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.disputed_date = '-';
                }
              });
            }
            setTimeout(
              () =>
                $("#disputedHistoryTable").DataTable({
                  searching: false,
                  aaSorting: [],
                  columnDefs: [
                    {
                      orderable: false,
                      targets: this.inactive
                        ? []
                        : this.loggedInUserGroupId === "Admin"
                        ? [3]
                        : [],
                    },
                  ],
                }),
              100
            );
            console.log("get Collateral Disputed details is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          setTimeout(
            () =>
              $("#disputedHistoryTable").DataTable({
                searching: false,
                aaSorting: [],
                columnDefs: [
                  {
                    orderable: false,
                    targets: this.inactive
                      ? []
                      : this.loggedInUserGroupId === "Admin"
                      ? [3]
                      : [],
                  },
                ],
              }),
            100
          );
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getDisputedDetails() close

    // this function is used to remove a particular dispute
    removeDispute(disputeId) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to remove the dispute?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path = "disputed-details/" + this.$route.params.id;
            axios
              .put(
                path,
                {
                  id: disputeId,
                  updateType: "flag",
                  flag: 1,
                  userId: this.$store.getters.getuserId,
                  moduleId: 6,
                  tabId: 25,
                  clientId: this.$store.state.client_id,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("dispute flag update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Dispute Flag removed successfully!"
                  );
                  setTimeout(() => {
                    this.$router.go();
                  }, 1500);
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          }
        },
      });
    },
  },
  computed: {
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
  },
  mounted() {
    this.getDisputedDetails();
  },
  created() {
    this.inactive = this.$route.params.inactive;
  },
};
</script>
