<!-- This is Loan page. It shows list of all the Loans and their details -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div
                      class="col-lg-6 col-sm-6"
                      v-if="loggedInUserGroupId == 'Borrower'"
                    >
                      <h1 class="headingt">
                        <i class="icofont-money-bag"></i> Summary
                      </h1>
                    </div>
                    <div class="col-lg-6 col-sm-6" v-else>
                      <h1 class="headingt">
                        <i class="icofont-money-bag"></i> Borrower Summary
                      </h1>
                    </div>

                    <!-- /.col -->
                    <!-- <div class="col-lg-6 col-sm-6">
                        <ul id="sr_btn">
                          <li>
                            <div class="form-group has-search m-0">
                              <span
                                class="fa fa-search form-control-feedback"
                              ></span>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Search"
                              />
                            </div>
                          </li>
                        </ul>
                      </div>-->
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 col-sm-5 col-6">
                <div class="form-group fc">             
                  <div class="form-field">
                    <label style="margin-right: 10px" class="exampleInputEmail1">Filter By:</label>
                    <input type="radio" v-model="filter" value="date" />
                    <label style="margin: 10px"> Date </label>

                    <input type="radio" v-model="filter" value="period" />
                    <label style="margin: 10px"> Period </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-2 col-sm-5 col-6" v-if="filter === 'date'">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">From Date</label>
                  <datepicker
                    :bootstrap-styling="true"
                    name="startDate"
                    placeholder="Select Date"
                    v-model="startDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fas fa-calendar"
                    :calendar-button="true"
                  />
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6" v-if="filter === 'date'">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">To Date</label>

                  <datepicker
                    :bootstrap-styling="true"
                    name="endDate"
                    placeholder="Select Date"
                    v-model="endDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                  ></datepicker>
                </div>
              </div>

              <div class="col-md-2" v-if="filter === 'period'">
                <div class="form-group fc">
                  <label>Period</label>
                  <select
                    id="selectedPeriod"
                    class="form-control"
                    name="selectedPeriod"
                    v-model="selectedPeriod"
                    required
                  >
                    <option value="mtd">MTD</option>
                    <option value="last_month">Last Month</option>
                    <option value="qtd">QTD</option>
                    <option value="last_quarter">Last Quarter</option>
                    <option value="last_2_quarters">Last 2 Quarters</option>
                    <option value="last_fy_1">Last FY 20-21 Year</option>
                    <option value="last_fy_2">Last FY 19-20 Year</option>
                  </select>
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6" v-if="filter === 'period'">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">From Date</label>
                  <datepicker
                    :bootstrap-styling="true"
                    name="endDate"
                    placeholder
                    v-model="startDatePeriod"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :disabled="true"
                  ></datepicker>
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6" v-if="filter === 'period'">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">To Date</label>
                  <datepicker
                    :bootstrap-styling="true"
                    name="endDate"
                    placeholder
                    v-model="endDatePeriod"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :disabled="true"
                  ></datepicker>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group fc">
                  <label>Status</label>
                  <select
                    id="selectedStatus"
                    class="form-control"
                    name="selectedStatus"
                    v-model="selectedStatus"
                    required
                  >
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                    <option value="All">All</option>
                  </select>
                </div>
              </div>

              <div class="col-md-3 col-sm-2 col-12">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-primary sh_n mt"
                      v-on:click="getAllBorrowerLoans()"
                    >
                      Filter
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh_n mt"
                      v-on:click="resetBorrowerLoan()"
                    >
                      Reset
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>-->

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="col-md-8 col-sm-5 col-6">
                <span style="font-size: 14px; color: red ; padding-top: 10px !important">Note: All the calculations are displayed in respective fund currency. </span>
              </div>
              <div class="container-fluid" id="al_tb">
                <div
                  class="card-body table-responsive p-0"
                  style="padding: 5px 0px !important"
                >
                  <!------menton here style="height: 300px;"-------->
                  <table
                    class="table table-head-fixed text-nowrap table-hover myTable1"
                    id="myTable1"
                  >
                    <thead>
                      <tr>
                        <th v-if="loggedInUserGroupId == 'Borrower'">Name</th>
                        <th v-else>Borrower Name</th>
                        <th class="c right">Loan Amount</th>
                        <!--<th class="c right" >Approved Amount</th>-->
                        <th class="c right">Drawdown Amount</th>
                        <th class="c right">Repaid Amount</th>
                        <th class="c right">Cost Amount</th>
                        <th class="c right">Outstanding Amount</th>
                        <th class="c right">Outstanding Expense</th>
                        <!-- <th class="c right" >Interest Accrued</th> -->
                        <th class="c right">Profit Return</th>
                        <!-- <th>Add Uti Req</th> -->
                        <th class="c center">Report</th>
                        <th
                          class="c center"
                          v-if="loggedInUserGroupId == 'Admin'"
                        >
                          Monthly Statement
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="loan in Loans" :key="loan.borrower_id">
                        <template
                          v-if="
                            loggedInUserGroupId == 'Admin' ||
                            (loggedInUserGroupId == 'Analyst' &&
                              loggedInUserSubGroupId == 0)
                          "
                        >
                          <td v-if="loan.loan_converted_amount  !== null" class="ln">
                            <router-link
                              active-link="active"
                              :to="{
                                name: 'LoanOverview',
                                params: { 
                                  id: loan.borrower_id,
                                  borrower_name: loan.borrower_name
                                },
                              }"
                              >{{ loan.borrower_name }}</router-link
                            >
                          </td>
                          <td v-else @click="noLoansAlert()">
                            {{ loan.borrower_name }}
                          </td>
                          <td
                            class="c right"
                            v-if="loan.loan_converted_amount  !== null"
                          >
                            {{ loan.loan_converted_amount  }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <!--<td v-if="loan.approved_amount !== null" class>
                            {{ loan.approved_amount }}
                          </td>
                            <td v-else >-</td>-->
                          <td
                            class="c right"
                            v-if="loan.drawdown_amount !== null"
                          >
                            {{ loan.drawdown_amount }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <td
                            class="c right"
                            v-if="loan.repayment_amount !== null"
                          >
                            {{ loan.repayment_amount  }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <td
                            class="c right"
                            v-if="loan.cost_amount !== null"
                          >
                            {{ loan.cost_amount  }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <td
                          class="c right"
                          v-if="loan.transaction_level_outstanding_amount !== null"
                          >
                            {{
                              loan.transaction_level_outstanding_amount
                           
                              }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <td
                            class="c right"
                            v-if="loan.cost_outstanding_amount !== null"
                          >
                            {{ loan.cost_outstanding_amount | numeral("0,0.00") }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <!-- <td class="c right"
                            
                              v-if="loan.outstanding_amount !== null && loan.outstanding_amount !== undefined"
                            >{{ loan.outstanding_amount }}</td>
                            <td class="c right" v-else>-</td> -->

                          <!-- <td
                            class="c right"
                            v-if="
                              loan.facility_cost !== null &&
                              loan.facility_cost !== undefined
                            "
                          >
                            {{ loan.facility_cost | numeral("0,0.00") }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <td
                            class="c right"
                            v-if="
                              loan.bank_charges !== null &&
                              loan.bank_charges !== undefined
                            "
                          >
                            {{ loan.bank_charges | numeral("0,0.00") }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <td
                            class="c right"
                            v-if="loan.Fees !== null && loan.Fees !== undefined"
                          >
                            {{ loan.Fees | numeral("0,0.00") }}
                          </td>
                          <td class="c right" v-else>-</td> -->

                          <!-- <td class="c right"
                            
                              v-if="loan.interest_paid !== null && loan.interest_paid !== undefined"
                            >{{ loan.interest_paid | numeral('0,0.00') }}</td>
                            <td class="c right" v-else>-</td>
                            <td class="c right"
                            
                              v-if="loan.accrued_interest !== null && loan.accrued_interest !== undefined"
                            >{{ loan.accrued_interest | numeral('0,0.00') }}</td>
                            <td class="c right" v-else>-</td> -->
                          <td
                            class="c right"
                            v-if="
                              loan.profit_return !== null &&
                              loan.profit_return !== undefined
                            "
                          >
                            {{ loan.profit_return | numeral("0,0.00") }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <td
                            class="c center"
                            v-on:click="showReportModal(loan.borrower_id,loan.registration_date)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-download text-primary"></i>
                          </td>
                          <td class="c center">
                            <router-link
                              style="text-decoration: none"
                              :to="{
                                name: 'BorrowerMonthlyStatement',
                                params: { id: loan.borrower_id },
                              }"
                            >
                              <i class="icofont-link"></i>
                            </router-link>
                          </td>
                        </template>
                        <template
                          v-else-if="
                            loggedInUserGroupId == 'Borrower' &&
                            loggedInUserSubGroupId == loan.borrower_id
                          "
                        >
                          <td class="ln">
                            <router-link
                              active-link="active"
                              :to="{
                                name: 'LoanOverview',
                                params: { id: loan.borrower_id },
                              }"
                              >{{ loan.borrower_name }}</router-link
                            >
                          </td>
                          <td
                            class="c right"
                            v-if="loan.request_amount !== null"
                          >
                            {{ loan.request_amount | numeral("0,0.00") }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <!--<td v-if="loan.approved_amount !== null">
                            {{ loan.approved_amount }}
                          </td>
                            <td class="c right" v-else>-</td>-->
                          <td
                            class="c right"
                            v-if="loan.drawdown_amount !== null"
                          >
                            {{ loan.drawdown_amount | numeral("0,0.00") }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <td
                            class="c right"
                            v-if="loan.repaid_amount !== null"
                          >
                            {{ loan.repaid_amount | numeral("0,0.00") }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <td
                            class="c right"
                            v-if="loan.outstanding_amount !== null"
                          >
                            {{
                              Math.abs(loan.outstanding_amount)
                                | numeral("0,0.00")
                            }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <!-- <td
                              v-if="loan.outstanding_amount !== null && loan.outstanding_amount !== undefined"
                            
                            >{{ loan.outstanding_amount | numeral('0,0.00') }}</td>
                            <td class="c right" v-else>-</td> -->
                          <td
                            class="c right"
                            v-if="
                              loan.facility_cost !== null &&
                              loan.facility_cost !== undefined
                            "
                          >
                            {{ loan.facility_cost | numeral("0,0.00") }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <td
                            class="c right"
                            v-if="
                              loan.bank_charges !== null &&
                              loan.bank_charges !== undefined
                            "
                          >
                            {{ loan.bank_charges | numeral("0,0.00") }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <td
                            class="c right"
                            v-if="loan.Fees !== null && loan.Fees !== undefined"
                          >
                            {{ loan.Fees | numeral("0,0.00") }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <!-- <td
                              v-if="loan.interest_paid !== null && loan.interest_paid !== undefined"
                            
                            >{{ loan.interest_paid | numeral('0,0.00') }}</td>
                            <td class="c right" v-else>-</td>
                            <td
                              v-if="loan.accrued_interest !== null && loan.accrued_interest !== undefined"
                            
                            >{{ loan.accrued_interest | numeral('0,0.00') }}</td>
                            <td class="c right" v-else>-</td> -->
                          <td
                            class="c right"
                            v-if="
                              loan.profit_return !== null &&
                              loan.profit_return !== undefined
                            "
                          >
                            {{ loan.profit_return | numeral("0,0.00") }}
                          </td>
                          <td class="c right" v-else>-</td>
                          <!-- <td="c text-danger">
                              <router-link
                                style="text-decoration: none"
                                :to="{
                                name: 'AddLoan',
                                params: { id: loan.borrower_id, type: 'loan' },
                              }"
                              >
                                <i="icofont-plus text-success"></i>
                              </router-link>
                            </td>-->
                          <td
                            class="c center"
                            v-on:click="showReportModal(loan.borrower_id,loan.registration_date)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-download text-primary"></i>
                          </td>
                        </template>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
      <!-- /.content-->
    </div>
    <!-- /.content-wrapper -->
    <!-- ./wrapper -->

    <div>
      <custom-loan-report-modal ref="customLoanReportModal">
        <template v-slot:header>
              <h4>Download Report</h4>
        </template>
        <div class="row">
              <div class="col-md-6">
                <div class="form-group fc">
                  <label>Select Report</label>
                  <select
                    id="selectedReport"
                    class="form-control"
                    name="selectedReport"
                    v-model="selectedReport"
                    disabled
                  >
                    <option :value="null">--- Select ---</option>
                    <option v-for="report in reports" :key="report.id" :value="report.id">
                      {{ report.report_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field"
                    >Period</label
                  >
                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="selectedReportPeriodDate"
                    placeholder="Select Period"
                    v-model="selectedReportPeriodDate"
                    minimum-view="month"
                    format="MM-yyyy"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :disabledDates="{
                      to: new Date(this.backcapBorrowerReportDate),
                      from: lastMonth,
                    }"
                  ></datepicker>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group fc">
                  <label class="required-field">Status</label>
                  <select
                    id="selectedReportStatus"
                    class="form-control"
                    name="selectedReportStatus"
                    v-model="selectedReportStatus"
                    required
                  >
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </select>
                </div>
              </div>
  
            </div>
        <template v-slot:footer>
          <div class="">
              <button
                style="margin-right: 6px"
                type="button"
                class="btn-sm btn btn-success sh"
                v-on:click="onClickExcel('excel')"
              >
                <i class="icofont-file-excel"></i> Excel
              </button>
              <button
                type="button"
                style="margin-right: 6px"
                class="btn-sm btn btn-danger sh"
                v-on:click="onClickExcel('pdf')"
              >
                <i class="icofont-file-pdf"></i> PDF
              </button>
              <button
                type="button"
                name="saveInfo"
                class="btn-sm btn btn-primary sh"
                style="margin-right: 10px;"
                v-on:click="onCloseReportModal()"
              >
                <i class="icofont-close-circled"></i> Close
              </button>
            </div>
        </template>
      </custom-loan-report-modal>
    </div>


    <!-- ./report modal start -->
    <!-- <modal name="report">
      <div class="modal-content" style="height: 500px">
        <div class="modal-header">
          <h4 class="modal-title">Download Report</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="$modal.hide('report')"
          >
            <i class="icofont-close-circled"></i>
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group fc">
                <label class="required-field">Select Report</label>
                <select
                  id="selectedReport"
                  class="form-control"
                  name="selectedReport"
                  v-model="selectedReport"
                  required
                >
                  <option :value="null">--- Select ---</option>
                  <option v-for="report in reports" :value="report.id">
                    {{ report.report_name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group fc">
                <label class="required-field">Period</label>
                <select
                  id="selectedPeriod"
                  class="form-control"
                  name="selectedPeriod"
                  v-model="selectedPeriod"
                  required
                >
                  <option value="null">--- Select ---</option>
                  <option value="mtd">MTD</option>
                  <option value="ytd">YTD</option>
                  <option value="lastmonth">Last Month</option>
                  <option value="lastquarter">Last 3 Months</option>
                  <option value="last2quarters">Last 6 Months</option>
                  <option value="lastfy1">Last 12 Months</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="mx-auto">
            <button
              style="margin-right: 6px"
              type="button"
              class="btn-sm btn btn-success sh"
              v-on:click="onClickExcel('excel')"
            >
              <i class="icofont-file-excel"></i> Excel
            </button>
            <button
              type="button"
              style="margin-right: 6px"
              class="btn-sm btn btn-danger sh"
              v-on:click="onClickExcel('pdf')"
              :style="{ cursor: 'pointer' }"
            >
              <i class="icofont-file-pdf"></i> PDF
            </button>
            <button
              style="margin-right: 6px"
              type="button"
              class="btn-sm btn btn-dark sh"
              data-dismiss="modal"
              @click="$modal.hide('report')"
            >
              <i class="icofont-close-circled"></i> Close
            </button>
          </div>
        </div>
      </div>
    </modal> -->
    <!-- ./report modal end -->
  </div>
</template>

<script>
import $ from "jquery";
// //Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import CustomLoanReportModal from './CustomLoanReportModal.vue';


export default {
  data() {
    return {
      Loans: [],
      regStatus: null,
      selectedStatus: "Open",
      startDate: null,
      endDate: null,
      filter: "date",
      startDatePeriod: null, //for period filter
      endDatePeriod: null, //for period filter
      borrowerId: null,
      selectedPeriod: null,
      selectedReport: 7,
      reports: [],
      selectedReportStatus: "Open",
      selectedReportPeriodDate: null,
      backcapBorrowerReportDate: null,
      lastMonth: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1
      ),
    };
  },
  components: {
    Datepicker,
    CustomLoanReportModal,

  },
  methods: {
    // displays popup if no loans are available for a particular borrower
    noLoansAlert() {
      Utils.make_alert(
        "warning",
        "Please add loan through Facility Setup",
        () => {
          this.$router.push("/facility");
        }
      );
    },
    getReports() {
      //this function is called to get list of borrower reports
      const path = "borrower-reports";
      axios
        .get(path)
        .then((res) => {
          this.reports = res.data.reports;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    onCloseReportModal() {
      console.log("onCloseReportModal");
      this.borrowerId = null;
      this.selectedReportPeriodDate = null;
      this.backcapBorrowerReportDate = null;
      this.selectedReportStatus = "Open";
      this.$refs.customLoanReportModal.close();
    },

    showReportModal(borrowerId,date) {
      this.borrowerId = borrowerId;
      this.backcapBorrowerReportDate = date;
      // this.$modal.show("report");
      this.$refs.customLoanReportModal.open();
    },

    onClickExcel(fileType) {
      if (this.selectedReport == null) {
        Utils.make_alert("warning", "Please select report type.");
        return;
      }
      if (this.selectedReportStatus == null) {
        Utils.make_alert("warning", "Please select status.");
        return;
      }
      let date = null
      if (this.selectedReportPeriodDate == null) {
        Utils.make_alert("warning", "Please select period.");
        return;
      } else {
        console.log("selectedReportPeriodDate",this.selectedReportPeriodDate)
        date = moment(this.selectedReportPeriodDate).endOf("month").format("MM-DD-YYYY");
      }
      const path =
        "/borrower_summary_report/" +
        this.selectedReport +
        "/" +
        this.borrowerId +
        "/" +
        fileType +
        "/" +
        date +
        "/" +
        this.selectedReportStatus;
        console.log("path",path)
        this.$refs.customLoanReportModal.close();
        Utils.start_loading();
      axios({
        url: path,
        params: { clientId: this.$store.state.client_id , userId: this.$store.getters.getuserId},
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          Utils.stop_loading();
          if (response.data.type == "application/json") {
            Utils.make_alert(
              "warning",
              "Something went wrong! Unable to download report."
            );
            this.borrowerId = null;
            this.selectedReportPeriodDate = null;
            this.backcapBorrowerReportDate = null;
            this.selectedReportStatus = "Open";
          } else {
            //give report type names as per selectedReport
            var reportName;
            var extension;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            console.log("Download")
            console.log(response);
            link.href = url;
            // Filename seems to have to be changed unless otherwise provided
            // the extension can be automatically decided
            if (fileType === "excel") {
              extension = ".xlsx";
            } else if (fileType === "pdf") {
              extension = ".pdf";
            }
            reportName = "Borrower Summary Report";
            link.setAttribute("download", reportName + extension);
            document.body.appendChild(link);
            link.click();
            this.borrowerId = null;
            this.selectedReportPeriodDate = null;
            this.backcapBorrowerReportDate = null;
            this.selectedReportStatus = "Open";
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.log("error.data: ", error.response.status);
          console.log("error: " + error);
          // if (error.response.status == 400) {
          //   Utils.make_alert(
          //     "warning",
          //     "No data available for the selected report period!"
          //   );
          // } else if (error.response.status == 406) {
          //   Utils.make_alert("warning", "No matching reports.");
          // } else {
          //   Utils.make_alert(
          //     "warning",
          //     "Something went wrong! Unable to download report."
          //   );
          // }
        });
    },

    getLoans() {
      Utils.start_loading();
      //this function will be called to get list of funds
      const path = "/borrower_summary";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.Loans = res.data.borrower_summary;
          setTimeout(
            () =>
              $("#myTable1").DataTable({
                aaSorting: [],
                columnDefs: [
                  {
                    orderable: false,
                    targets: [9, 10],
                  },
                ],
              }),
            100
          );
          if (res.data.status == "success") {
            console.log("get loans is successful.");
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    getAllBorrowerLoans() {
      if (this.filter === "date") {
        this.selectedPeriod = null;
        if (this.startDate != null) {
          this.startDate = moment(this.startDate).format("MM-DD-YYYY");
        }
        if (this.endDate != null) {
          this.endDate = moment(this.endDate).format("MM-DD-YYYY");
        }
      }

      if (this.filter === "period") {
        this.startDate = null;
        this.endDate = null;
      }

      const path =
        "loan-details/" +
        this.startDate +
        "/" +
        this.endDate +
        "/" +
        this.selectedPeriod +
        "/" +
        this.selectedStatus; // +
      // "/" +
      // this.selectedFund +
      // "/" +
      // this.selectedInvestmentCategory +
      // "/" +
      // this.selectedInvestorName;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          console.log(res.data);
          this.Loans = res.data.loan;
          this.selectedPeriod = res.data.period;
          this.selectedStatus = res.data.loan_status;
          //set the start and end date according to filter selected
          if (this.filter === "period") {
            this.startDatePeriod = res.data.start_date;
            this.endDatePeriod = res.data.end_date;
            this.startDate = null;
            this.endDate = null;
          } else if (this.filter === "date") {
            this.startDate = res.data.start_date;
            this.endDate = res.data.end_date;
            this.startDatePeriod = null;
            this.endDatePeriod = null;
          }
          if (res.data.status == "success") {
            console.log("get investment by investor id is successful.");
          }
        })
        .catch((error) => {
          console.error("error: " + error);
        });
    }, //getAllBorrowerLoans() close

    resetBorrowerLoan() {
      this.startDate = null;
      this.endDate = null;
      this.startDatePeriod = null;
      this.endDatePeriod = null;
      this.selectedPeriod = null;
      this.selectedStatus = "Open";
      this.filter = "date";
      this.getAllBorrowerLoans();
    }, //resetInvestment() close
  },
  created() {
    axios.defaults.headers.common["Authorisation"] =
      "Bearer " + sessionStorage.getItem("token");
    this.getLoans();
    this.getReports();
    this.selectedStatus = "Open";
    this.filter = "date";
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style scoped>
.required-field::after {
  content: "*";
  color: red;
}
th,
td {
  text-align: left;
}
.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}

.center {
  text-align: center !important;
}
.right {
  text-align: right !important;
}
</style>
