<!-- This is add Loan page. -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-plus"></i> Add Disbursement
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Basic Details</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Fund Name</label>
                      <select
                        id="selectedFund"
                        class="form-control"
                        name="selectedFund"
                        v-model="selectedFund"
                        @change="getBorrowersByFund()"
                        :class="{
                            'is-invalid':
                              isBasicDetailsSubmitted && $v.selectedFund.$error,
                          }"
                        :disabled="LTVtoDisbursement || (this.flow == 'Loan')"
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="fund in Funds"
                          :value="fund.fund_id"
                          :key="fund.fund_id"
                        >
                          {{ fund.fund_name }}
                        </option>
                      </select>
                      <div
                        v-if="isBasicDetailsSubmitted && $v.selectedFund.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedFund.required"
                          >Fund Name is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedBorrower" class="required-field"
                        >Borrower Name</label
                      >
                      <select
                        id="selectedBorrower"
                        class="form-control"
                        v-model="selectedBorrower"
                        @change="getFacilityByFundBorrowerCollateral()"
                        :class="{
                          'is-invalid':
                            isBasicDetailsSubmitted && $v.selectedBorrower.$error,
                        }"
                        :disabled="LTVtoDisbursement || (this.flow == 'Loan')"
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="borrower in borrowers"
                          :value="borrower.borrower_id"
                          :key="borrower.borrower_id"
                        >
                          {{ borrower.borrower_name }}
                        </option>
                      </select>
                      <div
                        v-if="isBasicDetailsSubmitted && $v.selectedBorrower.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedBorrower.required"
                          >Borrower Name is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedDisbursementOn" class="required-field"
                        >Disbursement On</label
                      >
                      <select
                        id="selectedDisbursementOn"
                        class="form-control"
                        v-model="selectedDisbursementOn"
                        @change="onChangeDisbursementOn();getFacilityByFundBorrowerCollateral()"
                        :class="{
                          'is-invalid':
                            isBasicDetailsSubmitted && $v.selectedDisbursementOn.$error,
                        }"
                        :disabled ="LTVtoDisbursement || (this.flow == 'Loan')" 
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="dis in disbursementOn"
                          :value="dis.id"
                          :key="dis.id"
                        >
                          {{ dis.disbursement_on }}
                        </option>
                      </select>
                      <div
                        v-if="isBasicDetailsSubmitted && $v.selectedDisbursementOn.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedDisbursementOn.required"
                          >Disbursement On is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <!-- <div v-if="this.selectedDisbursementOn == '1' || this.selectedDisbursementOn == '2'" class="col-md-3 col-sm-5 col-6">
                    <div class="form-group fc">
                        <label for="selectedCollateralID" class="required-field"
                          >Collateral Name</label
                        >
                        <select
                          id="selectedCollateralID"
                          class="form-control"
                          v-model="selectedCollateralID"
                          @change="getFacilityByFundBorrowerCollateral()"
                          :class="{
                            'is-invalid':
                              isBasicDetailsSubmitted && $v.selectedCollateralID.$error,
                          }"
                          :disabled="LTVtoDisbursement"
                        >
                          <option :value="null" :disabled="this.flow == 'Loan'">--- Select ---</option>
                          <option
                            v-for="col in collateralNames"
                            :value="col.id"
                            :key="col.id"
                          >
                            {{ col.collateral_name }}
                          </option>
                        </select>
                        <div
                          v-if="isBasicDetailsSubmitted && $v.selectedCollateralID.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedCollateralID.required"
                            >Collateral Name is required</span
                          >
                        </div>
                    </div>
                  </div> -->

                  <div v-if="this.selectedDisbursementOn == '1' || this.selectedDisbursementOn == '2'" class="col-md-6 col-sm-6 col-6">
                    <div class="form-group fc">
                      <label class="required-field"
                        >Collateral Name</label
                      >
                      <multiselect
                          v-model="selectedCollateralID"
                          :placeholder="collateralNames.length ? '--- Select ---' : 'No Collateral Available'"
                          label="collateral_name"
                          track-by="collateral_name"
                          @input="getFacilityByFundBorrowerCollateral()"
                          :options="collateralNames"
                          :multiple="true"
                          :class="{ 'is-invalid': isBasicDetailsSubmitted && $v.selectedCollateralID.$error }"
                          :disabled ="LTVtoDisbursement"
                          >
                        </multiselect>
                        <div
                          v-if="isBasicDetailsSubmitted && $v.selectedCollateralID.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedCollateralID.required"
                            >At least 1 Collateral is required</span
                          >
                        </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6 col-6">
                    <div class="form-group fc">
                      <label class="required-field"
                        >Facility ID</label
                      >
                      <multiselect
                          v-model="selectedFacility"
                          :placeholder="facilities.length ? '--- Select ---' : 'No Facilities Available'"
                          label="facility_registration_id"
                          track-by="facility_registration_id"
                          @input="getLoan()"
                          :options="facilities"
                          :multiple="true"
                          :class="{ 'is-invalid': isBasicDetailsSubmitted && $v.selectedFacility.$error }"
                          :disabled ="LTVtoDisbursement || (this.flow == 'Loan')"
                          >
                        </multiselect>
                        <!-- @input="getLoan();getOfftakerNameandLTV()" -->
                        <div
                          v-if="isBasicDetailsSubmitted && $v.selectedFacility.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.selectedFacility.required"
                            >At least 1 Facility is required</span
                          >
                        </div>
                    </div>
                  </div>

                  <!-- <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="DisbursementAmount" class="required-field">Disbursement Amt.(Fund Currency)</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="DisbursementAmount"
                        :class="{
                          'is-invalid': isBasicDetailsSubmitted && $v.DisbursementAmount.$error,
                        }"
                        id="DisbursementAmount"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                        :disabled ="LTVtoDisbursement"
                      />
                      <div
                        v-if="isBasicDetailsSubmitted && $v.DisbursementAmount.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.DisbursementAmount.required"
                          >Disbursement Amt.(Fund Currency) is required<br
                        /></span>
                        <span v-if="!$v.DisbursementAmount.maxLength"
                          >Max length of 15 digits exceeded</span
                        >
                      </div>
                    </div>
                  </div> -->

                  <!-- <div class="col-md-3 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="DisbursementDate" class="required-field">Disbursement Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        placeholder="Select Date"
                        v-model="DisbursementDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        :disabledDates="{ to: new Date(backCapDate), from: new Date() }"
                        :class="{
                          'is-invalid': isBasicDetailsSubmitted && $v.DisbursementDate.$error,
                        }"
                        :disabled ="LTVtoDisbursement"
                      ></datepicker>
                      <div
                        v-if="isBasicDetailsSubmitted && $v.DisbursementDate.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.DisbursementDate.required">Disbursement Date is required</span>
                      </div>
                    </div>
                  </div> -->
                </div>
                
                <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onBasicDetailsSaveInfo()"
                          :disabled="isDisabledBasicDetailsSaveInfo || LTVtoDisbursement"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
              </div>
            </div>
          </div>

          <div v-if="this.selectedDisbursementOn == '1' && this.isOfftakerDetailsVisible" class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Offtaker Details</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedOfftakerID" class="required-field"
                        >Offtaker Name</label
                      >
                      <select
                        id="selectedOfftakerID"
                        class="form-control"
                        v-model="selectedOfftakerID"
                        @change="getContractNoByOfftakerName()"
                        :class="{
                          'is-invalid':
                            isOfftakerDetailsSubmitted && $v.selectedOfftakerID.$error,
                        }"
                      >
                        <option :value="null">--- Select ---</option>
                        <option
                          v-for="ofn in OfftakerName"
                          :value="ofn.offtaker_id"
                          :key="ofn.offtaker_id"
                        >
                          {{ ofn.offtaker_name }}
                        </option>
                      </select>
                      <div
                        v-if="isOfftakerDetailsSubmitted && $v.selectedOfftakerID.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedOfftakerID.required"
                          >Borrower Name is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedContractNo" class="required-field"
                        >Contract No</label
                      >
                      <select
                        id="selectedContractNo"
                        class="form-control"
                        v-model="selectedContractNo"
                        @change="getOfftakerDetails()"
                        :class="{
                          'is-invalid':
                            isOfftakerDetailsSubmitted && $v.selectedContractNo.$error,
                        }"
                      >
                        <option :value="null">--- Select ---</option>
                          <option
                            v-for="ofn in ContractNo"
                            :value="ofn.id"
                            :key="ofn.id"
                          >
                          {{ ofn.contract_number }}
                        </option>
                      </select>
                      <div
                        v-if="isOfftakerDetailsSubmitted && $v.selectedContractNo.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedContractNo.required"
                          >Contract No is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="collateralValue" class="required-field">Collateral Value</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="collateralValue"
                        :class="{
                          'is-invalid': isOfftakerDetailsSubmitted && $v.collateralValue.$error,
                        }"
                        id="collateralValue"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                      />
                      <div
                        v-if="isOfftakerDetailsSubmitted && $v.collateralValue.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.collateralValue.required"
                          >Collateral Value is required<br
                        /></span>
                        <span v-if="!$v.collateralValue.maxLength"
                          >Max length of 15 digits exceeded</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Currency</label>
                      <select
                        id="selectedCollateralCurrency"
                        class="form-control"
                        v-model="selectedCollateralCurrency"
                        :class="{
                          'is-invalid':
                            isOfftakerDetailsSubmitted && $v.selectedCollateralCurrency.$error,
                        }"
                      >
                      <!-- @change="getCurrencyShortNameById(selectedCollateralCurrency)" -->
                        <option :value="null">-- Select a Currency --</option>
                        <option
                          v-for="curr in currencies"
                          :value="curr.id"
                          :key="curr.id"
                        >
                          {{ curr.currency_short_name }}
                        </option>
                      </select>
                      <div
                        v-if="isOfftakerDetailsSubmitted && $v.selectedCollateralCurrency.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedCollateralCurrency.required"
                          >Currency is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="LTV" class="required-field">LTV</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="LTV"
                        :class="{
                          'is-invalid': isOfftakerDetailsSubmitted && $v.LTV.$error,
                        }"
                        id="LTV"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                      />
                      <div
                        v-if="isOfftakerDetailsSubmitted && $v.LTV.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.LTV.required"
                          >LTV % is required.<br
                        /></span>
                        <span v-if="!$v.LTV.customValidation"
                          >LTV % cannot exceed 100</span
                        >
                      </div>
                    </div>
                  </div>

                </div>
                
                <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onOfftakerDetailsSaveInfo()"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
              </div>
            </div>
          </div>


          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Loan Mapping</h1>
                      </div>
                      <!-- /.col -->
                      <div v-if="flow != 'Loan'" class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addLoanMapping"
                              :disabled="loanMappingAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->
                    <div class="row">

                      <div v-if="this.selectedDisbursementOn != 3" class="col-md-3 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="borrowerLevelActualDisbursement">Borrower Level Actual Disbursement</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="borrowerLevelActualDisbursement"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            id="borrowerLevelActualDisbursement"
                            disabled
                          />
                        </div>
                      </div>

                      <div v-if="this.selectedDisbursementOn != 3" class="col-md-3 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="loanActualDisbursement">Actual Disbursement</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="loanActualDisbursement"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            id="loanActualDisbursement"
                            disabled
                          />
                        </div>
                      </div>

                      <div v-if="this.selectedDisbursementOn != 3" class="col-md-3 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="AvailableToDisburse">Available to Disburse</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="AvailableToDisburse"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            id="AvailableToDisburse"
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="row"
                      v-for="(lm, index) in loanMappingList"
                      :key="index"
                    >
                    
                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="selectedLoan" class="required-field">Loan Reg. ID</label>
                          <select
                            id="selectedLoan"
                            class="form-control"
                            name="selectedLoan"
                            v-model="lm.selectedLoan"
                            @change="onLoanSelectGetDetails(index)"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.loanMappingList.$each[index]
                                  .selectedLoan.$error,
                            }"
                            :disabled="!loanMappingFlag || (flow == 'Loan')"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="loan in loans"
                              :value="loan.loan_master_id"
                              :key="loan.loan_master_id"
                            >
                              {{ loan.loan_registration_id }}
                            </option>
                          </select>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.loanMappingList.$each[index].selectedLoan
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .selectedLoan.required
                              "
                              >Loan Reg. ID is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="loanDisbursementDate" class="required-field">Disbursement Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            placeholder="Select Date"
                            id="loanDisbursementDate"
                            v-model="lm.loanDisbursementDate"
                            @input="getloanConversionRateByCurrencyDate(index,lm.loanDisbursementDate)"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.loanMappingList.$each[index]
                                  .loanDisbursementDate.$error,
                            }"
                            :disabledDates="{ to: new Date(lm.backCapDate), from: new Date() }"
                            :disabled="!lm.selectedLoan"
                          ></datepicker>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.loanMappingList.$each[index].loanDisbursementDate
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanDisbursementDate.required
                              "
                              >Disbursement date is required</span
                            >
                          </div>
                        </div>
                      </div>

                      

                      <div class="col-md-3 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="loanDisbursementAmount" class="required-field">Disbursement Amt.(Fund Currency)</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="lm.loanDisbursementAmount"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            @keyup="onChangeDisbursementAmountValidation(index)"
                            :class="{
                              'is-invalid':
                                (isSubmitted &&
                                $v.loanMappingList.$each[index]
                                  .loanDisbursementAmount.$error) || (lm.loanDisbursementAmountError),
                            }"
                            id="loanDisbursementAmount"
                            :disabled="!lm.selectedLoan"
                          />
                          <div
                            v-if="
                              (isSubmitted &&
                              $v.loanMappingList.$each[index].loanDisbursementAmount
                                .$error) || (lm.loanDisbursementAmountError)
                            "
                            class="invalid-feedback"
                          >
                          <span
                              v-if="
                                (lm.loanDisbursementAmountError)
                              "
                              >{{ lm.loanDisbursementAmountErrorMessage }}</span
                            >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanDisbursementAmount.required
                              "
                              >Disbursement Amt.(Fund Currency) is required</span
                            >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanDisbursementAmount.maxLength
                              "
                              >Max length of 15 characters exceeded</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="selectedLoanCurrency">Loan Currency</label>
                          <select
                            id="selectedLoanCurrency"
                            class="form-control"
                            v-model="lm.selectedLoanCurrency"
                            disabled
                          >
                            <!-- :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.loanMappingList.$each[index]
                                  .selectedLoanCurrency.$error,
                            }" -->
                            <option :value="null">-- Loan Currency --</option>
                            <option
                              v-for="curr in currencies"
                              :value="curr.id"
                              :key="curr.id"
                            >
                              {{ curr.currency_short_name }}
                            </option>
                          </select>
                          <!-- <div
                            v-if="
                              isSubmitted &&
                              $v.loanMappingList.$each[index].selectedLoanCurrency
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .selectedLoanCurrency.required
                              "
                              >Loan Currency is required</span
                            >
                          </div> -->
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="loanConversionRate">Conversion Rate</label>
                          <input
                            type="number"
                            class="form-control"
                            v-model="lm.loanConversionRate"
                            id="loanConversionRate"
                            @keypress="isDecimal($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            @keyup="onloanDisbursementAmountChnage(index)"
                            disabled
                          />
                            <!-- :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.loanMappingList.$each[index]
                                  .loanConversionRate.$error,
                            }" -->
                          <!-- <div
                            v-if="
                              isSubmitted &&
                              $v.loanMappingList.$each[index].loanConversionRate
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanConversionRate.required
                              "
                              >Conversion Rate is required</span
                            >
                          </div> -->
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="loanConversionDate">Conversion Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            placeholder="Select Date"
                            @input="getloanConversionRateByCurrencyDate(index,lm.loanConversionDate)"
                            id="loanConversionDate"
                            v-model="lm.loanConversionDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            :disabledDates="{ to: new Date(lm.backCapDate), from: new Date() }"
                            disabled
                          ></datepicker>
                            <!-- :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.loanMappingList.$each[index]
                                  .loanConversionDate.$error,
                            }" -->
                          <!-- <div
                            v-if="
                              isSubmitted &&
                              $v.loanMappingList.$each[index].loanConversionDate
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanConversionDate.required
                              "
                              >Conversion Date is required</span
                            >
                          </div> -->
                        </div>
                      </div>


                      <div class="col-md-2 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="loanConvertedAmount" title="Converted Disbursement Amount(Loan Currency)">Conv. Dis. Amt.(Loan Currency)</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="lm.loanConvertedAmount"
                            id="loanConvertedAmount"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            disabled
                          />
                            <!-- :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.loanMappingList.$each[index]
                                  .loanConvertedAmount.$error,
                            }" -->
                          <!-- <div
                            v-if="
                              isSubmitted &&
                              $v.loanMappingList.$each[index].loanConvertedAmount
                                .$error
                            "
                            class="invalid-feedback"
                          >
                          <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanConvertedAmount.required
                              "
                              >Conv. Disbursement Amount(Loan Currency) is required</span
                            >
                            <span
                              v-if="
                                !$v.loanMappingList.$each[index]
                                  .loanConvertedAmount.maxLength
                              "
                              >Max length of 15 characters exceeded</span
                            >
                          </div> -->
                        </div>
                      </div>

                      <div v-if="flow != 'Loan'" class="col-md-1 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label
                            for="deleteLoanMapping"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            v-on:click="deleteLoanMapping(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="flow != 'Loan'" class="row">
                      <div class="col-md-12 text-right mb-3">
                        <!-- <button
                          type="button"
                          name="saveInfo"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onLoanMappingSaveInfo()"
                          :disabled="isDisabledLoanMappingSaveInfo"
                        >
                          Save Info
                        </button> -->
                        <button
                          type="button"
                          name="saveInfo"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onLoanMappingSaveInfo()"
                          :disabled="isDisabledLoanMappingSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Banking Details</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedBank">Beneficiary Bank</label>
                      <select
                        id="selectedBank"
                        class="form-control"
                        v-model="selectedBank"
                      >
                        <option :value="null">-- Select a Bank --</option>
                        <option
                          v-for="bank in banks"
                          :value="bank.id"
                          :key="bank.id"
                        >
                          {{ bank.bank_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="bic">Bank Identifier Code</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="bic"
                        id="bic"
                        :class="{
                          'is-invalid': isSubmitted && $v.bic.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.bic.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.bic.minLength"
                          >Min length of 8 characters required<br
                        /></span>
                        <span v-if="!$v.bic.maxLength"
                          >Max length of 11 characters exceeded<br
                        /></span>
                        <span v-if="!$v.bic.alphaNum"
                          >Bank Identifier Code should be alphanumeric
                          only</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="iban">IBAN </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="iban"
                        id="iban"
                        :class="{
                          'is-invalid': isSubmitted && $v.iban.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.iban.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.iban.required"
                          >IBAN cannot be 0</span
                        >
                        <span v-if="!$v.iban.maxLength"
                          >Max length of 35 characters exceeded<br
                        /></span>
                        <span v-if="!$v.iban.alphaNum"
                          >IBAN should be alphanumeric only</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="accountNumber">Account No.</label>
                      <input
                        type="text"
                        id="accountNumber"
                        class="form-control"
                        v-model="accountNumber"
                        :class="{
                          'is-invalid': isSubmitted && $v.accountNumber.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.accountNumber.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.accountNumber.required"
                          >Account No. cannot be 0</span
                        >
                        <span v-if="!$v.accountNumber.maxLength"
                          >Max length of 17 characters exceeded<br
                        /></span>
                        <span v-if="!$v.accountNumber.numeric"
                          >Account No. should be numeric only</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="accountName">Account Name </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="accountName"
                        id="accountName"
                        :class="{
                          'is-invalid': isSubmitted && $v.accountName.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.accountName.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.accountName.required"
                          >Account Name cannot be 0</span
                        >
                        <span v-if="!$v.accountName.maxLength"
                          >Max length of 100 characters exceeded<br
                        /></span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="reference">Reference </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="reference"
                        id="reference"
                        :class="{
                          'is-invalid': isSubmitted && $v.reference.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.reference.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.reference.required"
                          >Reference cannot be 0</span
                        >
                        <span v-if="!$v.reference.maxLength"
                          >Max length of 100 characters exceeded<br
                        /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documents</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addCostDoc()"
                              :disabled="DisbursementDocumentsAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(doc, index) in DisbursementDocuments"
                      :key="index"
                    >
                      <div class="col-md-4 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="customFile">Upload Document</label>
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="customFile"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              v-on:change="
                                handleFileUploadDisbursementDocuments(index, $event)
                              "
                              :class="{
                                'is-invalid': doc.errors.filesize,
                              }"
                            />
                            <small>
                              <p style="background-color: powderblue">
                                <b>{{ doc.displayFileName }}</b>
                                <small>
                                  <u
                                    style="color: blue; cursor: pointer"
                                    v-if="
                                      doc.DisbursementDocumentsRemoveDisabled === false
                                    "
                                    v-on:click="removeDisbursementDocuments(index)"
                                    >Remove</u
                                  >
                                </small>
                              </p>
                            </small>
                            <div
                              v-if="doc.errors.filesize"
                              class="invalid-feedback"
                            >
                              <span>{{ doc.errors.filesize }}</span>
                            </div>
                            <label class="custom-file-label" for="customFile"
                              >Choose file</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="comments">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="doc.comments"
                          id="comments"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.DisbursementDocuments.$each[index].comments
                                .$error,
                          }"
                          :disabled="(doc.displayFileName == null)"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.DisbursementDocuments.$each[index].comments.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.DisbursementDocuments.$each[index].comments
                                .maxLength
                            "
                            >Max length of 100 characters exceeded.</span
                          >
                        </div>
                      </div>
                    </div>

                      <div class="col-md-1 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            v-on:click="deleteDisbursementDocuments(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onDisbursementDocumentSaveInfo()"
                          :disabled="isDisabledDisbursementDocumentSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-success sh"
                      v-on:click="onSubmit()"
                    >
                      Submit
                    </button>
                  </li>
                  <li>
                    <a @click="$router.go(-1)">
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>
    <!-- ./wrapper -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {
  required,
  maxLength,
  minLength,
  numeric,
  alphaNum,
  maxValue,
  decimal,
  between,
} from "vuelidate/lib/validators";


export default {
  data() {
    return {
      borrowers: [],
      facilities: [],
      loanExpenses: [],
      currencies: [],
      banks: [],
      loans: [],
      Funds: [],
      disbursementOn: [],
      collateralNames: [],
      collateralNamesFromLoan: [],
      // Basic details
      selectedBorrower: null,
      selectedFund:null,
      fundCurrency:null,
      // selectedBorrower: 977,
      // selectedFund:376,
      // fundCurrency:2,
      selectedFacility: [],
      selectedFacilityIds:[],
      selectedCollateralID: [],
      selectedDisbursementOn:null,
      DisbursementAmount: null,
      DisbursementDate: null,
      backCapDate:new Date(moment(new Date()).subtract(10, "year")),
      // offtaker Details
      isOfftakerDetailsVisible:false,
      OfftakerName:[],
      ContractNo:[],
      selectedOfftakerID:null,
      selectedContractNo:null,
      collateralValue:null,
      selectedCollateralCurrency:null,
      ltvConversionDate:null,
      LTV: null,
      currencyShortName:null,
      // Bank details
      selectedBank: null,
      bic: null,
      iban: null,
      accountNumber: null,
      accountName: null,
      reference: null,
      basicDetailsSaveInfoDisabled: true, //save info button is disabled

      exisitingAllCollateralData:[],
      AccruedInterest:"0.00",
      totalNetExpenses:"0.00",
      ltvConversionDate: null,
      LTVCompany: null,
      LTVValue: null,
      totalLTVQuantity: null,
      totalLTVBag: null,
      totalLTVCompanyValuation: null,
      totalLTVCollateralValue: null,
      exposureData: [],

      //list for Loan Documents
      DisbursementDocuments: [],
      DisbursementDocumentFile: null,
      // DisbursementDocumentsButtonDisabled: true,
      DisbursementDocumentsDisabled: true,
      DisbursementDocumentSaveInfoDisabled: true, //save info button is disabled
      DisbursementDocumentsAddMoreDisabled: true,

      //list for Loan Mapping
      loanActualDisbursement:null,
      AvailableToDisburse: null,
      loanMappingFlag: false,
      loanMappingList: [],
      loanMappingSaveInfoDisabled: true, //save info button is disabled
      loanMappingAddMoreDisabled: false,
      totalDisbursementAmount: 0,

      approvalDate:null,
      loanID: null,
      flow:'Normal Disbursement',

      // for borrower level collateral value
      allSelectedFacility: null,
      allSelectedCollateralID: null,
      allCollateralData: [],
      exposures: [],
      price_types: [],
      borrowerLevelCollateralValue: '0.00',
      borrowerLeveltotalNetExpenses: '0.00',
      borrowerLevelActualDisbursement: null,

      LTVtoDisbursement: false,

      firstTimeFilteredCalled:false,

      isSubmitted: false,
      isBasicDetailsSubmitted:false,
      isOfftakerDetailsSubmitted:false,
    };
  },

  validations: {
    selectedBorrower: {
      required,
    },
    selectedFund: {
      required,
    },
    selectedFacility: {
      required: function () {
        if (this.selectedFacility.length < 1) return false;
        else return true;
      },
    },
    selectedCollateralID: {
      required: function () {
        if (this.selectedCollateralID.length < 1 && (this.selectedDisbursementOn == "1" || this.selectedDisbursementOn == '2')) return false;
        else return true;
      },
    },
    selectedDisbursementOn: {
      required,
    },
    // DisbursementAmount: {
    //   required: function () {
    //     if (!this.DisbursementAmount || this.DisbursementAmount == 0) return false;
    //     else return true;
    // //   },
    // //   maxLength: maxLength(15),
    // // },
    // DisbursementDate: {
    //   required,
    // },
    selectedOfftakerID: {
      required: function () {
        if (this.selectedOfftakerID == null && this.selectedDisbursementOn == "1") return false;
        else return true;
      },
    },
    selectedContractNo: {
      required: function () {
        if (this.selectedContractNo == null && this.selectedDisbursementOn == "1") return false;
        else return true;
      },
    },
    collateralValue: {
      required: function () {
        if ((!this.collateralValue || this.collateralValue == 0) && this.selectedDisbursementOn == "1") return false;
        else return true;
      },
      maxLength: maxLength(15),
    },
    selectedCollateralCurrency: {
      required: function () {
        if (this.selectedCollateralCurrency == null && this.selectedDisbursementOn == "1") return false;
        else return true;
      },
    },
    LTV:{
      required: function () {
        if ((!this.LTV || this.LTV == 0) && this.selectedDisbursementOn == "1") return false;
        else return true;
      },
      customValidation: function (value) {
        if ((value != null && value > 100) && this.selectedDisbursementOn == "1") {
          return false;
        }
        return true; // Return true if the validation is not needed
      },
    },
    bic: {
      alphaNum,
      minLength: minLength(8),
      maxLength: maxLength(11),
    },
    iban: {
      required: function () {
        if (this.iban === "0") return false;
        else return true;
      },
      alphaNum,
      maxLength: maxLength(35),
    },
    accountNumber: {
      required: function () {
        if (this.accountNumber === "0") return false;
        else return true;
      },
      numeric,
      maxLength: maxLength(17),
    },
    accountName: {
      required: function () {
        if (this.accountName === "0") return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    reference: {
      required: function () {
        if (this.reference === "0") return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    DisbursementDocuments: {
      $each: {
        comments: {
          maxLength: maxLength(100),
        },
      },
    },
    loanMappingList: {
      $each: {
        selectedLoan: {
          required,
        },
        loanDisbursementDate: {
          required,
        },
        loanDisbursementAmount: {
          required: function (val) {
            if (!val || val == 0) return false;
            else return true;
          },
          maxLength: maxLength(15),
        },
        // selectedLoanCurrency: {
        //   required,
        // },
        // loanConversionRate: {
        //   required,
        // },
        // loanConversionDate: {
        //   required,
        // },
        // loanConvertedAmount: {
        //   required: function (val) {
        //     if (!val || val == 0) return false;
        //     else return true;
        //   },
        //   maxLength: maxLength(15),
        // },

        // loanMappingRatio: {
        //   required: function (val) {
        //     if ((!val || val == 0) && this.loanMappingToggle) return false;
        //     else return true;
        //   },
        // },
        // loanActualDisbursement: {
        //   maxLength: function (val) {
        //     if (val ? val.length > 15 : false && !this.loanMappingToggle)
        //       return false;
        //     else return true;
        //   },
        //   required: function (val) {
        //     if ((!val || val == 0) && !this.loanMappingToggle) return false;
        //     else return true;
        //   },
        // },
      },
    },
  },

  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
    // //this is to computed disabled property of Basic Details Save Info button.
    // isDisabledBasicDetailsSaveInfo: function () {
    //   //We are checking whether all the input fields are filled. If condition is satisfied then only save info button will be enabled.
    //   if (
    //     this.selectedBorrower && 
    //     this.selectedFacility.length > 0 &&
    //     this.selectedDisbursementOn &&
    //     this.DisbursementAmount && 
    //     this.DisbursementDate
    //   ) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // }, //isDisabledBasicDetailsSaveInfo() close
    //this is to computed disabled property of Basic Details Save Info button.
    isDisabledBasicDetailsSaveInfo: function () {
      //We are checking whether all the input fields are filled. If condition is satisfied then only save info button will be enabled.
      if (
        this.selectedBorrower && 
        this.selectedFund && 
        this.selectedCollateralID.length > 0 && 
        this.selectedDisbursementOn && 
        this.selectedFacility.length > 0
      ) {
        this.basicDetailsSaveInfoDisabled = false;
      } else {
        this.basicDetailsSaveInfoDisabled = true;
      }
    }, //isDisabledBasicDetailsSaveInfo() close

    //this is to computed disabled property of DisbursementDocuments Save Info button.
    isDisabledDisbursementDocumentSaveInfo: function () {
      let checkEmptyDisbursementDocumentsDocName = this.DisbursementDocuments.filter(
        (line) => line.displayFileName === null
      );
      //if the line is empty then disable save info button
      if (
        this.DisbursementDocuments.length === 0 &&
        this.DisbursementDocumentSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyDisbursementDocumentsDocName.length >= 1 &&
        this.DisbursementDocuments.length > 0
      ) {
        this.DisbursementDocumentsDisabled = true;
      } else {
        this.DisbursementDocumentsDisabled = false;
      }
      //DisbursementDocumentsDisabled variable is to check whther all input fields except file upload are filled. DisbursementDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.DisbursementDocumentsDisabled === true ||
        this.DisbursementDocumentFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledDisbursementDocumentSaveInfo() close

    //this is to computed disabled property of Loan Mapping Save Info button.
    isDisabledLoanMappingSaveInfo: function () {
      let checkEmptyLoanMappingID = this.loanMappingList.filter(
        (line) => line.selectedLoan === null
      );
      // let checkEmptyLoanMappingActualDisAmt = this.loanMappingList
      //   .slice(-1)
      //   .filter((line) => line.loanActualDisbursement === null);
      // let checkEmptyLoanMappingAvilDis = this.loanMappingList
      //   .slice(-1)
      //   .filter((line) => line.AvailableToDisburse === null);
      let checkEmptyLoanMappingDisAmt = this.loanMappingList
        .slice(-1)
        .filter((line) => line.loanDisbursementAmount === null);
      let checkEmptyLoanMappingDisAmtString = this.loanMappingList.slice(-1).filter(
        (line) => line.loanDisbursementAmount === ''
      );
      let checkEmptyLoanMappingDisAmtZero = this.loanMappingList.slice(-1).filter(
        (line) => line.loanDisbursementAmount == 0
      );
      //if the line is empty then disable save info button
      if (
        this.loanMappingList.length === 0 &&
        this.loanMappingSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled. If condition is satisfied then only save info button will be enable
      if (
        (
          checkEmptyLoanMappingID.length >= 1 &&
          this.loanMappingList.length > 0
          ) ||
          (checkEmptyLoanMappingDisAmt.length >= 1 &&
            this.loanMappingList.length > 0) ||
          (checkEmptyLoanMappingDisAmtString.length >= 1 &&
          this.loanMappingList.length > 0) ||
          (checkEmptyLoanMappingDisAmtZero.length >= 1 &&
          this.loanMappingList.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledLoanMappingSaveInfo() close
  }, //main computed close

  components: {
    Datepicker,
    Multiselect: window.VueMultiselect.default,
  },
  methods: {
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },

    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },

    onChangeDisbursementOn(){
      // Collateral Value
      if (this.selectedDisbursementOn == "2"){
        this.isOfftakerDetailsSubmitted = false;
        this.selectedOfftakerID = null;
        this.selectedContractNo = null;
        this.collateralValue = null;
        this.selectedCollateralCurrency = null;
        this.LTV = null;     
        this.facilities = [];
        this.selectedFacility = [];
        this.selectedCollateralID = [];
        this.loanActualDisbursement = null;
        this.AvailableToDisburse = null;  
      }
      // No Collateral
      if (this.selectedDisbursementOn == "3" || this.selectedDisbursementOn == null){
        this.isOfftakerDetailsSubmitted = false;
        this.selectedOfftakerID = null;
        this.selectedContractNo = null;
        this.collateralValue = null;
        this.selectedCollateralCurrency = null;
        this.LTV = null;  
        this.facilities = [];
        this.selectedFacility = [];
        this.selectedCollateralID = [];
        this.loanMappingFlag=true;
        this.loanActualDisbursement = null;
        this.AvailableToDisburse = null; 
      }
    },

    //this method will make disabled property as false for Director Save Info
    onBasicDetailsSaveInfo() {
      console.log("onBasicDetailsSaveInfo")
      this.isBasicDetailsSubmitted = true;
      this.$v.selectedBorrower.$touch();
      this.$v.selectedFund.$touch();
      this.$v.selectedFacility.$touch();
      this.$v.selectedCollateralID.$touch();
      this.$v.selectedDisbursementOn.$touch();
      if (
          this.$v.selectedBorrower.$invalid ||
          this.$v.selectedFund.$invalid ||
          this.$v.selectedFacility.$invalid ||
          this.$v.selectedCollateralID.$invalid ||
          this.$v.selectedDisbursementOn.$invalid
        ) {
          // Handle invalid fields (e.g., show an error message)
          return;
        }
        // when selectedDisbursementOn is 1
        if (this.selectedDisbursementOn == '1'){
        Utils.make_alert("success", "Record saved.");
        console.log("hello")
        this.isOfftakerDetailsVisible = true;
        this.getOfftakerNameandLTV();
      }
      else if (this.selectedDisbursementOn == '2'){     // Collateral Value
        console.log("when selectedDisbursementOn is 2")
        // Creating a new array with the updated structure
        const updatedCollaterals = this.selectedCollateralID.map(coll => ({
            collateral_name: coll.collateral_name,
            collateral_id: coll.id
        }));
        // got to ltv check with borrower id and facility id this is when selectedDisbursementOn is 0
        this.$router.push({
          name: 'LTVChecker',
          params: {
              clientId: this.$store.state.client_id,
              selectedBorrower: this.selectedBorrower,
              selectedFund: this.selectedFund,
              selectedFacility: this.selectedFacility ,
              selectedFacilityIds: this.selectedFacilityIds ,
              selectedCollateralID: updatedCollaterals,
              fundCurrency: this.fundCurrency,
              selectedDisbursementOn: this.selectedDisbursementOn,
              LTV: this.LTV,
              flow: this.flow,
              loanID: this.loanID,
              approvalDate: this.approvalDate,
              collateralNames: this.collateralNames,
              DisbursementToLTV: true,
          }
        });
      
      }


    },

    onOfftakerDetailsSaveInfo() {
      this.isOfftakerDetailsSubmitted = true;
      this.$v.selectedOfftakerID.$touch();
      this.$v.selectedContractNo.$touch();
      this.$v.collateralValue.$touch();
      this.$v.selectedCollateralCurrency.$touch();
      this.$v.LTV.$touch();
      if (
          this.$v.selectedOfftakerID.$invalid ||
          this.$v.selectedContractNo.$invalid ||
          this.$v.collateralValue.$invalid ||
          this.$v.selectedCollateralCurrency.$invalid ||
          this.$v.LTV.$invalid
        ) {
          // Handle invalid fields (e.g., show an error message)
          return;
        }
      Utils.make_alert("success", "Record saved.");      
    },

    //this method will make disabled property as false for Director Save Info
    onDisbursementDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.DisbursementDocumentSaveInfoDisabled = false;
      this.DisbursementDocumentsAddMoreDisabled = false;
    },

    //this method will make disabled property as false for Loan Mapping Save Info
    onLoanMappingSaveInfo() {
      // Assuming this.loanMappingList is the array of objects and this.loans is the array of loan objects
      let flag = false;

      // Check for duplicates and set the flag and error message
      this.loanMappingList.forEach((loan, index) => {
        console.log("loan",loan)
        console.log("loanOSAmount",loan.loanOSAmount)
        if(Number(loan.loanDisbursementAmount) > Number(loan.loanOSAmount)){
          loan.loanDisbursementAmountError = true;
          loan.loanDisbursementAmountErrorMessage = `Disbursement Amt.(Fund Currency) cannot be greater than Outstanding Amount ${loan.loanOSAmount}`;
          flag = true;
          return;
        } else {
          flag = false;
          loan.loanDisbursementAmountError = false;
          loan.loanDisbursementAmountErrorMessage = "";
        }
      });

      if(!flag) {
        if(this.selectedDisbursementOn == 1 || this.selectedDisbursementOn == 2){
          this.calcTotalDisbursementAmount();
          if (this.totalDisbursementAmount > this.loanActualDisbursement) {
            let warningMessage = `Sum of Disbursement Amt.(Fund Currency) should not exceed the limit of Actual Disbursement:  ${this.loanActualDisbursement} ${this.currencyShortName} `;
            Utils.make_alert("warning", warningMessage);
          } else {
            this.loanActualDisbursement = this.loanActualDisbursement;
            this.AvailableToDisburse = (Number(this.loanActualDisbursement) - Number(this.totalDisbursementAmount)).toFixed(2);
            Utils.make_alert("success", "Record saved.");
            this.loanMappingSaveInfoDisabled = false;
            this.loanMappingAddMoreDisabled = false;
          }
          this.totalDisbursementAmount = 0;
        } else {
          Utils.make_alert("success", "Record saved.");
          this.loanMappingSaveInfoDisabled = false;
          this.loanMappingAddMoreDisabled = false;
        }
      } else {
        this.loanMappingAddMoreDisabled = true;
      }
    },

    calcTotalDisbursementAmount() {
      console.log(this.loanMappingList)
      for (let list in this.loanMappingList) {
        console.log(list)
        console.log(this.loanMappingList[list])
        this.totalDisbursementAmount += parseFloat(
          this.loanMappingList[list].loanDisbursementAmount
            ? this.loanMappingList[list].loanDisbursementAmount
            : 0
        );
      }
    },

    onloanDisbursementAmountChnage(index){
      let amount = this.loanMappingList[index].loanDisbursementAmount;
      let rate = this.loanMappingList[index].loanConversionRate;
      
      if (amount % 1 !== 0 && amount.toString().split(".")[1].length >= 2) {
        console.log("There are: " + amount)
        this.loanMappingList[index].loanDisbursementAmount = parseFloat(amount).toFixed(2);
      } 
      this.loanMappingList[index].loanConvertedAmount = (amount*rate).toFixed(2)
    },

    // this method will check if existing loan mapping is added again
    getLoanBackcappingDate(index) {
      console.log("getLoanBackcappingDate")
      let loanID = this.loanMappingList[index].selectedLoan
      console.log("this.loanMappingList[index].selectedLoan",this.loanMappingList[index].selectedLoan)
      
      const path = "/get_loan_backcap_date/" + loanID;
      Utils.start_loading();
      axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
      .then((res) => {
        Utils.stop_loading();
          console.log(res.data.status)
          if (res.data.status == 'success'){
            this.loanMappingList[index].backCapDate = res.data.transaction_date;
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
      
    },

    // this method will check if existing loan mapping is added again
    onLoanSelectGetDetails(index) {
      console.log("this.loanID",this.loanID)
      if(this.flow == 'Loan'){
        this.loanMappingList[index].selectedLoan = this.loanID;
      }
      console.log("this.loanMappingList[index].selectedLoan",this.loanMappingList[index].selectedLoan)
      let loan_count = this.loanMappingList.filter(
        (col) => col.selectedLoan == this.loanMappingList[index].selectedLoan
        ).length;
      console.log("loan_count",loan_count)
      
      // delete if existing loan is found
      if (loan_count > 1) {
        this.deleteLoanMapping(index);
        Utils.make_alert("warning", "Loan Mapping already exists");
        return;
      }
      
      console.log("this.loanMappingList[index].selectedLoan)",this.loanMappingList[index].selectedLoan)
      console.log("this.loans)",this.loans)
      let loan = this.loans.find(
        (col) => col.loan_master_id == this.loanMappingList[index].selectedLoan)
      console.log("loan",loan)
      let loanCurrencyID = loan.currency_id
      let loanDisbursementDate = new Date()

      this.loanMappingList[index].facilityID = loan.facility_setup_id;
      this.loanMappingList[index].loanDisbursementDate = loanDisbursementDate;
      if(loan.loan_balance_converted_amount != null){
        this.loanMappingList[index].loanOSAmount = loan.loan_balance_converted_amount.replaceAll(",", "");
        this.loanMappingList[index].loanDisbursementAmount = loan.loan_balance_converted_amount.replaceAll(",", "");
      } else {
        this.loanMappingList[index].loanOSAmount = null;
        this.loanMappingList[index].loanDisbursementAmount = null;
      }
      this.loanMappingList[index].selectedLoanCurrency = loanCurrencyID;

      console.log("inside onLoanSelectGetDetails and getFundCurrencyExchangeRateByCurrencyDate")
      this.getloanConversionRateByCurrencyDate(index , loanDisbursementDate)
      this.getLoanBackcappingDate(index)
    },

    // updateLoanMappingList(tempLoanMappingList) {
    // // Assuming tempLoan is the array of objects received from the API
    // this.loanMappingList = tempLoan.reduce((updatedList, apiData) => {
    //   const existingItemIndex = updatedList.findIndex((item) => item.selectedLoan === apiData.selectedLoan);

    //   if (existingItemIndex !== -1) {
    //     // If the object with the selectedLoan is found in the loanMappingList, update it
    //     updatedList.splice(existingItemIndex, 1, { ...updatedList[existingItemIndex], ...apiData });
    //   } else {
    //     // If the object with the selectedLoan is not found in the loanMappingList, add it
    //     updatedList.push(apiData);
    //   }
    //       return updatedList;
    //   }, [...this.loanMappingList]);
    // },

    removeDisbursementDocuments(id) {
      this.DisbursementDocumentFile = null;
      this.DisbursementDocuments[id].selectedFile = null;
      this.DisbursementDocuments[id].selectedFileName = null;
      this.DisbursementDocuments[id].displayFileName = null;
      this.DisbursementDocuments[id].comments = null;
      this.DisbursementDocuments[id].DisbursementDocumentsRemoveDisabled = true;
    },

    deleteDisbursementDocuments(index) {
      this.DisbursementDocumentFile = this.DisbursementDocumentFile
        ? this.DisbursementDocumentFile
        : "file deleted";
      this.DisbursementDocuments.splice(index, 1);
      if (
        typeof this.DisbursementDocuments !== "undefined" &&
        this.DisbursementDocuments.length == 0
      ) { 
        this.DisbursementDocumentsAddMoreDisabled = false;
        this.DisbursementDocumentSaveInfoDisabled = true;
      }
    },

    deleteLoanMapping(index) {
      this.loanMappingList.splice(index, 1);
      if (
        typeof this.loanMappingList !== "undefined" &&
        this.loanMappingList.length === 0
      ) {
        this.loanMappingAddMoreDisabled = false;
        this.loanMappingSaveInfoDisabled = true;
      }
    },

    onSubmit() {
      this.isBasicDetailsSubmitted = true;
      this.$v.selectedBorrower.$touch();
      this.$v.selectedFund.$touch();
      this.$v.selectedFacility.$touch();
      this.$v.selectedCollateralID.$touch();
      this.$v.selectedDisbursementOn.$touch();
      if (
        this.$v.selectedBorrower.$invalid ||
        this.$v.selectedFund.$invalid ||
        this.$v.selectedFacility.$invalid ||
        this.$v.selectedCollateralID.$invalid ||
        this.$v.selectedDisbursementOn.$invalid
      ) {
        // Handle invalid fields (e.g., show an error message)
        return;
      }
      
      if (this.selectedDisbursementOn == '2' && !this.LTVtoDisbursement){     // Collateral Value
        console.log("when selectedDisbursementOn is 2")
        const updatedCollaterals = this.selectedCollateralID.map(coll => ({
            collateral_name: coll.collateral_name,
            collateral_id: coll.id
        }));
        // got to ltv check with borrower id and facility id this is when selectedDisbursementOn is 0
        this.$router.push({
          name: 'LTVChecker',
          params: {
            clientId: this.$store.state.client_id,
              selectedBorrower: this.selectedBorrower,
              selectedFund: this.selectedFund,
              selectedFacility: this.selectedFacility ,
              selectedFacilityIds: this.selectedFacilityIds ,
              selectedCollateralID: updatedCollaterals,
              fundCurrency: this.fundCurrency,
              selectedDisbursementOn: this.selectedDisbursementOn,
              LTV: this.LTV,
              flow: this.flow,
              loanID: this.loanID,
              approvalDate: this.approvalDate,
              collateralNames: this.collateralNames,
              DisbursementToLTV: true,
          }
        });
        return;
      }
      this.isSubmitted = true;
      this.isBasicDetailsSubmitted = true;
      this.isOfftakerDetailsSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.totalDisbursementAmount = 0;
        return;
      }
      this.borrowerLevelActualDisbursement = parseFloat(this.borrowerLevelActualDisbursement);
      this.loanActualDisbursement = parseFloat(this.loanActualDisbursement);
      if(this.borrowerLevelActualDisbursement >= 0){
        if(this.borrowerLevelActualDisbursement < this.loanActualDisbursement){
          this.loanMappingAddMoreDisabled = true;
          this.loanMappingSaveInfoDisabled = true;
          this.loanMappingList = [];
          Utils.make_alert("warning",`As the Borrower Level Disbursement Value is not greater than the Actual Disbursement Value, the subsequent loan mapping / disbursement activity cannot be completed.`)
          return;
        }
      }
      this.AvailableToDisburse = parseFloat(this.AvailableToDisburse);
      if(this.AvailableToDisburse < 0){
        this.loanMappingAddMoreDisabled = true;
        this.loanMappingSaveInfoDisabled = true;
        this.loanMappingList = [];
        Utils.make_alert("warning", `Kindly add Collateral to Proceed with Drawdown Transaction. <br/> Note: Available to Disburse amount cannot be negative `)
        return;
      }
      if (this.loanMappingList.length == 0) {
        let warningMessage = `Please Map Some loan`;
        Utils.make_alert("warning", warningMessage);
        return;
      }

      let flag = false;
      // Check for duplicates and set the flag and error message
      this.loanMappingList.forEach((loan, index) => {
        if(Number(loan.loanDisbursementAmount) > Number(loan.loanOSAmount)){
          loan.loanDisbursementAmountError = true;
          loan.loanDisbursementAmountErrorMessage = `Disbursement Amt.(Fund Currency) cannot be greater than Outstanding Amount ${loan.loanOSAmount}`;
          flag = true;
          return;
        } else {
          flag = false;
          loan.loanDisbursementAmountError = false;
          loan.loanDisbursementAmountErrorMessage = "";
        }
      });

      if(!flag) {
        if(this.selectedDisbursementOn == 1 || this.selectedDisbursementOn == 2){
          this.calcTotalDisbursementAmount();
          if (this.totalDisbursementAmount > this.loanActualDisbursement) {
            let warningMessage = `Sum of Disbursement Amt.(Fund Currency) should not exceed the limit of Actual Disbursement:  ${this.loanActualDisbursement} ${this.currencyShortName} `;
            Utils.make_alert("warning", warningMessage);
            return;
          } else {
            this.loanActualDisbursement = this.loanActualDisbursement;
            this.AvailableToDisburse = (Number(this.loanActualDisbursement) - Number(this.totalDisbursementAmount)).toFixed(2);
            this.loanMappingSaveInfoDisabled = false;
            this.loanMappingAddMoreDisabled = false;
          }
          this.totalDisbursementAmount = 0;
        } else {
          this.loanMappingSaveInfoDisabled = false;
          this.loanMappingAddMoreDisabled = false;
        }
      } else {
        this.loanMappingAddMoreDisabled = true;
        return;
      }

      // let ltvConversionDate = null;
      // if (this.ltvConversionDate != null) {
      //   ltvConversionDate = moment(this.ltvConversionDate).format("MM-DD-YYYY");
      // }

      for (let i = 0; i < this.loanMappingList.length; i++) {
        if (this.loanMappingList[i].loanDisbursementDate !== null) {
          this.loanMappingList[i].loanDisbursementDate = moment(
            this.loanMappingList[i].loanDisbursementDate
          ).format("MM-DD-YYYY");
        }
        if (this.loanMappingList[i].loanConversionDate !== null) {
          this.loanMappingList[i].loanConversionDate = moment(
            this.loanMappingList[i].loanConversionDate
          ).format("MM-DD-YYYY");
        }
      }

      // for (let i = 0; i < this.exposureData.length; i++) {
      //   if (this.exposureData[i].conversion_date !== null) {
      //     this.exposureData[i].conversion_date = moment(
      //       this.exposureData[i].conversion_date
      //     ).format("MM-DD-YYYY");
      //   }
      // }

      this.DisbursementDocuments.map((doc, index) => {
        if (doc.displayFileName === null) this.DisbursementDocuments.splice(index, 1);
      });
      
      console.log("selectedCollateralID",this.selectedCollateralID)

      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("selectedBorrower", this.selectedBorrower);
      formData.append("selectedFund", this.selectedFund);
      formData.append("selectedDisbursementOn", this.selectedDisbursementOn);
      formData.append("selectedCollateralID", JSON.stringify(this.selectedCollateralID));
      formData.append("selectedFacility", JSON.stringify(this.selectedFacility));

      // formData.append("selectedOfftakerID", this.selectedOfftakerID);
      // formData.append("selectedContractNo", this.selectedContractNo);
      // formData.append("collateralValue", this.collateralValue);
      // formData.append("selectedCollateralCurrency", this.selectedCollateralCurrency);
      // formData.append("LTV", this.LTV);

      formData.append("selectedBank", this.selectedBank);
      formData.append("bic", this.bic);
      formData.append("iban", this.iban);
      formData.append("accountNumber", this.accountNumber);
      formData.append("accountName", this.accountName);
      formData.append("reference", this.reference);
      
      //append loan mapping information to formdata
      formData.append("loanActualDisbursement", this.loanActualDisbursement);
      formData.append("AvailableToDisburse", this.AvailableToDisburse);
      formData.append("loanMappingList", JSON.stringify(this.loanMappingList));
      
      // formData.append("totalNetExpenses", this.totalNetExpenses);
      // formData.append("AccruedInterest", this.AccruedInterest);
      // formData.append("LTVCompany", this.LTVCompany);
      // formData.append("ltvConversionDate", ltvConversionDate);
      // formData.append("LTVValue", this.LTVValue);
      // formData.append("totalLTVQuantity", this.totalLTVQuantity);
      // formData.append("totalLTVBag", this.totalLTVBag);
      // formData.append("totalLTVCompanyValuation", this.totalLTVCompanyValuation);
      // formData.append("totalLTVCollateralValue", this.totalLTVCollateralValue);

      // formData.append("exposureData", JSON.stringify(this.exposureData));
      //append DisbursementDocuments information to formdata
      formData.append("DisbursementDocuments", JSON.stringify(this.DisbursementDocuments));
      
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 4);
      formData.append("tabId", 35);
      const path = "/add-disbursement"
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          if (response.data.status == "success") {
            let loanIDs = response.data.loan_ids
            console.log("loanIDs",loanIDs)
            if(loanIDs.length > 0) {
              this.getReportLoanTransaction(loanIDs);
            }
          } else {
            Utils.make_alert("success", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
          Utils.make_alert("success", "Something went wrong!");
        });
    }, //onSubmit() close

    getReportLoanTransaction(loanIDs){
      console.log("getReportLoanTransaction")
      const path = "/report_loan_transaction/" + loanIDs + "/" + this.$store.state.client_id;
      Utils.start_loading();
      axios
      .post(path)
      .then((res) => {
        Utils.stop_loading();
          console.log(res.data.status)
          if (res.data.status == 'success'){
            Utils.make_alert(
              "success",
              "Disbursement Details saved successfully!"
            );
            console.log("SUCCESS!!");
            if(this.flow == "Normal Disbursement"){
              this.$router.push("/facility");
            } else {
              this.$router.push("/loan-util-overview-by-id/" + this.selectedBorrower + "/" + this.loanID);
            }
          } else {
            Utils.make_alert("success", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    //this method will add new line for additional Cost Documents
    addCostDoc() {
      //when new line is added then DisbursementDocumentFile variable and DisbursementDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.DisbursementDocumentFile = null),
        (this.DisbursementDocumentSaveInfoDisabled = true),
        (this.DisbursementDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.DisbursementDocuments.push({
          displayFileName: null,
          comments: null,
          errors: { filesize: "" },
          DisbursementDocumentsRemoveDisabled:true,

        });
    }, //addCostDoc() close

    getCurrencyShortNameById(id) {
      const currency = this.currencies.find((currency) => currency.id === id);
      this.currencyShortName = currency.currency_short_name
    },

    onChangeDisbursementAmountValidation(index){
      this.onloanDisbursementAmountChnage(index);
      console.log("onChangeDisbursementAmountValidation")
      let loanDisbursementAmount = this.loanMappingList[index].loanDisbursementAmount ;
      let loanOSAmount = this.loanMappingList[index].loanOSAmount ;
        if(Number(loanDisbursementAmount) > Number(loanOSAmount)){
          this.loanMappingList[index].loanDisbursementAmountError = true;
          this.loanMappingList[index].loanDisbursementAmountErrorMessage = `Disbursement Amt.(Fund Currency) cannot be greater than Outstanding Amount ${loanOSAmount}`;
          return;
        } else {
          this.loanMappingList[index].loanDisbursementAmountError = false;
          this.loanMappingList[index].loanDisbursementAmountErrorMessage = "";
          return;
        }
    },

    //this method will add new line for additional loan mapping
    addLoanMapping() {
      if(this.selectedDisbursementOn == 1 || this.selectedDisbursementOn == 2){
          this.calcTotalDisbursementAmount();
          if (this.totalDisbursementAmount > this.loanActualDisbursement) {
            if(!this.LTVtoDisbursement){
              let warningMessage = `Sum of Disbursement Amt.(Fund Currency) should not exceed the limit of Actual Disbursement:  ${this.loanActualDisbursement} ${this.currencyShortName} `;
              Utils.make_alert("warning", warningMessage);
            }
          } else {
              //when new line is added then loanMappingSaveInfoDisabled (Save info button disable property) should be re initialised to true.
              (this.loanMappingSaveInfoDisabled = true),
              (this.loanMappingAddMoreDisabled = true),
              //following block is created first time on mounted property
              this.loanActualDisbursement = this.loanActualDisbursement;
              this.AvailableToDisburse = (Number(this.loanActualDisbursement) - Number(this.totalDisbursementAmount)).toFixed(2);
              this.loanMappingList.push({
                selectedLoan: null,
                facilityID: null,
                loanDisbursementDate: null,
                loanOSAmount: null,
                loanDisbursementAmount: null,
                selectedLoanCurrency:null,
                loanConversionRate: null,
                loanConversionDate: null,
                backCapDate: new Date(),
                loanConvertedAmount: null,
                loanDisbursementAmountError: false,
                loanDisbursementAmountErrorMessage: "",
              });
          }
          this.totalDisbursementAmount = 0;
        } else {
            //when new line is added then loanMappingSaveInfoDisabled (Save info button disable property) should be re initialised to true.
            (this.loanMappingSaveInfoDisabled = true),
            (this.loanMappingAddMoreDisabled = true),
            //following block is created first time on mounted property
            this.loanMappingList.push({
              selectedLoan: null,
              facilityID: null,
              loanDisbursementDate: null,
              loanOSAmount: null,
              loanDisbursementAmount: null,
              selectedLoanCurrency:null,
              loanConversionRate: null,
              loanConversionDate: null,
              backCapDate: new Date(),
              loanConvertedAmount: null,
              loanDisbursementAmountError: false,
              loanDisbursementAmountErrorMessage: "",
            });
        }
    }, //addLoanMapping() close

    //handles a change on the file upload
    handleFileUploadDisbursementDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.DisbursementDocumentFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.DisbursementDocumentFile.size > 104857600) {
          e.preventDefault();
          self.DisbursementDocuments[id].errors.filesize =
            "File size should be less than 100MB";
          self.DisbursementDocuments[id].selectedFile = null;
          self.DisbursementDocuments[id].selectedFileName = null;
          self.DisbursementDocuments[id].displayFileName = null;
          self.DisbursementDocuments[id].DisbursementDocumentsRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.DisbursementDocumentFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.DisbursementDocuments[id].selectedFile = reader.result;
          self.DisbursementDocuments[id].selectedFileName = e.target.files[0].name;
          self.DisbursementDocuments[id].displayFileName = e.target.files[0].name; //to show filename in file input
          self.DisbursementDocuments[id].errors.filesize = "";
          self.DisbursementDocuments[id].DisbursementDocumentsRemoveDisabled = false;

        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadDisbursementDocuments() close

    getloanConversionRateByCurrencyDate(index , date) {
      //this function is called to get loan currency of that facility
      console.log("inside getloanConversionRateByCurrencyDate")
      let loan = this.loanMappingList.find(
        (col) => col.selectedLoan === this.loanMappingList[index].selectedLoan)
      console.log("loanCurrencyID",loan)
      let loanCurrencyID = loan.selectedLoanCurrency
      console.log("loanCurrencyID",loanCurrencyID)
      let Date = null;
      if (date != null) {
        Date = moment(date).format("MM-DD-YYYY");
      }
      let currencyID = null;
      if (this.selectedDisbursementOn == '1'){
        currencyID = this.selectedCollateralCurrency
      } else if (this.selectedDisbursementOn == '2' || this.selectedDisbursementOn == '3'){
        currencyID = this.fundCurrency
      }
      console.log("currencyID",currencyID)
      if(loanCurrencyID === currencyID){
        this.loanMappingList[index].loanConversionRate = 1;
        this.loanMappingList[index].loanConversionDate = Date;
        this.onloanDisbursementAmountChnage(index);
        return;
      }
      const path = "/currency-exchange-rate/" +
        loanCurrencyID +
        "/" +
        currencyID +
        "/" +
        Date
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log(res.data)
          let data = res.data.currency_rate[0]
          if(data != null){
              this.loanMappingList[index].loanConversionRate = data.currency_value;
              this.loanMappingList[index].loanConversionDate = data.date;
              this.onloanDisbursementAmountChnage(index);
            }else{
              Utils.make_alert("warning", "No currency conversion rate available.");
              this.loanMappingList[index].loanConversionRate = null;
              this.loanMappingList[index].loanConversionDate = null;
              this.onloanDisbursementAmountChnage(index);
            }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    getAllFacilitySetupByBorrower() {
      //this function is called to get list of day count
      const path = "/all-facility-setup-borrower/" + this.selectedBorrower;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // console.log(res.data.Facility_Setup);
          this.facilities = res.data.Facility_Setup;
          this.selectedFacility = [];
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLoan() {
      //this function is called to get list of loans
      console.log("inside get Loan")
      if((this.selectedDisbursementOn == '2') && !this.LTVtoDisbursement){
        return
      } 
      let finalSelectedFacility = null
      if(this.selectedFacility){
        finalSelectedFacility = this.selectedFacility.map(facility => facility.id);
      } else {
        finalSelectedFacility = null
      }
      Utils.start_loading()
      const path = "/get-loan-by-facilities/" + finalSelectedFacility + "/" + "loan";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          Utils.stop_loading()
          if(res.data.status == "success"){
            this.loans = res.data.loan_list;
            if(this.flow == 'Loan'){
              // this.addLoanMapping()
              this.onLoanSelectGetDetails(0)
            }
          }
        })
        .catch(error => {
          Utils.stop_loading()
          console.error(error);
        });
    },

    getOfftakerNameandLTV() {
      console.log("inside get LTV");
      // let selectedFacilities = this.selectedFacility;
      // const selectedIds = selectedFacilities.map(facility => facility.id);
      // console.log(selectedIds); // Do something with the selected IDs
      // this.selectedFacilityIds = selectedIds;

      // const path =
      //   "/get-offtaker-name/" +
      //   this.selectedBorrower +
      //   "?clientId=" +
      //   this.$store.state.client_id +
      //   selectedIds.map(id => `&facilityIDs=${id}`).join("");

      // axios
      //   .get(path)
      //   .then(res => {
      //     this.OfftakerName = res.data.offtaker_name;
      //     this.LTV = res.data.average_ltv;
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
    },

    getOfftakerDetails(){
      let id = this.selectedContractNo
      let offtakerDetails = this.ContractNo.filter((col) => col.id === id);

      if(offtakerDetails.length > 0){
        this.collateralValue = offtakerDetails[0].contract_value
        this.selectedCollateralCurrency = offtakerDetails[0].currency_id
      }

    },


    getContractNoByOfftakerName() {
      //this function is called to get list of LTV
      console.log("inside get LTV")

      const path = "/get-offtaker-contract/" + this.selectedOfftakerID;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          this.ContractNo = res.data.offtaker_contract;
        })
        .catch(error => {
          console.error(error);
        });
    },

    
    getFacilityByFundBorrowerCollateral() {
      console.log("getFacilityByFundBorrowerCollateral")
      if(this.selectedFund && this.selectedBorrower && this.selectedDisbursementOn){
          if (this.selectedFund) {
            let fund = this.Funds.find(fund => fund.fund_id == this.selectedFund)
            this.fundCurrency = fund.fund_currency_id
            this.getCurrencyShortNameById(this.fundCurrency)
          }
          if((this.selectedDisbursementOn == '1' || this.selectedDisbursementOn == '2') && this.selectedCollateralID.length == 0){
            return
          } 
          if (this.selectedDisbursementOn == '3') {
            this.selectedCollateralID = [];
            this.facilities = [];
            this.selectedFacility = [];
          }
          let finalSelectedCollateralID = null
            if(this.selectedCollateralID){
              finalSelectedCollateralID = this.selectedCollateralID.map(collateral => collateral.id);
            } else {
              finalSelectedCollateralID = null
            }
          Utils.start_loading();
          console.log("getFacilityByFundBorrowerCollateral final API CALL")
          const path = 
          "/get-facility-by-fund-borrower-disbursement/" + 
          this.selectedFund +
          "/" + 
          this.selectedBorrower +
          "/" + 
          this.selectedDisbursementOn +
          "/" + 
          finalSelectedCollateralID;
            axios
              .get(path, { params: { clientId: this.$store.state.client_id } })
              .then((res) => {
                Utils.stop_loading();
                console.log("status", res.data.status)
                if(res.data.status == "success"){
                  this.facilities = res.data.facility_list;
                  if(this.facilities.length == 0){
                    Utils.make_alert("warning", "No facility found");
                    this.selectedFacility = [];
                    this.selectedCollateralID = [];
                  } else {
                    // Extract the ids from facilities
                    const facilityIds = this.facilities.map(facility => facility.collateral_id);
                    console.log("facilityIds",facilityIds)

                    // Keep a copy of the original selectedCollateralID for comparison
                    const originalSelectedCollateralID = [...this.selectedCollateralID];

                    // Filter the selectedCollateralID array to keep only those items that exist in facility_list
                    this.selectedCollateralID = this.selectedCollateralID.filter(collateral => 
                        facilityIds.includes(collateral.id)
                    );
                    console.log("selectedCollateralID",this.selectedCollateralID)
                    
                    // Find removed items by comparing original and filtered arrays
                    const removedCollaterals = originalSelectedCollateralID.filter(collateral => 
                        !this.selectedCollateralID.some(filteredCollateral => 
                        filteredCollateral.id == collateral.id)
                        );
                    console.log("removedCollaterals",removedCollaterals)
                    // Check if any items were removed and log the names
                    if (removedCollaterals.length > 0) {
                        const removedNames = removedCollaterals.map(collateral => collateral.collateral_name).join(', ');
                        Utils.make_alert("warning", `No facility found for collateral: ${removedNames}`);
                        console.log(`Removed collaterals: ${removedNames}`);
                    } else {
                        console.log("No collaterals were removed, proceeding with the rest of the code");
                    }
                  }
                  if(!this.LTVtoDisbursement && this.flow == 'Normal Disbursement'){
                    this.selectedFacility = [];
                  } else {
                    console.log("Loan Flow calling get loan")
                    this.getLoan()
                    // this.getOfftakerNameandLTV()
                  }
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error(error);
              });
      }
    },

    // getFacilitySetupByFundBorrower() {
    //   if (this.selectedFund) {
    //     let fund = this.Funds.find(fund => fund.fund_id == this.selectedFund)
    //     this.fundCurrency = fund.fund_currency_id
    //   }
    //   const path = 
    //   "/get-facility-by-fund-borrower/" + 
    //   this.selectedBorrower +
    //   "/" + 
    //   this.selectedFund;
    //   axios
    //     .get(path, { params: { clientId: this.$store.state.client_id } })
    //     .then((res) => {
    //       console.log("status", res.data.status)
    //       if(res.data.status == "success"){
    //         this.facilities = res.data.facility_list;
    //         if(!this.LTVtoDisbursement){
    //           this.selectedFacility = [];
    //           this.collateralNames = [];
    //         } else {
    //           this.getLoan()
    //           this.getOfftakerNameandLTV()
    //         }
    //         this.getFacilityByFundBorrowerCollateral();
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },
    
    getBorrowersByFund() {
      console.log("getBorrowersByFund",this.selectedFund)
      const path = "/get-borrower-by-fund/" + this.selectedFund;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            this.borrowers = res.data.borrower_list;
            if(!this.LTVtoDisbursement && this.flow == 'Normal Disbursement'){
              this.selectedBorrower = null;
              this.selectedDisbursementOn = null;
              this.selectedCollateralID = [];
              this.selectedFacility = [];
              this.facilities = [];
            }else{
              this.getFacilityByFundBorrowerCollateral();
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFacilitySetupByFundBorrowerLTV() {
      console.log('getFacilitySetupByFundBorrowerLTV')
      const path = 
      "/get-facility-by-fund-borrower/" + 
      this.selectedBorrower +
      "/" + 
      this.selectedFund;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            let facilities = res.data.facility_list;
            this.allSelectedFacility = facilities.map(facility => facility.id);
            this.getCollateralByFacilityLTV();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCollateralByFacilityLTV() {
      console.log("getCollateralByFacilityLTV")
      Utils.start_loading();
      const path = 
      "/get-collateral-by-facility" +
      "/" +
      this.selectedFund +
      "/" +
      this.allSelectedFacility +
      "/" +
      false;
      console.log(path)
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            let collateralNames = res.data.collateral_name_id;
            this.allSelectedCollateral = collateralNames.map(col => col.collateral_id);
            this.getTotalNetExpense()
          } else {
            Utils.make_alert("warning","Something went wrong from server!")
          }
          
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
        });
    },

    getTotalNetExpense() {
      console.log('getTotalNetExpense')
      this.totalNetExpenses =  "0.00"
      this.allCollateralData = [];

      let conversionDate = moment(new Date()).format("MM-DD-YYYY");
    
      const path = 
        "/total-net-expense" +
        "/" +
        this.selectedBorrower +
        "/" +
        this.allSelectedFacility +
        "/" +
        this.fundCurrency +
        "/" +
        conversionDate +
        "/" +
        false 
        console.log(path)
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            console.log(res.data.total_net_expense)
            console.log(res.data.total_net_expense[0].net_expense)
            if(res.data.total_net_expense[0].net_expense != null){
              if(res.data.total_net_expense[0].net_expense){
                this.borrowerLeveltotalNetExpenses =  res.data.total_net_expense[0].net_expense;
              } else {
                this.borrowerLeveltotalNetExpenses =  "0.00"
              }
            } else {
              this.borrowerLeveltotalNetExpenses =  "0.00"
            }
            this.getCollateralExposures();
          } else {
            Utils.make_alert("warning","Something went wrong from server!")
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });

    },

    getCollateralExposures() {
      console.log("inside getCollateralExposures")
      let conversionDate = moment(new Date()).format("MM-DD-YYYY");
      console.log("this.allSelectedFacility",this.allSelectedFacility)
      console.log("this.allSelectedCollateral",this.allSelectedCollateral)
      const path = 
        "get-exposure-data" +
        "/" +
        this.selectedBorrower +
        "/" +
        this.allSelectedFacility +
        "/" +
        this.allSelectedCollateral +
        "/" +
        this.fundCurrency +
        "/" +
        conversionDate +
        "/" +
        false; 
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log("status", res.data.status)
          if(res.data.status == "success"){
            this.exposures =  res.data.exposures;
            const combinedData = {};

            this.exposures.forEach(exposure => {
              if (!combinedData[exposure.collateral_id]) {
                combinedData[exposure.collateral_id] = {
                  id: exposure.collateral_id,
                  collateral_id: exposure.collateral_id,
                  collateral_name: exposure.collateral_name,
                  exposures: [],
                };
              }
              combinedData[exposure.collateral_id].exposures.push(exposure);
            });

            // Convert the combinedData object to an array of objects
            const result = Object.values(combinedData);

            // Assign the result to your data property
            this.allCollateralData = result;

            console.log(this.price_types)
            // Loop through allCollateralData and add transactions
            this.allCollateralData.forEach(collateral => {collateral.price_types = this.price_types.filter(pt => pt.collateral_id === collateral.collateral_id)}); 
            this.allCollateralData = this.allCollateralData.map(collateral => {
              const exposuresWithCalculations = collateral.exposures.map(exposure => {
                const quantity = parseFloat(exposure.quantity.replace(/,/g, '')).toFixed(2);
                const collateralValue = (parseFloat(quantity) * (parseFloat(exposure.price) * parseFloat(exposure.conversion_rate))).toFixed(2);
                const availableToDisburse = ((parseFloat(collateralValue) * parseFloat(exposure.discount_value)) / 100).toFixed(2);
                console.log("collateralValue",collateralValue)
                console.log("available_to_disburse",availableToDisburse)
                return {
                  ...exposure,
                  collateralValue: parseFloat(collateralValue), // Convert string back to number if necessary
                  available_to_disburse: parseFloat(availableToDisburse), // Convert string back to number if necessary
                };
              });

              return {
                ...collateral,
                exposures: exposuresWithCalculations,
              };
            });

            console.log(this.allCollateralData)

            if (this.allCollateralData && this.allCollateralData.length > 0) {
                console.log("setting borrowerLevelActualDisbursement with if")
                  let total = this.allCollateralData.reduce((acc, collateral) => {
                      if (collateral.exposures) {
                        return acc + collateral.exposures.reduce((innerAcc, exposure) => {
                          return innerAcc + parseFloat(exposure.available_to_disburse);
                        }, 0);
                      }
                      return acc;
                  }, 0);
                  console.log("outside",total)
                  console.log("borrowerLeveltotalNetExpenses",this.borrowerLeveltotalNetExpenses)
                  let borrowerLeveltotalNetExpenses = parseFloat(this.borrowerLeveltotalNetExpenses.replace(/,/g, ''));
                  let final_total = total - borrowerLeveltotalNetExpenses;
                  this.borrowerLevelActualDisbursement = final_total.toFixed(2);
                  console.log("borrowerLevelActualDisbursement", this.borrowerLevelActualDisbursement)
                } else {
                  console.log("setting borrowerLevelActualDisbursement else")
                  if(this.borrowerLeveltotalNetExpenses){   
                    console.log("setting borrowerLevelActualDisbursement with else")
                    let borrowerLeveltotalNetExpenses = parseFloat(this.borrowerLeveltotalNetExpenses.replace(/,/g, ''));
                    let final_total = - borrowerLeveltotalNetExpenses;
                    this.borrowerLevelActualDisbursement = final_total.toFixed(2);
                    console.log("borrowerLevelActualDisbursement", this.borrowerLevelActualDisbursement)
                  }
                }
              this.borrowerLevelActualDisbursement = parseFloat(this.borrowerLevelActualDisbursement);
              this.loanActualDisbursement = parseFloat(this.loanActualDisbursement);
              if(this.borrowerLevelActualDisbursement >= 0){
                if(this.borrowerLevelActualDisbursement < this.loanActualDisbursement){
                  this.loanMappingAddMoreDisabled = true;
                  this.loanMappingSaveInfoDisabled = true;
                  this.loanMappingList = [];
                  Utils.make_alert("warning",`As the Borrower Level Disbursement Value is not greater than the Actual Disbursement Value, the subsequent loan mapping / disbursement activity cannot be completed.`)
                }
              }

          } else {
            Utils.make_alert("warning","Something went wrong from server!")
          }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });

    },

    // this function will be called to get list of price_types
    getPriceTypes() {
      console.log("getPriceTYpes")
      Utils.start_loading();
      const path = "price-type-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          this.price_types = res.data.price_types;
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
        });
    },

    getCollateral() {
      //this function is called to get list of Collateral
      const path = "collateral";
      axios
        .get(path)
        .then((res) => {
          this.collateralNames = res.data.collateral;
          if(this.flow == 'Loan'){
            this.collateralNames = this.collateralNamesFromLoan;
            
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFunds() {
      const path = "fund-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.Funds = res.data.funds;
          if(this.LTVtoDisbursement || this.flow == 'Loan'){
            this.getBorrowersByFund();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // getBorrowers() {
    //   const path = "borrower-master";
    //   axios
    //     .get(path, { params: { clientId: this.$store.state.client_id } })
    //     .then((res) => {
    //       this.borrowers = res.data.borrowers;
    //       this.selectedFacility = [];
    //       if (this.loggedInUserGroupId === "Borrower") {
    //         this.borrowers.filter((bor) => {
    //           if (bor.borrower_id.toString() === this.loggedInUserSubGroupId)
    //             this.selectedBorrower = bor.borrower_id;
    //             this.getAllFacilitySetupByBorrower();
    //         });
    //       } 
    //       // else{
    //       //   this.selectedBorrower = res.data.borrowers[0].borrower_id;
    //       //   this.getAllFacilitySetupByBorrower();
    //       // }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },

    getDisbursementOn() {
      //this function is called to get list of currencies
      const path = "disbursement-on-master";
      axios
        .get(path)
        .then((res) => {
          console.log(res.data)
          this.disbursementOn = res.data.disbursement_on;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLoanExpenses() {
      //this function is called to get list of loan expenses
      const path = "loan-expense-master";
      axios
        .get(path)
        .then((res) => {
          this.loanExpenses = res.data.loan_expenses;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBanks() {
      //this function is called to get list of banks
      const path = "bank-detail";
      axios
        .get(path)
        .then((res) => {
          console.log(res)
          console.log(res.data.bank_details)
          this.banks = res.data.bank_details;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }, //main function close
  // watch: {
  //   collateralValue: {
  //     handler(newCollateralValue) {
  //       if (this.selectedDisbursementOn === '1' && this.isOfftakerDetailsVisible) {
  //         this.loanActualDisbursement = (Number(newCollateralValue).toFixed(2) * Number(this.LTV)).toFixed(2);
  //         this.AvailableToDisburse = this.loanActualDisbursement
  //         this.loanMappingAddMoreDisabled = true;
  //           this.loanMappingList = [];
  //           this.loanMappingList.push({
  //             selectedLoan: null,
  //             facilityID: null,
  //             loanDisbursementDate: null,
  //             loanOSAmount: null,
  //             loanDisbursementAmount: null,
  //             selectedLoanCurrency:null,
  //             loanConversionRate: null,
  //             loanConversionDate: null,
  //             backCapDate: new Date(),
  //             loanConvertedAmount: null,
  //             loanDisbursementAmountError: false,
  //             loanDisbursementAmountErrorMessage: "",
  //           });
  //       }
  //     },
  //     immediate: true,
  //   },
  //   LTV: {
  //     handler(newLTV) {
  //       if (this.selectedDisbursementOn == '1' && this.isOfftakerDetailsVisible) {
  //         this.loanActualDisbursement = (Number(newLTV).toFixed(2) * Number(this.collateralValue)).toFixed(2);
  //         this.AvailableToDisburse = this.loanActualDisbursement;
  //         this.loanMappingAddMoreDisabled = true;
  //           this.loanMappingList = [];
  //           this.loanMappingList.push({
  //             selectedLoan: null,
  //             facilityID: null,
  //             loanDisbursementDate: null,
  //             loanOSAmount: null,
  //             loanDisbursementAmount: null,
  //             selectedLoanCurrency:null,
  //             loanConversionRate: null,
  //             loanConversionDate: null,
  //             backCapDate: new Date(),
  //             loanConvertedAmount: null,
  //             loanDisbursementAmountError: false,
  //             loanDisbursementAmountErrorMessage: "",
  //           });
  //       }
  //     },
  //     immediate: true,
  //   },
  // },

  mounted() {
    this.addCostDoc();
    this.addLoanMapping();
  },

  created() {
    console.log("created")
    this.getCollateral();
    this.getDisbursementOn();
    console.log("created 1")
    let data = this.$route.params;
    let data1 =  {
        "flow": "Loan",
        "selectedFund": 367,
        "selectedBorrower": "932",
        "selectedFacility": [
            {
                "facility_registration_id": "FR003VE0",
                "id": 785
            }
        ],
        "fundCurrency": 2,
        "loanID": "730",
        "approvalDate": "12-07-2023",
        "selectedCollateralID": 2,
        "collateralNames": [
            {
                "collateral_name": "Cocoa (London Futures GBP)",
                "id": 1
            }
        ],
        "selectedDisbursementOn": 1
    }

    let data2 = {
        "clientId": 1,
        "approvalDate": null,
        "loanID": null,
        "flow": "Normal Disbursement",
        "collateralNames": [
            {
                "collateral_name": "Cocoa",
                "id": 1
            },
            {
                "collateral_name": "Wheat",
                "id": 2
            }
        ],
        "selectedBorrower": 985,
        "selectedFund": 403,
        "selectedFacility": [
            {
                "collateral_id": 1,
                "facility_registration_id": "FR002LST",
                "id": 896
            }
        ],
        "selectedDisbursementOn": 2,
        "selectedCollateralID": [
            {
                "collateral_name": "Cocoa",
                "id": 1
            }
        ],
        "fundCurrency": 1,
        "LTV": null,
        "totalNetExpenses": "7,101,000.00",
        "AccruedInterest": "0.00",
        "allCollateralData": [
            {
                "id": 1,
                "collateral_id": 1,
                "collateral_name": "Cocoa",
                "exposures": [
                    {
                        "collateral_id": 1,
                        "collateral_name": "Cocoa",
                        "conversion_date": "03-01-2024",
                        "conversion_rate": 1.2634,
                        "currency_id": 2,
                        "discount_value": 60,
                        "exposure_name": "Uncleaned",
                        "price": 3444.33,
                        "price_type_id": 1,
                        "quantity": "4,000.00",
                        "selectedPriceType": "Market Price (Default)",
                        "std_currency_id": 1,
                        "total_bags": "4,000.00",
                        "collateral_value": "17,406,266.09",
                        "available_to_disburse": "10,443,759.65"
                    },
                    {
                        "collateral_id": 1,
                        "collateral_name": "Cocoa",
                        "conversion_date": "03-01-2024",
                        "conversion_rate": 1.2634,
                        "currency_id": 2,
                        "discount_value": 60,
                        "exposure_name": "Cleaned",
                        "price": 3444.33,
                        "price_type_id": 1,
                        "quantity": "1,000.00",
                        "selectedPriceType": "Market Price (Default)",
                        "std_currency_id": 1,
                        "total_bags": "1,000.00",
                        "collateral_value": "4,351,566.52",
                        "available_to_disburse": "2,610,939.91"
                    },
                    {
                        "collateral_id": 1,
                        "collateral_name": "Cocoa",
                        "conversion_date": "03-01-2024",
                        "conversion_rate": 1.2634,
                        "currency_id": 2,
                        "discount_value": 0,
                        "exposure_name": "Waste",
                        "price": 3444.33,
                        "price_type_id": 1,
                        "quantity": "2,000.00",
                        "selectedPriceType": "Market Price (Default)",
                        "std_currency_id": 1,
                        "total_bags": "2,000.00",
                        "collateral_value": "8,703,133.04",
                        "available_to_disburse": "0.00"
                    }
                ],
                "price_types": [
                    {
                        "collateral_id": 1,
                        "id": 1,
                        "price_name": "Market Price (Default)",
                        "price_type_id": 1
                    },
                    {
                        "collateral_id": 1,
                        "id": 2,
                        "price_name": "ICE Price UK",
                        "price_type_id": 2
                    },
                    {
                        "collateral_id": 1,
                        "id": 3,
                        "price_name": "ICE Price UK (Converted)",
                        "price_type_id": 3
                    },
                    {
                        "collateral_id": 1,
                        "id": 4,
                        "price_name": "ICE Price US",
                        "price_type_id": 4
                    },
                    {
                        "collateral_id": 1,
                        "id": 5,
                        "price_name": "Other Price (Input)",
                        "price_type_id": 5
                    }
                ],
                "transaction_price_types": [
                    {
                        "collateral_id": 1,
                        "id": 1,
                        "price_name": "Market Price (Default)",
                        "price_type_id": 1
                    },
                    {
                        "collateral_id": 1,
                        "id": 2,
                        "price_name": "ICE Price UK",
                        "price_type_id": 2
                    },
                    {
                        "collateral_id": 1,
                        "id": 3,
                        "price_name": "ICE Price UK (Converted)",
                        "price_type_id": 3
                    },
                    {
                        "collateral_id": 1,
                        "id": 4,
                        "price_name": "ICE Price US",
                        "price_type_id": 4
                    }
                ],
                "transactions": [
                    {
                        "collateral_id": 1,
                        "conversion_date": "03-01-2024",
                        "conversion_rate": 1.2634,
                        "converted_price": "4351.57",
                        "currency_id": 2,
                        "exposure_name": "Uncleaned",
                        "price": 3444.33,
                        "price_type_id": 1,
                        "quantity": "4,000.00",
                        "selectedPriceType": "Market Price (Default)",
                        "std_currency_id": 1,
                        "total_bags": "4,000.00",
                        "verification_id": "VR002LST",
                        "trans_id": 1
                    },
                    {
                        "collateral_id": 1,
                        "conversion_date": "03-01-2024",
                        "conversion_rate": 1.2634,
                        "converted_price": "4351.57",
                        "currency_id": 2,
                        "exposure_name": "Cleaned",
                        "price": 3444.33,
                        "price_type_id": 1,
                        "quantity": "1,000.00",
                        "selectedPriceType": "Market Price (Default)",
                        "std_currency_id": 1,
                        "total_bags": "1,000.00",
                        "verification_id": "VR002LST",
                        "trans_id": 2
                    },
                    {
                        "collateral_id": 1,
                        "conversion_date": "03-01-2024",
                        "conversion_rate": 1.2634,
                        "converted_price": "4351.57",
                        "currency_id": 2,
                        "exposure_name": "Waste",
                        "price": 3444.33,
                        "price_type_id": 1,
                        "quantity": "2,000.00",
                        "selectedPriceType": "Market Price (Default)",
                        "std_currency_id": 1,
                        "total_bags": "2,000.00",
                        "verification_id": "VR002LST",
                        "trans_id": 3
                    }
                ],
                "selectedPriceTypeTransaction": null,
                "selectedCurrencyTransaction": null
            }
        ],
        "LTVtoDisbursement": true
    }
    if (Object.keys(data).length === 0) {
        // If data is an empty object
        console.log('Data is empty');
        this.getFunds();
    } else {
        // If data is not empty
        console.log("from LTV to Dis", data);
        // Assign properties after checking if they exist in 'data'
        this.getPriceTypes();
        this.flow = 'flow' in data ? data.flow : null;
        this.loanID = 'loanID' in data ? data.loanID : null;
        this.approvalDate = 'approvalDate' in data ? data.approvalDate : null;
        this.collateralNamesFromLoan = 'collateralNames' in data ? data.collateralNames : [];
        this.selectedFund = 'selectedFund' in data ? data.selectedFund : null;
        this.selectedBorrower = 'selectedBorrower' in data ? data.selectedBorrower : null;
        this.selectedDisbursementOn = 'selectedDisbursementOn' in data ? data.selectedDisbursementOn : null;
        this.selectedFacility = 'selectedFacility' in data ? data.selectedFacility : [];
        this.selectedCollateralID = 'selectedCollateralID' in data ? data.selectedCollateralID : [];
        this.fundCurrency = 'fundCurrency' in data ? data.fundCurrency : null;
        this.LTV = 'LTV' in data ? data.LTV : null;
        this.totalNetExpenses = 'totalNetExpenses' in data ? data.totalNetExpenses : null;
        this.AccruedInterest = 'AccruedInterest' in data ? data.AccruedInterest : null;
        // this.ltvConversionDate = 'ltvConversionDate' in data ? data.ltvConversionDate : null;
        // this.LTVCompany = 'LTVCompany' in data ? data.LTVCompany : null;
        // this.totalLTVQuantity = 'totalLTVQuantity' in data ? data.totalLTVQuantity : null;
        // this.totalLTVBag = 'totalLTVBag' in data ? data.totalLTVBag : null;
        // this.totalLTVCollateralValue = 'totalLTVCollateralValue' in data ? data.totalLTVCollateralValue : null;
        // this.totalLTVCompanyValuation = 'totalLTVCompanyValuation' in data ? data.totalLTVCompanyValuation : null;
        this.LTVtoDisbursement = 'LTVtoDisbursement' in data ? data.LTVtoDisbursement : false;
        this.existingAllCollateralData = 'allCollateralData' in data ? data.allCollateralData : [];
        

        if (this.existingAllCollateralData && this.existingAllCollateralData.length > 0) {
          console.log("setting loanActualDisbursement with if available_to_disburse")
          // this.exposureData = this.existingAllCollateralData[0].exposures;
          //   let total = this.existingAllCollateralData[0].exposures.reduce((acc, exposure) => {
          //     return acc + parseFloat(exposure.available_to_disburse.replace(/,/g, ''));
          //   }, 0);
            // Sum available_to_disburse across all exposures in existingAllCollateralData
            let total = this.existingAllCollateralData.reduce((acc, collateral) => {
                if (collateral.exposures) {
                  return acc + collateral.exposures.reduce((innerAcc, exposure) => {
                    return innerAcc + parseFloat(exposure.available_to_disburse.replace(/,/g, ''));
                  }, 0);
                }
                return acc;
            }, 0);
            console.log("outside",total)
            let totalNetExpense = parseFloat(this.totalNetExpenses.replace(/,/g, ''));
            let final_total = total - totalNetExpense;
            this.loanActualDisbursement = final_total.toFixed(2);
            console.log("loanActualDisbursement", this.loanActualDisbursement)
            this.AvailableToDisburse = final_total.toFixed(2);
          } else {
            console.log("setting loanActualDisbursement else")
            if(this.totalNetExpenses){   
              console.log("setting loanActualDisbursement with available_to_disburse")
              let totalNetExpense = parseFloat(this.totalNetExpenses.replace(/,/g, ''));
              let final_total = - totalNetExpense;
              this.loanActualDisbursement = final_total.toFixed(2);
              console.log("loanActualDisbursement", this.loanActualDisbursement)
              this.AvailableToDisburse = final_total.toFixed(2);
          }
        }
        console.log("this.AvailableToDisburse",this.AvailableToDisburse)
        this.AvailableToDisburse = parseFloat(this.AvailableToDisburse);
        if(this.AvailableToDisburse < 0){
          this.loanMappingAddMoreDisabled = true;
          this.loanMappingSaveInfoDisabled = true;
          this.loanMappingList = [];
          Utils.make_alert("warning", `Kindly add Collateral to Proceed with Drawdown Transaction. <br/> Note: Available to Disburse amount cannot be negative `)
        }
        if(this.LTVtoDisbursement == true){
          this.getFacilitySetupByFundBorrowerLTV();
        }

        this.loanMappingFlag = true;
      }
      
    console.log("created 3")
    this.getFunds();
    this.getCurrencies();
    this.getLoanExpenses();
    this.getBanks();
  },

};
</script>

<style scoped>
.center {
  text-align: center !important;
}
.right {
  text-align: right !important;
}
.left {
  text-align: left !important;
}

.grey{
  /* background-color: #dee2e6 !important; */
  background-color: #f8f8fa !important;
}

.center-align {
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
.vdp-datepicker >>> .form-control[disabled] {
  background-color:#EEEEEE !important;
  opacity: 0.7;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.custom-input {
  width: 80px !important;
}

.multiselect  >>> .multiselect__tags  {
  min-height: 32px !important;
  max-height: 32px !important;
  background-color: #eeeef0 !important;
}
/* .multiselect  >>> .multiselect__tags-wrap  {
  height:auto !important;
  min-height: 32px !important;
  max-height: 32px !important;
  background-color: #eeeef0 !important;

} */
.multiselect  >>> .multiselect__single  {
  background-color: #eeeef0 !important;
}

.multiselect  >>> .multiselect__input  {
  background-color: #eeeef0 !important;
}
.multiselect >>> .multiselect__select  {
  height: 32px !important; /* Set the desired height */
  min-height: 32px !important; /* Set the desired height */
}

.multiselect--disabled  {
  height: 32px !important; /* Set the desired height */
  min-height: 32px !important; /* Set the desired height */
  max-height: 32px !important; /* Set the desired height */
  color: black !important; 
}
.multiselect >>> .multiselect__content-wrapper  {
  max-height: 130px !important; 
}

.multiselect >>> .multiselect__tag {
  margin-top: -3px !important;
}
.error {
  color: red;
  font-size: 13px;
  padding-left: 40px;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -75px;
}
.toggle-padding {
  margin-left: 5px;
}
.headingt #interestCalculationLabelYes {
  margin-left: 250px;
}
.headingt #interestCalculationLabelNo {
  margin-left: 20px;
}
.headingt #offtakerRepaymentLabelYes {
  margin-left: 95px;
}
.headingt #offtakerRepaymentLabelNo {
  margin-left: 20px;
}
</style>
