<!-- This is add Investor III page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Add Investment
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Investor Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="investorName"
                      id="investorName"
                      :disabled="true"
                      :class="{
                        'is-invalid': isSubmitted && $v.investorName.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.investorName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.investorName.required"
                        >Investment Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Investment Category</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="investmentCategory"
                      id="investmentCategory"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.investmentCategory.$error,
                      }"
                      :disabled="true"
                    />
                    <div
                      v-if="isSubmitted && $v.investmentCategory.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.investmentCategory.required"
                        >Investment Category is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label class="required-field">Fund Name</label>
                    <select
                      id="selectedFund"
                      class="form-control"
                      name="selectedFund"
                      v-model="selectedFund"
                      @change="getMgmtFees()"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedFund.$error,
                      }"
                    >
                      <option :value="null">-- Select a Fund --</option>
                      <option v-for="fund in funds" :value="fund.fund_id">
                        {{ fund.fund_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedFund.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedFund.required"
                        >Fund is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Issue Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="IssueDate"
                      id="IssueDate"
                      placeholder="Select Date"
                      v-model="IssueDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :class="{
                        'is-invalid': isSubmitted && $v.IssueDate.$error,
                      }"
                      :calendar-button="true"
                      :disabledDates="{
                        to: InvRegDate,
                        from: new Date(),
                      }"
                      @input="addMonthsToBackCapDate()"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.IssueDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.IssueDate.required"
                        >Issue Date is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Amount</label
                    >
                    <input
                      type="number"
                      class="form-control"
                      placeholder
                      v-model="investorAmount"
                      id="investorAmount"
                      step=".01"
                      min="0"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.investorAmount.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.investorAmount.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.investorAmount.required"
                        >Amount is required</span
                      >
                      <span v-if="!$v.investorAmount.maxLength"
                        >Max length of 10 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Currency</label
                    >
                    <select
                      id="selectedCurrency"
                      class="form-control"
                      name="selectedCurrency"
                      v-model="selectedCurrency"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedCurrency.$error,
                      }"
                    >
                      <option :value="null">-- Select a Currency --</option>
                      <option v-for="curr in currencies" :value="curr.id">
                        {{ curr.currency_short_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCurrency.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCurrency.required"
                        >Currency is required</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="investmentCategory != 'Participation'"
                >
                  <div class="form-group fc">
                    <label class="required-field">Interest Payment Sch</label>
                    <select
                      id="selectedInterestPaymentSchedule"
                      class="form-control"
                      name="selectedInterestPaymentSchedule"
                      v-model="selectedInterestPaymentSchedule"
                      @change="addMonthsToBackCapDate()"
                      :class="{
                        'is-invalid':
                          isSubmitted &&
                          $v.selectedInterestPaymentSchedule.$error,
                      }"
                    >
                      <option :value="null">
                        -- Select a Payment Schedule --
                      </option>
                      <option
                        v-for="payment in paymentTypes.slice(0, 3)"
                        :value="payment.id"
                        :key="payment.id"
                      >
                        {{ payment.interest_frequency_name }}
                      </option>
                    </select>
                    <div
                      v-if="
                        isSubmitted && $v.selectedInterestPaymentSchedule.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedInterestPaymentSchedule.required"
                        >Interest Payment Schedule is required</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="investmentCategory === 'Participation'"
                >
                  <div class="form-group fc">
                    <label class="required-field">Interest Payment Sch</label>
                    <input
                      :disabled="true"
                      id="selectedInterestPaymentSchedule"
                      class="form-control"
                      name="selectedInterestPaymentSchedule"
                      v-model="selectedInterestPaymentSchedule"
                      :class="{
                        'is-invalid':
                          isSubmitted &&
                          $v.selectedInterestPaymentSchedule.$error,
                      }"
                    />
                    <div
                      v-if="
                        isSubmitted && $v.selectedInterestPaymentSchedule.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedInterestPaymentSchedule.required"
                        >Interest Payment Schedule is required</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="investmentCategory != 'Participation'"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Maturity Date</label
                    >
                    <datepicker
                      :bootstrap-styling="true"
                      name="MaturityDate"
                      id="MaturityDate"
                      placeholder="Select Date"
                      v-model="MaturityDate"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :class="{
                        'is-invalid': isSubmitted && $v.MaturityDate.$error,
                      }"
                      :calendar-button="true"
                      :disabledDates="{
                        to: new Date(MaturityBackcapDate),
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.MaturityDate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.MaturityDate.required"
                        >Maturity Date is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label class="required-field">Interest Rate Type</label>
                    <select
                      id="selectedInterestRateType"
                      class="form-control"
                      name="selectedInterestRateType"
                      v-model="selectedInterestRateType"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedInterestRateType.$error,
                      }"
                    >
                      <option :value="null">
                        -- Select an Interest Type --
                      </option>
                      <option
                        v-for="interest in interestTypes"
                        :value="interest.interest_type_name"
                      >
                        {{ interest.interest_type_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedInterestRateType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedInterestRateType.required"
                        >Interest Rate Type is required</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  v-if="selectedInterestRateType === 'Fixed'"
                  class="col-md-2 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Interest Rate(%)</label
                    >
                    <input
                      type="number"
                      class="form-control"
                      placeholder
                      v-model="InterestRate"
                      id="InterestRate"
                      min="1"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.InterestRate.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.InterestRate.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.InterestRate.required"
                        >Interest Rate is required</span
                      >
                      <span v-if="!$v.InterestRate.maxDigit"
                        >Max value of 3 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  v-if="selectedInterestRateType === 'Floating'"
                  class="col-md-2 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Spread(%)</label
                    >
                    <input
                      type="number"
                      class="form-control"
                      placeholder
                      v-model="Spread"
                      id="Spread"
                      min="1"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.Spread.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.Spread.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.Spread.required">Spread is required</span>
                      <span v-if="!$v.Spread.maxDigit"
                        >Max value of 3 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  v-if="selectedInterestRateType === 'Floating'"
                  class="col-md-2 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Benchmark</label
                    >
                    <select
                      id="selectedBenchmark"
                      class="form-control"
                      name="selectedBenchmark"
                      v-model="selectedBenchmark"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedBenchmark.$error,
                      }"
                    >
                      <option :value="null">-- Select a Benchmark --</option>
                      <option
                        v-for="benchmark in benchmarks"
                        :value="benchmark.id"
                      >
                        {{ benchmark.benchmark_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedBenchmark.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedBenchmark.required"
                        >Benchmark is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Day Count</label
                    >
                    <select
                      id="selectedDayCount"
                      class="form-control"
                      name="selectedDayCount"
                      v-model="selectedDayCount"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedDayCount.$error,
                      }"
                    >
                      <option :value="null">-- Select a Count --</option>
                      <option v-for="dc in dayCounts" :value="dc.id">
                        {{ dc.day_count_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedDayCount.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedDayCount.required"
                        >Day Count is required</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  v-if="investmentCategory !== 'Notes'"
                  class="col-md-2 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Penalty (%)</label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder
                      v-model="Penalty"
                      id="Penalty"
                      @keypress="isDecimal($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.Penalty.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.Penalty.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.Penalty.maxDigit"
                        >Max value of 3 digits exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  v-if="investmentCategory !== 'Notes'"
                  class="col-md-2 col-sm-5 col-6"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1">Mgmt Fees (%)</label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder
                      v-model="MgmtFees"
                      id="MgmtFees"
                      disabled
                      @keypress="isDecimal($event)"
                    />
                    <!-- <div
                      v-if="isSubmitted && $v.MgmtFees.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.MgmtFees.maxDigit"
                        >Max value of 2 digits exceeded</span
                      >
                    </div> -->
                  </div>
                </div>

                <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="investmentCategory != 'Notes'"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1"
                      >Max. Investment Tenure</label
                    >
                    <input
                      type="number"
                      class="form-control"
                      placeholder
                      v-model="maximum_investment_tenure"
                      id="maximum_investment_tenure"
                      @keypress="isNum($event)"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.maximum_investment_tenure.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.maximum_investment_tenure.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.maximum_investment_tenure.maxLength"
                        >Max length of 3 characters exceeded</span
                      >
                      <span v-if="!$v.maximum_investment_tenure.required"
                        >Max Investment Tenure is required for Penalty</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="investmentCategory != 'Notes'"
                >
                  <div class="form-group fc">
                    <label>Period</label>
                    <select
                      id="period"
                      class="form-control"
                      name="period"
                      v-model="period"
                      :class="{
                        'is-invalid': isSubmitted && $v.period.$error,
                      }"
                    >
                      <option :value="null">-- Select a Period --</option>
                      <option value="Days">Days</option>
                      <option value="Weeks">Weeks</option>
                      <option value="Months">Months</option>
                      <option value="Years">Years</option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.period.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.period.required"
                        >Period is required for Penalty</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="investmentCategory === 'Participation'"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Borrower Name</label
                    >
                    <select
                      id="selectedBorrowerName"
                      class="form-control"
                      name="selectedBorrowerName"
                      v-model="selectedBorrowerName"
                      required
                      v-on:change="getUtilisationRequests()"
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedBorrowerName.$error,
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="borrower in borrowers"
                        :value="borrower.borrower_id"
                      >
                        {{ borrower.borrower_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedBorrowerName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedBorrowerName.required"
                        >Borrower Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="investmentCategory === 'Participation'"
                >
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Utilisation Req. ID</label
                    >
                    <select
                      id="selectedLoanId"
                      class="form-control"
                      name="selectedLoanId"
                      v-model="selectedLoanId"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedLoanId.$error,
                      }"
                      required
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="util in utilisationRequests"
                        :value="util.id"
                      >
                        {{ util.loan_registration_id }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedLoanId.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedLoanId.required"
                        >Utilisation Req. ID is required</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a @click="$router.go(-1)" style="cursor: pointer">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      investorName: null,
      selectedFund: null,
      funds: [],
      IssueDate: null,
      investorAmount: null,
      selectedCurrency: null,
      MaturityDate: null,
      MaturityBackcapDate: null,
      selectedInterestRateType: null,
      InterestRate: null,
      Spread: null,
      selectedBenchmark: null,
      benchmarks: [],
      selectedInterestPaymentSchedule: 1, //default set to At Maturity
      interestPaymentSchedule: [],
      selectedDayCount: null,
      DayCount: [],
      Penalty: null,
      MgmtFees: null,
      investmentCategory: null,
      selectedBorrowerName: null,
      BorrowerName: [],
      selectedLoanId: null,
      selectedUtilisationReqID: [],
      currencies: [],
      paymentTypes: [],
      dayCounts: [],
      interestTypes: [],
      IssueDate1: null,
      MaturityDate1: null,
      period: null,
      maximum_investment_tenure: null,
      borrowers: [],
      utilisationRequests: [],
      InvRegDate: null,
      error: "",
      isSubmitted: false,
      inactive: false,
    };
  },
  validations: {
    investorName: {
      required,
    },
    investmentCategory: {
      required,
    },
    selectedFund: {
      required,
    },
    IssueDate: {
      required,
    },
    investorAmount: {
      required: function () {
        if (this.investorAmount === null || this.investorAmount == 0) {
          return false;
        } else {
          return true;
        }
      },
      maxLength: maxLength(10),
    },
    selectedCurrency: {
      required,
    },
    MaturityDate: {
      required: function () {
        if (this.investmentCategory === "Notes" && this.MaturityDate === null) {
          return false;
        } else {
          return true;
        }
      },
    },
    selectedInterestRateType: {
      required,
    },
    Penalty: {
      // validation for value greater than 2 digits 3 decimals
      maxDigit: function (val) {
        if (Number(val) > 100 && this.investmentCategory === "Participation")
          return false;
        else return true;
      },
    },
    // MgmtFees: {
    //   // validation for value greater than 2 digits 3 decimals
    //   maxDigit: function (val) {
    //     if (Number(val) > 99.999 && this.investmentCategory === "Participation")
    //       return false;
    //     else return true;
    //   },
    // },
    maximum_investment_tenure: {
      maxLength: maxLength(3),
      required: function () {
        if (!this.maximum_investment_tenure && this.Penalty) {
          return false;
        } else {
          return true;
        }
      },
    },
    period: {
      required: function () {
        if (this.period === null && this.Penalty) {
          return false;
        } else {
          return true;
        }
      },
    },
    InterestRate: {
      required: function () {
        if (
          this.selectedInterestRateType === "Fixed" &&
          this.InterestRate === null
        ) {
          return false;
        } else {
          return true;
        }
      },
      // validation for value greater than 2 digits 3 decimals
      maxDigit: function (val) {
        if (Number(val) > 100 && this.selectedInterestRateType === "Fixed")
          return false;
        else return true;
      },
    },
    Spread: {
      required: function () {
        if (
          this.selectedInterestRateType === "Floating" &&
          this.Spread === null
        ) {
          return false;
        } else {
          return true;
        }
      },
      // validation for value greater than 2 digits 3 decimals
      maxDigit: function (val) {
        if (Number(val) > 100 && this.selectedInterestRateType === "Floating")
          return false;
        else return true;
      },
    },
    selectedBenchmark: {
      required: function () {
        if (
          this.selectedInterestRateType === "Floating" &&
          this.selectedBenchmark === null
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
    selectedInterestPaymentSchedule: {
      required,
    },
    selectedDayCount: {
      required,
    },
    selectedBorrowerName: {
      required: function () {
        if (
          this.investmentCategory === "Participation" &&
          this.selectedBorrowerName === null
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
    selectedLoanId: {
      required: function () {
        if (
          this.investmentCategory === "Participation" &&
          this.selectedLoanId === null
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
  },
  components: {
    Datepicker,
  },

  methods: {
    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },
    addMonthsToBackCapDate() {
      // setting Maturity Date to null if Issue Date or selectedInterestPaymentSchedule is changed
      this.MaturityDate = null;
      // check if IssueDate is not null
      if (this.IssueDate) {
        if (this.selectedInterestPaymentSchedule === 2) {
          // add 1 month and 1 day to Issue Date for Maturity Backcap Date if selectedInterestPaymentSchedule is Monthly
          this.MaturityBackcapDate = moment(this.IssueDate)
            .add(1, "M")
            .format("MM-DD-YYYY");
        }
        else if (this.selectedInterestPaymentSchedule === 3) {
          // add 3 months and 1 day to Issue Date for Maturity Backcap Date if selectedInterestPaymentSchedule is Quarterly
          this.MaturityBackcapDate = moment(this.IssueDate)
            .add(3, "M")
            .format("MM-DD-YYYY");
        }
        else {
          // When no specific schedule is selected, add one day to the issue date
          this.MaturityBackcapDate = moment(this.IssueDate)
            .add(1, "days") // Adding one extra day
            .toDate();
        } 
      }
    },

    // get Management Fees of selected Fund and assign it to MgmtFees
    getMgmtFees() {
      const path = "fund/" + this.selectedFund;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          let fund_details = res.data.fund_active;
          this.MgmtFees = fund_details.management_fees
            ? fund_details.management_fees
            : null;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getBorrowers() {
      const path = "borrower-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.borrowers = res.data.borrowers;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getUtilisationRequests() {
      const path =
        "utilisation-request-for-participation/" +
        this.investor_id +
        "/" +
        this.selectedBorrowerName;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.utilisationRequests = res.data.utilisation_request;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of documents master
    getFund() {
      const path = "fund-master";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.funds = res.data.funds;
        })
        .catch((error) => {
          console.error(error);
        });
    }, //getFund() close

    getInvestor() {
      //this function is called on page load and created events. it will show details on Investor with Investorid equal to this.id
      const path = "investor/" + this.investor_id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.investorName = this.inactive
            ? res.data.investor_inactive.investor_name
            : res.data.investor_active.investor_name;
          this.investmentCategory = this.inactive
            ? res.data.investor_inactive.investment_category_name
            : res.data.investor_active.investment_category_name;
          this.selectedInterestPaymentSchedule =
            this.investmentCategory === "Participation" ? "At Maturity" : 1;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getDayCount() {
      //this function is called to get list of day count
      const path = "day-count-master";
      axios
        .get(path)
        .then((res) => {
          this.dayCounts = res.data.day_counts;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getPaymentTypes() {
      //this function is called to get list of payment types
      const path = "interest-frequency";
      axios
        .get(path)
        .then((res) => {
          this.paymentTypes = res.data.interest_frequencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getInterestTypes() {
      //this function is called to get list of interest types
      const path = "interest-type";
      axios
        .get(path)
        .then((res) => {
          this.interestTypes = res.data.interest_types;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBenchmarks() {
      //this function is called to get list of benchmarks
      const path = "benchmark";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.benchmarks = res.data.benchmarks;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this method will submit the Investment form in backend.
    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      // if (
      //   this.selectedFund === null ||
      //   this.selectedInterestRateType === null ||
      //   this.IssueDate === null ||
      //   this.investorAmount === null ||
      //   this.selectedCurrency === null ||
      //   this.selectedInterestPaymentSchedule === null ||
      //   this.selectedDayCount === null
      // ) {
      //   Utils.make_alert("warning", "Mandatory fields are required.");
      //   return;
      // }
      // if (this.selectedInterestRateType === "Fixed") {
      //   if (this.InterestRate === null) {
      //     Utils.make_alert("warning", "Mandatory fields are required.");
      //     return;
      //   }
      // }

      // if (this.selectedInterestRateType === "Floating") {
      //   if (this.Spread === null || this.selectedBenchmark === null) {
      //     Utils.make_alert("warning", "Mandatory fields are required.");
      //     return;
      //   }
      // }

      // if (this.investmentCategory === "Notes") {
      //   if (this.MaturityDate === null) {
      //     Utils.make_alert("warning", "Mandatory fields are required.");
      //     return;
      //   }
      // }

      // if (this.investmentCategory === "Participation") {
      //   if (
      //     this.selectedBorrowerName === null ||
      //     this.selectedLoanId === null
      //   ) {
      //     Utils.make_alert("warning", "Mandatory fields are required.");
      //     return;
      //   }
      // }

      if (this.IssueDate != null) {
        this.IssueDate1 = moment(this.IssueDate).format("MM-DD-YYYY");
      }
      if (this.MaturityDate != null) {
        this.MaturityDate1 = moment(this.MaturityDate).format("MM-DD-YYYY");
      }
      if (this.investmentCategory === "Participation")
        this.selectedInterestPaymentSchedule = 1;
      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("investorName", this.investorName);
      formData.append("selectedFund", this.selectedFund);
      formData.append("IssueDate1", this.IssueDate1);
      formData.append("investorAmount", this.investorAmount);
      formData.append("selectedCurrency", this.selectedCurrency);
      formData.append("MaturityDate1", this.MaturityDate1);
      formData.append(
        "selectedInterestRateType",
        this.selectedInterestRateType
      );
      formData.append("InterestRate", this.InterestRate);
      formData.append("Spread", this.Spread);
      formData.append("selectedBenchmark", this.selectedBenchmark);
      formData.append(
        "selectedInterestPaymentSchedule",
        this.selectedInterestPaymentSchedule
      );
      formData.append("selectedDayCount", this.selectedDayCount);
      formData.append("Penalty", this.Penalty);
      formData.append("MgmtFees", this.MgmtFees);
      formData.append("selectedBorrowerName", this.selectedBorrowerName);
      formData.append("selectedLoanId", this.selectedLoanId);
      formData.append("period", this.period);
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 3);
      formData.append("tabId", 12);
      formData.append(
        "maximum_investment_tenure",
        this.maximum_investment_tenure
      );
      formData.append("investmentCategory", this.investmentCategory);

      const path =
        "investment-by-investor-id/" +
        this.investor_id +
        "/" +
        null +
        "/" +
        null +
        "/" +
        null +
        "/" +
        null;
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          console.log("status",response.data.status)
          if (response.data.status == "success") {
            Utils.make_alert("success", "Investment added successfully!");
            setTimeout(() => this.$router.go(this.$router.currentRoute), 1500);
            console.log("SUCCESS!!");
            this.$router.push("/investor-overview/" + this.investor_id);
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.log("Failure!!");
          console.error(error);
          Utils.make_alert(
            "warning",
            error.response.status === 400
              ? error.response.data.status
              : "Something went wrong!"
          );
        });
    }, //onSubmit() close
  }, //main function close

  mounted() {
    // var numberOfDecimals = 2;
    // $(document).on("input", ".allow-only-numbers", function () {
    //   var regExp = new RegExp("(\\.[\\d]{" + numberOfDecimals + "}).", "g");
    //   this.value = this.value
    //     .replace(/[^0-9.]/g, "")
    //     .replace(/(\..*)\./g, "$1")
    //     .replace(regExp, "$1");
    // });
  },
  created() {
    this.inactive = this.$route.params.props.inactive;
    this.investor_id = this.$route.params.props.id;
    this.InvRegDate = new Date(this.$route.params.props.InvRegDate);
    this.getInvestor();
    this.getFund();
    this.getCurrencies();
    this.getDayCount();
    this.getInterestTypes();
    this.getPaymentTypes();
    this.getBenchmarks();
    this.getBorrowers();
  },
};
</script>

<style scoped>
.required-field::after {
  content: "*";
  color: red;
}
.error-msg {
  color: red;
  font-weight: bold;
}
</style>
