<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt" id="pageHeading">
                        <i class="icofont-search-1"></i> Contract Overview
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="card-body br_overview">
                <!------menton here style="height: 300px;"-------->
                <table class="table table-hover table-head-fixed text-nowrap">
                  <tbody>
                    <tr>
                      <td class="wg" id="offtakerName">Offtaker Name:</td>
                      <td v-if="contractDetails.offtaker_name">
                        {{ contractDetails.offtaker_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="borrowerName">Borrower Name:</td>
                      <td v-if="contractDetails.borrower_name">
                        {{ contractDetails.borrower_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="collateral">Collateral:</td>
                      <td v-if="contractDetails.collateral_name">
                        {{ contractDetails.collateral_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <!-- <tr>
                      <td class="wg" id="collateral">Collateral Type:</td>
                      <td v-if="contractDetails.collateral_stage_name">
                        {{ contractDetails.collateral_stage_name }}
                      </td>
                      <td v-else>-</td>
                    </tr> -->

                    <tr>
                      <td class="wg" id="contractNo">Contract No.:</td>
                      <td v-if="contractDetails.contract_number">
                        {{ contractDetails.contract_number }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="contractDate">Contract Date:</td>
                      <td v-if="contractDetails.contract_date">
                        {{ contractDetails.contract_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="totalQuantity">Total Quantity:</td>
                      <td v-if="contractDetails.total_quantity">
                        {{ contractDetails.total_quantity }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="deliveryMonth">Delivery Month:</td>
                      <td v-if="contractDetails.delivery_month">
                        {{ contractDetails.delivery_month }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="priceType">Price Type:</td>
                      <td v-if="contractDetails.price_name">
                        {{ contractDetails.price_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="price">Price:</td>
                      <td v-if="contractDetails.price">
                        {{ contractDetails.price }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg">Contract Price Currency:</td>
                      <td>
                        {{ contractDetails.currency_id !== null ? getCurrencyShortNameById(contractDetails.currency_id) : '-' }}
                      </td>
                    </tr>

                    <tr>
                      <td class="wg" id="details">Details:</td>
                      <td v-if="contractDetails.details">
                        {{ contractDetails.details }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <!-- <tr>
                      <td class="wg" id="currency">Contract Currency:</td>
                      <td v-if="contractDetails.currency_short_name">
                        {{ contractDetails.currency_short_name }}
                      </td>
                      <td v-else>-</td>
                    </tr> -->

                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card-body br_overview">
                <table class="table table-hover" style="table-layout: fixed">
                  <tbody>
                    <tr>
                      <td class="wg" id="currency">Conversion Date:</td>
                      <td v-if="contractDetails.conversion_date">
                        {{ contractDetails.conversion_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="conversionRate">Conversion Rate:</td>
                      <td v-if="contractDetails.conversion_rate">
                        {{ contractDetails.conversion_rate }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    
                    <tr>
                      <td class="wg" id="finalPrice">Final Price:</td>
                      <td v-if="contractDetails.final_price">
                        {{ contractDetails.final_price }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="contractValue">Contract Value:</td>
                      <td v-if="contractDetails.contract_value">
                        {{ contractDetails.contract_value }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <tr>
                      <td class="wg" id="paymentTerms">Payment Terms:</td>
                      <td v-if="contractDetails.payment_terms">
                        {{ contractDetails.payment_terms }}{{ ' %' }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="agent">Agent:</td>
                      <td class="wrap_text" :title="contractDetails.agent" v-if="contractDetails.agent">
                        {{ truncate(contractDetails.agent,30) }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="quality">Quality:</td>
                      <td class="wrap_text" :title="contractDetails.quality" v-if="contractDetails.quality">
                        {{ truncate(contractDetails.quality,30) }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="terms">Terms:</td>
                      <td class="wrap_text" :title="contractDetails.terms" v-if="contractDetails.terms">
                        {{ truncate(contractDetails.terms,30) }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="arbitration">Arbitration:</td>
                      <td class="wrap_text" :title="contractDetails.arbitration" v-if="contractDetails.arbitration">
                        {{ truncate(contractDetails.arbitration,30) }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="comments">Comments:</td>
                      <td class="wrap_text" :title="contractDetails.comments" v-if="contractDetails.comments">
                        {{ truncate(contractDetails.comments,30) }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!--========================================================================-->

        <div v-if="contractPartialFills.length > 0" class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt" id="pageHeading">
                        Partial Fill Contracts
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div
            class="row"
            v-for="(partial, index) in contractPartialFills"
            :key="partial.id"
          >
          <div class="col-md-12">
              <div class="card-body br_overview">
                <!------menton here style="height: 300px;"-------->
                <table class="table table-hover table-head-fixed text-nowrap">
                  <tbody>
                    <tr id="container">
                      <td class="wg">Partial Fill No: {{ index + 1 }}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card-body br_overview">
                <!------menton here style="height: 300px;"-------->
                <table class="table table-hover table-head-fixed text-nowrap">
                  <tbody>
                    <!-- <tr id="container">
                      <td class="wg">Partial Fill No: {{ index + 1 }}</td>
                      <td></td>
                    </tr> -->

                    <tr>
                      <td class="wg" id="fixationDate">Fixation Date:</td>
                      <td v-if="partial.fixation_date !== null">
                        {{ partial.fixation_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="fixedQty">Fixed Quantity (MT):</td>
                      <td v-if="partial.fixed_quantity !== null">
                        {{ partial.fixed_quantity }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="balanceToBeFixed">
                        Balance To Be Fixed:
                      </td>
                      <td v-if="partial.balance_to_be_fixed !== null">
                        {{ partial.balance_to_be_fixed }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="price">Price:</td>
                      <td v-if="partial.price !== null">
                        {{ partial.price }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="differential">Differential:</td>
                      <td
                        v-if="partial.differential !== null"
                        class="fixed_width"
                      >
                        {{partial.differential_value == 1 ? '-' : ''}}{{ partial.differential }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg">Month:</td>
                      <td>
                        {{ partial.month !== null ? getMonthName(partial.month) : '-' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card-body br_overview">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td class="wg" id="baseCurrency">Base Currency:</td>
                      <td v-if="partial.base_currency_name !== null">
                        {{ partial.base_currency_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="currency">Conv. Price Currency:</td>
                      <td v-if="partial.currency_name !== null">
                        {{ partial.currency_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="currencyRate">Conversion Date:</td>
                      <td
                        v-if="partial.conversion_date !== null"
                        class="fixed_width"
                      >
                        {{ partial.conversion_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <tr>
                      <td class="wg" id="currencyRate">Conversion Rate:</td>
                      <td
                        v-if="partial.currency_rate !== null"
                        class="fixed_width"
                      >
                        {{ partial.currency_rate }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="finalPrice">Final Price (Conv.):</td>
                      <td
                        v-if="partial.final_price !== null"
                        class="fixed_width"
                      >
                        {{ partial.final_price }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="partialContractValue">
                        Partial Contract Value:
                      </td>
                      <td
                        v-if="partial.partial_contract_value"
                        class="fixed_width"
                      >
                        {{ partial.partial_contract_value }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- /.Tabpane third end-->
        
        <!--========================================================================-->

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-12">
                      <h1 class="headingt">Documents</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th></th>
                        <th id="srNo">Sr. no.</th>
                        <th id="doc">Documents</th>
                        <th class="c" id="issueDate">Issue Date</th>
                        <th class="c" id="comments">Comments</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(doc, index) in contractDocuments"
                        :key="doc.id"
                      >
                        <td
                          class="center"
                          v-on:click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-download text-info"></i>
                        </td>
                        <td v-if="doc.displayFileName">
                          {{ index + 1 }}
                        </td>
                        <td class="c" v-else>-</td>
                        <td v-if="doc.displayFileName">
                          {{ doc.displayFileName }}
                        </td>
                        <td class="c" v-else>-</td>
                        <td v-if="doc.IssueDate" class="c">
                          {{ doc.IssueDate }}
                        </td>
                        <td v-else class="c">-</td>
                        <td v-if="doc.comments" class="c">
                          {{ doc.comments }}
                        </td>
                        <td v-else class="c">-</td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.Tabpane second end-->
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper -->

    <!-- ./wrapper -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import moment from "moment";

export default {
  data() {
    return {
      currencies: [],
      contractDetails: [],
      contractDocuments: [],
      contractPartialFills: [],
      differentialValue: [
        {id:"1" , differential_value: "-"},
        {id:"0" , differential_value: "+"},
      ],
      Months : [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' }
      ],
    };
  },

  components: {
    Datepicker,
  },

  methods: {
    getMonthName(monthNumber) {
      const month = this.Months.find(m => m.id == monthNumber);
      return month ? month.name : '-';
    },

    getCurrencyShortNameById(id) {
      const currency = this.currencies.find((currency) => currency.id === id);
      return currency ? currency.currency_short_name : '-';
    },



    getOfftakerContract() {
      //this function will show details of contract with contract id
      const path = "offtaker-contract-overview/" + this.$route.params.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.contractDetails = res.data.offtaker_contract.offtaker_contract_details[0];
            if (this.contractDetails.contract_date != '-' && this.contractDetails.contract_date != null) {
              this.contractDetails.contract_date = moment(this.contractDetails.contract_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.contractDetails.contract_date = '-';
            }
            if (this.contractDetails.conversion_date != '-' && this.contractDetails.conversion_date != null) {
              this.contractDetails.conversion_date = moment(this.contractDetails.conversion_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
            } else {
              this.contractDetails.conversion_date = '-';
            }
            console.log(this.contractDetails);
            if (Object.keys(this.contractDetails).length === 0) {
              this.contractDetails = [];
            } else {
              if(this.contractDetails.delivery_month != null){
                this.contractDetails.delivery_month = moment(this.contractDetails.delivery_month).format("MMMM-YYYY")
              }
            }
            console.log("final",this.contractDetails);
            this.contractDocuments =
              res.data.offtaker_contract.offtaker_contract_documents_details;
            if(this.contractDocuments.length > 0) {
              this.contractDocuments.forEach(item => {
                if (item.IssueDate != '-' && item.IssueDate != null) {
                  item.IssueDate = moment(item.IssueDate, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.IssueDate = '-';
                }
              });
            }
            this.contractPartialFills =
              res.data.offtaker_contract.offtaker_contract_partial_fill_details;
            if(this.contractPartialFills.length > 0) {
              this.contractPartialFills.forEach(item => {
                if (item.fixation_date != '-' && item.fixation_date != null) {
                  item.fixation_date = moment(item.fixation_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.fixation_date = '-';
                }
                if (item.conversion_date != '-' && item.conversion_date != null) {
                  item.conversion_date = moment(item.conversion_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
                } else {
                  item.conversion_date = '-';
                }
              });
            }
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
        });
    }, //getOfftakerContract close

    //this function is called to get list of currencies
    getCurrencies() {
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    truncate(text, length) {
    return text.length > length ? text.substring(0, length) + '...' : text;
    },

    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },
  },
  created() {
    this.getCurrencies();
    this.getOfftakerContract();
  },
  computed: {
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>
<style scoped>
.fixed_width {
  min-width: 90px;
}

.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}

</style>
