<!-- This is add collateral page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Add Cleaning Process
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Request ID</label
                    >
                    <input
                      id="requestId"
                      class="form-control"
                      name="requestId"
                      v-model="requestId"
                      @keypress="isAlphaNum($event)"
                      maxlength="20"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.requestId.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.requestId.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.requestId.required"
                        >Request ID is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Utilisation ID</label
                    >
                    <input
                      id="utilId"
                      class="form-control"
                      name="utilId"
                      v-model="utilId"
                      @keypress="isAlphaNum($event)"
                      maxlength="20"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.utilId.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.utilId.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.utilId.required"
                        >Utilisation ID is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Borrower Name</label
                    >
                    <input
                      id="borrowerName"
                      class="form-control"
                      name="borrowerName"
                      v-model="borrowerName"
                      @keypress="isAlphaNum($event)"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.borrowerName.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.borrowerName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.borrowerName.required"
                        >Borrower Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Date</label
                    >
                    <datepicker
                      class="datepicker"
                      :bootstrap-styling="true"
                      name="date"
                      id="date"
                      placeholder="Select Date"
                      v-model="date"
                      :format="$store.state.date_format"
                      calendar-button-icon="fa fa-calendar"
                      :calendar-button="true"
                      :disabledDates="{
                        from: new Date(),
                        to: new Date(requestDate),
                      }"
                      :class="{
                        'is-invalid': isSubmitted && $v.date.$error,
                      }"
                    ></datepicker>
                    <div
                      v-if="isSubmitted && $v.date.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.date.required">Date is required</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Collateral Name</label
                    >
                    <select
                      id="selectedCollateral"
                      class="form-control"
                      name="selectedCollateral"
                      v-model="selectedCollateral"
                      disabled
                      :class="{
                        'is-invalid':
                          isSubmitted && $v.selectedCollateral.$error,
                      }"
                    >
                      <option :value="null">-- Select --</option>
                      <option v-for="col in collaterals" :value="col.id">
                        {{ col.collateral_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedCollateral.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedCollateral.required"
                        >Collateral Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Collateral Qty</label
                    >
                    <input
                      type="number"
                      min="0"
                      id="collateralQty"
                      class="form-control"
                      name="collateralQty"
                      v-model="collateralQty"
                      @keypress="isDecimal($event)"
                      :disabled="status === 'Fully Taken for Cleaning'"
                      :class="{
                        'is-invalid': isSubmitted && $v.collateralQty.$error,
                      }"
                    />
                    <div
                      v-if="
                        (isSubmitted && $v.collateralQty.$error) ||
                        collateralQty > collateralQtyLimit
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.collateralQty.required"
                        >Collateral Qty is required</span
                      >
                      <span v-if="!$v.collateralQty.maxLength"
                        >Max length of 15 characters exceeded<br
                      /></span>

                      <span v-if="!$v.collateralQty.collateral_qty_limit">
                        Collateral Qty cannot exceed the limit of
                        {{ collateralQtyLimit }}!
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Gross Weight</label
                    >
                    <input
                      type="number"
                      min="0"
                      id="grossWeight"
                      class="form-control"
                      name="grossWeight"
                      v-model="grossWeight"
                      @keypress="isDecimal($event)"
                      :disabled="status === 'Fully Taken for Cleaning'"
                      :class="{
                        'is-invalid': isSubmitted && $v.grossWeight.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.grossWeight.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.grossWeight.required"
                        >Gross Weight is required</span
                      >
                      <span v-if="!$v.grossWeight.maxLength"
                        >Max length of 15 characters exceeded<br
                      /></span>

                      <span v-if="!$v.grossWeight.gross_limit">
                        Gross Weight cannot exceed the limit of
                        {{ grossLimit }}!
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >Unit</label
                    >
                    <select
                      id="selectedUnit"
                      class="form-control"
                      name="selectedUnit"
                      v-model="selectedUnit"
                      disabled
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedUnit.$error,
                      }"
                    >
                      <option :value="null">-- Select --</option>
                      <option v-for="unit in UnitTypes" :value="unit.id">
                        {{ unit.name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedUnit.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedUnit.required"
                        >Unit is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >No. of Bags</label
                    >
                    <input
                      id="bags"
                      class="form-control"
                      name="bags"
                      v-model="bags"
                      @keypress="isNum($event)"
                      :disabled="status === 'Fully Taken for Cleaning'"
                      :class="{
                        'is-invalid': isSubmitted && $v.bags.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.bags.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.bags.required"
                        >No. of Bags is required</span
                      >
                      <span v-if="!$v.bags.maxLength"
                        >Max length of 15 characters exceeded<br
                      /></span>

                      <span v-if="!$v.bags.bag_limit">
                        No. of Bags cannot exceed the limit of {{ bagLimit }}!
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label for="exampleInputEmail1" class="required-field"
                      >GRN Number</label
                    >
                    <input
                      id="grnNo"
                      class="form-control"
                      name="grnNo"
                      v-model="grnNo"
                      @keypress="isNum($event)"
                      :class="{
                        'is-invalid': isSubmitted && $v.grnNo.$error,
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.grnNo.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.grnNo.required"
                        >GRN Number is required</span
                      >
                      <span v-if="!$v.grnNo.maxLength"
                        >Max length of 15 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Documents</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <button
                            type="button"
                            class="btn btn-sm sh_n btn-dark"
                            @click="addCleaningProcessDoc()"
                            :disabled="CleaningProcessDocumentsAddMoreDisabled"
                          >
                            <i class="icofont-plus"></i> Add More
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body p-0">
                  <!------menton here style="height: 300px;"-------->

                  <div
                    class="row"
                    v-for="(doc, index) in CleaningProcessDocuments"
                    :key="index"
                  >
                    <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Upload Document</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            name="file"
                            ref="file"
                            accept=".jpg,.jpeg,.png,.pdf"
                            v-on:change="
                              handleFileUploadCleaningProcessDocuments(
                                index,
                                $event
                              )
                            "
                          />
                          <!-- <div
                            v-if="isSubmitted && doc.displayFileName === null"
                            class="invalid-feedback"
                          >
                            <span v-if="doc.displayFileName === null"
                              >Document Name is required</span
                            >
                          </div> -->
                          <small>
                            <p style="background-color: powderblue">
                              <b>{{ doc.displayFileName }}</b>
                              <small>
                                <u
                                  style="color: blue; cursor: pointer"
                                  v-if="
                                    doc.CleaningProcessDocumentsRemoveDisabled ===
                                    false
                                  "
                                  v-on:click="
                                    removeCleaningProcessDocumentsDoc(index)
                                  "
                                  >Remove</u
                                >
                              </small>
                            </p>
                          </small>
                          <small>
                            <p class="error" v-if="doc.errors.filesize">
                              {{ doc.errors.filesize }}
                            </p>
                          </small>
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="exampleInputEmail1">Uploading Date</label>
                        <datepicker
                          class="datepicker"
                          :bootstrap-styling="true"
                          name="UploadingDate"
                          id="UploadingDate"
                          placeholder="Select Date"
                          v-model="doc.UploadingDate"
                          :format="$store.state.date_format"
                          calendar-button-icon="fa fa-calendar"
                          :disabledDates="{ from: new Date() }"
                          :calendar-button="true"
                        ></datepicker>
                        <!-- <div
                          v-if="
                            isSubmitted &&
                            $v.CleaningProcessDocuments.$each[index]
                              .UploadingDate.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.CleaningProcessDocuments.$each[index]
                                .UploadingDate.required
                            "
                            >Uploading Date is required</span
                          >
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-5 col-6">
                      <div
                        class="form-group fc"
                        @click="receiptValidation(doc.displayFileName)"
                      >
                        <label for="exampleInputEmail1">Receipt No.</label>
                        <input
                          id="receipt_number"
                          class="form-control"
                          name="receipt_number"
                          v-model="doc.receipt_number"
                          @keypress="isAlphaNum($event)"
                          :disabled="doc.displayFileName === null"
                          :class="{
                            'is-invalid':
                              (receiptValidate &&
                                doc.displayFileName === null) ||
                              (CleaningProcessDocuments[index].receipt_number
                                ? CleaningProcessDocuments[index].receipt_number
                                    .length > 20
                                : false),
                          }"
                        />
                        <div
                          v-if="doc.displayFileName === null"
                          class="invalid-feedback"
                        >
                          <span v-if="receiptValidate"
                            >Please upload the Document</span
                          >
                        </div>
                        <div
                          v-if="
                            CleaningProcessDocuments[index].receipt_number
                              ? CleaningProcessDocuments[index].receipt_number
                                  .length > 20
                              : false
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              CleaningProcessDocuments[index].receipt_number
                                ? CleaningProcessDocuments[index].receipt_number
                                    .length > 20
                                : false
                            "
                            >Max length of 20 characters exceeded</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label
                          for="exampleInputEmail1"
                          style="visibility: hidden"
                          >Delete</label
                        >
                        <div
                          style="width: 250px"
                          id="docDelete"
                          v-on:click="deleteCleaningProcessDocuments(index)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right mb-3">
                      <button
                        type="button"
                        class="btn-sm btn btn-primary sh"
                        v-on:click="onCleaningProcessDocumentSaveInfo()"
                        :disabled="isDisabledCleaningProcessDocumentSaveInfo"
                      >
                        Save Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    id="submit"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <a @click="$router.go(-1)" style="cursor: pointer">
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      requestId: null,
      utilId: null,
      borrowerName: null,
      borrowerId: null,
      requestDate: null,
      date: new Date(),
      selectedCollateral: null,
      collaterals: [],
      warrantRef: null,
      collateralQty: null,
      grossWeight: null,
      UnitTypes: [],
      Period: [],
      bags: null,
      grnNo: null,
      selectedUnit: null,
      collateralQtyLimit: null,
      grossLimit: null,
      bagLimit: null,
      collateralManagerId: null,
      collateralName: null,

      //list for CleaningProcess Documents
      CleaningProcessDocuments: [],
      CleaningProcessDocumentsFile: null,
      // CleaningProcessDocumentsButtonDisabled: true,
      CleaningProcessDocumentsDisabled: true,
      CleaningProcessDocumentsSaveInfoDisabled: true, //save info button is disabled
      CleaningProcessDocumentsAddMoreDisabled: true,
      msg: [],
      error: "",
      isSubmitted: false,
      onSubmitDisabled: false,
      receiptValidate: false,
      status: null,
    };
  },
  validations: {
    requestId: {
      required,
    },
    utilId: {
      required,
    },
    borrowerName: {
      required,
    },
    date: {
      required,
    },
    selectedCollateral: {
      required,
    },
    collateralQty: {
      required,
      maxLength: maxLength(15),
      collateral_qty_limit: function (value) {
        let containsCollateralQtyLimit = true;
        if (this.status === "Partially Taken for Cleaning") {
          containsCollateralQtyLimit =
            Number(value) < Number(this.collateralQtyLimit);
        }
        return containsCollateralQtyLimit;
      },
    },
    grossWeight: {
      required,
      maxLength: maxLength(15),
      gross_limit: function (value) {
        let containsGrossLimit = true;
        if (this.status === "Partially Taken for Cleaning") {
          containsGrossLimit = Number(value) < Number(this.grossLimit);
        }
        return containsGrossLimit;
      },
    },
    selectedUnit: {
      required,
    },
    bags: {
      required,
      maxLength: maxLength(15),
      bag_limit: function (value) {
        let containsBagLimit = true;
        if (this.status === "Partially Taken for Cleaning") {
          containsBagLimit = Number(value) < Number(this.bagLimit);
        }
        return containsBagLimit;
      },
    },
    grnNo: {
      required,
      maxLength: maxLength(15),
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup)
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },

    //this is to computed disabled property of CleaningProcessDocuments Save Info button.
    isDisabledCleaningProcessDocumentSaveInfo: function () {
      let checkEmptyUploadingDate = this.CleaningProcessDocuments.filter(
        (line) => line.UploadingDate === null
      );
      //if the line is empty then disable save info button
      if (
        this.CleaningProcessDocuments.length === 0 &&
        this.CleaningProcessDocumentsSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
      if (
        checkEmptyUploadingDate.length >= 1 &&
        this.CleaningProcessDocuments.length > 0
      ) {
        this.CleaningProcessDocumentsDisabled = true;
      } else {
        this.CleaningProcessDocumentsDisabled = false;
      }
      //CleaningProcessDocumentsDisabled variable is to check whther all input fields except file upload are filled. CleaningProcessDocumentsFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
      if (
        this.CleaningProcessDocumentsDisabled === true ||
        this.CleaningProcessDocumentsFile === null
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledCleaningProcessDocumentSaveInfo() close
  },
  components: {
    Datepicker,
  },
  methods: {
    receiptValidation(docName) {
      this.receiptValidate = docName === null ? true : false;
    },
    isAlphaNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    },
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },

    //this method will make disabled property as false for CleaningProcess Save Info
    onCleaningProcessDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.CleaningProcessDocumentsSaveInfoDisabled = false;
      this.CleaningProcessDocumentsAddMoreDisabled = false;
      console.log(this.CleaningProcessDocuments);
    },

    //this function deletes CleaningProcessDocument record
    deleteCleaningProcessDocuments(index) {
      this.CleaningProcessDocuments.splice(index, 1);
      if (
        typeof this.CleaningProcessDocuments !== "undefined" &&
        this.CleaningProcessDocuments.length == 0
      ) {
        this.CleaningProcessDocumentsAddMoreDisabled = false;
        this.CleaningProcessDocumentsSaveInfoDisabled = true;
      }
    },

    //this method will submit the CleaningProcess form in backend.
    onSubmit() {
      this.$confirm({
        auth: false,
        message: "Confirm Submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            console.log("here", this.$v);
            this.isSubmitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
              return;
            }
            if (this.date != null) {
              this.date = moment(this.date).format("MM-DD-YYYY");
            }
            for (let i = 0; i < this.CleaningProcessDocuments.length; i++) {
              if (this.CleaningProcessDocuments[i].displayFileName === null) {
                this.CleaningProcessDocuments.splice(i, 1);
              } else {
                if (this.CleaningProcessDocuments[i].UploadingDate !== null) {
                  this.CleaningProcessDocuments[i].UploadingDate = moment(
                    this.CleaningProcessDocuments[i].UploadingDate
                  ).format("MM-DD-YYYY");
                }
              }
            }
            //initialize the form data
            let formData = new FormData();
            formData.append("requestID", this.requestId);
            formData.append("utilisationId", this.utilId);
            formData.append("BorrowerID", this.borrowerId);
            formData.append("Date", this.date);
            formData.append("CollateralID", this.selectedCollateral);
            formData.append("collateralQuantity", this.collateralQty);
            formData.append("grossWeight", this.grossWeight);
            formData.append("numberBags", this.bags);
            formData.append("grnNumber", this.grnNo);
            formData.append("WarrantRef", this.warrantRef);
            formData.append("unit", this.selectedUnit);
            formData.append("CollateralManagerID", this.collateralManagerId);
            formData.append("status", this.status);
            // formData.append("receivedInfoId", this.$route.params.id);
            formData.append(
              "clientId",
              JSON.stringify(this.$store.state.client_id)
            );
            formData.append(
              "CleaningDocuments",
              JSON.stringify(this.CleaningProcessDocuments)
            );
            formData.append("userId", this.$store.getters.getuserId);
            formData.append("moduleID", 6);
            formData.append("tabID", 22);
            const path = "add-cleaning-process/" + this.$route.params.id;
            Utils.start_loading();
            axios
              .post(path, formData, {
                headers: {
                  enctype: "multipart/form-data",
                },
              })
              .then((response) => {
                Utils.stop_loading();
                console.log("status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Cleaning Process added successfully!"
                  );
                  console.log("SUCCESS!!");
                  //Status Update API
                  const path =
                    "received-info-uncleaned-overview/" +
                    this.borrowerId +
                    "/" +
                    this.collateralName;
                  Utils.start_loading();
                  axios
                    .put(
                      path,
                      {
                        updateType: "status",
                        filtertype: "ReceivedUncleaned",
                        updatedStatus: this.status,
                        id: this.$route.params.id,
                        requestID: this.requestId,
                        clientId: this.$store.state.client_id,
                        userId: this.$store.getters.getuserId,
                        moduleID: 6,
                        tabID: 21,
                      },
                      {
                        headers: {
                          "Content-type": "application/json",
                        },
                      }
                    )
                    .then((res) => {
                      Utils.stop_loading();
                      console.log("Update status: " + res.data.status);
                      if (res.data.status == "success") {
                        if (this.status === "Fully Taken for Cleaning") {
                          localStorage.setItem(
                            "collateralTab",
                            "custom-tabs-one-inventory-tab"
                          );
                          this.$router.push({
                            name: "Collateral",
                            params: { underProcess: true },
                          });
                        } else {
                          this.$router.go(-1);
                        }
                      } else {
                        Utils.make_alert(
                          "warning",
                          "Something went wrong from server in status update!"
                        );
                      }
                    })
                    .catch((error) => {
                      Utils.stop_loading();
                      console.error("error: " + error);
                      Utils.make_alert(
                        "warning",
                        "Something went wrong in status update!"
                      );
                    });
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error(error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          }
        },
      });
    }, //onSubmit() close

    //this method will add new line for additional Collateral Documents
    addCleaningProcessDoc() {
      //when new line is added then CleaningProcessDocumentsFile variable and CleaningProcessDocumentsSaveInfoDisabled (Save info button disable property) should be re initialised to null.
      (this.CleaningProcessDocumentsFile = null),
        (this.CleaningProcessDocumentsSaveInfoDisabled = true),
        (this.CleaningProcessDocumentsAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.CleaningProcessDocuments.push({
          id: null,
          CleaningProcess_document_id: null,
          CleaningProcessDocName: null,
          UploadingDate: new Date(),
          displayFileName: null,
          errors: { filesize: "", docName: "" },
          CleaningProcessDocumentsRemoveDisabled: true,
          receipt_number: null,
        });
    }, //addCleaningProcessDoc() close
    //handles a change on the file upload
    handleFileUploadCleaningProcessDocuments(id, e) {
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.CleaningProcessDocumentsFile = e.target.files[0];

        //check for filesize. if it is larger than threshold then return following error response
        if (this.CleaningProcessDocumentsFile.size > 104857600) {
          e.preventDefault();
          var response = { errors: {} };
          response.errors.filesize = "File size should be less than 100MB";
          self.CleaningProcessDocuments[id].errors.filesize = JSON.stringify(
            response.errors.filesize
          ).replace(/\"/g, "");
          self.CleaningProcessDocuments[id].selectedFile = null;
          self.CleaningProcessDocuments[id].selectedFileName = null;
          self.CleaningProcessDocuments[id].displayFileName = null;
          self.CleaningProcessDocuments[
            id
          ].CleaningProcessDocumentsRemoveDisabled = true;
          return;
        }

        reader.readAsDataURL(this.CleaningProcessDocumentsFile);
        console.log("id: " + id);
        reader.onload = function () {
          self.CleaningProcessDocuments[id].selectedFile = reader.result;
          self.CleaningProcessDocuments[id].selectedFileName =
            e.target.files[0].name;
          self.CleaningProcessDocuments[id].displayFileName =
            e.target.files[0].name; //to show filename in file input
          self.CleaningProcessDocuments[id].errors.filesize = "";
          self.CleaningProcessDocuments[
            id
          ].CleaningProcessDocumentsRemoveDisabled = false;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }, //handleFileUploadCleaningProcessDocuments() close

    ////this method handles remove doc attachment
    removeCleaningProcessDocumentsDoc(id) {
      this.CleaningProcessDocumentsFile = null;
      this.CleaningProcessDocuments[id].selectedFile = null;
      this.CleaningProcessDocuments[id].selectedFileName = null;
      this.CleaningProcessDocuments[id].displayFileName = null;
      this.CleaningProcessDocuments[
        id
      ].CleaningProcessDocumentsRemoveDisabled = true;
    },

    getFacilitySetupByBorrower(id) {
      //this function is called to get list of open facilities
      const path = "/facility-setup-borrower/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // console.log(res.data.Facility_Setup);
          this.facilities = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getLoan(id) {
      //this function is called to get list of loans
      const path = "/get-loan/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          // console.log(res.data.Facility_Setup);
          this.loans = res.data.Loans;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of benchmarks
    getUnitTypes() {
      const path = "unit_types";
      axios
        .get(path)
        .then((res) => {
          this.UnitTypes = res.data.UnitTypes;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCollateral() {
      //this function is called to get list of Collateral
      const path = "collateral";
      axios
        .get(path)
        .then((res) => {
          this.collaterals = res.data.collateral;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getUncleanedDetails() {
      //this function is called on page load and created events. it will show details on Uncleaned CleaningProcess Info
      const path =
        "received-info-uncleaned-details/" + this.$route.params.id + "/" + null;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.requestId = res.data.received_info_id_details[0]["request_id"];
            this.utilId =
              res.data.received_info_id_details[0]["loan_registration_id"];
            this.borrowerName =
              res.data.received_info_id_details[0]["borrower_name"];
            this.requestDate =
              res.data.received_info_id_details[0]["received_date"];
            this.borrowerId =
              res.data.received_info_id_details[0]["borrower_id"];
            this.collateralQtyLimit =
              res.data.received_info_id_details[0]["collateral_quantity"];
            this.selectedCollateral =
              res.data.received_info_id_details[0]["collateral_id"];
            this.collateralName =
              res.data.received_info_id_details[0]["collateral_name"];
            this.selectedUnit = res.data.received_info_id_details[0]["unit"];
            this.grossLimit =
              res.data.received_info_id_details[0]["gross_weight"];
            this.bagLimit =
              res.data.received_info_id_details[0]["number_of_bags"];
            this.collateralManagerId =
              res.data.received_info_id_details[0]["collateral_manager_id"];
            if (this.status === "Fully Taken for Cleaning") {
              this.collateralQty = this.collateralQtyLimit;
              this.grossWeight = this.grossLimit;
              this.bags = this.bagLimit;
            }
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getUncleanedDetails close
  },

  mounted() {
    this.addCleaningProcessDoc();
    this.getUncleanedDetails();
  },

  created() {
    console.log(this.$route.params);
    this.status = this.$route.params.status;
    if (!this.status) this.$router.go(-1);
    this.getCollateral();
    this.getUnitTypes();
  },
};
</script>

<style scoped>
.error {
  color: red;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -70px;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>
