<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt" id="pageHeading">
                        <i class="fas fa-tachometer-alt"></i> Request Overview
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="card-body br_overview">
                <!------menton here style="height: 300px;"-------->
                <table class="table table-hover table-head-fixed text-nowrap">
                  <tbody>
                    <tr>
                      <td class="wg" id="requestId">Request ID:</td>
                      <td v-if="request.request_id !== null">
                        {{ request.request_id }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="utilId">Utilisation ID:</td>
                      <td v-if="request.loan_master_id !== null">
                        {{ request.loan_master_id }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="borrowerName">Borrower Name:</td>
                      <td v-if="request.borrower_name !== null">
                        {{ request.borrower_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="collateralManagerName">
                        Collateral Manager Name:
                      </td>
                      <td
                        v-if="
                          request.collateral_manager_name !== null &&
                          request.status === 'Approved'
                        "
                      >
                        {{ request.collateral_manager_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card-body br_overview">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td class="wg" id="offtakerName">Offtaker Name:</td>
                      <td v-if="request.offtaker_name !== null">
                        {{ request.offtaker_name }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="requestDate">Request Date:</td>
                      <td v-if="request.request_date !== null">
                        {{ request.request_date }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td class="wg" id="collateralQuantity">
                        Collateral Qty:
                      </td>
                      <td
                        v-if="
                          request.release_quantity !== null ||
                          request.verification_quantity != null
                        "
                      >
                        {{
                          request.release_quantity ||
                          request.verification_quantity
                        }}
                        {{ request.unitname }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!--========================================================================-->

        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-12">
                      <h1 class="headingt">Documents</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table class="table table-head-fixed text-nowrap">
                    <thead>
                      <tr>
                        <th></th>
                        <th id="srNo">Sr. no.</th>
                        <th id="doc">Documents</th>
                        <th class="c" id="uploadDate">Uploading Date</th>
                        <th class="c" id="status">Status</th>
                        <th
                          class="c"
                          v-if="
                            loggedInUserGroupId == 'Analyst' ||
                            (loggedInUserGroupId == 'Admin' &&
                              loggedInUserSubGroupId == 0)
                          "
                        >
                          Change Status
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(doc, index) in releaseDocuments"
                        :key="doc.id"
                      >
                        <td
                          class="center"
                          v-on:click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-download text-info"></i>
                        </td>
                        <td
                          v-if="
                            doc.ReleaseDocName !== null ||
                            doc.VerificationDocName !== null
                          "
                        >
                          {{ index + 1 }}
                        </td>
                        <td class="c" v-else>-</td>
                        <td
                          v-if="
                            doc.ReleaseDocName !== null ||
                            doc.VerificationDocName !== null
                          "
                        >
                          {{ doc.ReleaseDocName || doc.VerificationDocName }}
                        </td>
                        <td class="c" v-else>-</td>
                        <td
                          v-if="
                            doc.ReleaseDocumentsDate !== null ||
                            doc.VerificationDocumentsDate !== null
                          "
                          class="c"
                        >
                          {{
                            doc.ReleaseDocumentsDate ||
                            doc.VerificationDocumentsDate
                          }}
                        </td>
                        <td v-else class="c">-</td>
                        <td
                          v-if="doc.document_status !== null"
                          :class="{
                            'c text-success':
                              doc.document_status === 'Approved',
                            'c text-warning':
                              doc.document_status === 'Received',
                            'c text-danger': doc.document_status === 'Rejected',
                            'c text-info':
                              doc.document_status === 'Near to Expiry',
                            'c text-dark': doc.document_status === 'Expired',
                          }"
                        >
                          {{
                            doc.document_status === "Received"
                              ? loggedInUserGroupId === "Borrower"
                                ? "Uploaded"
                                : doc.document_status
                              : doc.document_status
                          }}
                        </td>
                        <td v-else class="c">-</td>

                        <td
                          class="c status"
                          v-if="
                            loggedInUserGroupId == 'Analyst' ||
                            (loggedInUserGroupId == 'Admin' &&
                              loggedInUserSubGroupId == 0)
                          "
                        >
                          <div class="status">
                            <select
                              id="docStatus"
                              name="docStatus"
                              @change="
                                onChangeDocStatus(
                                  $event,
                                  doc.release_document_id ||
                                    doc.verification_document_id,
                                  doc.document_status,
                                  index,
                                  'RequestDocStatus'
                                )
                              "
                              :disabled="doc.document_status !== 'Received'"
                              class="form-control"
                              :value="doc.document_status"
                            >
                              <option value="Received">Received</option>
                              <option value="Approved">Approved</option>
                              <option value="Rejected">Rejected</option>
                              <option
                                v-if="doc.document_status === 'Near to Expiry'"
                                value="Near to Expiry"
                              >
                                Near to Expiry
                              </option>
                              <option
                                v-if="doc.document_status === 'Expired'"
                                value="Expired"
                              >
                                Expired
                              </option>
                            </select>
                          </div>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.Tabpane second end-->
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper -->

    <!-- ./wrapper -->
    <!-- ./modal start -->
    <div class="modal fade" id="view_doc">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-eye-alt"></i> View Document
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>
          <!-- Modal body -->
          <!-- <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <table class="table">
                    <tbody>
                      <tr v-for="doc in selectedDirDocumentsList">
                        <td class="col-10">{{ doc.document_name }}</td>
                        <td
                          class="c col-1"
                          v-on:click="downloadDocument(doc.document_path)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-download text-info"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> -->
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <!-- <router-link :to="{ name: 'BorrowerOverview' }">
                <button
                  type="button"
                  class="btn-sm btn btn-dark sh"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
  </div>
  <!-- ./modal end -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
export default {
  data() {
    return {
      id: null,
      request: [],
      releaseDocuments: [],
      requestType: null,
    };
  },

  components: {
    Datepicker,
  },

  methods: {
    showDirDocumentModal(docList) {
      this.selectedDirDocumentsList = docList;
      $("#view_doc").modal("show");
    },

    onChangeDocStatus(
      updatedStatus,
      ReleaseVerificationdocId,
      prevStatus,
      index,
      filtertype
    ) {
      //this function is called when user changes the document status. The status is updated in the database for that specific document.
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path = "single-request-summary-overview/" + this.id;
            if (filtertype === "RequestDocStatus") {
              axios
                .put(
                  path,
                  {
                    filtertype: filtertype,
                    updateType: "status",
                    updatedStatus: updatedStatus.target.value,
                    requestType: this.requestType,
                    id: ReleaseVerificationdocId,
                    request_id: this.id,
                    clientId: this.$store.state.client_id,
                    userId: this.$store.getters.getuserId,
                    tabID: this.requestType === "Release" ? 29 : 28,
                    moduleID: 11,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Utils.stop_loading();
                  console.log("kyc update status: " + res.data.status);
                  if (res.data.status == "success") {
                    this.releaseDocuments[index].document_status =
                      updatedStatus.target.value;
                    Utils.make_alert("success", "Status updated successfully!");
                    //if you want to send any data into server before redirection then you can do it here
                  } else {
                    updatedStatus.target.value = prevStatus;
                    Utils.make_alert("warning", "Something went wrong!");
                  }
                })
                .catch((error) => {
                  Utils.stop_loading();
                  updatedStatus.target.value = prevStatus;
                  console.error("error: " + error);
                  Utils.make_alert("warning", "Something went wrong!");
                });
            }
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeDocStatus close

    getRequest() {
      //this function is called on page load and created events. it will show details on Request with Requestid equal to this.id
      const path = "single-request-summary-overview/" + this.id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            // console.log(res.data);
            this.request = res.data.release.request_details[0];
            this.releaseDocuments =
              res.data.release.release_documents_details ||
              res.data.release.verification_documents_details;
            if (Object.keys(this.releaseDocuments).length == 0) {
              this.releaseDocuments = [];
              this.releaseDocuments.push({
                id: null,
                ReleaseDocName: null,
                VerificationDocName: null,
                ReleaseDocumentsDate: null,
                VerificationDocumentsDate: null,
                document_status: null,
                document_path: null,
                document_id: null,
              });
            }
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getRequest close

    downloadDocument: function (filePath) {
      Utils.downloadDocument(filePath);
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getRequest();
    this.requestType = this.id.startsWith("V") ? "Verification" : "Release";
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style>
textarea {
  border-style: none;
  border-color: Transparent;
  overflow: auto;
}
th,
td {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right !important;
}
</style>
