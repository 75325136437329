var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('nav-bar'),_c('aside-bar'),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid tabtop pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-6"},[_c('ul',{attrs:{"id":"bck_btn"}},[_c('li',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"icofont-caret-left text-dark"}),_c('span',[_vm._v("Back")])])])])])])])])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"offtakerName"}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.offtakerName),expression:"offtakerName"}],staticClass:"form-control",class:{
                      'is-invalid':
                        (_vm.isSubmitted && _vm.$v.offtakerName.$error) ||
                        _vm.offtakerExists,
                    },attrs:{"type":"text","placeholder":"","id":"offtakerName","name":"offtakerName"},domProps:{"value":(_vm.offtakerName)},on:{"keypress":function($event){return _vm.isAlphaNum($event)},"keyup":function($event){return _vm.checkOfftakerName()},"input":function($event){if($event.target.composing){ return; }_vm.offtakerName=$event.target.value}}}),(
                      (_vm.isSubmitted && _vm.$v.offtakerName.$error) ||
                      _vm.offtakerExists
                    )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.offtakerName.required)?_c('span',[_vm._v("Name is required")]):_vm._e(),(!_vm.$v.offtakerName.maxLength)?_c('span',[_vm._v("Max length of 100 characters exceeded")]):_vm._e(),(_vm.offtakerExists)?_c('span',[_vm._v("Offtaker Name already exists!")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"incorporationDate"}},[_vm._v("Incorporation Date")]),_c('datepicker',{staticClass:"datepicker",class:{
                      'is-invalid':
                        _vm.isSubmitted && _vm.$v.incorporationDate.$error,
                    },attrs:{"bootstrap-styling":true,"name":"incorporationDate","id":"incorporationDate","placeholder":"Select Date","format":_vm.$store.state.date_format,"calendar-button-icon":"fa fa-calendar","calendar-button":true,"disabledDates":{ from: new Date() }},model:{value:(_vm.incorporationDate),callback:function ($$v) {_vm.incorporationDate=$$v},expression:"incorporationDate"}}),(_vm.isSubmitted && _vm.$v.incorporationDate.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.incorporationDate.required)?_c('span',[_vm._v("Incorporation Date is required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-2 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"address"}},[_vm._v("Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.address.$error,
                    },attrs:{"type":"text","placeholder":"","id":"address","name":"address"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.address.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.address.required)?_c('span',[_vm._v("Address is required")]):_vm._e(),(!_vm.$v.address.maxLength)?_c('span',[_vm._v("Max length of 250 characters exceeded")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"selectedCountry"}},[_vm._v("Country")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCountry),expression:"selectedCountry"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.selectedCountry.$error,
                    },attrs:{"id":"selectedCountry","name":"selectedCountry"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedCountry=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getStatesByCountry()}]}},[_c('option',{domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.countries),function(country){return _c('option',{key:country.id,domProps:{"value":country.id}},[_vm._v(" "+_vm._s(country.country_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedCountry.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedCountry.required)?_c('span',[_vm._v("Country is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field"},[_vm._v("State")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedState),expression:"selectedState"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.isSubmitted && _vm.$v.selectedState.$error,
                    },attrs:{"id":"selectedState","name":"selectedState"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedState=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getCitiesByState()}]}},[_c('option',{domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.states),function(state){return _c('option',{key:state.id,domProps:{"value":state.id}},[_vm._v(" "+_vm._s(state.state_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedState.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedState.required)?_c('span',[_vm._v("State is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"selectedCity"}},[_vm._v("City")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCity),expression:"selectedCity"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.selectedCity.$error,
                    },attrs:{"id":"selectedCity","name":"selectedCity"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedCity=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.cities),function(city){return _c('option',{key:city.id,domProps:{"value":city.id}},[_vm._v(" "+_vm._s(city.city_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedCity.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.selectedCity.required)?_c('span',[_vm._v("City is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"website"}},[_vm._v("Website")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.website),expression:"website"}],staticClass:"form-control",class:{
                      'is-invalid':
                        (_vm.isSubmitted && _vm.$v.website.$error) || _vm.msg.website,
                    },attrs:{"type":"text","placeholder":"","id":"website","name":"website"},domProps:{"value":(_vm.website)},on:{"input":function($event){if($event.target.composing){ return; }_vm.website=$event.target.value}}}),(_vm.msg.website)?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.msg.website))])]):_vm._e(),(_vm.isSubmitted && _vm.$v.website.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.website.required)?_c('span',[_vm._v("Website is required")]):_vm._e(),(!_vm.$v.website.maxLength)?_c('span',[_vm._v("Max length of 100 characters exceeded")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"regDate"}},[_vm._v("Registration Date")]),_c('datepicker',{staticClass:"datepicker",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.regDate.$error,
                    },attrs:{"bootstrap-styling":true,"name":"regDate","id":"regDate","placeholder":"Select Date","format":_vm.$store.state.date_format,"calendar-button-icon":"fa fa-calendar","calendar-button":true,"disabledDates":{ from: new Date() }},model:{value:(_vm.regDate),callback:function ($$v) {_vm.regDate=$$v},expression:"regDate"}}),(_vm.isSubmitted && _vm.$v.regDate.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.regDate.required)?_c('span',[_vm._v("Registration Date is required")]):_vm._e()]):_vm._e()],1)])])])])]),_c('div',{staticClass:"container-fluid ot_tb pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_vm._m(1),_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"phoneNumber"}},[_vm._v("Contact No")]),_c('br'),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumberCode),expression:"phoneNumberCode"}],staticClass:"contact_code",class:{
                          'is-invalid':
                            _vm.isSubmitted && _vm.$v.phoneNumberCode.$error,
                        },attrs:{"id":"phoneNumberCode","name":"phoneNumberCode"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.phoneNumberCode=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("--Select--")]),_vm._l((_vm.countryCodes),function(code){return _c('option',{key:code.id,domProps:{"value":code.id}},[_vm._v(" "+_vm._s(code.country_code)+" ")])})],2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"}],staticClass:"contact",class:{
                          'is-invalid': _vm.isSubmitted && _vm.$v.phoneNumber.$error,
                        },attrs:{"id":"phoneNumber","placeholder":""},domProps:{"value":(_vm.phoneNumber)},on:{"keypress":function($event){return _vm.isNum($event)},"input":function($event){if($event.target.composing){ return; }_vm.phoneNumber=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.phoneNumberCode.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.phoneNumberCode.required)?_c('span',[_vm._v("Contact Number Code is required")]):_vm._e()]):_vm._e(),(_vm.isSubmitted && _vm.$v.phoneNumber.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.phoneNumber.required)?_c('span',[_vm._v("Contact Number is required")]):_vm._e(),(!_vm.$v.phoneNumber.minLength)?_c('span',[_vm._v("Min length of 10 characters required")]):_vm._e(),(!_vm.$v.phoneNumber.maxLength)?_c('span',[_vm._v("Max length of 13 characters exceeded")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-3 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{attrs:{"for":"phoneNumber"}},[_vm._v("Secondary Contact No")]),_c('br'),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.secondaryPhoneNumberCode),expression:"secondaryPhoneNumberCode"}],staticClass:"contact_code",class:{
                          'is-invalid':
                            _vm.isSubmitted && _vm.$v.secondaryPhoneNumberCode.$error,
                        },attrs:{"id":"secondaryPhoneNumberCode","name":"secondaryPhoneNumberCode"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.secondaryPhoneNumberCode=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("--Select--")]),_vm._l((_vm.countryCodes),function(code){return _c('option',{key:code.id,domProps:{"value":code.id}},[_vm._v(" "+_vm._s(code.country_code)+" ")])})],2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.secondaryPhoneNumber),expression:"secondaryPhoneNumber"}],staticClass:"contact",class:{
                          'is-invalid':
                            _vm.isSubmitted && _vm.$v.secondaryPhoneNumber.$error,
                        },attrs:{"id":"secondaryPhoneNumber","placeholder":""},domProps:{"value":(_vm.secondaryPhoneNumber)},on:{"keypress":function($event){return _vm.isNum($event)},"input":function($event){if($event.target.composing){ return; }_vm.secondaryPhoneNumber=$event.target.value}}}),(
                          _vm.isSubmitted && _vm.$v.secondaryPhoneNumberCode.$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.secondaryPhoneNumberCode.required)?_c('span',[_vm._v("Secondary Contact Number Code is required")]):_vm._e()]):_vm._e(),(_vm.isSubmitted && _vm.$v.secondaryPhoneNumber.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.secondaryPhoneNumber.required)?_c('span',[_vm._v("Secondary Contact Number is required")]):_vm._e(),(!_vm.$v.secondaryPhoneNumber.minLength)?_c('span',[_vm._v("Min length of 10 characters required")]):_vm._e(),(!_vm.$v.secondaryPhoneNumber.maxLength)?_c('span',[_vm._v("Max length of 13 characters exceeded")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-3 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field",attrs:{"for":"email"}},[_vm._v("Email ID")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{
                          'is-invalid':
                            (_vm.isSubmitted && _vm.$v.email.$error) || _vm.msg.email,
                        },attrs:{"type":"email","placeholder":"","id":"email","name":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),(_vm.msg.email)?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.msg.email))])]):_vm._e(),(_vm.isSubmitted && _vm.$v.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.email.required)?_c('span',[_vm._v("Email ID is required")]):_vm._e(),(!_vm.$v.email.maxLength)?_c('span',[_vm._v("Max length of 50 characters exceeded")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-3 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{attrs:{"for":"secondaryEmail"}},[_vm._v("Secondary Email ID")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.secondaryEmail),expression:"secondaryEmail"}],staticClass:"form-control",class:{
                          'is-invalid':
                            (_vm.isSubmitted && _vm.$v.secondaryEmail.$error) ||
                            _vm.msg.secondaryEmail,
                        },attrs:{"type":"email","placeholder":"","id":"secondaryEmail","name":"secondaryEmail"},domProps:{"value":(_vm.secondaryEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.secondaryEmail=$event.target.value}}}),(_vm.msg.secondaryEmail)?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.msg.secondaryEmail))])]):_vm._e(),(_vm.isSubmitted && _vm.$v.secondaryEmail.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.secondaryEmail.maxLength)?_c('span',[_vm._v("Max length of 50 characters exceeded")]):_vm._e()]):_vm._e()])])])])])])])]),_c('div',{staticClass:"container-fluid ot_tb pt-3"},[_c('div',{staticClass:"card card-primary card-tabs"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid",attrs:{"id":"al_tb"}},[_c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-lg-6"},[_c('ul',{attrs:{"id":"sr_btn"}},[_c('li',[_c('button',{staticClass:"btn btn-sm sh_n btn-dark",attrs:{"type":"button","disabled":_vm.OfftakerAddMoreDisabled},on:{"click":_vm.addOfftaker}},[_c('i',{staticClass:"icofont-plus"}),_vm._v(" Add More ")])])])])])]),_c('div',{staticClass:"card-body p-0"},[_vm._l((_vm.OfftakerList),function(off,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticClass:"required-field"},[_vm._v("Document")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(off.OfftakerDocName),expression:"off.OfftakerDocName"}],staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.isSubmitted &&
                            _vm.$v.OfftakerList.$each[index].OfftakerDocName
                              .$error,
                        },attrs:{"id":"OfftakerDocName","name":"OfftakerDocName"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(off, "OfftakerDocName", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.offtakerDocuments),function(document){return _c('option',{key:document.id,domProps:{"value":document.document_name}},[_vm._v(" "+_vm._s(document.document_name)+" ")])})],2),(
                          _vm.isSubmitted &&
                          _vm.$v.OfftakerList.$each[index].OfftakerDocName.$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(
                            !_vm.$v.OfftakerList.$each[index].OfftakerDocName
                              .required
                          )?_c('span',[_vm._v("Document Name is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4 col-sm-5 col-6"},[_c('div',{staticClass:"form-group fc",on:{"mouseenter":function($event){return _vm.mouseenter(off.OfftakerDocName, index)},"mouseleave":function($event){return _vm.mouseleave(index)}}},[_c('label',{staticClass:"required-field",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Upload Document")]),_c('div',{staticClass:"custom-file"},[_c('input',{ref:"file",refInFor:true,staticClass:"custom-file-input",class:{
                            'is-invalid':
                              (_vm.isSubmitted && off.displayFileName === null) ||
                              off.errors.filesize ||
                              off.errors.docName,
                          },attrs:{"type":"file","id":"customFile","name":"file","accept":".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx","disabled":off.OfftakerDocName === null},on:{"change":function($event){return _vm.handleFileUploadOfftaker(index, $event)}}}),_c('small',[_c('p',{staticStyle:{"background-color":"powderblue"}},[_c('b',[_vm._v(_vm._s(off.displayFileName))]),_c('small',[(off.OfftakerRemoveDisabled === false)?_c('u',{staticStyle:{"color":"blue","cursor":"pointer"},on:{"click":function($event){return _vm.removeOfftakerDoc(index)}}},[_vm._v("Remove")]):_vm._e()])])]),(off.errors.filesize)?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(off.errors.filesize))])]):_vm._e(),(off.errors.docName)?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(off.errors.docName))])]):_vm._e(),_c('label',{staticClass:"custom-file-label",attrs:{"for":"customFile"}},[_vm._v("Choose file")]),(_vm.isSubmitted && off.displayFileName === null)?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v("Document is required")])]):_vm._e()])])]),_c('div',{staticClass:"col-md-2 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc",on:{"click":function($event){return _vm.docValidation(off.displayFileName, 'issueDate')}}},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v("Issue Date")]),_c('datepicker',{staticClass:"datepicker",class:{
                          'is-invalid':
                            _vm.docIssueDateValidation &&
                            off.displayFileName === null,
                        },attrs:{"bootstrap-styling":true,"name":"UploadingDate","id":"UploadingDate","placeholder":"Select Date","format":_vm.$store.state.date_format,"calendar-button-icon":"fa fa-calendar","calendar-button":true,"disabledDates":{
                          from: new Date(),
                        },"disabled":off.displayFileName === null},model:{value:(off.UploadingDate),callback:function ($$v) {_vm.$set(off, "UploadingDate", $$v)},expression:"off.UploadingDate"}}),(off.displayFileName === null)?_c('div',{staticClass:"invalid-feedback"},[(_vm.docIssueDateValidation)?_c('span',[_vm._v("Please upload the Document")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-2 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc",on:{"click":function($event){return _vm.docValidation(off.displayFileName, 'expiryDate')}}},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v("Expiry Date")]),_c('datepicker',{staticClass:"datepicker",class:{
                          'is-invalid':
                            _vm.docExpiryDateValidation &&
                            off.displayFileName === null,
                        },attrs:{"bootstrap-styling":true,"name":"ExpiryDate","id":"ExpiryDate","placeholder":"Select Date","format":_vm.$store.state.date_format,"calendar-button-icon":"fa fa-calendar","calendar-button":true,"disabledDates":{
                          to: new Date(off.UploadingDate),
                        },"disabled":off.displayFileName === null},model:{value:(off.ExpiryDate),callback:function ($$v) {_vm.$set(off, "ExpiryDate", $$v)},expression:"off.ExpiryDate"}}),(off.displayFileName === null)?_c('div',{staticClass:"invalid-feedback"},[(_vm.docExpiryDateValidation)?_c('span',[_vm._v("Please upload the Document")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-2 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v("Comments")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(off.comments),expression:"off.comments"}],staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.isSubmitted &&
                            _vm.$v.OfftakerList.$each[index].comments.$error,
                        },attrs:{"type":"text","id":"OfftakerComments","placeholder":""},domProps:{"value":(off.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(off, "comments", $event.target.value)}}}),(
                          _vm.isSubmitted &&
                          _vm.$v.OfftakerList.$each[index].comments.$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(
                            !_vm.$v.OfftakerList.$each[index].comments.maxLength
                          )?_c('span',[_vm._v("Max length of 50 characters exceeded")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-1 col-sm-4 col-6"},[_c('div',{staticClass:"form-group fc"},[_c('label',{staticStyle:{"visibility":"hidden"},attrs:{"for":"exampleInputEmail1"}},[_vm._v("Delete")]),_c('div',{staticStyle:{"width":"250px"},style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.deleteOfftaker(index)}}},[_c('i',{staticClass:"icofont-trash text-danger"})])])])])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right mb-3"},[_c('button',{staticClass:"btn-sm btn btn-primary sh",attrs:{"type":"button","disabled":_vm.isDisabledOfftakerSaveInfo},on:{"click":function($event){return _vm.onOfftakerSaveInfo()}}},[_vm._v(" Save Info ")])])])],2)])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right mb-3"},[_c('ul',{attrs:{"id":"sr_btn"}},[_c('li',[_c('button',{staticClass:"btn-sm btn btn-success sh",attrs:{"type":"button"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" Submit ")])]),_c('li',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('button',{staticClass:"btn-sm btn btn-dark sh",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Cancel ")])])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"headingt"},[_c('i',{staticClass:"icofont-plus"}),_vm._v(" Add Offtaker ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tp_mid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"headingt"},[_vm._v("Contact Details")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"headingt"},[_vm._v("Documents")])])}]

export { render, staticRenderFns }