<!-- This is waste page. -->

<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">
                        <i class="icofont-plus"></i> Waste
                      </h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" style="cursor: pointer">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label id="facility_id">Facility ID</label><br />
                    <span>{{ facilityRegistrationId }}</span>
                  </div>
                </div>
                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label id="total_quantity">Total Quantity (MT)</label>
                    <br />
                    <span v-if="totalQuantity">{{ totalQuantity }}</span>
                    <span v-else>-</span>
                  </div>
                </div>

                <div class="col-md-2 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label id="total_bags">Total Bags</label>
                    <br />
                    <span v-if="totalBags">{{ totalBags }}</span>
                    <span v-else>-</span>
                  </div>
                </div>

                <!-- <div class="col-5"></div> -->

                <!-- <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="loggedInUserGroupId === 'Admin'"
                >
                  <div class="form-group fc">
                    <label for="selectedPriceType">Price Type</label>
                    <select
                      id="selectedPriceType"
                      class="form-control"
                      name="selectedPriceType"
                      v-model="selectedPriceType"
                      @change="getPrice()"
                    >
                      <option :value="null">--- Select ---</option>
                      <option v-for="p in priceTypes" :value="p.id" :key="p.id">
                        {{ p.price_name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="loggedInUserGroupId === 'Admin'"
                >
                  <div class="form-group fc">
                    <label for="price">Price(Per MT)</label>
                    <input
                      type="text"
                      class="form-control"
                      id="price"
                      v-model="price"
                      @change="calculateValue()"
                      @keypress="removeMinus($event)"
                      :disabled="selectedPriceType != 5"
                      :class="{
                        'is-invalid':
                          (isSubmitted && $v.price.$error) ||
                          (selectedPriceType === 5 &&
                            (price === null || price == 0)),
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.price.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.price.decimal"
                        >Price should be decimal only<br
                      /></span>
                      <span v-if="!$v.price.maxLength"
                        >Max length of 10 digits exceeded</span
                      >
                    </div>
                    <div
                      v-if="
                        selectedPriceType === 5 &&
                        (price === null || price == 0)
                      "
                      class="invalid-feedback"
                    >
                      <span>Please enter a price.</span>
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-2 col-sm-5 col-6"
                  v-if="loggedInUserGroupId === 'Admin'"
                >
                  <div class="form-group fc">
                    <label for="value">Value</label>
                    <input
                      type="number"
                      class="form-control"
                      id="value"
                      v-model="value"
                      disabled
                    />
                  </div>
                </div> -->

                <div class="col-md-4 col-sm-5 col-6">
                  <div class="form-group fc">
                    <label id="selectedWarehouse">Warehouse</label>
                    <br />
                    <span v-if="warehouseId"
                      >{{ warehouseId }}: {{ warehouseAddress }}</span
                    >
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6">
                  <h1 class="headingt">
                    <i class="icofont-refresh"></i> Warrants
                  </h1>
                </div>
                <div
                  class="col-lg-6 text-right"
                  v-if="
                    loggedInUserGroupId === 'Admin' ||
                    loggedInUserGroupId === 'Collateral Manager'
                  "
                >
                  <ul id="sr_btn">
                    <button
                      type="button"
                      class="btn btn-sm sh_n btn-primary"
                      @click="addWasteWarrant()"
                    >
                      <i class="icofont-plus"></i
                      ><span class="mob"> Add Warrants</span>
                    </button>
                  </ul>
                </div>
              </div>
            </div>
            <!---collapse part-->

            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mx-3">
                  <div class="hide collapse show" id="collapseExample">
                    <div id="accordion">
                      <div
                        class="card"
                        v-for="(war, index) in wasteWarrants"
                        :key="index"
                      >
                        <div class="card-header">
                          <ul id="acc_info">
                            <li @click="toggleIcon(index, war.warrant_id)">
                              <a
                                class="warrant_button"
                                data-toggle="collapse"
                                :href="'#' + war.warrant_id"
                              >
                                <i
                                  :class="war.icon"
                                  style="
                                    color: darkslategray;
                                    padding-right: 10px;
                                  "
                                ></i>
                              </a>
                            </li>
                            <li>
                              <div class="form-group fc d-flex">
                                <span
                                  class="input-group-text form-control new"
                                  style="max-width: 55px !important"
                                >
                                  Ref ID:</span
                                >
                                <input
                                  id="reference_id"
                                  type="text"
                                  class="form-control"
                                  v-model="war.reference_id"
                                  @keyup="
                                    checkDuplicateRefernceID(
                                      war.reference_id,
                                      index
                                    )
                                  "
                                  @keypress="removeSlash($event)"
                                  :disabled="war.id"
                                  :class="{
                                    'is-invalid':
                                      (isSubmitted &&
                                        $v.wasteWarrants.$each[index]
                                          .reference_id.$error) ||
                                      war.refIDexists,
                                  }"
                                /><br />
                                <div
                                  style="padding-left: 10px"
                                  v-if="
                                    (isSubmitted &&
                                      $v.wasteWarrants.$each[index].reference_id
                                        .$error) ||
                                    war.refIDexists
                                  "
                                  class="invalid-feedback"
                                >
                                  <span
                                    v-if="
                                      !$v.wasteWarrants.$each[index]
                                        .reference_id.required
                                    "
                                  >
                                    Ref ID is required</span
                                  >
                                  <span
                                    v-if="
                                      !$v.wasteWarrants.$each[index]
                                        .reference_id.maxLength
                                    "
                                    >Max length of 50 characters exceeded</span
                                  >
                                  <span v-if="war.refIDexists"
                                    >Ref ID already exists</span
                                  >
                                </div>
                              </div>
                            </li>
                            <li>
                              <button
                                type="button"
                                class="btn-sm btn btn-success sh shnone"
                                @click="
                                  showQualityParameter(war.reference_id, index)
                                "
                              >
                                Quality Parameters
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div
                          :id="war.warrant_id"
                          class="collapse hide"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-2 col-sm-5 col-6">
                                <div class="form-group fc">
                                  <label
                                    for="warrant_net_weight"
                                    class="required-field"
                                    >Net Weight (KG)</label
                                  >
                                  <input
                                    id="warrant_net_weight"
                                    type="text"
                                    class="form-control"
                                    v-model="war.net_weight"
                                    @keypress="removeMinus($event)"
                                    :disabled="
                                      loggedInUserGroupId === 'Analyst' ||
                                      loggedInUserGroupId === 'Borrower' ||
                                      (war.id && war.quantity === 0)
                                    "
                                    :class="{
                                      'is-invalid':
                                        isSubmitted &&
                                        $v.wasteWarrants.$each[index].net_weight
                                          .$error,
                                    }"
                                  />
                                  <div
                                    v-if="
                                      isSubmitted &&
                                      $v.wasteWarrants.$each[index].net_weight
                                        .$error
                                    "
                                    class="invalid-feedback"
                                  >
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index]
                                          .net_weight.required
                                      "
                                      >Net Weight is required</span
                                    >
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index]
                                          .net_weight.decimal
                                      "
                                      >Net Weight should be decimal only<br
                                    /></span>
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index]
                                          .net_weight.maxLength
                                      "
                                      >Max length of 10 digits exceeded</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-2 col-sm-5 col-6">
                                <div class="form-group fc">
                                  <label
                                    for="warrant_gross_weight"
                                    class="required-field"
                                    >Gross Weight (KG)</label
                                  >
                                  <input
                                    id="warrant_gross_weight"
                                    type="text"
                                    class="form-control"
                                    v-model="war.gross_weight"
                                    @keypress="removeMinus($event)"
                                    :disabled="
                                      loggedInUserGroupId === 'Analyst' ||
                                      loggedInUserGroupId === 'Borrower' ||
                                      (war.id && war.quantity === 0)
                                    "
                                    :class="{
                                      'is-invalid':
                                        isSubmitted &&
                                        $v.wasteWarrants.$each[index]
                                          .gross_weight.$error,
                                    }"
                                  />
                                  <div
                                    v-if="
                                      isSubmitted &&
                                      $v.wasteWarrants.$each[index].gross_weight
                                        .$error
                                    "
                                    class="invalid-feedback"
                                  >
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index]
                                          .gross_weight.required
                                      "
                                      >Gross Weight is required</span
                                    >
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index]
                                          .gross_weight.decimal
                                      "
                                      >Gross Weight should be decimal only<br
                                    /></span>
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index]
                                          .gross_weight.maxLength
                                      "
                                      >Max length of 10 digits exceeded</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-2 col-sm-5 col-6">
                                <div class="form-group fc">
                                  <label
                                    for="warrant_quantity"
                                    class="required-field"
                                    >Quantity (MT)</label
                                  >
                                  <input
                                    id="warrant_quantity"
                                    type="text"
                                    class="form-control"
                                    v-model="war.quantity_static"
                                    @keyup="calculateTotalQuantity()"
                                    @keypress="removeMinus($event)"
                                    :disabled="war.id"
                                    :class="{
                                      'is-invalid':
                                        isSubmitted &&
                                        $v.wasteWarrants.$each[index]
                                          .quantity_static.$error,
                                    }"
                                  />
                                  <div
                                    v-if="
                                      isSubmitted &&
                                      $v.wasteWarrants.$each[index]
                                        .quantity_static.$error
                                    "
                                    class="invalid-feedback"
                                  >
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index]
                                          .quantity_static.required
                                      "
                                      >Quantity is required</span
                                    >
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index]
                                          .quantity_static.decimal
                                      "
                                      >Quantity should be decimal only<br
                                    /></span>
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index]
                                          .quantity_static.maxLength
                                      "
                                      >Max length of 10 digits exceeded</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-2 col-sm-5 col-6">
                                <div class="form-group fc">
                                  <label
                                    for="warrant_bags"
                                    class="required-field"
                                    >Bags</label
                                  >
                                  <input
                                    id="warrant_bags"
                                    type="text"
                                    class="form-control"
                                    v-model="war.bags"
                                    @change="calculateTotalBags"
                                    :disabled="
                                      loggedInUserGroupId === 'Analyst' ||
                                      loggedInUserGroupId === 'Borrower' ||
                                      (war.id && war.quantity === 0)
                                    "
                                    :class="{
                                      'is-invalid':
                                        isSubmitted &&
                                        $v.wasteWarrants.$each[index].bags
                                          .$error,
                                    }"
                                  />
                                  <div
                                    v-if="
                                      isSubmitted &&
                                      $v.wasteWarrants.$each[index].bags.$error
                                    "
                                    class="invalid-feedback"
                                  >
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index].bags
                                          .required
                                      "
                                      >Bags are required</span
                                    >
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index].bags
                                          .numeric
                                      "
                                      >Bags should be numeric only<br
                                    /></span>
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index].bags
                                          .maxLength
                                      "
                                      >Max length of 10 digits exceeded</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-2 col-sm-5 col-6">
                                <div class="form-group fc">
                                  <label
                                    for="warrant_grn"
                                    class="required-field"
                                    >GRN</label
                                  >
                                  <input
                                    id="warrant_grn"
                                    type="text"
                                    class="form-control"
                                    v-model="war.grn"
                                    :disabled="
                                      loggedInUserGroupId === 'Analyst' ||
                                      loggedInUserGroupId === 'Borrower' ||
                                      (war.id && war.quantity === 0)
                                    "
                                    :class="{
                                      'is-invalid':
                                        isSubmitted &&
                                        $v.wasteWarrants.$each[index].grn
                                          .$error,
                                    }"
                                  />
                                  <div
                                    v-if="
                                      isSubmitted &&
                                      $v.wasteWarrants.$each[index].grn.$error
                                    "
                                    class="invalid-feedback"
                                  >
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index].grn
                                          .required
                                      "
                                      >GRN is required</span
                                    >
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index].grn
                                          .numeric
                                      "
                                      >GRN should be numeric only<br
                                    /></span>
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index].grn
                                          .maxLength
                                      "
                                      >Max length of 10 digits exceeded</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-2 col-sm-5 col-6"></div>
                              <div class="col-md-2 col-sm-5 col-6">
                                <div class="form-group fc">
                                  <label for="warrant_date">Date</label>
                                  <datepicker
                                    class="datepicker"
                                    :bootstrap-styling="true"
                                    name="warrant_date"
                                    id="warrant_date"
                                    placeholder="Select Date"
                                    v-model="war.date"
                                    :format="$store.state.date_format"
                                    calendar-button-icon="fa fa-calendar"
                                    :calendar-button="true"
                                    :disabled="
                                      loggedInUserGroupId === 'Analyst' ||
                                      loggedInUserGroupId === 'Borrower' ||
                                      (war.id && war.quantity === 0)
                                    "
                                  ></datepicker>
                                </div>
                              </div>

                              <div class="col-md-2 col-sm-5 col-6">
                                <div class="form-group fc">
                                  <label for="warrant_pending_quantity"
                                    >Pending Quantity (MT)</label
                                  >
                                  <input
                                    id="warrant_pending_quantity"
                                    type="text"
                                    class="form-control"
                                    v-model="war.quantity"
                                    disabled
                                  />
                                </div>
                              </div>

                              <div class="col-md-2 col-sm-5 col-6">
                                <div class="form-group fc">
                                  <label for="warrant_comments">Comments</label>
                                  <input
                                    id="warrant_comments"
                                    type="text"
                                    class="form-control"
                                    v-model="war.warrant_comments"
                                    :disabled="
                                      loggedInUserGroupId === 'Analyst' ||
                                      loggedInUserGroupId === 'Borrower' ||
                                      (war.id && war.quantity === 0)
                                    "
                                    :class="{
                                      'is-invalid':
                                        isSubmitted &&
                                        $v.wasteWarrants.$each[index]
                                          .warrant_comments.$error,
                                    }"
                                  />
                                  <div
                                    v-if="
                                      isSubmitted &&
                                      $v.wasteWarrants.$each[index]
                                        .warrant_comments.$error
                                    "
                                    class="invalid-feedback"
                                  >
                                    <span
                                      v-if="
                                        !$v.wasteWarrants.$each[index]
                                          .warrant_comments.maxLength
                                      "
                                      >Max length of 100 characters
                                      exceeded</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-1 col-sm-4 col-6"
                                v-if="!war.id"
                              >
                                <div class="form-group fc">
                                  <label
                                    for="exampleInputEmail1"
                                    style="visibility: hidden"
                                    >Delete</label
                                  >
                                  <div
                                    v-on:click="deleteWarrant(index)"
                                    :style="{ cursor: 'pointer' }"
                                  >
                                    <i class="icofont-trash text-danger"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div
                        class="row"
                        v-if="
                          selectedPriceType !== null &&
                          loggedInUserGroupId === 'Admin'
                        "
                      >
                        <div class="d-flex flex-row">
                          <div class="col-md-8 col-sm-6">
                            <p class="mx-3">
                              Do you want to consider above warrants for
                              Disbursement?
                            </p>
                          </div>
                          <div class="col-md-8 col-sm-6">
                            <div class="row">
                              <div class="form-check mx-3">
                                <input
                                  class="form-check-input"
                                  id="disburseWarrantsYes"
                                  type="radio"
                                  v-model="disburseWarrants"
                                  :value="true"
                                />
                                <label
                                  class="form-check-label"
                                  for="disburseWarrantsYes"
                                >
                                  Yes
                                </label>
                              </div>

                              <div class="form-check mx-3">
                                <input
                                  class="form-check-input"
                                  id="disburseWarrantsNo"
                                  type="radio"
                                  v-model="disburseWarrants"
                                  :value="false"
                                />
                                <label
                                  class="form-check-label"
                                  for="disburseWarrantsNo"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->

                      <div
                        class="row"
                        style="float: right; padding: 0px 10px 10px 0px"
                        v-if="
                          wasteExists &&
                          (loggedInUserGroupId === 'Admin' ||
                            loggedInUserGroupId === 'Collateral Manager')
                        "
                      >
                        <ul id="acc_info">
                          <li style="padding-right: 10px">
                            <button
                              type="button"
                              class="btn-sm btn btn-success sh shnone"
                              @click="
                                showCombinedQualityParameter('CombinedQP')
                              "
                            >
                              Combined Quality Parameters
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="btn-sm btn btn-success sh shnone"
                              @click="showCombinedQualityParameter('AllQP')"
                            >
                              Update All Quality Parameters
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Main content -->
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li
                  v-if="
                    loggedInUserGroupId === 'Admin' ||
                    loggedInUserGroupId === 'Collateral Manager'
                  "
                >
                  <button
                    type="button"
                    id="submit"
                    class="btn-sm btn btn-success sh"
                    v-on:click="onSubmit()"
                  >
                    Save
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    style="cursor: pointer"
                    @click="
                      $router.push({
                        name: 'VerificationSummary',
                        params: { id: verification_id },
                      })
                    "
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <ul id="sr_btn">
                <li>
                  <button
                    id="goToSummary"
                    type="button"
                    class="btn-sm btn btn-secondary sh"
                    style="cursor: pointer"
                    @click="
                      $router.push({
                        name: 'VerificationSummary',
                        params: { id: verification_id },
                      })
                    "
                  >
                    Go To Summary
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->
    <!-- ./modal start -->
    <div
      class="modal fade"
      id="single_qp"
      :data-backdrop="overviewFlag ? 'static' : 'true'"
      :data-keyboard="overviewFlag ? 'true' : 'false'"
    >
      <div class="modal-dialog modal-md-8">
        <div class="modal-content" style="width: 800px">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Quality Parameters - {{ currentRefId }}</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              @click="overviewFlag ? $router.go(-1) : null"
            >
              <i class="icofont-close-circled"></i>
            </button>
          </div>

          <!-- Modal body -->
          <div class="modal-body" v-if="currentWarrantId !== null">
            <div class="row">
              <div
                class="col-md-2 col-sm-5 col-6"
                v-for="qp in wasteWarrants[currentWarrantId].quality_parameters"
                :key="qp.id"
              >
                <div class="form-group fc">
                  <label for="exampleInputEmail1">{{
                    qp.quality_parameter
                  }}</label>
                  <select
                    v-if="qp.quality_parameter === 'Period'"
                    class="form-control"
                    v-model="qp.value"
                    :disabled="
                      loggedInUserGroupId === 'Analyst' ||
                      loggedInUserGroupId === 'Borrower' ||
                      overviewFlag
                    "
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="period in periods"
                      :value="period.id"
                      :key="period.id"
                    >
                      {{ period.period_name }}
                    </option>
                  </select>
                  <input
                    v-else
                    type="number"
                    v-model="qp.value"
                    class="form-control"
                    @keypress="isDecimal($event)"
                    :disabled="
                      loggedInUserGroupId === 'Analyst' ||
                      loggedInUserGroupId === 'Borrower' ||
                      overviewFlag
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-header">
            <h4 class="modal-title">Documents</h4>
          </div>
          <div class="modal-body" v-if="currentWarrantId !== null">
            <div class="row">
              <div
                class="col-1"
                v-if="wasteWarrants[currentWarrantId].document_path"
              >
                <label for="exampleInputEmail1">Download</label><br />
                <span>
                  <i
                    v-on:click="
                      downloadDocument(
                        wasteWarrants[currentWarrantId].document_path
                      )
                    "
                    :style="{ cursor: 'pointer' }"
                    id="buttons"
                    class="icofont-download text-info"
                  ></i>
                </span>
              </div>
              <div class="col-md-5 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">Upload Document</label>
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="customFile"
                      name="filename"
                      ref="file"
                      accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                      @change="
                        handleFileUploadQualityParams($event, currentWarrantId)
                      "
                      :disabled="
                        loggedInUserGroupId === 'Analyst' ||
                        loggedInUserGroupId === 'Borrower' ||
                        overviewFlag
                      "
                      :class="{
                        'is-invalid': wasteWarrants[currentWarrantId].errors,
                      }"
                    />
                    <small>
                      <p style="background-color: powderblue">
                        <b>{{
                          wasteWarrants[currentWarrantId].display_file_name
                        }}</b>
                      </p>
                    </small>
                    <div
                      v-if="wasteWarrants[currentWarrantId].errors"
                      class="invalid-feedback"
                    >
                      <span>{{ wasteWarrants[currentWarrantId].errors }}</span>
                    </div>
                    <label class="custom-file-label" for="customFile"
                      >Choose file</label
                    >
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">Issue Date</label>
                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    name="issueDate"
                    id="issueDate"
                    placeholder="Select Date"
                    v-model="wasteWarrants[currentWarrantId].issueDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :disabled="
                      loggedInUserGroupId === 'Analyst' ||
                      loggedInUserGroupId === 'Borrower' ||
                      overviewFlag
                    "
                  ></datepicker>
                </div>
              </div>

              <div class="col-md-3 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">Comments</label>
                  <input
                    type="text"
                    id="issueDate"
                    v-model="wasteWarrants[currentWarrantId].comments"
                    class="form-control"
                    :disabled="
                      loggedInUserGroupId === 'Analyst' ||
                      loggedInUserGroupId === 'Borrower' ||
                      overviewFlag
                    "
                    :class="{
                      'is-invalid':
                        wasteWarrants[currentWarrantId].comments !== null &&
                        wasteWarrants[currentWarrantId].comments.length > 100,
                    }"
                  />
                  <div
                    v-if="
                      wasteWarrants[currentWarrantId].comments !== null &&
                      wasteWarrants[currentWarrantId].comments.length > 100
                    "
                    class="invalid-feedback"
                  >
                    <span>Max length of 100 characters exceeded</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer" v-if="!overviewFlag">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    data-dismiss="modal"
                  >
                    Save
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
    <!-- ./modal start -->
    <div class="modal fade" id="combined_qp">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content" style="width: 800px">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              {{ combinedType === "CombinedQP" ? "Combined" : "Update All" }}
              Quality Parameters
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div
                class="col-md-2 col-sm-5 col-6"
                v-for="qp in combinedQualityParameters.quality_parameters[0]"
                :key="qp.id"
              >
                <div class="form-group fc">
                  <label for="exampleInputEmail1">{{
                    qp.quality_parameter
                  }}</label>
                  <select
                    v-if="qp.quality_parameter === 'Period'"
                    class="form-control"
                    v-model="qp.value"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="period in periods"
                      :value="period.id"
                      :key="period.id"
                    >
                      {{ period.period_name }}
                    </option>
                  </select>
                  <input
                    v-else
                    type="number"
                    v-model="qp.value"
                    class="form-control"
                    @keypress="isDecimal($event)"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <ul id="sr_btn">
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-success sh"
                    data-dismiss="modal"
                    @click="onCombinedAllQPSubmit()"
                  >
                    Submit
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-sm btn btn-dark sh"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./modal end -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {
  maxLength,
  decimal,
  numeric,
  required,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      wasteId: null,
      verification_id: null,
      currentRefId: null,
      currentWarrantId: null,
      warehouses: [],
      priceTypes: [],
      wasteWarrants: [],
      periods: [],
      combinedQualityParameters: {
        quality_parameters: [],
      },
      qualityParameters: [],
      facilityRegistrationId: null,
      facilityId: null,
      collateralId: null,
      verificationQuantity: null,
      verificationBags: null,
      totalQuantity: 0,
      totalBags: null,
      selectedWarehouse: null,
      warehouseId: null,
      warehouseAddress: null,
      selectedPriceType: null,
      price: null,
      value: null,
      transferType: null,
      processType: null,
      quantity: null,
      disburseWarrants: null,
      qualityParamsFile: null,
      combinedQualityParamsFile: null,
      combinedType: null,
      isSubmitted: false,
      wasteExists: false,
      overviewFlag: false,
    };
  },
  validations: {
    // price: {
    //   decimal,
    //   maxLength: maxLength(10),
    // },
    selectedWarehouse: {
      required,
    },
    wasteWarrants: {
      $each: {
        reference_id: {
          required: function (value) {
            if (value === null || value == 0) return false;
            else return true;
          },
          maxLength: maxLength(50),
        },
        net_weight: {
          required,
          decimal,
          maxLength: maxLength(10),
        },
        gross_weight: {
          required,
          decimal,
          maxLength: maxLength(10),
        },
        bags: {
          required,
          numeric,
          maxLength: maxLength(10),
        },
        quantity_static: {
          required,
          decimal,
          maxLength: maxLength(10),
        },
        grn: {
          required,
          numeric,
          maxLength: maxLength(10),
        },
        warrant_comments: {
          maxLength: maxLength(100),
        },
      },
    },
  },
  computed: {
    loggedInUserFullName() {
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
  components: {
    Datepicker,
  },
  methods: {
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },
    // remove - sign because decimal validator also accepts negative inputs
    removeMinus(e) {
      let invalidChars = ["-"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true; // If not match, don't add to input text
    },
    // remove / because it effects API
    removeSlash(e) {
      let invalidChars = ["/", "?"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true; // If not match, don't add to input text
    },

    // opens single quality parameter modal
    showQualityParameter(refId, id) {
      this.currentRefId = refId;
      this.currentWarrantId = id;
      $("#single_qp").modal("show");
    }, //showQualityParameter() close

    // opens combined quality parameter modal
    showCombinedQualityParameter(type) {
      this.combinedType = type;
      $("#combined_qp").modal("show");
    },

    downloadDocument: function (filePath) {
      if (filePath === null) {
        Utils.make_alert("warning", "No Document Available");
        return;
      }
      Utils.downloadDocument(filePath);
    },

    // flip warrant icon vertically on click
    toggleIcon(index, id) {
      // set all warrant icons to down except currently selected warrant
      this.wasteWarrants.map((war) => {
        if (war.warrant_id !== id) war.icon = "icofont-caret-down icofont-2x";
      });
      // set oppostite warrant icon with respect to current one
      if (this.wasteWarrants[index].icon === "icofont-caret-down icofont-2x")
        this.wasteWarrants[index].icon = "icofont-caret-up icofont-2x";
      else this.wasteWarrants[index].icon = "icofont-caret-down icofont-2x";
    },

    // calculates value by multiplying total quantity with price
    calculateValue() {
      this.value = 0;
      if (this.totalQuantity) {
        this.value = this.totalQuantity * this.price;
      }
      this.value = Number(this.value) == 0 ? null : this.value;
    },

    // calculates total quantity by taking sum of warrant quantities
    calculateTotalQuantity() {
      this.totalQuantity = 0;
      this.wasteWarrants.map((war) => {
        this.totalQuantity += Number(war.quantity);
      });
      this.totalQuantity = Number(this.totalQuantity).toFixed(8);
      // this.calculateValue();
    },

    // calculates total quantity by taking sum of warrant bags
    calculateTotalBags() {
      this.totalBags = 0;
      this.wasteWarrants.map((war) => {
        this.totalBags += Number(war.bags);
      });
    },

    // checks for duplicate warrant reference id
    checkDuplicateRefernceID(refId, i) {
      const collateralStageMasterId = 4;
      let exists = false;
      this.wasteWarrants.map((war, index) => {
        if (war.reference_id === refId && index !== i) exists = true;
      });
      if (exists) this.wasteWarrants[i].refIDexists = true;
      else this.wasteWarrants[i].refIDexists = false;

      if (refId) {
        const path =
          "/warrant-reference-id-check/" +
          collateralStageMasterId;
        axios
          .get(path, { params: { clientId: this.$store.state.client_id , refId: refId } })
          .then((res) => {
            let reference_ids = res.data.reference_ids;
            if (reference_ids.length) {
              this.wasteWarrants[i].refIDexists = true;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    // deletes a specific warrant
    deleteWarrant(index) {
      this.currentRefId = null;
      this.currentWarrantId = null;
      this.wasteWarrants.splice(index, 1);
      this.qualityParamsFile = "";
      this.calculateTotalBags();
      this.calculateTotalQuantity();
    },

    // this method will submit the data for Combined or Update All Quality Parameter Modal
    onCombinedAllQPSubmit() {
      this.$confirm({
        auth: false,
        message:
          this.combinedType === "AllQP"
            ? "Caution: Quality Parameters of All Warrants will be updated!"
            : "Confirm Submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            const path = "edit-waste/" + this.verification_id;
            axios
              .put(
                path,
                {
                  updateType: this.combinedType,
                  combinedQP: this.combinedQualityParameters,
                  wasteID: this.wasteId,
                  userId: this.$store.getters.getuserId,
                  moduleId: 7,
                  tabId: 31,
                  clientId: this.$store.state.client_id,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                if (res.data.status == "success") {
                  if (this.combinedType === "CombinedQP")
                    Utils.make_alert(
                      "success",
                      "Combined Quality Parameters applied successfully!"
                    );
                  else
                    Utils.make_alert(
                      "success",
                      "All Quality Parameters applied successfully!"
                    );
                  setTimeout(() => this.$router.go(), 1500);
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
              });
          }
        },
      });
    },

    //this method will submit the Cleaned Data.
    onSubmit() {
      this.$confirm({
        auth: false,
        message: "Confirm Submit?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            this.isSubmitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
              // if any validation doesn't pass open that warrant card
              if (this.$v.wasteWarrants.$each.$iter) {
                let warrants = Object.values(this.$v.wasteWarrants.$each.$iter);
                warrants.map((war, index) => {
                  if (war.$anyError)
                    document
                      .getElementsByClassName("warrant_button")
                      [index].click();
                });
              }
              return;
            }
            var reference_id_exists = false;
            // format warrant date and warrant qp document issue date if its not null
            this.wasteWarrants.map((war) => {
              if (war.date !== null) {
                war.date = moment(war.date).format("MM-DD-YYYY");
              }
              if (war.issueDate !== null) {
                war.issueDate = moment(war.issueDate).format("MM-DD-YYYY");
              }
              if (war.refIDexists) {
                reference_id_exists = true;
              }
            });
            // return if there is any refIDexists true
            if (reference_id_exists) {
              return;
            }
            //initialize the form data
            let formData = new FormData();
            if (this.wasteExists) {
              formData.append("wasteID", this.wasteId);
            }
            formData.append("facilityID", this.facilityId);
            formData.append("verificationID", this.verification_id);
            formData.append("totalQuantity", this.totalQuantity);
            formData.append("totalBags", this.totalBags);
            formData.append("selectedPriceType", this.selectedPriceType);
            formData.append("price", this.price);
            formData.append("value", this.value);
            formData.append("selectedWarehouse", this.selectedWarehouse);
            formData.append(
              "wasteWarrants",
              JSON.stringify(this.wasteWarrants)
            );
            formData.append(
              "clientId",
              JSON.stringify(this.$store.state.client_id)
            );
            formData.append("userId", this.$store.getters.getuserId);
            formData.append("moduleId", 7);
            formData.append("tabId", 31);
            const path = this.wasteExists
              ? "edit-waste/" + this.verification_id
              : "add-waste";
            Utils.start_loading();
            axios
              .post(path, formData, {
                headers: {
                  enctype: "multipart/form-data",
                },
              })
              .then((response) => {
                console.log(response);
                Utils.stop_loading();
                console.log("status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Waste details saved successfully!"
                  );
                  setTimeout(() => this.$router.go(), 1500);
                  console.log("SUCCESS!!");
                } else {
                  Utils.make_alert("warning", "Something went wrong!");
                }
              })
              .catch((error) => {
                console.log(error);
                Utils.stop_loading();
              });
          }
        },
      });
    }, //onSubmit() close

    //this method will add new line for additional Waste Warrants
    addWasteWarrant() {
      this.qualityParamsFile = null;
      this.wasteWarrants.push({
        id: null,
        icon: "icofont-caret-down icofont-2x",
        warrant_id: this.wasteWarrants.length + 1,
        reference_id: null,
        refIDexists: false,
        net_weight: null,
        gross_weight: null,
        bags: null,
        quantity: null,
        grn: null,
        date: null,
        quantity_static: null,
        warrant_comments: null,
        issueDate: new Date(),
        comments: null,
        document_id: null,
        document_path: null,
        display_file_name: null,
        selectedFile: null,
        selectedFileName: null,
        errors: "",
        quality_parameters: JSON.parse(JSON.stringify(this.qualityParameters)),
      });
    }, //addWasteWarrant() close

    //handles a change on the file upload
    handleFileUploadQualityParams(e, currentWarrantId) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        Utils.make_alert("warning", "Invalid File Type");
      } else {
        let self = this;
        let reader = new FileReader();
        this.qualityParamsFile = e.target.files[0];
        //check for filesize. if it is larger than threshold (100 MB) then return following error response
        if (this.qualityParamsFile.size > 104857600) {
          e.preventDefault();
          var error = "File size should be less than 100MB";
          self.wasteWarrants[currentWarrantId].errors = error;
          self.wasteWarrants[currentWarrantId].selectedFile = null;
          self.wasteWarrants[currentWarrantId].selectedFileName = null;
          self.wasteWarrants[currentWarrantId].display_file_name = null;
          self.$set(
            self.wasteWarrants,
            currentWarrantId,
            self.wasteWarrants[currentWarrantId]
          );
          return;
        }
        //save file details
        reader.readAsDataURL(this.qualityParamsFile);
        reader.onload = function () {
          self.wasteWarrants[currentWarrantId].selectedFile = reader.result;
          self.wasteWarrants[currentWarrantId].selectedFileName =
            e.target.files[0].name;
          self.wasteWarrants[currentWarrantId].errors = "";
          self.wasteWarrants[currentWarrantId].display_file_name =
            e.target.files[0].name;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
        self.$set(
          self.wasteWarrants,
          currentWarrantId,
          self.wasteWarrants[currentWarrantId]
        );
      }
    }, //handleFileUploadQualityParams()

    // this function is called to get request details with verification id
    getRequest() {
      const path = "single-request-summary-overview/" + this.verification_id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            let verification_request_details =
              res.data.verification_request_details.request_details[0];
            this.borrowerId = verification_request_details.borrower_id;
            this.getWarehouseByBorrower(this.borrowerId);
            this.selectedWarehouse =
              verification_request_details.warehouse_master_id;
            this.warehouseId =
              verification_request_details.warehouse_registration_id;
            this.warehouseAddress = verification_request_details.address;
            this.facilityId = verification_request_details.facility_id;
            this.facilityRegistrationId =
              verification_request_details.facility_registration_id;
            this.collateralId =
              verification_request_details.collateral_master_id;
            this.getQualityParamters(this.collateralId);
            this.getWaste();
            this.verificationQuantity =
              verification_request_details.verification_quantity;
            this.verificationBags =
              verification_request_details.verification_bags;
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getRequest close

    // this function is called to get waste details with verification id
    getWaste() {
      const path = "waste-overview/" + this.verification_id;
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          if (res.data.status == "success") {
            let waste_details = res.data.waste_overview_list[0];
            if (waste_details) {
              this.wasteExists = true;
              this.wasteId = waste_details.id;
              this.totalQuantity = waste_details.total_quantity;
              this.totalBags = waste_details.total_bags;
              this.selectedPriceType = waste_details.price_type;
              this.price = waste_details.price;
              this.value = waste_details.value;
            }
            let waste_warrant_details = res.data.waste_warrants_list.length;
            if (waste_warrant_details) {
              this.wasteWarrants = res.data.waste_warrants_list;
              this.wasteWarrants.map((war, index) => {
                war["warrant_id"] = index + 1;
                war["refIDexists"] = false;
                war["issueDate"] = new Date();
                war["errors"] = "";
              });
              this.calculateTotalQuantity();
              this.calculateTotalBags();
              this.getQualityParamters(this.collateralId, "waste");
              // open QP modal if warrant reference id is received from params
              let ref_id = this.$route.params.ref_id;
              if (ref_id) {
                let i = 0;
                this.wasteWarrants.map((wt, index) => {
                  if (wt.reference_id === ref_id) {
                    i = index;
                  }
                });
                this.showQualityParameter(ref_id, i);
              }
            }
          } else if (res.data.status == "unauthorised") {
            this.$router.push("/collateral");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getWaste close

    //this function is called to get price by price type and collateral id
    getPrice() {
      if (this.selectedPriceType !== 5) {
        const path =
          "/get-price/" + this.selectedPriceType + "/" + this.collateralId;
        axios
          .get(path, { params: { clientId: this.$store.state.client_id } })
          .then((res) => {
            this.price = res.data.price;
            // this.calculateValue();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    //this function is called to get list of warehouses by borrower id
    getWarehouseByBorrower(id) {
      const path = "/warehouse-borrower/" + id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.warehouses = res.data.warehouses;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of price types
    getPriceTypes() {
      const path = "price-type-master";
      axios
        .get(path)
        .then((res) => {
          this.priceTypes = res.data.price_types;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of collateral quality parameters by collateral id
    getQualityParamters(collateral_id, type) {
      const path = "/collateral-quality-parameter-master/" + collateral_id;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.qualityParameters = res.data.collateral_qp;
          if (type === "waste") {
            this.wasteWarrants.map((war) => {
              if (war.quality_parameters.length == 0) {
                war.quality_parameters = JSON.parse(
                  JSON.stringify(this.qualityParameters)
                );
              }
            });
          } else {
            this.combinedQualityParameters.quality_parameters.push(
              JSON.parse(JSON.stringify(this.qualityParameters))
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this function is called to get list of Period
    getPeriod() {
      const path = "period-master";
      axios
        .get(path)
        .then((res) => {
          this.periods = res.data.periods;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  created() {
    this.verification_id = this.$route.params.id;
    this.overviewFlag = this.$route.params.overview_flag
      ? this.$route.params.overview_flag
      : false;
    this.getPriceTypes();
    this.getPeriod();
    this.getRequest();
  },
};
</script>

<style scoped>
.accordion-title.collapsed:before {
  float: left !important;
  content: "\f0d8" !important;
}
ul#acc_info {
  min-width: 100%;
  list-style: outside none none;
  text-align: left !important;
  margin: 0px;
  padding: 0px;
}

ul#acc_info li {
  display: inline-block;
  width: auto;
  vertical-align: top;
}

ul#acc_info li:nth-child(3) {
  float: right;
}

ul#acc_info li .form-group {
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 500px) {
  ul#acc_info {
    text-align: center !important;
  }
  ul#acc_info li {
    margin-bottom: 10px;
  }
}
.input-group-text::after {
  content: "*";
  color: red;
}
</style>
