<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="container-fluid tabtop" id="al_tb">
        <div class="tp_mid">
          <div class="row">
            <div class="col-lg-12">
              <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                <li
                  class="nav-item"
                  v-if="
                    (loggedInUserGroupId == 'Offtaker' &&
                    loggedInUserSubGroupId !== 0)
                  "
                >
                  <a
                    class="nav-link active"
                    id="custom-tabs-one-offtaker-tab"
                    data-toggle="pill"
                    href="#one"
                    role="tab"
                    aria-controls="custom-tabs-one-offtaker"
                    aria-selected="true"
                    @click="setTab('custom-tabs-one-offtaker-tab')"
                    >Profile</a
                  >
                </li>
                <li class="nav-item" v-else-if=" loggedInUserGroupId == 'Admin' ||
                    (loggedInUserGroupId == 'Analyst' &&
                      loggedInUserSubGroupId == 0)">
                  <a
                    class="nav-link active"
                    id="custom-tabs-one-offtaker-tab"
                    data-toggle="pill"
                    href="#one"
                    role="tab"
                    aria-controls="custom-tabs-one-offtaker"
                    aria-selected="true"
                    @click="setTab('custom-tabs-one-offtaker-tab')"
                    >Offtaker</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    loggedInUserGroupId == 'Admin' ||
                    (loggedInUserGroupId == 'Analyst' &&
                      loggedInUserSubGroupId == 0) ||
                    (loggedInUserGroupId == 'Borrower') ||
                    (loggedInUserGroupId == 'Offtaker' &&
                    loggedInUserSubGroupId !== 0)
                  "
                >
                  <a
                    class="nav-link"
                    id="custom-tabs-one-contract-tab"
                    data-toggle="pill"
                    href="#two"
                    role="tab"
                    aria-controls="custom-tabs-one-contract"
                    aria-selected="false"
                    @click="setTab('custom-tabs-one-contract-tab')"
                    >Contract Tracker</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    loggedInUserGroupId == 'Admin' ||
                    (loggedInUserGroupId == 'Analyst' &&
                      loggedInUserSubGroupId == 0) ||
                      (loggedInUserGroupId == 'Borrower') ||
                    (loggedInUserGroupId == 'Offtaker' &&
                    loggedInUserSubGroupId !== 0)
                  "
                >
                  <a
                    class="nav-link"
                    id="custom-tabs-one-repayment-tab"
                    data-toggle="pill"
                    href="#three"
                    role="tab"
                    aria-controls="custom-tabs-one-repayment"
                    aria-selected="false"
                    @click="setTab('custom-tabs-one-repayment-tab')"
                    >Repayment</a
                  >
                </li>
              </ul>
            </div>
            <!-- /.col -->
          </div>
        </div>
      </div>

      <div class="tab-content" id="custom-tabs-one-tabContent">
        <div
          class="tab-pane fade show active"
          id="one"
          role="tabpanel"
          aria-labelledby="offtaker"
        >
          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div
                        class="col-lg-6 col-sm-6"
                        v-if="loggedInUserGroupId == 'Offtaker'"
                      >
                        <h1 class="headingt">
                          <i class="icofont-search-1"></i> Profile
                        </h1>
                      </div>
                      <div class="col-lg-6 col-sm-6" v-else>
                        <h1 class="headingt">
                          <i class="icofont-search-1"></i> Offtaker
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div
                        class="col-lg-6 col-sm-6"
                        v-if="
                          loggedInUserGroupId == 'Admin' ||
                          (loggedInUserGroupId == 'Analyst' &&
                            loggedInUserSubGroupId == 0)
                        "
                      >
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="$router.push('/add-offtaker')"
                            >
                              <i class="icofont-plus"></i>
                              <span class="mob">Add Offtaker</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div
                        class="col-lg-6 col-sm-6"
                        v-if="loggedInUserGroupId == 'Admin'"
                      >
                        <input
                          type="radio"
                          :value="false"
                          @change="destroyTable"
                          v-model="inActiveToggle"
                          id="active"
                        />
                        <label for="active" style="padding-right: 20px"
                          >Active</label
                        >
                        <input
                          type="radio"
                          :value="true"
                          @change="destroyTable"
                          v-model="inActiveToggle"
                          id="inactive"
                        />
                        <label for="inactive">InActive</label>
                      </div>
                    </div>
                  </div>

                  <div
                    class="card-body table-responsive p-0"
                    id="datatable-padding"
                  >
                    <!------menton here style="height: 300px;"-------->
                    <table
                      id="myTable1"
                      class="table table-head-fixed text-nowrap table-hover myTable1"
                    >
                      <thead>
                        <tr>
                          <th>Offtaker Name</th>
                          <th class="c">Registration Date</th>
                          <th class="c">Country</th>
                          <th class="c">Registration Status</th>
                          <th
                            class="c"
                            v-if="
                              !inActiveToggle &&
                              loggedInUserGroupId == 'Admin' &&
                              loggedInUserSubGroupId == 0
                            "
                          >
                            Change Status
                          </th>
                          <template v-if="!inActiveToggle">
                            <th
                              class="c"
                              v-if="
                                loggedInUserGroupId == 'Offtaker' ||
                                loggedInUserGroupId == 'Analyst' ||
                                (loggedInUserGroupId == 'Admin' &&
                                  loggedInUserSubGroupId == 0)
                              "
                            ></th>
                            <th
                              class="c"
                              v-if="
                                (loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0))
                              "
                            ></th>
                          </template>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(offtaker, index) in offtakers"
                          :key="offtaker.offtaker_master_id"
                        >
                          <template
                            v-if="
                              loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0)
                            "
                          >
                            <td
                              class="ln"
                              style="white-space: pre-wrap; max-width: 250px"
                            >
                              <router-link
                                :to="{
                                  name: 'OfftakerOverview',
                                  params: {
                                    id: offtaker.offtaker_master_id,
                                    inactive: inActiveToggle,
                                  },
                                }"
                                >{{ offtaker.offtaker_name }}</router-link
                              >
                            </td>
                            <td v-if="offtaker.registration_date !== null">
                              {{ offtaker.registration_date }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="offtaker.country !== null">
                              {{ offtaker.country }}
                            </td>
                            <td v-else>-</td>
                            <td
                              v-if="offtaker.status !== null"
                              :class="{
                                'c text-success':
                                  offtaker.status === 'Approved',
                                'c text-warning': offtaker.status === 'Pending',
                                'c text-danger': offtaker.status === 'Rejected',
                              }"
                            >
                              {{ offtaker.status }}
                            </td>
                            <td v-else>-</td>
                            <template
                              v-if="
                                !inActiveToggle &&
                                loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0
                              "
                            >
                              <td class="c">
                                <div class="status">
                                  <select
                                    id="regStatus"
                                    name="regStatus"
                                    @change="
                                      onChangeStatus(
                                        $event,
                                        offtaker.offtaker_master_id,
                                        offtaker.status,
                                        index
                                      )
                                    "
                                    class="form-control"
                                    :value="offtaker.status"
                                    :disabled="offtaker.status !== 'Pending'"
                                  >
                                    <option value="Pending">Pending</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                  </select>
                                </div>
                              </td>
                            </template>

                            <template v-if="!inActiveToggle">
                              <td
                                class="c"
                                v-if="
                                  loggedInUserGroupId == 'Analyst' ||
                                  loggedInUserGroupId == 'Admin'
                                "
                              >
                                <router-link
                                  style="text-decoration: none"
                                  :to="{
                                    name: 'EditOfftaker',
                                    params: {
                                      id: offtaker.offtaker_master_id,
                                      inactive: inActiveToggle,
                                    },
                                  }"
                                >
                                  <i class="icofont-ui-edit text-warning"></i>
                                </router-link>
                              </td>
                            </template>
                            <template v-if="
                                (loggedInUserGroupId == 'Admin' ||
                                (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0))
                              ">
                              <td
                                class="c"
                                v-if="
                                  (loggedInUserGroupId == 'Admin' ||
                                  (loggedInUserGroupId == 'Analyst' &&
                                  loggedInUserSubGroupId == 0)) &&
                                  !offtaker.loan_linked_flag &&
                                  offtaker.status !== 'Approved'
                                "
                                v-on:click="
                                  deleteOfftaker(
                                    offtaker.offtaker_master_id,
                                    offtaker.status
                                  )
                                "
                                :style="{ cursor: 'pointer' }"
                              >
                                <i class="icofont-trash text-danger"></i>
                              </td>
                              <td v-else style="opacity: 0.5">
                                <i class="icofont-trash text-danger"></i>
                              </td>
                            </template>
                          </template>
                          <template v-if="loggedInUserGroupId == 'Offtaker'">
                            <td class="ln">
                              <router-link
                                :to="{
                                  name: 'OfftakerOverview',
                                  params: {
                                    id: offtaker.offtaker_master_id,
                                    inactive: inActiveToggle,
                                  },
                                }"
                                >{{ offtaker.offtaker_name }}</router-link
                              >
                            </td>
                            <td v-if="offtaker.registration_date !== null">
                              {{ offtaker.registration_date }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="offtaker.country !== null">
                              {{ offtaker.country }}
                            </td>
                            <td v-else>-</td>
                            <td
                              v-if="offtaker.status !== null"
                              :class="{
                                'c text-success':
                                  offtaker.status === 'Approved',
                                'c text-warning': offtaker.status === 'Pending',
                                'c text-danger': offtaker.status === 'Rejected',
                              }"
                            >
                              {{ offtaker.status }}
                            </td>
                            <td v-else>-</td>
                            <template
                              v-if="
                                !inActiveToggle &&
                                loggedInUserGroupId == 'Admin' &&
                                loggedInUserSubGroupId == 0
                              "
                            >
                              <td class="c">
                                <div class="status">
                                  <select
                                    id="regStatus"
                                    name="regStatus"
                                    @change="
                                      onChangeStatus(
                                        $event,
                                        offtaker.offtaker_master_id,
                                        offtaker.status,
                                        index
                                      )
                                    "
                                    class="form-control"
                                    :value="offtaker.status"
                                    :disabled="offtaker.status !== 'Pending'"
                                  >
                                    <option value="Pending">Pending</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                  </select>
                                </div>
                              </td>
                            </template>
                            <template v-if="!inActiveToggle">
                              <td
                                class="c"
                                v-if="
                                  (loggedInUserGroupId == 'Admin' ||
                                      (loggedInUserGroupId == 'Analyst' &&
                                      loggedInUserSubGroupId == 0))
                                "
                              >
                                <router-link
                                  style="text-decoration: none"
                                  :to="{
                                    name: 'EditOfftaker',
                                    params: {
                                      id: offtaker.offtaker_master_id,
                                      inactive: inActiveToggle,
                                    },
                                  }"
                                >
                                  <i class="icofont-ui-edit text-warning"></i>
                                </router-link>
                              </td>
                            </template>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>
        <!-- /.Tabpane first end-->

        <div
          class="tab-pane fade"
          id="two"
          role="tabpanel"
          aria-labelledby="contract"
        >
          <div class="container-fluid">
            <div class="row">
              <div v-if="loggedInUserGroupId != 'Borrower'" class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="selectedBorrower" class="required-field">Borrower Name</label>
                  <select
                    id="selectedBorrower"
                    class="form-control"
                    name="selectedBorrower"
                    v-model="selectedBorrower"
                    @change="onBorrowerChange();getOfftakerNameByBorrower()"
                    >
                    <!-- @change="onBorrowerChange();getFacilitySetupByBorrower();getOfftakerNameByBorrower()" -->
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="bor in borrowers"
                      :value="bor.borrower_id"
                      :key="bor.borrower_id"
                    >
                      {{ bor.borrower_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="selectedOfftaker">Offtaker Name</label>
                  <select
                    id="selectedOfftaker"
                    class="form-control"
                    name="selectedOfftaker"
                    v-model="selectedOfftaker"
                    :disabled="loggedInUserGroupId == 'Offtaker'"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="off in offtakers_list"
                      :value="off.id"
                      :key="off.id"
                    >
                      {{ off.offtaker_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="startDate">From Date</label>

                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    id="startDate"
                    placeholder="Select Date"
                    v-model="startDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fas fa-calendar"
                    :calendar-button="true"
                  />
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="endDate">To Date</label>

                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    id="endDate"
                    placeholder="Select Date"
                    v-model="endDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :disabledDates="{ to: new Date(startDate) }"
                  ></datepicker>
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label>Status</label>
                  <select
                    id="selectedStatus"
                    class="form-control"
                    name="selectedStatus"
                    v-model="selectedStatus"
                    required
                  >
                    <option :value="null">--- Select ---</option>
                    <option value="Not Utilized">Not Utilized</option>
                    <option value="Partially Utilized">
                      Partially Utilized
                    </option>
                    <option value="Fully Utilized">Fully Utilized</option>
                  </select>
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-primary sh_n mt"
                      @click="filterResetContracts(true)"
                    >
                      Filter
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh_n mt"
                      @click="filterResetContracts()"
                    >
                      Reset
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6 col-sm-6">
                        <h1 class="headingt">
                          <!-- going in v-else -->
                          Contract Tracker
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div
                        class="col-lg-6 col-sm-6"
                        v-if="
                          loggedInUserGroupId == 'Admin' ||
                          (loggedInUserGroupId == 'Analyst' &&
                            loggedInUserSubGroupId == 0)
                        "
                      >
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="
                                $router.push({
                                  name: 'AddContract',
                                  params: {
                                          id: 0,
                                          partialFillFlag: 0,
                                        },
                                })
                              "
                            >
                              <i class="icofont-plus"></i>
                              <span class="mob">Add New Contract</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    class="card-body table-responsive p-0"
                    id="datatable-padding"
                  >
                    <!------menton here style="height: 300px;"-------->
                    <table
                      id="myTable2"
                      class="table text-nowrap table-hover myTable2"
                    >
                      <thead>
                        <tr>
                          <th>Offtaker Name</th>
                          <th>Contract No.</th>
                          <th>Contract Date</th>
                          <th>Total Quantity (MT)</th>
                          <th>Delivery Month</th>
                          <th>Contract Value</th>
                          <th>Contract Price Currency</th>
                          <th>Final Price</th>
                          <th>Quantity Fixed</th>
                          <th>Bal to be Fixed</th>
                          <th>Status</th>
                          <template
                            v-if="
                                  loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                    loggedInUserSubGroupId == 0)
                                "
                          >
                          <th>Add Partial Fill</th>
                          <th>Edit</th>
                          </template>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          id="tdrow"
                          v-for="(contract) in contracts" 
                          :key="contract.id">
                          <template
                            v-if="
                              loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0) ||
                              (loggedInUserGroupId == 'Borrower') ||
                              (loggedInUserGroupId == 'Offtaker' &&
                              loggedInUserSubGroupId !== 0)
                            "
                          >
                            <td
                              v-if="contract.offtaker_name !== null"
                              >
                              <!-- class="ln"
                              style="white-space: pre-wrap; max-width: 250px" -->
                              {{ contract.offtaker_name }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="contract.contract_number !== null">
                              <router-link
                                :to="{
                                  name: 'ContractOverview',
                                  params: {
                                    id: contract.id,
                                  },
                                }"
                              >
                                {{ contract.contract_number }}
                              </router-link>
                            </td>
                            <td v-else>-</td>
                            <td v-if="contract.contract_date !== null">
                              {{ contract.contract_date }}
                            </td>
                            <td v-else>-</td>
                            <td class="right" v-if="contract.total_quantity !== null">
                              {{ contract.total_quantity }}
                            </td>
                            <td class="right" v-else>-</td>
                            <td v-if="contract.delivery_month !== null">
                              {{ contract.delivery_month }}
                            </td>
                            <td v-else>-</td>
                            <td class="right" v-if="contract.contract_value !== null">
                              {{ contract.contract_value }}
                            </td>
                            <td class="right" v-else>-</td>
                            <td class="center" v-if="contract.currency_short_name !== null">
                              {{ contract.currency_short_name }}
                            </td>
                            <td class="center" v-else>-</td>
                            <td class="right" v-if="contract.final_price !== null">
                              {{ contract.final_price }}
                            </td>
                            <td class="right" v-else>-</td>
                            <td class="right" v-if="contract.fixed_quantity !== null">
                              {{ contract.fixed_quantity }}
                            </td>
                            <td class="right" v-else>-</td>
                            <td class="right" v-if="contract.outstanding_contract_quantity !== null">
                              {{ contract.outstanding_contract_quantity }}
                            </td>
                            <td class="right" v-else>-</td>
                            <td v-if="contract.status !== null">
                              {{ contract.status }}
                            </td>
                            <td v-else>-</td>
                            <template v-if="(
                                  loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                    loggedInUserSubGroupId == 0))">
                            <td 
                            v-if="contract.outstanding_contract_quantity != '0.0' && contract.repayment_flag != 1 && contract.logistics_shipping_flag == 0"
                            class="center">
                              <router-link
                                style="text-decoration: none"
                                :to="{
                                  name: 'AddContract',
                                  params: { 
                                    id: contract.id,
                                    partialFillFlag: 1,
                                  },
                                }"
                              >
                                <i class="icofont-plus text-success"></i>
                              </router-link>
                            </td>
                            <td class="center" v-else>
                              <i
                                  style="opacity: 0.5; cursor: not-allowed;"
                                  class="icofont-plus text-success"
                              ></i>
                            </td>
                            </template>
                            <template v-if="(
                                  loggedInUserGroupId == 'Analyst' ||
                                  (loggedInUserGroupId == 'Admin' &&
                                    loggedInUserSubGroupId == 0))">
                              <td
                                class="c center"
                                v-if="contract.details === 'Variable'"
                              >
                                <router-link
                                  style="text-decoration: none"
                                  :to="{
                                    name: 'AddContract',
                                    params: {
                                      id: contract.id,
                                      partialFillFlag: 0,
                                    },
                                  }"
                                >
                                  <i
                                    class="icofont-ui-edit text-warning"
                                  ></i>
                                </router-link>
                              </td>
                              <td class="center" title="Details have been fixed and are no longer editable." v-else>
                                <i
                                  style="opacity: 0.5; cursor: not-allowed;"
                                  class="icofont-ui-edit text-warning"
                                ></i>
                              </td>
                            </template>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>
        <!-- /.Tabpane second end-->

        <div
          class="tab-pane fade"
          id="three"
          role="tabpanel"
          aria-labelledby="repayment"
        >
          <div class="container-fluid">
            <div class="row">
              <div v-if="loggedInUserGroupId != 'Borrower'" class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="selectedBorrowerRepayment" class="required-field">Borrower Name</label>
                  <select
                    id="selectedBorrowerRepayment"
                    class="form-control"
                    name="selectedBorrowerRepayment"
                    v-model="selectedBorrowerRepayment"
                    @change="onBorrowerRepaymentChange();getFacilitySetupByRepaymentBorrower();getOfftakerNameByRepaymentBorrower();"
                    >
                    <!-- @change="onBorrowerChange();getFacilitySetupByBorrower();getOfftakerNameByBorrower()" -->
                    <option :value="null" disabled>--- Select ---</option>
                    <option
                      v-for="bor in borrowers"
                      :value="bor.borrower_id"
                      :key="bor.borrower_id"
                    >
                      {{ bor.borrower_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="selectedFacilityRepayment"
                    >Facility ID</label
                  >
                  <select
                    id="selectedFacilityRepayment"
                    class="form-control"
                    v-model="selectedFacilityRepayment"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="fac in facilities_rep"
                      :value="fac.id"
                      :key="fac.id"
                    >
                      {{ fac.facility_registration_id }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="selectedOfftakerRepayment">Offtaker Name</label>
                  <select
                    id="selectedOfftakerRepayment"
                    class="form-control"
                    name="selectedOfftakerRepayment"
                    v-model="selectedOfftakerRepayment"
                    :disabled="loggedInUserGroupId == 'Offtaker'"
                  >
                    <option :value="null">--- Select ---</option>
                    <option
                      v-for="off in offtakers_list_rep"
                      :value="off.id"
                      :key="off.id"
                    >
                      {{ off.offtaker_name }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="startDate">From Date</label>

                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    id="startDate"
                    placeholder="Select Date"
                    v-model="startDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fas fa-calendar"
                    :calendar-button="true"
                  />
                </div>
              </div>

              <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label for="endDate">To Date</label>

                  <datepicker
                    class="datepicker"
                    :bootstrap-styling="true"
                    id="endDate"
                    placeholder="Select Date"
                    v-model="endDate"
                    :format="$store.state.date_format"
                    calendar-button-icon="fa fa-calendar"
                    :calendar-button="true"
                    :disabledDates="{ to: new Date(startDate) }"
                  ></datepicker>
                </div>
              </div> -->

              <!-- <div class="col-md-2 col-sm-5 col-6">
                <div class="form-group fc">
                  <label>Status</label>
                  <select
                    id="selectedStatus"
                    class="form-control"
                    name="selectedStatus"
                    v-model="selectedStatus"
                    required
                  >
                    <option :value="null">--- Select ---</option>
                    <option value="Not Utilized">Not Utilized</option>
                    <option value="Partially Utilized">
                      Partially Utilized
                    </option>
                    <option value="Fully Utilized">Fully Utilized</option>
                  </select>
                </div>
              </div> -->

              <div class="col-md-2 col-sm-5 col-6">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-primary sh_n mt"
                      @click="filterResetRepayments(true)"
                    >
                      Filter
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-dark sh_n mt"
                      @click="filterResetRepayments()"
                    >
                      Reset
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6 col-sm-6">
                        <h1 class="headingt">
                          <!-- going in v-else -->
                          Repayment Tracker
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div
                        class="col-lg-6 col-sm-6"
                        v-if="
                          loggedInUserGroupId == 'Admin' ||
                          (loggedInUserGroupId == 'Analyst' &&
                            loggedInUserSubGroupId == 0)
                        "
                      >
                        <!-- <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-success"
                              @click="
                                $router.push({
                                  name: 'AddContract',
                                  params: {
                                          id: 0,
                                          partialFillFlag: 0,
                                        },
                                })
                              "
                            >
                              <i class="icofont-plus"></i>
                              <span class="mob">Add New Contract</span>
                            </button>
                          </li>
                        </ul> -->
                      </div>
                    </div>
                  </div>

                  <div
                    class="card-body table-responsive p-0"
                    id="datatable-padding"
                  >
                    <!------menton here style="height: 300px;"-------->
                    <table
                      id="repaymentTable"
                      class="table text-nowrap table-hover repaymentTable"
                    >
                      <thead>
                        <tr>
                          <th>Repayment ID</th>
                          <th>Facility ID</th>
                          <th>Offtaker Name</th>
                          <th>Contract No.</th>
                          <th>Partial Contract No.</th>
                          <th>Quantity (MT)</th>
                          <th>Repayment Amount</th>
                          <th>Repayment Currency</th>
                          <th>Conversion Date</th>
                          <th>Conv. Repayment Amt.</th>
                          <th>Fund Currency</th>
                          <!-- <th>Contract Value</th>
                          <th>Final Price</th>
                          <th>Quantity Fixed</th>
                          <th>Bal to be Fixed</th> -->
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          id="tdrow"
                          v-for="(re) in repayments" 
                          :key="re.id">
                          <template
                            v-if="
                              loggedInUserGroupId == 'Admin' ||
                              (loggedInUserGroupId == 'Analyst' &&
                                loggedInUserSubGroupId == 0) ||
                                (loggedInUserGroupId == 'Borrower') ||
                              (loggedInUserGroupId == 'Offtaker' &&
                              loggedInUserSubGroupId !== 0)
                            "
                          >
                            <td v-if="re.id !== null">
                              <router-link
                                :to="{
                                  name: 'RepaymentOverview',
                                  params: {
                                    id: re.id,
                                  },
                                }"
                              >
                                {{ re.id }}
                                <!-- {{ re.repayement_number }} -->
                              </router-link>
                            </td>
                            <td v-else>-</td>
                            <td v-if="re.facility_registration_id !== null">
                              <!-- class="ln"
                              style="white-space: pre-wrap; max-width: 250px" -->
                              {{ re.facility_registration_id }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="re.offtaker_name !== null">
                              <!-- class="ln"
                              style="white-space: pre-wrap; max-width: 250px" -->
                              {{ re.offtaker_name }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="re.contract_number !== null">
                              <!-- class="ln"
                              style="white-space: pre-wrap; max-width: 250px" -->
                              {{ re.contract_number }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="re.partial_contract_number !== null">
                              <!-- class="ln"
                              style="white-space: pre-wrap; max-width: 250px" -->
                              {{ re.partial_contract_number }}
                            </td>
                            <td v-else>-</td>
                            <td class="right" v-if="re.contract_qty !== null">
                              <!-- class="ln"
                              style="white-space: pre-wrap; max-width: 250px" -->
                              {{ re.contract_qty }}
                            </td>
                            <td class="right" v-else>-</td>
                            <td class="right" v-if="re.overall_repayment_amount !== null">
                              {{ re.overall_repayment_amount }}
                            </td>
                            <td class="right" v-else>-</td>
                            <td class="center" v-if="re.repayment_currency_short_name !== null">
                              {{ re.repayment_currency_short_name }}
                            </td>
                            <td class="center" v-else>-</td>
                            <td class="center" v-if="re.repayment_date !== null">
                              {{ re.repayment_date }}
                            </td>
                            <td class="center" v-else>-</td>
                            <td class="right" v-if="re.overall_repayment_converted_amount !== null">
                              {{ re.overall_repayment_converted_amount }}
                            </td>
                            <td class="right" v-else>-</td>
                            <td class="center" v-if="re.fund_currency_short_name !== null">
                              {{ re.fund_currency_short_name }}
                            </td>
                            <td class="center" v-else>-</td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>
        <!-- /.Tabpane third end-->
      </div>
      <!-- /.content-->
    </div>
    <!-- /.content-wrapper -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import $ from "jquery";
// //Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js";
import moment from "moment";

export default {
  data() {
    return {
      offtakers: [],
      facilities: [],
      offtakers_rep: [],
      facilities_rep: [],
      selectedFacility: null,
      selectedFacilityRepayment: null,
      repayments: [],
      // repayments: [
      //   {
      //     id: 1,
      //     contract_qty: "100",
      //     facility_registration_id: "FR123FR123",
      //     offtaker_name: "Company A",
      //     contract_number: "CN456",
      //     partial_contract_number: "PCN789",
      //     repayment_currency_short_name: "USD",
      //     fund_currency_short_name: 'EUR',
      //     overall_repayment_amount: 123456789,
      //     repayment_date: "2023-01-01",
      //     overall_repayment_converted_amount: 123456789
      //     // Add other fields as necessary
      //   },
      //   {
      //     id: 2,
      //     contract_qty: "200",
      //     facility_registration_id: "FR123FR123",
      //     offtaker_name: "Company A",
      //     contract_number: "CN456",
      //     partial_contract_number: "PCN789",
      //     repayment_currency_short_name: "USD",
      //     fund_currency_short_name: 'EUR',
      //     overall_repayment_amount: 1000,
      //     repayment_date: "2023-01-01",
      //     overall_repayment_converted_amount: 1000
      //   }
      //   // Add more objects as needed
      // ],
      contracts: [],
      borrowers: [],
      offtakers_list: [],
      offtakers_list_rep: [],
      selectedBorrower: null,
      selectedBorrowerRepayment: null,
      selectedOfftaker: null,
      selectedOfftakerRepayment: null,
      selectedStatus: null,
      startDate: null,
      endDate: null,
      inActiveToggle: false,
    };
  },
  components: {
    Datepicker,
  },
  methods: {
    // re-render datatable based on selected active/inactive option
    destroyTable() {
      $("#myTable1").dataTable().fnDestroy();
      if (!this.inActiveToggle) {
        this.$store.commit("setinactive", "offtaker_active");
        this.$router.go();
      } else {
        this.$store.commit("setinactive", "offtaker_inactive");
        this.getOfftakers();
      }
    },
    onChangeStatus(updatedStatus, offtakerId, prevStatus, index) {
      //this function is called when user changes the offtaker registration status. The status is updated in the database for that specific offtaker.
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update the status?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            const path = "offtaker-summary";
            Utils.start_loading();
            axios
              .put(
                path,
                {
                  type: "status",
                  status: updatedStatus.target.value,
                  offtakerId: offtakerId,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 12,
                  tabId: 30,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                Utils.stop_loading();
                console.log("registration update status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert(
                    "success",
                    "Registration Status updated successfully!"
                  );
                  this.offtakers[index].status = updatedStatus.target.value;
                  return true;
                } else {
                  Utils.make_alert(
                    "warning",
                    "Something went wrong from server!"
                  );
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.error("error: " + error);
                Utils.make_alert("warning", "Something went wrong!");
              });
          } else {
            updatedStatus.target.value = prevStatus;
          }
        },
      });
    }, //onChangeStatus close

    // filter & reset function
    filterResetContracts(filter) {
      this.onBorrowerChange();
      if (filter) {
        if (
          (this.startDate !== null && this.endDate === null) ||
          (this.startDate === null && this.endDate !== null)
        ) {
          Utils.make_alert(
            "warning",
            "Both From & To Dates are required to perform Date Filter"
          );
          return;
        } else {
          $("#myTable2").dataTable().fnDestroy();
          this.getContracts();
        }
      } else {
        this.$router.go();
      }
    },

    // filter & reset function
    filterResetRepayments(filter) {
      this.onBorrowerRepaymentChange();
      if (filter) {
        if (
          (this.startDate !== null && this.endDate === null) ||
          (this.startDate === null && this.endDate !== null)
        ) {
          Utils.make_alert(
            "warning",
            "Both From & To Dates are required to perform Date Filter"
          );
          return;
        } 
        else {
          $("#repaymentTable").dataTable().fnDestroy();
          this.getRepayments();
        }
      } else {
        this.$router.go();
      }
    },

    getOfftakers() {
      //this function will be called to get list of offtaker
      const path = "offtaker-summary";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          if (this.inActiveToggle) {
            this.offtakers = res.data.offtaker_inactive_list;
          } else {
            this.offtakers = res.data.offtaker_active_list;
          }
          console.log("from offtaker", this.loggedInUserGroupId);
          if (this.loggedInUserGroupId === "Offtaker")
            this.offtakers = this.offtakers.filter(
              (off) =>
                off.offtaker_master_id.toString() ===
                this.loggedInUserSubGroupId
            );
          // this.offtakers.map((off) => {
          //   off.registration_date = moment(off.registration_date).format(
          //     "MM-DD-YYYY"
          //   );
          // });
          if(this.offtakers.length > 0) {
            this.offtakers.forEach(item => {
              if (item.registration_date != '-' && item.registration_date != null) {
                item.registration_date = moment(item.registration_date).format(this.$store.state.moment_date_format);
              } else {
                item.registration_date = '-';
              }
            });
          }
          if (this.loggedInUserGroupId !== "Offtaker")
            setTimeout(
              () =>
                $("#myTable1").DataTable({
                  aaSorting: [],
                  columnDefs: this.inActiveToggle
                    ? []
                    : [
                        {
                          orderable: false,
                          targets:
                            this.loggedInUserGroupId === "Admin"
                              ? [4, 5, 6]
                              : this.loggedInUserGroupId === "Analyst"
                              ? [4,5]
                              : [4],
                        },
                      ],
                }),
              100
            );
          if (res.data.status == "success") {
            console.log("get offtakers is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },
    deleteOfftaker(offtakerId, status) {
      //this function will be called to delete a specific offtaker.
      this.$confirm({
        auth: false,
        message: "Are you sure you want to permanently delete?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          if (confirm) {
            Utils.start_loading();
            axios
              .delete("offtaker-summary", {
                data: {
                  offtakerId: offtakerId,
                  status: status,
                  clientId: this.$store.state.client_id,
                  userId: this.$store.getters.getuserId,
                  moduleId: 12,
                  tabId: 30,
                },
                headers: { "Content-type": "application/json" },
              })
              .then((res) => {
                Utils.stop_loading();
                console.log("delete status: " + res.data.status);
                if (res.data.status == "success") {
                  Utils.make_alert("success", "Offtaker deleted successfully!");
                  //if you want to send any data into server before redirection then you can do it here
                  setTimeout(() => this.$router.go(), 1500);
                } else {
                  console.log("Something went wrong from server!");
                }
              })
              .catch((error) => {
                Utils.stop_loading();
                console.log("error: " + error);
              });
          }
        },
      });
    }, //deleteOfftaker() close

    getContracts() {
      if (this.startDate != null) {
        this.startDate = moment(this.startDate).format("MM-DD-YYYY");
      }
      if (this.endDate != null) {
        this.endDate = moment(this.endDate).format("MM-DD-YYYY");
      }
      if (this.selectedBorrower === null) {
        Utils.make_alert("warning", "Borrower Filter is required");
        return;
      }
      //this function will be called to get list of contracts
      const path =
        "contract-tracker-summary/" +
        this.selectedBorrower +
        "/" +
        this.selectedOfftaker +
        "/" +
        this.startDate +
        "/" +
        this.endDate +
        "/" +
        this.selectedStatus + 
        "/" +
        "1";
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.contracts = res.data.contract_tracker_summary;
          if(this.contracts.length > 0) {
            this.contracts.forEach(item => {
              if (item.delivery_month !== null) {
                item.delivery_month = moment(item.delivery_month).format("MMMM-YYYY");
              }
              if (item.contract_date != '-' && item.contract_date != null) {
                item.contract_date = moment(item.contract_date, "MM-DD-YYYY").format(this.$store.state.moment_date_format);
              } else {
                item.contract_date = '-';
              }
              if (item.conversion_date != '-' && item.conversion_date != null) {
                item.conversion_date = moment(item.conversion_date).format(this.$store.state.moment_date_format);
              } else {
                item.conversion_date = '-';
              }
            });
          }
          setTimeout(
            () => {
              // Determine if the user is an Admin or Analyst
              const isScrollXEnabled = this.loggedInUserGroupId === 'Admin' || this.loggedInUserGroupId === 'Analyst';
              console.log("isScrollXEnabled", isScrollXEnabled)
              $("#myTable2").DataTable({
                scrollX: isScrollXEnabled, // Set scrollX based on user group
                aaSorting: [],
                columnDefs: [
                  {
                    orderable: false,
                    // Set targets for Admin or Analyst, otherwise an empty array
                    targets: isScrollXEnabled ? [11, 12] : [],
                  },
                ],
              });
            },
            100
          );
          if (res.data.status == "success") {
            console.log("get contract is successful.");
          } else {
            console.log("Something went wrong from server for get contracts");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    getRepayments() {
      if (this.selectedBorrowerRepayment === null) {
        Utils.make_alert("warning", "Borrower Filter is required");
        return;
      }
      //this function will be called to get list of contracts
      const path =
        "offtaker-repayment-summary/" +
        this.selectedBorrowerRepayment +
        "/" +
        this.selectedFacilityRepayment +
        "/" +
        this.selectedOfftakerRepayment
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.repayments = res.data.repayment_summary;
          if(this.repayments.length > 0) {
            this.repayments.forEach(item => {
              if (item.repayment_date != '-' && item.repayment_date != null) {
                item.repayment_date = moment(item.repayment_date).format(this.$store.state.moment_date_format);
              } else {
                item.repayment_date = '-';
              }
            });
          }
          setTimeout(
            () => {
              // Determine if the user is an Admin or Analyst
              $("#repaymentTable").DataTable({
                scrollX: true, // Set scrollX based on user group
                aaSorting: [],
                columnDefs: [
                  {
                    orderable: false,
                    // Set targets for Admin or Analyst, otherwise an empty array
                    targets: this.loggedInUserGroupId === 'Admin' || this.loggedInUserGroupId === 'Analyst' ? [] : [],
                  },
                ],
              });
            },
            100
          );
          if (res.data.status == "success") {
            console.log("get contract is successful.");
          } else {
            console.log("Something went wrong from server for get contracts");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    onBorrowerChange() {
      if (this.selectedBorrower) {
        localStorage.setItem("contractBorrowerFilter", this.selectedBorrower);
      }
    },
    onBorrowerRepaymentChange() {
      if (this.selectedBorrowerRepayment) {
        localStorage.setItem("repaymentBorrowerFilter", this.selectedBorrowerRepayment);
      }
    },

    getFacilitySetupByBorrower() {
      //this function is called to get list of day count
      const path = "/facility-setup-borrower/" + this.selectedBorrower;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.selectedFacility = null;
          this.facilities = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getOfftakerNameByBorrower() {
      console.log("inside get getOfftakerNameByBorrower");
      if (this.selectedBorrower === null) {
        Utils.make_alert("warning", "Borrower Filter is required");
        return;
      }
      // const path ="/get-offtaker-name-by-borrower/" + this.selectedBorrower;
      const path = 
      "/get-offtaker-name-by-borrower" + 
      "/" + 
      this.selectedBorrower +
      "/" +
      null +
      "/" +
      'Offtaker';
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          Utils.stop_loading();
          this.selectedOfftaker = null;
          this.offtakers_list = res.data.offtaker_names;
          console.log("offtakers_list", this.offtakers_list)
          if (this.loggedInUserGroupId === "Offtaker") {
            this.offtakers_list.filter((bor) => {
              if (bor.id.toString() === this.loggedInUserSubGroupId)
              this.selectedOfftaker = bor.id;
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },

    getFacilitySetupByRepaymentBorrower() {
      //this function is called to get list of day count
      console.log("inside getFacilitySetupByRepaymentBorrower");
      const path = "/facility-setup-borrower/" + this.selectedBorrowerRepayment;
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.selectedFacilityRepayment = null;
          this.facilities_rep = res.data.Facility_Setup;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getOfftakerNameByRepaymentBorrower() {
      console.log("inside get getOfftakerNameByRepaymentBorrower");
      console.log(this.selectedBorrowerRepayment)
      if (this.selectedBorrowerRepayment === null) {
        Utils.make_alert("warning", "Borrower Filter is required");
        return;
      }
      // const path ="/get-offtaker-name-by-borrower/" + this.selectedBorrowerRepayment;
      const path = 
      "/get-offtaker-name-by-borrower" + 
      "/" + 
      this.selectedBorrowerRepayment +
      "/" +
      null +
      "/" +
      'Offtaker';
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then(res => {
          Utils.stop_loading();
          this.selectedOfftakerRepayment = null;
          this.offtakers_list_rep = res.data.offtaker_names;
          console.log("offtakers_list_rep", this.offtakers_list_rep)
          if (this.loggedInUserGroupId === "Offtaker") {
            this.offtakers_list_rep.filter((bor) => {
              if (bor.id.toString() === this.loggedInUserSubGroupId)
              this.selectedOfftakerRepayment = bor.id;
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },

    // this function will be called to get list of borrowers
    getBorrowers() {
      console.log("get Borrowers being called")
      let path = null;
      if (this.loggedInUserGroupId === "Offtaker") {
        path = "borrower-master-offtaker/" + this.loggedInUserSubGroupId;
      } else {
        path = "borrower-master-offtaker/" + null;
      }
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          //this will be when response is received from server
          console.log("status: " + res.data.status);
          this.borrowers = res.data.borrowers;
          if (this.borrowers.length) {
            if (this.loggedInUserGroupId == "Borrower") {
              this.borrowers.filter((bor) => {
                if (bor.borrower_id.toString() == this.loggedInUserSubGroupId){
                  this.selectedBorrower = bor.borrower_id;
                  this.selectedBorrowerRepayment = bor.borrower_id;
                }
              });
            } else {
              this.selectedBorrower = this.borrowers[0].borrower_id;
              this.selectedBorrowerRepayment = this.borrowers[0].borrower_id;
            }
          } else {
            return;
          }
          let borrowerFilter = localStorage.getItem("contractBorrowerFilter");
          if (borrowerFilter === null || borrowerFilter === "null") {
            localStorage.setItem(
              "contractBorrowerFilter",
              this.selectedBorrower
            );
          } else {
            this.selectedBorrower = borrowerFilter;
          }
          let borrowerRepaymentFilter = localStorage.getItem("repaymentBorrowerFilter");
          if (borrowerRepaymentFilter === null || borrowerRepaymentFilter === "null") {
            localStorage.setItem(
              "repaymentBorrowerFilter",
              this.selectedBorrowerRepayment
            );
          } else {
            this.selectedBorrowerRepayment = borrowerRepaymentFilter;
          }
          this.onBorrowerChange();
          this.getOfftakerNameByBorrower();
          this.onBorrowerRepaymentChange();
          // this.getFacilitySetupByBorrower();
          this.getFacilitySetupByRepaymentBorrower();
          this.getOfftakerNameByRepaymentBorrower();
          $("#myTable2").dataTable().fnDestroy();
          $("#repaymentTable").dataTable().fnDestroy();
          this.getContracts();
          this.getRepayments();

          // //get Approved borrowers
          // if (res.data.borrowers_active)
          //   this.borrowers = this.borrowers.filter((bor) => {
          //     if (bor.status === "Approved") {
          //       return bor;
          //     }
          //   });

          if (res.data.status == "success") {
            console.log("get borrowers is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          //this is when response is not received from server
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    },

    // this function will be called to get list of offtakers
    getBuyers() {
      const path = "buyer";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          this.offtakers_list = res.data.Buyer;
          //get Approved Offtaker
          if (res.data.Buyer) {
            this.offtakers_list = this.offtakers_list.filter((bor) => {
              if (bor.status === "Approved") {
                return bor;
              }
            });
          }
          if (this.loggedInUserGroupId === "Offtaker") {
            this.offtakers_list.filter((bor) => {
              if (bor.id.toString() === this.loggedInUserSubGroupId)
              this.selectedOfftaker = bor.id;
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setTab(tab) {
      if (localStorage.getItem("offtakerTab") !== tab)
        localStorage.setItem("offtakerTab", tab);
    },
  },
  mounted() {
    let activeTab = localStorage.getItem("offtakerTab");
    if (activeTab) {
      document.getElementById(activeTab).click();
    } else {
      if(this.loggedInUserGroupId == 'Borrower'){
        document.getElementById("custom-tabs-one-contract-tab").click();
      } else {
        document.getElementById("custom-tabs-one-offtaker-tab").click();
      }
    }
  },
  created() {
    axios.defaults.headers.common["Authorisation"] =
      "Bearer " + sessionStorage.getItem("token");
    this.inActiveToggle =
      this.$store.state.inactive === "offtaker_inactive" ? true : false;
    // this.getBuyers();
    this.getBorrowers();
    this.getOfftakers();
      },
  computed: {
    loggedInUserFullName() {
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },
  },
};
</script>

<style scoped>
th,
td {
  text-align: left;
}
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}

.col{
  width: 120px !important;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}

.wrapper {
  margin-bottom: -20px;
  min-height: 680px !important;
}
</style>

