<!-- This is add Fund page. -->

<template>
  <div>
    <div class="wrapper">
      <nav-bar></nav-bar>
      <aside-bar></aside-bar>
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <div class="content">
          <!-- Main content -->
          <div class="container-fluid tabtop pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">
                          <i class="icofont-plus"></i> Add Proportionate Expense
                        </h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="bck_btn">
                          <li>
                            <a @click="$router.go(-1)" style="cursor: pointer">
                              <i class="icofont-caret-left text-dark"></i>
                              <span>Back</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Basic Details</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Expense Type</label>
                      <select
                        id="selectedExpense"
                        class="form-control"
                        name="selectedExpense"
                        v-model="selectedExpense"
                        @change="() => { this.fundMappingList = []; this.fundMappingAddMoreDisabled = false; }"
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedExpense.$error,
                        }"
                      >
                        <option :value="null">-- Select an Expense --</option>
                        <option
                          v-for="expense in expenses"
                          :value="expense.id" :key="expense.id"
                        >{{ expense.expense_name }}</option>
                      </select>
                      <div
                      v-if="isSubmitted && $v.selectedExpense.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.selectedExpense.required"
                        >Expense Type is required</span
                      >
                    </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6" v-show="type === 'expense'">
                    <div class="form-group fc">
                      <label class="required-field">Payment Type</label>
                      <select
                        id="selectedExpenseType"
                        class="form-control"
                        name="selectedExpenseType"
                        v-model="selectedExpenseType"
                        @change="() => { this.fundMappingList = []; this.fundMappingAddMoreDisabled = false; }"
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedExpenseType.$error,
                        }"
                      >
                        <option :value="null">-- Select a Payment Type --</option>
                        <option
                          v-for="exptype in expenseTypes"
                          :value="exptype.expense_type_name" :key="exptype.id"
                        >{{ exptype.expense_type_name }}</option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedExpenseType.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedExpenseType.required"
                          >Payment Type is required</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field">Amount</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Type Amount"
                        v-model="amount"
                        @keypress="isDecimalTwoFixed($event)"
                        @paste="truncateDecimalPlacesUptoTwo($event)"
                        @change="() => { this.fundMappingList = []; this.fundMappingAddMoreDisabled = false; }"
                        :class="{
                          'is-invalid': isSubmitted && $v.amount.$error,
                        }"
                        required
                      />
                      <div
                        v-if="isSubmitted && $v.amount.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.amount.required"
                          >Amount is required<br/></span
                        >
                        <span v-if="!$v.amount.maxLength"
                        >Max length of 15 digits exceeded.</span
                      >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label class="required-field">Standard Currency</label>
                      <select
                        id="selectedCurrency"
                        class="form-control"
                        name="selectedCurrency"
                        v-model="selectedCurrency"
                        @change="() => { this.fundMappingList = []; this.fundMappingAddMoreDisabled = false; getCurrencyShortNameById(this.selectedCurrency); }"
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedCurrency.$error,
                        }"
                        required
                      >
                        <option :value="null">-- Select a Currency --</option>
                        <option
                          v-for="curr in currencies"
                          :value="curr.id" :key="curr.id"
                        >{{ curr.currency_short_name }}</option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedCurrency.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedCurrency.required"
                          >Standard Currency is required</span
                        >
                      </div>
                    </div>
                  </div>
                  
                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-show="type === 'expense' && this.selectedExpenseType === 'Amortized'"
                  >
                    <div class="form-group fc">
                      <label class="required-field">Amortization Schedule</label>
                      <select
                        id="selectedInterestFrequency"
                        class="form-control"
                        name="selectedInterestFrequency"
                        v-model="selectedInterestFrequency"
                        :class="{
                          'is-invalid': isSubmitted && $v.selectedInterestFrequency.$error,
                        }"
                        required
                      >
                        <option :value="null">-- Select a Schedule --</option>
                        <option
                          v-for="intfreq in interestFrequencies.slice(1,5)"
                          :value="intfreq.id" :key="intfreq.id"
                        >{{ intfreq.interest_frequency_name }}</option>
                      </select>
                      <div
                        v-if="isSubmitted && $v.selectedInterestFrequency.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.selectedInterestFrequency.required"
                          >Amortization Schedule is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-md-2 col-sm-5 col-6"
                    v-show="type === 'expense' && this.selectedExpenseType === 'Amortized'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field">No. of Instalments</label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Type Installments"
                        v-model="installments"
                        :class="{
                          'is-invalid': isSubmitted && $v.installments.$error,
                        }"
                        required
                      />
                      <div
                        v-if="isSubmitted && $v.installments.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.installments.required"
                          >No. of Instalments is required</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="InvoiceNumber">Invoice Number</label>
                      <input
                        type="text"
                        class="form-control"
                        id="InvoiceNumber"
                        v-model="InvoiceNumber"
                        :class="{
                          'is-invalid': isSubmitted && $v.InvoiceNumber.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.InvoiceNumber.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.InvoiceNumber.customValidation"
                          >Invoice Number should not be zero<br
                        /></span>
                        <span v-if="!$v.InvoiceNumber.alphaNum"
                          >Invoice Number should be alphanumeric only<br/></span
                        >
                        <span v-if="!$v.InvoiceNumber.maxLength"
                          >Max length of 100 characters exceeded<br/></span
                        >
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="nameOfEntity">Name Of Entity</label>
                      <input
                        type="text"
                        class="form-control"
                        id="nameOfEntity"
                        v-model="nameOfEntity"
                        @keypress="isAlphaNum($event)"
                        :class="{
                          'is-invalid': isSubmitted && $v.nameOfEntity.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.nameOfEntity.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.nameOfEntity.customValidation"
                          >Name Of Entity should not be zero<br
                        /></span>
                        <span v-if="!$v.nameOfEntity.maxLength"
                          >Max length of 100 characters exceeded<br/></span
                        >
                      </div>
                    </div>
                  </div>
                  

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="Comments">Comments</label>
                      <input
                        type="text"
                        class="form-control"
                        id="Comments"
                        v-model="Comments"
                        :class="{
                          'is-invalid': isSubmitted && $v.Comments.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.Comments.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.Comments.customValidation"
                          >Comments should not be zero<br
                        /></span>
                        <span v-if="!$v.Comments.maxLength"
                          >Max length of 100 characters exceeded<br/></span
                        >
                      </div>
                    </div>
                  </div>
                  
                  <!-- <div
                    class="col-md-2 col-sm-5 col-6"
                    v-show="type === 'expense' && this.selectedExpenseType === 'Amortized'"
                  >
                    <div class="form-group fc">
                      <label for="exampleInputEmail1" class="required-field">Start Date</label>
                      <datepicker
                        class="datepicker"
                        :bootstrap-styling="true"
                        name="startDate"
                        placeholder="Select Date"
                        v-model="startDate"
                        :format="$store.state.date_format"
                        calendar-button-icon="fa fa-calendar"
                        :calendar-button="true"
                        required
                      ></datepicker>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt">Banking Details</h1>
                    </div>

                    <!-- /.col -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="selectedBank">Beneficiary Bank</label>
                      <select
                        id="selectedBank"
                        class="form-control"
                        v-model="selectedBank"
                      >
                        <option :value="null">-- Select a Bank --</option>
                        <option
                          v-for="bank in banks"
                          :value="bank.id"
                          :key="bank.id"
                        >
                          {{ bank.bank_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="bic">Bank Identifier Code</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="bic"
                        id="bic"
                        :class="{
                          'is-invalid': isSubmitted && $v.bic.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.bic.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.bic.required"
                          >Bank Identifier Code cannot be 0<br/></span
                        >
                        <span v-if="!$v.bic.minLength && $v.bic.required"
                          >Min length of 8 characters required<br
                        /></span>
                        <span v-if="!$v.bic.maxLength"
                          >Max length of 11 characters exceeded<br
                        /></span>
                        <span v-if="!$v.bic.alphaNum"
                          >Bank Identifier Code should be alphanumeric
                          only<br/></span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="iban">International Bank A/c No.</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="iban"
                        id="iban"
                        :class="{
                          'is-invalid': isSubmitted && $v.iban.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.iban.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.iban.required"
                          >IBAN cannot be 0<br/></span
                        >
                        <span v-if="!$v.iban.maxLength"
                          >Max length of 35 characters exceeded<br
                        /></span>
                        <span v-if="!$v.iban.alphaNum"
                          >IBAN should be alphanumeric only<br/></span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="accountNumber">Account No.</label>
                      <input
                        type="text"
                        id="accountNumber"
                        class="form-control"
                        v-model="accountNumber"
                        :class="{
                          'is-invalid': isSubmitted && $v.accountNumber.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.accountNumber.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.accountNumber.required"
                          >Account No. cannot be 0<br/></span
                        >
                        <span v-if="!$v.accountNumber.maxLength"
                          >Max length of 17 characters exceeded<br
                        /></span>
                        <span v-if="!$v.accountNumber.numeric"
                          >Account No. should be numeric only<br/></span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="accountName">Account Name </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="accountName"
                        id="accountName"
                        :class="{
                          'is-invalid': isSubmitted && $v.accountName.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.accountName.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.accountName.required"
                          >Account Name cannot be 0<br/></span
                        >
                        <span v-if="!$v.accountName.maxLength"
                          >Max length of 100 characters exceeded<br
                        /></span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="reference">Reference </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="reference"
                        id="reference"
                        :class="{
                          'is-invalid': isSubmitted && $v.reference.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.reference.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.reference.required"
                          >Reference cannot be 0<br/></span
                        >
                        <span v-if="!$v.reference.maxLength"
                          >Max length of 100 characters exceeded<br
                        /></span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-5 col-6">
                    <div class="form-group fc">
                      <label for="transactionID">Transaction ID</label>
                      <input
                        type="text"
                        class="form-control"
                        id="transactionID"
                        v-model="transactionID"
                        :class="{
                          'is-invalid': isSubmitted && $v.transactionID.$error,
                        }"
                      />
                      <div
                        v-if="isSubmitted && $v.transactionID.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.transactionID.required"
                          >Transaction ID should not be zero<br
                        /></span>
                        <span v-if="!$v.transactionID.numeric"
                          >Transaction ID should be numeric only<br/></span
                        >
                        <span v-if="!$v.transactionID.maxLength"
                          >Max length of 25 characters exceeded<br/></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Documents</h1>
                      </div>
                      <!-- /.col -->
                      <div class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addExpensePaymentDoc()"
                              :disabled="ExpensePaymentDocumentsAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->

                    <div
                      class="row"
                      v-for="(doc, index) in ExpensePaymentDocuments"
                      :key="index"
                    >
                      <div class="col-md-4 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label for="customFile">Upload Document</label>
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="customFile"
                              name="file"
                              ref="file"
                              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                              v-on:change="
                                handleFileUploadExpensePaymentDocuments(index, $event)
                              "
                              :class="{
                                'is-invalid': doc.errors.filesize,
                              }"
                            />
                            <small>
                              <p style="background-color: powderblue">
                                <b>{{ doc.displayFileName }}</b>
                                <small>
                                  <u
                                    style="color: blue; cursor: pointer"
                                    v-if="
                                      doc.ExpensePaymentDocumentsRemoveDisabled === false
                                    "
                                    v-on:click="removeExpensePaymentDocuments(index)"
                                    >Remove</u
                                  >
                                </small>
                              </p>
                            </small>
                            <div
                              v-if="doc.errors.filesize"
                              class="invalid-feedback"
                            >
                              <span>{{ doc.errors.filesize }}</span>
                            </div>
                            <label class="custom-file-label" for="customFile"
                              >Choose file</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-5 col-6">
                      <div class="form-group fc">
                        <label for="comments">Comments</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder
                          v-model="doc.comments"
                          id="comments"
                          :class="{
                            'is-invalid':
                              isSubmitted &&
                              $v.ExpensePaymentDocuments.$each[index].comments
                                .$error,
                          }"
                          :disabled="doc.displayFileName == null"
                        />
                        <div
                          v-if="
                            isSubmitted &&
                            $v.ExpensePaymentDocuments.$each[index].comments.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.ExpensePaymentDocuments.$each[index].comments
                                .maxLength
                            "
                            >Max length of 100 characters exceeded.<br/></span
                          >
                        </div>
                      </div>
                    </div>

                      <div class="col-md-1 col-sm-5 col-6">
                        <div class="form-group fc">
                          <label
                            for="exampleInputEmail1"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            v-on:click="deleteExpensePaymentDocuments(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onExpensePaymentDocumentSaveInfo()"
                          :disabled="isDisabledExpensePaymentDocumentSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid ot_tb pt-3">
            <div class="card card-primary card-tabs">
              <div class="card-body">
                <div class="container-fluid" id="al_tb">
                  <div class="tp_mid">
                    <div class="row">
                      <div class="col-lg-6">
                        <h1 class="headingt">Fund Mapping</h1>
                      </div>
                      <!-- /.col -->
                      <div v-if="flow != 'Fund'" class="col-lg-6">
                        <ul id="sr_btn">
                          <li>
                            <button
                              type="button"
                              class="btn btn-sm sh_n btn-dark"
                              @click="addFundMapping"
                              :disabled="fundMappingAddMoreDisabled"
                            >
                              <i class="icofont-plus"></i> Add More
                            </button>
                          </li>
                        </ul>
                      </div>
                      <!-- /.col -->
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <!------menton here style="height: 300px;"-------->
                    <div
                      class="row"
                      v-for="(lm, index) in fundMappingList"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="selectedFund" class="required-field">Fund Name</label>
                          <select
                            id="selectedFund"
                            class="form-control"
                            name="selectedFund"
                            v-model="lm.selectedFund"
                            @change="onFundSelectGetDetails(index)"
                            :class="{
                              'is-invalid':
                                isSubmitted &&
                                $v.fundMappingList.$each[index]
                                  .selectedFund.$error,
                            }"
                            :disabled="flow == 'Fund'"
                          >
                            <option :value="null">--- Select ---</option>
                            <option
                              v-for="fund in funds"
                              :value="fund.fund_id"
                              :key="fund.fund_id"
                            >
                              {{ fund.fund_name }}
                            </option>
                          </select>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.fundMappingList.$each[index].selectedFund
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.fundMappingList.$each[index]
                                  .selectedFund.required
                              "
                              >Fund Name is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div v-if="selectedExpenseType == 'Onetime'" class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="paymentDate" class="required-field">Payment Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            placeholder="Select Date"
                            id="paymentDate"
                            v-model="lm.paymentDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            @input="getfundConversionRateByCurrencyDate(index,lm.paymentDate)"
                            :class="{
                              'is-invalid': isSubmitted && $v.fundMappingList.$each[index]
                                  .paymentDate.$error,
                            }"
                            :disabled="!lm.selectedFund" 
                            :disabledDates="{ to: new Date(lm.backCapDate), from: null }"
                          ></datepicker>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.fundMappingList.$each[index].paymentDate
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.fundMappingList.$each[index]
                                  .paymentDate.required
                              "
                              >Payment Date is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div v-if="selectedExpenseType == 'Amortized'" class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="startDate" class="required-field">Start Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            placeholder="Select Date"
                            id="startDate"
                            v-model="lm.startDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            @input="getfundConversionRateByCurrencyDate(index,lm.startDate)"
                            :class="{
                              'is-invalid': isSubmitted && $v.fundMappingList.$each[index]
                                  .startDate.$error,
                            }"
                            :disabled="!lm.selectedFund" 
                            :disabledDates="{ to: new Date(lm.backCapDate), from: null }"
                          ></datepicker>
                          <div
                            v-if="
                              isSubmitted &&
                              $v.fundMappingList.$each[index].startDate
                                .$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.fundMappingList.$each[index]
                                  .startDate.required
                              "
                              >Start Date is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="fundAmount" class="required-field">Amount (Std. Currency)</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="lm.fundAmount"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            @keyup="onChangeFundAmountValidation(index)"
                            :class="{
                              'is-invalid':
                                (isSubmitted &&
                                $v.fundMappingList.$each[index]
                                  .fundAmount.$error) || (lm.fundAmountError),
                            }"
                            id="fundAmount"
                            :disabled="!lm.selectedFund"
                          />
                          <div
                            v-if="
                              (isSubmitted &&
                              $v.fundMappingList.$each[index].fundAmount
                                .$error) || (lm.fundAmountError)
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                (lm.fundAmountError)
                              "
                              >{{ lm.fundAmountErrorMessage }}</span
                            >
                            <span
                              v-if="
                                !$v.fundMappingList.$each[index]
                                  .fundAmount.required
                              "
                              >Amount (Std. Currency) is required</span
                            >
                            <span
                              v-if="
                                !$v.fundMappingList.$each[index]
                                  .fundAmount.maxLength
                              "
                              >Max length of 15 digits exceeded.</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="selectedFundCurrency">Fund Currency</label>
                          <select
                            id="selectedFundCurrency"
                            class="form-control"
                            v-model="lm.selectedFundCurrency"
                            disabled
                          >
                            <option :value="null">-- Fund Currency --</option>
                            <option
                              v-for="curr in currencies"
                              :value="curr.id"
                              :key="curr.id"
                            >
                              {{ curr.currency_short_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="fundConversionDate">Conversion Date</label>
                          <datepicker
                            class="datepicker"
                            :bootstrap-styling="true"
                            placeholder="Conversion Date"
                            id="fundConversionDate"
                            v-model="lm.fundConversionDate"
                            :format="$store.state.date_format"
                            calendar-button-icon="fa fa-calendar"
                            :calendar-button="true"
                            @input="getfundConversionRateByCurrencyDate(index,lm.fundConversionDate)"
                            disabled 
                            :disabledDates="{ to: new Date(lm.backCapDate), from: new Date() }"
                          ></datepicker>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="fundConversionRate">Conversion Rate</label>
                          <input
                            type="number"
                            class="form-control"
                            v-model="lm.fundConversionRate"
                            id="fundConversionRate"
                            @keypress="isDecimal($event)"
                            disabled
                          />
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label for="fundConvertedAmount">Converted Amount(Fund Currency)</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="lm.fundConvertedAmount"
                            @keypress="isDecimalTwoFixed($event)"
                            @paste="truncateDecimalPlacesUptoTwo($event)"
                            id="fundConvertedAmount"
                            disabled
                          />
                        </div>
                      </div>

                      <div v-if="flow != 'Fund'" class="col-md-1 col-sm-4 col-6">
                        <div class="form-group fc">
                          <label
                            for="deleteFundMapping"
                            style="visibility: hidden"
                            >Delete</label
                          >
                          <div
                            v-on:click="deleteFundMapping(index)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="icofont-trash text-danger"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="flow != 'Fund'" class="row">
                      <div class="col-md-12 text-right mb-3">
                        <button
                          type="button"
                          name="saveInfo"
                          class="btn-sm btn btn-primary sh"
                          v-on:click="onFundMappingSaveInfo()"
                          :disabled="isDisabledFundMappingSaveInfo"
                        >
                          Save Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <ul id="sr_btn">
                  <li>
                    <button
                      type="button"
                      class="btn-sm btn btn-success sh"
                      v-on:click="onSubmit()"
                    >
                      Submit
                    </button>
                  </li>
                  <li>
                    <a @click="$router.go(-1)">
                      <button
                        type="button"
                        class="btn-sm btn btn-dark sh"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Main content -->
      </div>
      <!-- /.content-wrapper-->
    </div>
    <!-- ./wrapper -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import {
  required,
  maxLength,
  minLength,
  numeric,
  alphaNum,
  decimal,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: null,
      inActive: false,
      index: null,
      ptype: null,
      fund: [],
      expenses: [],
      paymentExpenses: [],
      selectedExpense: null,
      // selectedExpense: 4,
      currencies: [],
      selectedCurrency: null,
      // selectedCurrency: 1,
      fundCurrencyShortName: '',
      // fundCurrencyShortName: 'GBP',
      expenseTypes: [],
      selectedExpenseType: null,
      // selectedExpenseType: 'Onetime',
      interestFrequencies: [],
      selectedInterestFrequency: null,
      installments: null,
      expenseBackCapDate: null,
      amount: null,
      // amount: 1000,
      description: null,
      errors: [],
      fundExpensePayment: [],
      fundExpenses: [],
      fundExpensesDates:[],
      paymentExpensesDates:[],
      //filter amortized expenses
      fromDateFundExpense: null,
      toDateFundExpense: null,
      //search filter
      filter: null,
      type: 'expense',
      selectedPeriod: "qtd", //selecting default as 1 year for fund expense filter
      selectedPaymentPeriod: "qtd",
      borrower_id: null,
      fund_inception_date: null,

      nameOfEntity:null,
      InvoiceNumber:null,
      Comments:null,

      // bank
      banks: [],
      selectedBank: null,
      bic: null,
      iban: null,
      accountNumber: null,
      accountName: null,
      reference: null,
      transactionID: null,
      // docs
      ExpensePaymentDocuments: [],

      //list for Loan Documents
      ExpensePaymentDocumentFile: null,
      // ExpensePaymentDocumentsButtonDisabled: true,
      ExpensePaymentDocumentsDisabled: true,
      ExpensePaymentDocumentSaveInfoDisabled: true, //save info button is disabled
      ExpensePaymentDocumentsAddMoreDisabled: true,

      //list for Fund Mapping
      fundMappingList: [],
      fundMappingSaveInfoDisabled: true, //save info button is disabled
      fundMappingAddMoreDisabled: false,
      fundMappingToggle: true,
      totalPerc: 0,
      totalFundAmount: 0,

      flow:'Normal Expense',
      fundID:null,

      isSubmitted: false,
    };
  },

  validations: {
    selectedExpense:{
      required
    },
    selectedExpenseType:{
      required: function(){
        if(this.type === "expense" && !this.selectedExpenseType)
          return false
        else
          return true
      }
    },
    amount: {
      required: function(){
        if(this.amount === null || this.amount == 0)
          return false
        else
          return true
      },
      maxLength: maxLength(15),
    },
    selectedCurrency: {
      required
    },
    selectedInterestFrequency:{
      required: function(){
        if(this.type ==='expense' && this.selectedExpenseType === 'Amortized' && !this.selectedInterestFrequency)
          return false
        else
          return true
      }
    },
    installments:{
      maxLength: maxLength(15),
      required: function(){
        if(this.type ==='expense' && this.selectedExpenseType === 'Amortized' && !this.installments)
          return false
        else
          return true
      }
    },
    // startDate:{
    //   required: function(){
    //     if(this.type ==='expense' && this.selectedExpenseType === 'Amortized' && !this.startDate)
    //       return false
    //     else
    //       return true
    //   }
    // },
    // paymentDate: {
    //   required: function(){
    //     if((this.type ==='payment' || this.selectedExpenseType === "Onetime") && !this.paymentDate)
    //       return false
    //     else
    //       return true
    //   }
    // },
    description: {
      maxLength: function(){
        if(this.type === 'payment' && this.description ? this.description.length > 50 : false)
          return false
        else 
          return true
      }
    },
    InvoiceNumber: {
      customValidation: function (value) {
        if (value != "" && value == 0) return false;
        else return true;
      },
      alphaNum,
      maxLength: maxLength(100),
    },
    nameOfEntity: {
      customValidation: function (value) {
        if (value != "" && value == 0) return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    Comments: {
      customValidation: function (value) {
        if (value != "" && value == 0) return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    bic: {
      alphaNum,
      required: function () {
        if (this.bic === "0") return false;
        else return true;
      },
      minLength: minLength(8),
      maxLength: maxLength(11),
    },
    iban: {
      required: function () {
        if (this.iban === "0") return false;
        else return true;
      },
      alphaNum,
      maxLength: maxLength(35),
    },
    accountNumber: {
      required: function () {
        if (this.accountNumber === "0") return false;
        else return true;
      },
      numeric,
      maxLength: maxLength(17),
    },
    accountName: {
      required: function () {
        if (this.accountName === "0") return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    reference: {
      required: function () {
        if (this.reference === "0") return false;
        else return true;
      },
      maxLength: maxLength(100),
    },
    transactionID: {
      numeric,
      required: function () {
        if (this.transactionID === "0") return false;
        else return true;
      },
      maxLength: maxLength(25),
    },
    ExpensePaymentDocuments: {
      $each: {
        comments: {
          maxLength: maxLength(100),
        },
      },
    },
    fundMappingList: {
      $each: {
        selectedFund:{
          required,
        },
        paymentDate:{
          required: function(value){
            if(this.selectedExpenseType == 'Onetime' && !value)
              return false
            else
              return true
          }
        },
        startDate:{
          required: function(value){
            if(this.selectedExpenseType == 'Amortized' && !value)
              return false
            else
              return true
          }
        },
        fundAmount: {
          required: function(value){
            if(value == null || value == 0)
              return false
            else
              return true
          },
          maxLength: maxLength(15),
        },
      },
    },
  },
  computed: {
    loggedInUserFullName() {
      // console.log(this.$store.getters.getuserProfessional)
      return this.$store.getters.getuserFullName;
    },
    loggedInUserGroupId() {
      // console.log(this.$store.getters.getuserGroup);
      // return this.$store.getters.getuserGroup;
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInUserSubGroupId() {
      // console.log(this.$store.getters.getuserSubGroup);
      // return this.$store.getters.getuserSubGroup;
      var loggedInUserSubGroupId = localStorage.getItem("subgroup_id");
      return loggedInUserSubGroupId;
    },

    //this is to computed disabled property of ExpensePaymentDocuments Save Info button.
    isDisabledExpensePaymentDocumentSaveInfo: function () {
        let checkEmptyExpensePaymentDocumentsDocName = this.ExpensePaymentDocuments.filter(
          (line) => line.displayFileName === null
        );
        //if the line is empty then disable save info button
        if (
          this.ExpensePaymentDocuments.length === 0 &&
          this.ExpensePaymentDocumentSaveInfoDisabled === true
        ) {
          return true;
        }
        //We are checking whether all the input fields are filled and file is also uploaded. If condition is satisfied then only save info button will be enabled.
        if (
          checkEmptyExpensePaymentDocumentsDocName.length >= 1 &&
          this.ExpensePaymentDocuments.length > 0
        ) {
          this.ExpensePaymentDocumentsDisabled = true;
        } else {
          this.ExpensePaymentDocumentsDisabled = false;
        }
        //ExpensePaymentDocumentsDisabled variable is to check whther all input fields except file upload are filled. ExpensePaymentDocumentFile variable is to check is file is uploaded and it is not null. If yes then disable Save Info button.
        if (
          this.ExpensePaymentDocumentsDisabled === true ||
          this.ExpensePaymentDocumentFile === null
        ) {
          return true;
        } else {
          return false;
        }
      }, //isDisabledExpensePaymentDocumentSaveInfo() close

    //this is to computed disabled property of Fund Mapping Save Info button.
    isDisabledFundMappingSaveInfo: function () {
      let checkEmptyFundMappingID = this.fundMappingList.filter(
        (line) => line.selectedFund === null
      );
      let checkEmptyFundMappingRatio = this.fundMappingList
        .slice(-1)
        .filter((line) => line.shareHoldingPattern === null);
      let checkEmptyFundMappingAmount = this.fundMappingList
        .slice(-1)
        .filter((line) => line.shareHoldingValue === null);
      //if the line is empty then disable save info button
      if (
        this.fundMappingList.length === 0 &&
        this.fundMappingSaveInfoDisabled === true
      ) {
        return true;
      }
      //We are checking whether all the input fields are filled. If condition is satisfied then only save info button will be enable
      if (
        (checkEmptyFundMappingID.length >= 1 &&
          this.fundMappingList.length > 0) ||
        (checkEmptyFundMappingRatio.length >= 1 &&
          this.fundMappingList.length > 0 &&
          checkEmptyFundMappingAmount.length >= 1 &&
          this.fundMappingList.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }, //isDisabledFundMappingSaveInfo() close
  }, //main computed close

  components: {
    Datepicker,
  },
  methods: {
    isDecimal(e) {
      let invalidChars = ["-", "+", "e", "E"];
      if (invalidChars.includes(e.key)) return e.preventDefault();
      else return true;
    },

    isAlphaNum(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    isDecimalTwoFixed(e) {
      const invalidChars = ["-", "+", "e", "E"];
      const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "ArrowLeft", "ArrowRight", "Tab"];
      const inputValue = e.target.value;
      const selectionStart = e.target.selectionStart;
      const decimalIndex = inputValue.indexOf(".");

      // If it's not an allowed character, prevent its input
      if (!allowedChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If the key is in invalidChars list, prevent its input
      if (invalidChars.includes(e.key)) {
        return e.preventDefault();
      }

      // If trying to input a '.' and one already exists, prevent its input
      if (e.key === '.' && decimalIndex !== -1) {
        return e.preventDefault();
      }

      // If there are already two characters after the decimal point and the current position is after the decimal, prevent further input
      if (decimalIndex !== -1 && selectionStart > decimalIndex && inputValue.substring(decimalIndex + 1).length >= 2 && e.key !== 'Backspace') {
        return e.preventDefault();
      }
    },

    truncateDecimalPlacesUptoTwo(event) {
      // Get the pasted value
      const pastedValue = event.clipboardData.getData('text/plain');
      
      // Ensure that there are a maximum of two decimal places
      const parts = pastedValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        const newValue = parts.join('.');
        
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Insert the truncated value into the input
        document.execCommand('insertText', false, newValue);
      }
    },

    getCurrencyShortNameById(id) {
      if (id === null) {
        return '';
      }
      const currency = this.currencies.find((currency) => currency.id === id);
      return currency ? currency.currency_short_name : '';
    },

    //this method will make disabled property as false for Director Save Info
    onCostDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.CostDocumentSaveInfoDisabled = false;
      this.CostDocumentsAddMoreDisabled = false;
    },

    //this method will make disabled property as false for Fund Mapping Save Info
    onFundMappingSaveInfo() {
      // Assuming this.fundMappingList is the array of objects and this.funds is the array of fund objects
      let flag = false;

      // Check for duplicates and set the flag and error message
      this.fundMappingList.forEach((fund, index) => {
        console.log("fund",fund)
        console.log("this.amount",this.amount)
        if(Number(fund.fundAmount) > Number(this.amount)){
          fund.fundAmountError = true;
          fund.fundAmountErrorMessage = `Amount (Std. Currency) cannot be greater than Overall Amount ${this.amount} ${this.fundCurrencyShortName}`;
          flag = true;
          return;
        } else {
          flag = false;
          fund.fundAmountError = false;
          fund.fundAmountErrorMessage = "";
        }
      });

      if(!flag) {
        this.calcTotalFundAmount();
        if (Number(this.totalFundAmount) > Number(this.amount)) {
          Utils.make_alert(
            "warning",
            `The sum of Fund Mapping Amount (Std. Currency) cannot be greater than the Overall Amount: ${this.amount} ${this.fundCurrencyShortName}`
          );
        } else {
          Utils.make_alert("success", "Record saved.");
          this.fundMappingSaveInfoDisabled = false;
          this.fundMappingAddMoreDisabled = false;
        }
        this.totalFundAmount = 0;
      } else {
        this.fundMappingAddMoreDisabled = true;
      }
      
    },

    calcTotalPerc() {
      for (let list in this.fundMappingList) {
        this.totalPerc += parseFloat(
          this.fundMappingList[list].fundMappingRatio
            ? this.fundMappingList[list].fundMappingRatio
            : 0
        );
      }
    },

    calcTotalFundAmount() {
      for (let list in this.fundMappingList) {
        this.totalFundAmount += parseFloat(
          this.fundMappingList[list].fundAmount
            ? this.fundMappingList[list].fundAmount
            : 0
        );
      }
    },

    onChangeFundAmountValidation(index){
      this.onfundAmountChnage(index);
      console.log("onChangeFundAmountValidation")
      let fundAmount = this.fundMappingList[index].fundAmount ;
      console.log("Number(this.amount)",Number(this.amount))
        if(Number(fundAmount) > Number(this.amount)){
          this.fundMappingList[index].fundAmountError = true;
          this.fundMappingList[index].fundAmountErrorMessage = `Amount (Std. Currency) cannot be greater than Overall Amount ${this.amount} ${this.fundCurrencyShortName}`;
          return;
        } else {
          this.fundMappingList[index].fundAmountError = false;
          this.fundMappingList[index].fundAmountErrorMessage = "";
          return;
        }
    },

    // this method will check if existing fund mapping is added again
    onFundSelectGetDetails(index) {
      console.log("onFundSelectGetDetails");
      this.isSubmitted = true;
      this.$v.selectedExpense.$touch();
      this.$v.selectedExpenseType.$touch();
      this.$v.amount.$touch();
      this.$v.selectedCurrency.$touch();
      // this.$v.selectedInterestFrequency.$touch();
      // this.$v.installments.$touch();
      if (
          this.$v.selectedExpense.$invalid ||
          this.$v.selectedExpenseType.$invalid ||
          this.$v.amount.$invalid ||
          this.$v.selectedCurrency.$invalid
          // this.$v.selectedInterestFrequency.$invalid ||
          // this.$v.installments.$invalid
        ) {
          console.log("Invalid")
          // Handle invalid fields (e.g., show an error message)
          this.fundMappingList[index].selectedFund = null;
          return;
        }
      if(this.flow == 'Fund'){
        this.fundMappingList[index].selectedFund = this.fundID;
      }
      console.log("this.fundMappingList[index].selectedFund",this.fundMappingList[index].selectedFund)
      let fund_count = this.fundMappingList.filter(
        (col) => col.selectedFund === this.fundMappingList[index].selectedFund
      ).length;
      // delete if existing fund is found
      if (fund_count > 1) {
        this.deleteFundMapping(index);
        Utils.make_alert("warning", "Fund Mapping already exists");
      }
      
      let fund = this.funds.find((fund) => fund.fund_id == this.fundMappingList[index].selectedFund)
      this.fundMappingList[index].selectedFundCurrency = fund.fund_currency_id;
      this.fundMappingList[index].backCapDate = fund.fund_inception_date;
      // this.fundMappingList[index].paymentDate = fund.fund_inception_date;
      // this.fundMappingList[index].startDate = fund.fund_inception_date;
      this.getExpenseBackcapDates(index)
    },

    getExpenseBackcapDates(index){
      console.log("getExpenseBackcapDates")
      const path ="filter-by-fund-id/" + this.fundMappingList[index].selectedFund;
      Utils.start_loading();
      axios
      .get(path, { params: { clientId: this.$store.state.client_id } })
      .then((res) => {
            Utils.stop_loading();
            this.fundMappingList[index].fundExpensesDates = res.data.expense_dates;
            let finalDate = null
            // if(this.selectedExpenseType == 'Onetime'){
            //   finalDate = this.fundMappingList[index].paymentDate
            // } else if (this.selectedExpenseType == 'Amortized'){
            //   finalDate = this.fundMappingList[index].startDate
            // }
            let date = this.fundMappingList[index].fundExpensesDates.filter(exp=> exp.expense_id == this.selectedExpense);
            console.log(" this.selectedExpense: " + this.selectedExpense)
            console.log("date LIST: ",date)
            if(date.length > 0){
              console.log(date.length)
              // console.log("date.length: ",date)
              let date1 = moment(date[0].date).add(1,"days").format("MM-DD-YYYY")
              this.fundMappingList[index].backCapDate = date1;
              console.log("backcap date",date1)
              this.fundMappingList[index].paymentDate = date1;
              this.fundMappingList[index].startDate = date1;
              finalDate = date1
            }
            console.log("date finalDate: " + finalDate)
            this.getfundConversionRateByCurrencyDate(index , finalDate)
            if (res.data.status == "success") {
              console.log("get fund expense dates by id is successful.");
            } else {
              console.log("error in get fund expense dates by id");
            }
          })
          .catch((error) => {
            console.error("error: " + error);
          });
    },

    getfundConversionRateByCurrencyDate(index , date) {
      //this function is called to get fund currency of that facility
      console.log("inside getfundConversionRateByCurrencyDate")
      let fund = this.fundMappingList.find(
        (col) => col.selectedFund === this.fundMappingList[index].selectedFund)
      console.log("fund",fund)
      let fundCurrencyID = fund.selectedFundCurrency
      console.log("fundCurrencyID",fundCurrencyID)
      let Date = null;
      if (date != null) {
        Date = moment(date).format("MM-DD-YYYY");
      } else {
        return
      }
      let currencyID = null;
      currencyID = this.selectedCurrency
      if(fundCurrencyID === currencyID){
        this.fundMappingList[index].fundConversionRate = 1;
        this.fundMappingList[index].fundConversionDate = Date;
        this.onfundAmountChnage(index);
        return;
      }
      const path = "/currency-exchange-rate/" +
        fundCurrencyID +
        "/" +
        currencyID +
        "/" +
        Date
      Utils.start_loading();
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          Utils.stop_loading();
          console.log(res.data)
          let data = res.data.currency_rate[0]
          if(data != null){
              this.fundMappingList[index].fundConversionRate = data.currency_value;
              this.fundMappingList[index].fundConversionDate = data.date;
              this.onfundAmountChnage(index);
            }else{
              Utils.make_alert("warning", "No currency conversion rate available.");
              this.fundMappingList[index].startDate = null;
              this.fundMappingList[index].paymentDate = null;
              this.fundMappingList[index].fundConversionRate = null;
              this.fundMappingList[index].fundConversionDate = null;
              this.onfundAmountChnage(index);
            }
        })
        .catch((error) => {
          console.error(error);
          Utils.stop_loading();
        });
    },

    onfundAmountChnage(index){
      let amount = this.fundMappingList[index].fundAmount;
      let rate = this.fundMappingList[index].fundConversionRate;
      
      if (amount % 1 !== 0 && amount.toString().split(".")[1].length >= 2) {
        console.log("There are: " + amount)
        this.fundMappingList[index].fundAmount = parseFloat(amount).toFixed(2);
      } 
      this.fundMappingList[index].fundConvertedAmount = (amount*rate).toFixed(2)
    },


    deleteFundMapping(index) {
      this.fundMappingList.splice(index, 1);
      console.log(typeof this.fundMappingList)
      if (
        typeof this.fundMappingList !== "undefined" &&
        this.fundMappingList.length == 0
      ) {
        console.log("HelloWorld")
        this.fundMappingAddMoreDisabled = false;
        this.fundMappingSaveInfoDisabled = true;
      }
    },

    //this method will add new line for additional fund mapping
    addFundMapping() {
      //when new line is added then fundMappingSaveInfoDisabled (Save info button disable property) should be re initialised to true.
      (this.fundMappingSaveInfoDisabled = true),
        (this.fundMappingAddMoreDisabled = true),
        //following block is created first time on mounted property
        this.fundMappingList.push({
          selectedFund: null,
          paymentDate: null,
          startDate: null,
          selectedFundCurrency: null,
          fundAmount: null,
          fundConversionRate: null,
          fundConversionDate: null,
          fundConvertedAmount: null,
          fundExpensesDates: [],
          fundAmountError: false,
          fundAmountErrorMessage: "",
          backCapDate: new Date(),
        });
    }, //addFundMapping() close

    getCurrencyShortNameById(id) {
      if (id == null) {
        this.fundCurrencyShortName = '';
        return;
      }
      console.log(this.currencies)
      const currency = this.currencies.find((currency) => currency.id == id);
      this.fundCurrencyShortName = currency ? currency.currency_short_name : '';
    },
    

    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.totalFundAmount = 0;
        return;
      }

      if (this.fundMappingList.length == 0) {
        let warningMessage = `Please Map Some fund`;
        Utils.make_alert("warning", warningMessage);
        return;
      }
      

      let flag = false;

      // Check for duplicates and set the flag and error message
      this.fundMappingList.forEach((fund, index) => {
        console.log("fund",fund)
        console.log("this.amount",this.amount)
        if(Number(fund.fundAmount) > Number(this.amount)){
          fund.fundAmountError = true;
          fund.fundAmountErrorMessage = `Amount (Std. Currency) cannot be greater than Overall Amount ${this.amount} ${this.fundCurrencyShortName}`;
          flag = true;
          return;
        } else {
          flag = false;
          fund.fundAmountError = false;
          fund.fundAmountErrorMessage = "";
        }
      });

      console.log("FLAG",flag)

      if(flag) {
        return;
      }

      if(!flag) {
        this.calcTotalFundAmount();
        if (Number(this.totalFundAmount) > Number(this.amount)) {
          Utils.make_alert(
            "warning",
            `The sum of Fund Mapping Amount (Std. Currency) cannot be greater than the Overall Amount: ${this.amount} ${this.fundCurrencyShortName}`
          );
          this.totalFundAmount = 0;
          return;
          } else if (Number(this.totalFundAmount) != Number(this.amount)) {
            let warningMessage = `The Sum of Fund Mapping Amount (Std. Currency) should be equal to Overall Amount: ${this.amount} ${this.fundCurrencyShortName}.<br/> Kindly add/edit fund to utilize the complete Overall Amount`;
            Utils.make_alert("warning", warningMessage);
            this.totalFundAmount = 0;
            this.fundMappingSaveInfoDisabled = false;
            this.fundMappingAddMoreDisabled = false;
            return
          } else {
          this.fundMappingSaveInfoDisabled = false;
          this.fundMappingAddMoreDisabled = false;
        }
        this.totalFundAmount = 0;
      } else {
        this.fundMappingAddMoreDisabled = true;
      }

      this.ExpensePaymentDocuments.map((doc, index) => {
        if (doc.displayFileName === null) this.ExpensePaymentDocuments.splice(index, 1);
      });

      this.fundMappingList.forEach((fund)=>{
          if(fund.paymentDate != null){
            fund.paymentDate = moment(fund.paymentDate).format("MM-DD-YYYY");
          }
          if(fund.startDate != null){
            fund.startDate = moment(fund.startDate).format("MM-DD-YYYY");
          }
          if(fund.backCapDate != null){
            fund.backCapDate = moment(fund.backCapDate).format("MM-DD-YYYY");
          }
        })

      console.log(this.fundMappingList)

      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("selectedExpense", this.selectedExpense);
      formData.append("selectedExpenseType", this.selectedExpenseType);
      formData.append("amount", this.amount);
      formData.append("selectedCurrency", this.selectedCurrency);
      formData.append("selectedInterestFrequency", this.selectedInterestFrequency)
      formData.append("installments", this.installments);

      formData.append("nameOfEntity", this.nameOfEntity);
      formData.append("InvoiceNumber", this.InvoiceNumber);
      formData.append("Comments", this.Comments);

      formData.append("selectedBank", this.selectedBank);
      formData.append("bic", this.bic);
      formData.append("iban", this.iban);
      formData.append("accountNumber", this.accountNumber);
      formData.append("accountName", this.accountName);
      formData.append("reference", this.reference);
      formData.append("transactionID", this.transactionID);
      //append ExpensePaymentDocuments information to formdata
      formData.append("ExpensePaymentDocuments", JSON.stringify(this.ExpensePaymentDocuments));
      
      //append fund mapping information to formdata
      formData.append("fundMappingList", JSON.stringify(this.fundMappingList));
      formData.append("clientId", JSON.stringify(this.$store.state.client_id));
      formData.append("userId", this.$store.getters.getuserId);
      formData.append("moduleId", 1);
      formData.append("tabId", 1);
      const path = "add-proportionate-expense"
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data",
          },
        })
        .then((response) => {
          Utils.stop_loading();
          if (response.data.status == "success") {
            Utils.make_alert(
              "success",
              "Proportionate Expense Details Added successfully!"
            );
            console.log("SUCCESS!!");
            this.$router.push("/fund");
          } else {
            Utils.make_alert("success", "Something went wrong!");
            console.log("FAILURE!!");
          }
        })
        .catch((error) => {
          Utils.stop_loading();
          console.error(error);
          Utils.make_alert("success", "Something went wrong!");
        });
    }, //onSubmit() close

     //this method will make disabled property as false for Director Save Info
    onExpensePaymentDocumentSaveInfo() {
      Utils.make_alert("success", "Record saved.");
      this.ExpensePaymentDocumentSaveInfoDisabled = false;
      this.ExpensePaymentDocumentsAddMoreDisabled = false;
    },

    removeExpensePaymentDocuments(id) {
      this.ExpensePaymentDocumentFile = null;
      this.ExpensePaymentDocuments[id].selectedFile = null;
      this.ExpensePaymentDocuments[id].selectedFileName = null;
      this.ExpensePaymentDocuments[id].displayFileName = null;
      this.ExpensePaymentDocuments[id].comments = null;
      this.ExpensePaymentDocuments[id].ExpensePaymentDocumentsRemoveDisabled = true;
    },


    deleteExpensePaymentDocuments(index) {
      this.ExpensePaymentDocumentFile = this.ExpensePaymentDocumentFile
        ? this.ExpensePaymentDocumentFile
        : "file deleted";
      this.ExpensePaymentDocuments.splice(index, 1);
      if (
        typeof this.ExpensePaymentDocuments !== "undefined" &&
        this.ExpensePaymentDocuments.length == 0
      ) {
        this.ExpensePaymentDocumentsAddMoreDisabled = false;
        this.ExpensePaymentDocumentSaveInfoDisabled = true;
      }
    },

    //this method will add new line for additional Cost Documents
    addExpensePaymentDoc() {
      console.log("addExpensePaymentDoc");
        //when new line is added then ExpensePaymentDocumentFile variable and ExpensePaymentDocumentSaveInfoDisabled (Save info button disable property) should be re initialised to null.
        (this.ExpensePaymentDocumentFile = null),
          (this.ExpensePaymentDocumentSaveInfoDisabled = true),
          (this.ExpensePaymentDocumentsAddMoreDisabled = true),
          //following block is created first time on mounted property
          this.ExpensePaymentDocuments.push({
            id: null,
            displayFileName: null,
            comments: null,
            edited: "false",
            errors: { filesize: "" },
            ExpensePaymentDocumentsRemoveDisabled:true,
          });
      }, //addExpensePaymentDoc() close

    //handles a change on the file upload
    handleFileUploadExpensePaymentDocuments(id, e) {
      let allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(e.target.files[0].name)) {
          Utils.make_alert("warning", "Invalid File Type");
        } else {
          let self = this;
          let reader = new FileReader();
          this.ExpensePaymentDocumentFile = e.target.files[0];

          //check for filesize. if it is larger than threshold then return following error response
          if (this.ExpensePaymentDocumentFile.size > 104857600) {
            e.preventDefault();
            self.ExpensePaymentDocuments[id].errors.filesize =
              "File size should be less than 100MB";
            self.ExpensePaymentDocuments[id].selectedFile = null;
            self.ExpensePaymentDocuments[id].selectedFileName = null;
            self.ExpensePaymentDocuments[id].displayFileName = null;
            self.ExpensePaymentDocuments[id].comments = null;
            self.ExpensePaymentDocuments[id].ExpensePaymentDocumentsRemoveDisabled = true;
            return;
          }

          reader.readAsDataURL(this.ExpensePaymentDocumentFile);
          console.log("id: " + id);
          reader.onload = function () {
            self.ExpensePaymentDocuments[id].selectedFile = reader.result;
            self.ExpensePaymentDocuments[id].selectedFileName = e.target.files[0].name;
            self.ExpensePaymentDocuments[id].displayFileName = e.target.files[0].name; //to show filename in file input
            self.ExpensePaymentDocuments[id].errors.filesize = "";
            self.ExpensePaymentDocuments[id].ExpensePaymentDocumentsRemoveDisabled = false;
          };
          reader.onerror = function (error) {
            console.log("Error: ", error);
          };
        }
    }, //handleFileUploadExpensePaymentDocuments() close

    // getFund(id) {
    //   //this function is called to get list of funds
    //   const path = "/get-fund-by-facility/" + id;
    //   Utils.start_loading()
    //   axios
    //   .get(path, { params: { clientId: this.$store.state.client_id } })
    //   .then((res) => {
    //       Utils.stop_loading()
    //       this.funds = res.data.funds;
    //       this.fundMappingAddMoreDisabled = false;
    //       if(this.flow == 'Normal Expense'){
    //         this.fundMappingList = [];
    //       }
    //       if(this.flow == 'Fund'){
    //         this.onFundSelectGetDetails(0)
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },

    getExpenses() {
      //this function is called to get list of expenses
      const path = "expense";
      axios
        .get(path)
        .then((res) => {
          this.expenses = res.data.expenses;
          this.expenses = this.expenses.filter(exp=> exp.expense_name !== 'Management Fees')
          this.expenses = this.expenses.filter(exp=> exp.expense_name !== 'Performance Fees')
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getExpenseTypes() {
      //this function is called to get list of expense types
      const path = "expense-type";
      axios
        .get(path)
        .then((res) => {
          this.expenseTypes = res.data.expense_types;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getInterestFrequencies() {
      //this function is called to get list of interest frequencies
      const path = "interest-frequency";
      axios
        .get(path)
        .then((res) => {
          this.interestFrequencies = res.data.interest_frequencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFunds() {
      Utils.start_loading();
      //this function will be called to get list of funds
      const path = "fund";
      axios
        .get(path, { params: { clientId: this.$store.state.client_id } })
        .then((res) => {
          //this will be when response is received from server
          Utils.stop_loading();
          if (res.data.status == "success") {
            this.funds = res.data.funds_active;
            console.log("get funds is successful.");
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch((error) => {
          //this is when response is not received from server
          Utils.stop_loading();
          console.error("error: " + error);
        });
    },

    getCurrencies() {
      //this function is called to get list of currencies
      const path = "currency";
      axios
        .get(path)
        .then((res) => {
          this.currencies = res.data.currencies;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBanks() {
        //this function is called to get list of banks
        const path = "bank-detail";
        axios
          .get(path)
          .then((res) => {
            console.log(res)
            console.log(res.data.bank_details)
            this.banks = res.data.bank_details;
          })
          .catch((error) => {
            console.error(error);
          });
      },

  }, //main function close

  mounted() {
    this.addExpensePaymentDoc();
  },

  created() {
    this.getExpenses();
    this.getExpenseTypes();
    this.getInterestFrequencies();
    this.getFunds();
    this.getCurrencies();
    this.getBanks();
  },
};
</script>

<style scoped>

.date-open {
  min-height: 1250px !important;
}
.error {
  color: red;
  font-size: 13px;
  padding-left: 40px;
}
.form-control:disabled {
	background-color:#EEEEEE !important;
  opacity: 0.7;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
  left: -75px;
}

.vdp-datepicker >>> .form-control[disabled] {
  background-color:#EEEEEE !important;
  opacity: 0.7;
  pointer-events: none !important;
  cursor: not-allowed !important;
}
.toggle-padding {
  margin-left: 5px;
}
.headingt #interestCalculationLabelYes {
  margin-left: 250px;
}
.headingt #interestCalculationLabelNo {
  margin-left: 20px;
}
.headingt #offtakerRepaymentLabelYes {
  margin-left: 95px;
}
.headingt #offtakerRepaymentLabelNo {
  margin-left: 20px;
}
</style>
