<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-blue-dark">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <i class="icofont-navigation-menu"></i>
        </a>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Messages Dropdown Menu -->

      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="icofont-user-male"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <!--<span class="dropdown-header">15 Notifications</span>-->
          <!--<div class="dropdown-divider"></div>-->
          <a type="button" class="dropdown-item" v-on:click="onClickExcel()">
            User Manual
          </a>
          <!--<div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"> Option 2 </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"> Option 3 </a>-->
        </div>
      </li>

      <li class="nav-item">
        <router-link to="/logout" class="nav-link" role="button"
          ><i class="icofont-power"></i>
        </router-link>

        <!-- <a class="nav-link" href="/logout" role="button"
          ><i class="icofont-power"></i
        ></a> -->
      </li>
    </ul>
  </nav>
</template>

<script>
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
export default {
  name: "NavBar",
  // data() {
  //   return {
  //     notifCount: 0,
  //     noti: [],
  //   };
  // },
  // created() {
  //   axios
  //     .get("routes/stl-notification/self-stl-notifications/")
  //     .then((response) => {
  //       console.log(response.data);
  //     })
  //     .catch((err) => console.log(err));
  // },
  methods: {
    onClickExcel() {
      const path = "/user-manual";
      axios({
        url: path,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          if (response.data.type == "application/json") {
            Utils.make_alert(
              "warning",
              "Something went wrong! Unable to download report."
            );
          } else {
            var reportName = 'User Guide Lundy Loan Management.pdf'
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            // Filename seems to have to be changed unless otherwise provided
            // the extension can be automatically decided
            link.setAttribute("download", reportName);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((error) => {
          console.log("error: " + error);
        });
    },
  },
};
</script>

<style></style>
