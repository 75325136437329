<template>
  <div class="wrapper">
    <nav-bar></nav-bar>
    <aside-bar></aside-bar>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="content">
        <!-- Main content -->
        <div class="container-fluid tabtop pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt"><i class="icofont-file-text"></i><b>Payment Overview</b></h1>                 
                    </div>                     
                    <!-- /.col -->
                    <div class="col-lg-6">
                      <ul id="bck_btn">
                        <li>
                          <a @click="$router.go(-1)" :style="{ cursor: 'pointer' }">
                            <i class="icofont-caret-left text-dark"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /.col -->
                  </div>
                </div>
              </div>
                  <div class="col-lg-8">
                    <h6 class="headingt"><i class="icofont-coins"></i>{{investorName}}</h6>                      
                  </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        
        <div class="container-fluid ot_tb pt-3">
          <div class="card card-primary card-tabs">
            <div class="card-body">
              <div class="container-fluid" id="al_tb">
                <div class="tp_mid">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="headingt"><i class="icofont-money-bag"></i>{{fundName}}</h1>
                    </div>

                    <!-- /.col -->

                    <!--<div class="col-lg-6">
                      <ul id="sr_btn">
                        <li>
                          <div class="form-group has-search m-0">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control" placeholder="Search" />
                          </div>
                        </li>
                      </ul>
                    </div>-->

                    <!-- /.col -->

                  </div>
                </div>

                <div class="card-body table-responsive p-0">
                  <!------menton here style="height: 300px;"-------->
                  <table id="myTable1" class="table table-head-fixed text-nowrap table-hover myTable1">
                    <thead>
                      <tr>
                        <th onclick="sortTable('myTable1', 0)">Date<i class="icofont-sort sortdd"></i></th>
                        <th onclick="sortTable('myTable1', 1)">Amount<i class="icofont-sort sortdd"></i></th>
                        <th onclick="sortTable('myTable1', 2)">Currency<i class="icofont-sort sortdd"></i></th>
                        <!--<th onclick="sortTable('myTable1', 0)">Payment Type<i class="icofont-sort sortdd"></i></th>-->
                        <th onclick="sortTable('myTable1', 3)" class="c">Tranche<i class="icofont-sort sortdd"></i></th>
                        <th class="c" v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"></th>
                        <th class="c" v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"></th>
                      </tr>
                    </thead>

                    <div v-if="payments == undefined || payments.length === 0">No items to display</div>
                    <tbody>
                      <tr v-for="payment in payments">
                        <td v-if="payment.payment_date !== null ">{{ payment.payment_date }}</td>
                        <td v-else>-</td>
                        <td v-if="payment.payment_amount !== null ">{{ payment.payment_amount | numeral('0,0.00') }}</td>
                        <td v-else>-</td>
                        <td v-if="payment.currency_short_name !== null ">{{ payment.currency_short_name }}</td>
                        <td v-else>-</td>
                        <!--<td></td>-->
                        <td v-if="payment.tranche_no !== null " class="c">{{ payment.tranche_no }}</td>
                        <td v-else>-</td>
                        <td style="text-decoration:none;"
                          class="c" v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"
                          v-on:click="showEditModal(payment.payment_transaction_id,payment.payment_amount,payment.payment_date,payment.currency_id,payment.tranche_no)"
                          :style="{ cursor: 'pointer'}"
                        >
                          <i class="icofont-ui-edit text-warning"></i>
                        </td>
                        <td
                          class="c" v-if="loggedInUserGroupId == 'Admin' && loggedInUserSubGroupId == 0"
                          v-on:click="deleteInvestorPayment(payment.payment_transaction_id)"
                          :style="{ cursor: 'pointer' }"
                        >
                          <i class="icofont-trash text-danger"></i>
                        </td>
                      </tr>
                      <!----------------------------------------------->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
      <!-- Main content -->
    </div>
    <!-- /.content-wrapper-->

    <!-- ./modal start -->
    <div class="modal fade" id="edit_payment">
      <div class="modal-dialog modal-md-8">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="icofont-ui-edit"></i> Edit Payment
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              <i class="icofont-close-circled"></i>
            </button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">

              <div class="col-md-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field">Amount</label>
                  <input type="number" class="form-control" v-model="paymentAmount" />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1">Currency</label>
                  <select id="paymentCurrency" class="form-control" name="paymentCurrency" v-model="paymentCurrency">
                    <!--<option :value="null">-- Select a Currency --</option>-->
                    <option v-for="curr in currencies" :value="curr.id">
                    {{ curr.currency_short_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field">Payment Date</label>
                  <datepicker class="datepicker" :bootstrap-styling="true" name="paymentDate" placeholder="Select Date" v-model="paymentDate" :format="$store.state.date_format" calendar-button-icon="fa fa-calendar" :calendar-button="true"></datepicker>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group fc">
                  <label for="exampleInputEmail1" class="required-field">Tranche No</label>
                  <input type="number" class="form-control" v-model="trancheNo" />
                </div>
              </div>

            </div>
          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="mx-auto">
              <button type="button" class="btn-sm btn btn-success sh" v-on:click="editInvestorPayment()">Submit</button>
              <button type="button" class="btn-sm btn btn-dark sh" data-dismiss="modal">Cancel</button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- ./modal end -->

  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function

export default {  
  data() {
    return {
      investor_id: null,
      fund_id: null,
      paymentStartDate: null,
      paymentEndDate: null,
      payments: [],
      fundName: null,
      investorName: null,
      //following fields required for edit modal payment
      paymentTransactionId: null,
      paymentAmount: null,
      paymentDate: null,
      paymentCurrency: null,
      trancheNo: null,
      currencies: [],
      start_date: null,
      end_date: null,
    };
  },
  components: {
    Datepicker
  },
  methods: {
      getPaymentByInvestorFundId(e) {
        if(this.start_date != null){
            this.start_date = moment(this.start_date).format('MM-DD-YYYY')
          }
        if(this.end_date != null){
            this.end_date = moment(this.end_date).format('MM-DD-YYYY')
          }
				const path = 'payment-by-investor-fund-id/'+this.$route.params.investor_id+'/'+this.$route.params.fund_id+'/'+this.start_date+'/'+this.end_date;
				axios.get(path)
          .then((res) => {
            console.log(res.data)
            this.payments = res.data.payments;
            this.fundName = res.data.fund_name;
            this.investorName = res.data.investor_name;
            if(res.data.status == 'success') {
              console.log('get payment by investor-fund id is successful.');
            }			
					})
					.catch((error) => {
					console.error('error: ' + error);
					});
			},	//getPaymentByInvestorId() close

      deleteInvestorPayment(paymentTransactionId) {
        //this function will be called to delete a specific investor fund expense
        if(confirm("Confirm delete?")){
            axios.delete('payment-by-investor-fund-id/'+this.fund_id+'/'+this.investor_id+'/'+this.paymentStartDate+'/'+this.paymentEndDate,
                { data: 
                    { 
                    paymentTransactionId: paymentTransactionId,
                    }
                },
                { headers: 
                    {
                    'Content-type': 'application/json',
                    }
                }).then((response) => {
                    console.log('delete invetor payment status: ' + response.data.status);
                    if(response.data.status == 'success') {
                    Utils.make_alert("success", 'Payment deleted successfully!');
                    //if you want to send any data into server before redirection then you can do it here
                    this.$router.go(this.$router.currentRoute)
                    }
                    else {
                    console.log('Something went wrong from server!');
                    Utils.make_alert("success", "Something went wrong!")
                    }
                }).catch(error => { 
                    console.log('error: ' + error); 
                    //this.$router.go(this.$router.currentRoute)
                });					
        }else{
            Utils.make_alert("success", "Cancelled!")
        }
        },	//deleteFundExpense() close

      showEditModal(payment_transaction_id,payment_amount,payment_date,currency,tranche_no) {
        this.paymentTransactionId = payment_transaction_id;
        this.paymentAmount = payment_amount;
        this.paymentDate = payment_date;
        this.paymentCurrency = currency;
        this.trancheNo = tranche_no;
        $('#edit_payment').modal('show');
      },	//showEditModal() close

      editInvestorPayment() {
				//this function is called on submit button for Edit Payment
          if(this.paymentDate === null || this.paymentAmount === null){
            Utils.make_alert("success", "Mandatory fields are required.");
            return;
          }
          if(this.paymentDate != null){
            this.paymentDate = moment(this.paymentDate).format('MM-DD-YYYY')
          }
					if(confirm("Confirm submit?")){	
						axios.put('payment-by-investor-fund-id/'+this.fund_id+'/'+this.investor_id+'/'+this.paymentStartDate+'/'+this.paymentEndDate,
              { 
              paymentTransactionId: this.paymentTransactionId,
              paymentAmount: this.paymentAmount,
              paymentDate: this.paymentDate,
              paymentCurrency: this.paymentCurrency,
              trancheNo: this.trancheNo,
              },
							{ headers: 
								{
								'Content-type': 'application/json',
								}
							}).then((response) => {
								console.log('edit payment status: ' + response.data.status);
								if(response.data.status == 'success') {
								Utils.make_alert("success", 'Payment updated successfully!');
								//if you want to send any data into server before redirection then you can do it here
								this.$router.push('/investor-overview/'+this.investor_id);
								}
								else {
                  Utils.make_alert("success", 'Something went wrong!');
								}
							}).catch(error => { 
                  console.log('error: ' + error); 
                  console.log('Something went wrong!');                  
							});					
					} else{
						Utils.make_alert("success", "Cancelled!")
					}	
				
			},	//editInvestorPayment() close

      getCurrencies() {
        //this function is called to get list of currencies
        const path = 'currency';
        axios.get(path)
            .then((res) => {
            this.currencies = res.data.currencies;
            })
            .catch((error) => {
            console.error(error);
            });
      },
  },

created() {
    this.investor_id = this.$route.params.investor_id;
    this.fund_id = this.$route.params.fund_id;
    this.start_date = this.$route.params.start_date;
    this.end_date = this.$route.params.end_date;
    this.getPaymentByInvestorFundId();
    this.getCurrencies();
  }
};
</script>

<style scoped>

.required-field::after {
    content: "*";
    color: red;
}
</style>
